import React from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import * as VendorEnrollmentViewActions from '../actions/VendorEnrollmentView';
import Details from '../components/Details';
import EnrollmentConfirmation from '../components/EnrollmentConfirmation';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { renderError, falseIfUndefined } from 'utils/HelperFunctions';
import VendorEnrollmentViewSelector from '../selectors/VendorEnrollmentView';
import { fromJS } from 'immutable';
import {defineMessages, injectIntl} from 'react-intl';


class VendorEnrollmentView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitClicked: false
        }
    }

    componentDidMount() {
        this.props.dispatch(VendorEnrollmentViewActions.resetEnrollmentInfo);
        this.props.dispatch({ type: "TERMS_AND_CONDITIONS_STATIC" });
        let requestInfo = {
            name: constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.ID,
            value: this.props.routeParams.match.params.enrollId
        };
        this.props.dispatch({ type: constants.UPDATE_ENROLLMENT_DETAIL_INFO, requestInfo});

    }

    onRequestInfoChangeHandler(inputName, event) {
        let requestInfo = {
            name: inputName,
            value: inputName !== "TERMS_READ_AND_AGREED"? event.target.value : event
        };

        this.props.dispatch(VendorEnrollmentViewActions.addRequestInfo(requestInfo));  
         
        if(inputName== constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SELECTED_TAX_CLASSIFICATION)     {
            if( event.target.value!="L"){
                let llcClassification={
                    name:constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.LIABILITY_TAX_CLASSIFICATION,
                    value:undefined
                };
                
                this.props.dispatch(VendorEnrollmentViewActions.addRequestInfo(llcClassification));   
            }
        }
        this.props.dispatch(VendorEnrollmentViewActions.formIsDirty());
    }
    onSubmitDetailsHandler() {        
        this.setState({ isSubmitClicked: true })
        this.props.dispatch(VendorEnrollmentViewActions.submitDetailInfo());
    }

    onServiceClassificationChangeHandler(isSelectedBusiness) {

        let requestInfo = {
            name:  constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SERVICE_CLASSIFICATION,
            value:isSelectedBusiness ? "CARWASH" : "REPAIR"
        };

        this.props.dispatch(VendorEnrollmentViewActions.addRequestInfo(requestInfo));
        this.props.dispatch(VendorEnrollmentViewActions.selectedBusiness(isSelectedBusiness));
    }

    render() {
        const messages = defineMessages({
            unsaved_data: {
                id: 'unsaved_data',
                description: 'unsaved_data',
                defaultMessage: "You have unsaved changes that will be lost if you decide to continue. Click OK to continue or Cancel to stay on the same page."
            },
        });
        const {formatMessage} = this.props.intl;
        return (
            <div>
                <Prompt when={!this.state.isSubmitClicked && this.props.isDirty}
    message={formatMessage(messages.unsaved_data)} />

    {
    //render server error                    
    renderError('genericErrorMessage', this.props, this.props.genericErrorMessage)
    }
        {
            !this.props.requestSubmitted ?
                <Details enrollmentDetailsInfo={this.props.enrollmentDetailsInfo}
            onRequestInfoChange={this.onRequestInfoChangeHandler.bind(this)}
            termsAndConditions={this.props.termsAndConditions}
            federalTaxClassifications={this.props.federalTaxClassifications}
            isLiabilitySelected={this.props.isLiabilitySelected}
            onSubmit={this.onSubmitDetailsHandler.bind(this)}
            formValidationDetails={this.props.formValidationDetails}
            error={renderError('enrollmentRequestErrorMessage', this.props, this.props.enrollmentRequestErrorMessage)}
            onServiceClassificationChange={this.onServiceClassificationChangeHandler.bind(this)}
        isServiceClassification={this.props.isServiceClassification}
        toggleAcceptedFlag={this.props.toggleAcceptedFlag}
        isFetchingTermsAndConditions={this.props.isFetchingTermsAndConditions}
        country={this.props.country}    />
    : <EnrollmentConfirmation requestId={this.props.requestId} />
    }
            </div>
        );
            }
};

function mapStateToProps(state, props) {
    return {
        ...VendorEnrollmentViewSelector(state, props),
        enrollmentDetailsInfo: state.appState.getIn(['uiData', 'vendorEnrollmentView', 'enrollmentDetailsInfo']),
        federalTaxClassifications: state.appState.getIn(['uiData', 'vendorEnrollmentView', 'federalTaxClassifications']),
        liabilityTaxClassification: state.appState.getIn(['uiData', 'vendorEnrollmentView', 'liabilityTaxClassification']),
        requestSubmitted: state.appState.getIn(['uiData', 'vendorEnrollmentView', 'requestSubmitted']),
        enrollmentRequestErrorMessage: state.appState.getIn(['uiData', 'vendorEnrollmentView', 'errorMessage']),
        genericErrorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        isDirty: state.appState.getIn(['uiData', 'vendorEnrollmentView', 'isDirty']),
        isServiceClassification:falseIfUndefined(state.appState.getIn(['uiData', 'vendorEnrollmentView', 'selectedServiceClassification'])),
        termsAndConditions:state.appState.getIn(['serverData', 'shared', 'vendorTermsAndConditions']) || null,
        toggleAcceptedFlag:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','toggleAcceptedFlag']) || false,
        isFetchingTermsAndConditions:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','isFetchingTermsAndCondition']) || false,
        country:state.appState.getIn(['uiData','vendorEnrollmentRequestView','enrollmentRequestInfo','country'])

    }
}

export default connect(mapStateToProps)(injectIntl(VendorEnrollmentView));