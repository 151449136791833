// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Popover } from 'react-bootstrap';
// our stuff
import './VehicleComponents.css';
import CheckCircle from 'components/CheckCircle';
import VehicleComponent from './VehicleComponent';
import CraneResult from './CraneResult';
import VehicleComponentGroup from './VehicleComponentGroup';
import CraneManufactureInformation from './CraneManufactureInformation';

class VehicleComponents extends React.Component {
	constructor(props) {
		super(props);
		this.handleChangeDebounced = _.debounce(this.handleChange, 1000);
	}

	handleChange = (id, value) => {
		this.props.setFormData(id, value);
	}

	handleInputChange = (event) => {
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);
	}

	handleChangeDebouncedCaller = (event) => {
		// Note: You must call the debounced function with id and value instead of passing the event object.
		//       Event may be null by the time the debounced function is invoked.
		this.handleChangeDebounced(event.target.id, event.target.value);
	}

	render() {

		const messages = defineMessages({
			crane_inspection_form__all_ok: {
				id: 'crane_inspection_form__all_ok',
				description: 'Crane inspection form All Ok button.',
				defaultMessage: 'All OK'
			},

			crane_inspection_form__manufacturer: {
				id: 'crane_inspection__manufacturer',
				description: 'Manufacturer',
				defaultMessage: 'Manufacturer'
			},
			crane_inspection_form__manufacturer_required: {
				id: 'crane_inspection__manufacturer_required',
				description: 'Manufacturer Required',
				defaultMessage: 'Provide manufacturer'
			},
			crane_inspection_form__capacity: {
				id: 'crane_inspection__capacity',
				description: 'Capacity',
				defaultMessage: 'Capacity'
			},
			crane_inspection_form__capacity_required: {
				id: 'crane_inspection__capacity_required',
				description: 'Required',
				defaultMessage: 'Provide capacity'
			},
			crane_inspection_form__serial_number: {
				id: 'crane_inspection__serial_number',
				description: 'Serial number',
				defaultMessage: 'Serial number'
			},
			crane_inspection_form__serial_number_required: {
				id: 'crane_inspection__serial_number_required',
				description: 'Serial Number Required',
				defaultMessage: 'Provide serial number'
			},
			crane_inspection_form__component_subtext_line1: {
				id: 'crane_inspection_form__component_subtext_line1',
				description: 'Crane inspection form component subtext line 1',
				defaultMessage: '* Important to maintain current copy of inspection report in this vehicle.'
			},
			crane_inspection_form__component_subtext_line2: {
				id: 'crane_inspection_form__component_subtext_line2',
				description: 'Crane inspection form component subtext line 2',
				defaultMessage: '* Local State laws may request inspections more frequently. Investigate all States in which you travel.'
			},
			crane_inspection_form__component_subtext_line3: {
				id: 'crane_inspection_form__component_subtext_line3',
				description: 'Crane inspection form component subtext line 3',
				defaultMessage: 'All unsatisfactory wire rope shall be replaced as per original manufacturer\'s recommendations.'
			},
			crane_inspection_form__component_300: {
				id: 'crane_inspection_form__component_300',
				description: 'Crane Inspection form component 300.',
				defaultMessage: 'Current certification posting'
			},
			crane_inspection_form__component_301: {
				id: 'crane_inspection_form__component_301',
				description: 'Crane Inspection form component 301.',
				defaultMessage: '*Operator instructions'
			},
			crane_inspection_form__component_302: {
				id: 'crane_inspection_form__component_302',
				description: 'Crane Inspection form component 302.',
				defaultMessage: '*Preventative maintenance records'
			},
			crane_inspection_form__component_303: {
				id: 'crane_inspection_form__component_303',
				description: 'Crane Inspection form component 303.',
				defaultMessage: 'Hand signal charts'
			},
			crane_inspection_form__component_304: {
				id: 'crane_inspection_form__component_304',
				description: 'Crane Inspection form component 304.',
				defaultMessage: '*Proper load charts'
			},
			crane_inspection_form__component_305: {
				id: 'crane_inspection_form__component_305',
				description: 'Crane Inspection form component 305.',
				defaultMessage: 'Properly marked operator controls, levels, diagrams'
			},
			crane_inspection_form__component_306: {
				id: 'crane_inspection_form__component_306',
				description: 'Crane Inspection form component 306.',
				defaultMessage: 'Required informational/warning decals'
			},
			crane_inspection_form__component_307: {
				id: 'crane_inspection_form__component_307',
				description: 'Crane Inspection form component 307.',
				defaultMessage: 'Warning device(s)/Backup alarm'
			},
			crane_inspection_form__component_308: {
				id: 'crane_inspection_form__component_308',
				description: 'Crane Inspection form component 308.',
				defaultMessage: 'Safety and machine guards'
			},
			crane_inspection_form__component_309: {
				id: 'crane_inspection_form__component_309',
				description: 'Crane Inspection form component 309.',
				defaultMessage: 'Access provisions (railings, etc.)'
			},
			crane_inspection_form__component_310: {
				id: 'crane_inspection_form__component_310',
				description: 'Crane Inspection form component 310.',
				defaultMessage: 'Fire extinguishers'
			},
			crane_inspection_form__component_311: {
				id: 'crane_inspection_form__component_311',
				description: 'Crane Inspection form component 311.',
				defaultMessage: 'Anti-two block'
			},
			crane_inspection_form__component_312: {
				id: 'crane_inspection_form__component_312',
				description: 'Crane Inspection form component 312.',
				defaultMessage: 'Load cells and indicators'
			},
			crane_inspection_form__component_313: {
				id: 'crane_inspection_form__component_313',
				description: 'Crane Inspection form component 313.',
				defaultMessage: 'Electrical components/assemblies'
			},
			crane_inspection_form__component_314: {
				id: 'crane_inspection_form__component_314',
				description: 'Crane Inspection form component 314.',
				defaultMessage: 'Emergency stop switch'
			},
			crane_inspection_form__component_315: {
				id: 'crane_inspection_form__component_315',
				description: 'Crane Inspection form component 315.',
				defaultMessage: 'Overload switch'
			},
			crane_inspection_form__component_316: {
				id: 'crane_inspection_form__component_316',
				description: 'Crane Inspection form component 316.',
				defaultMessage: 'Master switches or drum controller'
			},
			crane_inspection_form__component_317: {
				id: 'crane_inspection_form__component_317',
				description: 'Crane Inspection form component 317.',
				defaultMessage: 'Switches, contactors, relays'
			},
			crane_inspection_form__component_318: {
				id: 'crane_inspection_form__component_318',
				description: 'Crane Inspection form component 318.',
				defaultMessage: '*Main control station'
			},
			crane_inspection_form__component_319: {
				id: 'crane_inspection_form__component_319',
				description: 'Crane Inspection form component 319.',
				defaultMessage: 'Remote control station and buttons'
			},
			crane_inspection_form__component_320: {
				id: 'crane_inspection_form__component_320',
				description: 'Crane Inspection form component 320.',
				defaultMessage: 'Bucket/ground controls'
			},
			crane_inspection_form__component_321: {
				id: 'crane_inspection_form__component_321',
				description: 'Crane Inspection form component 321.',
				defaultMessage: 'Indicators: level, boom angle/length, drum rotation, load.'
			},
			crane_inspection_form__component_322: {
				id: 'crane_inspection_form__component_322',
				description: 'Crane Inspection form component 322.',
				defaultMessage: 'Motors'
			},
			crane_inspection_form__component_323: {
				id: 'crane_inspection_form__component_323',
				description: 'Crane Inspection form component 323.',
				defaultMessage: '*Shafts, gearing, coupling, guards'
			},
			crane_inspection_form__component_324: {
				id: 'crane_inspection_form__component_324',
				description: 'Crane Inspection form component 324.',
				defaultMessage: '*Bearing assembly(s)'
			},
			crane_inspection_form__component_325: {
				id: 'crane_inspection_form__component_325',
				description: 'Crane Inspection form component 325.',
				defaultMessage: 'Seals'
			},
			crane_inspection_form__component_326: {
				id: 'crane_inspection_form__component_326',
				description: 'Crane Inspection form component 326.',
				defaultMessage: 'Sheave assemblies'
			},
			crane_inspection_form__component_327: {
				id: 'crane_inspection_form__component_327',
				description: 'Crane Inspection form component 327.',
				defaultMessage: '*Equalizer sheave and pin'
			},
			crane_inspection_form__component_328: {
				id: 'crane_inspection_form__component_328',
				description: 'Crane Inspection form component 328.',
				defaultMessage: 'Brakes'
			},
			crane_inspection_form__component_329: {
				id: 'crane_inspection_form__component_329',
				description: 'Crane Inspection form component 329.',
				defaultMessage: 'Limit switch(s)'
			},
			crane_inspection_form__component_330: {
				id: 'crane_inspection_form__component_330',
				description: 'Crane Inspection form component 330.',
				defaultMessage: 'Locking devices'
			},
			crane_inspection_form__component_331: {
				id: 'crane_inspection_form__component_331',
				description: 'Crane Inspection form component 331.',
				defaultMessage: '*Drums and anchors'
			},
			crane_inspection_form__component_332: {
				id: 'crane_inspection_form__component_332',
				description: 'Crane Inspection form component 332.',
				defaultMessage: '*Load block assembly(s)'
			},
			crane_inspection_form__component_333: {
				id: 'crane_inspection_form__component_333',
				description: 'Crane Inspection form component 333.',
				defaultMessage: 'Counterweight hooks and bolts'
			},
			crane_inspection_form__component_334: {
				id: 'crane_inspection_form__component_334',
				description: 'Crane Inspection form component 334.',
				defaultMessage: '*Hooks tram/throat'
			},
			crane_inspection_form__component_335: {
				id: 'crane_inspection_form__component_335',
				description: 'Crane Inspection form component 335.',
				defaultMessage: 'Hook NDT'
			},
			crane_inspection_form__component_336: {
				id: 'crane_inspection_form__component_336',
				description: 'Crane Inspection form component 336.',
				defaultMessage: '*Bearing assembly(s)'
			},
			crane_inspection_form__component_337: {
				id: 'crane_inspection_form__component_337',
				description: 'Crane Inspection form component 337.',
				defaultMessage: '*Seals'
			},
			crane_inspection_form__component_338: {
				id: 'crane_inspection_form__component_338',
				description: 'Crane Inspection form component 338.',
				defaultMessage: 'Brakes'
			},
			crane_inspection_form__component_339: {
				id: 'crane_inspection_form__component_339',
				description: 'Crane Inspection form component 339.',
				defaultMessage: 'Drive line and battery(s)'
			},
			crane_inspection_form__component_340: {
				id: 'crane_inspection_form__component_340',
				description: 'Crane Inspection form component 340.',
				defaultMessage: '*Friction clutches, linkage, and pins'
			},
			crane_inspection_form__component_341: {
				id: 'crane_inspection_form__component_341',
				description: 'Crane Inspection form component 341.',
				defaultMessage: '*Gear box; all gears and pinions'
			},
			crane_inspection_form__component_342: {
				id: 'crane_inspection_form__component_342',
				description: 'Crane Inspection form component 342.',
				defaultMessage: '*Boom and attachments'
			},
			crane_inspection_form__component_343: {
				id: 'crane_inspection_form__component_343',
				description: 'Crane Inspection form component 343.',
				defaultMessage: '*Boom rotation assy., locks, and brakes'
			},
			crane_inspection_form__component_344: {
				id: 'crane_inspection_form__component_344',
				description: 'Crane Inspection form component 344.',
				defaultMessage: '*Boom hoist lock or pawl'
			},
			crane_inspection_form__component_345: {
				id: 'crane_inspection_form__component_345',
				description: 'Crane Inspection form component 345.',
				defaultMessage: '*Boom and jib back stops'
			},
			crane_inspection_form__component_346: {
				id: 'crane_inspection_form__component_346',
				description: 'Crane Inspection form component 346.',
				defaultMessage: 'Swing barriers'
			},
			crane_inspection_form__component_347: {
				id: 'crane_inspection_form__component_347',
				description: 'Crane Inspection form component 347.',
				defaultMessage: '*Automatic boom kickout'
			},
			crane_inspection_form__component_348: {
				id: 'crane_inspection_form__component_348',
				description: 'Crane Inspection form component 348.',
				defaultMessage: '*Outrigger assembly(s)'
			},
			crane_inspection_form__component_349: {
				id: 'crane_inspection_form__component_349',
				description: 'Crane Inspection form component 349.',
				defaultMessage: '*Welds, chords, lacings'
			},
			crane_inspection_form__component_350: {
				id: 'crane_inspection_form__component_350',
				description: 'Crane Inspection form component 350.',
				defaultMessage: '*Wedge sockets'
			},
			crane_inspection_form__component_351: {
				id: 'crane_inspection_form__component_351',
				description: 'Crane Inspection form component 351.',
				defaultMessage: 'Drums'
			},
			crane_inspection_form__component_352: {
				id: 'crane_inspection_form__component_352',
				description: 'Crane Inspection form component 352.',
				defaultMessage: 'Automatic lubrication systems'
			},
			crane_inspection_form__component_353: {
				id: 'crane_inspection_form__component_353',
				description: 'Crane Inspection form component 353.',
				defaultMessage: 'Manual lubrication/fluid levels'
			},
			crane_inspection_form__component_354: {
				id: 'crane_inspection_form__component_354',
				description: 'Crane Inspection form component 354.',
				defaultMessage: 'Gauge (oil, etc.) and gauge ports'
			},
			crane_inspection_form__component_355: {
				id: 'crane_inspection_form__component_355',
				description: 'Crane Inspection form component 355.',
				defaultMessage: 'Superstructure (main frame, glass)'
			},
			crane_inspection_form__component_356: {
				id: 'crane_inspection_form__component_356',
				description: 'Crane Inspection form component 356.',
				defaultMessage: 'Bolts, nuts, rivets, mounts, brackets'
			},
			crane_inspection_form__component_357: {
				id: 'crane_inspection_form__component_357',
				description: 'Crane Inspection form component 357.',
				defaultMessage: '*Air/hydraulic system: cylinders, hoses and fittings'
			},
			crane_inspection_form__component_358: {
				id: 'crane_inspection_form__component_358',
				description: 'Crane Inspection form component 358.',
				defaultMessage: 'Muffler/exhaust system'
			},
			crane_inspection_form__component_359: {
				id: 'crane_inspection_form__component_359',
				description: 'Crane Inspection form component 359.',
				defaultMessage: 'Housekeeping'
			},
			crane_inspection_form__component_360: {
				id: 'crane_inspection_form__component_360',
				description: 'Crane Inspection form component 360.',
				defaultMessage: 'Steering'
			},
			crane_inspection_form__component_361: {
				id: 'crane_inspection_form__component_361',
				description: 'Crane Inspection form component 361.',
				defaultMessage: 'Weldments'
			},
			crane_inspection_form__component_362: {
				id: 'crane_inspection_form__component_362',
				description: 'Crane Inspection form component 362.',
				defaultMessage: 'Bolts'
			},
			crane_inspection_form__component_363: {
				id: 'crane_inspection_form__component_363',
				description: 'Crane Inspection form component 363.',
				defaultMessage: 'Tracks'
			},
			crane_inspection_form__component_364: {
				id: 'crane_inspection_form__component_364',
				description: 'Crane Inspection form component 364.',
				defaultMessage: 'Track drive'
			},
			crane_inspection_form__component_365: {
				id: 'crane_inspection_form__component_365',
				description: 'Crane Inspection form component 365.',
				defaultMessage: 'Main Hoist'
			},
			crane_inspection_form__component_366: {
				id: 'crane_inspection_form__component_366',
				description: 'Crane Inspection form component 366.',
				defaultMessage: 'Aux. Hoist'
			},
			crane_inspection_form__component_367: {
				id: 'crane_inspection_form__component_367',
				description: 'Crane Inspection form component 367.',
				defaultMessage: 'Aux. Hoist (2)'
			},
			crane_inspection_form__component_368: {
				id: 'crane_inspection_form__component_368',
				description: 'Crane Inspection form component 368.',
				defaultMessage: 'Pendants'
			},
			crane_inspection_form__component_369: {
				id: 'crane_inspection_form__component_369',
				description: 'Crane Inspection form component 369.',
				defaultMessage: 'Pendants (2)'
			},
			crane_inspection_form__component_370: {
				id: 'crane_inspection_form__component_370',
				description: 'Crane Inspection form component 370.',
				defaultMessage: 'Jib Stays'
			},
			crane_inspection_form__component_371: {
				id: 'crane_inspection_form__component_371',
				description: 'Crane Inspection form component 371.',
				defaultMessage: 'Boom Hoist'
			},
			crane_inspection_form__component_group_18: {
				id: 'crane_inspection_form__component_group_18',
				description: 'Crane Inspection form component group 18.',
				defaultMessage: 'Records'
			},
			crane_inspection_form__component_group_19: {
				id: 'crane_inspection_form__component_group_19',
				description: 'Crane Inspection form component group 19.',
				defaultMessage: 'Safety equipment'
			},
			crane_inspection_form__component_group_20: {
				id: 'crane_inspection_form__component_group_20',
				description: 'Crane Inspection form component group 20.',
				defaultMessage: 'Electrical systems & control systems'
			},
			crane_inspection_form__component_group_21: {
				id: 'crane_inspection_form__component_group_21',
				description: 'Crane Inspection form component group 21.',
				defaultMessage: 'Hoist systems'
			},
			crane_inspection_form__component_group_22: {
				id: 'crane_inspection_form__component_group_22',
				description: 'Crane Inspection form component group 22.',
				defaultMessage: 'Boom / jib'
			},
			crane_inspection_form__component_group_23: {
				id: 'crane_inspection_form__component_group_23',
				description: 'Crane Inspection form component group 23.',
				defaultMessage: 'Lubes systems gauges'
			},
			crane_inspection_form__component_group_24: {
				id: 'crane_inspection_form__component_group_24',
				description: 'Crane Inspection form component group 24.',
				defaultMessage: 'Miscellaneous'
			},
			crane_inspection_form__component_group_25: {
				id: 'crane_inspection_form__component_group_25',
				description: 'Crane Inspection form component group 25.',
				defaultMessage: 'Crawler car body'
			},
			crane_inspection_form__component_group_26: {
				id: 'crane_inspection_form__component_group_26',
				description: 'Crane Inspection form component group 26.',
				defaultMessage: 'Wire rope and hook condition report'
			}
		});

		return (
			<div>

				<CraneManufactureInformation
					formData={this.props.formData}
					setFormData={this.props.setFormData}
					formValidationMessages={this.props.formValidationMessages}
				/>

				<div className='crane_inspection_vehicle_components__all_ok'>

					<CheckCircle
						name='isAllOk'
						className='crane_inspection_vehicle_components__check_circle'
						message={<FormattedMessage {...messages.crane_inspection_form__all_ok} />}
						checked={this.props.formData.get('isAllOk')}
						onClick={(name, checked) => this.props.setFormData(name, !checked)}
					/>

				</div>

				<VehicleComponentGroup
					groupId='18'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_18} />}
				/>

				<VehicleComponent
					componentId='300'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_300} />}
					componentDetails={this.props.componentDetails.get('300')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='301'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_301} />}
					componentDetails={this.props.componentDetails.get('301')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='302'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_302} />}
					componentDetails={this.props.componentDetails.get('302')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='303'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_303} />}
					componentDetails={this.props.componentDetails.get('303')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='304'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_304} />}
					componentDetails={this.props.componentDetails.get('304')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='305'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_305} />}
					componentDetails={this.props.componentDetails.get('305')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='306'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_306} />}
					componentDetails={this.props.componentDetails.get('306')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>

				<VehicleComponentGroup
					groupId='19'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_19} />}
				/>

				<VehicleComponent
					componentId='307'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_307} />}
					componentDetails={this.props.componentDetails.get('307')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='308'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_308} />}
					componentDetails={this.props.componentDetails.get('308')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='309'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_309} />}
					componentDetails={this.props.componentDetails.get('309')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='310'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_310} />}
					componentDetails={this.props.componentDetails.get('310')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='311'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_311} />}
					componentDetails={this.props.componentDetails.get('311')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='312'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_312} />}
					componentDetails={this.props.componentDetails.get('312')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponentGroup
					groupId='20'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_20} />}
				/>

				<VehicleComponent
					componentId='313'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_313} />}
					componentDetails={this.props.componentDetails.get('313')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='314'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_314} />}
					componentDetails={this.props.componentDetails.get('314')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='315'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_315} />}
					componentDetails={this.props.componentDetails.get('315')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='316'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_316} />}
					componentDetails={this.props.componentDetails.get('316')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='317'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_317} />}
					componentDetails={this.props.componentDetails.get('317')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='318'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_318} />}
					componentDetails={this.props.componentDetails.get('318')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='319'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_319} />}
					componentDetails={this.props.componentDetails.get('319')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='320'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_320} />}
					componentDetails={this.props.componentDetails.get('320')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='321'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_321} />}
					componentDetails={this.props.componentDetails.get('321')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponentGroup
					groupId='21'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_21} />}
				/>

				<VehicleComponent
					componentId='322'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_322} />}
					componentDetails={this.props.componentDetails.get('322')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='323'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_323} />}
					componentDetails={this.props.componentDetails.get('323')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='324'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_324} />}
					componentDetails={this.props.componentDetails.get('324')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='325'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_325} />}
					componentDetails={this.props.componentDetails.get('325')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='326'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_326} />}
					componentDetails={this.props.componentDetails.get('326')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='327'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_327} />}
					componentDetails={this.props.componentDetails.get('327')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='328'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_328} />}
					componentDetails={this.props.componentDetails.get('328')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='329'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_329} />}
					componentDetails={this.props.componentDetails.get('329')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='330'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_330} />}
					componentDetails={this.props.componentDetails.get('330')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='331'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_331} />}
					componentDetails={this.props.componentDetails.get('331')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='332'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_332} />}
					componentDetails={this.props.componentDetails.get('332')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='333'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_333} />}
					componentDetails={this.props.componentDetails.get('333')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='334'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_334} />}
					componentDetails={this.props.componentDetails.get('334')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='335'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_335} />}
					componentDetails={this.props.componentDetails.get('335')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponentGroup
					groupId='22'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_22} />}
				/>

				<VehicleComponent
					componentId='336'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_336} />}
					componentDetails={this.props.componentDetails.get('336')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='337'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_337} />}
					componentDetails={this.props.componentDetails.get('337')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='338'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_338} />}
					componentDetails={this.props.componentDetails.get('338')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='339'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_339} />}
					componentDetails={this.props.componentDetails.get('339')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='340'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_340} />}
					componentDetails={this.props.componentDetails.get('340')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='341'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_341} />}
					componentDetails={this.props.componentDetails.get('341')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='342'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_342} />}
					componentDetails={this.props.componentDetails.get('342')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='343'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_343} />}
					componentDetails={this.props.componentDetails.get('343')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='344'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_344} />}
					componentDetails={this.props.componentDetails.get('344')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='345'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_345} />}
					componentDetails={this.props.componentDetails.get('345')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='346'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_346} />}
					componentDetails={this.props.componentDetails.get('346')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='347'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_347} />}
					componentDetails={this.props.componentDetails.get('347')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='348'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_348} />}
					componentDetails={this.props.componentDetails.get('348')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='349'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_349} />}
					componentDetails={this.props.componentDetails.get('349')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='350'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_350} />}
					componentDetails={this.props.componentDetails.get('350')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='351'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_351} />}
					componentDetails={this.props.componentDetails.get('351')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponentGroup
					groupId='23'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_23} />}
				/>

				<VehicleComponent
					componentId='352'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_352} />}
					componentDetails={this.props.componentDetails.get('352')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='353'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_353} />}
					componentDetails={this.props.componentDetails.get('353')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='354'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_354} />}
					componentDetails={this.props.componentDetails.get('354')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponentGroup
					groupId='24'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_24} />}
				/>

				<VehicleComponent
					componentId='355'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_355} />}
					componentDetails={this.props.componentDetails.get('355')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='356'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_356} />}
					componentDetails={this.props.componentDetails.get('356')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='357'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_357} />}
					componentDetails={this.props.componentDetails.get('357')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='358'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_358} />}
					componentDetails={this.props.componentDetails.get('358')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='359'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_359} />}
					componentDetails={this.props.componentDetails.get('359')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponentGroup
					groupId='25'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_25} />}
				/>

				<VehicleComponent
					componentId='360'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_360} />}
					componentDetails={this.props.componentDetails.get('360')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='361'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_361} />}
					componentDetails={this.props.componentDetails.get('361')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='362'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_362} />}
					componentDetails={this.props.componentDetails.get('362')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='363'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_363} />}
					componentDetails={this.props.componentDetails.get('363')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='364'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_364} />}
					componentDetails={this.props.componentDetails.get('364')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponentGroup
					groupId='26'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_group_26} />}
				/>
				<VehicleComponent
					componentId='365'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_365} />}
					componentDetails={this.props.componentDetails.get('365')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='366'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_366} />}
					componentDetails={this.props.componentDetails.get('366')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='367'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_367} />}
					componentDetails={this.props.componentDetails.get('367')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='368'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_368} />}
					componentDetails={this.props.componentDetails.get('368')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='369'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_369} />}
					componentDetails={this.props.componentDetails.get('369')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='370'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_370} />}
					componentDetails={this.props.componentDetails.get('370')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent
					componentId='371'
					componentName={<FormattedMessage {...messages.crane_inspection_form__component_371} />}
					componentDetails={this.props.componentDetails.get('371')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>

				<div>
					<p className='crane_inspection_form__component_subtext_line1'>
						<FormattedMessage {...messages.crane_inspection_form__component_subtext_line1} />
					</p>
					<p className='crane_inspection_form__component_subtext_line2'>
						<FormattedMessage {...messages.crane_inspection_form__component_subtext_line2} />
					</p>
					<p className='crane_inspection_form__component_subtext_line3'>
						<FormattedMessage {...messages.crane_inspection_form__component_subtext_line3} />
					</p>
				</div>

				<CraneResult
					formData={this.props.formData}
					formValidationMessages={this.props.formValidationMessages}
					userLocale={this.props.userLocale}
					poCreationDate={this.props.poCreationDate}
					setFormData={this.props.setFormData}
					onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}
				/>

			</div>
		);
	}
}

export default VehicleComponents;