// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './BrowseButton.css';

const BrowseButton = ({onClick}) => {
    const messages = defineMessages({
            documents_browse_button: {
                id: 'documents_browse_button',
                description: 'Browse button',
                defaultMessage: 'Browse file'
            }
        });

    return <div className='documents__browse'>
        <span onClick={onClick} className='documents__browse_button' role="button">            
            <FormattedMessage {...messages.documents_browse_button}/>                
            {' '}<img src="/assets/VMS_28button_edit_clear.png"/>
        </span>        
    </div>
}

export default BrowseButton;