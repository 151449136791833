// #region Imports
import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";
// #endregion

// #region Styled Components
const MainStyled = styled.div`
	padding-top: 1rem;	
  padding-bottom: 1rem;	
`;

const UsersWrapperStyled = styled.div`
  display: flex;	
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  height: 100px;
  max-height: 100px;
  overflow-y: scroll;
`;

const NoUsersStyled = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;  
`;

const UserStyled = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  white-space: nowrap;  
`;

const RemoveUserStyled = styled.img`
  cursor:pointer;
`;
// #endregion

// #region Component
const SelectedUsers = ({ selectedUsers, allowRemovingUser, onRemoveUser }) => {  
  // #region State
  // #endregion

  // #region Ref
  // #endregion

  // #region Effects
  // #endregion

  // #region Event handlers
  // #endregion

  // #region International messages
  const messages = defineMessages({    
    select_users_none: {
      id: "select_users_none",
      description: "No users selected",
      defaultMessage: "No users selected"
    }
  });
  // #endregion

  // #region Render helpers
  // #endregion  

  // #region Render
  return (
    <MainStyled>               

      <UsersWrapperStyled>
        {!selectedUsers || selectedUsers.length === 0 &&
          <NoUsersStyled><FormattedMessage {...messages.select_users_none} /></NoUsersStyled>
        }

        {selectedUsers.map((user) => {
          return (
            <UserStyled key={user.id}>
              {user.firstName}{' '}{user.lastName}{' '}
              { allowRemovingUser &&
                <RemoveUserStyled                    
                  src="/assets/VMS_28button_kill_clear.png"
                  onClick={()=>onRemoveUser(user.id)}
                />
              }
            </UserStyled>
          );
        })}        
      </UsersWrapperStyled>        
      
    </MainStyled>
  );
  // #endregion
};
// #endregion

export default SelectedUsers;
