import {createSelector} from 'reselect';
import {Map,List} from 'immutable';
import React from 'react';
import {falseIfUndefined} from 'utils/HelperFunctions';

const parametersSelector =
    state => state.appState.getIn(['serverData', 'clientParameters','parameters']);

const getGeneralParameters = createSelector(parametersSelector,(generalParamsFilter) => {

    generalParamsFilter = generalParamsFilter.filter((params) => {
        return params.get('category') === 'client';
    }
        
     );

    return generalParamsFilter!=undefined? generalParamsFilter: Map(); 

});

const getVehicleParameters = createSelector(parametersSelector,(vinParamsFilter) => {


    return vinParamsFilter = vinParamsFilter.filter((params) =>{
        return params.get('category') == 'vehicle';

    });

});


const getGeneralParametersSortedSelector = createSelector (getGeneralParameters, (generalParametersDescription) =>
    generalParametersDescription === undefined ? List() :
        generalParametersDescription.sort( (a,b) =>  {
            return a.get('priority') - b.get('priority');
        }));

const getVinParametersSortedSelector = createSelector (getVehicleParameters, (vinParametersDescription) =>
    vinParametersDescription === undefined ? List() :
        vinParametersDescription.sort( (a,b) =>  {
            return a.get('priority') - b.get('priority');
        }));

export default createSelector(
    getGeneralParametersSortedSelector,
    getVinParametersSortedSelector,
    (generalParametersSorted,
     vinParametersSorted
    ) => {

        return {
            generalParametersSorted,
            vinParametersSorted
        };
    }
);