import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import VehicleInformation from 'components/VehicleInformation';
import './ComplaintVehicleInformation.css';

class ComplaintVehicleInformation extends React.Component {

    render() {
        return <div className="container-fluid complaint_vehicle_info_box">
            <VehicleInformation vehicleDetails={this.props.vehicleDetails}/>
        </div>;
    }
}

export default ComplaintVehicleInformation;
