import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage,injectIntl} from 'react-intl';
import {Map, fromJS} from 'immutable';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError,validateEmail,zeroIfUndefined } from 'utils/HelperFunctions';
import VehicleDetails from '../components/VehicleDetails';
import CreatePOForm from '../components/CreatePOForm';
import { Prompt } from 'react-router-dom';
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import { PO_AUTHORIZATION_STATUS, PO_PAYMENT_STATUS } from 'constants/App';
import * as CarWashViewActions from '../../../actions/CarWashView';
import CarWashViewSelector from "../../../selectors/CarWashView";

class CreatePOFormView extends React.Component {

    constructor(props){
       super(props);
       this.state = {
        isSubmitClicked: false
       };
    }

    componentDidMount() {

        this.props.dispatch(CarWashViewActions.carWashFetchClientNumbers());
        this.props.dispatch(CarWashViewActions.carWashFetchOrderDetails());
        this.props.dispatch(CarWashViewActions.carWashClearPOCreationDetails());

    };

    onCancelClick() {

        this.deleteLineConfirmWindow.showModal();
    }
    onOkClickHandler() {

        this.props.dispatch(CarWashViewActions.carWashClearPOCreationDetails());
        window.location.replace(`/carwash`);
    }

    onInputChangeHandler(key, value, e) {


        let poDetails = Map({
            key: key,
            value: e != undefined && e.target.value != undefined ? e.target.value : value,
            taxType: e != undefined && e.target.attributes['data-taxType'] != undefined ? e.target.attributes['data-taxType'].value : '',

        });

        this.props.dispatch(CarWashViewActions.carWashSavePODetails(poDetails));
        this.props.dispatch(CarWashViewActions.carWashFormIsDirty());

    }


    componentWillUnmount() {

        this.props.dispatch(CarWashViewActions.carWashClearPOCreationDetails());
    }

    carWashRepairChangeHandler(carWashRepairType) {



        if(carWashRepairType){
            let poDetails = Map({
                key: "DETAIL_CAR_WASH_COST",
                value: "0",

            });
            this.props.dispatch(CarWashViewActions.carWashSavePODetails(poDetails));

        }else{
            let poDetails = Map({
                key: "CAR_WASH_COST",
                value: "0",
            });
            this.props.dispatch(CarWashViewActions.carWashSavePODetails(poDetails));
        }
        this.props.dispatch(CarWashViewActions.saveCarWashRepairType(carWashRepairType));
    }


    odometerCheckOnBlurHandler(odometer){
        const messages = defineMessages({
            car_wash_error_1: {
                id: 'car_wash_error_1',
                description: 'car_wash_error_1',
                defaultMessage: 'Odometer is not within our expected mileage range for this vehicle'
            },


        });
        let requestPOInfo;
        if (odometer < this.props.estimatedOdometerRangeMin || odometer > this.props.estimatedOdometerRangeMax) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.ODOMETER,
                value: <FormattedMessage {...messages.car_wash_error_1}/>
            }));

        }
        else {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.ODOMETER,
                value: undefined
            }));

        }
    }

    onCreatePOClickHandler(serviceDate,invoiceNo,carWashCost,detailCarWashCost,taxAmount,odometer,HST,GST,QST,PST){
        const messages = defineMessages({
            car_wash_error_2: {
                id: 'car_wash_error_2',
                description: 'car_wash_error_2',
                defaultMessage: 'Please select a service date.'
            },
            car_wash_error_3: {
                id: 'car_wash_error_3',
                description: 'car_wash_error_3',
                defaultMessage: 'Please enter an Invoice Number longer than 3 characters and remove any empty spaces'
            },
            car_wash_error_4: {
                id: 'car_wash_error_4',
                description: 'car_wash_error_4',
                defaultMessage: 'Please enter Car Wash Cost'
            },
            car_wash_error_5: {
                id: 'car_wash_error_5',
                description: 'car_wash_error_5',
                defaultMessage: 'Item exceeds allowable service costs please contact Holman at 800-227-2273'
            },
            car_wash_error_6: {
                id: 'car_wash_error_6',
                description: 'car_wash_error_6',
                defaultMessage: 'Please enter Detail Car Wash Cost'
            },
            car_wash_error_7: {
                id: 'car_wash_error_7',
                description: 'car_wash_error_7',
                defaultMessage: 'Detail Car Wash Cost exceeds Maximum Pricing'
            },
            car_wash_error_8: {
                id: 'car_wash_error_8',
                description: 'car_wash_error_8',
                defaultMessage: 'Tax exceeds the tax threshold'
            },
            car_wash_error_9: {
                id: 'car_wash_error_9',
                description: 'car_wash_error_9',
                defaultMessage: "Odometer is not within our expected mileage range for this vehicle"
            },
            car_wash_error_10: {
                id: 'car_wash_error_10',
                description: 'car_wash_error_10',
                defaultMessage: 'Tax exceeds the tax threshold'
            },
        });
        this.setState({isSubmitClicked: true});

        let requestPOInfo;

        let serviceDateFlag,invoiceNoFlag,carWashCostFlag,detailCarWashCostFlag,taxAmountFlag,canadianTaxFlag;

        let canadianTaxAmount = Number(HST) + Number(GST) + Number(QST) + Number(PST);

        if (serviceDate === "") {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.SERVICE_DATE,
                value: <FormattedMessage {...messages.car_wash_error_2}/>
            }));
            serviceDateFlag = false;
        } else {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.SERVICE_DATE,
                value: undefined
            }));
            serviceDateFlag = true;
        }
        if (invoiceNo === "" || invoiceNo.length <= 3) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.INVOICE_NO,
                value: <FormattedMessage {...messages.car_wash_error_3}/>
            }));
            invoiceNoFlag = false;
        } else {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.INVOICE_NO,
                value: undefined
            }));
            invoiceNoFlag = true;
        }

        if (carWashCost === "" && this.props.carWashServiceData) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.CAR_WASH_COST,
                value: <FormattedMessage {...messages.car_wash_error_4}/>
            }));
            carWashCostFlag= false;
        } else if (Number(carWashCost) > this.props.regularWashMaxUnitPrice && this.props.regularWashMaxUnitPrice !== null && this.props.carWashServiceData) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.CAR_WASH_COST,
                value: <FormattedMessage {...messages.car_wash_error_5}/>
            }));
            carWashCostFlag = false;

        } else {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.CAR_WASH_COST,
                value: undefined
            }));
            carWashCostFlag = true;
        }

        if (detailCarWashCost === "" && !this.props.carWashServiceData) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.DETAIL_CAR_WASH_COST,
                value: <FormattedMessage {...messages.car_wash_error_6}/>
            }));
            detailCarWashCostFlag = false;
        } else if (Number(detailCarWashCost) > this.props.detailWashMaxUnitPrice && this.props.detailWashMaxUnitPrice !== null && !this.props.carWashServiceData) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.DETAIL_CAR_WASH_COST,
                value: <FormattedMessage {...messages.car_wash_error_7}/>
            }));
            detailCarWashCostFlag = false;
        } else {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.DETAIL_CAR_WASH_COST,
                value: undefined
            }));
            detailCarWashCostFlag = true;
        }

        if (Number(taxAmount) > Number(carWashCost) * .25 && this.props.carWashServiceData || Number(taxAmount) > Number(detailCarWashCost) * .25 && !this.props.carWashServiceData) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.TAX_AMOUNT,
                value: <FormattedMessage {...messages.car_wash_error_8}/>
            }));
            taxAmountFlag = false;
        } else {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.TAX_AMOUNT,
                value: undefined
            }));
            taxAmountFlag = true;
        }


        if (odometer < this.props.estimatedOdometerRangeMin || odometer > this.props.estimatedOdometerRangeMax) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.ODOMETER,
                value:<FormattedMessage {...messages.car_wash_error_9}/>
            }));

        }
        else {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.ODOMETER,
                value: undefined
            }));

        }

        if (canadianTaxAmount > Number(carWashCost) * .25 && this.props.carWashServiceData || canadianTaxAmount > Number(detailCarWashCost) * .25 && !this.props.carWashServiceData) {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.TAX_QST,
                value: <FormattedMessage {...messages.car_wash_error_10}/>
            }));
            canadianTaxFlag = false;
        } else {
            this.props.dispatch(CarWashViewActions.carWashAddRequestInfoPO(requestPOInfo = {
                name: constants.PO_FIELD_ERROR_TYPE.TAX_QST,
                value: undefined
            }));
            canadianTaxFlag = true;
        }


        if(this.props.country === "USA"){

            if(serviceDateFlag && invoiceNoFlag && carWashCostFlag && detailCarWashCostFlag && taxAmountFlag){
                this.props.dispatch(CarWashViewActions.createCarWashPO());
            }else{
                window.scrollTo(300, 300);
            }
        }else {

            if (serviceDateFlag && invoiceNoFlag && carWashCostFlag && detailCarWashCostFlag && canadianTaxFlag) {
                this.props.dispatch(CarWashViewActions.createCarWashPO());
            }else{
                window.scrollTo(300, 300);
            }
        }



    }

    render() {

        const messages = defineMessages({
            help_faq_return: {
                id: 'help_faq_return',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            car_wash_confirmation_title: {
                id: 'car_wash_confirmation_title',
                description: 'Cancel Request',
                defaultMessage: 'Cancel Request'
            },
            car_wash_confirmation_message: {
                id: 'car_wash_confirmation_message',
                description: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?',
                defaultMessage: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?'
            },
            car_wash_confirmation_no: {
                id: 'car_wash_confirmation_no',
                description: 'No',
                defaultMessage: 'No'
            },
            car_wash_confirmation_yes: {
                id: 'car_wash_confirmation_yes',
                description: 'Yes',
                defaultMessage: 'Yes'
            },
            unsaved_data: {
                id: 'unsaved_data',
                description: 'unsaved_data',
                defaultMessage: "You have unsaved changes that will be lost if you decide to continue. Click OK to continue or Cancel to stay on the same page."
            },

        });
        const {formatMessage} = this.props.intl;

        if (this.props.isFetchingData) {
            return <Spinner />;
        }

        return <div>
            <Prompt when={this.props.isFormDirty && !this.state.isSubmitClicked} message={formatMessage(messages.unsaved_data)} />


                    <div>
                        <VehicleDetails
                            vehicleDetails={this.props.vehicleDetails}
                        />

                        <CreatePOForm
                            country={this.props.country}
                            carWashRepairChange={this.carWashRepairChangeHandler.bind(this)}
                            carWashServiceData={this.props.carWashServiceData}
                            onInputChangeHandler={this.onInputChangeHandler.bind(this)}
                            poDetails={this.props.poDetails}
                            userLocale={this.props.userLocale}
                            carWashTotal={this.props.carWashTotal}
                            onCreatePOClick={this.onCreatePOClickHandler.bind(this)}
                            serviceDateError={this.props.serviceDateError}
                            invoiceNumberError={this.props.invoiceNumberError}
                            carWashCostError={this.props.carWashCostError}
                            detailCarWashCostError={this.props.detailCarWashCostError}
                            taxAmountError={this.props.taxAmountError}
                            vehicleDetails={this.props.vehicleDetails}
                            isCreatingPO={this.props.isCreatingPO}
                            odometerError={this.props.odometerError}
                            taxCanada={this.props.taxCanada}
                            canadianTaxError={this.props.canadianTaxError}
                            errorMessage={this.props.createPOErrorMessage}
                            odometerCheck={this.odometerCheckOnBlurHandler.bind(this)}
                            onCancelClick={this.onCancelClick.bind(this)}
                            selectedCurrency={this.props.selectedCurrency}
                            applicableTaxTypes={this.props.applicableTaxTypes}
                        />
                    </div>

            <ConfirmMessageBox
                ref={r => this.deleteLineConfirmWindow = r}
                title={messages.car_wash_confirmation_title}
                message={messages.car_wash_confirmation_message}
                okButtonText={messages.car_wash_confirmation_yes}
                cancelButtonText={messages.car_wash_confirmation_no}
                onOKClickEvent={() => this.onOkClickHandler()}
                onCancelClickEvent={null}
            />
        </div>;
    }
}

function select(state) {
    return {
        isFetchingData: falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','isFetchingData'])),
        isSearchingData: falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','isSearchingData'])),
        isCreatingPO: falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','isCreatingPO'])),
        country: state.appState.getIn(['uiData', 'shared','selectedCountry']),
        usStates: emptyStringIfUndefined(fromJS(state.appState.getIn(['uiData', 'shared', 'usStates']))),
        canadianProvinces: emptyStringIfUndefined(fromJS(state.appState.getIn(['uiData', 'shared', 'canadianProvinces']))),
        clientNumbers: emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','vendorClientNumbers'])),
        activePOs:emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','orderSearchDetails'])),
        showAllPIP: falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','showAllVendorActionNeededPIP'])),
        vehicleDetails:emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','vehicleDetails'])),
        poFormFlag:falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','poFormFlag'])),
        carWashServiceData:falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','carWashServiceTypeSelection'])),
        poDetails:emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','poDetails'])),
        userLocale:state.appState.getIn(['uiData', 'shared', 'selectedLocale']),
        serviceDateError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','serviceDateError']),
        invoiceNumberError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','invoiceNoError']),
        regularWashMaxUnitPrice:state.appState.getIn(['serverData', 'carWashAccount', 'vehicleDetails','parameterDetails','regularWashMaxUnitPrice']),
        detailWashMaxUnitPrice:state.appState.getIn(['serverData', 'carWashAccount', 'vehicleDetails','parameterDetails','detailWashMaxUnitPrice']),
        carWashCostError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','carWashCostError']),
        detailCarWashCostError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','detailCarWashCostError']),
        taxAmountError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','taxAmountError']),
        odometerError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','odometerError']),
        estimatedOdometerRangeMin:state.appState.getIn(['uiData', 'carWashAccount', 'vehicleDetails','estimatedOdometerRangeMin']),
        estimatedOdometerRangeMax:state.appState.getIn(['uiData', 'carWashAccount', 'vehicleDetails','estimatedOdometerRangeMax']),
        taxCanada:emptyMapIfUndefined(state.appState.getIn(['uiData','carWashAccount','poDetails','taxes'])),
        canadianTaxError:state.appState.getIn(['uiData', 'carWashAccount', 'poCreationError','taxQSTError']),
        createPOErrorMessage: state.appState.getIn(['uiData','carWashAccount','creatingPOError']),
        isFormDirty:state.appState.getIn(['uiData','carWashAccount','isDirty']) || false,
        showAllWaiting: falseIfUndefined(state.appState.getIn(['uiData','carWashAccount','showAllVendorActionNeededApproval'])),
        serviceClassification:state.appState.getIn(['serverData','shared','serviceClassification']),
        selectedCurrency:state.appState.getIn(['uiData', 'shared','selectedCurrency']),
        applicableTaxTypes:state.appState.getIn(['uiData', 'carWashAccount','applicableTaxTypes']) || undefined,
        vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
        showAllApprovedPOs:emptyMapIfUndefined(state.appState.getIn(['uiData',  'shopView','showAllApprovedPOs'])),
        ShowEditLinkForApprovedPOs: window.location.href.includes("/carWash"),
        multiVehicleDetails: emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'multiVehicleDetails'])),
        ...CarWashViewSelector(state)
    };
}

export default  connect(select)(injectIntl(CreatePOFormView));

