// #region Imports
import React, { useEffect } from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import {
    ModalMainHeader,
    ModalBodyTable,
    ButtonRowStyled,
    ButtonStyled,
    Row
} from "DesignSpecs/components/FormComponents";
import * as addNewLineItemsViewActions from '../actions/AddNewLineItemsView';
import * as constants from 'constants/App';
import { Map } from 'immutable';
import {connect} from 'react-redux';
import StepNumber from "./StepNumber";
// #endregion

// #region Styled Components
const Main = styled.div`	
  padding-bottom: 1rem;
`;

const DeleteButtonStyled = styled.div`
  cursor: ${({ disable }) => disable ? `not-allowed` : `pointer`};
  padding-top: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 700;
  color: red;
`;

// #endregion

// #region Component
const RepairTypeChangeStep = ({
    dispatch: reduxDispatch,
    lineItemDescription,
    vehicleId,
    onContinue,
    onDeleteLineItem,
    onClose,
    lineItemProductCode,
    stepNumber
}) => {
    // #region State
    // #endregion

    // #region Ref
    // #endregion

    // #region Effects
    useEffect(() => {
        //Load ata restricted repair types
        const searchData = {
                vehicleId: vehicleId,
                searchKey: lineItemDescription,
        }
        reduxDispatch({ type: constants.SEARCHED_ATA_CODES_DATA_LOADED, searchData });
    }, []);

    // #endregion

    // #region Event handlers
    const handleContinueButtonClick = () =>{
        //Update selected ata
        reduxDispatch(addNewLineItemsViewActions.updateATACode(Map({
            type: constants.UPDATE_ATA_CODE_TYPE.SELECTED_ATA_CODE,
            productCode: lineItemProductCode,
            vendorProductCode: undefined
        })));
        //Load ata parameters
        reduxDispatch({ type: constants.ATA_PARAMETERS_SCREEN_LOADED, productCode: lineItemProductCode });
        //Continue to next step
        onContinue();
    }

    const handleDeleteButtonClick = () =>{
        onDeleteLineItem();
        onClose();
    }
    // #endregion

    // #region International messages
    const messages = defineMessages({
        repair_type_change_step_continue: {
            id: "repair_type_change_step_continue",
            description: "Continue",
            defaultMessage: "Continue"
        },
        repair_type_change_step_delete_line: {
            id: "repair_type_change_step_delete_line",
            description: "Delete line",
            defaultMessage: "Delete line"
        },
        repair_type_change_step_header: {
            id: "repair_type_change_step_header",
            description: "Header for repair type change step",
            defaultMessage: "Confirm job details for this line"
        },
        repair_type_change_step_info_1: {
            id: "repair_type_change_step_info_1",
            description: "Line item additional detail",
            defaultMessage: "Note from Holman: The line item for <line_description></line_description> was added by a Holman technician but the estimate needs to be updated.",
            values: {line_description: data => <b>{lineItemDescription}</b>}
        },
        repair_type_change_step_info_2: {
            id: "repair_type_change_step_info_2",
            description: "Line item additional detail",
            defaultMessage: "Click Continue to update the estimate."
        },
        repair_type_change_step_info_3: {
            id: "repair_type_change_step_info_3",
            description: "Line item additional detail",
            defaultMessage: "Click Delete line to delete this item from the Purchase Order"
        }
    });
    // #endregion

    // #region Render helpers

    const renderContinueButton = () => (
        <ButtonStyled
            role="button"
            onClick={handleContinueButtonClick}
        >
            <FormattedMessage {...messages.repair_type_change_step_continue} />
            {" "}
            <img src="/assets/VMS_33button_go_bblue.png" />
        </ButtonStyled>
    );

    const renderDeleteLineButton = () =>(
        <DeleteButtonStyled
        role="button"
        onClick={handleDeleteButtonClick}
      >
        <img src="/assets/VMS_28button_delete_blue.png" />
        {" "}
        <FormattedMessage
          {...messages.repair_type_change_step_delete_line}
        />
      </DeleteButtonStyled>
    )
    // #endregion  

    // #region Render
    return (
        <Main>
            <ModalMainHeader>
                <FormattedMessage {...messages.repair_type_change_step_header} />
            </ModalMainHeader>

            <ModalBodyTable>
                <StepNumber number={stepNumber}/>
                <Row/>
                <Row style={{'display':'inline'}}>
                    <FormattedMessage {...messages.repair_type_change_step_info_1} />
                </Row>
                <Row/>
                <Row/>
                <Row>
                    <FormattedMessage {...messages.repair_type_change_step_info_2} />
                </Row>
                <Row>
                    <FormattedMessage {...messages.repair_type_change_step_info_3} />
                </Row>
            </ModalBodyTable>

            <ButtonRowStyled>
                {renderDeleteLineButton()}
                {renderContinueButton()}
            </ButtonRowStyled>

        </Main>
    );
    // #endregion
};
// #endregion

export default connect()(injectIntl(RepairTypeChangeStep));