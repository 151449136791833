import * as constants from 'constants/App';

export function addRequestInfo(requestInfo) {
    return { type: constants.UPDATE_ENROLLMENT_REQUEST_INFO, requestInfo }
}

export function submitRequestInfo() {
    return { type: constants.SUBMIT_ENROLLMENT_REQUEST_INFO }
}

export function formIsDirty() {
    return { type: constants.ENROLLMENT_REQUEST_FORM_IS_DIRTY, isDirty:true }
}

export function resetForm() {
    return function (dispatch) {
        dispatch({ type: constants.RESET_REQUEST_SUBMITTED_FLAG });
        dispatch({ type: constants.RESET_ENROLLMENT_REQUEST_INFO })
        dispatch({ type: constants.RESET_ENROLLMENT_INFO });
    };
}