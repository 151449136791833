export function ExcludeGamificationData(vendorId, serviceClassfication) {

    if ( vendorId === undefined || serviceClassfication === undefined )
        return true;


    // exclude car wash vendors
    if (serviceClassfication == "CARWASH")
        return true;
    
    const excludeGamificationDataVendors = [
            "565617UA",
            "511531UA",
            "038860UA",
            "542282UA",
            "568781UA",
            "530250UA",
            "594437UA",
            "593230UA", 
            "594926UA",
            "FC076675UA",
            "022701UA",
            "067657UA",
            "022683UA",
            "595755UA",
            "038860UA",
            "580467UA",
            "064001UA"
        ];

        // exclude parts suppliers and Virtual garage vendors 
        return excludeGamificationDataVendors.includes(vendorId) || vendorId.toUpperCase().startsWith("LVVG") || vendorId.toUpperCase().endsWith("MM");
}