// libraries
import React from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
// our stuff
import * as constants from 'constants/App';
import FileList from '../components/FileList';
import Upload from '../components/buttons/Upload';
import HideDocuments from '../components/buttons/HideDocuments';
import ReturnButton from '../components/buttons/ReturnButton';
import * as selectors from '../selectors/VehicleDocuments';
import configuration from 'configuration';

const emptyMap = Map({});

const onReturnClick = () => {    
    window.history.back();
}

const CurrentDocumentsStep = (props) => {                                        	
    return <div>
    
        <FileList files={props.files} 
                fileUrlBase={props.fileUrlBase}
                moreFilesToShow={props.moreFilesToShow}
                urlTemplate={`${configuration.serviceURL}v1/Vendors/${props.vendorId}/documents/{0}?token=${props.token}`}
                showAll={props.showAll} 
                onShowMoreClick={props.onShowMoreClick}
                onShowLessClick={props.onShowLessClick}
        />		

        { props.isReturnVisible && 
            <ReturnButton onClick={onReturnClick}/> 
        }
    </div>        
    
}

const mapStateToProps = (state, props) => {
    return {                
        allowToggleDocumentVisible: state.appState.getIn(['uiData', 'vehicleDocuments', 'allowToggleDocumentVisible']),        
        files: selectors.getFiles(state, props),        
        moreFilesToShow: selectors.getMoreFilesToShow(state, props),
        showAll: state.appState.getIn(['uiData', 'vehicleDocuments', 'showAll']),
        isReturnVisible: state.appState.getIn(['uiData', 'vehicleDocuments', 'isReturnVisible']),                
        vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
		token: state.appState.getIn(['serverData', 'shared', 'token']),		
    };
};

const mapDispatchToProps = (dispatch) => {
    return {        
        onShowMoreClick: () => {
            dispatch({type: constants.SHOW_MORE_VEHICLE_DOCUMENTS})
        },
        onShowLessClick: () => {
            dispatch({type: constants.SHOW_LESS_VEHICLE_DOCUMENTS})
        },
        onUploadClick: () => {
            dispatch({type: constants.SET_VEHICLE_DOCUMENTS_STEP, step: constants.VEHICLE_DOCUMENT_STEPS.BROWSE})
        }                    
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentDocumentsStep);