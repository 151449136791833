import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl, RawIntlProvider } from 'react-intl';
import { Overlay, Popover } from 'react-bootstrap';
import classnames from 'classnames';
import { enUS } from 'date-fns/locale';
import './DotResult.css';
import CheckCircle from 'components/CheckCircle';
import moment from 'moment';
import InputCalendar from 'components/InputCalendar';

const DotResult = (props) => {

	moment.locale(props.userLocale);
	const inspectionPassRef = useRef();
	const inspectionDateRef = useRef();
	const attestRef = useRef();
	const signatureOverlayRef = useRef();
	const signatureRef = useRef();

	const handlePassCheckCircleClick = (name, checked) => {
		if (!checked)
			props.setFormData('hasInspectionPassed', true);
		else
			props.setFormData('hasInspectionPassed', null);
	}

	const handleFailCheckCircleClick = (name, checked) => {
		if (!checked) {
			props.setFormData('hasInspectionPassed', false);
			props.setFormData('inspectorAttested', false);

			if (props.onInspectionFailClick)
				props.onInspectionFailClick();
		}
		else
			props.setFormData('hasInspectionPassed', null);
	}

	const onClickAttest = () => {
		props.setFormData('inspectorAttested',
			!props.formData.get('inspectorAttested'));
	}

	const handleChange = (event) => {
		event.preventDefault();
		props.setFormData(event.currentTarget.id, event.currentTarget.value);
	}

	const onDateChangeHandler = (selectedDay) => {
		const date = selectedDay !== undefined ? new Date(moment(selectedDay)) : undefined;
        const isDisabled = (modifiers.disabled.before == undefined || date < modifiers.disabled.before) && (modifiers.disabled.after == undefined || date > modifiers.disabled.after);

		props.onInspectionDateChangeHandler(selectedDay, isDisabled);
		if (isDisabled || !selectedDay) {
			props.setFormData('inspectionDate', null);
			return;
		}
		else {
			props.setFormData('inspectionDate', selectedDay);
		}
	}
		//#region style
		const modifiers={
			today: {},
			disabled:{
				before: new Date(moment(props.poCreationDate).subtract(90, 'days')),
				after: new Date(moment().subtract(0, 'days')),
			}
		}
		//#endregion

		const messages = defineMessages({
			dot_inspection__pass: {
				id: 'dot_inspection__pass',
				description: 'Inspection pass label',
				defaultMessage: 'PASS'
			},
			dot_inspection__fail: {
				id: 'dot_inspection__fail',
				description: 'Inspection fail label',
				defaultMessage: 'FAIL'
			},
			dot_inspection__has_inspection_passed_required: {
				id: 'dot_inspection__has_inspection_passed_required',
				description: 'Inspection pass/label label required',
				defaultMessage: 'Please select PASS or FAIL'
			},
			dot_inspection__has_inspection_passed_components_invalid: {
				id: 'dot_inspection__has_inspection_passed_components_invalid',
				description: 'Inspection passed but all components are not passed',
				defaultMessage: 'All vehicle components need to be marked as OK or NA or have a repaired date to pass inspection'
			},
			dot_inspection__has_inspection_passed_unsafe_conditions: {
				id: 'dot_inspection__has_inspection_passed_unsafe_conditions',
				description: 'Inspection passed but unsafe conditions was filled out',
				defaultMessage: 'The inspection cannot pass with unsafe conditions listed'
			},
			dot_inspection__has_inspection_passed_no_failure_specified: {
				id: 'dot_inspection__has_inspection_passed_no_failure_specified',
				description: 'Inspection failed but no component is marked as failed and no unsafe conditions specified',
				defaultMessage: 'Please specify failed components'
			},
			dot_inspection__has_inspection_failed_all_components_required: {
				id: 'dot_inspection__has_inspection_failed_all_components_required',
				description: 'Inspection failed but all components must specify OK/NA/Needs repair.',
				defaultMessage: 'All components must specify OK/NA/Needs repair.'
			},
			dot_inspection__has_inspection_failed_repair_date_required: {
				id: 'dot_inspection__has_inspection_failed_repair_date_required',
				description: 'Inspection failed but repair date is empty',
				defaultMessage: 'Repair date cannot be empty'
			},
			dot_inspection__has_inspection_failed_otherFailureReasons_required: {
				id: 'dot_inspection__has_inspection_failed_otherFailureReasons_required',
				description: 'Inspection failed but otherFailureReasons is empty',
				defaultMessage: 'Other failure reasons cannot be empty'
			},
			dot_inspection__inspection_date: {
				id: 'dot_inspection__inspection_date',
				description: 'Inspection date label',
				defaultMessage: 'Inspection date'
			},
			dot_inspection__inspection_date_invalid: {
				id: 'dot_inspection__inspection_date_invalid',
				description: 'Inspection date is invalid/incorrectly formatted',
				defaultMessage: 'Date invalid/incorrectly formatted, please enter a valid date'
			},
			dot_inspection__inspection_date_disabled: {
				id: 'dot_inspection__inspection_date_disabled',
				description: 'Inspection date disabled/out of range',
				defaultMessage: 'Please enter a valid date'
			},
			dot_inspection__inspector_attest: {
				id: 'dot_inspection__inspector_attest',
				description: 'Inspector attested label',
				defaultMessage: 'I attest that I am qualified to perform a DOT Inspection and that this vehicle has passed all the inspection items for the annual vehicle inspection in accordance with 49 CFR PART 396.'
			},
			dot_inspection__attest_required: {
				id: 'dot_inspection__attest_required',
				description: 'Validation: inspector did not attest',
				defaultMessage: 'Please attest to this statement'
			},
			dot_inspection__inspector_signature: {
				id: 'dot_inspection__inspector_signature',
				description: 'Inspector signature label',
				defaultMessage: "Inspector's signature"
			},
			dot_inspection__inspector_signature_note: {
				id: 'dot_inspection__inspector_signature_note',
				description: 'Inspector signature note',
				defaultMessage: "By typing your name, you agree this will serve as the electronic respresentation of your signature."
			},
			dot_inspection__signature_required: {
				id: 'dot_inspection__signature_required',
				description: 'Validation: inspector signature required',
				defaultMessage: 'Please enter your signature'
			},
		});

		return (
			<div>

				<div className="row no-gutters dot_result__result_code" >

					<div className="col-sm-2 no-gutters-col">
						<div className='dot_result__field_container' tabIndex='0'>
							<CheckCircle
								name='hasInspectionPassedTrue'
								checked={props.formData.get('hasInspectionPassed') === true}
								message={<FormattedMessage {...messages.dot_inspection__pass} />}
								onClick={handlePassCheckCircleClick} />
						</div>
					</div>

					<div className="col-sm-2 no-gutters-col">
						<div className='dot_result__field_container' tabIndex='0' ref={inspectionPassRef}>
							<CheckCircle
								name='hasInspectionPassedFalse'
								checked={props.formData.get('hasInspectionPassed') === false}
								onClick={handleFailCheckCircleClick}
								message={
									<FormattedMessage {...messages.dot_inspection__fail} />
								}
							/>

							{!!props.formValidationMessages.get('hasInspectionPassed') &&
								<Overlay
									show="true"
									target={inspectionPassRef.current}
									placement="right">
									<Popover
										id='hasInspectionPassedOverlay'
										className='dot_result__error_message'
									>
										<RawIntlProvider value={props.intl}>
											<FormattedMessage {...(messages[props.formValidationMessages.get('hasInspectionPassed')])} />
										</RawIntlProvider>
									</Popover>
								</Overlay>
							}
						</div>
					</div>

				</div>

				<div className='dot_result__inspection_date'>
					<div className='dot_result__field_container' tabIndex='0'>
						<div>
							<FormattedMessage {...messages.dot_inspection__inspection_date} />
						</div>
						<div className='dot_result__date_input' ref={inspectionDateRef}>
							<InputCalendar
								modifiers={modifiers}
								initialValue={props.formData.get('inspectionDate')}
								onDateChange={onDateChangeHandler}
								locale={props.userLocale}
							/>
							{!!props.formValidationMessages.get('inspectionDate') &&
								<Overlay
									show="true"
									target={inspectionDateRef.current}
									placement="auto">
									<Popover
										id='inspectionDateOverlay'
										className='dot_result__error_message'
									>
										<RawIntlProvider value={props.intl}>
											<FormattedMessage {...(messages[props.formValidationMessages.get('inspectionDate')])} />
										</RawIntlProvider>
									</Popover>
								</Overlay>
							}
						</div>
					</div>
				</div>

				{props.formData.get('hasInspectionPassed') !== false &&
					<div className='dot_result__attest'>
						<div className='dot_result__field_container' tabIndex='0' ref={attestRef}>
							<CheckCircle checked={props.formData.get('inspectorAttested')}
								name='inspectorAttested'
								message={<FormattedMessage {...messages.dot_inspection__inspector_attest} />}
								disabled={props.formData.get('hasInspectionPassed') === false}
								onClick={onClickAttest}
							/>
							{!!props.formValidationMessages.get('inspectorAttested') &&
								<Overlay
									show="true"
									target={attestRef.current}
									placement="bottom">
									<Popover
										id='inspectorAttestedOverlay'
										className='dot_result__error_message'
									>
										<RawIntlProvider value={props.intl}>
											<FormattedMessage {...(messages[props.formValidationMessages.get('inspectorAttested')])} />
										</RawIntlProvider>
									</Popover>
								</Overlay>
							}
						</div>
					</div>
				}

				<div className='dot_result__inspector_signature' ref={signatureRef}>
					<div className='dot_result__field_container'>
						<FormattedMessage {...messages.dot_inspection__inspector_signature} />
						{' '}
						<input
							id='inspectorSignature'
							className='txtName'
							maxLength='50'
							value={props.formData.get('inspectorSignature')}
							onChange={handleChange}
							ref={signatureOverlayRef}
						/>
						<div className='dot_result__inspector_signature_note'>
							<FormattedMessage {...messages.dot_inspection__inspector_signature_note} />
						</div>
						{!!props.formValidationMessages.get('inspectorSignature') &&
							<Overlay
								show="true"
								target={signatureOverlayRef.current}
								placement="bottom"><Popover
									id='inspectorSignatureOverlay'
									className='dot_result__error_message'
								>
									<RawIntlProvider value={props.intl}>
										<FormattedMessage {...(messages[props.formValidationMessages.get('inspectorSignature')])} />
									</RawIntlProvider>
								</Popover>
							</Overlay>
						}
					</div>
				</div>

			</div>
		)
};

export default injectIntl(DotResult);