// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './Layout.css';

const Layout = ({children}) => {    
    return <div className='adjustment__layout'>
        {children}
    </div>
}

export default Layout;