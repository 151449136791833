import { call, put, select,all } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
const diagATACode = '53001010';
const diagATADescription = 'DIAGNOSIS';
const diagComplaintCode = 'DG99';
import * as helperFunctions from 'utils/HelperFunctions';
import * as AddEditView from 'sagas/AddEditView';

export function* fetchDataForComplaintScreen(action) {
  try {
    const state = yield select();

    //clear out any error messages
    yield put({ type: constants.CLEAR_ERROR_MESSAGE});
    // Inform application that data request has started
    yield put({ type: constants.FETCH_DATA_FOR_COMPLAINT_SCREEN_REQUESTED });

    const [maintenanceServices, regulatoryServices] = yield all ([
      call(Api.fetchMaintenanceServices, action.vehicleId, helperFunctions.getToken(state)),
      call(Api.fetchRegulatoryServices, action.vehicleId, helperFunctions.getToken(state))
    ]);

    const ataCodes = helperFunctions.getServiceAtaCodesToSave(maintenanceServices,regulatoryServices);
    
    yield put({ type: constants.FETCH_DATA_FOR_COMPLAINT_SCREEN_RESOLVED, maintenanceServices, regulatoryServices, ataCodes });

  }
  catch (e) {
    yield put({ type: constants.FETCH_DATA_FOR_COMPLAINT_SCREEN_REJECTED, errorMessage:  helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_DATA_FOR_COMPLAINT_SCREEN_REQUESTED, errorObject: e });
  }
}

export function* createOrEditComplaint(action) {
  try {
    // Inform application that data request has started
    yield put({ type: constants.CREATE_OR_EDIT_COMPLAINT_REQUESTED, complaintKey: action.complaintKey });

    // Get po number from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
    const skippedComplaintCode = 'UN01';

    // Inspect state to check if this is a create or edit
    const oldComplaintSkipped = state.appState.getIn(['uiData', 'addEdit', 'complaints', action.complaintKey, 'wasComplaintSkipped']);
    const oldComplaintCode = oldComplaintSkipped ? skippedComplaintCode : action.oldComplaintCode;

    if (oldComplaintCode === undefined)
      // create complaint
      yield call(Api.createComplaint, poNumber, action.code, action.description, helperFunctions.getToken(state));
    else {
      // edit complaint
      yield call(Api.editComplaint, poNumber, oldComplaintCode, action.code, action.description, helperFunctions.getToken(state));
    }

    //if notes exist, update complaintCode in notes
    if (emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'notes'])).has(action.complaintKey)) 
    {
      let noteId = state.appState.getIn(['uiData', 'addEdit', 'notes', action.complaintKey, 'id']);      
      if (noteId != undefined) 
      {
        let newComplaintCode = action.code;
        yield Api.editComplaintNotes(poNumber, oldComplaintCode || 'UN01', newComplaintCode, undefined, noteId, helperFunctions.getToken(state));
      }      
    }

    // Inform UI so that it can update the app state 
    yield put({ type: constants.CREATE_OR_EDIT_COMPLAINT_RESOLVED, code: action.code, complaintKey: action.complaintKey, description: action.description });

    // Toggle the complaint component to hide the complaints
    yield put({ type: constants.TOGGLE_COMPLAINT_SELECT_MODE, complaintKey: action.complaintKey });

  }
  catch (e) {
    yield put({ type: constants.CREATE_OR_EDIT_COMPLAINT_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CREATE_OR_EDIT_COMPLAINT_REQUESTED, errorObject: e });
  }
}

export function* skipComplaint(action) {
  try {
    // Inform application that data request has started
    yield put({ type: constants.SKIP_COMPLAINT_REQUESTED, complaintKey: action.complaintKey });

    // Get po number from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
    const skippedComplaintCode = 'UN01';
    const skippedComplaintDescription = 'NOT SUPPLIED';

    // create complaint
    yield call(Api.createComplaint, poNumber, skippedComplaintCode, skippedComplaintDescription, helperFunctions.getToken(state));

    // Inform UI so that it can update the app state 
    yield put({ type: constants.SKIP_COMPLAINT_RESOLVED, complaintKey: action.complaintKey });

    // Toggle the complaint component to hide the complaints
    yield put({ type: constants.TOGGLE_COMPLAINT_SELECT_MODE, complaintKey: action.complaintKey });

  }
  catch (e) {
    yield put({ type: constants.SKIP_COMPLAINT_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SKIP_COMPLAINT_REQUESTED, errorObject: e });
  }
}

export function* goToNextStepClickedOnComplaintScreen(context, action) {

  try {
    yield put({ type: constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REQUESTED });

    // Read data from app state
    let state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
    const diagnosisDetails = state.appState.getIn(['uiData', 'complaintView', 'diagnosisItem']);
    let complaints = emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints']));
    const notes = emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'notes']));
    const countOfDueServicesSelected = emptyMapIfUndefined(state.appState.getIn(['uiData', 'complaintView', 'servicesSelected'])).size;
    let existingDiagLine;
    if (state.appState.getIn(['uiData', 'addEdit', 'lineItems']) != undefined) {
      existingDiagLine = state.appState.getIn(['uiData', 'addEdit', 'lineItems'])
        .filter(s => s.get('productCode') == diagATACode)
        .first();
    }

      //delete any complaints that are open and have no notes
    var complaintsToDelete = complaints.filter((comp, compKey) => comp.get('isComplaintOpen') && (notes.get(compKey) == undefined))
    .map((comp, compKey) => put({ type: constants.ADD_EDIT_VIEW_DELETE_COMPLAINT, complaintKey: compKey})).toArray();
     
     yield all (complaintsToDelete) ;
     
     
    state = yield select();//refresh state
    complaints = emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints'])); //get latest complaints (some may have been removed)
    
    // Verify all complaints are either selected or skipped
    if (complaints.count(v => v.get('isComplaintOpen')) > 0) {
      yield put({ type: constants.COMPLAINT_VIEW_SHOW_ERRORS });      
      window.scrollTo(0, 0);
      return;
    }

    // If diagnosis is selected 
    if (diagnosisDetails.get('diagnosisRadioSelected')) {

      // show error if labor hours or rate is missing
      if (diagnosisDetails.get("hours") == undefined || diagnosisDetails.get("hours") <= 0 || diagnosisDetails.get("rate") == undefined || diagnosisDetails.get("rate") <= 0) {
        yield put({ type: constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REJECTED, errorMessage: [{message:'Please provide both hours and rate for diagnosis'}]  });
        window.scrollTo(0, 0);
        return;
      }

      // If Diag line does not already exist on the PO
      if (existingDiagLine === undefined) {

        // create new diagnosis line item
        let hours = Number(diagnosisDetails.get("hours"));
        let rate = Number(diagnosisDetails.get("rate"));

        yield* AddEditView.addNewLineItem(hours, rate, diagATACode, diagATADescription, diagComplaintCode, constants.CORRECTION_TYPE.REPAIR, constants.LINE_ITEM_TYPE.LABOR, undefined, undefined, undefined, poNumber, helperFunctions.getToken(state));

      }
      else {

        // Call api to Edit diagnosis line item
        let newHours = Number(diagnosisDetails.get("hours"));
        let newRate = Number(diagnosisDetails.get("rate"));
        let existingHours = Number(existingDiagLine.get('quanity'));
        let existingRate = Number(existingDiagLine.get('unitPrice'));
        let existingLineItemId = existingDiagLine.get('lineItemId');
        if (newHours !== existingHours || newRate !== existingRate)
          yield call(Api.editLineItem, poNumber, existingLineItemId, newHours, newRate, helperFunctions.getToken(state),helpFunctions.getSelectedLanguageCode(state));

        // update line item in app state
        yield put({ type: constants.UPDATE_LINE_ITEM, lineItemId: existingLineItemId, quantity: newHours, unitPrice: newRate });
      }
    }
    else {

      // if diag line exists on PO, delete it
      if (existingDiagLine !== undefined) {
        let existingLineItemId = existingDiagLine.get('lineItemId');
        yield call(Api.deleteLineItem, poNumber, existingLineItemId, helperFunctions.getToken(state));

        // delete line item from app state
        yield put({ type: constants.DELETE_LINE_ITEM, lineItemId: existingLineItemId });
      }
    }

    // Set the flag to show services window when PO details screen loads
    if (countOfDueServicesSelected > 0) {
      yield put({ type: constants.SHOW_MODAL_ADD_SERVICE_LINE_ITEMS });
    }

    // Navigate to PO details page
      yield call(context.history.push,`/PO/add/0`);

    yield put({ type: constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_RESOLVED });

  }
  catch (e) {
    yield put({ type: constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REJECTED, errorMessage:  helperFunctions.getCustomErrorObject(e)});
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REQUESTED, errorObject: e });
  }
}