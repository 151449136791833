import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { zeroIfUndefined } from 'utils/HelperFunctions';
import './TreadDepthUnit.css';
import { defineMessages, FormattedMessage } from 'react-intl';

export const TreadDepthUnit = (props) => {
    const messages = defineMessages({
        inches_caption: {
            id: 'inches_caption',
            description: 'Inches',
            defaultMessage: 'Inches'
        },
        mm_caption: {
            id: 'mm_caption',
            description: 'MM',
            defaultMessage: 'MM'
        },
        unit_confirmation_box_title: {
            id: 'unit_confirmation_box_title',
            description: 'Confirm measurement unit change',
            defaultMessage: 'Confirm measurement unit change',
        },
        unit_confirmation_message_for_tires: {
            id: 'unit_confirmation_message_for_tires',
            description: 'Measurement unit change will be applied to all the tires. Press Ok if you want to change it to ',
            defaultMessage: 'Measurement unit change will be applied to all the tires. Press Ok if you want to change it to ',
        },
        unit_confirmation_message_for_brakes: {
            id: 'unit_confirmation_message_for_brakes',
            description: 'Measurement unit change will be applied to all the brake components. Press Ok if you want to change it to ',
            defaultMessage: 'Measurement unit change will be applied to all the brake components. Press Ok if you want to change it to ',
        },
         unit_confirmation_message_end_part: {
            id: 'unit_confirmation_message_end_part',
            description: '?',
            defaultMessage: '?',
        },
        unit_confirmation_yes: {
            id: 'unit_confirmation_yes',
            description: 'Yes, change the measurement unit.',
            defaultMessage: 'Yes, change the measurement unit.',
        },
        unit_confirmation_no: {
            id: 'unit_confirmation_no',
            description: 'No',
            defaultMessage: 'No',
        }

    })


    const changeUnit = (value) => {

        const formattedUnitValue = props.defaultMeasurementUnit == 'milli_meter'?messages.inches_caption.defaultMessage
            :  props.defaultMeasurementUnit == 'inch' ? messages.mm_caption.defaultMessage
            : ''

        const message = props.isProductType == 'tires'? 
            messages.unit_confirmation_message_for_tires.defaultMessage + formattedUnitValue + messages.unit_confirmation_message_end_part.defaultMessage
            : props.isProductType == 'brakes'? messages.unit_confirmation_message_for_brakes.defaultMessage + formattedUnitValue + messages.unit_confirmation_message_end_part.defaultMessage
            :''
        if (props.canShowUnitChangeConfirmation && value != props.defaultMeasurementUnit) {
            const isUserResponseYes = confirm(message);
            if (isUserResponseYes)
                props.onChangeMeasurementUnit(value)
        }
        else 
        {
                props.onChangeMeasurementUnit(value)            
        }
    }
    return (
        <div className={'flex-container'} >
            <div className={'flex-col-1'} >{props.caption}</div>
            <div className={'flex-col-1'}
                onClick={() => changeUnit('inch')} >
                <img role={'button'}
                    src={props.defaultMeasurementUnit === 'inch' ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png"}
                />
                {' '}<FormattedMessage {...messages.inches_caption} />

            </div>
            <div className={'flex-col-1'}
                onClick={() => changeUnit('milli_meter')} >
                <img role={'button'}
                    src={props.defaultMeasurementUnit === 'milli_meter' ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png"}
                    onClick={() => { }} />
                {' '}<FormattedMessage {...messages.mm_caption} />
            </div>
        </div >
    )
}
