// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './VehicleComponentHeader.css';

class VehicleComponentHeader extends React.Component {	
	render() {		
		const messages = defineMessages({
			bit_inspection_form__item: {
				id: 'bit_inspection_form__item',
				description: 'BIT inspection form item header.',
				defaultMessage: 'Item'
			},
			bit_inspection_form__ok: {
				id: 'bit_inspection_form__ok',
				description: 'BIT inspection form OK header.',
				defaultMessage: 'OK'
			},
			bit_inspection_form__na: {
				id: 'bit_inspection_form__na',
				description: 'BIT inspection form NA header.',
				defaultMessage: 'N/A'
			},
			bit_inspection_form__defective: {
				id: 'bit_inspection_form__defective',
				description: 'BIT inspection form defective header.',
				defaultMessage: 'Def'
			},
			bit_inspection_form__comments: {
				id: 'bit_inspection_form__comments',
				description: 'BIT inspection form comments header.',
				defaultMessage: 'Comments'
			},
		});
	
		return (
			<div className='bit_inspection_vehicle_component_header__row'>
				<div className='bit_inspection_vehicle_component_header__item_header'>					
					<FormattedMessage {...messages.bit_inspection_form__item} />
				</div>
				<div className='bit_inspection_vehicle_component_header__ok_header' >					
					<FormattedMessage {...messages.bit_inspection_form__ok} />
				</div>
				<div className='bit_inspection_vehicle_component_header__na_header' >					
					<FormattedMessage {...messages.bit_inspection_form__na} />
				</div>
				<div className='bit_inspection_vehicle_component_header__defective_header' >					
					<FormattedMessage {...messages.bit_inspection_form__defective} />
				</div>
				<div className='bit_inspection_vehicle_component_header__comments_header'>					
					<FormattedMessage {...messages.bit_inspection_form__comments} />
				</div>
			</div>
		)
    }
}

export default VehicleComponentHeader;
