// libraries
import React from 'react';
import moment from 'moment';
// our stuff
import './VehicleComponent.css';
import CheckCircle from 'components/CheckCircle';
import * as fraInspectionEnums from '../data/FraInspectionEnums';

class VehicleComponent extends React.Component {	
	handleComponentResultCodeClick = (name, checked) => {
		if (!checked)
			this.props.onComponentDetailsChanged(this.props.componentId, 'componentResultCode', name);
		else
			this.props.onComponentDetailsChanged(this.props.componentId, 'componentResultCode', null);
	}
	
	render() {
		const { showPass = true, showFail = true } = this.props;

		if (!this.props.componentDetails && (showPass || showFail))
			return null;

		return (
			<div className='fra_inspection_vehicle_component__row'>
				<div className='fra_inspection_vehicle_component__item_cell'>
					{this.props.renderName}
				</div>

				<div className='fra_inspection_vehicle_component__cell' tabIndex='0'>					
					{ showPass &&
						<CheckCircle
							name={fraInspectionEnums.componentResultCode.PASS}
							className='fra_inspection_vehicle_component__check_circle'
							checked={this.props.componentDetails.get('componentResultCode') === fraInspectionEnums.componentResultCode.PASS}
							onClick={this.handleComponentResultCodeClick}
						/>
					}
				</div>
				<div className='fra_inspection_vehicle_component__cell' tabIndex='0'>
					{ showFail &&
						<CheckCircle
							name={fraInspectionEnums.componentResultCode.FAIL}
							className='fra_inspection_vehicle_component__check_circle'
							checked={this.props.componentDetails.get('componentResultCode') === fraInspectionEnums.componentResultCode.FAIL}
							onClick={this.handleComponentResultCodeClick}
						/>
					}
				</div>				
				
			</div>
		)
    }
}

export default VehicleComponent;