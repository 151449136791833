import { createSelector } from 'reselect';
import { emptyMapIfUndefined, falseIfUndefined,emptyStringIfUndefined } from 'utils/HelperFunctions';
import { Map } from 'immutable';
import * as constants from 'constants/App';


const isfetchingDataSelector = state => falseIfUndefined(state.appState.getIn(['uiData', 'odometerView', 'isFetchingData']));
const isCreatingPOSelector = state => falseIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'isCreatingPO']));
const odometerDataSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'odometerView']));
const vehicleDetailsSelector = (state, props) => {
    //return emptyMapIfUndefined(state.appState.getIn(['uiData', 'odometerView', 'vehicleDetails']));
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'vehicleDetails']));
};

const clientDetailsSelector = (state, props) => {
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'client']));
};

const vehicleRecentServiceSelector = createSelector(
    state => state.appState.getIn(['serverData', 'repairHistory', 'services']),
    state => state.appState.getIn(['serverData', 'shared', 'vendorId']),
    state => state.appState.getIn(['serverData', 'shared', 'vendorName']),
    (recentServices, vendorId, vendorName) => {
        let vehrecentServices = Map();

        //transform recent services from the app state 
        if (recentServices != undefined) {
            vehrecentServices = recentServices.reduce((p,c)=>{         
                return p.set(c.get('id'), Map({                    
                    id:c.get('id'),
                    vendorName:(c.get('vendorId')==vendorId)?vendorName:constants.ARI_VENDOR,
                    createdDate:c.get('createdDate'),
                    vehicleOdometer:c.get('vehicleOdometer'),
                    complaintsDescription: c.get('complaints') != undefined ? c.get('complaints').map(x=>x.get('description')).join(', '): '' //concatenate complaints description
                }));
            }, Map());
        }

        //force sort by create date
        vehrecentServices = vehrecentServices.sortBy(po => new Date(po.get('createdDate'))).reverse();

        return vehrecentServices;
    });
const vin8Selector = createSelector(
    state => emptyStringIfUndefined(state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'vin'])),
    (vin) => {
        return vin.substr(vin.length - 8, vin.length);
    });

const driverValidationFlagSelector = createSelector(
    state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared','vehicleDetails','isDriverValidationRequired'])),
    (flag) => {
        return  flag == 'Y' ? true : false;
    });



const OdometerView = createSelector(
    isfetchingDataSelector,
    isCreatingPOSelector,
    odometerDataSelector,
    vehicleDetailsSelector,
    clientDetailsSelector,
    vehicleRecentServiceSelector,
    vin8Selector,
    driverValidationFlagSelector,
    (isfetchingData,
     isCreatingPO,   
     odometerData,
     vehicleDetails,
     client,
     vehicleRecentService,
     vin8,
     driverValidationFlag
    ) => {
        return {
            isfetchingData,
            isCreatingPO,
            odometerData,
            vehicleDetails,
            client,
            vehicleRecentService,
            vin8,
            driverValidationFlag
        }
    }
);

export default OdometerView;