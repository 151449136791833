import axios from 'axios';
import configuration from 'configuration';
import { schema, normalize } from 'normalizr';
import moment from 'moment';
import { emptyMapIfUndefined, NoIfNull, falseIfNull,emptyStringIfNull,validateEmail } from 'utils/HelperFunctions';
import * as constants from 'constants/App';
import { getErrorObject } from './HelperFunctions';
import { jwtDecode } from 'jwt-decode';

// Schemas
const activePOSchema = new schema.Entity('activePOs',{} , { idAttribute: 'id' });
const complaintSchema = new schema.Entity('complaints',{} , { idAttribute: 'code' });
const availableVehicleComplaintsSchema = new schema.Entity('availableVehicleComplaints',{} , { idAttribute: 'vehicleComplaintId' });
const notesSchema = new schema.Entity('notes',{} , { idAttribute:(note) => note.category+note.id });
const lineItemSchema = new schema.Entity('lineItems',{} , { idAttribute: 'lineItemId' });
const dueServicesSchema = new schema.Entity('dueServicesSchema',{} , { idAttribute: 'id' });
const contactEmailSchema = new schema.Entity('contactEmailSchema',{} , { idAttribute: 'id' });
const vendorServiceSchema = new schema.Entity('vendorServiceSchema',{} , { idAttribute: 'id' });
const myUnitsSchema = new schema.Entity('myUnits',{} , { idAttribute: 'id' });
const documentsSchema = new schema.Entity('documentsSchema',{} , { idAttribute: 'id' });
const linkedVendorSchema = new schema.Entity('linkedVendorSchema',{} , { idAttribute: 'id' });
const carWashClientsSchema = new schema.Entity('carWashClientsSchema',{} , { idAttribute: 'Id' });
const carWashVehicleSchema = new schema.Entity('carWashVehicleSchema',{} , { idAttribute: 'id' });
const dealerDraftLineItemSchema = new schema.Entity('dealerDraftLineItemSchema',{} , { idAttribute: 'productId' });
const dealerDraftProductDetails = new schema.Entity('dealerDraftProductDetails',{} , { idAttribute: 'id' });
const fieldValidationsSchema = new schema.Entity('workCompletedPermissions',{} , { idAttribute: 'featureName' });
const vendorFeaturesSchema = new schema.Entity('vendorFeatures',{} , { idAttribute: 'FeatureCode' });

function getHeaders(token,languageCode = "en-US") {

    return {
        headers: {
            'Authorization': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language':languageCode,
            'Client-Version': configuration.version
        }
    }
}





    function getLocale(state){            
        return state.appState.getIn(['uiData','shared','selectedLocale']) == null?'en-US':state.appState.getIn(['uiData','shared','selectedLocale']);
    }

    function getHeadersBankAccount(token, x_token) {
        return {
            headers: {
                'Authorization': token,
                'x-verification-token': x_token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
    }

    function getHeadersforPatchWithVerificationToken(token,x_token,languageCode){
        return {
            headers: {
                'Authorization': token,
                'x-verification-token': x_token,
                'Content-Type': 'application/json-patch+json; charset=UTF-8',
                'Accept-Language':languageCode
            }
        }
    }

    function getHeadersForPatch(token,languageCode = "en-US") {

        return {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json-patch+json; charset=UTF-8',
                'Accept-Language':languageCode
            }
        }
    }

        function getHeadersForMultipartPost(token,languageCode = "en-US") {
            return {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'multipart/form-data',
                    'Accept-Language':languageCode
                    //'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        }

        function getHeadersForPMInformation(token) {
            return {
                headers: {
                    'Authorization': token,
                    'Accept': 'application/octet-stream',
                    'Content-Type': 'application/json-patch+json; charset=UTF-8'
                }
            }
        }

    function getFriendlyErrorObject(e) {                        
        if (e === undefined) {
            return e;
        }
        else if (typeof(e) === 'object' && e.message === 'Network Error') {
            return {code: 'API-156', message: 'Cannot connect to Holman Partner Connect service.' }
        }
        else if (typeof(e) === 'object' && typeof(e.response) === 'object' && e.response.status === 401) {
            return {code: 'API-998', message: 'Not Authorized.' }        
        }
        else {
            return e.response.data.error;
        }
    }
    export async function fetchActivePOsForVendor(vendorId, token) {
        try {
            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/search?eventtype=REPAIR,PM,ROADSIDE,GLASS,TIRES,INSPECTION,PARTS,DECALS,SOLICITOR,OTHER,CARWASH,RENTAL,INSURANCE&paymentStatus=PAYMENT_NOT_REQUESTED,PAYMENT_IN_PROGRESS&dateRestrict=d180&vendorId=${vendorId}&fields=id,poNumber,createdDate,authorizedDate,authorizedAmount,pendingAmount,declinedAmount,totalAmount,taxAmount,authorizationStatus,paymentStatus,vehicleYear,vehicleMake,vehicleModel,vehicleVIN8,vendorReferenceNo,hasAssociatedClaim`, getHeaders(token));

            // Normalize it
            const normalizedJson = normalize(response.data, [activePOSchema]);

            // Return POs
            return normalizedJson.entities[activePOSchema.key];
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchOpenedPOsForVendor(vendorId, token) {
        try {
      
            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/search?authorizationStatus=APPROVED&eventtype=REPAIR,PM,ROADSIDE,GLASS,TIRES,INSPECTION,PARTS,DECALS,SOLICITOR,OTHER,RENTAL&paymentStatus=PAYMENT_NOT_REQUESTED&dateRestrict=d180:d30&vendorId=${vendorId}&fields=id,poNumber,createdDate,totalAmount,vehicleVIN8,vendorReferenceNo`, getHeaders(token));

            // Normalize it
            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function authenticateVendor(vendorId, password,state) {        
        
        try {
            // Prepare request data
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-key': `${validateEmail(vendorId)?configuration.PersonaAppKey: vendorId.endsWith('MM')?configuration.MexicoAppKey: configuration.AppKey}`,
                    'Accept-Language' : getLocale(state)
                }
            };

            const body = {
                'id': vendorId,
                'password': password
            };

            // Get response
            const response = await axios.post(`${configuration.serviceURL}v1/app/apc/Token`, body, config);

            // Return token
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }    

    export async function refreshToken(token) {
        try {                                
            let config = getHeaders(token);
            config.headers['x-app-key'] = configuration.AppKey;
            const response = await axios.post(`${configuration.serviceURL}v1/Token?token=${token}`, undefined, config);      

            return response.data;
        }
        catch (e) {
            throw getFriendlyErrorObject(e); 
        }
    }

    export async function refreshPersonaToken(token, state) {        
        try {            
            let config = getHeaders(token, getLocale(state));
            config.headers['x-app-key'] = configuration.PersonaAppKey;                                   
            const response = await axios.post(`${configuration.serviceURL}v1/app/apc/Token?token=${token}`, undefined, config);
            
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function authenticatePersonaUser(userId, password,state) {        
        try {
            // Prepare request data
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-key': configuration.PersonaAppKey,
                    'Accept-Language' : getLocale(state)
                }
            };

            const body = {
                'id': userId,
                'password': password
            };

            // Get response
            const response = await axios.post(`${configuration.serviceURL}v1/app/apc/Token`, body, config);

            // Return token
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    

    export async function fetchVendorDetails(vendorId, token) {
        try {

            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}?fields=serviceClassification,businessAddress,paymentType`, getHeaders(token));

            // return vendor details
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchComplaints(poNumber, token, state) {
        try {
            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/${poNumber}/complaints?fields=code,description`, getHeaders(token));

            // return complaints
            return normalize(convertComplaintsForUI(response.data, state), [complaintSchema]).entities.complaints;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVehicleByVin8(vin8, token,languageCode="en-US") {
        try {
            // Get vehicle data
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/Search?vin8=${encodeURIComponent(vin8)}&fields=id,color,engineType,licensePlate,year,make,model,lastOdometerReading,vin,estimatedOdometer,estimatedHourMeter,estimatedOdometerRangeMin,estimatedOdometerRangeMax,lastHourMeterReading,maintenanceServices,regulatoryServices,clientName,clientId,isOffRoadVehicle,ariUnitNo,countryCode,existingOrderWaitingOrApprovedAuthorization,isDriverValidationRequired,existingOrderPreviouslyVoided,isHsbClientVehicle`, getHeaders(token,languageCode));

            // Return data
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVehicleByVehicleId(vehicleId, token,languageCode="en-US") {
        try {
            // Get vehicle data
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles?vehicleId=${vehicleId}&fields=id,color,engineType,licensePlate,year,make,model,lastOdometerReading,vin,estimatedOdometer,estimatedHourMeter,estimatedOdometerRangeMin,estimatedOdometerRangeMax,lastHourMeterReading,maintenanceServices,regulatoryServices,clientName,clientId,isOffRoadVehicle,ariUnitNo,countryCode,existingOrderWaitingOrApprovedAuthorization,isDriverValidationRequired,isHsbClientVehicle`, getHeaders(token,languageCode));

            // Return data
            return response.data[0];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export function convertComplaintsForUI(complaintsJSON, state) {
        //take complaints in JSON format that came from server
        //and add properties to each complaint that are needed for appState/uiState props
        let newComplaints = complaintsJSON;
        let index = 1;
        for (var complaint in newComplaints) { //iterate through all properties in complaints

            let desc = state.appState.getIn(['serverData', 'shared', 'complaintTypes',
                newComplaints[complaint]['code'], 'description']);

            if (newComplaints.hasOwnProperty(complaint)) { //make sure property is a complaint JSON and not a property from prototype: http://stackoverflow.com/questions/684672/how-do-i-loop-through-or-enumerate-a-javascript-object
                newComplaints[complaint]['index'] = index;
                newComplaints[complaint]['title'] = 'Complaint ' + index;
                newComplaints[complaint]['description'] = desc != undefined ? desc : 'Other';
                newComplaints[complaint]['isSelectMode'] = false;
                newComplaints[complaint]['wasComplaintSkipped'] = false;
                newComplaints[complaint]['isComplaintOpen'] = false;
                index++;
            }
        }

        return newComplaints;
    }



    export async function fetchPurchaseOrder(poNumber, token,languageCode="en-US") {
        try {
            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/${poNumber}?fields=id,poNumber,vehicleId,vendorId,createdDate,estimatedCompletionDate,authorizationStatus,paymentStatus,pendingAmount,authorizedAmount,taxAmount,authorizationStatusReason,isClientAuthorizationRequired,paymentDate,intellipayFeePercent,nationalAccountFeePercent,isApcPo,isSupplierSitePo,isInsurancePo,vendorReferenceNo,repairStartDate,clientReferenceNo,entityForAssistance`, getHeaders(token,languageCode));

            const purchaseOrderData = response.data;

            // Set dates in correct format
            purchaseOrderData.estimatedCompletionDate = moment.utc(purchaseOrderData.estimatedCompletionDate).isValid()
                ? moment.utc(purchaseOrderData.estimatedCompletionDate).local()
                : purchaseOrderData.estimatedCompletionDate;
            purchaseOrderData.repairStartDate = moment.utc(purchaseOrderData.repairStartDate).isValid()
                ? moment.utc(purchaseOrderData.repairStartDate).local()
                : purchaseOrderData.repairStartDate;

            // Return data in expected format
            return purchaseOrderData;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchAvailableVehicleComplaints(vehicleId, vendorId, token) {
        try {
            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/Vendors/${vendorId}/availableComplaints?fields=vehicleComplaintId,complaintCode,description`, getHeaders(token));
            return normalize(response.data, [availableVehicleComplaintsSchema]).entities.availableVehicleComplaints;
        }
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchLineItems(poNumber, token,languageCode, actionRequiredFields = false) {
        try {
            let fields = 'id,productCode,vendorProductCode,productDescription,quantity,unitPrice,totalAmount,approvalStatus,complaintCode,lineItemType,createdDate,correctionType,vendorAcknowledgementStatus,pricingSource,vendorReferenceNo';
            if (actionRequiredFields) fields += ',previousQuantity,previousUnitPrice,createdBy,authorizationStatusReason';

            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/${poNumber}/lineitems?fields=${fields}`, getHeaders(token,languageCode));

            // Set Unique key for each line item as an identity key
            // TODO: we dont need this logic anymore, the containers/components should look for id field instead of lineItemId
            const lineItemsWithUniqueKey = response.data.map(function (item) {
                item['lineItemId'] = item['id'].toString()
                return item;
            });

            // Return data in expected format
            return normalize(lineItemsWithUniqueKey, [lineItemSchema]).entities.lineItems;
        }
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVehicle(poNumber, token) {
        try {
            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles?orderId=${poNumber}&fields=id,color,engineType,licensePlate,year,make,model,lastOdometerReading,vin,estimatedOdometer,estimatedHourMeter,estimatedOdometerRangeMin,estimatedOdometerRangeMax,lastHourMeterReading,maintenanceServices,regulatoryServices,clientName,clientId,isOffRoadVehicle,ariUnitNo,countryCode,assetType,clientBusinessAddress,isHsbClientVehicle`, getHeaders(token));

            // Return Vehicle
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorforClosePO(poNumber, token) {

        try {
            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors?orderId=${poNumber}&fields=id,accountType,businessName,bankAccountNumber,hourlyLaborRate,businessAddress,businessPhone,businessExtension,businessFax,consentToReceiveAuthorizationEmail,paymentType,isHPVendor,corporateVendor,paymentMethod`, getHeaders(token));

            // Return Vehicle
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }

    }

	export async function fetchRequiredDocuments(poNumber, token) {
	    try {            
	        const response = await axios.get(`${configuration.serviceURL}v1/Orders/${poNumber}/RequiredDocuments`, getHeaders(token));

	        return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendor(poNumber, token) {
        try {
            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors?orderId=${poNumber}`, getHeaders(token));

            // Return Vehicle
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorPaymentInfo(poNumber, token) {
        try {
            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors?orderId=${poNumber}&fields=paymentType,paymentMethod`, getHeaders(token));

            // Return Vehicle
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorBankAccountInformation(vendorId, bankAccountId, token) {
        try {

            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/bankaccounts/${bankAccountId}`, getHeaders(token));


            // Return Vehicle
            return response.data
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function closePO(poNumber, state, token) {
        try {

            const invoiceDetails = state.appState.getIn(['uiData', 'close', 'invoiceDetails']);

            let existedTaxAmount = invoiceDetails.get('taxAmount') > 0 ? invoiceDetails.get('taxAmount') :
                state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'taxAmount']);

            const requestData = {
                invoiceNo: invoiceDetails.get('invoiceNo').trim(),
                invoiceDate: moment(invoiceDetails.get('invoiceDate')).utc(),
                submittedBy: invoiceDetails.get('submittedBy'),
                repairStartDate: moment(invoiceDetails.get('repairStartDate')).utc(),
                repairCompletionDate: moment(invoiceDetails.get('repairCompletionDate')).utc(),
                isTaxExempt: invoiceDetails.get('isTaxExempted'),
                taxExemptReason: invoiceDetails.get('isTaxExempted') == true ? invoiceDetails.get('exemptedReason') : null,
                invoiceAmount: invoiceDetails.get('isTaxExempted') == true ? invoiceDetails.get('subTotal')
                    : (Number(invoiceDetails.get('subTotal')) + Number(existedTaxAmount)).toFixed(2),
                clientReferenceNo: invoiceDetails.get('clientReferenceNo')
            };

            // POST data
            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/pay`, requestData, getHeaders(token));

            return;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function cancelPo(poNumber, token) {
        try {

            var body = {
                'poNumber': poNumber
            }
            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/void`, body, getHeaders(token));

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchRepairHistory(vehicleId, offset, limit, token) {
        try {

            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/search?vehicleId=${vehicleId}&offset=${offset}&limit=${limit}&eventtype=REPAIR,PM,ROADSIDE,GLASS,TIRES,INSPECTION,PARTS,DECALS,SOLICITOR,INSURANCE&sort=-createdDate&fields=id,createdDate,poNumber,vendorId,vehicleOdometer,vehicleHourMeter,complaints(code,description)`, getHeaders(token));

            // Normalize it
            const normalizedJson = normalize(response.data, [activePOSchema]);

            // Return POs
            return normalizedJson.entities[activePOSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function createPO(state, token) {
        try {

            //Prepare data
            const requestData = {
                vehicleId: state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']),
                vehicleOdometer: state.appState.getIn(['uiData', 'odometerView', 'newOdometer']),
                vehicleHourmeter: state.appState.getIn(['uiData', 'odometerView', 'newEngineHours']),
                vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
                vendorReferenceNo: state.appState.getIn(['uiData', 'odometerView', 'vendorReferenceNo']).trim(),
                requestor: state.appState.getIn(['uiData', 'odometerView', 'vendorRequisitioner']),
                clientReferenceNo: state.appState.getIn(['uiData', 'odometerView', 'clientReferenceNo'])
            };

            // POST data
            const response = await axios.post(`${configuration.serviceURL}v1/orders`, requestData, getHeaders(token));

            return response.data.id;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchMaintenanceServices(vehicleId, token) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/maintenanceServices?fields=maintenanceServices`, getHeaders(token));

            // Normalize
            let normalizedJson = normalize(response.data.maintenanceServices, [dueServicesSchema]);

            return normalizedJson.entities[dueServicesSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchRegulatoryServices(vehicleId, token) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/regulatoryServices?fields=regulatoryServices`, getHeaders(token));

            // Normalize
            let normalizedJson = normalize(response.data.regulatoryServices, [dueServicesSchema]);

            return normalizedJson.entities[dueServicesSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function createComplaint(poNumber, complaintCode, complaintDescription, token) {
        try {

            // prepare data
            const complaintData = { code: complaintCode, description: complaintDescription };

            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/complaints`, complaintData, getHeaders(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function editComplaint(poNumber, oldComplaintCode, newComplaintCode, newComplaintDescription, token) {
        try {

            // prepare data
            const complaintData = [
                {
                    "op": "replace",
                    "path": "/code",
                    "value": newComplaintCode
                },
                {
                    "op": "replace",
                    "path": "/description",
                    "value": newComplaintDescription
                }];

            const response = await axios.patch(`${configuration.serviceURL}v1/orders/${poNumber}/complaints/${oldComplaintCode}`, complaintData, getHeadersForPatch(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function createLineItem(poNumber, lineItem, token,languageCode) {
        try {

            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/LineItems`, lineItem, getHeaders(token,languageCode));


            return response.data.id.toString();

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function editLineItem(poNumber, lineItemId, newQuantity, newUnitPrice, token,languageCode) {
        try {
            const patch = [{
                "op": "replace",
                "path": "/quantity",
                "value": newQuantity
            }, {
                "op": "replace",
                "path": "/unitPrice",
                "value": newUnitPrice
            }];

            const response = await axios.patch(`${configuration.serviceURL}v1/orders/${poNumber}/LineItems/${lineItemId}`, patch, getHeadersForPatch(token,languageCode));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function acknowledgeLineItem(poNumber, lineItemId, token) {        
        try {
            const patch = [{
                "op": "replace",
                "path": "/vendorAcknowledgementStatus",
                "value": constants.VENDOR_ACKNOWLEDGEMENT_STATUS.APPROVED
            }];

            const response = await axios.patch(`${configuration.serviceURL}v1/orders/${poNumber}/LineItems/${lineItemId}`, patch, getHeadersForPatch(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function deleteLineItem(poNumber, lineItemId, token) {
        try {
            const response = await axios.delete(`${configuration.serviceURL}v1/orders/${poNumber}/LineItems/${lineItemId}`, getHeaders(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function editEstimatedCompletionDate(poNumber, date, token) {
        try {

            // prepare data
            const data = [
                {
                    "op": "replace",
                    "path": "/estimatedCompletionDate",
                    "value": date
                }];

            const response = await axios.patch(`${configuration.serviceURL}v1/orders/${poNumber}`, data, getHeadersForPatch(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function feedbackFormSubmission(feedbackFormData) {
        try {

            const response = await axios.post(`${configuration.serviceURL}v1/vendorsupport/notify`, feedbackFormData);

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function requestApproval(poNumber, token,languageCode) {
        try {
            var body = {
                'poNumber': poNumber
            }
            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/approve`, body, getHeaders(token,languageCode));
        }
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function saveErrorInfo(stackTrace, state, actionName, result) {
        try {
            let token = state.appState.getIn(['serverData', 'shared', 'token']);

            const errorInfo = {
                Action: actionName,
                VendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
                Token: token,
                UserAgent: navigator.userAgent,
                AppState: JSON.stringify(state).substring(0, 10000),
                Result: result,
                StackTrace: JSON.stringify(stackTrace).substring(0, 10000),
                CreatedBy: ""
            };

            const response = await axios.post(`${configuration.logServiceURL}v1/Logs?type=vms`, errorInfo, getHeaders(token));
        }
        catch (e) {
            //absorb error
        }
    }

    export async function fetchComplaintNotes(orderId, token,languageCode) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/notes?category=complaint,line,aripay,client_vendor&orderId=${orderId}&fields=id,noteText,category,complaintCode,createdBy,lineItemId,createdDate`, getHeaders(token,languageCode));
            
            return normalize(response.data, [notesSchema]).entities.notes;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function createComplaintNotes(poNumber, complaintCode, notesData, token) {
        try {

            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/complaints/${complaintCode}/notes`, notesData, getHeaders(token));

            return response.data.id;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function editComplaintNotes(poNumber, complaintCode, newComplaintCode, noteText, noteId, token) {
        try {

            // update complaint note and or complaint code
            const patchData = [];
            if (noteText != undefined)
                patchData.push({
                    "op": "replace",
                    "path": "/noteText",
                    "value": noteText
                });
            if (newComplaintCode != undefined)
                patchData.push({
                    "op": "replace",
                    "path": "/complaintCode",
                    "value": newComplaintCode
                });

            if (patchData.length > 0 && noteId != undefined) {
                const response = await axios.patch(`${configuration.serviceURL}v1/orders/${poNumber}/complaints/${complaintCode}/notes/${noteId}`, patchData, getHeadersForPatch(token));
                return response.data;
            }
            else
                return {};

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchClientParameters(vehicleId, token) {
        try {

            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/parameters?category=client,vehicle`, getHeaders(token));

            // Return Vehicle
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchAtaParameters(vehicleId, productCode, complaintCode, token) {
        try {

            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/parameters?category=client,vehicle&productCode=${productCode}&complaintCode=${complaintCode}`, getHeaders(token));

            // Return Vehicle
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchAtaPmParameters(vehicleId, productCode, complaintCode, token) {
        try {
            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/parameters?category=client,vehicle&productCode=${productCode}&complaintCode=${complaintCode}`, getHeaders(token));

            // Return Vehicle
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function getATACodesBySearchKey(vehicleId, searchKey, token,languageCode) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/ataCodes?vehicleId=${vehicleId}&q=${searchKey}&fields=code,description,complaintcode,correctionandrepairtypesrestricted,multipleLineItemsPermitted`, getHeaders(token,languageCode));

            // Return Vehicle
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function getVendorProductCodesBySearchKey(vehicleId, vendorId, searchKey, token,languageCode) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/apc/vendorproductCodes?vehicleId=${vehicleId}&vendorId=${vendorId}&q=${encodeURIComponent(searchKey)}&fields=vendorProductCode,vendorProductDescription,productCode,productDescription,complaintcode,correctionandrepairtypesrestricted,multipleLineItemsPermitted,laborUnitPrice,partUnitPrice,isUnmappedVendorProductCode,partPricingSource,laborPricingSource`, getHeaders(token,languageCode));

            // Return vendor product code information
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function getPMInformationReport(vehicleId, token) {

        const response = await axios.get(`${configuration.serviceURL}v1/Vehicles/${vehicleId}/pmInformation?reportType=ExportToPdf`, getHeadersForPMInformation(token));

        return response.data;
    }

    export async function getBulletinMessages(vendorId, token,state) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/Vendors/${vendorId}/alerts?fields=Id,Title,SubTitle,Message,BulletinType`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function getGlobalBulletinMessages() {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/Vendors/alerts?fields=Id,Title,SubTitle,Message,BulletinType`);


            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }
    export async function changePassword(state, token, password, newPassword) {

        try {
            //Prepare data

            const requestData = {
                password: password,
                newPassword: newPassword
            };

            let id = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            let vendorAccountType = state.appState.getIn(['serverData', 'shared', 'vendorAccountType']);

            if(vendorAccountType === "Sub" && id === null)
            {
                id = state.appState.getIn(['serverData', 'shared', 'corporateId']);
            }

            // POST data
            const response = await axios.post(`${configuration.serviceURL}v1/vendors/${id}/changepassword`, requestData, getHeaders(token));

            return null;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function sendRecoverPasswordInformation(ariId, personalInformation) {
        try {
            const config = {
                headers: {
                    'x-app-key': `${configuration.AppKey}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post(`${configuration.serviceURL}v1/vendors/${ariId}/recoverpassword`, personalInformation, config);

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function sendPersonaRecoverPasswordInformation(personalInformation) {
        try {
            const config = {
                headers: {
                    'x-app-key': `${configuration.PersonaAppKey}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            };

            const response = await axios.post(`${configuration.serviceURL}v1/users/recoverpassword`, personalInformation, config);

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorVehicleParameters(vendorId, vehicleId, token) {
        try {
            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/vehicles/${vehicleId}/parameters?fields=laborRate,hourlyLaborRate`, getHeaders(token));

            // Return data
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorContactInformation(token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorLaborRateInformation(vendorId,token, state){
        try {
            

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/Vendors/${vendorId}/LaborRateInformation`, getHeaders(token));            
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function saveVendorLaborRateInformation(laborRate,vendorId,token){
        try{
         

            var requestData = [
                {
                    "op": "replace",
                    "path": "/standardRate",
                    "value": laborRate
                }
            ];
           
            const response = await axios.patch(`${configuration.serviceURL}v1/Vendors/${vendorId}/LaborRateInformation`, requestData, getHeadersForPatch(token));

            return response.data;
        }catch(e){
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchCorporateVendorContactInformation(token, state) {
        try {
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}`, getHeaders(token));

            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorBusinessGrowthInformation(token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}?fields=statistics(vehiclesStatistics)`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchOpenOrdersOver30DayCount(token, vendorId) {
        try {

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}?fields=statistics(ordersStatistics)`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchLinkedVendorAccountInformation(vendorState, zip, offset, limit, token, state) {
        try {
            let response;
            let corporateId = state.appState.getIn(['serverData', 'shared', 'corporateId']);
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            let id;
            let vendorAccountType = state.appState.getIn(['serverData', 'shared', 'vendorAccountType']);

            if (vendorAccountType === "Master") {
                id = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            } else if (vendorAccountType === "Sub" && vendorId === null) {
                id = state.appState.getIn(['serverData', 'shared', 'corporateId']);
            } else if (vendorAccountType === "Sub" && corporateId !== null && vendorId !== null) {
                id = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            }


            if (vendorState !== undefined) {
                // Get vendor vehicle parameter data
                response = await axios.get(`${configuration.serviceURL}v1/vendors/${id}/subvendors?state=${vendorState}&offset=${offset}&limit=${limit}&sort=-userId`, getHeaders(token));
            } else if (zip !== undefined) {
                response = await axios.get(`${configuration.serviceURL}v1/vendors/${id}/subvendors?zipCode=${zip}&offset=${offset}&limit=${limit}&sort=-userId`, getHeaders(token));

            } else {
                response = await axios.get(`${configuration.serviceURL}v1/vendors/${id}/subvendors?offset=${offset}&limit=${limit}&sort=-userId`, getHeaders(token));

            }

            const normalizedJson = normalize(response.data, [linkedVendorSchema]);

            // Return POs
            return normalizedJson.entities[linkedVendorSchema.key];



        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }



    export async function fetchVendorContactEmail(token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/contacts`, getHeaders(token));
            const normalizedJson = normalize(response.data, [contactEmailSchema]);

            return normalizedJson.entities[contactEmailSchema.key];


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function sendVendorContactInformation(state, token, vendorBusinessNumber, vendorExtension, vendorFaxNumber, vendorStreetAddress, vendorCityAddress, vendorStateAddress, vendorZipAddress, vendorCountry) {
        try {


            var requestData = [
                {
                    "op": "replace",
                    "path": "/businessPhone",
                    "value": vendorBusinessNumber
                },
                {
                    "op": "replace",
                    "path": "/businessExtension",
                    "value": vendorExtension
                },
                {
                    "op": "replace",
                    "path": "/businessFax",
                    "value": vendorFaxNumber
                },
                //--------------------------------------------------------------
                //--- The below code is commented out because we are currently not saving physical address from the vms to inifinium
                //--- Uncomment the code below when we have intigration in place
                //--------------------------------------------------------------

            ];

            if (vendorCountry == 'USA') {
                requestData.push({
                    "op": "replace",
                    "path": "/businessAddress",
                    "value": {
                        "address1": vendorStreetAddress,
                        "address2": null,
                        "city": vendorCityAddress,
                        "state": vendorStateAddress,
                        "zipCode": vendorZipAddress
                    }
                });
            }

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get vendor vehicle parameter data
            const response = await axios.patch(`${configuration.serviceURL}v1/vendors/${vendorId}`, requestData, getHeadersForPatch(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


    export async function saveVendorPaymentEmail(token, verificationToken, languageCode, vendorId,vendorEmailRecipient) {
        
        try {            

            let contact = `[{
                "op": "replace",
                "path": "email",
                "value": "${vendorEmailRecipient}"
            }]`;
    
                        
            const response = await axios.patch(`${configuration.serviceURL}v1/vendors/${vendorId}/contacts/${vendorId}`, contact,getHeadersforPatchWithVerificationToken(token,verificationToken,languageCode));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function saveVendorPaymentContactName(state, token, paymentContactName) {
        
        try {            

            let contact = `[{
                "op": "replace",
                "path": "name",
                "value": "${paymentContactName}"
            }]`;
    
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            let vendorLanguageCode = state.appState.getIn(['uiData', 'shared', 'selectedLocale']);
                
            const response = await axios.patch(`${configuration.serviceURL}v1/vendors/${vendorId}/contacts/${vendorId}`, contact,getHeadersForPatch(token,vendorLanguageCode));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function sendVendorContactInformationEmail(state, token, vendorName, vendorEmailRecipient) {
        try {
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            const vendorInfo = {
                id: vendorId,
                name: vendorName,
                email: vendorEmailRecipient,
                notifyApprovals: falseIfNull(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'contactsEmail', vendorId, 'notifyApprovals'])),
                notifyPayments: state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'contactsEmail', vendorId, 'notifyPayments']),
            };

            const response = await axios.put(`${configuration.serviceURL}v1/vendors/${vendorId}/contacts/${vendorId}`, vendorInfo, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVehicleDocuments(vehicleId, token,vendorLanguage) {
        try {
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/documents`, getHeaders(token,vendorLanguage));

            // Normalize
            let normalizedJson = normalize(response.data, [documentsSchema]);

            return normalizedJson.entities[documentsSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorDocuments(vendorId, token,vendorLanguage) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/documents`, getHeaders(token,vendorLanguage));

            // Normalize
            let normalizedJson = normalize(response.data, [documentsSchema]);

            return normalizedJson.entities[documentsSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function uploadVehicleDocument(token, vehicleId, file, fileInfo,vendorLanguage) {
        try {
            let data = new FormData();
            data.append('fileName', fileInfo.get('fileName'));
            data.append('name', fileInfo.get('name'));
            data.append('documentType', fileInfo.get('documentType'));
            data.append('createdDate', fileInfo.get('createdDate'));
            data.append('file', file);

            const response = await axios.post(`${configuration.serviceURL}v1/Vehicles/${vehicleId}/documents/`, data, getHeadersForMultipartPost(token,vendorLanguage));

            return response.data.id;
        }
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function uploadVendorDocument(token, vendorId, file, fileInfo,vendorLanguage) {
        try {
            let data = new FormData();
            data.append('fileName', fileInfo.get('fileName'));
            data.append('name', fileInfo.get('name'));
            data.append('documentType', fileInfo.get('documentType'));
            data.append('createdDate', fileInfo.get('createdDate'));
            data.append('file', file);

            const response = await axios.post(`${configuration.serviceURL}v1/Vendors/${vendorId}/documents/`, data, getHeadersForMultipartPost(token,vendorLanguage));

            return response.data.id;
        }
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function submitVendorEnrollmentRequest(requestInfoPostData, token) {
        try {
            // Submit vendor request details
            const response = await axios.post(`${configuration.serviceURL}v1/vendors/enroll`, requestInfoPostData, getHeaders(token));

            // Return data
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function submitVendorLinkedEnrollmentRequest(linkedVendorRegistrationData, token) {
        try {
            // Submit vendor request details
            const response = await axios.post(`${configuration.serviceURL}v1/vendors/registerAccount`, linkedVendorRegistrationData, getHeaders(token));

            // Return data
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }



    export async function submitVendorEnrollment(enrollmentPatchData, token) {
        try {

            // Submit vendor request details
            const response = await axios.patch(`${configuration.serviceURL}v1/vendors/enroll`, enrollmentPatchData, getHeadersForPatch(token));

            // Return data
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }
    export async function fetchVendorBankingInformation(token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/bankaccounts`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }
    export async function fetchVendorBankingPaymentStatusInformation(token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/bankaccounts?fields=status`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function verifyVendorBankingInformation(state, token, tax, taxType) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            const vendorTax = {
                taxId: btoa(tax),
                taxIdType: taxType
            };

            // Get vendor vehicle parameter data
            const response = await axios.post(`${configuration.serviceURL}v1/vendors/${vendorId}/token`, vendorTax, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function changeVendorBankingInformation(state, token, vendorRoutingNumber, vendorChecking1) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            const vendorBankingInfo = {
                id: vendorId + "MAINTENANCE",
                type: "MAINTENANCE",
                routingNumber: vendorRoutingNumber,
                accountNumber: vendorChecking1,
                requestedBy: state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'ARIId'])


            };
            const x_token = state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'verify']);


            const response = await axios.put(`${configuration.serviceURL}v1/vendors/${vendorId}/bankaccounts/${vendorId}`, vendorBankingInfo, getHeadersBankAccount(token, x_token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }
    

    export async function fetchVendorServiceInformation(token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            let vendorCountryCode = state.appState.getIn(['uiData', 'shared', 'selectedCountry']);
            let vendorLanguageCode = state.appState.getIn(['uiData', 'shared', 'selectedLocale']);

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/profile?countryCode=${vendorCountryCode}&languageCode=${vendorLanguageCode}`, getHeaders(token,vendorLanguageCode));
            const normalizedJson = normalize(response.data.services, [vendorServiceSchema]);

            return { vendorService: normalizedJson.entities[vendorServiceSchema.key], vendorMinority: response.data.minorityType };


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function sendVendorServices(state, token, vendorServices, vendorMinority) {
        try {

            let minorityType = "";
            let value = null;
            let listOfSelected = '';

            let minoritySelected = vendorMinority.filter((visible) => {

                return visible.get('isServiceOffered') === true;

            });

            let vendorSelected = vendorServices.filter((visible) => {

                return visible.get('isServiceOffered') === true;

            });

            minoritySelected.map(minority => {

                return minorityType = minority.get('id')

            });


            vendorSelected.forEach(service => {

                value = `
            ${`{"id": ${service.get("id")},
                    "isServiceOffered": ${service.get("isServiceOffered")}
                    },`}`;

                return listOfSelected += value;
            });
            let minority = `[{
            "op": "replace",
            "path": "minorityType",
            "value": "${minorityType}"
        },{
            "op": "add",
                "path": "/services",
                "value":[`;

            listOfSelected = minority + listOfSelected + ']}]';
            listOfSelected = listOfSelected.replace(',]', ']');
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            let vendorLanguageCode = state.appState.getIn(['uiData', 'shared', 'selectedLocale']);

            const response = await axios.patch(`${configuration.serviceURL}v1/vendors/${vendorId}/profile`, listOfSelected, getHeadersForPatch(token,vendorLanguageCode));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function submitAccountInfo(accountInfoData, token) {
        try {

            console.log('api.accountInfoData:', JSON.stringify(accountInfoData));

            return {};

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


    export async function submitBankInfo(bankInfo) {
        try {
            const response = await axios.post(`${configuration.serviceURL}v1/vendors/registerAccount`, bankInfo);
            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorTax(token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/Tax`, getHeaders(token));


            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorServiceInformationForRegistration(token, vendorInfo) {
        try {

            let vendorId = vendorInfo.vendorId;
            let vendorCountryCode = vendorInfo.vendorCountry;
            let vendorLanguageCode = vendorInfo.vendorLocale;
            // Get vendor vehicle parameter data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/profile?countryCode=${vendorCountryCode}&languageCode=${vendorLanguageCode}`, getHeaders(token,vendorLanguageCode));
            const normalizedJson = normalize(response.data.services, [vendorServiceSchema]);

            return { vendorService: normalizedJson.entities[vendorServiceSchema.key], vendorMinority: response.data.minorityType };


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }




    export async function sendVendorServicesForRegistration(state, token, vendorServices, vendorMinority) {
        try {

            let minorityType = "";
            let value = null;
            let listOfSelected = '';

            let minoritySelected = vendorMinority.filter((visible) => {
                return visible.get('isServiceOffered') === true;
            });

            let vendorSelected = vendorServices.filter((visible) => {
                return visible.get('isServiceOffered') === true;
            });

            minoritySelected.map(minority => {
                return minorityType = minority.get('id')
            });


            vendorSelected.forEach(service => {
                value = `
            ${`{"id": ${service.get("id")},
                    "isServiceOffered": ${service.get("isServiceOffered")}
                    },`}`;
                return listOfSelected += value;
            });

            let minority = `[{
            "op": "replace",
            "path": "minorityType",
            "value": "${minorityType}"
        },{
            "op": "add",
                "path": "/services",
                "value":[`;

            listOfSelected = minority + listOfSelected + ']}]';
            listOfSelected = listOfSelected.replace(',]', ']');
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            const response = await axios.patch(`${configuration.serviceURL}v1/vendors/${vendorId}/profile`, listOfSelected, getHeadersForPatch(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


    export async function saveAuthEmailNotificationConfigurationIsRegistered(vendorId, isRegistered, token) {
        try {
            
            // Get vendor vehicle parameter data
            const response = await axios.patch(`${configuration.serviceURL}v1/vendors/${vendorId}`, isRegistered, getHeadersForPatch(token));
            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchMyVendorUnits(offset, limit, token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/vehicles?hasServiced=${true}&offset=${offset}&limit=${limit}`, getHeaders(token));
            // Normalize it
            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchMyVendorOtherUnits(offset, limit, token, state) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/vehicles?hasServiced=${false}&offset=${offset}&limit=${limit}`, getHeaders(token));
            // Normalize it
            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function deleteUnitItem(vehicleId, state, token) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            const response = await axios.delete(`${configuration.serviceURL}v1/vendors/${vendorId}/vehicles/${vehicleId}`, getHeaders(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


    export async function saveAuthEmailNotificationConfigurationEmails(vendorId, notificationEmailConfig, token) {
        try {
            const response = await axios.post(`${configuration.serviceURL}v1/vendors/${vendorId}/contacts`, notificationEmailConfig, getHeaders(token));
            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchAuthEmailNotificationConfig(vendorId, token) {
        try {
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/contacts`, getHeaders(token));

            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


    export async function deleteAuthEmailNotificationConfigurationEmails(vendorId, contactId, token) {
        try {
            const response = await axios.delete(`${configuration.serviceURL}v1/vendors/${vendorId}/contacts/${contactId}`, getHeaders(token));
            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function updateAuthEmailNotificationConfigurationEmails(vendorId, notificationEmailConfig, token) {
        try {
            const response = await axios.put(`${configuration.serviceURL}v1/vendors/${vendorId}/contacts/${notificationEmailConfig.id}`, notificationEmailConfig, getHeaders(token));
            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function updatePO(poNumber, odometer, engineHours, repairCompletionDate,vendorReferenceNo,repairStartDate,clientReferenceNo,token) {
        try {

            // prepare data
            const data = [];

            if (odometer !== null)
                data.push(
                    {
                        "op": "replace",
                        "path": "/vehicleOdometer",
                        "value": odometer
                    });

            if (engineHours !== null)
                data.push(
                    {
                        "op": "replace",
                        "path": "/vehicleHourmeter",
                        "value": engineHours
                    });

            if (repairCompletionDate !== null)
                data.push(
                    {
                        "op": "replace",
                        "path": "/repairCompletionDate",
                        "value": repairCompletionDate
                    });

            if (vendorReferenceNo !== null)
                data.push(
                    {
                        "op": "replace",
                        "path": "/vendorReferenceNo",
                        "value": vendorReferenceNo
                    });

            if (repairStartDate !== null)
                data.push(
                    {
                        "op": "replace",
                        "path": "/repairStartDate",
                        "value": repairStartDate
                    });       
             
             if (clientReferenceNo !== null)
                data.push(
                    {
                        "op": "replace",
                        "path": "/clientReferenceNo",
                        "value": clientReferenceNo
                    });     
            
            if ( data.length > 0)    
            {    
                const response = await axios.patch(`${configuration.serviceURL}v1/orders/${poNumber}`, data, getHeadersForPatch(token));
                return response.data;
            }
            else 
                return null;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchVendorClientNumber(state, token) {
        try {
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/carwashClients`, getHeaders(token));

            // return vendor details
            let normalizedJson = normalize(response.data, [carWashClientsSchema]);

            return normalizedJson.entities[carWashClientsSchema.key];

        } catch (e) {
            throw e.responsedata.error;
        }
    }

    export async function fetchCarWashOrderDetails(state, token) {
        try {
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/search?eventtype=CARWASH&paymentStatus=PAYMENT_IN_PROGRESS,PAYMENT_NOT_REQUESTED&dateRestrict=d180&vendorId=${vendorId}&fields=id,poNumber,createdDate,authorizedDate,authorizedAmount,pendingAmount,declinedAmount,totalAmount,taxAmount,authorizationStatus,paymentStatus,vehicleYear,vehicleMake,vehicleModel,vehicleVIN8,vendorReferenceNo,licensePlate,invoiceNo,authorizationStatus=WAITING_FOR_CLIENT_APPROVAL,APPROVED`, getHeaders(token));
            // return vendor details
            let normalizedJson = normalize(response.data, [activePOSchema]);

            return normalizedJson.entities[activePOSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


export async function fetchCarWashDetailsVehCustARIUnitNo(state, token, clientNumber, unitNumber) {
    try {
        let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

        // Get response
        const response = await axios.get(`${configuration.serviceURL}v1/vehicles/Search?custId=${clientNumber.toUpperCase()}&ariUnitNo=${unitNumber.toUpperCase()}&fields=year,make,model,vin,clientName,ariUnitNo,licensePlate,id,clientId,lastOdometerReading,estimatedOdometer,estimatedOdometerRangeMin,estimatedOdometerRangeMax`, getHeaders(token));
        return response.data;

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

export async function fetchCarWashDetailsVehClientUnitNo(state, token, clientNumber, unitNumber) {
    try {
        let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

        const response = await axios.get(`${configuration.serviceURL}v1/vehicles/Search?custId=${clientNumber.toUpperCase()}&clientUnitNo=${unitNumber.toUpperCase()}&fields=year,make,model,vin,clientName,ariUnitNo,licensePlate,id,clientId,lastOdometerReading,estimatedOdometer,estimatedOdometerRangeMin,estimatedOdometerRangeMax`, getHeaders(token));

        return response.data;

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

    export async function fetchVehClientUnitNo(state, token, clientNumber, unitNumber) {
        try {
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/Search?custId=${clientNumber.toUpperCase()}&clientUnitNo=${unitNumber.toUpperCase()}&fields=id,color,engineType,licensePlate,year,make,model,lastOdometerReading,vin,estimatedOdometer,estimatedHourMeter,estimatedOdometerRangeMin,estimatedOdometerRangeMax,lastHourMeterReading,maintenanceServices,regulatoryServices,clientName,clientId,isOffRoadVehicle,ariUnitNo,countryCode,existingOrderWaitingOrApprovedAuthorization,isDriverValidationRequired,existingOrderPreviouslyVoided`, getHeaders(token));
        
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

export async function fetchVehCustARIUnitNo(state, token, clientNumber, unitNumber) {
    try {
        let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
        const response = await axios.get(`${configuration.serviceURL}v1/vehicles/Search?custId=${clientNumber.toUpperCase()}&ariUnitNo=${unitNumber.toUpperCase()}&fields=id,color,engineType,licensePlate,year,make,model,lastOdometerReading,vin,estimatedOdometer,estimatedHourMeter,estimatedOdometerRangeMin,estimatedOdometerRangeMax,lastHourMeterReading,maintenanceServices,regulatoryServices,clientName,clientId,isOffRoadVehicle,ariUnitNo,countryCode,existingOrderWaitingOrApprovedAuthorization,isDriverValidationRequired,existingOrderPreviouslyVoided,isHsbClientVehicle`, getHeaders(token));

        return response.data;

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

    export async function fetchCarWashParameterDetails(state, token) {
        try {

            let vehicleId = state.appState.getIn(['uiData', 'carWashAccount', 'vehicleDetails', 'id']);

            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/parameters/search?parameterType=carwash`, getHeaders(token));


            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function createCarWashPOSubmit(state, token, ) {
        try {
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            const poInfo = {
                vehicleId: Number(state.appState.getIn(['uiData', 'carWashAccount', 'vehicleDetails', 'id'])),
                odometer: Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'odometer'])),
                serviceDate: state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'serviceDate']),
                invoiceNo: state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'invoiceNo']),
                regularWashUnitPrice: Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'carWashCost'])) > 0 ? Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'carWashCost'])) : null,
                detailWashPrice: Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'detailCarWashCost'])) > 0 ? Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'detailCarWashCost'])) : null,
                salesTaxStandard: Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'taxAmount'])),
                salesTaxGST: Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'taxes', 'GST'])),
                salesTaxHST: Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'taxes', 'HST'])),
                salesTaxPST: Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'taxes', 'PST'])),
                salesTaxQST: Number(state.appState.getIn(['uiData', 'carWashAccount', 'poDetails', 'taxes', 'QST'])),
                vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
            };

            const response = await axios.post(`${configuration.serviceURL}v1/Orders/carwash`, poInfo, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchTireSpecifications(poNumber, token) {
        try {
            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/${poNumber}/tires?fields=id,vehicleId,axleNumber,position,eventType,treadDepth,lineItemId,width,diaMeter,manufacturer,model,wearOutReason,replacedTireTreadDepth,unitOfMeasurement,aspectRatio`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function saveTireSpecifications(poNumber, tires, token) {
        try {
            // Get response

            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/tires`, tires, getHeaders(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchDealerDraftDealerRequests(state, token) {
        try {
            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            const response = await axios.get(`${configuration.serviceURL}v1//Orders/drafts/search?status=open,active,processed,approved,pending,post&vendorid=${vendorId}`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchRequestsNoDetails(token, requestNo) {
        try {


            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/drafts/${requestNo}?fields=id,vendorId,issueDate,taxRate,status,draftTotal,registrationExpiration,deliveryDate,registrationState,licensePlate,draftTotal,vin8,clientId,ariUnitNo,vehicleMake,vehicleModel,vehicleYear,clientName,vehicleState,vehicleCity,tempLicensePlate,tempRegistrationExpDate,tempRegistrationState,whoWillLicense,isDeliveryDateRequired`, getHeaders(token));


            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function dealerDraftEditLineItem(requestNo, lineItemId, newUnitPrice, token) {
        try {
            const patch = [{
                "op": "replace",
                "path": "/unitPrice",
                "value": newUnitPrice
            }];

            const response = await axios.patch(`${configuration.serviceURL}v1/orders/drafts/${requestNo}/LineItems/${lineItemId}`, patch, getHeadersForPatch(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function dealerDraftDeleteLineItem(requestNo, lineItemId, token) {
        try {

            const response = await axios.delete(`${configuration.serviceURL}v1/orders/drafts/${requestNo}/LineItems/${lineItemId}`, getHeaders(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function dealerDraftFetchLineItems(requestNo, token) {
        try {

            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/drafts/${requestNo}/lineitems`, getHeaders(token));

            const normalizedJson = normalize(response.data, [dealerDraftLineItemSchema]);

            // Return POs
            return normalizedJson.entities[dealerDraftLineItemSchema.key];

        }
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchDealerDraftProductDetails(requestNo, token) {
        try {


            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/drafts/${requestNo}/availableproducts`, getHeaders(token));


            const normalizedJson = normalize(response.data, [dealerDraftProductDetails]);

            // Return POs
            return normalizedJson.entities[dealerDraftProductDetails.key];


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


    export async function dealerDraftCreateLineItem(requestNo, lineItem, token) {
        try {


            const response = await axios.post(`${configuration.serviceURL}v1/orders/drafts/${requestNo}/LineItems`, lineItem, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


        /* START - SEARCH FUNCTIONALITY  */

    export async function searchForPOsByVIN8AndVehicleId(vendorId, vehicleId, token,languageCode="en-US") {
        try {
            // Get vehicle data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/search?eventtype=REPAIR,PM,ROADSIDE,GLASS,TIRES,INSPECTION,PARTS,DECALS,SOLICITOR,OTHER,CARWASH,RENTAL,INSURANCE&vendorId=${vendorId}&vehicleId=${vehicleId}&fields=id,poNumber,createdDate,authorizedDate,authorizedAmount,pendingAmount,declinedAmount,totalAmount,taxAmount,authorizationStatus,paymentStatus,vehicleYear,vehicleMake,vehicleModel,vehicleVIN8,createdDate,paymentRequestedDate,paymentDate,invoiceNo,hasAssociatedClaim`, getHeaders(token,languageCode));

            // Normalize it
            const normalizedJson = normalize(response.data, [activePOSchema]);

            // Return POs
            return normalizedJson.entities[activePOSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function searchForPOsByPaymentNumber(vendorId, paymentNo, token,languageCode="en-US") {
        try {
            // Get vehicle data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/search?vendorId=${vendorId}&paymentNumber=${paymentNo}&eventtype=REPAIR,PM,ROADSIDE,GLASS,TIRES,INSPECTION,PARTS,DECALS,SOLICITOR,OTHER,CARWASH,RENTAL,INSURANCE&fields=id,poNumber,paymentNumber,createdDate,authorizedDate,authorizedAmount,pendingAmount,declinedAmount,totalAmount,taxAmount,authorizationStatus,paymentStatus,vehicleYear,vehicleMake,vehicleModel,vehicleVIN8,createdDate,paymentRequestedDate,paymentDate,invoiceNo,hasAssociatedClaim`, getHeaders(token,languageCode));

            // Normalize it
            const normalizedJson = normalize(response.data, [activePOSchema]);

            // Return POs
            return normalizedJson.entities[activePOSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function searchForPOsByClientPONumber(clientReferenceNo,vendorId, token,languageCode="en-US") {
        try {
         
            // Get vehicle data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/search?clientReferenceNo=${encodeURIComponent(clientReferenceNo)}&vendorId=${vendorId}&eventtype=REPAIR,PM,ROADSIDE,GLASS,TIRES,INSPECTION,PARTS,DECALS,SOLICITOR,OTHER,CARWASH,RENTAL,INSURANCE&fields=id,poNumber,serialNo,clientId,clientName,ariUnitNo,vin,authorizationStatus,paymentStatus,hasAssociatedClaim,clientPONumber`, getHeaders(token,languageCode));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function searchForPOsByInvoiceNumber(invoiceNo, vendorId, token,languageCode="en-US") {
        try {
            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/Search?invoiceNumber=${invoiceNo}&vendorId=${vendorId}&fields=id,poNumber,vehicleId,vendorId,createdDate,estimatedCompletionDate,authorizationStatus,paymentStatus,pendingAmount,authorizedAmount,taxAmount,authorizationStatusReason,isClientAuthorizationRequired`, getHeaders(token,languageCode));

            const purchaseOrderData = response.data[0];

            // Set dates in correct format
            purchaseOrderData.estimatedCompletionDate = moment.utc(purchaseOrderData.estimatedCompletionDate).isValid()
                ? moment.utc(purchaseOrderData.estimatedCompletionDate).local()
                : purchaseOrderData.estimatedCompletionDate;
            purchaseOrderData.repairStartDate = moment.utc(purchaseOrderData.repairStartDate).isValid()
                ? moment.utc(purchaseOrderData.repairStartDate).local()
                : purchaseOrderData.repairStartDate;

            // Return data in expected format
            return purchaseOrderData;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

        //This function supports group search by purchase orders, payments and Invoices for last 60 or 120
    export async function searchForPOsByDuration(vendorId, dateRestrictedField, days, token) {
        try {
            // Get vehicle data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/search?vendorId=${vendorId}&${dateRestrictedField}=d${days}${dateRestrictedField == 'dateRestrict' ? '&paymentStatus=PAYMENT_NOT_REQUESTED,PAYMENT_IN_PROGRESS,PAID' : ''}&eventtype=REPAIR,PM,ROADSIDE,GLASS,TIRES,INSPECTION,PARTS,DECALS,SOLICITOR,OTHER,CARWASH,RENTAL,INSURANCE&fields=id,poNumber,paymentNumber,createdDate,authorizedDate,authorizedAmount,pendingAmount,declinedAmount,totalAmount,taxAmount,authorizationStatus,paymentStatus,vehicleYear,vehicleMake,vehicleModel,vehicleVIN8,createdDate,paymentRequestedDate,paymentDate,invoiceNo,hasAssociatedClaim`, getHeaders(token));

            // Normalize it
            const normalizedJson = normalize(response.data, [activePOSchema]);

            // Return POs
            return normalizedJson.entities[activePOSchema.key];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

        /* END - SEARCH FUNCTIONALITY  */

    export async function saveBrakesInfo(poNumber, brakes, token) {
        try {
            // Get response
            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/brakes`, brakes, getHeaders(token));
            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }



    export async function fetchBrakesSpecifications(poNumber, token) {
        try {
            // Get response
            const response = await axios.get(`${configuration.serviceURL}v1/orders/${poNumber}/brakes?fields=id,vehicleId,axleNumber,position,eventType,measurement,lineItemId,unitOfMeasurement,maxDrumDiameter,minRotorThickness,component`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function dealerDraftRequestPayment(requestNo, requestPayment, token) {
        try {

            const response = await axios.post(`${configuration.serviceURL}v1/orders/drafts/${requestNo}/pay`, requestPayment, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


    export async function dealerDraftRequestReset(requestNo, token) {
        try {
            const response = await axios.post(`${configuration.serviceURL}v1/orders/drafts/${requestNo}/restore`, null, getHeaders(token));
            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function getTermsAndConditionText() {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/vendors/termsAndConditions?region=NA`);

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function getVendorTermsAcceptanceStatus(vendorId, token) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}?fields=latestTermsAndConditionsAccepted`,getHeaders(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function updateVendorAcceptStatus(vendorId,tcInitials, token) {
        try {

            const patch = [{
                "op": "replace",
                "path": "/latestTermsAndConditionsAcceptedBy",
                "value": tcInitials
            }];

            const response = await axios.patch(`${configuration.serviceURL}v1/vendors/${vendorId}`,patch, getHeadersForPatch(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function dealerDraftStatus(state, token) {
        try {

            let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}?fields=isDealer`, getHeaders(token));

            return response.data;


        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchApplicableTax(vehicleId, vendorId,token) {
        try {

            // Get data
            const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/applicableTaxes?vehicleId=${vehicleId}`, getHeaders(token));

            return response.data;
        }
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function createPONotes(poNumber, notesData, token) {
        try {

            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/notes`, notesData, getHeaders(token));

            return response.data.id;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function deleteComplaintNotes(noteId,poNumber, token) {
        try {

            const response = await axios.delete(`${configuration.serviceURL}v1/orders/${poNumber}/notes/${noteId}`, getHeaders(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


export async function fetchVehicleByPlateState(licensePlate,licensePlateState,countryCode, token) {
    try {
        // Get vehicle data
        const response = await axios.get(`${configuration.serviceURL}v1/vehicles/Search?licensePlate=${licensePlate}&licensePlateState=${licensePlateState}&countryCode=${countryCode}&fields=id,color,engineType,licensePlate,year,make,model,lastOdometerReading,vin,estimatedOdometer,estimatedHourMeter,estimatedOdometerRangeMin,estimatedOdometerRangeMax,lastHourMeterReading,maintenanceServices,regulatoryServices,clientName,clientId,isOffRoadVehicle,ariUnitNo,countryCode,existingOrderWaitingOrApprovedAuthorization,isDriverValidationRequired,existingOrderPreviouslyVoided`, getHeaders(token));

        // Return data
        return response.data;

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

export async function fetchVehicleByServiceNo(vehicleServiceCardNo, token) {
    try {
        // Get vehicle data
        const response = await axios.get(`${configuration.serviceURL}v1/vehicles/Search?vehicleServiceCardNo=${vehicleServiceCardNo}&fields=id,color,engineType,licensePlate,year,make,model,lastOdometerReading,vin,estimatedOdometer,estimatedHourMeter,estimatedOdometerRangeMin,estimatedOdometerRangeMax,lastHourMeterReading,maintenanceServices,regulatoryServices,clientName,clientId,isOffRoadVehicle,ariUnitNo,countryCode,existingOrderWaitingOrApprovedAuthorization,isDriverValidationRequired,existingOrderPreviouslyVoided`, getHeaders(token));

        // Return data
        return response.data;

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

export async function createDotInspection(poNumber, dotInspectionData, token,vendorLanguage) {
    try {

        const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/DotInspection`, dotInspectionData, getHeaders(token,vendorLanguage));        

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

export async function createBitInspection(poNumber, bitInspectionData, token,vendorLanguage) {
    try {

        const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/BitInspection`, bitInspectionData, getHeaders(token,vendorLanguage));        

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

export async function createFraInspection(poNumber, fraInspectionData, token,vendorLanguage) {
    try {

        const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/FraInspection`, fraInspectionData, getHeaders(token,vendorLanguage));

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

export async function createCraneInspection(poNumber, craneInspectionData, token,vendorLanguage) {
    try {

        const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/AnsiMobileCraneInspection`, craneInspectionData, getHeaders(token,vendorLanguage));

    } catch (e) {
        throw getFriendlyErrorObject(e);
    }
}

export async function fetchIsWorkCompletionFeatureEnabled(vendorId, token) {
    try {
        // Is work completion feature enabled?
        const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}?fields=canMarkOrderAsWorkCompleted`, getHeaders(token));
        
        // Return data
        return response.data.canMarkOrderAsWorkCompleted;

        } 
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
}

export async function fetchFieldValidations(vendorId, token) {
    try {
        // Get field validations
        const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/WorkCompletedPermissions`, getHeaders(token));
        
        // Normalize it
        const normalizedJson = normalize(response.data, [fieldValidationsSchema]);

        // Return validations
        return normalizedJson.entities[fieldValidationsSchema.key];

        } 
        catch (e) {
            throw getFriendlyErrorObject(e);
        }
}


export async function fetchVendorFeatures(vendorId, token) {
    try {
        // Get features that are enabled for the vendor
        const response = await axios.get(`${configuration.serviceURL}v1/vendors/${vendorId}/features`, getHeaders(token));
                
        // Get features applicable to APC
        response.data = response.data.filter(feature => feature.Applications.filter(a => a.applicationName == 'apc').length == 1);
        
        // Normalize it
        const normalizedJson = normalize(response.data, [vendorFeaturesSchema]);

        // Return features
        return normalizedJson.result;

        } 
        catch (e) {
           throw getFriendlyErrorObject(e);
        }
    }

export async function rejectAdjustments(poNumber, token) {
    try {

        var body = {
            'poNumber': poNumber
        }
        const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/RejectAdjustments`, body, getHeaders(token));

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchPOAdjustmentStatus(poNumber, token) {
        try {
                
            // Get adjustment data
            const response = await axios.get(`${configuration.serviceURL}v1/orders/${poNumber}/AdjustmentStatus`, getHeaders(token));

            // Return data
            return response.data;            

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    
    export async function resetPasswordForPersonaUser(userId, userPasswordInformation, token) {
        try {
            const config = {
                headers: {
                    'Authorization': token,
                    'x-app-key': `${configuration.PersonaAppKey}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            };
    
            const response = await axios.post(`${configuration.serviceURL}v1/Users/${userId}/ResetPassword`, userPasswordInformation, config);

            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }
    
    export async function resetPassword(vendorId, vendorPasswordInformation, token) {
     
        try {
            const config = {
                headers: {
                    'Authorization': token,
                    'x-app-key': `${configuration.AppKey}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            };
    
            const response = await axios.post(`${configuration.serviceURL}v1/vendors/${vendorId}/resetpassword`, vendorPasswordInformation, config);

            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function SubmitVendorNotes(poNumber,lineItemId,vendorNote, token) {
        try {
            
            var body = {
                "noteText":vendorNote               
                }   
                
            const response = await axios.post(`${configuration.serviceURL}v1/Orders/${poNumber}/LineItems/${lineItemId}/Notes`, body, getHeaders(token));
    
        } catch (e) {
            throw e.data.error;
        }
    }
    
    export async function authenticateOnBehalfOfUser(vendorId, token) {
        try {
            const payload = jwtDecode(token);

            // Prepare request data
            const config = {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                    'x-app-key': payload.appKey
                }
            };

            const body = {
                'id': vendorId
            };

            // Get response
            const response = await axios.post(`${configuration.serviceURL}v1/Token/obo`, body, config);

            // Return token
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function fetchStores(userId, pageNumber, pageSize, searchCriteria, adminAccess, token,adminUserId = ""){                          
        // Get Store data
        const response =searchCriteria==''?await axios.get(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}/stores/search?adminAccess=${adminAccess}&offset=${pageNumber}&limit=${pageSize}&adminUserId=${adminUserId}`,getHeaders(token)):
            await axios.get(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}/stores/search?adminAccess=${adminAccess}&q=${encodeURIComponent(searchCriteria.trim())}&offset=${pageNumber}&limit=${pageSize}&adminUserId=${adminUserId}`,getHeaders(token));                        
        
        return {
            vendors: response.data.items,
            vendorCount:response.data.itemsCount
        }                     
    }

    export async function getStoreAccessTokenForPersonaUser(userId,storeId,token) {        
        try { 
            
            // Get response
            const response = await axios.post(`${configuration.serviceURL}v1/app/apc/users/${userId}/vendors/${storeId}/token`, null,getHeaders(token));

            // Return token
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

export async function fetchUsers(userId, pageNumber, pageSize, searchCriteria, token, includeSearchFields) {
    
    try {
        let response;

        // Fetch all linked users.
        if (!searchCriteria) {            
            response = await axios.get(
                `${configuration.serviceURL}v1/app/apc/persona/users/${userId}/LinkedUsers?`
                +`offset=${pageNumber}&limit=${pageSize}`, 
                getHeaders(token)
            );
        }
        // Search for linked users.
        else {
            // Prepare optional query parameters.
            let includeSearchFieldsParameter = '';
            if (!!includeSearchFields)
                includeSearchFieldsParameter = `&includeSearchFields=${includeSearchFields}`;
                        
            response = await axios.get(
                `${configuration.serviceURL}v1/app/apc/persona/users/${userId}/LinkedUsers/Search?`
                +`q=${encodeURIComponent(searchCriteria.trim())}&offset=${pageNumber}&limit=${pageSize}${includeSearchFieldsParameter}`, 
                getHeaders(token)
            );
        }
                
        return {
            users: response.data.items,
            userCount:response.data.itemsCount
        }
    }
    catch (e) {
        throw getFriendlyErrorObject(e); 
    }
}

export async function createNewUser(userId, user, token){
    try {        
        const response = await axios.post(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}/LinkedUsers`, 
            user, 
            getHeaders(token)
        );        
        return response.data;
    }
    catch (e) {        
        throw e.response.data; 
    }
}

export async function fetchPermissions(userId, storeId, token) {       
    try {           
        const response =await axios.get(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}/stores/${storeId}/Permissions`, getHeaders(token));
        
        return response.data;
    }
    catch (e) {        
        throw getFriendlyErrorObject(e); 
    }
}

export async function createPermissions(userId, permissions, storeId, token) {
    try {        
        
        const response = await axios.post(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}/stores/${storeId}/Permissions`, 
            permissions, 
            getHeaders(token)
        );
        //force this to transpile
        return response.data;
    }
    catch (e) {
        throw getFriendlyErrorObject(e); 
    }
}

    export async function fetchUserInformation(userId, token){
       
        try {
         
            const response =await axios.get(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}`,getHeaders(token));
             
         
            return response.data;
        }
        catch (e) {
            throw getFriendlyErrorObject(e); 
        }
    }

    export async function RemoveUserPermissionStoreAccess(userId,storeId, token){       
        try {
            
            const response =await axios.delete(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}/stores/${storeId}/Permissions`,getHeaders(token));             
         
            return response.data;
        }
        catch (e) {
            throw getFriendlyErrorObject(e); 
        }
    }


    export async function RemoveUserPermissionAllAccess(userId, adminUserId, token){
       
        try {            
         
            const response =await axios.delete(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}/stores/Permissions?adminUserId=${adminUserId}`,getHeaders(token));
             
         
            return response.data;
        }
        catch (e) {
            throw getFriendlyErrorObject(e); 
        }
    }
    
    export async function sendUserInvitationLink(userId,adminUserId,emailAddress,token){
       
        try {

            const body = {
                'email': emailAddress
            };

            const response =await axios.post(`${configuration.serviceURL}v1/users/${userId}/SendInvitationLink`,body,getHeaders(token));
             
         
            return response.data;
        }
        catch (e) {
            throw getFriendlyErrorObject(e); 
        }
    }

    export async function fetchStoreUsers(userId,storeId,token,pageNumber,pageSize,searchCriteria,includeSearchFields){
        try {
            let response;
            if (!searchCriteria) {            
                response = await axios.get(`${configuration.serviceURL}v1/app/apc/persona/users/`
                +`${userId}/stores/${storeId}/LinkedUsers/search?offset=${pageNumber}&limit=${pageSize}`, 
                    getHeaders(token)
                );
            }
            // Search for linked users.
            else {
                // Prepare optional query parameters.
                let includeSearchFieldsParameter = '';

                if (!!includeSearchFields)
                    includeSearchFieldsParameter = `&includeSearchFields=${includeSearchFields}`;
                            
                response = await axios.get(`${configuration.serviceURL}v1/app/apc/persona/users/`
                +`${userId}/stores/${storeId}/LinkedUsers/search?offset=${pageNumber}&limit=${pageSize}&q=${encodeURIComponent(searchCriteria.trim())}${includeSearchFieldsParameter}`, 
                    getHeaders(token)
                );
            }

             return {
                users: response.data.items,
                userCount:response.data.itemsCount
        }
        }
        catch (e) {
            throw getFriendlyErrorObject(e); 
         }    
     
    } 

    
    export async function fetchStoreInformation(storeId,token){
      
        try {
         
            const response =await axios.get(`${configuration.serviceURL}v1/app/apc/persona/stores/${storeId}`,getHeaders(token));
             
            return response.data;
        }
        catch (e) {
            throw getFriendlyErrorObject(e); 
         }
      
    } 

    export async function getUserTermsAndConditionText(token,language) {
        try {

            const response = await axios.get(`${configuration.serviceURL}v1/app/apc/persona/users/termsAndConditions?language=${language}`,getHeaders(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function getUserTermsAcceptanceStatus(userId, token) {
        try {
            
            const response = await axios.get(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}?fields=latestUserTermsAndConditionsAccepted`,getHeaders(token));

            return response.data[0];

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function updateUserAcceptStatus(userId,language,tcInitials, token) {
        try {

            const patch = [{
                "op": "replace",
                "path": "/latestUserTermsAndConditionsAcceptedBy",
                "value": tcInitials
            }];
            

            const response = await axios.patch(`${configuration.serviceURL}v1/app/apc/persona/users/${userId}?language=${language}`,patch, getHeadersForPatch(token));

            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function DeleteUser(adminUserId,deletedUser, token){
       
        try {
         
            const response =await axios.delete(`${configuration.serviceURL}v1/app/apc/persona/users/${deletedUser}?adminUserId=${adminUserId}`,getHeaders(token));
             
            return response.data;
        }
        catch (e) {
            
            throw e.response.data; 
        }
    }

    export async function sendOrderToCustomer(poNumber, requestBody, token) {
        try {
            const response = await axios.post(`${configuration.serviceURL}v1/orders/${poNumber}/sendOrder`, requestBody, getHeaders(token));

            return response.data;
        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }
    
    export async function fetchFleetAdminContactInfoByVehicleId(vehicleId, token) {
        try {
            // Get Fleet Admin Contact Info by Vehicle Id
            const response = await axios.get(`${configuration.serviceURL}v1/vehicles/${vehicleId}/FleetAdmin`, getHeaders(token));
            // Return data
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }


    export async function fetchPartnerConnectServerSettings() {
        try {
            
            const response = await axios.get(`/settings.json`,
            {
              // query URL without using browser cache
              headers: {
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
              },
            });
            
            return response.data;

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function checkHolmanURL(id) {        
        try {

             await axios.get(`${configuration.holmanPartnerConnectRedirectUrl}`, { timeout: 10000 });
     
        } catch (error) {
            if (error.response && error.response.data && error.response.status && error.response.headers) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                newrelic.addPageAction('HolmanPartnerConnectUrlAjaxCallFailed',{URL:configuration.holmanPartnerConnectRedirectUrl,Id:id, ErrorResponse:error.response.data + " " + error.response.status + " " + error.response.headers});
              } else {
                // Something happened in setting up the request that triggered an Error
                newrelic.addPageAction('HolmanPartnerConnectUrlAjaxCallFailed',{URL:configuration.holmanPartnerConnectRedirectUrl,Id:id, ErrorResponse:error.message});

              }
 
        }
    }

    export async function logUserSignedIn(id) {        
      try {                   
        newrelic.addPageAction('UserSignedIn', {
          Id:id,
          version: configuration.version
        })          
      }
      catch {
      }
    }

    export async function logHolmanRedirectChoice(buttonPressed) {        
      try {           
        newrelic.addPageAction('HolmanRedirectButtonClicked',{buttonPressed})          
      }
      catch {
      }
    }

    export async function validateLineItemPrice(orderId, lineItem, token){
        try {
            const body = {
                productCode: lineItem.productCode,
                vendorProductCode: lineItem.vendorProductCode,
                quantity: lineItem.partQty ? Number(lineItem.partQty) : 0,
                unitPrice: lineItem.partCost ? Number(lineItem.partCost) : 0,
                totalAmount: Number(lineItem.total),
                complaintCode: lineItem.complaintCode,
                lineItemType: lineItem.lineItemType,
                correctionType: lineItem.correctionType
            }

            const response = await axios.post(`${configuration.serviceURL}v1/orders/${orderId}/ValidateLineItem`, body, getHeaders(token));

            let returnResponse = response.data;
            returnResponse.AtaCode = lineItem.productCode;

            return returnResponse

        } catch (e) {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function logIEPopupDisplayed(vendorId) {        
        try {          
            newrelic.addPageAction('ieUsagePopoverDisplayed', {Id:vendorId})          
        }
        catch {
        }
      }
      
      export async function logIEPopupSignoutSelected(vendorId) {        
        try {
            newrelic.addPageAction('ieUsagePopoverClickedSignOutButton', {Id:vendorId})          
        }
        catch {
        }
      }
    
    export async function uploadRepairEstimateImage(fileName, submissionNumber, file, repairEstimateId, token){
        try {
            const formData = new FormData();
            formData.append('FileName', fileName);
            formData.append('SubmissionNumber', submissionNumber);
            formData.append('SubmittedFile', file);

            const response = await axios.post(`${configuration.AccmanApiUrl}api/v1/RepairEstimates/${repairEstimateId}/Image`, formData, getHeadersForMultipartPost("bearer " + token));

            return response.data.id;
        }
        catch (e)
        {
            throw getFriendlyErrorObject(e);
        }
    }

    export async function uploadRepairEstimateDocument(fileName, submissionNumber, file, repairEstimateId, token){
        try {
            const formData = new FormData();
            formData.append('FileName', fileName);
            formData.append('SubmissionNumber', submissionNumber);
            formData.append('SubmittedFile', file);

            const response = await axios.post(`${configuration.AccmanApiUrl}api/v1/RepairEstimates/${repairEstimateId}/EstimateDocument`, formData, getHeadersForMultipartPost("bearer " + token));

            return response.data.id;
        }
        catch (e)
        {
            throw getFriendlyErrorObject(e);
        }
    }
