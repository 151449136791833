import * as constants from 'constants/App';


export function hideVehicleSearchErrorMessage() {
    return {type: constants.HIDE_VEHICLE_SEARCH_ERROR_MESSAGE}
}


export function addRequestInfoPO(requestPOInfo) {
    return { type: "VALIDATION_SEARCH_PO_CREATION", requestPOInfo }
}