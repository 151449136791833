// libraries
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,FormattedNumber } from 'react-intl';
// our stuff
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import Title from '../components/Title';
import Layout from '../components/Layout';
import FormWrapper from 'components/FormWrapper';
import VehicleInformation from 'components/VehicleInformation';
import Complaints from 'AddEditView/Complaints';
import POTotals  from 'PO/AddEditView/components/POTotals';
import Footer from '../components/Footer';
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import AdjustmentContainerSelector from '../selectors/AdjustmentContainer';
import Shared from 'selectors/shared';

const emptyMap = Map();

class Adjustment extends React.Component {
    componentDidMount() {
        this.props.onLoading();
        document.addEventListener('click', this.props.onDocumentClick, true);
    }

    componentWillUnmount() {
        this.props.onUnloading();
        document.removeEventListener('click', this.props.onDocumentClick, true);
    }    

    onDeleteLineItemClick(lineItemKey) {
        this.setState({lineItemKey});
        this.deleteLineConfirmWindow.showModal();
    }    

    render() {
        if (this.props.isBusy) {
            return <Spinner />;
        }

        const messages = defineMessages({                        
            add_edit_view_confirmation_title: {
                id: 'add_edit_view_confirmation_title',
                description: 'Edit PO',
                defaultMessage: 'Edit PO'
            },
            add_edit_view_confirmation_message: {
                id: 'add_edit_view_confirmation_message',
                description: 'If you are attempting to edit item quantity or price, please delete both parts and labor and resubmit the new values. Are you sure you want to delete this item?',
                defaultMessage: 'If you are attempting to edit item quantity or price, please delete both parts and labor and resubmit the new values. Are you sure you want to delete this item?'
            },
            add_edit_view_po_confirmation_no: {
                id: 'add_edit_view_po_confirmation_no',
                description: 'No',
                defaultMessage: 'No'
            },
            add_edit_view_confirmation_yes: {
                id: 'add_edit_view_confirmation_yes',
                description: 'Yes',
                defaultMessage: 'Yes'
            }
        });
        return <div>

            <Title poNumber={this.props.poNumber}/>
            
            <Layout>
                <VehicleInformation vehicleDetails={this.props.vehicleDetails} actionType={this.props.actionType}/>
            </Layout>

            <FormWrapper key='errorMessage' id="errorMessage" 
                {...this.props}
                formErrors={this.props.errorMessage} 
            />

            <Complaints 
                complaints={this.props.complaints}
                notes={this.props.notes}
                notesHistory={this.props.notesHistory}
                complaintTypes={this.props.complaintTypes}                
                canAddAnotherComplaint={false}
                lineItems={this.props.lineItems}
                showErrorMessages={false}
                actionType={this.props.actionType}
                isInitialAddMode={false}
                onEditLineItemClick={this.props.onEditLineItemClick}
                onUpdateLineItemClick={this.props.onUpdateLineItemClick}
                onCancelUpdateLineItemClick={this.props.onCancelUpdateLineItemClick}
                hideCostInformation={false}
                lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                disableMaintenanceComplaintType={false}
                onComplaintNotesSave={this.props.onComplaintNotesSave}
                onDeleteLineItemClick={this.onDeleteLineItemClick.bind(this)}
                selectedCurrency={this.props.selectedCurrency}
                listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
            />
                
            <POTotals
                approvedAmountTotal={this.props.approvedAmountTotal}
                pendingAmountTotal={this.props.pendingAmountTotal}
                actionType={this.props.actionType}
                taxAmount={this.props.taxAmount}
                selectedCurrency={this.props.selectedCurrency}
            />            

            <Footer poNumber={this.props.poNumber} onClosePOClick={this.props.onClosePOClick}/>

            <ConfirmMessageBox 
                ref={r=>this.deleteLineConfirmWindow=r}
                title={messages.add_edit_view_confirmation_title}
                message={messages.add_edit_view_confirmation_message}
                okButtonText={messages.add_edit_view_confirmation_yes}
                cancelButtonText={messages.add_edit_view_po_confirmation_no}
                onOKClickEvent={()=>this.props.onDeleteLineItemClick(this.state.lineItemKey)}
                onCancelClickEvent={null}
            />

        </div>
    }
}

const mapStateToProps = (state, props) => {
    return {
        isBusy: state.appState.getIn(['serverData', 'adjustment', 'isFetchingData']),
        actionType: 'ADJUSTMENT'.toUpperCase(),
        poNumber: state.appState.getIn(['serverData', 'adjustment', 'purchaseOrder', 'id']),
        vehicleDetails: state.appState.getIn(['serverData', 'adjustment', 'vehicleDetails']) || emptyMap,        
        complaints: state.appState.getIn(['serverData', 'adjustment', 'complaints']) || emptyMap,
        notes: state.appState.getIn(['serverData', 'adjustment', 'notes']) || emptyMap,
        notesHistory: state.appState.getIn(['serverData', 'adjustment', 'notesHistory']) || emptyMap,
        complaintTypes: state.appState.getIn(['serverData', 'shared', 'complaintTypes']) || emptyMap,
        lineItems: state.appState.getIn(['serverData', 'adjustment', 'lineItems']) || emptyMap,
        lineItemIdBeingEdited: state.appState.getIn(['serverData', 'adjustment', 'lineItemIdBeingEdited']),
        taxAmount: state.appState.getIn(['serverData', 'adjustment', 'purchaseOrder', 'taxAmount']) || 0,
        errorMessage: state.appState.getIn(['serverData', 'adjustment', 'errorMessage']) || 0,
        selectedCurrency:state.appState.getIn(['uiData', 'shared','selectedCurrency']),
        ...Shared(state, props),
        ...AdjustmentContainerSelector(state, props)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoading: () => {            
            let poNumber = props.routeParams.match.params.poNumber;
            dispatch({type: constants.FETCH_ADJUSTMENT_DATA, poNumber});
        },
        onUnloading: () => {

        },
        onEditLineItemClick: (lineItemKey) => {
            dispatch({type: constants.ADJUSTMENT_EDIT_LINE_ITEM, lineItemKey});
        },
        onUpdateLineItemClick: (lineItem) => {
            let lineItemKey = lineItem.get('lineItemKey');
            dispatch({type: constants.ADJUSTMENT_UPDATE_LINE_ITEM_CLICKED, lineItemKey, lineItem});
        },
        onCancelUpdateLineItemClick: (lineItemKey) => {
            dispatch({type: constants.ADJUSTMENT_CANCEL_UPDATE_LINE_ITEM, lineItemKey});
        },
        onComplaintNotesSave: (complaintKey, notes) => {
            dispatch({type: constants.ADJUSTMENT_SAVE_COMPLAINT_NOTES_CLICKED, complaintKey, notes});
        },
        onClosePOClick: () => {
            dispatch({type: constants.ADJUSTMENT_CLOSE_PO});
        },
        onDeleteLineItemClick: (lineItemKey) => {
            dispatch({type: constants.ADJUSTMENT_DELETE_LINE_ITEM, lineItemKey});
        },
        onDocumentClick: (event)=> {
            dispatch({type: constants.ADJUSTMENT_DOCUMENT_CLICK});
        }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Adjustment);