/*
This file has the code that pertains to field validations. The field validations are driven through data.

The app state has field validations that apply by default.

For example:

fieldValidations: Map({
                   REFERENCE_NO:  Map({           
                        isRequired : false,
                        minAllowedLength : null,
                        maxAllowedLength : null,
                        isVisible : true,
                        type: 'string'
                   }),
                   YOUR_NAME:  Map({           
                        isRequired : true,
                        minAllowedLength : null,
                        maxAllowedLength : null,
                        isVisible : true,
                        type: 'string'
                   })
			....


Depending on the "features" enabled for a given vendor, we will receive validaton data from service and update the app state.


*/

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { falseIfUndefined, nullIfUndefined } from './HelperFunctions';
import moment from 'moment';

export function isVisible(fieldValidations, fieldName) {
    return falseIfUndefined(fieldValidations.getIn([fieldName, 'isVisible']));
}

export function isRequired(fieldValidations, fieldName) {
    return falseIfUndefined(fieldValidations.getIn([fieldName, 'isRequired']));
}

export function getValidationErrorMessage(fieldValidations, fieldName, fieldData) {
    
    // is visible?
    if (!falseIfUndefined(fieldValidations.getIn([fieldName, 'isVisible'])))
        return null;

    // is required?
    if (!falseIfUndefined(fieldValidations.getIn([fieldName, 'isRequired'])))
        return null;
    


    if (fieldValidations.getIn([fieldName, 'type']) === 'mustbetrue')
    {
        if (fieldData !== true)
            return <FormattedMessage id={`${fieldName}_mandatory`} description='Unavailable' defaultMessage='Unavailable' />;
        else
            return null;
    }
    else if (fieldValidations.getIn([fieldName, 'type']) === 'number')
    {
        if (fieldData <= 0)
            return <FormattedMessage id={`${fieldName}_mandatory`} description='Unavailable' defaultMessage='Unavailable' />;
        else
            return null;
    }
    else if (fieldValidations.getIn([fieldName, 'type']) === 'date')
    {
        if (!(moment(fieldData).isValid()))
            return <FormattedMessage id={`${fieldName}_mandatory`} description='Unavailable' defaultMessage='Unavailable' />;
        else
            return null;    
    }
    else if (fieldValidations.getIn([fieldName, 'type']) === 'string') {
        
        // get min and max length
        const minLength = nullIfUndefined(fieldValidations.getIn([fieldName, 'minAllowedLength']))
        const maxLength = nullIfUndefined(fieldValidations.getIn([fieldName, 'maxAllowedLength']))

        if (minLength === null && maxLength === null) {
            if (fieldData.trim().length === 0)
                return <FormattedMessage id={`${fieldName}_mandatory`} description='Unavailable' defaultMessage='Unavailable' />;
            else
                return null;
        }

        if (minLength === null && maxLength !== null) {
            if (fieldData.trim().length > maxLength)
                return <FormattedMessage id={`${fieldName}_max_length`} description='Unavailable' defaultMessage='Unavailable' values={{ maxLength: maxLength }} />;
            else
                return null;
        }

        if (minLength !== null && maxLength === null) {
            if (fieldData.trim().length < minLength)
                return <FormattedMessage id={`${fieldName}_min_length`} description='Unavailable' defaultMessage='Unavailable' values={{ minLength: minLength }} />;
            else
                return null;
        }

        if (fieldData.trim().length < minLength || fieldData.trim().length > maxLength) {
            if (minLength != maxLength)
                return <FormattedMessage id={`${fieldName}_min_max_length`} description='Unavailable' defaultMessage='Unavailable' values={{ maxLength: maxLength, minLength: minLength }} />;
            else
                return <FormattedMessage id={`${fieldName}_exact_length`} description='Unavailable' defaultMessage='Unavailable' values={{ exactLength: minLength }} />;
        }
        else
            return null;
    }
    else
        throw `Type ${fieldValidations.getIn([fieldName, 'type'])} not identified`;
}