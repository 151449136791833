import React from 'react';
import classnames from "classnames";
import './Recaptcha.css';


class Recaptcha extends React.Component {


    componentDidMount() {

       const sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds))
            }
            sleep(1000).then(() => {

                if (typeof window !== 'undefined'
                    && typeof window.grecaptcha !== 'undefined'
                    && typeof window.grecaptcha.render === 'function')
                    return grecaptcha.render(document.getElementById('recaptcha'));
            })

    }



    render() {
        return <div className={classnames(this.props.reCaptchaVerificationToken === "" ?
            "g-recaptcha-inner-disabled" : 'g-recaptcha-inner')}>
            <div id="recaptcha" data-sitekey={this.props.reCaptchaKey}></div>
        </div>
    }

}


export default Recaptcha;