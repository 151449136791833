import React, { useEffect, useState } from 'react';
import  ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import Spinner from 'components/Spinner';
import moment from 'moment';
import {Modal, Button} from 'react-bootstrap';
import AddServiceLineItems from '../components/AddServiceLineItems';
import  * as addServiceLineItemsViewActions from '../actions/AddServiceLineItemsView';
import * as addNewLineItemsViewActions from '../../../screens/AddNewLineItemsView/actions/AddNewLineItemsView';
import AddServiceLineItemsViewSelector from '../selectors/AddServiceLineItemsView';
import * as constants from 'constants/App';
import * as sharedActions from '../../../../../../shared/actions/App';
import FormWrapper from 'components/FormWrapper';
import { falseIfUndefined } from 'utils/HelperFunctions';
import configuration from 'configuration';
import styled from 'styled-components';
import { ModalBody } from 'screens/App/shared/DesignSpecs/components/FormComponents';
import { useLineItemValidator } from '../../../hooks/useLineItemValidator';
import * as helperFunctions from 'utils/HelperFunctions';
import { useAppState } from 'screens/App/shared/AppState/app-state';


const StyledModal = styled(Modal).attrs({
    dialogClassName: "childStyle"
})`
    .childStyle {
      width: 900px;
      max-width: 900px;
}`;

const AddServiceLineItemsView = (props) => {
    const [ appState, dispatch ] = useAppState();

    const {validateLineItemAsync, priceValidations, pricesConfirmedList,
        handlePriceValdationConfirmationButtonClick, priceValidationCleanUp, priceValidationErrorMessage} = useLineItemValidator(props.token, props.serviceATAList.size);

    const downLoadPMInformation = (e) => {
        let pmUrl = `${configuration.serviceURL}v1/Vehicles/${props.vehicleDetails.get('id')}/pmInformation?reportType=Pdf&token=${props.token}`;

        props.dispatch(sharedActions.downLoadRemoteFile('pmInformation', pmUrl));
    }

    useEffect(() => {
        props.dispatch({type: constants.ATA_PM_PARAMETERS_SCREEN_LOADED,productCode: props.serviceATAList});   
    }, [])
    
    /*Gets ATA code description for a given ATA code
     * Takes
     *  ataCode: ATA code
     * Returns string of ata code description*/
    const getATACodeDescription = (ataCode) => {

        return props.ataCodes.getIn([ataCode,'description']);

    }

    /*Gets the complaint code for a given ATA code
     * Takes
     *  ataCode: ATA code
     * Returns string of complaint code*/
    const getATAComplaintCode = (ataCode) => {
        /*Return MA99 because this is a PM PO*/
        return 'MA99';
    }

    const getServiceAtaCodesFromState = () => {
        const dueServiceDetails = props.serviceATAList;
        const arrayOfATACodes = dueServiceDetails.keySeq().toArray();
        const ataResults = [];
        for (var i = 0; i < arrayOfATACodes.length; i++) {

            let ataCode = arrayOfATACodes[i];
            let partCost = helperFunctions.zeroIfUndefined(dueServiceDetails.getIn([ataCode, 'partCost']));
            let laborRate = helperFunctions.zeroIfUndefined(dueServiceDetails.getIn([ataCode, 'laborRate']));
            let total = helperFunctions.zeroIfUndefined(dueServiceDetails.getIn([ataCode, 'total']));
            if (!isNaN(total) && Number(total) >= 0) {
                ataResults.push({
                    productCode: ataCode,
                    vendorProductCode: "",
                    partQty: 1,
                    partCost: partCost,
                    total: total,
                    complaintCode: "MA99",
                    lineItemType: constants.LINE_ITEM_TYPE.PM,
                    correctionType: "PM",
                    poNumber: props.poNumber
                })
            }
        }
        return ataResults;
    }

    const onSaveServiceLineItems = () => {
        const serviceList = getServiceAtaCodesFromState();
        const addServiceLines = async () => {            
            var result
            if(appState.isPriceControlFeatureEnabled){
                result = await validateLineItemAsync(serviceList);
            }
            else{
                result = true;
            }
            if(result){
                props.dispatch({ type: constants.ADD_SERVICE_LINE_ITEM_CLICKED, priceValidations, priceValidationCleanUp });
            }
        }
        addServiceLines();
    }

    /* Saves the populated ATA codes, clears the temporarily saved ATA codes and shows PO line item modal*/
    const onSaveAndAddServiceLineItems = () => {
       
        onSaveServiceLineItems();
        props.dispatch(addNewLineItemsViewActions.addComplaintCode('MA99'));
        props.dispatch(addNewLineItemsViewActions.LoadFrequentlyUsedATACode(displayName, props.vehicleDetails.get('id'), 'MA99'));

        props.dispatch(addNewLineItemsViewActions.toggleShowWindow());


    }

    /* Handles key change event for cost and labor fields on Modal AddServiceLineItems
     * Takes
     *   inputType: either PART or LABOR
     *   ataCode: unique key for the field which will be ATA code
     *   e: event arguments*/
    const onHandleChangeForAddServiceLineItems = (inputType, ataCode, e,modelValue,viewValue) => {

        //compose line item (or the ATA code)
        const serviceLineItems = Map({
            lineItemValue: viewValue,
            lineItemKey: ataCode,
            lineItemType: inputType,
        });
        //remove or add line item (part or labor) depending on the field value
        props.dispatch(addServiceLineItemsViewActions.addServiceLineItem(serviceLineItems));
    }

    /* Close (or hide) the modal and remove the temporarily saved service ata codes*/
    const closeModalAddServiceLineItemsWithoutSave = () => {
        clearCosts();
        props.dispatch(addServiceLineItemsViewActions.toggleShowWindow());
        props.onClearServices();
    }

    /* Removes temporarily saved ata codes with part and labor cost*/
    const clearCosts = () => {
            props.dispatch(addServiceLineItemsViewActions.removeServiceATACode(Map()));
    }

    return (
            <StyledModal show={props.showAddServiceLineItemsWindow}
                   backdrop="static"
                   onHide={()=>props.dispatch(addServiceLineItemsViewActions.toggleShowWindow())}>
                <ModalBody style={{padding: "10px 40px 40px 40px"}}>
                <div className="row add-service-line-items__close-without-save" style={{marginLeft:'0', marginBottom:'0.5rem'}}>
                    <div onClick={closeModalAddServiceLineItemsWithoutSave.bind(this)}>
                        <img className="add-service-line-items__close-button"
                            src="/assets/VMS_33button_kill_blue.png" />
                        {<FormattedMessage
                            id='close_without_save'
                            description='Close without saving'
                            defaultMessage='Close without saving' />}
                    </div>
                </div>
                    <FormWrapper key='addServiceLineItemsErrorMessage' 
                         id="addServiceLineItemsErrorMessage" {...props} 
                         formErrors={props.addServiceLineItemsErrorMessage} />
                    <FormWrapper key='priceValidationErrorMessage'
                        id='priceValidationErrorMessage' {...props}
                        formErrors={priceValidationErrorMessage} />
                    
                    <AddServiceLineItems
                        serviceATACodes={props.serviceATAList}
                        saveServiceLineItems={onSaveServiceLineItems.bind(this)}
                        saveAndAddServiceLineItems={onSaveAndAddServiceLineItems.bind(this)}
                        parameterList={props.parameterList}
                        parentProps = {props}
                        handleChange={onHandleChangeForAddServiceLineItems.bind(this)}
                        ataAndReferencePmParametersDescription={props.referencePmParametersDescription}
                        referenceRulePmParametersDescription={props.referenceRulePmParametersDescription}
                        isFetchingData={props.isFetchingData}
                        downLoadPMInformation={downLoadPMInformation.bind(this)}
                        priceValidations={priceValidations}
                        pricesConfirmedList={pricesConfirmedList}
                        handlePriceValdationConfirmationButtonClick={handlePriceValdationConfirmationButtonClick}
                    />
                </ModalBody>
            </StyledModal>
    );
}

function mapStateToProps(state, props) {
    return {
        ...AddServiceLineItemsViewSelector(state, props),      
        addServiceLineItemsErrorMessage: state.appState.getIn(['uiData','addServiceLineItemsView', 'errorMessage']),
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        isFetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'shared', 'componentsFetchingDataFromAPI', 'pmInformation']) == 'pmInformation'),
        poNumber: state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id'])
    };
}
export default connect(mapStateToProps)(AddServiceLineItemsView);
