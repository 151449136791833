import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage} from 'react-intl';
import * as constants from 'constants/App';
import './TLSLearnMore.css';
import { isAuthenticated } from 'utils/HelperFunctions';

class TLSLearnMore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        };
    }

    componentDidMount() {
        this.setState({ show: this.props.is_user_logged_in });
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== undefined && prevProps.is_user_logged_in !== this.props.is_user_logged_in) {
            this.setState({ show: this.props.is_user_logged_in });
        }
    }

    componentWillUnmount() {
        this.setState({ show: false });
    }

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    render() {
        const messages = defineMessages({
            tls_learn_more_title: {
                id: 'tls_learn_more_title',
                description: 'tls learn more title',
                defaultMessage: 'Outdated or unsupported browser detected'
            },
            tls_learn_more_message: {
                id: 'tls_learn_more_message',
                description: 'tls learn more message',
                defaultMessage: '<p>This website does not allow older browsers to be used for security purposes. To access this website, you will need to upgrade to the latest version of Internet Explorer (IE), Chrome, Safari or Firefox.<br />Information for your IT Help desk:TLS 1.0 and TLS 1.1. protocols are disabled on this website in order to meet best practice security standards for safeguarding data.<br />You will need to install a web browser that is compatible with the TLS 1.2 protocol. The following browser versions support TLS 1.2:<ul><li>Google Chrome version 30 or higher</li><li>Mozilla Firefox version 27 or higher</li><li>Microsoft Internet Explorer 11 or higher</li><li>Microsoft Edge</li><li>Apple Safari 7 or higher</li></ul><br />If you have any questions, please contact Holman at <a href="mailto:partnerconnect@holman.com">partnerconnect@holman.com</a>.</p>'
            },
            return_button: {
                id: 'return_button',
                description: 'return_button',
                defaultMessage: 'Return'
            }
        });

        return <React.Fragment>
            {this.state.show &&
                (<div className="tls_learn_more container-fluid">
                    <div className="tls_learn_more-header row">
                        <FormattedMessage {...messages.tls_learn_more_title} />
                    </div>
                    <div className="tls_learn_more-body row">
                        <div className="container-fluid">
                            <div className="tls_learn_more_message row">
                 <FormattedMessage {...messages.tls_learn_more_message} values={{ br: data => <br/>, p: data=> <p>{data}</p>, ul: data=> <ul></ul>, li: data=> <li></li>, a: data=> <a href='mailto:partnerconnect@holman.com'>{data}</a>,b: data => <b>{data}</b>}}/>
                            </div>
                        </div>
                    </div>
                    <div className="tls_learn_more-footer row">
                    </div>
                </div>)
            }
        </React.Fragment>;
    }
}

function select(state) {
    return {
        is_user_logged_in: isAuthenticated(state)
    };
}

export default connect(select)(TLSLearnMore);