import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import VendorMinorityMap from 'reducers/VendorServices';


export function* changePasswordClicked(action) {

    try {
        const state = yield select();
        yield put({ type: 'RESET_ERROR_MESSAGES' });
        // Inform application that Password is being changed
        yield put({ type: 'CHANGE_PASSWORD_REQUESTED' });

        yield call(Api.changePassword, state, helperFunctions.getToken(state), action.password, action.newPassword);
        yield put({ type: 'CHANGE_PASSWORD_RESOLVED' });

    }
    catch (e) {

        if (e.code == 'API-045' || e.code == 'API-030' || e.code == 'API-142') {
            yield put({
                type: 'CHANGE_PASSWORD_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
            yield put({
                type: 'SHOW_CURRENT_PWD_ERROR_MESSAGE',
                flag: true
            });

        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });
        //yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CREATE_PO_REQUESTED, errorObject: e });
    }
}

export function* changeVendorInformationClicked(action) {

    try {
        const state = yield select();
        
        yield put({ type: constants.VENDOR_INFORMATION_SUBMIT_DATA_REQUESTED });        

        const vendorAccountType=state.appState.getIn(['serverData', 'shared', 'vendorAccountType']);
        const corporateVendorId= helperFunctions.emptyStringIfNull(state.appState.getIn(['serverData', 'shared', 'corporateId']))
        const currentVendorId= helperFunctions.emptyStringIfNull(state.appState.getIn(['serverData', 'shared', 'vendorId']))

        const isLinkedVendor = (vendorAccountType == 'Sub') ||
            (corporateVendorId != '' && currentVendorId != corporateVendorId);
        const isSSOUser=state.appState.getIn(['serverData', 'shared', 'isSSOUser'])==undefined?false:state.appState.getIn(['serverData', 'shared', 'isSSOUser']);
        const selectedCountry=state.appState.getIn(['uiData', 'shared', 'selectedCountry']);
   
        //TFS : 70124
        //If Linked vendor, then update both Payment Contact and email
        //If not, then update only contact name; because the payment email address update will be handled in AccountSettings section 
        if(isLinkedVendor || isSSOUser || selectedCountry=='MEX' )
        {
            yield call(Api.sendVendorContactInformationEmail, state, helperFunctions.getToken(state), action.vendorName.toUpperCase(), action.vendorEmailRecipient.toUpperCase());
        }
        else{
            yield call(Api.saveVendorPaymentContactName,state, helperFunctions.getToken(state), action.vendorName.toUpperCase());            
        }
        yield call(Api.sendVendorContactInformation, state, helperFunctions.getToken(state), action.vendorBusinessNumber, action.vendorExtension, action.vendorFaxNumber, action.vendorStreetAddress1, action.vendorCityAddress, action.vendorStateAddress, action.vendorZipAddress,action.vendorCountry);
        yield put({ type: constants.CLEAR_CONTACT_SETTINGS });      
        yield put({ type: constants.VENDOR_INFORMATION_SUBMIT_DATA_RESOLVED });
        yield fetchVendorInformation();
    }
    catch (e) {
        yield put({ type: constants.CHANGE_VENDOR_INFO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage:helperFunctions.getGenericErrorObject(e)});

    }
}

export function* fetchVendorInformation() {

    try {

        const state = yield select();
        yield put({ type: constants.VENDOR_INFORMATION_REQUESTED });
        yield put({ type: constants.CLEAR_CONTACT_SETTINGS });


        const vendorInformation = yield call(Api.fetchVendorContactInformation, helperFunctions.getToken(state), state);
        const vendorContactEmail = yield call(Api.fetchVendorContactEmail, helperFunctions.getToken(state), state);
        yield put({ type: constants.SET_VENDOR_INFORMATION, vendorInformation });
        yield put({ type: constants.SET_VENDOR_INFORMATION_EMAIL_CONTACT, vendorContactEmail });
        const isRegistered = vendorInformation.consentToReceiveAuthorizationEmail;
        yield put({ type: constants.ACCOUNT_VIEW_UPDATE_EMAIL_CONFIG_REGISTRATION, isRegistered: isRegistered });
        yield put({ type: constants.VENDOR_INFORMATION_RESOLVED });

    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: 'VENDOR_INFORMATION_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* verifyVendorInformation(action) {

    try {

        const state = yield select();

        const verifyToken = yield call(Api.verifyVendorBankingInformation, state, helperFunctions.getToken(state), action.tax, action.taxType);

        yield put({ type: constants.SET_VENDOR_BANKING_VERIFY_TOKEN, verifyToken })
        
        if(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'isPaymentEmailEditModeValidation'])==true)
        yield put({ type: 'SHOW_ACCOUNT_PAYMENT_EMAIL_EDIT' });
        else
        yield put({ type: constants.SHOW_ACCOUNT_EDIT });

        

    }
    catch (e) {
        if (e.code == 'API-045') {
            yield put({
                type: 'VENDOR_INFORMATION_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
        }
        else {
            yield put({ type: 'VENDOR_INFORMATION_REJECTED', errorMessage: helperFunctions.getCustomErrorObject(e) });                                
        }
    }
}

export function* fetchVendorBankingAccountInformation() {

    try {

        const state = yield select();

        yield put({ type: constants.CLEAR_ACCOUNT_INFORMATION });


        const vendorBankingInformation = yield call(Api.fetchVendorBankingInformation, helperFunctions.getToken(state), state);

        yield put({ type: constants.SET_VENDOR_BANKING_INFORMATION, vendorBankingInformation });


    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: 'VENDOR_INFORMATION_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}
export function* fetchVendorBankingPaymentStatusInformation() {

    try {

        const state = yield select();

        yield put({ type: constants.CLEAR_ACCOUNT_INFORMATION });


        const vendorBankingInformation = yield call(Api.fetchVendorBankingPaymentStatusInformation, helperFunctions.getToken(state), state);

        yield put({ type: constants.SET_VENDOR_PAYMENT_STATUS, vendorBankingInformation });


    }
    catch (e) {

           

    }
}


export function* changeVendorPaymentEmailInformation(action){
    try {
        
        const state = yield select();

        yield call(Api.saveVendorPaymentEmail, state, helperFunctions.getToken(state), action.paymentEmail.toUpperCase());
        //Update payment email in state        
        yield put({ type: constants.VENDOR_PAYMENT_EMAIL_SUCCESS });

    }
    catch (e) {
        yield put({ type: constants.CHANGE_VENDOR_INFO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });
    }
}
export function* changeVendorBankingAccountInformation(action) {

    try {

        const state = yield select();

        yield call(Api.changeVendorBankingInformation, state, helperFunctions.getToken(state), action.vendorRoutingNumber, action.vendorChecking1);

        const vendorBankingInformation = [{
            id: state.appState.getIn(['serverData', 'shared', 'vendorId']) + "MAINTENANCE",
            type: "MAINTENANCE",
            accountNumber: action.vendorChecking1.substring(action.vendorChecking1.length - 4)

        }];

        yield put({ type: constants.SET_VENDOR_BANKING_INFORMATION, vendorBankingInformation });
        yield put({ type: 'VENDOR_BANKING_ACCOUNT_SUCCESS' });

    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: 'VENDOR_INFORMATION_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* fetchVendorServices() {

    try {

        const state = yield select();

        yield put({ type: constants.FETCH_VENDOR_MINORITY, VendorMinorityMap });

        const vendorServiceMap = yield call(Api.fetchVendorServiceInformation, helperFunctions.getToken(state), state);

        if (vendorServiceMap.vendorService != null)
            yield put({ type: constants.VENDOR_SERVICES_MOUNT, vendorServiceMap: vendorServiceMap.vendorService });

        if (vendorServiceMap.vendorMinority != null)
            yield put({ type: constants.VENDOR_MINORITY_UPDATE, vendorMinorityId: vendorServiceMap.vendorMinority });



    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: 'VENDOR_INFORMATION_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* changeVendorServicesClicked() {

    try {
        const state = yield select();
        let vendorServices = state.appState.getIn(['uiData', 'vendorAccount', 'vendorProfile', 'services']);
        let vendorMinority = state.appState.getIn(['uiData', 'vendorAccount', 'vendorProfile', 'minorityVendor']);

        yield call(Api.sendVendorServices, state, helperFunctions.getToken(state), vendorServices, vendorMinority);

        yield put({ type: constants.HIDE_VENDOR_SERVICES_EDIT });
        yield put({ type: constants.FETCH_VENDOR_SERVICES });
    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: 'CHANGE_VENDOR_INFO_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
            yield put({
                type: 'SHOW_CURRENT_PWD_ERROR_MESSAGE',
                flag: true
            });

        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* fetchVendorTaxInformation() {

    try {

        const state = yield select();



        const vendorTaxInfo = yield call(Api.fetchVendorTax, helperFunctions.getToken(state), state);


        yield put({ type: constants.UPDATE_VENDOR_TAX_INFORMATION, vendorTaxInfo });



    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: 'VENDOR_INFORMATION_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* vendorBusinessGrowthInformation() {

    try {

        const state = yield select();

        yield put({ type: constants.MY_BUSINESS_GROWTH_REQUESTED });

        const vendorGrowthInfo = yield call(Api.fetchVendorBusinessGrowthInformation, helperFunctions.getToken(state), state);

        yield put({ type: constants.MY_BUSINESS_GROWTH_RESOLVED, vendorGrowthInfo });



    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: constants.MY_BUSINESS_GROWTH_REJECTED,
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}


export function* accountViewSaveAuthEmailNotificationConfiguration(context) {
    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_REQUESTED });

        const state = yield select();

        const authEmailConfigData = state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig']);
        const vendorId = helperFunctions.getVendorId(state);
        const isRegitered = authEmailConfigData.get('isRegistered');
        //SAVE ISREGISTERED
        const isRegisteredData = [{
            "op": "replace",
            "path": "consentToReceiveAuthorizationEmail",
            "value": isRegitered
        }]
        yield call(Api.saveAuthEmailNotificationConfigurationIsRegistered, vendorId, isRegisteredData, helperFunctions.getToken(state));

        const contacts = state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'contacts']).toJSON();


        //SAVE CONTACTS
        for (var index = 0; index < contacts.length; index++) {
            var contact = contacts[index];
            //ADD
            if (contact.elementState == constants.ELEMENT_STATE.ADD) {
                const notificationEmailConfig = {
                    name: contact.name,
                    email: contact.email,
                    notifyApprovals: true,
                    notifyPayments: false
                }
                yield call(Api.saveAuthEmailNotificationConfigurationEmails, vendorId, notificationEmailConfig, helperFunctions.getToken(state));
            }
            //UPDATE
            if (contact.elementState == constants.ELEMENT_STATE.UPDATE) {
                const notificationEmailConfig = {
                    id: contact.id,
                    name: contact.name,
                    email: contact.email,
                    notifyApprovals: true,
                    notifyPayments: contact.notifyPayments
                }
                yield call(Api.updateAuthEmailNotificationConfigurationEmails, vendorId, notificationEmailConfig, helperFunctions.getToken(state));
            }
            //DELETE
            if (contact.elementState == constants.ELEMENT_STATE.DELETE) {
                yield call(Api.deleteAuthEmailNotificationConfigurationEmails, vendorId, contact.id, helperFunctions.getToken(state));
            }
        }
        yield put({ type: constants.ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.ACCOUNT_VIEW_SUBMIT_AUTH_EMAIL_CONFIG_INFO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });

    }
}

export function* fetchAuthEmailNotificationConfig(context) {
    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REQUESTED });

        const state = yield select();
        const vendorId = helperFunctions.getVendorId(state);

        let response = yield call(Api.fetchAuthEmailNotificationConfig, vendorId, helperFunctions.getToken(state));

        //show contacts with notifyApprovals == true
        if (response != undefined) {
            response = response.filter((item) => item.notifyApprovals)
        }

        yield put({ type: constants.ACCOUNT_VIEW_UPDATE_NOTIFICATION_CONTACT, updatedContacts: response });
        yield put({ type: constants.FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.FETCH_ACCOUNT_VIEW_AUTH_EMAIL_NOTIFICATION_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });

    }
}

export function* saveAndFetchEmailNotificationConfig(context) {
    yield call(() => accountViewSaveAuthEmailNotificationConfiguration(context));
    yield call(() => fetchAuthEmailNotificationConfig(context));
}
