import React from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage, IntlProvider } from 'react-intl';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import * as HelpFAQViewActions from '../actions/HelpFAQView';
import { HELP_FAQ_TITLE_STATUS, TOGGLE_HELP_FAQ_QUESTION } from 'constants/App';
import './HelpFAQView.css';
import PublicSelections from '../selectors/SelectedPublicQuestions'
import FAQList from '../components/FAQList';
import { SystemInformation } from '../components/SystemInformation';
import * as i18n from 'i18n';
import classnames from 'classnames';
import TranslationIcon from 'components/SiteTranslations';
import * as config from '../../../../shared/configuration';
import {withRouter} from 'react-router-dom'

const HelpFAQView = (props) => {

    const onReturnClickHandler = (e) => {
        e.preventDefault();
        window.history.back();
    }

    const messages = defineMessages({
        contact_footer_1: {
            id: 'contact_footer_1',
            description: 'Contact link description',
            defaultMessage: 'Click here'
        },
        contact_footer_2: {
            id: 'contact_footer_2',
            description: 'Contact link description',
            defaultMessage: ' to send us an email with your question and we will be sure to get back to you soon'
        },
        help_faq_return: {
            id: 'help_faq_return',
            description: 'Return button',
            defaultMessage: 'Return'
        },
        faq_title_help: {
            id: 'faq_title_help',
            description: 'title for Help FAQ page',
            defaultMessage: 'Help'
        },
        faq_caption_help: {
            id: 'faq_caption_help',
            description: 'Caption for Help FAQ page',
            defaultMessage: 'Frequently asked questions'
        },
        contact_footer_3: {
            id: 'contact_footer_3',
            description: 'help contact',
            defaultMessage: 'If you require alternate language assistance, please contact <br/>one of our call centers and we will be happy to assist you.' +
            '<br/>In the US  - 800-227-2273' +
            '<br/>In Canada - 800-363-7676'
        },
        old_supplier_redirect_caption: {
            id: 'old_supplier_redirect_caption',
            description: 'old_supplier_redirect_caption',
            defaultMessage: 'You can access the old supplier site by clicking here.'
        },
    });
    const { sections, questions, faqLocale, token, vendorCountry } = props;

    let questionsForCurrentLocale = questions.map((ques)=>{                                  
        return ques.filter(x=>{
            let restrictedLocale = x.get('restrictedLocale') == undefined? []:x.get('restrictedLocale');                
            let restrictedCountry=x.get('restrictedCountry')==undefined?[]:x.get('restrictedCountry');
            return !restrictedLocale.includes(faqLocale) && !restrictedCountry.includes(props.vendorCountry);
        })  
    }); 

    let theSections = sections.map((section, sectionTitle) => {
        return <FAQList
            sectionTitle={sectionTitle}
            title={section.get('title')}
            questions={questionsForCurrentLocale.get(sectionTitle)}
            isVisible={section.get('isVisible')}
            titleClickHandler={(sectionTitle, event) => props.dispatch(HelpFAQViewActions.toggleHelpTitleShowAll(sectionTitle))}
            questionClickHandler={(sectionTitle, sectionKeyQuestion, event) => props.dispatch(HelpFAQViewActions.toggleHelpQuestionShowAll(sectionTitle, sectionKeyQuestion))}
            isVideoSection={section.get('isVideoSection') === true}        
        />;
    }).valueSeq();

    var sortedSections = theSections.sortBy(
        (section, sectionTitle) => sectionTitle);

    return (
        <IntlProvider locale={faqLocale} messages={i18n[faqLocale.substring(0, 2)]}>
            <div>
                <div className="row help-container__help">
                    <div className='col-md-4 '>
                        {props.token === "" ?
                            <TranslationIcon backgroundColor='blue'/>
                            : undefined 
                        }
                    </div>
                    <div className='col-md-4'><h1 className="help-title-item__title"><FormattedMessage {...messages.faq_title_help} /></h1></div>
                    <div className='col-md-4'>
                        <SystemInformation />
                    </div>
                </div>
                <div className="help-container__spacing">
                    <h4 className="help-title-item__caption"><FormattedMessage {...messages.faq_caption_help} /></h4>
                </div>
                    {sortedSections}
                <div className="help-container__spacing">
                    <text className="contact-link__handle"><Link
                        to='/contact/FeedbackForm'><FormattedMessage  {...messages.contact_footer_1} /></Link><FormattedMessage  {...messages.contact_footer_2} />
                    </text>
                </div>
                <div className="help-container__spacing help-container__footer-help">
                    <FormattedMessage  {...messages.contact_footer_3}  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                </div>                    
                <div className="help-container__return"
                    role="button"
                    onClick={onReturnClickHandler}>
                    <img src="/assets/VMS_33button_back_blue.png" />
                    {' '}
                    <FormattedMessage {...messages.help_faq_return} />
                </div>

            </div>
        </IntlProvider>
    );

}
function select(state) {

    return {
        sections: state.appState.getIn(['serverData', 'helpFAQSections', 'sections']),
        faqLocale: state.appState.getIn(['uiData', 'shared', 'selectedLocale']),
        token: state.appState.getIn(['serverData', 'shared', 'token']) || "",
        vendorCountry: state.appState.getIn(["uiData", "shared", "selectedCountry"]),
        questions: PublicSelections(state)
    };
}

export default withRouter(connect(select)(HelpFAQView));