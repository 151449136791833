import React from 'react';
import AtaSearchComponent from './AtaSearchComponent';

class AtaSearchContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleSearchResults: false,
            filterAtaCodes: []
        };
        this.state.ataCodes = this.props.ataCodes;
    }

    //converts to the title case
    convertToTitleCase(list) {
        if (list == undefined || list.length <= 0) return;

        return list.map((str) => {
            if(str.description==undefined) return str;
             
            str.description = str.description.toLowerCase().replace(/[^-.'\s]+/g, function (word) {
                return word.replace(/^./, function (first) {
                    return first.toUpperCase();
                });
            });

            //example with LOF
            let ignore = [{ word: 'LOF', regex: 'LOF\\s' }];

            for (let i in ignore) {
                let re = new RegExp(ignore[i].regex, 'gi');
                str.description = str.description.replace(re, ignore[i].word);
            }
            return str;
        });

    }

    //handles row selection
    handleRowClick(ataCode,ataDescription) {
        this.props.onSelectAtaCode(ataCode);        
        this.props.onUpdateAutoCompleteATACodeDesc(ataDescription, true);
    }

    //handles 
    handleOnSearch(text) {
        this.props.onUpdateAutoCompleteATACodeDesc(text);
        this.props.onHideContainer();
    }
    
    render() {
        return (
            <div>
                <AtaSearchComponent
                    ataCodes={this.convertToTitleCase(this.props.ataCodes) }
                    onRecordSelect={this.handleRowClick.bind(this)}
                    onSearch={this.handleOnSearch.bind(this) }
                    showResults={this.props.showAtaSearchResultContainer}
                    isfetchingData = {this.props.isfetchingData}
                    onHideContainer = {this.props.onHideContainer}
                    searchedText = {this.props.searchedText}
                    resetAtaSearch = {this.props.resetAtaSearch}
                    />
            </div>
        );
    }
}




export default AtaSearchContainer;