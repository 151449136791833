// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './VehicleComponent.css';
import CheckCircle from 'components/CheckCircle';
import * as bitInspectionEnums from '../data/BitInspectionEnums';

class VehicleComponent extends React.Component {
	handleComponentResultCodeClick = (name, checked) => {
		if (!checked)
			this.props.onComponentDetailsChanged(this.props.componentId, 'componentResultCode', name);
		else
			this.props.onComponentDetailsChanged(this.props.componentId, 'componentResultCode', null);
	}

	handleNoteTextChange = (event) => {
		this.props.onComponentDetailsChanged(this.props.componentId, 'noteText', event.target.value);
	}

	render() {
		if (!this.props.componentDetails)
			return null;

		return (
			<div className='bit_inspection_vehicle_component__row'>
				<div className='bit_inspection_vehicle_component__item_cell'>
					{this.props.componentName}
				</div>

				<div className='bit_inspection_vehicle_component__cell' tabIndex='0'>
					<CheckCircle
						name={bitInspectionEnums.componentResultCode.PASS}
						className='bit_inspection_vehicle_component__check_circle'
						checked={this.props.componentDetails.get('componentResultCode') === bitInspectionEnums.componentResultCode.PASS}
						onClick={this.handleComponentResultCodeClick}
					/>
				</div>
				<div className='bit_inspection_vehicle_component__cell' tabIndex='0'>
					<CheckCircle
						name={bitInspectionEnums.componentResultCode.NA}
						className='bit_inspection_vehicle_component__check_circle'
						checked={this.props.componentDetails.get('componentResultCode') === bitInspectionEnums.componentResultCode.NA}
						onClick={this.handleComponentResultCodeClick}
					/>
				</div>
				<div className='bit_inspection_vehicle_component__cell' tabIndex='0'>
					<CheckCircle
						name={bitInspectionEnums.componentResultCode.FAIL}
						className='bit_inspection_vehicle_component__check_circle'
						checked={this.props.componentDetails.get('componentResultCode') === bitInspectionEnums.componentResultCode.FAIL}
						onClick={this.handleComponentResultCodeClick}
					/>
				</div>

				<div className='bit_inspection_vehicle_component__comments_cell'>
					<input
						id='noteText'
						className='bit_inspection_vehicle_component__note_text'
						maxLength='100'
						onBlur={this.handleNoteTextChange}
					/>
				</div>

			</div>
		)
	}
}

export default VehicleComponent;
