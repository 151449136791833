// #region Imports
import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import { defineMessages, FormattedMessage,RawIntlProvider,injectIntl } from "react-intl";
import lodash from 'lodash';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import CheckCircle from 'components/CheckCircle';
// #endregion

// #region Data
const permissionData = [
  { entity: 'store', permission: 'STORE_ADMIN', general: false },
  { entity: 'ba', permission: 'BA_VW', general: false },
  { entity: 'ba', permission: 'BA_ED', general: false },
  { entity: 'po', permission: 'PO_VW', general: false },
  { entity: 'po', permission: 'PO_CR', general: true },
  { entity: 'po', permission: 'PO_VD', general: true },
  { entity: 'po', permission: 'PO_ED', general: true },
  { entity: 'po', permission: 'PO_CL', general: true },
  { entity: 'cd', permission: 'CD_VW', general: true },
  { entity: 'cd', permission: 'CD_CL', general: true },  
  { entity: 'as', permission: 'AS_VW', general: true },
  { entity: 'as', permission: 'AS_ED', general: true },  
  { entity: 'tx', permission: 'TX_VW', general: true },
  { entity: 'doc', permission: 'DOC_VW', general: true },
  { entity: 'doc', permission: 'DOC_ED', general: true },  
  { entity: 'auth_email', permission: 'AUTH_EMAIL_VW', general: true },
  { entity: 'auth_email', permission: 'AUTH_EMAIL_ED', general: true },
  { entity: 'services', permission: 'SERVICES_VW', general: true },
  { entity: 'services', permission: 'SERVICES_ED', general: true },
];

const minimumPermissions = [
  'PO_VW'
];

export const defaultPermissions = [
  'PO_VW',
  'PO_CR',
  'PO_VD',
  'PO_ED',
  'PO_CL'
];
// #endregion

// #region Styled Components
const EntityStyled = styled.div`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`;

const YesNoContainerStyled = styled.div`
  display: flex;
  width: 250px;
`;
const NoStyled = styled.div`
  padding-left: 0; 
`;
const YesStyled = styled.div`
  padding-left: 1rem;
`;

const PermissionItemsStyled = styled.div`
  display: flex;  
  flex-wrap: wrap;
  margin-left: 3rem;
  width: ${props=>props.columns === 1 ? `200px` : `400px`};
`;

const PermissionItemStyled = styled.div`
  flex: 1 0 150px;
  max-width: 200px;
`;

const CheckCircleStyled = styled(CheckCircle)`
  padding: 0.25rem;    
`;

const PermissionCheckCircleStyled = styled(CheckCircle).attrs({
  size: 'sm'
})`
  padding: 0.25rem;      
`;

const TitleStyled = styled.div`
	text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

const DelegateAccessTitleStyled = styled.div`
	text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 1rem;
`;

const SelectAllStyled = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: ${({enabled = true}) => {
    return enabled ? 'default' : 'not-allowed'
  }};
`;

const DelegateAccessInstructionsStyled = styled.div`  
  padding-bottom: 1rem;
`;
// #endregion

// #region Prop Types
const propTypes = {   
  selectedPermissions: PropTypes.arrayOf(PropTypes.string.isRequired),
  limitToThesePermissions: PropTypes.arrayOf(PropTypes.string.isRequired),  
  onPermissionChange:PropTypes.func,
  onError: PropTypes.func
};
// #endregion

// #region Component
const Permissions = ({   
  selectedPermissions,
  limitToThesePermissions = [],  
  onPermissionChange,
  onError,
  intl 
}) => {  
  // #region State
  let disabledPermissions = [];  
  if (limitToThesePermissions.length > 0)
    disabledPermissions = lodash.difference(permissionData.map(p=>p.permission), limitToThesePermissions);

  let everyGeneralPermissionsSelected = permissionData
    .filter(p => p.general && !disabledPermissions.includes(p.permission))
    .every(p => selectedPermissions.includes(p.permission));  
  // #endregion

  // #region Ref
  // #endregion

  // #region Effects
  // #endregion

  // #region Event handlers
  const handleAdminNoClick = (name, checked) => {    
    if (!checked) {                        
      if (selectedPermissions.includes('STORE_ADMIN')) {
        // Remove admin permission.
        let newPermissions = selectedPermissions.filter(p => p != 'STORE_ADMIN');
        onPermissionChange(newPermissions);
      }
    }
    else {
      //ignore click
    }		
  };

  const handleAdminYesClick = (name, checked) => {        
    if (!checked) {                                          
      if (!selectedPermissions.includes('STORE_ADMIN')) {
        // Select ADMIN and merge in already selected permissions.
        let newPermissions = ['STORE_ADMIN', ...selectedPermissions];
        onPermissionChange(newPermissions);
      }      
    }
    else {
      //ignore click
    }		
  };
  
  const handleEntityClick =  (name, checked) => {
    let entity = name;
    let newPermissions;    
    
    // Get all permissions for the clicked entity.
    const permissions = permissionData.filter(p => p.entity === entity).map(p => (p.permission));
    
    // If entity is currently checked...
    if (checked) {      
      // Prevent user from clicking entity if every child permission is not granted to current user.
      if (permissions.every(p=>disabledPermissions.includes(p))) {        
        return;
      }

      // Remove all the entity's permissions from selected permissions except disabled permissions.
      let permissionsToRemove = permissions.filter(p => !disabledPermissions.includes(p));
      newPermissions = selectedPermissions.filter(p => !permissionsToRemove.includes(p));
    }
    // If entity is currently NOT checked
    else {          
      // Add all the entity's permissions to the selected permissions.
      let permissionsToAdd = permissions.filter(p => !disabledPermissions.includes(p));
      newPermissions = selectedPermissions
        .filter(i => !permissionsToAdd.includes(i)) // remove if some exist (to avoid duplicates)
        .concat(permissionsToAdd);      
    }
    
    // Add back minimum permissions.
    if (!newPermissions.includes('PO_VW'))
      newPermissions = newPermissions.concat('PO_VW');

    onPermissionChange(newPermissions);    
  };

  const handlePermissionClick = (name, checked) => {    
    let permission = name;
    let newPermissions;      

    if (checked) {

      // Prevent user from unchecking permission if user is granted same permission.
      if ('currentPermission' === 'userHasThisPermission' && onError) {
        onError('error_admin_cannot_change_permission');
        return;
      }
      // Remove permission.
      newPermissions = selectedPermissions.filter(p => p !== permission);     
    }
    else {
      // Add permission.
      newPermissions = selectedPermissions.concat(permission);               
    }
    onPermissionChange(newPermissions);    
  };  

  const handleSelectAll = () => {
    // Get all permissions except admin and disabled permissions.
    let newPermissions = permissionData
      .filter(p => p.general && !disabledPermissions.includes(p.permission))
      .map(p => (p.permission));
      
    // Merge in already selected permissions. (and remove duplicates)    
    newPermissions = lodash.uniq([...newPermissions, ...selectedPermissions]);
    
    onPermissionChange(newPermissions);
  } 

  const handleDeselectAll = () => {
    // Get list of permissions we want to keep if they are already selected.
    let permissionsToKeep = lodash.uniq([
      ...permissionData.filter(p=>(p.general === false)).map(p => (p.permission)),
      ...disabledPermissions,
      ...minimumPermissions
    ]);    
    
    // Remove all selected permissions except the keep list.
    let newPermissions = selectedPermissions
      .filter(p => permissionsToKeep.includes(p));

    onPermissionChange(newPermissions);
  }  
  // #endregion

  // #region International messages
  const messages = defineMessages({
    permissions__grant_delegate_access_title: {
      id: "permissions__grant_delegate_access_title",
      description: "Grant administrator access",
      defaultMessage: "Grant administrator access"
    }, 
    permissions__grant_delegate_access_instructions: {
      id: "permissions__grant_delegate_access_instructions",
      description: "Instructions for granting the admin permission.",
      defaultMessage: "Admins may create new users, set permissions and edit permissions. Admins may only grant or edit permissions for which they already have access."
    },
    permissions__select_all: {
      id: "permissions__select_all",
      description: "Select all link",
      defaultMessage: "Click here to select all permission features"
    },    
    permissions__deselect_all: {
      id: "permissions__deselect_all",
      description: "Deselect all link",
      defaultMessage: "Click here to deselect all permission features"
    },
    permissions_yes: {
      id: "permissions_yes",
      description: "Yes",
      defaultMessage: "Yes"
    },
    permissions_no: {
      id: "permissions_no",
      description: "No",
      defaultMessage: "No"
    },    
    permissions__bank_details: {
      id: "permissions__bank_details",
      description: "Bank details label",
      defaultMessage: "Bank details"
    },    
    permissions__unable_to_edit_permission_level: {
      id: "permissions__unable_to_edit_permission_level",
      description: "Popup message when user cannot edit the permission due to their own permission level",
      defaultMessage: "You are unable to edit this feature at your current permission level."
    },    
    permissions__unable_to_edit_minimum: {
      id: "permissions__unable_to_edit_minimum",
      description: "Popup message when user cannot edit the permission since this is a minimum permission",
      defaultMessage: "The PO Search / View permission cannot be deselected because it is needed to view the PartnerConnect shopview screen and search for purchase orders."
    },  
    permissions_features: {
      id: "permissions_features",
      description: "Permissions features",
      defaultMessage: "Permissions features"
    },
    permissions_orders: {
      id: "permissions_orders",
      description: "PURCHASE ORDERS",
      defaultMessage: "PURCHASE ORDERS"
    },
    permissions_delivery_requests: {
      id: "permissions_delivery_requests",
      description: "COURTESY DELIVERY REQUESTS",
      defaultMessage: "COURTESY DELIVERY REQUESTS"
    },
    permissions__delivery_requests_us_only: {
      id: "permissions__delivery_requests_us_only",
      description: "Courtesy delivery requests U.S. only note",
      defaultMessage: "Only available for U.S. users"
    },
    permissions__car_wash_vendor_po_note: {
      id: "permissions__car_wash_vendor_po_note",
      description: "Courtesy delivery requests U.S. only note",
      defaultMessage: "For car wash vendors, all purchase order permissions are required"
    },
    permissions_your_account_settings_contact_details: {
      id: "permissions_your_account_settings_contact_details",
      description: "SETTINGS & CONTACT DETAILS",
      defaultMessage: "SETTINGS & CONTACT DETAILS"
    },
    permissions_bank: {
      id: "permissions_bank",
      description: "BANK DETAILS",
      defaultMessage: "BANK DETAILS"
    },
    permissions_tax: {
      id: "permissions_tax",
      description: "TAX DETAILS",
      defaultMessage: "TAX DETAILS"
    },
    permissions_documents: {
      id: "permissions_documents",
      description: "UPLOAD DOCUMENTS",
      defaultMessage: "UPLOAD DOCUMENTS"
    },
    permissions_auth_email: {
      id: "permissions_auth_email",
      description: "UPDATE AUTHORIZATION EMAIL CONFIGURATION",
      defaultMessage: "UPDATE AUTHORIZATION EMAIL CONFIGURATION"
    },
    permissions_services: {
      id: "permissions_services",
      description: "SERVICES",
      defaultMessage: "SERVICES"
    },
    permissions_view: {
      id: "permissions_view",
      description: "View",
      defaultMessage: "View"
    },
    permissions_search_view: {
      id: "permissions_search_view",
      description: "Search / View",
      defaultMessage: "Search / View"
    },
    permissions_create: {
      id: "permissions_create",
      description: "Create",
      defaultMessage: "Create"
    },
    permissions_edit: {
      id: "permissions_edit",
      description: "Edit",
      defaultMessage: "Edit"
    },
    permissions_close_view: {
      id: "permissions_close_view",
      description: "Close / View",
      defaultMessage: "Close / View"
    },
    permissions_close_view_edit: {
      id: "permissions_close_view_edit",
      description: "Close / View / Edit",
      defaultMessage: "Close / View / Edit"
    },
    permissions_void: {
      id: "permissions_void",
      description: "Cancel (void)",
      defaultMessage: "Cancel (void)"
    },    
    permissions_upload: {
      id: "permissions_upload",
      description: "Upload",
      defaultMessage: "Upload"
    },    
  });
  // #endregion

  // #region Render helpers
  const renderPermission = (permissionCode, message) => {

    const disabled = disabledPermissions.includes(permissionCode)
      || minimumPermissions.includes(permissionCode);
    const toolTipMessage = disabledPermissions.includes(permissionCode)
      ? messages.permissions__unable_to_edit_permission_level 
      : messages.permissions__unable_to_edit_minimum;

    return (
      <OverlayTrigger 
        placement='right'
        trigger={disabled ? ['hover','focus'] : null }
        overlay={<Tooltip id="tooltip"><RawIntlProvider value={intl}><FormattedMessage {...toolTipMessage} /></RawIntlProvider></Tooltip>}
      >
        <PermissionItemStyled>
          <PermissionCheckCircleStyled 
            name={permissionCode}
            checked={selectedPermissions.includes(permissionCode)}
            message={message}
            allowTab
            disabled={disabled}
            onClick={handlePermissionClick}
          />          
        </PermissionItemStyled>
      </OverlayTrigger>
    );
  }
  // #endregion  

  // #region Render
  return (
    <div>

      <DelegateAccessTitleStyled>
        <FormattedMessage {...messages.permissions__grant_delegate_access_title} />
      </DelegateAccessTitleStyled>

      <DelegateAccessInstructionsStyled>
        <FormattedMessage {...messages.permissions__grant_delegate_access_instructions} />
      </DelegateAccessInstructionsStyled>
      
      <YesNoContainerStyled>
        <NoStyled>
          <CheckCircleStyled
            name='STORE_ADMIN'
            checked={!selectedPermissions.includes('STORE_ADMIN')}
            message={<FormattedMessage {...messages.permissions_no} />}
            allowTab
            onClick={handleAdminNoClick}            
          />
        </NoStyled>      
        <YesStyled>
          <CheckCircleStyled
            name='STORE_ADMIN'
            checked={selectedPermissions.includes('STORE_ADMIN')}
            message={<FormattedMessage {...messages.permissions_yes} />}
            allowTab
            onClick={handleAdminYesClick}
          />
        </YesStyled>
      </YesNoContainerStyled>

      <hr />

      <TitleStyled>
        <FormattedMessage {...messages.permissions__bank_details} />
      </TitleStyled>

      <EntityStyled>
        <CheckCircleStyled 
          name='ba'            
          checked={['BA_VW', 'BA_ED'].every(p => selectedPermissions.includes(p))}
          message={<FormattedMessage {...messages.permissions_bank} />}
          allowTab
          disabled={['BA_VW', 'BA_ED'].every(p => disabledPermissions.includes(p))}
          onClick={handleEntityClick}
        />          
      </EntityStyled>
      
      <PermissionItemsStyled columns={1}>
        {renderPermission('BA_VW', <FormattedMessage {...messages.permissions_view} />)}
        {renderPermission('BA_ED', <FormattedMessage {...messages.permissions_edit} />)}        
      </PermissionItemsStyled>
      
      <hr />

      <TitleStyled>
        <FormattedMessage {...messages.permissions_features} />
      </TitleStyled>
      
      <SelectAllStyled> 
        {everyGeneralPermissionsSelected ?
          <a onClick={handleDeselectAll}>
            <FormattedMessage {...messages.permissions__deselect_all} />
          </a>
        :
          <a onClick={handleSelectAll}>
            <FormattedMessage {...messages.permissions__select_all} />
          </a>        
        }        
      </SelectAllStyled>

      <EntityStyled>
        <CheckCircleStyled 
            name='po'
            checked={['PO_VW', 'PO_CR', 'PO_VD', 'PO_ED', 'PO_CL'].every(p => selectedPermissions.includes(p))}
            message={<FormattedMessage {...messages.permissions_orders} />}
            allowTab
            disabled={['PO_VW', 'PO_CR', 'PO_VD', 'PO_ED', 'PO_CL'].every(p => disabledPermissions.includes(p))}
            onClick={handleEntityClick}
          />
          <div css={`
            padding-left: 35.5px;             
            color: ${({theme}) => theme.color.ariGray};            
          `}>
            <FormattedMessage {...messages.permissions__car_wash_vendor_po_note} />
          </div>        
      </EntityStyled>

      <PermissionItemsStyled>
        {renderPermission('PO_VW', <FormattedMessage {...messages.permissions_search_view} />)}
        {renderPermission('PO_CR', <FormattedMessage {...messages.permissions_create} />)}
        {renderPermission('PO_VD', <FormattedMessage {...messages.permissions_void} />)}
        {renderPermission('PO_ED', <FormattedMessage {...messages.permissions_edit} />)}
        {renderPermission('PO_CL', <FormattedMessage {...messages.permissions_close_view} />)}        
      </PermissionItemsStyled>      
    
      <EntityStyled>
        <CheckCircleStyled 
            name='cd'            
            checked={['CD_VW', 'CD_CL'].every(p => selectedPermissions.includes(p))}
            message={<FormattedMessage {...messages.permissions_delivery_requests} />}
            allowTab
            disabled={['CD_VW', 'CD_CL'].every(p => disabledPermissions.includes(p))}
            onClick={handleEntityClick}
          />
          <div css={`
            padding-left: 35.5px;             
            color: ${({theme}) => theme.color.ariGray};            
          `}>
            <FormattedMessage {...messages.permissions__delivery_requests_us_only} />
          </div>
      </EntityStyled>
      
      <PermissionItemsStyled>
        {renderPermission('CD_VW', <FormattedMessage {...messages.permissions_search_view} />)}
        {renderPermission('CD_CL', <FormattedMessage {...messages.permissions_close_view_edit} />)}
      </PermissionItemsStyled>

      <EntityStyled>
        <CheckCircleStyled 
          name='as'
          checked={['AS_VW', 'AS_ED'].every(p => selectedPermissions.includes(p))}
          message={<FormattedMessage {...messages.permissions_your_account_settings_contact_details} />}
          allowTab
          disabled={['AS_VW', 'AS_ED'].every(p => disabledPermissions.includes(p))}
          onClick={handleEntityClick}
        />          
      </EntityStyled>
      
      <PermissionItemsStyled columns={1}>
        {renderPermission('AS_VW', <FormattedMessage {...messages.permissions_view} />)}
        {renderPermission('AS_ED', <FormattedMessage {...messages.permissions_edit} />)}                                   
      </PermissionItemsStyled>


      <EntityStyled>
        <CheckCircleStyled 
          name='tx'
          checked={['TX_VW'].every(p => selectedPermissions.includes(p))}
          message={<FormattedMessage {...messages.permissions_tax} />}
          allowTab
          disabled={['TX_VW'].every(p => disabledPermissions.includes(p))}
          onClick={handleEntityClick}
        />
      </EntityStyled>
      
      <PermissionItemsStyled columns={1}>
        {renderPermission('TX_VW', <FormattedMessage {...messages.permissions_view} />)}
      </PermissionItemsStyled>

      <EntityStyled>
        <CheckCircleStyled 
          name='doc'
          checked={['DOC_VW', 'DOC_ED'].every(p => selectedPermissions.includes(p))}
          message={<FormattedMessage {...messages.permissions_documents} />}
          allowTab
          disabled={['DOC_VW', 'DOC_ED'].every(p => disabledPermissions.includes(p))}
          onClick={handleEntityClick}
        />
      </EntityStyled>

      <PermissionItemsStyled columns={1}>
        {renderPermission('DOC_VW', <FormattedMessage {...messages.permissions_view} />)}
        {renderPermission('DOC_ED', <FormattedMessage {...messages.permissions_edit} />)}
      </PermissionItemsStyled>

      <EntityStyled>
      <CheckCircleStyled 
          name='auth_email'
          checked={['AUTH_EMAIL_VW', 'AUTH_EMAIL_ED'].every(p => selectedPermissions.includes(p))}
          message={<FormattedMessage {...messages.permissions_auth_email} />}
          allowTab
          disabled={['AUTH_EMAIL_VW', 'AUTH_EMAIL_ED'].every(p => disabledPermissions.includes(p))}
          onClick={handleEntityClick}
        />
      </EntityStyled>

      <PermissionItemsStyled columns={1}>
        {renderPermission('AUTH_EMAIL_VW', <FormattedMessage {...messages.permissions_view} />)}
        {renderPermission('AUTH_EMAIL_ED', <FormattedMessage {...messages.permissions_edit} />)}                                    
      </PermissionItemsStyled>

      <EntityStyled>
        <CheckCircleStyled 
          name='services'
          checked={['SERVICES_VW', 'SERVICES_ED'].every(p => selectedPermissions.includes(p))}
          message={<FormattedMessage {...messages.permissions_services} />}
          allowTab
          disabled={['SERVICES_VW', 'SERVICES_ED'].every(p => disabledPermissions.includes(p))}
          onClick={handleEntityClick}
        />
      </EntityStyled>

      <PermissionItemsStyled columns={1}>
        {renderPermission('SERVICES_VW', <FormattedMessage {...messages.permissions_view} />)}
        {renderPermission('SERVICES_ED', <FormattedMessage {...messages.permissions_edit} />)}                            
      </PermissionItemsStyled>

    </div>
  );
  // #endregion
};
// #endregion

Permissions.propTypes = propTypes;

export default injectIntl(Permissions);