// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './CancelButton.css';

const CancelButton = ({onClick}) => {
    const messages = defineMessages({
            documents_cancel_button: {
                id: 'documents_cancel_button',
                description: 'Cancel button',
                defaultMessage: 'Cancel'
            }
        });

    return <div className='documents__cancel'>
        <span onClick={onClick} className='documents__cancel_button' role="button">
            <img src="/assets/VMS_28button_delete_blue.png"/>{' '}            
            <FormattedMessage {...messages.documents_cancel_button}/>                
        </span>        
    </div>
}

export default CancelButton;