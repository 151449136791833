import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}
	
	componentDidCatch(error, errorInfo) {
				
		// Catch errors in any components below and re-render with error message
		// Note that this only catches errors that effect render.
		this.setState({
			error: error,
			errorInfo: errorInfo
		})
		// You can also log error messages to an error reporting service here
	}
	
	handleHtmlLinkClick = (e) => {
		e.preventDefault();
		window.location.reload();
	}

	render() {
		const messages = defineMessages({			
			generic_ui_error_message_1: {
				id:'generic_ui_error_message_1', 
				description: "An unexpected error has occurred. Please ", 
				defaultMessage: "An unexpected error has occurred. Please ", 
			},
			generic_ui_error_message_2: {
				id:'generic_ui_error_message_2', 
				description: "click here", 
				defaultMessage: "click here", 
			},
			generic_ui_error_message_3: {
				id:'generic_ui_error_message_3', 
				description: " to reload the app or contact the Holman call center at 1-866-274-1025.",
				defaultMessage: " to reload the app or contact the Holman call center at 1-866-274-1025.",
			}
		  });

		if (this.state.errorInfo) {
			// Error path
			return (
				<div>				
					<div style={{padding:'2rem'}}>
						<FormattedMessage  {...messages.generic_ui_error_message_1} />              
						<a href='#' onClick={this.handleHtmlLinkClick}> <FormattedMessage  {...messages.generic_ui_error_message_2} />
						</a>
						<FormattedMessage  {...messages.generic_ui_error_message_3} />
					</div>
				
					<div style={{ whiteSpace: 'pre-wrap', padding:'2rem' }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo.componentStack}
					</div>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;