// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './Title.css';

const Title = ({poNumber}) => {
    const messages = defineMessages({
            purchase_order_paid: {
                id: 'purchase_order_paid',
                description: 'Purchase order no. {poNumber}',
                defaultMessage: 'Purchase order no. {poNumber}'
            }
        });

    return <div className='adjustment__title'>

        <FormattedMessage            
            {...messages.purchase_order_paid}
            values={{ poNumber }} />

    </div>
}

export default Title;