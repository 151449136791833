import React from 'react';
import ReactDOM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { zeroIfUndefined } from 'utils/HelperFunctions';
import './TireSpecifications.css';

class TireSpecifications extends React.Component {
    render() {
        return (
            <div >
                {this.props.children}
            </div>
        );
    }
};

TireSpecifications.propTypes={

}

export default TireSpecifications;  