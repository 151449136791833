import * as constants from 'constants/App';

export function carWashAddRequestInfo(requestInfo) {
    return { type: "VALIDATION_VEHICLE_SEARCH", requestInfo }
}

export function carWashClearVehicleSearch() {
    return { type: "CLEAR_VALIDATION_VEHICLE_SEARCH" }
}

export function carWashClearPOCreationDetails() {
    return { type: "CLEAR_CREATE_PO_DETAILS" }
}

export function carWashFetchClientNumbers() {
    return { type: "FETCH_CAR_WASH_CLIENT_NUMBERS" }
}

export function fetchCarWashDetails(clientNumber,unitNumber){

return { type: "FETCH_CAR_WASH_DETAILS",clientNumber,unitNumber}
}

export function carWashFetchOrderDetails(){
    return { type: "FETCH_ORDER_DETAILS"}
}

export function carWashToggleShowAllVendorActionNeededPIP() {
    return {type: "TOGGLE_SHOW_ALL_VENDOR_ACTION_NEEDED_PIP"}
}

export function carWashToggleShowAllVendorActionNeededApproval() {
    return {type: "TOGGLE_SHOW_ALL_VENDOR_ACTION_NEEDED_APPROVAL"}
}

export function saveCarWashRepairType(carWashRepairType) {
    return {type: "CAR_WASH_REPAIR_TYPE_CHANGE_SELECTION",carWashRepairType}
}

export function carWashSavePODetails(poDetails) {
    return {type: "SAVE_PO_DETAILS", poDetails};
}

export function createCarWashPO(){
    return { type: "CREATE_CAR_WASH_PO"}
}

export function carWashAddRequestInfoPO(requestPOInfo) {
    return { type: "VALIDATION_PO_CREATION", requestPOInfo }
}

export function carWashFormIsDirty() {
    return { type: "CAR_WASH_FORM_IS_DIRTY", isDirty:true }
}