import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import './ChangePassword.css';
import * as constants from 'constants/App';
import { defineMessages, FormattedMessage, injectIntl,RawIntlProvider } from 'react-intl';
import { falseIfUndefined, emptyStringIfUndefined, getTokenExpirationTimeinMinutes } from 'shared/utils/HelperFunctions';
import Spinner from 'components/Spinner';
import FormWrapper from 'components/FormWrapper';
import { Popover, Overlay } from 'react-bootstrap';
import * as ChangeActions from '../actions/ChangePassword';
import * as helperFunctions from 'shared/utils/HelperFunctions';
import TranslationIcon from 'components/SiteTranslations';
import PasswordPolicy from 'shared/components/PasswordPolicy';
import { Modal } from 'react-bootstrap';
import * as VendorAccountActions from 'VendorAccountInformation/actions/VendorAccountView';
import * as appActions from 'actions/App';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.txtCurrentPasswordRef = React.createRef();
        this.txtPasswordRef = React.createRef();
        this.txtConfirmPasswordRef = React.createRef();

        this.state = {
            showErrorMessage: false,
            modalLoaded: false
        }
        this.props.dispatch({ type: constants.RESET_ERROR_MESSAGES });
    }

    componentDidMount() {
        this.props.dispatch({ type: constants.RESET_ERROR_MESSAGES });
    }

    componentDidUpdate(prevProps) {
        if (this.props.isPasswordChangeSuccessed !== prevProps.isPasswordChangeSuccessed) {
            this.props.dispatch({ type: constants.CHANGE_PASSWORD_MOUNT });
            this.props.dispatch({ type: constants.RESET_PASSWORD_EXPIRY_DATE });
            this.setState({ modalLoaded: false });
        }
    }

    onCloseWindowHandler() {
        this.props.dispatch(appActions.signOut());
    }

    onHide() {
        this.props.dispatch({ type: constants.RESET_ERROR_MESSAGES });
    }

    validationPasswordHandler() {
        const { formatMessage } = this.props.intl;

        const errorMessages = defineMessages({
            current_password_field_required: {
                id: 'current_password_field_required',
                description: 'tile for required current password',
                defaultMessage: 'Current password cannot empty'
            },
            confirm_password_field_required: {
                id: 'confirm_password_field_required',
                description: 'tile for required confirm password',
                defaultMessage: 'Confirm password cannot empty'
            },
            password_field_required: {
                id: 'password_field_required',
                description: 'Please enter valid password',
                defaultMessage: 'Password must meet complexity criteria below'
            },
            new_password_field_matched: {
                id: 'new_password_field_matched',
                description: 'New password should not same as current password',
                defaultMessage: 'New password should not same as current password'
            },
            confirm_password_field_does_not_match: {
                id: 'confirm_password_field_does_not_match',
                description: 'Doesn\'t match  password and confirm password',
                defaultMessage: 'Confirm password doesn\'t match'
            }
        });

        let currentPassword = this.txtCurrentPasswordRef.current.value;
        let password = this.txtPasswordRef.current.value;
        let confirmPassword = this.txtConfirmPasswordRef.current.value;
        let hasValidationError = false;

        if (currentPassword == '') {
            this.props.dispatch(ChangeActions.saveCurrentPasswordErrorMessage(formatMessage(errorMessages.current_password_field_required)));
            this.props.dispatch(ChangeActions.showPopOverInvalidCurrentPassword(true));
            hasValidationError = true;
        }
        else if (!helperFunctions.validatePasswordPolicy(password)) {
            this.props.dispatch(ChangeActions.saveNewPasswordErrorMessage(formatMessage(errorMessages.password_field_required)));
            this.props.dispatch(ChangeActions.showPopOverInvalidPassword(true));
            hasValidationError = true;
        } else if (currentPassword === password) {
            this.props.dispatch(ChangeActions.saveNewPasswordErrorMessage(formatMessage(errorMessages.new_password_field_matched)));
            this.props.dispatch(ChangeActions.showPopOverInvalidPassword(true));
            hasValidationError = true;
        } else if (confirmPassword == '') {
            this.props.dispatch(ChangeActions.saveConfirmPasswordErrorMessage(formatMessage(errorMessages.confirm_password_field_required)));
            this.props.dispatch(ChangeActions.showPopOverConfirmPassword(true));
            hasValidationError = true;
        }
        else if (password !== confirmPassword) {
            this.props.dispatch(ChangeActions.saveConfirmPasswordErrorMessage(formatMessage(errorMessages.confirm_password_field_does_not_match)));
            this.props.dispatch(ChangeActions.showPopOverConfirmPassword(true));
            hasValidationError = true;
        }

        return !hasValidationError;
    }

    onSubmitHandler(event) {
        event.preventDefault();

        let hasValidated = this.validationPasswordHandler();

        if (hasValidated) {
            this.props.dispatch(VendorAccountActions.savePassword(this.props.vendorId, this.txtCurrentPasswordRef.current.value, this.txtConfirmPasswordRef.current.value));
        }
    }

    render() {
        const messages = defineMessages({
            close_dialog: {
                id: 'close_dialog',
                description: '  Close',
                defaultMessage: 'Close'
            },
            change_password_title: {
                id: 'change_password_title',
                description: 'title for input field groups',
                defaultMessage: 'Change your password'
            },
            change_password_sub_title: {
                id: 'change_password_sub_title',
                description: 'sub title of force password change',
                defaultMessage: 'Our records indicate that your current password doesn’t meet the complexity requirements. For your security, please set up a new password.'
            },
            current_password_field_required: {
                id: 'current_password_field_required',
                description: 'tile for required current password',
                defaultMessage: 'Current password cannot empty'
            },
            current_password_field: {
                id: 'current_password_field',
                description: 'title of old password field',
                defaultMessage: 'Current Password: '
            },
            new_password_field: {
                id: 'new_password_field',
                description: 'title of new password field',
                defaultMessage: 'New Password: '
            },
            confirm_password_field: {
                id: 'confirm_password_field',
                description: 'title of confirm password field',
                defaultMessage: 'Confirm Password: '
            },
            change_password_button: {
                id: 'changePassword_change_password',
                description: 'change password button text',
                defaultMessage: 'Change Password'
            },
            change_password_current_pwd_error_mesg1: {
                id: 'change_password_current_pwd_error_mesg1',
                description: 'Current password is incorrect.',
                defaultMessage: 'Current password is incorrect.'
            },
            already_used_in_last_5_passwords: {
                id: 'already_used_in_last_5_passwords',
                description: 'The password you have entered is not valid because it matches one of the last five you have used. Please create a new password.',
                defaultMessage: 'The password you have entered is not valid because it matches one of the last five you have used. Please create a new password.'
            },
            change_password_current_pwd_error_mesg: {
                id: 'change_password_current_pwd_error_mesg',
                description: 'Please enter current password.',
                defaultMessage: 'Please enter current password.'
            },

        });
      
        const isTermsAndConditionsAccepted = this.props.acceptedTermsAndConditions === "Y";
        const isPasswordExpired = this.props.passwordExpirationDate !== null && ((new Date().getTime() / 1000) >= this.props.passwordExpirationDate) ? true : false;
        const showModal = (this.props.vendorId != undefined && this.props.vendorId.toUpperCase().endsWith('MM')) ? isPasswordExpired : (isTermsAndConditionsAccepted && isPasswordExpired);

        if (this.props.isPasswordChangeSuccessed) {
            return <span />;
        }

        return <div className="change-password">
            {

                <Modal show={showModal}
                    onHide={()=>!showModal}
                    dialogClassName="change-password-modal"
                    backdrop="static"
                    keyboard={false}
                    onEntered={() => this.setState({ modalLoaded: true })}
                >
                    <Modal.Body>
                        <div className="change-password-component row" >
                            <div className="row">
                                <div className=" col-sm-12 change-password__close pull-left">
                                    <div onClick={this.onCloseWindowHandler.bind(this)}>
                                        <img
                                            src="/assets/VMS_33button_kill_blue.png" />
                                        {"  "} {<FormattedMessage {...messages.close_dialog} />}
                                    </div>
                                </div>
                            </div>
                            <div className="change-password-component__title row">
                                <span>
                                    <FormattedMessage {...messages.change_password_title} />
                                </span>
                            </div>
                            <div className="change-password-component__sub_title row">
                                <span>
                                    <FormattedMessage {...messages.change_password_sub_title} />
                                </span>
                            </div>
                            <FormWrapper key='changePasswordErr' id="changePasswordErr" {...this.props} formErrors={this.props.errorMessage} />
                            <FormWrapper key='genericError' id="genericError" {...this.props} formErrors={this.props.genericErrorMessage} />

                            <div className="change-password-component__input-section row">
                                <div className="row form-group">
                                    <div className="col-sm-5">
                                        <div className="change-password-component__label">
                                            <FormattedMessage {...messages.current_password_field} />
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <Overlay
                                            show={this.props.popOverDisplayShowCurrentPassword}
                                            onHide={this.onHide.bind(this)}
                                            placement="right"
                                            target={this.txtCurrentPasswordRef}
                                        >
                                            <Popover id="changePassword_PasswordErrorMessage"
                                                className="change-password-component__errorMessage">
                                                    <RawIntlProvider value={this.props.intl}>
                                                <FormattedMessage {...messages.current_password_field_required} />
                                                </RawIntlProvider>
                                            </Popover>
                                        </Overlay>
                                        <input type="password" tabIndex="1" ref={this.txtCurrentPasswordRef} id="txtCurrentPassword"
                                            className="form-control"
                                            onBlur={() => this.props.dispatch(ChangeActions.showPopOverInvalidCurrentPassword(false))}
                                            autocomplete="off"
                                            autoFocus
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-sm-5">
                                        <div className="change-password-component__label">
                                            <FormattedMessage {...messages.new_password_field} />
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <Overlay
                                            show={this.props.popOverDisplayShowPassword}
                                            onHide={this.onHide.bind(this)}
                                            placement="right"
                                            target={this.txtPasswordRef}
                                        >
                                            <Popover id="changePassword_PasswordErrorMessage"
                                                className="change-password-component__errorMessage">
                                                {this.props.newPasswordErrorMessage}
                                            </Popover>
                                        </Overlay>
                                        <input type="password" tabIndex="2" ref={this.txtPasswordRef} id="txtPassword"
                                            className="form-control"
                                            onBlur={() => this.props.dispatch(ChangeActions.showPopOverInvalidPassword(false))}
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="row form-group">
                                    <div className="col-sm-5">
                                        <div className="change-password-component__label">
                                            <FormattedMessage {...messages.confirm_password_field} />
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <Overlay
                                            show={this.props.popOverDisplayShowConfirmPassword}
                                            onHide={this.onHide.bind(this)}
                                            placement="right"
                                            target={this.txtConfirmPasswordRef}
                                        >
                                            <Popover id="changePassword_ConfirmPasswordErrorMessage"
                                                className="change-password-component__errorMessage">
                                                {this.props.confirmPasswordErrorMessage}
                                            </Popover>
                                        </Overlay>
                                        <input type="password" tabIndex="3" ref={this.txtConfirmPasswordRef} id="txtConfirmPassword"
                                            className="form-control"
                                            onBlur={() => this.props.dispatch(ChangeActions.showPopOverConfirmPassword(false))}
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <PasswordPolicy />
                                </div>
                            </div>
                            <div className="change-password__footer row">
                                <div
                                    className={this.props.isfetchingData ? "pull-right change-password-change_button change-password-change_button-disabled" : "pull-right change-password-change_button"}
                                    role="button"
                                    onClick={this.onSubmitHandler.bind(this)}
                                >
                                    <FormattedMessage {...messages.change_password_button} />
                                    {' '}
                                    {this.props.isfetchingData ?
                                        <div className="change-password__spinner-container">
                                            <Spinner spinnerType="dark-blue" />
                                        </div>
                                        : <img src="/assets/VMS_33button_go_bblue.png" />
                                    }

                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </div>
    }
}

function select(state) {
    return {
        vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
        acceptedTermsAndConditions: state.appState.getIn(['serverData', 'shared', 'acceptedTermsAndConditions']),
        passwordExpirationDate: state.appState.getIn(['serverData', 'changePassword', 'passwordExpirationDate']),
        popOverDisplayShowCurrentPassword: falseIfUndefined(state.appState.getIn(['uiData', 'changePassword', 'popOver', 'isPopOverDisplayedShowCurrentPassword'])),
        popOverDisplayShowPassword: falseIfUndefined(state.appState.getIn(['uiData', 'changePassword', 'popOver', 'isPopOverDisplayedShowPassword'])),
        popOverDisplayShowConfirmPassword: falseIfUndefined(state.appState.getIn(['uiData', 'changePassword', 'popOver', 'isPopOverDisplayedShowConfirmPassword'])),
        confirmPasswordErrorMessage: emptyStringIfUndefined(state.appState.getIn(['uiData', 'changePassword', 'confirmPasswordErrorMessage'])),
        newPasswordErrorMessage: emptyStringIfUndefined(state.appState.getIn(['uiData', 'changePassword', 'newPasswordErrorMessage'])),
        isfetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'changePassword', 'isFetchingData'])),
        isPasswordChangeSuccessed: falseIfUndefined(state.appState.getIn(['uiData', 'changePassword', 'isPasswordChangeSuccessed'])),
        hasErrorMessage: falseIfUndefined(state.appState.getIn(['uiData', 'changePassword', 'hasErrorMessage'])),
        errorMessage: state.appState.getIn(['uiData', 'changePassword', 'errorMessage']),
        genericErrorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage'])
    };
}

export default connect(select)(injectIntl(ChangePassword));