// #region Imports
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Api from "utils/Api";
import * as constants from "constants/App";
import styled from "styled-components";
import Spinner from "components/Spinner";
import ReactPaginate from "react-paginate";
import RemoveAccessModal from "common/RemoveAccessModal";
import DeleteUserModal from "common/DeleteUserModal";
import EmailConfirmation from "common/EmailConfirmation";
import FormWrapper from "components/FormWrapper";
import queryString from 'query-string'
import * as helperFunctions from "utils/HelperFunctions";
import {
  PageHeader,
  Subhead,
  ThirdLevelSubhead
} from "DesignSpecs/components/FontGuide";
import { PageLayout, PageLayoutClear } from "DesignSpecs/components/Layouts";
import {
  Label,
  FormTextGray,
  SubmitImage,
  IconImage,
  Row,
  Col,
  Input,
  InputFeedback
} from "DesignSpecs/components/FormComponents";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import Pagination from "common/Pagination";
import useRemoveStoreAccess from "common/useRemoveStoreAccess";
import useRemoveAllStoreAccess from "common/useRemoveAllStoreAccess";

// #endregion

// #region Styled Components
const NameHeader = styled.div`
	
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  color: black;
  font-size: 14px;
  white-space: nowrap;	
`;

const FieldGroup = styled.div`
	color: black;
  valign:bottom;
`;

const ActionButton = styled.div`
cursor: pointer;
color: #5587b7;
padding-top:1em;
&:hover {	
  color: #0f4e95;
}

`;

const NoResultsText = styled.div`
    font-size: 16px;
	  font-family: "Gotham SSm A", "Gotham SSm B";
   	color: #000000;
    font-style: normal;
    font-weight: 300;
    padding-left: 11em;
`;

const ImageWithText = styled.div`
cursor: pointer;
`;

const LinkBold = styled.b`
  padding-left: 3px;
  color: #5587b7;
  cursor: pointer;

  &:hover {	
    color: #0f4e95;
  }
`;

const Image = styled.img`
cursor: pointer;

`;

const ButtonBold = styled.b`
padding-left: 2px;
`;

const ClearImageStyled = styled.img`
  display: block;
  position: absolute;
  top: 2px;
  right: 3px; 
  cursor: pointer;                                
  width: 22px;
  height: 22px;
`;
// #endregion

// #region Component
const UserProfile = ({
  token,
  userId,
  adminUserId,
  intl,
  dispatch,
  routeParams  
}) => {
  // #region State
  const loadingStates = { loading: "loading", loaded: "loaded", error: "error" };
  const { formatMessage } = intl;
  const [loadingState, setLoadingState] = useState(loadingStates.loading);
  const [searchResultLoadingState, setSearchResultLoadingState] = useState(
    loadingStates.loaded
  );
  const [accessStoreLoadingState, setAccesStoreLoadingState] = useState(
    loadingStates.loaded
  );
  const [error, setError] = useState();
  const [stores, setStores] = useState();
  const [user, setUserInformation] = useState();    
  const routeUser = useMemo(() => {
    const queryParams = queryString.parse(routeParams.location.search);
    if (queryParams && queryParams.emailAddress
      && queryParams.firstName && queryParams.lastName) {
      return {
        emailAddress: queryParams.emailAddress,
        firstName: queryParams.firstName,
        lastName: queryParams.lastName}
    }
    else
      return undefined;
  }, [routeParams.location.search]);
  const [pageCount, setPageCount] = useState();
  const [storeSearchKeyWord, setStoreSearchKeyword] = useState("");
  const [forcePage, setForcePage] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [storeCount, setStoreCount] = useState();
  const pageSize = 20;
  const adminAccess = "N";
  const [showRemoveAccessConfirmationDialog, setRemoveAccessConfirmationDialogVisible] = useState(false);
  const [vendorIdForWhichAccessIsToBeRemoved, setVendorIdForWhichAccessIsToBeRemoved] = useState('');
  const [personaUserProfileErrorMessage, setPersonaUserProfileErrorMessage] = useState();
  const [showEmailConfirmation, setEmailConfirmation] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [setRemoveAccessInformation, isAccessBeingRemoved, isRemoveStoreAccessError] = useRemoveStoreAccess(token);
  const [setRemoveAllAccessInformation, isAllAccessBeingRemoved, isRemoveAllStoreAccessError] = useRemoveAllStoreAccess(token);
  const [showDeleteUserConfirmationDialog, setDeleteUserConfirmationDialogVisible] = useState(false);
  const [deleteUserInformation, setDeleteUserInformation] = useState(null);
  const [isDeletingUser, setIsDeletingUser] = useState(null);
  const [isDeleteUserError, setDeleteUserError] = useState(null);
  const handleSubmit = useCallback(data => { });  
  // #endregion

  // #region Event handlers
  function handlePageClick(data) {
    setCurrentPageNumber(data.selected);
  }

  function onSearchClick(value) {
    setCurrentPageNumber(0);
    setStoreSearchKeyword(value);
  }



  async function Fetch() {
    let didCancel = false;
    
    const fetchData = async () => {
      try {        
        setLoadingState(loadingStates.loading);
      
        const [vendorResult] = await Promise.all([
          Api.fetchStores(
            userId,
            currentPageNumber == undefined ? 0 : currentPageNumber,
            pageSize,
            storeSearchKeyWord,
            adminAccess,
            token,
            adminUserId
          )
        ]);

        const [userResult] = await Api.fetchUserInformation(
          userId,
          token          
        );
        
        if (!didCancel) {
          setStoreCount(vendorResult.vendorCount);
          setStores(vendorResult.vendors);          
          setUserInformation(userResult);
          setPageCount(Math.ceil(vendorResult.vendorCount / pageSize));
          setLoadingState(loadingStates.loaded);
        }
      } catch (e) {
        if (!didCancel) {          
          
          if (e.response && e.response.status === 403
              && routeUser && routeUser.emailAddress && routeUser.firstName && routeUser.lastName) {          
            setLoadingState(loadingStates.loaded);
            return;
          }
          else if (e.response && e.response.status === 403) {
            routeParams.history.goBack();
            return;  
          }
          setLoadingState(loadingStates.error);
          setPersonaUserProfileErrorMessage(helperFunctions.getCustomErrorObject(e));
        }
        
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }

  function handleRemoveStoreAccess() {

    if (vendorIdForWhichAccessIsToBeRemoved == undefined) {


      setRemoveAllAccessInformation({ userId:userId,adminUserId:adminUserId });

      if (!isAllAccessBeingRemoved)
        setRemoveAccessConfirmationDialogVisible(false);

    } else {


      setRemoveAccessInformation({ userId, storeId: vendorIdForWhichAccessIsToBeRemoved });

      if (!isAccessBeingRemoved)
        setRemoveAccessConfirmationDialogVisible(false);


    }

  }


  function removeStoreAccessClicked(vendorId, address) {
    setVendorIdForWhichAccessIsToBeRemoved(vendorId);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setAddress(address);
    setRemoveAccessConfirmationDialogVisible(true);

  }

  function removeAllStoreAccessClicked() {
    setVendorIdForWhichAccessIsToBeRemoved(undefined);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setRemoveAccessConfirmationDialogVisible(true);

  }

  async function sendUserInvitationLink() {     
    const fetchData = async () => {
      try {
        await Promise.all([
          Api.sendUserInvitationLink(
            userId,
            adminUserId,
            user.emailAddress,
            token
          )
        ]);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmailConfirmation(true);
      } catch (e) {                
        setPersonaUserProfileErrorMessage(helperFunctions.getErrorObject(e));
      }
    }
    fetchData();

  }

  function deleteUserClicked() {
    setDeleteUserConfirmationDialogVisible(true);
  }

  async function deleteUserAsync() {

    try {

      await Api.DeleteUser(
        deleteUserInformation.adminUserId,
        deleteUserInformation.deletedUser,
        token
      );

    } catch (error) {

      if (error !== undefined && error.code === 'API-004')
        setDeleteUserError(error);

      setIsDeletingUser(false);
    }


    setIsDeletingUser(false);

  }

  function handleDeleteUser() {

    setDeleteUserInformation({ adminUserId, deletedUser: userId });

    if (!isDeletingUser)
      setDeleteUserConfirmationDialogVisible(false);
  }

  // #endregion

  // #region Effects

  //pagination and remove access handler for re-mounting store list for user profile
  useEffect(
    () => {

      if (!isAccessBeingRemoved)
        Fetch();
    },
    [storeSearchKeyWord, currentPageNumber, isAccessBeingRemoved]
  );
  //if remove store access fails set error message
  useEffect(
    () => {
      setPersonaUserProfileErrorMessage(isRemoveStoreAccessError);
    },
    [isRemoveStoreAccessError]
  );
  //if remove all store access fails set error
  useEffect(
    () => {      
      setPersonaUserProfileErrorMessage(isRemoveAllStoreAccessError);
    },
    [isRemoveAllStoreAccessError]
  );
  //if user is deleted nav to user page
  useEffect(
    () => {
      if ((!isDeletingUser && isDeleteUserError === null && isDeletingUser !== null)
        || (!isAllAccessBeingRemoved && isRemoveAllStoreAccessError === null && isAllAccessBeingRemoved !== null))
        routeParams.history.goBack();
    },
    [isDeletingUser, isAllAccessBeingRemoved]
  );
  //if deleted user api errors
  useEffect(
    () => {
      if (isDeleteUserError != null)
        setPersonaUserProfileErrorMessage(helperFunctions.getCustomErrorObject(isDeleteUserError));
    },
    [isDeleteUserError]
  );

  useEffect(() => {

    if (deleteUserInformation !== null) {

      try {

        setIsDeletingUser(true);

        deleteUserAsync();

      }
      catch (ex) {

        setDeleteUserError(ex)
        setIsDeletingUser(false);

      }

    }

  }, [deleteUserInformation]);

  // If the user information was specified in the route, set that info
  useEffect(() => {        
    if (routeUser && routeUser.emailAddress && routeUser.firstName && routeUser.lastName) {
      setUserInformation(routeUser);
    }
  }, [routeUser]);
  // #endregion

  // #region International messages
  const messages = defineMessages({
    user_profile__title: {
      id: "user_profile__title",
      description: "User Profile",
      defaultMessage: "User Profile"
    },

    manage_stores_store_information: {
      id: "manage_stores_store_information",
      description: "Store information",
      defaultMessage: "Store information"
    },

    manage_stores_admin: {
      id: "manage_stores_admin",
      description: "Administrator",
      defaultMessage: "Administrator"
    },

    manage_stores__manage_stores: {
      id: "manage_stores__manage_stores",
      description: "Manage store",
      defaultMessage: "Manage store"
    },

    manage_stores__pagination_results_caption: {
      id: "manage_stores__pagination_results_caption",
      description: "Showing results {first}-{last} of {storeCount}",
      defaultMessage: "Showing results {first}-{last} of {storeCount}"
    },
    manage_stores__search_previous: {
      id: "manage_stores__search_previous",
      description: "previous",
      defaultMessage: "previous"
    },
    manage_stores__search_next: {
      id: "manage_stores__search_next",
      description: "next",
      defaultMessage: "next"
    },
    user_profile_resend_invite_link: {
      id: "user_profile_resend_invite_link",
      description: "Send invite",
      defaultMessage: "Send invite"
    },
    add_stores__title: {
      id: "add_stores__title",
      description: "Add store(s)",
      defaultMessage: "Add store(s)"
    },
    user_profile__edit_permissions: {
      id: "user_profile__edit_permissions",
      description: "Edit Permissions",
      defaultMessage: "Edit Permissions"
    },
    user_profile__remove_access: {
      id: "user_profile__remove_access",
      description: "Remove Access",
      defaultMessage: "Remove Access"
    },
    user_profile__remove_access_all: {
      id: "user_profile__remove_access_all",
      description: "Remove access to all stores",
      defaultMessage: "Remove access to all stores"
    },
    user_profile__back: {
      id: "user_profile__back",
      description: "Back to all users",
      defaultMessage: "Back to all users"
    },
    user_profile_no_stores_text: {
      id: "user_profile_no_stores_text",
      description: "User does not have access to any stores.",
      defaultMessage: "User does not have access to any stores."
    },
    user_profile__search_no_users_text: {
      id: "user_profile__search_no_users_text",
      description: "No results found for this search criteria.",
      defaultMessage: "No results found for this search criteria."
    },
    remove_access__title: {
      id: "remove_access__title",
      description: "Remove user access",
      defaultMessage: "Remove user access"
    },
    remove_access_message: {
      id: "remove_access_message",
      description: "Are you sure you want to remove {firstName} {lastName}'S access for {vendorId} at {address}? Click yes to continue. Click no to cancel.",
      defaultMessage: "Are you sure you want to remove {firstName} {lastName}'S access for {vendorId} at {address}? Click yes to continue. Click no to cancel."
    },
    remove_all_access_message: {
      id: "remove_all_access_message",
      description: "Are you sure you want to remove {firstName} {lastName}'S access to all of their stores? Click yes to continue. Click no to cancel.",
      defaultMessage: "Are you sure you want to remove {firstName} {lastName}'S access to all of their stores? Click yes to continue. Click no to cancel."
    },
    remove_access_yes: {
      id: "remove_access_yes",
      description: "Yes",
      defaultMessage: "Yes"
    },
    remove_access_no: {
      id: "remove_access_no",
      description: "No",
      defaultMessage: "No"
    },
    delete_user: {
      id: "delete_user",
      description: "Delete User",
      defaultMessage: "Delete User"
    },
    email_confirmation_title: {
      id: "email_confirmation_title",
      description: "Email Confirmation",
      defaultMessage: "Email Confirmation"
    },
    email_confirmation_message: {
      id: "email_confirmation_message",
      description: "An invitation link has been sent to {firstName} {lastName}.",
      defaultMessage: "An invitation link has been sent to {firstName} {lastName}."
    },
    email_confirmation_ok: {
      id: "email_confirmation_ok",
      description: "Ok",
      defaultMessage: "Ok"
    },
    delete_user__title: {
      id: "delete_user__title",
      description: "Delete user",
      defaultMessage: "Delete user"
    },
    delete_user__message: {
      id: "delete_user__message",
      description: "Are you sure you want to delete this user? Doing so will remove the user from Holman PartnerConnect and their access to any stores will be removed.",
      defaultMessage: "Are you sure you want to delete this user? Doing so will remove the user from Holman PartnerConnect and their access to any stores will be removed."
    },
    delete_user_yes: {
      id: "delete_user_yes",
      description: "Yes",
      defaultMessage: "Yes"
    },
    delete_user_no: {
      id: "delete_user_no",
      description: "No",
      defaultMessage: "No"
    },
    user_profile_search_text_place_holder: {
      id: "user_profile_search_text_place_holder",
      description: "Search for store",
      defaultMessage: "Search for store"
    },
    return: {
      id: "return",
      description: "Return button",
      defaultMessage: "Return"
    },
  });
  // #endregion

  // #region Render
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          StoreSearchKeyword: "",
          StoreList: []
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSearchClick(values.StoreSearchKeyword);
        }}
      >

        {props => {
          const {
            values,
            handleSubmit,
            handleBlur,
            touched,
            errors,
            setFieldValue
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              {<RemoveAccessModal
                FormattedMessageTitle={messages.remove_access__title}
                FormattedMessageBody={vendorIdForWhichAccessIsToBeRemoved != undefined ? messages.remove_access_message : messages.remove_all_access_message}
                FormattedMessageOkButtonText={messages.remove_access_yes}
                FormattedMessageCancelButtonText={messages.remove_access_no}
                onOKClickEvent={handleRemoveStoreAccess}
                onCancelClickEvent={() => { setRemoveAccessConfirmationDialogVisible(false); }}
                showCancelButton={true}
                show={showRemoveAccessConfirmationDialog}
                vendorId={vendorIdForWhichAccessIsToBeRemoved}
                firstName={firstName}
                lastName={lastName}
                address={address}
                isFetchingData={isAccessBeingRemoved}
                isFetchingDataAll={isAllAccessBeingRemoved}
              />}
              {<EmailConfirmation
                title={messages.email_confirmation_title}
                message={messages.email_confirmation_message}
                okButtonText={messages.email_confirmation_ok}
                onOKClickEvent={() => { setEmailConfirmation(false); }}
                show={showEmailConfirmation}
                firstName={firstName}
                lastName={lastName}
              />}
              {<DeleteUserModal
                FormattedMessageTitle={messages.delete_user__title}
                FormattedMessageBody={messages.delete_user__message}
                FormattedMessageOkButtonText={messages.delete_user_yes}
                FormattedMessageCancelButtonText={messages.delete_user_no}
                onOKClickEvent={handleDeleteUser}
                onCancelClickEvent={() => { setDeleteUserConfirmationDialogVisible(false); }}
                showCancelButton={true}
                show={showDeleteUserConfirmationDialog}
                isFetchingData={isAccessBeingRemoved}
                isFetchingDataAll={isAllAccessBeingRemoved}
              />}

              <div>

                <PageLayout>
                  <FormWrapper
                    key="userProfileErrorMessage"
                    id="userProfileErrorMessage"
                    formErrors={personaUserProfileErrorMessage}
                  />
                  <Row>
                    <Col>
                      <Subhead>
                        <FormattedMessage {...messages.user_profile__title} />
                      </Subhead>
                      <NameHeader>
                        {user == null
                          ? false
                          :
                          <FieldGroup>
                            <div key="userText">
                              <span> {user.firstName}&nbsp;{user.lastName}
                                <br />
                                {user.emailAddress}
                              </span>
                            </div>                            
                          </FieldGroup>
                        }

                      </NameHeader>
                    </Col>
                    <Col>
                      <ActionButton onClick={() => {
                        sendUserInvitationLink();
                      }}>
                        <Image src="/assets/Email_Icon.png" />
                        <ButtonBold><FormattedMessage {...messages.user_profile_resend_invite_link} /></ButtonBold>
                      </ActionButton>
                      <ActionButton onClick={() => { deleteUserClicked(); }}>
                        <Image src="/assets/Delete_Button_Light_Blue.png" />
                        <ButtonBold><FormattedMessage {...messages.delete_user} /></ButtonBold>
                      </ActionButton>
                      {stores == null || stores.length == 0
                        ? false :
                        <ActionButton onClick={() => { removeAllStoreAccessClicked(); }}>
                          <Image src="/assets/Remove_Access_Light_Blue.png" />
                          <b> <FormattedMessage {...messages.user_profile__remove_access_all} /></b>
                        </ActionButton>
                      }
                      <div style={{ "paddingTop": "1em" }}>
                        <Link to={`/Persona/Users/${userId}/stores/add`} >
                          <Image src="/assets/VMS_33button_add_Clear background.png" />
                          <LinkBold><FormattedMessage {...messages.add_stores__title} /></LinkBold>
                        </Link>
                      </div>
                    </Col>
                  </Row>
          
                
                  {loadingState == loadingStates.loading
                    ? <Spinner />
                    : <div>  <Row style={{paddingTop: "1em"}}> {stores == null || stores.length == 0
                      ?
                      <Col/>
                      : <div>
                          <FormTextGray>
                            <FormattedMessage
                              values={{
                                first: (currentPageNumber * pageSize) + 1,
                                last: (currentPageNumber * pageSize) + stores.length,
                                storeCount:storeCount
                              }}
                              {...messages.manage_stores__pagination_results_caption}
                            />


                          </FormTextGray>
                          </div>
                      }
                        
                  <div css={`
                    display: flex;
                    justify-content: flex-end;
                    padding-right : 2em;
                  `}>                        
                    <div css={`
                      position: relative;
                    `}>

                      <Input
                        id="txtStoreSearch"
                        type="text"
                        value={values.StoreSearchKeyword}
                        onChange={e => {
                          setFieldValue(
                            "StoreSearchKeyword",
                            e.target.value,
                            true
                          );


                        }}
                        placeholder={formatMessage(
                          messages.user_profile_search_text_place_holder
                        )}
                        onBlur={handleBlur}
                        error={errors.StoreSearchKeyword}
                        touched={touched.StoreSearchKeyword}
                      />
                      
                      {!!values.StoreSearchKeyword &&
                        <ClearImageStyled 
                          src='/assets/VMS_33button_kill_clear_inline.png'                           
                          onClick={()=>{
                            setStoreSearchKeyword('');
                            setCurrentPageNumber(0); 
                            setFieldValue("StoreSearchKeyword", '', true);                                
                          }}
                        />
                      }
                    </div>
                    
                    <div>
                      &nbsp;
                      <span style={{ display: "inline-block" }}>
                        {searchResultLoadingState ==
                          loadingStates.loading
                          ? <div
                            style={{
                              display: "inline-block",
                              width: "25px",
                              height: "25px",
                              position: "inherit",
                              "vertical-align": "top"
                            }}
                          >
                            <Spinner />
                          </div>
                          : <SubmitImage
                            type="image"
                            src="/assets/VMS_search_button_blue.png"
                            alt="Submit"
                            onclick={handleSubmit}
                          />}
                      </span>
                      <div>
                        {errors.userSearchKeyWord &&
                          touched.userSearchKeyWord &&
                          <InputFeedback>
                            {errors.userSearchKeyWord}
                          </InputFeedback>}
                      </div>
                    </div>
                  </div>
              </Row>
     
                      <br />
                      <Row fontWeight="bold">
                        <Col width="23em">
                          <Label>
                          <FormattedMessage {...messages.manage_stores_store_information} />

                          </Label>
                        </Col>
                        <Col width="11em">
                          <Label>
                          <FormattedMessage {...messages.manage_stores_admin} />
                          </Label>
                        </Col>

                        <Col width="15em">
                          <Label>
                            <FormattedMessage {...messages.user_profile__edit_permissions} />
                          </Label>
                        </Col>
                        <Col Col width="15rem">
                          <Label>
                            <FormattedMessage {...messages.user_profile__remove_access} />
                          </Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col width="100%">
                          <div
                            style={{
                              "borderTop": "2px solid black",
                              width: "100%"
                            }}
                          />

                        </Col>
                      </Row>

                      {(stores == null || stores.length == 0) && storeSearchKeyWord === ''
                        ? <div>
                            <NoResultsText>
                              <FormattedMessage                                
                                  {...messages.user_profile_no_stores_text}
                              />
                            </NoResultsText>
                        </div>
                        :
                       stores == null || stores.length == 0
                        ? <div>
                          <Row>

                            <NoResultsText>
                              <FormattedMessage {...messages.user_profile__search_no_users_text} />
                            </NoResultsText>

                          </Row>
                          
                        </div>
                        : !!stores &&
                        stores.map(vendor => {
                          return (
                            <div
                              key={vendor.id}
                              style={{
                                "borderBottom": "1px solid grey",
                                width: "100%",
                                "paddingTop": "5px"
                              }}
                            >
                              <Row>

                                <Col width="37em">
                                  <Link to={`/Persona/Stores/${vendor.id}`}>
                                    {vendor.id}
                                    {" "}
                                    {vendor.storeNumber != "" ?
                                      "/ " + vendor.storeNumber : undefined
                                    }
                                  </Link><br />
                                  {vendor.businessName} <br />
                                  {vendor.businessAddress.address1}
                                  {" "}
                                  <br />
                                  {vendor.businessAddress.city}
                                  {", "}
                                  {vendor.businessAddress.state}
                                  {" "}
                                  {" "}
                                  {" "}
                                  {vendor.businessAddress.zipCode}
                                  <br />
                                  {vendor.businessPhone.substr(0, 3) +
                                    "-" +
                                    vendor.businessPhone.substr(
                                      3,
                                      3
                                    ) +
                                    "-" +
                                    vendor.businessPhone.substr(6, 4)}
                                </Col>

                                <Col width="22em">
                                {vendor.isUserStoreAdmin}
                                </Col>

                                <Col width="22em" padTop="5px">

                                  <Link to={{
                                    pathname: `/Persona/Users/${userId}/Stores/${vendor.id}/edit`,
                                    state: { user: user, vendor }
                                  }
                                  }>
                                    <SubmitImage
                                      type="image"
                                      src="/assets/VMS_Edit_Permissions2.png"
                                      alt="Submit"
                                    />
                                  </Link>

                                </Col>
                                <Col width="11em" padTop="5px">
                                  <Image
                                    onClick={() => {
                                      removeStoreAccessClicked(vendor.id, vendor.businessAddress.address1);
                                    }}
                                    src="/assets/Remove_Access_Light_Blue.png"

                                  />
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      {stores == null || stores.length == 0
                        ? false :
                        <Row>
                          <Col width="28rem" />
                          <Col width="38rem">
                            {storeCount <= 20 ? false :
                              <ReactPaginate
                                previousLabel={formatMessage(
                                  messages.manage_stores__search_previous
                                )}
                                nextLabel={formatMessage(
                                  messages.manage_stores__search_next
                                )}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                className={"pagination pagination-lg"}
                                activeClassName={"page-item active"}
                                pageLinkClassName={"page-link"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                disableInitialCallback={true}
                                forcePage={currentPageNumber}
                              />
                            }
                          </Col>
                          <Col width="15rem" />
                        </Row>}
                    </div>}
                </PageLayout>
                <Row>

                  <Col width="35rem" padLeft="3em" padBottom="3em" padTop={storeCount <= 20 ? "11em" : "1em"}>

                    <div onClick={routeParams.history.goBack}>
                      <Image src="/assets/VMS_33button_back_blue2.png" />
                      <LinkBold style={{ "paddingLeft": "5px" }}><FormattedMessage {...messages.return} /></LinkBold>
                    </div>
                  </Col>
                </Row>
              </div>

            </form>
          );
        }}

      </Formik>

    </div>
  );
  // #endregion
};
// #endregion
// #region Props
const mapStateToProps = (state, props) => {

  return {
    token: state.appState.getIn(["serverData", "shared", "personaToken"]),
    userId: props.routeParams.match.params.userId,
    adminUserId: state.appState.getIn([
      "serverData",
      "shared",
      "personaUserId"
    ])
  };
};
// #endregion

export default connect(mapStateToProps)(injectIntl(UserProfile));
