// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
//import './Header.css';

const Header = (props) => {
    const messages = defineMessages({                        
        dri_val_complaint_title: {
            id: 'dri_val_complaint_title',
            description: 'title',
            defaultMessage: 'Complaint'
        },                              
    });

    return <div>

        <div className='app__big_title'>
            <FormattedMessage {...messages.dri_val_complaint_title}/>
        </div>        

    </div>
}

export default Header;

