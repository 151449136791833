// libraries
import React from 'react';
import lodash from 'lodash';

/// This component is used to download files via AJAX.
/// Browsers do not support downloading files via AJAX so this is a work-around.
/// Basically it creates a temporary A element in the dom, and clicks it.
/// DO NOT use this for regular links

class DownloadAJAXFile extends React.Component {                        
    constructor(props) {
        super(props);        
        this.id = lodash.uniqueId();
        this.elementId = `download_ajax_file${this.id}`;
        this.tempElementId = `download_ajax_file${this.id}__temp`;
    }    

    onDownloadClickHandler = () => {                
        let downloadDiv = document.getElementById(this.elementId);
        let linkElement = document.createElement('a');

        linkElement.setAttribute('id', this.tempElementId)            
        linkElement.setAttribute('href', this.props.url);            
        downloadDiv.appendChild(linkElement);

        let clickEvent;
        // IE
        if(document.createEvent) {                
            clickEvent = document.createEvent("MouseEvent");
            clickEvent.initMouseEvent("click",true,true,window,0,0,0,0,0,false,false,false,false,0,null);
        } // real browsers
        else {
            clickEvent = new MouseEvent("click", {
                "view": window,
                "bubbles": true,
                "cancelable": false
            });
        }
        linkElement.dispatchEvent(clickEvent);

        // Clean up temporary link.
        let removeLink = document.getElementById(this.tempElementId);
        removeLink.parentNode.removeChild(removeLink);        
    }

    render() {        
        return <div>

            <div onClick={this.onDownloadClickHandler}>
                {this.props.children}
            </div>

            <div id={this.elementId}></div>
            
        </div>
    }
}



export default DownloadAJAXFile;