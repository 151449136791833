import {createSelector} from 'reselect';
import {Map, List} from 'immutable';
import {LINE_ITEM_STATUS, LINE_ITEM_TYPE} from 'constants/App';
import moment from 'moment';
import {emptyStringIfUndefined, emptyMapIfUndefined, falseIfUndefined} from 'utils/HelperFunctions';
import * as constants from 'constants/App';


const approvedAmountTotalSelector = (state, props) => {

        return calculateApprovedTotal(state);
};

const pendingAmountTotalSelector = (state, props) => {

        return calculatePendingTotal(state);
};

const calculateApprovedTotal = (state) => {
    return state.appState.getIn(['serverData', 'adjustment', 'lineItems']) == undefined ? 0 :
        state.appState.getIn(['serverData', 'adjustment', 'lineItems'])
            .filter(li => li.get('approvalStatus') == LINE_ITEM_STATUS.APPROVED
            && !(li.get('lineItemType') == LINE_ITEM_TYPE.TAX
            || li.get('lineItemType') == LINE_ITEM_TYPE.CANADA_SALES_TAX))
            .reduce(sum, 0);
};

const calculatePendingTotal = (state) => {
    return state.appState.getIn(['serverData', 'adjustment', 'lineItems']) == undefined ? 0 :
        state.appState.getIn(['serverData', 'adjustment', 'lineItems'])
            .filter(li => li.get('approvalStatus') == LINE_ITEM_STATUS.PENDING
            && !(li.get('lineItemType') == LINE_ITEM_TYPE.TAX
            || li.get('lineItemType') == LINE_ITEM_TYPE.CANADA_SALES_TAX))
            .reduce(sum, 0);
};

const sum = (total, item) => {
    return total + item.get('totalAmount');
};

const AdjustmentContainer = createSelector(

    approvedAmountTotalSelector,
    pendingAmountTotalSelector,
    (approvedAmountTotal,
     pendingAmountTotal,
     ) => {
        return {
            approvedAmountTotal,
            pendingAmountTotal,
        }
    }
);

export default AdjustmentContainer;