import React from 'react';
import {defineMessages,FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import './AddComplaint.css';

class AddComplaint extends React.Component {

    render() {

        const messages = defineMessages({
            add_another_complaint: {
                id: 'add_another_complaint',
                description: 'Add another complaint',
                defaultMessage: 'Add another complaint'
            }
        });
        return <div className={classNames('add-another-complaint',
                                         {'add-another-complaint--disabled': !this.props.canAddAnotherComplaint})}
                    role="button"
                    onClick={this.props.canAddAnotherComplaint ? this.props.onAddAnotherComplaintClick : undefined}>
            <FormattedMessage {...messages.add_another_complaint}/>
            {' '}
            <img src={this.props.canAddAnotherComplaint ? "/assets/VMS_28button_add_blue.png" : "/assets/VMS_28button_add_grey.png"}/>
        </div>
    }
}

export default AddComplaint;