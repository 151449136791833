// libraries
import React from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
// our stuff
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import BusyLayout from '../components/BusyLayout';
import PageLayout from '../components/PageLayout';
import Header from '../components/Header';
import CurrentDocumentsStep from './CurrentDocumentsStep';
import UploadStep from './UploadStep';
import BrowseStep from './BrowseStep';
import FormWrapper from 'components/FormWrapper';

class VendorDocuments extends React.Component {                    
    constructor(props) {
        super(props);
        this.state = {
            fileForUpload: undefined
        };
    }  

    componentDidMount() {        
        this.props.onLoading();
    }

    componentWillUnmount() {        
        this.props.onUnloading();
    }      

    onDrop(acceptedFiles) {            
        if (acceptedFiles && acceptedFiles.length>0)
        {            
            this.setState({
                // have to save file in state (Redux cannot store files)
                fileForUpload: acceptedFiles[0] // just grab first file (only allow 1 at a time)
            });
        }
    }    

    render() {
        if (this.props.isBusy) 
        {
            return <BusyLayout><Spinner/></BusyLayout>;
        }        

        return <div>
        
            <PageLayout whiteBackground={this.props.documentsVisible}>                            

                <div>                        
                    
                    <Header                             
						documentsVisible={this.props.documentsVisible}
						onShowDocuments={this.props.onShowDocumentsClick}
						onHideDocumentsClick={this.props.onHideDocumentsClick}
                    />
                    <FormWrapper key='getDocumentErrorMessage' id="getDocumentErrorMessage" formErrors={this.props.errors}/>

                    {this.props.documentsVisible &&
                        <div>

                            { this.props.step === constants.VENDOR_DOCUMENT_STEPS.DOCUMENTS ?
                
                                <CurrentDocumentsStep/>

                            : this.props.step === constants.VENDOR_DOCUMENT_STEPS.BROWSE ?

                                <BrowseStep onDrop={this.onDrop.bind(this)}/>

                            : this.props.step === constants.VENDOR_DOCUMENT_STEPS.UPLOAD ?

                                <UploadStep file={this.state.fileForUpload}/>

                            : undefined }

                        </div>
                    }

                </div>                            

            </PageLayout>

        </div>        
    }
}

const mapStateToProps = (state, props) => {    
    return {
        isBusy: state.appState.getIn(['uiData', 'vendorDocuments', 'isBusy']),
        allowToggleDocumentVisible: state.appState.getIn(['uiData', 'vendorDocuments', 'allowToggleDocumentVisible']),
        step: state.appState.getIn(['uiData', 'vendorDocuments', 'step']),        
        documentsVisible: state.appState.getIn(['uiData', 'vendorDocuments', 'documentsVisible']),  
        errors: state.appState.getIn(['uiData', 'vendorDocuments', 'serverError'])              
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoading: () => {                        
            dispatch({type: constants.VENDOR_DOCUMENTS_LOADING});                                
        },
        onUnloading: () => {                        
            dispatch({type: constants.VENDOR_DOCUMENTS_UNLOADING})
        },
        onShowDocumentsClick: () => {                        
			dispatch({type: constants.FETCH_VENDOR_DOCUMENTS});
            dispatch({type: constants.SHOW_VENDOR_DOCUMENTS, visible: true})
        },
        onHideDocumentsClick: () => {
            dispatch({type: constants.SHOW_VENDOR_DOCUMENTS, visible: false})
        },        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorDocuments);