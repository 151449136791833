import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';
import { zeroIfUndefined ,addFloats } from 'utils/HelperFunctions';

export function uiDataCarWashView(state = initialAppState.getIn(['uiData', 'carWashAccount']), action) {
    switch (action.type) {
        case "CLEAR_CREATE_PO_DETAILS":
            return state.setIn(['poDetails', 'serviceDate'], undefined)
            .setIn(['poDetails', 'invoiceNo'], undefined)
            .setIn(['poDetails', 'odometer'], 0)
            .setIn(['poDetails', 'carWashCost'], 0)
            .setIn(['poDetails', 'detailCarWashCost'], 0)
            .setIn(['poDetails', 'taxes', 'HST'],0)
            .setIn(['poDetails', 'taxes', 'QST'],0)
            .setIn(['poDetails', 'taxes', 'PST'],0)
            .setIn(['poDetails', 'taxes', 'GST'],0)
            .setIn(['poDetails', 'taxes', 'tax'],0)
            .setIn(['poDetails', 'taxAmount'],0)
            .set('creatingPOError',undefined)
            .set('isCreatingPO', false)
            .set('isDirty', false)
            .set('poFormFlag',false);
        case "FETCH_CAR_WASH_CLIENT_NUMBERS_REQUESTED":
            return state.set('isFetchingData', true).set('poFormFlag',false);
        case "FETCH_CAR_WASH_CLIENT_NUMBERS_ACCEPTED":
            return state.set('vendorClientNumbers',fromJS(action.vendorClientNumber)).setIn(['vehicleSearchError', 'vehicleNumberError'],false)
            .setIn(['vehicleSearchError', 'clientNumberError'],false).set('isFetchingData', false).setIn(['vehicleSearchError', 'vehicleDetailsDispatchError'],"");
        case "FETCH_CAR_WASH_CLIENT_NUMBERS_REJECTED":
            return state.setIn(['vehicleSearchError', 'clientNumberDispatchError'], action.errorMessage).set('isFetchingData', false);
        case "FETCH_CAR_WASH_DETAILS_REQUESTED":
            return state.set('isSearchingData', true).setIn(['vehicleSearchError', 'vehicleDetailsDispatchError'],"")
                .setIn(['poCreationError', 'serviceDateError'],undefined)
                .setIn(['poCreationError', 'invoiceNoError'], undefined)
                .setIn(['poCreationError', 'carWashCostError'], undefined)
                .setIn(['poCreationError', 'detailCarWashCostError'],undefined)
                .setIn(['poCreationError', 'taxAmountError'],undefined)
                .setIn(['poCreationError', 'odometerError'],undefined)
                .setIn(['poCreationError', 'taxQSTError'],undefined)
                .set('carWashServiceTypeSelection', true)
                .set('creatingPOError',undefined)
                .set('isCreatingPO', false)
                .set('isDirty', false)
                ;
        case "FETCH_CAR_WASH_DETAILS_ACCEPTED":
            return state.setIn(['vehicleDetails'],fromJS(action.vehicleCarWashDetails)).set('isSearchingData', false).setIn(['vehicleSearchError', 'vehicleDetailsDispatchError'],"");
        case "FETCH_CAR_WASH_DETAILS_REJECTED":
            return state.setIn(['vehicleSearchError', 'vehicleDetailsDispatchError'], action.errorMessage).set('isSearchingData', false).set('poFormFlag',false);
        case "FETCH_CAR_WASH_ORDER_REQUESTED":
            return state.set('isFetchingData', true);
        case "FETCH_CAR_WASH_ORDER_ACCEPTED":
            return state.set('orderSearchDetails',fromJS(action.orderSearchDetails)).set('isFetchingData', false);
        case "FETCH_CAR_WASH_ORDER_REJECTED":
            return state.setIn(['vehicleSearchError', 'vehicleOrdersError'], action.errorMessage).set('isFetchingData', false);
        case "TOGGLE_SHOW_ALL_VENDOR_ACTION_NEEDED_PIP":
            return state.set('showAllVendorActionNeededPIP', !state.get('showAllVendorActionNeededPIP'));
        case "TOGGLE_SHOW_ALL_VENDOR_ACTION_NEEDED_APPROVAL":
            return state.set('showAllVendorActionNeededApproval', !state.get('showAllVendorActionNeededApproval'));
        case "CAR_WASH_REPAIR_TYPE_CHANGE_SELECTION":
            return state.set('carWashServiceTypeSelection', action.carWashRepairType, !state.get('carWashServiceTypeSelection', action.carWashRepairType));
        case "SAVE_PO_DETAILS":

            let value = action.poDetails.get('value');
            switch (action.poDetails.get('key')) {
                case constants.PO_FIELD_TYPE.SERVICE_DATE:
                    return state.setIn(['poDetails', 'serviceDate'], value);

                case constants.PO_FIELD_TYPE.INVOICE_NO:
                    return state.setIn(['poDetails', 'invoiceNo'], value);

                case constants.PO_FIELD_TYPE.ODOMETER:
                    return state.setIn(['poDetails', 'odometer'], value);
                case constants.PO_FIELD_TYPE.CAR_WASH_COST:
                    return state.setIn(['poDetails', 'carWashCost'], value);
                case constants.PO_FIELD_TYPE.DETAIL_CAR_WASH_COST:
                    return state.setIn(['poDetails', 'detailCarWashCost'], value);
                case constants.PO_FIELD_TYPE.TAX_AMOUNT:
                    //Check if tax amount is for Canada, then consider added tax amount;
                    let taxAmount = value != '' ? value : 0.00;
                    if (action.poDetails.get('taxType') != 'tax') {
                        let HST = zeroIfUndefined(state.getIn(['poDetails', 'taxes', 'HST']));
                        let GST = zeroIfUndefined(state.getIn(['poDetails', 'taxes', 'GST']));
                        let QST = zeroIfUndefined(state.getIn(['poDetails', 'taxes', 'QST']));
                        let PST = zeroIfUndefined(state.getIn(['poDetails', 'taxes', 'PST']));

                        //If already tax amount exists, so user is trying to update the new tax amount
                        if (action.poDetails.get('taxType') == 'HST')
                            HST = taxAmount;
                        else if (action.poDetails.get('taxType') == 'GST')
                            GST = taxAmount;
                        else if (action.poDetails.get('taxType') == 'QST')
                            QST = taxAmount;
                        else if (action.poDetails.get('taxType') == 'PST')
                            PST = taxAmount;

                        if (HST > 0 || GST || QST || PST) {
                            taxAmount = addFloats(HST,GST,QST,PST);
                            state.setIn(['poDetails', 'taxAmount'], taxAmount);
                        }
                    }

                    return state.setIn(['poDetails', 'taxAmount'], taxAmount)
                        .setIn(['poDetails', 'taxes', action.poDetails.get('taxType')], value != '' ? value : 0.00);
                default:
                    return state;
            }
        case "CREATE_CAR_WASH_PO_REQUESTED":
            return state.set('isCreatingPO', true);
        case "CREATE_CAR_WASH_PO_ACCEPTED":
            return state.set('isCreatingPO', false)
                .set('poFormFlag',false)
                .setIn(['poDetails', 'serviceDate'], undefined)
                .setIn(['poDetails', 'invoiceNo'], undefined)
                .setIn(['poDetails', 'odometer'], 0)
                .setIn(['poDetails', 'carWashCost'], 0)
                .setIn(['poDetails', 'detailCarWashCost'], 0)
                .setIn(['poDetails', 'taxes', 'HST'],0)
                .setIn(['poDetails', 'taxes', 'QST'],0)
                .setIn(['poDetails', 'taxes', 'PST'],0)
                .setIn(['poDetails', 'taxes', 'GST'],0)
                .setIn(['poDetails', 'taxes', 'tax'],0)
                .setIn(['poDetails', 'taxAmount'],0)
                .set('isDirty', false);
        case "CREATE_CAR_WASH_PO_REJECTED":
            return state.set('isCreatingPO', false).set('creatingPOError',action.errorMessage);
        case "VALIDATION_PO_CREATION":
            let fieldNamePOCreation = action.requestPOInfo.name;
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.SERVICE_DATE)
                return state.setIn(['poCreationError', 'serviceDateError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.INVOICE_NO)
                return state.setIn(['poCreationError', 'invoiceNoError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.CAR_WASH_COST)
                return state.setIn(['poCreationError', 'carWashCostError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.DETAIL_CAR_WASH_COST)
                return state.setIn(['poCreationError', 'detailCarWashCostError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.TAX_AMOUNT)
                return state.setIn(['poCreationError', 'taxAmountError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.ODOMETER)
                return state.setIn(['poCreationError', 'odometerError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.TAX_HST)
                return state.setIn(['poCreationError', 'taxHSTError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.TAX_GST)
                return state.setIn(['poCreationError', 'taxGSTError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.TAX_QST)
                return state.setIn(['poCreationError', 'taxQSTError'], action.requestPOInfo.value);
            if (fieldNamePOCreation === constants.PO_FIELD_ERROR_TYPE.TAX_PST)
                return state.setIn(['poCreationError', 'taxPSTError'], action.requestPOInfo.value);
           else
                return state;
        case "CAR_WASH_FORM_IS_DIRTY":
            return state.set('isDirty', action.isDirty);
        case "FETCH_CAR_WASH_PARAMETERS_ACCEPTED":
            return state.set('poFormFlag',true);
        case "FETCH_TAX_DATA_RESOLVED":
            return state.set('applicableTaxTypes',fromJS(action.applicableTax));
        default:
            return state;
    }
}

export function serverDataCarWashView(state = initialAppState.getIn(['serverData', 'carWashAccount']), action) {
    switch (action.type) {
        case "FETCH_CAR_WASH_PARAMETERS_REQUESTED":
            return state.set('isFetchingData', true);
        case "FETCH_CAR_WASH_PARAMETERS_ACCEPTED":
            return state.setIn(['vehicleDetails','parameterDetails'],fromJS(action.carWashParameterDetails)).set('isFetchingData', false);
        case "FETCH_CAR_WASH_PARAMETERS_REJECTED":
            return state.set('isFetchingData', false);
        default:
            return state;
    }
}