import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Popover } from 'react-bootstrap';
import { SplitButton, MenuItem } from 'react-bootstrap';
import moment from 'moment';

import './CraneInspectionFormHeader.css';

class CraneInspectionFormHeader extends React.Component {

	render() {
		const messages = defineMessages({			
			crane_inspection_form__customer: {
				id: 'crane_inspection_form__customer',
				description: 'Crane inspection form Customer',
				defaultMessage: 'Customer'
			},
			crane_inspection_form__address: {
				id: 'crane_inspection_form__address',
				description: 'Crane inspection form address',
				defaultMessage: 'Address'
			}
		});

		return (
			<div className='row crane_inspection_form__header_row'>

				<div className='col-md-4 crane_inspection_form__header_col'>
					<label>
						<FormattedMessage {...messages.crane_inspection_form__customer} />
					</label>
					<div>{this.props.formData.get('customer')}</div>
				</div>

				<div className='col-md-8 crane_inspection_form__header_col'>
					<label>
						<FormattedMessage {...messages.crane_inspection_form__address} />
					</label>
					<div>{this.props.formData.get('address')}</div>
				</div>
			</div>
		)
	}
};

export default CraneInspectionFormHeader;