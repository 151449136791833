// #region Imports
import { useState, useEffect } from "react";
import * as Api from "utils/Api";
// #endregion

// #region Component
export default function useRemoveStoreAccess(initialToken) {

  // #region State
  const [removeAccessInformation, setRemoveAccessInformation] = useState(null);
  const [isAccessBeingRemoved, setIsAccessBeingRemoved] = useState(false);
  const [error, setError] = useState(null);
  const [token] = useState(initialToken);

  // #endregion

  // #region EventHandler

  async function removeUserPermissionStoreAccessAsync() {

    try {
      await Api.RemoveUserPermissionStoreAccess(
        removeAccessInformation.userId,
        removeAccessInformation.storeId,
        token
      );
      setIsAccessBeingRemoved(false);
    }
    catch (ex) {
      setError(ex)
      setIsAccessBeingRemoved(false);    
    }    
  }

  // #endregion

 // #region Effect
  useEffect(() => {

    if (removeAccessInformation !== null) {

      setIsAccessBeingRemoved(true);

      removeUserPermissionStoreAccessAsync();
            
    }

  }, [removeAccessInformation]);
 // #endregion

  return [setRemoveAccessInformation, isAccessBeingRemoved, error]


}
// #endregion
