// #region Imports
import React, { useState, useEffect, useRef } from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import ReactPaginate from "react-paginate";
import * as Api from "utils/Api";
import * as helperFunctions from "utils/HelperFunctions";
import Spinner from 'components/Spinner';
import FormWrapper from "components/FormWrapper";
import SelectedStores from "./SelectedStores";
import CheckCircle from 'components/CheckCircle';
// #endregion

// #region Styled Components
const Main = styled.div`	
  padding-bottom: 1rem;
`;

const ModalCloseStyled = styled.div`
    padding-top: 10px;
    font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 500;
    color: #0f4e95;
    cursor: pointer;
`;
    
const ModalMainHeaderStyled = styled.div`
  font-size: 42px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 300;
  color: #5587B7;
  padding-top: 15px;
  padding-left: 0px;
  line-height: normal;
  margin-bottom: -10px;
`;

const ModalBodyStyled = styled.div`
  border: solid rgb(140, 170, 202);
  border-top-width: 1px;
  border-right-width: 2px;    
  border-bottom-width: 1px;
  border-left-width: 2px;    
  margin-top: 3em;
  margin-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
`;

const ButtonRowStyled = styled.div`	
  display: flex;
  justify-content: space-between;
`;

const ButtonStyled = styled.div`
  cursor: ${({disable}) => disable ? `not-allowed` : `pointer`};
  padding-top: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 700;
  color: #5587b7;
`;

const Header = styled.div`
	text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const SearchWrapperStyled = styled.div`
  display: flex;  
  padding-bottom: 1rem;
  align-items: center;
`;

const SearchInput = styled.input`
	width: 550px;   
  font-family: "Gotham SSm A", "Gotham SSm B";
  padding-left: 12px;
  padding-bottom: 5px;
  border: 1px solid #5587b7;
  &:-ms-input-placeholder{
    color: grey;
}
`;

const ClearImageStyled = styled.img`
  display: block;
  position: absolute;
  top: 2px;
  right: 3px; 
  cursor: pointer;                                
  width: 22px;
  height: 22px;
`;

const SearchIconInputStyled = styled.input`
  margin-left: 1rem;
  vertical-align: bottom;
`;

const HR = styled.hr`
  border-top: 1px solid ${props => props.theme.color.ariGray};
`;

const TitleStyled = styled.div`
	text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

const SpinnerContainer = styled.div`	
  display: inline-block;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const NoResultsStyled = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: ${props => props.theme.color.ariGray};
  font-style: normal;
  font-weight: 300;  
`;

const ResultCountStyled = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
	font-family: "Gotham SSm A", "Gotham SSm B";
	color: ${props => props.theme.color.ariGray};
  font-style: normal;
  font-weight: 300;  
`;

const HeaderRowStyled = styled.div`
	display: flex;	
	justify-content: flex-start;

	font-weight: normal;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  color:black;
	font-size: 14px;
	
	padding-bottom: 1rem;
  border-bottom: 2px solid #CCCCCC;
`;

const ResultsRowStyled = styled.div`
	display: flex;	
	justify-content: flex-start;

	font-weight: normal;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  color:black;
	font-size: 14px;
	
  padding-top: 5px;
	padding-bottom: 1rem;
  border-bottom: 1px solid black;
  border-bottom: 1px solid #CCCCCC;
`;

const SelectColStyled = styled.div`
  width: 8rem;  
  overflow-wrap: break-word;
  font-weight: bold;
  align-self: center;
`;

const AriIdColStyled = styled.div`
  width: 20rem;  
  overflow-wrap: break-word;  
`;

const StoreAddressColStyled = styled.div`
  width: 34rem;  
  overflow-wrap: break-word;  
`;

const HeaderStyled = styled.div`  
  font-weight: bold;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  color: black;
  font-size: 14px;
  white-space: nowrap;
`;

const FooterStyled= styled.div`  
  display: flex;
  justify-content: center;
`;

// #endregion

// #region Component
const SearchStores = ({
  selectedStores,
  token,  
  routeParams,
  personaUserId,
  intl: { formatMessage },
  onStoreSelect,
  onRemoveStore,
  onAddStores,
  onContinue  
}) => {
  const userId = routeParams.match.params.userId;
  
  // #region State
  const loadingStates = { loading: "loading", loaded: "loaded", error: "error" };
  const pageSize = 20;
  const adminAccess = "Y";
  const [loadingState, setLoadingState] = useState(loadingStates.loading);
  const [error, setError] = useState();  
  const [searchCurrentText, setSearchCurrentText] = useState('');
  const [storeSearchKeyword, setStoreSearchKeyword] = useState('');
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState();
  const [storeCount, setStoreCount] = useState(0);
  const [stores, setStores] = useState([]);    
  // #endregion

  // #region Ref    
  // #endregion

  // #region Effects   
  useEffect(
    () => {      
      let didCancel = false;
      
      const fetchData = async () => {

        try {
          setLoadingState(loadingStates.loading);
          
          const [response] = await Promise.all([
            Api.fetchStores(
              personaUserId,
              currentPageNumber == undefined ? 0 : currentPageNumber,
              pageSize,
              storeSearchKeyword,
              adminAccess,
              token
            )
          ]);
  
          if (!didCancel) {
            setStoreCount(response.vendorCount);
            setStores(response.vendors);
            setPageCount(Math.ceil(response.vendorCount / pageSize));
            setLoadingState(loadingStates.loaded);            
          }
        } catch (e) {
          
          if (!didCancel) {
            setLoadingState(loadingStates.error);                         
            setError(helperFunctions.getErrorObject(e));
          }
        }
      };
  
      fetchData();
  
      return () => {
        didCancel = true;
      };            
    },
    [storeSearchKeyword, currentPageNumber, personaUserId, pageSize, adminAccess, token]
  );
  // #endregion

  // #region Event handlers
  const handleSubmit = (event) => {        
    const value = event.target.search.value;
    setCurrentPageNumber(0);        
    setStoreSearchKeyword(value);
    event.preventDefault();
  }

  const handlePageClick = (data) => {
    setCurrentPageNumber(data.selected);
  }

  const handleSelectClick = vendor => (name, checked) => {
    if (checked) {
      onRemoveStore(vendor.id);
    }
    else {
      onStoreSelect(vendor);
    }
  }

  const handleSelectAllStoresClick = () => {    
    const fetchData = async () => {
      try {                        
        const response = await Api.fetchStores(
          personaUserId,
          0,
          10000,
          storeSearchKeyword,
          adminAccess,
          token
        );        
        response.vendors.forEach(vendor => {          
          onStoreSelect(vendor);
        });                            
      } catch (e) {
        setError(helperFunctions.getErrorObject(e));        
      }
    };

    fetchData();
  }
  // #endregion

  // #region International messages
  const messages = defineMessages({    
    close_dialog: {
      id: "close_dialog",
      description: "  Close",
      defaultMessage: "Close"
    },                
    add_stores_to_store_search_caption: {
      id: "add_stores_to_store_search_caption",
      description: "Search for stores",
      defaultMessage: "Search for stores"
    },    
    search_stores_caption: {
      id: "search_stores_caption",
      description: "Find and select stores",
      defaultMessage: "Find and select stores"
    },    
    select_stores_caption: {
      id: "select_stores_caption",
      description: "Selected stores",
      defaultMessage: "Selected stores"
    },
    add_stores_to_user__continue: {
      id: "add_stores_to_user__continue",
      description: "Finish selecting stores and continue",
      defaultMessage: "Finish selecting stores and continue"
    },
    add_stores_to_user__no_store_found: {
      id: "add_stores_to_user__no_store_found",
      description: "No store found",
      defaultMessage: "No store found"
    },
    add_stores_to_user__select: {
      id: "add_stores_to_user__select",
      description: "Select header",
      defaultMessage: "Select"
    },
    add_stores_to_user__select_all_stores: {
      id: "add_stores_to_user__select_all_stores",
      description: "Select all stores",
      defaultMessage: "Select all stores"
    },
    add_stores_to_user__ari_vendor_id_store_id: {
      id: "add_stores_to_user__ari_vendor_id_store_id",
      description: "Holman ID / Store no.",
      defaultMessage: "Holman ID / Store no."
    },
    add_stores_to_user__address: {
      id: "add_stores_to_user__address",
      description: "Store address",
      defaultMessage: "Store address"
    },
    add_stores_to_user__manage_stores: {
      id: "add_stores_to_user__manage_stores",
      description: "Manage store",
      defaultMessage: "Manage store"
    },
    add_stores_to_user__pagination_results_caption: {
      id: "add_stores_to_user__pagination_results_caption",
      description: "Showing results {first}-{last} of {storeCount}",
      defaultMessage: "Showing results {first}-{last} of {storeCount}"
    },
    add_stores_to_user__pagination_results_search_caption: {
      id: "add_stores_to_user__pagination_results_search_caption",
      description: " for the search term",
      defaultMessage: " for the search term"
    },
    add_stores_to_user__clear_search_results_caption:{
      id:"add_stores_to_user__clear_search_results_caption",
      description:". Clear search results",
      defaultMessage:". Clear search results",
    },
    add_stores_to_user__search_text_place_holder: {
      id: "add_stores_to_user__search_text_place_holder",
      description: "Search for store",
      defaultMessage: "Search for store"
    },
    add_stores_to_user__search_previous: {
      id: "add_stores_to_user__search_previous",
      description: "previous",
      defaultMessage: "previous"
    },
    add_stores_to_user__search_next: {
      id: "add_stores_to_user__search_next",
      description: "next",
      defaultMessage: "next"
    },
    add_stores_to_user__search_no_stores_text: {
      id: "add_stores_to_user__search_no_stores_text",
      description: "No results found for this search criteria.",
      defaultMessage: "No results found for this search criteria."
    }
  });
  // #endregion

  // #region Render helpers
  const renderCloseButton = () => (
    <ModalCloseStyled role='button' onClick={() => {
      routeParams.history.goBack();
    }}>
      <img src="/assets/VMS_33button_kill_blue.png"/>
      {"  "} {<FormattedMessage {...messages.close_dialog} />}
    </ModalCloseStyled>
  );  
  
  const renderContinueButton = () => (
    <ButtonStyled
      role="button"
      onClick={() => {if (selectedStores.length>0) onContinue()}}
      disable={selectedStores.length===0}
    >
      <FormattedMessage {...messages.add_stores_to_user__continue} />
      {" "}
      <img src="/assets/VMS_33button_go_bblue.png" />
    </ButtonStyled>
  );
  // #endregion  

  // #region Render
  return (    
    <Main>
      
      <FormWrapper
        key="accessStoreErrorMessage"
        id="accessStoreErrorMessage"
        formErrors={error}
      />

      <ButtonRowStyled>        
        {renderCloseButton()}
        <div></div>
      </ButtonRowStyled>                 
      

      <ModalMainHeaderStyled>
        <FormattedMessage
          {...messages.add_stores_to_store_search_caption}
        />        
      </ModalMainHeaderStyled>

      <ModalBodyStyled>
        
        <Header>
          <FormattedMessage {...messages.search_stores_caption} />
        </Header>      

        <form onSubmit={handleSubmit}> 
        <SearchWrapperStyled>        
          <div css={`
            position: relative;
          `}>
            <SearchInput 
              name='search'
              type="text"
              value={searchCurrentText}
              onChange={(e) => setSearchCurrentText(e.target.value)}
              placeholder={formatMessage(
                messages.add_stores_to_user__search_text_place_holder
              )}
              autocomplete="off"            
              tabIndex="0"                        
            />
            {!!searchCurrentText &&
              <ClearImageStyled 
                src='/assets/VMS_33button_kill_clear_inline.png'
                onClick={()=>{
                  setStoreSearchKeyword('');
                  setSearchCurrentText('');
                  setCurrentPageNumber(0);
                }}
              />
            }
          </div>
          <SearchIconInputStyled            
            type="image"
            src="/assets/VMS_search_button_blue.png"
            alt="Search"            
          />                      
        </SearchWrapperStyled>
        </form>
                          
        <HR />        

        <div>                    
          <div>                                                                              
            {loadingState == loadingStates.loading
              ? <SpinnerContainer><Spinner spinnerType="blue" /></SpinnerContainer>
              : stores == null || stores.length == 0
                ? <NoResultsStyled>                                                          
                  <span>
                    <FormattedMessage                      
                      {...messages.add_stores_to_user__search_no_stores_text}
                    />                    
                  </span>                                
                </NoResultsStyled>
                
                : <div>

                  <ResultCountStyled>                    
                    <FormattedMessage
                      values={{
                        first: (currentPageNumber * pageSize) + 1,
                        last: (currentPageNumber * pageSize) + stores.length,
                        storeCount:storeCount
                      }}
                      {...messages.add_stores_to_user__pagination_results_caption}
                    />
                    {storeSearchKeyword != undefined && storeSearchKeyword != '' ?
                      <span>                        
                        <span>
                          <FormattedMessage                          
                            {...messages.add_stores_to_user__pagination_results_search_caption}
                          />                        
                        </span>
                        {' "'}
                        <span css={`
                          color: ${({theme})=>theme.color.primaryBlue};
                        `}>
                          {storeSearchKeyword}
                        </span>
                        {'"'}
                      </span> : undefined
                    }
                  </ResultCountStyled>                                    

                  <div css={`padding-top:1rem;padding-bottom:1rem;`}>
                    <a onClick={handleSelectAllStoresClick}>
                      <FormattedMessage {...messages.add_stores_to_user__select_all_stores}/>
                    </a>
                  </div>

                  <HeaderRowStyled>
                    <SelectColStyled>
                      <HeaderStyled>
                        <FormattedMessage
                          {...messages.add_stores_to_user__select}
                        />
                      </HeaderStyled>
                    </SelectColStyled>

                    <AriIdColStyled>
                      <HeaderStyled>
                        <FormattedMessage
                          {...messages.add_stores_to_user__ari_vendor_id_store_id}
                        />
                      </HeaderStyled>
                    </AriIdColStyled>

                    <StoreAddressColStyled>
                      <HeaderStyled>
                        <FormattedMessage
                          {...messages.add_stores_to_user__address}
                        />
                      </HeaderStyled>
                    </StoreAddressColStyled>
                    
                  </HeaderRowStyled>                  

                  {!!stores &&
                    stores.map(vendor => {
                      return (                        
                        <ResultsRowStyled key={vendor.id}>

                          <SelectColStyled>
                            <CheckCircle 
                              name='selectStore'
                              checked={selectedStores.some(store => store.id === vendor.id)}
                              message=''
                              allowTab
                              onClick={handleSelectClick(vendor)}
                            />  
                          </SelectColStyled>

                          <AriIdColStyled>
                            {vendor.id}
                            {" "}
                            {vendor.storeNumber != "" ? 
                                   "/ " + vendor.storeNumber : undefined
                                   }
                          </AriIdColStyled>

                          <StoreAddressColStyled>
                            {vendor.businessName} <br />
                            {vendor.businessAddress.address1}
                            {" "}
                            <br />
                            {vendor.businessAddress.city}
                            {", "}
                            {vendor.businessAddress.state}
                            {" "}                              
                            {vendor.businessAddress.zipCode}
                            <br />
                            {vendor.businessPhone.substr(0, 3) +
                              "-" +
                              vendor.businessPhone.substr(
                                3,
                                3
                              ) +
                              "-" +
                              vendor.businessPhone.substr(6, 4)}
                          </StoreAddressColStyled>                              
                        </ResultsRowStyled>                        
                      );
                    })}

                  <FooterStyled> 
                  {storeCount <= 20 ? false :                                     
                    <ReactPaginate
                      previousLabel={formatMessage(
                        messages.add_stores_to_user__search_previous
                      )}
                      nextLabel={formatMessage(
                        messages.add_stores_to_user__search_next
                      )}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      className={"pagination pagination-lg"}
                      activeClassName={"page-item active"}
                      pageLinkClassName={"page-link"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}                                 
                      disableInitialCallback={true}
                      forcePage={currentPageNumber}
                    />
                      }
                  </FooterStyled>

                </div>
              }                        
          </div>                                      
          
        </div>

        <TitleStyled>          
          <FormattedMessage {...messages.select_stores_caption} /> 
          { !!selectedStores && selectedStores.length > 0 &&
            <span> - {selectedStores.length}</span>
          }
        </TitleStyled>   

        <SelectedStores
          selectedStores={selectedStores}
          allowRemovingStore={true}
          onRemoveStore={onRemoveStore}
        />
                    
      </ModalBodyStyled>
      
      <ButtonRowStyled>
        <div></div>
        {renderContinueButton()}
      </ButtonRowStyled>                 

    </Main>
  );  
  // #endregion
};
// #endregion

export default injectIntl(SearchStores);
