import React, { useRef, useEffect } from 'react';
import Alert from './Alert';

const AlertList = (props) => {
    
  let alerts =props.alerts !=undefined && typeof(props.alerts) != 'string'? props.alerts.map((alert, index) => {
      return <Alert message={alert.message} level={alert.level} key={index} />
  }): undefined;
  
  // #region Ref  
  const alertListRef = useRef(null);
  // #endregion  

  // #region Effects  
  useEffect(
    () => {      
      // When there is a page error, scroll it into view unless this feature is disabled via props.
      if (!props.preventAutoScroll && !!props.alerts && !!alertListRef && !!alertListRef.current) {
        alertListRef.current.scrollIntoView(false);        
      }
    }, [props.alerts]
  );
  // #endregion

  return (
    <div className="" ref={alertListRef}>
        {alerts}
    </div>
  );
}
    
export default AlertList;