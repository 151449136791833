import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import LineItem from './LineItem';
import './LineItems.css';

class LineItems extends React.Component {

    getSortedLineItems(lineItems) {
        return lineItems.sortBy(l => (l.get('productCode') + l.get('lineNo') + (l.get('lineItemType').toUpperCase() == 'PARTS' ? 0 : 1)));
    };

    render() {

        return <div className="adjust_po_line_items">
            {
                <LineItem lineItems={this.getSortedLineItems(this.props.lineItems)}
                          complaintCode={this.props.complaintCode}
                          hideCostInformation={this.props.hideCostInformation}
                          lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                          onOpenTiresInfo = {this.props.onOpenTiresInfo}
                          selectedCurrency={this.props.selectedCurrency}
                          />
            }
        </div>;
    }
}



export default LineItems;