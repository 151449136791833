import {createSelector} from 'reselect';
import {Map, Set, List} from 'immutable';
import {LINE_ITEM_STATUS, LINE_ITEM_TYPE,SERVICE_LINE_ITEM_DETAILS_TYPE} from 'constants/App';
import moment from 'moment';
import {emptyMapIfUndefined, isFloat} from 'utils/HelperFunctions';


//This function returns the cost amount for part or labor for temporarily saved service line items
//  Takes
//      serviceLineItems: map of service line items
//      ataCode: ataCode for which cost needs to be fetched
//      costType: part or labor
function getCostFromServiceLineItems(serviceLineItems, ataCode, costType) {
    let cost = serviceLineItems.get(ataCode) == undefined ? '' :serviceLineItems.get(ataCode).get(costType);
     return cost;
}

//Gets cost for a PO line item
//  Takes
//      lineItems: po line items
//      ataCode: ataCode for which cost needs to be fetched
//      type: part or labor
function getCostFromPO(lineItems, ataCode, type) {
    let filteredLineItem = emptyMapIfUndefined(lineItems.filter(x => x.get('lineItemType') == type && x.get('productCode') == ataCode));
    if (filteredLineItem.size > 1)
        return 0;
    let lineItemAmount = filteredLineItem.getIn([filteredLineItem.keys().next().value, 'totalAmount']);
    let cost = lineItemAmount == undefined ? '' : Number(lineItemAmount);
    return (isNaN(cost) ? 0 : cost);
}

//Checks if line item type exists in PO line items
//  Takes
//      lineItems: po line items
//      ataCode: ataCode for which cost needs to be fetched
//      type: part or labor
function lineItemTypeExists(lineItems, ataCode, type) {
    let exist = lineItems.filter(x => x.get('lineItemType') == type && x.get('productCode') == ataCode);
    return exist != undefined && exist.size > 0;
}

//Gets cost from a PO line item or a service line item
//  Takes
//      serviceLineItems: map of service line items
//      lineItems: po line items
//      ataCode: ataCode for which cost needs to be fetched
//      type: part or labor
function getCost(serviceLineItems, lineItems, ataCode, type) {
    if (type == LINE_ITEM_TYPE.PART)
        return lineItemTypeExists(lineItems, ataCode, LINE_ITEM_TYPE.PART) ?
            getCostFromPO(lineItems, ataCode, LINE_ITEM_TYPE.PART) : getCostFromServiceLineItems(serviceLineItems, ataCode, 'partCost')
    else if (type == LINE_ITEM_TYPE.LABOR)
        return lineItemTypeExists(lineItems, ataCode, LINE_ITEM_TYPE.LABOR) ?
            getCostFromPO(lineItems, ataCode, LINE_ITEM_TYPE.LABOR) : getCostFromServiceLineItems(serviceLineItems, ataCode, 'laborRate')
    else if (type ==SERVICE_LINE_ITEM_DETAILS_TYPE.TOTAL) {
        return getCostFromServiceLineItems(serviceLineItems, ataCode, 'total');
    }
}

const isCreatingLineItemsSelector = state => state.appState.getIn(['uiData', 'addServiceLineItemsView', 'isCreatingLineItems']);

//Returns list of ata codes already in the PO
const lineItemsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'lineItems']));

// Selector returns a list of services with a derived 'isSelected' flag
const servicesSelector = state => state.appState.getIn(['serverData', 'complaintView', 'maintenanceServices'])
    .reduce(servicesDataTransformer,
        Map(),
        emptyMapIfUndefined(state.appState.getIn(['uiData', 'complaintView', 'servicesSelected'])));

// The 'isSelected' flag is computed for each service by inspecting 'selectedServices'.
// The 'selectedServices' are available in 'this', as they are passed in as 'context' by the selector
// See documentation of Immutable.js Map.reduce() function for more details on how this works.
function servicesDataTransformer(serviceList, service) {
    let isSelected = this.filter(s => s === service.get('id')).count() == 1;
    return serviceList.setIn([service.get('id')], service.setIn(['isSelected'], isSelected));
}
//Returns selected list of services
const selectedListOfServicesSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'complaintView', 'servicesSelected']));
//Returns if add service line item model is allowed to be shown
const showWindowSelector = state => state.appState.getIn(['uiData', 'addServiceLineItemsView', 'showWindow']);
//Returns list of ata codes populated for the selected services
const serviceLineItemsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'addServiceLineItemsView', 'serviceLineItems']));
//Returns parameters for the vehicle
const parameterListSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'addServiceLineItemsView', 'vehicleParameters']));
//Returns master data for ATA codes
const ataCodesMasterSelector = state => 
emptyMapIfUndefined(state.appState.getIn(['serverData', 'complaintView', 'ataCodes']))
.merge(state.appState.getIn(['serverData', 'view', 'ataCodes']));

//Returns list of ATA codes to be added to the PO
const serviceATAListSelector = createSelector(
    state => state.appState.getIn(['serverData', 'complaintView', 'maintenanceServices']),
    state => state.appState.getIn(['serverData', 'complaintView', 'regulatoryServices']),
    selectedListOfServicesSelector,
    lineItemsSelector,
    ataCodesMasterSelector,
    state=>emptyMapIfUndefined(state.appState.getIn(['uiData', 'addServiceLineItemsView', 'serviceLineItems'])),
    state => state.appState.getIn(['serverData', 'view', 'maintenanceServices']),
    state => state.appState.getIn(['serverData', 'view', 'regulatoryServices']),
    (maintenanceServices, 
    regulatoryServices, 
    selectedServices, 
    lineItems, 
    ataCodesMaster, 
    serviceLineItems,
    maintenanceServicesForEditMode, 
    regulatoryServicesForEditMode) => {

        //get ata codes for the selected service id.
        let atasFromMaintenanceServices;
        let atasFromRegulatoryServices;

        if (maintenanceServices != undefined && maintenanceServices.size > 0)
            atasFromMaintenanceServices = maintenanceServices.filter(s => selectedServices.has(s.get('id'))).map(a => a.get('ataCodes'))
                .reduce((p, c) => {
                    let atas = c.reduce((p1, c1) => p1.add(c1.get('code')), Set());
                    return p.concat(atas);
                }, Set());

        if (regulatoryServices != undefined && regulatoryServices.size > 0)
            atasFromRegulatoryServices = regulatoryServices.filter(s => selectedServices.has(s.get('id'))).map(a => a.get('ataCodes'))
                .reduce((p, c) => {
                    let atas = c.reduce((p1, c1) => p1.add(c1.get('code')), Set());
                    return p.concat(atas);
                }, Set());

        if (atasFromMaintenanceServices == undefined) atasFromMaintenanceServices = new Set();
        if (atasFromRegulatoryServices == undefined) atasFromRegulatoryServices = new Set();

        let ataCodesFromSelectedServices = new Set([...atasFromMaintenanceServices, ...atasFromRegulatoryServices]);

        /*
            Get maintenance and regulatory service ata codes from Edit PO.
            Steps: 
                1. Reduce maintenance ata codes MAP object to SET of ata codes only
                2. Reduce regulatory ata codes MAP object to SET of ata codes 
                3. Combine both the SETs using spread operator to get the final list
        */
        let maintenanceAtaCodesInEditPO = emptyMapIfUndefined(maintenanceServicesForEditMode)
                .map(a => a.get('ataCodes'))
                .reduce((p, c) => {
                    let atas = c.reduce((p1, c1) => c1.get('code')==undefined || c1.get('code') == null?p1:p1.add(c1.get('code')), Set());
                    return p.concat(atas);
                }, Set()); 

        let regulatoryAtaCodesInEditPO = emptyMapIfUndefined(regulatoryServicesForEditMode)
                .map(a => a.get('ataCodes'))
                .reduce((p, c) => {
                    let atas = c.reduce((p1, c1) => c1.get('code')==undefined || c1.get('code') == null?p1:p1.add(c1.get('code')), Set());
                    return p.concat(atas);
                }, Set()); 

        if (maintenanceAtaCodesInEditPO == undefined) maintenanceAtaCodesInEditPO = new Set();
        if (regulatoryAtaCodesInEditPO == undefined) regulatoryAtaCodesInEditPO = new Set();

        //add the set to the final list of selected services
        ataCodesFromSelectedServices = new Set([...ataCodesFromSelectedServices, ...maintenanceAtaCodesInEditPO, ...regulatoryAtaCodesInEditPO]);

        //filter ata codes from po line items
        let existingATACodesInPOForLabor = emptyMapIfUndefined(lineItems.filter(x => x.get('lineItemType') == LINE_ITEM_TYPE.LABOR)).map(i => i.get('productCode')).toSet();
        let existingATACodesInPOForPart = emptyMapIfUndefined(lineItems.filter(x => x.get('lineItemType') == LINE_ITEM_TYPE.PART)).map(i => i.get('productCode')).toSet();
        let existingATACodesInPOForPM=emptyMapIfUndefined(lineItems.filter(x => x.get('lineItemType') == LINE_ITEM_TYPE.PM)).map(i => i.get('productCode')).toSet();
        //get unique set of ata codes from the above sets
        let existingATACodesInPO = existingATACodesInPOForLabor.intersect(existingATACodesInPOForPart).union(existingATACodesInPOForPM);
        //get services ata codes that are not present in the po
        let remainingATACodes = ataCodesFromSelectedServices.subtract(existingATACodesInPO);

        //return map for ata codes to be added
        return remainingATACodes.reduce((ataCodeList, ataCode) => {
            if(ataCode!=null && ataCode!=undefined)
            {
                let total = getCost(serviceLineItems, lineItems, ataCode, SERVICE_LINE_ITEM_DETAILS_TYPE.TOTAL); 
             
                return ataCodeList.set(ataCode, Map({
                    'productCode': ataCode,
                    'description': ataCodesMaster.getIn([ataCode, 'description']),
                    'partCost': getCost(serviceLineItems, lineItems, ataCode, LINE_ITEM_TYPE.PART),
                    'laborRate': getCost(serviceLineItems, lineItems, ataCode, LINE_ITEM_TYPE.LABOR),
                    'total': isFloat(total)? parseFloat(total).toFixed(2) : total,
                    'partCostEnabled': !lineItemTypeExists(lineItems, ataCode, LINE_ITEM_TYPE.PART),
                    'laborRateEnabled': !lineItemTypeExists(lineItems, ataCode, LINE_ITEM_TYPE.LABOR),
                }));
            }
            else 
                return ataCodeList;     
            }
            , Map());
    });
//Returns if add services line item modal will be shown
const showAddServiceLineItemsWindowSelector = createSelector(
    serviceATAListSelector,
    showWindowSelector,
    (serviceATAList, showWindow) => {
        return (serviceATAList != undefined && serviceATAList.size > 0) && showWindow;
    }
);

const vehicleDetailsSelector = state => {
    return state.appState.getIn(['serverData', 'shared', 'vehicleDetails']);
};

const ataPmParametersDescriptionSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'addServiceLineItemsView', 'ataPmParameters']));

const ataReferenceParametersDescriptionSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'complaintView', 'maintenanceServices']));

const getReferenceParametersReferenceRuleSelector = createSelector(ataReferenceParametersDescriptionSelector, (referenceParametersDescription) => {

    return referenceParametersDescription = referenceParametersDescription.filter((params) => {
        return params.get('ataCodes').get(0).get('code') == null && params.get('isReferenceRule') === true

    });
});

const getReferenceParametersSelector = createSelector(ataReferenceParametersDescriptionSelector, (referenceParametersDescription) => {
    return referenceParametersDescription = referenceParametersDescription.filter((params) => {
        return params.get('ataCodes').get(0).get('code') == null && params.get('isReferenceRule') === false;

    });
});

const getReferenceAndPmParametersSortedSelector = createSelector(getReferenceParametersSelector, ataPmParametersDescriptionSelector,
    (referenceParametersDescription, pmParametersDescription) => {
        let pmParameters = pmParametersDescription.reduce((p, c) => {
            return p.set(c.get('id'), Map({
                'description': c.get('description'), 
                'priority': c.get('priority')
            }))
        }, Map());

        pmParameters = pmParameters.sort((a,b) => a.get('priority')-b.get('priority'));

        let referenceParameters = referenceParametersDescription.reduce((p, c) => {
            return p.set(c.get('id'), Map({
                'description': c.get('serviceName'), 
                'priority':c.get('priority')
            }))
        }, Map());

        referenceParameters = referenceParameters.sort((a,b) => a.get('priority')-b.get('priority'));

        return pmParameters != undefined ? referenceParameters.concat(pmParameters) : referenceParameters;
    });

const getReferenceRuleAndPmParametersSortedSelector = createSelector(getReferenceParametersReferenceRuleSelector,
    (referenceParametersDescription) => {


        let referenceParameters = referenceParametersDescription.reduce((p, c) => {
            return p.set(c.get('id'), Map({
                'description': c.get('serviceName'),
                'priority':c.get('priority')
            }))
        }, Map());

        referenceParameters = referenceParameters.sort((a,b) => a.get('priority')-b.get('priority'));

        return referenceParameters;
    });

const AddServiceLineItemView = createSelector(
    isCreatingLineItemsSelector,
    serviceLineItemsSelector,
    parameterListSelector,
    ataCodesMasterSelector,
    showAddServiceLineItemsWindowSelector,
    serviceATAListSelector,
    vehicleDetailsSelector,
    getReferenceAndPmParametersSortedSelector,
    getReferenceRuleAndPmParametersSortedSelector,
    (isCreatingLineItems,
     serviceLineItems,
     parameterList,
     ataCodes,
     showAddServiceLineItemsWindow,
     serviceATAList,
     vehicleDetails,
     referencePmParametersDescription,
     referenceRulePmParametersDescription) => {
        return {
            isCreatingLineItems,
            serviceLineItems,
            parameterList,
            ataCodes,
            showAddServiceLineItemsWindow,
            serviceATAList,
            vehicleDetails,
            referencePmParametersDescription,
            referenceRulePmParametersDescription
        };
    }
);

export default AddServiceLineItemView;