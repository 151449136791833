import {createSelector} from 'reselect';
import {emptyMapIfUndefined,falseIfUndefined, emptyStringIfUndefined,emptyArrayIfUndefined} from 'utils/HelperFunctions';
import {Map, fromJS} from 'immutable';
import moment from 'moment';
import * as constants from 'constants/App';

const serviceSelectedSelector = state => state.appState.getIn(['uiData', 'complaintView', 'servicesSelected']);

/*Checks mandatory fields of the service obbject*/
function validateServiceObject(service) {
    if (service == undefined)
        return false;
    if (service.get('id') == undefined || service.get('id') == '' || service.get('id') == null)
        return false;
    if (service.get('serviceName') == undefined || service.get('serviceName') == '' || service.get('serviceName') == null)
        return false;
    if (service.get('dueBy') == undefined || service.get('dueBy') == '' || service.get('dueBy') == null)
        return false;
    return true;
}
// The 'isSelected' flag is computed for each service by inspecting 'selectedServices'.
// The 'selectedServices' are available in 'this', as they are passed in as 'context' by the selector
// See documentation of Immutable.js Map.reduce() function for more details on how this works.
function servicesDataTransformer(serviceList, service) {
    let isSelected = this.filter(s => s === service.get('serviceId')).count() == 1;
    return serviceList.setIn([service.get('serviceId')], service.setIn(['isSelected'], isSelected));
}

/*Get service details*/
function getServiceInfo(serviceRecord, vehicleInfo) {
    let overDueBy = '';
    let scheduled = '';
    let status = '';

    let dueBy = emptyMapIfUndefined(serviceRecord).get('dueBy') || '';

    switch (dueBy.toUpperCase()) {
        case 'METER':
            overDueBy = (serviceRecord.get('dueOdometer') - (vehicleInfo.get('estimatedOdometer') || 0)).toLocaleString() + ' miles';
            scheduled = serviceRecord.get('dueOdometer').toLocaleString();
            status = serviceRecord.get('dueStatus'); 
            break;
        case 'DAYS':
            let dueDate = Date.parse(serviceRecord.get('dueDate'));
            if (!isNaN(dueDate)) {
                overDueBy = (moment(serviceRecord.get('dueDate')).diff(new Date(), 'days')).toLocaleString() + ' days';
                scheduled = moment(serviceRecord.get('dueDate')).format('MM/DD/YYYY');
                status = serviceRecord.get('dueStatus');
            }
            break;
        case 'HOURS':
            overDueBy = (serviceRecord.get('dueHourMeter') - (vehicleInfo.get('estimatedHourMeter') || 0)).toLocaleString() + ' hours';
            scheduled = serviceRecord.get('dueHourMeter').toLocaleString();
            status = serviceRecord.get('dueStatus');
            break;
        default:
            break;
    }
    return {
        overDueBy: overDueBy,
        scheduled: scheduled,
        status: status
    };
}


/*Selector to transform the state data to the component data*/
const vehicleServicesSelector = createSelector(
    state => state.appState.getIn(['serverData', 'complaintView', 'maintenanceServices']),
    state => state.appState.getIn(['serverData', 'complaintView', 'regulatoryServices']),
    state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'vehicleDetails'])),
    (maintenanceServices, regulatoryServices, vehicleDetails) => {
        let vehMaintenanceServices = Map();
        let vehRegulatoryServices = Map();
        let servicesFlatStructure = Map();
        let servicesFiltered = Map();

        if (maintenanceServices != undefined) {
            servicesFlatStructure = maintenanceServices.filter(x=>x.get('ataCodes').get(0).get('code')!=null)
                .reduce((p,c)=>{
                    return p.set(c.get('id'), Map({
                        id:c.get('id'),
                        dueDate: c.get('dueDate'),
                        productCode: c.get('ataCodes').get(0).get('code')

                    }));
                }, Map());
        }

        if(servicesFlatStructure!=undefined ){
            servicesFiltered =  servicesFlatStructure.reduce((p, c) => {
                let dueATACodeWithEarlierDueDate = p.filter(x=>x.get('productCode')==c.get('productCode') && c.get('dueDate') <= x.get('dueDate'));

                if(dueATACodeWithEarlierDueDate!=undefined && dueATACodeWithEarlierDueDate.size>0){
                    return p.setIn([c.get('productCode')], c);
                }
                else{
                    return p.set(c.get('productCode'), c);
                }
            }, Map());
        }

        //get PM services
        if (maintenanceServices != undefined)

            vehMaintenanceServices = maintenanceServices.reduce((services, current) => {

                if (!validateServiceObject(current))
                    return services;

                let filteredItems = servicesFiltered.filter(x=>x.get('id')==current.get('id'));

                if(filteredItems==undefined || filteredItems.size == 0)
                    return services;

                let serviceInfo = getServiceInfo(current, vehicleDetails);

                if ( serviceInfo.status == constants.SERVICE_STATUS.APPROACHING || serviceInfo.status==constants.SERVICE_STATUS.OVERDUE || serviceInfo.status==constants.SERVICE_STATUS.DUE )
                    return services.set(current.get('id'), Map({
                        serviceId: current.get('id'),
                        serviceName: current.get('serviceName'),
                        serviceSchedule: serviceInfo.scheduled,
                        serviceOverDueBy: serviceInfo.overDueBy,
                        serviceType: constants.SERVICE_TYPE.PM,
                        serviceStatus: serviceInfo.status,
                        ataCodes: current.get('ataCodes')
                    }));
                else
                    return services;

            }, Map());



        if (regulatoryServices != undefined)
        //get regulatory services
            vehRegulatoryServices = regulatoryServices.reduce((services, current) => {
                if (!validateServiceObject(current))
                    return;
                var serviceInfo = getServiceInfo(current, vehicleDetails);
                if ( serviceInfo.status == constants.SERVICE_STATUS.APPROACHING || serviceInfo.status==constants.SERVICE_STATUS.OVERDUE || serviceInfo.status==constants.SERVICE_STATUS.DUE )
                    return services.set(current.get('id'), Map({
                        serviceId: current.get('id'),
                        serviceName: current.get('serviceName'),
                        serviceSchedule: serviceInfo.scheduled,
                        serviceOverDueBy: serviceInfo.overDueBy,
                        serviceType: constants.SERVICE_TYPE.INSPECTION,
                        serviceStatus: serviceInfo.status,
                        ataCodes: current.get('ataCodes')
                    }));
                else
                    return services;

            }, Map());

        //don't push undefined obejcts to the app state
        if (vehMaintenanceServices == undefined) vehMaintenanceServices = Map();
        if (vehRegulatoryServices == undefined) vehRegulatoryServices = Map();



        return new Map([...vehMaintenanceServices, ...vehRegulatoryServices]);
    }
);

const myUnitsSelector = state =>emptyArrayIfUndefined(fromJS(state.appState.getIn(['uiData', 'vendorMyUnits', 'myUnits'])));

const myUnitsStatusSort =createSelector(myUnitsSelector,(units) => {

    return  units.sort((a,b) => {

        var aDate =  new Date(a.get("pmDueDate"));
        var bDate =  new Date(b.get("pmDueDate"));

        if(a.get("pmDueDate")=== null){
            aDate = new Date("4/16/2500 12:00:00 AM")
        }
        else if(b.get("pmDueDate")=== null){
            bDate = new Date("4/16/2500 12:00:00 AM")
        }

        if (aDate < bDate) { return -1; }
        if (aDate > bDate) { return 1; }
        if (aDate === bDate) { return 0; }
    });

});

const myOtherUnitsSelector = state => emptyArrayIfUndefined(fromJS(state.appState.getIn(['uiData', 'vendorMyUnits', 'myOtherUnits'])));

const myOtherUnitsStatusSort =createSelector(myOtherUnitsSelector,(units) => {

    return units.sort((a,b) => {
        var aDate =  new Date(a.get("pmDueDate"));
        var bDate =  new Date(b.get("pmDueDate"));

         if(a.get("pmDueDate")=== null){
             aDate = new Date("4/16/2500 12:00:00 AM")
         }
         else if(b.get("pmDueDate")=== null){
             bDate = new Date("4/16/2500 12:00:00 AM")
         }

        if (aDate < bDate) { return -1; }
        if (aDate > bDate) { return 1; }
        if (aDate === bDate) { return 0; }
    });


});



// Selector returns a list of services with a derived 'isSelected' flag
const servicesSelector = createSelector(
    vehicleServicesSelector,
    serviceSelectedSelector,
    (services, serviceSelected) =>
        services.reduce(servicesDataTransformer, Map(), serviceSelected)
);


const MyUnitsView = createSelector(
    servicesSelector,
    myUnitsStatusSort,
    myOtherUnitsStatusSort,
    (services,myUnits,myOtherUnits) => {
        return {
            services,
            myUnits,
            myOtherUnits
        };
    }
);

export default MyUnitsView;
