import { call, put, select, take, cancel, fork }from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';

function* offsetGeneratorForFetchMyUnits() {
    let startValue = 0;
    while (true) {
        yield startValue++;
    }
}

export function* fetchVendorUnitInformation(offsetGenerator) {

    try {

        const state = yield select();

        yield put({type: constants.MY_UNITS_REQUESTED});

        let token = helperFunctions.getToken(state);
        if (token) {
            const vendorUnitInfo =   yield call(Api.fetchMyVendorUnits,offsetGenerator.next().value,25, token,state);

            yield put({type:constants.MY_UNITS_RESOLVED,vendorUnitInfo });
        }

    }
    catch (e) {

        if(e.code=='API-045') {
            yield put({
                type: constants.MY_UNITS_REJECTED,
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* myUnitsScreenFlow() {
    yield put({type:constants.CLEAR_MY_UNITS});
    let offsetGenerator = offsetGeneratorForFetchMyUnits();

    while (true) {
        let task = yield fork(fetchVendorUnitInformation,offsetGenerator);

        let nextAction = yield take([constants.FETCH_MORE_UNITS_CLICK, "MY_UNIT_UNLOADED"]);
        if (nextAction.type === "MY_UNIT_UNLOADED") {
            yield cancel(task);
            break;
        }
    }
}


function* offsetGeneratorForFetchMyOtherUnits() {
    let startValue = 0;
    while (true) {
        yield startValue++;
    }
}

export function* fetchVendorOtherUnitInformation(offsetGenerator) {

    try {

        const state = yield select();

        yield put({type: constants.MY_OTHER_UNITS_REQUESTED});

        let token = helperFunctions.getToken(state);
        if (token) {
            const vendorUnitInfo = yield call(Api.fetchMyVendorOtherUnits,offsetGenerator.next().value,25, token,state);
            
            yield put({type:constants.MY_OTHER_UNITS_RESOLVED,vendorUnitInfo });
        }


    }
    catch (e) {

        if(e.code=='API-045') {
            yield put({
                type: constants.MY_OTHER_UNITS_REJECTED,
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* myOtherUnitsScreenFlow() {

    yield put({type:constants.CLEAR_MY_OTHER_UNITS });
    let offsetGenerator = offsetGeneratorForFetchMyOtherUnits();

    while (true) {
        let task = yield fork(fetchVendorOtherUnitInformation,offsetGenerator);

        let nextAction = yield take([constants.FETCH_MORE_OTHER_UNITS_CLICK,  "MY_OTHER_UNIT_UNLOADED"]);

        if (nextAction.type === "MY_OTHER_UNIT_UNLOADED") {            
            yield cancel(task);
            break;
        }
    }
}

export function* emailVendorUnitClicked() {

    try {
        const state = yield select();

        //    yield call(Api., state, helperFunctions.getToken(state));

    }
    catch (e) {

        if(e.code=='API-045') {
            yield put({
                type: 'CHANGE_VENDOR_INFO_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
            yield put({
                type: 'SHOW_CURRENT_PWD_ERROR_MESSAGE',
                flag: true
            });

        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* rejectVendorUnitClicked(action) {

    try {
        const state = yield select();

        yield call(Api.deleteUnitItem,action.vehicleId, state, helperFunctions.getToken(state));

        yield put({type:constants.FETCH_VENDOR_UNITS});
        yield put({type:constants.FETCH_VENDOR_OTHER_UNITS});


    }
    catch (e) {

        if(e.code=='API-045') {
            yield put({
                type: constants.MY_UNITS_REJECTED,
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
            yield put({
                type: 'SHOW_CURRENT_PWD_ERROR_MESSAGE',
                flag: true
            });

        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* fetchVehicleForMyUnitsLookup(action) {

    try {
        const state = yield select();


        // Get vehicle data
        const vehicleDetails =  yield call(Api.fetchVehicleByVehicleId,  action.vehicleId, helperFunctions.getToken(state));


        yield put({ type: "FETCH_VEH_DATA_FOR_MY_UNITS_LOOKUP_RESOLVED", vehicleDetails });


    }
    catch (e) {
        var customError=helperFunctions.getCustomErrorObject(e);
        yield put({ type: "FETCH_VEH_DATA_FOR_MY_UNITS_LOOKUP_REJECTED", errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SET_VEHICLE_SEARCH_ERROR_MESSAGE, errorMessage: customError!=undefined && customError.length>0?customError[0].message:undefined });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED, errorObject: e });
    }
}