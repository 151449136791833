// Libraries
import React from 'react'
// Our stuff
import './DownloadButton.css';

const DownloadButton = ({onClick}) => {    
    return <span onClick={onClick} className='documents__upload_button' role="button">            
        <img src='/assets/vms-download2.png'/>
    </span>
    
}

export default DownloadButton;