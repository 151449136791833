import React from 'react';
import ReactDOM from 'react-dom';
import { Popover, Overlay } from 'react-bootstrap';
import { FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
// Our stuff
import './BindField.css';

/// This component wraps an input or select.
/// It injects some common props for binding with higher state.
/// It also displays popover errors
class BindField extends React.Component {            
    /// Inject props into children components
    renderChildren() {
        return React.Children.map(this.props.children, child => (            
            React.cloneElement(child, {
                name: this.props.name,
                ref: (r)=>{this.fieldRef=r}, // get reference of child
                onFocus: ()=>{this.props.onClearError(this.props.name)}, // clear error onFocus
                onBlur:(e)=>{this.props.onFieldBlur(e.target.name, e.target.value)} //raise event with name and value
            }))
        )            
    }

    render() {        
        return (
        <div>                                    
            {this.renderChildren()}

            { this.props.error &&
                <Overlay    
                    show={true}
                    target={ReactDOM.findDOMNode(this.fieldRef)}
                    placement="right">
                    <Popover id={this.props.name} className='documents__overlay'>
                    <RawIntlProvider value={this.props.intl}>
                        {<FormattedMessage {...this.props.error}/>}
                        </RawIntlProvider>
                    </Popover>
                </Overlay>
            }   
        </div>);
    }
};


export default injectIntl(BindField);