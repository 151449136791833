import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AccidentAssignmentsScreen from 'AccidentManagement/AccidentAssignments/AccidentAssignmentsScreen';
import { withRouter } from "react-router-dom";
import AccidentAssignmentsView from "AccidentManagement/AccidentAssignments/AddEditView/AccidentAssignmentsView";
const AccidentManagementRoute = (props) => {
    return (props.isAccidentAssignmentFeatureEnabled != undefined ?
        React.createElement(Switch, null,
            !props.isAccidentAssignmentFeatureEnabled
                ? React.createElement(Redirect, { to: {
                        pathname: '/'
                    } })
                : undefined,
            React.createElement(Route, { path: "/AccidentManagement/AccidentAssignments", exact: true, children: React.createElement(AccidentAssignmentsScreen, { vendorId: props.vendorId }) }),
            React.createElement(Route, { path: "/AccidentManagement/AccidentAssignments/:id", exact: true, children: React.createElement(AccidentAssignmentsView, null) }))
        :
            null);
};
export default withRouter(AccidentManagementRoute);
