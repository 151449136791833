import axios from 'axios';
import * as configuration from 'configuration';
import { getSelectedLanguageCode } from 'utils/HelperFunctions';


var getHeaders = (state,languageCode = "en-US") => {
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': state.appState.getIn(['serverData', 'shared', 'token']),
            'Accept-Language':languageCode
        }
    };
};



//Url to get ata codes by complaint type
function getFrequentlyUsedATACodesURL(vehicleId, complaintCode, state) {
    return axios.get(`${configuration.serviceURL}v1/ataCodes?vehicleId=${vehicleId}&complaintCode=${complaintCode}&fields=code,description,complaintcode,correctiontypesrestricted,correctionandrepairtypesrestricted,popularity,multipleLineItemsPermitted`, getHeaders(state,getSelectedLanguageCode(state)));
}
// Gets frequently used ata codes by complaint type
export function getFrequentlyUsedATACodes(componentName, vehicleId, complaintCode, state, callback) {
    axios.all([getFrequentlyUsedATACodesURL(vehicleId, complaintCode, state)])
        .then(axios.spread((frequentlyUsedATACodes) => {
            if (frequentlyUsedATACodes.status == '200') {
                if (frequentlyUsedATACodes.data.errorDescription == "" || frequentlyUsedATACodes.data.errorDescription == null) {
                    // Convert json data to Immutable object
                    let immutableATACodesResult = {
                        frequentlyUsedATACodes: frequentlyUsedATACodes.data,
                    };
                    callback(null, immutableATACodesResult);
                }
                else {
                    let message = "\n" + frequentlyUsedATACodes.data.errorDescription;
                    callback(message, null);
                }
            }
            else
                callback('Response was not OK', null);
        })).catch(function (response) {
            if (response instanceof Error) {
                // Something happened in setting up the request that triggered an Error
                const errorMessage = response.message != undefined ? response.message : response.statusText;
                console.log('Error', response);
                callback(errorMessage, null);
            }
        });
}
