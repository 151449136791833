// libraries
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,FormattedNumber } from 'react-intl';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError,validateEmail,zeroIfUndefined } from 'utils/HelperFunctions';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// our stuff
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import * as ActiveRequestActions from '../actions/DealerRequestsView';
import './ActiveRequestsView.css';
import Help from 'components/Help';
import AddDealerDraftLineItem from "../../RequestsApproval/components/AddDealerDraftLineItem";
import { useAppState } from 'shared/AppState/app-state';

const ActiveRequestsView = (props) => {

    const [ appState, dispatch ] = useAppState();

    const getTooltip = (toolTipText) => {
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }

    const getVehicle = (vin8,vehicleYear,vehicleMake,vehicleModel) => {

        return <OverlayTrigger
            placement='right'
            key={vin8}
            overlay={getTooltip((vehicleYear == null ? '' : vehicleYear)
                + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))}
        >
            <div key={vin8}>
                {(vehicleYear + ' ' + vehicleMake + ' ' + vehicleModel).length > 10
                    ? ((vehicleYear == null ? '' : vehicleYear)
                    + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel)).substr(0, 9) + '...'
                    : ((vehicleYear == null ? '' : vehicleYear)
                    + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))
                }
            </div>
        </OverlayTrigger>

    }

    const getFilteredRecords= (poList, showAll) => {

        if (showAll == false && poList.count() > 0)
            poList = poList.take(10);

        return poList;
    }


    const getFooter = (pos, showAll) => {

        const messages = defineMessages({
            show_all_waiting_requests: {
                id: 'show_all_waiting_requests',
                description: 'Link at bottom of PO List to show all POs',
                defaultMessage: 'Show all {poCount} requests'
            },
            hide_older_requests: {
                id: 'hide_older_requests',
                description: 'Link at bottom of PO List to hide older approved POs',
                defaultMessage: 'Hide older requests'
            }
        });

        // Don't show footer if we have 10 or less records
        if (pos.count() <= 10)
            return undefined;
        // If we are not showing all, show button to show all
        else if (!showAll) {
            return <div role="button" className="active_requests__footer">
                <FormattedMessage values={{poCount:pos.count()}}
                                  {...messages.show_all_waiting_requests}/>
                {' '}<img src="/assets/VMS_button_show_all_pos.png"/>
            </div>;
        }
        else {
            return <div role="button" className="active_requests__footer">
                <FormattedMessage {...messages.hide_older_requests}/>
                {' '}<img src="/assets/VMS_button_hide_older_pos.png"/>
            </div>;
        }
    }



        const messages = defineMessages({
            dealer_draft_active_requests_title: {
                id: 'dealer_draft_active_requests_title',
                description: 'dealer_draft_active_requests_title',
                defaultMessage: 'Active Requests'
            },
            dealer_draft_active_requests_client_no: {
                id: 'dealer_draft_active_requests_client_no',
                description: 'dealer_draft_active_requests_client_no',
                defaultMessage: 'Client No.'
            },
            dealer_draft_active_requests_vehicle_no: {
                id: 'dealer_draft_active_requests_vehicle_no',
                description: 'dealer_draft_active_requests_vehicle_no',
                defaultMessage: 'Holman Unit No.'
            },
            dealer_draft_active_requests_requests_no: {
                id: 'dealer_draft_active_requests_requests_no',
                description: 'dealer_draft_active_requests_requests_no',
                defaultMessage: 'Request No.'
            },
            dealer_draft_active_requests_vin8: {
                id: 'dealer_draft_active_requests_vin8',
                description: 'dealer_draft_active_requests_vin8',
                defaultMessage: 'Vin8'
            },
            dealer_draft_active_requests_no_requests_caption: {
                id: 'dealer_draft_active_requests_no_requests_caption',
                description: 'dealer_draft_active_requests_no_requests_caption',
                defaultMessage: 'None at this Time'
            },
            submit: {
                id: "submit",
                description: "Submit",
                defaultMessage: "Submit"
            },
            view: {
                id: 'view',
                description: 'view',
                defaultMessage: 'VIEW'
            }
        });

        const activePOs = getFilteredRecords(props.activePOs, props.showAll);

        return <div className="active_requests-list table-responsive">
            <div className="active_requests__caption">
                <FormattedMessage {...messages.dealer_draft_active_requests_title}/>
                <Help videoId="dealer_draft"  whiteSpaceAtBottomHeight="0.7em"/>
            </div>
            <table className="active_requests__table" width="100%">
                <thead>
                <tr>
                    <th className="active_requests__header-cell
                           active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.dealer_draft_active_requests_client_no}/>
                    </th>
                    <th className="active_requests__header-cell
                           active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.dealer_draft_active_requests_vehicle_no}/>
                    </th>
                    <th className="active_requests__header-cell
                           active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.dealer_draft_active_requests_requests_no}/>
                    </th>
                    <th className="active_requests__header-cell
                           active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.dealer_draft_active_requests_vin8}/>
                    </th>
                    <th className="active_requests__header-cell
                           active_requests__header-cell_align-left">

                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (activePOs.count() === 0)
                        ?  <tr>
                        <td colSpan="6" className="active_requests__cell
                                               active_requests__cell_shadow
                                               active-po-list__cell_both-corners-round
                                               active-po-list__cell_align-center">
                            <FormattedMessage {...messages.dealer_draft_active_requests_no_requests_caption}/>
                        </td>

                    </tr>

                        :

                        activePOs.valueSeq().map(request =>
                            <tr key={request.get('id')}>
                                <td className={classnames('active_requests__cell',
                                    'active_requests__cell_shadow',
                                    'active-po-list__cell_left-corner-round',
                                )}>
                                    {request.get("clientId")}
                                </td>
                                <td className={classnames('active_requests__cell',
                                    'active_requests__cell_shadow',
                                )}>
                                    {request.get("ariUnitNo")}
                                </td>
                                <td className={classnames('active_requests__cell',
                                    'active_requests__cell_shadow',
                                )}>
                                    {request.get("id")}
                                </td>
                                <td className={classnames('active_requests__cell',
                                    'active_requests__cell_shadow',
                                )}>
                                    {request.get("vin8").slice(9, request.get("vin8").length)}

                                </td>
                                <td className={classnames('active_requests__cell',
                                    'active_requests__cell_shadow',
                                    'active_requests__cell_right-corner-round'
                                )}>
                                   { appState.courteseyDeliveryPermissions.includes("CL") ?
                                   
                                   <Link to={`/dealerDrafts/edit/${request.get("id")}`}>
                                        <FormattedMessage {...messages.submit}/>
                                    </Link>
                                    :
                                    <Link to={`/dealerDrafts/view/${request.get("id")}`}>
                                        <FormattedMessage {...messages.view}/>
                                    </Link>
                                   }
                                </td>


                            </tr>
                        )
                }
                </tbody>
            </table>
            <div onClick={props.toggleShowAll}>
                {getFooter(props.activePOs, props.showAll)}
            </div>
        </div>;
    
}




export default ActiveRequestsView;
