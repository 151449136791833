import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS, List } from 'immutable';

export function uiDataAddNewLineItemsView(state = initialAppState.getIn(['uiData', 'addNewLineItemsView']), action) {
    switch (action.type) {
        case constants.CLEAR_ADD_NEWLINE_ITEM_ERROR_MESSAGES:
            return state.set('errorMessage', [])
                .setIn(['tires', 'errorMessage'], [])
                .setIn(['brakes', 'errorMessage'], [])
                .setIn(['tires', 'lineItemQtyMismatchErr'],[]);  

        case 'SHOW_LABOR_RATE_UPDATE_WINDOW':
        return state.set('showLaborRateUpdateWindow',action.flag)
        case 'RESET_ERROR_MESSAGE':
        return state.set('errorMessage',[]);
        case 'RESET_QUANTITY_MISMATCH_MESSAGE':
        return  state.setIn(['tires', 'lineItemQtyMismatchErr'],[]);
        case constants.ADD_NEW_LINE_ITEM_REQUESTED:
            return state.set('isCreatingLineItems', true)
                .set('errorMessage', []);
        case constants.LOAD_SEARCHED_ATA_CODES_DATA_REJECTED:
            return state.set('errorMessage', action.errorMessage);
        case constants.LOAD_SEARCHED_ATA_CODES_DATA_RESOLVED:
            return state.set('errorMessage', undefined);
        case constants.ADD_NEW_LINE_ITEM_RESOLVED:
            return state.set('isCreatingLineItems', false);
        case constants.ADD_NEW_LINE_ITEM_REJECTED:
            return state.set('isCreatingLineItems', false)
                .set('errorMessage', action.errorMessage)
                .set('showLaborRateUpdateWindow',false);

        /*update selected or searched ata code in state*/
        case constants.UPDATE_ATA_CODE:
            if (action.ataCode.get('type') == constants.UPDATE_ATA_CODE_TYPE.SELECTED_ATA_CODE)
                return state.setIn(['selectedATACode'], action.ataCode.get('productCode'))
                    .setIn(['vendorProductCode'], action.ataCode.get('vendorProductCode'))
                    .setIn(['searchedATACode'], '')
                    .setIn(['autoCompleteATACodeDescription'], '')
                    .setIn(['hours'], undefined)
                    .setIn(['rate'],undefined)
                    .setIn(['qty'], undefined)
                    .setIn(['cost'],undefined)
                    .setIn(['selectedCorrectionType'], '')
                    .setIn(['tireDetails'], Map())
                    .remove('partWarranty');
            /* only if ATA code is changed update it*/
            else if (action.ataCode.get('type') == constants.UPDATE_ATA_CODE_TYPE.SEARCHED_ATA_CODE  && action.ataCode.get('ataCode') !== state.get('searchedATACode'))
                return state.setIn(['searchedATACode'], action.ataCode.get('ataCode'))
                    .setIn(['vendorProductCode'], action.ataCode.get('vendorProductCode'))
                    .setIn(['vendorProductDescription'], action.ataCode.get('vendorProductDescription'))
                    .setIn(['selectedATACode'], '')
                    .setIn(['hours'], undefined)
                    .setIn(['rate'], undefined)
                    .setIn(['qty'], undefined)
                    .setIn(['cost'], undefined)
                    .setIn(['selectedCorrectionType'], '')
                    .setIn(['tireDetails'], Map())
                    .remove('partWarranty');
            else
                return state;

        case constants.UPDATE_SELECTED_CORRECTION_TYPE:
            /*Update correction type and reset values*/
            return state.setIn(['selectedCorrectionType'], action.correctionType)
                .setIn(['hours'], undefined)
                .setIn(['rate'], action.hourlyLaborRate)
                .setIn(['qty'], undefined)
                .setIn(['cost'], undefined)
                .remove('partWarranty');

        case constants.ADD_CORRECTION_DETAILS:
            switch (action.correctionDetails.get('type')) {
                case constants.CORRECTION_DETAILS_TYPE.QTY:
                    return state.setIn(['qty'], action.correctionDetails.get('value'));
                case constants.CORRECTION_DETAILS_TYPE.COST:
                    return state.setIn(['cost'], action.correctionDetails.get('value'));
                case constants.CORRECTION_DETAILS_TYPE.HOURS:
                    return state.setIn(['hours'], action.correctionDetails.get('value'));
                case constants.CORRECTION_DETAILS_TYPE.RATE:
                    return state.setIn(['rate'], action.correctionDetails.get('value'));
                case constants.CORRECTION_DETAILS_TYPE.WARRANTY_MONTHS:
                    return state.setIn(['partWarranty', 'months'], action.correctionDetails.get('value'));
                case constants.CORRECTION_DETAILS_TYPE.WARRANTY_MILES:
                    return state.setIn(['partWarranty', 'miles'], action.correctionDetails.get('value'));
                case constants.CORRECTION_DETAILS_TYPE.WARRANTY_HOURS:
                    return state.setIn(['partWarranty', 'hours'], action.correctionDetails.get('value'));
            }

        case constants.TOGGLE_SHOW_MODAL_ADD_NEW_LINE_ITEMS:
            return state.set('showWindow', !state.get('showWindow'));

        case constants.ADD_COMPLAINT_CODE:
            return state.set('selectedComplaintCode', action.complaintCode);

        case constants.SAVE_LINE_ITEM_ID_MISSING_PRODUCT_CODE:
            return state.set('lineItemIdMissingProductCode', action.lineItemId);   

        case constants.CLEAR_LINE_ITEM_ID_MISSING_PRODUCT_CODE:
                return state.set('lineItemIdMissingProductCode', undefined);
        
        case constants.SAVE_LINE_ITEM_ID_REPAIR_TYPE_CHANGE:
            return state.set('lineItemIdRepairTypeChange', action.lineItemId);

        case constants.CLEAR_LINE_ITEM_ID_REPAIR_TYPE_CHANGE:
            return state.set('lineItemIdRepairTypeChange', undefined);

        case constants.RESET_ADD_NEW_LINE_ITEM_DATA:
            /*Reset values*/
            return state.setIn(['selectedATACode'], '')
                .setIn(['selectedCorrectionType'], 'None')
                .setIn(['selectedComplaintCode'], '')
                .setIn(['searchedATACode'], '')
                .setIn(['hours'],undefined)
                .setIn(['rate'], undefined)
                .setIn(['qty'], undefined)
                .setIn(['cost'], undefined)
                .setIn(['autoCompleteATACodeDescription'], '')
                .setIn(['hideAtaSearchResultContainer'], true)
                .setIn(['itemsAddedToPo'], Map())
                .remove('partWarranty')
                .set('errorMessage', [])
                .set('showAtaSearchToolTip', false)
                .set('canShowAtaSearchToolTip', true)
                .setIn(['tireDetails'], Map())                
                .set('showLaborRateUpdateWindow',false)
                ;

        case constants.CLEAR_FIELDS_AFTER_SAVE_ADD_NEW_LINE_ITEM_DATA:
            /*Reset values*/
            return state.setIn(['selectedATACode'], '')
                .setIn(['selectedCorrectionType'], 'None')
                .setIn(['searchedATACode'], '')
                .setIn(['hours'], undefined)
                .setIn(['rate'], undefined)
                .setIn(['qty'], undefined)
                .setIn(['cost'], undefined)
                .setIn(['autoCompleteATACodeDescription'], '')
                .setIn(['hideAtaSearchResultContainer'], true)
                .remove('partWarranty')
                .set('showLaborRateUpdateWindow',false)
                ;
        case constants.AUTO_COMPLETE_ATA_CODE_DESCRIPTION:
            return state.setIn(['autoCompleteATACodeDescription'], action.value);
        case constants.HIDE_ATA_SEARCH_RESULTS_CONTAINER:
            return state.set('hideAtaSearchResultContainer', action.hide);
        case constants.SAVE_RECENT_ITEMS_ADDED_TO_PO:
            return state.setIn(['itemsAddedToPo', (action.lineItemForView.get('vendorProductCode') != undefined ? action.lineItemForView.get('vendorProductCode') : action.lineItemForView.get('productCode'))], fromJS(action.lineItemForView));
        case constants.SHOW_ATA_SEARCH_TOOL_TIP:
            return state.set('showAtaSearchToolTip', action.show);
        case constants.CAN_SHOW_ATA_SEARCH_TOOL_TIP:                
            return state.set('canShowAtaSearchToolTip', action.canShow);
        case constants.UPDATE_TIRE_DETAILS:
            return state.setIn(['tireDetails', action.tireDetail.type], action.tireDetail.inputValue);
        case constants.UPDATE_TIRE_DETAILS_ALL:            
            return state.setIn(['tireDetails'], fromJS(action.tireDetails));                                
        case constants.UPDATE_TIRE_DETAILS_VALIDATION_ERRS:
            return state.setIn(['tireDetails','validationErrs', action.validationErrs.name], action.validationErrs.value);   
        case constants.REMOVE_TIRE_DETAILS_VALIDATION_ERROR:
            return state.deleteIn(['tireDetails','validationErrs', action.fieldName]);
        case constants.LOAD_TIRE_SPECIFICATION:
            return state.setIn(['tires','tireSpecification'], fromJS(action.tireSpecs))          
        case constants.SHOULD_SHOW_LINK_TO_TIRE_SPECIFICATION:
            return state.setIn(['tires','shouldShowContinueLink'], action.shouldShowContinueLink)                        
        case constants.SHOW_TIRE_SPECIFICATION:
            return state.setIn(['tires','showTireSpecification'], action.showTireSpecification)                        
        case constants.UPDATE_AXLES:
            return state.setIn(['tires','tireSpecification'], fromJS(action.axles));
        case constants.SAVE_TREAD_DEPTH_MEASUREMENT_UNIT:
            return state.setIn(['tires','defaultTreadDepthUnit'], action.defaultTreadDepthUnit); 
        case constants.RESET_TIRE_SPECIFICATIONS:
            return state.setIn(['tires','tireSpecification'], List())
            .set('tireDetails', Map())                       
        case constants.ADD_DUMMY_AXLE:
            return state.setIn(['tires','dummyAxlesToAdd'],fromJS(action.axles));           
        case constants.SAVE_TIRE_SPECIFICATIONS_RESOLVED:
            return state.setIn(['tires','isSaving'],false);      
        case constants.SAVE_TIRE_SPECIFICATIONS_REQUESTED:
            return state.setIn(['tires','isSaving'],true)
                        .setIn(['tires','errorMessage'], []);      
        case constants.SAVE_TIRE_SPECIFICATIONS_REJECTED:
            return state.setIn(['tires','isSaving'],false)
                        .setIn(['tires','errorMessage'], action.errorMessage);
        case constants.ADD_LINE_ITEM_QTY_MISMATCH_ERR:
            return state.setIn(['tires','lineItemQtyMismatchErr'],action.errorMessage)
        case constants.REMOVE_LINE_ITEM_QTY_MISMATCH_ERR:
            return state.setIn(['tires','lineItemQtyMismatchErr'],[])           
        case constants.SHOW_BRAKES:
            return state.setIn(['brakes','showBrakes'],action.showBrakes)                                    
        case constants.ADD_NEW_BRAKE_AXLE:
            return state.setIn(['brakes','brakeSpec'], fromJS(action.dataForNewBrake))                                    
       case constants.RESET_BRAKE_SPECIFICATIONS:
            return state.setIn(['brakes','brakeSpec'], List())
            //.setIn(['brakes','defaultBrakeMeasurementUnit'], 'inch')
            .setIn(['brakes','validationErrs'], Map())    
            .setIn(['brakes','errorMessage'], [])    
       case constants.UPDATE_BRAKES_VALIDATION_ERRS:                 
            return state.setIn(['brakes','validationErrs', action.validationErrs.name], action.validationErrs.value);   
        case constants.CLEAR_BRAKES_VALIDATION_ERRS:                 
            return state.setIn(['brakes','validationErrs'], Map()); 
        case constants.SAVE_BRAKES_MEASUREMENT_UNIT:
            return state.setIn(['brakes','defaultBrakeMeasurementUnit'], action.defaultUnit);    
        case constants.LOAD_BRAKES_SPECIFICATION:
            return state.setIn(['brakes','brakeSpec'], fromJS(action.brakesSpecs)) 
        case constants.SAVE_BRAKE_SPECIFICATIONS_REJECTED:
            return state.setIn(['brakes','errorMessage'], action.errorMessage);        
        case constants.RESET_BRAKES_UNIT: 
            return state.setIn(['brakes','defaultBrakeMeasurementUnit'], 'milli_meter');      
        case constants.FETCH_TIRE_SPECIFICATIONS_REQUESTED:
            return state.setIn(['tires','isFetching'],true)
                        .setIn(['tires','errorMessage'], []);      
        case constants.FETCH_TIRE_SPECIFICATIONS_RESOLVED:
            return state.setIn(['tires','isFetching'],false);      
        case constants.FETCH_TIRE_SPECIFICATIONS_REJECTED:
            return state.setIn(['tires','isFetching'],false)
                        .setIn(['tires','errorMessage'], action.errorMessage);      
        case constants.RESET_TIRES_UNIT:
            return state.setIn(['tires','defaultTreadDepthUnit'], 'inch') 
         case constants.SAVE_BRAKE_SPECIFICATIONS_REQUESTED:
            return state.setIn(['brakes','isSaving'],true);
         case constants.SAVE_BRAKE_SPECIFICATIONS_RESOLVED:
            return state.setIn(['brakes','isSaving'],false);                                                                                                                                         
        default:
            return state;
    }
}

export function serverDataAddNewLineItemsView(state = initialAppState.getIn(['serverData', 'addNewLineItemsView']), action) {
    switch (action.type) {
        case constants.LOAD_SEARCHED_ATA_CODES_DATA:
            return state.set('searchedAtaCodes', action.serviceResult);
        case constants.LOAD_SEARCHED_ATA_CODES_DATA_REQUESTED:
            return state.set('isFetchingData', true);
        case constants.LOAD_SEARCHED_ATA_CODES_DATA_RESOLVED:
            return state.set('isFetchingData', false);
        case constants.LOAD_SEARCHED_ATA_CODES_DATA_REJECTED:
            return state.set('isFetchingData', false);
        case constants.CLEAR_ATA_CODES_BY_SEARCH:
            return state.setIn(['searchedAtaCodes'], new Array());
        case constants.LOAD_FREQUENTLY_USED_ATA_CODES:
            return state.setIn(['frequentlyUsedATACodes'], action.freqentlyUsedATACodes);
        case constants.CLEAR_ADD_LINE_ITEMS_SERVER_DATA:
            return state.setIn(['frequentlyUsedATACodes'], Map())
                .setIn(['searchedAtaCodes'], new Array()).deleteIn(['ataParameters'], Map());
        case constants.SAVE_ATA_PARAMETERS:
            return state.set('ataParameters', fromJS(action.currentParameters));                
        case constants.LOAD_COPY_TIRE_SPECIFICATION:
            return state.setIn(['tires','copyTireSpecification'], fromJS(action.tireSpecs)) 
        case constants.LOAD_COPY_BRAKES_SPECIFICATION:
            return state.setIn(['brakes','copyBrakeSpecification'], fromJS(action.brakesSpecs)) 
        
        default:
            return state;
    }
}