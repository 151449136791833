// #region Imports
import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import SearchStoresStep from "./SearchStoresStep";
import PermissionsStep from "./PermissionsStep";
import styled from "styled-components";
import {    
  ModalBody
} from "DesignSpecs/components/FormComponents";
import { useAppState } from 'shared/AppState/app-state';
// #endregion

// #region Styled Components
const ModalStyled = styled(Modal).attrs({
  dialogClassName: "childStyle"
})`
  .childStyle {
    width: 900px;
    max-width: 900px;
}`;
// #endregion

// #region Component
const AddStoresToUser = ({ routeParams }) => {  
  // #region State
  const [selectedStores, setSelectedStores] = useState([]);      
  const [step, setStep] = useState('SearchStores');
  const [ appState, dispatch ] = useAppState();
  const { personaUserId, personaToken } = appState; 
  // #endregion

  // #region Ref
  // #endregion

  // #region Effects
  // #endregion

  // #region Event handlers 
  const handleStoreSelect = (store) => {
    // add store to the selected list if it doesn't already exist there    
    if (
      selectedStores == null ||
      selectedStores.length == 0 ||
      selectedStores.filter(a => {
        return a.id == store.id;
      }).length == 0
    ) {
      setSelectedStores(currentSelectedStores => currentSelectedStores.concat(store));
    }
  }

  const handleRemoveStore = (storeId) => {
    if (storeId != null && storeId != undefined) {
      var index = selectedStores.findIndex(a => a.id == storeId);
      if (index > -1) {        
        setSelectedStores(selectedStores.filter(a => a.id != storeId));
      }
    }
  }
  // #endregion

  // #region International messages  
  // #endregion

  // #region Render helpers    
  // #endregion  

  // #region Render
  return (    
    <ModalStyled		  
      show={true}
      backdrop="static"
      keyboard={false}          
    >                     
      <ModalBody>          
        { step === 'SearchStores' ?
          <SearchStoresStep
            selectedStores={selectedStores}
            token={personaToken}
            personaUserId={personaUserId}
            routeParams={routeParams}
            onStoreSelect={handleStoreSelect}
            onRemoveStore={handleRemoveStore}              
            onContinue={()=>setStep('AddPermissions')}
          />          
        : step === 'AddPermissions' &&
          <PermissionsStep
            selectedStores={selectedStores}            
            token={personaToken}
            personaUserId={personaUserId}
            routeParams={routeParams}            
            onRemoveStore={handleRemoveStore}
            onReturn={()=>setStep('SearchStores')}
          />
        }    
            
      </ModalBody>                
    </ModalStyled>    
  );
};
// #endregion

export default AddStoresToUser;