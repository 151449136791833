import { Map } from 'immutable';
import * as constants from 'constants/App';
import * as sharedActions from 'actions/App';
import moment from 'moment';

/* Toggles the visibility of Add SErvice line items modal*/
export function toggleShowWindow() {
    return {type: constants.TOGGLE_SHOW_MODAL_ADD_SERVICE_LINE_ITEMS}
}

/* Add populated service ata codes to the PO line items
 * Takes
 *   serviceATACodes: ata code map */
export function addServiceATACodeToPO(serviceATACodes) {
    return {type: constants.ADD_SERVICE_ATA_CODE_TO_PO_DATA, serviceATACodes}
}

/* Removes populated service ata codes from the temporarily state node (not from the PO)
 * Takes
 *   serviceATACode: ata code key*/
export function removeServiceATACode(serviceATACodeKey) {
    return {type: constants.REMOVE_SERVICE_ATA_CODE_DATA, serviceATACodeKey}
}

/* Adds PART or LABOR item to the given ata code
 * Takes
 *  serviceItemData: PART or LABOR data*/
export function addServiceLineItem(serviceItemData) {
    return {type: constants.ADD_SERVICE_ITEM, serviceItemData}
}

/* Remove PART or LABOR item for the given ata code
 * Takes
 *  serviceItemData: PART or LABOR data*/
export function removeServiceLineItem(serviceItemData) {
    return {type:constants.REMOVE_SERVICE_ITEM, serviceItemData}
}