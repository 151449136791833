import { call, put, select, take, cancel, fork }from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';

export function* submitVendorLinkedEnrollmentRequest(context) {
    try {
        //clear out any error messages
        yield put({ type: "CLEAR_ERROR_MESSAGE" });

        // Read data from app state
        const state = yield select();
        const token = helperFunctions.getToken(state);

        let linkedVendorRegistrationData = {
            vendorId: state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'registerAccount']),
            password:state.appState.getIn(['uiData', 'vendorLinkedRegister', 'linkedRequestInfo','newPassword']),
            contactName:state.appState.getIn(['uiData', 'vendorLinkedRegister', 'linkedRequestInfo','contactName']),
            paymentEmail:state.appState.getIn(['uiData', 'vendorLinkedRegister', 'linkedRequestInfo','emailAddress']),
            accountType: state.appState.getIn(['serverData','shared','vendorAccountType'])



        };

        const response = yield call(Api.submitVendorLinkedEnrollmentRequest, linkedVendorRegistrationData,helperFunctions.getToken(state), token);

        const vendorId= state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'registerAccount']);
        const password = state.appState.getIn(['uiData', 'vendorLinkedRegister', 'linkedRequestInfo','newPassword']);
        yield put({ type: constants.SIGN_IN_CLICKED ,userId: vendorId, password: password,createCookieOnSuccess: true});


        yield put({ type: "SUBMIT_VENDOR_LINKED_ENROLLMENT_REQUEST_RESOLVED" });
    }
    catch (e) {
        yield put({ type: "SUBMIT_VENDOR_LINKED_ENROLLMENT_REQUEST_REJECTED" , errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    }
}