// #region Imports
import React, { useState, useRef } from 'react';
import { connect } from "react-redux";
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import styled from "styled-components";
import './EditPaymentEmailInformation.css'
import { Popover, Overlay } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import FormWrapper from "components/FormWrapper";
import Submit from 'components/Submit';
import * as Api from "utils/Api";
// #endregion

// #region Styled Components
const PaymentEmailInfoRow = styled.div`
    border-top: 0px solid #ffffff;
    padding: 20px 40px 10px 40px;`;

// #endregion

// #region component
const EditPaymentEmailInformation = ({
    paymentEmail,
    onCancelAccountClick,
    token,
    vendorId,
    vendorLanguageCode,
    verificationToken,
    intl,
    dispatch
}) => {    
    const { formatMessage } = intl;

    // #region state
    const [email, setEmail] = useState(paymentEmail);
    const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
    const [apiCallErrorMessage, setApiCallErrorMessage] = useState(null);
    const [saving, setSaving] = useState(false);
    // #endregion

    // #region ref
    
    const emailRef = useRef();

    //#endregion
    
    // #region event handlers
    function onHide() {
        setShowEmailErrorMessage(false);
    }

    function onSavePaymentEmailHandler() {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if (re.test(String(email).toLowerCase())) {
            setShowEmailErrorMessage(false);
            handleSavePaymentEmail();
        }
        else {
            setShowEmailErrorMessage(true);
        }
        
    }

    const handleSavePaymentEmail = () => {

        const postData = async () => {
            try {
                
                setSaving(true);

                let response = await Api.saveVendorPaymentEmail(token, verificationToken, vendorLanguageCode, vendorId, email.toUpperCase());

                //on success update the new email in state

                dispatch({ type: constants.VENDOR_PAYMENT_EMAIL_SUCCESS,vendorId:vendorId, paymentEmail: email.toUpperCase() });

                setSaving(false);
                setApiCallErrorMessage(null);
            }
            catch (e) {
                
                //set error
                setSaving(false);
                setApiCallErrorMessage(helperFunctions.getCustomErrorObject(e));
                dispatch({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
            }
        }

        if (!saving)
            postData();
    }

    // #endregion

    // #region Effects
  // #endregion

  // #region International messages
    const messages = defineMessages({
        edit_payment_email_caption: {
            id: 'edit_payment_email_caption',
            description: 'Payment Email Recipient',
            defaultMessage: 'Payment Email Recipient'
        },
        edit_payment_email_validation_error_message: {
            id: 'edit_payment_email_validation_error_message',
            description: 'Please enter a valid payment email',
            defaultMessage: 'Please enter a valid payment email'
        },

        edit_payment_email_cancel__edit_caption: {
            id: 'edit_payment_email_cancel__edit_caption',
            description: 'cancel caption',
            defaultMessage: 'Cancel'
        },
        edit_payment_email_save__caption: {
            id: 'edit_payment_email_save__caption',
            description: 'Save Payment Email Recipient',
            defaultMessage: 'Save Payment Email Recipient'
        }
   });

    // #endregion

    // #region render
    return (
        <div>
            {
                <FormWrapper key='vendorEmailUpdateErrorMessage' id="vendorEmailUpdateErrorMessage"
                    formErrors={apiCallErrorMessage} />
            }
            <div className="edit_paymentEmail-component__view4 row">
                <div className="col-md-7 edit_paymentEmail-component__title">
                    {

                        <FormattedMessage {...messages.edit_payment_email_caption} />
                    }
                </div>

            </div>
            <div className="row">
                <div className="col-md-5 edit_paymentEmail-component__text">
                    <Overlay
                        show={showEmailErrorMessage}
                        onHide={onHide}
                        placement="right"
                        target={props => emailRef.current}

                    >
                        <Popover id="RoutingNumberErrorMessage"
                            className="edit_paymentEmail-component__errorMessage">
                                 <RawIntlProvider value={intl}>
                            {formatMessage(messages.edit_payment_email_validation_error_message)}
                            </RawIntlProvider>
                        </Popover>

                    </Overlay>
                    <input ref={emailRef} className="edit_paymentEmail-component__input"
                        type="text" maxLength={50}
                        defaultValue={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                    />
                </div>
            </div>

            <div className="edit_paymentEmail-component__view2 row">
                <div className="col-md-6 edit_paymentEmail-component__cancel-padding">
                    <Submit caption={<FormattedMessage {...messages.edit_payment_email_cancel__edit_caption } />}
                            imagePath="/assets/VMS_28button_delete_blue.png"
                            onClickHandler={onCancelAccountClick}
                            showSpinner={false} 
                            spinnerType ={'blue'}
                            />

                </div>
               <div className="col-md-6 edit_paymentEmail-component__save-padding">
                    <div className="edit_paymentEmail-component__caption">
                    <Submit caption={<FormattedMessage {...messages.edit_payment_email_save__caption } />}
                            imagePath="/assets/VMS_28button_edit_clear.png"
                            onClickHandler={onSavePaymentEmailHandler}
                            showSpinner={saving} 
                            spinnerType ={'blue'}
                            />

                    </div>
                </div>

            </div>
        </div>

    );

    // #endregion
};

// #region Props
const mapStateToProps = state => {
    return {
        //token: state.appState.getIn(["serverData", "shared", "personaToken"]),
        token: state.appState.getIn(["serverData", "shared", "token"]),
        vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
        vendorLanguageCode: state.appState.getIn(['uiData', 'shared', 'selectedLocale']),
        verificationToken: state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'verify'])

    };
};
// #endregion

// #endregion

export default connect(mapStateToProps)(injectIntl(EditPaymentEmailInformation));
