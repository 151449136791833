import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import './BitInspectionFormFailOptions.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';

class BitInspectionFormFailOptions extends React.Component {	
	handleYesClick = () => {
		this.props.onYesClick();
	}

	handleNoClick = () => {
		this.props.onNoClick();
	}


	render() {
		const messages = defineMessages({			
			bit_inspection_form__fail_confirm_title: {
                id: 'bit_inspection_form__fail_confirm_title',
                description: 'BIT Inspection - fail confirmation title',
                defaultMessage: 'BIT inspection'
            }, 
			bit_inspection_form__fail_confirm_question: {
                id: 'bit_inspection_form__fail_confirm_question',
                description: 'BIT Inspection - fail confirmation question',
                defaultMessage: 'Are you going to repair the failed items on this repair invoice?'
			},
			bit_inspection_form__fail_confirm_yes: {
                id: 'bit_inspection_form__fail_confirm_yes',
                description: 'BIT Inspection form - fail yes button text',
                defaultMessage: 'Yes and edit PO'
            },
            bit_inspection_form__fail_confirm_no: {
                id: 'bit_inspection_form__fail_confirm_no',
                description: 'BIT Inspection form - fail No button text',
                defaultMessage: 'No and continue'
			},						
		});

		return (
			<div className='bit_inspection_form_fail_options'>
				
				<div className='bit_inspection_form_fail_options__title'>
					<FormattedMessage {...messages.bit_inspection_form__fail_confirm_title} />
				</div>

				<div className='bit_inspection_form_fail_options__question'>
					<FormattedMessage {...messages.bit_inspection_form__fail_confirm_question} />
				</div>

				<div className='row'>
					<div 
						className='col-sm-6 bit_inspection_form_fail_options__answer' 
						onClick={this.handleYesClick}
					>
						<img src="/assets/VMS_28button_kill_clear.png" />{' '}
						<FormattedMessage {...messages.bit_inspection_form__fail_confirm_yes} />
					</div>
					<div 
						className='col-sm-6 bit_inspection_form_fail_options__answer text-right' 
						onClick={this.handleNoClick}
					>
						<FormattedMessage {...messages.bit_inspection_form__fail_confirm_no} />
						{' '}<img src="/assets/VMS_33button_go_bblue.png" />
						<div className='bit_inspection_form__spinner'>
							{this.props.isSaving &&
								<Spinner spinnerType='blue' />										
							}
						</div>
					</div>
				</div>

			</div>
		);
	}
}

export default BitInspectionFormFailOptions;