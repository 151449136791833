import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import ServiceLineItem from './ServiceLineItem';
import './ServiceLineItems.css';
import { Col, Container, Row } from 'react-bootstrap';

const ServiceLineItems = (props) => {
    //Returns bool of price confirmation checkbox
    const getPriceConfirmed = (index) =>{
        return props.pricesConfirmedList[index];
    }

    const getHeader = () => {
        const messages = defineMessages({
            service_header_caption: {
                id: 'service_header_caption',
                description: 'Service ',
                defaultMessage: 'Service ',
            },
            total_part_header_caption: {
                id: 'total_part_header_caption',
                description: 'Parts',
                defaultMessage: 'Parts',
            },
            total_labor_header_caption: {
                id: 'total_labor_header_caption',
                description: 'Labor',
                defaultMessage: 'Labor',
            },
            total_amount_header_caption: {
                id: 'total_amount_header_caption',
                description: 'Total',
                defaultMessage: 'Total',
            },
        });
        return <Row className="service-line-items__row service-line-items__header">
            <Col sm md={6} className="service-line-items__description_header">
                <FormattedMessage {...messages.service_header_caption} />
            </Col>
            <Col sm md={2} className="service-line-items__cost_header">
                <FormattedMessage {...messages.total_part_header_caption} />
            </Col>
            <Col sm md={2} className="service-line-items__cost_header">
                <FormattedMessage {...messages.total_labor_header_caption} />
            </Col>
            <Col sm md={2} className="service-line-items__total_header">
                <FormattedMessage {...messages.total_amount_header_caption} />
            </Col>
        </Row>;
    };

    const getRows = () => {
        return props.serviceATAList.valueSeq().map((item, i)=>
                <ServiceLineItem ataCode={item}
                                 handleChange={props.handleChange}
                                 key={item.get('productCode')}
                                 priceValidations={props.priceValidations}
                                 priceConfirmed={getPriceConfirmed.bind(null,i)}
                                 handlePriceConfirmedClicked={() => {props.handlePriceValdationConfirmationButtonClick(i)}}
                                 serviceItemRef={(ref) => {
                                    const refMap = props.getRefMap();
                                    if(ref){
                                        props.serviceRefList.current = refMap.set(i, ref);
                                    }else{
                                        props.serviceRefList.current = refMap.delete(i);
                                    }
                                 }}
                />
        )
    };


        return (
            <div className="table-responsive">
                <Container style={{padding: "0"}}>
                    {getHeader()}
                    {getRows()}
                </Container>

            </div>
        );
}
;


export default ServiceLineItems;

