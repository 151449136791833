import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import classnames from 'classnames';
import './Header.css';
import LoginHeader from 'components/LoginHeader';
import NavigationBar from './NavigationBar';
import {Route,Switch,BrowserRouter  } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAuthenticated, hasTokenExpired } from '../shared/utils/HelperFunctions';
import {withRouter} from 'react-router-dom'
import ARIUserNavigationBar from './ARIUserNavigationBar';

class Header extends React.Component {
    onViewAllOpenPOsHandler(event) {
        event.preventDefault();
        this.props.onNavItemClick("/");
    }
    onSignOutClickHandler(event) {
        event.preventDefault();
        this.props.onSignOutClick();
    }

    render() {
        const messages = defineMessages({
            your_account_link: {
                id: 'your_account',
                description: 'Text for Your Account Link in header',
                defaultMessage: 'Your Account'
            },
            sign_out_link: {
                id: 'sign_out',
                description: 'Text for Sign Out Link in header',
                defaultMessage: 'Sign Out'
            },
            help_link_2: {
                id: 'help_link_2',
                description: 'Text for Help Link in header',
                defaultMessage: 'Help'
            },
            contact_link_2: {
                id: 'contact_link_2',
                description: 'Text for Contact Link in header',
                defaultMessage: 'Contact'
            },            
        });

        return <div>
            <div className="row site-header">
                <div className="offset-xl-3 col-xl-7 offset-lg-2 col-lg-8 offset-md-1">
                    <Route exact path="/login" component={LoginHeader} />
                    <Route exact path="/"  component={NavigationBar} />
                    <Route path="/carWash" exact component={NavigationBar} />
                    <Route path="/carWash/vehicle/:vehicleId" exact component={NavigationBar} />
                   <Route exact path="/contact/HelpFAQ" render={
                        (routeParams) => ((this.props.token === undefined || hasTokenExpired(this.props.token)) && (this.props.personaToken === undefined || hasTokenExpired(this.props.personaToken))
                            ? false
                            : <NavigationBar {...routeParams} />)} />
                    <Route path="/contact/FeedbackForm" exact render={
                        (routeParams) => ((this.props.token === undefined || hasTokenExpired(this.props.token)) && (this.props.personaToken === undefined || hasTokenExpired(this.props.personaToken))
                            ? false
                            : <NavigationBar {...routeParams} />)} />
                    <Route path="/yourAccount" component={NavigationBar} />
                    <Route path="/ariuser" exactly component={ARIUserNavigationBar} />
                    <Route path="/dealerDrafts" component={NavigationBar} />
                    <Route path="/linkedVendorAccount" exact render={
                        () => false} />
                    <Route path="/recoverPassword" exact render={
                        () => false} />
                    <Route path="/resetPassword" exact render={
                        () => false} />                   
                    <Route path="/terms" exact render={
                        () => false} />
                    <Route path="/exceptions" exact render={
                        () => false} />
                    <Route path="/attentionNeeded" component={NavigationBar} />
                    <Route path="/AccidentManagement" component={NavigationBar} />
                    <Route path="/learnMore" exact render={
                        (routeParams) => ((this.props.token === undefined || hasTokenExpired(this.props.token))
                            ? false
                            : <NavigationBar {...routeParams} />)} />

                    <Route path="/vehicle/:vehicleId/documents" exact component={NavigationBar} />

                    <Route exact path="/po/search" component={NavigationBar} />
                    <Route exact path="/vehicles/vin8/:vin8/:actionType"  component={NavigationBar} />
                    <Route exact path="/vehicles/plate/:plate/state/:state/:actionType"  component={NavigationBar} />
                    <Route exact path="/vehicles/clientId/:clientId/unitNo/:unitNo/:actionType"  component={NavigationBar} />
                    <Route exact path="/vehicles/serviceNo/:serviceNo/:actionType"  component={NavigationBar} />

                    <Route exact path="/po/:actionType/:trackNumber" render={(matchProps) => (
                        <Switch>
                            <Route exact path="/po/odometer/:vehicleId" component={NavigationBar} />
                            <Route exact path="/po/repairHistory/:vehicleID" component={NavigationBar} />
                            <Route exact path="/po/complaint/:vehicleId" component={NavigationBar} />

                            <Route exact path="/po/clientParameters/:vehicleID" component={NavigationBar} />
                            <Route exact path="/po/actionRequired/:trackNumber" component={NavigationBar} />

                            <Route component={NavigationBar} />
                        </Switch>
                    )} />
                 <Route path="/persona/resetPassword" exact render={
                        () => false} />  
                <Route path="/persona/setupPassword" exact render={
                        () => false} />  
                {this.props.location.pathname.startsWith('/Persona/') ?
                <Route component={NavigationBar}/> :false}
                
                </div>
            </div>
        </div>;
    }
}



function mapStateToProps(state, props) {
    return {
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        personaToken:state.appState.getIn(['serverData','shared','personaToken']),
    };
}

export default withRouter(connect(mapStateToProps)(Header));