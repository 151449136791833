import * as constants from 'constants/App';
import { Map } from 'immutable';

const initialUiData = Map();
const initialServerData = Map();

export function uiDataARIUser(state = initialUiData, action) {
    switch (action.type) {
        case constants.FETCH_SEARCH_VENDOR_REQUESTED:
            return state.set('isFetchingData', true)
                .setIn(['searchVendorErrorMessage'], undefined);
        case constants.FETCH_SEARCH_VENDOR_REJECTED:
            return state.set('isFetchingData', false)
                .set('isRequestedRejected', true);
        case constants.FETCH_SEARCH_VENDOR_RESOLVED:
            return state.set('isFetchingData', false);
        case constants.POP_OVER_DISPLAY_SHOW_VENDOR_ID_EMPTY:
            return state.setIn(['popOver', 'isPopOverDisplayShowVendorIdEmpty'],
                action.popOverDisplayShowVendorIdEmpty, !state.getIn(['popOver', 'isPopOverDisplayShowVendorIdEmpty'],
                    action.popOverDisplayShowVendorIdEmpty));
        case constants.POP_OVER_DISPLAY_SHOW_SUFFIX_MISSING:
            return state.setIn(['popOver', 'isPopOverDisplayShowSuffixMissing'],
                action.popOverDisplayShowSuffixMissing, !state.getIn(['popOver', 'isPopOverDisplayShowSuffixMissing'],
                    action.popOverDisplayShowSuffixMissing));
        case constants.POP_OVER_DISPLAY_SHOW_VENDOR_PERMISSION:
            return state.setIn(['popOver', 'isPopOverDisplayShowVendorPermission'],
                action.popOverDisplayShowVendorPermission, !state.getIn(['popOver', 'isPopOverDisplayShowVendorPermission'],
                    action.popOverDisplayShowVendorPermission));
        case constants.SET_SEARCH_VENDOR_ERROR_MESSAGE:
            return state.set('searchVendorErrorMessage', action.errorMessage);
        case constants.HIDE_SEARCH_VENDOR_ERROR_MESSAGE:
            return state.set('searchVendorErrorMessage', undefined);        
        case constants.CLEAR_ARIUER_SEARCH_VENDOR:
            return state.setIn(['popOver', 'isPopOverDisplayShowVendorPermission'], false)
                .setIn(['popOver', 'isPopOverDisplayShowVendorIdEmpty'], false)
                .setIn(['popOver', 'isPopOverDisplayShowSuffixMissing'], false)
                .setIn(['searchVendorErrorMessage'], undefined)
                .set('isFetchingData', false)
                .set('isRequestedRejected', true)
        default:
            return state;
    }
}

export function serverDataARIUser(state = initialServerData, action) {
    switch (action.type) {
        case constants.ARIUSER__SEARCH_NEW_VENDOR:
            return state.set('token', action.token)
                .set('vendorId', action.vendorId);        
        default:
            return state;
    }
}