import React from 'react';
import './BulletinMessage.css';
import {defineMessages, FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {emptyMapIfUndefined, falseIfUndefined} from 'utils/HelperFunctions';
import ShopViewSelector from "../selectors/ShopView";
import {withRouter} from 'react-router-dom'

class BulletinMessage extends React.Component {

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    render(){

            const messages = defineMessages({
                return: {
                    id: 'return',
                    description: 'Return button',
                    defaultMessage: 'Return'
                }
            });

        var selectedBulletinMessage = this.props.bulletinMessage.filter((param) => param.get("id") == this.props.routeParams.match.params.id);

        return <div>
            {selectedBulletinMessage.length === 0 ? false :
               selectedBulletinMessage.map((params) =>
                 <div>
                    <h1 className="bulletin-component__header">
                        <FormattedMessage id='alert_message_title' defaultMessage={params.get("subTitle")} values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                    </h1>
                    <p className="bulletin-component__message">
                    <div
                        dangerouslySetInnerHTML={{
                            __html:  params.get("message")
                        }}></div> 
                    </p>
                    <div className="bulletin-component__return"
                         role="button"
                         onClick={this.onReturnClickHandler.bind(this)}>
                        <img src="/assets/VMS_33button_back_blue.png"/>
                        {' '}
                        <FormattedMessage {...messages.return}/>
                    </div>
                </div>
            )}
        </div>;


    }

}
function select(state, props) {
    return{
        ...ShopViewSelector(state, props),

    };

}

export default withRouter(connect(select)(BulletinMessage));