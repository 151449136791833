import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map} from 'immutable';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
import {fromJS}  from 'immutable';

export function serverDataComplaintView(state = initialAppState.getIn(['serverData', 'complaintView']), action) {
    switch (action.type) {
        case constants.FETCH_DATA_FOR_COMPLAINT_SCREEN_RESOLVED:        
            return state.setIn(['maintenanceServices'], !action.maintenanceServices ? Map() : fromJS(action.maintenanceServices))
                    .setIn(['regulatoryServices'], !action.regulatoryServices ? Map() : fromJS(action.regulatoryServices))
                    .setIn(['ataCodes'], !action.ataCodes ? Map() : fromJS(action.ataCodes));
        case constants.LOAD_ATA_CODES_DATA:
            return state.set('ataCodes', action.ataCodes);
        default:
            return state;
    }
}

export function uiDataComplaintView(state = initialAppState.getIn(['uiData', 'complaintView']), action) {
    switch (action.type) {
        case constants.FETCH_DATA_FOR_COMPLAINT_SCREEN_REQUESTED:
            return state.set('isFetchingData', true)
                        .set('serviceErrorMessage',[])
                        .set('showErrorMessages', false);
        case constants.FETCH_DATA_FOR_COMPLAINT_SCREEN_RESOLVED:
            return state.set('isFetchingData', false)
                        .set('serviceErrorMessage',[]);       
        case constants.FETCH_DATA_FOR_COMPLAINT_SCREEN_REJECTED:
            return state.set('isFetchingData', false)
                        .set('serviceErrorMessage', action.errorMessage);
        case constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REQUESTED:
             return state.set('isGotoNextStepRequested', true)
                         .set('errorMessage', []);   
        case constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_REJECTED:
            return state.set('isGotoNextStepRequested', false)
                        .set('errorMessage', action.errorMessage);  
        case constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN_RESOLVED:
            return state.set('isGotoNextStepRequested', false)
                        .set('errorMessage', []);                
        case constants.TOGGLE_SERVICE_SELECTION:
            if (state.getIn(['servicesSelected', action.serviceId]) == undefined)
                return state.setIn(['servicesSelected', action.serviceId], action.serviceId);
            else
                return state.deleteIn(['servicesSelected', action.serviceId]);
        case constants.SET_DIAGNOSIS_DETAILS:
            return state.setIn(['diagnosisItem', 'hours'], action.hours).setIn(['diagnosisItem', 'rate'], action.rate).setIn(['diagnosisItem', 'diagnosisRadioSelected'], action.diagnosisSelected);
        case constants.CLEAR_DIAGNOSIS_DETAILS:
            return state.setIn(['diagnosisItem', 'hours'], undefined).setIn(['diagnosisItem', 'rate'], undefined).setIn(['diagnosisItem', 'diagnosisRadioSelected'], false).setIn(['diagnosisItem', 'readOnly'], false);
        case constants.CLEAR_SELECTED_SERVICES:
            return state.setIn(['servicesSelected'], Map());
        case constants.COMPLAINT_VIEW_SHOW_ERRORS:
            return state.set('showErrorMessages', true);
        case constants.CLEAR_COMPLAINT_VIEW_SHOW_ERRORS:
            return state.set('showErrorMessages', false);
        default:
            return state;
    }
}

