import * as constants from 'constants/App';

export function showPopOverInvalidCurrentPassword(popOverDisplayShowCurrentPassword) {
    return { type: constants.POP_OVER_DISPLAY_INVALID_CURRENT_PASSWORD, popOverDisplayShowCurrentPassword }
}

export function showPopOverInvalidPassword(popOverDisplayShowPassword) {
    return { type: constants.POP_OVER_DISPLAY_INVALID_PASSWORD, popOverDisplayShowPassword }
}

export function showPopOverConfirmPassword(popOverDisplayShowConfirmPassword) {
    return { type: constants.POP_OVER_DISPLAY_CONFIRM_PASSWORD, popOverDisplayShowConfirmPassword }
}

export function showResetPasswordInvalidTokenError(resetPasswordInvalidTokenError) {
    return { type: constants.RESET_PASSWORD_INVALID_TOKEN_ERROR, resetPasswordInvalidTokenError }
}

export function saveNewPasswordErrorMessage(newPasswordErrorMessage) {
    return { type: constants.SAVE_NEW_PASSWORD_ERROR_MESSGAE, newPasswordErrorMessage }
}

export function saveConfirmPasswordErrorMessage(confirmPasswordErrorMessage) {
    return { type: constants.SAVE_CONFIRM_PASSWORD_ERROR_MESSGAE, confirmPasswordErrorMessage }
}

export function saveCurrentPasswordErrorMessage(currentPasswordErrorMessage) {
    return { type: constants.SAVE_CURRENT_PASSWORD_ERROR_MESSGAE, currentPasswordErrorMessage }
}
