import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';

export function uiDataLinkedVendorView(state = initialAppState.getIn(['uiData', 'vendorLinkedAccounts']), action) {
    switch (action.type) {
        case constants.LINKED_VENDORS_REQUESTED:
            return state.set('isFetchingData', true);
        case constants.LINKED_VENDORS_REJECTED:
            return state.set('isFetchingData', false);
        case constants.LINKED_VENDORS_RESOLVED:
            let hasMoreLinkedVendors = action.vendorSubVendor == undefined || action.vendorSubVendor.size == 0 ? false : true;
            return state.set('isFetchingData', false).set('vendorSubVendor',state.getIn(['vendorSubVendor']).merge(fromJS(action.vendorSubVendor))).set('nextLinkedVendorsExist',hasMoreLinkedVendors);
        case constants.CLEAR_LINKED_VENDORS_SEARCHED:
            return state.setIn(['vendorSubVendor'],Map());
        case constants.SAVE_REGISTER_LINKED_VENDOR:
            return state.set('registerAccount', action.register);
        case "SAVE_CURRENT_CORPORATE_VENDOR_DETAILS":
            return state.set('corporateInformation',fromJS(action.corporateVendorInformation));
        case constants.CLEAR_CORPORATE_VENDOR_DETAILS:
            return state.set('corporateInformation',undefined);
        default:
            return state;
    }
}