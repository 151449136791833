import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Map} from 'immutable';
import './MyUnitsView.css';
import MyUnitsViewSelector from '../selectors/MyUnitsView';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import {emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, zeroIfUndefined} from 'utils/HelperFunctions';
import MyUnits from '../components/Units';
import MyServicesDueModal from '../components/MyServicesDueModal';
import ConfirmMessageBox from '../components/ConfirmRejectBox';
import {Modal} from 'react-bootstrap';

class MyUnitsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            createFilters: {
            }
        };
    }
    componentDidMount() {
        this.props.dispatch({type: constants.FETCH_VENDOR_UNITS});
        this.props.dispatch({type: constants.FETCH_VENDOR_OTHER_UNITS});
    };

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    onCreatePOClickHandler() {

        let createFilters = this.state.createFilters;
        createFilters.createFor= "VIN8";
        createFilters.createValue = {
            vin8: this.props.currentVin8,
            myUnitsPage: true
        };

        this.props.dispatch({type: "CREATE_PO_SEARCH_CLICK", createFilters});

    }

    onCancelServiceModalClickedHandler() {
        this.props.dispatch({type: constants.MY_UNITS_SCREEN_UNLOADED});
        this.props.dispatch({type: constants.MY_OTHER_UNITS_SCREEN_UNLOADED});
    }

    fetchMoreUnitsClicked() {
        this.props.dispatch({type: constants.FETCH_MORE_UNITS_CLICK});
    }

    fetchMoreOtherUnitsClicked() {

        this.props.dispatch({type: constants.FETCH_MORE_OTHER_UNITS_CLICK});
    }

    componentWillUnmount() {
        this.props.dispatch({type: "MY_UNIT_UNLOADED"});
        this.props.dispatch({type: "MY_OTHER_UNIT_UNLOADED"});
    }


    onEmailClickHandler() {
        this.props.dispatch({type: constants.EMAIL_VENDOR_UNITS});

    }

    onOkClickHandler() {

        this.props.dispatch({type: constants.REJECT_VENDOR_UNITS, vehicleId: this.props.currentVehicleId});

    }

    onRejectClickHandler(id) {

        this.props.dispatch({type: constants.CURRENT_VEHICLE_ID, vehicleId: id});
        this.refs.rejectUnitConfirmWindow.showModal();
    }

    onServiceDueClickHandler(id, vin8) {
        this.props.dispatch({type: constants.CURRENT_VIN_ID, vin8: vin8});
        this.props.dispatch({type: "SEARCH_VEHICLE_FOR_MY_UNITS_CLICKED", vehicleId: id})
        this.props.dispatch({type: constants.COMPLAINT_SCREEN_LOADED, vehicleId: id});
        this.props.dispatch({type: constants.SHOW_DUE_SERVICES});
    }


    render() {
        const messages = defineMessages({
            your_units_title: {
                id: 'your_units_title',
                description: 'your units',
                defaultMessage: 'Your Units'
            },
            units_due_caption: {
                id: 'units_due_caption',
                description: 'units with services due caption',
                defaultMessage: 'Clients with Holman vehicles that you have serviced in the past'
            },
            units_due_sub_caption: {
                id: 'units_due_sub_caption',
                description: 'units with services due sub caption',
                defaultMessage: 'Holman High Performance Vendors have visibility to vehicles in their respective areas, and are provided with tools to help manage the preventative services on those assets. This page contains information such as vehicles which are coming due or are past due for preventive services or inspections. Holman is providing this information only to our High Performance Vendors.'
            },
            units_due_sub_caption_non_hp: {
                id: 'units_due_sub_caption_non_hp',
                description: 'units with services due sub caption',
                defaultMessage: 'Holman High Performance Vendors have visibility to vehicles in their respective areas, and are provided with tools to help manage the preventative services on those assets. This page contains information such as vehicles which are coming due or are past due for preventive services or inspections.'
            },
            return: {
                id: 'return',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            other_units_caption: {
                id: 'other_units_caption',
                description: 'other units caption',
                defaultMessage: 'Other units'
            },
            confirm_reject_title: {
                id: 'confirm_reject_title',
                description: 'confirm reject title',
                defaultMessage: 'Rejecting Unit?'
            },
            confirm_reject_message: {
                id: 'confirm_reject_message',
                description: 'confirm reject message',
                defaultMessage: 'Are you sure, you want to reject the unit?'
            },
            confirm_reject_yes: {
                id: 'confirm_reject_yes',
                description: 'confirm reject yes btn',
                defaultMessage: 'Yes'
            },
            confirm_reject_no: {
                id: 'confirm_reject_no',
                description: 'confirm reject no btn',
                defaultMessage: 'No'
            },
            other_units_due_sub_caption: {
                id: 'other_units_due_sub_caption',
                description: 'other_units_due_sub_caption',
                defaultMessage: 'Additional business opportunities, these units have not been in your shop.'
            },
        });

        if (this.props.isFetchingDataUnits) {
            return <Spinner />;
        }
        else if (this.props.isFetchingDataOtherUnits) {
            return <Spinner />;
        }

        return <div>
            {
                <ConfirmMessageBox ref="rejectUnitConfirmWindow"
                                   title={messages.confirm_reject_title}
                                   message={messages.confirm_reject_message}
                                   okButtonText={messages.confirm_reject_yes}
                                   cancelButtonText={messages.confirm_reject_no}
                                   onOKClickEvent={this.onOkClickHandler.bind(this)}
                                   onCancelClickEvent={null}/>

            }
            {
                <Modal show={this.props.myServicesShowWindow}
                       onHide={() => this.props.dispatch({type: constants.SHOW_DUE_SERVICES})}
                       dialogClassName="my_units-view"
                       backdrop="static"
                       keyboard={false}>
                    <Modal.Body>
                        <div className="my_units-view__body">

                            <MyServicesDueModal
                                services={this.props.services}
                                onCancelServiceModalClicked={this.onCancelServiceModalClickedHandler.bind(this)}
                                onCreatePOClick={this.onCreatePOClickHandler.bind(this)}
                                isFetchingDataModal={this.props.isFetchingDataModal}
                            />

                        </div>
                    </Modal.Body>
                </Modal>

            }
            <div className="my_units_view__title flex-row justify-center">
                <FormattedMessage {...messages.your_units_title}/>
            </div>
            <div className="my_units_view__caption row">
                <FormattedMessage {...messages.units_due_caption}/>
            </div>

            {this.props.isHPVendor === "Y" || this.props.myUnits.size !== 0  || this.props.myOtherUnits.size !== 0 ?
                <div>
                    <div className="my_units_view__text_pad row">
                        <div className="my_units_view__text col-md-11">
                            <FormattedMessage {...messages.units_due_sub_caption}/>
                        </div>
                    </div>
                    <MyUnits
                        onCreatePOClickHandler={this.onCreatePOClickHandler.bind(this)}
                        fetchMoreUnitsClicked={this.fetchMoreUnitsClicked.bind(this)}
                        onRejectClickHandler={this.onRejectClickHandler.bind(this)}
                        onServiceDueClickHandler={this.onServiceDueClickHandler.bind(this)}
                        nextUnitsExist={this.props.nextUnitsExist}
                        units={this.props.myUnits}
                        isHPVendor={this.props.isHPVendor}
                        isFetchingDataModal={this.props.isFetchingDataModal}
                    />
                    <div>
                            <hr className="my_units_view__hr"/>
                            <div className="my_units_view__caption row">
                                <FormattedMessage {...messages.other_units_caption}/>
                            </div>
                            <div className="my_units_view__text_pad row">
                                <div className="my_units_view__text col-md-11">
                                    <FormattedMessage {...messages.other_units_due_sub_caption}/>
                                </div>
                            </div>

                            <MyUnits
                                onCreatePOClickHandler={this.onCreatePOClickHandler.bind(this)}
                                fetchMoreUnitsClicked={this.fetchMoreOtherUnitsClicked.bind(this)}
                                onRejectClickHandler={this.onRejectClickHandler.bind(this)}
                                onServiceDueClickHandler={this.onServiceDueClickHandler.bind(this)}
                                nextUnitsExist={this.props.nextOtherUnitsExist}
                                units={this.props.myOtherUnits}
                                isHPVendor={this.props.isHPVendor}
                                isFetchingDataModal={this.props.isFetchingDataModal}
                            />
                        </div>
                </div>
                :
                <div>
                    <div className="my_units_view__text_pad row">
                        <div className="my_units_view__text col-md-11">
                            <FormattedMessage {...messages.units_due_sub_caption_non_hp}/>
                        </div>
                    </div>
                    <div className="my_units-view_img">
                        <img src="/assets/myUnits.png"/>
                    </div>
                </div>
            }
            {/*
             Could be used for future use

             <MyUnits
             nextUnitsExist={this.props.nextUnitsExist}
             units={this.props.myUnits}
             isHPVendor={this.props.isHPVendor}
             fetchMoreUnitsClicked={this.fetchMoreUnitsClicked.bind(this)}
             onServiceDueClickHandler={this.onServiceDueClickHandler.bind(this)}
             />
             <hr className="my_units_view__hr"/>
             <div className="my_units_view__caption row">
             <FormattedMessage {...messages.other_units_caption}/>
             </div>
             <div className="my_units_view__text_pad row">
             <div className="my_units_view__text col-md-11">
             <FormattedMessage {...messages.other_units_due_sub_caption}/>
             </div>
             </div>
             <MyUnits
             nextUnitsExist={this.props.nextOtherUnitsExist}
             units={this.props.myOtherUnits}
             isHPVendor={this.props.isHPVendor}
             fetchMoreUnitsClicked={this.fetchMoreOtherUnitsClicked.bind(this)}
             onServiceDueClickHandler={this.onServiceDueClickHandler.bind(this)}
             />
             */}

            <div className="my_units_view__return"
                 role="button"
                 onClick={this.onReturnClickHandler.bind(this)}>
                <img src="/assets/VMS_33button_back_blue.png"/>
                {' '}
                <FormattedMessage {...messages.return}/>
            </div>
        </div>;
    }
}

function select(state) {
    return {
        nextUnitsExist: falseIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'nextUnitsExist'])),
        rejectConfirm: falseIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'rejectConfirm'])),
        myServicesShowWindow: falseIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'showWindow'])),
        nextOtherUnitsExist: falseIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'nextOtherUnitsExist'])),
        currentVin8: emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'currentVin8'])),
        currentVehicleId: emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'currentVehicleId'])),
        isFetchingDataUnits: falseIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'isFetchingDataUnits'])),
        isFetchingDataOtherUnits: falseIfUndefined(state.appState.getIn(['uiData', 'vendorMyUnits', 'isFetchingDataOtherUnits'])),
        isFetchingDataModal: falseIfUndefined(state.appState.getIn(['uiData', 'complaintView', 'isFetchingData'])),
        isHPVendor: falseIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'isHPVendor'])),
        ...MyUnitsViewSelector(state)
    };
}

export default  connect(select)(MyUnitsView);