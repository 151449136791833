// Libraries
import React from 'react'
// Our stuff
import './BusyLayout.css';

const BusyLayout = (props) => {    
    return <div className='documents__busy_layout'>
        {props.children}
    </div>
}

export default BusyLayout;