/**
 * Created by prboyapa on 1/11/2016.
 */
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { LINE_ITEM_STATUS,PO_AUTHORIZATION_STATUS  } from 'constants/App';
import './Title.css';

class Title extends React.Component {
   
    getCaptionByAction() {
        const messages = defineMessages({            
            approval_not_requested:{
                id:'approval_not_requested',
                description:'Approval not requested',
                defaultMessage:'Approval not requested'
            },           
            action_required_title:{
                id:'action_required_title',
                description:'Your Action Required',
                defaultMessage:'Your Action Required'
            }            
        }); 

        
        if(this.props.authorizationStatus==PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED)
            return <FormattedMessage {...messages.approval_not_requested}/>;
        else
        return <FormattedMessage {...messages.action_required_title}/>;        
        
    }
   

    render() {
        const vinNumber = this.props.vin;
        var lastOfVin8='';
        var firstOfVin8='';
        if (this.props.vin != undefined) {
            lastOfVin8 = vinNumber.slice(9, vinNumber.length);
            firstOfVin8 = vinNumber.slice(0, 9);
        }
        const messages = defineMessages({
            adjust_reject_purchase_order_number: {
                id: 'adjust_reject_purchase_order_number',
                description: 'Purchase order no.',
                defaultMessage: 'Purchase order no.'
            },
            action_required_line_items_title:{
                id:'action_required_line_items_title',
                description:'PO Request for VIN:',
                defaultMessage:'PO Request for VIN:'
            }
        });
        
        return <div className='container-fluid adjusted_po__title'>
            <div>
                {this.getCaptionByAction()}
                <div className="row justify-content-center" >
                        
                        <div className="adjusted_po__sub_title">
                        <FormattedMessage {...messages.action_required_line_items_title}/>
                        <span className="adjusted_po__sub_title_vin">
                            {firstOfVin8}</span>
                        <span className="adjusted_po__sub_title_bold">{lastOfVin8}</span>
                            
                        </div>
                    </div>
                {/*< div className="adjusted_po__sub_title">
                    <FormattedMessage {...messages.adjust_reject_purchase_order_number}/>
                    {' '} { this.props.poNumber}
                </div>*/
                }
            </div>
            <div>
                {this.props.authorizationStatusReason}
            </div>
        </div>;
    }
}

/*Title.propTypes = {
    poNumber: React.PropTypes.string.isRequired,
    lineStatus: React.PropTypes.string.isRequired,
    authorizationStatusReason: React.PropTypes.string
};*/

export default Title;
