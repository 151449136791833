import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { isAuthenticated } from 'utils/HelperFunctions';

function composeDataToBePostedForEnrollmentDetails(enrollmentDetailsInfo,country) {    
    let ssn = enrollmentDetailsInfo.get('ssnArea') + enrollmentDetailsInfo.get('ssnGroup') + enrollmentDetailsInfo.get('ssnSerial');
    let employerId = enrollmentDetailsInfo.get('employerIdFirstPart') + enrollmentDetailsInfo.get('employerIdSecondPart');
    let businessNumber= enrollmentDetailsInfo.get('businessNumber');
    let formattedTaxId = helperFunctions.formatTaxId(ssn, employerId,businessNumber);    
    let enrollmentDetails=
     [{
         "op": "replace",
         "path": "id",
         "value": enrollmentDetailsInfo.get('id')
     }, {
         "op": "replace",
         "path": "clientReferralName",
         "value": enrollmentDetailsInfo.get('clientReferenceName')
     }, {
         "op": "replace",
         "path": "taxInformation/legalBusinessName",
         "value": enrollmentDetailsInfo.get('businessNameDba')
     }, {
         "op": "replace",
         "path": "taxInformation/corporationName",
         "value": enrollmentDetailsInfo.get('businessName')
     }, {
         "op": "replace",
         "path": "taxInformation/contactName",
         "value": enrollmentDetailsInfo.get('contactName')
     },  {
         "op": "replace",
         "path": "taxInformation/taxId",
         "value": formattedTaxId.taxId
     }, {
         "op": "replace",
         "path": "taxInformation/taxIdType",
         "value": formattedTaxId.taxType
     }, {
         "op": "replace",
         "path": "serviceClassification",
         "value": enrollmentDetailsInfo.get('serviceClassification')
     }, {
         "op": "replace",
         "path": "laborRateInformation/standardRate",
         "value":enrollmentDetailsInfo.get('standardRate')==''?0: helperFunctions.zeroIfUndefined(enrollmentDetailsInfo.get('ariStandardRate'))
     },{
         "op": "replace",
         "path": "laborRateInformation/bodyShopRate",
         "value":enrollmentDetailsInfo.get('bodyShopRate')==''?0: helperFunctions.zeroIfUndefined(enrollmentDetailsInfo.get('bodyShopRate'))
     },{
         "op": "replace",
         "path": "laborRateInformation/mobileRate",
         "value":enrollmentDetailsInfo.get('mobileRate')==''?0: helperFunctions.zeroIfUndefined(enrollmentDetailsInfo.get('mobileRate'))
     },{
         "op": "replace",
         "path": "laborRateInformation/equipmentRate",
         "value":enrollmentDetailsInfo.get('equipmentRate')==''?0: helperFunctions.zeroIfUndefined(enrollmentDetailsInfo.get('equipmentRate'))
     },{
         "op": "replace",
         "path": "laborRateInformation/lightDutyRate",
         "value":enrollmentDetailsInfo.get('lightDutyRate')==''?0: helperFunctions.zeroIfUndefined(enrollmentDetailsInfo.get('lightDutyRate'))
     },{
         "op": "replace",
         "path": "laborRateInformation/mediumDutyRate",
         "value":enrollmentDetailsInfo.get('mediumDutyRate')==''?0: helperFunctions.zeroIfUndefined(enrollmentDetailsInfo.get('mediumDutyRate'))
     },{
         "op": "replace",
         "path": "laborRateInformation/heavyDutyRate",
         "value":enrollmentDetailsInfo.get('heavyDutyRate')==''?0: helperFunctions.zeroIfUndefined(enrollmentDetailsInfo.get('heavyDutyRate'))
     },{
         "op": "replace",
         "path": "laborRateInformation/trailerRate",
         "value":enrollmentDetailsInfo.get('trailerRate')==''?0: helperFunctions.zeroIfUndefined(enrollmentDetailsInfo.get('trailerRate'))
     }];
    
    if(country=='USA'){
        enrollmentDetails.push({
            "op": "replace",
            "path": "taxInformation/taxClassification",
            "value": enrollmentDetailsInfo.get('selectedTaxClassification')
        });
        enrollmentDetails.push({"op": "replace",
            "path": "taxInformation/llcTaxClassification",
            "value": enrollmentDetailsInfo.get('liabilityTaxClassification')
        });
    }   


    return enrollmentDetails;   
}

export function* submitVendorEnrollmentDetails(context) {
    try {        
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.SUBMIT_VENDOR_ENROLLMENT_DETAIL_REQUESTED });
        // Read data from app state
        const state = yield select();
        const token = helperFunctions.getToken(state);
        let country=state.appState.getIn(['uiData','vendorEnrollmentRequestView','enrollmentRequestInfo','country']);
        
        const enrollmentPatchData = composeDataToBePostedForEnrollmentDetails(state.appState.getIn(['uiData', 'vendorEnrollmentView', 'enrollmentDetailsInfo']),country);
        const response = yield call(Api.submitVendorEnrollment, enrollmentPatchData, token);
        const isDetailInfoSubmittedSuccessfully = response.referenceNumber != undefined && !isNaN(response.referenceNumber) && response.referenceNumber > 0;

        yield put({ type: constants.SUBMIT_VENDOR_ENROLLMENT_DETAIL_RESOLVED, response: response });
    }
    catch (e) {
        yield put({ type: constants.SUBMIT_VENDOR_ENROLLMENT_DETAIL_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    }
}