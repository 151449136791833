import React from 'react';
import classnames from 'classnames';
import './Spinner.css';

class Spinner extends React.Component {

    render() {

        return (
            <div className={classnames('spinner',
                {'spinner--centered': !this.props.manualPositioning},
                {'spinner--blue': this.props.spinnerType == 'blue'}, 
                {'spinner--dark-blue': this.props.spinnerType == 'dark-blue'}
                )}>
            </div>
        );
    }

}

export default Spinner;