var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { format, isValid, parse } from "date-fns";
import FocusTrap from "focus-trap-react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { DayPicker, useInput } from "react-day-picker";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { enUS } from "date-fns/locale";
const PopperDiv = styled.div `
background-color: white;
z-index: 999;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
`;
const InputCalendarTS = forwardRef((props, ref) => {
    const [_locale, setLocale] = useState(enUS);
    //Initialize props for Calendar and associated input
    const { inputProps, dayPickerProps, setSelected } = useInput({
        format: 'P',
        defaultSelected: isValid(props.initialValue) ? props.initialValue : undefined
    });
    //Date input display value
    const [inputValue, setInputValue] = useState(isValid(props.initialValue) ? format(props.initialValue, 'P', { locale: _locale }) : '');
    //Input ref for popper
    const popperRef = useRef(null);
    //Element for popper to display
    const [popperElement, setPopperElement] = useState(null);
    //State controlling popper visibility
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    //Create popper
    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'top-start'
    });
    useEffect(() => {
        var locale = props.locale;
        if (locale == 'es-MX') {
            locale = 'es';
        }
        const importLocale = () => __awaiter(void 0, void 0, void 0, function* () {
            const localeToSet = yield import('date-fns/locale/' + locale + '/index.js');
            setLocale(localeToSet);
        });
        if (_locale.code !== locale) {
            importLocale();
        }
    }, [props.locale]);
    const onFocus = () => {
        setIsPopperOpen(true);
    };
    const onBlur = () => {
        setIsPopperOpen(false);
    };
    //Handle Calendar combo box input change
    const handleInputChange = (e) => {
        var _a, _b;
        let before = (_a = props.modifiers.disabled) === null || _a === void 0 ? void 0 : _a.before;
        let after = (_b = props.modifiers.disabled) === null || _b === void 0 ? void 0 : _b.after;
        setInputValue(e.currentTarget.value);
        const date = parse(e.currentTarget.value, 'P', new Date());
        if (isValid(date) && (before == undefined || date >= before) && (after == undefined || date <= after)) {
            setSelected(date);
            props.onDateChange(date);
        }
        else {
            setSelected(undefined);
            props.onDateChange(undefined);
        }
    };
    const handleDaySelect = (day) => {
        setSelected(day);
        setInputValue(format(day, 'P', { locale: _locale }));
        props.onDateChange(day);
        onBlur();
    };
    return (React.createElement("div", { id: props.id },
        React.createElement("div", { ref: popperRef },
            React.createElement("input", Object.assign({}, inputProps, props.inputProps, { onFocus: onFocus, onChange: (e) => {
                    handleInputChange(e);
                }, value: inputValue, placeholder: "MM/DD/YYYY", ref: ref, id: props.id + "_input" }))),
        isPopperOpen ?
            //Focus trap to handle focus
            React.createElement(FocusTrap, { active: true, focusTrapOptions: {
                    initialFocus: false,
                    allowOutsideClick: true,
                    clickOutsideDeactivates: true,
                    onDeactivate: onBlur,
                    returnFocusOnDeactivate: false
                } },
                React.createElement(PopperDiv, Object.assign({ tabIndex: -1, style: popper.styles.popper, className: 'input_popper_calendar' }, popper.attributes.popper, { ref: setPopperElement, role: "dialog" }),
                    React.createElement(DayPicker, Object.assign({}, dayPickerProps, { mode: 'single', onSelect: (day) => {
                            handleDaySelect(day);
                        }, fixedWeeks: true, modifiers: props.modifiers, locale: _locale }))))
            : null));
});
export default InputCalendarTS;
