import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { Popover, Overlay } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { Wheel } from './Wheel';
import './Axle.css';
import Submit from 'components/Submit';

export const Axle = (props) => {

    //get the left inner wheel to render from the children
    const leftInnerWheel = (props) => React.Children.map(props.children, child => {
        if (child != null && child.props.type === constants.TIRE_POSITIONS.LEFT_INNER)
            return child
    })

    //get the left outer wheel to render from the children
    const leftOuterWheel = (props) => React.Children.map(props.children, child => {
        if (child != null && child.props.type === constants.TIRE_POSITIONS.LEFT_OUTER)
            return child
    })

    //get the right inner wheel to render from the children
    const rightInnerWheel = (props) => React.Children.map(props.children, child => {
        if (child != null && child.props.type === constants.TIRE_POSITIONS.RIGHT_INNER)
            return child
    })

    //get the right outer wheel to render from the children
    const rightOuterWheel = (props) => React.Children.map(props.children, child => {
        if (child != null && child.props.type === constants.TIRE_POSITIONS.RIGHT_OUTER)
            return child
    })

    const messages = defineMessages({
        add_wheels: {
            id: 'add_wheels',
            description: 'add_wheels',
            defaultMessage: 'Add Wheels'
        },
        remove_wheels: {
            id: 'remove_wheels',
            description: 'remove_wheels',
            defaultMessage: 'Remove Wheels'
        },
        front_axle: {
            id: 'front_axle',
            description: 'front_axle',
            defaultMessage: 'Front Axle'
        },
    });

    const frontAxle =
        <div className={classnames('front-axle')} >
            <div className='front-axle-image'>
                <div className='front-axle__image-caption'><FormattedMessage {...messages.front_axle} /></div>
                <img src="/assets/VMS_front_axle_2.png" /></div>
            <div className=''></div>
        </div>;

    const rearAxle =
        <div className={classnames('front-axle__col', 'rear-axle')} >
            <img src="/assets/VMS_rear_axle_2.png" />
        </div>


    return (
        <div className='front-axle-container'>
            <div className='front-axle-flex'>
                <div className='left-outer-wheel'>
                    {leftOuterWheel(props)}
                </div>
                <div className='left-inner-wheel'>
                    {leftInnerWheel(props)}
                </div>
                <div className={classnames('front-axle-filler')} >
                    {props.position == 1 ? frontAxle : rearAxle}
                </div>
                <div className='right-inner-wheel'>
                    {rightInnerWheel(props)}
                </div>
                <div className='right-outer-wheel'>
                    {rightOuterWheel(props)}
                </div>
            </div>

            {!props.readOnly ?
                <div className={'add-remove-wheel'}>
                    {
                        props.canAddWheel ?
                            <div className=''>
                                <Submit
                                    caption={<FormattedMessage {...messages.add_wheels} />}
                                    onClickHandler={() => props.onAddWheel(props.position)}
                                    imagePath="/assets/VMS_33button_add_blue.png"
                                    imageHeight={'25px'}
                                    imageWidth={'auto'}
                                />
                            </div>
                            : false
                    }
                    {
                        props.canRemoveWheel ?
                            <div className=''>
                                <Submit
                                    caption={<FormattedMessage {...messages.remove_wheels} />}
                                    onClickHandler={() => props.onRemoveWheel(props.position)}
                                    imagePath="/assets/VMS_33button_remove_blue.png"
                                    imageHeight={'25px'}
                                    imageWidth={'auto'}
                                />
                            </div>
                            : false
                    }
                </div>
                : false
            }


        </div>
    )
};



export const FrontAxle = (props) => {
    return (
        <div>
            <Axle position={1}
                onAddWheel={props.onAddWheel}
                canAddWheel={false}
                onRemoveWheel={props.onRemoveWheel}
                canRemoveWheel={false}
                readOnly={props.readOnly}

            >
                {!props.leftOuterWheel ? false
                    : <Wheel axle={1}
                        type={props.leftOuterWheel.type}
                        tireSelected={props.leftOuterWheel.tireSelected}
                        tempId={props.leftOuterWheel.tempId}
                        newTreadDepth={props.leftOuterWheel.newTreadDepth}
                        oldTreadDepth={props.leftOuterWheel.oldTreadDepth}
                        canSelectTire={props.leftOuterWheel.canSelectTire}
                        onChangeTreadDepth={props.onChangeTreadDepth}
                        onSelectTire={props.onSelectTire}
                        defaultMeasurementUnit={props.defaultMeasurementUnit}
                        showTireValidationError={props.showTireValidationError}
                        readOnly={props.readOnly}
                    />}
                {!props.leftInnerWheel ? false
                    : <Wheel axle={1}
                        type={props.leftInnerWheel.type}
                        tireSelected={props.leftInnerWheel.tireSelected}
                        tempId={props.leftInnerWheel.tempId}
                        newTreadDepth={props.leftInnerWheel.newTreadDepth}
                        oldTreadDepth={props.leftInnerWheel.oldTreadDepth}
                        canSelectTire={props.leftInnerWheel.canSelectTire}
                        onChangeTreadDepth={props.onChangeTreadDepth}
                        onSelectTire={props.onSelectTire}
                        defaultMeasurementUnit={props.defaultMeasurementUnit}
                        showTireValidationError={props.showTireValidationError}
                        readOnly={props.readOnly}
                    />}
                {!props.rightInnerWheel ? false
                    : <Wheel axle={1}
                        type={props.rightInnerWheel.type}
                        tireSelected={props.rightInnerWheel.tireSelected}
                        newTreadDepth={props.rightInnerWheel.newTreadDepth}
                        oldTreadDepth={props.rightInnerWheel.oldTreadDepth}
                        canSelectTire={props.rightInnerWheel.canSelectTire}
                        tempId={props.rightInnerWheel.tempId}
                        onChangeTreadDepth={props.onChangeTreadDepth}
                        onSelectTire={props.onSelectTire}

                        showTireValidationError={props.showTireValidationError}

                        defaultMeasurementUnit={props.defaultMeasurementUnit} 
                        readOnly={props.readOnly}
                        />}
                {!props.rightOuterWheel ? false
                    : <Wheel axle={1}
                        type={props.rightOuterWheel.type}
                        tireSelected={props.rightOuterWheel.tireSelected}
                        tempId={props.rightOuterWheel.tempId}
                        newTreadDepth={props.rightOuterWheel.newTreadDepth}
                        oldTreadDepth={props.rightOuterWheel.oldTreadDepth}
                        canSelectTire={props.rightOuterWheel.canSelectTire}
                        onChangeTreadDepth={props.onChangeTreadDepth}
                        onSelectTire={props.onSelectTire}
                        defaultMeasurementUnit={props.defaultMeasurementUnit}
                        showTireValidationError={props.showTireValidationError}
                        readOnly={props.readOnly}
                    />}
            </Axle>
        </div >
    )
}




export const RearAxle = (props) => {
    const commonProps = {
        onSelectTire: props.onSelectTire,
        onChangeTreadDepth: props.onChangeTreadDepth,
        defaultMeasurementUnit: props.defaultMeasurementUnit,
        axle: props.position,
        showTireValidationError: props.showTireValidationError,
        readOnly:props.readOnly
    }
    return (
        <div>
            <Axle position={props.position}
                onAddWheel={props.onAddWheel}
                canAddWheel={props.canAddWheel}
                onRemoveWheel={props.onRemoveWheel}
                canRemoveWheel={props.canRemoveWheel}
                readOnly={props.readOnly}

            >
                {!props.leftOuterWheel ? false
                    : <Wheel
                        type={props.leftOuterWheel.type}
                        tireSelected={props.leftOuterWheel.tireSelected}
                        tempId={props.leftOuterWheel.tempId}
                        newTreadDepth={props.leftOuterWheel.newTreadDepth}
                        oldTreadDepth={props.leftOuterWheel.oldTreadDepth}
                        canSelectTire={props.leftOuterWheel.canSelectTire}
                        {...commonProps}
                    />}
                {!props.leftInnerWheel ? false
                    : <Wheel
                        type={props.leftInnerWheel.type}
                        tireSelected={props.leftInnerWheel.tireSelected}
                        tempId={props.leftInnerWheel.tempId}
                        newTreadDepth={props.leftInnerWheel.newTreadDepth}
                        oldTreadDepth={props.leftInnerWheel.oldTreadDepth}
                        canSelectTire={props.leftInnerWheel.canSelectTire}

                        {...commonProps}

                    />}
                {!props.rightInnerWheel ? false
                    : <Wheel
                        type={props.rightInnerWheel.type}
                        tireSelected={props.rightInnerWheel.tireSelected}
                        tempId={props.rightInnerWheel.tempId}
                        newTreadDepth={props.rightInnerWheel.newTreadDepth}
                        oldTreadDepth={props.rightInnerWheel.oldTreadDepth}
                        canSelectTire={props.rightInnerWheel.canSelectTire}

                        {...commonProps}

                    />}
                {!props.rightOuterWheel ? false
                    : <Wheel
                        type={props.rightOuterWheel.type}
                        tireSelected={props.rightOuterWheel.tireSelected}
                        tempId={props.rightOuterWheel.tempId}
                        newTreadDepth={props.rightOuterWheel.newTreadDepth}
                        oldTreadDepth={props.rightOuterWheel.oldTreadDepth}
                        canSelectTire={props.rightOuterWheel.canSelectTire}

                        {...commonProps}
                    />}
            </Axle>
        </div >
    )
}


