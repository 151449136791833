const bitInspectionComponentData = {
	truck: {
		51: {
			id: 51,     
			componentResultCode: null,
			noteText: null
		 },
		 52: {
			id: 52,     
			componentResultCode: null,
			noteText: null
		 },
		 53: {
			id: 53,     
			componentResultCode: null,
			noteText: null
		 },
		 54: {
			id: 54,     
			componentResultCode: null,
			noteText: null
		 },
		 55: {
			id: 55,     
			componentResultCode: null,
			noteText: null
		 },
		 56: {
			id: 56,     
			componentResultCode: null,
			noteText: null
		 },
		 57: {
			id: 57,     
			componentResultCode: null,
			noteText: null
		 },
		 58: {
			id: 58,     
			componentResultCode: null,
			noteText: null
		 },
		 59: {
			id: 59,     
			componentResultCode: null,
			noteText: null
		 },
		 60: {
			id: 60,     
			componentResultCode: null,
			noteText: null
		 },
		 61: {
			id: 61,     
			componentResultCode: null,
			noteText: null
		 },
		 62: {
			id: 62,     
			componentResultCode: null,
			noteText: null
		 },
		 63: {
			id: 63,     
			componentResultCode: null,
			noteText: null
		 },
		 64: {
			id: 64,     
			componentResultCode: null,
			noteText: null
		 },
		 65: {
			id: 65,     
			componentResultCode: null,
			noteText: null
		 },
		 66: {
			id: 66,     
			componentResultCode: null,
			noteText: null
		 },
		 67: {
			id: 67,     
			componentResultCode: null,
			noteText: null
		 },
		 68: {
			id: 68,     
			componentResultCode: null,
			noteText: null
		 },
		 69: {
			id: 69,     
			componentResultCode: null,
			noteText: null
		 },
		 70: {
			id: 70,     
			componentResultCode: null,
			noteText: null
		 },
		 71: {
			id: 71,     
			componentResultCode: null,
			noteText: null
		 },
		 72: {
			id: 72,     
			componentResultCode: null,
			noteText: null
		 },
		 73: {
			id: 73,     
			componentResultCode: null,
			noteText: null
		 },
		 74: {
			id: 74,     
			componentResultCode: null,
			noteText: null
		 },
		 75: {
			id: 75,     
			componentResultCode: null,
			noteText: null
		 },
		 76: {
			id: 76,     
			componentResultCode: null,
			noteText: null
		 },
		 77: {
			id: 77,     
			componentResultCode: null,
			noteText: null
		 },
		 78: {
			id: 78,     
			componentResultCode: null,
			noteText: null
		 },
		 79: {
			id: 79,     
			componentResultCode: null,
			noteText: null
		 },
		 80: {
			id: 80,     
			componentResultCode: null,
			noteText: null
		 },
		 81: {
			id: 81,     
			componentResultCode: null,
			noteText: null
		 },
		 82: {
			id: 82,     
			componentResultCode: null,
			noteText: null
		 },
		 83: {
			id: 83,     
			componentResultCode: null,
			noteText: null
		 },
		 84: {
			id: 84,     
			componentResultCode: null,
			noteText: null
		 },
		 85: {
			id: 85,     
			componentResultCode: null,
			noteText: null
		 },
		 86: {
			id: 86,     
			componentResultCode: null,
			noteText: null
		 },
		 87: {
			id: 87,     
			componentResultCode: null,
			noteText: null
		 },
		 88: {
			id: 88,     
			componentResultCode: null,
			noteText: null
		 },
		 89: {
			id: 89,     
			componentResultCode: null,
			noteText: null
		 },
		 90: {
			id: 90,     
			componentResultCode: null,
			noteText: null
		 },
		 91: {
			id: 91,     
			componentResultCode: null,
			noteText: null
		 },	
		 					  
	},

	trailer: {
		100: {
			id: 100,     
			componentResultCode: null,
			noteText: null
		 },
		 101: {
			id: 101,     
			componentResultCode: null,
			noteText: null
		 },
		 102: {
			id: 102,     
			componentResultCode: null,
			noteText: null
		 },
		 103: {
			id: 103,     
			componentResultCode: null,
			noteText: null
		 },
		 104: {
			id: 104,     
			componentResultCode: null,
			noteText: null
		 },
		 105: {
			id: 105,     
			componentResultCode: null,
			noteText: null
		 },
		 106: {
			id: 106,     
			componentResultCode: null,
			noteText: null
		 },
		 107: {
			id: 107,     
			componentResultCode: null,
			noteText: null
		 },
		 108: {
			id: 108,     
			componentResultCode: null,
			noteText: null
		 },
		 109: {
			id: 109,     
			componentResultCode: null,
			noteText: null
		 },
		 110: {
			id: 110,     
			componentResultCode: null,
			noteText: null
		 },
		 111: {
			id: 111,     
			componentResultCode: null,
			noteText: null
		 },
		 112: {
			id: 112,     
			componentResultCode: null,
			noteText: null
		 },
		 113: {
			id: 113,     
			componentResultCode: null,
			noteText: null
		 },
		 114: {
			id: 114,     
			componentResultCode: null,
			noteText: null
		 },
		 115: {
			id: 115,     
			componentResultCode: null,
			noteText: null
		 },
		 116: {
			id: 116,     
			componentResultCode: null,
			noteText: null
		 },
		 117: {
			id: 117,     
			componentResultCode: null,
			noteText: null
		 },
		 118: {
			id: 118,     
			componentResultCode: null,
			noteText: null
		 },
		 119: {
			id: 119,     
			componentResultCode: null,
			noteText: null
		 },
		 120: {
			id: 120,     
			componentResultCode: null,
			noteText: null
		 },
		 121: {
			id: 121,     
			componentResultCode: null,
			noteText: null
		 },
	},

	bus: {
		150: {
			id: 150,     
			componentResultCode: null,
			noteText: null
		 },
		 151: {
			id: 151,     
			componentResultCode: null,
			noteText: null
		 },
		 152: {
			id: 152,     
			componentResultCode: null,
			noteText: null
		 },
		 153: {
			id: 153,     
			componentResultCode: null,
			noteText: null
		 },
		 154: {
			id: 154,     
			componentResultCode: null,
			noteText: null
		 },
		 155: {
			id: 155,     
			componentResultCode: null,
			noteText: null
		 },
		 156: {
			id: 156,     
			componentResultCode: null,
			noteText: null
		 },
		 157: {
			id: 157,     
			componentResultCode: null,
			noteText: null
		 },
		 158: {
			id: 158,     
			componentResultCode: null,
			noteText: null
		 },
		 159: {
			id: 159,     
			componentResultCode: null,
			noteText: null
		 },
		 160: {
			id: 160,     
			componentResultCode: null,
			noteText: null
		 },
		 161: {
			id: 161,     
			componentResultCode: null,
			noteText: null
		 },
		 162: {
			id: 162,     
			componentResultCode: null,
			noteText: null
		 },
		 163: {
			id: 163,     
			componentResultCode: null,
			noteText: null
		 },
		 164: {
			id: 164,     
			componentResultCode: null,
			noteText: null
		 },
		 165: {
			id: 165,     
			componentResultCode: null,
			noteText: null
		 },
		 166: {
			id: 166,     
			componentResultCode: null,
			noteText: null
		 },
		 167: {
			id: 167,     
			componentResultCode: null,
			noteText: null
		 },
		 168: {
			id: 168,     
			componentResultCode: null,
			noteText: null
		 },
		 169: {
			id: 169,     
			componentResultCode: null,
			noteText: null
		 },
		 170: {
			id: 170,     
			componentResultCode: null,
			noteText: null
		 },
		 171: {
			id: 171,     
			componentResultCode: null,
			noteText: null
		 },
		 172: {
			id: 172,     
			componentResultCode: null,
			noteText: null
		 },
		 173: {
			id: 173,     
			componentResultCode: null,
			noteText: null
		 },
		 174: {
			id: 174,     
			componentResultCode: null,
			noteText: null
		 },
		 175: {
			id: 175,     
			componentResultCode: null,
			noteText: null
		 },
		 176: {
			id: 176,     
			componentResultCode: null,
			noteText: null
		 },
		 177: {
			id: 177,     
			componentResultCode: null,
			noteText: null
		 },
		 178: {
			id: 178,     
			componentResultCode: null,
			noteText: null
		 },
		 179: {
			id: 179,     
			componentResultCode: null,
			noteText: null
		 },
		 180: {
			id: 180,     
			componentResultCode: null,
			noteText: null
		 },
		 181: {
			id: 181,     
			componentResultCode: null,
			noteText: null
		 },
		 182: {
			id: 182,     
			componentResultCode: null,
			noteText: null
		 },
		 183: {
			id: 183,     
			componentResultCode: null,
			noteText: null
		 },
		 184: {
			id: 184,     
			componentResultCode: null,
			noteText: null
		 },
		 185: {
			id: 185,     
			componentResultCode: null,
			noteText: null
		 },
		 186: {
			id: 186,     
			componentResultCode: null,
			noteText: null
		 },
		 187: {
			id: 187,     
			componentResultCode: null,
			noteText: null
		 },
		 188: {
			id: 188,     
			componentResultCode: null,
			noteText: null
		 },
		 189: {
			id: 189,     
			componentResultCode: null,
			noteText: null
		 },
		 190: {
			id: 190,     
			componentResultCode: null,
			noteText: null
		 }
	}
							
};

export default bitInspectionComponentData;
