import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map, fromJS} from 'immutable';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';

export function uiDataDriverValidationComplaints(state = initialAppState.getIn(['uiData', 'driverValidationComplaints']), action) {
    switch (action.type) {                
        default:
            return state;
    }
}

export function serverDataDriverValidationComplaints(state = initialAppState.getIn(['serverData', 'driverValidationComplaints']), action) {
    switch (action.type) {
        case constants.FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_REQUESTED:             
            return state
                .set('isFetchingData', true);
        case constants.FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_RESOLVED:            
            return state
                .set('isFetchingData', true)
                .set('availableVehicleComplaints', action.availableVehicleComplaints);
        case constants.FETCH_DRIVER_VALIDATION_COMPLAINTS_DATA_REJECTED:
            return state
                .set('isFetchingData', false)
                .set('errorMessage', action.errorMessage);
                
        case constants.DRIVER_VALIDATION_COMPLAINT_SELECTED:
            return state
                .setIn(['availableVehicleComplaints', action.vehicleComplaintId.toString(), 'isSelected'], true);                
        case constants.DRIVER_VALIDATION_COMPLAINT_UNSELECTED:            
            return state
                .setIn(['availableVehicleComplaints', action.vehicleComplaintId.toString(), 'isSelected'], false);        

        case constants.DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_REQUESTED:             
            return state
                .set('isFetchingData', true);
        case constants.DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_RESOLVED:            
            return state
                .set('isFetchingData', true);
        case constants.DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP_REJECTED:
            return state
                .set('isFetchingData', false)
                .set('errorMessage', action.errorMessage);

        default:
            return state;
    }
}