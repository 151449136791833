import { call, put, select, take, cancel, fork,delay } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import * as sharedActions from '../../../../shared/actions/App';

// Shop View 
function* fetchShopViewData() {
  while (true) {
    const state = yield select();
    try {      

      //clear out any error messages
      yield put({ type: constants.CLEAR_ERROR_MESSAGE});
      // Inform application that data request has started
      yield put({ type: constants.SHOP_VIEW_DATA_REQUESTED });

      // Fetch shop view data
      const activePOs = yield call(Api.fetchActivePOsForVendor, helperFunctions.getVendorId(state), helperFunctions.getToken(state));

      // Refresh token  
      if (helperFunctions.shouldTokenBeRefreshed(state, configuration.sessionExpirationTimeInMinutes))
          yield put(sharedActions.refreshToken());

      // Save data in app state
      yield put({ type: constants.FETCH_SHOP_VIEW_DATA_RESOLVED, activePOs });
        //throw Error('Testing Backend Error Messages. Please ignore.');  

    } catch (e) {

      // Error handling
      yield put({ type: constants.FETCH_SHOP_VIEW_DATA_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
      
      yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage:  helperFunctions.getGenericErrorObject(e) });

      yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SHOP_VIEW_DATA_REQUESTED, errorObject: e });      
    }
    // Fetch data again after few mins
    yield delay(configuration.shopViewRefreshFrequencyInMinutes * 60 * 1000);
  }
}

export function* pollShopViewData() {

  // Start polling for data
  const task = yield fork(fetchShopViewData);

  // If user navigates away from Shop View, stop polling
  const action = yield take(constants.SHOP_VIEW_UNLOADED);
  yield cancel(task);
}

export function* receiveBulletinMessage(action) {
  try {

    // Read data from app state
    const state = yield select();
    const vendorId = state.appState.getIn(['serverData','shared','vendorId']);

    if(helperFunctions.isAuthenticated(state)) {

      const currentBulletinMessage = yield call(Api.getBulletinMessages,vendorId, helperFunctions.getToken(state),state);

      yield put({ type: constants.SAVE_BULLETIN_MESSAGE, currentBulletinMessage });
      yield put({ type: constants.SEND_BULLETIN_MESSAGE_RESOLVED });

    }else{

      const currentBulletinMessage = yield call(Api.getGlobalBulletinMessages);

      yield put({ type: constants.SAVE_BULLETIN_MESSAGE, currentBulletinMessage });
      yield put({ type: constants.SEND_BULLETIN_MESSAGE_RESOLVED });
    }


  }
  catch (e) {
    yield put({ type: constants.SEND_BULLETIN_MESSAGE_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SHOP_VIEW_DATA_REQUESTED, errorObject: e });
  }
}
