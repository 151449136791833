import {call, put, select} from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';


export function* receiveAtaParameters(action) {
    try {
        yield put({ type: constants.FETCH_DATA_FOR_ATA_PARAMETERS });

        // Read data from app state
        const state = yield select();

        const vehicleId = state.appState.getIn(['serverData','shared','vehicleDetails','id']);
        const complaintCode = state.appState.getIn(['uiData','addNewLineItemsView','selectedComplaintCode']);

        const currentParameters = yield call(Api.fetchAtaParameters,vehicleId,action.productCode,complaintCode, helperFunctions.getToken(state));

        yield put({ type: constants.SAVE_ATA_PARAMETERS, currentParameters });
        yield put({ type: constants.SEND_CLICK_ATA_PARAMETERS_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.SEND_CLICK_ATA_PARAMETERS_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SIGN_IN_DATA_REQUESTED, errorObject: e });
    }
}



//Get ata codes by search key
export function* fetchATACodesBySearchKey(action) {
    try {

        // Read data from app state
        const state = yield select();
        let searchKeyState = state.appState.getIn(['serverData', 'shared', 'ataCodesBySearchKeys', action.searchData.searchKey]);

        //Fetch results only if search key doesnt exist in the state
        if (searchKeyState == undefined || searchKeyState.size <= 0) {
            yield put({ type: constants.LOAD_SEARCHED_ATA_CODES_DATA_REQUESTED });

            let serviceResult = yield call(Api.getATACodesBySearchKey, action.searchData.vehicleId, action.searchData.searchKey, helperFunctions.getToken(state),helperFunctions.getSelectedLanguageCode(state));

            //prepare frequently used ata Code data
            const searchKey = action.searchData.searchKey;
            
            //save searched ata code keys to the state
            if (serviceResult != undefined && serviceResult.length > 0)
                yield put({ type: constants.ADD_ATA_CODES_BY_SEARCH_KEY, searchKey });
            
           //If Unmapped product codes are selected then we want to use correction restriction types from unmapped product code instead of the newly selected ata      
           if(action.searchData.correctionTypesAssociatedToVendorProductCode != undefined){
             
            for(var serviceKey in serviceResult){
                serviceResult[serviceKey].correctionAndRepairTypesRestricted=action.searchData.correctionTypesAssociatedToVendorProductCode;
            
            }
           
        }     
            yield put({ type: constants.LOAD_SEARCHED_ATA_CODES_DATA, serviceResult });
            yield put({ type: constants.LOAD_SEARCHED_ATA_CODES_DATA_RESOLVED });

        }
    }
    catch (e) {
        var customError=helperFunctions.getCustomErrorObject(e);
        var genericError=helperFunctions.getGenericErrorObject(e);
        if(customError!=undefined && customError.length>0)
            yield put({ type: constants.LOAD_SEARCHED_ATA_CODES_DATA_REJECTED, errorMessage: customError});
        else if (genericError!=undefined && genericError.length>0)
            yield put({ type: constants.LOAD_SEARCHED_ATA_CODES_DATA_REJECTED, errorMessage: genericError });

        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SIGN_IN_DATA_REQUESTED, errorObject: e });
    }
}
