import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';

function* offsetGeneratorForFetchLinkedVendor() {
    let startValue = 0;
    while (true) {
        yield startValue++;
    }
}




export function* fetchLinkedVendorsInformation(action, offsetGenerator) {
    try {
        const state = yield select();

        yield put({ type: constants.LINKED_VENDORS_REQUESTED });

        let token = helperFunctions.getToken(state);
        if (token) {
            const vendorSubVendor = yield call(Api.fetchLinkedVendorAccountInformation, action.vendorState, action.zip, offsetGenerator.next().value, 25, token, state);
            yield put({ type: constants.LINKED_VENDORS_RESOLVED, vendorSubVendor });
        }
    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: constants.LINKED_VENDORS_REJECTED,
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* linkedVendorScreenFlow(action) {

    yield put({ type: constants.CLEAR_LINKED_VENDORS_SEARCHED });
    let offsetGenerator = offsetGeneratorForFetchLinkedVendor();

    while (true) {
        let task = yield fork(fetchLinkedVendorsInformation, action, offsetGenerator);

        let nextAction = yield take([constants.FETCH_MORE_LINKED_VENDORS_CLICK, constants.LINKED_VENDORS_SCREEN_UNMOUNTED]);
        if (nextAction.type === constants.LINKED_VENDORS_SCREEN_UNMOUNTED) {
            yield cancel(task);
            break;
        }
    }
}

export function* fetchCorporateVendorInformation() {

    try {
        const state = yield select();
        const corporateVendorInformation = yield call(Api.fetchCorporateVendorContactInformation, helperFunctions.getToken(state), state);
        yield put({ type: "SAVE_CURRENT_CORPORATE_VENDOR_DETAILS", corporateVendorInformation });
    }
    catch (e) {

        if (e.code == 'API-045') {
            yield put({
                type: 'CORPORATE_VENDOR_INFORMATION_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
        }

        else
            yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });
    }
}

export function* forgotCorporateVendorPassword(context, action) {
    try {
        yield call(context.history.push,'/recoverPassword');
    }
    catch (e) {
        var customError = helperFunctions.getCustomErrorObject(e);
        var genericError = helperFunctions.getGenericErrorObject(e);
        var errorMesg = customError != undefined && customError.length > 0 ? customError[0].message : genericError != undefined && genericError.length > 0 ? genericError[0].message : undefined;
        yield put({ type: constants.SEND_RECOVER_PASSWORD_REJECTED, errorMessage: errorMesg });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SEND_RECOVER_PASSWORD_REQUESTED, errorObject: e });
    }
}