import React from 'react';
import { Map } from 'immutable';
import classnames from 'classnames';
import './CorrectionTypes.css';
import { defineMessages, FormattedMessage } from 'react-intl';

class CorrectionTypes extends React.Component {
    handleSelectCorrectionType(enabled, correctionType) {
        if (!enabled)
            return;
        this.props.onSelectCorrectionType(correctionType);
    }

    subTitleSelected(id){
        const messages = defineMessages({
            correction_parts_labor_subTitle: {
                id: 'correction_parts_labor_subTitle',
                description: '(PARTS AND/OR LABOR)',
                defaultMessage: '(PARTS AND/OR LABOR)'
            },
            correction_labor_subTitle: {
                id: 'correction_labor_subTitle',
                description: '(LABOR ONLY)',
                defaultMessage: '(LABOR ONLY)'
            },
            correction_pm_subTitle: {
                id: 'correction_pm_subTitle',
                description: '(MENU PRICED ITEMS)',
                defaultMessage: '(MENU PRICED ITEMS)'
            },
        });

        switch(id) {
            case 'correctionTypes_REPLACE':
                return <FormattedMessage {...messages.correction_parts_labor_subTitle}/>;
            case 'correctionTypes_REPAIR':
                return <FormattedMessage {...messages.correction_labor_subTitle}/>;
            case 'correctionTypes_ADJUST':
                return <FormattedMessage {...messages.correction_labor_subTitle}/>;
            case 'correctionTypes_PM':
                return <FormattedMessage {...messages.correction_pm_subTitle}/>;
        }
    }

    getCorrectionTypes() {

        return this.props.correctionTypes.valueSeq().map((item)=>
            <div key={item.get('id')}
                 role="button"
                 className={classnames('correction-types__type',
                     item.get('isSelected') ? 'correction-types__type-selected' : undefined,
                     !item.get('enabled') ? 'correction-types__type-disabled' : undefined)}
                 onClick={this.handleSelectCorrectionType.bind(this, item.get('enabled'), item.get('correctionType'))}
            ><FormattedMessage
                id={item.get('id')}
                description={item.get('correctionType')}
                defaultMessage={item.get('correctionType')}/>
                <div className={'correction-types__type-sub'}> {this.subTitleSelected(item.get('id'))} </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.getCorrectionTypes()}
            </div>
        );
    }
}


export default CorrectionTypes;
