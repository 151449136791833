const craneInspectionComponentData = {
	300: {
		id: 300,
		componentResultCode: null,
		componentFailureReasons: null
	},
	301: {
		id: 301,
		componentResultCode: null,
		componentFailureReasons: null
	},
	302: {
		id: 302,
		componentResultCode: null,
		componentFailureReasons: null
	},
	303: {
		id: 303,
		componentResultCode: null,
		componentFailureReasons: null
	},
	304: {
		id: 304,
		componentResultCode: null,
		componentFailureReasons: null
	},
	305: {
		id: 305,
		componentResultCode: null,
		componentFailureReasons: null
	},
	306: {
		id: 306,
		componentResultCode: null,
		componentFailureReasons: null
	},
	307: {
		id: 307,
		componentResultCode: null,
		componentFailureReasons: null
	},
	308: {
		id: 308,
		componentResultCode: null,
		componentFailureReasons: null
	},
	309: {
		id: 309,
		componentResultCode: null,
		componentFailureReasons: null
	},
	310: {
		id: 310,
		componentResultCode: null,
		componentFailureReasons: null
	},
	311: {
		id: 311,
		componentResultCode: null,
		componentFailureReasons: null
	},
	312: {
		id: 312,
		componentResultCode: null,
		componentFailureReasons: null
	},
	313: {
		id: 313,
		componentResultCode: null,
		componentFailureReasons: null
	},
	314: {
		id: 314,
		componentResultCode: null,
		componentFailureReasons: null
	},
	315: {
		id: 315,
		componentResultCode: null,
		componentFailureReasons: null
	},
	316: {
		id: 316,
		componentResultCode: null,
		componentFailureReasons: null
	},
	317: {
		id: 317,
		componentResultCode: null,
		componentFailureReasons: null
	},
	318: {
		id: 318,
		componentResultCode: null,
		componentFailureReasons: null
	},
	319: {
		id: 319,
		componentResultCode: null,
		componentFailureReasons: null
	},
	320: {
		id: 320,
		componentResultCode: null,
		componentFailureReasons: null
	},
	321: {
		id: 321,
		componentResultCode: null,
		componentFailureReasons: null
	},
	322: {
		id: 322,
		componentResultCode: null,
		componentFailureReasons: null
	},
	323: {
		id: 323,
		componentResultCode: null,
		componentFailureReasons: null
	},
	324: {
		id: 324,
		componentResultCode: null,
		componentFailureReasons: null
	},
	325: {
		id: 325,
		componentResultCode: null,
		componentFailureReasons: null
	},
	326: {
		id: 326,
		componentResultCode: null,
		componentFailureReasons: null
	},
	327: {
		id: 327,
		componentResultCode: null,
		componentFailureReasons: null
	},
	328: {
		id: 328,
		componentResultCode: null,
		componentFailureReasons: null
	},
	329: {
		id: 329,
		componentResultCode: null,
		componentFailureReasons: null
	},
	330: {
		id: 330,
		componentResultCode: null,
		componentFailureReasons: null
	},
	331: {
		id: 331,
		componentResultCode: null,
		componentFailureReasons: null
	},
	332: {
		id: 332,
		componentResultCode: null,
		componentFailureReasons: null
	},
	333: {
		id: 333,
		componentResultCode: null,
		componentFailureReasons: null
	},
	334: {
		id: 334,
		componentResultCode: null,
		componentFailureReasons: null
	},
	335: {
		id: 335,
		componentResultCode: null,
		componentFailureReasons: null
	},
	336: {
		id: 336,
		componentResultCode: null,
		componentFailureReasons: null
	},
	337: {
		id: 337,
		componentResultCode: null,
		componentFailureReasons: null
	},
	338: {
		id: 338,
		componentResultCode: null,
		componentFailureReasons: null
	},
	339: {
		id: 339,
		componentResultCode: null,
		componentFailureReasons: null
	},
	340: {
		id: 340,
		componentResultCode: null,
		componentFailureReasons: null
	},
	341: {
		id: 341,
		componentResultCode: null,
		componentFailureReasons: null
	},
	342: {
		id: 342,
		componentResultCode: null,
		componentFailureReasons: null
	},
	343: {
		id: 343,
		componentResultCode: null,
		componentFailureReasons: null
	},
	344: {
		id: 344,
		componentResultCode: null,
		componentFailureReasons: null
	},
	345: {
		id: 345,
		componentResultCode: null,
		componentFailureReasons: null
	},
	346: {
		id: 346,
		componentResultCode: null,
		componentFailureReasons: null
	},
	347: {
		id: 347,
		componentResultCode: null,
		componentFailureReasons: null
	},
	348: {
		id: 348,
		componentResultCode: null,
		componentFailureReasons: null
	},
	349: {
		id: 349,
		componentResultCode: null,
		componentFailureReasons: null
	},
	350: {
		id: 350,
		componentResultCode: null,
		componentFailureReasons: null
	},
	351: {
		id: 351,
		componentResultCode: null,
		componentFailureReasons: null
	},
	352: {
		id: 352,
		componentResultCode: null,
		componentFailureReasons: null
	},
	353: {
		id: 353,
		componentResultCode: null,
		componentFailureReasons: null
	},
	354: {
		id: 354,
		componentResultCode: null,
		componentFailureReasons: null
	},
	355: {
		id: 355,
		componentResultCode: null,
		componentFailureReasons: null
	},
	356: {
		id: 356,
		componentResultCode: null,
		componentFailureReasons: null
	},
	357: {
		id: 357,
		componentResultCode: null,
		componentFailureReasons: null
	},
	358: {
		id: 358,
		componentResultCode: null,
		componentFailureReasons: null
	},
	359: {
		id: 359,
		componentResultCode: null,
		componentFailureReasons: null
	},
	360: {
		id: 360,
		componentResultCode: null,
		componentFailureReasons: null
	},
	361: {
		id: 361,
		componentResultCode: null,
		componentFailureReasons: null
	},
	362: {
		id: 362,
		componentResultCode: null,
		componentFailureReasons: null
	},
	363: {
		id: 363,
		componentResultCode: null,
		componentFailureReasons: null
	},
	364: {
		id: 364,
		componentResultCode: null,
		componentFailureReasons: null
	},
	365: {
		id: 365,
		componentResultCode: null,
		componentFailureReasons: null
	},
	366: {
		id: 366,
		componentResultCode: null,
		componentFailureReasons: null
	},
	367: {
		id: 367,
		componentResultCode: null,
		componentFailureReasons: null
	},
	368: {
		id: 368,
		componentResultCode: null,
		componentFailureReasons: null
	},
	369: {
		id: 369,
		componentResultCode: null,
		componentFailureReasons: null
	},
	370: {
		id: 370,
		componentResultCode: null,
		componentFailureReasons: null
	},
	371: {
		id: 371,
		componentResultCode: null,
		componentFailureReasons: null
	}
};

export default craneInspectionComponentData;