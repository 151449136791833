import React from 'react';
import ReactDoM from 'react-dom';
import * as constants from 'constants/App';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import Submit from 'components/Submit';
import './AccountInfo.css';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';
import TranslationIcon from 'components/SiteTranslations';
import PasswordPolicy from 'shared/components/PasswordPolicy';

class AccountInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsFocusedAfterValidation: [],   //array of fields that have recieved focus event
        }
    }
    //Adds the field to the list of focused fields
    onFieldFocus(fieldName) {
        let updatedFocusFields = this.state.fieldsFocusedAfterValidation.concat(fieldName);
        this.setState({ fieldsFocusedAfterValidation: updatedFocusFields });

        this.props.removeValidationErr(fieldName);
    }

    tempShowError(fieldName) {
        let message = this.props.formDataValidationMessages.get(fieldName);
        let showError = message != undefined && !this.state.fieldsFocusedAfterValidation.includes(fieldName);
        return showError;
    }

    onSubmit(e) {
        this.setState({ fieldsFocusedAfterValidation: [] });
        this.props.onSubmitStep(1);     
    }
    onChange(inputName, event) {        
        this.props.onChangeRegistrationFieldValue(inputName, event);
    }

    onChangeSSN(number, type, inputName, event) {
        this.props.onChangeRegistrationFieldValue(inputName, event);
        if (event.target.value.length >= event.target.maxLength) {
            if (number == "1" || number == "2") {
                number += 1;
                document.getElementById((type + number).toString()).focus();
            }
        }
    }
    onChangeBN(inputName,event){        
        this.props.onChangeRegistrationFieldValue(inputName, event);
    }
    onCountryChange(event){                
        const country = event.currentTarget.attributes.value.value; 
        const ariId=this.props.formData.get(constants.FORM_DATA.ARI_ID);
        this.props.onCountryChange(country,ariId);        
    }

    onChangeEmployerId(number, type, inputName, event) {
        this.props.onChangeRegistrationFieldValue(inputName, event);
        if (number == "1" && event.target.value.length >= event.target.maxLength) {
            number += 1;
            document.getElementById((type + number).toString()).focus();
        }
    }

    renderValidationOverlay(field, fieldName, placement) {
        let message = this.props.formDataValidationMessages.get(fieldName);
        let showError = message != undefined && !this.state.fieldsFocusedAfterValidation.includes(fieldName);
        if (message != undefined)
            return <Overlay
        show={showError}
        target={() => field}
        placement={placement != undefined ? placement : "right"}
        >
                <Popover id={"popOver" + fieldName} className="enrollment-details__error_message step1__popover">
                <RawIntlProvider value={this.props.intl}>
                    <FormattedMessage {...message} />
                    </RawIntlProvider>
                </Popover>
            </Overlay>
                else
            return false;
                }
  
    render() {
                    const messages = defineMessages({
                        create_new_account: {
                            id: 'create_new_account',
                            description: 'Step 1 of 5: Create a New Account',
                            defaultMessage: 'Step 1 of 5: Create a New Account'
                        },
                        ari_id: {
                            id: 'ari_id',
                            description: 'ID Issued by Holman',
                            defaultMessage: 'ID Issued by Holman'
                        },
                        tax_id: {
                            id: 'tax_id',
                            description: 'Federal Tax ID',
                            defaultMessage: 'Federal Tax ID'
                        },
                        create_pwd: {
                            id: 'create_pwd',
                            description: 'Create Password',
                            defaultMessage: 'Create Password'
                        },
                        confirm_pwd: {
                            id: 'confirm_pwd',
                            description: 'Confirm Password',
                            defaultMessage: 'Confirm Password'
                        },
                        go_to_next_step: {
                            id: 'go_to_next_step',
                            description: 'Go to next step',
                            defaultMessage: 'Go to next step'
                        },
                        ssn: {
                            id: 'ssn',
                            description: 'Social Security no.',
                            defaultMessage: 'Social Security no.'
                        },
                        employer_id: {
                            id: 'employer_id',
                            description: 'Employer ID no.',
                            defaultMessage: 'Employer ID no.'
                        },
                        businessNumber:{
                            id:'businessNumber',
                            description:'Business Number',
                            defaultMessage:'Business Number'
                        },
                        mexTaxId:{
                            id:"mex_tax_id",
                            description: 'Federal Tax Id' ,
                            defaultMessage: 'Federal Tax Id'
                        }
                    })

                    return (
                        <div className='step1'>
                            <div className='step1__header'><FormattedMessage {...messages.create_new_account } /></div>
                            <div className='step1__create-account'>
                                <div className='step1__create-account-input-container'>
                                    {this.props.error}
                                    <div className='step1__input-group'>
                                        <label ><FormattedMessage {...messages.ari_id} /></label>
                                        <div className='step1__block'>
                                            <input type='text'
                    maxLength={'6'}
                    ref={input => this.ariId = input}
                    className='step1__input'
                    value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.ARI_ID))}                                    
                    onChange={this.onChange.bind(this, constants.FORM_DATA.ARI_ID)}
                    onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.ARI_ID)}
                    onKeyPress={helperFunctions.isNumeric.bind(this)} />
                {this.renderValidationOverlay(this.ariId, constants.FORM_DATA.ARI_ID, 'right')}
                    {'  '}
                    <img className={this.props.country=='USA'?'step1__country-flag-selected':'step1__country-flag'} src="/assets/VMS_US_Flag.jpg" title="United States"
                    value="USA" role="button" onClick={this.onCountryChange.bind(this)} />
                    {'  '}
                    <img className={this.props.country=='CAN'?'step1__country-flag-selected':'step1__country-flag'} src="/assets/VMS_CANADA_Flag.jpg" title="CANADA" 
                    value="CAN" role="button" onClick={this.onCountryChange.bind(this)}/>
                    {'  '}
                    <img className={this.props.country=='MEX'?'step1__country-flag-selected':'step1__country-flag'} src="/assets/VMS_MEXICO_Flag.jpg" title="MEXICO" 
                    value="MEX" role="button" onClick={this.onCountryChange.bind(this)}/>
                    <div className="step1_container form-inline">                                
                                <span className="step1_seperator">|</span>
                                <div className="step1_country">
                                    <TranslationIcon backgroundColor='blue' />
                                </div>
                            </div>
                </div>
            </div>

                    {this.props.country=='MEX'?
                    <div id='ftd' className='row' >
                        <div className='col-md-8 step1__input-group step1__ss'>
                            <label><FormattedMessage {...messages.mexTaxId } /></label>
                            <div className='step1__block '>
                                <input type="password" id="mexTaxId" maxLength="12" className='step1__input-12-chars'
                        ref={input => this.federalTaxId = input}
                        value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.FEDERAL_TAX_ID))}
                        onChange={this.onChangeBN.bind(this,constants.FORM_DATA.FEDERAL_TAX_ID)}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.FEDERAL_TAX_ID)}
                        onKeyPress={helperFunctions.isAlphaNumeric.bind(this)} />                                 
                </div>
            </div>
                        {this.renderValidationOverlay(this.federalTaxId, constants.FORM_DATA.FEDERAL_TAX_ID, 'right')}
                        </div>:
                        this.props.country=='CAN'?
                    <div id='bn' className='row' >
                        <div className='col-md-6 step1__input-group step1__ss'>
                            <label><FormattedMessage {...messages.businessNumber } /></label>
                            <div className='step1__block '>
                                <input type="password" id="businessNumber" maxLength="9" className='step1__input-9-chars'
                        ref={input => this.bn = input}
                        value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.BUSINESS_NUMBER))}
                        onChange={this.onChangeBN.bind(this,constants.FORM_DATA.BUSINESS_NUMBER)}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.BUSINESS_NUMBER)}
                        onKeyPress={helperFunctions.isNumeric.bind(this)} />                                 
                </div>
            </div>
                        {this.renderValidationOverlay(this.bn, constants.FORM_DATA.BUSINESS_NUMBER, 'right')}
                        </div>
                        :
                            <div id='ein' className='row' >
                                <div className='col-md-6 step1__input-group step1__ss'>
                                    <label><FormattedMessage {...messages.ssn } /></label>
                                    <div className='step1__block '>
                                        <input type="password" id="ssn1" maxLength="3" className='step1__input-3-chars'
                        ref={input => this.ssn1 = input}                                        
                        onChange={this.onChangeSSN.bind(this, 1, 'ssn', constants.FORM_DATA.SSN_AREA)}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.TAX_ID)}
                        value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.SSN_AREA))}
                        onKeyPress={helperFunctions.isNumeric.bind(this)} />-
                    <input type="password" id="ssn2" maxLength="2" className='step1__input-2-chars-ssn'
                        ref={input => this.ssn2 = input}
                        onChange={this.onChangeSSN.bind(this, 2, 'ssn', constants.FORM_DATA.SSN_GROUP)}
                        value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.SSN_GROUP))}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.TAX_ID)}
                        onKeyPress={helperFunctions.isNumeric.bind(this)} />-
                    <input type="password" id="ssn3" maxLength="4" className='step1__input-4-chars'
                        ref={input => this.ssn3 = input}
                        onChange={this.onChangeSSN.bind(this, 3, 'ssn', constants.FORM_DATA.SSN_SERIAL)}
                        value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.SSN_SERIAL))}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.TAX_ID)}
                        onKeyPress={helperFunctions.isNumeric.bind(this)} />
                </div>
            </div>
            <div className='col-md-1 step1__block step1__or'>OR</div>
            <div className='col-md-5 step1__block step1__input-group step1__ein'>
                <label><FormattedMessage {...messages.employer_id } /></label>
                <div className='step1__block '>
                    <input type="password" id="employerId1" maxLength="2" className=' step1__input-2-chars'
                        ref={input => this.ssn4 = input}
                        onChange={this.onChangeEmployerId.bind(this, 1, 'employerId',
                            constants.FORM_DATA.EMPLOYER_ID_FIRST_PART)}
                        value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.EMPLOYER_ID_FIRST_PART))}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.TAX_ID)}
                        onKeyPress={helperFunctions.isNumeric.bind(this)} />-
                    <input type="password" id="employerId2" maxLength="7" className='step1__input-7-chars'
                        ref={input => this.taxId = input}
                        onChange={this.onChangeEmployerId.bind(this, 2, 'employerId',
                            constants.FORM_DATA.EMPLOYER_ID_SECOND_PART)}
                        value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.EMPLOYER_ID_SECOND_PART))}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.TAX_ID)}
                        onKeyPress={helperFunctions.isNumeric.bind(this)}
                    />
                </div>
            </div>
                        {this.renderValidationOverlay(this.taxId, constants.FORM_DATA.TAX_ID, 'right')}
                        </div>
                        
                    }
                    <div className='step1__input-group'>
                        <label ><FormattedMessage {...messages.create_pwd } /></label>
                        <input type='password'
                    maxLength={'24'}
                    ref={input => this.password = input}
                    className='step1__input step1__block'
                    value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.PASSWORD))}
                    onChange={this.onChange.bind(this, constants.FORM_DATA.PASSWORD)}
                    onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.PASSWORD)} />
            </div>
                    {this.renderValidationOverlay(this.password, constants.FORM_DATA.PASSWORD, 'right')}

                    <div className='step1__input-group'>
                        <label ><FormattedMessage {...messages.confirm_pwd } /></label>
                        <input type='password'
                    maxLength={'24'}
                    ref={input => this.confirmPassword = input}
                    className='step1__input step1__block'
                    value={helperFunctions.emptyStringIfUndefined(this.props.formData.get(constants.FORM_DATA.CONFIRM_PASSWORD))}
                    onChange={this.onChange.bind(this, constants.FORM_DATA.CONFIRM_PASSWORD)}
                    onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.CONFIRM_PASSWORD)}
                />
                    {this.renderValidationOverlay(this.confirmPassword, constants.FORM_DATA.CONFIRM_PASSWORD, 'right')}
                    </div>
                <div>
                    <PasswordPolicy />
                </div>
            </div>                    
            <div className='step1__next-step'>
                        <Submit caption={<FormattedMessage {...messages.go_to_next_step }/>}
                            imagePath="/assets/VMS_33button_go_bblue.png"
                            onClickHandler={this.onSubmit.bind(this)}
    showSpinner={this.props.isSubmittingAccountInfo} />
            </div>
    </div>
</div>
);
    }
};
export default injectIntl(AccountInfo);