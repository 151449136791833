const fraInspectionComponentData = {	
	201: {
		id: 201,     
		componentResultCode: null
	},
	202: {
		id: 202,     
		componentResultCode: null
	},
	203: {
		id: 203,     
		componentResultCode: null
	},
	204: {
		id: 204,     
		componentResultCode: null
	},
	205: {
		id: 205,     
		componentResultCode: null
	},
	206: {
		id: 206,     
		componentResultCode: null
	},
	207: {
		id: 207,     
		componentResultCode: null
	},
	208: {
		id: 208,     
		componentResultCode: null
	},
	209: {
		id: 209,     
		componentResultCode: null
	},
	210: {
		id: 210,     
		componentResultCode: null
	},
	211: {
		id: 211,     
		componentResultCode: null
	},
	212: {
		id: 212,     
		componentResultCode: null
	},
	213: {
		id: 213,     
		componentResultCode: null
	},
	214: {
		id: 214,     
		componentResultCode: null
	},
	215: {
		id: 215,     
		componentResultCode: null
	},
	216: {
		id: 216,     
		componentResultCode: null
	}								
};

export default fraInspectionComponentData;