import { createSelector } from 'reselect';
import { emptyMapIfUndefined, falseIfUndefined } from 'utils/HelperFunctions';
import { Map, fromJS } from 'immutable';
import * as constants from 'constants/App';
import {LINE_ITEM_STATUS, LINE_ITEM_TYPE} from 'constants/App';

const vendorCheckingAccount =
    state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'checkingInitial']));

const productDetails = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'productDetails']));

const vendorCheckingAccountInitialFilter = createSelector(vendorCheckingAccount, (account) => {

    return account.map(e => e).filter((visible) => {

        return visible.get('type') === "MAINTENANCE";

    });


});


const vendorCheckingAccountInitial = createSelector(vendorCheckingAccountInitialFilter, (account) => {

    return account.map(e => e.get("accountNumber")).first();


});

const productDetailsSort = createSelector(productDetails,(items)=>{

   return items.map((item)=>{return item}).sort((a,b) => {
       if (a.get('description') < b.get('description')) return -1;
       if (a.get('description') > b.get('description')) return 1;
       return 0;
   });

});

const calculateSubTotal = (state) => {
    return state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'lineItems']) == undefined ? 0 :
        state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'lineItems'])
            .filter(li => li.get('approvalStatus') == LINE_ITEM_STATUS.APPROVED
            && !(li.get('lineItemType') == LINE_ITEM_TYPE.TAX
            || li.get('lineItemType') == LINE_ITEM_TYPE.CANADA_SALES_TAX))
            .reduce(sum, 0);
};

const sum = (total, item) => {
    return Number(total) + Number(item.get('totalAmount'));
};

const RequestsApprovalView = createSelector(
    vendorCheckingAccountInitial,
    productDetailsSort,
    calculateSubTotal,
    (
        vendorCheckingAccount,
        productDetails,
        subTotal
    ) => {

        return {
            vendorCheckingAccount,
            productDetails,
            subTotal
        };
    }
);

export default RequestsApprovalView;