import React from 'react';
import {defineMessages, FormattedMessage,FormattedNumber} from 'react-intl';
import './RequestTotals.css';
import {emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError, validateEmail, zeroIfUndefined} from 'utils/HelperFunctions';
import classNames from 'classnames';
import { IntlProvider } from 'react-intl';

class RequestTotals extends React.Component {


    render() {

        const messages = defineMessages({
            request_no_subtotal_view: {
                id: 'request_no_subtotal_view',
                description: 'request_no_subtotal_view',
                defaultMessage: 'Subtotal'
            },
            request_no_tax_rate_view: {
                id: 'request_no_tax_rate_view',
                description: 'request_no_tax_rate_view',
                defaultMessage: 'Tax Rate'
            },

        });

        return <div className={classNames('table-responsive', 'request_totals_view__edit')}>
            <table className="request_totals_custom-responsive">
                <tbody>
                <tr className="row">
                    <td className="col-md-1" css={`width: inherit;`}>
                    </td>
                    <td className="col-md-5 request_totals_view__line_item">
                        <FormattedMessage {...messages.request_no_subtotal_view} />
                    </td>
                    <td className="col-md-3 request_totals_view__line_item">
                    </td>
                    <td className="col-md-2 request_totals_view__line_item request_totals_view__currency">
                        <IntlProvider locale="en">
                            <FormattedNumber value={this.props.subTotal}
                                         style="currency"
                                         currency={this.props.selectedCurrency}/>
                        </IntlProvider>
                    </td>
                    <td className="col-md-1">
                    </td>
                </tr>
                <tr className="row">
                    <td className="col-md-1" css={`width: inherit;`}>
                    </td>
                    <td className="col-md-5 request_totals_view__line_item">
                        <FormattedMessage {...messages.request_no_tax_rate_view} />
                    </td>
                    <td className="col-md-3 request_totals_view__line_item">
                    </td>
                    <td className="col-md-2 request_totals_view__line_item request_totals_view__currency">
                        <span>{this.props.taxRate.toFixed(2)}</span>
                    </td>
                    <td className="col-md-1">
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    }
}




export default (RequestTotals);


