import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { fromJS } from 'immutable';

export function uiDataSearchPO(state = initialAppState.getIn(['uiData', 'searchPO']), action) {
    switch (action.type) {
        case constants.SEARCH_FOR_POS_BY_REQUESTED:
            return state.set('isFetchingPOData', true);
        case constants.FETCH_VEH_DATA_FOR_SEARCH_VENDOR_POS_REJECTED:
            return state.set('isFetchingPOData', false);
        case constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REQUESTED:
            return state.set('isFetchingPOData', true);
        case constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED:
        case constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REJECTED:
            return state.set('isFetchingPOData', false);
        case constants.TOGGLE_SHOW_ALL_SERVICE_IN_PROGRESS_POS:
            return state.set('showAllServiceInProgressPOs', !state.get('showAllServiceInProgressPOs'));
        case constants.TOGGLE_SHOW_ALL_PAYMENT_IN_PROGRESS_POS:
            return state.set('showAllPaymentInProgressPOs', !state.get('showAllPaymentInProgressPOs'));
        case constants.TOGGLE_SHOW_ALL_PAID_POS:
            return state.set('showAllPaidPOs', !state.get('showAllPaidPOs'));
        case constants.SET_SEARCH_PO_VIN8_ERROR_MESSAGE:
            return state.set('searchPOErrorMessage', action.errorMessage);
        case constants.HIDE_SEARCH_PO_VIN8_ERROR_MESSAGE:
            return state.set('searchPOErrorMessage', undefined);
        case constants.SAVE_SEARCH_FILTER_SEARCH_FOR:
            return state.setIn(['searchFilters', 'searchFor'], action.searchFor);
        case constants.SAVE_SEARCH_FILTER_DAYS:
            return state.setIn(['searchFilters', 'days'], action.days);
        case constants.SAVE_SEARCH_TEXT:
            return state.setIn(['searchFilters', 'searchText'], action.searchText);
        case constants.RESET_SEARCH_FILTERS:
            return state.setIn(['searchFilters', 'searchFor'], constants.SEARCH_FILTER_TYPE.VIN8).
                setIn(['searchFilters', 'days'], constants.SEARCH_DURATION_TYPE.DURATION).
                setIn(['searchFilters', 'searchText'], '');

        default:
            return state;
    }
}

export function serverDataSearchPO(state = initialAppState.getIn(['serverData', 'searchPO']), action) {
    switch (action.type) {
        case constants.SEARCH_FOR_POS_BY_RESOLVED:
            return state.set('vehicleDetails', fromJS(action.vehicleDetails));
        case constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED:
            return state.set('pos', fromJS(action.pos));
        case constants.RESOLVE_SEARCH_FILTERS:
            return state.set('searchFilters', action.searchFilters);

        default:
            return state;
    }
}