import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import './RepairNotes.css';

const RepairNotes = (props) => {
    const [showAll, setshowAll] = useState(false);

    const showMoreClickHandler = (e) => {
        setshowAll(true);
    }

    const showLessClickHandler = (e) => {
        setshowAll(false);
    }

    const showMore = props.intl.formatMessage({
        id: 'repair_notes_show_more', description: '...show More',
        defaultMessage: '...show more'
    });
    const showLess = props.intl.formatMessage({
        id: 'repair_notes_show_less', description: '...show less',
        defaultMessage: '...show less'
    });
    const yourNote = props.intl.formatMessage({
        id: 'repair_notes_your_note', description: 'Your Note: ',
        defaultMessage: 'Your Note: '
    });
    const repairNotesCaption = props.intl.formatMessage({
        id: 'repair_notes_caption', description: 'Repair notes',
        defaultMessage: 'Repair Notes'
    });
    const clientNote = props.intl.formatMessage({
        id: 'repair_notes_client_note', description: 'Client: ',
        defaultMessage: 'Client: '
    });

    // Keeps track of the number of lines displayed
    var lineCount = 0;
    // Indicates if the ShowMore link is displayed or not
    // Show more link is displayed only when this flag value is true
    // Once the link is displayed, the falg is again made 'false' during the next line count check
    // This enables the show more link to be displayed only once
    var flag = true && !showAll;
    var maxLineCount = 10;

    const getNoteElement = (note) => {
        const isVendorNote = note.get('createdBy') == 'VENDOR';
        return (
            <div key={note.get('id')} className={isVendorNote ? 'repair-notes_vendor_note' : 'repair-notes_client_note'}>
                {isVendorNote ? yourNote : clientNote}
                {note.get('noteText').split("\n").map((line, index) => {
                    if (lineCount > maxLineCount) flag = false;
                    else lineCount = lineCount + 1;
                    return lineCount > maxLineCount && !showAll ? flag ?
                        <a className="repair-notes_more_less_link" onClick={showMoreClickHandler.bind(this)}>
                            {showMore}</a> : false
                        :
                        index == 0 ? line : <div key={note.get('id') + '-' + index} >{line}</div>
                })}
            </div>
        );
    }

    return <div className="repair-notes">
        {
            <>
                <div className="repair-notes_caption">{repairNotesCaption}</div>
                <div>
                    {
                        props.notesHistory?.filter(n => n.get('category') == 'CLIENT_VENDOR')
                            .sortBy((item)=> -item.get("id"))
                            .map((note) => getNoteElement(note)).valueSeq()
                    }
                    {
                        showAll ?
                            <div className="repair-notes_more_less_link">
                                <a className="repair-notes_more_less_link" onClick={showLessClickHandler.bind(this)}>{showLess}</a></div> : false

                    }

                </div>
            </>
        }
    </div>
}


export default injectIntl(RepairNotes);