// #region Imports
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import Notification from 'Notification';
import * as constants from 'constants/App';
import { Container, Row } from 'react-bootstrap';
import './IEWarning.css'
import { useAppState } from '../shared/AppState/app-state';

// #endregion

// #region Styled Components
// #endregion

// #region Component
const IEWarningBanner = (props) => {
    // #region State
    const [appState] = useAppState(); 
    // #endregion

    // #region Ref
    // #endregion

    //#region Hooks
    const intl = useIntl();
    //#endregion

    // #region Event handlers
    // #endregion

    // #region Effects
    // #endregion

    // #region International messages
    const messages = defineMessages({
        ie_warning_title: {
            id: 'ie_warning_title',
            description: 'ie warning title',
            defaultMessage: 'Browser security warning'
        },
        ie_warning_message_line1: {
            id: 'ie_warning_message_line1',
            description: 'ie warning message line 1',
            defaultMessage: 'Effective October 31, 2023, Holman PartnerConnect will no longer be compatible with Microsoft Internet Explorer (IE) because it is no longer being supported by Microsoft. This means IE no longer receives security updates, making it unsafe to use for Holman, our customers, or partners.'
        },
        ie_warning_message_line2: {
            id: 'ie_warning_message_line2',
            description: 'ie warning message line 2',
            defaultMessage: 'If you want to learn more, please see {articleLink} from Microsoft: '
        },
        ie_warning_message_line3: {
            id: 'ie_warning_message_line3',
            description: 'ie warning message line ',
            defaultMessage: 'Common questions:'
        },
        ie_warning_message_question1: {
            id: 'ie_warning_message_question1',
            description: 'ie warning Q/A 1',
            defaultMessage: 'What should I do next?'
        },
        ie_warning_message_question2: {
            id: 'ie_warning_message_question2',
            description: 'ie warning Q/A 2',
            defaultMessage: "What if I don't know my username and password because it's saved in my browser?"
        },
        ie_warning_message_question3: {
            id: 'ie_warning_message_question3',
            description: 'ie warning Q/A 3',
            defaultMessage: "What if I can't download a new browser?"
        },
        ie_warning_message_answer1: {
            id: 'ie_warning_message_answer1',
            description: 'ie warning Q/A 1',
            defaultMessage: "You should download a modern browser such as Microsoft Edge, Google Chrome, or Mozilla Firefox."
        },
        ie_warning_message_answer2: {
            id: 'ie_warning_message_answer2',
            description: 'ie warning Q/A 2',
            defaultMessage: "First download a new browser."
        },
        ie_warning_message_answer3: {
            id: 'ie_warning_message_answer3',
            description: 'ie warning Q/A 3',
            defaultMessage: "Then you can {resetpasswordLink}."
        },
        ie_warning_message_answer4: {
            id: 'ie_warning_message_answer4',
            description: 'ie warning Q/A 4',
            defaultMessage: "Contact your IT Team."
        },
        ie_warning_message_answer5: {
            id: 'ie_warning_message_answer5',
            description: 'ie warning Q/A 5',
            defaultMessage: "If you don't have an IT person in your company, you can reach out to Holman IT at {mailAddress}."
        },
        ie_warning_article_text: {
            id: 'ie_warning_article_text',
            description: 'Microsoft article text',
            defaultMessage: 'this article'
        },
        ie_warning_reset_password_text: {
            id: 'ie_warning_reset_password_text',
            description: 'rest password text',
            defaultMessage: 'reset your password'
        },
        ie_warning_learn_more_button: {
            id: 'ie_warning_learn_more_button',
            description: 'ie warning learn more button',
            defaultMessage: 'Learn More'
        }
    });
    // #endregion

    // #region Render helpers    
    // #endregion  

    // #region Render
    const isIE = false || !!document.documentMode;

    return (
        (isIE && appState.isIEWarningFeatureEnabled) ? <Notification type={constants.TYPE.WARNING} bodyStyle={{ display: "-ms-flexbox" }} render={
            <Container className="ie_warning">
                <Row className="ie_warning-header">
                    <FormattedMessage {...messages.ie_warning_title} />
                </Row>
                <Row className="ie_warning-body">
                    <Container>
                        <Row className="ie_warning_message_line">
                            <FormattedMessage {...messages.ie_warning_message_line1} />
                        </Row>
                        <br />
                        <Row className="ie_warning_message_line">
                            <FormattedMessage {...messages.ie_warning_message_line2}
                                values={{
                                    articleLink: (<a href='https://blogs.windows.com/windowsexperience/2021/05/19/the-future-of-internet-explorer-on-windows-10-is-in-microsoft-edge/' target="_blank" rel="noopener noreferrer">
                                        &nbsp;{intl.formatMessage(messages.ie_warning_article_text)}&nbsp;
                                    </a>)
                                }} />
                        </Row>
                        <br />
                        <Row className='ie_warning_message_line'>
                            <FormattedMessage {...messages.ie_warning_message_line3} />
                        </Row>
                        <Row>
                            <ul>
                                <li><FormattedMessage {...messages.ie_warning_message_question1} /></li>
                                <ul>
                                    <li><FormattedMessage {...messages.ie_warning_message_answer1} /></li>
                                </ul>
                                <li><FormattedMessage {...messages.ie_warning_message_question2} /></li>
                                <ul>
                                    <li><FormattedMessage {...messages.ie_warning_message_answer2} /></li>
                                    <li><FormattedMessage {...messages.ie_warning_message_answer3} 
                                        values={{resetpasswordLink: (<a href='https://partnerconnect.holman.com/recoverPassword'>
                                            {intl.formatMessage(messages.ie_warning_reset_password_text)}
                                        </a>)}}/>
                                    </li>
                                </ul>
                                <li><FormattedMessage {...messages.ie_warning_message_question3} /></li>
                                <ul>
                                    <li><FormattedMessage {...messages.ie_warning_message_answer4} /></li>
                                    <li><FormattedMessage {...messages.ie_warning_message_answer5} 
                                        values={{mailAddress: (<a href='mailto:APCITsupport@holman.com'>APCITsupport@holman.com</a>)}}/>
                                    </li>
                                </ul>
                            </ul>
                        </Row>
                    </Container>
                </Row>
            </Container>
        } />
            : undefined
    );
    // #endregion
};

export default IEWarningBanner;