import React from 'react';
import {Link} from 'react-router-dom';
import { defineMessages, FormattedMessage,FormattedNumber } from 'react-intl';
import { Map } from 'immutable';
import {PO_AUTHORIZATION_STATUS} from 'constants/App';
import classnames from 'classnames';
import './ActivePOList.css';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import CannotEditClaimBox from 'AddEditView/CannotEditClaimBox';
import Help from 'components/Help';
import {partVendors} from 'data/partVendors';
import { IntlProvider } from 'react-intl';
import Features from 'components/Features';
import {withRouter} from 'react-router-dom';
import { useAppState } from 'shared/AppState/app-state';


const ActivePOList = (props) => {    
   
    const [appState, dispatch] = useAppState();

    
    const isPartVendor = partVendors.includes(props.vendorId)
    const closePOBox = {};

    
    const isJustApproved = (approvalDate) => {
        var today = new Date();
        // temporary hack to add add minutes to current datetime based on current location's timezoneoffset
        today.setTime(today.getTime() + today.getTimezoneOffset()*60*1000);        
        var diffMs = (today - new Date(approvalDate)); // milliseconds between now & PO date
        var diffMins = Math.floor((diffMs / 1000) / 60); // minutes
        return diffMins < 15;     // TODO - change 15 to a configurable value
    }

    const getCaption= (componentType,country) => {

        const messages = defineMessages({
            shopview_approved_pos_caption: {
                id: 'shopview_approved_pos_caption',
                description: 'Caption for Approved PO List',
                defaultMessage: 'Approved for service'
            },
            shopview_waiting_for_driver_validation_caption: {
                id: 'shopview_waiting_for_driver_validation_caption',
                description: 'Caption for Waiting for Driver Validation PO List',
                defaultMessage: 'Waiting for driver validation'
            },
            shopview_waiting_for_ari_approval_caption: {
                id: 'shopview_waiting_for_ari_approval_caption',
                description: 'Caption for Waiting for Holman Approval PO List',
                defaultMessage: 'Waiting for Holman approval'
            },
            shopview_waiting_for_ari_approval_caption_mex: {
                id: 'shopview_waiting_for_ari_approval_caption_mex',
                description: 'Caption for Waiting for Holman Approval PO List',
                defaultMessage: 'Waiting for ARIZA approval'
            },
            shopview_waiting_for_client_approval_caption: {
                id: 'shopview_waiting_for_client_approval_caption',
                description: 'Caption for Waiting for client Approval PO List',
                defaultMessage: 'Waiting for client approval'
            },
            shopview_your_action_required: {
                id: 'shopview_your_action_required',
                description: 'Caption for Vendor action required PO List',
                defaultMessage: 'Your action required'
            },
            new_work_assignment: {
                id: 'new_work_assignment',
                description: 'Caption for New work assignment PO List',
                defaultMessage: 'New Work Assignment'
            },
            work_completed: {
                id: 'work_completed',
                description: 'Caption for work completed PO List',
                defaultMessage: 'Work Completed'
            }
        });

        switch (componentType) {
            case PO_AUTHORIZATION_STATUS.APPROVED:
                return <FormattedMessage {...messages.shopview_approved_pos_caption}/>;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION:
                return <FormattedMessage {...messages.shopview_waiting_for_driver_validation_caption}/>;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL:
                return country==="MEX"? <FormattedMessage {...messages.shopview_waiting_for_ari_approval_caption_mex}/>: <FormattedMessage {...messages.shopview_waiting_for_ari_approval_caption}/>;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL:
                return <FormattedMessage {...messages.shopview_waiting_for_client_approval_caption}/>;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL:
                return <FormattedMessage {...messages.shopview_your_action_required}/>;
            case PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT:
                return <FormattedMessage {...messages.new_work_assignment} />;    
            case PO_AUTHORIZATION_STATUS.WORK_COMPLETED:
                return <FormattedMessage {...messages.work_completed} />;    
        }
    }

    const getHelpLink= (componentType,showVideoLinks) => {
        if(!showVideoLinks) return false;
        switch (componentType) {
            case PO_AUTHORIZATION_STATUS.APPROVED:
                return <Help videoId="approved_for_service" whiteSpaceAtBottomHeight="1.25em"/>;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL:
                return <Help videoId="waiting_for_ari_client_approval" whiteSpaceAtBottomHeight="1.25em"/>;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL:
                return <Help videoId="waiting_for_ari_client_approval" whiteSpaceAtBottomHeight="1.25em"/>;
            case PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL:
                return <Help videoId="your_action_is_required" whiteSpaceAtBottomHeight="1.25em"/>;
            case PO_AUTHORIZATION_STATUS.WORK_COMPLETED:
                return <Help videoId="work_completed_pos" whiteSpaceAtBottomHeight="1.25em"/>;    
            
        }
    }

    const getFilteredRecords= (poList, showAll) => {
        var filteredRecords = appState.isAccidentAssignmentFeatureEnabled ?
            poList.filter(po => !po.get('hasAssociatedClaim')).sortBy(po => new Date(po.get('createdDate'))).reverse()
            : poList.sortBy(po => new Date(po.get('createdDate'))).reverse();

        if (showAll == false && filteredRecords.count() > 0)
            filteredRecords = filteredRecords.take(10);

        return filteredRecords;
    }

    const getHeaders = (componentType) => {
        const messages = defineMessages({
            active_po_vehicle_column_header: {
                id: 'active_po_vehicle_column_header',
                description: 'Header for Vehicle Column',
                defaultMessage: 'Vehicle'
            },
            active_po_vin_column_header: {
                id: 'active_po_vin_column_header',
                description: 'Header for VIN Column',
                defaultMessage: 'VIN'
            },
            active_po_po_number_column_header: {
                id: 'active_po_po_number_column_header',
                description: 'Header for PO Number Column',
                defaultMessage: 'PO no.'
            },
            active_po_reference_number_column_header: {
                id: 'active_po_reference_number_column_header',
                description: 'Header for ref # Column',
                defaultMessage: 'Your Ref #'
            },
            active_po_po_total_amount_column_header: {
                id: 'active_po_po_total_amount_column_header',
                description: 'Header for PO Total Amount Column',
                defaultMessage: 'Amount'
            },
            active_po_po_approved_amount_column_header: {
                id: 'active_po_po_approved_amount_column_header',
                description: 'Header for PO Approved Amount Column',
                defaultMessage: 'Approved'
            },
            active_po_po_pending_amount_column_header: {
                id: 'active_po_po_pending_amount_column_header',
                description: 'Header for PO Pending Amount Column',
                defaultMessage: 'Pending'
            }
        });

        return <tr>
            <th className="active-po-list__header-cell
        active-po-list__header-cell_align-left">
<FormattedMessage {...messages.active_po_vehicle_column_header}/>
</th>
<th className="active-po-list__header-cell
        active-po-list__header-cell_align-left">
<FormattedMessage {...messages.active_po_vin_column_header}/>
</th>
<th className="active-po-list__header-cell
        active-po-list__header-cell_align-left">
<FormattedMessage {...messages.active_po_po_number_column_header}/>
</th>
<th className="active-po-list__header-cell
        active-po-list__header-cell_align-left">
<FormattedMessage {...messages.active_po_reference_number_column_header}/>
</th>
        {componentType == PO_AUTHORIZATION_STATUS.APPROVED
            ? <th className="active-po-list__header-cell
            active-po-list__header-cell_align-right">
<FormattedMessage {...messages.active_po_po_total_amount_column_header}/>
</th>
            : undefined
        }
        {componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
        componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION ||
        componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
        componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL || 
        componentType == PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT || 
        componentType == PO_AUTHORIZATION_STATUS.WORK_COMPLETED
            ? <th className="active-po-list__header-cell
            active-po-list__header-cell_align-right">
<FormattedMessage {...messages.active_po_po_approved_amount_column_header}/>
</th>
            : undefined
        }
        {componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
        componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION ||
        componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
        componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL || 
        componentType == PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT || 
        componentType == PO_AUTHORIZATION_STATUS.WORK_COMPLETED
            ? <th className="active-po-list__header-cell
            active-po-list__header-cell_align-right">
<FormattedMessage {...messages.active_po_po_pending_amount_column_header}/></th>
: undefined
        }
        </tr>;
    }
    const getTooltip = (toolTipText)=>{
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }
    const getVehicle = (vin8,vehicleYear,vehicleMake,vehicleModel) => {

        return <OverlayTrigger
        placement='right'
        key={vin8}
        overlay={getTooltip((vehicleYear == null ? '' : vehicleYear)
            + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))}
    >
        <div key={vin8}>
            {(vehicleYear + ' ' + vehicleMake + ' ' + vehicleModel).length > 10
                ? ((vehicleYear == null ? '' : vehicleYear)
            + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel)).substr(0, 9) + '...'
                : ((vehicleYear == null ? '' : vehicleYear)
            + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))
    }
            </div>
        </OverlayTrigger>

            }

    const getReferenceNo= (refNo) => {

                return <OverlayTrigger
                placement='right'
                overlay={getTooltip(refNo == null ? "None" : refNo)}
            >
                <div>
                    {refNo == null ? <FormattedMessage {...messages.none}/> : refNo.length > 5
                        ? (refNo == null ? <FormattedMessage {...messages.none}/> : refNo).substr(0, 5) + '...'
                        : (refNo == null ? <FormattedMessage {...messages.none}/> : refNo)
                    }
                </div>
            </OverlayTrigger>

            }

    const getRows= (componentType, filteredRecords,poFeatures) => {        
        const today = new Date();

        const messages = defineMessages({
            po_edit_link_caption: {
                id: 'po_edit_link_caption',
                description: 'Text for PO Edit link',
                defaultMessage: 'EDIT'
            },
            po_view_link_caption: {
                id: 'po_view_link_caption',
                description: 'Text for PO View link',
                defaultMessage: 'VIEW'
            },
            po_close_link_caption: {
                id: 'po_close_link_caption',
                description: 'Text for PO Close link',
                defaultMessage: 'CLOSE'
            },
            po_reply_link_caption: {
                id: 'po_reply_link_caption',
                description: 'Text for PO Reply link',
                defaultMessage: 'Reply'
            },
            none: {
                id: 'none',
                description: 'Text for po number column when po is not approved',
                defaultMessage: 'None'
            },
            no_pos_caption: {
                id: 'no_pos_caption',
                description: 'Caption to display if no POs are available for display on Shop View',
                defaultMessage: 'None at this Time'
            },
            close_confirmation_title: {
                id: 'close_confirmation_title',
                description: 'Adjust PO',
                defaultMessage: 'Adjust PO'
            },
            close_confirmation_message: {
                id: 'close_confirmation_message',
                description: 'Are there any adjustments required in this PO?',
                defaultMessage: 'Are there any adjustments required in this PO?'
            },
            close_confirmation_yes: {
                id: 'close_confirmation_yes',
                description: 'Yes',
                defaultMessage: 'Yes'
            },
            close_confirmation_no: {
                id: 'close_confirmation_no',
                description: 'No',
                defaultMessage: 'No'
            },
                insurance_edit_check_message: {
                id: 'insurance_edit_check_message',
                description: 'insurance_edit_check_message',
                defaultMessage: "If you need to revise an estimate on claim-related events please email a supplement and supporting documentation to <a href='mailto:fleetclaims@holman.com'>fleetclaims@holman.com</a>"
            },
            insurance_edit_check_ok: {
                id: 'insurance_edit_check_ok',
                description: 'insurance_edit_check_ok',
                defaultMessage: 'Ok'
            }            
        });


        if (filteredRecords.count() == 0)
            return  <tr>
                    <td colSpan="6" className="active-po-list__cell
        active-po-list__cell_shadow
        active-po-list__cell_both-corners-round
        active-po-list__cell_align-center">
<FormattedMessage {...messages.no_pos_caption}/>
</td>
</tr>
    else 
            return filteredRecords.valueSeq().map(po =>
                <tr key={po.get('id')}>
                    <td className={classnames('active-po-list__cell',
                                              'active-po-list__cell_shadow',
                                              'active-po-list__cell_left-corner-round',
                    {'active-po-list__cell_just-approved': componentType == PO_AUTHORIZATION_STATUS.APPROVED && isJustApproved(po.get('authorizedDate'))},
                        {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                                              )}>
    {getVehicle(po.get('vehicleVIN8'),po.get('vehicleYear'),po.get('vehicleMake'),po.get('vehicleModel'))}
                    </td>
                    <td className={classnames('active-po-list__cell',
                                             'active-po-list__cell_shadow',
                    {'active-po-list__cell_just-approved': componentType == PO_AUTHORIZATION_STATUS.APPROVED && isJustApproved(po.get('authorizedDate'))},
                        {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                                             )}>
    {po.get('vehicleVIN8')}
</td>
        <td className={classnames('active-po-list__cell',
                                 'active-po-list__cell_shadow',
        {'active-po-list__cell_just-approved': componentType == PO_AUTHORIZATION_STATUS.APPROVED && isJustApproved(po.get('authorizedDate'))},
        {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                         )}>
    {componentType == PO_AUTHORIZATION_STATUS.APPROVED || componentType == PO_AUTHORIZATION_STATUS.WORK_COMPLETED
        ? po.get('poNumber')
        : <FormattedMessage {...messages.none}/>
    }
                    </td>
                    <td className={classnames('active-po-list__cell',
                                              'active-po-list__cell_shadow',
                                              'active-po-list__cell_align-center',
                    {'active-po-list__cell_just-approved': componentType == PO_AUTHORIZATION_STATUS.APPROVED && isJustApproved(po.get('authorizedDate'))},
                        {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                                              )}>
    { po.get('vendorReferenceNo')==null?<FormattedMessage {...messages.none}/>:getReferenceNo(po.get('vendorReferenceNo'))}

 </td>
        <td className={classnames('active-po-list__cell',
                                      'active-po-list__cell_shadow',
                                      'active-po-list__cell_align-right',
        {'active-po-list__cell_just-approved': componentType == PO_AUTHORIZATION_STATUS.APPROVED && isJustApproved(po.get('authorizedDate'))},
        {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                               )}>
                        <IntlProvider locale="en">
                                <FormattedNumber value={po.get('authorizedAmount')}
                            style="currency"
                            currency={props.selectedCurrency}
                            minimumFractionDigits={2}/>
   </IntlProvider>
</td>
                            {
                                (componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
                                    componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION ||
                                    componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
                                    componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL || 
                                    componentType == PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT || 
                                    componentType == PO_AUTHORIZATION_STATUS.WORK_COMPLETED

                                        ? <td className={classnames('active-po-list__cell',
                                                          'active-po-list__cell_shadow',
                                                          'active-po-list__cell_align-right',                                                  
                                        {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                                                          )}>
                                        <IntlProvider locale="en">
                                        <FormattedNumber value={po.get('pendingAmount')}
                            style="currency"
                            currency={props.selectedCurrency}
                            minimumFractionDigits={2}/></IntlProvider></td>
           : undefined
                        )
                    }
    {

        (componentType == PO_AUTHORIZATION_STATUS.APPROVED && !props.ShowEditLinkForApprovedPOs|| componentType == PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT
                ?
                <td className={classnames('active-po-list__cell',
                                          'active-po-list__cell_shadow',
                                          'active-po-list__cell_right-corner-round',
                                          'active-po-list__cell_left-margin',
                                          'active-po-list__cell_edit-action',
                {'active-po-list__cell_just-approved': isJustApproved(po.get('authorizedDate')) && componentType == PO_AUTHORIZATION_STATUS.APPROVED
                },
                {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                                          )}>
    {!poFeatures.readOnlyPOMode && appState.poPermissions.includes('ED') ?
    po.get('hasAssociatedClaim') ? 
        <div> 
            <CannotEditClaimBox
                ref={(ref) => closePOBox[po.get('id')] = ref}
                message={messages.insurance_edit_check_message}
                okButtonText={messages.insurance_edit_check_ok}
                onOKClickEvent={false}
                selectedCountry={props.selectedCountry}
                />

            <div onClick={()=>closePOBox[po.get('id')].showModal()} role="button">
                <FormattedMessage {...messages.po_edit_link_caption} />
            </div>
        </div>
    :
    <Link to={`/PO/edit/${po.get('id')}`} >
        <FormattedMessage {...messages.po_edit_link_caption}/>
    </Link>
    :
    <Link to={`/PO/view/${po.get('id')}`}>
        <FormattedMessage {...messages.po_view_link_caption}/>
    </Link>
    }
                                    

</td>
: undefined
)
    }
    {
(componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION ||
componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL || 
componentType == PO_AUTHORIZATION_STATUS.WORK_COMPLETED
?
<td className={classnames('active-po-list__cell',
    'active-po-list__cell_shadow',
    'active-po-list__cell_right-corner-round',
    'active-po-list__cell_left-margin',
    'active-po-list__cell_edit-action',
    {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
)}>

    <Link to={`/PO/view/${po.get('id')}`}>
        <FormattedMessage {...messages.po_view_link_caption}/>
    </Link>
</td>
: undefined
)
    }
    {
(componentType == PO_AUTHORIZATION_STATUS.APPROVED
&& !poFeatures.readOnlyPOMode && appState.poPermissions.includes('CL')
?
<td className={classnames('active-po-list__cell',
                          'active-po-list__cell_right-corner-round',
                          'active-po-list__cell_left-margin',
                          'active-po-list__cell_close-action',
    {'active-po-list__cell_close-action-just-approved': isJustApproved(po.get('authorizedDate'))},
    {'active-po-list__cell-action-required': componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
                          )}>                                    

    {isPartVendor ? 
        <div> 
            <ConfirmMessageBox
                ref={(ref) => closePOBox[po.get('id')] = ref}
                title={messages.close_confirmation_title}
                message={messages.close_confirmation_message}
                okButtonText={messages.close_confirmation_yes}
                cancelButtonText={messages.close_confirmation_no}
                onOKClickEvent={goToUrlClosure(`/po/adjustment/${po.get('id')}`)}
                onCancelClickEvent={goToUrlClosure(`/po/close/${po.get('id')}`)} />

            <div onClick={()=>closePOBox[po.get('id')].showModal()} role="button">
                <FormattedMessage {...messages.po_close_link_caption} />
            </div>
        </div>
    :
        <Link to={`/po/close/${po.get('id')}`}>
            <FormattedMessage {...messages.po_close_link_caption}/>
        </Link>
        }
</td>
: undefined
)
        }
        {
(componentType == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL
?
<td className="active-po-list__cell
               active-po-list__cell_shadow
               active-po-list__cell_right-corner-round
               active-po-list__cell_left-margin
               active-po-list__cell-action-required
               active-po-list__cell-reply-required">
        {!poFeatures.readOnlyPOMode && appState.poPermissions.includes('ED') ?
    <Link to={`/po/actionRequired/${po.get('id')}`}>
        <FormattedMessage {...messages.po_reply_link_caption}/>
    </Link>
    :
    <Link to={`/PO/view/${po.get('id')}`}>
        <FormattedMessage {...messages.po_view_link_caption}/>
    </Link>
    }
                                    
</td>
: undefined
)
    }
</tr>
)
    }

    const goToUrlClosure = (url) => {

        return ()=>{props.history.push(url)};
    }

const getFooter= (poList, filteredRecords) => {

const messages = defineMessages({
        show_all_waiting_pos: {
        id: 'show_all_waiting_pos',
        description: 'Link at bottom of PO List to show all POs',
        defaultMessage: 'Show all {poCount} purchase orders'
    },
        hide_older_pos: {
        id: 'hide_older_pos',
        description: 'Link at bottom of PO List to hide older approved POs',
        defaultMessage: 'Hide older purchase orders'
    }
    });

        // Don't show footer if we have less than 10 records to display
if (filteredRecords.count() < 10)
return undefined;
        // If we are showing only top x records
    else if (poList.count() > filteredRecords.count()) {
return <div role="button" className="active-po-list__footer">
<FormattedMessage values={{poCount:poList.count()}}
    {...messages.show_all_waiting_pos}/>
    {' '}<img src="/assets/VMS_button_show_all_pos.png"/>
</div>;
    }
    else {
return <div role="button" className="active-po-list__footer">
<FormattedMessage {...messages.hide_older_pos}/>
    {' '}<img src="/assets/VMS_button_hide_older_pos.png"/>
</div>;
    }
    }    


const filteredRecords = getFilteredRecords(props.activePOs, props.showAll);
const { type, activePOs, footerClickHandler } = props;

return <Features render={restrictedFeatures => (
<div className="active-po-list table-responsive">
        
<div className={classnames('active-po-list__caption',
        'float-left',
    {'active-po-list__caption_action-required': type == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL}
        )}>
    {getCaption(type,restrictedFeatures.selectedCountry)}
    {getHelpLink(type,restrictedFeatures.showVideoLinks)}
</div>
<table className="active-po-list__table" width="101%">
<thead>
    {getHeaders(type)}
</thead>
<tbody>
    {getRows(type, filteredRecords, restrictedFeatures)}
</tbody>
</table>
<div onClick={footerClickHandler.bind(this)}>
    {getFooter(activePOs, filteredRecords)}
</div>
       
</div>
)} />;
    
    }


export default withRouter(ActivePOList);