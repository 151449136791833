import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Map} from 'immutable';
import './MyBusinessGrowth.css';
import {Link} from 'react-router-dom';
import {emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined,zeroIfUndefined} from 'utils/HelperFunctions';


class MyUnitsView extends React.Component {

    render() {
        const messages = defineMessages({
            grow_business_caption: {
                id: 'grow_business_caption',
                description: 'grow your business',
                defaultMessage: 'Grow your business'
            },
            my_units_caption: {
                id: 'my_units_caption',
                description: 'get more business',
                defaultMessage: 'Get more business today'
            },
           total_vehicle_count_caption: {
                id: 'total_vehicle_count_caption',
                description: 'total vehicle count',
                defaultMessage: 'Total Holman vehicles assigned to you'
            },
            services_due_now_caption: {
                id: 'services_due_now_caption',
                description: 'services due now count',
                defaultMessage: 'Holman vehicles you have worked on in the past with services due now'
            },
            additional_services_due_count_caption: {
                id: 'additional_services_due_count_caption',
                description: 'additional services due count',
                defaultMessage: 'Additional Holman vehicles in your area with services due now'
            },
        });

        return <div>
            <div className="my_business_grow__caption">
                <FormattedMessage {...messages.grow_business_caption}/>
            </div>

            <div className="my_business_grow__row row">
                <div className="col-md-4">
                    <div className="my_business_grow__units">
                        {this.props.vehicleTotalCount}
                    </div>
                    <div className="my_business_grow__units2">
                        <FormattedMessage {...messages.total_vehicle_count_caption}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="my_business_grow__units">
                        {this.props.vehicleServiceDue}
                    </div>
                    <div className="my_business_grow__units2">
                        <FormattedMessage {...messages.services_due_now_caption}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="my_business_grow__units">
                        {this.props.additionalVehicleServiceDue}
                    </div>
                    <div className="my_business_grow__units2">
                        <FormattedMessage {...messages.additional_services_due_count_caption}/>
                    </div>
                </div>
            </div>

            <div className="my_business_grow__view container">
                    <Link className="my_business_grow__caption2"
                          to='/yourAccount/MyUnits'>
                        <FormattedMessage {...messages.my_units_caption}/>{' '}
                        <img src="/assets/VMS_33button_go_bblue.png"/>
                    </Link>
            </div>
            <hr className="my_business_grow__hr"/>
        </div>;
    }
}


export default (MyUnitsView);