import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Map} from 'immutable';
import './LinkedVendorRegister.css';
import * as constants from 'constants/App';
import {Popover, Overlay} from 'react-bootstrap';
import Submit from 'components/Submit';
import Register from '../components/Register';
import LinkedVendorRegisterSelector from '../selectors/LinkedVendorRegister'
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError,validateEmail,zeroIfUndefined } from 'utils/HelperFunctions';

class LinkedVendorRegister extends React.Component {


    //saves error message to state
    onRequestInfoChangeHandler(inputName, event) {
        let requestInfo = {
            name: inputName,
            value: event.target.value
        };
        this.props.dispatch({type: constants.UPDATE_LINKED_ENROLLMENT_INFO, requestInfo});


    }

    //api call
    onSubmitRequestHandler() {
        this.props.dispatch({type: constants.SUBMIT_LINKED_VENDOR_REGISTER});
    }

    render() {

        return <div>
            <Register
                isRequestFormValid={this.props.isRequestFormValid}
                onRequestInfoChange={this.onRequestInfoChangeHandler.bind(this)}
                requestSubmitted={this.props.requestSubmitted}
                error={renderError('linkedRequestErrorMessage', this.props, this.props.linkedRequestErrorMessage)}
                onSubmit={this.onSubmitRequestHandler.bind(this)}
            />
        </div>;
    }
}
function select(state) {
    return {
        linkedVendors: emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorLinkedAccounts', 'linkedVendors'])),
        linkedRequestErrorMessage: state.appState.getIn(['uiData', 'vendorLinkedAccounts','vendorLinkedRegister', 'errorMessage']),
        ...LinkedVendorRegisterSelector(state)
    };
}

export default connect(select)(LinkedVendorRegister);

