import React from 'react';
import './Gamification.css';
import { defineMessages, FormattedMessage,injectIntl } from 'react-intl';
import * as d3 from 'd3';

class Gamification extends React.Component {

    constructor() {
        super();

        var margin = {
            top: 30,
            right: 30,
            bottom: 50,
            left: 50
        };
        var width = 600 - margin.left - margin.right;
        var height = 270 - margin.top - margin.bottom;

        // Set up a property to hold the data
        var data = {};
   
        this.state = {
            data: data,
            margin: margin,
            width: width,
            height: height
        }
   
    }

    componentDidMount() {
        this.setUp();
    }

    shouldComponentUpdate() {
        // Ensure that component does not refresh with future updates, from React
        return false;
    }


    render() {
        const messages = defineMessages({
            onlineusage_caption: {
                id: 'onlineusage_caption',
                description: 'Online usage',
                defaultMessage: 'Online usage'
            },
            onlineusage_description: {
                id: 'onlineusage_description',
                description: 'Purchase Orders created online.',
                defaultMessage: 'Purchase Orders created online.'
            },
            repairvalue_caption: {
                id: 'repairvalue_caption',
                description: 'Estimate accuracy',
                defaultMessage: 'Estimate accuracy'
            },
            repairvalue_description: {
                id: 'repairvalue_description',
                description: 'Repair costs are measured against available published pricing, as well ...',
                defaultMessage: 'Repair costs are measured against available published pricing, as well ...'
            },
            pmcompletion_caption: {
                id: 'pmcompletion_caption',
                description: 'PM completion',
                defaultMessage: 'PM completion'
            },
            pmcompletion_description: {
                id: 'pmcompletion_description',
                description: 'Holman vehicles assigned to you for which PM was completed on time.',
                defaultMessage: 'Holman vehicles assigned to you for which PM was completed on time.'
            },
            workretention_caption: {
                id: 'workretention_caption',
                description: 'Work retention',
                defaultMessage: 'Work retention'
            },
            workretention_description: {
                id: 'workretention_description',
                description: 'Holman vehicles assigned to you for which work was retained.',
                defaultMessage: 'Holman vehicles assigned to you for which work was retained.'
            },
            performance: {
                id: 'performance',
                description: 'performance',
                defaultMessage: 'Performance'
            },
            chart_legend: {
                id: 'chart_legend',
                description: 'chart_legend',
                defaultMessage: 'Chart legend'
            },
            best_in_class: {
                id: 'best_in_class',
                description: 'best_in_class',
                defaultMessage: 'Best in class'
            },
            above_average: {
                id: 'above_average',
                description: 'above_average',
                defaultMessage: 'Above Avg'
            },
            avg: {
                id: 'avg',
                description: 'avg',
                defaultMessage: 'Avg'
            },
            needs_improvement: {
                id: 'needs_improvement',
                description: 'needs_improvement',
                defaultMessage: 'Needs Improvement'
            },
        });

        return (
            <div className="gamification-container">
                <div className="caption"><FormattedMessage {...messages.performance} /></div>
                <div className="chart-caption" id="chart-caption"></div>
                <div className="legend" id="legend" >
                    <div className="legend_item legend_caption"><FormattedMessage {...messages.chart_legend} /></div>
                    <div className="legend_item legend_top_of_class"><FormattedMessage {...messages.best_in_class} /></div>
                    <div className="legend_item legend_above_avg"><FormattedMessage {...messages.above_average} /></div>
                    <div className="legend_item legend_avg"><FormattedMessage {...messages.avg} /></div>
                    <div className="legend_item legend_below_avg"><FormattedMessage {...messages.needs_improvement} /></div>
                </div>
                <div className="chart-container" id="chart-container">
                    <div className="chart" id="chart" />
                    <div id="onlineusage" className="variable" onClick={this.updateChart.bind(this, 'onlineUsage')} title="Purchase Orders created online.">
                        <div className="icon fa fa-laptop fa-lg" />
                        <div className="variable_sub-title"><FormattedMessage {...messages.onlineusage_caption} /></div>
                        <div className="variable_description"><FormattedMessage {...messages.onlineusage_description} /></div>
                    </div>
                    <div id="repairvalue" className="variable" onClick={this.updateChart.bind(this, 'repairValue')} title="Repair costs are measured against available published pricing, as well as Holman historical data. In addition, nationally known parts pricing for both OEM and high quality aftermarket parts is taken into consideration to produce an acceptable price range for most common repairs.">
                        <div className="icon fa fa-usd fa-lg" />
                        <div className="variable_sub-title "><FormattedMessage {...messages.repairvalue_caption} /></div>
                        <div className="variable_description"><FormattedMessage {...messages.repairvalue_description} /></div>
                    </div>
                    <div id="pmcompletion" className="variable" onClick={this.updateChart.bind(this, 'pmCompletion')} title="Holman vehicles assigned to you for which PM was completed on time.">
                        <div className="icon fa fa-check fa-lg" />
                        <div className="variable_sub-title "><FormattedMessage {...messages.pmcompletion_caption} /></div>
                        <div className="variable_description"><FormattedMessage {...messages.pmcompletion_description} /></div>
                    </div>
                    <div id="workretention" className="variable" onClick={this.updateChart.bind(this, 'workRetention')} title="Holman vehicles assigned to you for which work was retained.">
                        <div className="icon fa fa-refresh fa-lg" />
                        <div className="variable_sub-title "><FormattedMessage {...messages.workretention_caption} /></div>
                        <div className="variable_description"><FormattedMessage {...messages.workretention_description} /></div>
                    </div>
                </div>
            </div>
        );
    }

    setUp() {
        let currentComponent = this;    
        d3.json(this.props.serverURL).then(function(serverData) {

            // function to parse datetime
            var parseTime = d3.timeParse('%m/%d/%Y');

            const dateCompare = function (a, b) {
                if (a.date < b.date)  
                    return -1
                else if (a.date > b.date)  
                    return 1
                else 
                    return 0
             }

            // parse online usage data  
            serverData.onlineUsage.forEach(d => {
                d.date = parseTime(d.date);
                d.percentage = +d.percentage;
            });            

            // parse repair value data
            serverData.repairValue.forEach(d => {
                d.date = parseTime(d.date);
                d.percentage = +d.percentage;
            });

            // parse PM completetion data
            serverData.pmCompletion.forEach(d => {
                d.date = parseTime(d.date);
                d.percentage = +d.percentage;
            });

            // parse work retention data
            serverData.workRetention.forEach(d => {
                d.date = parseTime(d.date);
                d.percentage = +d.percentage;
            });

            // sort 
            serverData.onlineUsage.sort(dateCompare)
            serverData.repairValue.sort(dateCompare)
            serverData.pmCompletion.sort(dateCompare)
            serverData.workRetention.sort(dateCompare)

            // copy data into local variable
            currentComponent.setState({
                data: serverData
            });
       
            // set width and height of svg
            d3.selectAll('svg').remove();
            var svg = d3.select('#chart')
                .append('svg')
                .attr('width', currentComponent.state.width + currentComponent.state.margin.left + currentComponent.state.margin.right)
                .attr('height', currentComponent.state.height + currentComponent.state.margin.top + currentComponent.state.margin.bottom)
                .call(currentComponent.responsivefy)
                .append('g')
                .attr('transform', `translate(${currentComponent.state.margin.left}, ${currentComponent.state.margin.top})`)
                .append('path')

            currentComponent.updateChart('onlineUsage');

        })
    }


    updateChart(chartType) {
        const messages = defineMessages({
            onlineUsage_month: {
                id: 'onlineUsage_month',
                description: 'onlineUsage_month',
                defaultMessage: 'Online usage %, by month'
            },
            repairValue_month: {
                id: 'repairValue_month',
                description: 'repairValue_month',
                defaultMessage: 'Repair value %, by month'
            },
            pmCompletion_month: {
                id: 'pmCompletion_month',
                description: 'pmCompletion_month',
                defaultMessage: 'PM completion %, by month'
            },
            workRetention_month: {
                id: 'workRetention_month',
                description: 'workRetention_month',
                defaultMessage: 'Work retention %, by month'
            },
        });

        const {formatMessage} = this.props.intl;

        // Make one of the variables active
        d3.select('#onlineusage')
            .classed('activeChart', chartType == 'onlineUsage')
        d3.select('#repairvalue')
            .classed('activeChart', chartType == 'repairValue')
        d3.select('#pmcompletion')
            .classed('activeChart', chartType == 'pmCompletion')
        d3.select('#workretention')
            .classed('activeChart', chartType == 'workRetention')

        // Set the chart caption based on chart type
        switch (chartType) {
            case 'onlineUsage':
                d3.select('#chart-caption')
                    .text(formatMessage(messages.onlineUsage_month));
                break;

            case 'repairValue':
                d3.select('#chart-caption')
                    .text(formatMessage(messages.repairValue_month));
                break;

            case 'pmCompletion':
                d3.select('#chart-caption')
                    .text(formatMessage(messages.pmCompletion_month));
                break;

            case 'workRetention':
                d3.select('#chart-caption')
                    .text(formatMessage(messages.workRetention_month));
        }


        var parseTime = d3.timeParse('%Y/%m/%d');
        
        this.renderChart(this.state.data[chartType], 'chart', this.state.width, this.state.height);

    }


    renderChart(values, divName, width, height) {

        // set width and height of svg
        var svg = d3.select('#' + divName)
            .select('svg')
            .select('g')

        // setup x scale
        var today = new Date();

        var xScale = d3.scaleTime()
            .domain([
                new Date(today.getFullYear(), today.getMonth() - 3, 0),
                new Date(today.getFullYear(), today.getMonth() + 1, 0)
            ])
            .range([0, width])
            .nice();

        // setup y scale
        var yScale = d3.scaleLinear()
            .domain([
                0,
                100
            ])
            .range([height, 0]);

        // bands
        svg.selectAll('.band').remove()
        this.appendColorBands(svg, xScale, yScale);

        // add x scale
        svg.selectAll('.xaxis').remove();
        svg
            .append('g')
            .attr('transform', `translate(0, ${height})`)
            .attr("class", "xaxis")
            .call(d3.axisBottom(xScale).ticks(4));

        // add y scale
        svg.selectAll('.yaxis').remove();
        svg
            .append('g')
            .attr("class", "yaxis")
            .call(d3.axisLeft(yScale)
                .ticks(4)
                .tickFormat(function (d) {
                    return d + "%";
                }));

        // setup line
        var line = d3.line()
            .x(d => xScale(d.date))
            .y(d => yScale(d.percentage))
            .curve(d3.curveCatmullRom.alpha(0.5));


        // add the Y gridlines
        svg.selectAll(".grid").remove()
        svg.append("g")
            .attr("class", "grid")
            .call(this.make_y_gridlines(yScale)
                .tickSize(-width)
                .tickFormat("")
            )

        // add new line
        var path = svg
            .select('path')
            .transition()
            .attr('class', 'curve')
            .attr('d', line(values));

        // Remove the old scatterplot
        svg.selectAll("circle").remove()

        // Add the scatterplot for first and last value 
        var update = svg.selectAll("circle")
            .data([values[0],values[values.length-1]])           
            .enter().append("circle")
            .attr("id", (d) => xScale(d.date) + '_' + yScale(d.percentage))
            .attr('class','scatterplot')
            .attr("r", 5)
            .attr("cx", function (d) {
                return xScale(d.date);
            })
            .attr("cy", function (d) {
                return yScale(d.percentage);
            });


        // Remove the zero value tick 
        svg.selectAll(".tick")
            .filter(function (d) {
                return d === 0;
            })
            .remove();

    }

    pathBandData(yStart, yEnd, x, y) {
        // check to see if band falls outside of the y-Axis scale, if so, return nothing
        if (yStart > y.domain()[1] && yEnd > y.domain()[1]) {
            return null;
        } else // otherwise, draw the banding box. still check we don't go beyond the y-Axis scale at each point.
        {
            return "M" + x(x.domain()[0]) + "," + (yStart > y.domain()[1] ? y(y.domain()[1]) : y(yStart)) +
                "L" + x(x.domain()[0]) + "," + (yEnd > y.domain()[1] ? y(y.domain()[1]) : y(yEnd)) +
                "L" + x(x.domain()[1]) + "," + (yEnd > y.domain()[1] ? y(y.domain()[1]) : y(yEnd)) +
                "L" + x(x.domain()[1]) + "," + (yStart > y.domain()[1] ? y(y.domain()[1]) : y(yStart));
        }
    }

    appendColorBands(svg, x, y) {
        var GreenValue = y.domain()[0];
        var EndValue = y.domain()[1];

        // SWAP RED/GREEN START POINTS IF LESS IS WORSE
        GreenValue = y.domain()[1];
        EndValue = y.domain()[0];

        svg.append("g").append("path") // GREEN
            .attr("d", this.pathBandData(80, 100, x, y))
            .attr("class", "band band_top_of_class");

        svg.append("g").append("path")
            .attr("d", this.pathBandData(70, 80, x, y))
            .attr("class", "band band_above_avg");

        svg.append("g").append("path")
            .attr("d", this.pathBandData(40, 70, x, y))
            .attr("class", "band band_avg");

        svg.append("g").append("path")
            .attr("d", this.pathBandData(0, 40, x, y))
            .attr("class", "band band_below_avg");
    }

    // gridlines in y axis function
    make_y_gridlines(yScale) {
        return d3.axisLeft(yScale)
            .ticks(5)
    }

    responsivefy(svg) {
        // get container + svg aspect ratio
        var container = d3.select(svg.node().parentNode),
            width = parseInt(svg.style("width")),
            height = parseInt(svg.style("height")),
            aspect = width / height;

        // add viewBox and preserveAspectRatio properties,
        // and call resize so that svg resizes on inital page load
        svg.attr("viewBox", "0 0 " + width + " " + height)
            .attr("preserveAspectRatio", "xMinYMid")
            .call(resize);

        // to register multiple listeners for same event type,
        // you need to add namespace, i.e., 'click.foo'
        // necessary if you call invoke this function for multiple svgs
        // api docs: https://github.com/mbostock/d3/wiki/Selections#on
        d3.select(window).on("resize." + container.attr("id"), resize);

        // get width of container and resize svg to fit it
        function resize() {
            var targetWidth = parseInt(container.style("width"));
            svg.attr("width", targetWidth);
            svg.attr("height", Math.round(targetWidth / aspect));
        }
    }

}



export default (injectIntl(Gamification));