import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import {LINE_ITEM_STATUS} from 'constants/App';
import Complaint from './Complaint';
import './Complaints.css';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';

class Complaints extends React.Component {

    /*Filter the line items based on the complaint code*/
    getLineItemsByComplaintCode(complaintCode) {
        return this.props.lineItems.filter(function (lineItem) {
            return (lineItem.get('complaintCode') == complaintCode
            && lineItem.get('approvalStatus') == LINE_ITEM_STATUS.APPROVED);
        });
    }

    render() {

        return <div className="adjust_po_complaints">
            {  this.props.complaints.valueSeq().map(function (complaint, index) {
                let complaintCode = complaint.get('code');
                return <Complaint key={complaintCode} seq={index + 1}
                                  complaint={complaint}
                                  notesText={emptyMapIfUndefined(this.props.notes).filter(notes => notes.get('complaintCode') === complaint.get('code'))}
                                  lineItems={this.getLineItemsByComplaintCode(complaintCode)}
                                  complaintCode={complaintCode}
                                  isLastComplaint={(this.props.complaints.count() - 1)  == index }
                                  hideCostInformation = {this.props.hideCostInformation}
                                  selectedCurrency={this.props.selectedCurrency}
                                  listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
                                  />
            }, this)}
        </div>
    }
}



export default Complaints;