import React from 'react';
import  ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import {ButtonGroup, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
import './FrequentlyUsedATACodes.css';
import classnames from 'classnames';
import Help from 'components/Help';

class FrequentlyUsedATACodes extends React.Component {

    getTooltip(toolTipText){
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>; 
    }
    getATACodes() {
        return this.props.frequentlyUsedATACodes.valueSeq().map((item)=>
            <OverlayTrigger placement = 'right' key={item.get('productCode')} overlay = {this.getTooltip(item.get('fullDescription'))} >
                <div key={item.get('productCode')}
                    role="button"
                    className={classnames('frequently-used-ata-codes__ata-code',
                        item.get('isSelected')?'frequently-used-ata-codes__ata-code_selected':'frequently-used-ata-codes__ata-code')
                        }
                    onClick={this.props.onSelectATACode.bind(this,item.get('productCode'))}
                >
                    {item.get('description')}
                </div>
            </OverlayTrigger>
        )
    }

    render() {
        const messages = defineMessages({
            freq_used_ata_code: {
                id: 'freq_used_ata_code',
                description: 'Or choose from frequently entered parts:',
                defaultMessage: 'Or choose from frequently entered parts:'
            },
        });
        return (
            <div className="frequently-used-ata-codes">
                <FormattedMessage {...messages.freq_used_ata_code} />
                <Help videoId="search_tips"/>
                {this.getATACodes()}                
            </div>
        );
    }
}
;

export default FrequentlyUsedATACodes;

