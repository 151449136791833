import { Map, fromJS } from 'immutable';
import * as constants from 'constants/App';

export function savePassword(ariId,password,newPassword) {

    return {type: constants.SAVE_PASSWORD, ariId,password,newPassword}
}

export function updateAriIDErrorMessage(errorMessage){
    return {type:constants.UPDATE_ARI_ID_ERROR_MESSAGE,errorMessage}
}

export function updateConfirmNewErrorMessage(errorMessage){
    return {type:constants.UPDATE_CONFIRM_PWD_ERROR_MESSAGE,errorMessage}
}
export function updateNewPwdErrorMessage(errorMessage){
    return {type:constants.UPDATE_NEW_PWD_ERROR_MESSAGE,errorMessage}
}
export function showPasswordSettings(){
    return {type:constants.SHOW_PASSWORD_SETTINGS}
}

export function changePasswordClick(){
    return {type:"CHANGE_PASSWORD_CLICKED"}
}

export function resetErrorMessages(){
    return {type:constants.RESET_ERROR_MESSAGES}
}

export function hidePasswordSettings(){
    return {type:constants.HIDE_PASSWORD_SETTINGS}
}

export function updatePasswordDetailstoStore(ariId, currentPassword, newPassword){

    return {type:constants.UPDATE_PASSWORD_TO_STORE,ariId, currentPassword,newPassword}
}

export function showAriIDErrorMessage(flag){
    return {type:constants.SHOW_ARI_ID_ERROR_MESSAGE,flag}
}

export function showCurrentPasswordErrorMessage(flag){
    return {type:constants.SHOW_CURRENT_PWD_ERROR_MESSAGE,flag}
}

export function showNewPasswordErrorMessage(flag){
    return {type:constants.SHOW_NEW_PWD_ERROR_MESSAGE,flag}
}
export function showConfirmNewErrorMessage(flag){
    return {type:constants.SHOW_CONFIRM_NEW_PWD_ERROR_MESSAGE,flag}
}

export function clearPasswordSettings(){
    return {type:constants.CLEAR_PASSWORD_SETTINGS}
}

export function showContactSettings(){
    return {type:constants.SHOW_CONTACT_SETTINGS}
}

export function hideContactSettings(){
    return {type:constants.HIDE_CONTACT_SETTINGS}
}

export function updateContactDetailsToStore(vendorName,vendorBusinessNumber, vendorExtension,vendorFaxNumber, vendorStreetAddress1,vendorCityAddress, vendorStateAddress,vendorZipAddress,vendorEmailRecipient,vendorId, vendorCountry){    
    return {type:constants.UPDATE_VENDOR_DETAILS,vendorName,vendorBusinessNumber,vendorExtension,vendorFaxNumber,vendorStreetAddress1,vendorCityAddress,vendorStateAddress,vendorZipAddress,vendorEmailRecipient,vendorId, vendorCountry}
}

export function showVendorNameErrorMessage(flag){
    return {type:constants.SHOW_VENDOR_NAME_ERROR,flag}
}

export function showVendorBusinessNumberErrorMessage(flag){
    return {type:constants.SHOW_VENDOR_BUSINESS_NUMBER_ERROR,flag}
}
export function showVendorBusinessExtnErrorMessage(flag){
    return {type:constants.SHOW_BUSINESS_EXTN_ERROR,flag}
}

export function showVendorFaxNumberErrorMessage(flag){
    return {type:constants.SHOW_VENDOR_FAX_NUMBER_ERROR,flag}
}

export function showVendorStreetAddress1ErrorMessage(flag){
    return {type:constants.SHOW_VENDOR_STREET_ADDRESS1_ERROR,flag}
}

export function showVendorCityAddressErrorMessage(flag){
    return {type:constants.SHOW_VENDOR_CITY_ADDRESS_ERROR,flag}
}

export function showVendorZipAddressErrorMessage(flag){
    return {type:constants.SHOW_VENDOR_ZIP_ADDRESS_ERROR,flag}
}

export function clearVendorInfoSettings(flag){
    return {type:constants.CLEAR_CONTACT_SETTINGS,flag}
}

export function showAccountVerification(){
    return {type:constants.SHOW_ACCOUNT_VALIDATION}
}

export function showPaymentEmailVerification(){
    return {type:constants.SHOW_PAYMENT_EMAIL_VALIDATION}
}

export function showVendorEmailErrorMessage(flag){
    return {type:constants.SHOW_VENDOR_EMAIL_ERROR,flag}
}

export function clearAccountSettings(){
    return {type:constants.CLEAR_ACCOUNT_INFORMATION}
}

export function showAccountEdit(){
    return {type:constants.SHOW_ACCOUNT_EDIT}
}


export function showPopOverARIId(popOverDisplayShowARIId) {
    return {type: constants.POP_OVER_DISPLAY_SHOW_ARI_ID, popOverDisplayShowARIId}

}

export function showPopOverVendorPersonal(popOverDisplayShowVendorPersonal) {
    return {type: constants.POP_OVER_DISPLAY_SHOW_PERSONAL, popOverDisplayShowVendorPersonal}

}

export function saveARIId(ARIId) {
    return {type: constants.SAVE_ACCOUNT_INFORMATION_ID, ARIId}

}

export function savePersonal(personal) {
    return {type: constants.SAVE_ACCOUNT_INFORMATION_PERSONAL, personal}

}

export function saveIdError(idError) {
    return {type: constants.ERROR_ACCOUNT_INFORMATION_ID, idError}

}

export function savePersonalError(personalError) {
    return {type: constants.ERROR_ACCOUNT_INFORMATION_PERSONAL,personalError}

}

export function agreedToTermsAndConditions(submission) {
    return {type: constants.AGREE_TERMS_AND_CONDITIONS,submission}
}

export function saveChecking1Error(checking1Error) {
    return {type: constants.ERROR_CHECKING_ACCOUNT_INFORMATION, checking1Error}

}

export function saveChecking2Error(checking2Error) {
    return {type: constants.ERROR_SECOND_CHECKING_ACCOUNT,checking2Error}

}

export function saveRoutingNumberError(routingNumberError) {
    return {type: constants.ERROR_ROUTING_NUMBER,routingNumberError}

}

export function showPopOverChecking1(popOverDisplayShowChecking1) {
    return {type: constants.POP_OVER_DISPLAY_SHOW_CHECKING1, popOverDisplayShowChecking1}

}

export function showPopOverChecking2(popOverDisplayShowChecking2) {
    return {type: constants.POP_OVER_DISPLAY_SHOW_CHECKING2, popOverDisplayShowChecking2}

}

export function showPopOverRoutingNumber(popOverDisplayShowRoutingNumber) {
    return {type: constants.POP_OVER_DISPLAY_SHOW_ROUTING_NUMBER, popOverDisplayShowRoutingNumber}

}

export function saveChecking1(vendorAccountChecking1) {
    return {type: constants.SAVE_ACCOUNT_CHECKING1, vendorAccountChecking1}

}
export function saveChecking2(vendorAccountChecking2) {
    return {type: constants.SAVE_ACCOUNT_CHECKING2, vendorAccountChecking2}

}
export function saveRoutingNumber(vendorAccountRoutingNumber) {
    return {type: constants.SAVE_ACCOUNT_ROUTING_NUMBER, vendorAccountRoutingNumber}

}

export function updateBankingAccountDetailsToStore(vendorRoutingNumber, vendorChecking1, vendorChecking2){

    return {type:constants.UPDATE_VENDOR_BANKING_ACCOUNT_DETAILS,vendorRoutingNumber, vendorChecking1,vendorChecking2}
}

export function updatePaymentEmailAddressToStore(paymentEmail){    
    return {type:constants.UPDATE_VENDOR_PAYMENT_EMAIL_DETAILS,paymentEmail}
}



export function showTermsAndConditionsErrorMessage(termsErrorMessage){
    return {type:constants.SHOW_TERMS_AND_CONDITION_ERROR,termsErrorMessage}
}

export function showPopOverTerms(popOverDisplayShowTerms) {
    return {type: constants.POP_OVER_DISPLAY_SHOW_TERMS, popOverDisplayShowTerms}

}

export function showVendorServicesEdit(){
    return {type:constants.SHOW_VENDOR_SERVICES_EDIT}
}

export function hideVendorServicesEdit(){
    return {type:constants.HIDE_VENDOR_SERVICES_EDIT}
}

export function saveVendorServiceSelection(servicesSelected,serviceKey) {
    return {type: constants.VENDOR_SERVICE_SELECTED, servicesSelected,serviceKey}

}

export function saveVendorMinoritySelection(minoritySelected,minorityKey) {
    return {type: constants.VENDOR_MINORITY_SELECTED, minoritySelected,minorityKey}

}

export function verifyVendorInformation(tax,taxType){
    return {type:constants.FETCH_VENDOR_VERIFY_INFORMATION,tax,taxType}

}
export function clearAccountErrors() {
    return {type:constants.CLEAR_ACCOUNT_INFORMATION_ERROR}
    
}