import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Link } from 'react-router-dom';
const AccidentAssignmentsText = styled.div `
    color:#337ab7;
    font-size: 16px;
    text-align: center;
    padding-right: .7em;
`;
const AccidentAssignmentsChild = styled.div `
    position: relative;
    font-size: 15px;
    font-family: "Gotham SSm A","Gotham SSm B";
    font-style: normal;
    font-weight: 800;
    color: white;
    padding-top: .25rem;
    margin-left: 5.5rem;
    margin-bottom: -2.6rem;
`;
const AccidentAssignments = styled.div `
    height: 120px;
    width: auto;
    padding-bottom: 10em;
    padding-left: 1em;
    cursor: pointer;
`;
const AccidentAssignmentStatistics = ({ pendingRepairEstimateCount }) => {
    const messages = defineMessages({
        repair_estimate_link: {
            id: 'repair_estimate_link',
            description: 'Text for navigating to accident assigments',
            defaultMessage: 'View all open Accident POs'
        }
    });
    return (React.createElement(AccidentAssignments, null,
        React.createElement(Link, { id: "accident_assignment_btn", to: "/AccidentManagement/AccidentAssignments" },
            React.createElement("div", { className: "justify-content-center" },
                React.createElement(AccidentAssignmentsChild, null, pendingRepairEstimateCount),
                React.createElement("img", { src: "/assets/AccidentAssignmentIcon.png", style: { width: "95px", height: "80px", marginLeft: "-1rem" } })),
            React.createElement("div", null,
                React.createElement(AccidentAssignmentsText, null,
                    React.createElement(FormattedMessage, Object.assign({}, messages.repair_estimate_link)))))));
};
export default (AccidentAssignmentStatistics);
