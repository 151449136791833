import React from 'react';
import ReactDOM from 'react-dom'
const scrollIntoView = require('dom-scroll-into-view')
import { defineMessages, FormattedMessage } from 'react-intl';
import { Popover } from 'react-bootstrap';
import moment from 'moment';

import './BitInspectionForm.css';
import * as constants from 'constants/App';
import CheckCircle from 'components/CheckCircle';
import VehicleComponents from './VehicleComponents';
import Spinner from 'components/Spinner';

class BitInspectionForm extends React.Component {		  	
	handleVehicleTypeClick = (name, checked) => {
		if (!checked) {
			this.props.setFormData('vehicleType', name);
		}		
	}

	handleInspectorQualifiedClick = () => {
		this.props.setFormData('inspectorQualified', !this.props.formData.get('inspectorQualified'));
	}	
	
    handleChange = (event) => {
        event.preventDefault();
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);		
	}
	
	handleBlur = (event) => {
        event.preventDefault();
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);

		if (event.currentTarget.id === 'inspectorName' && !this.props.formData.get('inspectorSignature')) {
			this.props.setFormData('inspectorSignature', event.currentTarget.value);
		}
    }

	render() {
		const messages = defineMessages({			
			bit_inspection_form__title: {
				id: 'bit_inspection_form__title',
				description: 'BIT inspection form title',
				defaultMessage: 'BIT inspection form'
			},
			bit_inspection__truck: {
                id: 'bit_inspection__truck',
                description: 'BIT inspection title truck',
                defaultMessage: 'truck'
			},
			bit_inspection__bus: {
                id: 'bit_inspection__bus',
                description: 'BIT inspection title bus',
                defaultMessage: 'bus'
			},
			bit_inspection__trailer: {
                id: 'bit_inspection__trailer',
                description: 'BIT inspection title trailer',
                defaultMessage: 'trailer'
			},		
			bit_inspection_form__carrier_name: {
				id: 'bit_inspection_form__carrier_name',
				description: 'BIT inspection form Carrier name',
				defaultMessage: 'Carrier name'
			},
			bit_inspection_form__unit_number: {
				id: 'bit_inspection_form__unit_number',
				description: 'BIT inspection form unit number',
				defaultMessage: 'Unit number'
			},
			bit_inspection_form__year: {
				id: 'bit_inspection_form__year',
				description: 'BIT inspection form year',
				defaultMessage: 'Year'
			},
			bit_inspection_form__make: {
				id: 'bit_inspection_form__make',
				description: 'BIT inspection form make',
				defaultMessage: 'Make'
			},
			bit_inspection_form__license_plate: {
				id: 'bit_inspection_form__license_plate',
				description: 'BIT inspection form License plate',
				defaultMessage: 'License plate'
			},
			bit_inspection_form__date: {
				id: 'bit_inspection_form__date',
				description: 'BIT inspection form Date',
				defaultMessage: 'Date'
			},
			bit_inspection_form__save: {
                id: 'bit_inspection_form__save',
                description: 'BIT inspection form save and continue button',
                defaultMessage: 'Save and continue'
			},	
			bit_inspection_form__mileage: {
				id: 'bit_inspection_form__mileage',
				description: 'BIT inspection form Mileage',
				defaultMessage: 'Mileage'
			},	
		});

		return (
			<div className='bit_inspection_form'>
				
				{/*Title*/}

				<div className='bit_inspection_form__title'>                
					<FormattedMessage {...messages.bit_inspection_form__title} />
					{' - '}
					{ this.props.assetType === 'trailer' ?
						<FormattedMessage {...messages.bit_inspection__trailer} />
					: this.props.assetType === 'bus' ?
						<FormattedMessage {...messages.bit_inspection__bus} />
					: 
						<FormattedMessage {...messages.bit_inspection__truck} />
					}
				</div>				

				<div className='bit_inspection_form__outer_box'>
				
					{/*Header*/}

					<div className='row bit_inspection_form__header_row'>
									
						<div className='form-group col-md-4 bit_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.bit_inspection_form__carrier_name} />
							</label>
							<div>{this.props.formData.get('carrierName')}</div>
						</div>

						<div className='form-group col-md-4 bit_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.bit_inspection_form__unit_number} />
							</label>
							<div>{this.props.formData.get('unitNumber')}</div>
						</div>

						<div className='form-group col-md-4 bit_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.bit_inspection_form__year} />
							</label>
							<div>{this.props.formData.get('year')}</div>
						</div>

						<div className='form-group col-md-4 bit_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.bit_inspection_form__make} />
							</label>
							<div>{this.props.formData.get('make')}</div>
						</div>

						<div className='form-group col-md-4 bit_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.bit_inspection_form__license_plate} />
							</label>
							<div>{this.props.formData.get('licensePlate')}</div>
						</div>																	

						<div className='form-group col-md-4 bit_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.bit_inspection_form__date} />
							</label>
							<div>{moment(this.props.formData.get('inspectionDate')).format("MM/DD/YYYY")}</div>
						</div>
						<div className='form-group col-md-4 bit_inspection_form__item'>
							<label className='control-label'>
								<FormattedMessage {...messages.bit_inspection_form__mileage} />
							</label>
							<div>{this.props.formData.get('mileage')}</div>
						</div>
					</div>				

					<VehicleComponents 
						formData={this.props.formData}
						setFormData={this.props.setFormData}
						formValidationMessages={this.props.formValidationMessages}  
						componentDetails={this.props.componentDetails}
						assetType={this.props.assetType}
						userLocale={this.props.userLocale}
						poCreationDate={this.props.poCreationDate}
						onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}						
					/>				

				</div>

				{/*Footer*/}

				<div className='bit_inspection_form__footer'>        
					<div className='row'>					

						<div className='offset-sm-6 col-sm-6 bit_inspection_form__no_gutter_col'>
							<div className='bit_inspection_form__save' onClick={this.props.onFormSaveClick}>
								<FormattedMessage {...messages.bit_inspection_form__save}/>{' '}
								<img src='/assets/VMS_33button_add_blue.png' />
								<div className='bit_inspection_form__spinner'>
									{this.props.isSaving &&
										<Spinner spinnerType='blue' />										
									}
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default BitInspectionForm;