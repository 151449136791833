/**
 * Created by prboyapa on 2/25/2016.
 */
import React from 'react';
import './Comments.css';

class Comments extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditComment: false
        }
  }

    onServiceDescriptionSaveHandler = (event) => {
        event.preventDefault();
        /*Hide the text area */
        this.setState({
            isEditComment: false
        });

        /*Save the comments to the app state by complaint code*/
        this.props.onServiceDescriptionSave(this.refs.refServiceDescription.value,
                                            this.props.complaintCode);
    }

    onEditServiceDescriptionHandler = (event) => {
        /*Show the text area */
        this.setState({
            isEditComment: true
        });
    }

    render() {
        return <div>
            {
                this.state.isEditComment == true ?
                    <div className="row comments__description">
                        <div className="col-md-9">
                                <textarea className="comments_description__textarea" ref="refServiceDescription"
                                          defaultValue={this.props.serviceDescription}
                                          maxLength="1000" />
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-12">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <a onClick={this.onServiceDescriptionSaveHandler.bind(this)}>Save
                                        changes</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="row comments__description
                                comments__description_readOnly">
                    <div className="col-md-12">
                        {
                            this.props.serviceDescription.length > 0 ?
                                this.props.serviceDescription.split("\n").map((line, index) => {
                                    return <div key={index}>{line}</div>;
                                })
                                : undefined
                        }
                        <a onClick={this.onEditServiceDescriptionHandler.bind(this)}>Edit complaint</a>
                    </div>
                </div>
            }
        </div>
    }

}


export default Comments;