import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { LINE_ITEM_STATUS, LINE_ITEM_TYPE, INVOICE_FIELD_TYPE,PAYMENT_TYPE } from 'constants/App';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, zeroIfUndefined } from 'utils/HelperFunctions';
import moment from 'moment';
import * as helperFunctions from 'utils/HelperFunctions';
import *  as fieldValidations from 'utils/FieldValidations';


const isfetchingDataSelector = state => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'isFetchingData']));
}

const userLocaleSelector = state => state.appState.getIn(['uiData', 'shared', 'selectedLocale']);

const clientDetailsSelector = (state) => {
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'client']));
};

const vendorDetailsSelector = (state) => {
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'vendor']));
};

const approvedAmountTotalSelector = (state) => {
    let approvedAmount = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'authorizedAmount']);
    return approvedAmount == undefined ? 0 : approvedAmount;
};

const pendingAmountTotalSelector = (state) => {
    let pendingAmount = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'pendingAmount']);
    return pendingAmount == undefined ? 0 : pendingAmount;
};

const authorizedStatusSelector = (state, props) => {
    return emptyStringIfUndefined(state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'authorizationStatus']));
};

const paymentStatusSelector = (state, props) => {
    return emptyStringIfUndefined(state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'paymentStatus']));
};

const complaintsSelector = (state) => {
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'complaints']));
};

const lineItemsSelector = (state) => {
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'lineItems']));
};

const vehicleDetailsSelector = (state) => {
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'vehicleDetails']));
}

const vehicleDetailsForOdometerWarningsSelector = (state) => {
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'vehicleDetailsToShowOdometerWarnings']));
}

const invoiceDetailsTaxSelector = (state) => {

    return emptyMapIfUndefined(state.appState.getIn(['uiData', 'close', 'invoiceDetails']));

};


const selectedCountry = (state) => {

    return emptyMapIfUndefined(state.appState.getIn(['uiData', 'shared', 'selectedCountry']));

};

const invoiceDetailsUIData = state => {
    let invoice = emptyMapIfUndefined(state.appState.getIn(['uiData', 'close', 'invoiceDetails']));

    let vendor = emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'vendor']));
    let existedTaxAmount = invoice.get('taxAmount') > 0 ? invoice.get('taxAmount') :
        state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'taxAmount']);
    let poTotal = approvedAmountTotalSelector(state) +
        pendingAmountTotalSelector(state) +
        (invoice.get('isTaxExempted') == true
            ? 0.00 : vendor.get('country') == 'CAN' ? canadianTaxTotalAmount(state) : Number(existedTaxAmount));

    return invoice.set('canShowMultipleTaxTypes', vendor.get('country') == 'CAN');
}

const uiTaxes = (state) => {
    return  emptyMapIfUndefined(state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'taxes']));
};

const uiTaxAmount = (state) => {
  return   zeroIfUndefined(state.appState.getIn(['uiData', 'close', 'invoiceDetails','taxAmount']));
};


const poCreatedDateSelector = (state) => {
    return state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'createdDate']);
}

function isValidOdometer(poOdometer, poEngineHours){
    let odometer = poOdometer==undefined || poOdometer.length==0 ? 0 : poOdometer
    let engineHours = poEngineHours==undefined || poEngineHours.length==0 ? 0 : poEngineHours

    return !(odometer===0 && engineHours===0)
}

/*
•	There is no change for the Intellipay vendor.  They would be able to close the PO using partner connect site
•	If a vendor is a Standard payment vendor
    o	    If the vendor id starts with 0 ( Zero)
    	Do not show the radio button for confirming the account information because these vendors will be always paid by check
 
    o	If the vendor id starts with 5 or any digit 
    	if the vendor default payment method is by check , do not show the radio button for confirming the account information 
    	if the vendor default payment method is by ACH , then show the radio button for confirming the account information 

 */
const showBankAccountRadioButtonSelector = state => {   
    let vendor = emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'vendor']));
    
    (vendor.get('paymentType')=="STANDARD" && vendor.get('id').startsWith('0')) ||
    (vendor.get('paymentType')=="STANDARD" && !vendor.get('id').startsWith('0') && vendor.get('paymentMethod')=="CHECK") ?
    false: vendor.get('bankAccountNumber') != undefined && vendor.get('bankAccountNumber')!=''? true:false;
}

const showInvoiceRadioButtonSelector = state => {    
    let vendor = emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'vendor']));
    vendor.get('paymentType')=='STANDARD'  && (vendor.get('paymentMethod')=='CHECK'|| vendor.get('paymentMethod')=='ACCOUNT')?
    true:false;
}

const validationDetails = state => {
    
    let fieldValidationsData = emptyMapIfUndefined(state.appState.getIn(['uiData', 'shared', 'fieldValidations']));
    const isWorkCompletionFeatureEnabled= state.appState.getIn(['uiData', 'shared', 'features','workCompletion']);



    let invoiceData = emptyMapIfUndefined(state.appState.getIn(['uiData', 'close', 'invoiceDetails']));
    let vendor = emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'vendor']));
    let existedTaxAmount = invoiceData.get('taxAmount') > 0 ? invoiceData.get('taxAmount') :
        state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'taxAmount']);
    let  serverVendorReferenceNo = state.appState.getIn(['serverData', 'close', 'vendorReferenceNo']);
    const paymentType = state.appState.getIn(['serverData', 'shared', 'paymentType']);
    let applicableTaxType = state.appState.getIn(['serverData', 'close','applicableTaxTypes']);
    var applicationTaxType =applicableTaxType !== undefined ? applicableTaxType.map((tax)=> {return tax.toArray().toString().substring(0,3)}).toArray().toString() : "";

    //If work completion feature is enabled, then do not display for bank account information
    //If vendor is STANDARD vendor and vendor id starts with '0' or payment method is 'CHECK', then do not display bank account info
    let showBankAccount=
    (vendor.get('paymentType')=="STANDARD" && vendor.get('id').startsWith('0')) ||
    (vendor.get('paymentType')=="STANDARD" && !vendor.get('id').startsWith('0') && vendor.get('paymentMethod')=="CHECK") ||
    isWorkCompletionFeatureEnabled ?
    false: vendor.get('bankAccountNumber') != undefined && vendor.get('bankAccountNumber')!=''? true:false;

    let showInvoice=vendor.get('paymentType')=='STANDARD'  && (vendor.get('paymentMethod')=='CHECK'|| vendor.get('paymentMethod')=='ACCOUNT')?
    true:false;
    let uiTaxes = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'taxes']);

    let referenceNumber = invoiceData.get('refNumber') != undefined ?invoiceData.get('refNumber') : serverVendorReferenceNo;
    let applicableTaxError = false;

    if(uiTaxes.get("HST") > 0 && !applicationTaxType.includes("HST")){
        applicableTaxError= true;
    }
    else if(uiTaxes.get("QST") > 0 &&  !applicationTaxType.includes("QST")){
        applicableTaxError= true; }
    else if(uiTaxes.get("PST") > 0 &&  !applicationTaxType.includes("PST")){
        applicableTaxError= true;
    }else if(uiTaxes.get("GST") > 0 &&  !applicationTaxType.includes("GST")){
        applicableTaxError= true;
    }else{
        applicableTaxError= false;
    }


    let validationDetails = Map({
        invoiceNo: fieldValidations.getValidationErrorMessage(fieldValidationsData, 'INVOICE_NO', invoiceData.get('invoiceNo') === undefined || invoiceData.get('invoiceNo') === null ? '' : invoiceData.get('invoiceNo')) === null,
        invoiceDate:  fieldValidations.getValidationErrorMessage(fieldValidationsData, 'INVOICE_DATE', invoiceData.get('invoiceDate') === undefined || invoiceData.get('invoiceDate') === null ? '' : invoiceData.get('invoiceDate')) === null,
        submittedBy: fieldValidations.getValidationErrorMessage(fieldValidationsData, 'YOUR_NAME', invoiceData.get('submittedBy') === undefined || invoiceData.get('submittedBy') === null ? '' : invoiceData.get('submittedBy')) === null,
        repairCompletionDate:  fieldValidations.getValidationErrorMessage(fieldValidationsData, 'REPAIR_COMPLETION_DATE', invoiceData.get('repairCompletionDate') === undefined || invoiceData.get('repairCompletionDate') === null ? '' : invoiceData.get('repairCompletionDate')) === null ,
        repairStartDate:  fieldValidations.getValidationErrorMessage(fieldValidationsData, 'REPAIR_START_DATE', invoiceData.get('repairStartDate') === undefined || invoiceData.get('repairStartDate') === null ? '' : invoiceData.get('repairStartDate')) === null ,
        odometer: ( invoiceData.get('engineHours') === undefined ? 0 :  invoiceData.get('engineHours')) > 0 ? true: 
               ( fieldValidations.getValidationErrorMessage(fieldValidationsData, 'ODOMETER', invoiceData.get('odometer') === undefined || invoiceData.get('odometer') === null ? '' : invoiceData.get('odometer')) === null),
        taxAmount: invoiceData.get('isTaxExempted') == true ? true : 
                                        existedTaxAmount > 0 && existedTaxAmount <= invoiceData.get("subTotal") * .15,
        exemptedReason: invoiceData.get('isTaxExempted') == true ? invoiceData.get('exemptedReason').length > 0 : true,
        isPaymentCreditToAccount:showBankAccount ? invoiceData.get('isPaymentCreditToAccount') : true,
        isWorkCompleted: fieldValidations.getValidationErrorMessage(fieldValidationsData, 'CONFIRM_WORK_IS_COMPLETE', invoiceData.get('isWorkCompleted') === undefined || invoiceData.get('isWorkCompleted') === null ? false : invoiceData.get('isWorkCompleted')) === null,
        canShowMultipleTaxTypes: vendor.get('country') != undefined && vendor.get('country') != '' && vendor.get('country') == 'CAN',
        canadianTaxes: invoiceData.get('isTaxExempted') == false && canadianTaxTotalAmount(state) > 0  ? (canadianTaxTotalAmount(state) > 0 && !applicableTaxError) : false,
        isInvoiceReproducible: showInvoice? invoiceData.get('isInvoiceReproducible'):true,
        refNumber:fieldValidations.getValidationErrorMessage(fieldValidationsData, 'REFERENCE_NO', referenceNumber === undefined || referenceNumber === null ? '' : referenceNumber) === null
    });
    
    validationDetails = validationDetails.set("isFormValid",
        validationDetails.get("invoiceNo") &&
        validationDetails.get("invoiceDate") &&
        validationDetails.get("submittedBy") &&
        validationDetails.get("repairCompletionDate") &&
        validationDetails.get("repairStartDate") &&
        validationDetails.get("odometer") &&
        validationDetails.get("exemptedReason") &&         
         validationDetails.get("isPaymentCreditToAccount") &&
        validationDetails.get("isWorkCompleted") &&        
         validationDetails.get("isInvoiceReproducible") &&         
         (!fieldValidations.isRequired(fieldValidationsData,'TAX')? true:  (invoiceData.get('isTaxExempted') == false && validationDetails.get("canShowMultipleTaxTypes")
            ? validationDetails.get("canadianTaxes")
            : validationDetails.get("taxAmount"))) &&
        validationDetails.get("refNumber")
    );
   
    return validationDetails;
}

const canadianTaxTotalAmount = createSelector(uiTaxes,uiTaxAmount,
    (taxes,uiTaxAmount) => {

         if (taxes.size > 0) {
            const hstState = (taxes.get('HST') != undefined ? Number(taxes.get('HST')) : 0.00);
            const gstState = (taxes.get('GST') != undefined ? Number(taxes.get('GST')) : 0.00);
            const pstState = (taxes.get('PST') != undefined ? Number(taxes.get('PST')) : 0.00);
            const qstState = (taxes.get('QST') != undefined ? Number(taxes.get('QST')) : 0.00);
            const taxTotalAmt = helperFunctions.addFloats(hstState, gstState, pstState, qstState);

            return taxTotalAmt;
        }
        else {

            return uiTaxAmount;
        }
});

const setPopOverDisplayShowInvoiceNo = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowInvoiceNo']));
}

const setPopOverDisplayShowInvoiceDate = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowInvoiceDate']));
}

const setPopOverDisplayShowInvoiceName = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowInvoiceName']));
}

const setPopOverDisplayShowRepairCompleteDate = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowRepairCompleteDate']));
}

const setPopOverDisplayShowRepairStartDate = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowRepairStartDate']));
}

const setPopOverDisplayShowOdometer = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowOdometer']));
}

const setPopOverDisplayShowEngineHours = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowEngineHours']));
}

const setPopOverDisplayShowPaymentCredit = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowPaymentCredit']));
}
const setPopOverDisplayShowWorkComplete = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowWorkComplete']));
}

const setPopOverDisplayShowStandard = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowStandard']));
}



const setPopOverDisplayShowTax = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowTax']));
}

const setPopOverDisplayShowTaxCanada = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayedShowTaxCanada']));
}

const setPopOverDisplayShowRefNumber = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayShowRefNumber']));
}


//TODO: Cleanup the code -- This setOn constants need to be removed once we implement for canada close PO
const setOnTotalAmount = createSelector(approvedAmountTotalSelector, pendingAmountTotalSelector, canadianTaxTotalAmount, invoiceDetailsUIData, invoiceDetailsTaxSelector, uiTaxAmount,
    (approvedAmount, pendingAmount, canadaAmount, invoiceDetails, invoice, pendingTax) => {
        let totalAmount = 0;
        var taxAmount = 0.00;

        if (invoice.get('isTaxExempted')) {
            return totalAmount = approvedAmount
                + pendingAmount;

        } else {

            if (invoiceDetails.get('canShowMultipleTaxTypes') == false)
                taxAmount = Number(invoice.get('taxAmount')) != undefined ? Number(invoice.get('taxAmount')) : Number(pendingTax);
            else {
                taxAmount = Number(canadaAmount);
            }

            return totalAmount = approvedAmount
                + pendingAmount
                + Number(taxAmount);

        }

    });

const setOnTaxAmount = createSelector(canadianTaxTotalAmount, invoiceDetailsUIData, invoiceDetailsTaxSelector,
    (canadaAmount, invoiceDetails, invoice) => {

        if (invoiceDetails.get('canShowMultipleTaxTypes') == false) {
            return  Number(invoice.get('taxAmount')) != undefined ? Number(invoice.get('taxAmount')) : 0.00;
        } else {
            return Number(canadaAmount);

        }

    });

const calculateSubTotal = createSelector(approvedAmountTotalSelector, pendingAmountTotalSelector, (approvedTotals, pendingTotals) => {

    return approvedTotals + pendingTotals;


});

const calculateTaxTotal = createSelector(invoiceDetailsTaxSelector, canadianTaxTotalAmount, invoiceDetailsUIData, uiTaxAmount,selectedCountry,
    (invoice, canadaAmount, invoiceDetails, uiTax,country) => {

   if(country === "USA" && !invoice.get('isTaxExempted')){
       return Number(uiTax);
   }else if(country === "CAN" && !invoice.get('isTaxExempted')){
       return Number(canadaAmount);
   }else{
       return 0.00;
   }

    });


const closePurchaseOrderErrorMessageSelector = state => {
    return state.appState.getIn(['uiData', 'close', 'errorMessage']);
};

const showOdometerWarningSelector = (state) => {
    const show = state.appState.getIn(['uiData', 'close', 'showOdometerWarning']);
    return show !=undefined ? show:false;
}

const showEngineHoursWarningSelector = (state) => {
    const show = state.appState.getIn(['uiData', 'close', 'showEngineHoursWarning']);
    return show !=undefined ? show:false;
}

const setPopOverDisplayShowTaxExe = (state) => {
    return falseIfUndefined(state.appState.getIn(['uiData', 'close', 'popOver', 'isPopOverDisplayShowTaxExe']));
}

const applicableTaxTypes = (state) => {
    return emptyMapIfUndefined(state.appState.getIn(['serverData', 'close','applicableTaxTypes']));
}

const ClosePOSelector = createSelector(
    isfetchingDataSelector,
    complaintsSelector,
    lineItemsSelector,
    vehicleDetailsSelector,
    invoiceDetailsUIData,
    authorizedStatusSelector,
    paymentStatusSelector,
    clientDetailsSelector,
    approvedAmountTotalSelector,
    pendingAmountTotalSelector,
    userLocaleSelector,
    vendorDetailsSelector,
    validationDetails,
    poCreatedDateSelector,
    uiTaxAmount,
    setPopOverDisplayShowInvoiceNo,
    setPopOverDisplayShowInvoiceDate,
    setPopOverDisplayShowInvoiceName,
    setPopOverDisplayShowRepairCompleteDate,
    setPopOverDisplayShowRepairStartDate,
    setPopOverDisplayShowOdometer,
    setPopOverDisplayShowEngineHours,
    setPopOverDisplayShowPaymentCredit,
    setPopOverDisplayShowWorkComplete,
    setPopOverDisplayShowTax,
    setPopOverDisplayShowTaxCanada,
    setPopOverDisplayShowRefNumber,
    setOnTotalAmount,
    setOnTaxAmount,
    calculateSubTotal,
    calculateTaxTotal,
    closePurchaseOrderErrorMessageSelector,
    vehicleDetailsForOdometerWarningsSelector,
    showOdometerWarningSelector,
    showEngineHoursWarningSelector,
    setPopOverDisplayShowStandard,
    showBankAccountRadioButtonSelector,
    showInvoiceRadioButtonSelector,
    setPopOverDisplayShowTaxExe,
    applicableTaxTypes,
    uiTaxes,
    (isfetchingData,
     complaints,
     lineItems,
     vehicleDetails,
     invoiceDetails,
     authorizedStatus,
     paymentStatus,
     client,
     approvedAmountTotal,
     pendingAmountTotal,
     userLocale,
     vendor,
     validationDetails,
     poCreatedDate,
     lineItemsTax,
     popOverDisplayShowInvoiceNo,
     popOverDisplayShowInvoiceDate,
     popOverDisplayShowInvoiceName,
     popOverDisplayShowRepairCompleteDate,
     popOverDisplayShowRepairStartDate,
     popOverDisplayShowOdometer,
     popOverDisplayShowEngineHours,
     popOverDisplayShowPaymentCredit,
     popOverDisplayShowWorkComplete,
     popOverDisplayShowTax,
     popOverDisplayShowTaxCanada,
     popOverDisplayShowRefNumber,
     onTotalAmount,
     onTaxAmount,
     subTotal,
     taxTotal,
     closePurchaseOrderErrorMessage,
     vehicleDetailsForOdometerWarnings,
     showOdometerWarning,
     showEngineHoursWarning,
     popOverDisplayShowStandard,
     showBankAccountRadioButton,
     showInvoiceRadioButton,
     popOverDisplayShowTaxExe,
     applicableTaxTypes,
     uiTaxes) => {
        return {
            isfetchingData,
            complaints,
            lineItems,
            vehicleDetails,
            invoiceDetails,
            authorizedStatus,
            paymentStatus,
            client,
            approvedAmountTotal,
            pendingAmountTotal,
            userLocale,
            vendor,
            validationDetails,
            poCreatedDate,
            lineItemsTax,
            popOverDisplayShowInvoiceNo,
            popOverDisplayShowInvoiceDate,
            popOverDisplayShowInvoiceName,
            popOverDisplayShowRepairCompleteDate,
            popOverDisplayShowRepairStartDate,
            popOverDisplayShowOdometer,    
            popOverDisplayShowEngineHours,        
            popOverDisplayShowPaymentCredit,
            popOverDisplayShowWorkComplete,
            popOverDisplayShowTax,
            popOverDisplayShowTaxCanada,
            popOverDisplayShowRefNumber,
            onTotalAmount,
            onTaxAmount,
            subTotal,
            taxTotal,
            closePurchaseOrderErrorMessage,
            vehicleDetailsForOdometerWarnings,
            showOdometerWarning,
            showEngineHoursWarning,
            popOverDisplayShowStandard,
            showBankAccountRadioButton,
            showInvoiceRadioButton,
            popOverDisplayShowTaxExe,
            applicableTaxTypes,
            uiTaxes
        }
    });

export default ClosePOSelector;