import React from 'react'
import classNames from 'classnames';
import './PageLayout.css';

const PageLayout = ({ whiteBackground, children }) => {
    return (
        <div className={classNames('documents__layout',
                                  {'documents__layout--white_background': whiteBackground})}>
            {children}
        </div>
    )
}

export default PageLayout;