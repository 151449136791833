import React, { useRef } from 'react';
import ReactDOM from 'react-dom'
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import classnames from 'classnames';
import Submit from 'components/Submit';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';
import * as constants from 'constants/App';
import './BrakeAxleWithThickness.css';

const BrakeAxleWithThickness = (props) => {
    const leftBrakeRef = useRef();
    const rightBrakeRef = useRef();
    const minThicknessRef = useRef();
    const axleRef = useRef();

    const renderValidationOverlay = (fieldName, placement, ref) => {
        let message = props.validationErrors.get(fieldName);
        let showError = message != undefined && props.showValidationError;
        if (message != undefined)
            return <Overlay
                show={showError}
                target={ref.current}
                placement={placement != undefined ? placement : "right"}>
                <Popover id={"popOver" + fieldName} className="enrollment-details__error_message step1__popover">
                <RawIntlProvider value={props.intl}>
                    <FormattedMessage {...message} />
                    </RawIntlProvider>
                </Popover>
            </Overlay>
        else
            return false;
    }
        const leftBrakeId = props.axleNumber + constants.BRAKE_POSITIONS.LEFT;
        const rightBrakeId = props.axleNumber + constants.BRAKE_POSITIONS.RIGHT;
        const axleId = 'axle' + props.axleNumber;
        const minThicknessId = 'minThick'  + props.axleNumber;
        return (
            <div className='brake-axle-min-max-flex'
                ref={axleRef}>
                <div className='brake-axle-simple-left'>
                    <input className={'brakes-thickness'}
                                            maxLength = {'5'}

                        ref={leftBrakeRef}
                        type='text'
                        onChange={(event) => props.onChangeBrakeThickness(props.axleNumber, constants.BRAKE_POSITIONS.LEFT, event.target.value)}
                        value={props.leftBrake.measurement}
                        placeholder={''}
                        onKeyDown={helperFunctions.isNumberKey.bind(this)}
                    />
                    {renderValidationOverlay(leftBrakeId, 'left', leftBrakeRef)}
                </div>
                <div className='brake-axle-simple-right'>
                    <input className={'brakes-thickness'}
                        maxLength = {'5'}
                        ref={rightBrakeRef}
                        type='text'
                        onChange={(event) => props.onChangeBrakeThickness(props.axleNumber, constants.BRAKE_POSITIONS.RIGHT, event.target.value)}
                        value={props.rightBrake.measurement}
                        placeholder={''}
                        onKeyDown={helperFunctions.isNumberKey.bind(this)}
                    />
                    {renderValidationOverlay(rightBrakeId, 'right', rightBrakeRef)}
                </div>
                <div  className='brake-axle-min-max'>
                    <input className={'brakes-thickness'}
                        maxLength = {'5'}
                        ref={minThicknessRef}
                        type='text'
                        onChange={(event) => props.onChangeBrakeThickness(props.axleNumber, constants.BRAKE_POSITIONS.MIN_PAD_THICKNESS, event.target.value)}
                        value={props.minRotorThickness}
                        placeholder={''}
                        onKeyDown={helperFunctions.isNumberKey.bind(this)}
                    />
                    {renderValidationOverlay(minThicknessId, 'right', minThicknessRef)}                    
                </div>
                <div className='brake-axle-simple'>                                  
                    {props.defaultMeasurementUnit}
                </div>                
                {renderValidationOverlay(axleId, 'left', axleRef)}

            </div>
        )
}

export default injectIntl(BrakeAxleWithThickness);
