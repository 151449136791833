import React from 'react';
import * as Api from 'utils/Api';
import configuration from 'configuration';
const Bowser = require('bowser');

export const SystemInformation = () => {
  const [serverSettings, setServerSettings] = React.useState()
  const browser = React.useMemo(() => {
    const b = Bowser.getParser(window.navigator.userAgent);
    return b.getBrowser();    
  }, []);

  React.useEffect(
    () => {
      const f = async () => {
        const settings = await Api.fetchPartnerConnectServerSettings();
        setServerSettings(settings);
      }
      f()
    },
    []
  ); 

  const hardReload = () => {
    const url = new URL(window.location.href);
    //If serverversion doesn't exist, use datetime stamp
    const version = serverSettings.version ? serverSettings.version : Date.now().toString();
    url.searchParams.set('v', version.toString());
    
    //Replace history with new searchquery url
    history.replaceState(null, "", url);
    //Navigate to new url and reload cache
    window.location.href = url.toString();
  }

  return (
    <div css={`display: flex; flex-direction: column; align-items: flex-end; text-align: end; font-size: 1.1rem;`}>
      
      <div>Version: {configuration.version}</div>

      {!!configuration.version && !!serverSettings && !!serverSettings.version && configuration.version !== serverSettings.version &&
        <button onClick={()=>{hardReload()}}>Update</button>
      }

      <div>Browser: {browser?.name} {browser?.version}</div>

    </div>
  )
}