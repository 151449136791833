import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';

export function uiDataHelpFeedbackForm(state = initialAppState.getIn(['uiData','feedbackForm']), action) {
    switch (action.type) {
        case constants.SAVE_FEEDBACK_FORM:
            return state.merge(action.dataForm);
        case constants.SAVE_FEEDBACK_FORM_QUESTIONS_SELECTION:
            return state.merge(action.dataDrop);
        case constants.FETCH_DATA_FOR_FEEDBACK_FORM_REQUEST:
            return state.set('isSendingData', true)
                        .set('errorMessage',[]);
        case constants.SEND_CLICK_FEEDBACK_FORM_RESOLVED:
            return state.set('isSendingData', false);
        case constants.SEND_CLICK_FEEDBACK_FORM_REJECTED:
            return state.set('isSendingData', false)
                        .set('errorMessage',action.errorMessage);            
        case constants.SEND_FEEDBACK_FORM_APPROVED:
            return state.set('isApprovedData', true);
        case constants.SEND_FEEDBACK_FORM_MOUNT:
            return state.set('isApprovedData', false);
        default:
            return state;

    }
}

