import React from 'react';
import  {createRoot} from 'react-dom/client';
import  { BrowserRouter ,Route,Router  } from 'react-router-dom';
import App from '../screens/App/containers/App';
import ProviderWithRouter from './ProviderWithRouter';


const root = createRoot(document.getElementById('root'));
root.render(<div>
    <BrowserRouter>
        <ProviderWithRouter isDevelopment={false}>

            <Route path="/" component={App} />

        </ProviderWithRouter>

    </BrowserRouter>
</div>)
