import React from 'react';
import ReactDOM from 'react-dom'
const scrollIntoView = require('dom-scroll-into-view')
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import { Popover } from 'react-bootstrap';
import moment from 'moment';

import './DotInspectionForm.css';
import * as constants from 'constants/App';
import CheckCircle from 'components/CheckCircle';
import VehicleComponents from './VehicleComponents';
import Spinner from 'components/Spinner';

class DotInspectionForm extends React.Component {
	componentDidUpdate(prevProps, prevState) {		
		if (prevProps.formValidationMessages.count() === 0 && this.props.formValidationMessages.count() > 0) {

			if (!!this.props.formValidationMessages.get('inspectorName')) {
				this.inspectorNameRef.scrollIntoView(false);				
			} else if (!!this.props.formValidationMessages.get('inspectorQualified')) {
				this.inspectorQualifiedContainerRef.scrollIntoView(false);				
			}			
		}
	}		  
	
	handleVehicleTypeClick = (name, checked) => {
		if (!checked) {
			this.props.setFormData('vehicleType', name);
		}		
	}

	handleInspectorQualifiedClick = () => {
		this.props.setFormData('inspectorQualified', !this.props.formData.get('inspectorQualified'));
	}	
	
    handleChange = (event) => {
        event.preventDefault();
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);		
	}
	
	handleBlur = (event) => {
        event.preventDefault();
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);

		if (event.currentTarget.id === 'inspectorName' && !this.props.formData.get('inspectorSignature')) {
			this.props.setFormData('inspectorSignature', event.currentTarget.value);
		}
    }

	render() {
		const messages = defineMessages({			
			dot_inspection_form__title: {
				id: 'dot_inspection_form__title',
				description: 'DOT inspection form title',
				defaultMessage: 'DOT annual vehicle inspection form'
			},
			dot_inspection_form__errors: {
				id: 'dot_inspection_form__errors',
                description: 'DOT inspection form errors exist on form message.',
                defaultMessage: 'Please correct the issues below.'
			},
			dot_inspection_form__vehicle_history: {
                id: 'dot_inspection_form__vehicle_history',
                description: 'DOT inspection form vehicle history record.',
                defaultMessage: 'Vehicle history record'
			},
			dot_inspection_form__report_number: {
                id: 'dot_inspection_form__report_number',
                description: 'DOT inspection form report number label.',
                defaultMessage: 'Report number'
			},
			dot_inspection_form__fleet_unit_number: {
                id: 'dot_inspection_form__fleet_unit_number',
                description: 'DOT inspection form fleet unit number label.',
                defaultMessage: 'Fleet unit number'
			},
			dot_inspection_form__date: {
                id: 'dot_inspection_form__date',
                description: 'DOT inspection form date label.',
                defaultMessage: 'Date'
			},
			dot_inspection_form__motor_carrier: {
                id: 'dot_inspection_form__motor_carrier',
                description: 'DOT inspection form - Motor carrier operator.',
                defaultMessage: 'Motor carrier operator'
			},
			dot_inspection_form__inspector_name: {
                id: 'dot_inspection_form__inspector_name',
                description: "DOT inspection form - Inspector's name.",
                defaultMessage: "Inspector's name"
			},
			dot_inspection__inspector_name_required: {
				id: 'dot_inspection__inspector_name_required',
                description: "DOT inspection form - Inspector's name required.",
                defaultMessage: "Please enter inspector's name"
			},
			dot_inspection_form__motor_carrier_address: {
                id: 'dot_inspection_form__motor_carrier_address',
                description: 'DOT inspection form - Address, city, state, zip.',
                defaultMessage: 'Address, city, state, zip'
			},
			dot_inspection_form__inspector_qualified_statement: {
                id: 'dot_inspection_form__inspector_qualified_statement',
                description: 'DOT inspection form - inspector qualified statement.',
                defaultMessage: 'This inspector meets the qualification requirements in section 396.19.'
			},
			dot_inspection__inspector_qualified_required: {
				id: 'dot_inspection__inspector_qualified_required',
                description: 'DOT inspection form - inspector qualified required.',
                defaultMessage: 'Inspector must be qualified to mark inspection as pass'
			},
			dot_inspection_form__inspector_qualified_yes: {
                id: 'dot_inspection_form__inspector_qualified_yes',
                description: 'DOT inspection form - YES inspector qualified.',
                defaultMessage: 'YES'
			},
			dot_inspection_form__vehicle_identification: {
                id: 'dot_inspection_form__vehicle_identification',
                description: 'DOT inspection form - Vehicle identification.',
                defaultMessage: 'Vehicle identification'
			},
			dot_inspection_form__repairing_vendor: {
                id: 'dot_inspection_form__repairing_vendor',
                description: 'DOT inspection form - Repairing vendor.',
                defaultMessage: 'Repairing vendor'
			},
			dot_inspection_form__vehicle_type: {
                id: 'dot_inspection_form__vehicle_type',
                description: 'DOT inspection form - vehicle type',
                defaultMessage: 'Vehicle type'
			},			
			dot_inspection_form__tractor: {
                id: 'dot_inspection_form__tractor',
                description: 'DOT inspection form - tractor.',
                defaultMessage: 'Tractor'
			},
			dot_inspection_form__trailer: {
                id: 'dot_inspection_form__trailer',
                description: 'DOT inspection form - Trailer.',
                defaultMessage: 'Trailer'
			},
			dot_inspection_form__truck: {
                id: 'dot_inspection_form__truck',
                description: 'DOT inspection form - Truck.',
                defaultMessage: 'Truck'
			},
			dot_inspection_form__other: {
                id: 'dot_inspection_form__other',
                description: 'DOT inspection form - Other.',
                defaultMessage: 'Other'
			},
			dot_inspection_form__vendor_address: {
                id: 'dot_inspection_form__vendor_address',
                description: 'DOT inspection form - Vendor address, city, state, zip.',
                defaultMessage: 'Address, city, state, zip'
			},
			dot_inspection_form__save: {
                id: 'dot_inspection_form__save',
                description: 'DOT inspection form save and continue button',
                defaultMessage: 'Save and continue'
			},		
		});

		return (
			<div className='dot_inspection_form'>
				
				{/*Title*/}

				<div className='dot_inspection_form__title'>                
					<FormattedMessage {...messages.dot_inspection_form__title} />				
				</div>				

				{/*Vehicle history*/}

				<div className='row dot_inspection_form__vehicle_history'>
					<div className='offset-sm-7 col-sm-5 dot_inspection_form__no_gutter_col'>

						<div className='dot_inspection_form__vehicle_history_title'>
							<FormattedMessage {...messages.dot_inspection_form__vehicle_history} />
						</div>

						<table className='dot_inspection_form__vehicle_history_details'>
							<tbody>
								<tr>
									<td className='dot_inspection_form__cell' style={{width:'45%'}}>
										<div className='dot_inspection_form__field_label'>
											<FormattedMessage {...messages.dot_inspection_form__report_number} />
										</div>
									</td>
									<td className='dot_inspection_form__cell'>
										<div className='dot_inspection_form__field_label'>
											<FormattedMessage {...messages.dot_inspection_form__fleet_unit_number} />
										</div>
									</td>
								</tr>
								<tr>
									<td className='dot_inspection_form__cell' style={{width:'35%'}}>
										{this.props.formData.get('reportNumber')}
									</td>
									<td className='dot_inspection_form__cell'>
										{this.props.formData.get('fleetUnitNumber')}
									</td>
								</tr>
								<tr>
									<td className='dot_inspection_form__cell' colSpan='2'>
										<span className='dot_inspection_form__field_label'>
											<FormattedMessage {...messages.dot_inspection_form__date} />
										</span>
										{' '}
										{moment(this.props.formData.get('inspectionDate')).format("MM/DD/YYYY")}
									</td>
								</tr>
							</tbody>
						</table>

					</div>
				</div>

				{/*General information*/}

				<div>

					<table className='dot_inspection_form__general_inforamtion'>
						<tbody>
							<tr>
								<td className='dot_inspection_form__cell' style={{width:'50%'}}>
									<div className='dot_inspection_form__field_label'>
										<FormattedMessage {...messages.dot_inspection_form__motor_carrier} />
									</div>
									<div>							
										{this.props.formData.get('motorCarrierOperator')}
									</div>
								</td>
								<td className='dot_inspection_form__cell'>
									<div className='dot_inspection_form__field_label'>
										<FormattedMessage {...messages.dot_inspection_form__inspector_name} />
									</div>																				
									<div className='dot_inspection_form__field_container'>
										<input 								
											className='responsiveTxt'
											id='inspectorName'
											maxLength='50'
                                            value={this.props.formData.get('inspectorName')}
											onChange={this.handleChange}
											onBlur={this.handleBlur}
											ref={(r)=>{this.inspectorNameRef=r}}
										/>

										{ !!this.props.formValidationMessages.get('inspectorName') &&
											<Popover 
												id='inspectorQualifiedPopover' 
												className='dot_inspection_form__error_message'
												placement='left' 
												positionTop='-15' 
												positionLeft='-250'
												style={{'margin-left':'-19em'}}
											>
												<RawIntlProvider value={this.props.intl}>
												<FormattedMessage {...(messages[this.props.formValidationMessages.get('inspectorName')])}/>
												</RawIntlProvider>
											</Popover>
										}									                    									
									</div>

								</td>
							</tr>

							<tr>
								<td className='dot_inspection_form__cell'>						
									<div className='dot_inspection_form__field_label'>
										<FormattedMessage {...messages.dot_inspection_form__motor_carrier_address} />
									</div>
									<div>
										{this.props.formData.get('motorCarrierAddressCityStateZip')}
									</div>
								</td>
								<td className='dot_inspection_form__cell'>
									<div className='dot_inspection_form__field_label'>
										<FormattedMessage {...messages.dot_inspection_form__inspector_qualified_statement} />
									</div>
									<div 
										className='dot_inspection_form__field_container' 
										ref={(r)=>{this.inspectorQualifiedContainerRef=r}}
										tabIndex='0'
									>
										<CheckCircle 
											name='inspectorQualified'
											checked={this.props.formData.get('inspectorQualified') === true} 
											message={<FormattedMessage {...messages.dot_inspection_form__inspector_qualified_yes}/>}
											onClick={this.handleInspectorQualifiedClick}										
										/>

										{ !!this.props.formValidationMessages.get('inspectorQualified') &&
											<Popover 
												id='inspectorQualifiedOverlay' 
												className='dot_inspection_form__error_message'  
												placement='left'
												positionTop='-15'
												positionLeft='-275'
											>
												<RawIntlProvider value={this.props.intl}>
												<FormattedMessage {...(messages[this.props.formValidationMessages.get('inspectorQualified')])}/>
												</RawIntlProvider>
											</Popover>
										}
									</div>
									
								</td>
							</tr>

							<tr>
								<td className='dot_inspection_form__cell'>
									<div className='dot_inspection_form__field_label'>
										<FormattedMessage {...messages.dot_inspection_form__vehicle_identification} />
									</div>
									<div>
										{this.props.formData.get('vehicleIdentification')}
									</div>
								</td>
								<td className='dot_inspection_form__cell'>
									<div className='dot_inspection_form__field_label'>
										<FormattedMessage {...messages.dot_inspection_form__repairing_vendor} />
									</div>
									<div>								
										{this.props.formData.get('repairingVendor')}
									</div>
								</td>
							</tr>

							<tr>
								<td className='dot_inspection_form__cell'>
									<div className='dot_inspection_form__field_label'>
										<FormattedMessage {...messages.dot_inspection_form__vehicle_type} />
									</div>
									
									<div className='row dot_inspection_form__vehicle_type'>
										<div className='col-sm-3 dot_inspection_form__no_gutter_col'>
											<div tabIndex='0'>
												<CheckCircle 
													name='TRACTOR'
													checked={this.props.formData.get('vehicleType') === 'TRACTOR'} 
													message={<FormattedMessage {...messages.dot_inspection_form__tractor} />}
													onClick={this.handleVehicleTypeClick}
												/>
											</div>
										</div>
										<div className='col-sm-3 dot_inspection_form__no_gutter_col'>
											<div tabIndex='0'>
												<CheckCircle 
													name='TRAILER'
													checked={this.props.formData.get('vehicleType') === 'TRAILER'} 
													message={<FormattedMessage {...messages.dot_inspection_form__trailer} />}
													onClick={this.handleVehicleTypeClick}
												/>
											</div>
										</div>
										<div className='col-sm-3 dot_inspection_form__no_gutter_col'>
											<div tabIndex='0'>
												<CheckCircle 
													name='TRUCK'
													checked={this.props.formData.get('vehicleType') === 'TRUCK'} 
													message={<FormattedMessage {...messages.dot_inspection_form__truck} />}
													onClick={this.handleVehicleTypeClick}
												/>
											</div>
										</div>
										<div className='col-sm-3 dot_inspection_form__no_gutter_col'>
											<div tabIndex='0'>											
												<CheckCircle 
													name='OTHER'
													checked={this.props.formData.get('vehicleType') === 'OTHER'} 
													message={<FormattedMessage {...messages.dot_inspection_form__other} />}
													onClick={this.handleVehicleTypeClick}
												/>
											</div>
										</div>
									</div>
								</td>
								<td className='dot_inspection_form__cell'>
									<div className='dot_inspection_form__field_label'>
										<FormattedMessage {...messages.dot_inspection_form__vendor_address} />
									</div>
									<div>
										{this.props.formData.get('vendorAddressCityStateZip')}
									</div>
								</td>
							</tr>

						</tbody>
					</table>
				</div>

				<VehicleComponents 
					formData={this.props.formData}
					setFormData={this.props.setFormData}
					formValidationMessages={this.props.formValidationMessages}  
					componentDetails={this.props.componentDetails}
					userLocale={this.props.userLocale}
					poCreationDate={this.props.poCreationDate}					
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
					onAllOkClick={this.props.onAllOkClick}
					onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}
				/>				
				
				{/*Footer*/}

				<div className='dot_inspection_form__footer'>        
					<div className='row dot_inspection_form__row'>					

						<div className='offset-sm-6 col-sm-6 dot_inspection_form__no_gutter_col'>
							<div className='dot_inspection_form__save' onClick={this.props.onFormSaveClick}>
								<FormattedMessage {...messages.dot_inspection_form__save}/>{' '}
								<img src='/assets/VMS_33button_add_blue.png' />
								<div className='dot_inspection_form__spinner'>
									{this.props.isSaving &&
										<Spinner spinnerType='blue' />										
									}
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default injectIntl(DotInspectionForm);