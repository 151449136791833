// #region Imports
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import ATASearchCorrectionsAndCostStep from '../components/ATASearchCorrectionsAndCostStep';
import TiresStepAddLine from '../components/TiresStepAddLine';
import TiresStepViewEditLine from '../components/TiresStepViewEditLine';
import BrakesStepAddLine from '../components/BrakesStepAddLine';
import BrakesStepViewEditLine from '../components/BrakesStepViewEditLine';
import LaborRateUpdateStep from '../components/LaborRateUpdateStep';
import ProductCodeSearchStep from '../components/ProductCodeSearchStep';
import MissingProductCodeInformationStep from '../components/MissingProductCodeInformationStep';
import {
  ModalBody
} from "DesignSpecs/components/FormComponents";
import AddNewLineItemsSelector from '../selectors/AddNewLineItemsView';
import * as constants from 'constants/App';
import * as addNewLineItemsViewActions from '../actions/AddNewLineItemsView';
import { emptyMapIfUndefined } from 'utils/HelperFunctions';
import { useAppState } from 'shared/AppState/app-state';
import FormWrapper from 'components/FormWrapper';
import { usePrevious } from 'shared/hooks/usePrevious';
import { useLineItemValidator } from "../../../hooks/useLineItemValidator";
import { select } from "redux-saga/effects";
import { FormattedMessage } from "react-intl";
import RepairTypeChangeStep from "../components/RepairTypeChangeStep";
import * as purchaseOrderViewEvents from '../../../events/AddEditView';
// #endregion

// #region Styled Components
const ModalStyled = styled(Modal).attrs({
  dialogClassName: "childStyle"
})`
  .childStyle {
    width: 900px;
    max-width: 900px;
}`;
// #endregion

// #region Component 

/*
This component supports three modes.

- In the 'AddLine' mode the wizard takes us through the steps below:
    MissingProductCodeInformationStep
    ProductCodeSearchStep 
    ATASearchCorrectionsAndCostStep
    TiresStep
    BrakesStep
    LaborRateUpdateStep
- In the 'TiresViewEdit' mode the wizard lets us view/edit previously entered Tire specs.
- In the 'BrakesViewEdit' mode the wizard lets us view/edit previously entered Brakes specs.
*/

const LineItemWizard = (props) => {
  // #region State  
  const [currentMode, setCurrentMode] = useState('AddLine');
  const [currentStep, setCurrentStep] = useState('ATASearchCorrectionsAndCostStep');
  const [tiresStepNeeded, setTiresStepNeeded] = useState(false);
  const [brakesStepNeeded, setBrakesStepNeeded] = useState(false);
  const [missingProductCodeInformationStepNeeded, setMissingProductCodeInformationStepNeeded] = useState(false);
  const [LaborRateUpdateStepNeeded, setLaborRateUpdateStepNeeded] = useState(false);
  const [productCode, setProductCode] = useState();
  const [isUnmappedProductCodeSelected, setIsUnmappedProductCodeSelected] = useState(false);
  const [ appState, dispatch ] = useAppState();
  const [laborRateStepNumber, setLaborRateStepNumber] = useState();
  const {validateLineItemAsync, priceValidations, pricesConfirmedList,
    handlePriceValdationConfirmationButtonClick, priceValidationCleanUp, priceValidationErrorMessage} = useLineItemValidator(props.token, 1);
  const [repairTypeChangeStepNeeded, setRepairTypeChangeStepNeeded] = useState(false);

  const prevAreLinesBeingSaved = usePrevious(props.areLinesBeingSaved);
  const prevIsTireInfoBeingSaved = usePrevious(props.isTireInfoBeingSaved);
  const prevIsBrakeInfoBeingSaved = usePrevious(props.isBrakeInfoBeingSaved);

  // #endregion

  // #region Ref
  // #endregion  
  
  // #region Event handlers
  const cleanUp = () => {
    props.dispatch(addNewLineItemsViewActions.resetAddNewLineItemData());
    props.dispatch(addNewLineItemsViewActions.clearAddLineItemsServerData());
    props.dispatch({ type: constants.CLEAR_ADD_NEWLINE_ITEM_ERROR_MESSAGES });
    props.dispatch({type: constants.CLEAR_LINE_ITEM_ID_REPAIR_TYPE_CHANGE});

    setCurrentMode('AddLine');
    setCurrentStep('ATASearchCorrectionsAndCostStep');
    setTiresStepNeeded(false);
    setBrakesStepNeeded(false);
    setLaborRateUpdateStepNeeded(false);
    setMissingProductCodeInformationStepNeeded(false);
    setRepairTypeChangeStepNeeded(false);
    priceValidationCleanUp();

    // Clear the product code 
    if(appState.isProductCodeFeatureEnabled)
      setProductCode();

    // Clear the line item id missing product code
    if(missingProductCodeInformationStepNeeded)
      props.dispatch({ type: constants.CLEAR_LINE_ITEM_ID_MISSING_PRODUCT_CODE }); 
  }

  const onCloseHandler = () => {
    cleanUp();

    props.dispatch(addNewLineItemsViewActions.toggleShowWindow());
  }

  const saveData = () => {
    /*  
    All steps are done, now its time to save data. 
    We are saving data at the end of all steps to conform to existing requirement of letting user use the 'Return' link to change data provided on a prior step.
    This function is used in 'AddLine' mode only. For other modes, the Step components save the data independetly.
    */

    // For now reading this data from a selector as values below are computed and it is not a straight read from redux state.
    const isCorrectionTypeReplaceOrRepair = props.correctionType == constants.CORRECTION_TYPE.REPLACE || props.correctionType == constants.CORRECTION_TYPE.REPAIR;
    let newLineItems = props.newLineItems;

    
    let lineItem = {
      productCode: newLineItems.get('ataCode'),
      vendorProductCode: newLineItems.get('vendorProductCode'),
      description: newLineItems.get('lineDescription').toUpperCase(),
      partQty: newLineItems.get('qty'),
      partCost: newLineItems.get('partCost'),
      laborHours: newLineItems.get('hours'),
      laborRate: newLineItems.get('laborRate'),
      complaintCode: newLineItems.get('complaintCode'),
      correctionType: newLineItems.get('selectedCorrectionType'),
      partWarrantyMonths: newLineItems.getIn(['partWarranty', 'months']),
      partWarrantyMiles: newLineItems.getIn(['partWarranty', 'miles']),
      partWarrantyHours: newLineItems.getIn(['partWarranty', 'hours']),
      multipleLineItemsPermittedForATA: appState.isAllowMultipleLinesWithSameATAFeatureEnabled ? true : props.selectedATAAllowsMultipleLines,
      partPricingSource: productCode?.partPricingSource,
      laborPricingSource: productCode?.laborPricingSource,
      // store unix timestamp as a unique reference number, if feature is enabled
      vendorReferenceNo: appState.isStoreAssociationLineIdFeatureEnabled ? Math.round(new Date().getTime()/1000): '',
      lineItemIdRepairTypeChange: props.lineItemIdRepairTypeChange,
      repairTypeChangeStepNeeded: repairTypeChangeStepNeeded
    }

    const addNewLineItem = async (lineItem) => {
  
      const result = await validateLineItem(lineItem);
  
      if (result) {
        lineItem.lineItemValidationReferenceId = priceValidations == undefined ? null : priceValidations[0].lineItemValidationReferenceId;
  
        if (tiresStepNeeded)
          props.dispatch({ type: constants.SAVE_NEW_LINE_ITEM_WITH_TIRE_SPECS, lineItem, priceValidationCleanUp });
        else if (brakesStepNeeded)
          props.dispatch({ type: constants.SAVE_NEW_LINE_ITEM_WITH_BRAKE_SPECS, lineItem, priceValidationCleanUp });
        else
          props.dispatch({ type: constants.ADD_NEW_LINE_ITEM_CLICKED, lineItem, priceValidationCleanUp });
  
        // Labor rate step saves its data independently
  
        // Clear the product code 
        if (appState.isProductCodeFeatureEnabled)
          setProductCode();
  
        // Delete the original line from which 'missing product code' workflow was started  
        if (missingProductCodeInformationStepNeeded)
          props.dispatch({ type: constants.DELETE_LINE_ITEM_CLICKED, lineItemKey: props.lineItemIdMissingProductCode });
      }
    }

    addNewLineItem(lineItem);
  }

  const onATASearchCorrectionsAndCostStepContinue = () => {
    props.dispatch({ type: constants.CLEAR_ADD_NEWLINE_ITEM_ERROR_MESSAGES });

    const validateLineItemStep = async () => {
      const isLineItemValid = await validateLineItem();

      if (isLineItemValid) {
        if (tiresStepNeeded)
          setCurrentStep('TiresStep');
        else if (brakesStepNeeded)
          setCurrentStep('BrakesStep');
        else if (LaborRateUpdateStepNeeded)
          setCurrentStep('LaborRateUpdateStep');
        else {
         saveData();
         if (appState.isProductCodeFeatureEnabled)
             setCurrentStep('ProductCodeSearchStep');
         else
             setCurrentStep('ATASearchCorrectionsAndCostStep');
        }
      }
    }

    validateLineItemStep();
  }

  const validateLineItem = async (enteredLineitem) => {
    if (appState.isPriceControlFeatureEnabled) {
      let lineItem = null;

      if (enteredLineitem) {
        lineItem = enteredLineitem
      }
      else {
        let newLineItems = props.newLineItems;
        lineItem = {
          productCode: newLineItems.get('ataCode'),
          vendorProductCode: newLineItems.get('vendorProductCode'),
          partQty: newLineItems.get('qty'),
          partCost: newLineItems.get('partCost'),
          laborHours: newLineItems.get('hours'),
          laborRate: newLineItems.get('laborRate'),
          complaintCode: newLineItems.get('complaintCode'),
          correctionType: newLineItems.get('selectedCorrectionType'),
          partPricingSource: productCode?.partPricingSource
        }
      }

      //return true if pricing source is NAP or CCP
      if (lineItem.partPricingSource == 'national_account_pricing'
        || lineItem.partPricingSource == 'client_contract_pricing') {
        return true;
      }

      //Add line item type and total for price validation
      if (lineItem.correctionType == "PM") {
        lineItem.lineItemType = constants.LINE_ITEM_TYPE.PM;

        if (lineItem.laborHours == undefined || Number(lineItem.laborHours == 0)) {
          lineItem.total = Number(lineItem.partQty) * Number(lineItem.partCost);
        }
        else {
          lineItem.total = Number(lineItem.partCost) + Number(lineItem.laborRate);
        }
      }
      else if (lineItem.partCost !== undefined && Number(lineItem.partCost) >= 0) {
        lineItem.lineItemType = constants.LINE_ITEM_TYPE.PART;
        lineItem.total = lineItem.partCost * lineItem.partQty;
      }
      //Add po number for price validation
      lineItem.poNumber = props.poNumber;

      const result = await validateLineItemAsync([lineItem]);

      return result;
    }
    else {
      return true;
    }
  }

  const onMissingProductCodeInformationStepReturn = () => {
  // Delete the original line from which Missing product code work flow was started
   props.dispatch({type: constants.DELETE_LINE_ITEM_CLICKED, lineItemKey: props.lineItemIdMissingProductCode}); 
   onCloseHandler();
  }

  const goToFirstStep = () => {
    if (appState.isProductCodeFeatureEnabled)
      setCurrentStep('ProductCodeSearchStep');
    else
      setCurrentStep('ATASearchCorrectionsAndCostStep');
  }

  const onATASearchCorrectionsAndCostStepReturnClicked = () => {
    if(appState.isProductCodeFeatureEnabled)
      setCurrentStep('ProductCodeSearchStep');
    else{
      setCurrentStep('RepairTypeChangeStep');
    }
  }

  // #endregion

  // #region Effects
  //clearing out brake validation before you navigate to the brake page
  useEffect(()=>{   
    props.dispatch({ type: constants.CLEAR_BRAKES_VALIDATION_ERRS });
  },[currentStep === 'BrakesStep'])

  // Determine step number for Labor rate step
  useEffect(() => {
    if (appState.isProductCodeFeatureEnabled && isUnmappedProductCodeSelected && (tiresStepNeeded || brakesStepNeeded))
      setLaborRateStepNumber(6);
    else if (appState.isProductCodeFeatureEnabled && isUnmappedProductCodeSelected)
      setLaborRateStepNumber(5);
    else if (!appState.isProductCodeFeatureEnabled && (tiresStepNeeded || brakesStepNeeded))
      setLaborRateStepNumber(5);
    else
      setLaborRateStepNumber(4);
  }, [appState.isProductCodeFeatureEnabled, isUnmappedProductCodeSelected, tiresStepNeeded, brakesStepNeeded])

  // Determine the correct mode by looking at incoming props
  useEffect(() => {
    if (props.showTiresInViewEdit && props.isTireProductCode)
      setCurrentMode('TiresViewEdit');
    else if (props.showTiresInViewEdit && props.isBrakesProductCode)
      setCurrentMode('BrakesViewEdit');
    else
      setCurrentMode('AddLine');
  }, [props.showTiresInViewEdit, props.isTireProductCode, props.isBrakesProductCode]);

  // Determine if 'Missing Product Code Information' Step is needed
  useEffect(() => {
    if (props.lineItemIdMissingProductCode != undefined)
      setMissingProductCodeInformationStepNeeded(true);
    else
      setMissingProductCodeInformationStepNeeded(false);
  }, [props.lineItemIdMissingProductCode]);

  // Set flag if Repair Type Change step is needed
  useEffect(() => {
    if (props.lineItemIdRepairTypeChange != undefined)
      setRepairTypeChangeStepNeeded(true);
    else
      setRepairTypeChangeStepNeeded(false);
  }, [props.lineItemIdRepairTypeChange]);

  // Determine what the first step is going to be
  useEffect(() => {
    if (props.lineItemIdMissingProductCode != undefined)
      setCurrentStep('MissingProductCodeInformationStep');
    else if (props.lineItemIdRepairTypeChange != undefined)
      setCurrentStep('RepairTypeChangeStep');
    else if (appState.isProductCodeFeatureEnabled)
      setCurrentStep('ProductCodeSearchStep');
    else
      setCurrentStep('ATASearchCorrectionsAndCostStep');
  },[props.isVisible]);

  // Unmapped product code related 
    useEffect(() => {
        if (productCode !== undefined) {

            setIsUnmappedProductCodeSelected(productCode.isUnmappedVendorProductCode);

            // This ensures that in ATASearchCorrectionAndCostType component, the Correction step is not enabled until vendors picks an ATA code
            if (productCode.isUnmappedVendorProductCode)
                props.dispatch({ type: constants.CLEAR_FIELDS_AFTER_SAVE_ADD_NEW_LINE_ITEM_DATA });
        }
    }, [productCode]);

  // Control navigation to first step 
  useEffect(() => {

    // finished saving lines
    if (prevAreLinesBeingSaved && !props.areLinesBeingSaved && !tiresStepNeeded && !brakesStepNeeded) {
      if (missingProductCodeInformationStepNeeded)
        onCloseHandler();
      else
        goToFirstStep();
    }

    // finished saving Tires info
    if (prevIsTireInfoBeingSaved && !props.isTireInfoBeingSaved) {
      if (missingProductCodeInformationStepNeeded)
        onCloseHandler();
      else
        goToFirstStep();
    }

    // finished saving Brakes info
    if (prevIsBrakeInfoBeingSaved && !props.isBrakeInfoBeingSaved) {
      if (missingProductCodeInformationStepNeeded)
        onCloseHandler();
      else
        goToFirstStep();
    }

  }, [props.areLinesBeingSaved, props.isTireInfoBeingSaved, props.isBrakeInfoBeingSaved])
  // #endregion

  // #region International messages  
  // #endregion

  // #region Render
  const TiresViewEditModeComponent = <TiresStepViewEditLine
    onContinue={onCloseHandler}
    onClose={onCloseHandler}
    readOnly={props.isTireReadOnly}
  />;

  const BrakesViewEditModeComponent = <BrakesStepViewEditLine
    stepNumber={appState.isProductCodeFeatureEnabled && isUnmappedProductCodeSelected ? 5 : 4} 
    onContinue={onCloseHandler}
    onClose={onCloseHandler}
    readOnly={props.isTireReadOnly}
  />;

  const AddLineModeComponent = <React.Fragment>
    {currentStep === 'MissingProductCodeInformationStep' ?
      <MissingProductCodeInformationStep
        complaintCode={props.complaintCode}
        lineItemDescription={props.existingPOLineItems.getIn([props.lineItemIdMissingProductCode, 'productDescription'])}
        onReturn={onMissingProductCodeInformationStepReturn}
        onContinue={() => {
          setCurrentStep('ProductCodeSearchStep');
        }}
      />
      : undefined
    }
    {currentStep === 'RepairTypeChangeStep' ?
      <RepairTypeChangeStep
        lineItemDescription={props.existingPOLineItems.getIn([props.lineItemIdRepairTypeChange, 'productDescription'])}
        lineItemProductCode={props.existingPOLineItems.getIn([props.lineItemIdRepairTypeChange, 'productCode'])}
        vehicleId={props.vehicleDetails.get('id')}
        stepNumber={1}
        onDeleteLineItem={() => props.dispatch({type: constants.DELETE_LINE_ITEM_CLICKED, lineItemKey: props.lineItemIdRepairTypeChange})}
        onContinue={() => {
          setCurrentStep('ATASearchCorrectionsAndCostStep');
        }}
        onClose={onCloseHandler}
      />
      : undefined}
    {currentStep === 'ProductCodeSearchStep' ?
      <ProductCodeSearchStep
        itemsAddedToPo={props.itemsAddedToPo}
        addNewLineItemsErrorMessage={props.addNewLineItemsErrorMessage}
        complaintCode={props.complaintCode}
        vehicleId={props.vehicleDetails.get('id')}
        vendorId={props.vendorId}
        ataParametersSorted={props.ataParametersSorted}
        languageCode={props.languageCode}
        token={props.token}
        onProductCodeSelect={setProductCode}
        selectedProductCode={productCode}
        stepNumber={1}
        onContinue={() => {
          props.dispatch({ type: constants.CLEAR_ADD_NEWLINE_ITEM_ERROR_MESSAGES });

          setCurrentStep('ATASearchCorrectionsAndCostStep');
        }}
        lineItemMissingProductCode={props.existingPOLineItems != undefined ? props.existingPOLineItems.getIn([props.lineItemIdMissingProductCode, 'productDescription']) : ""}
        missingProductCodeInformationStepNeeded={missingProductCodeInformationStepNeeded}
      />
      : undefined
    }
    {currentStep === 'ATASearchCorrectionsAndCostStep' ?
      <ATASearchCorrectionsAndCostStep
        vendorProductCodeInformation={productCode}
        isUnmappedProductCodeSelected={isUnmappedProductCodeSelected}
        isRepairTypeChangeNeeded={repairTypeChangeStepNeeded}
        stepNumber={appState.isProductCodeFeatureEnabled ? 2 : 1}
        showReturnLink={appState.isProductCodeFeatureEnabled || repairTypeChangeStepNeeded}
        onReturn={onATASearchCorrectionsAndCostStepReturnClicked}
        onContinue={onATASearchCorrectionsAndCostStepContinue}
        setTiresStepNeeded={(isStepNeeded) => {
          if (isStepNeeded) {
            // Pre-fetch the specs
            props.dispatch({ type: constants.FETCH_TIRE_SPECIFICATIONS, lineItemId: undefined, mode: 'add' });
          }
          setTiresStepNeeded(isStepNeeded);
        }}
        setBrakesStepNeeded={(isStepNeeded) => {
          if (isStepNeeded) {
            // Pre-fetch the specs
            props.dispatch({ type: constants.FETCH_BRAKES_SPECIFICATIONS, lineItemId: undefined, mode: 'add' });
          }
          setBrakesStepNeeded(isStepNeeded);
        }}
        setLaborRateUpdateStepNeeded={setLaborRateUpdateStepNeeded}
        priceValidations={priceValidations}
        pricesConfirmedList={pricesConfirmedList}
        handlePriceValdationConfirmationButtonClick={handlePriceValdationConfirmationButtonClick}
        priceValidationCleanUp={priceValidationCleanUp}
      />
      : undefined
    }
    {currentStep === 'TiresStep' ?
      <TiresStepAddLine
        stepNumber={appState.isProductCodeFeatureEnabled && isUnmappedProductCodeSelected ? 5 : 4} 
        onReturn={() => {
          setCurrentStep('ATASearchCorrectionsAndCostStep');
        }}
        onContinue={() => {
          if (LaborRateUpdateStepNeeded)
            setCurrentStep('LaborRateUpdateStep')
          else {
            saveData();
          }
        }}
      />
      : undefined
    }
    {currentStep === 'BrakesStep' ?
      <BrakesStepAddLine
        stepNumber={appState.isProductCodeFeatureEnabled && isUnmappedProductCodeSelected ? 5 : 4} 
        onReturn={() => {
          setCurrentStep('ATASearchCorrectionsAndCostStep');
        }}
        onContinue={() => {
          if (LaborRateUpdateStepNeeded)
            setCurrentStep('LaborRateUpdateStep')
          else {
            saveData();
          }
        }}
      />
      : undefined
    }
    {currentStep === 'LaborRateUpdateStep' ?
      <LaborRateUpdateStep
        stepNumber={laborRateStepNumber} 
        onReturn={() => {
          if (tiresStepNeeded)
            setCurrentStep('TiresStep');
          else if (brakesStepNeeded)
            setCurrentStep('BrakesStep');
          else
            setCurrentStep('ATASearchCorrectionsAndCostStep');
        }}
        onContinue={() => {
          saveData();
        }}
      />
      : undefined
    }</React.Fragment>;

  return (
    <ModalStyled
      show={props.showAddNewLineItemsWindow}
      backdrop="static"
      keyboard={false}
    >
      <ModalBody>
      {currentMode === 'BrakesViewEdit' ||
        currentMode === 'TiresViewEdit' ?
        null:
        <div className="row add-new-line-items__close" style={{marginLeft: 0, marginBottom: '0.5rem'}}>
          <div onClick={onCloseHandler}>
            <img className="add-new-line-items__close-button"
              src="/assets/VMS_33button_kill_blue.png" />
            {"  "} {<FormattedMessage
              id="close_dialog"
              description="Close"
              defaultMessage="Close" />}
          </div>
        </div>}
      <FormWrapper key='addNewLineItemsErrorMessage'
                id="addNewLineItemsErrorMessage" {...props}
                formErrors={props.addNewLineItemsErrorMessage} />
      <FormWrapper key='brakesErrorMessage'
                id="brakesErrorMessage" {...props}
                formErrors={props.brakesErrorMessage} /> 
      <FormWrapper key='tireErrorMessage'
                id="tireErrorMessage" {...props}
                formErrors={props.tireErrorMessage} />
      <FormWrapper key='priceValidationErrorMessage' {...props}
                formErrors={priceValidationErrorMessage}/>             
        {
          currentMode === 'BrakesViewEdit' ?
            BrakesViewEditModeComponent :
            currentMode === 'TiresViewEdit' ?
              TiresViewEditModeComponent
              :
              AddLineModeComponent
        }
      </ModalBody>
    </ModalStyled>
  );
};// #endregion

const mapStateToProps = (state, props) => {
  return {
    ...AddNewLineItemsSelector(state, props),
    addNewLineItemsErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'errorMessage']),
    tireErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'errorMessage']),
    brakesErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'errorMessage']),
    token: state.appState.getIn(["serverData", "shared", "token"]),
    vendorId: state.appState.getIn(["serverData", "shared", "vendorId"]),
    languageCode: state.appState.getIn(["uiData", "shared", "selectedLocale"]),
    vehicleDetails: emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'vehicleDetails'])),
    isVisible: state.appState.getIn(['uiData', 'addNewLineItemsView','showWindow']),
    areLinesBeingSaved: state.appState.getIn(['uiData', 'addNewLineItemsView', 'isCreatingLineItems']),
    isTireInfoBeingSaved: state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires','isSaving']),
    isBrakeInfoBeingSaved: state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes','isSaving']),
    lineItemIdMissingProductCode: state.appState.getIn(['uiData', 'addNewLineItemsView', 'lineItemIdMissingProductCode']),
    poNumber: state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']),
    lineItemIdRepairTypeChange: state.appState.getIn(['uiData', 'addNewLineItemsView', 'lineItemIdRepairTypeChange'])
  };
};

export default connect(mapStateToProps)(LineItemWizard);