// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './VehicleComponentHeader.css';

class VehicleComponentHeader extends React.Component {	
	render() {		
		const messages = defineMessages({
			fra_inspection_form__item: {
				id: 'fra_inspection_form__item',
				description: 'FRA inspection form item header.',
				defaultMessage: 'Action items'
			},
			fra_inspection_form__pass: {
				id: 'fra_inspection_form__pass',
				description: 'FRA inspection form pass header.',
				defaultMessage: 'PASS'
			},
			fra_inspection_form__fail: {
				id: 'fra_inspection_form__fail',
				description: 'FRA inspection form fail header.',
				defaultMessage: 'FAIL'
			},
		});
	
		return (
			<div className='fra_inspection_vehicle_component_header__row'>
				<div className='fra_inspection_vehicle_component_header__item_header'>					
					<FormattedMessage {...messages.fra_inspection_form__item} />
				</div>
				<div className='fra_inspection_vehicle_component_header__pass_header' >					
					<FormattedMessage {...messages.fra_inspection_form__pass} />
				</div>
				<div className='fra_inspection_vehicle_component_header__fail_header' >					
					<FormattedMessage {...messages.fra_inspection_form__fail} />
				</div>				
			</div>
		)
    }
}

export default VehicleComponentHeader;