// #region Imports
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import {
    ModalBody
} from "DesignSpecs/components/FormComponents";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { jwtDecode }  from "jwt-decode";
import * as actions from "../actions/App";
import * as Api from 'utils/Api';
import { useAppState } from "../shared/AppState/app-state";
// #endregion

// #region Styled Components
const ModalStyled = styled(Modal).attrs({
    dialogClassName: "childStyle"
})`
  .childStyle {
    width: 900px;
    max-width: 900px;
}`;
const ModalCloseStyled = styled.div`
padding-top: 1rem;
font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 500;
color: #0f4e95;
cursor: pointer;
`;

const ButtonStyled = styled.div`
text-align: right;
padding-top: 10px;
font-family: "Gotham SSm A", "Gotham SSm B";
font-style: normal;
font-weight: 700;
color: #5587b7;
`
const RowTitleStyled = styled(Row)`
font-size: 42px;
font-family: "Gotham SSm A", "Gotham SSm B";
font-style: normal;
font-weight: 300;
color: #5587B7;
padding-top: 15px;
padding-left: 0px;
line-height: normal;
margin-bottom: 10px;
`
// #endregion

// #region Component
const IELoginWarningModal = (props) => {
    // #region State
    const [showModal, setShowModal] = useState(false);
    const [appstate] = useAppState();

    const isIE = false || !!document.documentMode;
    // #endregion

    // #region Ref
    // #endregion

    // #region Hooks
    const intl = useIntl();
    // #endregion

    // #region Effects
    useEffect(() => {
        let result = showIELoginWarning()
        if(result){
            Api.logIEPopupDisplayed(props.vendorId);
        }
    }, [appstate.isIEWarningFeatureEnabled, isIE, props.token, props.personaToken]);
    // #endregion

    // #region Event handlers
    const onSignoutButtonClicked = () => {
        Api.logIEPopupSignoutSelected(props.vendorId);
        //Check if token is meant for APC user or SSO user    
        if (props.personaToken !== undefined) props.history.push(`/login?signed_out=1`);
        else {
            const jwtToken = jwtDecode(props.token);
            const audien = jwtToken.aud;
            if (
                audien == "VMS" || audien == "VMS_MEXICO" || jwtToken.sub === "ARIUSER"
            )
            props.history.push(`/login?signed_out=1`);
            else props.history.push(`/sso?signed_out=1`);
        }
        props.dispatch(actions.signOut());
        setShowModal(false);
    }

    const showIELoginWarning = () =>{
        const result = appstate.isIEWarningFeatureEnabled && isIE && (props.token != undefined || props.personaToken != undefined);
        if (result)
            setShowModal(result);
        return result;
    }
    // #endregion

    // #region International messages
    const messages = defineMessages({
        close_dialog: {
            id: "close_dialog",
            description: "  Close",
            defaultMessage: "Close"
        },
        ie_warning_title: {
            id: 'ie_warning_title',
            description: 'ie warning title',
            defaultMessage: 'Browser security warning'
        },
        ie_warning_message_line1: {
            id: 'ie_warning_message_line1',
            description: 'ie warning message line 1',
            defaultMessage: 'Effective October 31, 2023, Holman PartnerConnect will no longer be compatible with Microsoft Internet Explorer (IE) because it is no longer being supported by Microsoft. This means IE no longer receives security updates, making it unsafe to use for Holman, our customers, or partners.'
        },
        ie_warning_message_line2: {
            id: 'ie_warning_message_line2',
            description: 'ie warning message line 2',
            defaultMessage: 'If you want to learn more, please see {articleLink} from Microsoft: '
        },
        ie_warning_message_line3: {
            id: 'ie_warning_message_line3',
            description: 'ie warning message line ',
            defaultMessage: 'Common questions:'
        },
        ie_warning_message_question1: {
            id: 'ie_warning_message_question1',
            description: 'ie warning Q/A 1',
            defaultMessage: 'What should I do next?'
        },
        ie_warning_message_question2: {
            id: 'ie_warning_message_question2',
            description: 'ie warning Q/A 2',
            defaultMessage: "What if I don't know my username and password because it's saved in my browser?"
        },
        ie_warning_message_question3: {
            id: 'ie_warning_message_question3',
            description: 'ie warning Q/A 3',
            defaultMessage: "What if I can't download a new browser?"
        },
        ie_warning_message_answer1: {
            id: 'ie_warning_message_answer1',
            description: 'ie warning Q/A 1',
            defaultMessage: "You should download a modern browser such as Microsoft Edge, Google Chrome, or Mozilla Firefox."
        },
        ie_warning_message_answer2: {
            id: 'ie_warning_message_answer2',
            description: 'ie warning Q/A 2',
            defaultMessage: "First download a new browser."
        },
        ie_warning_message_answer3: {
            id: 'ie_warning_message_answer3',
            description: 'ie warning Q/A 3',
            defaultMessage: "Then you can {resetpasswordLink}."
        },
        ie_warning_message_answer4: {
            id: 'ie_warning_message_answer4',
            description: 'ie warning Q/A 4',
            defaultMessage: "Contact your IT Team."
        },
        ie_warning_message_answer5: {
            id: 'ie_warning_message_answer5',
            description: 'ie warning Q/A 5',
            defaultMessage: "If you don't have an IT person in your company, you can reach out to Holman IT at {mailAddress}."
        },
        ie_warning_article_text: {
            id: 'ie_warning_article_text',
            description: 'Microsoft article text',
            defaultMessage: 'this article'
        },
        ie_warning_reset_password_text: {
            id: 'ie_warning_reset_password_text',
            description: 'rest password text',
            defaultMessage: 'reset your password'
        },
        ie_warning_learn_more_button: {
            id: 'ie_warning_learn_more_button',
            description: 'ie warning learn more button',
            defaultMessage: 'Learn More'
        },
        ie_warning_signout_button: {
            id: 'ie_warning_signout_button',
            description: 'ie warning signout button',
            defaultMessage: 'Sign out and use a different browser'
        }
    });
    // #endregion

    // #region Render helpers
    const renderCloseButton = () => (
        <ModalCloseStyled role='button' onClick={() => setShowModal(false)}>
            <img src="/assets/VMS_33button_kill_blue.png" />
            {"  "} {<FormattedMessage {...messages.close_dialog} />}
        </ModalCloseStyled>
    );

    const renderSignoutButton = () => (
        <ButtonStyled
            role="button"
            onClick={onSignoutButtonClicked}
        >
            <FormattedMessage {...messages.ie_warning_signout_button} />
            {" "}
            <img src="/assets/VMS_33button_go_bblue.png" />
        </ButtonStyled>
    );
    // #endregion  

    // #region Render
    return (
        <ModalStyled
            show={showModal}
            backdrop="static"
            keyboard={false}
        >
            <ModalBody>
                {renderCloseButton()}
                <Container className="ie_warning">
                    <RowTitleStyled className="ie_warning-header">
                        <FormattedMessage {...messages.ie_warning_title} />
                    </RowTitleStyled>
                    <Row className="ie_warning-body">
                        <Container>
                            <Row className="ie_warning_message_line">
                                <FormattedMessage {...messages.ie_warning_message_line1} />
                            </Row>
                            <br />
                            <Row className="ie_warning_message_line">
                                <FormattedMessage {...messages.ie_warning_message_line2}
                                    values={{
                                        articleLink: (<a href='https://blogs.windows.com/windowsexperience/2021/05/19/the-future-of-internet-explorer-on-windows-10-is-in-microsoft-edge/' target="_blank" rel="noopener noreferrer">
                                            &nbsp;{intl.formatMessage(messages.ie_warning_article_text)}&nbsp;
                                        </a>)
                                    }} />
                            </Row>
                            <br />
                            <Row className='ie_warning_message_line'>
                                <FormattedMessage {...messages.ie_warning_message_line3} />
                            </Row>
                            <Row>
                                <ul>
                                    <li><FormattedMessage {...messages.ie_warning_message_question1} /></li>
                                    <ul>
                                        <li><FormattedMessage {...messages.ie_warning_message_answer1} /></li>
                                    </ul>
                                    <li><FormattedMessage {...messages.ie_warning_message_question2} /></li>
                                    <ul>
                                        <li><FormattedMessage {...messages.ie_warning_message_answer2} /></li>
                                        <li><FormattedMessage {...messages.ie_warning_message_answer3}
                                            values={{
                                                resetpasswordLink: (<a href='https://partnerconnect.holman.com/recoverPassword'>
                                                    {intl.formatMessage(messages.ie_warning_reset_password_text)}
                                                </a>)
                                            }} />
                                        </li>
                                    </ul>
                                    <li><FormattedMessage {...messages.ie_warning_message_question3} /></li>
                                    <ul>
                                        <li><FormattedMessage {...messages.ie_warning_message_answer4} /></li>
                                        <li><FormattedMessage {...messages.ie_warning_message_answer5}
                                            values={{ mailAddress: (<a href='mailto:APCITsupport@holman.com'>APCITsupport@holman.com</a>) }} />
                                        </li>
                                    </ul>
                                </ul>
                            </Row>
                        </Container>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                        <Col>
                            {renderSignoutButton()}
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </ModalStyled>
    );
};
// #endregion

const mapStateToProps = state => {
    return {
      token: state.appState.getIn(["serverData", "shared", "token"]),
      personaToken: state.appState.getIn([
        "serverData",
        "shared",
        "personaToken"
      ]),
      vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
    };
  };

export default connect(mapStateToProps)(withRouter(IELoginWarningModal));