import React from 'react';
import { browserHistory } from 'react-router-dom';
// import styles from '../../../../styles';
 import AlertList from 'components/AlertList';

class FormWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dirty: false, 
            modal: false, 
            nextRoute: null,
            alerts: []
        };
    }

    componentDidMount() {
        // this.props.router.setRouteLeaveHook(this.props.route, (nextRoute) => {
        //     let currentRoute = this.props.route.path;

        //     if (this.state.dirty) {        
        //         this.reducer('SET_NEXT_ROUTE', nextRoute);
        //         this.reducer('SHOW_MODAL');
        // //set route back to original
        //         this.props.router.replace(currentRoute);
        //         return false;
        //     }
        //     else {
        //         return true;
        //     }
        // });
    }

    handleAddAlert(alert) {
    //state async issue.  May not be present with redux
        setTimeout(() => {
            this.reducer('ADD_ALERT', alert);  
        }, 0);
    
    }

    handleClearAlert() {
        this.reducer('CLEAR_ALERTS');
    }

    handleNextRoute() {
        this.reducer('FORM_IS_CLEAN');
    //state async issue.  May not be present with redux
        setTimeout(() => {
            this.props.router.push(`${this.state.nextRoute.pathname}`);
        }, 0);
    
    }

    handleDirtyForm() {
        this.reducer('FORM_IS_DIRTY');
    }

    reducer(action, data = null) {
        switch(action) {
        case 'FORM_IS_DIRTY':
            this.setState({dirty: true});
            break;
        case 'FORM_IS_CLEAN':
            this.setState({dirty: false});
            break;
        case 'SHOW_MODAL':
            this.setState({modal: true});
            break;
        case 'SET_NEXT_ROUTE':
            this.setState({nextRoute: data});
            break;
        case 'ADD_ALERT':
            this.setState({
                alerts: [...this.state.alerts, data]
            });
            break;
        case 'CLEAR_ALERTS':
            this.setState({
                alerts: []
            });
            break;
        }
    }
  
    render() {
        const styles = {
            navBar: {
                marginBottom: '100px'
            },
            icon: {
                marginLeft: '10px'
            },
            listIcon: {
                marginRight: '10px'
            },
            pageHeading: {
                paddingBottom: '50px'
            },
            transparentBg: {
                background: 'transparent'
            },
            alerts: {
                marginBottom: '10px',
                minHeight: '50px'
            },
            ataTextDiv: {
                position: 'relative',
                width: '250px',
                textAlign: 'left',
                height: '100%'
            },
            ataText: {
                width: "250px",
                transition: "all 0.5s ease",
                ':focus': {
                    width: "400px"
                }
            },
            ataResults: {
                position: 'absolute',
                marginTop: '10px',
                marginLeft: '5px',
                width: '400px',
                minHeight: '200px',
                background: '#F6F6F6',
                borderTop: '1px solid #fffcfc',
                borderLeft: '1px solid #fffcfc',
                borderBottom: '1px solid #998f8f',
                borderRight: '1px solid #998f8f',
                borderRadius: '10px',
                boxShadow: '5px 5px 10px 10px rgba(0,0,0,0.5)'
            }
        };
        const childForms = React.Children.map(this.props.children,
     (child) => React.cloneElement(child, {
         onDirtyForm: this.handleDirtyForm.bind(this),
         isDirty: this.state.dirty,
         addAlert: this.handleAddAlert.bind(this),
         clearAlerts: this.handleClearAlert.bind(this),
         formErrors: this.props.formErrors
     })
    );
        return (
      <div>
      {
          this.props.formErrors !=undefined && this.props.formErrors.length > 0?
          <div>
            <div className="text-center" style={styles.alerts}>
            {
                <AlertList alerts={this.props.formErrors} />
            }
            </div>
            <div className="text-center">
                {childForms}
            </div>
          </div>: 
          false
      }
      </div>
    );
    }
}

export default FormWrapper;