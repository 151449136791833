import React from 'react';
import './ClientParametersComponent.css';


class ClientParametersComponent extends React.Component {

    convertToTitleCaseParameters(list) {


            if (list == undefined) return list;

            list = list.toLowerCase().replace(/[^-.'\s]+/g, function (word) {
                return word.replace(/^./, function (first) {
                    return first.toUpperCase();
                });
            });

            return list;



    }

    render() {

        return <div className="parameters__description">

            {this.convertToTitleCaseParameters(this.props.description)}

        </div>;


    }

}


export default ClientParametersComponent;