import React, { createContext, useReducer, useContext } from "react"

export const AppStateContext = createContext()

export function AppStateProvider({ reducer, initialState , children }) {
  const value = useReducer(reducer, initialState)
  return <AppStateContext.Provider value={value} children={children} />
}

export function useAppState() {
  return useContext(AppStateContext)
}