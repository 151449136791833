import React from 'react';
import ReactDoM from 'react-dom';
import * as constants from 'constants/App';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import Submit from 'components/Submit';
import './BankingInfo.css';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';


class BankingInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsFocusedAfterValidation: []   //array of fields that have recieved focus event
        }
    }
    //Adds the field to the list of focused fields
    onFieldFocus(fieldName) {
        let updatedFocusFields = this.state.fieldsFocusedAfterValidation.concat(fieldName);
        this.setState({ fieldsFocusedAfterValidation: updatedFocusFields });
        this.props.removeValidationErr(fieldName);  
    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({ fieldsFocusedAfterValidation: [] });
        this.props.onSubmitStep(3);
    }
    onChange(inputName, event) {
        this.props.onChangeRegistrationFieldValue(inputName, event);
    }

    renderValidationOverlay(field, fieldName, placement) {
        let message = this.props.formDataValidationMessages.get(fieldName);
        let showError = message != undefined && !this.state.fieldsFocusedAfterValidation.includes(fieldName);
        if (message != undefined)
            return <Overlay
        show={showError}
        target={() => field}
        placement={placement != undefined ? placement : "right"}
        container={this}>
                <Popover id={"popOver" + fieldName} className="enrollment-details__error_message step1__popover">
                <RawIntlProvider value={this.props.intl}>
                    <FormattedMessage {...message} />
                    </RawIntlProvider>
                </Popover>
            </Overlay>
                else
            return false;
                }
    render() {
                    const messages = defineMessages({
                        bank_info: {
                            id: 'bank_info',
                            description: 'Step 3 of 5: Banking Information',
                            defaultMessage: 'Step 3 of 5: Banking Information'
                        },
                        routing_number: {
                            id: 'routing_number',
                            description: 'Enter 9 digit Routing and Transit number',
                            defaultMessage: 'Enter 9 digit Routing and Transit number'
                        },
                        checking_acc_number: {
                            id: 'checking_acc_number',
                            description: 'Enter Checking Account Number (Do not include check number)',
                            defaultMessage: 'Enter Checking Account Number (Do not include check number)'
                        },
                        re_enter_checking_acc_number: {
                            id: 're_enter_checking_acc_number',
                            description: 'Re-Enter Checking Account Number',
                            defaultMessage: 'Re-Enter Checking Account Number'
                        },
                        payment_email: {
                            id: 'payment_email',
                            description: 'Payment Email Address',
                            defaultMessage: 'Payment Email Address'
                        },
                        go_to_next_step: {
                            id: 'go_to_next_step',
                            description: 'Go to next step',
                            defaultMessage: 'Go to next step'
                        },
                        institute_id:{
                            id:'institute_id',
                            description:'Institute Id',
                            defaultMessage:'Institute Id'
                        },
                        transit_id:{
                            id:'transit_id',
                            description:'Transit Id',
                            defaultMessage:'Transit Id'
                        },
                        checking_acc_no_canada:{
                            id: 'checking_acc_no_canada',
                            description: 'Financial Institution Account Number',
                            defaultMessage: 'Financial Institution Account Number'
                        },
                        reenter_checking_acc_no_canada:{
                            id: 'reenter_checking_acc_no_canada',
                            description: 'Confirm Account Number',
                            defaultMessage: 'Confirm Account Number'
                        },
                        payment_email_info: {
                            id: 'payment_email_info',
                            description: 'Step 2 of 4: Payment Email Information',
                            defaultMessage: 'Step 2 of 4: Payment Email Information'
                        }
                    })
                    return (
                        <div className='step2'>
                            <div className='step2__header'>{this.props.country=='MEX'?<FormattedMessage {...messages.payment_email_info } /> : <FormattedMessage {...messages.bank_info } />}</div>
                            <div className='step2__bank-info'>
                                <div className='step2__bank-info-input'>
                                    {this.props.error}
                    {this.props.country=='CAN'?
                    <div className='step2__input-group'>
                        <label ><FormattedMessage {...messages.institute_id } /></label>
                        <input type='text'
                        maxLength={'3'}
                        ref={input => this.institueId = input}
                        className='step1__block step1__input'
                        onChange={this.onChange.bind(this, constants.FORM_DATA.INSTITUTE_ID)}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.INSTITUTE_ID)} 
                        onKeyPress={helperFunctions.isNumeric.bind(this)}
                        value = {this.props.formData.get(constants.FORM_DATA.INSTITUTE_ID)}/>
                    {this.renderValidationOverlay(this.institueId, constants.FORM_DATA.INSTITUTE_ID, 'right')}

                </div>:false}
                        {this.props.country=='CAN'?
                        <div className='step2__input-group'>
                            <label ><FormattedMessage {...messages.transit_id } /></label>
                            <input type='text'
                            maxLength={'5'}
                            ref={input => this.transitId = input}
                            className='step1__block step1__input'
                            onChange={this.onChange.bind(this, constants.FORM_DATA.TRANSIT_ID)}
                            onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.TRANSIT_ID)} 
                            onKeyPress={helperFunctions.isNumeric.bind(this)}
                            value = {this.props.formData.get(constants.FORM_DATA.TRANSIT_ID)}/>
                        {this.renderValidationOverlay(this.transitId, constants.FORM_DATA.TRANSIT_ID, 'right')}

                    </div>
                        :false}
                        {this.props.country=='USA'?
                        <div className='step2__input-group'>
                            <label ><FormattedMessage {...messages.routing_number } /></label>
                            <input type='text'
                            maxLength={'9'}
                            ref={input => this.routingNumber = input}
                            className='step1__block step1__input'
                            onChange={this.onChange.bind(this, constants.FORM_DATA.ROUTING_NUMBER)}
                            onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.ROUTING_NUMBER)} 
                            onKeyPress={helperFunctions.isNumeric.bind(this)}
                            value = {this.props.formData.get(constants.FORM_DATA.ROUTING_NUMBER)}/>
                        {this.renderValidationOverlay(this.routingNumber, constants.FORM_DATA.ROUTING_NUMBER, 'right')}

                    </div>:false
                        }
                        {this.props.country=='MEX'?false:
                        <div className='step2__input-group'>
                            <label >{this.props.country=='CAN'?<FormattedMessage {...messages.checking_acc_no_canada } />:<FormattedMessage {...messages.checking_acc_number } />}</label>
                            <input type='password'
                                maxLength={'17'}
                        ref={input => this.checkingNumber = input}
                        className='step1__block  step1__input'
                        onChange={this.onChange.bind(this, constants.FORM_DATA.CHECKING_ACC_NUMBER)}
                        onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.CHECKING_ACC_NUMBER)} 
                        onKeyPress={helperFunctions.isNumeric.bind(this)}
                        value = {this.props.formData.get(constants.FORM_DATA.CHECKING_ACC_NUMBER)}/>
                    {this.renderValidationOverlay(this.checkingNumber, constants.FORM_DATA.CHECKING_ACC_NUMBER, 'right')}
                </div>
                    }
                    {this.props.country=='MEX'?false:
                    <div className='step2__input-group'>
                        <label >{this.props.country=='CAN'?<FormattedMessage {...messages.reenter_checking_acc_no_canada } />:<FormattedMessage {...messages.re_enter_checking_acc_number } />}</label>                            
                        <input type='password'
                            maxLength={'17'}
                    ref={input => this.reEnterCheckingNumber = input}
                    className='step1__block  step1__input'
                    onChange={this.onChange.bind(this, constants.FORM_DATA.CONFIRM_CHECKING_ACC_NUMBER)}
                    onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.CONFIRM_CHECKING_ACC_NUMBER)} 
                    onKeyPress={helperFunctions.isNumeric.bind(this)}
                    value = {this.props.formData.get(constants.FORM_DATA.CONFIRM_CHECKING_ACC_NUMBER)}/>
                {this.renderValidationOverlay(this.reEnterCheckingNumber, constants.FORM_DATA.CONFIRM_CHECKING_ACC_NUMBER, 'right')}
            </div>
                }
                        <div className='step2__input-group'>
                            <label ><FormattedMessage {...messages.payment_email } /></label>
                            <input type='text'
                                ref={input => this.paymentEmail = input}
                                className='step1__block  step1__input'
                                onChange={this.onChange.bind(this, constants.FORM_DATA.PAYMENT_EMAIL)}
    onFocus={this.onFieldFocus.bind(this, constants.FORM_DATA.PAYMENT_EMAIL)} 
    value = {this.props.formData.get(constants.FORM_DATA.PAYMENT_EMAIL)}/>
    {this.renderValidationOverlay(this.paymentEmail, constants.FORM_DATA.PAYMENT_EMAIL, 'right')}
                        </div>
                        {this.props.country=='MEX'?false:
                        <div className='step2__check-image'>
                        {this.props.country=='CAN'?<img src="/assets/bank_check_canada.jpg" alt="sample-check-image"/>: <img src="/assets/bank_check.png" alt="sample-check-image"/>}
                        </div>
                        }
                    </div>
                    <div className='step2__next-step'>
                        <Submit caption={<FormattedMessage {...messages.go_to_next_step }/>}
                            imagePath="/assets/VMS_33button_go_bblue.png"
                            onClickHandler={this.onSubmit.bind(this)} 
                            showSpinner={this.props.isSubmittingBankInfo}/>
                    </div>
                </div>
            </div>
        );
                        }
};
export default injectIntl(BankingInfo);