import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Map } from 'immutable';
import styled from "styled-components";
import ComplaintVehicleTitle from '../components/ComplaintVehicleTitle';
import ComplaintVehicleInformation from '../components/ComplaintVehicleInformation';
import Complaints from 'AddEditView/Complaints';
import AddComplaint from 'AddEditView/AddComplaint';
import Services from '../components/Services';
import GoToNextStep from '../components/GoToNextStep';
import * as ComplaintViewActions from '../actions/ComplaintView';
import * as purchaseOrderViewActions from '../../AddEditView/actions/AddEditView';
import * as addServiceLineItemsViewActions from '../../../PO/AddEditView/screens/AddServiceLineItemsView/actions/AddServiceLineItemsView';
import * as sharedActions from 'actions/App';
import * as addNewLineItemsViewActions from '../../../PO/AddEditView/screens/AddNewLineItemsView/actions/AddNewLineItemsView';
import Spinner from 'components/Spinner';
import ComplaintViewSelector from '../selectors/ComplaintView';
import Diagnosis from '../../../../shared/AddEditView/Diagnosis';
import * as constants from 'constants/App';
import './ComplaintView.css';
import FormWrapper from 'components/FormWrapper';
import Shared from 'selectors/shared';
import { useAppState } from 'shared/AppState/app-state';
import ContactCustomerDirectlyForApprovalBanner from 'components/ContactCustomerDirectlyForApprovalBanner';
import * as Api from "utils/Api";

// #region Styled Components
const SpinnerContainer = styled.div`
  display: inline-block;

  & .spinner {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: inherit;
    vertical-align: top;
    margin-left: 5px;
    border-width: 5px;
  }
`;
// #endregion

const ComplaintView = props => { 

    const [showDiagnosisErrorMessage, setShowDiagnosisErrorMessage] = useState(false);
    const [diagnosisErrorMessage, setDiagnosisErrorMessage] = useState(undefined);
    const [ appState, dispatch ] = useAppState();
    const token = appState.token;
    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const loadingStates = { loading: "loading", loaded: "loaded", error: "error" };
    const [loadingState, setLoadingState] = useState(loadingStates.loading);
    
    useEffect(
        () => {
            props.dispatch({ type: constants.COMPLAINT_SCREEN_LOADED, vehicleId: props.routeParams.match.params.vehicleId });
        },
        [] 
    );    
   
    useEffect(() => {
        let didCancel = false;

        const fetchPurchaseOrderData = async () => {
            if (!props.purchaseOrderId || !token)
                return;
                try {
                    setLoadingState(loadingStates.loading);
                    const [purchaseOrder] = await Promise.all([
                        Api.fetchPurchaseOrder(
                            props.purchaseOrderId,
                            token
                        )
                    ]);
                    if (!didCancel) {
                        if (purchaseOrder) {
                            setPurchaseOrder(purchaseOrder);
                        }
                        setLoadingState(loadingStates.loaded);
                    }
                } catch (error) {
                    if (!didCancel) {
                        setLoadingState(loadingStates.error);
                        //Error
                    }
                }
            };

            fetchPurchaseOrderData();

            return () => {
                didCancel = true;
            };
        }, [props.purchaseOrderId, token]
    );

    const onSelectServiceHandler = (serviceId) => {
        props.dispatch(ComplaintViewActions.setServiceSelection(serviceId));
    }

    const onSaveServicesHandler = () => {
        props.dispatch({ type: constants.GO_TO_NEXT_STEP_CLICKED_ON_COMPLAINT_SCREEN });
    }

    const onAddAnotherComplaintHandler= () => {
        props.dispatch(purchaseOrderViewActions.createNewComplaint());
    }

    const onComplaintTypeHandler = (complaintCode, complaintDescription, complaintKey, oldComplaintCode) => {        
        props.dispatch({ type: constants.UPDATE_COMPLAINT_CODE, code: complaintCode, complaintKey: complaintKey, description: complaintDescription });
        props.dispatch(purchaseOrderViewActions.selectComplaint(complaintKey, complaintCode, complaintDescription, oldComplaintCode));
    }

    const onChangeComplaintHandler = (complaintKey, event) => {
        event.preventDefault();
        props.dispatch(purchaseOrderViewActions.toggleComplaintSelectMode(complaintKey));
    }

    const onSkipComplaintHandler = (complaintKey, event) => {
        event.preventDefault();
        props.dispatch(purchaseOrderViewActions.skipComplaint(complaintKey));
    }

    const SaveDiagnosisValues = (hours, rate, diagnosisSelected) => {
        props.dispatch(ComplaintViewActions.setDiagnosisDetails(hours, rate, diagnosisSelected));
    }

    const onComplaintNotesSaveHandler = (complaintKey, notes) => {
        props.dispatch({type: constants.UPDATE_COMPLAINT_NOTES, complaintKey, notes});
        props.dispatch({type: constants.SAVE_COMPLAINT_NOTES_CLICKED, complaintKey, notes});
    }

    const onComplaintNotesSaveStateHandler = (complaintKey, notes) => {
        props.dispatch({type: constants.UPDATE_COMPLAINT_NOTES, complaintKey, notes});

    }
       
    if (props.isfetchingData) {
        return <Spinner />;
    }

    return <div>

        {(props.purchaseOrderExists == false)
            ? <Redirect to={{
                pathname: '/'
            }} />
            : undefined
        }
    
        <FormWrapper key='complaintErrorMessage' 
                        id="complaintErrorMessage" {...props} 
                        formErrors={props.genericErrorMessage} />

        { 
        loadingState == loadingStates.loading
        ? <SpinnerContainer>Loading...&nbsp;<Spinner spinnerType="dark-blue" manualPositioning /></SpinnerContainer>
        :
        props.vehicleDetails.get('isHsbClientVehicle') && purchaseOrder && purchaseOrder.entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER && <ContactCustomerDirectlyForApprovalBanner />}
        
        <ComplaintVehicleTitle caption={<div>{props.vehicleDetails.get('year') + ' ' +
            props.vehicleDetails.get('make') + ' ' +
            props.vehicleDetails.get('model') } </div>}/>
        <ComplaintVehicleInformation vehicleDetails={props.vehicleDetails}/>

        <FormWrapper key='serviceErrorMessage' 
                        id="serviceErrorMessage" {...props} 
                        formErrors={props.serviceErrorMessage} />

        <Services services={props.services}
                    onSelectService={onSelectServiceHandler} selectedCurrency={props.selectedCurrency}
                    listOfComplaintsForWhichNoteIsRequired={props.listOfComplaintsForWhichNoteIsRequired}
                    isReadOnly={appState.isPMServicesReadOnlyFeatureEnabled}
        />

        <FormWrapper key='complaintNotesErrorMessage'
                        id="complaintNotesErrorMessage" {...props}
                        formErrors={props.complaintNotesErrorMessage} />

        <div className="complaints-view__complaints-section">                
            <Complaints complaints={props.complaints}
                            notes={props.notes}
                            notesHistory={props.notesHistory}
                            complaintTypes={props.complaintTypes}
                            disableMaintenanceComplaintType={false}
                            isInitialAddMode={true}
                            showErrorMessages={props.showErrorMessages}
                            canAddAnotherComplaint={props.canAddAnotherComplaint}
                            onAddAnotherComplaintClick={onAddAnotherComplaintHandler}
                            onComplaintTypeClick={onComplaintTypeHandler}
                            onChangeComplaintClick={onChangeComplaintHandler}
                            onSkipComplaintClick={onSkipComplaintHandler}
                            onComplaintNotesSave={onComplaintNotesSaveHandler}
                            onComplaintNotesSaveState={onComplaintNotesSaveStateHandler}
                            creatingOrEditingComplaintForComplaintKey={props.creatingOrEditingComplaintForComplaintKey}
                            skippingComplaintForComplaintKey={props.skippingComplaintForComplaintKey}
                            actionType="COMPLAINT"
                        selectedCurrency={props.selectedCurrency}
                        listOfComplaintsForWhichNoteIsRequired={props.listOfComplaintsForWhichNoteIsRequired}
            />

            <AddComplaint onAddAnotherComplaintClick={onAddAnotherComplaintHandler}
                            canAddAnotherComplaint={props.canAddAnotherComplaint} />
            
        </div>
                    
        <FormWrapper key='diagnoseErrorMessage' 
            id="diagnoseErrorMessage" {...props} 
            formErrors={props.diagnoseErrorMessage} />          
        <Diagnosis selectedCurrency={props.selectedCurrency} showErrorMessage={showDiagnosisErrorMessage} errorMessage={diagnosisErrorMessage} isReadOnly={props.diagnosisDetails.get('readOnly')} isSelected={props.diagnosisDetails.get('diagnosisRadioSelected') } Rate={props.diagnosisDetails.get('rate') } Hours={props.diagnosisDetails.get('hours') } editMode={true} laborRate={props.hourlyLaborRate} onSaveDiagnosisDetails={SaveDiagnosisValues }  />

        <GoToNextStep selectedLocale={props.selectedLocale} onClickHandler={onSaveServicesHandler } showSpinner={props.isGotoNextStepRequested} spinnerType='blue'/>
        
    </div>;
}

//I moved the original selector into this function.  It was getting too "clunky".
//If you just needed 1 node from the Redux store, you don't need a reselect.
//Instead you can make a new property in the JSON object below and assign the value from state.
//If you need a derived value, create a new selector in a new file and call below.
function mapStateToProps(state, props) {
    return {
        notes: state.appState.getIn(['uiData', 'addEdit', 'notes']),
        notesHistory: state.appState.getIn(['uiData', 'addEdit', 'notesHistory']),
        //example:
        //oneToOnePropToState: state.appState.getIn(['uiData', 'area', 'oneToOnePropToState']),
        //newDerivedProp: newDerivedSelector(state, props),
        ...ComplaintViewSelector(state, props),
        genericErrorMessage: state.appState.getIn(['uiData','shared','errorMessage']),
        complaintNotesErrorMessage: state.appState.getIn(['uiData','addEdit','errorMessage']),
        diagnoseErrorMessage: state.appState.getIn(['uiData','complaintView','errorMessage']),
        serviceErrorMessage: state.appState.getIn(['uiData','complaintView','serviceErrorMessage']),
        selectedCurrency:state.appState.getIn(['uiData', 'shared','selectedCurrency']),
        selectedLocale:state.appState.getIn(['uiData','shared','selectedLocale']),
        purchaseOrderId: state.appState.getIn(['uiData','addEdit','purchaseOrder','id']),
        ...Shared(state, props),
    };
}

export default connect(mapStateToProps)(ComplaintView);