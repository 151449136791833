import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map, fromJS} from 'immutable';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';

export function uiDataDealerDraftRequestsApproval(state = initialAppState.getIn(['uiData', 'dealerDraftRequestsApproval']), action) {
    switch (action.type) {
        case "FETCH_REQUEST_NO_DETAILS_REQUESTED":
            return state.set('isFetchingDataRequestDetails', true);
        case "FETCH_REQUEST_NO_DETAILS_RESOLVED":
           return state
            .set('requestOrder', fromJS(action.requestDetails.requestOrder))
            .set('lineItems', fromJS(action.requestDetails.lineItemsDealerDraft))
            .set('complaints', fromJS(action.requestDetails.complaints))
            .set('isDirty', false)
            .set('isFetchingDataRequestDetails', false)
            .set('requestStatus',fromJS(action.requestDetails.requestStatus))
            .delete('errorMessage');
        case "FETCH_REQUEST_NO_DETAILS_REJECTED":
            return state.set('isFetchingDataRequestDetails', false);
        case "DEALER_DRAFT_EDIT_LINE_ITEM":
            return state
                .set('lineItemIdBeingEdited', action.lineItemKey)
                .setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], true).set('isDirty', true);
        case "DEALER_DRAFT_UPDATE_LINE_ITEM_REQUESTED":
            return state.delete('errorMessage');
        case "DEALER_DRAFT_UPDATE_LINE_ITEM_RESOLVED":
            return state
                .mergeIn(['lineItems', action.lineItemKey], action.lineItem)
                .setIn(['lineItems', action.lineItemKey, 'isAdjustedByPartVendor'], true)
                .delete('lineItemIdBeingEdited')
                .setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], false)
                .set('isDirty', false);
        case "DEALER_DRAFT_UPDATE_LINE_ITEM_REJECTED":
            return state
                .delete('lineItemIdBeingEdited')
                .setIn(['lineItems', action.lineItemKey, 'isAdjustedByPartVendor'], true)
                .setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], false)
                .setIn(['lineItems', action.lineItemKey, 'costError'], action.errorMessage)
                .set('isDirty', false);
        case "DEALER_DRAFT_CANCEL_UPDATE_LINE_ITEM":
            return state
                .delete('lineItemIdBeingEdited')
                .setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], false)
                .set('isDirty', false);
        case "DEALER_DRAFT_DELETE_LINE_ITEM_REQUESTED":
            return state.delete('errorMessage');
        case "DEALER_DRAFT_DELETE_LINE_ITEM_RESOLVED":
            return state.deleteIn(['lineItems', action.lineItemKey]);
        case "TOGGLE_SHOW_MODAL_ADD_NEW_LINE_ITEMS_DEALER_DRAFT":
            return state.set('showWindow', !state.get('showWindow'));
        case "ADD_COMPLAINT_CODE_DEALER_DRAFT":
            return state.set('selectedComplaintCode', action.complaintCode,);
        case "FETCH_PRODUCT_DETAILS_REQUESTED":
            return state.set('isFetchingDataProductDetails', true);
        case "FETCH_PRODUCT_DETAILS_RESOLVED":
            return state
                .set('productDetails', fromJS(action.productDetails))
                .set('isFetchingDataProductDetails', false)
                .delete('errorMessage');
        case "FETCH_PRODUCT_DETAILS_REJECTED":
            return state.set('isFetchingDataProductDetails', false);
        case "DEALER_DRAFT_ADD_NEW_LINE_ITEM_REQUESTED":
            return state.set('isCreatingLineItems', true)
                .set('errorMessage', []);
        case "DEALER_DRAFT_ADD_NEW_LINE_ITEM_REJECTED":
            return state.set('isCreatingLineItems', false)
                .set('errorMessage', action.errorMessage);
        case "DEALER_DRAFT_CLEAR_FIELDS_AFTER_SAVE_ADD_NEW_LINE_ITEM_DATA":
            /*Reset values*/
            return state.setIn(['cost'], undefined)
                .setIn(['description'], undefined)
                ;
        case "DEALER_DRAFT_CLEAR_DATA":
            /*Reset values*/
            return state.setIn(['cost'], undefined)
                .setIn(['description'], undefined)
                .setIn(['itemsAddedToPo'], Map())
                .setIn(['lineItemKey'], undefined)
                .setIn(['licensePlate'], undefined)
                .setIn(['registrationState'], undefined)
                .setIn(['deliveryDate'], undefined)
                .setIn(['registrationExpiration'], undefined)
                .setIn(['popOver', 'isPopOverDisplayedShowPaymentError'],false)
                .setIn(['paymentAcceptedToggle'],false)
                .setIn(['tempLicensePlate'], undefined)
                .setIn(['tempRegistrationExpDate'], undefined)
                .setIn(['tempRegistrationState'], undefined)
                .setIn(['initialLicenseToggle'], undefined)
                .setIn(['tempLicenseToggle'], undefined)
                .setIn(['noSelectionToggle'], undefined)
                .setIn(['popOver', 'isPopOverDisplayedShowRegistrationError'],false)
                .setIn(['popOver', 'isPopOverDisplayShowDeliveryDateError'],false)
                ;
        case "DEALER_DRAFT_ADD_NEW_LINE_ITEM_RESOLVED":
            return state.set('isCreatingLineItems', false);
        case "DEALER_DRAFT_SAVE_RECENT_ITEMS_ADDED_TO_REQUEST":
            return state.setIn(['itemsAddedToPo', action.lineItemForView.get('productDescription')], fromJS(action.lineItemForView));
        case "DEALER_DRAFT_SAVE_LINE_ITEM":
            //Hack for setIn function weird behavior where it swallows the exception if
            //one of the nested keys doesnt exists
            if(state.get('lineItems') == undefined && state.getIn(['lineItems', action.lineItem.lineItemId]) == undefined )
            {
                const item = Map();
                return state.set('lineItems', item.set(action.lineItem.lineItemId, fromJS(action.lineItem))).set('isDirty', true);
            }
            else
                return state.setIn(['lineItems', action.lineItem.lineItemId],fromJS(action.lineItem)).set('isDirty', true);
        case "TOGGLE_REQUEST_PAYMENT":
            return state.set('paymentAcceptedToggle', !state.get('paymentAcceptedToggle'));
        case "TOGGLE_INITIAL_LICENSE":
                return state.set('initialLicenseToggle', action.initialLicenseToggle);
        case "TOGGLE_TEMP_LICENSE":
                    return state.set('tempLicenseToggle', action.tempLicenseToggle);
        case "TOGGLE_NO_SELECTION":
                return state.set('noSelectionToggle', action.noSelectionToggle);
        case "DEALER_DRAFT_ADD_CORRECTION_DETAILS":
            switch (action.correctionDetails.get('type')) {
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.DESCRIPTION:
                    return state.setIn(['description'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.COST:
                    return state.setIn(['cost'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.LINE_ITEM_KEY:
                    return state.setIn(['lineItemKey'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.LICENSE_PLATE:
                    return state.setIn(['licensePlate'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.REGISTRATION_STATE:
                    return state.setIn(['registrationState'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.DELIVERY_DATE:
                    return state.setIn(['deliveryDate'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.REGISTRATION_DATE:
                    return state.setIn(['registrationExpiration'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.TEMP_LICENSE_PLATE:
                    return state.setIn(['tempLicensePlate'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.TEMP_REGISTRATION_EXP_DATE:
                    return state.setIn(['tempRegistrationExpDate'], action.correctionDetails.get('value')).set('isDirty', true);
                case constants.DEALER_DRAFT_CORRECTION_DETAILS_TYPE.TEMP_REGISTRATION_STATE:
                    return state.setIn(['tempRegistrationState'], action.correctionDetails.get('value')).set('isDirty', true);   
                default:
                    return state;
            }
        case "DEALER_DRAFT_PAYMENT_RESOLVED":
            return state
                .set('isSendingPaymentRequest', false);
        case "DEALER_DRAFT_PAYMENT_REJECTED":
            return state.set('isSendingPaymentRequest', false);
        case "DEALER_DRAFT_PAYMENT_REQUESTED":
            return state.set('isSendingPaymentRequest', true)
                .set('errorMessage', [])
                .set('isDirty', false);
        case "POP_OVER_DISPLAY_PAYMENT_ACCEPTED_ERROR":
            return state.setIn(['popOver', 'isPopOverDisplayedShowPaymentError'], action.popOverDisplayShowPaymentError, !state.getIn(['popOver', 'isPopOverDisplayedShowPaymentError'], action.popOverDisplayShowPaymentError));
        case "POP_OVER_DISPLAY_REGISTRATION_ERROR":
                return state.setIn(['popOver', 'isPopOverDisplayedShowRegistrationError'], action.popOverDisplayShowRegistrationError, !state.getIn(['popOver', 'isPopOverDisplayedShowRegistrationError'], action.popOverDisplayShowRegistrationError));
        case "POP_OVER_DISPLAY_DELIVERY_DATE_ERROR":
                    return state.setIn(['popOver', 'isPopOverDisplayShowDeliveryDateError'], action.popOverDisplayShowDeliveryDateError, !state.getIn(['popOver', 'isPopOverDisplayShowDeliveryDateError'], action.popOverDisplayShowDeliveryDateError));
        
        default:
            return state;
    }
}
