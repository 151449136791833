import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import { Map, fromJS } from 'immutable';
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { defineMessages, FormattedMessage ,injectIntl} from 'react-intl';
import React from 'react';
import { jwtDecode } from 'jwt-decode';


//This is a gateway for all search functionality.
export function* searchForPOsBy(context, action) {
    try {
        
        yield put({ type: constants.SEARCH_FOR_POS_BY_REQUESTED });
        let searchFilters = action.searchFilters;

        if (searchFilters.searchFor == constants.SEARCH_FILTER_TYPE.VIN8) {
            yield* searchForPOsByVIN8(context, searchFilters);
        }

        if (searchFilters.searchFor == constants.SEARCH_FILTER_TYPE.PURCHASEORDER) {

            if (searchFilters.searchText.length == 0 && searchFilters.days != constants.SEARCH_DURATION_TYPE.DURATION)
                yield* searchForPOsByDuration(context, searchFilters, "dateRestrict");
            else
                yield* searchForPOsByPONumber(context, searchFilters);
        }

        if (searchFilters.searchFor == constants.SEARCH_FILTER_TYPE.PAYMENT) {
            if (searchFilters.searchText.length == 0 && searchFilters.days != constants.SEARCH_DURATION_TYPE.DURATION)
                yield* searchForPOsByDuration(context, searchFilters, "dateRestrcitPayment");
            else
                yield* searchForPOsByPaymentNumber(context, searchFilters);
        }

        if (searchFilters.searchFor == constants.SEARCH_FILTER_TYPE.INVOICE) {

            if (searchFilters.searchText.length == 0 && searchFilters.days != constants.SEARCH_DURATION_TYPE.DURATION)
                yield* searchForPOsByDuration(context, searchFilters, "dateRestrictInvoice");
            else
                yield* searchForPOsByInvoiceNumber(context, searchFilters);
        }
        if (searchFilters.searchFor == constants.SEARCH_FILTER_TYPE.DEALER_SUBMITTED) {

            yield* searchForDealerDraftSubmitted(context, searchFilters);
        }

        if (searchFilters.searchFor == constants.SEARCH_FILTER_TYPE.CLIENT_REF_NO) {
            yield* searchForPOsByClientPONumber(context, searchFilters);
        }

    }
    catch (e) {
        const messages = defineMessages({

            custom_error_message: {
                id: 'custom_error_message',
                description: 'custom_error_message',
                defaultMessage: "No purchase orders found, that match this criteria."
            },
        });
        var customError = helperFunctions.getCustomErrorObject(e);
        var genericError = helperFunctions.getCustomErrorObject(e)!= undefined ?   <FormattedMessage  {...messages.custom_error_message} values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/> : undefined;
        yield put({ type: constants.FETCH_VEH_DATA_FOR_SEARCH_VENDOR_POS_REJECTED, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SET_SEARCH_PO_VIN8_ERROR_MESSAGE, errorMessage: (customError != undefined && customError.length > 0 ? customError[0].message : undefined) ||genericError});
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SEARCH_FOR_POS_BY_REQUESTED, errorObject: e });
}
}

//Individual vin8 and fumid search
function* searchForPOsByVIN8(context, searchFilters) {
    const state = yield select();
    let existedFilters = helperFunctions.emptyStringIfUndefined(state.appState.getIn(['serverData', 'searchPO', 'searchFilters']));
    let isSameSearch = existedFilters.searchFor == searchFilters.searchFor && existedFilters.searchText == searchFilters.searchText && existedFilters.days == searchFilters.days;
    let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
    let languageCode=helperFunctions.getSelectedLanguageCode(state);
    //if (!isSameSearch) {
    // Get vehicle data
    let vehicleDetails = yield call(Api.fetchVehicleByVin8, searchFilters.searchText.trim(), helperFunctions.getToken(state),languageCode);

    //Save to serverData/searchPO before clear from uiData/searchPO
    yield put({type: constants.RESOLVE_SEARCH_FILTERS, searchFilters: fromJS(searchFilters)});

    if(vehicleDetails.length > 1){
        yield put({type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED});
        yield put({type: constants.SAVE_MULTI_VEH_DATA_FOR_PO_CREATION_DATA, vehicleDetails});
        yield call(context.history.push,`/vehicles/vin8/${searchFilters.searchText}/${"SearchPO"}`);
        yield put({type: constants.SEARCH_FOR_POS_BY_RESOLVED,});
    }else {

        yield put({type: constants.SEARCH_FOR_POS_BY_RESOLVED, vehicleDetails: vehicleDetails[0], searchFilters});

        yield* searchForPOByVin8AndVehicleId(context,{vehicleId: vehicleDetails[0].id});

    }
    //}
}

//Individual client PO Number
function* searchForPOsByClientPONumber(context, searchFilters) {
    //Get Purchase order details: 
    const state = yield select();
    let languageCode=helperFunctions.getSelectedLanguageCode(state);    
    let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
    let purchaseOrderData = yield call(Api.searchForPOsByClientPONumber, searchFilters.searchText.trim(),vendorId, helperFunctions.getToken(state),languageCode);
    const isCarWashVendor = state.appState.getIn(['serverData', 'shared', 'serviceClassification']) == constants.CARWASH;
    let selectedCountry=state.appState.getIn(['uiData', 'shared', 'selectedCountry']);

    const jwtToken = jwtDecode(helperFunctions.getToken(state));        
    const readOnlyMode=jwtToken.vendor !== undefined && jwtToken.vendor.permissions!=undefined && !jwtToken.vendor.permissions.PO.includes('ED');

    // Validate
    if (purchaseOrderData.length === 0) {
        throw Error("Cannot load po search data: Invalid Client PO number");
    }
    // Inform application that PO data is fetched
    yield put({ type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED });

    //Save to serverData/searchPO before clear from uiData/searchPO
    yield put({ type: constants.RESOLVE_SEARCH_FILTERS, searchFilters: fromJS(searchFilters) });
   
    if(purchaseOrderData.length > 1){
        yield put({type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED,pos: purchaseOrderData});
        yield call(context.history.push, `/po/search`); 
    }
    else{

    if(!isCarWashVendor)
    {        
        // Navigate to respective PO details page ||
        if (purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.CANCELLED)
            yield call(context.history.push, `/`);
        
        else if (readOnlyMode){
          
            yield call(context.history.push, `/po/view/${purchaseOrderData[0].id}`);
        }

    else if ((purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ||
        purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL) && !purchaseOrderData[0].hasAssociatedClaim)
        yield call(context.history.push, `/po/actionRequired/${purchaseOrderData[0].id}`);

    else if ((purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.APPROVED &&
        purchaseOrderData[0].paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED) && !purchaseOrderData[0].hasAssociatedClaim)
        {
            if(selectedCountry=='MEX' || jwtToken.vendor !== undefined  && !jwtToken.vendor.permissions.PO.includes('ED'))
                yield call(context.history.push, `/po/view/${purchaseOrderData[0].id}`);
        else
        yield call(context.history.push, `/po/edit/${purchaseOrderData[0].id}`);
        }

    else if (purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
        purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
        purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WORK_COMPLETED ||
        purchaseOrderData[0].paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS ||
        purchaseOrderData[0].paymentStatus == constants.PO_PAYMENT_STATUS.PAID ||
        purchaseOrderData[0].hasAssociatedClaim ||
        purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION)
        
        yield call(context.history.push, `/po/view/${purchaseOrderData[0].id}`);
    }
    else 
    {
        // Navigate to respective PO details page ||
        if (purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.CANCELLED)
            yield call(context.history.push, `/carWash`);

        (purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
            purchaseOrderData[0].authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
            purchaseOrderData[0].paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS ||
            purchaseOrderData[0].paymentStatus == constants.PO_PAYMENT_STATUS.PAID)
            
        yield call(context.history.push, `/po/view/${purchaseOrderData[0].id}`);

    }
}
}

export function* searchForPOByVin8AndVehicleId(context,action) {

    const state = yield select();
    let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
    let languageCode=helperFunctions.getSelectedLanguageCode(state);
    // Inform application that PO data is being fetched
    yield put({type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REQUESTED});

    // fetch PO data
    const pos = yield call(Api.searchForPOsByVIN8AndVehicleId, vendorId, action.vehicleId, helperFunctions.getToken(state),languageCode);

    // Save PO data into app state
    yield put({type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED, pos});
    yield call(context.history.push, `/po/search`);

}

//Individual purchase order no search
function* searchForPOsByPONumber(context, searchFilters) {

    //Get Purchase order details: 
    const state = yield select();
    let languageCode=helperFunctions.getSelectedLanguageCode(state);    
    const purchaseOrder = yield call(Api.fetchPurchaseOrder, searchFilters.searchText.trim(), helperFunctions.getToken(state),languageCode);
    const isCarWashVendor = state.appState.getIn(['serverData', 'shared', 'serviceClassification']) == constants.CARWASH;
    let currentVendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
    let vendorAccountType = state.appState.getIn(['serverData', 'shared', 'vendorAccountType']);
    let selectedCountry=state.appState.getIn(['uiData', 'shared', 'selectedCountry']);

    const jwtToken = jwtDecode(helperFunctions.getToken(state));        
    const readOnlyMode=jwtToken.vendor !== undefined && jwtToken.vendor.permissions!=undefined && !jwtToken.vendor.permissions.PO.includes('ED');
   
    // Validate
    if ((purchaseOrder != undefined && purchaseOrder.id != searchFilters.searchText) ||  purchaseOrder.vendorId === "Holman VENDOR") {
        throw Error("Cannot load po search data: Invalid PO number");
    }

    yield put({ type: constants.SEARCH_FOR_POS_BY_RESOLVED, purchaseOrder });

    // Inform application that PO data is fetched
    yield put({ type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED });

    //Save to serverData/searchPO before clear from uiData/searchPO
    yield put({ type: constants.RESOLVE_SEARCH_FILTERS, searchFilters: fromJS(searchFilters) });

    if(!isCarWashVendor)
    {        
        // Navigate to respective PO details page ||
        if (purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.CANCELLED)
            yield call(context.history.push, `/`);
        
        else if (readOnlyMode){
            yield call(context.history.push, `/po/view/${purchaseOrder.id}`);
        }

    else if ((purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ||
      purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL) && !purchaseOrder.isInsurancePo)
        yield call(context.history.push, `/po/actionRequired/${purchaseOrder.id}`);

    else if ((purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.APPROVED &&
      purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED) && !purchaseOrder.isInsurancePo)
        {
            if(selectedCountry=='MEX' || jwtToken.vendor !== undefined  && !jwtToken.vendor.permissions.PO.includes('ED'))
                yield call(context.history.push, `/po/view/${purchaseOrder.id}`);
        else
        yield call(context.history.push, `/po/edit/${purchaseOrder.id}`);
        }

    else if (purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
      purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
      purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WORK_COMPLETED ||
      purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS ||
      purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAID ||
      purchaseOrder.isInsurancePo  ||
      purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_DRIVER_VALIDATION)
        yield call(context.history.push, `/po/view/${purchaseOrder.id}`);
    }
    else 
    {
        // Navigate to respective PO details page ||
        if (purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.CANCELLED)
            yield call(context.history.push, `/carWash`);

        (purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
          purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
          purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS ||
          purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAID)
        yield call(context.history.push, `/po/view/${purchaseOrder.id}`);

    }
}

//Individual invoice no search
function* searchForPOsByInvoiceNumber(context, searchFilters) {

    //Get Purchase order details: 
    const state = yield select();
    let languageCode=helperFunctions.getSelectedLanguageCode(state);
    
    let currentVendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
    const purchaseOrder = yield call(Api.searchForPOsByInvoiceNumber, searchFilters.searchText.trim(), currentVendorId, helperFunctions.getToken(state),languageCode);
    const isCarWashVendor = state.appState.getIn(['serverData', 'shared', 'serviceClassification']) == constants.CARWASH;
    let vendorAccountType = state.appState.getIn(['serverData', 'shared', 'vendorAccountType']);
    let selectedCountry=state.appState.getIn(['uiData', 'shared', 'selectedCountry']);

    // Validate
    if ((purchaseOrder == undefined || purchaseOrder == null) || purchaseOrder.vendorId === "Holman VENDOR") {
        throw Error("Cannot load po search data: Invalid invoice number");
    }

    yield put({ type: constants.SEARCH_FOR_POS_BY_RESOLVED, purchaseOrder });

    // Inform application that PO data is fetched
    yield put({ type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED });

    //Save to serverData/searchPO before clear from uiData/searchPO
    yield put({ type: constants.RESOLVE_SEARCH_FILTERS, searchFilters: fromJS(searchFilters) });

    if(!isCarWashVendor)
    {
        // Navigate to respective PO details page ||
        if (purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.CANCELLED)
            yield call(context.history.push, `/`);

    else if ((purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ||
      purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL) && !purchaseOrder.isInsurancePo)
        yield call(context.history.push, `/po/actionRequired/${purchaseOrder.id}`);

    else if ((purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.APPROVED &&
      purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED) && !purchaseOrder.isInsurancePo){
          if(selectedCountry=='MEX')
              yield call(context.history.push, `/po/view/${purchaseOrder.id}`);
      else
      yield call(context.history.push, `/po/edit/${purchaseOrder.id}`);
      
      }

    else if (purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
      purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
      purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS ||
      purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAID || 
      purchaseOrder.isInsurancePo)
        yield call(context.history.push, `/po/view/${purchaseOrder.id}`);
    }
    else 
    {
        // Navigate to respective PO details page ||
        if (purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.CANCELLED)
            yield call(context.history.push, `/carWash`);

    (purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_ARI_APPROVAL ||
      purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_CLIENT_APPROVAL ||
      purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS ||
      purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAID)
      yield call(context.history.push, `/po/view/${purchaseOrder.id}`);

    }
}

//Individual payment no search
function* searchForPOsByPaymentNumber(context, searchFilters) {
    const state = yield select();

    let languageCode=helperFunctions.getSelectedLanguageCode(state);
    // Check if vehicle data exists in app state
    let existedFilters = helperFunctions.emptyStringIfUndefined(state.appState.getIn(['serverData', 'searchPO', 'searchFilters']));
    let isSameSearch = existedFilters.searchFor == searchFilters.searchFor && existedFilters.searchText == searchFilters.searchText && existedFilters.days == searchFilters.days;

    //if (!isSameSearch) {
    // Inform application that PO data is being fetched
    yield put({ type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REQUESTED });

    // fetch PO data
    let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
    const pos = yield call(Api.searchForPOsByPaymentNumber, vendorId, searchFilters.searchText.trim(), helperFunctions.getToken(state),languageCode);

    //Save to serverData/searchPO before clear from uiData/searchPO
    yield put({ type: constants.RESOLVE_SEARCH_FILTERS, searchFilters: fromJS(searchFilters) });

    // Save PO data into app state
    yield put({ type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED, pos });
    yield call(context.history.push, `/po/search`);
  //}
}

//Group search - This function supports search by purchase orders, payments and Invoices for last 60 or 120
function* searchForPOsByDuration(context, searchFilters, dateRestrictedField) {
    const state = yield select();

    let existedFilters = helperFunctions.emptyStringIfUndefined(state.appState.getIn(['serverData', 'searchPO', 'searchFilters']));
    let isSameSearch = existedFilters.searchFor == searchFilters.searchFor && existedFilters.searchText == searchFilters.searchText && existedFilters.days == searchFilters.days;

    //if (!isSameSearch) {
    // Inform application that PO data is being fetched
    yield put({ type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_REQUESTED });

    // fetch PO data
    let vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
    let days = 0;
    if (searchFilters.days == constants.SEARCH_DURATION_TYPE.LAST_60_DAYS)
        days = '60';
    else if (searchFilters.days == constants.SEARCH_DURATION_TYPE.LAST_120_DAYS)
        days = 120;

    const pos = yield call(Api.searchForPOsByDuration, vendorId, dateRestrictedField, days, helperFunctions.getToken(state));

    //Save to serverData/searchPO before clear from uiData/searchPO
    yield put({ type: constants.RESOLVE_SEARCH_FILTERS, searchFilters: fromJS(searchFilters) });

    // Save PO data into app state
    yield put({ type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED, pos });
    yield call(context.history.push, `/po/search`);
  //}
}

function* searchForDealerDraftSubmitted(context, searchFilters) {
    const state = yield select();
    const messages = defineMessages({
        dealer_submitted_request_error: {
            id: 'dealer_submitted_request_error',
            description: 'dealer_submitted_request_error',
            defaultMessage: "This is not a valid courtesy request"
        },
    });
    try {

        yield put({type: "DEALER_DRAFT_CLEAR_DATA"});

        const requestOrder = yield call(Api.fetchRequestsNoDetails, helperFunctions.getToken(state), searchFilters.searchText.trim());

        let hasDealerDraftEditPermissions = false;
        // Parse token
        const jwtToken = jwtDecode(helperFunctions.getToken(state));
        const audien = jwtToken.aud;

        // If token is for a vendor, set all permissions
        if (audien == "VMS") {
            hasDealerDraftEditPermissions = true;    
        }
        else {
            // If token is for a user-vendor, set only permissions that are available in the token
            if (jwtToken.vendor !== undefined && jwtToken.vendor.permissions.CD !== undefined)
                hasDealerDraftEditPermissions = jwtToken.vendor.permissions.CD.includes("ED");
        }  
        
        if ((requestOrder.status !== "OPEN" && requestOrder.status !== "ACTIVE" ) || !hasDealerDraftEditPermissions) {
            yield call(context.history.push, `/dealerDrafts/view/${searchFilters.searchText.trim()}`);
            yield put({type: "FETCH_REQUEST_NO_DETAILS", requestNo:searchFilters.searchText.trim()})
        }
        else {
            yield call(context.history.push, `/dealerDrafts/edit/${searchFilters.searchText.trim()}`);
            yield put({type: "FETCH_REQUEST_NO_DETAILS", requestNo:searchFilters.searchText.trim()})
        }
        yield put({type: constants.FETCH_PO_DATA_FOR_SEARCH_VENDOR_POS_RESOLVED});
    }catch(e){
        yield put({
            type: constants.SET_SEARCH_PO_VIN8_ERROR_MESSAGE,
            errorMessage: <FormattedMessage {...messages.dealer_submitted_request_error}/>
});
yield put({ type: constants.FETCH_VEH_DATA_FOR_SEARCH_VENDOR_POS_REJECTED});
}
}
