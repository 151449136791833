import * as constants from 'constants/App';
import { Map } from 'immutable';

import {uiDataShared,serverDataShared}  from './Shared';
import {uiDataShopView,serverDataShopView} from '../../screens/PO/ShopView';
import {uiDataOdometerView} from '../../screens/PO/OdometerView';
import {uiDataDriverValidationComplaints, serverDataDriverValidationComplaints} from 'PO/DriverValidationComplaints';
import {uiDataComplaintView,serverDataComplaintView} from '../../screens/PO/ComplaintView';
import {uiDataAddEditView, serverDataAddEditView} from '../../screens/PO/AddEditView';
import {uiDataAdjustment, serverDataAdjustment} from 'PO/Adjustment';
import {uiDataAddServiceLineItemsView,serverDataAddServiceLineItemsView} from '../../screens/PO/AddEditView/screens/AddServiceLineItemsView/reducers/AddServiceLineItemsView';
import {uiDataActionRequired,serverDataActionRequired} from '../../screens/PO/ActionRequired';
import {uiDataClosePO,serverDataClosePO} from '../../screens/PO/ClosePO';
import {uiDataDotInspection,serverDataDotInspection} from '../../screens/PO/ClosePO/screens/DotInspection';
import {uiDataBitInspection,serverDataBitInspection} from '../../screens/PO/ClosePO/screens/BitInspection';
import {uiDataFraInspection,serverDataFraInspection} from '../../screens/PO/ClosePO/screens/FraInspection';
import {uiDataCraneInspection,serverDataCraneInspection} from 'PO/ClosePO/screens/CraneInspection';
import {uiDataSearchPO,serverDataSearchPO} from '../../screens/PO/SearchPO';
import {serverDataHelpFAQSections,serverDataHelpFAQQuestions} from '../../screens/Contact/HelpFAQ';
import initialAppState from './InitialAppState';
import {uiDataLoginView} from '../../screens/Home/Login';
import {uiDataAddNewLineItemsView,serverDataAddNewLineItemsView} from '../../screens/PO/AddEditView/screens/AddNewLineItemsView/reducers/AddNewLineItemsView';
import { clearState,saveState } from '../utils/LocalStorage';
import {serverDataClientParameters} from '../../screens/PO/ClientParameters';
import {uiDataRecoverPassword} from '../../screens/Home/RecoverPassword';
import {uiDataVendorAccountView, serverDataVendorAccountView} from '../../screens/YourAccount/VendorAccountInformation';
import {uiDataMyUnitsView} from '../../screens/YourAccount/MyUnits';
import {uiDataVendorDocuments,serverDataVendorDocuments} from '../../screens/YourAccount/VendorDocuments';
import {uiDataVehicleDocuments,serverDataVehicleDocuments} from '../../screens/Vehicle/VehicleDocuments';
import {uiDataLinkedVendorView} from '../../screens/CorporateAccount'
import {uiDataLinkedVendorRegister} from '../../screens/CorporateAccount/LinkedVendorRegister'
import {uiDataCarWashView,serverDataCarWashView} from '../../screens/CarWash';
import {uiDataDealerDraftDealerRequests} from '../../screens/DealerDrafts/DealerRequests';
import {uiDataDealerDraftRequestsApproval} from '../../screens/DealerDrafts/RequestsApproval';
import {uiDataVehicleSearchCreatePO,serverDataVehicleSearchCreatePO} from '../VehicleSearchCreatePO/reducers/VehicleSearchCreatePO';

//TODO: change to repair history
import {serverDataRepairHistory, uiDataRepairHistory} from '../../screens/PO/RepairHistoryView' ;
import {uiDataHelpFeedbackForm} from '../../screens/Contact/FeedbackForm';
import {uiDataVendorEnrollmentRequestView} from '../../screens/YourAccount/VendorEnrollmentRequest';
import {uiDataVendorEnrollmentView} from '../../screens/YourAccount/VendorEnrollment';
import {uiDataRegistrationView} from '../../screens/YourAccount/Registration';
import {uiDataResetPassword} from '../../screens/Home/ResetPassword';
import { uiDataARIUser, serverDataARIUser } from '../../screens/ARIUser';
import {uiDataChangePassword, serverDataChangePassword} from 'YourAccount/ChangePassword';


function uiData(state = initialAppState.get('uiData'), action) {
    return Map({
        shared: uiDataShared(state.get('shared'), action),
        shopView: uiDataShopView(state.get('shopView'), action),
        odometerView: uiDataOdometerView(state.get('odometerView'), action),
        driverValidationComplaints: uiDataDriverValidationComplaints(state.get('driverValidationComplaints'), action),
        complaintView: uiDataComplaintView(state.get('complaintView'), action),
        addEdit: uiDataAddEditView(state.get('addEdit'), action),
        adjustment: uiDataAdjustment(state.get('adjustment'), action),
        close: uiDataClosePO(state.get('close'), action),
		dotInspection: uiDataDotInspection(state.get('dotInspection'), action),
		bitInspection: uiDataBitInspection(state.get('bitInspection'), action),
		fraInspection: uiDataFraInspection(state.get('fraInspection'), action),
        craneInspection: uiDataCraneInspection(state.get('craneInspection'), action),
        loginView:uiDataLoginView(state.get('loginView'),action),
        addServiceLineItemsView:uiDataAddServiceLineItemsView(state.get('addServiceLineItemsView'), action),
        actionRequired:uiDataActionRequired(state.get('actionRequired'), action),
        addNewLineItemsView: uiDataAddNewLineItemsView(state.get('addNewLineItemsView'), action),
        searchPO: uiDataSearchPO(state.get('searchPO'), action),
        feedbackForm: uiDataHelpFeedbackForm(state.get('feedbackForm'),action),
        recoverPassword: uiDataRecoverPassword(state.get('recoverPassword'),action),
        vendorAccount: uiDataVendorAccountView(state.get('vendorAccount'),action),
        vendorEnrollmentRequestView: uiDataVendorEnrollmentRequestView(state.get('vendorEnrollmentRequestView'),action),
        vendorEnrollmentView: uiDataVendorEnrollmentView(state.get('vendorEnrollmentView'),action),
        vendorMyUnits: uiDataMyUnitsView(state.get('vendorMyUnits'),action),
		vendorDocuments: uiDataVendorDocuments(state.get('vendorDocuments'),action),
		vehicleDocuments: uiDataVehicleDocuments(state.get('vehicleDocuments'),action),
        registrationView: uiDataRegistrationView(state.get('registrationView'),action),
        vendorLinkedAccounts:uiDataLinkedVendorView(state.get("vendorLinkedAccounts"),action),
        vendorLinkedRegister:uiDataLinkedVendorRegister(state.get("vendorLinkedRegister"),action),
        carWashAccount: uiDataCarWashView(state.get("carWashAccount"),action),
        dealerDraftDealerRequests:uiDataDealerDraftDealerRequests(state.get("dealerDraftDealerRequests"),action),
        dealerDraftRequestsApproval:uiDataDealerDraftRequestsApproval(state.get("dealerDraftRequestsApproval"),action),
        vehicleSearchCreatePO:uiDataVehicleSearchCreatePO(state.get("vehicleSearchCreatePO"),action),
        resetPassword: uiDataResetPassword(state.get('resetPassword'),action),
        ariUser: uiDataARIUser(state.get('ariUser'), action),
        changePassword: uiDataChangePassword(state.get('changePassword'), action)
    });
}

export function serverData(state = initialAppState.get('serverData'), action) {
    return Map({
        shared: serverDataShared(state.get('shared'), action),
        shopView: serverDataShopView(state.get('shopView'), action),
        driverValidationComplaints: serverDataDriverValidationComplaints(state.get('driverValidationComplaints'), action),
        complaintView: serverDataComplaintView(state.get('complaintView'), action),        
        view: serverDataAddEditView(state.get('view'), action),
        adjustment: serverDataAdjustment(state.get('adjustment'), action),
        close: serverDataClosePO(state.get('close'), action),
		dotInspection: serverDataDotInspection(state.get('dotInspection'), action),
		bitInspection: serverDataBitInspection(state.get('bitInspection'), action),
		fraInspection: serverDataFraInspection(state.get('fraInspection'), action),
        craneInspection: serverDataCraneInspection(state.get('craneInspection'), action),
        addServiceLineItemsView:serverDataAddServiceLineItemsView(state.get('addServiceLineItemsView'), action),
        actionRequired: serverDataActionRequired(state.get('actionRequired'), action),
        addNewLineItemsView: serverDataAddNewLineItemsView(state.get('addNewLineItemsView'), action),
        searchPO: serverDataSearchPO(state.get('searchPO'), action),
        repairHistory: serverDataRepairHistory(state.get('repairHistory'), action),
        helpFAQSections: serverDataHelpFAQSections(state.get('helpFAQSections'),action),
        helpFAQQuestions: serverDataHelpFAQQuestions(state.get('helpFAQQuestions'),action),
        clientParameters: serverDataClientParameters(state.get('clientParameters'),action),
		vendorDocuments: serverDataVendorDocuments(state.get('vendorDocuments'),action),
		vehicleDocuments: serverDataVehicleDocuments(state.get('vehicleDocuments'),action),
        vendorAccount: serverDataVendorAccountView(state.get('vendorAccount'),action),
        carWashAccount: serverDataCarWashView(state.get("carWashAccount"),action),
        vehicleSearchCreatePO:serverDataVehicleSearchCreatePO(state.get("vehicleSearchCreatePO"),action),
        ariUser: serverDataARIUser(state.get('ariUser'), action),
        changePassword: serverDataChangePassword(state.get('changePassword'), action)
    });
}

export function appReducer(state = initialAppState, action) {
    return Map({
        uiData: uiData(state.get('uiData'), action),
        serverData: serverData(state.get('serverData'), action)
    });
}

// Using this approach instead of Combine reducer
export function reducer(state = {}, action) {
    
    if (action.type=='PERSONA_USER_STORE_SIGN_OUT'){
        
        //preserve persona data          
        //perserve selecated locale information        
        //clear rest of the data        
        //state.appState=appReducer(initialState,action);        
        //set the preserved data again in state
       /* return {
            appState:state.appState
        }*/    
        state.appState= undefined;
    }
    else if(action.type==constants.SIGN_OUT || action.type==constants.ARIUSER__SEARCH_NEW_VENDOR) {        
        state.appState = undefined;
        clearState();
   }

    return {
        appState: appReducer(state.appState, action)
    };
}