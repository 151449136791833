import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import {Map} from 'immutable';
import {connect} from 'react-redux';
import * as constants from 'constants/App';
import './ClientParameterView.css';
import ClientParametersComponent from '../components/ClientParametersComponent';
import ClientParametersSelector from '../selectors/SelectedClientParameters'
import Spinner from 'components/Spinner';
import {falseIfUndefined} from 'utils/HelperFunctions';
import FormWrapper from 'components/FormWrapper';

class ClientParameterView extends React.Component {

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    componentDidMount() {
        this.props.dispatch({type: constants.CLIENT_PARAMETERS_SCREEN_LOADED});
    }


    render() {

        const messages = defineMessages({
            client_parameter_header: {
                id: 'client_parameter_header',
                description: 'Header for client parameters page',
                defaultMessage: 'Client Parameters'
            },
            general_parameter_header: {
                id: 'general_parameter_header',
                description: 'Header for general parameters section',
                defaultMessage: 'General parameters'
            },
            vin_parameter_header: {
                id: 'vin_parameter_header',
                description: 'Header for vin parameters section',
                defaultMessage: 'Parameters for VIN'
            },
            return: {
                id: 'return',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            empty_parameters: {
                id: 'empty_parameters',
                description: 'Description when parameters are empty',
                defaultMessage: 'None at this time'
            }
        });


        let generalParams = this.props.generalParametersSorted == undefined ? Map()
            : this.props.generalParametersSorted.map((param) => {

            return <ClientParametersComponent key={param.get('id')}
                description={param.get('description')}
            />
        });

        let vinParams = this.props.vinParametersSorted == undefined ? Map()
            : this.props.vinParametersSorted.map((param) => {

            return <ClientParametersComponent key={param.get('id')}
                description={param.get('description')}
            />
        });
        if (this.props.getFetchedData) {

            return <Spinner />;
        }
        return <div>
            <FormWrapper key='clientParameterErrorMessage' 
            id="clientParameterErrorMessage" {...this.props} 
            formErrors={this.props.genericErrorMessage} />
            
            <div className="parameters-title__header">
                <FormattedMessage {...messages.client_parameter_header}/>
            </div>

            <div>
                <div className="parameters-spacing__div">
                    <text className="parameters-sub__header">
                        <FormattedMessage {...messages.general_parameter_header}/>
                    </text>

                    <div> {generalParams.size === 0 ?
                        <p className="parameters-empty__description">
                            <FormattedMessage {...messages.empty_parameters}/>
                        </p>
                        :
                        <div  className="parameters-description__div">{generalParams} </div>
                    }
                    </div>
                </div>
                <text className="parameters-sub__header">
                    <FormattedMessage {...messages.vin_parameter_header}/> {this.props.getVinNumber}
                </text>

                <div> {vinParams.size === 0 ?
                    <p className="parameters-empty__description">
                        <FormattedMessage {...messages.empty_parameters}/>
                    </p>
                    :
                    <div className="parameters-description__div">{vinParams} </div>
                }
                </div>
            </div>
            <div className="parameter-container__return">
                <div
                    role="button"
                    onClick={this.onReturnClickHandler.bind(this)}>
                    <img src="/assets/VMS_33button_back_blue.png"/>
                    {' '}
                    <FormattedMessage {...messages.return}/>
                </div>
            </div>

        </div>;


    }

}
function select(state) {

    return {
        getVinNumber: state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'vin']),
        getFetchedData: falseIfUndefined(state.appState.getIn(['serverData', 'clientParameters', 'isFetchingData'])),
        genericErrorMessage: state.appState.getIn(['uiData','shared','errorMessage']),
        ...ClientParametersSelector(state)
    };
}

export default connect(select)(ClientParameterView);