import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import { zeroIfUndefined, emptyMapIfUndefined,getSelectedLanguageCode } from 'utils/HelperFunctions';
import { Schema, arrayOf, normalize } from 'normalizr';
import * as helperFunctions from 'utils/HelperFunctions';
import { Map } from 'immutable';
import moment from 'moment';
import * as fraInspectionEnums from '../data/FraInspectionEnums';

export function* windowShown(context, action) {
    try {
        const state = yield select();                
        		
		const vehicleDetails = state.appState.getIn(['serverData', 'close', 'vehicleDetails']);		
						
		yield put({ type: constants.FRA_INSPECTION__SET_DEFAULT_VALUES, 
			carrierName: vehicleDetails.get('clientName'),
			unitNumber: vehicleDetails.get('ariUnitNo'),		
			year: vehicleDetails.get('year'),		
			make: vehicleDetails.get('make'),				
			licensePlate: vehicleDetails.get('licensePlate'),
			assetType: vehicleDetails.get('assetType') 
		});				
    }
    catch (e) {
        yield put({
            type: constants.FETCH_CLOSE_PO_DATA_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }

}

export function* saveConfirmation(context, action) {
    try {
		yield put({type: constants.FRA_INSPECTION__SAVE_CONFIRMATION_REQUESTED});
        const state = yield select();
        
        const token = helperFunctions.getToken(state);
        
        const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);
		const assetType = state.appState.getIn(['serverData', 'close', 'vendor', 'assetType']);	        
        
		let fraInspectionData = state.appState.getIn(['uiData', 'fraInspection', 'formData']).toJSON();
		const componentDetails = state.appState.getIn(['uiData', 'fraInspection', 'componentDetails'])
			.map(component => {
				return component.set('componentResultCode', fraInspectionEnums.componentResultCode.PASS)
			});
		const componentDetailsJSON = componentDetails.toList().toJSON();
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
       
        // Set defaults
		fraInspectionData['id'] = 0;
		fraInspectionData['vehicleOdometer'] = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'odometer']);
		fraInspectionData['guideWheelLoadLeftFrontCheckOk'] = true;
		fraInspectionData['guideWheelLoadRightFrontCheckOk'] = true;
		fraInspectionData['guideWheelLoadLeftRearCheckOk'] = true;
		fraInspectionData['guideWheelLoadRightRearCheckOk'] = true;
		fraInspectionData['componentDetails'] = componentDetailsJSON;		

        yield call(Api.createFraInspection, poNumber, fraInspectionData, token,vendorLanguage);

		yield put({type: constants.FRA_INSPECTION__DISPLAY, show: false});

        // Continue where request payment left off.
		yield put({type: constants.CLOSE_PO_CLICKED, closePoData: {poNumber: poNumber}});
		
		yield put({type: constants.FRA_INSPECTION__SAVE_CONFIRMATION_RESOLVED});
    }
    catch (e) {
        yield put({
            type: constants.FRA_INSPECTION__SAVE_CONFIRMATION_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FRA_INSPECTION__SAVE_CONFIRMATION_REQUESTED,
            errorObject: e
        });
    } 
}

export function* saveForm(context, action) {
    try {
		yield put({type: constants.FRA_INSPECTION__SAVE_FORM_REQUESTED});

        const state = yield select();        
        const token = helperFunctions.getToken(state);        
        const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);        
		let fraInspectionData = state.appState.getIn(['uiData', 'fraInspection', 'formData']).toJSON();				
		const componentDetails = state.appState.getIn(['uiData', 'fraInspection', 'componentDetails']);
		const componentDetailsJSON = componentDetails.toList().toJSON();
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
       
        // Set defaults
		fraInspectionData['id'] = 0;        
		fraInspectionData['vehicleOdometer'] = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'odometer']);
        fraInspectionData['componentDetails'] = componentDetailsJSON;

        yield call(Api.createFraInspection, poNumber, fraInspectionData, token,vendorLanguage);

		yield put({type: constants.FRA_INSPECTION__DISPLAY, show: false});
		
        // Continue where request payment left off.
		yield put({type: constants.CLOSE_PO_CLICKED, closePoData: {poNumber: poNumber}});
		
		yield put({type: constants.FRA_INSPECTION__SAVE_FORM_RESOLVED});
    }
    catch (e) {
        window.scrollTo(0, 0);
        yield put({
            type: constants.FRA_INSPECTION__SAVE_FORM_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }  
}
