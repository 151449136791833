import * as Locales from "date-fns/locale";
import React, { useState } from "react";
import { Col, Container, Modal, ModalBody, Row } from "react-bootstrap";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useAppState } from "shared/AppState/app-state";
import { ButtonStyled, ModalClose, ModalMainHeader } from "shared/DesignSpecs/components/FormComponents";
import FormWrapper from "shared/components/FormWrapper";
import { useUpdateRepairEstimateEtaMutation } from "shared/types/__generated__/graphql-type";
import styled from 'styled-components';
import Spinner from "shared/components/Spinner";
import InputCalendarTS from "shared/components/InputCalendarTS";
import * as constants from "shared/constants/App";
//#endregion
// #region Styled Components
const ModalStyled = styled(Modal).attrs({
    dialogClassName: "childStyle"
}) `
.childStyle {
  width: 900px;
  max-width: 900px;
  min-height: 600px;
}
.childStyle > div > div {
  min-height: 10em;
}`;
const EtaContainer = styled(Container) `
  padding: 1rem 3rem 3rem 3rem;
`;
const NoteInput = styled.textarea `
  width:90%;
  height:10em;
  font-Weight:500;
  background-color: ${props => props.value === "" || props.value.length < 3 || props.value.length > 900 ? "#f8b6c7" : "none"}
`;
//#endregion
//#region Component
const AccidentAssignmentsEtaModal = ({ showModal, toggleShowModal, submissionCount, repairEstimateId, viewRefetch, estimatedCompletionDate, etaDate, setEtaDate, etaNoteValue, setEtaNoteValue }) => {
    // #region State
    const [errorMessages, setErrorMessages] = useState([]);
    // #endregion
    // #region Ref
    // #endregion
    // #region Hooks
    const intl = useIntl();
    const [appState, dispatch] = useAppState();
    const [updateRepairEstimateEtaMutation, { data, loading, error }] = useUpdateRepairEstimateEtaMutation();
    // #endregion
    // #region Effects
    // #endregion
    // #region Event handlers
    const handleEtaSubmitClicked = () => {
        let isValid = true;
        setErrorMessages(errorMessages => []);
        if (etaDate === undefined) {
            setErrorMessages(errorMessages => errorMessages.concat({ message: intl.formatMessage(messages.accident_assignments_eta_date_error) }));
            isValid = false;
        }
        if (etaNoteValue === "") {
            setErrorMessages(errorMessages => errorMessages.concat({ message: intl.formatMessage(messages.accident_assignments_eta_note_error) }));
            isValid = false;
        }
        else if (etaNoteValue.length < 3 || etaNoteValue.length > 900) {
            setErrorMessages(errorMessages => errorMessages.concat({ message: intl.formatMessage(messages.accident_assignments_eta_note_length_error) }));
            isValid = false;
        }
        if (isValid) {
            updateRepairEstimateEtaMutation({
                variables: {
                    repairEstimateId: repairEstimateId,
                    estimatedCompletionDate: etaDate,
                    noteText: etaNoteValue,
                }
            }).then((result) => {
                if (result.errors) {
                    result.errors.forEach(error => {
                        setErrorMessages(errorMessages => errorMessages.concat({ message: error.message }));
                    });
                }
                else {
                    viewRefetch();
                    onCloseModal();
                }
            }, (error) => {
                var _a;
                if (error.networkError.name == "ServerError") {
                    let serverError = error.networkError;
                    let serverErrorRecord = serverError.result;
                    let serverErrorMessage = (_a = serverErrorRecord.errors[0]) === null || _a === void 0 ? void 0 : _a.message;
                    if (serverErrorMessage == messages.accident_assignments_locked_po_message.defaultMessage || serverErrorMessage == messages.accident_assignments_locked_po_2_message.defaultMessage)
                        setErrorMessages(errorMessages => [...errorMessages, { message: intl.formatMessage(messages.accident_assignments_locked_po_message) }]);
                    else
                        setErrorMessages(errorMessages => [...errorMessages, { message: constants.GENERIC_ERROR_MESSAGE }]);
                }
                else
                    setErrorMessages(errorMessages => [...errorMessages, { message: constants.GENERIC_ERROR_MESSAGE }]);
            });
        }
    };
    const onCloseModal = () => {
        onCleanUp();
        toggleShowModal();
    };
    const onCleanUp = () => {
        setEtaDate(null);
        setEtaNoteValue("");
        setErrorMessages([]);
    };
    // #endregion
    // #region International messages
    const messages = defineMessages({
        accident_assignments_eta_title: {
            id: "accident_assignments_eta_title",
            description: "Update repair ETA",
            defaultMessage: "Update repair ETA"
        },
        cancel: {
            id: "cancel",
            description: "Cancel",
            defaultMessage: "Cancel"
        },
        accident_assignments_eta_body_1: {
            id: "accident_assignments_body_1",
            description: "Enter the estimated completion date for all repairs",
            defaultMessage: "Enter the estimated completion date for all repairs"
        },
        update_eta_link: {
            id: "update_eta_link",
            description: "Update repair ETA",
            defaultMessage: "Update repair ETA"
        },
        accident_assignments_eta_note_body: {
            id: "accident_assignments_eta_note_body",
            description: "Please enter a reason for the change in repair ETA",
            defaultMessage: "Please enter a reason for the change in repair ETA"
        },
        accident_assignments_eta_date_error: {
            id: "accident_assignments_eta_date_error",
            description: "Please enter a valid estimated completion date",
            defaultMessage: "Please enter a valid estimated completion date"
        },
        accident_assignments_eta_note_error: {
            id: "accident_assignments_eta_note_error",
            description: "Please enter a reason for the change in repair ETA",
            defaultMessage: "Please enter a reason for the change in repair ETA"
        },
        accident_assignments_eta_note_length_error: {
            id: "accident_assignments_eta_note_length_error",
            description: "Please enter a reason between 3-900 characters",
            defaultMessage: "Please enter a reason between 3-900 characters"
        },
        accident_assignments_locked_po_2_message: {
            id: "accident_assignments_locked_po_message",
            description: "PO is currently being reviewed by another Holman Technician.",
            defaultMessage: "PO is currently being reviewed by another Holman Technician."
        },
        accident_assignments_locked_po_message: {
            id: "accident_assignments_locked_po_message",
            description: "PO is currently being reviewed by a Holman Technician.",
            defaultMessage: "PO is currently being reviewed by a Holman Technician."
        },
    });
    // #endregion
    // #region Render helpers
    const renderCloseButton = () => {
        return (React.createElement(ModalClose, { role: 'button', id: "close_btn", onClick: () => onCloseModal(), style: { width: "fit-content", marginBottom: "0.5rem" } },
            React.createElement("img", { src: "/assets/VMS_33button_kill_blue.png" }),
            "  ",
            " ",
            React.createElement(FormattedMessage, Object.assign({}, messages.cancel))));
    };
    const getLocalization = (userLocale) => {
        var _a;
        return (_a = Locales[userLocale.substring(0, 2)]) !== null && _a !== void 0 ? _a : Locales.enUS;
    };
    // #endregion  
    // #region Render
    const modifiers = {
        disabled: {
            before: new Date(new Date().toDateString())
        }
    };
    let calendarInputProps = { style: etaDate ? undefined : { backgroundColor: "#f8b6c7" } };
    return (React.createElement(ModalStyled, { show: showModal, backdrop: "static", keyboard: false, style: { overflow: "auto" } },
        React.createElement(ModalBody, null,
            renderCloseButton(),
            loading ? React.createElement(Spinner, { spinnerType: "blue" })
                : React.createElement(React.Fragment, null,
                    React.createElement(FormWrapper, { id: "etaSubmissionErrorMessages", key: "etaSubmissionErrorMessages", formErrors: errorMessages }),
                    React.createElement(EtaContainer, null,
                        React.createElement(ModalMainHeader, null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_eta_title))),
                        React.createElement(Row, { className: "justify-content-center", style: { padding: "5rem 0 3rem 0" } },
                            React.createElement(Col, { lg: 8 },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_eta_body_1))),
                            React.createElement(Col, { lg: 3 },
                                React.createElement(InputCalendarTS, { modifiers: modifiers, initialValue: etaDate ? new Date(etaDate) : undefined, onDateChange: (day) => setEtaDate(day), locale: intl.locale, id: "eta-input", inputProps: calendarInputProps }))),
                        React.createElement(Row, { className: "justify-content-center" },
                            React.createElement(NoteInput, { id: "note-input", value: etaNoteValue, onChange: (e) => { setEtaNoteValue(e.target.value); }, placeholder: intl.formatMessage(messages.accident_assignments_eta_note_body) })),
                        React.createElement(Row, { className: "justify-content-end" },
                            React.createElement(ButtonStyled, { role: "button", onClick: handleEtaSubmitClicked, style: { width: "fit-content", paddingRight: "0", paddingTop: "3.5rem" }, id: "submit_eta_btn" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.update_eta_link)),
                                " ",
                                React.createElement("img", { src: "/assets/VMS_33button_go_blue.png" }))))))));
    // #endregion
};
// #endregion
export default AccidentAssignmentsEtaModal;
