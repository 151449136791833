import React from 'react';
import './RecoverPasswordTooAttemptsComponent.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as constants from 'constants/App';
import { connect } from 'react-redux';
import TranslationIcon from 'components/SiteTranslations';

class RecoverPasswordTooAttemptsComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    onSubmitHandler(e) {
        e.preventDefault();
        this.props.dispatch({ type: constants.NAVIGATE_TO, path: `/login` });
    }

    render() {
        const messages = defineMessages({
            recover_too_attempts_title: {
                id: 'recover_too_attempts_title',
                description: 'too attempts for forgot password',
                defaultMessage: 'Reset your password'
            },
            recover_too_attempts_subtext: {
                id: 'recover_too_attempts_subtext',
                description: 'too attempts subtext for forgot password',
                defaultMessage: 'You have exceeded the number of successful reset password attempts that can be completed in one hour. For your security, a new reset password email will not be sent at this time.'
            },
            recover_too_attempts_subtext_2: {
                id: 'recover_too_attempts_subtext_2',
                description: 'too attempts subtext 2 for forgot password',
                defaultMessage: 'If you have any questions, please contact the U.S. Vendor Relations team at 1-888-683,8327, or the Canadian Vendor Relations team at 1-866-416-6542.'
            },
            return_button: {
                id: 'return_button',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            login_button: {
                id: 'login_button',
                description: 'login button',
                defaultMessage: 'Login'
            }
        });

        return <div className="recover-confirmation-component">
            <div className="recover_confirmation_help_container row">
                <span>
                    <TranslationIcon backgroundColor='blue' />
                </span>
            </div>
            <div className="recover-confirmation__header">
                <span>
                    <FormattedMessage {...messages.recover_too_attempts_title} />
                </span>
            </div>
            <div className="recover-confirmation__body row">
                <div className="recover-confirmation__message">
                <div className="row">
                        <span>
                            <FormattedMessage  {...messages.recover_too_attempts_subtext} />
                        </span>
                    </div>
                    <div className="row">
                        <span>
                            <FormattedMessage  {...messages.recover_too_attempts_subtext_2} />
                        </span>
                    </div>
                </div>
            </div>            
            <div className="recover-confirmation__footer">
                <div className="pull-left recover-confirmation_button"
                    role="button"
                    onClick={this.onReturnClickHandler.bind(this)}>
                    <img src="/assets/VMS_33button_back_blue.png" />
                    {' '}
                    <FormattedMessage {...messages.return_button} />
                </div>
                <div
                    className="pull-right recover-confirmation_button"
                    role="button"
                    onClick={this.onSubmitHandler.bind(this)} >
                    <FormattedMessage {...messages.login_button} />
                    {' '}
                    <img src="/assets/VMS_33button_go_bblue.png" />
                </div>
            </div>
        </div>;
    }
}

function select(state) {
    return {};
}

export default connect(select)(RecoverPasswordTooAttemptsComponent);