import React from 'react';
import './CheckCircle.css';
import classNames from 'classnames';

class CheckCircle extends React.Component {    
	constructor(props) {
        super(props);
        
        this.state = {
			// This determines if the "checked" value is being controlled 
			// from the consuming component.			
			controlledCompnent: this.props.checked !== undefined, 
			uncontrolledChecked: false
        };
    }
	
	handleClick = () => {
		// If disabled, do not respond to click event.
		if (!!this.props.disabled) 
			return;

		if (this.state.controlledCompnent) {
			this.props.onClick(this.props.name, this.props.checked);
		}
		else {
			let checked = !this.state.uncontrolledChecked;
			this.setState(()=>({uncontrolledChecked: checked}));
			this.props.onClick(this.props.name, checked);
		}				
	}	

    render() {		
		const checked = (
			this.state.controlledCompnent
				? this.props.checked 
				: this.state.uncontrolledChecked);

        return (
			<div 
				className={classNames('check_circle', 
					{'check_circle__disabled': this.props.disabled},
					this.props.className)}
				{...(this.props.allowTab && {tabIndex:0})}				
				onClick={this.handleClick}
			>
				<div className='check_circle__image_box'>
					<img
						src={checked
							? "/assets/VMS_checked.png"
							: "/assets/VMS_unchecked.png"}
						className={classNames('check_circle__image', 
							{'check_circle__image--small': this.props.size === 'sm'})}
						width='29'
						height='29'
					/>
				</div>
								
				{this.props.message &&
					<div className='check_circle__message'>				
						{this.props.message}
					</div>
				}
				

            </div>
        );
    }
}

export default CheckCircle;

