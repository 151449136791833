import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import RepairHistory  from 'RepairHistory/RepairHistory';
import Spinner from 'components/Spinner';
import * as configuration from 'configuration';
import './RepairHistoryView.css';
import Title from 'components/Title';
import * as sharedActions from 'actions/App';
import * as constants from 'constants/App';

import RepairHistoryViewSelector from '../selectors/RepairHistoryView';

class RepairHistoryView extends React.Component {

    constructor(props) {
        super(props);        
    }

    componentDidMount() {
        this.props.dispatch({type: constants.REPAIR_HISTORY_SCREEN_LOADED, vehicleId: this.props.routeParams.match.params.vehicleID});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.routeParams.match.params.vehicleID !== this.props.routeParams.match.params.vehicleID)
            this.props.dispatch({type: constants.REPAIR_HISTORY_SCREEN_LOADED, vehicleId: this.props.routeParams.match.params.vehicleID});
    } 

    componentWillUnmount() {
        this.props.dispatch({type: constants.REPAIR_HISTORY_SCREEN_UNLOADED});
    }

    fetchMorePOsClicked() {
        this.props.dispatch({type: constants.REPAIR_HISTORY_SCREEN_FETCH_MORE_POS_CLICKED});
    }

    render() {

        const messages = defineMessages({
            repair_history_of_vehicle: {
                id: 'repair_history_of_vehicle',
                description: 'Repair History',
                defaultMessage: `Repair History for ${this.props.vin8}`
            }
        });

        if (this.props.isfetchingData) {
            return <Spinner />;
        }

        return <div className="container-fluid repair-history__box">
            <div className="repair-history__caption">
                <Title caption={<FormattedMessage {...messages.repair_history_of_vehicle}/>}/>
            </div>
            <RepairHistory lineItems={this.props.vehicleRepairHistory}
                showMoreLink = {true}
                fetchRepairHistory={this.fetchMorePOsClicked.bind(this) }
                nextPageExist = {this.props.nextPageExist}
                returnLink = {'/po/odometer/' + this.props.routeParams.match.params.vehicleId}
                history={this.props.history}
            />

        </div>
            ;
    }
}



export default connect(RepairHistoryViewSelector)(RepairHistoryView);