import React from 'react';
import ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import CorrectionDetailsAdjust from './CorrectionDetailsAdjust';
import CorrectionDetailsPM from './CorrectionDetailsPM';
import CorrectionDetailsReplace from './CorrectionDetailsReplace';
import CorrectionDetailsRepair from './CorrectionDetailsRepair';
import * as constants from 'constants/App';

class CorrectionDetails extends React.Component {
    render() {

        return (
            <div className="correction-details">
                {this.props.correctionType == constants.CORRECTION_TYPE.REPLACE
                    ? <CorrectionDetailsReplace key={constants.CORRECTION_TYPE.REPLACE}
                        handleChange={this.props.handleChange}
                        correctionDetailsTotal={this.props.correctionDetailsTotal}
                        hideLaborInputFields={this.props.hideLaborInputFields}
                        currentLaborRate={this.props.currentLaborRate}
                        currentLaborHours={this.props.currentLaborHours}
                        currentPartsQty={this.props.currentPartsQty}
                        currentPartsCost={this.props.currentPartsCost}
                        currentMonths={this.props.currentMonths}
                        currentMiles={this.props.currentMiles}
                        currentHours={this.props.currentHours}
                        restrictedRepairTypes={this.props.restrictedRepairTypes}
                        partsCostEditable={this.props.partsCostEditable}
                        laborCostEditable={this.props.laborCostEditable}
                        partsCostHidden={this.props.partsCostHidden}
                        laborCostHidden={this.props.laborCostHidden}
                        QtyShouldBeWholeNumber={this.props.QtyShouldBeWholeNumber}
                        isFlatRateFeatureEnabled={this.props.isFlatRateFeatureEnabled}
                        isQtySyncFeatureEnabled={this.props.isQtySyncFeatureEnabled}
                        priceValidations={this.props.priceValidations}
                        partsCheckRef={this.props.partsCheckRef}
                        handlePriceValdationConfirmationButtonClick={this.props.handlePriceValdationConfirmationButtonClick}
                        pricesConfirmedList={this.props.pricesConfirmedList}
                    />
                    : undefined
                }
                {this.props.correctionType == 'None' || this.props.correctionType == ''

                    ? <CorrectionDetailsRepair key={constants.CORRECTION_TYPE_PLACE_HOLDER}
                        handleChange={this.props.handleChange}
                        correctionDetailsTotal={this.props.correctionDetailsTotal}
                        hideLaborInputFields={this.props.hideLaborInputFields}
                        currentLaborRate={this.props.currentLaborRate}
                        currentLaborHours={this.props.currentLaborHours}
                        partsCostEditable={this.props.partsCostEditable}
                        laborCostEditable={this.props.laborCostEditable}
                        laborCostHidden={this.props.laborCostHidden}
                        QtyShouldBeWholeNumber={this.props.QtyShouldBeWholeNumber}
                        isFlatRateFeatureEnabled={this.props.isFlatRateFeatureEnabled}
                    />
                    : undefined
                }
                {//this.props.correctionType == constants.CORRECTION_TYPE.REPAIR || this.props.correctionType == 'None' || this.props.correctionType == ''
                    this.props.correctionType == constants.CORRECTION_TYPE.REPAIR
                        ? <CorrectionDetailsRepair key={constants.CORRECTION_TYPE.REPAIR}
                            handleChange={this.props.handleChange}
                            correctionDetailsTotal={this.props.correctionDetailsTotal}
                            hideLaborInputFields={this.props.hideLaborInputFields}
                            currentLaborRate={this.props.currentLaborRate}
                            currentLaborHours={this.props.currentLaborHours}
                            laborCostEditable={this.props.laborCostEditable}
                            laborCostHidden={this.props.laborCostHidden}
                            QtyShouldBeWholeNumber={this.props.QtyShouldBeWholeNumber}
                            isFlatRateFeatureEnabled={this.props.isFlatRateFeatureEnabled}
                        />
                        : undefined
                }
                {this.props.correctionType == constants.CORRECTION_TYPE.ADJUST
                    ? <CorrectionDetailsAdjust key={constants.CORRECTION_TYPE.ADJUST}
                        handleChange={this.props.handleChange}
                        correctionDetailsTotal={this.props.correctionDetailsTotal}
                        hideLaborInputFields={this.props.hideLaborInputFields}
                        currentLaborRate={this.props.currentLaborRate}
                        currentLaborHours={this.props.currentLaborHours}
                        laborCostEditable={this.props.laborCostEditable}
                        laborCostHidden={this.props.laborCostHidden}
                        QtyShouldBeWholeNumber={this.props.QtyShouldBeWholeNumber}
                        isFlatRateFeatureEnabled={this.props.isFlatRateFeatureEnabled}
                    />
                    : undefined
                }
                {this.props.correctionType == constants.CORRECTION_TYPE.PM
                    ? <CorrectionDetailsPM key={constants.CORRECTION_TYPE.PM}
                        handleChange={this.props.handleChange}
                        correctionDetailsTotal={this.props.correctionDetailsTotal}
                        currentPartsQty={this.props.currentPartsQty}
                        currentPartsCost={this.props.currentPartsCost}
                        partsCostEditable={this.props.partsCostEditable}
                        partsCostHidden={this.props.partsCostHidden}
                        QtyShouldBeWholeNumber={this.props.QtyShouldBeWholeNumber}
                        priceValidations = {this.props.priceValidations}
                        pmCheckRef={this.props.pmCheckRef}
                        handlePriceValdationConfirmationButtonClick={this.props.handlePriceValdationConfirmationButtonClick}
                        pricesConfirmedList={this.props.pricesConfirmedList}
                    />
                    : undefined
                }
            </div>
        );
    }
}
;

export default CorrectionDetails;
