import { createSelector } from 'reselect';
import { emptyMapIfUndefined, falseIfUndefined } from 'utils/HelperFunctions';
import { Map } from 'immutable';
import * as constants from 'constants/App';


const isfetchingDataSelector = state => falseIfUndefined(state.appState.getIn(['uiData', 'repairHistory', 'isFetchingData']));

const vehicleIdSelector = state => state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);

//Return concatenated (joined) descriptions
function joinComplaintsDescriptions(complaints, descriptionsToBeIgnored){   
    return complaints.get('complaints')
    .filter(y=>!descriptionsToBeIgnored.includes(y.get('description')))
    .map(x => x.get('description'))
    .join(', ');
}

const vehicleRepairHistorySelector = createSelector(
    state => state.appState.getIn(['serverData', 'repairHistory', 'services']),
    state => state.appState.getIn(['serverData', 'shared', 'vendorId']),
    state => state.appState.getIn(['serverData', 'shared', 'vendorName']),
    state => state.appState.getIn(['serverData', 'repairHistory', 'complaintDescriptionsToIgnore']),
    (recentServices, vendorId, vendorName, complaintDescriptionsToIgnore) => {
        let vehrecentServices = Map();

        //transform recent services from the app state 
        if (recentServices != undefined) {
            vehrecentServices = recentServices.reduce((p, c) => {
                return p.set(c.get('id'), Map({
                    id: c.get('id'),
                    vendorName: (c.get('vendorId') == vendorId) ? vendorName : constants.ARI_VENDOR,
                    createdDate: c.get('createdDate'),
                    vehicleOdometer: c.get('vehicleOdometer'),                    
                    complaintsDescription: joinComplaintsDescriptions(c,complaintDescriptionsToIgnore) //concatenate complaints description                    
                }));
            }, Map());
        }

        //force sort by create date
        vehrecentServices = vehrecentServices.sortBy(po => new Date(po.get('createdDate'))).reverse();

        return vehrecentServices;
    });

const offsetSelector = state => state.appState.getIn(['uiData', 'repairHistory', 'offset']);

const vin8Selector = createSelector(
    state => state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'vin']),
    (vin) => {
        if(vin==undefined) 
            return '';
        else 
            return vin.substr(vin.length - 8, vin.length);
    });

const nextPageExistSelector = state => state.appState.getIn(['serverData', 'repairHistory', 'nextPageExist']);

const RepairHistoryView = createSelector(
    vehicleRepairHistorySelector,
    isfetchingDataSelector,
    vehicleIdSelector,
    offsetSelector,
    vin8Selector,
    nextPageExistSelector,
    (vehicleRepairHistory,
        isfetchingData,
        vehicleId,
        currentOffset,
        vin8,
        nextPageExist) => {
        return {
            vehicleRepairHistory,
            isfetchingData,
            vehicleId,
            currentOffset,
            vin8,
            nextPageExist
        };
    }
);

export default RepairHistoryView;