import { createSelector } from 'reselect';
import { falseIfUndefined } from 'utils/HelperFunctions';
import {emptyMapIfUndefined,emptyStringIfUndefined} from 'utils/HelperFunctions';
import {Map} from "immutable";

const isfetchingDataSelector = state => falseIfUndefined(state.appState.getIn(['uiData', 'loginView','isFetchingData']));
const errorMessageDataSelector = state => state.appState.getIn(['uiData', 'loginView', 'loginErrorMessage']);
const selectedCountryDataSelector=state=>state.appState.getIn(['uiData','shared','selectedCountry']);
const genericErrorMessageDataSelector = state => state.appState.getIn(['uiData', 'loginView', 'genericLoginErrorMessage']);

const entireBulletinMessage = state => emptyMapIfUndefined(state.appState.getIn(['serverData','shopView','bulletinMessage']));

const getPrimaryBulletinSelector = createSelector(entireBulletinMessage,(bulletinList) => {

    return bulletinList.filter((params) => {
            return params.get('bulletinType') === 'primary';
        }

    ).sortBy((param)=> -param.get("id"));

});

const getSecondaryBulletinSelector = createSelector(entireBulletinMessage,(bulletinList) => {

    return bulletinList.filter((params) => {
            return params.get('bulletinType') === 'secondary';
        }

    ).sortBy((param)=> -param.get("id"));

});


const LoginHeader = createSelector(
    isfetchingDataSelector,
    errorMessageDataSelector,
    selectedCountryDataSelector,
    entireBulletinMessage,
    genericErrorMessageDataSelector,
    getPrimaryBulletinSelector,
    getSecondaryBulletinSelector,
    (isfetchingData,errorMessage,selectedCountry,bulletinMessage,genericErrorMessage,primaryBulletin,secondaryBulletin) => {
        return {
            isfetchingData, errorMessage,selectedCountry,bulletinMessage,genericErrorMessage,primaryBulletin,secondaryBulletin
        }
    }
);

export default LoginHeader;