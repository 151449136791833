import {call, put, select} from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';


export function* receivePmAtaParameters(action){
    try {
        yield put({type: constants.FETCH_DATA_FOR_ATA_PM_PARAMETERS});

        // Read data from app state
        const state = yield select();

        const vehicleId = state.appState.getIn(['serverData','shared','vehicleDetails','id']);
        const complaintCode = 'MA99';
        const productCode = action.productCode != undefined && action.productCode.size > 0 ? action.productCode.map(x=>x.get('productCode')).join(",") : '';
        let currentParameters;
        
        if(vehicleId!=undefined && action.productCode != undefined && action.productCode.size > 0)
            currentParameters = yield call(Api.fetchAtaPmParameters,vehicleId,productCode,complaintCode, helperFunctions.getToken(state));

        yield put({ type: constants.SAVE_ATA_PM_PARAMETERS, currentParameters });
        yield put({type: constants.SEND_CLICK_ATA_PM_PARAMETERS_RESOLVED});
    }
    catch (e) {
        yield put({type: constants.SEND_CLICK_ATA_PM_PARAMETERS_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
        yield put({type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e)});
    }





}


