import React from 'react';
import  ReactDoM from 'react-dom';
import './LinkToUrl.css';
import {Link} from 'react-router-dom';

class LinkToUrl extends React.Component {
    render() {
        return (
            <div className="linkToUrl">
                <Link to={this.props.url}>
                    {this.props.imageAlignment == 'left'
                    ? <div><img src={this.props.imagePath}/> {this.props.caption} </div>
                    : <div> {this.props.caption} <img src={this.props.imagePath}/> </div> 
                    }
                   
                </Link> 
            </div>
        );
    }
}


export default LinkToUrl;
