import React from 'react';
import {defineMessages, FormattedMessage,injectIntl} from 'react-intl';
import './QuestionSelectionsComponent.css';
import {Map} from 'immutable';


class QuestionSelectionsComponent extends React.Component {

    onTheClick() {

        let feedBackType = Map({
            comments:this.props.login == false ? '' : this.refs.suggestion.value,
            services: this.props.login == false ? '' : this.refs.services.value,
            prospective: this.props.login == false ? this.refs.prospective.value : ''
        });

        this.props.onSaveForm(feedBackType);

    }
render(){

    const {formatMessage} = this.props.intl;

    const messages = defineMessages({
        suggestion_dropdown_question: {
            id: 'suggestion_dropdown_question',
            description: 'Question for what you have to say',
            defaultMessage: 'What do you have to say?'
        },
        section_dropdown_question: {
            id: 'section_dropdown_question',
            description: 'Question for which section your feedback is for',
            defaultMessage: 'What section is your feedback for?'
        },
        comment_title: {
            id: 'comment_title',
            description: 'Comment title for dropdown',
            defaultMessage: 'Comment/Suggestion'
        },
        criticism_title: {
            id: 'criticism_title',
            description: 'Criticism selection',
            defaultMessage: 'Criticism'
        },
        praise_title: {
            id: 'praise_title',
            description: 'Praise selection',
            defaultMessage: 'Praise'
        },
        question_title: {
            id: 'question_title',
            description: 'Question selection',
            defaultMessage: 'Question'
        },
        problem_title: {
            id: 'problem_title',
            description: 'Problem selection',
            defaultMessage: 'Found a Problem'
        },
        section_question_title: {
            id: 'section_question_title',
            description: 'Web site general selection',
            defaultMessage: 'Web site general'
        },
        create_po_title: {
            id: 'create_po_title',
            description: 'create po pages selection',
            defaultMessage: 'Create PO'
        },
        close_po_title: {
            id: 'close_po_title',
            description: 'close po selection',
            defaultMessage: 'Close PO'
        },
        edit_po_title: {
            id: 'edit_po_title',
            description: 'edit po title selection',
            defaultMessage: 'Edit PO'
        },
        search_po_title: {
            id: 'search_po_title',
            description: 'search po selection',
            defaultMessage: 'Search PO'
        },
        po_dashboard_title: {
            id: 'po_dashboard_title',
            description: 'po dashboard selection',
            defaultMessage: 'PO Dashboard'
        },
        other_title: {
            id: 'other_title',
            description: 'Other selection',
            defaultMessage: 'Other'
        },
        radio_title:{
            id: 'radio_title',
            description: 'Prospective Supplier Radio button description',
            defaultMessage: 'Prospective supplier'
        }

    });

    return <div>
        {
            this.props.login == false ? <div className="feedback-prospective-supplier-radio__format">
                <form  name="prospective_radio_form">
                <input type="radio" name="prospective" value="supplier" ref="prospective"
                       onClick={this.onTheClick.bind(this)}/>
                    <FormattedMessage {...messages.radio_title}/>
                    <br/>
                </form>
            </div>
                :
                <div className="Feedback-dropdown__wrapper">
                    <div className="feedback-question__format">
                        <div className="feedback-question-style__text">
                            <FormattedMessage {...messages.suggestion_dropdown_question}/>
                        </div>
                        <div className="feedback-dropdown-specific__spacing">
                            <FormattedMessage {...messages.section_dropdown_question}/>
                        </div>
                    </div>
                    <div className="feedback-dropdown-container__wrapper">
                        <div>
                            <select  className="feedback-dropdown__text-selection" onChange={this.onTheClick.bind(this)}
                                    ref="suggestion" defaultValue="commentTitle" >
                                <option value="commentTitle" disabled>
                                    { formatMessage(messages.comment_title)}
                                </option>
                                <option value="criticism">
                                    { formatMessage(messages.criticism_title)}
                                </option>
                                <option value="praise">
                                    { formatMessage(messages.praise_title)}
                                </option>
                                <option value="question">
                                    { formatMessage(messages.question_title)}
                                </option>
                                <option value="problem">
                                    { formatMessage(messages.problem_title)}
                                </option>
                            </select>
                        </div>

                        <div className="feedback-dropdown-specific__spacing">
                            <select className="feedback-dropdown__text-selection" onChange={this.onTheClick.bind(this)}
                                    ref="services" defaultValue="questionTitle">
                                <option value="questionTitle" disabled>
                                    { formatMessage(messages.section_question_title)}
                                </option>
                                <option value="Create PO">
                                    { formatMessage(messages.create_po_title)}
                                </option>
                                <option value="Close PO">
                                    { formatMessage(messages.close_po_title)}
                                </option>
                                <option value="Edit PO">
                                    { formatMessage(messages.edit_po_title)}
                                </option>
                                <option value="Search PO">
                                    { formatMessage(messages.search_po_title)}
                                </option>
                                <option value="PO Dashboard">
                                    { formatMessage(messages.po_dashboard_title)}
                                </option>
                                <option value="Other">
                                    { formatMessage(messages.other_title)}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
        }
    </div>


}


}

export default injectIntl(QuestionSelectionsComponent);