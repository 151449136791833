import React from "react";
import "./PaymentStatusBulletin.css";
import { defineMessages, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { emptyMapIfUndefined, falseIfUndefined } from "utils/HelperFunctions";
import ShopViewSelector from "../selectors/ShopView";
import { withRouter } from "react-router-dom";

class PaymentStatusBulletin extends React.Component {
  onReturnClickHandler(e) {
    e.preventDefault();
    window.history.back();
  }

  render() {
    const messages = defineMessages({
      return: {
        id: "return",
        description: "Return button",
        defaultMessage: "Return",
      },
      payment_status_bulletin_body: {
        id: "payment_status_bulletin_body",
        description: "third bulletin message",
        defaultMessage:"Recent changes to your bank account information have placed all payments on a temporary hold.  Please be prepared to receive a phone call from Holman to verify these changes to allow payments to resume."
      },
      message_title: {
        id: "message_title",
        description: "Message Title",
        defaultMessage:"Payment details update in progress"
      },
    });



    return (
      <div>
        <div>
                <h1 className="payment-component__header">
                  <FormattedMessage
                    id="alert_message_title" {...messages.message_title}
                  />
                </h1>
                <p className="bulletin-component__message">
                <FormattedMessage {...messages.payment_status_bulletin_body} />
                </p>
                <div
                  className="bulletin-component__return"
                  role="button"
                  onClick={this.onReturnClickHandler.bind(this)}
                >
                  <img src="/assets/VMS_33button_back_blue.png" />{" "}
                  <FormattedMessage {...messages.return} />
                </div>
              </div>
      </div>
    );
  }
}
function select(state, props) {
  return {
    ...ShopViewSelector(state, props),
  };
}

export default withRouter(connect(select)(PaymentStatusBulletin));
