// #region Imports
import React from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components";
import {
    ModalClose,
    ModalMainHeader,
    ModalBodyTable,
    ButtonRowStyled,
    ButtonStyled,
    Row
} from "DesignSpecs/components/FormComponents";
// #endregion

// #region Styled Components
const Main = styled.div`	
  padding-bottom: 1rem;
`;

const Header = styled.div`
  text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const DeleteButtonStyled = styled.div`
  cursor: ${({ disable }) => disable ? `not-allowed` : `pointer`};
  padding-top: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 700;
  color: red;
`;

// #endregion

// #region Component
const MissingProductCodeInformationStep = ({
    complaintCode,
    lineItemDescription,
    onReturn,
    onContinue,
}) => {
    // #region State
    // #endregion

    // #region Ref
    // #endregion

    // #region Effects
    // #endregion

    // #region Event handlers
    // #endregion

    // #region International messages
    const messages = defineMessages({
        missing_product_code_step_continue: {
            id: "missing_product_code_step_continue",
            description: "Continue",
            defaultMessage: "Continue"
        },
        missing_product_code_step_return: {
            id: "missing_product_code_step_return",
            description: "Delete line",
            defaultMessage: "Delete line"
        },
        add_new_line_item_caption: {
            id: 'add_new_line_item_caption',
            description: 'Add new line item caption',
            defaultMessage: 'Add {complaint_description} items'
        },
        missing_product_code_information_1: {
            id: 'missing_product_code_information',
            description: 'Missing product code information',
            defaultMessage: 'Note from Holman: The line item for <line_description></line_description> was added without a product code. Please add the correct product code and pricing, if applicable.',
            values: { line_description: data => <b>{lineItemDescription}</b> }
        },
        missing_product_code_information_2: {
            id: 'missing_product_code_information_2',
            description: 'Missing product code information',
            defaultMessage: 'Click Continue to provide missing product code information.'
        },
        missing_product_code_information_3: {
            id: 'missing_product_code_information_3',
            description: 'Missing product code information',
            defaultMessage: 'Click Delete line to delete this line item from the Purchase Order.'
        }
    });
    // #endregion

    // #region Render helpers

    const renderContinueButton = () => (
        <ButtonStyled
            role="button"
            onClick={onContinue}
        >
            <FormattedMessage {...messages.missing_product_code_step_continue} />
            {" "}
            <img src="/assets/VMS_33button_go_bblue.png" />
        </ButtonStyled>
    );

    const renderReturnButton = () => (
        <DeleteButtonStyled
          role="button"
          onClick={onReturn}
        >
          <img src="/assets/VMS_28button_delete_blue.png" />
          {" "}
          <FormattedMessage
            {...messages.missing_product_code_step_return}
          />
        </DeleteButtonStyled>
      );
    // #endregion  

    // #region Render
    return (
        <Main>
            <ModalMainHeader>
                {complaintCode.get('id') !== undefined ?
                    <FormattedMessage
                        values={{
                            complaint_description:
                                <FormattedMessage
                                    id={complaintCode.get('id')}
                                    description={complaintCode.get('description')}
                                    defaultMessage={complaintCode.get('description')} />
                        }}
                        {...messages.add_new_line_item_caption} />
                    : undefined}
            </ModalMainHeader>

            <ModalBodyTable>
                <Row/>
                <Row/>
                <FormattedMessage {...messages.missing_product_code_information_1} />
                <Row/>
                <FormattedMessage {...messages.missing_product_code_information_2} />
                <Row/>
                <FormattedMessage {...messages.missing_product_code_information_3} />
                <Row/>
                <Row/>
            </ModalBodyTable>

            <ButtonRowStyled>
                {renderReturnButton()}
                {renderContinueButton()}
            </ButtonRowStyled>

        </Main>
    );
    // #endregion
};
// #endregion

export default injectIntl(MissingProductCodeInformationStep);