const allManufacturers = [
    { value: 'BRI', name: 'BRIDGESTONE', isSelected: false },
    { value: 'CEN', name: 'CENTENNIAL', isSelected: false },
    { value: 'CON', name: 'CONTINENTAL', isSelected: false },
    { value: 'COO', name: 'COOPER', isSelected: false },
    { value: 'DUN', name: 'DUNLOP', isSelected: false },
    { value: 'FIR', name: 'FIRESTONE', isSelected: false },
    { value: 'GEN', name: 'GENERAL', isSelected: false },
    { value: 'GOO', name: 'GOODYEAR', isSelected: false },
    { value: 'HAN', name: 'HANKOOK', isSelected: false },
    { value: 'MIC', name: 'MICHELIN', isSelected: false },
    { value: 'MUL', name: 'MULTI-MILE', isSelected: false },
    { value: 'NIT', name: 'NITTO', isSelected: false },
    { value: 'NOK', name: 'NOKIAN', isSelected: false },
    { value: 'NOR', name: 'NORDMAN', isSelected: false },
    { value: 'OTH', name: 'OTHER', isSelected: false },
    { value: 'PIR', name: 'PIRELLI', isSelected: false },
    { value: 'SUM', name: 'SUMITOMO', isSelected: false },
    { value: 'TOY', name: 'TOYO', isSelected: false },
    { value: 'UNI', name: 'UNIROYAL', isSelected: false },
    { value: 'YOK', name: 'YOKOHAMA', isSelected: false }
]
export default allManufacturers;