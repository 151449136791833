import { Map, fromJS } from 'immutable';
import * as constants from 'constants/App';

export function saveOdometerDetails(newOdometer,newEngineHours,vendorReferenceNo,vendorRequisitioner,clientReferenceNo) {
    return {type: constants.SAVE_ODOMETER_DATA, newOdometer,newEngineHours,vendorReferenceNo,vendorRequisitioner,clientReferenceNo}
}

export function saveVehicleAtShopDetails(isVehicleAtShop) {
    return {type: constants.VEHICLE_AT_SHOP_SELECTION_CHANGE,isVehicleAtShop}
}

export function clearOdometerDetails() {
    return {type: constants.CLEAR_ODOMETER_DATA}
}

export function odometerScreenLoaded(vehicleId,shouldLoadLaborRate) {
    const data = {vehicleId: vehicleId, shouldLoadLaborRate: shouldLoadLaborRate};
    return {type: constants.ODOMETER_SCREEN_LOADED, data}
}

