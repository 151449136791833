import React from 'react';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import { Overlay, Popover } from 'react-bootstrap';

import './RailGearInformation.css';

class RailGearInformation extends React.Component {
	constructor(props){
		super(props);
		this.rearSerialNumberRef = React.createRef();
		this.frontSerialNumberRef = React.createRef();
		this.modelRef = React.createRef();
	}

	handleChange = (event) => {        
        this.props.setFormData(event.currentTarget.id, event.currentTarget.value);
	}
	
    render() {
        const messages = defineMessages({
            fra_inspection__rail_gear: {
                id: 'fra_inspection__rail_gear',
                description: 'Rail gear title',
                defaultMessage: 'Rail gear'
			},
			fra_inspection__model: {
                id: 'fra_inspection__model',
                description: 'Model',
                defaultMessage: 'Model'
			},
			fra_inspection__rail_gear_model_required: {
				id: 'fra_inspection__rail_gear_model_required',
                description: 'Required',
                defaultMessage: 'Provide model'
			},
			fra_inspection__front_serial_number: {
                id: 'fra_inspection__front_serial_number',
                description: 'Front serial number',
                defaultMessage: 'Front serial number'
			},
			fra_inspection__front_serial_required: {
				id: 'fra_inspection__front_serial_required',
                description: 'Required',
                defaultMessage: 'Provide front serial number'
			},
			fra_inspection__rear_serial_number: {
                id: 'fra_inspection__rear_serial_number',
                description: 'Rear serial number',
                defaultMessage: 'Rear serial number'
			},
			fra_inspection__rear_serial_required: {
				id: 'fra_inspection__rear_serial_required',
                description: 'Required',
                defaultMessage: 'Provide rear serial number'
			},
        });

        return (
            <div>                    

                <div className='row rail_gear_information_row'>
					<div className='col-md-11 rail_gear_information_title'>
						<FormattedMessage {...messages.fra_inspection__rail_gear} />
					</div>
				</div>
				
				<div className='row rail_gear_information_row'>
					
					<div className='col-md-4 rail_gear_information_col'>
						<label><FormattedMessage {...messages.fra_inspection__model} /></label>
						<div>
							<input 
								type='text' 
								className='rail_gear_information_input' 
								id='railGearModel' 							
								maxLength='200'
								value={this.props.formData.get('railGearModel')}
								onChange={this.handleChange}
								ref={this.modelRef}
							/>
							{ !!this.props.formValidationMessages.get('railGearModel') &&
								<Overlay
								target={this.modelRef.current}
								show="true"
								placement='top'>
									<Popover 
									id='railGearModelOverlay' 
									className='rail_gear_information__error_message'
								>
									<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('railGearModel')])}/>
									</RawIntlProvider>
								</Popover>
								</Overlay>
							}
						</div>
					</div>

					<div className='col-md-4 rail_gear_information_col'>
						<label><FormattedMessage {...messages.fra_inspection__front_serial_number} /></label>
						<div>
							<input 
								type='text' 
								className='rail_gear_information_input' 
								id='railGearFrontSerialNumber' 							
								maxLength='50'
								value={this.props.formData.get('railGearFrontSerialNumber')}
								onChange={this.handleChange}
								ref={this.frontSerialNumberRef}
							/>
							{ !!this.props.formValidationMessages.get('railGearFrontSerialNumber') &&
								<Overlay
								target={this.frontSerialNumberRef.current}
								show="true"
								placement='top'>
									<Popover 
									id='railGearFrontSerialNumberOverlay' 
									className='rail_gear_information__error_message'
								>
									<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('railGearFrontSerialNumber')])}/>
									</RawIntlProvider>
								</Popover>
								</Overlay>
							}
						</div>
					</div>

					<div className='col-md-4 rail_gear_information_col'>
						<label><FormattedMessage {...messages.fra_inspection__rear_serial_number} /></label>
						<div>
							<input 
								type='text' 
								className='rail_gear_information_input' 
								id='railGearRearSerialNumber' 							
								maxLength='50'
								value={this.props.formData.get('railGearRearSerialNumber')}
								onChange={this.handleChange}
								ref={this.rearSerialNumberRef}
							/>
						</div>
						{ !!this.props.formValidationMessages.get('railGearRearSerialNumber') &&
							<Overlay
							target={this.rearSerialNumberRef.current}
							show="true"
							placement='top'>
								<Popover 
									id='railGearRearSerialNumberOverlay' 
									className='rail_gear_information__error_message'
								>
									<RawIntlProvider value={this.props.intl}>
									<FormattedMessage {...(messages[this.props.formValidationMessages.get('railGearRearSerialNumber')])}/>
									</RawIntlProvider>
								</Popover>
							</Overlay>
							}
					</div>

				</div>           

            </div>
        )
    }
};

export default injectIntl(RailGearInformation);