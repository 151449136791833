import * as configuration from 'configuration';
import axios from 'axios';
import { Map, fromJS } from 'immutable';

var getHeaders = (state) => {
    return {
        headers: {
            'Accept': 'application/json',
            'Authorization': state.appState.getIn(['serverData', 'shared', 'token'])
        }
    };
};

function getATACodes(state) {
    return axios.get(`${configuration.serviceURL}ataCodes?fields=code,description,complaintcode,correctiontypesrestricted`, getHeaders(state));
}
//Gets ata codes
export function getATACodesData(componentName, state, callback) {
    axios.all([getATACodes(state)])
        .then(axios.spread((ataCodes) => {
            if (ataCodes.status == '200') {
                if (ataCodes.data.errorDescription == "") {
                    // Convert json data to Immutable object
                    let immutableATACodesResult = {
                        ataCodes: ataCodes.data,
                    };
                    callback(null, immutableATACodesResult);
                }
                else {
                    let message = "\n" + ataCodes.data.errorDescription;
                    callback(message, null);
                }
            }
            else
                callback('Response was not OK', null);
        })).catch(function (response) {
            if (response instanceof Error) {
                // Something happened in setting up the request that triggered an Error
                const errorMessage = response.message != undefined ? response.message : response.statusText;
                console.log('Error', response);
                //stopSpinnerAndDisplayError(componentName, errorMessage, dispatch);
                callback(errorMessage, null);
            }
            else {
                const errorMessage = response.message != undefined ? response.message : response.statusText;
                callback(response.status, errorMessage);
            }
        })

        ;
}


function fetchRestrictedATACodesURL(state) {
    return axios.get(`${configuration.serviceURL}v1/ataCodes/restricted?fields=code,repairType,restrictedCode,restrictedRepairType`, getHeaders(state));
}

//Gets ata codes for the search keyword
export function fetchRestrictedATACodes(state, callback) {
    axios.all([fetchRestrictedATACodesURL(state)])
        .then(axios.spread((restrictedATACodes) => {
            if (restrictedATACodes.status == '200') {
                if (restrictedATACodes.data.errorDescription == "" || restrictedATACodes.data.errorDescription == null) {

                    // Convert json data to Immutable object
                    let immutableATACodesResult = {
                        ataCodes: restrictedATACodes.data,
                    };
                    callback(null, immutableATACodesResult);
                }
                else {
                    let message = "\n" + restrictedATACodes.data.errorDescription;
                    callback(message, null);
                }
            }
            else
                callback('Response was not OK', null);
        })).catch(function (response) {
            if (response instanceof Error) {
                // Something happened in setting up the request that triggered an Error
                const errorMessage = response.message != undefined ? response.message : response.statusText;
                console.log('Error', response);
                //stopSpinnerAndDisplayError(componentName, errorMessage, dispatch);
                callback(errorMessage, null);
            }
            else {
                const errorMessage = response.message != undefined ? response.message : response.statusText;
                callback(response.status, errorMessage);
            }
        });
}


export function downLoadRemoteFile(state, fileUrl, selectedLocale, callback) {    
    axios({
        method: 'get',
        url: fileUrl,
        responseType: 'arraybuffer',
        headers:{'Accept-Language': selectedLocale}
    }).then(response => {
        SaveFile(response.data, getFileName(response.headers["content-disposition"]), response.headers["content-type"]);

        callback(null, null);
    }).catch(error => {        
        //var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data));
        var decodedString= new TextDecoder("utf-8").decode(new Uint8Array(error.response.data));
        var errorInstance = JSON.parse(decodedString);
        callback(errorInstance.error.code, errorInstance.error.message);
    });
}

function SaveFile(data, filename, mime) {
    var blob = new Blob([data], { type: mime || 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were 
        // revoked by closing the blob for which they were created. 
        // These URLs will no longer resolve as the data backing 
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    }
    else {
        var blobURL = window.URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);
        tempLink.setAttribute('target', '_blank');
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    }
}

function getFileName(contentDisposition) {
    var fileName = "";
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        var filenNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
        }
    }

    return fileName;
}