// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './Upload.css';

const Upload = ({onClick}) => {
    const messages = defineMessages({
            documents_upload: {
                id: 'documents_upload',
                description: 'Link to upload new document',
                defaultMessage: 'Upload new document'
            }
        });

    return <div className='documents__upload'>
        <span onClick={onClick} className='documents__upload_button' role="button">
            <FormattedMessage {...messages.documents_upload}/>                
            {' '}<img src='/assets/VMS_28button_add_blue.png'/>
        </span>
    </div>
}

export default Upload;