// Libraries
import React from 'react'
// Our stuff
import './BlueBorder.css';

const BlueBorder = (props) => {    
    return <div className='your_account__blue_border'>
        {props.children}
    </div>
}

export default BlueBorder;