// libraries
import React from 'react';
import {connect} from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
// our stuff
import * as constants from 'constants/App';
import UploadStaging from '../components/UploadStaging';
import CancelButton from '../components/buttons/CancelButton';
import BrowseButton from '../components/buttons/BrowseButton';

class BrowseStep extends React.Component {     
    onBrowseClick() {        
        this.dropzone.open();
    }       

    render() {
        return <div>
        
            <UploadStaging onDrop={this.props.onDrop} dropzoneRef={(ref)=>{this.dropzone=ref}}/>                        

            <Row>
                <Col sm={6}>
                    <CancelButton onClick={this.props.onCancelClick}/>
                </Col>            
                <Col sm={6}>                                    
                    <BrowseButton onClick={this.onBrowseClick.bind(this)}/>                
                </Col>            
            </Row>

        </div>        
    }
}

const mapStateToProps = (state, props) => {
    return {                
        allowToggleDocumentVisible: state.appState.getIn(['uiData', 'vendorDocuments', 'allowToggleDocumentVisible']),        
        files: state.appState.getIn(['serverData', 'vendorDocuments', 'files']),        
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {        
        onCancelClick: () => {            
            dispatch({type: constants.CLEAR_FILE_INFO_VENDOR_DOCUMENTS});
            dispatch({type: constants.SET_VENDOR_DOCUMENTS_STEP, step: constants.VENDOR_DOCUMENT_STEPS.DOCUMENTS})
        },
        onDrop: (acceptedFiles) => {
            if (acceptedFiles && acceptedFiles.length>0) {                
                props.onDrop(acceptedFiles);

                let createdDate = moment().utc().format();
                dispatch({type: constants.VENDOR_DOCUMENTS_SET_UPLOAD_FILE_NAME, file: acceptedFiles[0].name, createdDate});
                dispatch({type: constants.SET_VENDOR_DOCUMENTS_STEP, step: constants.VENDOR_DOCUMENT_STEPS.UPLOAD});
            }
        }       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrowseStep);