/**
 * Created by prboyapa on 7/20/2016.
 */
import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import './ConfirmMessageBox.css';

export default class ConfirmMessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }


    showModal() {
        this.setState({ show: true });
    }

    hideModal() {
        this.setState({ show: false });
    }

    onOKClick(e) {
        e.preventDefault();
        this.hideModal();

        if (this.props.onOKClickEvent != undefined && this.props.onOKClickEvent != null)
            this.props.onOKClickEvent();
    }

    onCancelClick(e) {
        e.preventDefault();
        this.hideModal();
        if (this.props.onCancelClickEvent != undefined && this.props.onCancelClickEvent != null)
            this.props.onCancelClickEvent();
    }

    render() {

        return (
            <Modal
                {...this.props}
                show={this.state.show}
                dialogClassName="custom-modal">
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <span className="confirm_message_box__header">
                                    {
                                        <FormattedMessage
                                            id={this.props.title.id}
                                            description={this.props.title.description}
                                            defaultMessage={this.props.title.defaultMessage}
                                        />
                                    }
                                </span>
                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col>
                                <div className="confirm_message_box__message">
                                    {

                                        <FormattedMessage
                                            id={this.props.message.id}
                                            description={this.props.message.description}
                                            defaultMessage={this.props.message.defaultMessage}
                                        />
                                    }
                                </div>

                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col>
                                <div className="confirm_message_box__links-no">
                                    {this.props.showCancelButton == true ?
                                        <a onClick={this.onCancelClick.bind(this)}>
                                            <img src="/assets/VMS_33button_kill_blue.png" />{' '}
                                            {this.props.showCancelButton == true ?
                                                <FormattedMessage
                                                    id={this.props.cancelButtonText.id}
                                                    description={this.props.cancelButtonText.description}
                                                    defaultMessage={this.props.cancelButtonText.defaultMessage}
                                                /> : undefined
                                            }
                                        </a>
                                        : undefined}
                                </div>
                            </Col>
                            &nbsp;&nbsp;
                            <Col>
                                <div className="confirm_message_box__links-yes">
                                    <a onClick={this.onOKClick.bind(this)}>
                                        {
                                            <FormattedMessage
                                                id={this.props.okButtonText.id}
                                                description={this.props.okButtonText.description}
                                                defaultMessage={this.props.okButtonText.defaultMessage}
                                            />
                                        }
                                        {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        {
                            this.props.footerMessage != undefined && this.props.footerMessage != '' ?
                                < Row >
                                    <Col>
                                        <div className="confirm_message_box__message">
                                            {
                                                <FormattedMessage
                                                    id={this.props.footerMessage.id}
                                                    description={this.props.footerMessage.description}
                                                    defaultMessage={this.props.footerMessage.defaultMessage}
                                                />
                                            }
                                        </div>

                                    </Col>
                                </Row>
                                : undefined

                        }
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}
ConfirmMessageBox.defaultProps = {
    showCancelButton: true,
    title: 'Confirmation Message',
    okButtonText: 'Yes',
    cancelButtonText: 'No'
}

