// libraries
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import * as constants from 'constants/App';
import ComplaintVehicleTitle from 'PO/ComplaintView/components/ComplaintVehicleTitle';
import * as selector from '../selectors/DriverValidationComplaints';
import { vehicleDetailsSelector } from 'PO/ComplaintView/selectors/ComplaintView';
import Spinner from 'components/Spinner';
import TopLayout from '../components/TopLayout';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AvailableComplaints from '../components/AvailableComplaints';
import VehicleInformation from 'components/VehicleInformation';

const emptyMap = Map();

class DriverValidationComplaints extends React.Component {
    componentDidMount() {
        this.props.onLoading();        
    }

    componentWillUnmount() {
        this.props.onUnloading();        
    }              

    render() {
        if (this.props.isBusy) {
            return <Spinner />;
        }
        
        return <div>

            <TopLayout>

                <ComplaintVehicleTitle caption={<div>{this.props.vehicleDetails.get('year') + ' ' +
                    this.props.vehicleDetails.get('make') + ' ' +
                    this.props.vehicleDetails.get('model') } </div>}/>

                <VehicleInformation vehicleDetails={this.props.vehicleDetails} actionType={this.props.actionType}/>

            </TopLayout>

            <Layout>

                <Header/>

                <AvailableComplaints 
                    availableComplaints={this.props.availableComplaints}
                    onSelect={this.props.onSelectComplaint}
                    onUnselect={this.props.onUnselectComplaint}
                    />

                <Footer onGoToNextStep={this.props.onGoToNextStep}/>

            </Layout>

        </div>
    }
}

const mapStateToProps = (state, props) => {
    return {
        isBusy: state.appState.getIn(['serverData', 'adjustment', 'isFetchingData']),        
        poNumber: state.appState.getIn(['uiData', 'addedit', 'purchaseOrder', 'id']),
        actionType: 'DRIVERVALIDATIONCOMPLAINTS',
        vehicleDetails: vehicleDetailsSelector(state),
        availableComplaints: selector.getAvailableComplaints(state),
        complaintTypes: state.appState.getIn(['serverData', 'shared', 'complaintTypes']) || emptyMap,        
        errorMessage: state.appState.getIn(['serverData', 'adjustment', 'errorMessage']) || 0,
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoading: () => {                        
            dispatch({type:constants.DRIVER_VALIDATION_COMPLAINTS_SCREEN_LOADING});
        },
        onUnloading: () => {

        },
        onSelectComplaint: (vehicleComplaintId) => {            
            dispatch({type:constants.DRIVER_VALIDATION_COMPLAINT_SELECTED, vehicleComplaintId});            
        },        
        onUnselectComplaint: (vehicleComplaintId) => {            
            dispatch({type:constants.DRIVER_VALIDATION_COMPLAINT_UNSELECTED, vehicleComplaintId});
        },  
        onGoToNextStep: () => {            
            dispatch({type:constants.DRIVER_VALIDATION_COMPLAINTS_NEXT_STEP});
        },  
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverValidationComplaints);