import * as constants from 'constants/App';


export function toggleHelpTitleShowAll(sectionTitle) {
    return {type: constants.TOGGLE_HELP_FAQ_TITLE,sectionTitle}
}

export function toggleHelpQuestionShowAll(sectionTitle,sectionKeyQuestion) {
    return {type: constants.TOGGLE_HELP_FAQ_QUESTION,sectionTitle,sectionKeyQuestion}
}

export function changeLocale(locale) {
    return {type: constants.CHANGE_FAQ_LOCALE,locale}    
}

export function clearLocale() {
    return {type: "CLEAR_FAQ_LOCALE"}
}