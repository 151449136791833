import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { Popover, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import './TireDetails.css';
import classnames from 'classnames';

const TireDetails = (props) => {
    const tireWidthRef = useRef();
    const aspectRatioRef = useRef();
    const rimDiameterRef = useRef();
    const manufacturerRef = useRef();
    const modelRef = useRef();
    const reasonRef = useRef();

    let fieldsFocusedAfterValidation = [];
    const { manufacturers,
        reasons,
        defaultManufacturer,
        defaultModel,
        defaultReason,
        tireWidth,
        tireAspectRatio,
        tireRimDiameter,
        title,
        onChangeDetails,
        validationErrors,
        onRemoveTireDetailsValidationErr,
        intl} = props;

    const allManufacturersOptions = manufacturers.map((item) => {
        return <option key={item.value} value={item.name}>{item.name}</option>
    })

    const allReasonsOptions = reasons.map(item => {        
        return <option key={item.value} value={item.name}>{intl.formatMessage({id: "reason_" + item.value, defaultMessage: item.name})}</option>
    })

    const onFieldFocus = (fieldName) => {
        fieldsFocusedAfterValidation.concat(fieldName);
        onRemoveTireDetailsValidationErr(fieldName);
    }

    const renderValidationOverlay = (fieldName, placement, ref) => {
        let message = validationErrors.get(fieldName);
        let showError = message != undefined && !fieldsFocusedAfterValidation.includes(fieldName);
        if (message != undefined)
            return <Overlay
                show={showError}
                target={ref.current}
                placement={placement || "right"}
                container={this}>
                <Popover id={"popOver" + fieldName} className="enrollment-details__error_message step1__popover">
                <RawIntlProvider value={props.intl}>
                    <FormattedMessage {...message} />
                    </RawIntlProvider>
                </Popover>
            </Overlay>
        else
            return false;
    }

    const tooltip = (toolTipText) => <Tooltip id="tooltip">{toolTipText}</Tooltip>;

    const onChangeRimDiameter = (fieldName, value) => {
        let regexExp = new RegExp('^[0-9]{1,3}(\.[0-9]{0,1})?$');
        let allowInt = true;
        let allowDec = true;
        if (!value)
            onChangeDetails(fieldName, value);
        else
        {
            //below hack makes sure more than 3 digits before decimal are not allowed.
            //The regex '^[0-9]{1,3}(\.[0-9]{0,1})?$' given above does not prevent whole numbers more than 3
            const digits = value.split('.')

            if(!!digits[0] && digits[0].length > 2)
            {
                allowInt = false;
            }

            if(!!digits[1] && digits[1].length > 1)
            {
                allowDec = false;
            }

            if(allowInt && allowDec)     
                onChangeDetails(fieldName, value);
            }
        



    }

    const {formatMessage} = props.intl;

    const messages = defineMessages({
        model: {
            id: 'model',
            description: 'model',
            defaultMessage: 'Model'
        },
        tire_width_tooltip: {
            id: 'tire_width_tooltip',
            description: 'Tire Width',
            defaultMessage: 'Tire Width'
        },
        tire_aspect_ratio_tooltip: {
            id: 'tire_aspect_ratio_tooltip',
            description: 'Aspect Ratio',
            defaultMessage: 'Aspect Ratio'
        },
        tire_rim_diameter_tooltip: {
            id: 'tire_rim_diameter_tooltip',
            description: 'Rim Diameter',
            defaultMessage: 'Rim Diameter'
        },
        tire_manufacturer_tooltip: {
            id: 'tire_manufacturer_tooltip',
            description: 'Tire Manufacturer',
            defaultMessage: 'Tire Manufacturer'
        },
        tire_model_tooltip: {
            id: 'tire_model_tooltip',
            description: 'Tire Model',
            defaultMessage: 'Tire Model'
        },
        tire_reason_for_replacement_tooltip: {
            id: 'tire_reason_for_replacement_tooltip',
            description: 'Reason For Replacement',
            defaultMessage: 'Reason For Replacement'
        },
    });
    return (
        <div className='tire-details__container'>
            {
                !props.editOnly ?
                    <div className='tire-details__step-number-5'>
                        <img src="/assets/VMS_28button_5_clear.png" />
                    </div>

                    : undefined
            }
            <div className='tire-details__title'>{props.title}</div>
            <div className='tire-details__example-text'>{props.exampleText}</div>
            <div className='tire-details__group-panel'>
                <div className='tire-details__measurements'>
                    <OverlayTrigger placement='right' overlay={tooltip(<FormattedMessage {...messages.tire_width_tooltip }/>)}>
                        <input type='text'
                            className='tire-details__tire-width'
                            placeholder={0}
                            value={tireWidth}
                            maxLength={3}
                            ref={tireWidthRef}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.TIRE_WIDTH, event.target.value)}
                            onKeyPress={helperFunctions.isNumeric.bind(this)}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.TIRE_WIDTH)}
                        />
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.TIRE_WIDTH, 'left', tireWidthRef)}

                    /
                    <OverlayTrigger placement='right' overlay={tooltip(<FormattedMessage {...messages.tire_aspect_ratio_tooltip }/>)}>
                        <input type="text"
                            className='tire-details__tire-aspect-ratio'
                            placeholder={0}
                            value={tireAspectRatio}
                            maxLength={2}
                            ref={aspectRatioRef}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.ASPECT_RATIO)}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.ASPECT_RATIO, event.target.value)}
                            onKeyPress={helperFunctions.isNumeric.bind(this)} />
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.ASPECT_RATIO, 'top', aspectRatioRef)}

                    R
                    <OverlayTrigger placement='right' overlay={tooltip(<FormattedMessage {...messages.tire_rim_diameter_tooltip }/>)}>
                        <input type="text"
                            className='tire-details__tire-rim-diameter'
                            placeholder={0}
                            value={tireRimDiameter}
                            ref={rimDiameterRef}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.RIM_DIAMETER)}
                            onChange={(event) => onChangeRimDiameter(constants.TIRE_DETAILS.RIM_DIAMETER, event.target.value)}
                            onKeyPress={helperFunctions.isNumberKey.bind(this)} />
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.RIM_DIAMETER, 'bottom', rimDiameterRef)}
                </div>
                <div >
                    <OverlayTrigger placement='right' overlay={tooltip(<FormattedMessage {...messages.tire_manufacturer_tooltip}/>)}>
                        <select name='allManufacturers'
                            className={classnames('tire-details__manufacturers', defaultManufacturer || 'tire-details__disabled-text')}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.MANUFACTURER, event.target.value)}
                            value={defaultManufacturer || 'placeholder'}
                            ref={manufacturerRef}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.MANUFACTURER)}>
                            <option className={'tire-details__disabled-text'} key={'placeholderM'} value={'placeholder'} disabled></option>
                            {allManufacturersOptions}
                        </select>
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.MANUFACTURER, 'left', manufacturerRef)}

                </div>
                <div >
                    <OverlayTrigger placement='right' overlay={tooltip(<FormattedMessage {...messages.tire_model_tooltip }/>)}>
                        <input type="text"
                            className='tire-details__model'
                            placeholder={formatMessage(messages.model)}
                            value={defaultModel}
                            maxLength={50}
                            ref={modelRef}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.MODEL)}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.MODEL, event.target.value)} />
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.MODEL, 'left', modelRef)}

                </div>
                <div >
                    <OverlayTrigger placement='right' overlay={tooltip(<FormattedMessage {...messages.tire_reason_for_replacement_tooltip }/>)}>
                        <select name='allReasons'
                            className={classnames('tire-details__reason', defaultReason || 'tire-details__disabled-text')}
                            onChange={(event) => onChangeDetails(constants.TIRE_DETAILS.REASON, event.target.value)}
                            value={defaultReason || 'placeholder'}
                            ref={reasonRef}
                            onFocus={onFieldFocus.bind(this, constants.TIRE_DETAILS.REASON)}>
                            <option className={'tire-details__disabled-text'} key={'placeholderR'} value={'placeholder'} disabled></option>
                            {allReasonsOptions}
                        </select>
                    </OverlayTrigger>
                    {renderValidationOverlay(constants.TIRE_DETAILS.REASON, 'left', reasonRef)}

                </div>
            </div>
        </div>
    );

};


export default injectIntl(TireDetails);