import { Map, fromJS } from 'immutable';
import * as sharedActions from '../../../../shared/actions/App';
import * as constants from 'constants/App';
import * as sharedReducers from 'reducers/Shared';
import * as addNewLineItemsViewActions from '../../../PO/AddEditView/screens/AddNewLineItemsView/actions/AddNewLineItemsView';
import { normalize, Schema, arrayOf } from 'normalizr';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';

//TODO: change the constant add_complaint_data
export function LoadServiceData(services) {
    return { type: constants.LOAD_SERVICE_DATA, services };
}

export function toggleServiceSelection(serviceId) {
    return { type: constants.TOGGLE_SERVICE_SELECTION, serviceId };
}

export function LoadATACodesData(ataCodes) {
    return { type: constants.LOAD_ATA_CODES_DATA, ataCodes };
}

//toggle selected services from the state. Remove the service if its already in the state OR Add the service
//if its not in the state
export function setServiceSelection(serviceId) {
    return function (dispatch) {
        dispatch(toggleServiceSelection(serviceId));
    }
}


export function setDiagnosisDetails(hours, rate, diagnosisSelected) {
    return { type: constants.SET_DIAGNOSIS_DETAILS, hours, rate, diagnosisSelected }
}

export function clearSelectedServices() {
    return { type: constants.CLEAR_SELECTED_SERVICES }
}

export function clearDiagnosisDetails() {
    return { type: constants.CLEAR_DIAGNOSIS_DETAILS }
}

function stopSpinnerAndDisplayError(componentName, message, dispatch) {
    // Stop spinner
    dispatch(sharedActions.fetchDataFromAPIComplete(componentName));

    // Dispatch error
    dispatch(sharedActions.setErrorMessage(message));
}

export function showErrorMessages() {
    return { type: constants.COMPLAINT_VIEW_SHOW_ERRORS }
}

export function clearErrorMessages() {
    return { type: constants.CLEAR_COMPLAINT_VIEW_SHOW_ERRORS }
}