import { Map } from 'immutable';
import * as sharedActions from 'actions/App';
import * as constants from 'constants/App';
import { Schema, arrayOf } from 'normalizr';
import callAPI from 'utils/CallAPI';
import configuration from 'configuration';


export function shopViewLoaded() {
    return {type: constants.SHOP_VIEW_LOADED}
}

export function shopViewUnLoaded() {
    return {type: constants.SHOP_VIEW_UNLOADED}
}

export function toggleShowAllApprovedPOs() {
    return {type: constants.TOGGLE_SHOW_ALL_APPROVED_POS}
}

export function toggleShowAllWaitingForARIApprovalPOs() {
    return {type: constants.TOGGLE_SHOW_ALL_WAITING_FOR_ARI_APPROVAL_POS}
}

export function toggleShowAllWaitingForClientApprovalPOs() {
    return {type: constants.TOGGLE_SHOW_ALL_WAITING_FOR_CLIENT_APPROVAL_POS}
}

export function toggleShowAllVendorActionNeededPOs() {
    return {type: constants.TOGGLE_SHOW_ALL_VENDOR_ACTION_NEEDED_POS}
}

export function toggleShowAllWaitingForDriverValidationPOs() {
    return {type: constants.TOGGLE_SHOW_ALL_WAITING_FOR_DRIVER_VALIDATION_POS}
}

export function toggleShowAllNewWorkAssignment(){
    return {type: constants.TOGGLE_SHOW_ALL_NEW_WORK_ASSIGNMENT_POS}    
}

export function toggleShowAllWorkCompleted(){
    return {type: constants.TOGGLE_SHOW_ALL_WORK_COMPLETED_POS}    
}