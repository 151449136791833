import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import LinkToUrl from 'components/LinkToUrl';
import RepairHistory  from 'RepairHistory/RepairHistory';
import './RecentService.css';
import Title from 'components/Title';

class RecentService extends React.Component {

    render(){        
        const messages = defineMessages({
            recent_service: {
                id: 'recent_service',
                description: 'Recent Service',
                defaultMessage: 'Recent Service'
            }, 
            repair_history_of_vehicle: {
                id: 'repair_history_of_vehicle',
                description: 'Repair History of Vehicle',
                defaultMessage: 'Repair History'
            }
        });

        return <div className="container-fluid"> 
              <div className="recent-service__box">
                  <div className="recent-service__caption">
                      <Title caption={<FormattedMessage {...messages.recent_service}/>}/>
                  </div>
                  <RepairHistory lineItems={this.props.vehicleRecentService}/>       
                  <div className="row" style={{textAlign:'right'}}>
                        <div className="recent-service__goto-repair-history">
                            <LinkToUrl
                                caption={<FormattedMessage {...messages.repair_history_of_vehicle}/>}
                                imagePath="/assets/VMS_33button_go_blue.png" 
                                url={'/po/repairHistory/' + this.props.vehicleDetails.get('id')} 
                                imageAlignment ='right'/>
                        </div>
                    </div> 
              </div>
         </div>;
    }   
}


export default RecentService;