import React from "react";
import { Map } from "immutable";
import { Modal, Popover, Overlay } from "react-bootstrap";
import ReactDOM from 'react-dom';
import "./LaborRateUpdate.css";
import { Container, Row, Col } from 'react-bootstrap';
import { defineMessages, FormattedMessage,RawIntlProvider,injectIntl} from 'react-intl';

class LaborRateUpdateView extends React.Component {
  constructor(props) {
    super(props);
    this.colLaborRateYes = React.createRef();
    this.divLaborRateYes = React.createRef();

    this.state = {
      yesButtonSelected: false,
      noButtonSelected: false
    }
  }

  radioButtonClick(flag, eventFlag, e) {
    if (flag == 'Y') {
      this.setState({ 'yesButtonSelected': true });
      this.setState({ 'noButtonSelected': false });
    }
    else {
      this.setState({ 'noButtonSelected': true });
      this.setState({ 'yesButtonSelected': false });
    }
    this.props.saveLaborRateFlag(flag);
    //if(eventFlag) this.props.radioButtonClicked(flag,this.props.newRate);
  }
  render() {
    const messages = defineMessages({
      labor_rate_update_title: {
        id: "labor_rate_update_title",
        description: "Labor Rate Update",
        defaultMessage: "Labor Rate Update"
      },
      labor_rate_update_question_line1: {
        id: "labor_rate_update_question_line1",
        description: "You have changed labor rate from ${currentRate} to ${newRate}.  Is this your new standard labor rate?",
        defaultMessage: "You have changed labor rate from ${currentRate} to ${newRate}.  Is this your new standard labor rate?"
      },
      labor_rate_update_question_line2: {
        id: "labor_rate_update_question_line2",
        description: "Is this your new standard labor rate?",
        defaultMessage: "Is this your new standard labor rate?"
      },
      labor_rate_update_yes: {
        id: "labor_rate_update_yes",
        description: "Yes, request new rate",
        defaultMessage: "Yes, request new rate"
      },
      labor_rate_update_no: {
        id: "labor_rate_update_no",
        description: "No",
        defaultMessage: "No"
      },
      labor_rate_update_error: {
        id: 'labor_rate_update_error',
        description: "Please select One",
        defaultMessage: 'Please select One'
      }
    });
    return (

      <div>

        <div>{' '}</div>

        <div className="labor-rate-update__table_border">
          {

            <div className="laborrate-frame__step-number-4">
              {this.props.frameNumber == 4 ?

                <img src="/assets/VMS_28button_4_clear.png" />
                :
                this.props.frameNumber == 5 ?
                  <img src="/assets/VMS_28button_5_clear.png" />
                  :
                  <img src="/assets/VMS_28button_6_clear.png" />
              }
            </div>
          }
          <div className="laborrate_update__text">
            <FormattedMessage
              values={{
                currentRate: this.props.currentRate,
                newRate: this.props.newRate
              }}
              {...messages.labor_rate_update_question_line1} />
          </div>

          <div>

            <div>

              <Row>
                <Col
                  sm={4}
                  className="laborrate_update__radio"
                  onClick={this.radioButtonClick.bind(this, 'Y', true)}
                  onMouseUp={this.radioButtonClick.bind(this, 'Y', false)}
                  ref={this.colLaborRateYes}
                >
                  <Overlay
                    show={this.props.showLaborRateValidationError}
                    //onHide={this.props.onInputBlurHandler.bind(this, "invoiceNo")}
                    placement="right"
                    target={this.divLaborRateYes.current}

                  >

                    <Popover id="error_popover_invoice_no"
                      className="laborrate__error_message"
                    >
                    <RawIntlProvider value={this.props.intl}>
                      <FormattedMessage {...messages.labor_rate_update_error} />
                      </RawIntlProvider>
                    </Popover>
                  </Overlay>
                  {
                    <div ref={this.divLaborRateYes}>{
                      this.state.yesButtonSelected ? <img src={"/assets/VMS_checked.png"} /> :
                        <img src={"/assets/VMS_unchecked.png"} />
                    }

                      {" "}
                      {" "}
                      <FormattedMessage {...messages.labor_rate_update_yes} />
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col
                  sm={8}
                  className="laborrate_update__radio"
                  onClick={this.radioButtonClick.bind(this, 'N', true)}
                  onMouseUp={this.radioButtonClick.bind(this, 'N', false)}
                >
                  {this.state.noButtonSelected ? <img src={"/assets/VMS_checked.png"} /> :
                    <img src={"/assets/VMS_unchecked.png"} />}
                  {" "}
                  {" "}
                  <FormattedMessage {...messages.labor_rate_update_no} />
                </Col>
              </Row>
              <Row>
                <Col sm={10} />
                <Col sm={2} />
              </Row>

            </div>
          </div>
        </div>
        <div>

          {" "}


        </div>

      </div>

    );
  }
}

export default injectIntl(LaborRateUpdateView);
