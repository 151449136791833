import {Map, fromJS} from 'immutable';
import * as constants from 'constants/App';
import * as sharedActions from '../../../../shared/actions/App';
import {normalize, Schema, arrayOf} from 'normalizr';
import * as service from '../service/AddEditView';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
import * as complaintViewActions from '../../ComplaintView/actions/ComplaintView';
import * as odometerViewActions from '../../OdometerView/actions/OdometerView';
import moment from 'moment';


export function poDetailsScreenLoaded (poNumber, mode, shouldLoadLaborRate) {
    return {type: constants.PO_DETAILS_SCREEN_LOADED, poNumber, mode, shouldLoadLaborRate}
}

export function saveServiceDescription(comments, complaintCode, actionType) {
    return {type: constants.SAVE_SERVICE_DESCRIPTION, comments, complaintCode, actionType}
}

export function saveEstimatedDate(newEstimatedDate, actionType) {
    return {type: constants.ESTIMATED_COMPLETION_DATE_CLICKED, newEstimatedDate, actionType}
}

export function deleteLineItem(lineItemKey, actionType) {
    return {type: constants.DELETE_LINE_ITEM_CLICKED, lineItemKey, actionType}
}

export function editLineItem(lineItemKey, actionType) {
    return {type: constants.EDIT_LINE_ITEM, lineItemKey, actionType}
}

export function updateLineItem(lineItem) {
    return {type: constants.UPDATE_LINE_ITEM_CLICKED, lineItemId: lineItem.get('lineItemKey'),quantity: lineItem.get('quantity'), unitPrice:  lineItem.get('unitPrice') }
}

export function cancelUpdateLineItem(lineItemKey) {
    return {type: constants.CANCEL_UPDATE_LINE_ITEM, lineItemKey}
}

export function deleteAllLineItems() {
    return {type: constants.DELETE_ALL_LINE_ITEMS}
}

export function clearPurchaseOrderDetails() {
    return {type: constants.CLEAR_PURCHASE_ORDER_DETAILS}
}

export function displayErrorMessage(errorMessage) {
    return (dispatch)=> {
        dispatch(sharedActions.setErrorMessage(errorMessage));
    }
}

export function savePO(componentName, approvedTotal, pendingTotal, estimatedCompletionDate) {

    return (dispatch, getState) => {

        // Start spinner
        dispatch(sharedActions.fetchDataFromAPIStart(componentName));

        const token = getState().appState.getIn(['serverData', 'shared', 'token']);

        let serviceList = emptyMapIfUndefined(getState().appState.getIn(['uiData', 'addEdit', 'lineItems'])).toArray();
        let lineItemsList = [];
        let lineItem;
        for (var i = 0; i < serviceList.length; i++) {

            lineItem = serviceList[i].toJSON();
            lineItem.lineItemId = i;
            lineItemsList[i] = lineItem;
        }

        let requestBody = {
            vehicleId: getState().appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']),
            vehicleOdometer: getState().appState.getIn(['uiData', 'odometerView', 'newOdometer']),//"10000",
            vehicleHourmeter: getState().appState.getIn(['uiData', 'odometerView', 'newEngineHours']),//"10" ,
            vendorId: getState().appState.getIn(['serverData', 'shared', 'vendorId']),//"029296UA"
            authorizedAmount: approvedTotal,
            pendingAmount: pendingTotal,
            estimatedCompletionDate: getState().appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'estimatedCompletionDate']) == undefined ?
                moment(moment().hours() < 12 ? 8 : moment().hours() < 18 ? 12 : 18, "HH").utc() :
                //moment(8, "HH").utc() :
                getState().appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'estimatedCompletionDate']).utc(),
            vehicleArrivalDate: getState().appState.getIn(['uiData', 'odometerView', 'isVehicleAtShop']) ? moment().utc() : undefined,
            lineItems: lineItemsList
        };

      
    }
}

export function updatePO(componentName, poNumber, callback) {
    return (dispatch, getState) => {
        // Start spinner
        dispatch(sharedActions.fetchDataFromAPIStart(componentName));

        const token = getState().appState.getIn(['serverData', 'shared', 'token']);

        let estimateDate = getState().appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'estimatedCompletionDate']);
        let editPORequestBody = {
            id: poNumber,
            estimatedCompletionDate: estimateDate == undefined ? moment(8, "HH").utc() : moment(estimateDate).utc(),
            lineItems: getState().appState.getIn(['uiData', 'addEdit', 'lineItems']).toArray()
        };
        
        console.log(JSON.stringify(editPORequestBody));
      
    }
}


function stopSpinnerAndDisplayError(componentName, message, dispatch) {
    // Stop spinner
    dispatch(sharedActions.fetchDataFromAPIComplete(componentName));

    // Dispatch error
    dispatch(sharedActions.setErrorMessage(message));
}

export function resetComplaints() {
    return function (dispatch, getState) {
        dispatch(clearComplaints());
        dispatch(createNewComplaint());
    }
}

/* complaints */
export function clearComplaints() {
    return {type: constants.CLEAR_COMPLAINTS };
}

export function createNewComplaint() {
    return function (dispatch, getState) {
        let nextComplaintId = emptyMapIfUndefined(getState().appState.getIn(["uiData","addEdit","complaints"])).count() + 1;
        let newComplaintObj = {};
        let complaintKey = 'complaint' + nextComplaintId;
        newComplaintObj[complaintKey] = Map({
            index: nextComplaintId,
            title: "Complaint " + nextComplaintId,
            code: undefined,
            description: undefined,
            isSelectMode: true,
            wasComplaintSkipped: false,
            isComplaintOpen: true});
        let newComplaint = new Map(newComplaintObj);
        dispatch(addComplaint(newComplaint));
    }
}

export function convertComplaintsForUI(complaintsJSON, getState) {
    //take complaints in JSON format that came from server
    //and add properties to each complaint that are needed for appState/uiState props
    let newComplaints = complaintsJSON;
    let index = 1;
    for (var complaint in newComplaints) { //iterate through all properties in complaints

        if (newComplaints.hasOwnProperty(complaint)) { //make sure property is a complaint JSON and not a property from prototype: http://stackoverflow.com/questions/684672/how-do-i-loop-through-or-enumerate-a-javascript-object
            newComplaints[complaint]['index'] = index;
            newComplaints[complaint]['title'] = 'Complaint ' + index;
            newComplaints[complaint]['description'] =
                getState().appState.getIn(['serverData','shared','complaintTypes',
                    newComplaints[complaint]['code'], 'description']);
            newComplaints[complaint]['isSelectMode'] = false;
            newComplaints[complaint]['wasComplaintSkipped'] = false;
            newComplaints[complaint]['isComplaintOpen'] = false;
            index++;
        }
    }

    return newComplaints;
}

export function addComplaint(newComplaint) {
    return {type: constants.ADD_EDIT_VIEW_ADD_COMPLAINT, newComplaint };
}

export function selectComplaint(complaintKey, code, description, oldComplaintCode) {
    return {type: constants.CREATE_OR_EDIT_COMPLAINT_CLICKED, complaintKey, code, description, oldComplaintCode};
}

export function skipComplaint(complaintKey) {
    return {type: constants.SKIP_COMPLAINT_CLICKED, complaintKey};
}

export function toggleComplaintSelectMode(complaintKey) {
    return {type: constants.TOGGLE_COMPLAINT_SELECT_MODE, complaintKey};
}