import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map, fromJS} from 'immutable';


export function serverDataRepairHistory(state = initialAppState.getIn(['serverData', 'repairHistory']), action) {
    switch (action.type) {
        case constants.SAVE_RECENT_SERVICE_DATA:
            //if the request is for the 1st page then reset the nextPageExist flag to true
            let hasMoreRepairHistory = action.recentServicesData == undefined || action.recentServicesData.size == 0 ? false : true;            
            return state.set('services',state.getIn(['services']).merge(fromJS(action.recentServicesData)))
                    .set('nextPageExist', hasMoreRepairHistory);
        case constants.CLEAR_REPAIR_HISTORY:
            return state.set('services', Map());
        default:
            return state;
    }
}