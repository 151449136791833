import * as constants from 'constants/App';
import { Map } from 'immutable';

const initialUiData = Map();
const initialServerData = Map();

export function uiDataChangePassword(state = initialUiData, action) {
    switch (action.type) {
        case constants.RESET_ERROR_MESSAGES:
            return state.setIn(['popOver', 'isPopOverDisplayedShowPassword'], false)
                .setIn(['popOver', 'isPopOverDisplayedShowCurrentPassword'], false)
                .setIn(['popOver', 'isPopOverDisplayedShowConfirmPassword'], false)
                .setIn(['isPasswordChangeSuccessed'], false)
                .setIn(['isRequestedRejected'], false)
                .setIn(['isFetchingData'], false)
                .setIn(['hasErrorMessage'], false)
                .setIn(['errorMessage'], [])
                .setIn(['newPasswordErrorMessage'], '')
                .setIn(['currentPasswordErrorMessage'], '')
                .setIn(['confirmPasswordErrorMessage'], '');
        case constants.POP_OVER_DISPLAY_INVALID_CURRENT_PASSWORD:
            return state.setIn(['popOver', 'isPopOverDisplayedShowCurrentPassword'],
                action.popOverDisplayShowCurrentPassword, !state.getIn(['popOver', 'isPopOverDisplayedShowCurrentPassword'],
                    action.popOverDisplayShowCurrentPassword));
        case constants.POP_OVER_DISPLAY_INVALID_PASSWORD:
            return state.setIn(['popOver', 'isPopOverDisplayedShowPassword'],
                action.popOverDisplayShowPassword, !state.getIn(['popOver', 'isPopOverDisplayedShowPassword'],
                    action.popOverDisplayShowPassword));
        case constants.POP_OVER_DISPLAY_CONFIRM_PASSWORD:
            return state.setIn(['popOver', 'isPopOverDisplayedShowConfirmPassword'],
                action.popOverDisplayShowConfirmPassword, !state.getIn(['popOver', 'isPopOverDisplayedShowConfirmPassword'],
                    action.popOverDisplayShowConfirmPassword));
        case constants.CHANGE_PASSWORD_REQUESTED:
            return state.set('isFetchingData', true)
                .setIn(['errorMessage'], [])
                .setIn(['isRequestedRejected'], false)
                .setIn(['hasErrorMessage'], false);
        case constants.CHANGE_PASSWORD_RESOLVED:
            return state.set('isFetchingData', false)
                .set('isRequestedRejected', false)
                .set('isPasswordChangeSuccessed', true);
        case constants.CHANGE_PASSWORD_MOUNT:
            return state.set('isPasswordChangeSuccessed', false);
        case constants.SAVE_NEW_PASSWORD_ERROR_MESSGAE:
            return state.set('newPasswordErrorMessage', action.newPasswordErrorMessage);
        case constants.SAVE_CONFIRM_PASSWORD_ERROR_MESSGAE:
            return state.set('confirmPasswordErrorMessage', action.confirmPasswordErrorMessage);
        case constants.SAVE_CURRENT_PASSWORD_ERROR_MESSGAE:
            return state.set('currentPasswordErrorMessage', action.currentPasswordErrorMessage);
        case constants.CHANGE_PASSWORD_REJECTED:
            return state.set('isFetchingData', false)
                .set('errorMessage', [{ code: action.errorCode, message: action.errorMessage }])
                .set('isPasswordChangeSuccessed', false)
                .set('isRequestedRejected', true);
        case constants.SHOW_CURRENT_PWD_ERROR_MESSAGE:
            return state.setIn(['hasErrorMessage'], action.flag);
        default:
            return state;
    }
}

export function serverDataChangePassword(state = initialServerData, action) {
    switch (action.type) {
        case constants.SAVE_PASSWORD_EXPIRY_DATE:
            return state.set('passwordExpirationDate', action.passwordExpirationDate);
        case constants.RESET_PASSWORD_EXPIRY_DATE:
            return state.set('passwordExpirationDate', null);
        default:
            return state;
    }
}