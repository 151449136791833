import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map,fromJS} from 'immutable';

export function uiDataVehicleDocuments(state = initialAppState.getIn(['uiData','documents']), action) {
    switch (action.type) {
        case constants.VEHICLE_DOCUMENTS_LOADING:
            // Show documents for first time if vendor documents (no vehicleId will be supplied).
            // Hide documents for first time if vehicle documents (vehicleId will be supplied).
            // Likewise logic to toggle and display return button.
            return state.clear()
                .set('documentsVisible', action.vehicleId !== undefined)
                .set('allowToggleDocumentVisible', action.vehicleId === undefined)
                .set('isReturnVisible', action.vehicleId !== undefined)                
                .set('step', constants.VEHICLE_DOCUMENT_STEPS.DOCUMENTS)
                .set('showAll', false)
                .set('showLessCount', 25);
        case constants.VEHICLE_DOCUMENTS_UNLOADING:
            return state.clear();
        case constants.FETCH_VEHICLE_DOCUMENTS_DATA_REQUESTED:                
            return state.set('isBusy', true);
        case constants.FETCH_VEHICLE_DOCUMENTS_DATA_RESOLVED:
                return state.set('isBusy', false).delete('serverError');      
        case constants.FETCH_VEHICLE_DOCUMENTS_DATA_REJECTED:        
            return state.set('isBusy', false)     
            .set('serverError', action.errorMessage);     
        case constants.UPLOAD_VEHICLE_DOCUMENT_REQUESTED:
            return state.set('isBusy', true)
                        .delete('serverError');            
        case constants.UPLOAD_VEHICLE_DOCUMENT_RESOLVED:        
            return state.set('isBusy', false)
                        .delete('serverError');
        case constants.UPLOAD_VEHICLE_DOCUMENT_REJECTED:        
            return state.set('isBusy', false)
                        .set('serverError', action.errorMessage);         
        case constants.SHOW_VEHICLE_DOCUMENTS:        
            return state.set('documentsVisible', action.visible);
        case constants.SHOW_MORE_VEHICLE_DOCUMENTS:        
            return state.set('showAll', true);
        case constants.SHOW_LESS_VEHICLE_DOCUMENTS:        
            return state.set('showAll', false);
        case constants.SET_VEHICLE_DOCUMENTS_STEP:        
            return state.set('step', action.step);
        case constants.VEHICLE_DOCUMENTS_SET_UPLOAD_FILE_NAME:
            return state.setIn(['fileInfo', 'fileName'], action.file)                        
                        .setIn(['fileInfo', 'createdDate'], action.createdDate);        
        case constants.SET_FILE_INFO_VEHICLE_DOCUMENTS:                        
            return state.setIn(['fileInfo', action.field], action.value);
        case constants.CLEAR_FILE_INFO_VEHICLE_DOCUMENTS:                        
            return state.delete('fileInfo')
                        .delete('serverError');
        case constants.VEHICLE_DOCUMENTS_VALIDATION_FAILED:
            return state.set('validationErrors', action.validationErrors);
        case constants.VEHICLE_DOCUMENTS_CLEAR_ERROR:        
            return state.deleteIn(['validationErrors', action.name]);
        default:
            return state;
    }
}

export function serverDataVehicleDocuments(state = initialAppState.getIn(['serverData','documents']), action) {
    switch (action.type) {
        case constants.VEHICLE_DOCUMENTS_LOADING:            
            return state.clear();
        case constants.VEHICLE_DOCUMENTS_UNLOADING:
            return state.clear();
        case constants.FETCH_VEHICLE_DOCUMENTS_DATA_RESOLVED:                
            let tempState = state.set('files', fromJS(action.data.files));
            if (action.data.vehicle)
                tempState = tempState.set('vehicle', fromJS(action.data.vehicle));
            return tempState;
        case constants.UPLOAD_VEHICLE_DOCUMENT_RESOLVED:                        
            return state.set('files', (state.get('files')||Map()).concat(action.uploadedFileInfo));
        default:
            return state;
    }
}