// libraries
import React from 'react';
import {connect} from 'react-redux';
import {Map,fromJS} from 'immutable';
import {defineMessages, FormattedMessage, FormattedNumber, RawIntlProvider,injectIntl} from 'react-intl';
import {Modal,Popover, Overlay} from 'react-bootstrap';
import { Prompt } from 'react-router-dom';
import classnames from 'classnames';
import { IntlProvider } from 'react-intl';
import ReactDOM from 'react-dom';
import moment from 'moment';

// our stuff
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import Complaints from 'AddEditView/Complaints';
import VehicleDetails from '../components/VehicleDetails';
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import  * as requestApprovalViewActions from '../actions/RequestsApprovalView';
const emptyMap = Map();
import RequestsApprovalViewSelector from '../selectors/RequestsApprovalView'
import AddDealerDraftLineItem from '../components/AddDealerDraftLineItem';
import './RequestsApprovalView.css';
import FormWrapper from 'components/FormWrapper';
import RequestTotals from '../components/RequestTotals';
import ViewVehicleDetails from '../components/ViewVehicleDetails';
import AdditionalVehicleDetails from '../components/AdditionalVehicleDetails';

class RequestsApprovalView extends React.Component {


    componentDidUpdate(prevProps){
        if(this.props.routeParams.match.params.actionType !== prevProps.routeParams.match.params.actionType) {
            this.props.onLoading();
        }

    }

    componentDidMount() {

        this.props.onLoading();

    }

    componentWillUnmount() {
          this.props.onUnloading();

    }

    onDeleteLineItemClick(lineItemKey) {
        this.setState({lineItemKey});
        this.deleteLineConfirmWindow.showModal();
    }

    onDeleteResetClick() {

        this.deleteResetConfirmWindow.showModal();
    }

    clearAtaSearchToolTip() {
        window.clearTimeout(this.state.timeOutIdShow);
        window.clearTimeout(this.state.timeOutIdHide);

    }
    onReturnClickHandler() {
        window.history.back();
    }


    render() {
        if (this.props.isBusy) {
            return <Spinner />;
        }

        const messages = defineMessages({
            dealer_draft_add_edit_view_confirmation_title: {
                id: 'dealer_draft_add_edit_view_confirmation_title',
                description: 'Delete Request',
                defaultMessage: 'Delete Request'
            },
            dealer_draft_reset_confirmation_title: {
                id: 'dealer_draft_reset_confirmation_title',
                description: 'dealer_draft_reset_confirmation_title',
                defaultMessage: 'Reset Request'
            },
            add_edit_view_confirmation_message: {
                id: 'add_edit_view_confirmation_message',
                description: 'If you are attempting to edit item quantity or price, please delete both parts and labor and resubmit the new values. Are you sure you want to delete this item?',
                defaultMessage: 'If you are attempting to edit item quantity or price, please delete both parts and labor and resubmit the new values. Are you sure you want to delete this item?'
            },
           reset_confirmation_message: {
                id: 'reset_confirmation_message',
                description: 'reset_confirmation_message',
                defaultMessage: 'Are you sure you want to reset this item?'
            },
            add_edit_view_po_confirmation_no: {
                id: 'add_edit_view_po_confirmation_no',
                description: 'No',
                defaultMessage: 'No'
            },
            add_edit_view_confirmation_yes: {
                id: 'add_edit_view_confirmation_yes',
                description: 'Yes',
                defaultMessage: 'Yes'
            },
            return: {
                id: 'return',
                description: 'Return',
                defaultMessage: 'Return'
            },
            request_payment: {
                id: 'request_payment',
                description: 'request_payment',
                defaultMessage: 'Request Payment '
            },
            restore_request: {
                id: 'restore_request',
                description: 'restore_request',
                defaultMessage: 'Restore request'
            },
            unsaved_data: {
                id: 'unsaved_data',
                description: 'unsaved_data',
                defaultMessage: "You have unsaved changes that will be lost if you decide to continue. Click OK to continue or Cancel to stay on the same page."
            },
            dealer_draft_confirm_payment: {
                id: 'dealer_draft_confirm_payment',
                description: 'dealer_draft_confirm_payment',
                defaultMessage: 'Confirm the payment will be credited to account ending in {checkingAccount}'
            },
            dealer_draft_payment_accepted_error: {
                id: 'dealer_draft_payment_accepted_error',
                description: 'dealer_draft_payment_accepted_error',
                defaultMessage: 'Please confirm payment before requesting approval.'
            },
        });

        const {formatMessage} = this.props.intl;
        return <div>
            <Prompt when={this.props.isDirty} message={formatMessage(messages.unsaved_data)} />

            <Modal show={this.props.showAddNewLineItemsWindow}
                   onHide={this.props.onCloseWindowHandler}
                   dialogClassName="request_approval__modal"
                   backdrop="static"
                   keyboard={false}
                   onEntered={() => this.setState({modalLoaded: true})}
                   onExited={this.clearAtaSearchToolTip.bind(this)}>
                <Modal.Body>
                    <FormWrapper key='dealerDraftLineItemsErr'
                                 id="dealerDraftLineItemsErr" {...this.props}
                                 formErrors={this.props.errorMessage}/>
                    <AddDealerDraftLineItem
                        onAddLineItemToRequest={() => this.props.onAddLineItemToRequestHandler(this.props.newlineItem)}
                        onCloseWindow={this.props.onCloseWindowHandler}
                        productDetails={this.props.productDetails}
                        itemsAddedToPo={this.props.itemsAddedToPo}
                        handleChange={this.props.onHandleChange}
                        enableAddLineItemToPo={this.props.newlineItem.get("cost") > 0}
                        onClickChange={this.props.onClickChange}
                        isCreatingLineItems={this.props.isCreatingLineItems}
                        newlineItem={this.props.newlineItem}
                        requestStatus={this.props.routeParams.match.params.actionType.toUpperCase()}
                    />
                </Modal.Body>
            </Modal>
            <VehicleDetails
                requestNo={this.props.routeParams.match.params.requestNo}
                vehicleDetails={this.props.vehicleDetails}
                requestStatus={this.props.requestStatus}
            />


                <Complaints
                    complaints={this.props.complaints}
                    complaintTypes={this.props.complaintTypes}
                    canAddAnotherComplaint={false}
                    lineItems={this.props.lineItems}
                    showErrorMessages={false}
                    actionType={this.props.routeParams.match.params.actionType.toUpperCase() == "VIEW" ? this.props.routeParams.match.params.actionType :this.props.requestStatus}
                    isInitialAddMode={false}
                    onEditLineItemClick={this.props.onEditLineItemClick}
                    onUpdateLineItemClick={this.props.onUpdateLineItemClick}
                    onCancelUpdateLineItemClick={this.props.onCancelUpdateLineItemClick}
                    hideCostInformation={false}
                    lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                    disableMaintenanceComplaintType={false}
                    onDeleteLineItemClick={this.onDeleteLineItemClick.bind(this)}
                    onAddLineItemsClick={() => this.props.onAddLineItemsClickHandler("dealerDraft")}
                    selectedCurrency={this.props.selectedCurrency}
                />

            <ConfirmMessageBox
                ref={r => this.deleteLineConfirmWindow = r}
                title={messages.dealer_draft_add_edit_view_confirmation_title}
                message={messages.add_edit_view_confirmation_message}
                okButtonText={messages.add_edit_view_confirmation_yes}
                cancelButtonText={messages.add_edit_view_po_confirmation_no}
                onOKClickEvent={() => this.props.onDeleteLineItemClick(this.state.lineItemKey)}
                onCancelClickEvent={null}
            />
            <ConfirmMessageBox
                ref={r => this.deleteResetConfirmWindow = r}
                title={messages.dealer_draft_reset_confirmation_title}
                message={messages.reset_confirmation_message}
                okButtonText={messages.add_edit_view_confirmation_yes}
                cancelButtonText={messages.add_edit_view_po_confirmation_no}
                onOKClickEvent={() => this.props.onPaymentResetHandler()}
                onCancelClickEvent={null}
            />

            <hr className="request_approval__hr"/>
             <RequestTotals
                 taxRate={this.props.taxRate}
                 subTotal={this.props.subTotal}
                 selectedCurrency={this.props.selectedCurrency}
             />
 <hr className="request_approval__hr"/>
 {this.props.requestStatus === "VIEW" ?
          <ViewVehicleDetails
                userLocale={this.props.userLocale}
                usStates={this.props.usStates}
                newlineItem={this.props.newlineItem}
                vendorCheckingAccount={this.props.vendorCheckingAccount}
                licensePlate={this.props.licensePlate}
                registrationState={this.props.registrationState}
                requestStatus={this.props.routeParams.match.params.actionType.toUpperCase()}
                tempLicensePlate={this.props.tempLicensePlate}
                tempRegistrationState={this.props.tempRegistrationState}
                 registrationExpiration={this.props.registrationExpiration}
                 deliveryDate={this.props.deliveryDate}
                 tempRegistrationExpDate={this.props.tempRegistrationExpDate}
            />
            :
            <AdditionalVehicleDetails
                userLocale={this.props.userLocale}
                usStates={this.props.usStates}
                onHandleChange={this.props.onHandleChange}
                vendorCheckingAccount={this.props.vendorCheckingAccount}
                licensePlate={this.props.licensePlate}
                registrationState={this.props.registrationState}
                requestStatus={this.props.routeParams.match.params.actionType.toUpperCase()}
                intl={this.props.intl}
                tempLicensePlate={this.props.tempLicensePlate}
                tempRegistrationState={this.props.tempRegistrationState}
                whoWillLicense={this.props.whoWillLicense}
                registrationExpiration={this.props.registrationExpiration}
                deliveryDate={this.props.deliveryDate}
                tempRegistrationExpDate={this.props.tempRegistrationExpDate}
                deliveryDateError={this.props.deliveryDateError}
                isDeliveryDateRequired={this.props.isDeliveryDateRequired}
                onPaymentRequestedHandler={this.props.onPaymentRequestedHandler}
                subTotal={this.props.subTotal}
                selectedCurrency={this.props.selectedCurrency}
                onReturnClickHandler={()=> this.onReturnClickHandler()}
                onDeleteResetClick={()=> this.onDeleteResetClick()}
                vendorId={this.props.vendorId}
            />
    }   
        </div>
    }
}

const mapStateToProps = (state, props) => {
    return {
        isBusy: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'isFetchingDataRequestDetails']),
        vehicleDetails: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder']) || emptyMap,
        complaints: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'complaints']) || emptyMap,
        complaintTypes: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'complaintTypes']) || emptyMap,
        lineItems: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'lineItems']) || emptyMap,
        lineItemIdBeingEdited: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'lineItemIdBeingEdited']),
        approvedAmountTotal: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder', 'authorizedAmount']) || 0,
        pendingAmountTotal: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder', 'pendingAmount']) || 0,
        taxAmount: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder', 'taxAmount']) || 0,
        errorMessage: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'errorMessage']) || 0,
        showAddNewLineItemsWindow: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'showWindow']) || false,
        itemsAddedToPo: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'itemsAddedToPo']),
        newlineItem: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval']),
        isCreatingLineItems:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','isCreatingLineItems']),
        taxRate:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','requestOrder','taxRate']) || 0,
        usStates: fromJS(state.appState.getIn(['uiData', 'shared', 'usStates'])) || "",
        paymentAcceptedToggle:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','paymentAcceptedToggle']) || false,
        vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
        paymentAcceptedError:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','popOver','isPopOverDisplayedShowPaymentError']) || false,
        isDirty:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','isDirty']),
        isCreatingPO: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'isSendingPaymentRequest']),
        selectedCurrency:state.appState.getIn(['uiData', 'shared','selectedCurrency']),
        requestStatus:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestStatus']),
        licensePlate:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder','licensePlate']),
        registrationState:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','requestOrder', 'registrationState']),
        registrationExpiration:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','requestOrder', 'registrationExpiration']),
        tempLicensePlate:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder','tempLicensePlate']),
        tempRegistrationExpDate:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder','tempRegistrationExpDate']),
        tempRegistrationState:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder','tempRegistrationState']),
        userLocale:state.appState.getIn(['uiData', 'shared', 'selectedLocale']),
        registrationError:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','popOver','isPopOverDisplayedShowRegistrationError']) || false,
        whoWillLicense:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder','whoWillLicense']),
        initialLicenseToggle:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','initialLicenseToggle']) || false,
        tempLicenseToggle:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','tempLicenseToggle']) || false,
        noSelectionToggle:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','noSelectionToggle']) || false,
        deliveryDate:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder','deliveryDate']),
        isDeliveryDateRequired: state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder','isDeliveryDateRequired']),
        deliveryDateError:state.appState.getIn(['uiData', 'dealerDraftRequestsApproval','popOver','isPopOverDisplayShowDeliveryDateError']) || false,
        
        ...RequestsApprovalViewSelector(state)
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onLoading: () => {
            let requestNo = props.routeParams.match.params.requestNo;
            dispatch({type: "DEALER_DRAFT_CLEAR_DATA"});
            dispatch({type: "FETCH_REQUEST_NO_DETAILS", requestNo});
            dispatch({type: "FETCH_DEALER_DRAFT_PRODUCT_DETAILS", requestNo});
            dispatch({type: constants.FETCH_VENDOR_BANKING_INFORMATION});
            dispatch(requestApprovalViewActions.dealerDraftRegistrationError(false));
            dispatch(requestApprovalViewActions.dealerDraftPaymentAcceptedError(false));
        },
        onUnloading: () => {
            dispatch({type: "DEALER_DRAFT_CLEAR_DATA"});
        },
        onEditLineItemClick: (lineItemKey) => {

            dispatch({type: "DEALER_DRAFT_EDIT_LINE_ITEM", lineItemKey});
        },
        onUpdateLineItemClick: (lineItem) => {
            let lineItemKey = lineItem.get('lineItemKey');
            dispatch({type: "DEALER_DRAFT_UPDATE_LINE_ITEM_CLICKED", lineItemKey, lineItem});
        },
        onCancelUpdateLineItemClick: (lineItemKey) => {
            dispatch({type: "DEALER_DRAFT_CANCEL_UPDATE_LINE_ITEM", lineItemKey});
        },
        onDeleteLineItemClick: (lineItemKey) => {
            dispatch({type: "DEALER_DRAFT_DELETE_LINE_ITEM", lineItemKey});
        },
        onAddLineItemsClickHandler: (complaintCode) => {

            dispatch(requestApprovalViewActions.dealerDraftAddComplaintCode(complaintCode));
            dispatch(requestApprovalViewActions.dealerDraftToggleShowWindow());

        },
        /*Close window*/
        onCloseWindowHandler: () => {

            //cancel update line item event if popup was opened as part of edit tire info

            dispatch(requestApprovalViewActions.dealerDraftToggleShowWindow());
            dispatch({type: "DEALER_DRAFT_CLEAR_DATA"});

        },
        /*Handle input value change*/
        onHandleChange(inputType, e, modelValue, viewValue) {
            dispatch(requestApprovalViewActions.dealerDraftRegistrationError(false));
            let correctionDetails = Map({
                type: inputType,
                value: viewValue !== undefined ? viewValue : e.target.value
            });

            dispatch(requestApprovalViewActions.dealerDraftAddCorrectionDetails(correctionDetails));
        },
        onClickChange(inputType, e) {

            if (inputType = "LINE_ITEM_KEY") {
                var split = e.target.value.split(",");
                var v1 = split[0];
                var v2 = split[1];

                let correctionDetails = Map({
                    type: "DESCRIPTION",
                    value: v2
                });
                dispatch(requestApprovalViewActions.dealerDraftAddCorrectionDetails(correctionDetails));

                 correctionDetails = Map({
                    type: inputType,
                    value: v1
                });
                dispatch(requestApprovalViewActions.dealerDraftAddCorrectionDetails(correctionDetails));
            }else {
                let correctionDetails = Map({
                    type: inputType,
                    value: e.target.value
                });

                dispatch(requestApprovalViewActions.dealerDraftAddCorrectionDetails(correctionDetails));
            }
        },
        onAddLineItemToRequestHandler: (newLineItem) => {

            let requestNo = props.routeParams.match.params.requestNo;


            let lineItem = {
                description: newLineItem.get("description"),
                lineItemKey: newLineItem.get("lineItemKey"),
                unitPrice: newLineItem.get("cost"),
                requestNo: requestNo

            };
            dispatch({type: "DEALER_DRAFT_ADD_NEW_LINE_ITEM_CLICKED", lineItem});
        },
        onInputDateChangeHandler(key, value, e) {


            let correctionDetails = Map({
                type: key,
                value: e != undefined && e.target.value != undefined ? e.target.value : value,

            });

            dispatch(requestApprovalViewActions.dealerDraftAddCorrectionDetails(correctionDetails));

        },
        onPaymentAcceptedClickHandler: (paymentAcceptedToggle) => {

            if(!paymentAcceptedToggle) {

                dispatch(requestApprovalViewActions.dealerDraftPaymentAcceptedError(false));
            }
            dispatch(requestApprovalViewActions.dealerDraftTogglePaymentAccepted());

        },
        onPaymentRequestedHandler: (paymentInformation) => {

            let requestNo = props.routeParams.match.params.requestNo;

            let requestPayment = {
                registrationExpiration: paymentInformation.licenseDate !== undefined ? new Date(moment(paymentInformation.licenseDate)) : undefined,
                deliveryDate:paymentInformation.deliveryDate !== undefined ? new Date(moment(paymentInformation.deliveryDate)) : undefined,
                registrationState: paymentInformation.licenseState,
                licensePlate: paymentInformation.licensePlate,
                tempLicensePlate:paymentInformation.tempPlate,
                tempRegistrationExpDate:paymentInformation.tempDate !== undefined ? new Date(moment(paymentInformation.tempDate)) : undefined,
                tempRegistrationState:paymentInformation.tempState, 
                vendorId: paymentInformation.vendorId,
                id:requestNo

            };
            
                dispatch({type: "DEALER_DRAFT_REQUEST_PAYMENT", requestPayment});

            
        },

        onPaymentResetHandler: () => {

            let requestNo = props.routeParams.match.params.requestNo;


                dispatch({type: "DEALER_DRAFT_REQUEST_RESET", requestNo});

        },

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(RequestsApprovalView));