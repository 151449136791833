import React, { useEffect, useRef, useState } from 'react';
import  ReactDoM from 'react-dom'
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage} from 'react-intl';
import ServiceLineItems from './ServiceLineItems';
import Submit from '../../../../../../../App/shared/components/Submit';
import './AddServiceLineItems.css';
import ClientParametersComponent from 'components/ClientParametersComponent';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

const AddServiceLineItems = (props) => {
        const serviceRefList = useRef();

        const getRefMap = () =>{
            if(!serviceRefList.current){
                serviceRefList.current = new Map();
            }
            return serviceRefList.current;
        }

        const handleServiceFocusLinkClick = (index) =>{
            const refMap = getRefMap();
            const linkRef = refMap.get(index);
            linkRef.focus()
        }

        const renderSaveDisabledTooltip = (props) =>(
            <Tooltip id='services_save_disabled_exceeds_tooltip' style={{maxWidth: '220px'}}>
                {messages.services_save_disabled_exceeds_tooltip_message.defaultMessage}
            </Tooltip>
        )

        const messages = defineMessages({
            save_and_add_caption: {
                id: 'save_and_add_caption',
                description: 'Save and add additional line items',
                defaultMessage: 'Save and add additional line items'
            },
            save: {
                id: 'save',
                description: 'Save ',
                defaultMessage: 'Save '
            },
            add_pm_caption: {
                id: 'add_pm_caption',
                description: 'Add Services Due',
                defaultMessage: 'Add Services Due'
            },
            parameters_header_services: {
                id: 'parameters_header_services',
                description: 'Header for ata parameters ',
                defaultMessage: 'Client Vehicle Service Rules'
            },
            empty_parameters: {
                id: 'empty_parameters',
                description: 'Description when parameters are empty',
                defaultMessage: 'None at this time'
            },
            coupon_header_services: {
                id: 'coupon_header_services',
                description: 'coupon_header_services',
                defaultMessage: 'Coupon Schedule'
            },
            pm_sheet_header: {
                id: 'pm_sheet_header',
                description: 'pm_sheet_header',
                defaultMessage: 'Schedule'
            },
            pm_sheets: {
                id: 'pm_sheets',
                description: 'PM information pdf ',
                defaultMessage: 'PM Information'
            },
            service_exceeds_error_message: {
                id: 'service_exceeds_error_message',
                description: 'Message to display when PM service price exceeds final price value',
                defaultMessage: "The highlighted field(s) below have a cost that is too high. Please edit the cost, then click confirm. If the cost is correct, please click confirm. The save buttons will be re-enabled once you confirm your cost."
            },
            validation_link_message: {
                id: 'validation_link_message',
                description: 'Confirm {description} Cost',
                defaultMessage: 'Confirm {description} Cost'
            },
            services_save_disabled_exceeds_tooltip_message: {
                id: 'services_save_disabled_exceeds_tooltip_message',
                description: 'Tooltip to display when price exceeds and save button is disabled',
                defaultMessage: 'Services due cannot be added until you confirm your prices on the highlighted lines.'
            }
        });

        let productCodeParams = props.ataAndReferencePmParametersDescription == undefined ? '' :
            props.ataAndReferencePmParametersDescription.map((param) => {

                return <ClientParametersComponent
                    description={param.get('description')}
                />
            }).valueSeq();

        let referenceRuleParams = props.referenceRulePmParametersDescription == undefined ? '' :
            props.referenceRulePmParametersDescription.map((param) => {

                return <ClientParametersComponent
                    description={param.get('description')}
                />
        }).valueSeq();
        
        //Flag for validation confirmation buttons
        let pricesValidationConfirmed = props.pricesConfirmedList.every((p, i) => p===true || (props.priceValidations ? props.priceValidations[i].lineItemValidationReferenceId == null : true));
        //Flag for final price validation
        let priceValidationExceeds = props.priceValidations != null 
        && props.priceValidations.some(x => x != undefined && x.menupricingexceeds);

        const priceControlValidation = <Alert variant='danger' style={{marginBottom: "0", marginTop: "1rem"}}>
            <FormattedMessage {...messages.service_exceeds_error_message} />
            <ul style={{ textAlign: 'left', textDecoration: 'underline', listStyleType: 'none', paddingTop: "0.5rem" }}>
                {props.serviceATACodes.valueSeq().map((item, i) => {
                    let description = item.get("description");

                    if(props.priceValidations?.find(x => x.ataCode == item.get('productCode') && x.menupricingexceeds)){
                        return <li key={i}>{props.pricesConfirmedList[i] ? null : <Alert.Link onClick={handleServiceFocusLinkClick.bind(null, i)}>
                            <FormattedMessage {...messages.validation_link_message} values={{description: description}}/>
                        </Alert.Link>}</li>
                    }
                    else{
                        return;
                    }
                })}
            </ul>
        </Alert>

        return (
            <div className="add-service-line-items">
                {priceValidationExceeds ? priceControlValidation : null}
                <div className="row">
                    <div className="col-lg-12 add-service-line-items__title">
                        <FormattedMessage {...messages.add_pm_caption} />
                    </div>
                </div>
                <ServiceLineItems serviceATAList={props.serviceATACodes}
                                  handleChange={props.handleChange}
                                  priceValidations={props.priceValidations}
                                  serviceRefList={serviceRefList}
                                  getRefMap={getRefMap}
                                  pricesConfirmedList={props.pricesConfirmedList}
                                  handlePriceValdationConfirmationButtonClick={props.handlePriceValdationConfirmationButtonClick}/>
                <div className="row" style={{justifyContent: 'end'}}>
                    <div className={classNames("col-lg-2 add-service-line-items__save",
                        priceValidationExceeds && !pricesValidationConfirmed ? "add-service-line-items__save__disabled" : null)}>
                        <OverlayTrigger
                            placement='right'
                            trigger={priceValidationExceeds && !pricesValidationConfirmed ? ['focus', 'hover'] : null}
                            overlay={renderSaveDisabledTooltip()}>
                            <div>
                                <Submit
                                    caption={<FormattedMessage {...messages.save} />}
                                    imagePath="/assets/VMS_33button_add_blue.png"
                                    onClickHandler={props.saveServiceLineItems}
                                    showSpinner={props.parentProps.isCreatingLineItems}
                                    spinnerType='blue' />
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="row" style={{justifyContent: 'end'}}>
                    <div className={classNames("col-lg-6 add-service-line-items__save",
                        priceValidationExceeds && !pricesValidationConfirmed ? "add-service-line-items__save__disabled" : null)}>
                        <OverlayTrigger
                            placement='right'
                            trigger={priceValidationExceeds && !pricesValidationConfirmed ? ['focus', 'hover'] : null}
                            overlay={renderSaveDisabledTooltip()}>
                            <div>
                                <Submit
                                    caption={<FormattedMessage {...messages.save_and_add_caption} />}
                                    imagePath="/assets/VMS_33button_add_blue.png"
                                    onClickHandler={props.saveAndAddServiceLineItems} />
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="add-service-line-parameters__header">
                    <FormattedMessage {...messages.parameters_header_services}/>
                </div>
                <div>
                    { productCodeParams.size === 0 ?
                        <p className="add-service-line-empty__description">
                            <FormattedMessage {...messages.empty_parameters}/>
                        </p>
                        :
                        <div> {productCodeParams} </div>
                    }
                </div>
                <div className="add-service-line-parameters__header">
                    <FormattedMessage {...messages.coupon_header_services}/>
                </div>
                <div>
                    { referenceRuleParams.size === 0 ?
                        <p className="add-service-line-empty__description">
                            <FormattedMessage {...messages.empty_parameters}/>
                        </p>
                        :
                        <div> {referenceRuleParams} </div>
                    }
                </div>
                <div>
                    <div className="add-service-line-parameters__header">
                        <FormattedMessage {...messages.pm_sheet_header}/>
                    </div>
                    <div >
                        <a className={props.isFetchingData ? 'vehicle_info_disable__pm_info' : undefined}
                           onClick={props.downLoadPMInformation.bind(this)}>
                            <FormattedMessage {...messages.pm_sheets} />
                        </a>
                    </div>
                </div>
            </div>

        );
    }
;


export default AddServiceLineItems;
