import {createSelector} from 'reselect';
import {Map} from 'immutable';

const emptyMap = Map();
const filesSelector = state => state.appState.getIn(['serverData', 'vendorDocuments', 'files']);
const showAllSelector = state => state.appState.getIn(['uiData', 'vendorDocuments', 'showAll']);
const showLessCountSelector = state => state.appState.getIn(['uiData', 'vendorDocuments', 'showLessCount']);
const vehicleIdSelector = state => state.appState.getIn(['serverData', 'vendorDocuments', 'vehicle', 'id']);
const vendorSelector = state => state.appState.getIn(['serverData', 'shared', 'vendorId']);

export const getFiles = 
    createSelector(
        filesSelector, showAllSelector, showLessCountSelector, 
        (files, showAll, showLessCount) => {
            if (!files)
                return emptyMap;

            const filesSorted = files.sort((a,b)=>(
                (new Date(b.get('createdDate'))) - (new Date(a.get('createdDate')))
            ));

            if (showAll)
                return filesSorted;
            else 
                return filesSorted.take(showLessCount);
        }
    )
;

export const getMoreFilesToShow = 
    createSelector(
        filesSelector, showLessCountSelector, 
        (files, showLessCount) => {
            if (!files)
                return false;
            
            return (files.count() > showLessCount);                
        }
    )
;