// Libraries
import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
// Our stuff
import './AvailableComplaints.css';
import CheckButton from './CheckButton';

const AvailableComplaints = (props) => {
    const messages = defineMessages({
        dri_val_complaint_select: {
            id: 'dri_val_complaint_select',
            description: 'select instructions',
            defaultMessage: 'Select complaint type(s) confirmed by driver.'
        },
    });

    return <div>

        <div className='available-complaints-notes'>
            <FormattedMessage {...messages.dri_val_complaint_select} />
        </div>

        {
            props.availableComplaints.map(availableComplaint => {

                return (
                    <div>
                      
                        <div className='available-complaints-list'>
                            <div className='available-complaints__complaint-row'>
                                <div>
                                    <img className="available-complaints__toggle"
                                        src={!!availableComplaint.get('isSelected') ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png"}
                                        onClick={!!availableComplaint.get('isSelected') ?
                                            () => props.onUnselect(availableComplaint.get('vehicleComplaintId')) : () => props.onSelect(availableComplaint.get('vehicleComplaintId'))} />
                                </div>
                            </div>
                            <div className='available-complaints__complaint-note'>
                                <div>
                                    {availableComplaint.get('description')}
                                </div>
                                <div >
                                    {availableComplaint.get('complaintNote')}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }).valueSeq()
        }

    </div>
}

export default injectIntl(AvailableComplaints);