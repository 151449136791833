import {createSelector} from 'reselect';
import {emptyMapIfUndefined, falseIfUndefined} from 'utils/HelperFunctions';

const posSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData','searchPO','pos']));
const vehicleDetailsSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'searchPO', 'vehicleDetails']));
const clientDetailsSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'searchPO', 'client']));
const vendorIdSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'vendorId'])); 
const showAllServiceInProgressPOsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData','searchPO','showAllServiceInProgressPOs']));
const showAllPaymentInProgressPOsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData','searchPO','showAllPaymentInProgressPOs']));
const showAllPaidPOsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData','searchPO','showAllPaidPOs']));
const isFetchingDataSelector = state => falseIfUndefined(state.appState.getIn(['uiData','searchPO', 'isFetchingVehicleData'])) || falseIfUndefined(state.appState.getIn(['uiData','searchPO', 'isFetchingPOData']));
const searchFiltersSelector = state =>  state.appState.getIn(['serverData', 'searchPO', 'searchFilters'])

const SearchPO = createSelector(
    posSelector,
    vehicleDetailsSelector,
    clientDetailsSelector,
    vendorIdSelector,
    showAllServiceInProgressPOsSelector,
    showAllPaymentInProgressPOsSelector,
    showAllPaidPOsSelector,
    isFetchingDataSelector,
    searchFiltersSelector,
    (pos,
     vehicleDetails,
     client,
     vendorId,
     showAllServiceInProgressPOs,
     showAllPaymentInProgressPOs,
     showAllPaidPOs,
     isFetchingData,
     searchFilters) => {
        return {
            pos,
            vehicleDetails,
            client,
            vendorId,
            showAllServiceInProgressPOs,
            showAllPaymentInProgressPOs,
            showAllPaidPOs,
            isFetchingData,
            searchFilters
        }
    }
);

export default SearchPO;