// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// Our stuff
import './Footer.css';
import Submit from 'components/Submit';

const Footer = (props) => {
    const messages = defineMessages({
            tax_edit_text: {
                id: 'tax_edit_text',
                description: 'Tax can be added/edited while closing the PO',
                defaultMessage: 'Tax can be added/edited while closing the PO'
            },
            user_action_edit_caption: {
                id: 'user_action_edit_caption',
                description: 'Close PO',
                defaultMessage: 'Close PO'
            }
        });

    return <div className='adjustment_footer'>
        
        <div className="adjustment_footer__close">
            <Link to={`/po/close/${props.poNumber}`}>
                <span onClick={props.onClosePOClick} role="button" >
                    <FormattedMessage {...messages.user_action_edit_caption} />{' '}
                    <img src="/assets/VMS_33button_go_bblue.png"/>                
                </span>
            </Link>
        </div>

        <div className='adjustment_footer__message'>
            <FormattedMessage {...messages.tax_edit_text}/>                
        </div>

    </div>
}

export default Footer;