import React from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage} from 'react-intl';
import Spinner from 'components/Spinner';
import ClosePOSelector from '../selectors/ClosePO';
import * as Notes from '../selectors/Notes';
import  * as actions from '../actions/ClosePO';
import  * as events from '../events/ClosePO';
import ClosePurchaseOrder from '../../ClosePO/components/ClosePO';
import Title from 'components/Title';
import PurchaseOrderBasicDetails from 'AddEditView/PurchaseOrderBasicDetails';
import ApprovedLineItems from '../../shared/components/ApprovedLineItems';
import POTotals  from '../../shared/components/POTotals';
import ConfirmMessageBox from 'AddEditView/ConfirmMessageBox';
import {ACTION_TYPE} from 'constants/App';
import './ClosePO.css';
import FormWrapper from 'components/FormWrapper';
import Shared from 'selectors/shared';
import * as constants from 'constants/App';
import { isCarWashVendor} from 'utils/HelperFunctions';
import DotInspection from '../screens/DotInspection';
import BitInspection from '../screens/BitInspection';
import FraInspection from '../screens/FraInspection';
import CraneInspection from '../screens/CraneInspection';
import ContactCustomerDirectlyForApprovalBanner from 'components/ContactCustomerDirectlyForApprovalBanner';
import { ThirdLevelSubhead } from 'shared/DesignSpecs/components/FontGuide';

class ClosePO extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Load Data
        this.props.dispatch(actions.closeScreenPOLoaded(this.props.routeParams.match.params.trackNumber));
        this.props.dispatch(actions.resetInvoiceDetails());
    }

    RedirectUserToDashboardHandler = (e) => {
        event.preventDefault();
        /*this.setState({showConfirmationForClosePO: true});*/
        if(isCarWashVendor(this.props.serviceClassification))
            this.props.dispatch({type: constants.NAVIGATE_TO, path: '/carWash'});
        else 
            this.props.dispatch({type: constants.NAVIGATE_TO, path: '/'});
        
    }

    onClosePoHandler(){        
        this.props.dispatch(actions.closePOClicked({poNumber: this.props.routeParams.match.params.trackNumber, claimId: this.props.routeParams.location.state?.claimId}));

    }
    updateOdometerWarningsHandler(showWarning){
        this.props.dispatch(actions.showOdometerWarnings(showWarning));        
    }
    updateEngineHoursWarningsHandler(showWarning){
        this.props.dispatch(actions.showEngineHoursWarnings(showWarning));        
    }    
    render() {
        if (this.props.isfetchingData || this.props.vehicleDetails == undefined) {
            return <Spinner />;
        }

        const messages = defineMessages({
            close_po_title: {
                id: 'page_title',
                description: `Close purchase order no.`,
                defaultMessage: `Close purchase order no.`
            },
            close_po_confirmation_title: {
                id: 'close_po_confirmation_title',
                description: `Close PO`,
                defaultMessage: `Close PO`
            },
            close_po_has_diagnosis_lines_only_message: {
                id: 'close_po_has_diagnosis_lines_only_message',
                description: 'PO’s cannot be closed for diagnosis only. Please correct line item(s) to indicate the repairs completed and resubmit or contact 1-866-274-1025 for assistance.',
                defaultMessage: 'PO’s cannot be closed for diagnosis only. Please correct line item(s) to indicate the repairs completed and resubmit or contact 1-866-274-1025 for assistance.'
            },
            close_po__non_intellipay_vendor: {
                id: 'close_po__non_intellipay_vendor',
                description: 'This PO cannot be processed online. Please submit this invoice through your corporate remit for payment.',
                defaultMessage: 'This PO cannot be processed online. Please submit this invoice through your corporate remit for payment.'
            },
            close_po_confirmation_ok: {
                id: 'close_po_confirmation_ok',
                description: 'OK',
                defaultMessage: 'OK'
            },
            close_po__accident_claim: {
                id: "close_po__accident_claim",
                description: "For claim {claimId}",
                defaultMessage: "For claim {claimId}",
            }
        });

        /* Filter the data based on the complaint code for Complaints component */
        const nonMaintenanceLineItems = this.props.lineItems.valueSeq().filter(li => li.get('complaintCode') != 'MA99' && li.get('complaintCode') != 'DG99').toMap();

        /* Filter the line items data based on the complaint code for Services components */
        const maintenanceLineItems = this.props.lineItems.valueSeq().filter(li => li.get('complaintCode') == 'MA99').toMap();

        /* Filter the line items data based on the complaint code for Diagnosis components */
        const diagnosisLineItems = this.props.lineItems.valueSeq().filter(li => li.get('complaintCode') == 'DG99').toMap();

        const showBankAccountRadioButton =       
                !this.props.isWorkCompletionFeatureEnabled &&       
                ((this.props.vendor.get('paymentType')=="STANDARD" && this.props.vendor.get('id').startsWith('0')) ||
                (this.props.vendor.get('paymentType')=="STANDARD" && !this.props.vendor.get('id').startsWith('0') && this.props.vendor.get('paymentMethod')=="CHECK") ||
                this.props.isWorkCompletionFeatureEnabled ?
                false: this.props.vendor.get('bankAccountNumber') != undefined && this.props.vendor.get('bankAccountNumber')!=''? true:false);

        const showInvoiceRadioButton = this.props.vendor.get('paymentType')=='STANDARD'  && 
        (this.props.vendor.get('paymentMethod')=='CHECK'|| this.props.vendor.get('paymentMethod')=='ACCOUNT')? true:false;
    
        /*Allow the user to close the PO only if the 
            paymentType is INTELLIPAY or
            payment type is STANDARD AND paymentMethod is 'ACCOUNT' OR 'CHECK'
            
            AND 
            PO is not Diagnosis PO
         */
        const vendorCanClosePo = this.props.vendor.get('paymentType')=='INTELLIPAY' || 
            (this.props.vendor.get('paymentType')=='STANDARD' &&            
                ((this.props.vendor.get('paymentMethod')=='ACCOUNT' && this.props.vendor.get('bankAccountNumber')!=null) ||
                    this.props.vendor.get('paymentMethod')=='CHECK'));      
        const isDiagnosisPo = nonMaintenanceLineItems.count() == 0 && maintenanceLineItems.count() == 0 && diagnosisLineItems.count() > 0;         

        if (!vendorCanClosePo || isDiagnosisPo)                
            this.refs.closePOShowConfirmWindow != undefined ? this.refs.closePOShowConfirmWindow.showModal() : false;
 

        const isInsuracePo = !this.props.closePOPurchaseOrderData? false : this.props.closePOPurchaseOrderData.get('isInsurancePo'); 
        const entityForAssistance = !this.props.closePOPurchaseOrderData? false : this.props.closePOPurchaseOrderData.get('entityForAssistance'); 

        return <div>            
            <FormWrapper key='closePoErrorMessage' id="close-po-generic-error" {...this.props} formErrors={this.props.errorMessage} />
            <div className="close_po__title">
                <Title caption={<FormattedMessage {...messages.close_po_title}/>}/>
                {this.props.routeParams.match.params.trackNumber}
                {this.props.routeParams.location.state?.claimId ?
                    <ThirdLevelSubhead>
                        <FormattedMessage {...messages.close_po__accident_claim} values={{ claimId: this.props.routeParams.location.state.claimId }} />
                    </ThirdLevelSubhead>
                : null}
            </div>

            <FormWrapper key='closePurchaseOrderErrorMessage' id="" {...this.props} formErrors={this.props.closePurchaseOrderErrorMessage} />    
            {this.props.vehicleDetails.get('isHsbClientVehicle') && entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER && <ContactCustomerDirectlyForApprovalBanner />}
            <ClosePurchaseOrder invoiceDetails={this.props.invoiceDetails}
                                onClosePO={this.onClosePoHandler.bind(this)}
                                onChange={events.onChangeHandlerClosePO.bind(this)}
                                onEditPO={events.onEditPOHandler.bind(this)}
                                approvedAmountTotal={this.props.approvedAmountTotal}
                                pendingAmountTotal={this.props.pendingAmountTotal}
                                vendor={this.props.vendor}
                                validationDetails={this.props.validationDetails}
                                poCreatedDate={this.props.poCreatedDate}
                                taxAmount={this.props.invoiceDetails.get('taxAmount')}
                                lineItemsTax={this.props.lineItemsTax}
                                popOverDisplayShowInvoiceNo={this.props.popOverDisplayShowInvoiceNo}
                                popOverDisplayShowInvoiceDate={this.props.popOverDisplayShowInvoiceDate}
                                popOverDisplayShowInvoiceName={this.props.popOverDisplayShowInvoiceName}
                                userLocale={this.props.userLocale}
                                popOverDisplayShowRepairCompleteDate={this.props.popOverDisplayShowRepairCompleteDate}
                                popOverDisplayShowRepairStartDate={this.props.popOverDisplayShowRepairStartDate}
                                popOverDisplayShowOdometer = {this.props.popOverDisplayShowOdometer}
                                popOverDisplayShowEngineHours = {this.props.popOverDisplayShowEngineHours}
                                popOverDisplayShowPaymentCredit={this.props.popOverDisplayShowPaymentCredit}
                                popOverDisplayShowWorkComplete={this.props.popOverDisplayShowWorkComplete}
                                popOverDisplayShowStandard={this.props.popOverDisplayShowStandard}
                                popOverDisplayShowTax={this.props.popOverDisplayShowTax}
                                popOverDisplayShowTaxCanada={this.props.popOverDisplayShowTaxCanada}
                                popOverDisplayShowTaxExe={this.props.popOverDisplayShowTaxExe}
                                onTotalAmount={this.props.onTotalAmount}
                                onTaxAmount={this.props.onTaxAmount}
                                subTotal={this.props.subTotal}
                                taxTotal={this.props.taxTotal}
                                taxErrorMessage={this.props.taxErrorMessage}
                                vehicleDetails={this.props.vehicleDetails}
                                vehicleDetailsForOdometerWarnings = {this.props.vehicleDetailsForOdometerWarnings}
                                updateOdometerWarnings = {this.updateOdometerWarningsHandler.bind(this)}
                                updateEngineHoursWarnings = {this.updateEngineHoursWarningsHandler.bind(this)}
                                showOdometerWarning = {this.props.showOdometerWarning}
                                showEngineHoursWarning = {this.props.showEngineHoursWarning}
                                showBankAccountRadioButton={showBankAccountRadioButton}
                                showInvoiceRadioButton={showInvoiceRadioButton}
                                isClosingPO={this.props.isClosingPO}
                                selectedCurrency={this.props.selectedCurrency}
                                applicableTaxTypes={this.props.applicableTaxTypes}
                                allowWorkCompletion = {this.props.isWorkCompletionFeatureEnabled}
                                allowEditPO = {!(isCarWashVendor(this.props.serviceClassification) || isInsuracePo) }
                                popOverDisplayShowRefNumber={this.props.popOverDisplayShowRefNumber}
                                closePOVendorReferenceNo={this.props.closePOVendorReferenceNo}
                                uiTaxes={this.props.uiTaxes}
                                fieldValidationsData={this.props.fieldValidationsData}
                                selectedCountry={this.props.country}
            />
            
            <PurchaseOrderBasicDetails vehicleDetails={this.props.vehicleDetails}
                                       client={this.props.client}
                                       paymentStatus={this.props.paymentStatus}
                                       authorizedStatus={this.props.authorizedStatus}
                                       actionType={ACTION_TYPE.CLOSE}/>

            <ApprovedLineItems complaints={this.props.complaints}
                               nonMaintenanceNotes={this.props.nonMaintenanceNotes}
                               maintenanceNotesText={this.props.maintenanceNotesText}
                               lineItems={this.props.lineItems}
                               paymentStatus={this.props.paymentStatus}
                               authorizedStatus={this.props.authorizedStatus}
                               actionType={ACTION_TYPE.CLOSE}
                               selectedCurrency={this.props.selectedCurrency}
                               listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
            />

            <POTotals approvedAmountTotal={this.props.approvedAmountTotal}
                      pendingAmountTotal={this.props.pendingAmountTotal}
                      taxAmount={this.props.invoiceDetails.get('taxAmount')}
                      actionType={ACTION_TYPE.CLOSE}
                      paymentStatus={this.props.paymentStatus}
                      selectedCurrency={this.props.selectedCurrency}
                      authorizedStatus={this.props.authorizedStatus}/>
 
            { (!vendorCanClosePo || isDiagnosisPo) && !this.props.isWorkCompletionFeatureEnabled ?
                <ConfirmMessageBox ref="closePOShowConfirmWindow" showCancelButton={false}
                                    title={messages.close_po_confirmation_title}
                                    message={isDiagnosisPo ? messages.close_po_has_diagnosis_lines_only_message 
                                        : messages.close_po__non_intellipay_vendor}
                                    okButtonText={messages.close_po_confirmation_ok}
                                    cancelButtonText={messages.close_po_confirmation_ok}
                                    onOKClickEvent={this.RedirectUserToDashboardHandler.bind(this)}
                                    onCancelClickEvent={null}/>

            : undefined} 

            <DotInspection />
			<BitInspection />
			<FraInspection />
            <CraneInspection />
                        
        </div>
    }
}

//I moved the original selector into this function.  It was getting too "clunky".
//If you just needed 1 node from the Redux store, you don't need a reselect.
//Instead you can make a new property in the JSON object below and assign the value from state.
//If you need a derived value, create a new selector in a new file and call below.
function mapStateToProps(state, props) {
    return {
        nonMaintenanceNotes: Notes.getNonMaintenanceNotes(state, props),
        maintenanceNotesText: Notes.getMaintenanceNotesText(state, props),
        taxErrorMessage: state.appState.getIn(['uiData', 'close', 'taxErrorMessage']),        
        //example:
        //oneToOnePropToState: state.appState.getIn(['uiData', 'area', 'oneToOnePropToState']),
        //newDerivedProp: newDerivedSelector(state, props),
        ...ClosePOSelector(state, props),
         ...Shared(state, props),
         errorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        isClosingPO: state.appState.getIn(['uiData', 'close', 'isClosingPO']),
        selectedCurrency:state.appState.getIn(['uiData', 'shared','selectedCurrency']),
        serviceClassification: state.appState.getIn(['serverData', 'shared', 'serviceClassification']),
        closePOPurchaseOrderData: state.appState.getIn(['serverData', 'close', 'purchaseOrder']),
        closePOVendorReferenceNo: state.appState.getIn(['serverData', 'close', 'vendorReferenceNo']),
        isDotInpsectionRequired: state.appState.getIn(['serverData', 'close', 'isDotInspectionRequired']),
		fieldValidationsData: state.appState.getIn(['uiData', 'shared', 'fieldValidations']),
        isWorkCompletionFeatureEnabled: state.appState.getIn(['uiData', 'shared', 'features','workCompletion']),
        country: state.appState.getIn(['uiData', 'shared','selectedCountry']),
    };
}


export default connect(mapStateToProps)(ClosePO);