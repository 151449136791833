import React from 'react';
import {render} from 'react-dom';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Map} from 'immutable';
import './FAQQuestionComponent.css';
import videos from 'data/videos';
import Features from 'components/Features';
import JWPlayer from '@jwplayer/jwplayer-react';

class FAQQuestionComponent extends React.Component {


    render() {

        return <Features render={restrictedFeatures => (<div className="question-body__handle">
            <table >
                <tbody>
                        {
                            restrictedFeatures.showVideoLinks==false && this.props.answer.get('isVideo')=== 'Y'?
                            false:
                            this.props.isVisible == false
                                ?
                                <tr role="button" className="question-text__handle" 
                                    onClick={this.props.questionClickHandler.bind(this, this.props.sectionTitle, this.props.sectionKeyQuestion)}>
                                    <td>
                                       <div className="question_spacing">
                                    {this.props.answer.get('isVideo') === 'Y' ? <i className="fa fa-youtube-play video-icon" aria-hidden="true"></i> : undefined }   
                                    <FormattedMessage
                                        id={this.props.question.get('id')}
                                        description={this.props.question.get('description')}
                                        defaultMessage={this.props.question.get('defaultMessage')}
                                        values={{ br: data => <br/> ,divFAQ: data => <div style={{'line-height':'110%'}}/>,b: data => <b>{data}</b>, a: data => <a>{data}</a> }}
                                    />
                                   </div>
                                </td>
                            </tr>
                                :
                                            <tr role="button" className="question-text__handle"
                                        onClick={this.props.questionClickHandler.bind(this, this.props.sectionTitle, this.props.sectionKeyQuestion)}>
                                        <div className="question_spacing">
                                        {this.props.answer.get('isVideo') === 'Y' ? <i className="fa fa-youtube-play video-icon" aria-hidden="true"></i> : undefined }
                                        <FormattedMessage
                                        id={this.props.question.get('id')}
                                        description={this.props.question.get('description')}
                                        defaultMessage={this.props.question.get('defaultMessage')}
                                        values={{ br: data => <br/> ,divFAQ: data => <div style={{'line-height':'110%'}}/>,b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>

                                    </div>

                                </tr>


                                    }

                        <tr>{!this.props.isVisible ? null
                            :
                            (
                                this.props.answer.get('isVideo') === 'Y'
                                    ?
                                    <div className="answer-text__handle">
                                    <JWPlayer
                                        file={videos[this.props.answer.get('videoId')].videoLocation}
                                        library={videos.playerLocation}
                                        id={this.props.answer.get('id')}
                                        image={videos[this.props.answer.get('videoId')].imageLocation}
                                        autostart={"viewable"}
                                    />
                    </div>

                                    :
                                <div className="answer-text__handle" onClick={this.props.questionClickHandler.bind(this, this.props.sectionTitle, this.props.sectionKeyQuestion)}>
                                    <FormattedMessage
                            id={this.props.answer.get('id')}
                            description={this.props.answer.get('description')}
                            defaultMessage={this.props.answer.get('defaultMessage')}
                            values={{ br: data => <br/> ,divFAQ: data => <div style={{'line-height':'110%'}}/> }}
                        />
                    </div>

                            )                            
                        }
                        </tr>
                </tbody>
            </table>
                    </div>)}/>;


    }
}

export default FAQQuestionComponent;