import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';

export function uiDataRegistrationView(state = initialAppState.getIn(['uiData', 'registrationView']), action) {
    switch (action.type) {
        case 'REGISTRATION_SELECTED_COUNTRY_CHANGED':
            return state.set('formData', Map())
                        .set('formDataValidationErrs', Map()); 
        case constants.SUBMIT_STEP_ACCOUNT_INFO_REQUESTED:
            return state.set('isSubmittingAccountInfo', true)
                .set('accountInfoErrorMessage', []);
        case constants.SUBMIT_STEP_ACCOUNT_INFO_RESOLVED:
            return state.set('isSubmittingAccountInfo', false)
            .set('bankInfoErrorMessage', []);
        case constants.SUBMIT_STEP_ACCOUNT_INFO_REJECTED:
            return state.set('isSubmittingAccountInfo', false)
                .set('accountInfoErrorMessage', action.errorMessage)
                .set('formData',Map());            
        case constants.SUBMIT_BANK_INFO_REQUESTED:
            return state.set('isSubmittingBankInfo', true)
                .set('bankInfoErrorMessage', []);
        case constants.SUBMIT_BANK_INFO_RESOLVED:
            return state.set('isSubmittingBankInfo', false);
        case constants.SUBMIT_BANK_INFO_REJECTED:
            return state.set('isSubmittingBankInfo', false)
                .set('bankInfoErrorMessage', action.errorMessage);
        case constants.FETCH_SERVICE_REQUESTED:
            return state.set('isFetchingServiceInfo', true)
                        .set('fetchingServiceErrorMessage', []);
        case constants.FETCH_SERVICE_RESOLVED:
            return state.set('isFetchingServiceInfo', false);
        case constants.FETCH_SERVICE_REJECTED:
            return state.set('isFetchingServiceInfo', false)
                            .set('fetchingServiceErrorMessage', action.errorMessage);
        case constants.SUBMIT_SERVICE_INFO_REQUESTED:
            return state.set('isSubmittingServiceInfo', true)
                .set('serviceInfoErrorMessage', []);
        case constants.SUBMIT_SERVICE_INFO_RESOLVED:
            return state.set('isSubmittingServiceInfo', false);
        case constants.SUBMIT_SERVICE_INFO_REJECTED:
            return state.set('isSubmittingServiceInfo', false)
                .set('serviceInfoErrorMessage', action.errorMessage);
        case constants.SUBMIT_AUTH_EMAIL_CONFIG_INFO_REQUESTED:
            return state.set('isSubmittingAuthEmailConfigInfo', true)
                .set('authorizationEmailConfigErrormessage', []);
        case constants.SUBMIT_AUTH_EMAIL_CONFIG_INFO_RESOLVED:
            return state.set('isSubmittingAuthEmailConfigInfo', false);
        case constants.SUBMIT_AUTH_EMAIL_CONFIG_INFO_REJECTED:
            return state.set('isSubmittingAuthEmailConfigInfo', false)
                .set('authorizationEmailConfigErrormessage', action.errorMessage);
        case constants.UPDATE_REGISTRATION_FIELD:
            return state.setIn(['formData', action.registrationField.name], action.registrationField.value);
        case constants.UPDATE_FORM_DATA_VALIDATION_ERRS:
            return state.setIn(['formDataValidationErrs', action.validationErrs.name], action.validationErrs.value);
        case constants.RESET_REGISTRATION:
            return state.set('formData', Map())
                .set('currentStep', 1)
                .set('vendorServiceMap', Map())
                .set('vendorMinorityMap', Map())
                .set('formDataValidationErrs', Map())
                .setIn(['authorizationEmailConfig', 'contacts'], [{ name: '', email: '' }])
                .setIn(['authorizationEmailConfig', 'isRegistered'], undefined)
                .setIn(['authorizationEmailConfigValidationErrs', 'contacts'], Map());
        case constants.REGISTRATION_NEXT_STEP:
            let currentStep = state.get('currentStep') != undefined ? state.get('currentStep') : 1;
            return state.set('currentStep', currentStep + 1);
        case constants.FETCH_VENDOR_MINORITY_FOR_REGISTRATION:
            return state.setIn(['vendorMinorityMap'], fromJS(action.VendorMinorityMap));
        case constants.SAVE_VENDOR_MINORITY_FOR_REGISTRATION:
            return state.setIn(['vendorMinorityMap', 'minorityVendor', action.vendorMinorityId, 'isServiceOffered'], true);
        case constants.LOAD_VENDOR_SERVICES_FOR_REGISTRATION:
            return state.setIn(['vendorServiceMap', 'services'], fromJS(action.vendorServiceMap));
        case constants.REMOVE_VALIDATION_ERROR:
            return state.deleteIn(['formDataValidationErrs', action.fieldName]);
        case constants.UPDATE_NOTIFICATION_CONTACT:
        case constants.REMOVE_NOTIFICATION_CONTACT:
            return state.setIn(['authorizationEmailConfig', 'contacts'], action.updatedContacts);
        case constants.UPDATE_EMAIL_CONFIG_REGISTRATION:
            return state.setIn(['authorizationEmailConfig', 'isRegistered'], action.isRegistered);
        case constants.UPDATE_EMAIL_CONFIG_VALIDATION_ERRS:
            return state.setIn(['authorizationEmailConfigValidationErrs', 'contacts', action.validationErrs.name], action.validationErrs.value);
        case constants.REMOVE_EMAIL_CONFIG_VALIDATION_ERRS:
            return state.deleteIn(['authorizationEmailConfigValidationErrs', 'contacts', action.fieldName]);     
        case "TERMS_AND_CONDITIONS_VALIDATION_ERRS":
            return state.setIn(['formDataValidationErrs',  action.validationErrs.name], action.validationErrs.value);
  
        //vendor profile
        case constants.VENDOR_SERVICE_SELECTED_FOR_REGISTRATION:
            return state.setIn(['vendorServiceMap', 'services',action.serviceKey.toString(),'isServiceOffered'],action.servicesSelected);
        case constants.VENDOR_MINORITY_SELECTED_FOR_REGISTRATION:
            return state.setIn(['vendorMinorityMap', 'minorityVendor',action.minorityKey,'isServiceOffered'],action.minoritySelected);
            
        default:
            return state;
    }
}