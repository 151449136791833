import {createSelector} from 'reselect';
import {Map} from 'immutable';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
 
const notesSelector = state => state.appState.getIn(['serverData', 'close','notes']);

const getMaintenanceNotesText = createSelector(notesSelector,(notesHistory) =>
    emptyMapIfUndefined(notesHistory).filter(notesHistory => notesHistory.get('complaintCode') === 'MA99')
);

const getNonMaintenanceNotes = createSelector(notesSelector, (notes) => {
    return emptyMapIfUndefined(notes).filter(notes => notes.get('complaintCode') != 'MA99')
});

export  {
    getMaintenanceNotesText,
    getNonMaintenanceNotes
};