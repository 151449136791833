// #region Imports
import { useState, useEffect } from "react";
import * as Api from "utils/Api";
// #endregion

// #region Component
export default function useRemoveAllStoreAccess(initialToken) {

    // #region State
    const [removeAllAccessInformation, setRemoveAllAccessInformation] = useState(null);
    const [isAllAccessBeingRemoved, setIsAllAccessBeingRemoved] = useState(null);
    const [error, setError] = useState(null);
    const [token] = useState(initialToken);
    // #endregion

    // #region eventHandler
    
    // #endregion

    // #region Effect
    useEffect(() => {

        if (removeAllAccessInformation !== null) {
            try {
                setIsAllAccessBeingRemoved(true);
                
                const removeUserPermissionAllStoreAccessAsync = async () => {

                    await Api.RemoveUserPermissionAllAccess(
                        removeAllAccessInformation.userId,
                        removeAllAccessInformation.adminUserId,
                        token
                    );
                    setIsAllAccessBeingRemoved(false);                    
                };
                removeUserPermissionAllStoreAccessAsync();
            }
            catch (ex) {                
                setError(ex)
                setIsAllAccessBeingRemoved(false);
            }
        }

    }, [removeAllAccessInformation]);
    // #endregion

    return [setRemoveAllAccessInformation, isAllAccessBeingRemoved, error]


}
// #endregion
