const videos = {
    playerLocation: "https://content.jwplatform.com/libraries/kPVNgryJ.js",
    quick_start: {
        videoLocation: "https://cdn.jwplayer.com/videos/6m2kZCSD-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/6m2kZCSD-1920.jpg",
        title: "Quick Start"
    },
    quick_start_mex: {
        videoLocation: "https://cdn.jwplayer.com/videos/1BCYqekD-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/1BCYqekD-1920.jpg",
        title: "Quick Start"
    },
    quick_start_lube: {
        videoLocation: "https://cdn.jwplayer.com/videos/VePwOfis-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/VePwOfis-1920.jpg",
        title: "Quick Start for Lube Vendors"
    },
    username_and_password: {
        videoLocation: "https://cdn.jwplayer.com/videos/wNEEgyw6-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/wNEEgyw6-1920.jpg"
    },
    explanation_of_po_types: {
        videoLocation: "https://cdn.jwplayer.com/videos/vbwYC5Vg-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/vbwYC5Vg-1920.jpg"
    },
    your_action_is_required: {
        videoLocation: "https://cdn.jwplayer.com/videos/dnytef1s-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/dnytef1s-1920.jpg"
    },
    approved_for_service: {
        videoLocation: "https://cdn.jwplayer.com/videos/E148qosj-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/E148qosj-1920.jpg"
    },
    waiting_for_ari_client_approval: {
        videoLocation: "https://cdn.jwplayer.com/videos/6UNiEKTh-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/6UNiEKTh-1920.jpg"
    },
    payment_in_progress: {
        videoLocation: "https://cdn.jwplayer.com/videos/WBYxFQht-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/WBYxFQht-1920.jpg"
    },
    search_for_my_pos: {
        videoLocation: "https://cdn.jwplayer.com/videos/iIW7uQTC-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/iIW7uQTC-1920.jpg"
    },
    searching_for_vehicle: {
        videoLocation: "https://cdn.jwplayer.com/videos/KmISmlH6-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/KmISmlH6-1920.jpg"
    },
    close_po: {
        videoLocation: "https://cdn.jwplayer.com/videos/qLfBXVGK-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/qLfBXVGK-1920.jpg"
    },
    build_po: {
        videoLocation: "https://cdn.jwplayer.com/videos/04Kj1nra-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/04Kj1nra-1920.jpg"
    },
    edit_po: {
        videoLocation: "https://cdn.jwplayer.com/videos/cRVtsD8W-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/cRVtsD8W-1920.jpg"
    },
    help: {
        videoLocation: "https://cdn.jwplayer.com/videos/H5xIKxdU-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/H5xIKxdU-1920.jpg"
    },
    contact: {
        videoLocation: "https://cdn.jwplayer.com/videos/zGYYhcfz-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/zGYYhcfz-1920.jpg"
    },
    cancel_po: {
        videoLocation: "http://content.jwplatform.com/videos/1BLAOYAe-yE1lo0L7.mp4",
        imageLocation: "http://content.jwplatform.com/thumbs/1BLAOYAe-1280.jpg"
    },
    search_tips: {
        videoLocation: "https://cdn.jwplayer.com/videos/xC9TSHJJ-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/xC9TSHJJ-1920.jpg"
    },
    vehicle_information: {
        videoLocation: "https://cdn.jwplayer.com/videos/w6L0YcgJ-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/w6L0YcgJ-1920.jpg"
    },
    brakes: {
        videoLocation: "https://cdn.jwplayer.com/videos/TiJlyvAw-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/TiJlyvAw-1920.jpg"
    },
    tires: {
        videoLocation: "https://cdn.jwplayer.com/videos/o6ZB0Q5e-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/o6ZB0Q5e-1920.jpg"
    },
    enrollement: {
        videoLocation: "https://cdn.jwplayer.com/videos/oEsAkqdV-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/oEsAkqdV-1920.jpg"
    },
    new_supplier_registration: {
        videoLocation: "https://cdn.jwplayer.com/videos/z4RivLEC-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/z4RivLEC-1920.jpg"
    },
    dealer_draft: {
        videoLocation: "https://cdn.jwplayer.com/videos/hbJFHOly-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/hbJFHOly-1920.jpg"
    },
    car_wash_pos: {
        videoLocation: "https://cdn.jwplayer.com/videos/8KkdINzN-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/8KkdINzN-1920.jpg"
    },
    work_completed_pos: {
        videoLocation: "https://cdn.jwplayer.com/videos/aFI3shO8-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/aFI3shO8-1920.jpg"
    },
    adv_search_ata: {
        videoLocation: "https://cdn.jwplayer.com/videos/AL7OLh2Z-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/AL7OLh2Z-1920.jpg"
    },
    registration_information: {
        videoLocation: "https://cdn.jwplayer.com/videos/hbJFHOly-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/hbJFHOly-1920.jpg"
    },
    aged_pos: {
        videoLocation: "https://cdn.jwplayer.com/videos/4TAU2xzi-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/4TAU2xzi-1920.jpg"
    },
    forgot_password: {
        videoLocation: "https://cdn.jwplayer.com/videos/wcdqvgTx-k5CZG3ps.mp4",
        imageLocation: "https://cdn.jwplayer.com/thumbs/wcdqvgTx-1920.jpg"
    }


};

export default videos;