import { createSelector } from 'reselect';
import { Map, Set, List, fromJS } from 'immutable';
import { LINE_ITEM_TYPE } from 'constants/App';
import * as constants from 'constants/App';
import lodash from 'lodash';

import {
    emptyMapIfUndefined,
    emptyStringIfUndefined,
    emptyArrayIfUndefined,
    emptySetIfUndefined,
    falseIfUndefined,
    getWheelObject,
    isNewTire,
    getTireTempId
} from 'utils/HelperFunctions';
import allManufacturers from '../data/Manufacturers';
import allReasons from '../data/Reasons';
import tireProductCodes from 'data/TireProductCodes';
import brakesProductCodes from 'data/BrakesProductCodes';

//convert to title casing
function convertToTitle(list) {
    if (list == undefined || list.length <= 0) return;

    let str = list;
    str = str.toLowerCase().replace(/[^-.'\s]+/g, function (word) {
        return word.replace(/^./, function (first) {
            return first.toUpperCase();
        });
    });

    //example with LOF
    let ignore = [{ word: 'LOF', regex: 'LOF\\s' }];

    for (let i in ignore) {
        let re = new RegExp(ignore[i].regex, 'gi');
        str = str.replace(re, ignore[i].word);
    }
    return str;
}

const isCreatingLineItemsSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'isCreatingLineItems'])

/*Parameters for the vehicle*/
const parameterListSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'addNewLineItemsView', 'vehicleParameters']));
/*Add new line items data*/
const addNewLineItemsViewSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView']);
/*selected correction type*/
const correctionTypeSelector = state => {
    return state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'lineItemCorrectionType'])
        || state.appState.getIn(['uiData', 'addNewLineItemsView', 'selectedCorrectionType'])
}


/*All ata codes*/
const ataCodesSelector = state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'searchedAtaCodes']);
/*Show toggle*/
const showAddNewLineItemsWindowSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'showWindow']);
/*Auto complete ata code description*/
const autoCompleteATACodeDescSelector = state => emptyStringIfUndefined(state.appState.getIn(['uiData', 'addNewLineItemsView', 'autoCompleteATACodeDescription']));

/*Returns selected or searched ata code */
const currentATACodeSelector = state => {    
    return emptyStringIfUndefined(state.appState.getIn(['uiData', 'addNewLineItemsView', 'selectedATACode'])) == ''
        ? emptyStringIfUndefined(state.appState.getIn(['uiData', 'addNewLineItemsView', 'searchedATACode']))
        : emptyStringIfUndefined(state.appState.getIn(['uiData', 'addNewLineItemsView', 'selectedATACode']));
};

/* Is restricted labor for selected ATA*/
const hideLaborInputFieldsSelector = state => {
    var selectedATACode = currentATACodeSelector(state);
    var exclusiveATAsForSelectedCode = emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'restrictedATACodes', selectedATACode, 'restrictedCodesForLabor']));
    var existingPOLineItems = emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'lineItems']));

    //Check if both has ATA codes
    if (exclusiveATAsForSelectedCode.size > 0 && existingPOLineItems.size > 0) {

        //find if any existing line item has had mutually exclusive labor already
        let filteredLineItems = existingPOLineItems.filter(line => {
            return (exclusiveATAsForSelectedCode.filter(e => e.get('restrictedCode') == line.get('productCode')
                && line.get('lineItemType') == LINE_ITEM_TYPE.LABOR)).size > 0;
        });


        //This logic make sure that meaningful message to be shown to the user when adding labor to restricted product code
        if (filteredLineItems != undefined && filteredLineItems.size > 0) {
            let existingProductDescription = filteredLineItems.valueSeq().first().get('productDescription');
            let newProductDescription = emptyStringIfUndefined(state.appState.getIn(['uiData', 'addNewLineItemsView', 'vendorProductDescription'])) == ''
                ? emptyStringIfUndefined(state.appState.getIn(['uiData', 'addNewLineItemsView', 'autoCompleteATACodeDescription']))
                : emptyStringIfUndefined(state.appState.getIn(['uiData', 'addNewLineItemsView', 'vendorProductDescription']));

            if (existingProductDescription != undefined && existingProductDescription.length > 0 && newProductDescription != undefined && newProductDescription.length > 0) {
                return Map({
                    newProductDescription: newProductDescription,
                    existingProductDescription: existingProductDescription
                })
            }
            else
                return Map();
        }
        return Map();
    }
    else
        return Map();

};

/*Frequently used ata codes*/
const frequentlyUsedATACodesSelector = createSelector(
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'frequentlyUsedATACodes']),
    ataCodesSelector,
    state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'selectedATACode']),
    state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'selectedComplaintCode']),
    (frequentlyUsedATACodes,
        allATACodes,
        selectedATACode) => {
        if (frequentlyUsedATACodes == undefined) return Map();

        frequentlyUsedATACodes = frequentlyUsedATACodes.reduce((freqATACodes, ataCode) => {
            let ataDesc = ataCode.get('description').length > 27 ? ataCode.get('description').substr(0, 24) + '...' : ataCode.get('description');

            return freqATACodes.set(ataCode, Map({
                productCode: ataCode.get('productCode'),
                description: convertToTitle(ataDesc),
                fullDescription: convertToTitle(ataCode.get('description')),
                isSelected: ataCode.get('productCode') == selectedATACode,
                popularity : ataCode.get('popularity')
            }));                            
        }, Map());
                                                                                                                                  
        return frequentlyUsedATACodes.sortBy(x =>x.get('popularity') === 0 ? x.get('description') : x.get('popularity'));
        
    }
);

/*Correction types*/
const correctionTypesSelector = createSelector(
    currentATACodeSelector,
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'searchedAtaCodes']),
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'frequentlyUsedATACodes']),
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'correctionTypes']),
    state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'selectedCorrectionType']),
    (currentATACode,
        searchedAtaCodes,
        freqUsedAtaCodes,
        allCorrectionTypes,
        selectedCorrectionType) => {
        /*correction types for the ata code*/
        let searchedAtaCode = searchedAtaCodes != undefined ? searchedAtaCodes.find(x => x.code == currentATACode) : searchedAtaCodes;
        let freqUsedAtaCode = freqUsedAtaCodes != undefined ? freqUsedAtaCodes.find(x => x.get('productCode') == currentATACode) : freqUsedAtaCodes;

        
        let restrictedCorrectionRepairTypes;

        //If user searches for ATA and clicks on it
        //get the restricted correction types and restricted correctionandRepair types
        if (searchedAtaCode != undefined)
        {            
            restrictedCorrectionRepairTypes=searchedAtaCode.correctionAndRepairTypesRestricted;
        }
        //If user selects a frequently used ATA code
        else if (freqUsedAtaCode != undefined)
        {            
            restrictedCorrectionRepairTypes=freqUsedAtaCode.get('correctionAndRepairTypesRestricted');
        }        

        let allCorrectionTypesSet = new Set(allCorrectionTypes);        
        let correctionTypesAllowed ;

        //get the allowed correction types 
        //here we are taking out all the correction types with restricted repair Type as 'ALL';  ALL indicates that user can not use that correction type at all

        //This condition is placed to handle the scenario of restricted correction and repair type combination not available
        if(restrictedCorrectionRepairTypes!=undefined)            
            correctionTypesAllowed=allCorrectionTypes.filter(x=> restrictedCorrectionRepairTypes.find(a=>a.repairType=="ALL" && a.correctionType==x.get('description'))==undefined);       

       return allCorrectionTypes.reduce((correctionTypes, correctionType) => {
            return correctionTypes.set(correctionType, Map({
                id: correctionType.get("id"),
                correctionType: correctionType.get("description"),
                enabled: correctionTypesAllowed!=undefined && correctionTypesAllowed.find(x=>x.get('description')==correctionType.get('description'))!=undefined,
                isSelected: correctionType.get("description") == selectedCorrectionType,
                restrictedRepairTypes:restrictedCorrectionRepairTypes==undefined?undefined:restrictedCorrectionRepairTypes.filter(a=>a.correctionType==correctionType.get('description')).map(function(d) { return d.repairType; })
            }));
        }, Map());
    }
);

//This is to select the restricted repair types - LABOR, PART, ALL for the selected correction type of the ATA Code
const restrictedRepairTypesSelector=createSelector(correctionTypesSelector,
state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'selectedCorrectionType']),
(correctionTypes,selectedCorrectionType)=>{

//Get the selected correction type object from the available list
var correctionType=correctionTypes!=undefined?correctionTypes.valueSeq().toArray().find(a=>a.get('correctionType')==selectedCorrectionType):undefined;
//Get the restricted repair types from the select object
var restrictedRepairTypes=correctionType!=undefined?correctionType.get('restrictedRepairTypes'):undefined;     
    
    return restrictedRepairTypes;
});

    


/*Input totals */
const correctionDetailsTotalSelector = state => {
    var hours = Number(state.appState.getIn(['uiData', 'addNewLineItemsView', 'hours']));
    var rate = Number(state.appState.getIn(['uiData', 'addNewLineItemsView', 'rate']));
    var qty = Number(state.appState.getIn(['uiData', 'addNewLineItemsView', 'qty']));
    var cost = Number(state.appState.getIn(['uiData', 'addNewLineItemsView', 'cost']));

    return Map({
        laborTotal: hours * rate,
        partTotal: qty * cost
    });
};

/*Selected complaint code*/
const complaintCodeSelector = state => {
    var complaintCode = state.appState.getIn(['uiData', 'addNewLineItemsView', 'selectedComplaintCode']);
    var complaintCodeMap = emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'complaintTypes'])
        .filter(x => x.get('code') == complaintCode));
    return emptyMapIfUndefined(complaintCodeMap.get(complaintCode));
};



/*ATA codes for search.*/
const ataCodesForSearchSelector = createSelector(
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'searchedAtaCodes']),
    (searchedAtaCodes) => {
        return searchedAtaCodes != undefined ? searchedAtaCodes : new Array();
    }
);
/*Selected ata code description*/
const selectedATACodeDescriptionSelector = createSelector(
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'searchedAtaCodes']),
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'frequentlyUsedATACodes']),
    currentATACodeSelector,
    (searchedAtaCodes,
        freqUsedAtaCodes,
        currentATACode) => {
        let searchedAtaCode = searchedAtaCodes != undefined ? searchedAtaCodes.find(x => x.code == currentATACode) : searchedAtaCodes;
        let freqUsedAtaCode = freqUsedAtaCodes != undefined ? freqUsedAtaCodes.find(x => x.get('productCode') == currentATACode) : freqUsedAtaCodes;
        if (searchedAtaCode != undefined)
            return searchedAtaCode.description;
        else if (freqUsedAtaCode != undefined)
            return freqUsedAtaCode.get('description');
        else
            return '';
    }
);

const selectedATAAllowsMultipleLinesSelector=createSelector(
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'searchedAtaCodes']),
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'frequentlyUsedATACodes']),
    currentATACodeSelector,
    (searchedAtaCodes,
        freqUsedAtaCodes,
        currentATACode) => {
        let searchedAtaCode = searchedAtaCodes != undefined ? searchedAtaCodes.find(x => x.code == currentATACode) : searchedAtaCodes;
        let freqUsedAtaCode = freqUsedAtaCodes != undefined ? freqUsedAtaCodes.find(x => x.get('productCode') == currentATACode) : freqUsedAtaCodes;            
        if (searchedAtaCode != undefined)
            return searchedAtaCode.multipleLineItemsPermitted;
        else if (freqUsedAtaCode != undefined)
            return freqUsedAtaCode.get('multipleLineItemsPermitted');
        else
            return '';
    }

);


/*Line item*/
const newLineItemsSelector = createSelector(
    correctionDetailsTotalSelector,
    addNewLineItemsViewSelector,
    complaintCodeSelector,
    selectedATACodeDescriptionSelector,
    currentATACodeSelector,
    (correctionDetailsTotal,
        addNewLineItemsView,
        complaintCode,
        selectedATACodeDescription,
        currentATACode) =>
        Map({
            ataCode: currentATACode,
            lineDescription: selectedATACodeDescription,
            vendorProductCode: addNewLineItemsView.get('vendorProductCode'),
            qty: addNewLineItemsView.get('qty'),
            partCost: addNewLineItemsView.get('cost'),
            partTotal: correctionDetailsTotal.get('partTotal'),
            hours: addNewLineItemsView.get('hours'),
            laborRate: addNewLineItemsView.get('rate'),
            laborTotal: correctionDetailsTotal.get('laborTotal'),
            complaintCode: emptyStringIfUndefined(complaintCode.get('code')),
            selectedCorrectionType: addNewLineItemsView.get('selectedCorrectionType'),
            partWarranty: Map({
                months: addNewLineItemsView.getIn(['partWarranty', 'months']),
                miles: addNewLineItemsView.getIn(['partWarranty', 'miles']),
                hours: addNewLineItemsView.getIn(['partWarranty', 'hours'])
            })
        })
);

/*Searched ata code selector*/
const searchedATACodeSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'searchedATACode']);

const existingPOLineItemsSelector = state => state.appState.getIn(['uiData', 'addEdit', 'lineItems']);

const vehicleDetailsSelector = state => {
    return state.appState.getIn(['serverData', 'shared', 'vehicleDetails']);
};

const isfetchingDataSelector = state => state.appState.getIn(['uiData', 'shared', 'componentsFetchingDataFromAPI', 'AddNewLineItems']) != undefined;

const hideAtaSearchResultContainerSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'hideAtaSearchResultContainer']);

const ataCodesSearchResultSelector = state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'searchedAtaCodes']);

const ataParametersDescriptionSelector = state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'ataParameters']);

const getAtaParametersSortedSelector = createSelector(ataParametersDescriptionSelector, (ataParametersDescription) =>
    ataParametersDescription === undefined ? List() :
        ataParametersDescription.sort((a, b) => {
            return a.get('priority') - b.get('priority');
        }));
const itemsAddedToPoSelector = state => {
    let productDescriptions = emptyMapIfUndefined(state.appState.getIn(['uiData', 'addNewLineItemsView', 'itemsAddedToPo']));

    // please note: ata description is used as map key to create uniqueness in the collection
    return productDescriptions.reduce((p, c) => p.set(c.get('productDescription'),
        Map({ productDescription: convertToTitle(c.get('vendorProductCode') != undefined ? c.get('vendorProductCode') + ' - ' + c.get('productDescription') : c.get('productDescription')) })),
        Map());
};

const isFetchiDataForSearchATASelector = state => falseIfUndefined(state.appState.getIn(['serverData', 'addNewLineItemsView', 'isFetchingData']));

const enableAddLineItemToPoSelector = createSelector(addNewLineItemsViewSelector, (newLineItems) => {


    return newLineItems.get('cost') >= 0
        && newLineItems.get('qty') > 0
        || newLineItems.get('rate') >= 0 && newLineItems.get('hours') > 0;
});

function manufacturers() { return allManufacturers };
function reasons() { return allReasons };
function tireProductCodesData() { return tireProductCodes };
function brakesProductCodesData() { return brakesProductCodes };

const tireManufacturingDetailsSelector = createSelector(
    manufacturers,
    reasons,
    state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'tireDetails']) || Map(),
    (allManufacturers, allReasons, tireDetails) => {
        const defaultManufacturer=  tireDetails.get(constants.TIRE_DETAILS.MANUFACTURER) || '';
        const defaultReason = tireDetails.get(constants.TIRE_DETAILS.REASON) || '';

        return {
            allManufacturers: allManufacturers,
            allReasons: allReasons,
            defaultManufacturer: defaultManufacturer.toUpperCase(),
            defaultModel: tireDetails.get(constants.TIRE_DETAILS.MODEL) || '',
            defaultReason: defaultReason.toUpperCase(),
            tireWidth: tireDetails.get(constants.TIRE_DETAILS.TIRE_WIDTH) || null,
            tireAspectRatio: tireDetails.get(constants.TIRE_DETAILS.ASPECT_RATIO) || null,
            tireRimDiameter: tireDetails.get(constants.TIRE_DETAILS.RIM_DIAMETER) || null,
        }
    }
)

//--------------------


const lineItemIdBeingEditedSelector = (state) => {

    const lineItemIdForTires = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'lineItemForTires']);
    return lineItemIdForTires || null;
}

const tireSpecificationsSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'tireSpecification']);


const maxAxlesAllowedSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'maxAxlesAllowed']);
const wheelsForLineItemSelector = createSelector(
    lineItemIdBeingEditedSelector,
    tireSpecificationsSelector,
    (lineItemIdBeingEdited, tireSpecifications) => {
        let wheels = [];
        if (tireSpecifications) {
            wheels = tireSpecifications.toJSON();

        }
        return wheels;
    }
)

const defaultTreadDepthUnitSelector = createSelector(
    state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'defaultTreadDepthUnit']),
    wheelsForLineItemSelector,
    (defaultUnit, wheelsAxles)=>{
        let unit = defaultUnit;
        if(wheelsAxles!=undefined && wheelsAxles.length>0){
            unit = wheelsAxles[0].unitOfMeasurement
        }
        return unit;
    }
);


const axlesSelector = createSelector(
    tireSpecificationsSelector,
    (tireSpecifications) => {
        return tireSpecifications
    }
);



const spareWheelSelector = createSelector(
    wheelsForLineItemSelector,
    correctionTypeSelector,
    defaultTreadDepthUnitSelector,
    lineItemIdBeingEditedSelector,
    (wheelsForLineItem, correctionType, defaultTreadDepthUnit, lineItemIdBeingEdited) => {
        const spareWheel = wheelsForLineItem.find(x => x.axleNumber == null);

        if (!spareWheel) // create dummy wheel
            return {
                axlePosition: 0,
                spareWheel: getWheelObject(
                    getTireTempId(lineItemIdBeingEdited, 0, constants.TIRE_POSITIONS.SPARE),
                    false, //?
                    constants.TIRE_POSITIONS.SPARE,
                    '',
                    '',
                    defaultTreadDepthUnit,
                    canEditWheel(correctionType, null, lineItemIdBeingEdited),
                    false
                ),
                canAddWheel: false
            }
        else
            return {
                axlePosition: 0,
                spareWheel: getWheelObject(
                    getTireTempId(lineItemIdBeingEdited, 0, constants.TIRE_POSITIONS.SPARE),
                    isNewTire(spareWheel.eventType), //?
                    spareWheel.position,
                    spareWheel.replacedTireTreadDepth || '',
                    spareWheel.treadDepth || '',
                    spareWheel.unitOfMeasurement,
                    canEditWheel(correctionType, spareWheel.lineItemId, lineItemIdBeingEdited),
                    false
                ),
                canAddWheel: false
            }
    }
)
function canEditWheel(correctionType, currentLineItemId, lineItemIdBeingEdited) {
    return (correctionType == constants.CORRECTION_TYPE.REPLACE && currentLineItemId == lineItemIdBeingEdited)
        || (correctionType!=null && correctionType!=undefined && correctionType.toLowerCase() == 'none' && (!currentLineItemId || currentLineItemId == lineItemIdBeingEdited))
        || (correctionType == constants.CORRECTION_TYPE.REPLACE && !currentLineItemId)

}
const frontAxleSelector = createSelector(
    wheelsForLineItemSelector,
    correctionTypeSelector,
    defaultTreadDepthUnitSelector,
    lineItemIdBeingEditedSelector,
    (wheelsForLineItem, correctionType, defaultTreadDepthUnit, lineItemIdBeingEdited) => {
        let leftInnerWheel = wheelsForLineItem.find(x => x.axleNumber == 1 && x.position == constants.TIRE_POSITIONS.LEFT_FRONT)
        let rightInnerWheel = wheelsForLineItem.find(x => x.axleNumber == 1 && x.position == constants.TIRE_POSITIONS.RIGHT_FRONT)
        let dummyLeftInnerWheel = undefined;
        let dummyrightInnerWheel = undefined;

        if (!leftInnerWheel)
            dummyLeftInnerWheel = getWheelObject(
                getTireTempId(lineItemIdBeingEdited, 1, constants.TIRE_POSITIONS.LEFT_FRONT),
                false,
                constants.TIRE_POSITIONS.LEFT_INNER,
                '',
                '',
                defaultTreadDepthUnit,
                correctionType == constants.CORRECTION_TYPE.REPLACE,
                false)
        else
            leftInnerWheel = getWheelObject(
                getTireTempId(lineItemIdBeingEdited, 1, constants.TIRE_POSITIONS.LEFT_FRONT),
                isNewTire(leftInnerWheel.eventType),
                leftInnerWheel.position == constants.TIRE_POSITIONS.LEFT_FRONT ? constants.TIRE_POSITIONS.LEFT_INNER : undefined,
                leftInnerWheel.replacedTireTreadDepth || '',
                leftInnerWheel.treadDepth || '',
                leftInnerWheel.unitOfMeasurement,
                canEditWheel(correctionType, leftInnerWheel.lineItemId, lineItemIdBeingEdited),
                false)

        if (!rightInnerWheel)
            dummyrightInnerWheel = getWheelObject(
                getTireTempId(lineItemIdBeingEdited, 1, constants.TIRE_POSITIONS.RIGHT_FRONT),
                false,
                constants.TIRE_POSITIONS.RIGHT_INNER,
                '',
                '',
                defaultTreadDepthUnit,
                correctionType == constants.CORRECTION_TYPE.REPLACE,
                false)
        else
            rightInnerWheel = getWheelObject(
                getTireTempId(lineItemIdBeingEdited, 1, constants.TIRE_POSITIONS.RIGHT_FRONT),
                isNewTire(rightInnerWheel.eventType),
                rightInnerWheel.position == constants.TIRE_POSITIONS.RIGHT_FRONT ? constants.TIRE_POSITIONS.RIGHT_INNER : undefined,
                rightInnerWheel.replacedTireTreadDepth || '',
                rightInnerWheel.treadDepth || '',
                rightInnerWheel.unitOfMeasurement,
                canEditWheel(correctionType, rightInnerWheel.lineItemId, lineItemIdBeingEdited),
                false)


        return {
            axlePosition: 1,
            leftInnerWheel: !leftInnerWheel ? dummyLeftInnerWheel : leftInnerWheel,
            rightInnerWheel: !rightInnerWheel ? dummyrightInnerWheel : rightInnerWheel,
            canAddWheel: true
        }
    }
)
const dummyAxlesToAddSelector = state => {
    return state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'dummyAxlesToAdd']) || List()
};

const rearAxlesSelector = createSelector(
    wheelsForLineItemSelector,
    correctionTypeSelector,
    defaultTreadDepthUnitSelector,
    dummyAxlesToAddSelector,
    lineItemIdBeingEditedSelector,
    (wheelsForLineItem, correctionType, defaultTreadDepthUnit, dummyAxlesToAdd, lineItemIdBeingEdited) => {
        var rearAxles = wheelsForLineItem.filter(x => x.axleNumber > 1) || [];
        rearAxles = rearAxles.sort((a, b) => {

            if(b.axleNumber > a.axleNumber)
                return -1; 
            if(b.axleNumber < a.axleNumber)
                return 1 ; 
            return 0 ; 
        });

        const groupedAxles = lodash.groupBy(rearAxles, 'axleNumber');
        let rearAxlesReModeled = [];
        for (var i in groupedAxles) {
            let axle = groupedAxles[i];

            if (axle) {
                let leftInner = axle.find(x => x.position == constants.TIRE_POSITIONS.LEFT_INNER);
                let righInner = axle.find(x => x.position == constants.TIRE_POSITIONS.RIGHT_INNER);
                let leftOuter = axle.find(x => x.position == constants.TIRE_POSITIONS.LEFT_OUTER);
                let rightOuter = axle.find(x => x.position == constants.TIRE_POSITIONS.RIGHT_OUTER);

                const leftInnerAndRightInnerMissing = (!!leftOuter && !!rightOuter) && (!leftInner && !righInner);
                const isDummyRightOuterRequired = !!leftOuter && !rightOuter;
                const isDummyRightInnerRequired = (!!leftInner && !righInner) || (!!leftOuter || !!rightOuter);
                const isDummyLeftInnerRequired = (!!righInner && !leftInner) || (!!leftOuter || !!rightOuter);
                const isDummyLeftOuterRequired = !!rightOuter && !leftOuter;



                /*                
                    LO  _   ++++++++++  _   _       (Scenario A)

                    _   LI  ++++++++++  _   _       (Scenario B)

                    _   _   ++++++++++  RI  _       (Scenario C)

                    _   _   ++++++++++  _   RO      (Scenario D)

                    LO  _   ++++++++++  _   RO      (Scenario E)
                */


                const dummyrightOuter = isDummyRightOuterRequired ?
                    getWheelObject(
                        getTireTempId(lineItemIdBeingEdited, i, constants.TIRE_POSITIONS.RIGHT_OUTER),
                        false,
                        constants.TIRE_POSITIONS.RIGHT_OUTER,
                        '',
                        '',
                        defaultTreadDepthUnit,
                        canEditWheel(correctionType, null, lineItemIdBeingEdited),
                        false)
                    : undefined;


                const dummyrighInner = isDummyRightInnerRequired ?
                    getWheelObject(
                        getTireTempId(lineItemIdBeingEdited, i, constants.TIRE_POSITIONS.RIGHT_INNER),
                        false,
                        constants.TIRE_POSITIONS.RIGHT_INNER,
                        '',
                        '',
                        defaultTreadDepthUnit,
                        canEditWheel(correctionType, null, lineItemIdBeingEdited),
                        false)
                    : undefined


                const dummyleftInner = isDummyLeftInnerRequired ?
                    getWheelObject(
                        getTireTempId(lineItemIdBeingEdited, i, constants.TIRE_POSITIONS.LEFT_INNER),
                        false,
                        constants.TIRE_POSITIONS.LEFT_INNER,
                        '',
                        '',
                        defaultTreadDepthUnit,
                        canEditWheel(correctionType, null, lineItemIdBeingEdited),
                        false)
                    : undefined

                const dummyleftOuter = isDummyLeftOuterRequired ?
                    getWheelObject(getTireTempId(lineItemIdBeingEdited, i, constants.TIRE_POSITIONS.LEFT_OUTER),
                        false,
                        constants.TIRE_POSITIONS.LEFT_OUTER,
                        '',
                        '',
                        defaultTreadDepthUnit,
                        canEditWheel(correctionType, null, lineItemIdBeingEdited),
                        false)
                    : undefined

                rightOuter = !!rightOuter ?
                    getWheelObject(getTireTempId(lineItemIdBeingEdited, i, rightOuter.position),
                        isNewTire(rightOuter.eventType),
                        rightOuter.position,
                        rightOuter.replacedTireTreadDepth || '',
                        rightOuter.treadDepth || '',
                        rightOuter.unitOfMeasurement,
                        canEditWheel(correctionType, rightOuter.lineItemId, lineItemIdBeingEdited),
                        false)
                    : undefined

                righInner = !!righInner ? getWheelObject(
                    getTireTempId(lineItemIdBeingEdited, i, righInner.position),
                    isNewTire(righInner.eventType),
                    righInner.position,
                    righInner.replacedTireTreadDepth || '',
                    righInner.treadDepth || '',
                    righInner.unitOfMeasurement,
                    canEditWheel(correctionType, righInner.lineItemId, lineItemIdBeingEdited),
                    false)
                    : undefined

                leftInner = !!leftInner ?
                    getWheelObject(getTireTempId(lineItemIdBeingEdited, i, leftInner.position),
                        isNewTire(leftInner.eventType),
                        leftInner.position,
                        leftInner.replacedTireTreadDepth || '',
                        leftInner.treadDepth || '',
                        leftInner.unitOfMeasurement,
                        canEditWheel(correctionType, leftInner.lineItemId, lineItemIdBeingEdited),
                        false)
                    : undefined

                leftOuter = !!leftOuter ?
                    getWheelObject(getTireTempId(lineItemIdBeingEdited, i, leftOuter.position),
                        isNewTire(leftOuter.eventType), //?
                        leftOuter.position,
                        leftOuter.replacedTireTreadDepth || '',
                        leftOuter.treadDepth || '',
                        leftOuter.unitOfMeasurement,
                        canEditWheel(correctionType, leftOuter.lineItemId, lineItemIdBeingEdited),
                        false)
                    : undefined

                //user can add wheels if neither actual outer wheels nor dummy outer wheels are present
                const canAddWheelToAxle = !leftOuter && !dummyleftOuter && !rightOuter && !dummyrightOuter;

                const item = {
                    axlePosition: i,
                    leftInnerWheel: !leftInner ? dummyleftInner : leftInner,
                    rightInnerWheel: !righInner ? dummyrighInner : righInner,
                    leftOuterWheel: !leftOuter ? dummyleftOuter : leftOuter,
                    rightOuterWheel: !rightOuter ? dummyrightOuter : rightOuter,
                    canAddWheel: canAddWheelToAxle
                }

                rearAxlesReModeled.push(item);
            }
        }


        dummyAxlesToAdd = dummyAxlesToAdd.toJSON();
        const dummyAxles = lodash.groupBy(dummyAxlesToAdd, 'axleNumber');
        let rearAxlesWithDummyWheels = [];

        for (var axle in dummyAxles) {

            const shouldAddLeftInnerWheel = dummyAxlesToAdd.findIndex(x => x.axleNumber == axle && x.position == constants.TIRE_POSITIONS.LEFT_INNER) > -1;
            const shouldAddRightInnerWheel = dummyAxlesToAdd.findIndex(x => x.axleNumber == axle && x.position == constants.TIRE_POSITIONS.RIGHT_INNER) > -1;
            const shouldAddLeftOuterWheel = dummyAxlesToAdd.findIndex(x => x.axleNumber == axle && x.position == constants.TIRE_POSITIONS.LEFT_OUTER) > -1;
            const shouldAddRightOuterWheel = dummyAxlesToAdd.findIndex(x => x.axleNumber == axle && x.position == constants.TIRE_POSITIONS.RIGHT_OUTER) > -1;

            let leftInnerWheelForAxle = wheelsForLineItem.find(y => y.axleNumber == axle && y.position == constants.TIRE_POSITIONS.LEFT_INNER);
            let rightInnerForAxle = wheelsForLineItem.find(y => y.axleNumber == axle && y.position == constants.TIRE_POSITIONS.RIGHT_INNER);
            let leftOuterForAxle = wheelsForLineItem.find(y => y.axleNumber == axle && y.position == constants.TIRE_POSITIONS.LEFT_OUTER);
            let rightOuterForAxle = wheelsForLineItem.find(y => y.axleNumber == axle && y.position == constants.TIRE_POSITIONS.RIGHT_OUTER);

            const argsForLeftInnerWheel = [getTireTempId(lineItemIdBeingEdited, axle, constants.TIRE_POSITIONS.LEFT_INNER),
                false, constants.TIRE_POSITIONS.LEFT_INNER, '', '', defaultTreadDepthUnit, canEditWheel(correctionType, null, lineItemIdBeingEdited),
                false];

            const argsForRightInnerWheel = [getTireTempId(lineItemIdBeingEdited, axle, constants.TIRE_POSITIONS.RIGHT_INNER),
                false, constants.TIRE_POSITIONS.RIGHT_INNER, '', '', defaultTreadDepthUnit, canEditWheel(correctionType, null, lineItemIdBeingEdited),
                false];

            const argsForLeftOuterWheel = [getTireTempId(lineItemIdBeingEdited, axle, constants.TIRE_POSITIONS.LEFT_OUTER),
                false, constants.TIRE_POSITIONS.LEFT_OUTER, '', '', defaultTreadDepthUnit, canEditWheel(correctionType, null, lineItemIdBeingEdited),
                false];

            const argsForRightOuterWheel = [getTireTempId(lineItemIdBeingEdited, axle, constants.TIRE_POSITIONS.RIGHT_OUTER),
                false, constants.TIRE_POSITIONS.RIGHT_OUTER, '', '', defaultTreadDepthUnit, canEditWheel(correctionType, null, lineItemIdBeingEdited),
                false];

            leftInnerWheelForAxle = !leftInnerWheelForAxle && shouldAddLeftInnerWheel ? getWheelObject(...argsForLeftInnerWheel) : leftInnerWheelForAxle;
            rightInnerForAxle = !rightInnerForAxle && shouldAddRightInnerWheel ? getWheelObject(...argsForRightInnerWheel) : rightInnerForAxle;
            leftOuterForAxle = !leftOuterForAxle && shouldAddLeftOuterWheel ? getWheelObject(...argsForLeftOuterWheel) : leftOuterForAxle;
            rightOuterForAxle = !rightOuterForAxle && shouldAddRightOuterWheel ? getWheelObject(...argsForRightOuterWheel) : rightOuterForAxle;

            //add left inner and right inner when axle is added (this logic is for adding axle)
            if (rearAxlesReModeled.findIndex(y => y.axlePosition == axle) == -1) {

                const canAddMoreWheels = !leftOuterForAxle && !rightOuterForAxle;
                rearAxlesReModeled = rearAxlesReModeled.concat(
                    getAxleModel(
                        axle,
                        leftInnerWheelForAxle,
                        rightInnerForAxle,
                        leftOuterForAxle,
                        rightOuterForAxle,
                        canAddMoreWheels)
                )
            }

            //add left outer and right outer when wheels are added and axle is already present (this logic is for adding wheels)         
            //NOTE: left inner and right inner will be automatically added by this time from the logic above this loop
            const idx = rearAxlesReModeled.findIndex(y => y.axlePosition == axle && !y.leftOuterWheel && !y.rightOuterWheel);
            let outerDummyWheelsExist = false; 
            if (idx > -1 && shouldAddLeftOuterWheel && shouldAddRightOuterWheel) {
                rearAxlesReModeled[idx].leftOuterWheel = leftOuterForAxle;
                rearAxlesReModeled[idx].rightOuterWheel = rightOuterForAxle;
                rearAxlesReModeled[idx].canAddWheel = false;
                outerDummyWheelsExist = true;
            }
        
        }

        rearAxlesReModeled = rearAxlesReModeled
            .sort((a, b) => {

                if(b.axlePosition > a.axlePosition)
                    return -1; 
                if(b.axlePosition < a.axlePosition)
                    return 1 ; 
                return 0 ; 
            });
        return rearAxlesReModeled;
    }
);

function getAxleModel(axlePosition, leftInnerWheel, rightInnerWheel, leftOuterWheel, rightOuterWheel, canAddWheelToAxle) {
    return {
        axlePosition: axlePosition,
        leftInnerWheel: leftInnerWheel,
        rightInnerWheel: rightInnerWheel,
        leftOuterWheel: leftOuterWheel,
        rightOuterWheel: rightOuterWheel,
        canAddWheel: canAddWheelToAxle
    }
}
const shouldShowContinueLinkSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'shouldShowContinueLink']) || false;
const showLaborRateUpdateWindowSelector= state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'showLaborRateUpdateWindow']) || false;
const showTireSpecificationSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'showTireSpecification']) || false;
const showTireManufacturingDetailsSelector = createSelector(
    correctionTypeSelector,
    lineItemIdBeingEditedSelector,
    tireSpecificationsSelector || List(),
    state => state.appState.getIn(['uiData', 'addEdit', 'lineItems']) || Map(),
    state => state.appState.getIn(['serverData', 'view', 'lineItems']) || Map(),    
    (correctionType, lineItemIdBeingEdited, tireSpecifications, existingPOLineItems, existingPOLineItemsFromServerData) => {

        let isAnyTireReplacedInTheLineItem = false;

        let productCode = '';
        let product = existingPOLineItems.find(x=>x.get('lineItemId') == lineItemIdBeingEdited) || Map();
        if(product.size == 0)        
            product = existingPOLineItemsFromServerData.find(x=>x.get('lineItemId') == lineItemIdBeingEdited) || Map();
        
        const lineItemType = product.get('lineItemType');

        const canShow = !lineItemType ? true: product.get('lineItemType') == constants.LINE_ITEM_TYPE.PART;
        if (tireSpecifications)
            isAnyTireReplacedInTheLineItem = tireSpecifications
                .findIndex(
                x => x.get('eventType') == constants.EVENT_TYPE.REPLACED && (x.get('lineItemId') == lineItemIdBeingEdited || !x.get('lineItemId'))
                ) > - 1;

        return (((correctionType == constants.CORRECTION_TYPE.REPLACE) || (isAnyTireReplacedInTheLineItem)) && (canShow))
    }
)
const axleCountForSelectedLineItemSelector = createSelector(
    rearAxlesSelector,
    (rearAxles) => {
        return !rearAxles ? 2 : rearAxles.length + 1
    }
)


const anyTireSelectedSelector = createSelector(
    wheelsForLineItemSelector,
    lineItemIdBeingEditedSelector,
    (wheelsForLineItem, lineItemIdBeingEdited) => {
        const anyTireSelected = wheelsForLineItem.length > 0 &&
            wheelsForLineItem.findIndex(x => x.eventType == constants.EVENT_TYPE.REPLACED && x.lineItemId == lineItemIdBeingEdited) > -1;
        return anyTireSelected;
    }
)

const showTiresInViewEditSelector = state => {

    const show = !state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'lineItemForTires']) ? false : true
    return show;
}

const selectedTiresSelector = createSelector(
    wheelsForLineItemSelector,
    lineItemIdBeingEditedSelector,
    (wheelsForLineItem, lineItemIdBeingEdited) => {
        const selectedTires = wheelsForLineItem.filter(x => x.eventType == constants.EVENT_TYPE.REPLACED && x.lineItemId == lineItemIdBeingEdited);
        return selectedTires;
    }
)

const currentLineItemQtySelector = createSelector(
    addNewLineItemsViewSelector,
    state => state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'lineItemQty']),
    (addNewLineItemsView, qtyFromPurchaseOrder) => {
        let qty = isNaN(addNewLineItemsView.get('qty')) ? null : Number(addNewLineItemsView.get('qty'));
        if (!qty) {
            qty = isNaN(qtyFromPurchaseOrder) ? null : Number(qtyFromPurchaseOrder);
        }

        return !qty ? 0 : qty;
    }
)

const tiresSelectedMatchesWithQtySelector = createSelector(
    currentLineItemQtySelector,
    selectedTiresSelector,
    (currentLineItemQty, selectedTires) => {
        const selectedTiresCount = selectedTires.length;
        return currentLineItemQty == selectedTiresCount;
    }
)

const productCodeBeingEditedSelector = createSelector(
    lineItemIdBeingEditedSelector,
    state => state.appState.getIn(['uiData', 'addEdit', 'lineItems']) || Map(),
    state => state.appState.getIn(['serverData', 'view', 'lineItems']) || Map(),    
    (lineItemIdBeingEdited, existingPOLineItems, existingPOLineItemsFromServerData)=>{
        let productCode = '';

        let product = existingPOLineItems.find(x=>x.get('lineItemId') == lineItemIdBeingEdited) || Map();

        if(product.size == 0)
        {
            product = existingPOLineItemsFromServerData.find(x=>x.get('lineItemId') == lineItemIdBeingEdited) || Map();
        }

        productCode = !product ? '': product.get('productCode');        

        return productCode;
    }
)

const isUsingTireProductcodeSelector = createSelector(
    currentATACodeSelector,
    tireProductCodesData,
    productCodeBeingEditedSelector,
    (currentATACode, tireProductCodes, productCodeBeingEdited) => {
        const prodCode = !currentATACode? productCodeBeingEdited:currentATACode;

        return (tireProductCodes != undefined && tireProductCodes.length > 0 && tireProductCodes.includes(prodCode));
    }
)

const poDetailSelector = createSelector(
    state => state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder']),
    (poDetailsBeingUpdated) => {
        return Map({
            lineItemKey: !poDetailsBeingUpdated ? '' : poDetailsBeingUpdated.get('lineItemForTires'),
            quantity: !poDetailsBeingUpdated ? null : poDetailsBeingUpdated.get('lineItemQty'),
            unitPrice: !poDetailsBeingUpdated ? null : poDetailsBeingUpdated.get('lineItemUnitPrice'),
            correctionType: !poDetailsBeingUpdated ? null : poDetailsBeingUpdated.get('lineItemCorrectionType'),
        })
    }

)

const currentLineItemTypeSelector = createSelector(
    lineItemIdBeingEditedSelector,
    state => state.appState.getIn(['uiData', 'addEdit', 'lineItems']) || Map(),
    state => state.appState.getIn(['serverData', 'view', 'lineItems']) || Map(),    
    (lineItemIdBeingEdited, existingPOLineItems, existingPOLineItemsFromServerData)=>{
        let lineItemType = '';

        let product = existingPOLineItems.find(x=>x.get('lineItemId') == lineItemIdBeingEdited) || Map();

        if(product.size == 0)
        {
            product = existingPOLineItemsFromServerData.find(x=>x.get('lineItemId') == lineItemIdBeingEdited) || Map();
        }

        lineItemType = !product ? '': product.get('lineItemType');        

        return lineItemType;
    }
)

//--------------------
const showBrakesSelector = state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'showBrakes']) || false;

const isUsingBrakesProductcodeSelector = createSelector(
    currentATACodeSelector,
    brakesProductCodesData,
    productCodeBeingEditedSelector,
    (currentATACode, brakesProductCodes, productCodeBeingEdited) => {
        const prodCode = !currentATACode? productCodeBeingEdited:currentATACode;
        return (brakesProductCodes != undefined && brakesProductCodes.length > 0 && brakesProductCodes.findIndex(x => x.productCode == prodCode) > -1);
    }
)

const showBrakesInViewEditSelector = createSelector(
    currentATACodeSelector,
    brakesProductCodesData,
    (currentATACode, brakesProductCodes) => {
        return false;
    }
)

const brakeImagesSelector = createSelector(
    currentATACodeSelector,
    brakesProductCodesData,
    productCodeBeingEditedSelector,
    (currentATACode, brakesProductCodes, productCodeBeingEdited) => {
        const prodCode = !currentATACode? productCodeBeingEdited:currentATACode;
        return {
            isRotor: brakesProductCodes.findIndex(x => x.productCode == prodCode && x.componentType == constants.BRAKE_COMPONENT_TYPE.ROTOR) > -1,
            isShoe: brakesProductCodes.findIndex(x => x.productCode == prodCode && x.componentType == constants.BRAKE_COMPONENT_TYPE.SHOE) > -1,
            isPad: brakesProductCodes.findIndex(x => x.productCode == prodCode && x.componentType == constants.BRAKE_COMPONENT_TYPE.PAD) > -1,
            isDrum: brakesProductCodes.findIndex(x => x.productCode == prodCode && x.componentType == constants.BRAKE_COMPONENT_TYPE.DRUM) > -1,
        }
    }
)

const brakeAxlesFromStateSelector = createSelector(
    state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'brakeSpec']) || List(),
    (axles) => {
        return !axles ? [] : axles.toJSON();
    }
)

const maxBrakeAxlesAllowedSelector = createSelector(
    state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'maxBrakeAxlesAllowed']),
    (maxBrakeAxlesAllowed) => {
        return maxBrakeAxlesAllowed
    }
)
const componentTypeSelector = createSelector(
    currentATACodeSelector,
    brakesProductCodesData,
    productCodeBeingEditedSelector,
    (currentATACode, brakesProductCodes, productCodeBeingEdited) => {
        let productCode = !currentATACode?productCodeBeingEdited:currentATACode;        
        const brakeProduct = brakesProductCodes.find(x => x.productCode == productCode) || {};        
        return brakeProduct.componentType;
    }
)

const brakeAxlesSelector = createSelector(
    currentATACodeSelector,
    brakesProductCodesData,
    brakeAxlesFromStateSelector,
    lineItemIdBeingEditedSelector,
    componentTypeSelector,
    (currentATACode, brakesProductCodes, brakeAxlesFromState, lineItemIdBeingEdited, componentType) => {
        if (!brakeAxlesFromState)
            return [];

        let groupedAxles = lodash.groupBy(brakeAxlesFromState, 'axleNumber');
        let brakes = [];

        for (var i in groupedAxles) {


            let leftBrake = brakeAxlesFromState.find(x => x.axleNumber == i && x.position == constants.BRAKE_POSITIONS.LEFT && x.component == componentType);
            let rightBrake = brakeAxlesFromState.find(x => x.axleNumber == i && x.position == constants.BRAKE_POSITIONS.RIGHT && x.component == componentType);
            let maxDrumDiameter = !leftBrake ? null : leftBrake.maxDrumDiameter;
            let minRotorThickness = !leftBrake ? null : leftBrake.minRotorThickness;

            const shouldHaveAxle = !(!leftBrake && !rightBrake); //shouuld have alteast one brake in the axle

            if(shouldHaveAxle){
                let brakeAxle = {
                    axleNumber: i,
                    leftBrake: !leftBrake ? {} : leftBrake,
                    rightBrake: !rightBrake ? {} : rightBrake,
                    maxDrumDiameter: maxDrumDiameter,
                    minRotorThickness: minRotorThickness
                }

                brakes = brakes.concat(brakeAxle);
            }
        }

        return brakes;

    }

)

const brakeAxleCountForSelectedLineItemSelector = createSelector(
    brakeAxlesSelector,
    (brakeAxles) => {
        return brakeAxles.length
    }
)

const isFrontAxleProductSelector = createSelector(
    currentATACodeSelector,
    brakesProductCodesData,
    (currentATACode, brakesProductCodesData) => {
        return brakesProductCodesData.findIndex(x => x.productCode == currentATACode && x.appliesToAxle == 1) > -1;
    }
)

const isRearAxleProductSelector = createSelector(
    currentATACodeSelector,
    brakesProductCodesData,
    (currentATACode, brakesProductCodesData) => {
        return brakesProductCodesData.findIndex(x => x.productCode == currentATACode && x.appliesToAxle == 2) > -1;
    }
)

const canAddBrakesAxleSelector = createSelector(
    brakeAxleCountForSelectedLineItemSelector,
    maxBrakeAxlesAllowedSelector,
    (brakeAxleCountForSelectedLineItem, maxBrakeAxlesAllowed)=>{
        return brakeAxleCountForSelectedLineItem < maxBrakeAxlesAllowed
    }
)

const canRemoveBrakesAxleSelector = createSelector(
     brakeAxleCountForSelectedLineItemSelector,
    ( brakeAxleCountForSelectedLineItem)=>{
        return brakeAxleCountForSelectedLineItem > 2
    }
)

const defaultBrakeMeasurementUnitSelector = createSelector(
    state => state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'defaultBrakeMeasurementUnit']),
    brakeAxlesFromStateSelector,
    (defaultBrakeUnit, brakeAxles)=>{
        let unit = defaultBrakeUnit;
        if(brakeAxles!=undefined && brakeAxles.length>0){
            unit = brakeAxles[0].unitOfMeasurement
        }
        return unit;
    }
);

const poInLaborRateWindowSelector=(state,props)=>{
    let value=state.appState.getIn(['uiData', 'addNewLineItemsView','poInLaborRateWindow']);
    if(value==null || value==undefined) return false;
     else return value;
}
const standardLaborRateSelector=(state,props)=>{
    let standardLaborRate=state.appState.getIn(['serverData','shared','laborRateDetails','standardRate']);
    return standardLaborRate;
}

const laborRateWindowShownForThisSessionSelector=(state,props)=>{
    return state.appState.getIn(['serverData','shared','laborRateWindowShownForThisSession']);
}

const isStandardRateUpdateDueSelector=(state,props)=>{
    return state.appState.getIn(['serverData','shared','laborRateDetails','isStandardRateUpdateDue']);
}

const clientPreferredLaborRateSelector=(state,props)=>{
    return state.appState.getIn(['serverData','shared','vendorVehicleParameters','hourlyLaborRate']);    
}



const hourlyLaborRateSelector=createSelector(
    standardLaborRateSelector, clientPreferredLaborRateSelector,
   ( standardLaborRate,clientPreferredLaborRate)=>{       
       if(clientPreferredLaborRate==null || clientPreferredLaborRate==0)
       return standardLaborRate
       else return clientPreferredLaborRate;
   }
);

const currentLineLaborRateSelector=(state,props)=>{
    return state.appState.getIn(['uiData', 'addNewLineItemsView','rate']);
}

const laborRateExceedsLimitSelector=createSelector(standardLaborRateSelector, clientPreferredLaborRateSelector,currentLineLaborRateSelector,
(standardLaborRate,clientPreferredLaborRate,currentRate)=>{    
    if(clientPreferredLaborRate!=null && clientPreferredLaborRate!=0) return false ;
    else if(currentRate==null || currentRate==0) return false;
    else if (standardLaborRate==null || standardLaborRate==0 || currentRate>standardLaborRate) return true;
    else return false;
});

const brakesInfoSelector = createSelector(
    isUsingBrakesProductcodeSelector,
    showBrakesInViewEditSelector,
    showBrakesSelector,
    brakeImagesSelector,
    brakeAxlesSelector,
    componentTypeSelector,
    brakeAxleCountForSelectedLineItemSelector,
    maxBrakeAxlesAllowedSelector,
    defaultBrakeMeasurementUnitSelector,
    brakeAxlesFromStateSelector,
    isFrontAxleProductSelector,
    isRearAxleProductSelector,
    canAddBrakesAxleSelector,
    canRemoveBrakesAxleSelector,
    (
        isUsingBrakesProductcode,
        showBrakesInViewEdit,
        showBrakes,
        brakeImages,
        brakeAxles,
        componentType,
        brakeAxleCountForSelectedLineItem,
        maxBrakeAxlesAllowed,
        defaultBrakeMeasurementUnit,
        brakeAxlesFromState,
        isFrontAxleProduct,
        isRearAxleProduct,
        canAddBrakesAxle,
        canRemoveBrakesAxle,
    ) => {
        return {
            isUsingBrakesProductcode: isUsingBrakesProductcode,
            showBrakesInViewEdit: showBrakesInViewEdit,
            showBrakes: showBrakes,
            brakeImages: brakeImages,
            brakeAxles: brakeAxles,
            componentType: componentType,
            brakeAxleCountForSelectedLineItem: brakeAxleCountForSelectedLineItem,
            maxBrakeAxlesAllowed: maxBrakeAxlesAllowed,
            defaultBrakeMeasurementUnit: defaultBrakeMeasurementUnit,
            brakeAxlesFromState: brakeAxlesFromState,
            isFrontAxleProduct: isFrontAxleProduct,
            isRearAxleProduct: isRearAxleProduct,            
            canAddBrakesAxle: canAddBrakesAxle,
            canRemoveBrakesAxle: canRemoveBrakesAxle,
        }
    }
)

const AddNewLineItemsView = createSelector(
    isCreatingLineItemsSelector,
    parameterListSelector,
    frequentlyUsedATACodesSelector,
    ataCodesForSearchSelector,
    selectedATACodeDescriptionSelector,
    correctionTypesSelector,
    correctionTypeSelector,
    correctionDetailsTotalSelector,
    complaintCodeSelector,
    newLineItemsSelector,
    showAddNewLineItemsWindowSelector,
    searchedATACodeSelector,
    autoCompleteATACodeDescSelector,
    existingPOLineItemsSelector,
    vehicleDetailsSelector,
    hideLaborInputFieldsSelector,
    isfetchingDataSelector,
    hideAtaSearchResultContainerSelector,
    ataCodesSearchResultSelector,
    ataParametersDescriptionSelector,
    getAtaParametersSortedSelector,
    itemsAddedToPoSelector,
    isFetchiDataForSearchATASelector,
    enableAddLineItemToPoSelector,
    currentATACodeSelector,
    tireManufacturingDetailsSelector,
    tireSpecificationsSelector,
    axlesSelector,
    maxAxlesAllowedSelector,
    spareWheelSelector,
    frontAxleSelector,
    rearAxlesSelector,
    defaultTreadDepthUnitSelector,
    shouldShowContinueLinkSelector,
    showTireSpecificationSelector,
    showTireManufacturingDetailsSelector,
    axleCountForSelectedLineItemSelector,
    anyTireSelectedSelector,
    showTiresInViewEditSelector,
    selectedTiresSelector,
    tiresSelectedMatchesWithQtySelector,
    isUsingTireProductcodeSelector,
    poDetailSelector,
    brakesInfoSelector,    
    currentLineItemQtySelector,
    currentLineItemTypeSelector,
    restrictedRepairTypesSelector,
    standardLaborRateSelector,
     isStandardRateUpdateDueSelector,
     hourlyLaborRateSelector, 
     clientPreferredLaborRateSelector,
     laborRateExceedsLimitSelector,
     currentLineLaborRateSelector,
     laborRateWindowShownForThisSessionSelector,
     poInLaborRateWindowSelector,
     showLaborRateUpdateWindowSelector,
     selectedATAAllowsMultipleLinesSelector,
    (isCreatingLineItems,
        parameterList,
        frequentlyUsedATACodes,
        ataCodesForSearch,
        selectedATACodeDescription,
        correctionTypes,
        correctionType,
        correctionDetailsTotal,
        complaintCode,
        newLineItems,
        showAddNewLineItemsWindow,
        searchedATACode,
        autoCompleteATACodeDesc,
        existingPOLineItems,
        vehicleDetails,
        hideLaborInputFields,
        isfetchingData,
        hideAtaSearchResultContainer,
        ataCodesSearchResult,
        ataParametersDescription,
        ataParametersSorted,
        itemsAddedToPo,
        isFetchiDataForSearchATA,
        enableAddLineItemToPo,
        currentATACode,
        tireDetails,
        tireSpecifications,
        axles,
        maxAxlesAllowed,
        spareWheel,
        frontAxle,
        rearAxles,
        defaultTreadDepthUnit,
        shouldShowContinueLink,
        showTireSpecification,
        showTireManufacturingDetails,
        axleCountForSelectedLineItem,
        anyTireSelected,
        showTiresInViewEdit,
        selectedTires,
        tiresSelectedMatchesWithQty,
        isUsingTireProductcode,
        poDetail,
        brakesInfo,        
        currentLineItemQty,
        currentLineItemType,
        restrictedRepairTypes,
        standardLaborRate,
        isStandardRateUpdateDue,
        hourlyLaborRate, 
        clientPreferredLaborRate,
        laborRateExceedsLimit,
        currentLineLaborRate,
        laborRateWindowShownForThisSession,
        poInLaborRateWindow,
        showLaborRateUpdateWindow,
        selectedATAAllowsMultipleLines
    ) => {
        return {
            isCreatingLineItems,
            parameterList,
            frequentlyUsedATACodes,
            ataCodesForSearch,
            selectedATACodeDescription,
            correctionTypes,
            correctionType,
            correctionDetailsTotal,
            complaintCode,
            newLineItems,
            showAddNewLineItemsWindow,
            searchedATACode,
            autoCompleteATACodeDesc,
            existingPOLineItems,
            vehicleDetails,
            hideLaborInputFields,
            isfetchingData,
            hideAtaSearchResultContainer,
            ataCodesSearchResult,
            ataParametersDescription,
            ataParametersSorted,
            itemsAddedToPo,
            isFetchiDataForSearchATA,
            enableAddLineItemToPo,
            currentATACode,
            tireDetails,
            tireSpecifications,
            axles,
            maxAxlesAllowed,
            spareWheel,
            frontAxle,
            rearAxles,
            defaultTreadDepthUnit,
            shouldShowContinueLink,
            showTireSpecification,
            showTireManufacturingDetails,
            axleCountForSelectedLineItem,
            anyTireSelected,
            showTiresInViewEdit,
            selectedTires,
            tiresSelectedMatchesWithQty,
            isUsingTireProductcode,
            poDetail,
            brakesInfo,
            currentLineItemQty,            
            currentLineItemType,
            restrictedRepairTypes,
            standardLaborRate,
            isStandardRateUpdateDue,
            hourlyLaborRate, 
            clientPreferredLaborRate,
            laborRateExceedsLimit,
            currentLineLaborRate,
            laborRateWindowShownForThisSession,
            poInLaborRateWindow,
            showLaborRateUpdateWindow,
            selectedATAAllowsMultipleLines
        };
    }
);


export default AddNewLineItemsView;