import { call, put, select,all} from 'redux-saga/effects'
import { Map } from 'immutable';
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import { diagnosisDetailsSelector, complaintsSelector } from 'selectors/ComplaintView';
import { emptyMapIfUndefined, emptyArrayIfUndefined,getSelectedLanguageCode } from 'utils/HelperFunctions';
import { Schema, arrayOf, normalize } from 'normalizr';
import moment from 'moment';
import * as helperFunctions from 'utils/HelperFunctions';

export function* fetchData(context, action) {
    try {
        const state = yield select();
        const token = helperFunctions.getToken(state);
            
        // Inform application that PO data is being fetched    
        yield put({ type: constants.FETCH_ADJUSTMENT_DATA_REQUESTED });        

        const [purchaseOrder, lineItems, vehicleDetails, vendor, complaints, notes] = yield all ([
            call(Api.fetchPurchaseOrder, action.poNumber, token),
            call(Api.fetchLineItems, action.poNumber, token,getSelectedLanguageCode(state)),
            call(Api.fetchVehicle, action.poNumber, token),
            call(Api.fetchVendor, action.poNumber, token),
            call(Api.fetchComplaints, action.poNumber, token, state),
            call(Api.fetchComplaintNotes, action.poNumber, token)
        ]);        

        // Prepare data
        var purchaseOrderData = {
            purchaseOrder,
            vehicleDetails,
            vendor,
            complaints,
            notes,
            lineItems
        };
                
        yield put({ type: constants.FETCH_ADJUSTMENT_DATA_RESOLVED, purchaseOrderData });

    }
    catch (e) {
        yield put({ type: constants.FETCH_ADJUSTMENT_DATA_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });        
    }
}

export function* updateLineItem(context, action) {
    const { lineItemKey, lineItem } = action;
    try {        
        const state = yield select();
        const token = helperFunctions.getToken(state);        
        const poNumber = state.appState.getIn(['serverData', 'adjustment', 'purchaseOrder', 'id']);                
        const originalUnitPrice = state.appState.getIn(['serverData', 'adjustment', 'lineItems', lineItemKey, 'unitPrice']);

        yield put({ type: constants.ADJUSTMENT_UPDATE_LINE_ITEM_REQUESTED, lineItemKey });
         
        // update line item on PO
        if (originalUnitPrice != lineItem.get('unitPrice')) {
          yield call(Api.editLineItem, poNumber, lineItemKey, lineItem.get('quantity'), lineItem.get('unitPrice'), helperFunctions.getToken(state),getSelectedLanguageCode(state));
        }        

        yield put({ type: constants.ADJUSTMENT_UPDATE_LINE_ITEM_RESOLVED, lineItemKey, lineItem });
        

    }
    catch (e) {
        yield put({ type: constants.ADJUSTMENT_UPDATE_LINE_ITEM_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e), lineItemKey });                
    }
}

export function* deleteLineItem(action) {
  try {
    const lineItemKey = action.lineItemKey;
    yield put({ type: constants.ADJUSTMENT_DELETE_LINE_ITEM_REQUESTED, lineItemKey });

    // Read data from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['serverData', 'adjustment', 'purchaseOrder', 'id']);    

    // delete line item from PO
    yield call(Api.deleteLineItem, poNumber, action.lineItemKey, helperFunctions.getToken(state));    

    yield put({ type: constants.ADJUSTMENT_DELETE_LINE_ITEM_RESOLVED, lineItemKey });

  }
  catch (e) {
    yield put({ type: constants.ADJUSTMENT_DELETE_LINE_ITEM_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e), lineItemKey });    
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.DELETE_LINE_ITEM_REQUESTED, errorObject: e });
  }
}

export function* saveComplaintNotes(action) {
  try {    
    yield put({ type: constants.ADJUSTMENT_SAVE_COMPLAINT_NOTES_REQUESTED, complaintKey: action.complaintKey });

    // Read data from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['serverData', 'adjustment', 'purchaseOrder', 'id']);
    let complaintCode = state.appState.getIn(['serverData', 'adjustment', 'complaints', action.complaintKey, 'code']);    
    let noteId = state.appState.getIn(['serverData', 'adjustment', 'notes', action.complaintKey, 'id']);

    if (noteId != undefined) {
      yield call(Api.editComplaintNotes, poNumber, complaintCode, undefined, action.notes, noteId, helperFunctions.getToken(state));
    }
    else {
      // add complaint note
      if (action.notes != undefined && action.notes != '') {
        const notesData = {
          noteText: action.notes
        };
        noteId = yield call(Api.createComplaintNotes, poNumber, complaintCode, notesData, helperFunctions.getToken(state));
      }
    }

    // update line item in app state
    yield put({ type: constants.UPDATE_COMPLAINT_NOTES, complaintKey: action.complaintKey, notes: action.notes, noteId });

    yield put({ type: constants.ADJUSTMENT_SAVE_COMPLAINT_NOTES_RESOLVED, complaintKey: action.complaintKey });
  }
  catch (e) {
    yield put({
      type: constants.ADJUSTMENT_SAVE_COMPLAINT_NOTES_REJECTED,
      complaintNotesData: {
        complaintKey: action.complaintKey,
        errorMessage: helperFunctions.getCustomErrorObject(e)
      }
    });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
  }
}