import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Popover, Overlay, Input } from 'react-bootstrap';
import { defineMessages, FormattedMessage ,injectIntl} from 'react-intl';
import { falseIfUndefined } from 'utils/HelperFunctions';
import Spinner from 'components/Spinner';
import { connect } from 'react-redux';
import * as actions from '../actions/App';
import classNames from 'classnames';
import { SEARCH_FILTER_TYPE, SEARCH_DURATION_TYPE } from 'constants/App.js';
import './SearchPO.css';
import Help from 'components/Help';
import {withRouter} from 'react-router-dom'
import { useAppState } from 'shared/AppState/app-state';
import { useGetRepairEstimateSearchQuery } from 'shared/types/__generated__/graphql-type.ts';

const SearchPO  = (props) => {
    // #region Hooks
    const [appState] = useAppState();
    const [skipQuery, setSkipQuery] = useState(true);
    const { loading, error } = useGetRepairEstimateSearchQuery({
        skip: skipQuery,
        variables: {
            vendorId: props.vendorId,
            duration: null,
            claimId: Number.parseInt(props.searchFilters.toJSON().searchText)
        },
        onCompleted: (result) => {
            setSkipQuery(true);
            if (result.repairEstimateSearch.length == 0) {
                props.dispatch(actions.setSearchPOErrorMessage(props.intl.formatMessage(messages.search_claim_error, { claimId: searchFilters.searchText })));
            } else {
                props.history.push(`/AccidentManagement/AccidentAssignments/${result.repairEstimateSearch[0].id}`);
            }
        }
    });
    // #endregion

    // #region Refs    
    const divSearch = useRef();
    const refSearchText = useRef();
    // #endregion

    // #region useEffect
    // #endregion

    const onGetSearchOptionText = (optionValue) => {
        const messages = defineMessages({
            vin8_label: {
                id: 'vin8_label',
                description: 'Search by VIN8',
                defaultMessage: 'VIN8'
            },
            purchase_order_label: {
                id: 'purchase_order_label',
                description: 'Search by purchase order',
                defaultMessage: 'Purchase Order'
            },
            invoice_label: {
                id: 'invoice_label',
                description: 'Search by invoice number',
                defaultMessage: 'Invoice'
            },
            payment_label: {
                id: 'payment_label',
                description: 'Search by payment number',
                defaultMessage: 'Payment'
            },
            search_duration: {
                id: 'search_duration',
                description: 'Select searc duration',
                defaultMessage: 'Duration'
            },
            last_60_days: {
                id: 'last_60_days',
                description: 'Search for POs for last 60 days',
                defaultMessage: 'Last 60 days'
            },
            last_120_days: {
                id: 'last_120_days',
                description: 'Search for POs for last 120 days',
                defaultMessage: 'Last 120 days'
            },
            dealerdraft_submit_label: {
                id: 'dealerdraft_submit_label',
                description: 'dealerdraft_submit_label by VIN8',
                defaultMessage: 'Courtesy Request'
            },
            client_po_label: {
                id: 'client_po_label',
                description: 'Search by client po number',
                defaultMessage: 'Client PO'
            },
            claim_no_label: {
                id: 'claim_no_label',
                description: 'Accident Claim',
                defaultMessage: 'Accident Claim'
            },
        });

        switch (optionValue) {
            case SEARCH_FILTER_TYPE.VIN8:
                return <FormattedMessage {...messages.vin8_label} />;

            case SEARCH_FILTER_TYPE.PURCHASEORDER:
                return <FormattedMessage {...messages.purchase_order_label} />;

            case SEARCH_FILTER_TYPE.PAYMENT:
                return <FormattedMessage {...messages.payment_label} />;

            case SEARCH_FILTER_TYPE.INVOICE:
                return <FormattedMessage {...messages.invoice_label} />;

            case SEARCH_FILTER_TYPE.DEALER_SUBMITTED:
                return <FormattedMessage {...messages.dealerdraft_submit_label} />;

            case SEARCH_DURATION_TYPE.DURATION:
                return <FormattedMessage {...messages.search_duration} />;

            case SEARCH_DURATION_TYPE.LAST_60_DAYS:
                return <FormattedMessage {...messages.last_60_days} />;

            case SEARCH_DURATION_TYPE.LAST_120_DAYS:
                return <FormattedMessage {...messages.last_120_days} />;

            case SEARCH_FILTER_TYPE.CLIENT_REF_NO:
                return <FormattedMessage {...messages.client_po_label} />;
            
            case SEARCH_FILTER_TYPE.ACCIDENT_CLAIM_NO:
                return <FormattedMessage {...messages.claim_no_label} />
        }
    }

    const onSearchTypeChangedHandler = (searchFor, e) => {
        props.dispatch(actions.saveAdvancedSearchForOption(searchFor));

        //Reset values
        onDurationChangedHandler(SEARCH_DURATION_TYPE.DURATION, e);
    }

    const onTextChangedHandler = (e) => {
        props.dispatch(actions.saveAdvancedSearchText(e.target.value));
        props.dispatch(actions.saveAdvancedDurationOption(SEARCH_DURATION_TYPE.DURATION));
    }

    const onDurationChangedHandler = (days, e) => {
        props.dispatch(actions.saveAdvancedSearchText(''));
        props.dispatch(actions.saveAdvancedDurationOption(days));
    }

    const onPOSearchClickHandler = (e) => {
        e.preventDefault();
        let searchFilters = props.searchFilters.toJSON();
        if (searchFilters.days != SEARCH_DURATION_TYPE.DURATION || searchFilters.searchText.length > 0) {
            //Search by user selected filters
            if(searchFilters.searchFor == SEARCH_FILTER_TYPE.ACCIDENT_CLAIM_NO){
                setSkipQuery(false);
            }
            else{
                props.dispatch(actions.searchForPOsClicked(searchFilters));
            }
        }
    }

    const OnHide = () => {
        props.dispatch(actions.hideSearchPOErrorMessage());
    }

    const getSearchInputPlaceHolderText = (searchForFilter) => {
        const {formatMessage} = props.intl;
        const messages = defineMessages({
            search_for_po_orders_by_vin: {
                id: 'search_for_po_orders_by_vin',
                description: 'search_for_po_orders_by_vin',
                defaultMessage: 'Search for Purchase order(s) by VIN8...'
            },search_for_po_orders_by_po: {
                id: 'search_for_po_orders_by_po',
                description: 'search_for_po_orders_by_po',
                defaultMessage: 'Search for Purchase order(s) by PO #...'
            },search_for_po_orders_by_payment: {
                id: 'search_for_po_orders_by_payment',
                description: 'search_for_po_orders_by_payment',
                defaultMessage: 'Search for Purchase order(s) by Payment #...'
            },search_for_po_orders_by_invoice: {
                id: 'search_for_po_orders_by_invoice',
                description: 'search_for_po_orders_by_invoice',
                defaultMessage: 'Search for Purchase order(s) by Invoice #...'
            },search_for_po_orders: {
                id: 'search_for_po_orders',
                description: 'search_for_po_orders',
                defaultMessage: 'Search for PO...'
            },
            search_for_dealerdraft_submit: {
                id: 'search_for_dealerdraft_submit',
                description: 'search_for_dealerdraft_submit',
                defaultMessage: 'Search for Courtesy Request...'
            },
            search_for_client_po: {
                id: 'search_for_client_po',
                description: 'search_for_client_po',
                defaultMessage: 'Search for Purchase order(s) by Client PO#...'
            },
            search_for_claim_no: {
                id: 'search_for_claim_no',
                description: 'search_for_client_po',
                defaultMessage: 'Search for Accident Estimate by Claim #...'
            },
        });
        if (searchForFilter == SEARCH_FILTER_TYPE.VIN8)
            return  formatMessage(messages.search_for_po_orders_by_vin);
        else if (searchForFilter == SEARCH_FILTER_TYPE.PURCHASEORDER)
            return formatMessage(messages.search_for_po_orders_by_po);
        else if (searchForFilter == SEARCH_FILTER_TYPE.PAYMENT)
            return formatMessage(messages.search_for_po_orders_by_payment);
        else if (searchForFilter == SEARCH_FILTER_TYPE.INVOICE)
            return formatMessage(messages.search_for_po_orders_by_invoice);
        else if (searchForFilter === SEARCH_FILTER_TYPE.DEALER_SUBMITTED)
            return formatMessage(messages.search_for_dealerdraft_submit);
        else if (searchForFilter === SEARCH_FILTER_TYPE.CLIENT_REF_NO)
            return formatMessage(messages.search_for_client_po);
        else if (searchForFilter == SEARCH_FILTER_TYPE.ACCIDENT_CLAIM_NO)
            return formatMessage(messages.search_for_claim_no);
        else
            return formatMessage(messages.search_for_po_orders);
    }

    const messages = defineMessages({
        search_po_caption: {
            id: 'search_po_caption',
            description: 'Search for my purchase orders by either Payment, purchase order, invoice and VIN8',
            defaultMessage: 'Search by'
        },
        or_by_caption: {
            id: 'or_by_caption',
            description: 'or_by_caption',
            defaultMessage: 'Or by'
        },
        search_claim_error: {
            id: 'search_claim_error',
            description: 'search_claim_error',
            defaultMessage: 'Invalid Claim Number #{claimId}. Please reconfirm and try again.'
        }
    });

    let searchFilters = props.searchFilters.toJSON();
    return <div className="row basic_search" id="adv-search">
        <div className="col-sm-12">
            <form onSubmit={onPOSearchClickHandler}>
                <div className="input-group" ref={divSearch}>
                    <div className="input-group-btn search-panel basic_search__clear">
                        {props.selectedCountry !== 'MEX' ? <Help videoId="search_for_my_pos" whiteSpaceAtBottomHeight=".75em" /> : false}
                    </div>
                    <div id="divAdvanceSearchOption" className="input-group-btn search-panel dropup">
                        <button id="btnAdvanceSearchOptions" name="btnSearchOptions" type="button" className="btn btn-default" data-bs-toggle="dropdown">
                            <span id="search_for" className="basic_search__text">{onGetSearchOptionText(searchFilters.searchFor)}</span>
                            <span className="caret"></span>
                        </button>
                        <ul id="ulSearchOptions" name="ulSearchOptions" className="dropdown-menu" role="menu">
                            <li><a onClick={onSearchTypeChangedHandler.bind(this, SEARCH_FILTER_TYPE.VIN8)}>
                                {onGetSearchOptionText(SEARCH_FILTER_TYPE.VIN8)}
                            </a></li>

                            <li><a onClick={onSearchTypeChangedHandler.bind(this, SEARCH_FILTER_TYPE.PURCHASEORDER)}>
                                {onGetSearchOptionText(SEARCH_FILTER_TYPE.PURCHASEORDER)}
                            </a></li>

                            <li><a onClick={onSearchTypeChangedHandler.bind(this, SEARCH_FILTER_TYPE.INVOICE)}>
                                {onGetSearchOptionText(SEARCH_FILTER_TYPE.INVOICE)}
                            </a></li>

                            <li><a onClick={onSearchTypeChangedHandler.bind(this, SEARCH_FILTER_TYPE.PAYMENT)}>
                                {onGetSearchOptionText(SEARCH_FILTER_TYPE.PAYMENT)}
                            </a></li>
                            {props.isDealerDraft ?
                                <li><a onClick={onSearchTypeChangedHandler.bind(this, SEARCH_FILTER_TYPE.DEALER_SUBMITTED)}>
                                    {onGetSearchOptionText(SEARCH_FILTER_TYPE.DEALER_SUBMITTED)}
                                </a></li>
                                : undefined
                            }
                            <li><a onClick={onSearchTypeChangedHandler.bind(this, SEARCH_FILTER_TYPE.CLIENT_REF_NO)}>
                                {onGetSearchOptionText(SEARCH_FILTER_TYPE.CLIENT_REF_NO)}
                            </a></li>
                            {appState.isAccidentAssignmentFeatureEnabled ?
                                <li><a onClick={onSearchTypeChangedHandler.bind(this, SEARCH_FILTER_TYPE.ACCIDENT_CLAIM_NO)}>
                                    {onGetSearchOptionText(SEARCH_FILTER_TYPE.ACCIDENT_CLAIM_NO)}
                                </a></li>
                                : null
                            }
                        </ul>
                    </div>
                    <input id="txtSearchText" name="txtSearchText" ref={refSearchText} type="text"
                        className="form-control" placeholder={getSearchInputPlaceHolderText(searchFilters.searchFor)}
                        value={searchFilters.searchFor == SEARCH_FILTER_TYPE.CLIENT_REF_NO ? searchFilters.searchText.toUpperCase() : searchFilters.searchText}
                        disabled={searchFilters.days == SEARCH_DURATION_TYPE.LAST_60_DAYS ||
                            searchFilters.days == SEARCH_DURATION_TYPE.LAST_120_DAYS ? true : false}
                        onChange={onTextChangedHandler.bind(this)} />

                    {
                        searchFilters.searchFor == SEARCH_FILTER_TYPE.VIN8 || searchFilters.searchFor == SEARCH_FILTER_TYPE.DEALER_SUBMITTED
                            || searchFilters.searchFor == SEARCH_FILTER_TYPE.CLIENT_REF_NO || searchFilters.searchFor == SEARCH_FILTER_TYPE.ACCIDENT_CLAIM_NO
                            ? false
                            : <div className="input-group-btn search-panel">
                                <a href="#" className="btn btn-secondary disabled" role="button" aria-disabled="true">
                                    <span className="basic_search__text"><FormattedMessage {...messages.or_by_caption} /></span></a>
                            </div>
                    }

                    {
                        searchFilters.searchFor == SEARCH_FILTER_TYPE.VIN8 || searchFilters.searchFor == SEARCH_FILTER_TYPE.DEALER_SUBMITTED
                            || searchFilters.searchFor == SEARCH_FILTER_TYPE.CLIENT_REF_NO || searchFilters.searchFor == SEARCH_FILTER_TYPE.ACCIDENT_CLAIM_NO
                            ? false
                            : <div className="input-group-btn search-panel dropup">
                                <button id="btnDuration" name="btnDuration" type="button" className="btn btn-default dropdown-toggle" data-bs-toggle="dropdown">
                                    <span id="search_duration" className="basic_search__text">{onGetSearchOptionText(searchFilters.days)}</span>
                                    <span className="caret"></span>
                                </button>
                                <ul id="btnDuration" name="btnDuration" className="dropdown-menu" role="menu">
                                    <li><a onClick={onDurationChangedHandler.bind(this, SEARCH_DURATION_TYPE.DURATION)}>
                                        {onGetSearchOptionText(SEARCH_DURATION_TYPE.DURATION)}
                                    </a></li>

                                    <li><a onClick={onDurationChangedHandler.bind(this, SEARCH_DURATION_TYPE.LAST_60_DAYS)}>
                                        {onGetSearchOptionText(SEARCH_DURATION_TYPE.LAST_60_DAYS)}
                                    </a></li>

                                    <li><a onClick={onDurationChangedHandler.bind(this, SEARCH_DURATION_TYPE.LAST_120_DAYS)}>
                                        {onGetSearchOptionText(SEARCH_DURATION_TYPE.LAST_120_DAYS)}
                                    </a></li>
                                </ul>
                            </div>
                    }

                    <div className="input-group-btn search-panel basic_search__clear">
                        &nbsp;
                    </div>
                    <div className="input-group-btn search-panel" css={`display: inline-flex;`}>
                        <span className="input-group-btn">
                            <img className="basic_search__image" src="/assets/VMS_28button_find_blue.png" onClick={onPOSearchClickHandler} />
                        </span>

                        {props.isFetchingData || loading ?
                            <div className="input-group-btn po-search__spinner-container">
                                <Spinner spinnerType="blue" />
                            </div>
                            : undefined
                        }

                    </div>


                    <Overlay
                        show={props.hasErrorMessage || error}
                        onHide={OnHide.bind(this)}
                        placement="bottom"
                        container={divSearch.current}
                        target={refSearchText.current}
                        rootClose
                    >
                        <Popover id="popOverError" className="basic_search__errorMessage">
                            {error ? error.message : props.errorMessage}
                        </Popover>

                    </Overlay>
                </div>
            </form>
        </div>
    </div >;
}


function select(state) {
    return {
        isFetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'searchPO', 'isFetchingPOData'])),
        hasErrorMessage: state.appState.getIn(['uiData', 'searchPO', 'searchPOErrorMessage']) != undefined,
        errorMessage: state.appState.getIn(['uiData', 'searchPO', 'searchPOErrorMessage']),
        searchFilters: state.appState.getIn(['uiData', 'searchPO', 'searchFilters']),
        isDealerDraft:state.appState.getIn(['serverData','shared','isDealerDraft']),
        selectedCountry:state.appState.getIn(['uiData','shared','selectedCountry']),
        vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
    };
}

export default withRouter(connect(select)(injectIntl(SearchPO)));