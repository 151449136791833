import {Map} from 'immutable';
import  * as actions from '../actions/ClosePO';
import * as constants from 'constants/App';

/* Handles invoice details values on change event*/
export function onChangeHandlerClosePO(poInvoiceDetails) {
    this.props.dispatch(actions.saveInvoiceDetails(poInvoiceDetails));
}

/* Edit PO*/
export function onEditPOHandler() {
    //redirect to the shop view
    const poEditPath = `/PO/edit/${this.props.routeParams.match.params.trackNumber}`;
    this.props.dispatch({type: constants.NAVIGATE_TO, path: poEditPath});
}