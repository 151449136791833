import fetch from 'isomorphic-fetch';
import * as sharedActions from 'actions/App';
import { normalize } from 'normalizr';
import {fromJS}  from 'immutable';
import * as configuration from 'configuration';
import * as constants from 'constants/App';
import { jwtDecode } from 'jwt-decode';

export function callLogService(stackTrace, state, actionName, result) {

    try {
        if (configuration.enableVMSLogging) {
            //todo: fetch breaks with large stack trace, limiting to 10,000 for now
            var stackTraceTruncated = stackTrace.substring(0, 10000);

            // Do not log sensitive vendor information
            var newState = state.appState.setIn(['uiData', 'addEdit', 'vendor'], null)
                .setIn(['serverData', 'view', 'vendor'], null)
                .setIn(['serverData', 'close', 'vendor'], null)
                .setIn(['serverData', 'shared', 'vendorDetails'], null)
                .setIn(['uiData', 'vendorAccount', 'accountInformation'], null);

            fetch(`${configuration.logServiceURL}v1/Logs?type=vms`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Action: actionName,
                    VendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
                    Token: state.appState.getIn(['serverData', 'shared', 'token']),
                    UserAgent: navigator.userAgent,
                    AppState: JSON.stringify(newState),
                    Result: result,
                    StackTrace: stackTraceTruncated,
                    CreatedBy: ""
                })
            });
        }
    } catch (e) {
        //absorb error - this try/catch avoids an infinite loop since onError will call this function again
    }
}

export default function callAPI(request, schema, componentName, onSuccessAction, state, dispatch) {

    // Start spinner
    dispatch(sharedActions.fetchDataFromAPIStart(componentName));

    // Set Auth header
    request.headers.append('Authorization', state.appState.getIn(['serverData', 'shared', 'token']));

    // Set Accept header
    request.headers.append('Accept', 'application/json');


    // Make API call
    fetch(request)
        .then(response => {

            if (response.ok) {

                response.json().then(json => {

                    if (json.errorDescription == "") {

                        // Normalize response data
                        const normalizedJson = normalize(json.results, [schema]);

                        // Convert json data to Immutable object
                        const immutableObject = fromJS(normalizedJson.entities[schema.key]);

                        // Dispatch action to add data to the state
                        dispatch(onSuccessAction(immutableObject));

                        // Stop spinner
                        dispatch(sharedActions.fetchDataFromAPIComplete(componentName));
                    }
                    else {
                        // Stop spinner
                        dispatch(sharedActions.fetchDataFromAPIComplete(componentName));

                        // Dispatch error
                        dispatch(sharedActions.setErrorMessage(json.ErrorDescription));
                    }
                });
            }
            else {
                // Stop spinner
                dispatch(sharedActions.fetchDataFromAPIComplete(componentName));

                // Dispatch error
                dispatch(sharedActions.setErrorMessage('Response was not OK'));
            }
        }).catch(error => {

        // Stop spinner
        dispatch(sharedActions.fetchDataFromAPIComplete(componentName));

        // Dispatch error
        dispatch(sharedActions.setErrorMessage(error.message));
    });
}

export function callRefreshTokenService(dispatch, appState) {
    fetch(`${configuration.serviceURL}v1/Token?token=${appState.getIn(['serverData', 'shared', 'token'])}`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'x-app-key':`${configuration.AppKey}`
        }
        }).then(response=> {
            
        if (response.status == '401') {
            response.json().then(json=> {
                dispatch(sharedActions.setErrorMessage(json.errorDescription));
            });
        }
        else if (response.ok) {
            response.json().then(json=> {
                if (json.errorDescription != '' && json.errorDescription != null) {
                    //dispatch(sharedActions.setErrorMessage(json.errorDescription));
                }
                else {                    
                    // Save token in app state
                    let token = json;
                    const jwtToken = jwtDecode(token);
                    var corporateId, id, audien, isPersonaUser, vendorId, isARIUser;
                    audien = jwtToken.aud;
                    isPersonaUser = jwtToken.aud === 'ARIEXTERNALSIGNON';
                    isARIUser = isPersonaUser ? false: jwtToken.sub === 'ARIUSER';
                    if (isARIUser) {
                        corporateId = jwtToken.obo.corporateId;
                        id = jwtToken.obo.id;
                    }
                    else if (isPersonaUser) //Persona User
                    {
                        id = jwtToken.vendor.id;
                        corporateId = null;
                    }
                    else {
                        corporateId = jwtToken.corporateId;
                        id = jwtToken.id;
                    }

                    vendorId = id != null && id != "" ? id : corporateId;
                    if (jwtToken.termsAccepted === false && id !== null) {

                        dispatch({ type: constants.SIGN_OUT });

                        //Check if token is meant for APC user or SSO user
                        if (!!isPersonaUser || audien == "VMS" || isARIUser)
                            dispatch({ type: constants.NAVIGATE_TO, path: '/login?signed_out=1' });
                        else
                            dispatch({ type: constants.NAVIGATE_TO, path: '/sso?signed_out=1' });
                    }
                    else {
                        dispatch(sharedActions.setToken(token));
                    }   
                }
            });
        }
    }).catch(error=> {
        
        // TODO - do not show any error message if refresh fails

    });
}