// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './Title.css';

const Title = ({vin8}) => {
    const messages = defineMessages({
            documents_title: {
                id: 'documents_title',
                description: 'Title for documents',
                defaultMessage: 'Documents'
            },
            documents_title_for_vehicle: {
                id: 'documents_title_for_vehicle',
                description: 'Title for documents for specific vehicle',
                defaultMessage: 'Documents for VIN {vin8}'
            }
        });

    if (vin8)
    {
        return <div className='documents__title'>                           
            <FormattedMessage {...messages.documents_title_for_vehicle} values={{vin8}} />
        </div>
    }
    else
    {
        return <div className='documents__title'>
            <FormattedMessage {...messages.documents_title}/>                
        </div>
    }
}

export default Title;