import * as constants from 'constants/App';

export function showPopOverInvalidPassword(popOverDisplayShowPassword) {
    return { type: constants.POP_OVER_DISPLAY_INVALID_PASSWORD, popOverDisplayShowPassword }
}
export function showPopOverConfirmPassword(popOverDisplayShowConfirmPassword) {
    return { type: constants.POP_OVER_DISPLAY_CONFIRM_PASSWORD, popOverDisplayShowConfirmPassword }
}

export function showResetPasswordEndPointError(resetPasswordEndPointError) {
    return { type: constants.RESET_PASSWORD_END_POINT_ERROR, resetPasswordEndPointError }
}

export function showResetPasswordInvalidTokenError(resetPasswordInvalidTokenError) {
    return { type: constants.RESET_PASSWORD_INVALID_TOKEN_ERROR, resetPasswordInvalidTokenError }
}

export function clearResetPasswordError(resetPasswordError) {
    return { type: constants.CLEAR_RESET_PASSWORD_ERROR, resetPasswordError }
}

export function saveResetPasswordPersonal(resetPasswordPersonal) {
    return { type: constants.SAVE_RESET_PASSWORD_INFORMATION_PERSONAL, resetPasswordPersonal }
}

export function saveConfirmPasswordErrorMessage(confirmPasswordErrorMessage) {
    return {type: constants.SAVE_CONFIRM_PASSWORD_ERROR_MESSGAE, confirmPasswordErrorMessage}
}
