import { EstimateStatus, PaymentStatus, useGetRepairEstimatesQuery } from "shared/types/__generated__/graphql-type";
import React, { useState } from 'react';
import "./AccidentAssignmentsScreen.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import Spinner from "shared/components/Spinner";
import configuration from "shared/configuration";
const AccidentAssignmentsScreen = (props) => {
    // #region State
    const [showAllYourAction, setShowAllYourAction] = useState(false);
    const [showAllApproved, setShowAllApproved] = useState(false);
    const [showAllPendingApproval, setShowAllPendingApproval] = useState(false);
    const [showAllPayment, setShowAllPayment] = useState(false);
    // #endregion
    // #region Hooks
    const { data, loading, error } = useGetRepairEstimatesQuery({
        variables: {
            vendorId: props.vendorId,
            paymentStatuses: [PaymentStatus.PaymentNotRequested, PaymentStatus.PaymentInProgress, PaymentStatus.WorkCompleted],
            estimateStatuses: [EstimateStatus.WaitingForEstimateAndImages, EstimateStatus.WaitingForHolmanApproval, EstimateStatus.Approved, EstimateStatus.WaitingForRepairEta]
        },
        pollInterval: configuration.accidentAssignmentsViewRefreshFrequencyInMinutes * 60 * 1000
    });
    const intl = useIntl();
    //#endregion
    // #region Render helpers
    const getVehicle = (record) => {
        let vehicleText = (record.vehicle.year ? record.vehicle.year + " " : "")
            + (record.vehicle.make ? record.vehicle.make + " " : "")
            + (record.vehicle.model ? record.vehicle.model + " " : "");
        return (React.createElement(OverlayTrigger, { placement: "right", key: record.id + "vehicle", overlay: React.createElement(Tooltip, { id: "tooltop" }, vehicleText) },
            React.createElement("div", { key: record.id + "vehicle" }, vehicleText.length > 15 ? vehicleText.substring(0, 14) + "..." : vehicleText)));
    };
    const getStatus = (record, status) => {
        const messages = defineMessages({
            images_and_estimates_requested: {
                id: 'images_and_estimates_requested',
                description: 'Images and estimates requested',
                defaultMessage: 'Images and estimates requested'
            },
            repair_approved_eta_pending: {
                id: 'repair_approved_eta_pending',
                description: 'Repair approved, repair ETA needed',
                defaultMessage: 'Repair approved, repair ETA needed'
            },
            repair_approved: {
                id: 'repair_approved',
                description: 'Repair approved',
                defaultMessage: 'Repair approved'
            },
            payment_submitted: {
                id: 'payment_submitted',
                description: 'Payment submitted',
                defaultMessage: 'Payment submitted'
            },
            pending_repair_approval: {
                id: 'pending_repair_approval',
                description: 'Pending approval for {submission}',
                defaultMessage: 'Pending approval for {submission}'
            },
            original_estimate: {
                id: "original_estimate",
                description: "original estimate",
                defaultMessage: "original estimate"
            },
            supplement: {
                id: "supplement",
                description: "supplement {submissionNumber}",
                defaultMessage: "supplement {submissionNumber}"
            },
        });
        let statusText = "";
        switch (status) {
            case EstimateStatus.WaitingForEstimateAndImages:
                statusText = intl.formatMessage(messages.images_and_estimates_requested);
                break;
            case EstimateStatus.WaitingForHolmanApproval:
                statusText = intl.formatMessage(messages.pending_repair_approval, { submission: record.submissionNumber === 1 ? intl.formatMessage(messages.original_estimate) : intl.formatMessage(messages.supplement, { submissionNumber: record.submissionNumber - 1 }) });
                break;
            case EstimateStatus.Approved:
                statusText = intl.formatMessage(messages.repair_approved);
                break;
            case EstimateStatus.WaitingForRepairEta:
                statusText = intl.formatMessage(messages.repair_approved_eta_pending);
                break;
            case PaymentStatus.Paid:
            case PaymentStatus.PaymentInProgress:
            case PaymentStatus.WorkCompleted:
                statusText = intl.formatMessage(messages.payment_submitted);
                break;
        }
        return (React.createElement("div", { style: { width: "24rem" } }, statusText));
    };
    const getFooter = (claims, showAll) => {
        const messages = defineMessages({
            show_all_waiting_claim: {
                id: 'show_all_waiting_claim',
                description: 'Link at bottom of claim List to show all claims',
                defaultMessage: 'Show all {claimCount} claims'
            },
            hide_older_claim: {
                id: 'hide_older_claim',
                description: 'Link at bottom of claim List to hide older approved claims',
                defaultMessage: 'Hide older claims'
            }
        });
        // Don't show footer if we have 10 or less records
        if (claims.length <= 10)
            return undefined;
        // If we are not showing all, show button to show all
        else if (!showAll) {
            return React.createElement("div", { role: "button", className: "accident_assignments__footer" },
                React.createElement(FormattedMessage, Object.assign({ values: { claimCount: claims.length } }, messages.show_all_waiting_claim)),
                ' ',
                React.createElement("img", { src: "/assets/VMS_button_show_all_pos.png" }));
        }
        else {
            return React.createElement("div", { role: "button", className: "accident_assignments__footer" },
                React.createElement(FormattedMessage, Object.assign({}, messages.hide_older_claim)),
                ' ',
                React.createElement("img", { src: "/assets/VMS_button_hide_older_pos.png" }));
        }
    };
    //#endregion
    const messages = defineMessages({
        accident_assignments_your_action_title: {
            id: "accident_assignments_your_action_title",
            description: "Your action required title",
            defaultMessage: "Your action required"
        },
        accident_assignments_approved_title: {
            id: "accident_assignments_approved_title",
            description: "Approved title",
            defaultMessage: "Approved"
        },
        accident_assignments_pending_approval_title: {
            id: "accident_assignments_pending_approval_title",
            description: "Pending Approval title",
            defaultMessage: "Pending approval"
        },
        accident_assignments_payment_in_progress_title: {
            id: "accident_assignments_payment_in_progress_title",
            description: "Payment in Progress title",
            defaultMessage: "Payment in progress"
        },
        accident_assignments_vehicle_header: {
            id: "accident_assignments_vehicle_header",
            description: "Vehicle header",
            defaultMessage: "Vehicle"
        },
        accident_assignments_vin_header: {
            id: "accident_assignments_vin_header",
            description: "VIN header",
            defaultMessage: "VIN"
        },
        accident_assignments_claim_header: {
            id: "accident_assignments_claim_header",
            description: "Claim header",
            defaultMessage: "Claim #"
        },
        accident_assignments_status_header: {
            id: "accident_assignments_status_header",
            description: "Status header",
            defaultMessage: "Status"
        },
        accident_assignments_view_caption: {
            id: "accident_assignments_view_caption",
            description: "VIEW",
            defaultMessage: "VIEW"
        },
        accident_assignments_no_claim: {
            id: "accident_assignments_no_claim",
            description: "None at this time",
            defaultMessage: "None at this time"
        },
    });
    let yourActionClaims = data === null || data === void 0 ? void 0 : data.repairEstimates.filter(estimate => {
        return (estimate.estimateStatus === EstimateStatus.WaitingForEstimateAndImages
            || (estimate.estimateStatus === EstimateStatus.WaitingForRepairEta) && estimate.paymentStatus === PaymentStatus.PaymentNotRequested);
    });
    let approvedClaims = data === null || data === void 0 ? void 0 : data.repairEstimates.filter(estimate => {
        return (estimate.estimateStatus === EstimateStatus.Approved
            && estimate.paymentStatus === PaymentStatus.PaymentNotRequested);
    });
    let pendingApprovalClaims = data === null || data === void 0 ? void 0 : data.repairEstimates.filter(estimate => {
        return estimate.estimateStatus === EstimateStatus.WaitingForHolmanApproval;
    });
    let pendingPaymentClaims = data === null || data === void 0 ? void 0 : data.repairEstimates.filter(estimate => {
        return (estimate.paymentStatus === PaymentStatus.PaymentInProgress
            || estimate.paymentStatus === PaymentStatus.WorkCompleted);
    });
    return (React.createElement("div", { className: "accident_assignments-list table-responsive" }, loading ? React.createElement(Spinner, null)
        : React.createElement(React.Fragment, null,
            React.createElement("div", { className: "accident_assignments__caption accident_assignments__caption_action-required" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_your_action_title))),
            React.createElement("table", { className: "accident_assignments__table", width: "100%" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vin_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_claim_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_status_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" }))),
                React.createElement("tbody", null, (!yourActionClaims || yourActionClaims.length === 0)
                    ? React.createElement("tr", null,
                        React.createElement("td", { colSpan: 6, className: "accident_assignments__cell\r\n                                                accident_assignments__cell_shadow\r\n                                                active-po-list__cell_both-corners-round\r\n                                                active-po-list__cell_align-center" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_no_claim))))
                    :
                        yourActionClaims.slice(0, showAllYourAction ? undefined : 10).map((estimate, i) => React.createElement("tr", { key: estimate.id },
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'active-po-list__cell_left-corner-round', 'active-po-list__cell-action-required') }, getVehicle(estimate)),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'active-po-list__cell-action-required') }, estimate.vehicle.vin8),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'active-po-list__cell-action-required') }, estimate.claimId),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'active-po-list__cell-action-required') }, getStatus(estimate, estimate.estimateStatus)),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'active-po-list__cell-action-required', 'accident_assignments__cell_right-corner-round') },
                                React.createElement(Link, { id: `view_your_action_${i + 1}_btn`, to: `/AccidentManagement/AccidentAssignments/${estimate.id}` },
                                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_view_caption)))))))),
            React.createElement("div", { onClick: () => setShowAllYourAction(!showAllYourAction), id: "show_all_your_action_btn" }, data ? getFooter(yourActionClaims, showAllYourAction) : null),
            React.createElement("div", { className: "accident_assignments__caption" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_approved_title))),
            React.createElement("table", { className: "accident_assignments__table", width: "100%" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vin_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_claim_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_status_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" }))),
                React.createElement("tbody", null, (!approvedClaims || approvedClaims.length === 0)
                    ? React.createElement("tr", null,
                        React.createElement("td", { colSpan: 6, className: "accident_assignments__cell\r\n                                                accident_assignments__cell_shadow\r\n                                                active-po-list__cell_both-corners-round\r\n                                                active-po-list__cell_align-center" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_no_claim))))
                    :
                        approvedClaims.slice(0, showAllApproved ? undefined : 10).map((estimate, i) => React.createElement("tr", { key: estimate.id },
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'active-po-list__cell_left-corner-round') }, getVehicle(estimate)),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, estimate.vehicle.vin8),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, estimate.claimId),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, getStatus(estimate, estimate.estimateStatus)),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'accident_assignments__cell_right-corner-round') },
                                React.createElement(Link, { id: `view_approved_${i + 1}_btn`, to: `/AccidentManagement/AccidentAssignments/${estimate.id}` },
                                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_view_caption)))))))),
            React.createElement("div", { onClick: () => setShowAllApproved(!showAllApproved), id: "show_all_approved_btn" }, data ? getFooter(approvedClaims, showAllApproved) : null),
            React.createElement("div", { className: "accident_assignments__caption" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pending_approval_title))),
            React.createElement("table", { className: "accident_assignments__table", width: "100%" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vin_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_claim_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_status_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" }))),
                React.createElement("tbody", null, (!pendingApprovalClaims || pendingApprovalClaims.length === 0)
                    ? React.createElement("tr", null,
                        React.createElement("td", { colSpan: 6, className: "accident_assignments__cell\r\n                                                accident_assignments__cell_shadow\r\n                                                active-po-list__cell_both-corners-round\r\n                                                active-po-list__cell_align-center" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_no_claim))))
                    :
                        pendingApprovalClaims.slice(0, showAllPendingApproval ? undefined : 10).map((estimate, i) => React.createElement("tr", { key: estimate.id },
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'active-po-list__cell_left-corner-round') }, getVehicle(estimate)),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, estimate.vehicle.vin8),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, estimate.claimId),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, getStatus(estimate, estimate.estimateStatus)),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'accident_assignments__cell_right-corner-round') },
                                React.createElement(Link, { id: `view_pending_${i + 1}_btn`, to: `/AccidentManagement/AccidentAssignments/${estimate.id}` },
                                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_view_caption)))))))),
            React.createElement("div", { onClick: () => setShowAllPendingApproval(!showAllPendingApproval), id: "show_all_pending_btn" }, data ? getFooter(pendingApprovalClaims, showAllPendingApproval) : null),
            React.createElement("div", { className: "accident_assignments__caption" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_payment_in_progress_title))),
            React.createElement("table", { className: "accident_assignments__table", width: "100%" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vin_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_claim_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_status_header))),
                        React.createElement("th", { className: "accident_assignments__header-cell\r\n                            accident_assignments__header-cell_align-left" }))),
                React.createElement("tbody", null, (!pendingPaymentClaims || pendingPaymentClaims.length === 0)
                    ? React.createElement("tr", null,
                        React.createElement("td", { colSpan: 6, className: "accident_assignments__cell\r\n                                                accident_assignments__cell_shadow\r\n                                                active-po-list__cell_both-corners-round\r\n                                                active-po-list__cell_align-center" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_no_claim))))
                    :
                        pendingPaymentClaims.slice(0, showAllPayment ? undefined : 10).map((estimate, i) => React.createElement("tr", { key: estimate.id },
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'active-po-list__cell_left-corner-round') }, getVehicle(estimate)),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, estimate.vehicle.vin8),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, estimate.claimId),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow') }, getStatus(estimate, estimate.paymentStatus)),
                            React.createElement("td", { className: classNames('accident_assignments__cell', 'accident_assignments__cell_shadow', 'accident_assignments__cell_right-corner-round') },
                                React.createElement(Link, { id: `view_payment_${i + 1}_btn`, to: `/AccidentManagement/AccidentAssignments/${estimate.id}` },
                                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_view_caption)))))))),
            React.createElement("div", { onClick: () => setShowAllPayment(!showAllPayment), id: "show_all_payment_btn" }, data ? getFooter(pendingPaymentClaims, showAllPayment) : null))));
};
export default (AccidentAssignmentsScreen);
