import React from 'react';
import  ReactDoM from 'react-dom';
import './Submit.css';
import Spinner from 'components/Spinner';

class Submit extends React.Component {
    render() {
        const tabIndexProp = this.props.tabIndex!=undefined ? {tabIndex: this.props.tabIndex} : {};
        const imageStyle = {
            width: !this.props.imageWidth?'auto':this.props.imageWidth,
            height: !this.props.imageHeight?'auto':this.props.imageHeight,
        }
        const imageStyleProp = { style: imageStyle } ;
        return (
            <div className='submit-container'>
                <div className="submit inline-block"
                     role="button"
                     onClick={this.props.onClickHandler.bind(this)}
                     {...tabIndexProp}>
                    {this.props.caption}
                    {' '}
                    <img src={this.props.imagePath} {...imageStyleProp}/>
                </div>
                    <div className="inline-block">
                                {this.props.showSpinner ?                                
                                    <Spinner spinnerType={this.props.spinnerType} />
                                : undefined
                                }
                    </div>       
            </div>
        );
    }
}
;


export default Submit;

