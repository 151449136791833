import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import './ResetPassword.css';
import * as constants from 'constants/App';
import { defineMessages, FormattedMessage, injectIntl,RawIntlProvider } from 'react-intl';
import { falseIfUndefined, emptyStringIfUndefined, getTokenExpirationTimeinMinutes, renderError } from 'utils/HelperFunctions';
import Spinner from 'components/Spinner';
import FormWrapper from 'components/FormWrapper';
import { Popover, Overlay } from 'react-bootstrap';
import * as ChangeActions from '../actions/ResetPassword';
import { jwtDecode } from 'jwt-decode';
import * as helperFunctions from '../../../../shared/utils/HelperFunctions';
import * as configuration from '../../../../shared/configuration';
import TranslationIcon from 'components/SiteTranslations';
import * as sharedActions from '../../../../shared/actions/App';
import * as CookieHelper from '../../../../shared/utils/CookieHelper';
import queryString from 'query-string'
import PasswordPolicy from 'shared/components/PasswordPolicy';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.txtPasswordRef = React.createRef();
        this.txtConfirmPasswordRef = React.createRef();

        let params = queryString.parse(this.props.routeParams.location.search);
        
        let requestId = params != null && params.requestId != '' ? params.requestId : '';
        let country = params != null && params.country != '' ? params.country : '';
        let isPersonaUser=this.props.routeParams.location.pathname.toLowerCase()=='/persona/resetpassword' || 
        this.props.routeParams.location.pathname.toLowerCase()=='/persona/setuppassword';
        let isPersonaPasswordSetUp=this.props.routeParams.location.pathname.toLowerCase()=='/persona/setuppassword';
        
        try {
            const jwtToken = jwtDecode(requestId);
        }
        catch (err) {
            requestId = '';
        }

        this.state = {
            passwordStyle: undefined,
            showErrorMessage: false,
            requestId: requestId,
            country: country,
            isPersonaUser:isPersonaUser,
            isPersonaPasswordSetUp:isPersonaPasswordSetUp
        }
    }

    componentDidMount() {
        if (this.state.country !== '' && this.state.country === 'MEX') {
            let languageLocale = 'es-MX';
            this.props.dispatch(sharedActions.selectLocale(languageLocale));
            CookieHelper.createCookie('vms_language_preference', JSON.stringify({
                languageLocaleCookie: languageLocale
            }));
        }
        
        if (this.state.requestId == '') {
            this.props.dispatch({ type: constants.NAVIGATE_TO, path: `/resetPassword/confirmation/invalid` });
        }
        else {
            this.props.dispatch({ type: constants.RESET_PASSWORD_MOUNT });        
        }
    
    }

    onHide() {
        this.props.dispatch({ type: constants.CLEAR_RESET_PASSWORD_ERROR });
    }

    validationPasswordHandler() {
        const { formatMessage } = this.props.intl;

        const errorMessages = defineMessages({
            confirm_password_field_required: {
                id: 'confirm_password_field_required',
                description: 'tile for required confirm password',
                defaultMessage: 'Confirm password cannot empty'
            },
            confirm_password_field_does_not_match: {
                id: 'confirm_password_field_does_not_match',
                description: 'Doesn\'t match  password and confirm password',
                defaultMessage: 'Confirm password doesn\'t match'
            }
        });

        let password = this.txtPasswordRef.current.value;
        let confirmPassword = this.txtConfirmPasswordRef.current.value;
        let hasValidationError = false;

        if (!helperFunctions.validatePasswordPolicy(password)) {
            this.props.dispatch(ChangeActions.showPopOverInvalidPassword(true));
            hasValidationError = true;
        } else if (confirmPassword == '') {
            this.props.dispatch(ChangeActions.saveConfirmPasswordErrorMessage(formatMessage(errorMessages.confirm_password_field_required)));
            this.props.dispatch(ChangeActions.showPopOverConfirmPassword(true));
            hasValidationError = true;
        }
        else if (password !== confirmPassword) {
            this.props.dispatch(ChangeActions.saveConfirmPasswordErrorMessage(formatMessage(errorMessages.confirm_password_field_does_not_match)));
            this.props.dispatch(ChangeActions.showPopOverConfirmPassword(true));
            hasValidationError = true;
        }

        if (!hasValidationError) {
            let resetPasswordInfo = {
                requestId: this.state.requestId,
                newPassword: password
            };

            this.props.dispatch(ChangeActions.saveResetPasswordPersonal(resetPasswordInfo));
        }
        return !hasValidationError;
    }

    onSubmitHandler() {
        let hasValidated = this.validationPasswordHandler();

        if (hasValidated) {
            this.props.dispatch({ type: constants.RESET_PASSWORD_REQUEST, isPersonaUser:this.state.isPersonaUser });
        }
    }

    onHomeClickHandler(e) {
        e.preventDefault();
        this.props.dispatch({ type: constants.NAVIGATE_TO, path: `/login` });
    }

    render() {
        const messages = defineMessages({
            reset_password_title: {
                id: 'reset_password_title',
                description: 'title for input field groups',
                defaultMessage: 'Reset your password'
            },
            password_field_required: {
                id: 'password_field_required',
                description: 'Please enter valid password',
                defaultMessage: 'Password must meet complexity criteria below'
            },
            new_password_field: {
                id: 'new_password_field',
                description: 'title of new password field',
                defaultMessage: 'New Password: '
            },
            confirm_password_field: {
                id: 'confirm_password_field',
                description: 'title of confirm password field',
                defaultMessage: 'Confirm Password: '
            },
            reset_password_button: {
                id: 'reset_password_button',
                description: 'reset password button text',
                defaultMessage: 'Reset Password'
            },
            reset_password_home_button: {
                id: 'reset_password_home_button',
                description: 'reset password home button text',
                defaultMessage: 'Home'
            },
            reset_password_persona_user_registration_mesg: {
                id: 'reset_password_persona_user_registration_mesg',
                description: 'Enter password to register your account with Holman Partner Connect',
                defaultMessage: 'Enter password to register your account with Holman Partner Connect'
            },
            reset_password_persona_user_registration_title:{
                id:'reset_password_persona_user_registration_title',
                description:'Set Password',
                defaultMessage:'Set Password'
            }
        });

        if (this.props.isfetchingData) {
            return <Spinner />;
        }

        return <div>
            {
                <div className="reset-password-component row" >
                    <div className="reset_password_component_help_container row">
                        <span>
                            <TranslationIcon backgroundColor='blue' />
                        </span>
                    </div>
                    <div className="reset-password-component__title">
                        <span>
                        {this.state.isPersonaPasswordSetUp?
                        <FormattedMessage {...messages.reset_password_persona_user_registration_title} />:
                        
                            <FormattedMessage {...messages.reset_password_title} />
                        }
                        </span>
                    </div>

                    <div className="reset-password-component__input-section">
                        {this.props.hasErrorMessage
                            ? <div className="row reset-password-alerts">
                                {renderError('resetPasswordErr', this.props, this.props.errorMessage)}
                                {renderError('genericErrorMessage', this.props, this.props.genericErrorMessage)}
                            </div>
                            : ''}
                        {this.state.isPersonaPasswordSetUp?
                        <div className="row form-group">
                            <div className="col-sm-12">
                                <div className="reset-password-component__label">
                                    <FormattedMessage {...messages.reset_password_persona_user_registration_mesg} />
                                </div>
                            </div>
                        </div>:false}
                        <div className="row form-group">
                            <div className="col-sm-5">
                                <div className="reset-password-component__label">
                                    <FormattedMessage {...messages.new_password_field} />
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <Overlay
                                    show={this.props.popOverDisplayShowPassword}
                                    onHide={this.onHide.bind(this)}
                                    placement="right"
                                    target={this.txtPasswordRef.current}
                                >
                                    <Popover id="resetPassword_PasswordErrorMessage"
                                        className="reset-password-component__errorMessage">
                                              <RawIntlProvider value={this.props.intl}>
                                        <FormattedMessage {...messages.password_field_required} />
                                        </RawIntlProvider>
                                    </Popover>
                                </Overlay>
                                <input type="password" tabIndex="1" ref={this.txtPasswordRef}
                                    className="form-control"
                                    onBlur={() => this.props.dispatch(ChangeActions.showPopOverInvalidPassword(false))}
                                    autocomplete="off"
                                />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-sm-5">
                                <div className="reset-password-component__label">
                                    <FormattedMessage {...messages.confirm_password_field} />
                                </div>
                            </div>
                            <div className="col-sm-7">
                                <Overlay
                                    show={this.props.popOverDisplayShowConfirmPassword}
                                    onHide={this.onHide.bind(this)}
                                    placement="right"
                                    target={this.txtConfirmPasswordRef.current}
                                >
                                    <Popover id="resetPassword_ConfirmPasswordErrorMessage"
                                        className="reset-password-component__errorMessage">
                                        {this.props.confirmPasswordErrorMessage}
                                    </Popover>
                                </Overlay>
                                <input type="password" tabIndex="2" ref={this.txtConfirmPasswordRef}
                                    className="form-control"
                                    onBlur={() => this.props.dispatch(ChangeActions.showPopOverConfirmPassword(false))}
                                    autocomplete="off"
                                />
                            </div>
                        </div>
                        <div>
                            <PasswordPolicy />
                        </div>
                    </div>
                    <div className="reset-password__footer container">
                        <div className={this.props.isfetchingData ? "pull-left reset-password_button reset-password_button-disabled" : "pull-left reset-password_button"}
                            role="button"
                            onClick={this.onHomeClickHandler.bind(this)}>
                            <img src="/assets/VMS_33button_back_blue.png" />
                            {' '}
                            <FormattedMessage {...messages.reset_password_home_button} />
                        </div>
                        <div
                            className="pull-right reset-password_button"
                            role="button"
                            onClick={this.onSubmitHandler.bind(this)}
                        >
                        {this.state.isPersonaPasswordSetUp? <FormattedMessage {...messages.reset_password_persona_user_registration_title} /> :
                            <FormattedMessage {...messages.reset_password_button} />}
                            {' '}
                            <img src="/assets/VMS_33button_go_bblue.png" />
                        </div>
                    </div>
                </div>
            }
        </div>
    }
}

function select(state) {
    return {
        popOverDisplayShowPassword: falseIfUndefined(state.appState.getIn(['uiData', 'resetPassword', 'popOver', 'isPopOverDisplayedShowPassword'])),
        popOverDisplayShowConfirmPassword: falseIfUndefined(state.appState.getIn(['uiData', 'resetPassword', 'popOver', 'isPopOverDisplayedShowConfirmPassword'])),
        confirmPasswordErrorMessage: emptyStringIfUndefined(state.appState.getIn(['uiData', 'resetPassword', 'confirmPasswordErrorMessage'])),
        isfetchingData: falseIfUndefined(state.appState.getIn(['uiData', 'resetPassword', 'isFetchingData'])),
        hasErrorMessage: falseIfUndefined(state.appState.getIn(['uiData', 'resetPassword', 'hasErrorMessage'])),
        errorMessage: state.appState.getIn(['uiData', 'resetPassword', 'errorMessage']),
        genericErrorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage'])
    };
}

export default connect(select)(injectIntl(ResetPassword));