// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './SaveButton.css';

const SaveButton = ({onClick}) => {
    const messages = defineMessages({
            documents_save_button: {
                id: 'documents_save_button',
                description: 'Save button',
                defaultMessage: 'Save new document'
            }
        });

    return <div className='documents__save'>
        <span onClick={onClick} className='documents__save_button' role="button">            
            <FormattedMessage {...messages.documents_save_button}/>                
            {' '}<img src="/assets/VMS_28button_edit_clear.png"/>
        </span>        
    </div>
}

export default SaveButton;