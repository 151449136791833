import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import { zeroIfUndefined, emptyMapIfUndefined,getSelectedLanguageCode } from 'utils/HelperFunctions';
import { Schema, arrayOf, normalize } from 'normalizr';
import * as helperFunctions from 'utils/HelperFunctions';
import { Map } from 'immutable';
import moment from 'moment';

import * as craneInspectionEnums from '../data/CraneInspectionEnums';

export function* windowShown(context, action) {
    try {
        const state = yield select();                
        		
		const vehicleDetails = state.appState.getIn(['serverData', 'close', 'vehicleDetails']);		
        
		const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);
		const vendor = state.appState.getIn(['serverData', 'close', 'vendor']);
		const inspectorSignature = state.appState.getIn(['uiData', 'craneInspection', 'formData', 'inspectorSignature']);		
		
		yield put({ type: constants.CRANE_INSPECTION__SET_DEFAULT_VALUES, 
            customer: vehicleDetails.get('clientName'),
            unitNumber: vehicleDetails.get('ariUnitNo'),		
			reportNumber: poNumber,
			address: [vehicleDetails.getIn(['clientBusinessAddress', 'address2']), ', ', 
			vehicleDetails.getIn(['clientBusinessAddress', 'address3']), ', ',
			vehicleDetails.getIn(['clientBusinessAddress', 'address4']), ', ',
			vehicleDetails.getIn(['clientBusinessAddress', 'city']), ', ',
			vehicleDetails.getIn(['clientBusinessAddress', 'state']), ', ',
			vehicleDetails.getIn(['clientBusinessAddress', 'zipCode'])].join('') || 'N/A'
		});				
    }
    catch (e) {
        yield put({
            type: constants.FETCH_CLOSE_PO_DATA_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }
}

export function* saveConfirmation(context, action) {
    try {
		yield put({type: constants.CRANE_INSPECTION__SAVE_CONFIRMATION_REQUESTED});
        const state = yield select();
        
        const token = helperFunctions.getToken(state);
        
        const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);
		const assetType = state.appState.getIn(['serverData', 'close', 'vendor', 'assetType']);	        
        
		let craneInspectionData = state.appState.getIn(['uiData', 'craneInspection', 'formData']).toJSON();
		const componentDetails = state.appState.getIn(['uiData', 'craneInspection', 'componentDetails'])
			.map(component => {
				return component.set('componentResultCode', craneInspectionEnums.componentResultCode.PASS)
			});
		const componentDetailsJSON = componentDetails.toList().toJSON();
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
       
        // Set defaults
		craneInspectionData['id'] = 0;
        
		craneInspectionData['componentDetails'] = componentDetailsJSON;		

        yield call(Api.createCraneInspection, poNumber, craneInspectionData, token,vendorLanguage);

		yield put({type: constants.CRANE_INSPECTION__DISPLAY, show: false});

        // Continue where request payment left off.
		yield put({type: constants.CLOSE_PO_CLICKED, closePoData: {poNumber: poNumber}});
		
		yield put({type: constants.CRANE_INSPECTION__SAVE_CONFIRMATION_RESOLVED});
    }
    catch (e) {
        yield put({
            type: constants.CRANE_INSPECTION__SAVE_CONFIRMATION_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.CRANE_INSPECTION__SAVE_CONFIRMATION_REQUESTED,
            errorObject: e
        });
    } 
}

export function* saveForm(context, action) {
    try {
		yield put({type: constants.CRANE_INSPECTION__SAVE_FORM_REQUESTED});

        const state = yield select();        
        const token = helperFunctions.getToken(state);        
        const poNumber = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'id']);        
		let craneInspectionData = state.appState.getIn(['uiData', 'craneInspection', 'formData']).toJSON();				
		const componentDetails = state.appState.getIn(['uiData', 'craneInspection', 'componentDetails']);
		const componentDetailsJSON = componentDetails.toList().toJSON();
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
       
        // Set defaults
		craneInspectionData['id'] = 0;        
		craneInspectionData['componentDetails'] = componentDetailsJSON;

        yield call(Api.createCraneInspection, poNumber, craneInspectionData, token,vendorLanguage);

		yield put({type: constants.CRANE_INSPECTION__DISPLAY, show: false});
		
        // Continue where request payment left off.
		yield put({type: constants.CLOSE_PO_CLICKED, closePoData: {poNumber: poNumber}});
		
		yield put({type: constants.CRANE_INSPECTION__SAVE_FORM_RESOLVED});
    }
    catch (e) {
        window.scrollTo(0, 0);
        yield put({
            type: constants.CRANE_INSPECTION__SAVE_FORM_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }  
}
