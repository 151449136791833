// #region Imports
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { emptyMapIfUndefined, emptyStringIfUndefined, getCustomErrorObject, getErrorObject } from 'utils/HelperFunctions';
import styled from "styled-components";
// our stuff
import { useAppState } from 'shared/AppState/app-state';
import * as Api from "utils/Api";
import * as MainSelector from '../selectors/ActionRequired';
import * as Notes from '../selectors/Notes';
import * as constants from 'constants/App';
import Title from '../components/Title';
import AuthorizationStatusReason from '../components/AuthorizationStatusReason';
import ActionRequiredLineItems from '../components/ActionRequiredLineItems';
import Complaints from 'AddEditView/Complaints';
import Spinner from 'components/Spinner';
import './ActionRequired.css';
import FormWrapper from 'components/FormWrapper';
import PurchaseOrderBasicDetails from 'AddEditView/PurchaseOrderBasicDetails';
import Shared from 'selectors/shared';
import ContactCustomerDirectlyForApprovalBanner from 'components/ContactCustomerDirectlyForApprovalBanner';
import ForwardForFurtherReview from 'components/ForwardForFurtherReview';
import FleetAdminContactInfo from 'shared/components/FleetAdminContactInfo';
// #endregion

// #region Styled Components
const SpinnerContainer = styled.div`
  display: inline-block;

  & .spinner {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: inherit;
    vertical-align: top;
    margin-left: 5px;
    border-width: 5px;
  }
`;
// #endregion

// #region Component
const ActionRequired = (props) => {
    // #region State
    const [appState] = useAppState();
    const token = appState.token;
    const displayName = 'POActionsView';
    const [isHsbClientVehicle, setIsHsbClientVehicle] = useState(undefined);
    const loadingStates = { loading: "loading", loaded: "loaded", error: "error", cancelled: "cancelled" };
    const [loadingState, setLoadingState] = useState(loadingStates.loading);
    const [fleetAdminContactInfo, setFleetAdminContactInfo] = useState(null);
    const [fleetAdminContactInfoError, setfleetAdminContactInfoError] = useState(null);
    const [vehicleId, setVehicleId] = useState(undefined);
    // #endregion

    // #region Ref
    const forwardPOShowConfirmWindowRef = useRef(null);
    // #endregion

    // #region Event handlers 

    const onCancelPOClickEvent = (e) => {
        props.dispatch({ type: constants.ACTION_REQUIRED_CANCEL_PO_CLICKED });
    }

    const onSubmitClickHandler = (e) => {
        //on submit click, if any lines are not acknowledged, then show the error 

        props.dispatch({ type: 'ACTION_REQUIRED_SHOW_REVIEW_PENDING_LINE_ERRORS' });
        //Check if all lines have been acknowledged
        if (props.actionRequiredLines.filter(a => a.get('reviewCompleted') != true).count() == 0) {
            props.dispatch({
                type: 'ACTION_REQUIRED_SUBMIT_PO',
                lineItems: props.actionRequiredLines,
                associatedPendingLines: props.associatedPendingLines,
                editPO: false,
                cancelPo: props.allLinesRejected
            }
            );
        }
    }

    const onAcceptAdjustmentsClickHandler = (lineItemId, e) => {
        props.dispatch({
            type: 'ACTION_REQUIRED_ACCEPT_ADJUSTMENT',
            id: lineItemId
        });
    }

    const onAcceptRejectionClickHandler = (lineItemId, e) => {
        props.dispatch({
            type: 'ACTION_REQUIRED_ACCEPT_REJECTION',
            id: lineItemId
        });
    }

    const onAcceptNewLineClickHandler = (lineItemId, e) => {
        props.dispatch({
            type: 'ACTION_REQUIRED_ACCEPT_NEW_LINE',
            id: lineItemId
        });
    }

    const onSubmitVendorNotesClickHandler = (lineItemId, notes, e) => {
        props.dispatch({
            type: 'ACTION_REQUIRED_UPDATE_VENDOR_NOTE',
            id: lineItemId,
            note: notes
        });
    }

    const onRejectAdjustmentsHandler = (event) => {
        event.preventDefault();
        //props.dispatch();         
        if (props.isPOAdjustedBySystem) {
            props.dispatch({
                type: constants.REJECT_PO_ADJUSTMENTS,
                lineItems: props.rejectedLineItems,
                moreToAcknowledge: false,
                cancelPo: false,
                rejectAdjustments: true,
                isHsbClientVehicle: props.vehicleDetails.get('isHsbClientVehicle'),
                sendOrderToCustomerRequestBody: {
                    to: constants.SEND_ORDER_NOTES_TO.CUSTOMER,
                    noteText: formatMessage({
                        id: 'reject_system_adjustment', description: 'Initial negotiation rejected by supplier. Please review parts pricing and labor time(s) accordingly.',
                        defaultMessage: 'Initial negotiation rejected by supplier. Please review parts pricing and labor time(s) accordingly.'
                    })
                }
            });
        }
    }

    const onSubmitPOClickHandler = (event) => {
        event.preventDefault();
        props.dispatch({
            type: 'ACTION_REQUIRED_SUBMIT_PO',
            lineItems: props.actionRequiredLines,
            associatedPendingLines: props.associatedPendingLines,
            cancelPO: props.allLinesRejected
        })
    }

    const onForwardPOHandler = (e) => {
        e.preventDefault();
        forwardPOShowConfirmWindowRef.current.showModal();
    }

    // #endregion

    // #region Effects
    useEffect(() => {
        props.dispatch({ type: constants.ACTION_REQUIRED_SCREEN_LOADED, poNumber: props.routeParams.match.params.trackNumber });
    }, [props.dispatch, props.routeParams.match.params.trackNumber]);

    useEffect(() => {
        setIsHsbClientVehicle(props.vehicleDetails?.get('isHsbClientVehicle'));
        setVehicleId(props.vehicleDetails?.get('id'));

    }, [props.vehicleDetails]);

    useEffect(() => {
        let didCancel = false;

        const fetchFleetAdminData = async () => {
            if (!vehicleId || !token || !isHsbClientVehicle)
            {
                setLoadingState(loadingStates.cancelled);
                return;                
            }
                
            try {
                setLoadingState(loadingStates.loading);

                const [fleetAdminContactList] = await Promise.all([
                    Api.fetchFleetAdminContactInfoByVehicleId(
                        vehicleId,
                        token
                    )
                ]);

                if (!didCancel) {
                    if (fleetAdminContactList != null && fleetAdminContactList.length > 0) {
                        setFleetAdminContactInfo(fleetAdminContactList[0]);
                    }
                    setLoadingState(loadingStates.loaded);
                }
            } catch (error) {
                if (!didCancel) {

                    if (error !== undefined && (error.code === 'API-163' || error.code === 'API-164')) {
                        setfleetAdminContactInfoError(getCustomErrorObject(error));
                    } else {
                        setfleetAdminContactInfoError(getErrorObject(error));
                    }

                    setLoadingState(loadingStates.error);
                    //Error
                }
            }
        };

        fetchFleetAdminData();

        return () => {
            didCancel = true;
        };
        }, [vehicleId, token]
    );

    // #endregion

    // #region Render helpers
    const getLineItem = (lineItemData, key) => {
        return <LineItemReadOnly
            key={key}
            data={lineItemData}
            hideCostInformation={props.hideCostInformation} />
    }

    // #endregion

    // #region International messages     
    const { formatMessage } = props.intl;
    const messages = defineMessages({
        action_required_po_edit: {
            id: 'action_required_po_edit',
            description: 'Edit PO',
            defaultMessage: 'Edit PO'
        },
        action_required_issue_note: {
            id: 'action_required_issue_note',
            description: 'If you have questions regarding these adjustments, please call\n1-866-274-1025 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call\n1-866-274-1025 to discuss with a Holman technician'
        },
        action_required_issue_note_can: {
            id: 'action_required_issue_note_can',
            description: 'If you have questions regarding these adjustments, please call\n1-800-363-7676 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call\n1-800-363-7676 to discuss with a Holman technician'
        },
        approval_request_or_cancel_po: {
            id: 'approval_request_or_cancel_po',
            description: 'Request Approval or Cancel PO',
            defaultMessage: 'Request Approval or Cancel PO'
        },
        approval_request_unsaved_changes_prompt: {
            id: 'approval_request_unsaved_changes_prompt',
            description: 'This purchase order has unsaved changes. Please click CANCEL to stay on this screen and review the changes. Then click Submit at bottom of the screen to send the changes to Holman.',
            defaultMessage: 'This purchase order has unsaved changes. Please click CANCEL to stay on this screen and review the changes. Then click Submit at bottom of the screen to send the changes to Holman.'
        },
        auto_negotiated_note_US: {
            id: 'auto_negotiated_note_US',
            description: 'If you do not accept these changes, you must call Holman at \n1-866-274-1025',
            defaultMessage: 'If you do not accept these changes, you must call Holman at \n1-866-274-1025'
        },
        auto_negotiated_note_CAN: {
            id: 'auto_negotiated_note_CAN',
            description: 'If you do not accept these changes, you must call Holman at \n1-800-363-7676',
            defaultMessage: 'If you do not accept these changes, you must call Holman at \n1-800-363-7676'
        },
        forward_for_further_review: {
            id: 'action_required_forward_for_further_review',
            description: 'Forward for further review',
            defaultMessage: 'Forward for further review'
        },
        adjusted_line_item_view_cancel_po: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        }
    });
    // #endregion


    const isDirtyPO = props.actionRequiredLines.filter(a => a.get('reviewCompleted') == true).count();
    const unsavedMessage = formatMessage(messages.approval_request_unsaved_changes_prompt);

    const sortedNotesByCreatedDate = props.notes
      ?.filter((item) => item.get("category") === 'CLIENT_VENDOR')
      ?.sortBy((item) => new Date(item.get("createdDate"))).reverse();
    let isLastNoteFromClient = false;
    let lastNote = undefined;
    
    if(sortedNotesByCreatedDate && sortedNotesByCreatedDate.count() > 0)
    {
        lastNote = sortedNotesByCreatedDate.first();
        isLastNoteFromClient = lastNote?.get('createdBy') === 'CLIENT';
    }

    return <React.Fragment>
        {
            props.isBusy ? <Spinner /> :
                <div>
                    <Prompt when={isDirtyPO > 0} message={unsavedMessage} />

                    <FormWrapper key='actionRequiredErrorMessage'
                        id="actionRequiredErrorMessage" {...props}
                        formErrors={props.genericErrorMessage} />

                    <FormWrapper key='actionRequiredErrorMessage1'
                        id="actionRequiredErrorMessage1" {...props}
                        formErrors={props.actionRequiredErrorMessage} />

                    { 
                        isHsbClientVehicle && 
                        props.entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER && 
                            <ContactCustomerDirectlyForApprovalBanner />
                    }

                    <Title poNumber={props.routeParams.match.params.trackNumber}
                        authorizationStatus={props.authorizedStatus}
                        vin={props.vehicleDetails.get('vin')}
                    />

                    <div className="action_required">
                        {
                            props.authorizedStatus == constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ||
                                (props.authorizedStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL &&
                                    isHsbClientVehicle && isLastNoteFromClient) ?
                                <AuthorizationStatusReason
                                    authorizationStatus={props.authorizedStatus}
                                    isHsbClientVehicle={isHsbClientVehicle}
                                    note={lastNote}
                                    intl={props.intl}
                                /> : undefined
                        }
                        {

                            /*Show only if adjusted lines exists*/
                            props.actionRequiredLines.count() > 0 ?
                                <ActionRequiredLineItems complaints={props.complaints}
                                    nonMaintenanceNotes={props.nonMaintenanceNotes}
                                    maintenanceNotesText={props.maintenanceNotesText}
                                    lineItems={props.actionRequiredLines}
                                    isClientAuthorizationRequired={props.isClientAuthorizationRequired}
                                    onCancelPOClickEvent={onCancelPOClickEvent.bind(this)}
                                    selectedCurrency={props.selectedCurrency}
                                    selectedCountry={props.selectedCountry}
                                    onRejectAdjustments={onRejectAdjustmentsHandler.bind(this)}
                                    authorizationStatus={props.authorizedStatus}
                                    isPOAdjustedBySystem={props.isPOAdjustedBySystem}
                                    onAcceptAdjustmentsClickHandler={onAcceptAdjustmentsClickHandler.bind(this)}
                                    onAcceptRejectionClickHandler={onAcceptRejectionClickHandler.bind(this)}
                                    onAcceptNewLineClickHandler={onAcceptNewLineClickHandler.bind(this)}
                                    onSubmitVendorNotesClickHandler={onSubmitVendorNotesClickHandler.bind(this)}
                                    showReviewPendingErrors={props.showReviewPendingErrors}
                                    onSubmitClickHandler={onSubmitClickHandler.bind(this)}
                                    associatedPendingLines={props.associatedPendingLines}
                                    allLinesRejected={props.allLinesRejected}
                                    needMoreNotesFlag={props.needMoreNotesLineItems.count() > 0}
                                    routeParams={props.routeParams}
                                    dispatch={props.dispatch}
                                    isHsbClientVehicle={isHsbClientVehicle}
                                    entityForAssistance={props.entityForAssistance}
                                    fleetAdminContactInfo={fleetAdminContactInfo}
                                    fleetAdminContactInfoError={fleetAdminContactInfoError}
                                />
                                : undefined
                        }
                        {
                            isHsbClientVehicle &&
                            props.entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER &&
                            props.actionRequiredLines.count() == 0 &&
                            props.authorizedStatus !== constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ? <div className="adjusted_line_item__cancel">
                                <Link to={`/PO/edit/${props.routeParams.match.params.trackNumber}`}>
                                        <FormattedMessage {...messages.action_required_po_edit} />
                                        {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                                </Link>
                            </div>
                                : undefined
                        }
                        {
                            isHsbClientVehicle &&
                                props.entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER &&
                                !props.isPOAdjustedBySystem &&
                                props.actionRequiredLines.count() == 0 &&
                                props.authorizedStatus !== constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ? <div className="adjusted_line_item__cancel">
                                <ForwardForFurtherReview
                                    ref={forwardPOShowConfirmWindowRef}
                                    routeParams={props.routeParams}
                                    dispatch={props.dispatch}
                                />
                                <a onClick={(e) => { onForwardPOHandler(e); }}>
                                    <FormattedMessage {...messages.forward_for_further_review} />
                                    {' '} <img src="/assets/VMS_28button_go_clear.png" />
                                </a>
                            </div>
                                : undefined
                        }
                        {
                            isHsbClientVehicle &&
                                props.entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER &&
                                props.actionRequiredLines.count() == 0 &&
                                props.authorizedStatus !== constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ? <div className="adjusted_line_item__cancel">
                                <a onClick={(e) => { onCancelPOClickEvent(e) }}>
                                    <FormattedMessage {...messages.adjusted_line_item_view_cancel_po} />
                                    {' '} <img src="/assets/VMS_28button_delete_blue.png" />
                                </a>
                            </div>
                                : undefined
                        }
                        {
                            props.authorizedStatus == constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ?
                                <div className="adjusted_line_item__accept">
                                    <Link to={`/PO/edit/${props.routeParams.match.params.trackNumber}`}>
                                        <FormattedMessage {...messages.approval_request_or_cancel_po} />
                                        {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                                    </Link>

                                </div>
                                :
                                <div>
                                    {
                                        loadingState == loadingStates.loading
                                            ? <SpinnerContainer>Loading...&nbsp;<Spinner spinnerType="dark-blue" manualPositioning /></SpinnerContainer>
                                            :
                                            isHsbClientVehicle && props.entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER ?
                                                <FleetAdminContactInfo messageFor={constants.FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.ISSUE_NOTE} fleetAdminContactInfo={fleetAdminContactInfo} fleetAdminContactInfoError={fleetAdminContactInfoError} />
                                                : props.rejectedLineItems.size > 0 ?
                                                    <div className="action_required__issue_note-angry">
                                                        {props.selectedCountry == 'USA' ? <FormattedMessage {...messages.auto_negotiated_note_US} /> : <FormattedMessage {...messages.auto_negotiated_note_CAN} />}
                                                    </div>
                                                    : <div className="action_required__issue_note">
                                                        {props.selectedCountry == 'USA' ? <FormattedMessage {...messages.action_required_issue_note} /> : <FormattedMessage {...messages.action_required_issue_note_can} />}
                                                    </div>
                                    }
                                </div>
                        }
                    </div>
                    {
                        /* check if any complaints exist that don't need acknowledgement  */
                        props.ackNotNeededComplaints.count() > 0 ?
                            <Complaints complaints={props.ackNotNeededComplaints}
                                complaintTypes={props.complaintTypes}
                                notesHistory={props.notes}
                                disableMaintenanceComplaintType={false}
                                lineItems={props.ackNotNeededLineItems}
                                showErrorMessages={false}
                                actionType="ACTIONREQUIRED"
                                isInitialAddMode={false}
                                hideCostInformation={!props.doesPOBelongToVendor}
                                selectedCurrency={props.selectedCurrency}
                                listOfComplaintsForWhichNoteIsRequired={props.listOfComplaintsForWhichNoteIsRequired}
                            />
                            : undefined
                    }
                </div>
        }
    </React.Fragment>
        ;
}
// #endregion

// #region Props
const mapStateToProps = (state, props) => {
    return {
        isBusy: state.appState.getIn(['uiData', 'actionRequired', 'isBusy']),
        authorizationStatusReason: state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'authorizationStatusReason']),
        ackNotNeededComplaints: MainSelector.getAckNotNeededComplaints(state),
        complaintTypes: emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'complaintTypes'])),
        notes: Notes.getNotes(state, props),
        ackNotNeededLineItems: MainSelector.getAckNotNeededLineItems(state),
        actionRequiredLines: MainSelector.getAllActionRequiredLines(state),
        associatedPendingLines: MainSelector.getAssociatedPendingLines(state),
        rejectedLineItems: MainSelector.getRejectedLineItems(state),
        allLinesRejected: MainSelector.allLinesRejected(state),
        adjustedLineItems: MainSelector.getAdjustedLineItems(state),
        vehicleDetails: emptyMapIfUndefined(state.appState.getIn(['serverData', 'actionRequired', 'vehicleDetails'])),
        authorizedStatus: emptyStringIfUndefined(state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'authorizationStatus'])),
        paymentStatus: emptyStringIfUndefined(state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'paymentStatus'])),
        doesPOBelongToVendor: MainSelector.getDoesPOBelongToVendor(state, props),
        isClientAuthorizationRequired: state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'isClientAuthorizationRequired']) == 'Y',
        genericErrorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        actionRequiredErrorMessage: state.appState.getIn(['uiData', 'actionRequired', 'errorMessage']),
        selectedCurrency: state.appState.getIn(['uiData', 'shared', 'selectedCurrency']),
        selectedCountry: state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
        isPOAdjustedBySystem: MainSelector.isPOAdjustedBySystem(state),
        allLineItems: emptyMapIfUndefined(state.appState.getIn(['serverData', 'actionRequired', 'lineItems'])),
        needMoreNotesLineItems: MainSelector.getNeedMoreNotesLineItems(state),
        allLinesPendingNotes: MainSelector.allLinesPendingNotes(state),
        listofLinesWithPendingNotes: MainSelector.listofLinesWithPendingNotes(state),
        showReviewPendingErrors: state.appState.getIn(['uiData', 'actionRequired', 'showReviewPendingErrors']),
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        entityForAssistance: emptyStringIfUndefined(state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'entityForAssistance'])),
        ...Shared(state, props),
    };
};
// #endregion

export default connect(mapStateToProps)(injectIntl(ActionRequired));