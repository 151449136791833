import * as constants from 'constants/App';
import * as api from 'utils/CallAPI';
import { Map, fromJS } from 'immutable';
import * as service from '../service/App';
import * as sharedActions from '../shared/actions/App';
import * as odometerViewActions from '../screens/PO/OdometerView/actions/OdometerView';
import * as complaintViewActions from '../screens/PO/ComplaintView/actions/ComplaintView';
import * as purchaseOrderViewActions from '../screens/PO/AddEditView/actions/AddEditView';



export function setVehicleSearchErrorMessage(errorMessage) {
    return {type: constants.SET_VEHICLE_SEARCH_ERROR_MESSAGE, errorMessage}
}

export function clearVehicleSearchErrorMessage() {
    return {type: constants.CLEAR_VEHICLE_SEARCH_ERROR_MESSAGE}
}
export function loadVehicleData(vehicleData){
    return {type: constants.LOAD_VEHICLE_CLIENT_DATA, vehicleData}
}

export function signOut() {
    return {type: constants.SIGN_OUT}
}

export function personaUserStoreSignOut(){
    return {type:"PERSONA_USER_STORE_SIGN_OUT"}
}


export function stopSpinnerAndDisplayError(componentName, message, dispatch,path) {

    // Stop spinner
    dispatch(sharedActions.fetchDataFromAPIComplete(componentName));
    // Dispatch error
    dispatch(setVehicleSearchErrorMessage(message));
    //Clear previous vehicle search results in shared state
    //dispatch(loadVehicleData({vehicleDetails:undefined, client:undefined}));
    if(path!=undefined) //Other than index / shopview
    {
        dispatch(updatePath('/'));
    }

}

export function searchForPOsClicked(searchFilters){
    return {type: constants.SEARCH_FOR_POS_CLICKED, searchFilters}
}

export function stopSpinnerAndDisplayErrorSearchPO(componentName, message, dispatch) {
    // Stop spinner
    dispatch(sharedActions.fetchDataFromAPIComplete(componentName));
    // Dispatch error
    dispatch(setSearchPOErrorMessage(message));
}

export function setSearchPOErrorMessage(errorMessage) {

    return {type: constants.SET_SEARCH_PO_VIN8_ERROR_MESSAGE, errorMessage}
}

export function hideSearchPOErrorMessage() {
    return {type: constants.HIDE_SEARCH_PO_VIN8_ERROR_MESSAGE}
}

export function existingPoClicked(){
    return {type: constants.EXISTING_PO_YES}
}

export function saveAdvancedSearchForOption(searchFor) {
    return {type: constants.SAVE_SEARCH_FILTER_SEARCH_FOR, searchFor}
}

export function saveAdvancedDurationOption(days) {
    return {type: constants.SAVE_SEARCH_FILTER_DAYS, days}
}

export  function saveAdvancedSearchText(searchText)
{
    return {type: constants.SAVE_SEARCH_TEXT, searchText}
}

export function resetAdvancedSearchOptions(){
    return {type: constants.RESET_SEARCH_FILTERS}
}


export function termsAndConditionsToggleAccepted() {
    return {type:  constants.TOGGLE_TERMS_AND_CONDITIONS_ACCEPTED}
}

export function termsAndConditionsAccepted(termsAndConditionsFlag) {
    return {type: constants.SAVE_TERMS_AND_CONDITIONS_STATUS,termsAndConditionsFlag}
}

export function termsAndConditionsInitialsAccepted(tcInitialsValue) {
    return {type: constants.TC_INITIALS_TERMS_AND_CONDITIONS_ACCEPTED,tcInitialsValue}
}


export function tcConditionTermsAndConditionsChecked(bool) {
    return {type:  constants.TC_CONTINUE_TERMS_AND_CONDITIONS_FLAG,bool}
}

