import React from 'react';
import ReactDOM from 'react-dom';
import './AtaSearchComponent.css';
import classnames from 'classnames';
import Spinner from 'components/Spinner';
import { defineMessages, FormattedMessage,injectIntl } from 'react-intl';
import Help from 'components/Help';

class AtaSearchComponent extends React.Component {

    constructor(props) {
        super(props);
        this._ataList = [];

    }

    handleSearchInputOnChange() {
        this.props.onSearch(this._searchInput.value);
    }

    highlightWord(word) {
        const escapeRegExp = (str = '') => (
            str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
          );
        if (this._searchInput == undefined) return;
        let searchTerm = this._searchInput.value;
        let result = '';
        for (let wordSection of word.split(' ')) {
            for (let term of searchTerm.split(' ')) {
                let termRegex = new RegExp(`(${escapeRegExp(term)})`, 'i');
                let match = termRegex.exec(wordSection);
                if (match != undefined && match[1]) {
                    wordSection = wordSection.replace(termRegex, `<span class="ata-search-keywork-highlight" >${match[1]}</span>`);
                    break;
                }
            }
            result += wordSection + ' ';
        }
        return result;
    }

    handleRowScroll(index, e) {
        switch (e.key) {
            case 'ArrowDown':
                if (this.props.ataCodes.length - 1 !== index) {
                    this._ataList[index + 1].focus();
                }
                break;
            case 'ArrowUp':
                if (index !== 0) {
                    this._ataList[index - 1].focus();
                }
                else if (index === 0) {
                    this._searchInput.focus();
                }
                break;
            case 'Enter':
                this.handleRowClick(index);
                break;
            case 'Escape':
                this.clearSearchText();
                break;
        }
    }
    clearSearchText() {
        this.props.onSearch('');
        this.props.onHideContainer(true);
        this.props.resetAtaSearch();
    }
    handleSearchInputOnKeyDown(e) {
        switch (e.key) {
            case 'ArrowDown':
                if (this.props.ataCodes.length > 0) {
                    e.preventDefault(); //prevents scrollbar scroll
                    this._ataList[0].focus();
                }
                break;
            case 'Escape':
                this.clearSearchText();
                break;
        }
    }


    handleRowClick(index) {
        let selectedAtaCode = this._ataList[index].getAttribute('data-code');
        let selectedAtaCodeDescription = this._ataList[index].getAttribute('data-description');
        this.props.onRecordSelect(selectedAtaCode, selectedAtaCodeDescription);
        this.props.onHideContainer(true);
    }

    //render spinner
    renderSpinner() {
        return <div className="ata-search-spinner ata-search__input-div"><Spinner spinnerType="blue" /></div>;
    }

    //render ata search results
    renderSearchResults() {
        return <div id="search-result-outer-box">
            <div className="ata-search__results pre-scrollable" >
                <div id='ataSearchResult'>
                    {
                        this.props.ataCodes != undefined && this.props.ataCodes.length > 0 ?
                            this.props.ataCodes.map((ata, index) =>
                                <div
                                    ref={(c) => this._ataList[index] = c}
                                    tabIndex={index + 1}
                                    onKeyDown={this.handleRowScroll.bind(this, index)}
                                    key={index}
                                    className={classnames('ata-search__record', { 'ata-search__record-even': index % 2 == 0 }, { 'ata-search__record-odd': index % 2 != 0 })}
                                    data-code={ata.code}
                                    data-description={ata.description}
                                    onClick={this.handleRowClick.bind(this, index)}>
                                    <span dangerouslySetInnerHTML={{ __html: this.highlightWord(ata.description) }}></span>
                                </div>
                            )
                            : <div className="text-center ata-search__no-records">No Results Found</div>
                    }
                </div>
            </div>
        </div>;
    }
    render() {

        const renderClearSearchTextButton = this.props.searchedText.length > 0 ?
            <div className="ata-search__clear-text ata-search__input-div">
                <img src="/assets/VMS_33button_kill_clear_inline.png"
                    className='ata-search__clear-text-img'
                    onClick={this.clearSearchText.bind(this)} />
            </div>
            : undefined

        const renderSearchSpinner = this.props.isfetchingData ? this.renderSpinner(): undefined
        const {formatMessage} = this.props.intl;
        const messages = defineMessages({
            adv_search_ata: {
                id: 'adv_search_ata',
                description: 'adv_search_ata',
                defaultMessage: 'Search for parts'
            },
        });
        return (
            <div className="" >
                <div className="ata-search__input-box">
                    <input
                        id={'ataSearchInput'}
                        className={
                            classnames(
                                this.props.isfetchingData ? 'ata-search__input-with-spinner' : 'ata-search__input'
                            )
                        }
                        type="text"
                        onKeyDown={this.handleSearchInputOnKeyDown.bind(this)}
                        ref={(c) => { this._searchInput = c; }}
                        onChange={this.handleSearchInputOnChange.bind(this)}
                        value={this.props.searchedText}
                        placeholder={formatMessage(messages.adv_search_ata)} />
                    {
                        //show spinner if fetching data
                       renderSearchSpinner
                    }
                    {
                        renderClearSearchTextButton
                    }
                    {
                        this.props.showResults && !this.props.isfetchingData ?
                            this.renderSearchResults() : undefined
                    }   
                </div>
                    {
                        <Help videoId="adv_search_ata"/>

                    }
            </div>
        );
    }
}

export default injectIntl(AtaSearchComponent);

