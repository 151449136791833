// #region Imports
import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR } from "constants/App";
import FormWrapper from "components/FormWrapper";
// #endregion

// #region Styled Components

const FleetAdminContactInfoText = styled.div`
    font-size: 16px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    font-weight: 300;
    text-align: center;
    white-space: pre-wrap;
`;

const FleetAdminContactInfoRejectedLineItemText = styled.div`
    padding: 1em 0 1em 0;    
    color: red;
`;

const FleetAdminContactInfoNonRejectedLineItemText = styled.div`
    padding: 1em 0 1em 0;    
`;

const FleetAdminContactInfoIssueNoteText = styled.div`
    padding: 1em 0 1em 0; 
`;
// #endregion

// #region Component
const FleetAdminContactInfo = ({ 
  messageFor,
  fleetAdminContactInfo,
  fleetAdminContactInfoError }) => {  
  // #region State  
  // #endregion

  // #region Ref
  // #endregion

  // #region Event handlers    
  
  // #endregion

  // #region Effects
  
  // #endregion

  // #region International messages  
  const messages = defineMessages({        
    fleet_admin_fallback_ari_contact_info: {
      id: "fleet_admin_fallback_ari_contact_info",
      description: "If you do not accept these changes, you may forward this PO for further review \nor call Holman technician.",
      defaultMessage: "If you do not accept these changes, you may forward this PO for further review \nor call Holman technician."
    },
    fleet_admin_fallback_contact_info: {
      id: "fleet_admin_fallback_contact_info",
      description: "If you do not accept these changes, you may forward this PO for further review \nor call Holman technician.",
      defaultMessage: "If you do not accept these changes, you may forward this PO for further review \nor call Holman technician."
    }
  });
  // #endregion

  // #region Render helpers
  let formatPhoneNumber = (phoneNumberStr) => {
    //Filter only numbers from the input
    let cleaned = ('' + phoneNumberStr).replace(/\D/g, '');
    
    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    
    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    
    return null;
  }

  const renderFleetAdminContactInfo = (messageFor, fleetAdminContactInfo) => {

    const messages = defineMessages({        
      fleet_admin_contact_info: {
        id: "fleet_admin_contact_info",
        description: "If you do not accept these changes, you may forward this PO for further review \nor call {fleetAdminName} at {fleetAdminPhone}.",
        defaultMessage: "If you do not accept these changes, you may forward this PO for further review \nor call {fleetAdminName} at {fleetAdminPhone}."
      },
      fleet_admin_contact_info_issue_note: {
        id: "fleet_admin_contact_info_issue_note",
        description: "If you any questions regarding this client/notes request, you may forward \nthis PO for further review or call {fleetAdminName} at {fleetAdminPhone}.",
        defaultMessage: "If you any questions regarding this client/notes request, you may forward \nthis PO for further review or call {fleetAdminName} at {fleetAdminPhone}."
      }
    });

    const fleetAdminName = fleetAdminContactInfo.name;
    const fleetAdminPhone = formatPhoneNumber(fleetAdminContactInfo.phoneNumber);

    switch (messageFor) {
        case FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.APPROVED_LINEITEM:
        case FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.ADJUSTED_LINEITEM:
        case FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.NEW_LINEITEM:
        case FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.NEED_MORE_NOTES:
            return <FleetAdminContactInfoNonRejectedLineItemText> <FormattedMessage 
            values={{ fleetAdminName: fleetAdminName, fleetAdminPhone: fleetAdminPhone}} 
            {...messages.fleet_admin_contact_info} /></FleetAdminContactInfoNonRejectedLineItemText>;
        case FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.REJECTED_LINEITEM:
              return <FleetAdminContactInfoRejectedLineItemText> <FormattedMessage 
              values={{ fleetAdminName: fleetAdminName, fleetAdminPhone: fleetAdminPhone}} 
              {...messages.fleet_admin_contact_info} /></FleetAdminContactInfoRejectedLineItemText>;
        case FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.ISSUE_NOTE:
            return <FleetAdminContactInfoIssueNoteText> <FormattedMessage 
            values={{ fleetAdminName: fleetAdminName, fleetAdminPhone: fleetAdminPhone}}  
            {...messages.fleet_admin_contact_info_issue_note} /></FleetAdminContactInfoIssueNoteText>;
        default:
          return <FleetAdminContactInfoIssueNoteText> <FormattedMessage 
            values={{ fleetAdminName: fleetAdminName, fleetAdminPhone: fleetAdminPhone}}  
            {...messages.fleet_admin_contact_info} /></FleetAdminContactInfoIssueNoteText>;            
      }
  }
  
  // #endregion  

  // #region Render
  return (    
    <div>
       {
         <FleetAdminContactInfoText>
           {
              fleetAdminContactInfo != null ? renderFleetAdminContactInfo(messageFor, fleetAdminContactInfo) 
                : fleetAdminContactInfoError != null ? <FleetAdminContactInfoIssueNoteText> <FormattedMessage {...messages.fleet_admin_fallback_ari_contact_info} /></FleetAdminContactInfoIssueNoteText>
                  : <FleetAdminContactInfoIssueNoteText> <FormattedMessage {...messages.fleet_admin_fallback_contact_info} /></FleetAdminContactInfoIssueNoteText>
            }
         </FleetAdminContactInfoText>
       }
    </div> 
  );
  // #endregion
};
// #endregion
// #region Props
// #endregion

export default FleetAdminContactInfo;