import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { emptyMapIfUndefined, falseIfUndefined, emptyStringIfUndefined } from 'utils/HelperFunctions';
import * as constants from 'constants/App';
import moment from 'moment';

const requestIdSelector = (state) => {
    return emptyStringIfUndefined(state.appState.getIn(['uiData', 'vendorEnrollmentView', 'referenceNumber']));
}

const isLiabilitySelectedSelector = (state) => {
    let enrollmentInfo = state.appState.getIn(['uiData', 'vendorEnrollmentView', 'enrollmentDetailsInfo']);
    let selectecTaxClassification = enrollmentInfo.get('selectedTaxClassification');
    return selectecTaxClassification == "L";
}

//get form validation details
const formValidationDetailsSelector = (state) => {
    let enrollmentInfo = state.appState.getIn(['uiData', 'vendorEnrollmentView', 'enrollmentDetailsInfo']);
    let liabilityTaxClassification = state.appState.getIn(['uiData', 'vendorEnrollmentView', 'liabilityTaxClassification']);
    let country=state.appState.getIn(['uiData','vendorEnrollmentRequestView','enrollmentRequestInfo','country']);
    let fieldsToValidate = ['businessName',
        'contactName',
        'termsReadAndAgreed',
        //'selectedTaxClassification',
        'businessNameDba',
        'ariStandardRate'
    ];

    if(country=='USA') fieldsToValidate.push('selectedTaxClassification');
    
    //required fields should be populated
    let inValidFields = fieldsToValidate.reduce((p, c) => {       
       
        if(c == 'termsReadAndAgreed' && (enrollmentInfo.get(c)==undefined || !enrollmentInfo.get(c)))
            return p.concat(c);
        else if (c != 'termsReadAndAgreed' && emptyStringIfUndefined(enrollmentInfo.get(c)).trim() == '')
            return p.concat(c);
        else
            return p;
    }, []);   
    

    if(country=='USA')
    {
        //check if liability tax classification is filled when liabitlity tax is selected
        if(enrollmentInfo.get('selectedTaxClassification') == 'L'
            && !liabilityTaxClassification.includes(enrollmentInfo.get('liabilityTaxClassification'))){
            inValidFields = inValidFields.concat('liabilityTaxClassification');
        }

        //either SSN or EmployerId is required
        let ssn = emptyStringIfUndefined(enrollmentInfo.get('ssnArea') + enrollmentInfo.get('ssnGroup') + enrollmentInfo.get('ssnSerial'));
        let employerId = emptyStringIfUndefined(enrollmentInfo.get('employerIdFirstPart') + enrollmentInfo.get('employerIdSecondPart'));

        if ((isNaN(ssn) && isNaN(employerId)
            || (ssn == '' && employerId == '')
            || (ssn.length == 0 && employerId.length < 9)
            || (ssn.length < 9 && employerId.length == 0)
            || (ssn.length > 0 && employerId.length > 0)
            )) {

            inValidFields = inValidFields.concat('ein');
        }
    }
    else if(country=='CAN'){
        let businessNumber=emptyStringIfUndefined(enrollmentInfo.get('businessNumber'));
        if(isNaN(businessNumber) || businessNumber==''|| businessNumber.length<9)
            inValidFields=inValidFields.concat('businessNumber');
    }
    

     if(enrollmentInfo.get('ariStandardRate') < 9.99 || isNaN(enrollmentInfo.get('ariStandardRate')) ||enrollmentInfo.get('ariStandardRate') == undefined || enrollmentInfo.get('ariStandardRate') == ''){
         inValidFields = inValidFields.concat('ariStandardRate');
     }

     if(enrollmentInfo.get('bodyShopRate') < 9.99 && !isNaN(enrollmentInfo.get('bodyShopRate')) && enrollmentInfo.get('bodyShopRate') != undefined && enrollmentInfo.get('bodyShopRate') != ''){         
         inValidFields = inValidFields.concat('bodyShopRate');
     }
     if(enrollmentInfo.get('mobileRate') < 9.99 && !isNaN(enrollmentInfo.get('mobileRate')) && enrollmentInfo.get('mobileRate') != undefined && enrollmentInfo.get('mobileRate') != ''){         
         inValidFields = inValidFields.concat('mobileOnsiteRate');
     }
     if(enrollmentInfo.get('equipmentRate') < 9.99 && !isNaN(enrollmentInfo.get('equipmentRate')) && enrollmentInfo.get('equipmentRate') != undefined && enrollmentInfo.get('equipmentRate') != ''){         
         inValidFields = inValidFields.concat('equipmentAddRate');
     }
     if(enrollmentInfo.get('lightDutyRate') < 9.99 && !isNaN(enrollmentInfo.get('lightDutyRate')) && enrollmentInfo.get('lightDutyRate') != undefined && enrollmentInfo.get('lightDutyRate') != ''){         
         inValidFields = inValidFields.concat('lightDutyRate');
     }
     if(enrollmentInfo.get('mediumDutyRate') < 9.99 && !isNaN(enrollmentInfo.get('mediumDutyRate')) && enrollmentInfo.get('mediumDutyRate') != undefined && enrollmentInfo.get('mediumDutyRate') != ''){         
         inValidFields = inValidFields.concat('mediumDutyRate');
     }
     if(enrollmentInfo.get('heavyDutyRate') < 9.99 && !isNaN(enrollmentInfo.get('heavyDutyRate')) && enrollmentInfo.get('heavyDutyRate') != undefined && enrollmentInfo.get('heavyDutyRate') != ''){         
         inValidFields = inValidFields.concat('heavyDutyRate');}

    if(enrollmentInfo.get('trailerRate') < 9.99 && !isNaN(enrollmentInfo.get('trailerRate')) && enrollmentInfo.get('trailerRate') != undefined && enrollmentInfo.get('trailerRate') != ''){         
         inValidFields = inValidFields.concat('trailerRate');
     }
    
    return {
        isFormValid: inValidFields == undefined || inValidFields.length == 0,
        inValidFields: inValidFields
    }
}

const VendorEnrollmentView = createSelector(
    requestIdSelector,
    isLiabilitySelectedSelector,
    formValidationDetailsSelector,
    (requestId, isLiabilitySelected, formValidationDetails) => {
        
        return {
            requestId, isLiabilitySelected, formValidationDetails
        };
    }
);

export default VendorEnrollmentView;