// #region Imports
import React from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage } from "react-intl";
// #endregion

// #region Styled Components
const MainStyled = styled.div`
	padding-top: 1rem;	
  padding-bottom: 1rem;	
`;

const StoresWrapperStyled = styled.div`
  display: flex;	
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  height: 100px;
  max-height: 100px;
  overflow-y: scroll;
`;

const NoStores = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;  
`;

const StoreStyled = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  white-space: nowrap;  
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  color: black;
  font-size: 16px;
`;

const RemoveStoreStyled = styled.img`
  cursor:pointer;
`;
// #endregion

// #region Component
const SelectedStores = ({ selectedStores, allowRemovingStore, onRemoveStore }) => {  
  // #region State
  // #endregion

  // #region Ref
  // #endregion

  // #region Effects
  // #endregion

  // #region Event handlers
  // #endregion

  // #region International messages
  const messages = defineMessages({    
    select_stores_none: {
      id: "select_stores_none",
      description: "No stores selected",
      defaultMessage: "No stores selected"
    }
  });
  // #endregion

  // #region Render helpers
  // #endregion  

  // #region Render
  return (
    <MainStyled>               

      <StoresWrapperStyled>
        {!selectedStores || selectedStores.length === 0 &&
          <NoStores><FormattedMessage {...messages.select_stores_none} /></NoStores>
        }

        {selectedStores.map((store) => {
          return (
            <StoreStyled key={store.id}>
              {store.id} {' '} 
              { allowRemovingStore &&
                <RemoveStoreStyled                    
                  src="/assets/VMS_28button_kill_clear.png"
                  onClick={()=>onRemoveStore(store.id)}
                />
              }          
            </StoreStyled>
          );
        })}        
      </StoresWrapperStyled>        
      
    </MainStyled>
  );
  // #endregion
};
// #endregion

export default SelectedStores;
