import { createSelector } from 'reselect';
import { emptyMapIfUndefined, falseIfUndefined, emptyStringIfUndefined } from 'utils/HelperFunctions';
import { Map, fromJS } from 'immutable';
import * as constants from 'constants/App';


const servicesSelector =
    state => emptyMapIfUndefined(fromJS(state.appState.getIn(['uiData', 'registrationView', 'vendorServiceMap', 'services'])));

const vendorMinority =
    state => emptyMapIfUndefined(fromJS(state.appState.getIn(['uiData', 'registrationView', 'vendorMinorityMap', 'minorityVendor'])));

const getServicesSelector = createSelector(servicesSelector, (services) => {
    let vendorProfileServices = services.map((section) => {
        return section
    }).sort((a, b) => a.get("description").localeCompare(b.get("description")));
    return emptyMapIfUndefined(vendorProfileServices);
});

const getMinoritySelector = createSelector(vendorMinority, (services) => {
    let minority = services.map((section) => {
        if (section.get("description") != undefined)
            return section;
        else
            return;
    }).sort((a, b) => a.get("description").localeCompare(b.get("description")));

    return emptyMapIfUndefined(minority);
});

const getMinoritySelected = createSelector(getMinoritySelector, (selected) => {
    return selected.filter((visible) => {
        return visible.get('isServiceOffered') === true;
    });
});

const formDataSelector = state => {
    const formData = state.appState.getIn(['uiData', 'registrationView', 'formData']);
    const selectedCountry=emptyStringIfUndefined(state.appState.getIn(['uiData', 'shared', 'selectedCountry']));
    const ssn = emptyStringIfUndefined(formData.get(constants.FORM_DATA.SSN_AREA))
        + emptyStringIfUndefined(formData.get(constants.FORM_DATA.SSN_GROUP))
        + emptyStringIfUndefined(formData.get(constants.FORM_DATA.SSN_SERIAL));
    const employerId = emptyStringIfUndefined(formData.get(constants.FORM_DATA.EMPLOYER_ID_FIRST_PART))
        + emptyStringIfUndefined(formData.get(constants.FORM_DATA.EMPLOYER_ID_SECOND_PART));
    const country=emptyStringIfUndefined(formData.get(constants.FORM_DATA.COUNTRY));
    
    if(country==null||country=='')    
   return formData.set(constants.FORM_DATA.COUNTRY,selectedCountry==null||selectedCountry==''?'USA':selectedCountry).set(constants.FORM_DATA.SSN, ssn)
        .set(constants.FORM_DATA.EMPLOYER_ID, employerId)
    else return formData.set(constants.FORM_DATA.SSN, ssn)
        .set(constants.FORM_DATA.EMPLOYER_ID, employerId);        
}

const genericErrSelector = state => state.appState.getIn(['uiData', 'shared', 'errorMessage'])
const accountInfoErrSelector = createSelector(
    genericErrSelector,
    state => state.appState.getIn(['uiData', 'registrationView', 'accountInfoErrorMessage']),
    (genericErr, accountInfoErr) => {
        return accountInfoErr == undefined || accountInfoErr.length ==0 ? genericErr : accountInfoErr;
    }
)

const bankingInfoErrSelector = createSelector(
    genericErrSelector,
    state => state.appState.getIn(['uiData', 'registrationView', 'bankInfoErrorMessage']),
    (genericErr, bankingInfoErr) => {
        return bankingInfoErr == undefined || bankingInfoErr.length ==0 ? genericErr : bankingInfoErr;
    }
)

const serviceProfileErrSelector = createSelector(
    genericErrSelector,
    state => state.appState.getIn(['uiData', 'registrationView', 'fetchingServiceErrorMessage']),
    state => state.appState.getIn(['uiData', 'registrationView', 'serviceInfoErrorMessage']),
    (genericErr, fetchErr, submitErr) => {
        if (fetchErr == undefined || fetchErr.length==0) {
            if (submitErr == undefined || submitErr.length==0)
                return genericErr
            else
                return submitErr
        }
        else
            return fetchErr;
    }
)

const authEmailNotificationErrSelector = createSelector(
    genericErrSelector,
    state => state.appState.getIn(['uiData', 'registrationView', 'authorizationEmailConfigErrormessage']),
    (genericErr, authEmailNotifyErr) => {
        return authEmailNotifyErr == undefined || authEmailNotifyErr.length==0? genericErr : authEmailNotifyErr;
    }
)

const RegistrationView = createSelector(
    getServicesSelector,
    getMinoritySelected,
    getMinoritySelector,
    formDataSelector,
    genericErrSelector,
    accountInfoErrSelector,
    bankingInfoErrSelector,
    serviceProfileErrSelector,
    authEmailNotificationErrSelector,
    (
        vendorServices,
        minoritySelected,
        vendorMinority,
        formData,
        genericErr,
        accountInfoErrorMessage,
        bankInfoErrorMessage,
        serviceInfoErrorMessage,
        authorizationEmailConfigErrormessage,
    ) => {
        return {
            vendorServices,
            minoritySelected,
            vendorMinority,
            formData,
            genericErr,
            accountInfoErrorMessage,
            bankInfoErrorMessage,
            serviceInfoErrorMessage,
            authorizationEmailConfigErrormessage,
        };
    }
);

export default RegistrationView;

