//libraries
import React from 'react';
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
//our stuff
import { LINE_ITEM_TYPE, LINE_ITEM_STATUS, FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR, ENTITY_FOR_ASSISTANCE } from 'constants/App';
import '../containers/ReviewActionRequiredItemView.css';
import './AdjustedLineItem.css';
import Notes from './Notes.js';
import { useAppState } from 'shared/AppState/app-state';
import FleetAdminContactInfo from 'shared/components/FleetAdminContactInfo';

const NewLineItem = (props) => {

    const [appState, dispatch] = useAppState();


    const onAcceptClick = (lineItemId, e) => {
        props.onAcceptNewLineClickHandler(lineItemId);
        props.closeModalHandler();
    }

    const messages = defineMessages({
        adjusted_line_item_view_quantity: {
            id: 'adjusted_line_item_view_quantity',
            description: 'Qty',
            defaultMessage: 'Qty'
        },
        adjusted_line_item_view_hour: {
            id: 'adjusted_line_item_view_hour',
            description: 'Hour',
            defaultMessage: 'Hour'
        },
        adjusted_line_item_view_cost: {
            id: 'adjusted_line_item_view_cost',
            description: 'Cost',
            defaultMessage: 'Cost'
        },
        adjusted_line_item_view_amount: {
            id: 'adjusted_line_item_view_amount',
            description: 'Amount',
            defaultMessage: 'Amount'
        },
        adjusted_line_item_view_submitted: {
            id: 'adjusted_line_item_view_submitted',
            description: 'Submitted',
            defaultMessage: 'Submitted'
        },
        adjusted_line_item_view_ari_adjustment: {
            id: 'adjusted_line_item_view_ari_adjustment',
            description: 'Holman Adjustment',
            defaultMessage: 'Holman Adjustment'
        },
        adjusted_line_item_view_ari_created: {
            id: 'adjusted_line_item_view_ari_created',
            description: 'Created by Holman',
            defaultMessage: 'Created by Holman'
        },

        adjusted_line_item_view_cancel_po: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        },
        adjusted_line_item_view_cancel_po_confirmation_no: {
            id: 'cancel_po_confirmation_no',
            description: 'No',
            defaultMessage: 'No'
        },
        adjusted_line_item_view_cancel_po_confirmation_yes: {
            id: 'cancel_po_confirmation_yes',
            description: 'Yes',
            defaultMessage: 'Yes'
        },
        adjusted_line_item_view_cancel_po_confirmation_title: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        },
        adjusted_line_item_view_cancel_po_confirmation_message: {
            id: 'cancel_po_confirmation_message', description: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?',
            defaultMessage: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?'
        },
        close_dialog: {
            id: 'close_dialog',
            description: '  Close',
            defaultMessage: 'Close'
        },
        action_required_issue_note: {
            id: 'action_required_issue_note',
            description: 'If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician'
        },
        action_required_issue_note_can: {
            id: 'action_required_issue_note_can',
            description: 'If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician'
        },
        new_line_item_status_description: {
            id: 'new_line_item_status_description',
            description: '{productDescription} was created by Holman',
            defaultMessage: '{productDescription} was created by Holman'
        },
        new_line_item_reviewed_description: {
            id: 'new_line_item_reviewed_description',
            description: "You've reviewed this line",
            defaultMessage: "You've reviewed this line"
        },
        new_line_item_created_by_ari: {
            id: 'new_line_item_created_by_ari',
            description: "Created by Holman",
            defaultMessage: "Created by Holman"
        },
        action_required_note_from_ari_description: {
            id: 'action_required_note_from_ari_description',
            description: 'Note from Holman',
            defaultMessage: 'Note from Holman'
        },
        new_line_item_accept_description: {
            id: 'new_line_item_accept_description',
            description: 'new_line_item_accept_description',
            defaultMessage: 'new_line_item_accept_description'
        },
        new_line_window_title: {
            id: "new_line_window_title",
            description: "Please Review",
            defaultMessage: "Please Review"
        },
        add_product_code_instruction: {
            id: "add_product_code_instruction",
            description: "These lines have been added by a Holman technician, but do not have product codes. Please follow the steps to add product code.",
            defaultMessage: "These lines have been added by a Holman technician, but do not have product codes. Please follow the steps to add product code."
        }
    });




    const lineItem = props.lineItem;
    const productDescription = ( appState.isProductCodeFeatureEnabled && lineItem.get('vendorProductCode') !== undefined && lineItem.get('vendorProductCode') !== null ? lineItem.get('vendorProductCode') + ' - ' : '' ) + lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase());
    const submittedQty = lineItem.get('previousQuantity') != null ? lineItem.get('previousQuantity') : lineItem.get('quantity'); //if previous value is null, that means Holman tech did not adjust this field
    const submittedCost = lineItem.get('previousUnitPrice') != null ? lineItem.get('previousUnitPrice') : lineItem.get('unitPrice');
    const submittedTotal = submittedQty * submittedCost;
    const ariQty = lineItem.get('quantity');
    const ariCost = lineItem.get('unitPrice');
    const ariTotal = lineItem.get('totalAmount');
    const hasAdjustment = lineItem.get('previousQuantity') != null || lineItem.get('previousUnitPrice') != null;
    const lineNotes = lineItem.get('authorizationStatusReason');
    const showAddProductCodeInstruction = appState.isMissingProductCodeWorkFlowEnabled && ( lineItem.get('vendorProductCode') == undefined || lineItem.get('vendorProductCode') == null );


    return <div className="add-new-line-items">
        <div className="row action_required_line_item__item1 add-new-line-items__close">
            <div onClick={props.closeModalHandler}>
                <img className="add-new-line-items__close-button"
                    src="/assets/VMS_33button_kill_blue.png" />
                {"  "} {<FormattedMessage {...messages.close_dialog} />}
            </div>
        </div>
        <div className='action_required_line_item__item1 add-new-line-items_step-1-2-3-container'>
            <div className="row add-new-line-items__caption">
                <FormattedMessage {...messages.new_line_window_title} />

            </div>
        </div>
        {lineItem.get('reviewCompleted') ?
            <div className="action_required_line_item__item1">
                <div className="row action_required_line_item__header">
                    <FormattedMessage {...messages.new_line_item_reviewed_description} /><img className="adjusted_line_item__img_reviewed"
                        src="/assets/greenOK.png" />
                </div>
            </div> : undefined}
        <div className="add-new-line-items__search-ata-codes_border">
            <div className="action_required_line_item">

                <div className="action_required_line_item__lines">
                    <Row>
                        <Col sm={12} className="action_required_line_item__header_2 action_required_line_item__center">

                            <FormattedMessage values={{ productDescription: productDescription }} {...messages.new_line_item_status_description} />

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={10}>
                            <Row className="action_required_line_item">
                                <Col sm={2}></Col>

                                <Col sm={5} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage  {...messages.adjusted_line_item_view_quantity} />
                                </Col>
                                <Col sm={2} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage {...messages.adjusted_line_item_view_cost} />
                                </Col>
                                <Col sm={2} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage {...messages.adjusted_line_item_view_amount} />
                                </Col>
                                <Col sm={2}></Col>
                            </Row>

                            <Row className="action_required_line_item__ari_created_row">
                                <Col sm={2}></Col>
                                <Col sm={3} className="action_required_line_item__item action_required_line_item__row_bottom_last">
                                    <FormattedMessage {...messages.new_line_item_created_by_ari} /></Col>
                                <Col sm={2}
                                    className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                    {submittedQty}
                                </Col>
                                <Col sm={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={submittedCost} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>

                                </Col>
                                <Col sm={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={submittedTotal} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>
                                </Col>
                                <Col sm={2}></Col>
                            </Row>
                        </Col>
                        <Col sm={1}></Col>
                    </Row>

                    <Row className="row">
                        <Col className="action_required_line_item__header_2" sm={12}>
                            <FormattedMessage {...messages.action_required_note_from_ari_description} /></Col></Row>
                    <Row>
                        <Col sm={12} className="action_required_line_item__item1">
                            <Notes text={lineNotes} />
                        </Col>
                    </Row>

                    {showAddProductCodeInstruction &&
                        <Row>
                            <Col sm={12} className="action_required_line_item__item1">
                                <FormattedMessage {...messages.add_product_code_instruction} />
                            </Col>
                        </Row>
                    }

                    <Row>
                        <Col sm={10}></Col>
                        <Col sm={2} className="action_required_line_item__item">

                        </Col>
                    </Row>

                </div>
            </div>
        </div>
        <div className="action_required_line_item__item add-new-line-items__save" onClick={() => onAcceptClick(lineItem.get('id'))}>
            <FormattedMessage {...messages.new_line_item_accept_description} /> <img src="/assets/VMS_33button_go_bblue.png" />

        </div>
        {
            props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ? <FleetAdminContactInfo messageFor={FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.NEW_LINEITEM}  fleetAdminContactInfo={props.fleetAdminContactInfo} fleetAdminContactInfoError={props.fleetAdminContactInfoError} /> :
            <div className="action_required__issue_note">
            {
                props.selectedCountry == 'USA' ? <FormattedMessage {...messages.action_required_issue_note} /> :
                    <FormattedMessage {...messages.action_required_issue_note_can} />
            }
            </div>
        }
    </div>
        ;
}


export default NewLineItem;