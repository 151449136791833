import {Map,fromJS} from 'immutable';
import { normalize, arrayOf } from 'normalizr';
import * as sharedActions from 'actions/App';
import * as constants from 'constants/App';

export function toggleShowAllServiceInProgressPOs() {
    return {type: constants.TOGGLE_SHOW_ALL_SERVICE_IN_PROGRESS_POS}
}

export function toggleShowAllPaymentInProgressPOs() {
    return {type: constants.TOGGLE_SHOW_ALL_PAYMENT_IN_PROGRESS_POS}
}

export function toggleShowAllPaidPOs() {
    return {type: constants.TOGGLE_SHOW_ALL_PAID_POS}
}

export function searchVendorPOsLoaded(vendorId, searchFilters) {
    return {type: constants.SEARCH_VENDOR_POS_LOADED, vendorId, searchFilters}
}