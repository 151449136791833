import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import './FeedbackFormComponent.css';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {Map} from 'immutable';
import Recaptcha from '../../../../shared/components/Recaptcha';
import classnames from "classnames";

class FeedbackFormComponent extends React.Component {

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    focusPhone(number, event) {

        if (event.target.value.length >= 3) {
            number += 1;
            document.getElementById(number.toString()).focus();

        }


    }

    onSave() {

       let feedBackData = Map({
            message: this.refs.feedback.value,
            name: this.refs.nameFeedback.value,
            company: this.refs.companyFeedback.value,
            email: this.refs.emailFeedback.value,
            phone : this.props.login == true ? '':
            this.refs.areaCode.value + this.refs.phonePre.value + this.refs.phoneSuf.value,
            extension: this.props.login == true ? '' : this.refs.extens.value
        });

        this.props.onSaveForm(feedBackData);

    }


    render() {
        const messages = defineMessages({
            name_header: {
                id: 'name_header',
                description: 'Name field header',
                defaultMessage: 'Name'
            },
            company_header: {
                id: 'company_header',
                description: 'company field header',
                defaultMessage: 'Company (Optional)'
            },
            email_header: {
                id: 'email_header',
                description: 'email field header',
                defaultMessage: 'Email'
            },
            phone_header: {
                id: 'phone_header',
                description: 'phone field header',
                defaultMessage: 'Phone'
            },
            ext_header: {
                id: 'ext_header',
                description: 'extension field header',
                defaultMessage: 'Ext. (Optional)'
            },
            send_message_header: {
                id: 'send_message_header',
                description: 'Header of send a message form',
                defaultMessage: 'Send a message'
            },
            return: {
                id: 'return',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            send: {
                id: 'send',
                description: 'Send button',
                defaultMessage: 'Send'
            }
        });

        return <div className="feedback-container">

            <h3 className="feedback-header__format">
                <FormattedMessage {...messages.send_message_header} />
            </h3>

            <form  name="Form" ref="feedbackForm"
                   onSubmit={this.props.onSubmitter}>
                <div >
                    <textarea className="message-box__width" name="feedback" ref="feedback" required
                    onBlur={this.onSave.bind(this)} maxLength="1000" />
                </div>
                <br/>
                <div className="feedback-group__format">
                <div className="formatted-messages_format">
                    <FormattedMessage {...messages.name_header} />
                    </div>
                    <input className="feedback-box__input" type="text" name="nameFeedback" ref="nameFeedback" required
                           onBlur={this.onSave.bind(this)} />
                    <br/>
                </div>
                <div className="feedback-group__company">
                    <div className="formatted-messages_format">
                    <FormattedMessage {...messages.company_header} />
                     </div>
                    <input className="feedback-box__company" type="text" name="company" ref="companyFeedback" 
                           onBlur={this.onSave.bind(this)}   />
                    <br/>
                </div>
                <div className="feedback-wrapper__wrap">
                    <div className="feedback-group__format">
                        <div className="formatted-messages_format">
                        <FormattedMessage {...messages.email_header} />
                            </div>
                        <input type="email" name="email" ref="emailFeedback" required className="feedback-box__input"
                               onBlur={this.onSave.bind(this)}  />
                        <br/>
                    </div>

                    { this.props.login == true ? <div></div> :
                        <form className="feedback-div__float-right">
                                <div className="feedback-div__float-left">
                                    <div className="formatted-messages_format">
                                        <FormattedMessage {...messages.phone_header} />
                                    </div>
                                    <input className="feedback-area-phone__format"
                                           type="text" name="area" maxLength="3" pattern="[0-9]" ref="areaCode"
                                           onChange={this.focusPhone.bind(this, 1)} id="1"
                                           onBlur={this.onSave.bind(this)}/> -
                                </div>

                                <div className="feedback-div__float-left">
                                    <input className="feedback-prefix-phone__format"
                                           type="text" name="prefix" maxLength="3" pattern="[0-9]" ref="phonePre"
                                           onChange={this.focusPhone.bind(this, 2)} id="2"
                                           onBlur={this.onSave.bind(this)}/> -
                                </div>

                                <div className="feedback-div__float-left">
                                    <input className="feedback-suffix-phone__format"
                                           type="text" name="suffix" maxLength="4" pattern="[0-9]" ref="phoneSuf" id="3"
                                           onBlur={this.onSave.bind(this)}/>
                                </div>

                                <div className="feedback-div__float-left" >
                                    <div className="feedback-phone__space_left">
                                    <div className="formatted-messages_format">
                                        <FormattedMessage {...messages.ext_header} />
                                    </div>
                                        <input className="feedback-ext-phone__format" type="text" name="ext"
                                               ref="extens"
                                               onBlur={this.onSave.bind(this)}/>
                                        </div>
                                </div>

                        </form>}
                </div>
                {this.props.login === true ? <div className="g-recaptcha-outer"/> :
                    <div className="g-recaptcha-outer">
                        <Recaptcha reCaptchaKey={this.props.reCaptchaKey}
                                   reCaptchaVerificationToken={this.props.reCaptchaVerificationToken}/>

                    </div>}

                <div className="feedback-container__buttons">
                <button className="feedback-container__send"
                      type="submit" >
                    <FormattedMessage {...messages.send} />
                    {' '}
                    <img  src="/assets/VMS_33button_go_bblue.png"/>
                </button>
                <div className="feedback-container__return"
                     role="button"
                     onClick={this.onReturnClickHandler.bind(this)}>
                    <img src="/assets/VMS_33button_back_blue.png"/>
                    {' '}
                    <FormattedMessage {...messages.return}/>
                </div>
                </div>
            </form>
        </div>;

    }


}



export default FeedbackFormComponent;