import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage,FormattedNumber} from 'react-intl';
import {Map, fromJS} from 'immutable';
import './PaymentInProgress.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError,validateEmail,zeroIfUndefined } from 'utils/HelperFunctions';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import { IntlProvider } from 'react-intl';

class PaymentInProgress extends React.Component {

    getTooltip(toolTipText){
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }

    getVehicle(vin8,vehicleYear,vehicleMake,vehicleModel) {

        return <OverlayTrigger
            placement='right'
            key={vin8}
            overlay={this.getTooltip((vehicleYear == null ? '' : vehicleYear)
                + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))}
        >
            <div key={vin8}>
                {(vehicleYear + ' ' + vehicleMake + ' ' + vehicleModel).length > 10
                    ? ((vehicleYear == null ? '' : vehicleYear)
                    + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel)).substr(0, 9) + '...'
                    : ((vehicleYear == null ? '' : vehicleYear)
                    + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))
                }
            </div>
        </OverlayTrigger>

    }

    getFilteredRecords(poList, showAll) {
        var filteredRecords = poList.sortBy(po=>new Date(po.get('createdDate'))).reverse();

        if (showAll == false && filteredRecords.count() > 0)
            filteredRecords = filteredRecords.take(10);

        return filteredRecords;
    }


    getFooter(poList, filteredRecords) {

        const messages = defineMessages({
            show_all_waiting_pos: {
                id: 'show_all_waiting_pos',
                description: 'Link at bottom of PO List to show all POs',
                defaultMessage: 'Show all {poCount} purchase orders'
            },
            hide_older_pos: {
                id: 'hide_older_pos',
                description: 'Link at bottom of PO List to hide older approved POs',
                defaultMessage: 'Hide older purchase orders'
            }
        });

        // Don't show footer if we have 10 or less records
        if (filteredRecords.count() < 10)
            return undefined;
        // If we are not showing all, show button to show all
        else if (poList.count() > filteredRecords.count()) {
            return <div role="button" className="car-wash__footer">
                <FormattedMessage values={{poCount:poList.count()}}
                                  {...messages.show_all_waiting_pos}/>
                {' '}<img src="/assets/VMS_button_show_all_pos.png"/>
            </div>;
        }
        else {
            return <div role="button" className="car-wash__footer">
                <FormattedMessage {...messages.hide_older_pos}/>
                {' '}<img src="/assets/VMS_button_hide_older_pos.png"/>
            </div>;
        }
    }
    render() {

        const messages = defineMessages({
            payment_in_progress_caption: {
                id: 'payment_in_progress_caption',
                description: 'Caption for Payment in progress POs',
                defaultMessage: 'Payment in progress'
            },
            waiting_for_client_caption: {
                id: 'waiting_for_client_caption',
                description: 'Caption for waiting for client approval',
                defaultMessage: 'Waiting for client approval'
            },
            payment_in_progress_vehicle_column_header: {
                id: 'payment_in_progress_vehicle_column_header',
                description: 'Header for Vehicle Column',
                defaultMessage: 'Vehicle'
            },
            payment_in_progress_vin_column_header: {
                id: 'payment_in_progress_vin_column_header',
                description: 'Header for VIN Column',
                defaultMessage: 'VIN'
            },
            payment_in_progress_po_number_column_header: {
                id: 'payment_in_progress_po_number_column_header',
                description: 'Header for PO Number Column',
                defaultMessage: 'PO no.'
            },
            payment_in_progress_plate_number_column_header: {
                id: 'payment_in_progress_plate_number_column_header',
                description: 'Header for Plate Number Column',
                defaultMessage: 'Plate No.'
            },
            active_po_invoice_number_column_header: {
                id: 'active_po_invoice_number_column_header',
                description: 'Header for invoice # Column',
                defaultMessage: 'Invoice No.'
            },
            payment_in_progress_total_amount_column_header: {
                id: 'payment_in_progress_total_amount_column_header',
                description: 'Header for  Total Amount Column',
                defaultMessage: 'Total'
            },
            payment_in_progress_po_view_link_caption: {
                id: 'payment_in_progress_po_view_link_caption',
                description: 'Text for PO View link',
                defaultMessage: 'VIEW'
            },
            payment_in_progress_no_pos_caption: {
                id: 'payment_in_progress_no_pos_caption',
                description: 'Caption to display if no POs are available for display on Shop View',
                defaultMessage: 'None at this Time'
            },
            payment_in_progress_today: {
                id: 'payment_in_progress_today',
                description: 'Text for po date column when po date is Today',
                defaultMessage: 'Today'
            }

        });

        const filteredActivePOs = this.getFilteredRecords(this.props.activePOs, this.props.showAll);

        return <div className="car-wash-pip-po-list table-responsive">
            <div className="car-wash-pip-po-list__caption">
                {this.props.status === "WAITING" ? <FormattedMessage {...messages.waiting_for_client_caption}/>
                    :
                    <FormattedMessage {...messages.payment_in_progress_caption}/>
                }
            </div>
            <table className="car-wash-pip-po-list__table" width="100%">
                <thead>
                <tr>
                    <th className="car-wash-pip-po-list__header-cell">
                        <FormattedMessage {...messages.payment_in_progress_vehicle_column_header}/>
                    </th>
                    <th className="car-wash-pip-po-list__header-cell">
                        <FormattedMessage {...messages.payment_in_progress_vin_column_header}/>
                    </th>
                    <th className="car-wash-pip-po-list__header-cell">
                        <FormattedMessage {...messages.payment_in_progress_plate_number_column_header}/>
                    </th>
                    <th className="car-wash-pip-po-list__header-cell">
                        <FormattedMessage {...messages.active_po_invoice_number_column_header}/>
                    </th>
                    <th className="car-wash-pip-po-list__header-cell">
                        <FormattedMessage {...messages.payment_in_progress_po_number_column_header}/>
                    </th>
                    <th className="car-wash-pip-po-list__header-cell">
                        <FormattedMessage {...messages.payment_in_progress_total_amount_column_header}/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    (filteredActivePOs.count() === 0)
                        ?  <tr>
                        <td colSpan="6" className="car-wash-pip-po-list__cell pip-po-list__cell_align-center">
                            <FormattedMessage {...messages.payment_in_progress_no_pos_caption}/>
                        </td>
                    </tr>

                        :

                        filteredActivePOs.valueSeq().map(po =>
                            <tr key={po.get('id')} className="pip-po-list__row">
                                <td className="car-wash-pip-po-list__cell">
                                    {this.getVehicle(po.get('vehicleVIN8'),po.get('vehicleYear'),po.get('vehicleMake'),po.get('vehicleModel'))}
                                </td>
                                <td className="car-wash-pip-po-list__cell">
                                    {po.get('vehicleVIN8')}
                                </td>
                                <td className="car-wash-pip-po-list__cell">
                                    {po.get('licensePlate')}
                                </td>
                                <td className="car-wash-pip-po-list__cell">
                                    {po.get('invoiceNo')}
                                </td>
                                <td className="car-wash-pip-po-list__cell">
                                    {po.get('poNumber')}
                                </td>
                                <td className="car-wash-pip-po-list__cell">
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={po.get('totalAmount')}
                                                     style="currency"
                                                     currency={this.props.selectedCurrency}
                                                     minimumFractionDigits={2}/>
                                    </IntlProvider>
                                </td>
                            </tr>
                        )
                }
                </tbody>
            </table>
            <div onClick={this.props.footerClickHandler.bind(this)}>
                {this.getFooter(this.props.activePOs, filteredActivePOs)}
            </div>
        </div>;
    }
}


export default (PaymentInProgress);

