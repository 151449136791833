import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';



export function* recoverPasswordInformationForPersonaUser(action) {
    
    // Get state object
    try {
        yield put({ type: constants.SEND_RECOVER_PASSWORD_REQUESTED });

        const state = yield select();
        const ariID = state.appState.getIn(['uiData', 'recoverPassword', 'ARIId']);
        const personalInfo ={
            email:action.email
        } 

        const recoverPasswordEmail = yield call(Api.sendPersonaRecoverPasswordInformation,personalInfo);

        yield put({ type: constants.SEND_RECOVER_PASSWORD_APPROVED, recoverPasswordEmail });
        yield put({ type: constants.SEND_RECOVER_PASSWORD_RESOLVED });
        yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/confirmation/success' });
    }
    catch (e) {
        var customError = helperFunctions.getCustomErrorObject(e);
        var genericError = helperFunctions.getGenericErrorObject(e);
        var errorMesg = customError != undefined && customError.length > 0 ? customError[0].message : genericError != undefined && genericError.length > 0 ? genericError[0].message : undefined;
        yield put({ type: constants.SEND_RECOVER_PASSWORD_REJECTED, errorMessage: errorMesg });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SEND_RECOVER_PASSWORD_REQUESTED, errorObject: e });
        
        if (e.code == 'API-138') {
            yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/tooManyAttempts' });
        } else if (e.code == 'API-139') {
            yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/confirmation/mismatch' });
        } else if (e.code == 'API-051') {
            yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/confirmation/locked' });
        }
        else {
            yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/confirmation/error' });
        }
    }
}

export function* recoverPasswordInformation() {

    // Get state object
    try {
        yield put({ type: constants.SEND_RECOVER_PASSWORD_REQUESTED });

        const state = yield select();
        const ariID = state.appState.getIn(['uiData', 'recoverPassword', 'ARIId']);
        const personalInfo = state.appState.getIn(['uiData', 'recoverPassword', 'personal']);

        const recoverPasswordEmail = yield call(Api.sendRecoverPasswordInformation, ariID, personalInfo);

        yield put({ type: constants.SEND_RECOVER_PASSWORD_APPROVED, recoverPasswordEmail });
        yield put({ type: constants.SEND_RECOVER_PASSWORD_RESOLVED });
        yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/confirmation/success' });
    }
    catch (e) {
        var customError = helperFunctions.getCustomErrorObject(e);
        var genericError = helperFunctions.getGenericErrorObject(e);
        var errorMesg = customError != undefined && customError.length > 0 ? customError[0].message : genericError != undefined && genericError.length > 0 ? genericError[0].message : undefined;
        yield put({ type: constants.SEND_RECOVER_PASSWORD_REJECTED, errorMessage: errorMesg });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.SEND_RECOVER_PASSWORD_REQUESTED, errorObject: e });
        
        if (e.code == 'API-138') {
            yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/tooManyAttempts' });
        } else if (e.code == 'API-139') {
            yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/confirmation/mismatch' });
        } else if (e.code == 'API-051') {
            yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/confirmation/locked' });
        }
        else {
            yield put({ type: constants.NAVIGATE_TO, path: '/recoverPassword/confirmation/error' });
        }
    }
}