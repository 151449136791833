import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {fromJS}  from 'immutable';

export function serverDataShopView(state = initialAppState.getIn(['serverData','shopView']), action) {
    switch (action.type) {
        case constants.FETCH_SHOP_VIEW_DATA_RESOLVED:
            return state.set('activePOs', fromJS(action.activePOs));
        case constants.SAVE_BULLETIN_MESSAGE:
            return state.set('bulletinMessage', fromJS(action.currentBulletinMessage));
        default:
            return state;
    }
}

export function uiDataShopView(state = initialAppState.getIn(['uiData','shopView']), action) {
    switch (action.type) {
        case constants.SHOP_VIEW_DATA_REQUESTED:
            return state.set('isFetchingData', true);
        case constants.FETCH_SHOP_VIEW_DATA_RESOLVED:
        case constants.FETCH_SHOP_VIEW_DATA_REJECTED:
            return state.set('isFetchingData', false);
        case constants.TOGGLE_SHOW_ALL_APPROVED_POS:
            return state.set('showAllApprovedPOs', !state.get('showAllApprovedPOs'));
        case constants.TOGGLE_SHOW_ALL_WAITING_FOR_DRIVER_VALIDATION_POS:
            return state.set('showAllWaitingForDriverValidationPOs', !state.get('showAllWaitingForDriverValidationPOs'));
        case constants.TOGGLE_SHOW_ALL_WAITING_FOR_ARI_APPROVAL_POS:
            return state.set('showAllWaitingForARIApprovalPOs', !state.get('showAllWaitingForARIApprovalPOs'));
        case constants.TOGGLE_SHOW_ALL_WAITING_FOR_CLIENT_APPROVAL_POS:
            return state.set('showAllWaitingForClientAuthorizationPOs', !state.get('showAllWaitingForClientAuthorizationPOs'));
        case constants.TOGGLE_SHOW_ALL_VENDOR_ACTION_NEEDED_POS:
            return state.set('showAllVendorActionNeededPOs', !state.get('showAllVendorActionNeededPOs'));
        case constants.TOGGLE_SHOW_ALL_NEW_WORK_ASSIGNMENT_POS:
            return state.set('showAllNewWorkAssignmentPOs', !state.get('showAllNewWorkAssignmentPOs'));
        case constants.TOGGLE_SHOW_ALL_WORK_COMPLETED_POS:
            return state.set('showAllWorkCompletedPOs', !state.get('showAllWorkCompletedPOs'));
        default:
            return state;
    }
}