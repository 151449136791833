import { call, put, select, take, cancel, fork,all } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import { zeroIfUndefined, emptyMapIfUndefined,getSelectedLanguageCode,getFormattedPersonaUserName } from 'utils/HelperFunctions';
import { Schema, arrayOf, normalize } from 'normalizr';
import * as helperFunctions from 'utils/HelperFunctions';
import * as AddEditView from 'sagas/AddEditView';
import { LINE_ITEM_TYPE,INVOICE_FIELD_TYPE } from 'constants/App';
import { Map } from 'immutable';
import moment from 'moment';

const skippedComplaintCode = 'UN01';
const skippedComplaintDescription = 'NOT SUPPLIED';
const diagATACode = '53001010';
const diagATADescription = 'DIAGNOSIS';
const diagComplaintCode = 'DG99';
const maintenanceComplaintCode = 'MA99';
import * as enjs from '../../../../../../config/i18n/en';
import * as fieldValidations from 'utils/FieldValidations';


export function* fetchClosePOData(context, action) {
    try {
        
        const state = yield select();
        const token = helperFunctions.getToken(state);
        const countrySelected = state.appState.getIn(["uiData", "shared", "selectedCountry"]);
        const vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);
        var applicableTax;
        // Reset invoice details
        yield put({ type: constants.RESET_INVOICE_DETAILS });

        // Inform application that PO data is being fetched
        yield put({ type: constants.FETCH_CLOSE_PO_DATA_REQUESTED });
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });        
        

        const [purchaseOrder, lineItems, vehicle, vendor, complaints, notes] = yield all ([
            call(Api.fetchPurchaseOrder, action.poNumber, token),
            call(Api.fetchLineItems, action.poNumber, token,getSelectedLanguageCode(state)),
            call(Api.fetchVehicle, action.poNumber, token),
            call(Api.fetchVendorforClosePO, action.poNumber, token),
            call(Api.fetchComplaints, action.poNumber, token, state),
            call(Api.fetchComplaintNotes, action.poNumber, token)
        ]);   
          
        /*if(notes != undefined ) {
            for (var key in notes) {
                const createdBy = notes[key].createdBy;
                if (createdBy !== vendorId) {
                    notes[key].noteText = "Holman - " + notes[key].noteText.split("\n");
                }
            }
        } */        
        
        var productDescription='';
       
        if(notes != undefined ) {
            for (var key in notes) {                
                productDescription='';
                    for(var lineKey in lineItems){
                        if(lineItems[lineKey].id==notes[key].lineItemId)                        
                        productDescription=lineItems[lineKey].vendorProductCode == null ? lineItems[lineKey].productDescription +', '+ (lineItems[lineKey].lineItemType == constants.LINE_ITEM_TYPE.PM ? constants.LINE_ITEM_TYPE.PM_TEXT : lineItems[lineKey].lineItemType.toUpperCase()) :  lineItems[lineKey].vendorProductCode + ' - ' + lineItems[lineKey].productDescription +', '+ (lineItems[lineKey].lineItemType == constants.LINE_ITEM_TYPE.PM ? constants.LINE_ITEM_TYPE.PM_TEXT : lineItems[lineKey].lineItemType.toUpperCase());   
                    }
                    notes[key].vendorId=vendorId;
                    notes[key].productDescription=productDescription;

            }
        }

        const vehicleDetailsToShowOdometerWarnings = yield call(Api.fetchVehicleByVehicleId,  vehicle.id, token);
        const paymentType = state.appState.getIn(['serverData', 'shared', 'paymentType']);

        const isWorkCompletionFeatureEnabled= state.appState.getIn(['uiData', 'shared', 'features','workCompletion']);

        //If the work completion feature is enabled, then do not query for bank account information
        //If logged in vendor is different from PO vendor; then do not query for the bank account information
        if(!isWorkCompletionFeatureEnabled && purchaseOrder.vendorId==helperFunctions.getVendorId(state)) {
            const vendorBankAccountInformation = yield call(Api.fetchVendorBankAccountInformation,purchaseOrder.vendorId, purchaseOrder.vendorId + 'MAINTENANCE', helperFunctions.getToken(state));
            vendor.bankAccountNumber = vendorBankAccountInformation!=null?vendorBankAccountInformation.accountNumber:null;            
        }

        vendor.country = vendor.businessAddress.country;
        
        if ((purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.APPROVED || purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT) 
            && purchaseOrder.paymentStatus == constants.PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED) {


                applicableTax = yield  call(Api.fetchApplicableTax, vehicle.id, purchaseOrder.vendorId,token);


            // Prepare data
            const purchaseOrderData = {
                purchaseOrder: purchaseOrder,
                vehicleDetails: vehicle,
                vendor: vendor,
                complaints: complaints,
                notes: notes,
                lineItems: lineItems, 
                vehicleDetailsToShowOdometerWarnings: vehicleDetailsToShowOdometerWarnings,
                applicableTax:applicableTax != undefined ? applicableTax : null,
                vendorReferenceNo:purchaseOrder.vendorReferenceNo
            };
  
            let poInvoiceDetails = Map({
                key: constants.INVOICE_FIELD_TYPE.REPAIR_START_DATE,
                value: purchaseOrder.repairStartDate,

            });
     
            yield put({type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails});

            for (var key in lineItems) {
                 const correctionType = lineItems[key].correctionType;
                 if(correctionType === "SALESTAX") {
                     let poInvoiceDetails = Map({
                         key: INVOICE_FIELD_TYPE.TAX_AMOUNT,
                         value: lineItems !== null ? lineItems[key].totalAmount : '',
                         taxType: lineItems !== null ? lineItems[key].productDescription : '',

                     });

                     yield put({type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails});
                 }
            }


            let subTotal = purchaseOrderData.purchaseOrder.authorizedAmount + purchaseOrderData.purchaseOrder.pendingAmount;
            subTotal = subTotal == undefined ? 0 : subTotal;

            yield put({ type: constants.FETCH_CLOSE_PO_DATA_RESOLVED, purchaseOrderData, subTotal });
        }
        else {            
            yield put({ type: constants.FETCH_CLOSE_PO_DATA_REJECTED });

            if(helperFunctions.isCarWashVendor(state.appState.getIn(['serverData', 'shared', 'serviceClassification'])))
                yield call(context.history.push,'/carWash');
            else
                yield call(context.history.push,'/');
        }

         let poInvoiceDetails = Map({
            key: constants.INVOICE_FIELD_TYPE.ODOMETER,
            value: vehicle.lastOdometerReading
        });
        yield put( {type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails});

        poInvoiceDetails = Map({
            key: constants.INVOICE_FIELD_TYPE.ENGINE_HOURS,
            value: vehicle.lastHourMeterReading
        });
        
        yield put( {type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails});

        poInvoiceDetails = Map({
            key: constants.INVOICE_FIELD_TYPE.CLIENT_REF_NO,
            value: purchaseOrder.clientReferenceNo
        });
        yield put( {type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails});


        const personaUser=state.appState.getIn(['serverData','shared','personaUser']);        
        if (personaUser != undefined && personaUser != null) {
            let poInvoiceDetails = Map({
                key: constants.INVOICE_FIELD_TYPE.NAME,
                value: helperFunctions.getFormattedPersonaUserName(personaUser.get('firstName'), personaUser.get('lastName'))
                
            });
            yield put({ type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails });
            poInvoiceDetails = Map({
                key: "makeSubmittedByReadOnly",
                value: true                
            });
            yield put({ type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails });
        }
        else {
            let poInvoiceDetails = Map({
                key: "makeSubmittedByReadOnly",
                value: false
            });
            yield put({ type: constants.SAVE_INVOICE_DETAILS, poInvoiceDetails });
        }
    }
    catch (e) {
        yield put({
            type: constants.FETCH_CLOSE_PO_DATA_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });
        yield put({
            type: constants.SAVE_ERROR_INFORMATION,
            actionName: constants.FETCH_CLOSE_PO_DATA_REQUESTED,
            errorObject: e
        });
    }
}

export function* closePO(context, action) {
    const state = yield select();
    
    try {

        yield put({ type: constants.CLOSE_PO_REQUESTED });
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });

        const vendorCountry = state.appState.getIn(['serverData', 'close', 'vendor', 'country']);
        const token = helperFunctions.getToken(state);

        const repairCompletionDate  = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'repairCompletionDate']);
        const repairStartDate = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'repairStartDate']);
        const odometer = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'odometer']);
        const engineHours = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'engineHours']);
        const vendorReferenceNo = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'refNumber']);
        const isWorkCompletionFeatureEnabled = state.appState.getIn(['uiData', 'shared', 'features','workCompletion']);        
        const clientReferenceNo = state.appState.getIn(['uiData', 'close', 'invoiceDetails','clientReferenceNo']);

        // Update tax 
        if (fieldValidations.isVisible(state.appState.getIn(['uiData', 'shared', 'fieldValidations']),'TAX'))
        {
            const complaintCode = 'SA01';
            const correctionType = 'SALESTAX';
            const isTaxExempt = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'isTaxExempted']);
            const existingTaxLinesPoNumber = emptyMapIfUndefined(state.appState.getIn(['serverData', 'close', 'lineItems']))
                .filter(li => li.get('correctionType') === "SALESTAX");
            const HSTAmount = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'taxes', 'HST']);
            const GSTAmount = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'taxes', 'GST']);
            const PSTAmount = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'taxes', 'PST']);
            const QSTAmount = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'taxes', 'QST']);
            const usaAmount = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'taxes', 'tax']);

            const taxExemptReasonCode = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'exemptedReason'])
            const taxExemptReasonDescription = isTaxExempt ?
                taxExemptReasonCode == 'VehicleExempt' ?
                    'Interstate commerce vehicle exempt'
                    : taxExemptReasonCode == 'TaxExempt' ?
                       'Repair type is tax exempt'
                        : 'Unknown'
                : 'Unknown';
            const isInsurancePO = state.appState.getIn(['serverData', 'close', 'purchaseOrder', 'isInsurancePo']);

		// Check if documents are required.		
		const requiredDocuments = yield call(Api.fetchRequiredDocuments, action.closePoData.poNumber, token);				
		if (Array.isArray(requiredDocuments) && requiredDocuments.length > 0) {			
			for (var i = 0; i < requiredDocuments.length; i++) {				
				switch (requiredDocuments[i].displayName) {
					case "Department of Transportation Annual Vehicle Inspection":
						// Display DOT Inspection Form
						yield put({type: constants.CLOSE_PO__HIDE_ALL_POPOVERS});
						yield put({type: constants.DOT_INSPECTION__RESET});
						yield put({type: constants.DOT_INSPECTION__DISPLAY, show: true});
						return;
					case "Basic Inspection of Terminals":
						// Display BIT Inspection Form
						yield put({type: constants.CLOSE_PO__HIDE_ALL_POPOVERS});
						yield put({type: constants.BIT_INSPECTION__RESET});
						yield put({type: constants.BIT_INSPECTION__DISPLAY, show: true});
						return;
					case "Federal Railroad Administration Rail Gear Inspection Report":
						// Display FRA Inspection Form
						yield put({type: constants.CLOSE_PO__HIDE_ALL_POPOVERS});
						yield put({type: constants.FRA_INSPECTION__RESET});
						yield put({type: constants.FRA_INSPECTION__DISPLAY, show: true});
                        return;
                    case "ANSI Crane Inspection Report":
						// Display ANSI Crane Inspection Form
						yield put({type: constants.CLOSE_PO__HIDE_ALL_POPOVERS});
						yield put({type: constants.CRANE_INSPECTION__RESET});
						yield put({type: constants.CRANE_INSPECTION__DISPLAY, show: true});
						return;
				}				
			};
		}

            if (vendorCountry === 'USA') {
                yield* processTaxLine(isInsurancePO ? '88001A20' : '53999A01', usaAmount, isTaxExempt, existingTaxLinesPoNumber, complaintCode, correctionType, action.closePoData.poNumber, token, getSelectedLanguageCode(state), 'SALES TAX', 'TAX');
            } else {
                yield* processTaxLine(isInsurancePO ? '88001A38' : '53999A29', HSTAmount, isTaxExempt, existingTaxLinesPoNumber, complaintCode, correctionType, action.closePoData.poNumber, token, getSelectedLanguageCode(state), 'SALES TAX - HST', 'CANADA_SALES_TAX');
                yield* processTaxLine(isInsurancePO ? '88001A36' : '53999A30', GSTAmount, isTaxExempt, existingTaxLinesPoNumber, complaintCode, correctionType, action.closePoData.poNumber, token, getSelectedLanguageCode(state), 'SALES TAX - GST', 'CANADA_SALES_TAX');
                yield* processTaxLine(isInsurancePO ? '88001A35' : '53999A27', PSTAmount, isTaxExempt, existingTaxLinesPoNumber, complaintCode, correctionType, action.closePoData.poNumber, token, getSelectedLanguageCode(state), 'SALES TAX - PST', 'CANADA_SALES_TAX');
                yield* processTaxLine(isInsurancePO ? '88001A37' : '53999A28', QSTAmount, isTaxExempt, existingTaxLinesPoNumber, complaintCode, correctionType, action.closePoData.poNumber, token, getSelectedLanguageCode(state), 'SALES TAX - QST', 'CANADA_SALES_TAX');
            }

            if (vendorCountry == 'CAN' && isTaxExempt) {
                if (isWorkCompletionFeatureEnabled) {
                    const invoiceSubmittedBy = state.appState.getIn(['uiData', 'close', 'invoiceDetails', 'submittedBy']);
                    const notesData = {
                        noteText: `This PO is tax extempted due to ${taxExemptReasonDescription} and request submitted by ${invoiceSubmittedBy}`
                    }
                    yield call(Api.createPONotes, action.closePoData.poNumber, notesData, token);
                }
            }
        }
        
        // Update PO
        yield call(Api.updatePO, action.closePoData.poNumber,
            odometer === undefined ? null : odometer,
            engineHours === undefined ? null : engineHours,
            repairCompletionDate !== undefined && repairCompletionDate !== null ? moment(repairCompletionDate) : null,
            vendorReferenceNo === undefined ? null : vendorReferenceNo,
            repairStartDate !== undefined && repairStartDate !== null ? moment(repairStartDate) : null,
            clientReferenceNo === undefined ? null : clientReferenceNo,
            token);

        if(!isWorkCompletionFeatureEnabled)     
            // Close PO
            yield call(Api.closePO, action.closePoData.poNumber, state, helperFunctions.getToken(state));

        // Reset invoice details
        yield put({ type: constants.RESET_INVOICE_DETAILS });

        // Redirect to view PO screen
        yield call(context.history.push,`/PO/view/${action.closePoData.poNumber}`, {claimId: action.closePoData.claimId});

        yield put({ type: constants.CLOSE_PO_RESOLVED });

    }
    catch (e) { 

        if(e.code!=null && e.code=="PO-188")       {
            // Redirect to view PO screen
            yield call(context.history.push,`/PO/view/${action.closePoData.poNumber}`);

            yield put({ type: constants.CLOSE_PO_RESOLVED });
        }
        else
        {

        //Update state with the line items
        const lineItems=yield call(Api.fetchLineItems, action.closePoData.poNumber, helperFunctions.getToken(state),getSelectedLanguageCode(state));
        yield put({ type: constants.CLOSE_PO_FETCH_LINE_ITEMS, lineItems:lineItems });
    
        yield put({
            type: constants.CLOSE_PO_REJECTED,
            errorMessage: helperFunctions.getCustomErrorObject(e)
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
        });

        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CLOSE_PO_REQUESTED, errorObject: e });
        }
        
    }

}
function* processTaxLine(atacode,uiTaxAmount,isTaxExempt,existingTaxLinesPoNumber,complaintCode,correctionType,poNumber,token,selectedLanguage,ataDescription,lineItemType) {

    let taxLineId = existingTaxLinesPoNumber.filter(li => li.get('productCode') === atacode).size > 0 ? existingTaxLinesPoNumber.filter(li => li.get('productCode') === atacode).first().get('lineItemId') : undefined ;
    let serverTaxAmount = existingTaxLinesPoNumber.filter(li => li.get('lineItemId') === taxLineId).size > 0 ? existingTaxLinesPoNumber.filter(li => li.get('lineItemId') === taxLineId).first().get('totalAmount') : undefined;

        if ((uiTaxAmount === 0 || isTaxExempt ) && taxLineId !== undefined) {

            yield call(Api.deleteLineItem, poNumber, taxLineId, token);

        }
       else if (taxLineId !== undefined && uiTaxAmount !== serverTaxAmount) {
                yield call(Api.editLineItem, poNumber, taxLineId, 1, uiTaxAmount, token, selectedLanguage);
        }
       else if(taxLineId === undefined && uiTaxAmount > 0 && !isTaxExempt) {

            yield* AddEditView.addNewLineItem(1, uiTaxAmount, atacode, ataDescription, complaintCode, correctionType, lineItemType, undefined, undefined, undefined, poNumber, token);

        }

}
