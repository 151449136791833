import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map,fromJS } from 'immutable';

export function uiDataAddServiceLineItemsView(state = initialAppState.getIn(['uiData', 'addServiceLineItemsView']), action) {
    switch (action.type) {
       case constants.ADD_SERVICE_LINE_ITEM_REQUESTED:
            return state.set('isCreatingLineItems', true)
                        .set('errorMessage', []);
       case constants.ADD_SERVICE_LINE_ITEM_RESOLVED:
            return state.set('isCreatingLineItems', false)
                        .set('errorMessage', []);
       case constants.ADD_SERVICE_LINE_ITEM_REJECTED:
            return state.set('isCreatingLineItems', false)
                        .set('errorMessage', action.errorMessage);
        case constants.ADD_SERVICE_ITEM:
            if(action.serviceItemData.get('lineItemType') == constants.LINE_ITEM_TYPE.PART)
                return state.setIn(['serviceLineItems', action.serviceItemData.get('lineItemKey'), 'partCost'], action.serviceItemData.getIn(['lineItemValue'])).
                    setIn(['serviceLineItems',action.serviceItemData.get('lineItemKey'),'total'],((isNaN(action.serviceItemData.getIn(['lineItemValue']))?0:Number(action.serviceItemData.getIn(['lineItemValue'])))+
                    (isNaN(state.getIn(['serviceLineItems',action.serviceItemData.get('lineItemKey'),'laborRate']))?0:Number(state.getIn(['serviceLineItems',action.serviceItemData.get('lineItemKey'),'laborRate'])))).toFixed(2));
            else if(action.serviceItemData.get('lineItemType') == constants.LINE_ITEM_TYPE.LABOR)
                return state.setIn(['serviceLineItems', action.serviceItemData.get('lineItemKey'), 'laborRate'], action.serviceItemData.getIn(['lineItemValue'])).setIn(['serviceLineItems',action.serviceItemData.get('lineItemKey'),'total'],((isNaN(action.serviceItemData.getIn(['lineItemValue']))?0:Number(action.serviceItemData.getIn(['lineItemValue'])))+
                    (isNaN(state.getIn(['serviceLineItems',action.serviceItemData.get('lineItemKey'),'partCost']))?0:Number(state.getIn(['serviceLineItems',action.serviceItemData.get('lineItemKey'),'partCost'])))).toFixed(2));
            else if(action.serviceItemData.get('lineItemType') == constants.SERVICE_LINE_ITEM_DETAILS_TYPE.TOTAL)
                return state.setIn(['serviceLineItems', action.serviceItemData.get('lineItemKey'), 'laborRate'], '').setIn(['serviceLineItems', action.serviceItemData.get('lineItemKey'), 'partCost'], '').setIn(['serviceLineItems', action.serviceItemData.get('lineItemKey'), 'total'],action.serviceItemData.getIn(['lineItemValue'])==''?action.serviceItemData.getIn(['lineItemValue']):action.serviceItemData.getIn(['lineItemValue']));
            else
                return state;
        case constants.REMOVE_SERVICE_ITEM:
            if(action.serviceItemData.get('lineItemType') == constants.LINE_ITEM_TYPE.PART)
                return state.deleteIn(['serviceLineItems', action.serviceItemData.get('lineItemKey'), 'partCost'],'');
            else if((action.serviceItemData.get('lineItemType') == constants.LINE_ITEM_TYPE.LABOR))
                return state.deleteIn(['serviceLineItems', action.serviceItemData.get('lineItemKey'), 'laborRate'],'');
            else if((action.serviceItemData.get('lineItemType') == constants.SERVICE_LINE_ITEM_DETAILS_TYPE.TOTAL))
                return state.deleteIn(['serviceLineItems', action.serviceItemData.get('lineItemKey')]);
            else
                return state;
        case constants.TOGGLE_SHOW_MODAL_ADD_SERVICE_LINE_ITEMS:
            return state.set('showWindow', !state.get('showWindow'));
        case constants.SHOW_MODAL_ADD_SERVICE_LINE_ITEMS:
            return state.set('showWindow', true);    
        case constants.REMOVE_SERVICE_ATA_CODE_DATA:
            //had to re-add serviceLineItems node because 'deleteIn' is removing the node when empty Map is passed in
            return state.deleteIn(['serviceLineItems'], action.serviceATACodeKey).set('serviceLineItems',Map());
        case constants.FETCH_DATA_FOR_ATA_PM_PARAMETERS:
            return state.set('errorMessage', []); 
        case constants.SEND_CLICK_ATA_PM_PARAMETERS_RESOLVED:
            return state.set('errorMessage', []); 
        case constants.SEND_CLICK_ATA_PM_PARAMETERS_REJECTED:
            return state.set('errorMessage', action.errorMessage);
        default:
            return state;
    }
}

export function serverDataAddServiceLineItemsView(state = initialAppState.getIn(['serverData', 'addServiceLineItemsView']), action) {
    switch (action.type) {
        case constants.SAVE_ATA_PM_PARAMETERS:
            return state.set('ataPmParameters',fromJS(action.currentParameters));
        default:
            return state;
    }
}