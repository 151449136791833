import * as constants from 'constants/App';
import * as api from 'utils/CallAPI';
import { Map, fromJS, Record } from 'immutable';
import * as sharedService from '../services/Shared';

export function selectLocale(locale) {
    return { type: constants.SELECT_LOCALE, locale }
}

export function selectCurrency(currency) {
    return { type: constants.SELECT_CURRENCY, currency }
}

export function clearLocale() {
    return {type: "CLEAR_SELECT_LOCALE"}
}

export function fetchDataFromAPIStart(componentName) {
    return { type: constants.FETCH_DATA_FROM_API_START, componentName }
}

export function fetchDataFromAPIComplete(componentName) {
    return { type: constants.FETCH_DATA_FROM_API_COMPLETE, componentName }
}

export function setErrorMessageInState(errorMessage) {
    return { type: constants.SET_ERROR_MESSAGE, errorMessage }
}

export function loadRecentServicesData(recentServicesData) {
    return { type: constants.LOAD_RECENT_SERVICES_DATA, recentServicesData }
}

export function updateOffSet(offset) {
    return { type: constants.UPDATE_OFFSET, offset };
}

export function setErrorMessage(errorMessage) {
    return (dispatch, getState) => {

        dispatch(setErrorMessageInState(errorMessage));

        try {
            var error = new Error(errorMessage);
         
        }
        catch (e) {
            //absorb this error since we raised it just to grab stacktrace info
        }
        ;
    }
}

export function clearErrorMessage() {
    return { type: constants.CLEAR_ERROR_MESSAGE }
}

export function setVehicleSearchErrorMessage(errorMessage) {
    return { type: constants.SET_VEHICLE_SEARCH_ERROR_MESSAGE, errorMessage }
}

export function clearVehicleSearchErrorMessage() {
    return { type: constants.CLEAR_VEHICLE_SEARCH_ERROR_MESSAGE }
}

export function setVendorDetails(decodedToken, token, vendorDetails) {
    return { type: constants.SET_VENDOR_DETAILS, decodedToken, token, vendorDetails }
}

export function setToken(token) {
    return { type: "SET_TOKEN", token }
}
export function setSelectedCountry(country) {
    return { type: constants.SET_SELECTED_COUNTRY, country }
}

//Token expirationTime in minutes
export function refreshToken() {    
    return (dispatch, getstate) => {

        api.callRefreshTokenService(dispatch, getstate().appState);

        return Promise.resolve();
    }
}

export function loadVehicleData(vehicleData) {
    return { type: constants.LOAD_VEHICLE_CLIENT_DATA, vehicleData }
}

export function loadRestrictedATACodesData(restrictedATACodes) {
    return { type: constants.LOAD_RESTRICTED_ATA_CODES_DATA, restrictedATACodes }
}

export function stopSpinnerAndDisplayError(componentName, message, dispatch, path) {
    // Stop spinner
    dispatch(fetchDataFromAPIComplete(componentName));

    // Dispatch error
    dispatch(setErrorMessage(message));

    if (path != undefined && (path != '' || path != null))
        dispatch(updatePath(path));
}

export function fetchRecentServicesViewData(componentName, vehicleId, offset, limit) {
    return (dispatch, getState) => {
        // Start spinner
        dispatch(fetchDataFromAPIStart(componentName));

      
    };

}

export function signOut() {    
    return { type: constants.SIGN_OUT }
}

//Get restricted ata codes
export function fetchRestrictedATACodes(componentName) {

    return function (dispatch, getState) {

        dispatch(fetchDataFromAPIStart(componentName));

        sharedService.fetchRestrictedATACodes(
            getState(),
            function (err, serviceResult) {
                if (err) {
                    stopSpinnerAndDisplayError(componentName, err, dispatch);
                    return false;
                }

                // Convert json data to Immutable and in app state structure
                const restrictedATACodes = Map({
                    ataCodes: fromJS(parseRestrictedCodes(serviceResult.ataCodes))
                });

                //save ata codes detail to the app state
                dispatch(loadRestrictedATACodesData(restrictedATACodes));

                dispatch(fetchDataFromAPIComplete(componentName));
            }
        );
    }
}

//This will create a list of restricted labor ata codes with parent-child/hierarchical data.
function parseRestrictedCodes(data) {
    var groupedATACodes = {};

    for (var i = 0; i < data.length; i++) {
        var item = data[i];

        if (!groupedATACodes[item.code]) {
            groupedATACodes[item.code] = {};
            groupedATACodes[item.code].code = item.code;
            groupedATACodes[item.code].repairType = item.repairType;

            groupedATACodes[item.code].restrictedCodesForLabor = [];
        }

        if (!groupedATACodes[item.code].restrictedCodesForLabor[item.restrictedCode])
            groupedATACodes[item.code].restrictedCodesForLabor.push({
                restrictedCode: item.restrictedCode,
                restrictedRepairType: item.restrictedRepairType
            });
    }

    return groupedATACodes;
}

export function downLoadRemoteFile(componentName, fileUrl,selectedLocale="en-US") {    
    return function (dispatch, getState) {
        dispatch(fetchDataFromAPIStart(componentName));

        sharedService.downLoadRemoteFile(
            getState(), fileUrl,selectedLocale,
            function (code, message) {
                if (code) {
                    stopSpinnerAndDisplayError(componentName, message, dispatch, undefined);
                    return false;
                }

                dispatch(fetchDataFromAPIComplete(componentName));
            }
        );
    }
}
