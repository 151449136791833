// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
// our stuff
import { LINE_ITEM_STATUS,PO_AUTHORIZATION_STATUS } from 'constants/App';
import './AuthorizationStatusReason.css';


class AuthorizationStatusReason extends React.Component {

    // this will upper case the first letter of each sentence and make the rest lower case
    convertToSentenceCase(myString) {

        if (myString == undefined) return myString;

        var rg = /(^\w{1}|\.\s*\w{1})/gi;
        return (myString.toLowerCase().replace(rg, function(toReplace) {
            return toReplace.toUpperCase();
        }));
    }

    render() {
        const {formatMessage} = this.props.intl;
        const messages = defineMessages({
            authorization_status_reason: {
                id: 'authorization_status_reason',
                description: 'Note from Holman technician',
                defaultMessage: 'Note from Holman technician'
            },
            approval_request_not_submitted: {
                id: 'approval_request_not_submitted',
                description: 'You have created/updated a Purchase Order but approval was never requested for it. Please verify if the repair is needed and request for approval. Otherwise Cancel the PO',
                defaultMessage: 'You have created/updated a Purchase Order but approval was never requested for it. Please verify if the repair is needed and request for approval. Otherwise Cancel the PO'
            },
            authorization_status_reason_client: {
                id: 'authorization_status_reason_client',
                description: 'Note from client',
                defaultMessage: 'Note from client'
            },
        });

        const showTechnicianNote = this.props.authorizationStatus == PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED;
        const isLastNoteFromClient = this.props.note?.get('createdBy') ==='CLIENT';
        const showClientNote = this.props.authorizationStatus == PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL && this.props.isHsbClientVehicle && isLastNoteFromClient;
        let title, note;

        if (showTechnicianNote) {
            title = formatMessage(messages.authorization_status_reason);
            note = formatMessage(messages.approval_request_not_submitted)
        } else if (showClientNote) {
            title = formatMessage(messages.authorization_status_reason_client);
            note = this.props.note.get('noteText');
        }

        return <div>  
        {showTechnicianNote || showClientNote ?
            <div className='authorization_status_reason'>

                <div className='authorization_status_reason__title'>
                    {title}
                </div>
                <div className='authorization_status_reason__notes'>                 
                    {note}
                </div>
            </div>
            : undefined
        }</div>;
    }
}



export default AuthorizationStatusReason;