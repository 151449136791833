//libraries
import React, { useState, useRef } from 'react';
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl, RawIntlProvider } from 'react-intl';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Container, Row, Col, Overlay, Popover } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
//our stuff
import { LINE_ITEM_TYPE, LINE_ITEM_STATUS, FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR, ENTITY_FOR_ASSISTANCE } from 'constants/App';
import '../containers/ReviewActionRequiredItemView.css';
import './AdjustedLineItem.css';
import Notes from './Notes.js';
import ReactDOM from 'react-dom';
import { useAppState } from 'shared/AppState/app-state';
import FleetAdminContactInfo from 'shared/components/FleetAdminContactInfo';

const NeedMoreNotesLineItem = (props) => {

    const [vendorNote, setVendorNote] = useState(props.lineItem.get('vendorNote'));
    const [showPopOver, setShowPopOver] = useState(false);
    const refVendorNotes = useRef();
    const [appState, dispatch] = useAppState();

    const onSubmitClick = (lineItemId, e) => {
        if (vendorNote == null || vendorNote == '' || vendorNote.trim().length < 4) {
            refVendorNotes.current.focus();
            setShowPopOver(true);
        }
        else {
            props.onSubmitVendorNotesClickHandler(lineItemId, vendorNote);
            props.closeModalHandler();
        }
    }

    const vendorNoteChange = (e) => {
        setVendorNote(e.target.value);
    }

    const messages = defineMessages({
        adjusted_line_item_view_quantity: {
            id: 'adjusted_line_item_view_quantity',
            description: 'Qty',
            defaultMessage: 'Qty'
        },
        adjusted_line_item_view_hour: {
            id: 'adjusted_line_item_view_hour',
            description: 'Hour',
            defaultMessage: 'Hour'
        },
        adjusted_line_item_view_cost: {
            id: 'adjusted_line_item_view_cost',
            description: 'Cost',
            defaultMessage: 'Cost'
        },
        adjusted_line_item_view_amount: {
            id: 'adjusted_line_item_view_amount',
            description: 'Amount',
            defaultMessage: 'Amount'
        },
        adjusted_line_item_view_submitted: {
            id: 'adjusted_line_item_view_submitted',
            description: 'Submitted',
            defaultMessage: 'Submitted'
        },
        adjusted_line_item_view_ari_adjustment: {
            id: 'adjusted_line_item_view_ari_adjustment',
            description: 'Holman Adjustment',
            defaultMessage: 'Holman Adjustment'
        },
        adjusted_line_item_view_ari_created: {
            id: 'adjusted_line_item_view_ari_created',
            description: 'Created by Holman',
            defaultMessage: 'Created by Holman'
        },
        adjusted_line_item_view_cancel_po: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        },
        adjusted_line_item_view_cancel_po_confirmation_no: {
            id: 'cancel_po_confirmation_no',
            description: 'No',
            defaultMessage: 'No'
        },
        adjusted_line_item_view_cancel_po_confirmation_yes: {
            id: 'cancel_po_confirmation_yes',
            description: 'Yes',
            defaultMessage: 'Yes'
        },
        adjusted_line_item_view_cancel_po_confirmation_title: {
            id: 'cancel_po_confirmation_title',
            description: 'Cancel PO',
            defaultMessage: 'Cancel PO'
        },
        adjusted_line_item_view_cancel_po_confirmation_message: {
            id: 'cancel_po_confirmation_message', description: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?',
            defaultMessage: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?'
        },
        close_dialog: {
            id: 'close_dialog',
            description: '  Close',
            defaultMessage: ' Close'
        },
        action_required_issue_note: {
            id: 'action_required_issue_note',
            description: 'If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician'
        },
        action_required_issue_note_can: {
            id: 'action_required_issue_note_can',
            description: 'If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician',
            defaultMessage: 'If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician'
        },
        need_more_notes_line_item_status_description: {
            id: 'need_more_notes_line_item_status_description',
            description: 'Holman needs more information about {productDescription}',
            defaultMessage: 'Holman needs more information about {productDescription}'
        },
        need_more_notes_line_item_related_line_description: {
            id: 'need_more_notes_line_item_related_line_description',
            description: 'Associated {associatedLineType} line will not be approved until you provide more notes for this line.',
            defaultMessage: 'Associated {associatedLineType} line will not be approved until you provide more notes for this line.'
        },
        need_more_notes_line_item_reviewed_description: {
            id: 'need_more_notes_line_item_reviewed_description',
            description: "You've reviewed this line",
            defaultMessage: "You've reviewed this line"
        },
        action_required_submitted_description: {
            id: 'action_required_submitted_description',
            description: 'Submitted',
            defaultMessage: 'Submitted'
        },
        action_required_note_from_ari_description: {
            id: 'action_required_note_from_ari_description',
            description: 'Note from Holman',
            defaultMessage: 'Note from Holman'
        },
        action_required_provide_note_description: {
            id: 'action_required_provide_note_description',
            description: 'Please provide your response below',
            defaultMessage: 'Please provide your response below'
        },
        need_more_notes_submit_description: {
            id: 'need_more_notes_submit_description',
            description: ' Submit ',
            defaultMessage: ' Submit '
        },
        need_more_notes_window_title: {
            id: "need_more_notes_window_title",
            description: "Please Review",
            defaultMessage: "Please Review"
        },
        need_more_notes_vendor_note_error_message: {
            id: "need_more_notes_vendor_note_error_message",
            description: "Please provide your response below; enter 4 or more characters",
            defaultMessage: "Please provide your response below; enter 4 or more characters"
        }

    });

    const lineItem = props.lineItem;
    const productDescription = ( appState.isProductCodeFeatureEnabled && lineItem.get('vendorProductCode') !== undefined && lineItem.get('vendorProductCode') !== null ? lineItem.get('vendorProductCode') + ' - ' : '' ) + lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase());
    const submittedQty = lineItem.get('previousQuantity') != null ? lineItem.get('previousQuantity') : lineItem.get('quantity'); //if previous value is null, that means Holman tech did not adjust this field
    const submittedCost = lineItem.get('previousUnitPrice') != null ? lineItem.get('previousUnitPrice') : lineItem.get('unitPrice');
    const submittedTotal = submittedQty * submittedCost;
    const ariQty = lineItem.get('quantity');
    const ariCost = lineItem.get('unitPrice');
    const ariTotal = lineItem.get('totalAmount');
    const hasAdjustment = lineItem.get('previousQuantity') != null || lineItem.get('previousUnitPrice') != null;
    const lineNotes = lineItem.get('authorizationStatusReason');
    const associatedLineTypes = props.associatedPendingLines.map(a => { return a.toUpperCase() }).join(', ');

    return <div className="add-new-line-items">
        <div className="row action_required_line_item__item1 add-new-line-items__close">
            <div onClick={props.closeModalHandler}>
                <img className="add-new-line-items__close-button"
                    src="/assets/VMS_33button_kill_blue.png" />
                {"  "} {<FormattedMessage {...messages.close_dialog} />}
            </div>
        </div>
        <div className='action_required_line_item__item1 add-new-line-items_step-1-2-3-container'>
            <div className="row add-new-line-items__caption">
                <FormattedMessage {...messages.need_more_notes_window_title} />
            </div>
        </div>
        {lineItem.get('reviewCompleted') ?
            <div className="action_required_line_item__item1">
                <div className="row action_required_line_item__header">

                    {<FormattedMessage {...messages.need_more_notes_line_item_reviewed_description} />}<img className="adjusted_line_item__img_reviewed"
                        src="/assets/greenOK.png" />
                </div>
            </div> : undefined}
        <div className="add-new-line-items__search-ata-codes_border">
            <div className="action_required_line_item">

                <div className="action_required_line_item__lines">
                    <Row>
                        <Col sm={12} className="action_required_line_item__header_2 action_required_line_item__center">

                            <FormattedMessage values={{ productDescription: productDescription }} {...messages.need_more_notes_line_item_status_description} />

                        </Col>
                    </Row>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={10}>
                            <Row className="action_required_line_item">
                                <Col sm={2}></Col>

                                <Col sm={5} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage  {...messages.adjusted_line_item_view_quantity} />
                                </Col>
                                <Col sm={2} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage {...messages.adjusted_line_item_view_cost} />
                                </Col>
                                <Col sm={2} className="action_required_line_item__row_header adjusted_line_item__number ">
                                    <FormattedMessage {...messages.adjusted_line_item_view_amount} />
                                </Col>
                                <Col sm={2}></Col>
                            </Row>

                            <Row>
                                <Col sm={2}></Col>
                                <Col sm={3} className="action_required_line_item__item action_required_line_item__row_bottom_border">
                                    <FormattedMessage {...messages.action_required_submitted_description} />
                                </Col>
                                <Col sm={2}
                                    className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_border">
                                    {submittedQty}
                                </Col>
                                <Col sm={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_border">
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={submittedCost} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>

                                </Col>
                                <Col sm={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_border">
                                    <IntlProvider locale="en">
                                        <FormattedNumber value={submittedTotal} style="currency"
                                            currency={props.selectedCurrency} />
                                    </IntlProvider>
                                </Col>
                                <Col sm={2}></Col>
                            </Row>
                            {ariQty != submittedQty || ariCost != submittedCost ?
                                <Row className="action_required_line_item__ari_created_row">
                                    <Col sm={2}></Col>
                                    <Col sm={3} className="action_required_line_item__item action_required_line_item__row_bottom_last">
                                        <FormattedMessage {...messages.adjusted_line_item_view_ari_adjustment} />
                                    </Col>
                                    <Col sm={2}
                                        className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                        {ariQty}

                                    </Col>
                                    <Col sm={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">

                                        <IntlProvider locale="en">
                                            <FormattedNumber value={ariCost} style="currency"
                                                currency={props.selectedCurrency} />
                                        </IntlProvider>
                                    </Col>
                                    <Col sm={2} className="action_required_line_item__item adjusted_line_item__number action_required_line_item__row_bottom_last">
                                        <IntlProvider locale="en">
                                            <FormattedNumber value={ariTotal} style="currency"
                                                currency={props.selectedCurrency} />
                                        </IntlProvider>
                                    </Col>
                                    <Col sm={2}></Col>
                                </Row>
                                :
                                false}
                        </Col>
                        <Col sm={1}></Col>
                    </Row>

                    <Row className="row">
                        <Col className="action_required_line_item__header_2" sm={12}>
                            <FormattedMessage {...messages.action_required_note_from_ari_description} /></Col></Row>
                    <Row>
                        <Col sm={12} className="action_required_line_item__item1">
                            <Notes text={lineNotes} />
                        </Col>
                    </Row>

                    <Row className="row">
                        <Col sm={12} className="action_required_line_item__header_2">
                            <FormattedMessage {...messages.action_required_provide_note_description} />
                        </Col></Row>
                    <Row>
                        <Col className="action_required_line_item__item1" sm={12}>

                            <Overlay
                                show={showPopOver}
                                placement="top"
                                target={props => ReactDOM.findDOMNode(refVendorNotes.current)}
                            >
                                <Popover id="error_popover_VendorNotes"
                                    className="ErrorMessage"
                                >
                                    <RawIntlProvider value={props.intl}>
                                        <FormattedMessage {...messages.need_more_notes_vendor_note_error_message} />
                                    </RawIntlProvider>
                                </Popover>
                            </Overlay>

                            <textarea id={lineItem.get('id')} ref={refVendorNotes}
                                className={classNames(
                                    'complaint-notes-view__textarea',
                                    { 'complaint-notes-view__alert': vendorNote == null || vendorNote == '' })}
                                key={lineItem.get('id')}
                                defaultValue={lineItem.get('vendorNote')}
                                onChange={vendorNoteChange}
                                maxLength="1000" />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={10}></Col>
                        <Col sm={2} className="action_required_line_item__item">

                        </Col>
                    </Row>
                    {associatedLineTypes != null && associatedLineTypes != '' ?
                        <Row className="adjusted_line_item__header3">
                            <Col sm={12}>
                                <FormattedMessage values={{
                                    assciatedLineType: associatedLineTypes
                                }} {...messages.need_more_notes_line_item_related_line_description} />
                            </Col>

                        </Row> : undefined
                    }
                    {associatedLineTypes != null && associatedLineTypes != '' ?
                        <Row>
                            <Col sm={10}></Col>
                            <Col sm={2} className="action_required_line_item__item">

                            </Col>
                        </Row>
                        : undefined
                    }


                </div>
            </div>
        </div>
        <div className="action_required_line_item__item add-new-line-items__save" onClick={() => onSubmitClick(lineItem.get('id'))}>
            <FormattedMessage {...messages.need_more_notes_submit_description} /><img src="/assets/VMS_33button_go_bblue.png" />

        </div>
        {
            props.isHsbClientVehicle && props.entityForAssistance == ENTITY_FOR_ASSISTANCE.CUSTOMER ? <FleetAdminContactInfo messageFor={FLEET_ADMIN_CONTACT_INFO_MESSGAE_FOR.NEED_MORE_NOTES}  fleetAdminContactInfo={props.fleetAdminContactInfo} fleetAdminContactInfoError={props.fleetAdminContactInfoError} /> :
            <div className="action_required__issue_note">
            {
                props.selectedCountry == 'USA' ? <FormattedMessage {...messages.action_required_issue_note} /> :
                    <FormattedMessage {...messages.action_required_issue_note_can} />
            }
            </div>
        }
    </div>
        ;
}


export default injectIntl(NeedMoreNotesLineItem);