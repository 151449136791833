import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map,fromJS} from 'immutable';

export function uiDataActionRequired(state = initialAppState.getIn(['uiData','actionRequired']), action) {
    switch (action.type) {
        case constants.FETCH_ACTION_REQUIRED_DATA_REQUESTED:
        case constants.ACKNOWLEDGE_LINE_ITEMS_REQUESTED:
        case constants.ACTION_REQUIRED_CANCEL_PO_REQUESTED: 
        case constants.REJECT_PO_ADJUSTMENTS_REQUESTED:
        case 'ACTION_REQUIRED_SUBMIT_VENDOR_NOTES_REQUESTED':
            return state.set('isBusy', true).set('errorMessage',undefined).set('showReviewPendingErrors',false);
        case constants.FETCH_ACTION_REQUIRED_DATA_RESOLVED:
        case constants.ACKNOWLEDGE_LINE_ITEMS_RESOLVED:
        case constants.ACTION_REQUIRED_CANCEL_PO_RESOLVED:
        case 'ACTION_REQUIRED_SUBMIT_VENDOR_NOTES_RESOLVED':
            return state.set('isBusy', false).set('errorMessage',undefined);;
        case constants.FETCH_ACTION_REQUIRED_DATA_REJECTED:
        case constants.ACKNOWLEDGE_LINE_ITEMS_REJECTED:
        case constants.ACTION_REQUIRED_CANCEL_PO_REJECTED:
        case 'ACTION_REQUIRED_SUBMIT_PO_REJECTED':
        case 'ACTION_REQUIRED_SUBMIT_VENDOR_NOTES_REJECTED':
        case constants.ACTION_REQUIRED_SUBMIT_CLIENT_VENDOR_NOTES_REJECTED:
            return state.set('isBusy', false).set('errorMessage',action.errorMessage);
            case "ACTION_REQUIRED_SHOW_REVIEW_PENDING_LINE_ERRORS"   :
            return state.set('showReviewPendingErrors',true);
        default:
            return state;
    }
}

export function serverDataActionRequired(state = initialAppState.getIn(['serverData','actionRequired']), action) {
    switch (action.type) {
        case 'ACTION_REQUIRED_UPDATE_VENDOR_NOTE':         
        return state.setIn(['lineItems',action.id.toString(),'vendorNote'],action.note).setIn(['lineItems',action.id.toString(),'reviewCompleted'],true);
        /*return state.withMutations(s => {            
                s.setIn(['lineItems', action.id.toString(), 'vendorNote'], action.note);            
        });*/   
        case 'ACTION_REQUIRED_ACCEPT_ADJUSTMENT':
        case 'ACTION_REQUIRED_ACCEPT_REJECTION':
        case 'ACTION_REQUIRED_ACCEPT_NEW_LINE':
        case 'ACTION_REQUIRED_ACCEPT_NEED_MORE_NOTES':
        return state.setIn(['lineItems',action.id.toString(),'reviewCompleted'],true);
        case constants.FETCH_ACTION_REQUIRED_DATA_RESOLVED:
            return Map({
                vendor: fromJS(action.purchaseOrderData.vendor),
                vehicleDetails: fromJS(action.purchaseOrderData.vehicleDetails),
                purchaseOrder: fromJS(action.purchaseOrderData.purchaseOrder),
                complaints: fromJS(action.purchaseOrderData.complaints),
                notes: fromJS(action.purchaseOrderData.notes),
                lineItems: fromJS(action.purchaseOrderData.lineItems),
                adjustmentStatus: fromJS(action.purchaseOrderData.adjustmentStatus)
            });
        case constants.ACKNOWLEDGE_LINES:
            return state.withMutations(s => {
                action.lineItems.forEach(li => {
                    s.setIn(['lineItems', li.get('lineItemId').toString(), 'vendorAcknowledgementStatus'], constants.VENDOR_ACKNOWLEDGEMENT_STATUS.APPROVED);
                })
            });
        case constants.ACKNOWLEDGE_LINE_ITEMS_RESOLVED:
        case constants.ACTION_REQUIRED_CANCEL_PO_RESOLVED:
            return state.clear();       
        case constants.CLEAR_PURCHASE_ORDER:
            return state.set('purchaseOrder', Map())
                .set('vehicleDetails', Map());
        default:
            return state;
    }
}