import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import ChangePassword from './ChangePassword';
import './Settings.css';
import VendorContactInformation from './VendorContactInformation'
import EditVendorContact from './EditVendorContact';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
import { useAppState } from 'shared/AppState/app-state';

const Settings = (props) => {

    const [ appState, dispatch ] = useAppState();

        const messages = defineMessages({
            settings_view_caption: {
                id: 'settings_view_caption',
                description: 'YourAccount',
                defaultMessage: 'YourAccount'
            }});

        return <div>
            <div className="settings__caption">
                <FormattedMessage {...messages.settings_view_caption}/>
            </div>
            {!props.editModeContact ?
                <VendorContactInformation
                    isLinkedVendor={props.isLinkedVendor}                    
                    isSSOUser={props.isSSOUser}
                    country={props.country}
                    onChangeContactClick={props.onChangeContactClick.bind(this)}
                    businessName={props.businessName}
                    businessAddress={props.businessAddress}
                    businessState={props.businessState}
                    businessCity={props.businessCity}
                    businessZip={props.businessZip}
                    businessContact={props.businessContact}
                    businessPhone={props.businessPhone}
                    businessExtension={props.businessExtension}
                    businessFax={props.businessFax}
                    businessEmail={props.businessEmail}
                    contactNamePayment={props.contactNamePayment}
                    contactEmailPayment={props.contactEmailPayment}
                    vendorAccountType={props.vendorAccountType}
                />
                : <EditVendorContact
                    isLinkedVendor={props.isLinkedVendor}                    
                    isSSOUser={props.isSSOUser}
                    onCancelContactClick={props.onCancelContactClick.bind(this)}
                    showVendorError={props.showVendorError}
                    saveOnClickHandler={props.saveOnClickHandler.bind(this)}
                    businessName={props.businessName}
                    businessAddress={props.businessAddress}
                    businessState={props.businessState}
                    businessCity={props.businessCity}
                    businessZip={props.businessZip}
                    businessContact={props.businessContact}
                    businessPhone={props.businessPhone}
                    businessExtension={props.businessExtension}
                    businessFax={props.businessFax}
                    businessEmail={props.businessEmail}
                    onBlurChange={props.onBlurChange}
                    contactNamePayment={props.contactNamePayment}
                    contactEmailPayment={props.contactEmailPayment}
                    isSubmittingData = {props.isSubmittingData}
                    tabIndexStartFrom = {props.tabIndexStartFrom}
                    country={props.country}
                    vendorAccountType={props.vendorAccountType}
                    vendorContactUpdateErrorMessage={props.vendorContactUpdateErrorMessage}
                />
            }
            { !appState.isPersonaUserLoggedIn &&
            <ChangePassword
                passwordSettings={props.passwordSettings}
                onTextChangeHandler={props.onTextChangeHandler.bind(this)}
                editMode={props.passwordSettings.get('isEditMode')}
                onCancelClickHandler={props.onCancelClickHandler.bind(this)}
                onSavePasswordClickHandler={props.onSavePasswordClickHandler.bind(this)}
                onChangePasswordClickHandler={props.onChangePasswordClickHandler.bind(this)}
                tabIndexStartFrom = {props.tabIndexStartFrom + 50}
                />
            }
        </div>;
    }


export default (Settings);