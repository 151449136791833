// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './HideDocuments.css';

const HideDocuments = ({onClick}) => {
    const messages = defineMessages({
            documents_hide: {
                id: 'documents_hide',
                description: 'Link to hide documents',
                defaultMessage: 'Hide documents'
            }
        });

    return <div className='documents__hide'>        
        <span onClick={onClick} className='documents__hide_button' role="button">
            <FormattedMessage {...messages.documents_hide}/>
            {' '}<img src="/assets/VMS_button_hide_older_pos.png"/>
        </span>
    </div>
}

export default HideDocuments;