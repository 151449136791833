import React, { useState, useEffect } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import styled from "styled-components";
//import "./SiteTranslations.css";
import { connect } from "react-redux";
import * as actions from "../actions/App";
import * as constants from "constants/App";
import { useAppState } from 'shared/AppState/app-state';
import * as Api from 'utils/Api';
import configuration from "shared/configuration";
import { EstimateStatus, PaymentStatus, useGetRepairEstimateCountQuery } from "shared/types/__generated__/graphql-type.ts";

// #region Styled Components
const MainStyled = styled.div`
  padding-bottom: 1em;
  padding-top: 1em;
`;

const ULStyled = styled.ul`
  & > li > div {
    text-transform: none;
    color: #333;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
  }
`;

const MenuItemStyled = styled.div`
  padding-left: 10px;
`;

const MenuItemPersonaStyled = styled.div`
  padding-left: 10px;
  padding-right: 25px;
  line-height: 10px;
  cursor: default;
  display: flex;  
`;

const PersonaNameStyled = styled.div`  
  padding-top: 4px;
  font-weight: 500;    
`;
const PersonaEmailStyled = styled.div`  
  color: ${props => props.theme.color.ariGray};
`;

const LogoSearch = styled.img`  
  transform: scaleX(-1);
  `;
// #endregion

const UserAccountMenu = ({
  token,
  personaToken,
  adminUser,
  vendorAccountType,
  mainCorporateId,
  isDealerDraftProp,
  serviceClassification,
  personaUserId,
  selectedLocale,
  dispatch,
  location,
  history,
  vendorId
}) => {  

  // #region State
  const [ appState ] = useAppState();  
  const showLinkToViewAllOpenPOs = (location.pathname == '/' || location.pathname == '/ariuser' || token == null) ? false : true;
  const showAdvancePOSearch = token === undefined ? false: true;
  const isDealerDraft = (location.pathname == '/dealerDrafts') ? false : isDealerDraftProp ? true : false;    
  const showYourAccountLink = token != null 
    && (serviceClassification !== 'CARWASH' 
      || (serviceClassification === 'CARWASH'
        && (
          appState.accountSettingPermissions.includes("VW")
          || appState.accountSettingPermissions.includes("ED")
          || appState.bankAccountPermissions.includes("VW")
          || appState.bankAccountPermissions.includes("ED")
          || appState.taxIdPermssions.includes("VW")
          || appState.authorizationEmailPermissions.includes("VW")
          || appState.authorizationEmailPermissions.includes("ED")
          || appState.documentPermisions.includes("VW")
          || appState.documentPermisions.includes("ED")
          || appState.servicesPermissions.includes("VW")
          || appState.servicesPermissions.includes("ED")
    )));  
    const [agedPoCount, setAgedPoCount] = useState(0);
 // #endregion

 // #region Hooks
  const { data: repairEstimateData } = useGetRepairEstimateCountQuery({
    skip: !appState.isAccidentAssignmentFeatureEnabled,
    variables: {
      vendorId: vendorId,
      estimateStatuses: [EstimateStatus.WaitingForEstimateAndImages, EstimateStatus.WaitingForHolmanApproval, EstimateStatus.Approved, EstimateStatus.WaitingForRepairEta],
      paymentStatuses: [PaymentStatus.PaymentNotRequested, PaymentStatus.PaymentInProgress, PaymentStatus.WorkCompleted]
    },
    pollInterval: configuration.accidentAssignmentsViewRefreshFrequencyInMinutes * 60 * 1000
  });
 // #endregion

// #region International messages
  const messages = defineMessages({
    user_account_menu_your_account: {
      id: "user_account_menu_your_account",
      description: " Your Account",
      defaultMessage: " Your Account"
    },
    user_account_menu_sign_out: {
      id: "user_account_menu_sign_out",
      description: " Sign Out",
      defaultMessage: " Sign Out"
    },
    user_account_corporate_account_link: {
      id: "user_account_corporate_account_link",
      description: " Text for corporate Account Link in header",
      defaultMessage: " Corporate Account"
    },
    user_account_menu_manage_stores: {
      id: "user_account_menu_manage_stores",
      description: " Manage Stores",
      defaultMessage: " Manage Stores"
    },
    user_account_menu_manage_users: {
      id: "user_account_menu_manage_users",
      description: " Manage Users",
      defaultMessage: " Manage Users"
    },
    user_account_menu_access_stores: {
      id: "user_account_menu_access_stores",
      description: " Access Stores",
      defaultMessage: " Access Stores"
    },    
    open_pos_link_header: {
      id: 'open_pos_link_header',
      description: 'Text for Viewing All Open Purchase Orders Link in header',
      defaultMessage: 'View Orders'
    },
    open_pos_link_3: {
      id: 'open_pos_link_3',
      description: 'open_pos_link_3',
      defaultMessage: 'Courtesy requests'
    },
    help_link_2: {
      id: 'help_link_2',
      description: 'Text for Help Link in header',
      defaultMessage: 'Help'
    },
    contact_link_2: {
      id: 'contact_link_2',
      description: 'Text for Contact Link in header',
      defaultMessage: 'Contact'
    },
    advanced_search_link_2: {
        id: 'advanced_search_link_2',
        description: 'Text for navigating to advance search',
        defaultMessage: 'Search orders'
    },
    aged_po_link2: {
        id: 'aged_po_link2',
        description: 'Text for navigating to aged po',
        defaultMessage: 'View aged orders'
    },
    accident_po_link: {
      id: 'accident_po_link',
      description: 'Text for navigating to accident assignments',
      defaultMessage: 'View accident orders'
    }

  });
 // #endregion

 // #region UseEffect
 useEffect(() => {
  const fetchAgedPOsCount = async () => {
      try {

          // Make API call 
          if (token !== undefined) {
              const count = await Api.fetchOpenOrdersOver30DayCount(token, vendorId);

              setAgedPoCount(count.statistics.ordersStatistics.openOrdersOver30DaysCount);
          }

      }
      catch (e) {
      }
  };
  fetchAgedPOsCount();
}, [dispatch, token,vendorId,location.pathname]);
// #endregion

  function onSignOutClick() {
    //Check if token is meant for APC user or SSO user    
    if (personaToken !== undefined) history.push(`/login?signed_out=1`);
    else {
      const jwtToken = jwtDecode(token);
      const audien = jwtToken.aud;
      if (
        audien == "VMS" || audien == "VMS_MEXICO" || jwtToken.sub === "ARIUSER"
      )
        history.push(`/login?signed_out=1`);
      else history.push(`/sso?signed_out=1`);
    }
    dispatch(actions.signOut());
  }

  function onYourAcountClick() {
    history.push(`/yourAccount`);
  }

  function onAccessUsersClick() {    
    history.push(`/Persona/AccessStores`);    
  }
  function onManageUsersClick() {       
    history.push(`/Persona/Users`);
  }
  function onManageStoresClick() {    
    history.push(`/Persona/Stores`);
  }
  function onAccidentAssignmentsClickHandler(){
    history.push(`/AccidentManagement/AccidentAssignments`);
  }
  
  function onCorporateClickHandler() {
    dispatch({
      type: constants.SAVE_CORPORATE_VENDOR_DETAILS,
      subId: mainCorporateId
    });
    dispatch({ type: constants.CLEAR_CORPORATE_VENDOR_DETAILS });

    if (serviceClassification === "CARWASH") {
      if (location.pathname === "/carWash") {
        dispatch({ type: "FETCH_CAR_WASH_CLIENT_NUMBERS" });
        dispatch({ type: "FETCH_ORDER_DETAILS" });
      } else {
        dispatch({ type: constants.NAVIGATE_TO, path: "/carWash" });
      }
    } else {
      if (location.pathname === "/") {
        dispatch({ type: constants.SHOP_VIEW_LOADED });
      } else {
        dispatch({ type: constants.NAVIGATE_TO, path: "/" });
      }
    }
  }

  function onViewAllOpenPOsHandler(event) {
    event.preventDefault();
    dispatch({type: constants.NAVIGATE_TO, path: '/'});

  }

  function onViewAllOpenCourtesyHandler(event) {
    event.preventDefault();
    dispatch({type: constants.NAVIGATE_TO, path: '/dealerDrafts'});

  }

  function onContactClickHandler(event){
    event.preventDefault();
    dispatch({type: constants.NAVIGATE_TO, path: '/contact/FeedbackForm'});
  }
  function onHelpFaqClickHandler(event){
    event.preventDefault();
    dispatch({type: constants.NAVIGATE_TO, path: '/contact/HelpFAQ'});
  }  

  function onViewAgedClickHandler(event){
    event.preventDefault();
    dispatch({type: constants.NAVIGATE_TO, path: '/attentionNeeded?type=agedPOs'});
  }  


  function expandSelect() {
    document.getElementById("btnAdvanceSearchOptions").scrollIntoView();
    setTimeout(() => {document.getElementById("btnAdvanceSearchOptions").click();}, 500);
  }

  return (
    <MainStyled>

      <div className="dropdown">
        <span
          id="drop1"
          
          role="button"
          data-bs-toggle="dropdown"
        >
          <img src="/assets/UserIcon2.png" />
        </span>
        <ULStyled className="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="dLabel" style={{"textTransform":"initial"}}>          
          
          {!!appState.personaUser &&
            <React.Fragment>
              <li>
                  <MenuItemPersonaStyled>
                    <div css={`align-self:flex-start;padding-right: 6px;`}>
                      <img src="/assets/UserIcon2.png" width={20} height={20} />
                    </div>
                    <div>
                      <PersonaNameStyled>
                        {appState.personaUser.firstName}
                        {' '}
                        {appState.personaUser.lastName}
                      </PersonaNameStyled>
                      <br />
                      <PersonaEmailStyled>{appState.personaUser.emailAddress}</PersonaEmailStyled>
                    </div>
                  </MenuItemPersonaStyled>
              </li>
              <li role="presentation" className="dropdown-divider" />
            </React.Fragment>
          }

          {serviceClassification !== "CARWASH" ?
            <React.Fragment>
              { (showLinkToViewAllOpenPOs) ?
                <React.Fragment>
                  <li className="d-xl-none"
                            href="/" onClick={onViewAllOpenPOsHandler }>
                      <MenuItemStyled>
                        <img src='/assets/VMS_PO_Icon_light_blue.png' />&nbsp;
                        <FormattedMessage {...messages.open_pos_link_header}/>
                      </MenuItemStyled>
                  </li>
                  <li role="presentation" className="dropdown-divider d-xl-none" />
                </React.Fragment>
                : undefined}

              {(isDealerDraft) ?
                <React.Fragment>
                  <li className="d-xl-none"
                            href="/" onClick={onViewAllOpenCourtesyHandler }>
                      <MenuItemStyled>
                        <img src='/assets/View_All_Courtesy_DeliveryIcon_light_blue.png'
                          width={20} height={20}
                        />&nbsp;
                        <FormattedMessage {...messages.open_pos_link_3}/>
                      </MenuItemStyled>
                  </li>
                  <li role="presentation" className="dropdown-divider d-xl-none" />
                </React.Fragment>
                : undefined
              }

              
            </React.Fragment>
            : undefined
          }
            
          <li className="d-xl-none"
                    href="#" onClick={onHelpFaqClickHandler} >
            <MenuItemStyled>
              <img src='/assets/Help_Icon_light_blue.png' />&nbsp;
              <FormattedMessage {...messages.help_link_2}/>
            </MenuItemStyled></li>

          <li className="d-xl-none"  href="#"
            onClick={onContactClickHandler}
          >
            <MenuItemStyled>
              <img src='/assets/ContactIcon_light_blue.png' />&nbsp;
              <FormattedMessage {...messages.contact_link_2}/>
            </MenuItemStyled>
          </li>
          {agedPoCount > 0 && (location.pathname != '/attentionNeeded') && appState.isReviewOpenPOsUponLoginEnabled ?
          <li className="d-xl-none" 
            onClick={onViewAgedClickHandler}
          >
            <MenuItemStyled>
              <LogoSearch src='/assets/Aged_PO_icon2.png'  width={20} height={20}/>&nbsp;
              <FormattedMessage {...messages.aged_po_link2} />
            </MenuItemStyled>
          </li>
          : undefined
          }
          {repairEstimateData?.repairEstimateStatistics?.pendingRepairEstimateCount > 0 && (location.pathname != '/AccidentManagement/AccidentAssignments') ?
            <li className="d-xl-none"
              onClick={onAccidentAssignmentsClickHandler}
            >
              <MenuItemStyled>
                <LogoSearch src='/assets/AccidentAssignmentIconSmall.png' width={20} height={20} />&nbsp;
                <FormattedMessage {...messages.accident_po_link} />
              </MenuItemStyled>
            </li>
            : undefined
          }
          {showAdvancePOSearch ?
          <li className="d-xl-none" 
            onClick={expandSelect}
          >
            <MenuItemStyled>
              <LogoSearch src='/assets/vms_adv_search_glass.png' />&nbsp;
              <FormattedMessage {...messages.advanced_search_link_2}/>
            </MenuItemStyled>
          </li>
          : undefined
          }
          <li role="presentation" className="dropdown-divider d-xl-none" />

          {showYourAccountLink &&
            <React.Fragment>
              <li onClick={onYourAcountClick} role={"button"}>
              <MenuItemStyled>
                <img src='/assets/Your_Account_Icon_light_blue.png' />&nbsp;
                <FormattedMessage {...messages.user_account_menu_your_account} />
              </MenuItemStyled>
              </li>
              <li role="presentation" className="dropdown-divider" />
            </React.Fragment>
          }          

          {vendorAccountType === "Master" && mainCorporateId !== null
            ? <React.Fragment>
              <li onClick={onCorporateClickHandler} role={"button"} >
                <MenuItemStyled>
                  <img src='/assets/Your_Account_Icon_light_blue.png' />&nbsp;
                  <FormattedMessage {...messages.user_account_corporate_account_link} />
                </MenuItemStyled>
              </li>
              <li role="presentation" className="dropdown-divider" />
            </React.Fragment>
            : undefined}

            
          {personaToken != null && adminUser
            ? <React.Fragment>
              <li onClick={onManageStoresClick} role={"button"}>
                <MenuItemStyled>
                  <img src='/assets/Manage_store_light_blue.png' />&nbsp;
                  <FormattedMessage {...messages.user_account_menu_manage_stores}/>
                </MenuItemStyled>
              </li>                    
              <li onClick={onManageUsersClick}>
                <MenuItemStyled>
                  <img src='/assets/Manage_Users_light_blue.png' />&nbsp;
                  <FormattedMessage {...messages.user_account_menu_manage_users} /></MenuItemStyled>
              </li>
              <li role="presentation" className="dropdown-divider" />
            </React.Fragment>
            : undefined}
            

                     
          {personaToken != null
            ? <React.Fragment>
              <li onClick={onAccessUsersClick} role={"button"}>                
                <MenuItemStyled>
                  <img src='/assets/Access_Stores_light_blue.png' />&nbsp;
                  <FormattedMessage {...messages.user_account_menu_access_stores} />
                </MenuItemStyled>
              </li>
              <li role="presentation" className="dropdown-divider" />
            </React.Fragment>
            : undefined}
                         

          <li onClick={onSignOutClick} role={"button"}>
            <MenuItemStyled>
              <img src='/assets/Sign_Out_light_blue.png' />&nbsp;
              <FormattedMessage {...messages.user_account_menu_sign_out} />
            </MenuItemStyled>

          </li>
            
        </ULStyled>
      </div>

    </MainStyled>
  );
};
const mapStateToProps = state => {
  return {
    token: state.appState.getIn(["serverData", "shared", "token"]),
    personaToken: state.appState.getIn([
      "serverData",
      "shared",
      "personaToken"
    ]),
    personaUserId: state.appState.getIn([
      "serverData",
      "shared",
      "personaUserId"
    ]),
    adminUser: state.appState.getIn([
      "serverData",
      "shared",
      "adminUser"
    ]),
    vendorAccountType: state.appState.getIn([
      "serverData",
      "shared",
      "vendorAccountType"
    ]),
    mainCorporateId: state.appState.getIn([
      "serverData",
      "shared",
      "corporateId"
    ]),
    serviceClassification: state.appState.getIn([
      "serverData",
      "shared",
      "serviceClassification"
    ]),
    vendorId: state.appState.getIn(["serverData", "shared", "vendorId"]),
    isDealerDraftProp: state.appState.getIn([
      "serverData",
      "shared",
      "isDealerDraft"
    ]),

    businessAddress: state.appState.getIn([
      "serverData",
      "shared",
      "businessAddress"
    ]),
    selectedLocale: state.appState.getIn(['uiData','shared','selectedLocale']),          
  };
};

export default connect(mapStateToProps)(withRouter(UserAccountMenu));
