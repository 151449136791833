import React from 'react';
import  ReactDoM from 'react-dom';
import './SiteTranslations.css';
import {DropdownButton,Radio} from 'react-bootstrap';
import * as sharedActions from 'actions/App';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as CookieHelper from 'utils/CookieHelper';

class SiteTranslations extends React.Component {

    onChangeLanguage(locale) {
        this.props.dispatch(sharedActions.selectLocale(locale));
        CookieHelper.createCookie('vms_language_preference', JSON.stringify({
            languageLocaleCookie:locale,
        }));
    }

    render() {

        return (
            <div className="form-inline submit-container">
                <div className="form-group submit-container">
                <DropdownButton  onClick={(e) => e.stopPropagation()} id="translate" title={this.props.languageLocale.substring(0,2).toUpperCase()}
        className={classNames({"site-translation__box1" : this.props.backgroundColor === "grey"},{"site-translation__box2" : this.props.backgroundColor === "blue"})}>
                    <div onClick={this.onChangeLanguage.bind(this,"en-US")} className={"site_translation__language"} >
                    <img className="site_translation__language-default"  src={this.props.languageLocale === "en-US"
                        ? "/assets/VMS_checked.png"
                        : "/assets/VMS_unchecked.png"}
                    />
                        <span className={"site_translation__language"}>  ENGLISH - EN </span>
                    </div>
                    <div onClick={this.onChangeLanguage.bind(this,"fr-CA")} className={"site_translation__language"}>
                    <img className="site_translation__language-default"  src={this.props.languageLocale === "fr-CA"
                        ? "/assets/VMS_checked.png"
                        : "/assets/VMS_unchecked.png"}
                    />
                    <span className={"site_translation__language"}>  LE FRANÇAIS - FR</span>
                    </div>
                    <div onClick={this.onChangeLanguage.bind(this,"es-MX")} className={"site_translation__language"}>
                    <img className="site_translation__language-default"  src={this.props.languageLocale === "es-MX"
                        ? "/assets/VMS_checked.png"
                        : "/assets/VMS_unchecked.png"}
                    />
                    <span className={"site_translation__language"}>  ESPAÑOL - ES</span>
                    </div>
                    
                </DropdownButton>
                </div>
            </div>
        );
                    }
}
function select(state) {

    return {
        languageLocale: state.appState.getIn(['uiData', 'shared', 'selectedLocale']) || "en-US"
    };
}
export default connect(select)(SiteTranslations);

