import React from 'react';
import './ThankYouComponent.css';
import {defineMessages, FormattedMessage} from 'react-intl';

import * as constants from 'constants/App';
import {Map} from 'immutable';
import {connect} from 'react-redux';

class ThankYouComponent extends React.Component {

    onThankYouClickHandler(event){
        event.preventDefault();

        this.props.dispatch({type: constants.NAVIGATE_TO, path: this.props.loginPersona && this.props.personaDefaultStoreId === undefined ? '/Persona/AccessStores' : this.props.personaDefaultStoreId !== undefined ?  window.history.back() : this.props.login ?
            this.props.isCarWashVendor ==="CARWASH" ?'/carWash': '/' : '/login'});
    }

    render() {

        const messages = defineMessages({
            thanks_title: {
                id: 'thanks_title',
                description: 'Thank you title ',
                defaultMessage: 'Thank you!'
            },
            thanks_sub: {
                id: 'thanks_sub',
                description: 'Thank you sub header ',
                defaultMessage: 'Your message has been sent successfully.'
            },
            return_login: {
                id: 'return_login',
                description: 'Return button',
                defaultMessage: 'Return to main page'
            }

        });


        return <div>
            <h1 className="feedback-thanks__header-format">
                <FormattedMessage {...messages.thanks_title}/>
            </h1>
            <br/>
            <div className="feedback-thanks__subheader-format">
                <text className="feedback-thanks__subheader-format">
                    <FormattedMessage {...messages.thanks_sub}/>
                </text>
            </div>
            <div className="feedback-thanks__return">
                <a href="#" onClick={this.onThankYouClickHandler.bind(this)}>
                    <FormattedMessage {...messages.return_login}/>
                </a>
            </div>
        </div>;


    }

}

export default connect(null)(ThankYouComponent);