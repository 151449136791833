import React from 'react';
import  ReactDoM from 'react-dom';
import {defineMessages, FormattedMessage} from 'react-intl';
import Spinner from 'components/Spinner';
import './VendorTermsAndConditions.css';
import { Overlay, Popover } from 'react-bootstrap';
import ReactDOM from 'react-dom';
class VendorTermsAndConditions extends React.Component {

    
    onAcceptedTermsWithValidation = () => {

        this.props.onInitialsAcceptedTerms(this.txtTcInitials.value)
    }

    handleChange(e) {

        let regexExp = new RegExp("^[a-zA-Z]+$");
        if (!regexExp.test(e.key))
            e.preventDefault();
    
       }
       
    render() {
        const messages = defineMessages({
            read_and_agree: {
                id: 'read_and_agree',
                description: 'I have read and agree to the terms and conditions indicated above.',
                defaultMessage: 'I have read and agree to the terms and conditions indicated above.'
            },
            tc_initials: {
                id: 'tc_initials',
                description: 'Please enter first and last name initials',
                defaultMessage: 'Please enter first and last name initials'
            },
            sign_in: {
                id: 'sign_in',
                description: 'Your initials',
                defaultMessage: 'Your initials'
            },
            accept_terms_and_condition: {
                id: 'accept_terms_and_condition',
                description: 'Please accept Terms and Conditions',
                defaultMessage: 'Please accept Terms and Conditions'
            },
        });
        if (this.props.isFetchingTermsAndConditions ) {

            return <Spinner spinnerType="dark-blue"/>;
        }

        return (
            <div className='terms_conditions__container'>
                <span dangerouslySetInnerHTML={{ __html: this.props.termsAndConditions }}></span>

                <div className='horizontal-line'></div>
                <div className='terms_conditions__accepted row'>
                    <div className='col-md-1 terms_conditions__radio'
                    
                        onClick={this.props.onToggleAcceptedTerms.bind(this)} >
                        <img ref="termsReadAndAgreed"
                             src={this.props.toggleAcceptedFlag ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png"}

                        />

                    </div>
                    <Overlay
                        show={this.props.tcContinueValdiationFlag && !this.props.toggleAcceptedFlag}
                        placement="right"
                        target={this.termsReadAndAgreedMessage}
                        rootClose
                    >                
                        <Popover id="popOver" className="ErrorMessage" >
                            <FormattedMessage {...messages.accept_terms_and_condition } />
                        
                        </Popover>
                    </Overlay>
                    <div ref={(r) => { this.termsReadAndAgreedMessage = r }} className='col-md-10 terms_conditions__accepted-by'>
                        <div className='row'><FormattedMessage {...messages.read_and_agree } /></div>
                    </div>
                </div>
                <Overlay
                    show={this.props.tcContinueValdiationFlag && this.props.tcInitialsAcceptedFlag?.length != 2? true : false}
                    placement="right"
                    target={this.txtTcInitials}
                    rootClose>                
                    <Popover id="popOver" className="ErrorMessage" >
                        <FormattedMessage {...messages.tc_initials } />
                    </Popover>
                </Overlay>
                <div className='initials_input_box_title'><FormattedMessage {...messages.sign_in } />
                    <input  
                      ref={(r) => { this.txtTcInitials = r }}
                        size="6"
                        maxLength="2"
                        className='initials_input_box'
                        onKeyPress={this.handleChange.bind(this)}
                        onKeyUp={this.onAcceptedTermsWithValidation.bind(this)}
                    />
                </div>

            </div>
        );
    }
};


export default VendorTermsAndConditions;

