// libraries
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,FormattedNumber } from 'react-intl';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError,validateEmail,zeroIfUndefined } from 'utils/HelperFunctions';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// our stuff
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import * as DealerRequestActions from '../actions/DealerRequestsView';
import './DealerRequestsView.css';
import Help from 'components/Help';
import ActiveRequests from '../components/ActiveRequestsView.js';
import DealerRequestsSelector from '../selectors/DealerRequestsView';
import ProcessedRequests from '../components/ProcessedRequests.js';

class DealerRequestsView extends React.Component {
    componentDidMount() {

        this.props.dispatch(DealerRequestActions.dealerDraftFetchDealerRequests());

    }


    render() {


        const messages = defineMessages({

            submit: {
                id: "submit",
                description: "Submit",
                defaultMessage: "Submit"
            },
        });

        if (this.props.isFetchingDealerRequests) {
            return <Spinner />;
        }

        return <div>
          <ActiveRequests
              activePOs={this.props.dealerDraftActiveRequests}
              showAll={this.props.showAllActive}
              toggleShowAll={() => this.props.dispatch(DealerRequestActions.dealerDraftToggleShowAllActiveRequests())}
          />
            <ProcessedRequests
                processedPOs={this.props.dealerDraftProcessedRequests}
                showAll={this.props.showAllProcessed}
                toggleShowAll={() => this.props.dispatch(DealerRequestActions.dealerDraftToggleShowAllProcessedRequests())}
            />
        </div>;
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...DealerRequestsSelector(state, props),
        isFetchingDealerRequests:falseIfUndefined(state.appState.getIn(['uiData','dealerDraftDealerRequests','isFetchingDealerRequests'])),
        showAllActive: falseIfUndefined(state.appState.getIn(['uiData','dealerDraftDealerRequests','showAllActiveRequests'])),
        showAllProcessed: falseIfUndefined(state.appState.getIn(['uiData','dealerDraftDealerRequests','showAllProcessedRequests'])),

    }
}



export default connect(mapStateToProps)(DealerRequestsView);
