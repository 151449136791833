import React,{ useState,useRef} from 'react';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,RawIntlProvider,injectIntl } from 'react-intl';
import { Input, OverlayTrigger, Popover } from 'react-bootstrap';
import './OdometerUpdate.css';
import Spinner from 'components/Spinner';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import classnames from 'classnames';
import {falseIfUndefined,getFormattedPersonaUserName} from 'utils/HelperFunctions';
import *  as fieldValidations from 'utils/FieldValidations';
import { useAppState } from 'shared/AppState/app-state';


    const OdometerUpdate = (props) => {    
    const [appState, dispatch] = useAppState();
    const [hourMeterUpdated,setHourMeterUpdated]=useState(false);
    const [odometerErrorMessage,setOdometerErrorMessage]=useState(undefined);
    const [engineHoursErrorMessage,setEngineHoursErrorMessage]=useState(undefined);
    const [nonRoadVehicleErrorMessage, setNonRoadVehicleErrorMessage]=useState(undefined);
    const [refNumberErrorMessage,setRefNumberErrorMessage]=useState(undefined);
    const [overlayOdometerShown, setOverlayOdometerShown]=useState(false);
    const [overlayEngineHoursShown, setOverlayEngineHoursShown]=useState(false);
    const [overlayShopShown, setOverlayShopShown]=useState(false);
    const [overlayRefNumberShown, setOverlayRefNumberShown]=useState(false);
    const txtEngineHours=useRef(null);
    const txtOdometer=useRef(null);
    const txtRefNumber=useRef(null);
    const txtReqName=useRef(null);
    const txtClientRefNumber=useRef(null);

   const onVehicleAtShopChangeHandler=(value, event)=> {
        event.preventDefault();
        var odometerData = Map({
            isVehicleAtShop: value
        });

        props.onVehicleAtShopSelectionChange(value);
    };

    const validateOdometerDetails = ()=> {

        var result = helperFunctions.validateOdometerDetails(txtOdometer.current,
            txtEngineHours.current,
            hourMeterUpdated,
            props.vehicleDetails);

        //udpate 
        if (result.updateEngineHoursWithOdometer)
            txtEngineHours.current.value = txtOdometer.current.value;

        if (result.HourMeterUpdated)            
            setHourMeterUpdated(true);

        if (result.NonRoadVehicleErrorMessageUpdated)
            setNonRoadVehicleErrorMessage(result.NonRoadVehicleErrorMessage);

        if (result.OdometerErrorMessageUpdated)
            setOdometerErrorMessage(result.OdometerErrorMessage);
            

        const isErrorMessageSet = result.isErrorMessageSet;

        if (isErrorMessageSet) {
            setOverlayOdometerShown(true);
            return false;
        }
        else {            
            setOdometerErrorMessage(undefined);
            setOverlayOdometerShown(false);

        }
        return true;
    }
    const ontxtOdometerChange=(event)=> {

        event.preventDefault();

        if (props.odometerData.get('newOdometer') != txtOdometer.current.value) {
               
            validateOdometerDetails();
                saveOdometerDetails();
       
        }

    }

   const ontxtEngineHoursChange=(event)=> {
        event.preventDefault();
        if (props.odometerData.get('newEngineHours') != txtEngineHours.current.value) {
          
            validateEngineHours();
            saveOdometerDetails();
        }
    }

    const validateEngineHours=() =>{
        const result = helperFunctions.validateEngineHours(txtEngineHours.current, props.vehicleDetails);

        setEngineHoursErrorMessage(result.EngineHoursErrorMessage);

        if (result.showOverlayEngineHours)
            setOverlayEngineHoursShown(true);
        else
            setOverlayEngineHoursShown(false);

        return result.isValid;
    }

    const validateOdometerDetails1=()=> {
        var result = helperFunctions.validateOdometerDetails(txtOdometer,
            txtEngineHours,
            hourMeterUpdated,
            props.vehicleDetails);

        //udpate 
        if (result.updateEngineHoursWithOdometer)
            txtEngineHours.current.value= txtOdometer.current.value;

        if (result.HourMeterUpdated)            
            setHourMeterUpdated(true);

        if (result.NonRoadVehicleErrorMessageUpdated)
            setNonRoadVehicleErrorMessage(result.NonRoadVehicleErrorMessage);

        if (result.OdometerErrorMessageUpdated)
            setOdometerErrorMessage(result.OdometerErrorMessage);

        const isErrorMessageSet = result.isErrorMessageSet;

        if (isErrorMessageSet) {
            setOverlayOdometerShown(true);
            return false;
        }
        else {
            setOdometerErrorMessage(undefined);
            setOverlayOdometerShown(false);

        }
        return true;
    }

    const saveOdometerDetails=()=> {

        props.onSaveVehicleDetails(txtOdometer.current.value, txtEngineHours.current.value, fieldValidations.isVisible(props.fieldValidations, 'REFERENCE_NO') ? txtRefNumber.current.value: '', txtReqName.current.value,txtClientRefNumber.current.value);

    }
   
    const onSaveUpdateClick=(event)=> {
        event.preventDefault();

        saveOdometerDetails();

        setOdometerErrorMessage('ododmeter_Validation_Message_6');
        setOverlayOdometerShown(false);
        setOverlayShopShown(false);
         var refNumberCheck  = true, odometerCheck  = true ,isVehicleAtShopCheck  = true;

         if (fieldValidations.isVisible(props.fieldValidations, 'REFERENCE_NO')) {
            setOverlayRefNumberShown(false);
             const referenceNumberValidationErrorMessage = fieldValidations.getValidationErrorMessage(props.fieldValidations, 'REFERENCE_NO', txtRefNumber.current.value);
             if (referenceNumberValidationErrorMessage !== null) {
                 setRefNumberErrorMessage(referenceNumberValidationErrorMessage);
                 setOverlayRefNumberShown(true);
                 refNumberCheck = false;
             } else {
                 setRefNumberErrorMessage(undefined);
                 setOverlayRefNumberShown(false);
                 refNumberCheck = true;
             }
         } 

        if (props.odometerData.get('newOdometer') == undefined || props.odometerData.get('newOdometer').trim().length === 0 || (props.odometerData.get('newOdoisVehicleAtShopmeter'))) {
            setOverlayOdometerShown(true);
            odometerCheck = false;
        } else {
            setOdometerErrorMessage(undefined);

            setOverlayOdometerShown(false);
            odometerCheck = true;
        }
        if ( props.odometerData.get('isVehicleAtShop') == undefined) {
            setOverlayShopShown(true);
            isVehicleAtShopCheck = false;
        } else {
            setOverlayShopShown(false);
            isVehicleAtShopCheck = true;
        }

        if(refNumberCheck && odometerCheck && isVehicleAtShopCheck )
        props.onSaveUpdateClick();
    }

    const resetMaxValue=(maxLength, event)=> {
        event.preventDefault();

        if (event.target.value != undefined && event.target.value.length > maxLength) {
            event.target.value = event.target.value.slice(0, maxLength);
        }
    }   
        



        /* Globalization of labels */
        const messages = defineMessages({
            odometer_details_caption: {
                id: 'odometer_details_caption',
                description: 'Required update',
                defaultMessage: 'Required update'
            },
            odometer_details_odometer: {
                id: 'odometer_details_odometer',
                description: 'Odometer',
                defaultMessage: 'Odometer'
            },
            odometer_details_last_reading: {
                id: 'odometer_details_last_reading',
                description: 'Last reading',
                defaultMessage: 'Last reading'
            },
            odometer_details_engine_hours: {
                id: 'odometer_details_engine_hours',
                description: 'Hour meter',
                defaultMessage: 'Hour meter'
            },
            odometer_details_optional: {
                id: 'odometer_details_optional',
                description: 'optional display',
                defaultMessage: ' (if equipped)'
            },
            odometer_details_select_one: {
                id: 'odometer_details_select_one',
                description: 'Select One',
                defaultMessage: 'Select One'
            },
            odometer_details_at_shop: {
                id: 'odometer_details_at_shop',
                description: 'Vehicle is at the shop',
                defaultMessage: 'Vehicle is at the shop'
            },
            odometer_details_not_at_shop: {
                id: 'odometer_details_not_at_shop',
                description: 'Vehicle is not at the shop',
                defaultMessage: 'Vehicle is not at the shop'
            },
            odometer_details_save_update: {
                id: 'odometer_details_save_update',
                description: 'Confirm Vehicle and go to the next step',
                defaultMessage: 'Confirm Vehicle and go to the next step'
            },
            odometer_details_unavailable: {
                id: 'odometer_details_unavailable',
                description: 'Unavailable',
                defaultMessage: 'Unavailable'
            },
            ododmeter_Validation_Message_1: {
                id: 'ododmeter_Validation_Message_1',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.'
            },
            ododmeter_Validation_Message_2: {
                id: 'ododmeter_Validation_Message_2',
                description: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.',
                defaultMessage: 'Warning: The meter entered exceeds the estimated meter by: {newOdometer}.'
            },
            ododmeter_Validation_Message_3: {
                id: 'ododmeter_Validation_Message_3',
                description: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.'
            },
            ododmeter_Validation_Message_4: {
                id: 'ododmeter_Validation_Message_4',
                description: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.',
                defaultMessage: 'Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.'
            },
            ododmeter_Validation_Message_5: {
                id: 'ododmeter_Validation_Message_5',
                description: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.',
                defaultMessage: 'Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.'
            },
            hourmeter_Validation_Message: {
                id: 'hourmeter_Validation_Message',
                description: 'Warning: Hour meter cannot be less than last recorded value.',
                defaultMessage: 'Warning: Hour meter cannot be less than last recorded value.'
            },
            ododmeter_Validation_Message_6: {
                id: 'ododmeter_Validation_Message_6',
                description: 'Please enter Odometer value',
                defaultMessage: 'Please enter Odometer value'
            },
            shop_select: {
                id: 'shop_select',
                description: 'Odometer shop or not selection error message',
                defaultMessage: 'Please Select One!'
            },
            driver_validation_text: {
                id: 'driver_validation_text',
                description: 'validation warning for drivers who need to call Holman',
                defaultMessage: 'This purchase order requires driver validation. Advise driver to validate repairs with Holman prior to performing work.s'

            },
            driver_reference_number_title: {
                id: 'driver_reference_number_title',
                description: 'title for reference number section',
                defaultMessage: 'Your reference#'
            },
            driver_reference_number_subTitle: {
                id: 'driver_reference_number_subTitle',
                description: 'subtitle for reference number section',
                defaultMessage: 'Invoice#/RO#/WO#'
            },
            po_requester_title: {
                id: 'po_requester_title',
                description: 'Name for issuer',
                defaultMessage: 'Your Name'
            },
            reference_number_validation_message: {
                id: 'reference_number_validation_message',
                description: 'reference_number_validation_message',
                defaultMessage: 'Please input your 6 digit reference number'
            },
            client_po_title: {
                id: 'client_po_title',
                description: 'client_po_title',
                defaultMessage: 'Client-provided PO#'
            },
            client_po_subtitle: {
                id: 'client_po_subtitle',
                description: 'client_po_subtitle',
                defaultMessage: '(if available)'
            },
        });

        const odometerData = props.odometerData.toJSON();
        const vehicleDetails = props.vehicleDetails.toJSON();
        
        return (
        <div className="container-fluid odometer_update_box">
            <div className="row odometer_update__caption">
                <div className="col-md-12">
                    <FormattedMessage {...messages.odometer_details_caption} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {props.driverValidationFlag ?
                        <div className="odometer_driver-validation__div-placement">
                            <div className="odometer_driver-validation__div">
                                <div className="odometer_driver-validation__text">
                                    <FormattedMessage {...messages.driver_validation_text} />
                                </div>
                            </div>
                        </div>
                        : undefined
                    }
                    <div className="row">
                        <div className="col-md-4 odometer_update_col__label">
                            <FormattedMessage {...messages.odometer_details_odometer} />
                        </div>
                        <div className="col-md-3">
                            <OverlayTrigger show={overlayOdometerShown}
                                placement="right"
                                trigger="manual"

                                overlay={
                                    <Popover id="popOverOdometer" className="ErrorMessage">
                                       <RawIntlProvider value={props.intl}>
                                        {odometerErrorMessage == undefined ? false :
                                            <FormattedMessage
                                                values={{
                                                    newOdometer: txtOdometer.current.value,
                                                    odometerDifference: (txtOdometer.current.value != '' && props.vehicleDetails.toJSON().lastOdometerReading != undefined && props.vehicleDetails.toJSON().lastOdometerReading != '') ?
                                                        Number(txtOdometer.current.value) - Number(props.vehicleDetails.toJSON().lastOdometerReading) : '',

                                                    estimatedOdometer: props.vehicleDetails.toJSON().estimatedOdometer,
                                                    currentDate: (new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear(),
                                                    estimatedOdometerDiff: props.vehicleDetails.toJSON().estimatedOdometer != undefined && props.vehicleDetails.toJSON().estimatedOdometer != '' ? Number(props.vehicleDetails.toJSON().estimatedOdometer) - (txtOdometer.current.value == undefined ? 0 : Number(txtOdometer.current.value)) : ''

                                                }}

                                                {...messages[odometerErrorMessage]}
                                            />}
                                        {nonRoadVehicleErrorMessage != undefined && odometerErrorMessage != undefined ?
                                            <br /> : ''}
                                        {nonRoadVehicleErrorMessage == undefined ? false :
                                            <FormattedMessage {...messages[nonRoadVehicleErrorMessage]} />}
                                             </RawIntlProvider>
                                    </Popover>}

                            >

                                <DynamicNumber
                                    className="odometer_update__input-box"
                                    onInput={resetMaxValue.bind(this, 6)}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    fraction={0}
                                    onBlur={ontxtOdometerChange.bind(this)}
                                    value={odometerData.newOdometer !== undefined ? odometerData.newOdometer : ""}
                                    ref={txtOdometer}
                                />
                            </OverlayTrigger>


                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 odometer_update_col_unedit__label">
                            <FormattedMessage {...messages.odometer_details_last_reading} />
                        </div>
                        <div className="col-md-3 odometer_update_col_unedit__val">
                            {vehicleDetails.lastOdometerReading == undefined || vehicleDetails.lastOdometerReading == '' ?
                                <FormattedMessage {...messages.odometer_details_unavailable} /> : vehicleDetails.lastOdometerReading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="odometer_update_col__label">
                                <FormattedMessage {...messages.odometer_details_engine_hours} />
                            </div>
                            <div className="odometer_update_col__label_optional">
                                <FormattedMessage {...messages.odometer_details_optional} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <OverlayTrigger show={overlayEngineHoursShown}
                                placement="right"
                                trigger="manual"


                                overlay={
                                    <Popover id="popOverEngineHours" className="ErrorMessage">
                                         <RawIntlProvider value={props.intl}>
                                        {engineHoursErrorMessage == undefined ? false :
                                            <FormattedMessage {...messages[engineHoursErrorMessage]} />}
                                            </RawIntlProvider>
                                    </Popover>}

                            >
                                <DynamicNumber
                                    className="odometer_update__input-box"
                                    onInput={resetMaxValue.bind(this, 6)}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    fraction={0}
                                    onBlur={ontxtEngineHoursChange.bind(this)}
                                    value={odometerData.newEngineHours != undefined ? odometerData.newEngineHours : ""}
                                    ref={txtEngineHours}
                                />

                            </OverlayTrigger>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 odometer_update_col_unedit__label">
                            <FormattedMessage {...messages.odometer_details_last_reading} />
                        </div>
                        <div className="col-md-3 odometer_update_col_unedit__val">
                            {vehicleDetails.lastHourMeterReading == undefined || vehicleDetails.lastHourMeterReading == '' ?
                                <FormattedMessage {...messages.odometer_details_unavailable} /> : vehicleDetails.lastHourMeterReading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 odometer_update_select">
                            <FormattedMessage {...messages.odometer_details_select_one} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <OverlayTrigger show={overlayShopShown}
                                placement="right"
                                trigger="manual"


                                overlay={
                                    <Popover id="popOverShopYes" className="ErrorMessage">
                                             <RawIntlProvider value={props.intl}>
                                        <FormattedMessage {...messages.shop_select} />
                                        </RawIntlProvider>
                                    </Popover>}

                            >
                                <div className="odometer_update__radio"
                                    onClick={onVehicleAtShopChangeHandler.bind(this, true)}>
                                    <img src={!odometerData.isVehicleAtShop || odometerData.isVehicleAtShop == undefined
                                        ? "/assets/VMS_unchecked.png"
                                        : "/assets/VMS_checked.png"}
                                    />
                                    {' '} <FormattedMessage {...messages.odometer_details_at_shop} />

                                </div>
                            </OverlayTrigger>

                            <div className="odometer_update__radio"
                                onClick={onVehicleAtShopChangeHandler.bind(this, false)}>
                                <img src={odometerData.isVehicleAtShop || odometerData.isVehicleAtShop == undefined
                                    ? "/assets/VMS_unchecked.png"
                                    : "/assets/VMS_checked.png"}
                                />
                                {' '} <FormattedMessage {...messages.odometer_details_not_at_shop} />

                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 ">
                            <div className="odometer_update_col__label">
                                <FormattedMessage {...messages.po_requester_title} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            {appState.personaUser!=undefined && appState.personaUser!=null?
                            <input className="odometer_update__input-box"
                            ref={txtReqName}
                            cols="6"
                            size="6"
                            maxLength="15"
                            value={getFormattedPersonaUserName(appState.personaUser.firstName,appState.personaUser.lastName)}                            
                            readOnly={true}
                            disabled={true}
                        />:

                            <input className="odometer_update__input-box"
                                ref={txtReqName}
                                cols="6"
                                size="6"
                                maxLength="15"
                                onKeyUp={saveOdometerDetails.bind(this)}
                                
                            />
                            }
                        </div>
                    </div>
                    
                    {fieldValidations.isVisible(props.fieldValidations,'REFERENCE_NO') ?
                    <div>
                    <div className="row">
                        <div className="col-md-4 ">
                            <div className="odometer_update_col__label">
                                <FormattedMessage {...messages.driver_reference_number_title} />
                            </div>
                        </div>
                        <OverlayTrigger show={overlayRefNumberShown}
                                        placement="right"
                                        trigger="manual"


                                        overlay={
                                            <Popover id="popOverRefNumber" className="ErrorMessage">
                                                  <RawIntlProvider value={props.intl}>
                                                {refNumberErrorMessage == undefined ? false :
                                                    refNumberErrorMessage}
                                                    </RawIntlProvider>
                                            </Popover>}

                        >

                        <div className="col-md-3">

                            <input className="odometer_update__input-box"
                                ref={txtRefNumber}
                                cols="6"
                                size="6"
                                maxLength="25"
                                onKeyUp={saveOdometerDetails.bind(this)}
                            />
                        </div>
                        </OverlayTrigger>
                    </div>
                    <div className="row">
                        <div className="col-md-4 odometer_update_col_unedit__label">
                            <FormattedMessage {...messages.driver_reference_number_subTitle} />
                        </div>
                    </div>
                    </div>
                   : undefined 
                   }         
                   <div className="row">
                        <div className="col-md-4 ">
                            <div className="odometer_clientPO_col__label">
                            <FormattedMessage {...messages.client_po_title} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <input className="odometer_update__input-box"
                                ref={txtClientRefNumber}
                                cols="6"
                                size="6"
                                maxLength="20"
                                value={txtClientRefNumber.current != null ? txtClientRefNumber.current.value.toUpperCase() : ""}
                                onChange={saveOdometerDetails.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 odometer_update_col__label_optional">
                        <FormattedMessage {...messages.client_po_subtitle} />
                        </div>
                    </div>
                    <div className="row odometer_update__save" style={{textAlign:'right'}}>
                        <div className={classnames("row",
                            {"row" : props.selectedLocale === "en-US"},
                            {"odometer_updated__disabled" :props.isCreatingPO})}>
                            <a onClick={onSaveUpdateClick.bind(this)}>
                                <FormattedMessage {...messages.odometer_details_save_update} />
                                {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                            </a>
                            <div className="inline-block">
                                {props.isCreatingPO ?
                                    <Spinner spinnerType="blue" />
                                    : undefined
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );    
};




export default injectIntl(OdometerUpdate);