import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import ContactInformation from '../components/ContactInformationComponent';
import FeedbackFormComponent from '../components/FeedbackFormComponent';
import QuestionSelectionComponent from '../components/QuestionSelectionsComponent';
import ThankYouComponent from '../components/ThankYouComponent';
import './FeedbackFormView.css';
import {connect} from 'react-redux';
import {isAuthenticated,falseIfUndefined,isPersonaAuthenticated} from 'utils/HelperFunctions';
import * as FeedbackFormViewActions from '../actions/FeedbackFormView';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import FormWrapper from 'components/FormWrapper';
import configuration from 'configuration';
import {Map} from "immutable";

class FeedbackFormView extends React.Component {

    submitHandler(event){

        event.preventDefault();

       if(typeof window !== 'undefined'
           && typeof window.grecaptcha !== 'undefined'
           && typeof window.grecaptcha.render === 'function') {
           let feedBackData = Map({
               reCaptchaVerificationToken: !this.props.login && !this.props.loginPersona ? grecaptcha.getResponse() : ""
           });
           let reCaptchaCheck = feedBackData.map((x) => {
               return x
           }).first();

           this.onSaveHandler(feedBackData);

           if (reCaptchaCheck !== "" && !this.props.login && !this.props.loginPersona) {
               this.props.dispatch({type: constants.SEND_FEEDBACK_FORM_CLICKED});
           }
       }
       if(this.props.login || this.props.loginPersona){
        this.props.dispatch({type: constants.SEND_FEEDBACK_FORM_CLICKED});
       }
       
    }

    onSaveHandler(postData){

        this.props.dispatch(FeedbackFormViewActions.saveFeedbackForm(postData));

    }

    componentDidMount() {

        this.props.dispatch(FeedbackFormViewActions.mountDataFeedbackForm());
        this.props.dispatch({type:constants.CLEAR_ERROR_MESSAGE});
    }

    onDropHandler(postData){

        this.props.dispatch(FeedbackFormViewActions.saveDataFeedbackFormSelect(postData));


    }
    
    NavigateToHelpFAQ(event){    
        event.preventDefault();        
        this.props.dispatch({type: constants.NAVIGATE_TO, path: '/contact/HelpFAQ'});
    }

    render() {
        const {country,form,isSendingData,isApprovedData} = this.props;
 
        const isUserLoggedIn = this.props.login  || this.props.loginPersona;
        const messages = defineMessages({
            feedback_form_header: {
                id: 'feedback_form_header',
                description: 'Header for feedback form non public page',
                defaultMessage: 'Feedback Form'
            },
            faq_footer1: {
                id: 'faq_footer1',
                description: 'Help FAQ quick link',
                defaultMessage: 'You may find answers to your questions and concerns in our '
            },
            faq_footer2: {
                id: 'faq_footer2',
                description: 'Frequently Asked Questions',
                defaultMessage: 'Frequently Asked Questions.'
            }, active_address_header: {
                id: 'active_address_header',
                description: 'Address of contact information',
                defaultMessage: 'Holman Global Headquarters <br/> 4001 Leadenhall Road <br/> Mount Laurel, NJ 08054'
            }, canadian_active_address_header: {
                id: 'canadian_active_address_header',
                description: 'Canadian address of contact information',
                defaultMessage: 'Holman Financial Services Inc. <br/> 1270 Central Parkway W. <br/> Suite 600 <br/> Mississauga, Ontario L5C 4P4 <br/> 800-361-5882'
            }, mexican_active_address_header:{
                id:'mexican_active_address_header',
                description:'Boulevard Manuel Avila Camacho #685 <br/> Fraccionamiento Industrial Alce Blanco <br/>    Naucalpan Mexico 53370 ',
                defaultMessage: 'Boulevard Manuel Avila Camacho #685 <br/> Fraccionamiento Industrial Alce Blanco <br/>    Naucalpan Mexico 53370 ',
            }
        });
        if (isSendingData) {
            return <Spinner />;
        }

        return <div>

            {isApprovedData == true  ?  <ThankYouComponent login={this.props.login} loginPersona={this.props.loginPersona} personaDefaultStoreId={this.props.personaDefaultStoreId} isCarWashVendor={this.props.isCarWashVendor} />
                : <div>
                 <FormWrapper key='feedbackFormGenericErrorMessage' 
                         id="feedbackFormGenericErrorMessage" {...this.props} 
                         formErrors={this.props.feedbackFormGenericErrorMessage} />

                 <FormWrapper key='feedbackFormErrorMessage' 
                         id="feedbackFormErrorMessage" {...this.props} 
                         formErrors={this.props.feedbackFormErrorMessage} />
                    {this.props.country.get('selectedCountry') === "USA" ?  <div className="row feedback__spacing">
                        <div className="col-sm-4 feedback-information__address-header">

                            <FormattedMessage {...messages.active_address_header} values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>

                        </div>
                        <h3 className="col-8 feedback-title__header">
                            <FormattedMessage {...messages.feedback_form_header}/>
                        </h3>
                    </div>
                        :
                        this.props.country.get('selectedCountry')==='MEX'? <div className="row feedback__spacing">
                        <div className="feedback-information__address-header">

                            <FormattedMessage {...messages.mexican_active_address_header} values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>

                        </div>
                        <h3 className="feedback-title__header">
                            <FormattedMessage {...messages.feedback_form_header}/>
                        </h3>
                    </div>
                    :

                        <div className="row feedback__spacing">
                            <div className="feedback-information__address-header">

                                <FormattedMessage {...messages.canadian_active_address_header} values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>

                            </div>
                            <h3 className="feedback-title__header-canadian">
                                <FormattedMessage {...messages.feedback_form_header}/>
                            </h3>
                        </div>
                    }
                <div>
                    <ContactInformation country={country.get('selectedCountry')}  login={isUserLoggedIn}/>
                </div>
                <div>
                    <QuestionSelectionComponent login={isUserLoggedIn}
                                               onSaveForm={this.onDropHandler.bind(this)}/>
                </div>

                    <p>&nbsp;</p>
                    <div>
                        <FeedbackFormComponent login={isUserLoggedIn} history={this.props.history}
                                               onSaveForm={this.onSaveHandler.bind(this)}
                                               onSubmitter={this.submitHandler.bind(this)}
                                               reCaptchaKey={configuration.reCaptchaKey}
                                               reCaptchaVerificationToken={this.props.reCaptchaVerificationToken}
                        />

                    </div>
                    <div className="feedback-helpLink__div">
                        <FormattedMessage {...messages.faq_footer1}/>
                        <a href='#' onClick={this.NavigateToHelpFAQ.bind(this)}><FormattedMessage {...messages.faq_footer2}/></a>
                    </div>


            </div>

            }
        </div>;

    }


}

function select(state) {

    return {
        country: state.appState.getIn(['uiData', 'shared']),
        form: state.appState.getIn(['uiData','feedbackForm']),
        isSendingData: falseIfUndefined(state.appState.getIn(['uiData', 'feedbackForm','isSendingData'])),
        login:isAuthenticated(state),
        loginPersona: isPersonaAuthenticated(state),
        isApprovedData: state.appState.getIn(['uiData', 'feedbackForm','isApprovedData']),
        feedbackFormGenericErrorMessage:state.appState.getIn(['uiData','shared','errorMessage']),
        feedbackFormErrorMessage:state.appState.getIn(['uiData','feedbackForm','errorMessage']),
        isCarWashVendor:state.appState.getIn(['serverData','shared','serviceClassification']),
        reCaptchaVerificationToken: state.appState.getIn(['uiData', 'feedbackForm', 'reCaptchaVerificationToken']),
        personaDefaultStoreId: state.appState.getIn(['serverData', 'shared', 'personaUser','defaultStoreId'])
    };
}

export default connect(select)(FeedbackFormView);
