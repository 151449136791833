import {OrderedMap,Map} from 'immutable';

const documentTypes = {
    vendor: OrderedMap({
        1:Map({type:'VEN-Certificate of Liability Insurance',displayName:'Certificate of Liability Insurance'}),
        2:Map({type:'VEN-Authorized Dealer Certificates',displayName:'Authorized Dealer Certificates'}),
        3:Map({type:'VEN-Authorized Repair Facility Certificates',displayName:'Authorized Repair Facility Certificates'}),
        4:Map({type:'VEN-Employee Training Certificates (ASE)',displayName:'Employee Training Certificates (ASE)'}),
        5:Map({type:'VEN-Minority Certificates',displayName:'Minority Certificates'}),
        6:Map({type:'VEN-General Shop Information',displayName:'General Shop Information'}),
        //7:Map({type:'VEN-Client Specific Agreements',displayName:'Client Specific Agreements'}),        
    }),    
};

export default documentTypes;