// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
import moment from 'moment';
// Our stuff
import './FileList.css';
import DownloadButton from './buttons/DownloadButton';
import TruncateWithTooltip from 'components/TruncateWithTooltip';
import Submit from 'components/Submit';
import DownloadAJAXFile from 'components/DownloadAJAXFile';

const Header = (props) => {        
    const messages = defineMessages({
            documents_header_name: {
                id: 'documents_header_name',
                description: 'Header for name',
                defaultMessage: 'Name'
            },
            documents_header_upload_date: {
                id: 'documents_header_upload_date',
                description: 'Header for upload date',
                defaultMessage: 'Upload date'
            },
            documents_header_document_type: {
                id: 'documents_header_document_type',
                description: 'Header for document type',
                defaultMessage: 'Document type'
            }
        });

    return <thead>
        <tr className='documents__file_list_row'>
            <th className='documents__file_list_cell documents__file_list_cell--first col-sm-5'>
                <FormattedMessage {...messages.documents_header_name}/>
            </th>
            <th className='documents__file_list_cell col-sm-5'>
                <FormattedMessage {...messages.documents_header_document_type}/>
            </th>
            <th colSpan='2' className='documents__file_list_cell col-sm-2'>
                <FormattedMessage {...messages.documents_header_upload_date}/>
            </th>            
        </tr>
    </thead>
}

const FileRow = (props) => {        
    const localDateTime = moment.utc(props.file.get('createdDate')).local().format('MM/DD/YYYY');
    
    return <tr className='documents__file_list_row'>
        <td className='documents__file_list_cell documents__file_list_cell--first col-sm-5'>
            <TruncateWithTooltip maxLength={25}>
                {props.file.get('name')}
            </TruncateWithTooltip>
        </td>
        <td className='documents__file_list_cell col-sm-5'>
            <TruncateWithTooltip maxLength={25}>
                {props.file.get('documentType')}
            </TruncateWithTooltip>
        </td>
        <td className='documents__file_list_cell col-sm-1'>
            {localDateTime}            
        </td>        
        <td className='documents__download_cell col-sm-1'>            
            <DownloadAJAXFile url={props.url}>
                <DownloadButton/>
            </DownloadAJAXFile>            
        </td>
    </tr>
}

const Body = (props) => {            
    const messages = defineMessages({
            documents_none: {
                id: 'documents_none',
                description: 'No documents to display',
                defaultMessage: 'None at this time'
            }
        });

    if (props.files && props.files.count() > 0) {
        return <tbody>
            { props.files.map((file, key) => {                                                                        
                let id = file.get('id');
                let url = props.urlTemplate.replace('{0}', id);
                return <FileRow {...{key,file,url}} />                
            }).valueSeq()}
        </tbody>
    }
    else {
        return (
            <tbody className='documents__none'>
                <tr>
                    <td colSpan={4}>
                        <FormattedMessage {...messages.documents_none}/>
                    </td>
                </tr>
            </tbody>
        );
    }
}

const Footer = (props) => {        
    const messages = defineMessages({            
            show_more: {
                id: 'show_more',
                description: 'Show more',
                defaultMessage: 'Show more'
            },
            show_less: {
                id: 'show_less',
                description: 'Show less',
                defaultMessage: 'Show less'
            }
        });

    return <tfoot>
        <tr>
            <td colSpan={4} className='documents__footer_cell'>                        
                {props.showAll ?
                    <Submit caption={<FormattedMessage {...messages.show_less}/>}
                            imagePath="/assets/VMS_button_hide_older_pos.png"
                            onClickHandler={props.onShowLessClick}/>
                : 
                    <Submit caption={<FormattedMessage {...messages.show_more}/>}
                            imagePath="/assets/VMS_button_show_all_pos.png"
                            onClickHandler={props.onShowMoreClick}/>
                }
            </td>
        </tr>
    </tfoot>
}

const FileList = (props) => {                
    return <div className='documents__file_list'>        

        <table className='documents__file_list_table' width="100%">

            <Header/>

            <Body files={props.files} urlTemplate={props.urlTemplate}/>

            { props.moreFilesToShow && 
                <Footer 
                    showAll={props.showAll}
                    onShowLessClick={props.onShowLessClick} 
                    onShowMoreClick={props.onShowMoreClick}/>
            }
        </table>
    
    </div>
}

export default FileList;