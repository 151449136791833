import { Map, fromJS } from 'immutable';
import * as constants from 'constants/App';
import * as sharedActions from 'actions/App';
import { normalize, Schema, arrayOf } from 'normalizr';
import { emptyMapIfUndefined } from 'utils/HelperFunctions';
import moment from 'moment';

export function submitDetailInfo() {
    return { type: constants.SUBMIT_ENROLLMENT_DETAIL_INFO }
}

export function resetEnrollmentInfo() {
    return { type: constants.RESET_ENROLLMENT_INFO };
}

export function formIsDirty() {
    return { type: constants.ENROLLMENT_FORM_IS_DIRTY, isDirty:true }
}

export function addRequestInfo(requestInfo) {
    return { type: constants.UPDATE_ENROLLMENT_DETAIL_INFO, requestInfo }
}

export function selectedBusiness(isSelectedBusiness) {
    return { type: "SELECTED_SERVICE_CLASSIFICATION", isSelectedBusiness }
}