import React from 'react';
import { Redirect, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import * as VendorEnrollmentRequestViewActions from '../actions/VendorEnrollmentRequestView';
import Request from '../components/Request';
import EnrollmentConfirmation from '../components/EnrollmentConfirmation';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { renderError } from 'utils/HelperFunctions';
import VendorEnrollmentRequestViewSelector from '../selectors/VendorEnrollmentRequestView';
import { fromJS } from 'immutable';
import {defineMessages, injectIntl} from 'react-intl';
import configuration from 'configuration';



class VendorEnrollmentRequestView extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //clear out the state for both request and enrollment
        this.props.dispatch(VendorEnrollmentRequestViewActions.resetForm());
    }

    onRequestInfoChangeHandler(inputName, event) {
        let requestInfo = {
            name: inputName,
            value: event.target.value
        };
        this.props.dispatch(VendorEnrollmentRequestViewActions.addRequestInfo(requestInfo));
        this.props.dispatch(VendorEnrollmentRequestViewActions.formIsDirty());

    }
    scrollToTop(){
        if(this.props.enrollmentRequestErrorMessage!=undefined 
            && typeof(this.props.enrollmentRequestErrorMessage) != 'string'
            && this.props.enrollmentRequestErrorMessage.length>0){
            window.scrollTo(0,0);
        }
    }
    onSubmitRequestHandler() {
        if(typeof window !== 'undefined'
            && typeof window.grecaptcha !== 'undefined'
            && typeof window.grecaptcha.render === 'function') {
            let requestInfo = {
                name: constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.RECAPTCHA_VERIFICATION_TOKEN,
                value: grecaptcha.getResponse() != undefined ? grecaptcha.getResponse() : ""
            };

            this.props.dispatch(VendorEnrollmentRequestViewActions.addRequestInfo(requestInfo));

            if (requestInfo.value !== "") {
                this.props.dispatch(VendorEnrollmentRequestViewActions.submitRequestInfo());
                setTimeout(this.scrollToTop.bind(this), 250);
            }
        }else{
            this.props.dispatch(VendorEnrollmentRequestViewActions.submitRequestInfo());
            setTimeout(this.scrollToTop.bind(this), 250);
        }
    }


    render() {
        const messages = defineMessages({
            unsaved_data: {
                id: 'unsaved_data',
                description: 'unsaved_data',
                defaultMessage: "You have unsaved changes that will be lost if you decide to continue. Click OK to continue or Cancel to stay on the same page."
            },
        });
        const {formatMessage} = this.props.intl;
        return (
            <div>

                {
                    !this.props.isSubmitting ?
                        <Prompt when={this.props.isDirty == true} message={formatMessage(messages.unsaved_data)} />
                        : undefined
                        }
        {
        //render server error                    
                    renderError('genericErrorMessage', this.props, this.props.genericErrorMessage)
    }
    {
        !this.props.requestSubmitted ?
            <Request enrollmentRequestInfo={this.props.enrollmentRequestInfo}
                onRequestInfoChange={this.onRequestInfoChangeHandler.bind(this)}
        usStates={this.props.usStates.toJSON()}
        onSubmit={this.onSubmitRequestHandler.bind(this)}
        isRequestFormValid={this.props.isRequestFormValid}
        requestSubmitted={this.props.requestSubmitted}
        error={renderError('enrollmentRequestErrorMessage', this.props, this.props.enrollmentRequestErrorMessage)} 
        canadianProvinces={this.props.canadianProvinces.toJSON()}
                     reCaptchaKey={configuration.reCaptchaKey}
                     reCaptchaVerificationToken={this.props.reCaptchaVerificationToken}/>
                        : <EnrollmentConfirmation />
                        }
            </div>
        );
                        }
};

function mapStateToProps(state, props) {
    return {
        ...VendorEnrollmentRequestViewSelector(state, props),
        enrollmentRequestInfo: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'enrollmentRequestInfo']),
        usStates: fromJS(state.appState.getIn(['uiData', 'shared', 'usStates'])),
        enrollmentStep: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'enrollmentStep']),
        confirmationMessage: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'confirmationMessage']),
        termsAndConditions: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'enrollmentTermsAndConditions']),
        federalTaxClassifications: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'federalTaxClassifications']),
        liabilityTaxClassification: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'liabilityTaxClassification']),
        requestSubmitted: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'requestSubmitted']),
        enrollmentRequestErrorMessage: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'errorMessage']),
        genericErrorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        isDirty: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'isDirty']),
        isSubmitting: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'isSubmitting']),
        canadianProvinces:fromJS(state.appState.getIn(['uiData', 'shared', 'canadianProvinces'])),
        reCaptchaVerificationToken: state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'enrollmentRequestInfo','reCaptchaVerificationToken'])
    }
}

export default connect(mapStateToProps)(injectIntl(VendorEnrollmentRequestView));