import React from 'react';
import './ResetPasswordResultView.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as constants from 'constants/App';
import { connect } from 'react-redux';
import TranslationIcon from 'components/SiteTranslations';

class ResetPasswordSuccessView extends React.Component {
    constructor(props) {
        super(props);
    }

    onReturnClickHandler(e) {
        e.preventDefault();
        this.props.dispatch({ type: constants.NAVIGATE_TO, path: `/login` });
    }

    render() {
        const messages = defineMessages({
            login_button: {
                id: 'login_button',
                description: 'login button',
                defaultMessage: 'Login'
            },
            reset_password_success_title: {
                id: 'reset_password_success_title',
                description: 'reset password success title',
                defaultMessage: 'Reset your password'
            },
            reset_password_success_subtext: {
                id: 'reset_password_success_subtext',
                description: 'reset password success subtext',
                defaultMessage: 'Your password has been successfully changed. You may login to Holman PartnerConnect now.'
            }
        });

        return <div className="reset-confirmation-component">
            <div className="reset_confirmation_help_container row">
                <span>
                    <TranslationIcon backgroundColor='blue' />
                </span>
            </div>
            <div className="reset-confirmation__header">
                <span>
                    <FormattedMessage {...messages.reset_password_success_title} />
                </span>
            </div>
            <div className="reset-confirmation__body row">
                {/* <div className="col-xs-3">
                    <img src="/assets/success_mark.png" />
                </div> */}
                <div className="reset-confirmation__message row">
                    <span>
                        <FormattedMessage {...messages.reset_password_success_subtext} />
                    </span>
                </div>
            </div>

            <div className="reset-confirmation__footer">
                <div className="pull-right reset-confirmation_button"
                    role="button"
                    onClick={this.onReturnClickHandler.bind(this)}>
                    <FormattedMessage {...messages.login_button} />
                    {' '}
                    <img src="/assets/VMS_33button_go_bblue.png" />
                </div>
            </div>
        </div>;
    }
}

function select(state) {
    return {};
}

export default connect(select)(ResetPasswordSuccessView);