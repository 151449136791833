import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';

export function uiDataOdometerView(state = initialAppState.getIn(['uiData', 'odometerView']), action) {

    switch (action.type) {
        case constants.FETCH_DATA_FOR_ODOMETER_SCREEN_REQUESTED:
            return state.set('isFetchingData', true)
                .set('errorMessage', []);
        case constants.FETCH_DATA_FOR_ODOMETER_SCREEN_RESOLVED:
        case constants.FETCH_DATA_FOR_ODOMETER_SCREEN_REJECTED:
            return state.set('isFetchingData', false)
                .set('errorMessage', action.errorMessage);
        case constants.SAVE_ODOMETER_DATA:
            return state.set('newOdometer', action.newOdometer)
                .set('newEngineHours', action.newEngineHours).set('vendorReferenceNo', action.vendorReferenceNo)
                .set('vendorRequisitioner',action.vendorRequisitioner).set('clientReferenceNo',action.clientReferenceNo);
        case constants.VEHICLE_AT_SHOP_SELECTION_CHANGE:
            return state.set('isVehicleAtShop', action.isVehicleAtShop, !state.get('isVehicleAtShop', action.isVehicleAtShop));
        case constants.CLEAR_ODOMETER_DATA:
            return state.set('newOdometer', undefined)
                .set('newEngineHours', undefined)
                .set('isVehicleAtShop', undefined)
                .set('vendorRequisitioner',undefined)
                .set('vendorReferenceNo',undefined);
        case constants.CREATE_PO_REQUESTED:
            return state.set('errorMessage', []);
        case constants.CREATE_PO_REJECTED:
            return state.set('errorMessage', action.errorMessage);
        default:
            return state;
    }
}