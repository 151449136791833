import {call, put, select} from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import {isAuthenticated,isPersonaAuthenticated} from 'utils/HelperFunctions';

export function* sendClickFeedbackFormRequest() {
    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE});
        yield put({type: constants.FETCH_DATA_FOR_FEEDBACK_FORM_REQUEST});

        // Read data from app state
        const state = yield select();


        let feedbackFormData;

        if (isAuthenticated(state)) {

            feedbackFormData = {
                category: "feedback",
                senderType: "vendor",
                senderId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
                senderName: state.appState.getIn(['uiData', 'feedbackForm', 'name']),
                senderCompany: state.appState.getIn(['uiData', 'feedbackForm', 'company']),
                senderEmail: state.appState.getIn(['uiData', 'feedbackForm', 'email']),
                senderPhone: "",
                extension: "",
                title: state.appState.getIn(['uiData', 'feedbackForm', 'comments']),
                subTitle: state.appState.getIn(['uiData', 'feedbackForm', 'services']),
                message: state.appState.getIn(['uiData', 'feedbackForm', 'message']),
                country: state.appState.getIn(['uiData', 'shared', 'selectedCountry'])
            };

        }
        else if(isPersonaAuthenticated(state)){
            feedbackFormData = {
                category: "feedback",
                senderType: "personauser",
                senderId: state.appState.getIn(['serverData', 'shared', 'personaUserId']),
                senderName: state.appState.getIn(['uiData', 'feedbackForm', 'name']),
                senderCompany: state.appState.getIn(['uiData', 'feedbackForm', 'company']),
                senderEmail: state.appState.getIn(['uiData', 'feedbackForm', 'email']),
                senderPhone: "",
                extension: "",
                title: state.appState.getIn(['uiData', 'feedbackForm', 'comments']),
                subTitle: state.appState.getIn(['uiData', 'feedbackForm', 'services']),
                message: 'This is a Persona User: ' + state.appState.getIn(['uiData', 'feedbackForm', 'message']),
                country: state.appState.getIn(['uiData', 'shared', 'selectedCountry'])
            };


        }
        else {

            feedbackFormData = {
                  category: "feedback",
                  senderType: "anonymous",
                  senderId: "",
                  senderName: state.appState.getIn(['uiData', 'feedbackForm', 'name']),
                  senderCompany: state.appState.getIn(['uiData', 'feedbackForm', 'company']),
                  senderEmail: state.appState.getIn(['uiData', 'feedbackForm', 'email']),
                  senderPhone: state.appState.getIn(['uiData', 'feedbackForm', 'phone']),
                  extension: state.appState.getIn(['uiData', 'feedbackForm', 'extension']),
                  title: "",
                  subTitle: "",
                  message: state.appState.getIn(['uiData', 'feedbackForm', 'message']),
                  country: state.appState.getIn(['uiData', 'shared', 'selectedCountry']),
                  reCaptchaVerificationToken: state.appState.getIn(['uiData', 'feedbackForm', 'reCaptchaVerificationToken'])
              };

        }

        yield call(Api.feedbackFormSubmission, feedbackFormData);

        yield put({type: constants.SEND_CLICK_FEEDBACK_FORM_RESOLVED});
        yield put({type: constants.SEND_FEEDBACK_FORM_APPROVED});
    }
    catch (e) {
        grecaptcha.reset();
        yield put({type: constants.SEND_CLICK_FEEDBACK_FORM_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
        yield put({type: constants.SET_ERROR_MESSAGE, errorMessage:  helperFunctions.getGenericErrorObject(e)});
    }
}