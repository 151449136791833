/**
 * Created by prboyapa on 2/26/2016.
 */
import * as purchaseOrderViewActions from '../actions/AddEditView';
import * as addServiceLineItemsViewActions from '../screens/AddServiceLineItemsView/actions/AddServiceLineItemsView'
import * as addNewLineItemsViewActions from '../screens/AddNewLineItemsView/actions/AddNewLineItemsView'
import * as sharedActions from 'actions/App';
import * as constants from 'constants/App';

export function displayErrorMessage(dispatch, errorMessage) {
    dispatch(purchaseOrderViewActions.displayErrorMessage(errorMessage));
}

/*TODO: Need to implement this method:
 Add new pending line items to the specific complaint section based on the complaint*/
export function onAddLineItemsClickHandler(dispatch, complaintCode) {
        dispatch(addNewLineItemsViewActions.addComplaintCode(complaintCode));       
        dispatch(addNewLineItemsViewActions.toggleShowWindow());
}

export function onMissingProductCodeIconClickHandler(dispatch, complaintCode,lineItemIdMissingProductCode) {
        dispatch(addNewLineItemsViewActions.addComplaintCode(complaintCode));
        dispatch({ type: constants.SAVE_LINE_ITEM_ID_MISSING_PRODUCT_CODE, lineItemId: lineItemIdMissingProductCode });
        dispatch(addNewLineItemsViewActions.toggleShowWindow());
}

export function onChangeRepairTypeClickHandler(dispatch, lineItemIdRepairTypeChange, complaintCode){
    dispatch(addNewLineItemsViewActions.addComplaintCode(complaintCode));
    dispatch({type: constants.SAVE_LINE_ITEM_ID_REPAIR_TYPE_CHANGE, lineItemId: lineItemIdRepairTypeChange})
    dispatch(addNewLineItemsViewActions.toggleShowWindow());
}

export function onOpenTiresInfoHandler(lineItemId, complaintCode, productCode, dispatch, lineItemQty, unitPrice, correctionType,
    isUsingTireProductcode, isUsingBrakesProductcode, mode) {
    dispatch({
        type: constants.SAVE_LINE_ITEM_FOR_TIRES, lineItem: {
            lineItemId: lineItemId,
            lineItemQty: lineItemQty,
            unitPrice: unitPrice,
            correctionType: correctionType
        }
    }
    )
    dispatch(addNewLineItemsViewActions.addComplaintCode(complaintCode));
    dispatch({ type: constants.ATA_PARAMETERS_SCREEN_LOADED, productCode: productCode });

    if (isUsingTireProductcode) {
        dispatch({ type: constants.RESET_TIRE_SPECIFICATIONS });
        dispatch({ type: constants.FETCH_TIRE_SPECIFICATIONS, lineItemId, mode });
        dispatch({ type: constants.SHOW_TIRE_SPECIFICATION,  showTireSpecification:true});

    }
    if (isUsingBrakesProductcode) {
        dispatch({ type: constants.RESET_BRAKE_SPECIFICATIONS });
        dispatch({ type: constants.FETCH_BRAKES_SPECIFICATIONS, lineItemId, mode });

    }



    dispatch(addNewLineItemsViewActions.toggleShowWindow());
}
/*
 Add new line items for the maintenance*/
export function onAddLineItemsMaintenanceClickHandler(dispatch, routeParams,vehicleDetails) {
    if (routeParams.match.params.actionType == 'edit')
        dispatch({ type: constants.DUE_SERVICES_DATA_LOADED, vehicleId: vehicleDetails.get('id') });
    dispatch(addNewLineItemsViewActions.addComplaintCode("MA99"));
    dispatch(addServiceLineItemsViewActions.toggleShowWindow());
}

/*This method update custom comments by vendor for each complaint*/
export function onServiceDescriptionSaveHandler(comments, complaintCode,dispatch, actionType) {
    dispatch(purchaseOrderViewActions.saveServiceDescription(comments, complaintCode, actionType));
}

/*This method update estimated completion date; by default current date.
 TODO: * Need to discuss on it, if we need default date or not. */
export function onDateChangeEventHandler(newEstimatedDate, dispatch, actionType) {
    dispatch(purchaseOrderViewActions.saveEstimatedDate(newEstimatedDate, actionType));
}

export function onDeleteLineItemClickHandler(lineItemKey, dispatch, actionType) {
    dispatch(purchaseOrderViewActions.deleteLineItem(lineItemKey, actionType));
}

export function onEditLineItemClickHandler(lineItemKey,dispatch, actionType) {
    dispatch(purchaseOrderViewActions.editLineItem(lineItemKey, actionType));
}

export function onUpdateLineItemClickHandler(lineItem, dispatch) {
    dispatch(purchaseOrderViewActions.updateLineItem(lineItem));
}

export function onCancelUpdateLineItemClickHandler(lineItemKey, dispatch) {
    dispatch(purchaseOrderViewActions.cancelUpdateLineItem(lineItemKey));
}

export function onSavePurchaseOrderDetailsHandler(routeParams, dispatch) {
    dispatch({ type: constants.REQUEST_APPROVAL_CLICKED, actionType: routeParams.match.params.actionType });
}

/* Close the PO and reset the state*/
export function onUpdatePurchaseOrderDetailsHandler(dispatch, isDirtyPO,authorizedStatus, actionType) {
    /*Save invoice details to the database*/
    dispatch({ type: constants.REQUEST_APPROVAL_OR_CLOSEPO_CLICKED, isDirtyPO: isDirtyPO, authorizedStatus: authorizedStatus, actionType:actionType });
}

export function onCancelPOHandler(actionType, dispatch) {
    dispatch({ type: constants.CANCEL_PO_REQUESTED, actionType: actionType });
}

export function onAddAnotherComplaintHandler(event, dispatch) {
    event.preventDefault();
    dispatch(purchaseOrderViewActions.createNewComplaint());
}

export function onSelectComplaintTypeHandler(complaintCode, complaintDescription, complaintKey, dispatch) {
    dispatch({ type: constants.UPDATE_COMPLAINT_CODE, code: complaintCode, complaintKey: complaintKey, description: complaintDescription });
    dispatch(purchaseOrderViewActions.selectComplaint(complaintKey, complaintCode, complaintDescription));
}

export function onChangeComplaintHandler(complaintKey, event, dispatch) {
    event.preventDefault();
    dispatch(purchaseOrderViewActions.toggleComplaintSelectMode(complaintKey));
}

export function onComplaintNotesSaveHandler(complaintKey, notes, dispatch) {
    dispatch({ type: constants.SAVE_COMPLAINT_NOTES_CLICKED, complaintKey, notes });
}

export function onComplaintNotesSaveStateHandler(complaintKey, notes, dispatch) {
    dispatch({type: constants.UPDATE_COMPLAINT_NOTES, complaintKey, notes});

}

export function onClearServicesHandler(dispatch) {
    dispatch({ type: constants.CLEAR_ADD_EDIT_VIEW_SERVICES });
}

export function onUpdateEstimatedCompletionDateHandler(isEstimateDateValid, dispatch) {
    dispatch({ type: constants.UPDATE_IS_ESTIMATED_COMPLETION_DATE_VALID, isEstimateDateValid });
}