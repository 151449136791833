// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './CraneInspectionResultConfirmation.css';
import CraneManufactureInformation from './CraneManufactureInformation';
import CraneResult from './CraneResult';
import Spinner from 'components/Spinner';

class CraneInspectionResultConfirmation extends React.Component {
	render() {
		const messages = defineMessages({
			crane_inspection__title: {
				id: 'crane_inspection__title',
				description: 'Crane inspection title',
				defaultMessage: 'Mobile crane safety inspection'
			},
			crane_inspection__edit_form: {
				id: 'crane_inspection__edit_form',
				description: 'View/edit form button',
				defaultMessage: 'View/Edit Crane inspection form'
			},
			crane_inspection__save_continue: {
				id: 'crane_inspection__save_continue',
				description: 'Save and continue button',
				defaultMessage: 'Save and continue'
			},
		});

		return (
			<div>
				<div className='crane_inspection_result_confirmation__title'>
					<FormattedMessage {...messages.crane_inspection__title} />
				</div>

				<div className='crane_inspection_result_confirmation__outer_box'>

					<CraneManufactureInformation
						formData={this.props.formData}
						setFormData={this.props.setFormData}
						formValidationMessages={this.props.formValidationMessages}
					/>

					<CraneResult
						formData={this.props.formData}
						setFormData={this.props.setFormData}
						formValidationMessages={this.props.formValidationMessages}
						userLocale={this.props.userLocale}
						poCreationDate={this.props.poCreationDate}
						onInspectionFailClick={this.props.onConfirmationFailClick}
						onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}
					/>

					<div className='row crane_inspection_result_confirmation__row'>

						<div className='offset-sm-6 col-sm-6 crane_inspection_result_confirmation__col'>

							<div
								className='crane_inspection_result_confirmation__edit_form_button'
								onClick={this.props.onEditCraneFormClick}
							>
								<FormattedMessage {...messages.crane_inspection__edit_form} />
							</div>

						</div>

					</div>

				</div>

				<div className='crane_inspection_result_confirmation__footer'>

					<div className='row crane_inspection_result_confirmation__row'>

						<div className='offset-sm-6 col-sm-6 crane_inspection_result_confirmation__col'>

							{this.props.formData.get('hasInspectionPassed') !== false &&
								<div className='crane_inspection_result_confirmation__save_button'
									onClick={this.props.onSaveConfirmationClick}
								>
									<FormattedMessage {...messages.crane_inspection__save_continue} />{' '}
									<img src='/assets/VMS_33button_add_blue.png' />
									<div className='crane_inspection_result_confirmation__spinner'>
										{this.props.isSaving &&
											<Spinner spinnerType='blue' />
										}
									</div>
								</div>
							}

						</div>

					</div>

				</div>
			</div>
		);
	}
}

export default CraneInspectionResultConfirmation;