/**
 * Created by prboyapa on 1/11/2016.
 */
import React from 'react';
import Title from 'components/Title';
import './OdometerVehicleTitle.css';

class OdometerVehicleTitle extends React.Component {

    render() {
        //render the view
        return <div className="container-fluid odometer_vehicle_title">
            <Title caption={this.props.caption}/>
        </div>;
    }
}

export default OdometerVehicleTitle;