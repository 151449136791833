import { createSelector } from 'reselect';
import {Map} from 'immutable';
import {emptyMapIfUndefined, emptyStringIfUndefined} from 'utils/HelperFunctions';

import * as constants from 'constants/App';

const getPurchaseOrderVendorId = state => emptyStringIfUndefined(state.appState.getIn(['uiData', 'actionRequired', 'purchaseOrder', 'vendorId']));
const getCurrentVendorId = state => emptyStringIfUndefined(state.appState.getIn(['serverData', 'shared', 'vendorId']));
const getLineItems = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'actionRequired', 'lineItems']));
const getComplaints = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'actionRequired', 'complaints']));
const getPOAdjustmentStatus=state=>emptyMapIfUndefined(state.appState.getIn(['serverData', 'actionRequired', 'adjustmentStatus']));




export const isPOAdjustedBySystem =createSelector(getPOAdjustmentStatus, (poAdjustmentStatus) =>{    
 if(poAdjustmentStatus.get('IsAcknowledged')==false && poAdjustmentStatus.get('ByWho')=='SYSTEM')
     return true;
     else return false;
 });



export const allLinesRejected =
    createSelector(getLineItems,
                  (   lineItems) =>
        {
            let totalLineItems = lineItems.count();
            let totalRejected = lineItems.count(li => li.get('approvalStatus') == constants.LINE_ITEM_STATUS.REJECTED);
            return totalLineItems != 0 && totalRejected === totalLineItems;
        });
    
const getDoesPOBelongToVendor =
    createSelector(getPurchaseOrderVendorId, getCurrentVendorId,
                  (   purchaseOrderVendorId,    currentVendorId) =>
    {
        return purchaseOrderVendorId != '' ? currentVendorId == purchaseOrderVendorId : true;
    });

    export const getAllActionRequiredLines=
    createSelector(getLineItems,
       (   lineItems) =>
   {
    
    return filterAllActionRequiredLines(lineItems);

   });
   
   export const filterAllActionRequiredLines = ((lineItems) =>
  {
   //return lineItems.valueSeq().filter(li => li.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING).toMap();
   const actionRquiredLines= lineItems.valueSeq().filter(li => li.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING || 
   li.get('approvalStatus')==constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES).map(li=>
       { return li.set('ActionRequiredStatus', 
       li.get('approvalStatus')==constants.LINE_ITEM_STATUS.REJECTED? constants.LINE_ITEM_STATUS.REJECTED:
       li.get('approvalStatus')==constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES?constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES :
       li.get('approvalStatus')==constants.LINE_ITEM_STATUS.APPROVED?
           li.get('createdBy')=='Holman' ? 'CREATED_BY_ARI' : 
           li.get('previousQuantity') != li.get('quantity') || li.get('previousUnitPrice') != li.get('unitPrice') ? 'ADJUSTED':
           'PENDING' :
       li.get('approvalStatus')==constants.LINE_ITEM_STATUS.PENDING? 
            li.get('createdBy')=='Holman' ? 'CREATED_BY_ARI' : 
           li.get('previousQuantity') != li.get('quantity') || li.get('previousUnitPrice') != li.get('unitPrice') ? 'ADJUSTED' : 
           'PENDING':'PENDING')});    
   return actionRquiredLines.filter(a => a.get('ActionRequiredStatus') != 'PENDING');

         
  });

const getRejectedLineItems =
    createSelector(getLineItems,
                  (   lineItems) =>
    {
        return lineItems.valueSeq().filter(li => li.get('approvalStatus') == constants.LINE_ITEM_STATUS.REJECTED && li.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING).toMap();
    });

const getAdjustedLineItems =
    createSelector(getLineItems,
                  (   lineItems) =>
    {
        //check if this line has been adjusted
        return lineItems.filter(li =>
            (li.get('approvalStatus') != constants.LINE_ITEM_STATUS.REJECTED
            && li.get('approvalStatus') != constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES
                && li.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING)
        ).sortBy(l => (l.get('productCode')
                        + (l.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING ? 1 : 0)
                        + (l.get('lineItemType').toUpperCase() == 'PARTS' ? 0 : 1)
        ));
    });

    const getNeedMoreNotesLineItems =
    createSelector(getLineItems,
                  (   lineItems) =>
    {
        //check if this line has been adjusted
        return lineItems.filter(li =>
            (li.get('approvalStatus') == constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES
                )
        ).sortBy(l => (l.get('productCode')
                        + (l.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING ? 1 : 0)
                        + (l.get('lineItemType').toUpperCase() == 'PARTS' ? 0 : 1)
        ));
    });

    export const listofLinesWithPendingNotes =
    createSelector(getLineItems,
                  (   lineItems) =>
    {
        //check if this line has been adjusted
        return lineItems.filter(li =>
            (li.get('approvalStatus') == constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES
                && li.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING
                && (li.get('vendorNote')===undefined || li.get('vendorNote')==='')))
        .map(li=>{return {id: li.get('id')}});        
        
    });

    export const allLinesPendingNotes =
    createSelector(getLineItems,
                  (   lineItems) =>
        {
            let totalLineItems = lineItems.count(li=>li.get('vendorAcknowledgementStatus')==constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING);
            let totalPendingNotes = lineItems.count(li => li.get('approvalStatus') == constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES);            
            return totalLineItems != 0 && totalPendingNotes === totalLineItems;
        });

    


    const pendingNotesSelector = (state, props) =>{
            if(state.appState.getIn(['uiData', 'actionRequired', 'lineItems'])==undefined)
            {
             
             return state.appState.getIn(['serverData', 'actionRequired', 'lineItems']).filter(li =>
                (li.get('approvalStatus') != constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES
                    && li.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING)
                ).sortBy(l => (l.get('productCode')
                            + (l.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING ? 1 : 0)
                            + (l.get('lineItemType').toUpperCase() == 'PARTS' ? 0 : 1)
            )).map((lineItem)=>{
                return { id: lineItem.get('id'),
                         vendorNotes: undefined
                        };
            });
            }
            else return state.appState.getIn(['uiData', 'actionRequired', 'lineItems']);
        }

       // getAllActionRequiredLines

    const getAckNotNeededLineItems =
       createSelector(getLineItems, getAllActionRequiredLines,
                     (   lineItems,    actionRequiredLineItems) =>
       {
           //get all line items that are approved and not adjusted
           return lineItems.filter(li => actionRequiredLineItems.count(adj => adj.get('id') == li.get('id')) == 0
           );
       });
   
   const getAckNotNeededComplaints =
       createSelector(getComplaints, getAckNotNeededLineItems,
                     (   complaints,    ackNotNeededLineItems) =>
       {
           
           //get all complaints that have line items that need vendor acknowledgement
           return complaints.filter(comp =>
               ackNotNeededLineItems.count(adj => adj.get('complaintCode') == comp.get('code')) > 0
           );
       });

    export const getAssociatedPendingLines =
    createSelector(getLineItems, getAllActionRequiredLines,
        (   lineItems,    adjustedLineItems) =>
        {
        //get all line items that are approved and not adjusted
        return lineItems.filter(li =>
        (li.get('vendorAcknowledgementStatus') == constants.VENDOR_ACKNOWLEDGEMENT_STATUS.PENDING)        
        && adjustedLineItems.count(adj => adj.get('id') == li.get('id')) == 0
        );
        });

export
{
    getDoesPOBelongToVendor,
    getRejectedLineItems,
    getAdjustedLineItems,
    getAckNotNeededLineItems,
    getAckNotNeededComplaints,
    getNeedMoreNotesLineItems    
    //getPendingNotes 
    };