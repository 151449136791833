// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
import Dropzone from 'react-dropzone';
// Our stuff
import './UploadStaging.css';

const UploadStaging = (props) => {       
    const { onDrop } = props;
    const messages = defineMessages({
            documents_drag_drop: {
                id: 'documents_drag_drop',
                description: 'Message on drag and drop upload box',
                defaultMessage: 'Drag and drop your file here or click to browse.'
            }
        });        

    return <div>
                <Dropzone multiple={false} ref={props.dropzoneRef}  {...{onDrop}} accept='.jpg,.jpeg,.png,.gif,.pdf,.xls,.xlsx,.doc,.docx'> 
                    {({getRootProps, getInputProps}) => (
                        <div>
                        <div
                            {...getRootProps({
                            className: 'documents__dropzone',
                            onDrop: event => event.stopPropagation()
                            })}
                        >
                            <input {...getInputProps()} />
                            <FormattedMessage {...messages.documents_drag_drop}/>
                        </div>
                        </div>
                    )}
                    </Dropzone>
       
        
    </div>    
}

export default UploadStaging;