const brakesProductCodes = [
    { productCode: '13001014', componentType: 'shoe', appliesToAxle: 1 },
    { productCode: '13001023', componentType: 'drum', appliesToAxle: 1 },
    { productCode: '13001026', componentType: 'rotor', appliesToAxle: 1 },
    { productCode: '13001029', componentType: 'pad', appliesToAxle: 1 },
    { productCode: '13002013', componentType: 'shoe', appliesToAxle: 2 },
    { productCode: '13002023', componentType: 'drum', appliesToAxle: 2 },
    { productCode: '13002027', componentType: 'pad', appliesToAxle: 2 },
    { productCode: '13002124', componentType: 'rotor', appliesToAxle: 2 },

]
export default brakesProductCodes;   