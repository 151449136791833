// Libraries
import React from 'react'
// Our stuff
import './FeeMessage.css';

const FeeMessage = (props) => {
    if (props.feeInfo.paymentType !== 'INTELLIPAY')
        return null;

    return <div className='fee_message__message'>

        { props.noteText.map(note=>note.get('noteText')).valueSeq()}
    
    </div>    
}



export default FeeMessage;