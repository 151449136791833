import React from 'react';
import ReactDoM from 'react-dom';
import './PasswordPolicy.css';
import { defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

class PasswordPolicy extends React.Component {
    render() {
        const messages = defineMessages({
            password_policy_title: {
                id: 'password_policy_title',
                description: 'password_policy_title',
                defaultMessage: 'Passwords must meet the following criteria:'
            },
            password_policy_8_chars: {
                id: 'password_policy_8_chars',
                description: 'password_policy_8_chars',
                defaultMessage: 'Contain at least 8 characters'
            },
            password_policy_uppercase: {
                id: 'password_policy_uppercase',
                description: 'password_policy_uppercase',
                defaultMessage: 'Contain at least 1 uppercase letter'
            },
            password_policy_lowercase: {
                id: 'password_policy_lowercase',
                description: 'password_policy_lowercase',
                defaultMessage: 'Contain at least 1 lowercase letter'
            },
            password_policy_digit: {
                id: 'password_policy_digit',
                description: 'password_policy_digit',
                defaultMessage: 'Contain at least 1 digit'
            },
            password_policy_special_char: {
                id: 'password_policy_special_char',
                description: 'password_policy_special_char',
                defaultMessage: 'Contain at least 1 symbol from this list !#$%&\'()*+,-./:;<=>?@[\]^_{|}~'
            },
        });

        const passwordPolicyList = [
            { policy: 'password_policy_8_chars', id: 0 },
            { policy: 'password_policy_uppercase', id: 1 },
            { policy: 'password_policy_lowercase', id: 2 },
            { policy: 'password_policy_digit', id: 3 },
            { policy: 'password_policy_special_char', id: 4 }
        ];

        var passwordPolicyListTemplate = passwordPolicyList.map(function (passwordPolicy, index) {
            return (
                <ListGroupItem id={"password-policy-id-" + index} componentClass="ul">
                    <i class="fa fa-square-o" aria-hidden="true"></i>&nbsp;
                    <FormattedMessage {...messages[passwordPolicy.policy]} />
                </ListGroupItem>
            );
        });

        return (
            <div className="password-policy container-fluid">
                <div className="password-policy-title row">
                    <FormattedMessage {...messages.password_policy_title} />
                </div>
                <div className="password-policy-content row">
                    <ListGroup>
                        {passwordPolicyListTemplate}
                    </ListGroup>
                </div>
            </div>
        );
    }
}

export default PasswordPolicy;
