import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Stores from "PersonaRoutes/Stores";
import AccessStores from "PersonaRoutes/AccessStores";
import StoreProfile from "PersonaRoutes/StoreProfile";
import AddUsersToStore from "PersonaRoutes/AddUsersToStore";
import Users from "PersonaRoutes/Users";
import AddUsers from "PersonaRoutes/AddUsers";
import UserProfile from "PersonaRoutes/UserProfile";
import AddStoresToUser from "PersonaRoutes/AddStoresToUser";
import ModifyUserPermissions from "PersonaRoutes/ModifyUserPermissions";
import { connect } from 'react-redux';
import {hasTokenExpired } from '../../shared/utils/HelperFunctions';
import { useAppState } from 'shared/AppState/app-state';

const PersonaRoutes = ({ routeParams }) => {
  const [ appState ] = useAppState();
  const { personaToken } = appState;


  return (
    <Switch>
       {(personaToken === undefined || hasTokenExpired(personaToken))
       ? <Redirect to={{
                            pathname: '/login'
                        }} />
                        : undefined
                    
        }
      <Route
        path="/Persona/AccessStores"
        exact
        render={routeParams => <AccessStores />}
      />

      <Route path="/Persona/Stores" exact render={routeParams => <Stores routeParams={routeParams}/>} />

      <Route
        path="/Persona/Stores/:storeId"
        exact
        render={routeParams => <StoreProfile routeParams={routeParams} />}
      />

      <Route
        path="/Persona/Stores/:storeId/users/add"
        exact
        render={routeParams => <AddUsersToStore routeParams={routeParams} />}
      />

      <Route path="/Persona/Users" exact render={routeParams => <Users routeParams={routeParams}/>} />

      <Route
        path="/Persona/Users/add"
        exact
        render={routeParams => <AddUsers routeParams={routeParams} />}
      />
      
      <Route path="/Persona/Users/:userId" exact render={routeParams => <UserProfile routeParams={routeParams}/>} />

      <Route
        path="/Persona/Users/:userId/stores/add"
        exact
        render={routeParams => <AddStoresToUser routeParams={routeParams} />}
      />

      <Route
        path="/Persona/Users/:userId/Stores/:storeId/edit"
        exact
        render={routeParams => <ModifyUserPermissions routeParams={routeParams} />}
      />      

    </Switch>
  );
};

export default withRouter((PersonaRoutes));
