// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
// Our stuff
import './UploadFileInfo.css';
import documentTypes from '../data/vehicleDocumentTypes';
import BindField from './BindField';

const UploadFileInfo = ({fileInfo, validationErrors, onFieldBlur, onClearError}) => {
    const messages = defineMessages({
            documents_file_name: {
                id: 'documents_file_name',
                description: 'Header for file name.',
                defaultMessage: 'Selected file name'
            },
            documents_name: {
                id: 'documents_name',
                description: 'Header for name.',
                defaultMessage: 'Name'
            },            
            documents_document_type: {
                id: 'documents_document_type',
                description: 'Header for Document type.',
                defaultMessage: 'Document type'
            }            
        });
    const errorMessages = defineMessages({
            documents_name_required: {
                id: 'documents_name_required',
                description: 'Name is a required field',
                defaultMessage: 'Please enter a name.'
            },
            documents_document_type_required: {
                id: 'documents_document_type_required',
                description: 'Required field document type',
                defaultMessage: 'Please select a document type.'
            }
        });
    const docTypes = documentTypes.vehicle;    

    return <div className='documents__upload_file_info'>

        <form onSubmit={(e)=>{e.preventDefault()}} className='documents__form'>

            <Row>
                <Col sm={4}>
                    <label htmlFor="fileName"><FormattedMessage {...messages.documents_file_name}/></label>
                </Col>            
                <Col sm={8}>                                    
                    <input name="fileName" disabled="disabled"
                        defaultValue={fileInfo.get('fileName')} 
                        onBlur={(e)=>{onFileInfoBlur(e.target.name, e.target.value)}} />
                </Col>            
            </Row>

            <Row>
                <Col sm={4}>
                    <label htmlFor="name"><FormattedMessage {...messages.documents_name}/></label>
                </Col>            
                <Col sm={8}>                                        
                    <BindField name='name'
                                error={errorMessages[validationErrors.get('name')]} 
                                {...{onClearError, onFieldBlur}}>
                        <input defaultValue={fileInfo.get('name')} 
                                maxLength={150} />
                    </BindField>
                </Col>            
            </Row>                        

            <Row>
                <Col sm={4}>
                    <label htmlFor="documentType"><FormattedMessage {...messages.documents_document_type}/></label>
                </Col>            
                <Col sm={8}>
                    <BindField name='documentType'
                                error={errorMessages[validationErrors.get('documentType')]} 
                                {...{onClearError, onFieldBlur}}>  
                        <select>                                                
                            <option></option>
                            {docTypes.map((docType, key)=>(
                                <option value={docType.get('type')} key={key}>
                                    {docType.get('displayName')}
                                </option>
                            )).valueSeq()}
                        </select>
                    </BindField>
                </Col>            
            </Row>            

        </form>

    </div>
}

export default UploadFileInfo;