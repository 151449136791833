import React from 'react';
import { connect } from 'react-redux';
import initialAppState from 'reducers/InitialAppState';

class Features extends React.Component {    

    // There can be stored in Redux and this component is connnected to store.
    restrictedFeatures ={                    
            readOnlyPOMode:this.props.selectedCountry=='MEX',
            showVideoLinks:this.props.selectedCountry!=='MEX',
            selectedCountry:this.props.selectedCountry
    };	

    render() {          
		  return ( 
			<div>
				{this.props.render(this.restrictedFeatures)}
			</div>
      );
    }
  };

 
  

  function mapStateToProps(state, props) {      
    return {        
        selectedCountry:state.appState.getIn(['uiData', 'shared', 'selectedCountry'])
    };
}
  
export default connect(mapStateToProps)(Features);