import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';

export function serverDataHelpFAQSections(state = initialAppState.getIn(['serverData','helpFAQSections']), action) {
    switch (action.type) {
        case constants.TOGGLE_HELP_FAQ_TITLE:
            return state.setIn(['sections',action.sectionTitle, 'isVisible'], !state.getIn(['sections',action.sectionTitle, 'isVisible']));

        case constants.CHANGE_FAQ_LOCALE:
            return state.set('locale',action.locale);
        case "CLEAR_FAQ_LOCALE":
            return state.set('locale',"en-US");
        default:
            return state;

    }
}
    export function serverDataHelpFAQQuestions(state = initialAppState.getIn(['serverData','helpFAQQuestions']), action) {
        switch (action.type) {
            case constants.TOGGLE_HELP_FAQ_QUESTION:
                return state.setIn(['questions', action.sectionTitle, action.sectionKeyQuestion, 'isVisible'], !state.getIn(['questions', action.sectionTitle, action.sectionKeyQuestion, 'isVisible']));
            default:
                return state;

        }

    }