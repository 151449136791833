import { createSelector } from "reselect";
import { Map } from "immutable";
import {
  emptyMapIfUndefined,
  falseIfUndefined,
  emptyStringIfUndefined,
  validateEmail
} from "utils/HelperFunctions";
import * as constants from "constants/App";
import moment from "moment";

const serviceResponseSelector = state => {
  let serviceResponse = state.appState.getIn([
    "uiData",
    "vendorEnrollmentRequestView",
    "serviceResponse"
  ]);
  if (serviceResponse != undefined) return serviceResponse;
  else return undefined;
};

const requestIdSelector = createSelector(
  serviceResponseSelector,
  serviceResponse => {
    if (serviceResponse != undefined) return serviceResponse.referenceNumber;
    else return undefined;
  }
);

const isRequestFormValidSelector = state => {
  let enrollmentInfo = state.appState.getIn([
    "uiData",
    "vendorEnrollmentRequestView",
    "enrollmentRequestInfo"
  ]);
  let fieldsToValidate = [
    "businessName",
    "address1",
    "city",
    "state",
    "zip",
    "country",
    "contactName",
    "phoneArea",
    "phonePrefix",
    "phoneSuffix"
  ];

  //required fields should be populated
  let inValidFields = fieldsToValidate.reduce((p, c) => {
    if (emptyStringIfUndefined(enrollmentInfo.get(c)) == "") return p.concat(c);
    else return p;
  }, []);

  //validate email
  if (!validateEmail(enrollmentInfo.get("email")))
    inValidFields = inValidFields.concat("email");

  //email and re-enter email should match
  if (enrollmentInfo.get("email") != enrollmentInfo.get("reEnterEmail")) {
    inValidFields = inValidFields.concat("reEnteraEmail");
  }

  var faxTestExpression = /^\d{10}$/;
  var faxNumber =
    emptyStringIfUndefined(enrollmentInfo.get("faxArea")) +
    emptyStringIfUndefined(enrollmentInfo.get("faxPrefix")) +
    emptyStringIfUndefined(enrollmentInfo.get("faxSuffix"));
  if (faxNumber != "" && !faxTestExpression.test(faxNumber))
    inValidFields = inValidFields.concat("faxSuffix");

  var phoneNumber =
    emptyStringIfUndefined(enrollmentInfo.get("phoneArea")) +
    emptyStringIfUndefined(enrollmentInfo.get("phonePrefix")) +
    emptyStringIfUndefined(enrollmentInfo.get("phoneSuffix"));

  if (!faxTestExpression.test(phoneNumber)) {
    inValidFields = inValidFields.concat("phoneSuffix");
  }

  //zip code should be either 5 or 9 digits
  var re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  var zipCode=emptyStringIfUndefined(enrollmentInfo.get('zip'));
  if (enrollmentInfo.get("country") == "CAN") {
    re = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}\s{1}\d{1}[A-Z]{1}\d{1}$/;
    zipCode=zipCode.toUpperCase();
  }
  if (!re.test(zipCode))
    inValidFields = inValidFields.concat("zip");

  return {
    isFormValid: inValidFields.length == 0,
    inValidFields: inValidFields
  };
};

const VendorEnrollmentDetailView = createSelector(
  requestIdSelector,
  isRequestFormValidSelector,
  (requestId, isRequestFormValid) => {
    return {
      requestId,
      isRequestFormValid
    };
  }
);

export default VendorEnrollmentDetailView;
