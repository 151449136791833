// libraries
import React from 'react';
import {connect} from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {Map} from 'immutable';
// our stuff
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import CancelButton from '../components/buttons/CancelButton';
import SaveButton from '../components/buttons/SaveButton';
import UploadFileInfo from '../components/UploadFileInfo';

const emptyMap = Map({});

const UploadStep = (props) => {                                        
    return <div>

        <UploadFileInfo 
            fileInfo={props.fileInfo} 
            validationErrors={props.validationErrors}            
            onFieldBlur={props.onFieldBlur}
            onClearError={props.onClearError}/>

        <Row>
            <Col sm={6}>
                <CancelButton onClick={props.onCancelClick}/>
            </Col>            
            <Col sm={6}>                                    
                <SaveButton onClick={props.onSaveClick}/>                
            </Col>            
        </Row>

    </div>        
    
}

const mapStateToProps = (state, props) => {        
    return {                
        fileInfo: state.appState.getIn(['uiData', 'vendorDocuments', 'fileInfo']) || emptyMap,
        validationErrors: state.appState.getIn(['uiData', 'vendorDocuments', 'validationErrors']) || emptyMap
        };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onFieldBlur: (field, value) => {
            dispatch({type: constants.SET_FILE_INFO_VENDOR_DOCUMENTS, field, value});
        },        
        onCancelClick: () => {
            dispatch({type: constants.CLEAR_FILE_INFO_VENDOR_DOCUMENTS});
            dispatch({type: constants.SET_VENDOR_DOCUMENTS_STEP, step: constants.VENDOR_DOCUMENT_STEPS.DOCUMENTS});
        },
        onSaveClick: () => {            
            dispatch({type: constants.UPLOAD_VENDOR_DOCUMENT, file: props.file});
        },
        onClearError: (name) => {
            dispatch({type: constants.VENDOR_DOCUMENTS_CLEAR_ERROR, name});
        }        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadStep);