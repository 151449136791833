import React, { useEffect } from 'react';
import { fromJS } from 'immutable';
import classnames from 'classnames';
import './Content.css';
import SideBar from './SideBar';
import ShopView from '../screens/PO/ShopView';
import { Redirect ,Route,Switch} from 'react-router-dom';
import * as LoginComponents from '../screens/Home/Login';
import VehicleSearchCreatePO from '../shared/VehicleSearchCreatePO/containers/VehicleSearchCreatePOView';
import AddEditView from '../screens/PO/AddEditView';
import OdometerView from '../screens/PO/OdometerView';
import RepairHistoryView from '../screens/PO/RepairHistoryView';
import ComplaintView from '../screens/PO/ComplaintView';
import ClosePO from '../screens/PO/ClosePO';
import SearchPO from '../screens/PO/SearchPO';
import ClientParameters from '../screens/PO/ClientParameters';
import ActionRequired from '../screens/PO/ActionRequired';
import HelpFAQ from '../screens/Contact/HelpFAQ';
import FeedbackForm from '../screens/Contact/FeedbackForm'
import VendorAccount from '../screens/YourAccount/VendorAccountInformation';
import BulletinMessage from '../screens/PO/ShopView/components/BulletinMessage';
import RecoverPassword from '../screens/Home/RecoverPassword';
import MyUnits from '../screens/YourAccount/MyUnits/containers/MyUnitsView';
import VehicleDocuments from 'Vehicle/VehicleDocuments';
import Adjustment from 'PO/Adjustment';
import DriverValidationComplaints from 'PO/DriverValidationComplaints';
import { connect } from 'react-redux';
import { emptyArrayIfUndefined, hasTokenExpired, falseIfUndefined } from '../shared/utils/HelperFunctions';
import VendorEnrollmentRequestView from '../screens/YourAccount/VendorEnrollmentRequest';
import VendorEnrollmentView from '../screens/YourAccount/VendorEnrollment';
import RegistrationView from '../screens/YourAccount/Registration';
import VendorLinkedAccountsView from '../screens/CorporateAccount/containers/LinkedVendorView';
import VendorLinkedRegister from '../screens/CorporateAccount/LinkedVendorRegister/containers/LinkedVendorRegister';
import CarWashView from '../screens/CarWash/containers/CarWashView';
import CarWashCreatePO from '../screens/CarWash/screens/CreatePOForm/containers/CreatePOFormView';
import DealerDraftsDealerRequests from '../screens/DealerDrafts/DealerRequests/containers/DealerRequestsView';
import DealerDraftsRequestsDetails from '../screens/DealerDrafts/RequestsApproval/containers/RequestsApprovalView';
import TermsAndConditions from '../screens/Home/TermsAndConditions/containers/TermsView';
import TermsAndConditionsModal from 'TermsAndConditionsModal';
import ClientExceptions from '../screens/Home/ClientExceptions/containers/ClientExceptionsView';
import SSORedirect from 'SSORedirect';
import * as constants from 'constants/App';
import VehicleSearchResultView from '../shared/VehicleSearchResults/containers/VehiclesSearchResultsView';
import ResetPassword from '../screens/Home/ResetPassword';
import RecoverPasswordConfirmationComponent from '../screens/Home/RecoverPassword/components/RecoverPasswordConfirmationComponent';
import ResetPasswordResultView from '../screens/Home/ResetPassword/components/ResetPasswordResultView';
import RecoverPasswordTooAttemptsComponent from '../screens/Home/RecoverPassword/components/RecoverPasswordTooAttemptsComponent';
import {withRouter} from 'react-router-dom'
import ARIUser from '../screens/ARIUser';
import ChangePassword from 'YourAccount/ChangePassword';
import TLSLearnMore from 'Home/TLSWarning/components/TLSLearnMore';
import AttentionNeeded from '../screens/AttentionNeeded';

import Stores from 'PersonaRoutes/Stores';
import AccessStores from 'PersonaRoutes/AccessStores';
import StoreProfile from 'PersonaRoutes/StoreProfile';
import AddUsersToStore from 'PersonaRoutes/AddUsersToStore';
import PersonaRoutes from 'PersonaRoutes/common/Routes';
import { useAppState } from 'shared/AppState/app-state';
import * as Api from 'utils/Api';
import { HolmanRedirect } from '../components/HolmanRedirect';
import IELoginWarningModal from './IELoginWarningModal';
import PaymentStatusBulletin from '../screens/PO/ShopView/components/PaymentStatusBulletin';
import AccidentManagementRoute from 'AccidentManagement/common/Routes.tsx';

const Content = ({
    dispatch: reduxDispatch, 
    token, 
    personaToken, 
    vendorId,
    personaUser, 
    serviceClassification, 
    isDealerDraft, 
    adminUser, 
    selectedLocale, 
    country,
    location}) => { 

    const [ appState, dispatch ] = useAppState();    
    const isIE = false || !!document.documentMode;
    
    useEffect(() => { 
        dispatch({type:'TOKEN_CHANGED', token  });
    },[dispatch, token]);

    // Save vendor features in AppState
    useEffect(() => {
        const fetchVendorFeatures = async () => {
            if (token !== undefined && vendorId !== undefined) {

                // Make API call 
                const vendorFeatures = await Api.fetchVendorFeatures(vendorId, token);
                
                // Save features
                dispatch({ type: 'VENDOR_FEATURES_LOADED', vendorFeatures });
            }
        }
        fetchVendorFeatures();
    }, [dispatch, token, vendorId]);

    useEffect(() => {
        dispatch({type:'PERSONA_TOKEN_CHANGED', token: personaToken });
    },[dispatch, personaToken]);

    useEffect(() => {        
        let personaUserJSON = personaUser === undefined ? undefined : personaUser.toJSON();
        dispatch({type:'PERSONA_USER_CHANGED', personaUser: personaUserJSON});        
    },[dispatch, personaUser]);


    useEffect(() => {
        dispatch({type:'VENDOR_TYPE_CHANGED', serviceClassification: serviceClassification });
    },[serviceClassification]);

    useEffect(() => {
        dispatch({type:'VENDOR_IS_DEALER', dealerDraftStatus: isDealerDraft });
    },[isDealerDraft]);
    
    // Sign out persona user out of store if they navigate back into persona screens.
    useEffect(() => {        
        if (location.pathname.startsWith('/Persona/') && !!appState.token) {
            reduxDispatch({type: 'PERSONA_USER_STORE_SIGN_OUT'});            
            reduxDispatch({ 
                type: 'SET_PERSONA_DETAILS', 
                personaToken: appState.personaToken, 
                personaUserId: appState.personaUserId, 
                adminUser: adminUser,
                selectedLocale: selectedLocale,
                personaUser: fromJS(appState.personaUser)
              });
        }
    }, [location.pathname.startsWith('/Persona/')]); // This dependency array should not include token as it causes the effect to run too many times.

        const vehicleSearchCreateComponent = country=='MEX' || !appState.poPermissions.includes('CR') 
            || (appState.serviceClassification === 'CARWASH' && !appState.poPermissions.includes('CL')) ? <div></div> : <VehicleSearchCreatePO/>;
        
        return <div className="row container-fluid">
            <HolmanRedirect />
            <div className="d-none d-xl-block links offset-lg-1 col-lg-1 offset-xl-1 col-xl-2">
                <Route path="/vendorTermsAndConditions" exact render={(props) => (
                    <div></div>)} 
                />
                <Route path="/personaTermsAndConditions" exact render={(props) => (
                    <div></div>)} 
                />
                <Route render={(props) => (
                    <div><TermsAndConditionsModal location={props.location} /></div>)} 
                /> 

                <Route render={(props) => (<div><IELoginWarningModal /></div>)} />

                <ChangePassword />

                

                <Route path="/sso" exact render={() => <div></div>} />

                <Route exact path="/login" render={() => <div></div>} />
                <Route
                    path="/Persona"
                    component={SideBar}
                />
                <Route
                    path="/attentionNeeded"
                    component={SideBar}
                />
                <Route
                    path="/AccidentManagement"
                    component={SideBar}
                />
                <Route exact  path="/"   component={SideBar} />
               {(token === undefined || hasTokenExpired(token)) && (personaToken === undefined || hasTokenExpired(personaToken))
                     ? false :
                <Route exact path="/contact/HelpFAQ"  component={SideBar}/>
                    }
                 {(token === undefined || hasTokenExpired(token)) && (personaToken === undefined || hasTokenExpired(personaToken)) 
                 ? false :
                <Route path="/contact/FeedbackForm"  component={SideBar} />
                 }
                <Route path="/yourAccount"  component={SideBar}/>                
                <Route path="/Stores" component={SideBar}/>
                <Route path="/StoreProfile/:storeId" component={SideBar}/>                
                <Route path="/ariuser" render={() => <div></div>} />
                <Route path="/linkedVendorAccount" exact render={
                      () =>  ((token === undefined || hasTokenExpired(token))
                        ? <Redirect to={{
                            pathname: '/login'
                        }} /> : undefined)
                     } />
                <Route path="/resetPassword" render={
                    () => false} />
                <Route path="/recoverPassword" render={
                    () => false} />
                <Route path="/terms" exact render={
                    () => false} />
                <Route path="/vehicle/:vehicleId/documents" component={SideBar} />                
                <Route path="/carWash" component={SideBar} />
                <Route path="/dealerDrafts" component={SideBar} />
                {(token === undefined || hasTokenExpired(token)) && (personaToken === undefined || hasTokenExpired(personaToken))
                    ? false : 
                <Route path="/learnMore"  component={SideBar} />
                }
                <Route path="/po/search" component={SideBar} />
            
                <Route path="/vehicles/vin8/:vin8/:actionType"component={SideBar} />
                <Route path="/vehicles/plate/:plate/state/:state/:actionType" component={SideBar} />
                <Route path="/vehicles/clientId/:clientId/unitNo/:unitNo/:actionType" component={SideBar} />
                <Route path="/vehicles/serviceNo/:serviceNo/:actionType"  component={SideBar}/>

                <Route path="/po/:actionType/:trackNumber" render={ () =>
                   <Switch>
                        <Route path="/po/odometer/:vehicleId" component={SideBar} />
                        <Route path="/po/repairHistory/:vehicleID" component={SideBar}/>
                        <Route path="/po/driverValidationComplaints/:vehicleId" component={SideBar} />
                        <Route path="/po/complaint/:vehicleId" component={SideBar} />
                        <Route path="/po/close/:trackNumber" component={SideBar} />
                        <Route path="/po/clientParameters/:vehicleID" component={SideBar} />
                        <Route path="/po/actionRequired/:trackNumber" component={SideBar} />
                        <Route component={SideBar} />
                   </Switch>
                } />
                <Route path="/tlsWarning/learnMore" component={SideBar} />  
                <Route path="/paymentStatusBulletin/learnMore" component={SideBar} />                
              
            </div>
            <div className={isIE ? classnames('col-sm-12',
                'offset-md-1',
                'col-md-11',
                'offset-lg-2',
                'col-lg-8',
                'offset-xl-0'
            ) : classnames('col-sm-12',
                'offset-md-1',
                'col-md-11',
                'offset-lg-2',
                'col-lg-8',
                'offset-xl-0',
                'col-xl-8'
            )}>
                <Route path="/exceptions" exact render={
                    (routeParams) => (<div className="data-content"><ClientExceptions routeParams={routeParams} /></div>)} />
            <Route path="/sso" exact render={
                    (routeParams) => (<div className="data-content"><SSORedirect routeParams={routeParams} /></div>)} />
                <Route exact path="/login" component={LoginComponents.LoginContent} />
                <Route exact path="/"  render={() => <div className='data-content'>{vehicleSearchCreateComponent}<ShopView/> </div>} />
                <Route exact path="/contact/HelpFAQ" render={(routeParams) => (serviceClassification === "CARWASH" || (token === undefined || hasTokenExpired(token)) )?
                    <div className='data-content'><HelpFAQ routeParams={routeParams} /></div>
                    : <div className='data-content'>{vehicleSearchCreateComponent}<HelpFAQ routeParams={routeParams} /></div>
                } />
                <Route exact path="/contact/FeedbackForm" render={(routeParams) =>(serviceClassification === "CARWASH" || (token === undefined || hasTokenExpired(token))) ?
                    <div className='data-content'><FeedbackForm routeParams={routeParams} /></div>
                    : <div className='data-content'>{vehicleSearchCreateComponent}<FeedbackForm routeParams={routeParams} /></div>
                } />
                <Route path="/yourAccount" exact render={(routeParams) =>(serviceClassification === "CARWASH" ) ? <div className='data-content'><VendorAccount routeParams={routeParams} isPaymentStatusCheckEnabled={appState.isPaymentStatusCheckEnabled}/></div> : <div className='data-content'>{vehicleSearchCreateComponent} <VendorAccount routeParams={routeParams} isPaymentStatusCheckEnabled={appState.isPaymentStatusCheckEnabled} /></div>} />
                <Route path="/ariuser" 
                     exactly render={
                    (routeParams) => (<div className='data-content ari-user-screen'><ARIUser routeParams={routeParams} /></div>)} />
                <Route exact path="/carWash"  render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<CarWashView routeParams={routeParams} /></div>} />
                <Route path="/carWash/vehicle/:vehicleId" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<CarWashCreatePO routeParams={routeParams}/> <CarWashView routeParams={routeParams} /></div>} />

                <Route path="/linkedVendorAccount" exact render={(routeParams) => <div className='data-content'><VendorLinkedAccountsView routeParams={routeParams} /></div>} />
                <Route path="/linkedVendorAccount/Register" exact render={(routeParams) => <div className='data-content'><VendorLinkedRegister routeParams={routeParams} /></div>} />
                <Route path="/recoverPassword" exact render={
                    (routeParams) => (<div className='data-content'><RecoverPassword routeParams={routeParams} /></div>)} />
                <Route path="/resetPassword" 
                     exact render={
                    (routeParams) => (<div className='data-content'><ResetPassword routeParams={routeParams} /></div>)} />
                <Route path="/resetPassword/confirmation/:status"  exact render={
                    (routeParams) => (<div className='data-content'><ResetPasswordResultView routeParams={routeParams} /></div>)} />
                <Route path="/recoverPassword/confirmation/:status"  exact render={
                    (routeParams) => (<div className='data-content'><RecoverPasswordConfirmationComponent routeParams={routeParams} /></div>)} />
                <Route path="/recoverPassword/tooManyAttempts"  exact render={
                    (routeParams) => (<div className='data-content'><RecoverPasswordTooAttemptsComponent routeParams={routeParams} /></div>)} />                
                <Route path="/yourAccount/myUnits" exact render={
                    (routeParams) => (<div className='data-content'>{vehicleSearchCreateComponent}<MyUnits routeParams={routeParams} /></div>)} />
                <Route path="/terms" exact render={
                    (routeParams) => (<div className='data-content'><TermsAndConditions routeParams={routeParams} /></div>)} />

                <Route path="/vehicle/:vehicleId/documents" exact render={
                    (routeParams) => (<div className='data-content'><VehicleDocuments vehicleId={routeParams.match.params.vehicleId} /></div>)} />

                <Route path="/learnMore/:id" exact render={
                     (routeParams) => ((token === undefined || hasTokenExpired(token)) && (personaToken === undefined || hasTokenExpired(personaToken))
                     ? (<div className='data-content'><BulletinMessage routeParams={routeParams} /></div>)
                        : (<div className='data-content'>{vehicleSearchCreateComponent}<BulletinMessage routeParams={routeParams} /></div>))} />

                <Route path="/po/search" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<SearchPO routeParams={routeParams} /></div>} />

                <Route path="/vehicles/vin8/:vin8/:actionType" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<VehicleSearchResultView routeParams={routeParams} /></div>} />
                <Route path="/vehicles/plate/:plate/state/:state/:actionType" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<VehicleSearchResultView routeParams={routeParams} /></div>} />
                <Route path="/vehicles/clientId/:clientId/unitNo/:unitNo/:actionType" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<VehicleSearchResultView routeParams={routeParams} /></div>} />
                <Route path="/vehicles/serviceNo/:serviceNo/:actionType" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<VehicleSearchResultView routeParams={routeParams} /></div>} />				

                <Route path="/persona/setupPassword"
                exact render={
                    (routeParams) => (<div className='data-content'><ResetPassword routeParams={routeParams} /></div>)} />
                <Route path="/persona/resetPassword" 
                     exact render={
                    (routeParams) => (<div className='data-content'><ResetPassword routeParams={routeParams} /></div>)} />
                <Route path="/persona/resetPassword/confirmation/:status"  exact render={
                    (routeParams) => (<div className='data-content'><ResetPasswordResultView routeParams={routeParams} /></div>)} />
                {
                    location.pathname.startsWith('/Persona/')?
                    <Route exact render={
                        (routeParams) => (<div className='data-content'><PersonaRoutes routeParams={routeParams} /></div>)} />:
                        false
                }   
                {
                    location.pathname.startsWith('/AccidentManagement/')?
                    <Route exact children={<div className='data-content'><AccidentManagementRoute isAccidentAssignmentFeatureEnabled={appState.isAccidentAssignmentFeatureEnabled} vendorId={vendorId} /></div>} />:
                        undefined
                } 
                
                <Route exact path="/po/:actionType/:trackNumber" render={(matchProps) => (
                    <Switch>
                        <Route path="/po/repairHistory/:vehicleID" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<RepairHistoryView routeParams={routeParams} /></div>} />
                        <Route path="/po/odometer/:vehicleId" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<OdometerView routeParams={routeParams} /></div>} />
                        <Route path="/po/driverValidationComplaints/:vehicleId" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<DriverValidationComplaints routeParams={routeParams} /></div>} />
                        <Route path="/po/complaint/:vehicleId" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<ComplaintView routeParams={routeParams} /></div>} />
                        <Route path="/po/close/:trackNumber" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<ClosePO routeParams={routeParams} /></div>} />
                        <Route path="/po/clientParameters/:vehicleID" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<ClientParameters routeParams={routeParams} /></div>} />
                        <Route path="/po/actionRequired/:trackNumber" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<ActionRequired routeParams={routeParams} /></div>} />
                        <Route path="/po/adjustment/:poNumber" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<Adjustment routeParams={routeParams} /></div>} />

                        <Route render={() =>
                            (matchProps.match.params.actionType == 'add' || matchProps.match.params.actionType == 'edit' || matchProps.match.params.actionType == 'view')
                                ? <div className='data-content'>{vehicleSearchCreateComponent}<AddEditView routeParams={matchProps} /></div>
                                : undefined
                        } />
                    </Switch>
                )} />
                <Route path="/attentionNeeded" exact render={(routeParams) => <div className='data-content'>{vehicleSearchCreateComponent}<AttentionNeeded routeParams={routeParams} /></div>} />

                <Route  path="/dealerDrafts" exact render={(routeParams) => <div className='data-content'> <DealerDraftsDealerRequests routeParams={routeParams} /></div>} />
                <Route  path="/dealerDrafts/:actionType/:requestNo" render={(matchProps) => (
                             <div className='data-content'>{vehicleSearchCreateComponent}<DealerDraftsRequestsDetails routeParams={matchProps}/></div>
                )}/>

                <Route path="/vendorEnrollment/request" exact render={(routeParams) => (<div className='data-content'><VendorEnrollmentRequestView routeParams={routeParams} /></div>)} />
                <Route path="/tlsWarning/learnMore"  exact render={
                    (routeParams) => (<div className='data-content tls_learn_more_screen'><TLSLearnMore routeParams={routeParams} /></div>)} />
                <Route path="/PaymentStatusBulletin/learnMore"  exact render={
                    (routeParams) => (<div className='data-content'><PaymentStatusBulletin routeParams={routeParams} /></div>)} />
            </div>

            <Route path="/vendorEnrollment/request/detail/:enrollId" exact render={(routeParams) => (
                <div className='vendor-enrollment__screen'>
                    <div className='row'>
                        <div className='col-md-2' />
                        <div className='col-md-8 data-content'><VendorEnrollmentView routeParams={routeParams} /></div>
                        <div className='col-md-2' />
                    </div>
                </div>
            )} />



            <Route path="/registration/:step" exact render={(routeParams) => (
                <div className='container'>
                    <div className='row justify-center'>
                        <div className='col-md-2' />
                        <div className='col-md-8 data-content vendor-registration__screen'><RegistrationView routeParams={routeParams} /></div>
                        <div className='col-md-2' />
                    </div>
                </div>
            )} />
        </div>;
    }


function mapStateToProps(state) {
    return {
        token: state.appState.getIn(['serverData', 'shared', 'token']),
        personaToken: state.appState.getIn(['serverData', 'shared', 'personaToken']),
        vendorId: state.appState.getIn(['serverData', 'shared', 'vendorId']),
        personaUser:state.appState.getIn(['serverData', 'shared', 'personaUser']),    
        serviceClassification: state.appState.getIn(['serverData', 'shared', 'serviceClassification']),
        country: state.appState.getIn(['uiData', 'shared','selectedCountry']),                                                
        isDealerDraft: falseIfUndefined(state.appState.getIn(['serverData', 'shared', 'isDealerDraft'])),        
        adminUser: state.appState.getIn(["serverData", "shared", "adminUser"]),
        selectedLocale: state.appState.getIn(['uiData','shared','selectedLocale']),
    };
}

export default withRouter(connect(mapStateToProps)(Content));
