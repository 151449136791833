import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import './VendorTaxInformation.css'


class VendorTaxInformation extends React.Component {


    render() {
        const messages = defineMessages({
            tax_information_caption: {
                id: 'tax_information_caption',
                description: 'tax information title',
                defaultMessage: 'Tax information'
            },
            federal_tax_caption: {
                id: 'federal_tax_caption',
                description: 'federal tax title',
                defaultMessage: 'Federal tax classification'
            },
            ssn_caption: {
                id: 'ssn_caption',
                description: 'ssn title',
                defaultMessage: 'Social Security no./Employer ID'
            },
            gst_caption_canada:{
                id: 'gst_caption_canada',
                description: 'Business Number',
                defaultMessage: 'Business Number'                
            }

        });
        return <div>
            {this.props.country=="CAN"?false:
            <div className="tax_info_component__view row">
                <div className="col-md-8  tax_info_component__title">
                    <FormattedMessage {...messages.tax_information_caption}/>
                </div>
            </div>
            }
            {this.props.country=="CAN"?false:
            <div className="tax_info_component__view row">
                <div className="col-md-6 tax_info_component__text">
                    {this.props.taxClassification}
                </div>
            </div>
            }
            <div className="tax_info_component__view row">
                <div className="col-md-8  tax_info_component__title">
                    {this.props.country=="CAN"? <FormattedMessage {...messages.gst_caption_canada}/> :<FormattedMessage {...messages.ssn_caption}/>}
                    
                </div>
            </div>
            <div className="tax_info_component__view row">
                <div className="col-md-3 tax_info_component__text">
                    {this.props.taxId}
                </div>
            </div>
        </div>;
    }
}

export default (VendorTaxInformation);
