import React from 'react';
import './RecoverPasswordConfirmationComponent.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as constants from 'constants/App';
import { connect } from 'react-redux';
import TranslationIcon from 'components/SiteTranslations';
import {Link} from 'react-router-dom';

class RecoverPasswordConfirmationComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    onSubmitHandler(e) {
        e.preventDefault();
        this.props.dispatch({ type: constants.NAVIGATE_TO, path: `/login` });
    }

    render() {
        const messages = defineMessages({
            thank_you_recover_password_title: {
                id: 'thank_you_recover_password_title',
                description: 'thank you title for forgot password',
                defaultMessage: 'Reset your password'
            },
            thank_you_recover_password_subtext: {
                id: 'thank_you_recover_password_subtext',
                description: 'thank you subText for forgot password',
                defaultMessage: 'If the information you provided matches the account information we have on record, we will send an email within the next 10 minutes to the address on file with details for resetting your password.'
            },
            return_button: {
                id: 'return_button',
                description: 'Return button',
                defaultMessage: 'Return'
            },
            login_button: {
                id: 'login_button',
                description: 'login button',
                defaultMessage: 'Login'
            }
        });

        return <div className="recover-confirmation-component">
            <div className="recover_confirmation_help_container row">
                <span>
                    <TranslationIcon backgroundColor='blue' />
                </span>
            </div>
            <div className="recover-confirmation__header">
                <span>
                    <FormattedMessage {...messages.thank_you_recover_password_title} />
                </span>
            </div>
            <div className="recover-confirmation__body">
                <div className="recover-confirmation__message">
                    <span>
                        <FormattedMessage  {...messages.thank_you_recover_password_subtext} />
                    </span>
                </div>
            </div>
            <div className="recover-confirmation__footer">
                <div className="pull-left recover-confirmation_button"
                    role="button"
                    onClick={this.onReturnClickHandler.bind(this)}>
                    <img src="/assets/VMS_33button_back_blue.png" />
                    {' '}
                    <FormattedMessage {...messages.return_button} />
                </div>
                <div
                    className="pull-right recover-confirmation_button"
                    role="button"
                    onClick={this.onSubmitHandler.bind(this)} >
                    <FormattedMessage {...messages.login_button} />
                    {' '}
                    <img src="/assets/VMS_33button_go_bblue.png" />
                </div>
            </div>
        </div>;
    }
}

function select(state) {
    return {};
}

export default connect(select)(RecoverPasswordConfirmationComponent);