import React from 'react';
import styled from 'styled-components';

export const PageLayout = styled.div`
	margin: 2px;	
	padding-left: 4rem;
	padding-right: 4rem;	
`;

export const PageLayoutClear = styled.div`
	margin: 2px;	
	padding-left: 4rem;
	padding-right: 4rem;
	background: white;	
`;