import React from 'react';
import {defineMessages,FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import Complaint from './Complaint';
import AddComplaint from './AddComplaint';
import './Complaints.css';
import LineItems from './../../screens/PO/AddEditView/components/LineItems';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';

class Complaints extends React.Component {

    /*Filter the line items based on the complaint code*/
    getLineItemsByComplaintCode(complaintCode) {
        return this.props.lineItems.filter(function (lineItem) {
            return (lineItem.get('complaintCode') == complaintCode);
        });
    }

    getComplaintTypes() {
        /* add property to determine which complaint types were already selected to prevent duplicates */
        return this.props.complaintTypes.map(
            complaintType => complaintType.concat(
                {selected: this.props.complaints.count(
                    existingComplaint=>existingComplaint.get('code')==complaintType.get('code')) > 0
                    || (this.props.disableMaintenanceComplaintType && complaintType.get('code')=='MA99')})
        );

    }

    getComplaintAndLineItems(complaint, key) {
        return <div key={key}><Complaint key={key}
                                         complaintKey={key}
                                         actionType={this.props.actionType}
                                         canChangeComplaint={this.props.canChangeComplaint}
                                         title={complaint.get('title')}
                                         complaintTypes={this.getComplaintTypes()}
                                         code={complaint.get('code')}
                                         description={complaint.get('description')}
                                         isSelectMode={complaint.get('isSelectMode')}
                                         isInitialAddMode={this.props.isInitialAddMode}
                                         wasComplaintSkipped={complaint.get('wasComplaintSkipped')}
                                         isComplaintOpen={complaint.get('isComplaintOpen')}
                                         showErrorMessages={this.props.showErrorMessages}
                                         onComplaintTypeClick={this.props.onComplaintTypeClick}
                                         onChangeComplaintClick={this.props.onChangeComplaintClick}
                                         onComplaintNotesSave={this.props.onComplaintNotesSave}
                                         onComplaintNotesSaveState={this.props.onComplaintNotesSaveState}
                                         notesText={this.props.notes != undefined ? this.props.notes.getIn([key, 'noteText']) : undefined}
                                         notesHistory={ emptyMapIfUndefined(this.props.notesHistory).filter(note => note.get('complaintCode') === complaint.get('code') && complaint.get('code') != undefined)}
                                         savingNote={this.props.notes != undefined ? this.props.notes.getIn([key, 'savingNote']) : false}
                                         isChangingComplaintType={this.props.creatingOrEditingComplaintForComplaintKey === key || this.props.skippingComplaintForComplaintKey === key }
                                         selectedCurrency={this.props.selectedCurrency}
                                         listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
        />

            {complaint.get('code') == undefined && this.props.isInitialAddMode
            && complaint.get('isSelectMode') && this.props.complaints.count(v => v.get('wasComplaintSkipped')) == 0 ?
                 false : true}

            {this.props.lineItems != undefined && complaint.get('code') != undefined ?
                <LineItems lineItems={this.getLineItemsByComplaintCode(complaint.get('code'))}
                           actionType={this.props.actionType}
                           complaintCode={complaint.get('code')}
                           complaintType={complaint.get('description')}
                           isServiceComplaint={complaint.get('code')=='MA99'}
                           onAddLineItemsClick={this.props.onAddLineItemsClick}
                           onMissingProductCodeIconClick={this.props.onMissingProductCodeIconClick}
                           onDeleteLineItemClick={this.props.onDeleteLineItemClick}
                           onEditLineItemClick={this.props.onEditLineItemClick}
                           onChangeRepairTypeClick={this.props.onChangeRepairTypeClick}
                           onUpdateLineItemClick={this.props.onUpdateLineItemClick}
                           onCancelUpdateLineItemClick={this.props.onCancelUpdateLineItemClick}
                           hideCostInformation={this.props.hideCostInformation}
                           lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                           onOpenTiresInfo = {this.props.onOpenTiresInfo}
                           selectedCurrency={this.props.selectedCurrency}
                />
                : undefined}
        </div>
    }

    render() {
        return <div className={classNames('add-edit-view-complaints',
                {'add-edit-view-complaints__dark-background': !this.props.isInitialAddMode}
                )}>
            {this.props.complaints.sortBy(v=>v.get('index'))
                       .map(this.getComplaintAndLineItems, this)
                       .valueSeq()}

        </div>
    }
}


export default Complaints;