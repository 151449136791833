import React from 'react';
import ReactDOM from 'react-dom';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { Popover, Overlay } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import './VehicleFrame.css';
import classnames from 'classnames';
import { Axle, FrontAxle, RearAxle } from './Axle';
import { TreadDepthUnit } from './TreadDepthUnit';
import { Wheel } from './Wheel';
import Submit from 'components/Submit';
import Help from 'components/Help';
import StepNumber from "components/StepNumber";


class VehicleFrame extends React.Component {

    render() {
        const messages = defineMessages({
            add_axle: {
                id: 'add_axle',
                description: 'add_axle',
                defaultMessage: 'Add Axle'
            },
            remove_axle: {
                id: 'remove_axle',
                description: 'remove_axle',
                defaultMessage: 'Remove Axle'
            },
            measurements_in: {
                id: 'measurements_in',
                description: 'measurements_in',
                defaultMessage: 'Measurements in:'
            },
            spare_tire: {
                id: 'spare_tire',
                description: 'spare_tire',
                defaultMessage: 'Spare'
            },
        })
        return (
            <div >
                {
                    !this.props.editOnly ?
                        <StepNumber number={this.props.stepNumber}/>
                        : undefined
                }
                <div className='vehicle-frame__title'>{this.props.title}<Help videoId="tires" whiteSpaceAtBottomHeight="1.25em"/></div>
                <div className='vehicle-frame__tread-unit'>
                    <TreadDepthUnit caption={<FormattedMessage {...messages.measurements_in} />}
                        onChangeMeasurementUnit={this.props.onChangeMeasurementUnit}
                        defaultMeasurementUnit={this.props.defaultTreadDepthUnit} 
                        canShowUnitChangeConfirmation = {this.props.canShowUnitChangeConfirmation}
                        isProductType = {'tires'}
                        />
                </div>
                <div className='vehicle-frame__container'>
                    <div className={'vehicle-frame__axles'}>
                        <FrontAxle
                            leftOuterWheel={this.props.frontAxle.leftOuterWheel}
                            leftInnerWheel={this.props.frontAxle.leftInnerWheel}
                            rightInnerWheel={this.props.frontAxle.rightInnerWheel}
                            rightOuterWheel={this.props.frontAxle.rightOuterWheel}

                            onSelectTire={this.props.onSelectTire}
                            onAddWheel={this.props.onAddWheel}
                            canAddWheel={this.props.frontAxle.canAddWheel}
                            onRemoveWheel={this.props.onRemoveWheel}
                            canRemoveWheel={!this.props.frontAxle.canAddWheel}
                            onChangeTreadDepth={this.props.onChangeTreadDepth}
                            newTreadDepth={this.props.newTreadDepth}
                            oldTreadDepth={this.props.oldTreadDepth}
                            defaultMeasurementUnit={this.props.defaultTreadDepthUnit}
                            showTireValidationError={this.props.showTireValidationError}
                            readOnly={this.props.readOnly}

                        />
                        {
                            this.props.rearAxles.map((item) => {
                                return <RearAxle key={'rearAxle' + item.axlePosition}
                                    position={item.axlePosition}
                                    leftOuterWheel={item.leftOuterWheel}
                                    leftInnerWheel={item.leftInnerWheel}
                                    rightInnerWheel={item.rightInnerWheel}
                                    rightOuterWheel={item.rightOuterWheel}
                                    onSelectTire={this.props.onSelectTire}
                                    onAddWheel={this.props.onAddWheel}
                                    canAddWheel={item.canAddWheel}
                                    onRemoveWheel={this.props.onRemoveWheel}
                                    canRemoveWheel={!item.canAddWheel}
                                    onChangeTreadDepth={this.props.onChangeTreadDepth}
                                    newTreadDepth={this.props.newTreadDepth}
                                    oldTreadDepth={this.props.oldTreadDepth}
                                    defaultMeasurementUnit={this.props.defaultTreadDepthUnit}
                                    showTireValidationError={this.props.showTireValidationError}
                                    readOnly={this.props.readOnly}

                                />
                            })
                        }

                        {!this.props.readOnly ?
                            <div className='row vehicle-frame__axle-footer'>
                                {
                                    this.props.canAddAxle ?
                                        <div className='col-md-9'>
                                            <Submit
                                                caption={<FormattedMessage {...messages.add_axle} />}
                                                onClickHandler={this.props.onAddAxle}
                                                imagePath="/assets/VMS_33button_add_blue.png"
                                                imageHeight={'25px'}
                                                imageWidth={'auto'}
                                            />
                                        </div>
                                        : undefined
                                }
                                {
                                    this.props.canRemoveAxle ?
                                        <div className='col-md-9'>
                                            <Submit
                                                caption={<FormattedMessage {...messages.remove_axle} />}
                                                onClickHandler={this.props.onRemoveAxle}
                                                imagePath="/assets/VMS_33button_remove_blue.png"
                                                imageHeight={'25px'}
                                                imageWidth={'auto'}
                                            />
                                        </div>
                                        : undefined
                                }
                            </div>
                            : undefined
                        }

                    </div>
                    {
                        !this.props.spareWheel ? false :
                            <div className={classnames('vehicle-frame__spare', this.props.readOnly ? 'tire-container-disabled' : undefined)}>
                                <div className='vehicle-frame__spare-caption'><FormattedMessage {...messages.spare_tire} /></div>
                                <Wheel axle={this.props.spareWheel.axlePosition}
                                    tempId={this.props.spareWheel.spareWheel.tempId}
                                    type={this.props.spareWheel.spareWheel.type}
                                    tireSelected={this.props.spareWheel.spareWheel.tireSelected}
                                    onSelectTire={this.props.onSelectTire}
                                    onChangeTreadDepth={this.props.onChangeTreadDepth}
                                    newTreadDepth={this.props.spareWheel.spareWheel.newTreadDepth}
                                    oldTreadDepth={this.props.spareWheel.spareWheel.oldTreadDepth}
                                    defaultMeasurementUnit={this.props.defaultTreadDepthUnit}
                                    canSelectTire={this.props.spareWheel.spareWheel.canSelectTire}
                                    showTireValidationError={this.props.showTireValidationError}
                                    readOnly={this.props.readOnly}
                                />
                            </div>
                    }
                </div>

            </div >
        );
    }
};



export default VehicleFrame;  