import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Map} from 'immutable';
import './ClientExceptionsView.css';
import classnames from 'classnames';

class ClientExceptionsView extends React.Component {

    render() {
        const messages = defineMessages({
            exceptions_Name: {
                id: 'exceptions_Name',
                description: 'exceptions_Name',
                defaultMessage: 'Name'
            },
            exceptions_Base_Intellipay_Fee: {
                id: 'exceptions_Base_Intellipay_Fee',
                description: 'exceptions_Base_Intellipay_Fee',
                defaultMessage: 'Base Holman Pay Fee'
            },
            exceptions_Escalator: {
                id: 'exceptions_Escalator',
                description: 'exceptions_Escalator',
                defaultMessage: 'Escalator for non PartnerConnect use'
            },
            exceptions_Other_Notes: {
                id: 'exceptions_Other_Notes',
                description: 'exceptions_Other_Notes',
                defaultMessage: 'Other Notes'
            },
            exceptions_Canada: {
                id: 'exceptions_Canada',
                description: 'exceptions_Canada',
                defaultMessage: 'Canada'
            },
            exceptions_Supplier: {
                id: 'exceptions_Supplier',
                description: 'exceptions_Supplier',
                defaultMessage: 'Supplier:'
            },
            exceptions_Supplier_sub_header: {
                id: 'exceptions_Supplier_sub_header',
                description: 'exceptions_Supplier_sub_header',
                defaultMessage: 'The following government entities have different terms than our standard Terms and Conditions (visit <a href="https://partnerconnect.holman.com/terms">https://partnerconnect.holman.com/terms</a> ). Please note that transactions for these specific entities will be processed according to the chart below. Transactions for all other Holman clients will follow the standard Terms and Conditions.'
            },
            exceptions_gov_canada: {
                id: 'exceptions_gov_canada',
                description: 'exceptions_gov_canada',
                defaultMessage: 'Federal Government of Canada'
            },
            exceptions_intellipay_4: {
                id: 'exceptions_intellipay_4',
                description: 'exceptions_intellipay_4',
                defaultMessage: '4% Holman Pay Fee'
            },
            exceptions_intellipay_3: {
                id: 'exceptions_intellipay_3',
                description: 'exceptions_intellipay_3',
                defaultMessage: '3% Holman Pay Fee'
            },
            exceptions_no_escalator: {
                id: 'exceptions_no_escalator',
                description: 'exceptions_no_escalator',
                defaultMessage: 'No escalator'
            },
            exceptions_other_notes1: {
                id: 'exceptions_other_notes1',
                description: 'exceptions_other_notes1',
                defaultMessage: 'Allow existing payment flexibilities'
            },
            exceptions_other_notes2: {
                id: 'exceptions_other_notes2',
                description: 'exceptions_other_notes2',
                defaultMessage: 'Can mandate APC and IP'
            },
            exceptions_prov_1: {
                id: 'exceptions_prov_1',
                description: 'exceptions_prov_1',
                defaultMessage: 'Province of Northwest Territories'
            },
            exceptions_prov_2: {
                id: 'exceptions_prov_2',
                description: 'exceptions_prov_2',
                defaultMessage: 'Province of British Columbia'
            },
            exceptions_prov_3: {
                id: 'exceptions_prov_3',
                description: 'exceptions_prov_3',
                defaultMessage: 'Province of Nova Scotia'
            },
            exceptions_prov_4: {
                id: 'exceptions_prov_4',
                description: 'exceptions_prov_4',
                defaultMessage: 'Province of Alberta'
            },
            exceptions_prov_5: {
                id: 'exceptions_prov_5',
                description: 'exceptions_prov_5',
                defaultMessage: 'Province of Ontario'
            },
            exceptions_prov_6: {
                id: 'exceptions_prov_6',
                description: 'exceptions_prov_6',
                defaultMessage: 'BC Hydro'
            },
            exceptions_prov_7: {
                id: 'exceptions_prov_7',
                description: 'exceptions_prov_7',
                defaultMessage: 'Hydro One'
            },
            exceptions_rcmp: {
                id: 'exceptions_rcmp',
                description: 'exceptions_rcmp',
                defaultMessage: 'RCMP'
            },
            exceptions_state_1: {
                id: 'exceptions_state_1',
                description: 'exceptions_state_1',
                defaultMessage: 'State of Connecticut'
            },
            exceptions_state_2: {
                id: 'exceptions_state_2',
                description: 'exceptions_state_2',
                defaultMessage: 'State of Georgia'
            },
            exceptions_state_3: {
                id: 'exceptions_state_3',
                description: 'exceptions_state_3',
                defaultMessage: 'State of Minnesota'
            },
            exceptions_state_4: {
                id: 'exceptions_state_4',
                description: 'exceptions_state_4',
                defaultMessage: 'State of New York'
            },
            exceptions_state_5: {
                id: 'exceptions_state_5',
                description: 'exceptions_state_5',
                defaultMessage: 'State of North Carolina'
            },
            exceptions_state_6: {
                id: 'exceptions_state_6',
                description: 'exceptions_state_6',
                defaultMessage: 'State of West Virginia'
            },
            exceptions_state_7: {
                id: 'exceptions_state_7',
                description: 'exceptions_state_7',
                defaultMessage: 'State of Wisconsin'
            },
            exceptions_us: {
                id: 'exceptions_us',
                description: 'exceptions_us',
                defaultMessage: 'United States'
            },
        });


        return <div className="client_exceptions_table data_content">
            <div className="client_exceptions__container__width">
                <div className="row  client_exceptions_text_wrapper client_exceptions__header">
                    <FormattedMessage {...messages.exceptions_Supplier}/>
                </div>
                <div className="client_exceptions_text_wrapper2">
                    <div className="row client_exceptions__sub-header">
                        <FormattedMessage {...messages.exceptions_Supplier_sub_header}  values={{ br: data => <br/>, a: data =><a href='https://partnerconnect.holman.com/terms'>{data}</a>,b: data => <b>{data}</b>}}/>
                    </div>
                </div>
            </div>
            <div>
                <div className="client_exceptions_title"><FormattedMessage {...messages.exceptions_Canada}/></div>
            </div>
            <table className="client_exceptions__active_requests__table">
                <thead>
                <tr>
                    <th className="client_exceptions__active_requests__header-cell
                           client_exceptions__active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.exceptions_Name}/></th>
                    <th className="client_exceptions__active_requests__header-cell
                           client_exceptions__active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.exceptions_Base_Intellipay_Fee}/></th>
                    <th className="client_exceptions__active_requests__header-cell
                           client_exceptions__active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.exceptions_Escalator}/></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_gov_canada}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_rcmp}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_prov_1}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_prov_2}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_prov_3}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_prov_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_3}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_prov_5}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_prov_6}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_prov_7}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                </tbody>
            </table>
            <div>
                <div className="client_exceptions_title"><FormattedMessage {...messages.exceptions_us}/></div>
            </div>
            <table className="client_exceptions__active_requests__table">
                <thead>
                <tr>
                    <th className="client_exceptions__active_requests__header-cell
                           client_exceptions__active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.exceptions_Name}/></th>
                    <th className="client_exceptions__active_requests__header-cell
                           client_exceptions__active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.exceptions_Base_Intellipay_Fee}/></th>
                    <th className="client_exceptions__active_requests__header-cell
                           client_exceptions__active_requests__header-cell_align-left">
                        <FormattedMessage {...messages.exceptions_Escalator}/></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_state_1}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_state_2}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_state_3}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_3}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_state_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_3}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_state_5}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_4}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_state_6}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_3}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                <tr>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active-po-list__cell_left-corner-round',
                    )}><FormattedMessage {...messages.exceptions_state_7}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                    )}><FormattedMessage {...messages.exceptions_intellipay_3}/></td>
                    <td className={classnames('client_exceptions__active_requests__cell2',
                        'client_exceptions__active_requests__cell_shadow',
                        'client_exceptions__active_requests__cell_right-corner-round'
                    )}><FormattedMessage {...messages.exceptions_no_escalator}/></td>
                </tr>
                </tbody>
            </table>
        </div>;
    }
}


export default (ClientExceptionsView);