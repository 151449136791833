import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';

export function uiDataRecoverPassword(state = initialAppState.getIn(['uiData', 'recoverPassword']), action) {

    switch (action.type) {
        case 'RECOVER_PASSWORD_SELECTED_COUNTRY_CHANGED':
        return state.setIn(['popOver', 'isPopOverDisplayedShowARIId'], false)
        .setIn(['popOver', 'isPopOverDisplayedShowTaxId'], false)
            .setIn(['popOver', 'isPopOverDisplayedShowBusinessNumber'], false)
            .setIn(['popOver', 'isPopOverDisplayedShowFederalTaxId'], false)
            .setIn(['popOver', 'isPopOverDisplayedShowEmail'], false)
            .setIn(['popOver', 'isPopOverDisplayedShowPersonal'], false)
            .setIn(['personal'], '').setIn(['errorMessage'], []);
        case constants.SAVE_RECOVER_PASSWORD_INFORMATION_ID:
            return state.set('ARIId', action.recoverPasswordARIId);
        case constants.SAVE_RECOVER_PASSWORD_INFORMATION_PERSONAL:
            return state.set('personal', action.recoverPasswordPersonal);
        case constants.POP_OVER_DISPLAY_SHOW_ARI_ID:
            return state.setIn(['popOver', 'isPopOverDisplayedShowARIId'],
                action.popOverDisplayShowARIId, !state.getIn(['popOver', 'isPopOverDisplayedShowARIId'],
                    action.popOverDisplayShowARIId));
        case constants.POP_OVER_DISPLAY_SHOW_PERSONAL:
            return state.setIn(['popOver', 'isPopOverDisplayedShowPersonal'],
                action.popOverDisplayShowVendorPersonal, !state.getIn(['popOver', 'isPopOverDisplayedShowPersonal'],
                    action.popOverDisplayShowVendorPersonal));
        case constants.CLEAR_RECOVER_PASSWORD_ERROR:
            return state.setIn(['popOver', 'isPopOverDisplayedShowARIId'], false)
            .setIn(['popOver', 'isPopOverDisplayedShowTaxId'], false)
                .setIn(['popOver', 'isPopOverDisplayedShowBusinessNumber'], false)
                .setIn(['popOver', 'isPopOverDisplayedShowFederalTaxId'], false)
                .setIn(['popOver', 'isPopOverDisplayedShowEmail'], false)
                .setIn(['popOver', 'isPopOverDisplayedShowPersonal'], false)
            .setIn(['ARIId'], '').setIn(['personal'], '').setIn(['errorMessage'], []);
        case constants.SEND_RECOVER_PASSWORD_REJECTED:
            return state.set('isFetchingData', false).set('errorMessage', [{ message: action.errorMessage }]).set('isRequestedRejected', true);
        case constants.SEND_RECOVER_PASSWORD_RESOLVED:
            return state.set('isFetchingData', false).set('isRequestedRejected', false);
        case constants.SEND_RECOVER_PASSWORD_APPROVED:
            return state.set('isApprovedData', true).set('vendorEmail', action.recoverPasswordEmail);
        case constants.RECOVER_PASSWORD_MOUNT:
            return state.set('isApprovedData', false);
        case constants.ERROR_RECOVER_PASSWORD_PERSONAL:
            return state.setIn(['errorMessage', 'informationPersonalError'], action.recoverPasswordPersonalError);
        case constants.SEND_RECOVER_PASSWORD_REQUESTED:
            return state.set('isFetchingData', true).setIn(['errorMessage'], []);

        case constants.POP_OVER_DISPLAY_SHOW_TAX_ID:
            return state.setIn(['popOver', 'isPopOverDisplayedShowTaxId'],
                action.popOverDisplayShowTaxId, !state.getIn(['popOver', 'isPopOverDisplayedShowTaxId'],
                    action.popOverDisplayShowTaxId));

        case constants.POP_OVER_DISPLAY_SHOW_BUSINESS_NUMBER:
            return state.setIn(['popOver', 'isPopOverDisplayedShowBusinessNumber'],
                action.popOverDisplayShowBusNum, !state.getIn(['popOver', 'isPopOverDisplayedShowBusinessNumber'],
                    action.popOverDisplayShowBusNum));

        case constants.POP_OVER_DISPLAY_SHOW_EMAIL:
            return state.setIn(['popOver', 'isPopOverDisplayedShowEmail'],
                action.popOverDisplayShowEmail, !state.getIn(['popOver', 'isPopOverDisplayedShowEmail'],
                    action.popOverDisplayShowEmail));

        case constants.POP_OVER_DISPLAY_SHOW_FEDERAL_TAX_ID:
            return state.setIn(['popOver', 'isPopOverDisplayedShowFederalTaxId'],
                action.popOverDisplayShowFedTaxId, !state.getIn(['popOver', 'isPopOverDisplayedShowFederalTaxId'],
                    action.popOverDisplayShowFedTaxId));


        default:
            return state;
    }
}