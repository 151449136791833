// Libraries
import React from 'react';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import lodash from 'lodash';

class TruncateWithTooltip extends React.Component {                        
    constructor(props) {
        super(props);        
        this.id = lodash.uniqueId('tooltip');        
    } 

    render() {
        let props = this.props;
        
        if (typeof props.children !== 'string' || props.children instanceof String)
            return false;
        else if (props.children.length===0 || props.children===null)
            return false;
        else {
            let fullText = props.children;        

            if (fullText.length > props.maxLength) {
                let truncatedText = props.children.substr(0, props.maxLength) + '...';
                let placement = props.placement || 'right';

                return <div>
                    <OverlayTrigger 
                        placement={placement}
                        overlay={
                            <Tooltip id={this.id}> {fullText} </Tooltip>
                        }
                    >                
                        <span>{truncatedText}</span>
                    </OverlayTrigger>
                </div>
            }
            else {
                return <div>{fullText}</div>
            }
        }
    }
}



export default TruncateWithTooltip;