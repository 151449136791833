import React from 'react';
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import { defineMessages, FormattedMessage,injectIntl } from 'react-intl';
import classnames from 'classnames';
import Submit from 'components/Submit';
import TireSpecifications from 'TireSpecs/TireSpecifications';
import TireDetails from 'TireSpecs/TireDetails';
import VehicleFrame from 'TireSpecs/VehicleFrame';
import ClientParametersComponent from 'components/ClientParametersComponent';
import './Tires.css';

class Tires extends React.Component {

    render() {
        const messages = defineMessages({
            save_tire_changes: {
                id: 'save_tire_changes',
                description: 'Save',
                defaultMessage: 'Save'
            },
            parameters_header: {
                id: 'parameters_header',
                description: 'Header for ata parameters ',
                defaultMessage: 'Parameters '
            },
            empty_parameters: {
                id: 'empty_parameters',
                description: 'Description when parameters are empty',
                defaultMessage: 'None at this time'
            },
            add_new_line_item_caption: {
                id: 'add_new_line_item_caption',
                description: 'Add new line item caption',
                defaultMessage: 'Add {complaint_description} items'
            },
            continue_to_tire_spec: {
                id: 'continue_to_tire_spec',
                description: 'Continue',
                defaultMessage: 'Continue'
            },
            return: {
                id: 'return',
                description: 'Return',
                defaultMessage: 'Return'
            },tires_replaced: {
                id: 'tires_replaced',
                description: 'tires_replaced',
                defaultMessage: 'Click on the Tires being replaced'
            },
            tires_tread: {
                id: 'tires_tread',
                description: 'tires_tread',
                defaultMessage: 'Tires tread depth'
            },
            new_tires: {
                id: 'new_tires',
                description: 'new_tires',
                defaultMessage: 'New Tire Details'
        }
        });

        let productCodeParams = this.props.ataParametersSorted == undefined ? '' :
            this.props.ataParametersSorted.map((param) => {
                return <ClientParametersComponent key={param.get('id')}
                    description={param.get('description')}
                />
            });


        const saveTireInfoLink = <Submit
            caption={<FormattedMessage {...messages.save_tire_changes} />}
            imagePath="/assets/VMS_33button_add_blue.png"
            onClickHandler={this.props.onSaveTireChanges}
            showSpinner={this.props.isSavingTireInfo}
            spinnerType="blue" />

        const tireDetailContainterWidthClass = 'add-new-line-items__vehicle-frame__20';
        const vehicleFrameContainerWidthClass = 'add-new-line-items__tire-details__80';


        const titleForTiresSelection = <FormattedMessage {...messages.tires_replaced} />;
        const titleWhenTiresNotSelectable = <FormattedMessage {...messages.tires_tread} />;
        const {formatMessage} = this.props.intl;
        return (
            <div >
                <div className="add-new-line-items">
                   
                    <div key='div-container' className={classnames('add-new-line-items__step-4-5-container', this.props.readOnly ? 'tire-container-disabled' : undefined)}>
                        <div key='div-container-spec'>
                            <TireSpecifications>
                                <div className={classnames('tire-specification', !this.props.showTireManufacturingDetails ? 'space-around' : undefined)}>
                                    <div className={vehicleFrameContainerWidthClass}>
                                        <VehicleFrame title={this.props.showTireManufacturingDetails ? this.props.readOnly ? titleWhenTiresNotSelectable : titleForTiresSelection : this.props.readOnly ? titleWhenTiresNotSelectable : titleWhenTiresNotSelectable}
                                            frontAxle={this.props.frontAxle}
                                            rearAxles={this.props.rearAxles}
                                            spareWheel={this.props.spareWheel}
                                            onAddWheel={this.props.onAddWheel}
                                            onRemoveWheel={this.props.onRemoveWheel}
                                            onAddAxle={this.props.onAddAxle}
                                            canAddAxle={this.props.canAddAxle}
                                            onRemoveAxle={this.props.onRemoveAxle}
                                            canRemoveAxle={this.props.canRemoveAxle}
                                            onChangeTreadDepth={this.props.onChangeTreadDepth}
                                            onSelectTire={this.props.onSelectTire}
                                            onChangeMeasurementUnit={this.props.onChangeMeasurementUnit}
                                            defaultTreadDepthUnit={this.props.defaultTreadDepthUnit}
                                            showTireValidationError={this.props.showTireValidationError}
                                            readOnly={this.props.readOnly}
                                            editOnly={true}
                                            canShowUnitChangeConfirmation = {this.props.canShowUnitChangeConfirmation}
                                             isProductType = {'tires'}
                                        />
                                    </div>
                                    {
                                        this.props.showTireManufacturingDetails ?
                                            <div td className={tireDetailContainterWidthClass}>
                                                {
                                                    <TireDetails title={formatMessage(messages.new_tires)}
                                                        exampleText={'eg. 195/65R15.5'}
                                                        manufacturers={this.props.tireManufacturers}
                                                        reasons={this.props.reasonsForReplacingTires}
                                                        defaultManufacturer={this.props.defaultTireManufacturer}
                                                        defaultModel={this.props.defaultTireModel}
                                                        defaultReason={this.props.defaultReasonForReplacingTire}
                                                        tireWidth={this.props.defaultTireWidth}
                                                        tireAspectRatio={this.props.defaultTireAspectRatio}
                                                        tireRimDiameter={this.props.defaultTireRimDiameter}
                                                        onChangeDetails={this.props.onChangeTireDetails}
                                                        validationErrors={this.props.tireDetailsValidationErrs}
                                                        onRemoveTireDetailsValidationErr={this.props.onRemoveTireDetailsValidationErr}
                                                        editOnly={true}
                                                    />
                                                }
                                            </div>
                                            : undefined
                                    }

                                </div>
                            </TireSpecifications>

                        </div>
                    </div>
                    {
                        !this.props.readOnly ?
                            <div className="row">
                                <div className={classnames('col-md-12', 'add-new-line-items__save')}>
                                    {saveTireInfoLink}
                                </div>
                            </div>
                            : undefined
                    }
                    <div className="row add-new-line-parameters">
                        <div className="add-new-line-parameters__header">
                            <FormattedMessage {...messages.parameters_header} />
                        </div>
                        <div>
                            {productCodeParams.size === 0 ?
                                <p className="add-new-line-empty__description">
                                    <FormattedMessage {...messages.empty_parameters} />
                                </p>
                                :
                                <div> {productCodeParams} </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default injectIntl(Tires);