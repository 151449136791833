import * as constants from 'constants/App';
import { Map, fromJS } from 'immutable';
import moment from 'moment';
import bitInspectionComponentData from '../data/BitInspectionComponentData';
import * as bitInspectionEnums from '../data/BitInspectionEnums';

const componentDetails = bitInspectionComponentData;

const initialUiData = Map();
const defaultUiData = Map({
    showWindow: false,
    formData: Map({
        hasInspectionPassed: null,        
		inspectorAttested: false,
		inspectorSignature: '',                		
		inspectionDate: null,
		carrierName: '',
		unitNumber: '',
		year: '',
		make: '',
		licensePlate: '',
		isAllOk: false
    }),
	formValidationMessages: Map(),
	componentDetails: Map(),
	shouldSkipFailOptions: false, 
	assetType: ''
});
const initialServerData = Map();

export function uiDataBitInspection(state = initialUiData, action) {    
    switch (action.type) {
		case constants.BIT_INSPECTION__DISPLAY:		
			let stateTemp = defaultUiData.set('showWindow', action.show);
            return stateTemp;		

        case constants.BIT_INSPECTION__RESET:
            return defaultUiData;
		case constants.BIT_INSPECTION__RESET_ERROR:
			return state.set('errorMessage', undefined);
		case constants.BIT_INSPECTION__SET_DEFAULT_VALUES:			
			let newState = state
				.setIn(['formData', 'carrierName'], action.carrierName)
				.setIn(['formData', 'unitNumber'], action.unitNumber)
				.setIn(['formData', 'year'], action.year)
				.setIn(['formData', 'make'], action.make)
				.setIn(['formData', 'licensePlate'], action.licensePlate)
				.setIn(['formData', 'mileage'], action.mileage)
				.set('assetType', action.assetType);				

			if (action.assetType == 'trailer')
				newState = newState.setIn(['componentDetails'], fromJS(componentDetails.trailer));
			else if (action.assetType == 'bus')
				newState = newState.setIn(['componentDetails'], fromJS(componentDetails.bus));
			else
				newState = newState.setIn(['componentDetails'], fromJS(componentDetails.truck));

			return newState;
			
		case constants.BIT_INSPECTION__SET_FORM_DATA: {
			let newState = state.setIn(['formData', action.field], action.value);

			if (action.field === 'isAllOk' && action.value === true) {
				let otherComponentId = null;
				switch(state.get('assetType'))
				{
					case 'trailer':
						otherComponentId = 121;
						break;
					case 'bus':
						otherComponentId = 190;
						break;
					default:
						otherComponentId = 91;
						break;					
				}
			
				newState = newState.set('componentDetails', newState.get('componentDetails').map((component) => 				
				(component.set('componentResultCode', component.get('id') == otherComponentId ? bitInspectionEnums.componentResultCode.NA : bitInspectionEnums.componentResultCode.PASS))));

			}

			return newState;
		}
        case constants.BIT_INSPECTION__SET_VALIDATION_MESSAGE:
            return state.setIn(['formValidationMessages', action.field], action.message);
        case constants.BIT_INSPECTION__RESET_VALIDATION_MESSAGES:
            return state.set('formValidationMessages', Map());

		case constants.BIT_INSPECTION__SET_COMPONENT_DETAILS: {
			let newState = state.setIn(['componentDetails', action.componentId, action.field], action.value);

			if (action.value !== bitInspectionEnums.componentResultCode.PASS) {
				newState = newState.setIn(['formData', 'isAllOk'], false);
			}

			return newState;
		}
		case constants.BIT_INSPECTION__SKIP_FAIL_OPTIONS:
			return state.set('shouldSkipFailOptions', true);

		case constants.BIT_INSPECTION__SAVE_CONFIRMATION_REQUESTED:
			return state
				.set('errorMessage', undefined)
				.set('isSaving', true);
		case constants.BIT_INSPECTION__SAVE_CONFIRMATION_REJECTED:
			return state
				.set('errorMessage', action.errorMessage)
				.set('isSaving', false);
		case constants.BIT_INSPECTION__SAVE_CONFIRMATION_RESOLVED:
			return state.set('isSaving', false);

		case constants.BIT_INSPECTION__SAVE_FORM_REQUESTED:
			return state
				.set('errorMessage', undefined)
				.set('isSaving', true);
		case constants.BIT_INSPECTION__SAVE_FORM_REJECTED:
			return state
				.set('errorMessage', action.errorMessage)
				.set('isSaving', false);
		case constants.BIT_INSPECTION__SAVE_FORM_RESOLVED:
			return state.set('isSaving', false);

        default:
            return state;
    }
}

export function serverDataBitInspection(state = initialServerData, action) {
    switch (action.type) {                               
        default:
            return state;
    }
}
