// libraries
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage,FormattedNumber } from 'react-intl';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, emptyStringIfNull, renderError,validateEmail,zeroIfUndefined } from 'utils/HelperFunctions';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// our stuff
import * as constants from 'constants/App';
import './ProcessedRequests.css';

class ProcessedRequests extends React.Component {

    getFilteredRecords(poList, showAll) {


        if (showAll == false && poList.count() > 0)
            poList = poList.take(10);

        return poList;
    }


    getFooter(pos, showAll) {

        const messages = defineMessages({
            show_all_waiting_requests: {
                id: 'show_all_waiting_requests',
                description: 'Link at bottom of PO List to show all POs',
                defaultMessage: 'Show all {poCount} requests'
            },
            hide_older_requests: {
                id: 'hide_older_requests',
                description: 'Link at bottom of PO List to hide older approved POs',
                defaultMessage: 'Hide older requests'
            }
        });

        // Don't show footer if we have 10 or less records
        if (pos.count() <= 10)
            return undefined;
        // If we are not showing all, show button to show all
        else if (!showAll) {
            return <div role="button" className="active_requests__footer">
                <FormattedMessage values={{poCount:pos.count()}}
                                  {...messages.show_all_waiting_requests}/>
                {' '}<img src="/assets/VMS_button_show_all_pos.png"/>
            </div>;
        }
        else {
            return <div role="button" className="active_requests__footer">
                <FormattedMessage {...messages.hide_older_requests}/>
                {' '}<img src="/assets/VMS_button_hide_older_pos.png"/>
            </div>;
        }
    }





    render() {


        const messages = defineMessages({
            dealer_draft_processed_requests_title: {
                id: 'dealer_draft_processed_requests_title',
                description: 'dealer_draft_processed_requests_title',
                defaultMessage: 'Processed Requests'
            },
            dealer_draft_active_requests_client_no: {
                id: 'dealer_draft_active_requests_client_no',
                description: 'dealer_draft_active_requests_client_no',
                defaultMessage: 'Client No.'
            },
            dealer_draft_active_requests_vehicle_no: {
                id: 'dealer_draft_active_requests_vehicle_no',
                description: 'dealer_draft_active_requests_vehicle_no',
                defaultMessage: 'Holman Unit No.'
            },
            dealer_draft_active_requests_requests_no: {
                id: 'dealer_draft_active_requests_requests_no',
                description: 'dealer_draft_active_requests_requests_no',
                defaultMessage: 'Request No.'
            },
            dealer_draft_active_requests_vin8: {
                id: 'dealer_draft_active_requests_vin8',
                description: 'dealer_draft_active_requests_vin8',
                defaultMessage: 'Vin8'
            },
            dealer_draft_active_requests_no_requests_caption: {
                id: 'dealer_draft_active_requests_no_requests_caption',
                description: 'dealer_draft_active_requests_no_requests_caption',
                defaultMessage: 'None at this Time'
            },
            view: {
                id: 'view',
                description: 'view',
                defaultMessage: 'VIEW'
            },
        });

        const processedPOs = this.getFilteredRecords(this.props.processedPOs, this.props.showAll);

        return <div className="process_requests-list table-responsive">
            <div className="process_requests-list__caption">
                <FormattedMessage {...messages.dealer_draft_processed_requests_title}/>

            </div>
            <table className="process_requests-list__table" width="100%">
                <thead>
                <tr>
                    <th className="process_requests-list__header-cell">
                        <FormattedMessage {...messages.dealer_draft_active_requests_client_no}/>
                    </th>
                    <th className="process_requests-list__header-cell">
                        <FormattedMessage {...messages.dealer_draft_active_requests_vehicle_no}/>
                    </th>
                    <th className="process_requests-list__header-cell">
                        <FormattedMessage {...messages.dealer_draft_active_requests_requests_no}/>
                    </th>
                    <th className="process_requests-list__header-cell">
                        <FormattedMessage {...messages.dealer_draft_active_requests_vin8}/>
                    </th>
                    <th className="process_requests-list__header-cell"/>
                </tr>
                </thead>
                <tbody>
                {
                    (processedPOs.count() == 0)
                        ?  <tr>
                            <td colSpan="6" className="process_requests-list__cell process_requests-list__cell_align-center">
                                <FormattedMessage {...messages.dealer_draft_active_requests_no_requests_caption}/>
                            </td>
                        </tr>

                        :

                        processedPOs.valueSeq().map(request =>
                            <tr key={request.get('id')}>
                                <td className="process_requests-list__cell">
                                    {request.get("clientId")}
                                </td>
                                <td className="process_requests-list__cell">
                                    {request.get("ariUnitNo")}
                                </td>
                                <td className="process_requests-list__cell">
                                    {request.get("id")}
                                </td>
                                <td className="process_requests-list__cell">
                                    {request.get("vin8").slice(9, request.get("vin8").length)}
                                </td>
                                <td className="process_requests-list__cell
                                               process_requests-list__cell_edit-action">
                                    <Link to={`/dealerDrafts/view/${request.get('id')}`}>
                                        <FormattedMessage {...messages.view}/>
                                    </Link>
                                </td>
                            </tr>
                        )
                }
                </tbody>
            </table>
            <div onClick={this.props.toggleShowAll}>
                {this.getFooter(this.props.processedPOs, this.props.showAll)}
            </div>
        </div>;
    }
}



export default ProcessedRequests;
