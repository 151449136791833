import {createSelector} from 'reselect';
import {Map, Set} from 'immutable';
import {LINE_ITEM_TYPE} from 'constants/App';
import {emptyMapIfUndefined, emptyStringIfUndefined,getListOfComplaintsForWhichNoteIsRequired,emptyArrayIfUndefined} from 'utils/HelperFunctions';
import {select} from "redux-saga/effects";

//Gets cost for a PO line item
//  Takes
//      lineItems: po line items
//      ataCode: ataCode for which cost needs to be fetched
//      type: part or labor
function getCostFromPO(lineItems, ataCode, type) {
    let filteredLineItem = emptyMapIfUndefined(lineItems.filter(x => x.get('lineItemType') == type && x.get('productCode') == ataCode));
    if (filteredLineItem.size > 1)
        return 0;
    let lineItemAmount = filteredLineItem.getIn([filteredLineItem.keys().next().value, 'totalAmount']);
    let cost = lineItemAmount == undefined ? '' : Number(lineItemAmount);
    return (isNaN(cost) ? 0 : cost);
}

//Gets cost from a PO line item or a service line item
//  Takes
//      serviceLineItems: map of service line items
//      lineItems: po line items
//      ataCode: ataCode for which cost needs to be fetched
//      type: part or labor
function getCost(serviceLineItems, lineItems, ataCode, type) {
    if (type == LINE_ITEM_TYPE.PART)
        return lineItemTypeExists(lineItems, ataCode, LINE_ITEM_TYPE.PART) ?
            getCostFromPO(lineItems, ataCode, LINE_ITEM_TYPE.PART) : getCostFromServiceLineItems(serviceLineItems, ataCode, 'partCost');
    else if (type == LINE_ITEM_TYPE.LABOR)
        return lineItemTypeExists(lineItems, ataCode, LINE_ITEM_TYPE.LABOR) ?
            getCostFromPO(lineItems, ataCode, LINE_ITEM_TYPE.LABOR) : getCostFromServiceLineItems(serviceLineItems, ataCode, 'laborRate');
}


//Checks if line item type exists in PO line items
//  Takes
//      lineItems: po line items
//      ataCode: ataCode for which cost needs to be fetched
//      type: part or labor
function lineItemTypeExists(lineItems, ataCode, type) {
    let exist = lineItems.filter(x => x.get('lineItemType') == type && x.get('productCode') == ataCode);
    return exist != undefined && exist.size > 0;
}

//This function returns the cost amount for part or labor for temporarily saved service line items
//  Takes
//      serviceLineItems: map of service line items
//      ataCode: ataCode for which cost needs to be fetched
//      costType: part or labor
function getCostFromServiceLineItems(serviceLineItems, ataCode, costType) {
    let cost = serviceLineItems.get(ataCode) == undefined ? '' : Number(serviceLineItems.get(ataCode).get(costType));
    return (isNaN(cost) ? 0 : cost);
}

//Returns selected list of services
const selectedListOfServicesSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'complaintView', 'servicesSelected']));

//Returns list of ata codes already in the PO
const lineItemsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'lineItems']));

//Returns master data for ATA codes
const ataCodesMasterSelector = state => 
emptyMapIfUndefined(state.appState.getIn(['serverData', 'complaintView', 'ataCodes']))
.merge(state.appState.getIn(['serverData', 'view', 'ataCodes']));

//Returns list of ata codes populated for the selected services
const serviceLineItemsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'addServiceLineItemsView', 'serviceLineItems']));

const serviceATAListSelector = createSelector(
    state => state.appState.getIn(['serverData', 'complaintView', 'maintenanceServices']),
    state => state.appState.getIn(['serverData', 'complaintView', 'regulatoryServices']),
    selectedListOfServicesSelector,
    lineItemsSelector,
    ataCodesMasterSelector,
    serviceLineItemsSelector,
    state => state.appState.getIn(['serverData', 'view', 'maintenanceServices']),
    state => state.appState.getIn(['serverData', 'view', 'regulatoryServices']),
    (maintenanceServices, 
    regulatoryServices, 
    selectedServices, 
    lineItems, 
    ataCodesMaster, 
    serviceLineItems,
    maintenanceServicesForEditMode, 
    regulatoryServicesForEditMode) => {

        //get ata codes for the selected service id.
        let atasFromMaintenanceServices;
        let atasFromRegulatoryServices;

        if (maintenanceServices != undefined && maintenanceServices.size > 0)
            atasFromMaintenanceServices = maintenanceServices.filter(s => selectedServices.has(s.get('id'))).map(a => a.get('ataCodes'))
                .reduce((p, c) => {
                    let atas = c.reduce((p1, c1) => p1.add(c1.get('code')), Set());
                    return p.concat(atas);
                }, Set());

        if (regulatoryServices != undefined && regulatoryServices.size > 0)
            atasFromRegulatoryServices = regulatoryServices.filter(s => selectedServices.has(s.get('id'))).map(a => a.get('ataCodes'))
                .reduce((p, c) => {
                    let atas = c.reduce((p1, c1) => p1.add(c1.get('code')), Set());
                    return p.concat(atas);
                }, Set());

        if (atasFromMaintenanceServices == undefined) atasFromMaintenanceServices = new Set();
        if (atasFromRegulatoryServices == undefined) atasFromRegulatoryServices = new Set();

        let ataCodesFromSelectedServices = new Set([...atasFromMaintenanceServices, ...atasFromRegulatoryServices]);

        //Get the ata codes from the AddEditView state
        let otherAtaCodesForMaintenanceServices = emptyMapIfUndefined(maintenanceServicesForEditMode)
                .map(a => a.get('ataCodes'))
                .reduce((p, c) => {
                    let atas = c.reduce((p1, c1) => c1.get('code')==undefined || c1.get('code') == null?p1:p1.add(c1.get('code')), Set());
                    return p.concat(atas);
                }, Set()); 

        let otherAtaCodesForRegulatoryServices = emptyMapIfUndefined(regulatoryServicesForEditMode)
                .map(a => a.get('ataCodes'))
                .reduce((p, c) => {
                    let atas = c.reduce((p1, c1) => c1.get('code')==undefined || c1.get('code') == null?p1:p1.add(c1.get('code')), Set());
                    return p.concat(atas);
                }, Set()); 

        if (otherAtaCodesForMaintenanceServices == undefined) otherAtaCodesForMaintenanceServices = new Set();
        if (otherAtaCodesForRegulatoryServices == undefined) otherAtaCodesForRegulatoryServices = new Set();

        //add the set to the final list of selected services
        ataCodesFromSelectedServices = new Set([...ataCodesFromSelectedServices, ...otherAtaCodesForMaintenanceServices, ...otherAtaCodesForRegulatoryServices]);

        //filter ata codes from po line items
        let existingATACodesInPOForLabor = emptyMapIfUndefined(lineItems.filter(x => x.get('lineItemType') == LINE_ITEM_TYPE.LABOR)).map(i => i.get('productCode')).toSet();
        let existingATACodesInPOForPart = emptyMapIfUndefined(lineItems.filter(x => x.get('lineItemType') == LINE_ITEM_TYPE.PART)).map(i => i.get('productCode')).toSet();
        //get unique set of ata codes from the above sets
        let existingATACodesInPO = existingATACodesInPOForLabor.intersect(existingATACodesInPOForPart);
        //get services ata codes that are not present in the po
        let remainingATACodes = ataCodesFromSelectedServices.subtract(existingATACodesInPO);

        //return map for ata codes to be added
        return remainingATACodes.reduce((ataCodeList, ataCode) => {
            if(ataCode!=null && ataCode!=undefined)
                return ataCodeList.set(ataCode, Map({
                    'productCode': ataCode,
                    'description': ataCodesMaster.getIn([ataCode, 'description']),
                    'partCost': getCost(serviceLineItems, lineItems, ataCode, LINE_ITEM_TYPE.PART),
                    'laborRate': getCost(serviceLineItems, lineItems, ataCode, LINE_ITEM_TYPE.LABOR),
                    'total': getCost(serviceLineItems, lineItems, ataCode, LINE_ITEM_TYPE.PART) + getCost(serviceLineItems, lineItems, ataCode, LINE_ITEM_TYPE.LABOR),
                    'partCostEnabled': !lineItemTypeExists(lineItems, ataCode, LINE_ITEM_TYPE.PART),
                    'laborRateEnabled': !lineItemTypeExists(lineItems, ataCode, LINE_ITEM_TYPE.LABOR)
                }));
            else 
                return ataCodeList;     
        }
            , Map());
    });

//Returns if add service line item model is allowed to be shown
const showWindowSelector = state => state.appState.getIn(['uiData', 'addServiceLineItemsView', 'showWindow']);

//Returns if add services line item modal will be shown
const showAddServiceLineItemsWindowSelector = createSelector(
    serviceATAListSelector,
    showWindowSelector,
    (serviceATAList, showWindow) => {
        return (serviceATAList != undefined && serviceATAList.size > 0) && showWindow;
    }
);

const errorMessageSelector = state => {
    let errorMessages = emptyStringIfUndefined(state.appState.getIn(['uiData','shared','errorMessage']));  
    if(errorMessages=='' || errorMessages == undefined)
        return [];
    else 
        return [{message:errorMessages}];
};

//Returns selected list of complaints
const complaints = state => emptyArrayIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints']));


const listOfComplaintsForWhichNoteIsRequiredSelector = createSelector(complaints, state => state, (complaint, state) => {
    let listOfComplaints = [];

    listOfComplaints = getListOfComplaintsForWhichNoteIsRequired(state);


    //to do : error message
    return listOfComplaints.map(complaint => {
        return complaint;
    });


});


const Shared = createSelector(
  serviceATAListSelector,
  showAddServiceLineItemsWindowSelector,
  errorMessageSelector,
    listOfComplaintsForWhichNoteIsRequiredSelector,
    (serviceATAList,
    showAddServiceLineItemsWindow,
    errorMessage,
     listOfComplaintsForWhichNoteIsRequired) => {
        return {
            serviceATAList,
            showAddServiceLineItemsWindow,
            errorMessage,
            listOfComplaintsForWhichNoteIsRequired
        };
    }
);

export default Shared;