//libraries
import React from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';

class Notes extends React.Component 
{    
        constructor(props){
            super(props);
            this.state={
                showAll:false            
            }
        }
        

        showMoreClickHandler(e){
            this.setState({showAll:true});
        }

        showLessClickHandler(e){
            this.setState({showAll:false});
        }
    
    render() 
    { 

        

        const showMore=this.props.intl.formatMessage({id: 'note_show_more_description',description: 'show more',
        defaultMessage: 'show more'});
        const showLess=this.props.intl.formatMessage({id: 'note_show_less_description', description: 'show less',
        defaultMessage: 'show less'});

        return <div>
        {
            this.props.text==null || this.props.text.toString().length<200 ? 
            <div>{this.props.text}</div>
            :   
            this.state.showAll ?                        
            <div>{ this.props.text}{" ... "}
            <a onClick={this.showLessClickHandler.bind(this)}>{showLess}</a></div>        
            :
            <div>{ this.props.text.substring(0,200)}{" ... "}
            <a onClick={this.showMoreClickHandler.bind(this)}>{showMore}</a></div>
        }
        </div>
        
       ;    
    }   
           
}

export default injectIntl(Notes);

