import React from 'react';
import ReactDOM from 'react-dom'
const scrollIntoView = require('dom-scroll-into-view')
import { defineMessages, FormattedMessage } from 'react-intl';
import { Popover } from 'react-bootstrap';
import moment from 'moment';

import './CraneInspectionForm.css';
import * as constants from 'constants/App';
import CheckCircle from 'components/CheckCircle';
import VehicleComponents from './VehicleComponents';
import Spinner from 'components/Spinner';
import CraneInspectionVehicleHistory from './CraneInspectionVehicleHistory';
import CraneInspectionFormHeader from './CraneInspectionFormHeader';

class CraneInspectionForm extends React.Component {
	
	handleChange = (event) => {
		event.preventDefault();
		this.props.setFormData(event.currentTarget.id, event.currentTarget.value);
	}

	render() {
		const messages = defineMessages({
			crane_inspection_form__title: {
				id: 'crane_inspection_form__title',
				description: 'Crane inspection form title',
				defaultMessage: 'Mobile crane safety inspection form'
			},
			crane_inspection_form__save: {
				id: 'crane_inspection_form__save',
				description: 'Crane inspection form save and continue button',
				defaultMessage: 'Save and continue'
			}
		});

		return (
			<div className='crane_inspection_form'>

				{/*Title*/}

				<div className='crane_inspection_form__title'>
					<FormattedMessage {...messages.crane_inspection_form__title} />
				</div>

				<CraneInspectionVehicleHistory
					formData={this.props.formData}
					setFormData={this.props.setFormData}
					formValidationMessages={this.props.formValidationMessages}
				/>

				<div className='crane_inspection_form__outer_box'>
					<CraneInspectionFormHeader
						formData={this.props.formData}
						setFormData={this.props.setFormData}
						formValidationMessages={this.props.formValidationMessages}
					/>

					<VehicleComponents
						formData={this.props.formData}
						setFormData={this.props.setFormData}
						formValidationMessages={this.props.formValidationMessages}
						componentDetails={this.props.componentDetails}
						assetType={this.props.assetType}
						userLocale={this.props.userLocale}
						poCreationDate={this.props.poCreationDate}
						onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						onAllOkClick={this.props.onAllOkClick}
						onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}
					/>

				</div>

				{/*Footer*/}

				<div className='crane_inspection_form__footer'>
					<div className='row'>

						<div className='offset-sm-6 col-sm-6 crane_inspection_form__no_gutter_col'>
							<div className='crane_inspection_form__save' onClick={this.props.onFormSaveClick}>
								<FormattedMessage {...messages.crane_inspection_form__save} />{' '}
								<img src='/assets/VMS_33button_add_blue.png' />
								<div className='crane_inspection_form__spinner'>
									{this.props.isSaving &&
										<Spinner spinnerType='blue' />
									}
								</div>
							</div>
						</div>

					</div>
				</div>

			</div>
		);
	}
}

export default CraneInspectionForm;