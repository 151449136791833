import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';

export function uiDataLinkedVendorRegister(state = initialAppState.getIn(['uiData','vendorLinkedRegister']), action) {
    switch (action.type) {
        case constants.UPDATE_LINKED_ENROLLMENT_INFO:
            let fieldName = action.requestInfo.name;
            if (fieldName == "LINKED_VENDOR_ENROLLMENT_CONTACT_NAME")
                return state.setIn(['linkedRequestInfo', 'contactName'], action.requestInfo.value);
            if (fieldName == "LINKED_VENDOR_ENROLLMENT_EMAIL_ADDRESS")
                return state.setIn(['linkedRequestInfo', 'emailAddress'], action.requestInfo.value);
            if (fieldName ==  "LINKED_VENDOR_ENROLLMENT_NEW_PASSWORD")
                return state.setIn(['linkedRequestInfo', 'newPassword'], action.requestInfo.value);
            if (fieldName == "LINKED_VENDOR_ENROLLMENT_CONFIRM_PASSWORD")
                return state.setIn(['linkedRequestInfo', 'confirmPassword'], action.requestInfo.value);
            else
                return state;
        default:
            return state;
    }
}