// #region Imports
import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import SearchUsersStep from "./SearchUsersStep";
import AddNewUserStep from "./AddNewUserStep";
import PermissionsStep from "./PermissionsStep";
import styled from "styled-components";
import {    
  ModalBody
} from "DesignSpecs/components/FormComponents";
// #endregion

// #region Styled Components
const ModalStyled = styled(Modal).attrs({
  dialogClassName: "childStyle"
})`
  .childStyle {
    width: 900px;
    max-width: 900px;
}`;
// #endregion

// #region Component
const AddUsersToStore = ({ token, personaUserId, routeParams }) => {  
  // #region State
  const [selectedUsers, setSelectedUsers] = useState([]);    
  const [initialUser, setInitialUser] = useState([]);      
  const [step, setStep] = useState('SearchUsers'); 
  // #endregion

  // #region Ref
  // #endregion  

  // #region Event handlers
  const handleAddUsers = (defaultUser) => {
    setInitialUser(defaultUser);
    setStep('AddUsers');
  }
  
  const handleAddNewUserClick = (user) => {
    setSelectedUsers(selectedUsers.concat(user));
  }

  const handleUserSelect = (user) => {
    if (
      selectedUsers == null ||
      selectedUsers.length == 0 ||
      selectedUsers.filter(a => {
        return a.id == user.id;
      }).length == 0
    ) {
      setSelectedUsers(selectedUsers.concat(user));
    } else {
      setSelectedUsers(selectedUsers.filter(a => a.id != user.id));
    }
  }

  const handleRemoveUser = (userId) => {
    if (userId != null && userId != undefined) {
      var index = selectedUsers.findIndex(a => a.id == userId);
      if (index > -1) {        
        setSelectedUsers(selectedUsers.filter(a => a.id != userId));
      }
    }
  }  
  // #endregion

  // #region Effects
  // #endregion

  // #region International messages  
  // #endregion

  // #region Render
  return (    
    <ModalStyled		  
      show={true}
      backdrop="static"
      keyboard={false}          
    >                     
      <ModalBody>          
        { step === 'SearchUsers' ?
          <SearchUsersStep
            selectedUsers={selectedUsers}
            token={token}
            personaUserId={personaUserId}
            routeParams={routeParams}
            onUserSelect={handleUserSelect}
            onRemoveUser={handleRemoveUser}
            onAddUsers={handleAddUsers}
            onContinue={()=>setStep('AddPermissions')}
          />
        : step === 'AddUsers' ?
          <AddNewUserStep 
            initialUser={initialUser}
            selectedUsers={selectedUsers}
            token={token}
            personaUserId={personaUserId}
            routeParams={routeParams}
            onAddNewUserClick={handleAddNewUserClick} 
            onRemoveUser={handleRemoveUser}              
            onReturn={()=>setStep('SearchUsers')}
            onContinue={()=>setStep('SearchUsers')}
          />
        : step === 'AddPermissions' &&
          <PermissionsStep
            selectedUsers={selectedUsers}            
            token={token}
            personaUserId={personaUserId}
            routeParams={routeParams}            
            onRemoveUser={handleRemoveUser}
            onReturn={()=>setStep('SearchUsers')}
          />
        }    
            
      </ModalBody>                
    </ModalStyled>    
  );
};
// #endregion

const mapStateToProps = state => {
  return {
    token: state.appState.getIn(["serverData", "shared", "personaToken"]),
    personaUserId: state.appState.getIn(["serverData", "shared", "personaUserId"]),
  };
};

export default connect(mapStateToProps)(AddUsersToStore);