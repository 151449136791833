// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './VehicleComponentGroup.css';

class VehicleComponentGroup extends React.Component {
	render() {
		const messages = defineMessages({
			crane_inspection_form__pass: {
				id: 'crane_inspection_form__pass',
				description: 'Crane inspection form pass header.',
				defaultMessage: 'Sat.'
			},
			crane_inspection_form__fail: {
				id: 'crane_inspection_form__fail',
				description: 'Crane inspection form fail header.',
				defaultMessage: 'Unsat.'
			},
			crane_inspection_form__na: {
				id: 'crane_inspection_form__na',
				description: 'Crane inspection form Na header.',
				defaultMessage: 'N/A'
			},
			crane_inspection_form__deficiencies: {
				id: 'crane_inspection_form__deficiencies',
				description: 'Crane inspection form Na header.',
				defaultMessage: 'Deficiencies'
			}
		});

		return (
			<div className='crane-inspection-vehicle-component-flex-row crane-inspection-vehicle-component-flex-header'>
				<div className='crane-inspection-vehicle-component-flex-col'>
					{this.props.componentName}
				</div>
				<div className='crane-inspection-vehicle-component-flex-col'>
					<FormattedMessage {...messages.crane_inspection_form__pass} />
				</div>
				<div className='crane-inspection-vehicle-component-flex-col'>
					<FormattedMessage {...messages.crane_inspection_form__fail} />
				</div>
				<div className='crane-inspection-vehicle-component-flex-col'>
					<FormattedMessage {...messages.crane_inspection_form__na} />
				</div>
				<div className='crane-inspection-vehicle-component-flex-col'>
					<FormattedMessage {...messages.crane_inspection_form__deficiencies} />
				</div>
			</div>
		)
	}
}

export default VehicleComponentGroup;