import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import { Map } from 'immutable';
const emptyMap = Map();
export function* fetchingRequestsNoDetails(context,action) {

    try {

        const state = yield select();

        yield put({ type: 'FETCH_REQUEST_NO_DETAILS_REQUESTED' });

        const requestOrder = yield call(Api.fetchRequestsNoDetails, helperFunctions.getToken(state), action.requestNo);


        const lineItems =  yield call(Api.dealerDraftFetchLineItems, action.requestNo,  helperFunctions.getToken(state));

        const complaints = [{
            "code":"dealer_draft",
            "description":"Vehicle delivery charges"
        }];
        let lineItemsDealerDraft = Map();
        for (var key in lineItems) {

             lineItemsDealerDraft=  lineItemsDealerDraft.set(key.toString(),Map({
                 productDescription: lineItems[key].description,
                 lineItemId: lineItems[key].productId,
                 unitPrice: lineItems[key].unitPrice,
                 complaintCode: "dealer_draft",
                 approvalStatus: "approved",
                 lineItemType: "dealer_draft",
                 quantity: 1,
                 totalAmount: lineItems[key].unitPrice

             }))
        }

        var requestDetails = {
            requestOrder,
            lineItemsDealerDraft,
            complaints,
            requestStatus: requestOrder.status === "OPEN" || requestOrder.status === "ACTIVE"  ? "DEALERDRAFT" : "VIEW"
        };

        yield put({ type: 'FETCH_REQUEST_NO_DETAILS_RESOLVED',requestDetails });

    }
    catch (e) {

        yield put({
            type: 'FETCH_REQUEST_NO_DETAILS_REJECTED',
            errorCode: e.code ? e.code : '',
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });
        yield put({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });
        yield call(context.history.push(`/dealerDrafts`));
    }
}

export function* updateLineItem(action) {
    const { lineItemKey, lineItem } = action;
    try {
        const state = yield select();
        const token = helperFunctions.getToken(state);
        const requestNo = state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder', 'id']);
        const originalUnitPrice = state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'lineItems', lineItemKey, 'unitPrice']);

        yield put({ type: "DEALER_DRAFT_UPDATE_LINE_ITEM_REQUESTED", lineItemKey });

        // update line item on PO
        if (originalUnitPrice !== lineItem.get('unitPrice')) {
            yield call(Api.dealerDraftEditLineItem, requestNo, lineItemKey, lineItem.get('unitPrice'),token);
        }

        yield put({ type: "DEALER_DRAFT_UPDATE_LINE_ITEM_RESOLVED", lineItemKey, lineItem });

    }
    catch (e) {
        yield put({
            type: 'DEALER_DRAFT_UPDATE_LINE_ITEM_REJECTED',
            errorCode: e.code ? e.code : '',
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });
        window.scrollTo(0, 0);
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* deleteLineItem(action) {
    try {
        const lineItemKey = action.lineItemKey;
        yield put({ type: "DEALER_DRAFT_DELETE_LINE_ITEM_REQUESTED", lineItemKey });

        // Read data from app state
        const state = yield select();
        const requestNo = state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'requestOrder', 'id']);

        // delete line item from PO
        yield call(Api.dealerDraftDeleteLineItem, requestNo, action.lineItemKey, helperFunctions.getToken(state));

        yield put({ type:  "DEALER_DRAFT_DELETE_LINE_ITEM_RESOLVED", lineItemKey });

    }
    catch (e) {
        yield put({
            type: 'DEALER_DRAFT_DELETE_LINE_ITEM_REJECTED',
            errorCode: e.code ? e.code : '',
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });

    }
}

export function* fetchingDealerDraftProductDetails(action) {

    try {

        const state = yield select();

        yield put({ type: 'FETCH_PRODUCT_DETAILS_REQUESTED' });

        const productDetails = yield call(Api.fetchDealerDraftProductDetails,action.requestNo,helperFunctions.getToken(state));

        yield put({ type: 'FETCH_PRODUCT_DETAILS_RESOLVED',productDetails });

    }
    catch (e) {

        yield put({
            type: 'FETCH_PRODUCT_DETAILS_REJECTED',
            errorCode: e.code ? e.code : '',
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });

    }
}

export function* dealerDraftAddNewLineItemHandler(action) {
    try {


        //clear out any error messages

        yield put({ type: "DEALER_DRAFT_ADD_NEW_LINE_ITEM_REQUESTED" });

        // Read data from app state
        const state = yield select();
        const draftTotal = state.appState.getIn(['uiData','dealerDraftRequestsApproval','requestOrder','draftTotal']);

        const existingLineItems = state.appState.getIn(['uiData', 'dealerDraftRequestsApproval', 'lineItems']) || emptyMap;

        // Check if part or labor line already exists on PO

            if (existingLineItems.filter(x => x.get('productDescription') === action.lineItem.description).size > 0) {
                yield put({
                    type: "DEALER_DRAFT_ADD_NEW_LINE_ITEM_REJECTED",
                    errorMessage: [{ message: 'The item you have selected already exists on this Request. Please update the existing line item or contact Holman for assistance.' }]
                });
                window.scrollTo(0, 0);
                return;
            }

        yield* dealerDraftAddNewLineItem(action.lineItem.description,action.lineItem.lineItemKey, action.lineItem.unitPrice,action.lineItem.requestNo,helperFunctions.getToken(state));

        // Reset data in app state
        yield put({ type: "DEALER_DRAFT_CLEAR_FIELDS_AFTER_SAVE_ADD_NEW_LINE_ITEM_DATA" });

        yield put({ type:"DEALER_DRAFT_ADD_NEW_LINE_ITEM_RESOLVED"});

        // Set line items details for viewing in the add new line item
        let lineItemForView = Map({
            productCode: action.lineItem.lineItemKey,
            productDescription: action.lineItem.description
        });
        yield put({ type: "DEALER_DRAFT_SAVE_RECENT_ITEMS_ADDED_TO_REQUEST", lineItemForView });
    }
    catch (e) {

        yield put({ type:  'DEALER_DRAFT_ADD_NEW_LINE_ITEM_REJECTED', errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: "DEALER_DRAFT_ADD_NEW_LINE_ITEM_REJECTED", errorObject: e });
    }
}

export function* dealerDraftAddNewLineItem(description,lineItemKey,cost,requestNo,token) {

    let lineItem = {
        productId: lineItemKey,
        description: description,
        unitPrice: cost,
    };

    // call api to create new line
    yield call(Api.dealerDraftCreateLineItem, requestNo, lineItem,token);

    let lineItemNew = {
        productDescription: description,
        lineItemId:lineItemKey,
        unitPrice: cost,
        complaintCode: "dealer_draft",
        approvalStatus: "approved",
        lineItemType: "dealer_draft",
        quantity: 1,
        totalAmount: cost
    };

    // save diagnosis line in app state
    yield put({ type: "DEALER_DRAFT_SAVE_LINE_ITEM", lineItem: lineItemNew});

}

export function* dealerDraftRequestPayment(context,action) {

    try {
        const state = yield select();

        yield put({ type: "DEALER_DRAFT_PAYMENT_REQUESTED" });


        yield call(Api.dealerDraftRequestPayment, action.requestPayment.id, action.requestPayment,helperFunctions.getToken(state));

        yield put({ type: "DEALER_DRAFT_PAYMENT_RESOLVED" });

        yield call(context.history.push, `/dealerDrafts/view/${action.requestPayment.id}`);
    }
    catch (e) {

        yield put({
            type: 'DEALER_DRAFT_PAYMENT_REJECTED',
            errorCode: e.code ? e.code : '',
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });
        window.scrollTo(0, 0);
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });
    }

}

export function* dealerDraftRequestReset(action) {

    try {
        const state = yield select();

        yield put({ type: "DEALER_DRAFT_RESET_REQUESTED" });


        yield call(Api.dealerDraftRequestReset, action.requestNo,helperFunctions.getToken(state));

        yield put({ type: "DEALER_DRAFT_RESET_RESOLVED" });
        yield put({type: "FETCH_REQUEST_NO_DETAILS",requestNo: action.requestNo,requestType: "DEALERDRAFT"})

    }
    catch (e) {

        yield put({
            type: 'DEALER_DRAFT_PAYMENT_REJECTED',
            errorCode: e.code ? e.code : '',
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });
        window.scrollTo(0, 0);
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });
    }

}