import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { LINE_ITEM_STATUS, LINE_ITEM_TYPE, INVOICE_FIELD_TYPE } from 'constants/App';
import { emptyMapIfUndefined, emptyStringIfUndefined, falseIfUndefined, zeroIfUndefined } from 'utils/HelperFunctions';
import moment from 'moment';



const carWashServiceCost = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'carWashAccount','poDetails','carWashCost']));

const detailCarWashServiceCost = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'carWashAccount','poDetails','detailCarWashCost']));

const taxServiceCost = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'carWashAccount','poDetails','taxAmount']));

const poCreationError = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'carWashAccount','poCreationError']));

const countryFlag = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'shared','selectedCountry']));

const taxServiceCanadianCost = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'carWashAccount','poDetails','taxes']));

//TODO: Cleanup the code -- This setOn constants need to be removed once we implement for canada close PO
const setOnTotalAmount = createSelector( carWashServiceCost, detailCarWashServiceCost,taxServiceCost,taxServiceCanadianCost,countryFlag,
    (carWashCost, detailCarWashCost, pendingTax,canadianTax,country) => {
        let totalAmount = 0;


        if(country === "USA") {

            if (carWashCost > 0) {
                return totalAmount = Number(carWashCost)
                    + Number(pendingTax);
            } else if (detailCarWashCost > 0) {
                return totalAmount =Number(detailCarWashCost)
                    + Number(pendingTax);

            } else {
                return totalAmount;
            }

        }else{
            if (carWashCost > 0) {
                return totalAmount = Number(carWashCost)
                    + (Number(canadianTax.get('HST'))) +
                    (Number(canadianTax.get('GST'))) +
                    (Number(canadianTax.get('PST'))) +
                    (Number(canadianTax.get('QST')));
            } else if (detailCarWashCost > 0) {
                return totalAmount = Number(detailCarWashCost)
                    + (Number(canadianTax.get('HST'))) +
                    (Number(canadianTax.get('GST'))) +
                    (Number(canadianTax.get('PST'))) +
                    (Number(canadianTax.get('QST')));

            } else {
                return totalAmount;
            }

        }
    });

const entireBulletinMessage = state => emptyMapIfUndefined(state.appState.getIn(['serverData','shopView','bulletinMessage']));

const getBulletinSelector = createSelector(entireBulletinMessage,(bulletinList) => {

    return bulletinList.sortBy((param)=> -param.get("id"));

});


const getPrimaryBulletinSelector = createSelector(entireBulletinMessage,(bulletinList) => {

    return bulletinList.filter((params) => {
            return params.get('bulletinType') === 'primary';
        }

    );

});

const getSecondaryBulletinSelector = createSelector(entireBulletinMessage,(bulletinList) => {

    return bulletinList.filter((params) => {
            return params.get('bulletinType') === 'secondary';
        }

    ).sortBy((param)=> -param.get("id"));

});


const CarWashView = createSelector(
    setOnTotalAmount,
    getPrimaryBulletinSelector,
    getSecondaryBulletinSelector,
    (carWashTotal,
    primaryBulletin,secondaryBulletin
    ) => {
        return {
            carWashTotal,
            primaryBulletin,secondaryBulletin
        }
    });

export default CarWashView;