import React from 'react';
import './ResetPasswordResultView.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as constants from 'constants/App';
import { connect } from 'react-redux';
import ResetPasswordSuccessView from '../components/ResetPasswordSuccessView';
import TranslationIcon from 'components/SiteTranslations';

class ResetPasswordResultView extends React.Component {
    constructor(props) {
        super(props);
    }

    onReturnClickHandler(e) {
        e.preventDefault();
        this.props.dispatch({ type: constants.NAVIGATE_TO, path: `/recoverPassword` });
    }

    render() {
        const messages = defineMessages({
            forgot_password_button: {
                id: 'forgot_password_button',
                description: 'Forgot Password button',
                defaultMessage: 'Forgot Password'
            },
            reset_password_result_title: {
                id: 'reset_password_result_title',
                description: 'reset password error title',
                defaultMessage: 'Reset your password'
            },
            reset_password_result_subtext: {
                id: 'reset_password_result_subtext',
                description: 'reset password error subtext',
                defaultMessage: 'The reset password link you have selected was either used or has expired. For your security, please complete the reset password process again.'
            },
            reset_password_result_subtext_2: {
                id: 'reset_password_result_subtext_2',
                description: 'reset password error subtext 2',
                defaultMessage: 'If you have any questions, please contact the U.S Vendor Relations team at 1-888-683-8327 or the Canadian Vendor Relations team at 1-866-416-6542.'
            },
            reset_password_result_subtext3: {
                id: 'reset_password_result_subtext3',
                description: 'reset_password_result_subtext3',
                defaultMessage: 'This link has expired. Please contact the person who invited you and request that he or she send a new link.'
            }, 
            send_invite_result_title: {
                id: 'send_invite_result_title',
                description: 'Invitation',
                defaultMessage: 'Invitation'
            },
        });

        if (this.props.routeParams.match.params.status == 'success') {
            return <ResetPasswordSuccessView />;
        }

        return <div className="reset-confirmation-component">
            <div className="reset_confirmation_help_container row">
                <span>
                    <TranslationIcon backgroundColor='blue' />
                </span>
            </div>{this.props.routeParams.match.params.status == 'expiredSendInvite' ? 
            <div className="reset-confirmation__header row">
                <span>
                    <FormattedMessage {...messages.send_invite_result_title} />
                </span>
            </div>:
            <div className="reset-confirmation__header row">
                <span>
                    <FormattedMessage {...messages.reset_password_result_title} />
                </span>
            </div>
            }
            <div className="reset-confirmation__body row">
                {/* <div className="col-xs-3 reset-confirmation__message">
                    <img src="/assets/warning_mark.png" />
                </div> */}
               <div className="reset-confirmation__message">
               {this.props.routeParams.match.params.status == 'expiredSendInvite'? 
               <div className="row">
                        <span>
                            <FormattedMessage {...messages.reset_password_result_subtext3} />
                        </span>
                    </div> :<div>
                    <div className="row">
                        <span>
                            <FormattedMessage {...messages.reset_password_result_subtext} />
                        </span>
                    </div>
                    <div className="row">
                        <span>
                            <FormattedMessage {...messages.reset_password_result_subtext_2} />
                        </span>
                    </div>
                    </div>
                    }
                </div>
            </div>

            <div className="reset-confirmation__footer">
                <div className="pull-right reset-confirmation_button"
                    role="button"
                    onClick={this.onReturnClickHandler.bind(this)}>
                    <FormattedMessage {...messages.forgot_password_button} />
                    {' '}
                    <img src="/assets/VMS_33button_go_bblue.png" />
                </div>
            </div>
        </div>;
    }
}

function select(state) {
    return {};
}

export default connect(select)(ResetPasswordResultView);