import React from "react";
import { connect } from "react-redux";
import { Map } from "immutable";
import LineItems from "./LineItems";
import "./Complaint.css";
import ComplaintNotesReadOnly from "./../../../../shared/AddEditView/ComplaintNotesReadOnly";
import { defineMessages, FormattedMessage } from "react-intl";

class Complaint extends React.Component {
  render() {
    return (
      <div>
        {this.props.lineItems.count() > 0
          ? <div className="adjust_complaint">
              <div className="row adjust_complaint__caption">
                <div className="col-md-12">
                  <FormattedMessage
                    id={"complaint_code_" + this.props.complaint.get("code")}
                    description={this.props.complaint.get("description")}
                    defaultMessage={this.props.complaint.get("description")}
                  />
                </div>
              </div>
              <div className="row adjust_complaint__description">
                <div className="col-md-12">
                  {<ComplaintNotesReadOnly notes={this.props.notesText} />}

                </div>
              </div>
              <div className="row">
                <div className="col-md-12 adjust_complaint_view__line_item_border_align">
                  <LineItems
                    lineItems={this.props.lineItems}
                    complaintCode={this.props.complaintCode}
                    hideCostInformation={this.props.hideCostInformation}
                    onOpenTiresInfo={this.props.onOpenTiresInfo}
                    selectedCurrency={this.props.selectedCurrency}
                  />
                </div>
              </div>
            </div>
          : undefined}
      </div>
    );
  }
}

export default Complaint;
