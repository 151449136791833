import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage,injectIntl} from 'react-intl';
import {Map} from 'immutable';
import './LinkedVendorComp.css';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Submit from 'components/Submit';

class LinkedVendorComp extends React.Component {

    getTooltip(toolTipText) {
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }

    getLocation(id, city, state, zip) {

        return <OverlayTrigger
            placement='right'
            key={id}
            overlay={this.getTooltip((city == null ? '' : city)
                + ', ' + (state == null ? '' : state) + ' ' + (zip == null ? '' : zip))}
        >
            <div key={id}>
                {(city + ', ' + state + ' ' + zip).length > 10
                    ? ((city == null ? '' : city)
                    + ', ' + (state == null ? '' : state) + ' ' + (zip == null ? '' : zip)).substr(0, 9) + '...'
                    : ((city == null ? '' : city)
                    + ', ' + (state == null ? '' : state) + ' ' + (zip == null ? '' : zip))
                }
            </div>
        </OverlayTrigger>

    }

    getCompany(id, companyName) {

        return <OverlayTrigger
            placement='right'
            key={id}
            overlay={this.getTooltip((companyName == null ? '' : companyName))}
        >
            <div key={id}>
                {(companyName).length > 10
                    ? ((companyName == null ? '' : companyName)).substr(0, 9) + '...'
                    : ((companyName == null ? '' : companyName))
                }
            </div>
        </OverlayTrigger>

    }

    lookupLinkedVendors() {

        this.props.onLocationSelect(this.refs.vendorState.value, this.refs.searchLocation.value)
    }

    render() {
        const {formatMessage} = this.props.intl;
        const messages = defineMessages({
            company_name_title: {
                id: 'company_name_title',
                description: 'company name',
                defaultMessage: 'Company name'
            },
            location_title: {
                id: 'location_title',
                description: 'location',
                defaultMessage: 'Location'
            },
            phone_title: {
                id: 'phone_title',
                description: 'phone',
                defaultMessage: 'Phone'
            },
            ari_id_title: {
                id: 'ari_id_title',
                description: 'ari accounts',
                defaultMessage: 'Holman Issued ID'
            },
            search_by_title: {
                id: 'search_by_title',
                description: 'filter by',
                defaultMessage: 'Search by:'
            },
            sign_in_caption1: {
                id: 'sign_in_caption1',
                description: 'sign in',
                defaultMessage: 'Registered '
            },
            sign_in_caption2: {
                id: 'sign_in_caption2',
                description: 'sign in',
                defaultMessage: 'Sign In'
            },
            register_caption: {
                id: 'register_caption',
                description: 'register',
                defaultMessage: 'Register '
            },
            linked_account_title: {
                id: 'linked_account_title',
                description: 'linked accounts',
                defaultMessage: 'To Access Your Linked Account'
            },
            select_caption: {
                id: 'select_caption',
                description: 'select caption',
                defaultMessage: 'Select Location'
            },
            corporate_account_link: {
                id: 'corporate_account_link',
                description: 'corp accounts',
                defaultMessage: 'Corporate Account'
            },
            or_caption: {
                id: 'or_caption',
                description: 'or',
                defaultMessage: 'Or'
            },
            show_more: {
                id: 'show_more',
                description: 'Show more',
                defaultMessage: 'Show more'
            },
            search_zip:{
                id: 'search_zip',
                description: 'search_zip',
                defaultMessage: 'Search Zip...'
            },
            all_dropdown:{
                id: 'all_dropdown',
                description: 'all_dropdown',
                defaultMessage: 'All'
            }

        });

        return <div>
            {/*First case is for generic corporate vendor and second case is for master vendor*/}
            {this.props.vendorAccountType === "Sub" && this.props.corporateVendorId != "" && this.props.currentVendorId == null
            || this.props.vendorAccountType === "Master" && this.props.currentVendorId != "" && this.props.corporateVendorId === null ?
                <div>
                    <div className="linked-accounts__title">
                        <FormattedMessage {...messages.linked_account_title}/>
                    </div>
                    <div className="linked-accounts__filter">
                        <FormattedMessage {...messages.search_by_title}/>
                    </div>
                    <div className="linked-accounts__row">
                        <select className="linked-accounts__select" ref='vendorState'
                                onChange={this.lookupLinkedVendors.bind(this)}>
                            <option key="select_state" value="" default> {formatMessage(messages.select_caption)}</option>
                            <option key="all" value="">{formatMessage(messages.all_dropdown)}</option>
                            {this.props.location.map((item) => {
                                return <option key={item.get("abbreviation")}
                                               value={item.get("abbreviation")}>{item.get("name")}</option>
                            })}
                        </select>
                        <div className="linked_accounts_or">
                            <FormattedMessage {...messages.or_caption}/>
                        </div>
                        <div className="linked-accounts__search-pad">
                            <input className="linked-accounts__input-box" type="number" ref="searchLocation"
                                   maxLength="5"
                                   placeholder={formatMessage(messages.search_zip)}/>
                            <div className="linked-accounts__image">
                                <input type="image" className="po-search__image"
                                       src="/assets/VMS_28button_find_blue.png"
                                       role="button" onClick={this.lookupLinkedVendors.bind(this)}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="linked-accounts__table_pad">
                        <table className="linked-accounts__table">
                            <thead>
                            <tr >
                                <th className="linked-accounts__header linked-accounts__table_text col-md-2.5">
                                    <FormattedMessage {...messages.company_name_title}/>
                                </th>
                                <th className="linked-accounts__header linked-accounts__table_text">
                                    <FormattedMessage {...messages.location_title}/>
                                </th>
                                <th className="linked-accounts__header linked-accounts__table_text">
                                    <FormattedMessage {...messages.phone_title}/>
                                </th>
                                <th className="linked-accounts__header linked-accounts__table_text">
                                    <FormattedMessage {...messages.ari_id_title}/>
                                </th>
                                <th className="linked-accounts__header linked-accounts__table_text">
                                    &nbsp;
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            {this.props.linkedVendors.map((subVendor) =>
                                <tr key={subVendor.get("id")}>
                                    <td className="linked-accounts__item">
                                        {this.getCompany(subVendor.get("id"), subVendor.get("businessName"))}
                                    </td>
                                    <td className="linked-accounts__item">
                                        {this.getLocation(subVendor.get("id"), subVendor.getIn(["businessAddress", "city"]), subVendor.getIn(["businessAddress", "state"]), subVendor.getIn(["businessAddress", "zipCode"]))}
                                    </td>
                                    <td className="linked-accounts__item">
                                        {subVendor.get("businessPhone")}
                                    </td>
                                    <td className="linked-accounts__item">
                                        {subVendor.get("id")}
                                    </td>
                                    {this.props.vendorAccountType == "Master" ?
                                        <td className="linked-accounts__item">
                                            <a className="linked-accounts__nav-link"
                                               onClick={this.props.onSignInClick.bind(this, subVendor.get("id"))}><FormattedMessage {...messages.sign_in_caption2}/></a>
                                        </td>
                                        :
                                        subVendor.get("isRegistered") == "N" ?
                                            <td className="linked-accounts__item">
                                                <a className="linked-accounts__nav-link"
                                                   onClick={this.props.onRegisterClick.bind(this, subVendor.get("id"))}><FormattedMessage {...messages.register_caption}/></a>
                                            </td>

                                            :
                                            <td className="linked-accounts__item">
                                                <FormattedMessage {...messages.sign_in_caption1}/>
                                                <a className="linked-accounts__nav-link"
                                                   onClick={this.props.onSignOutClick.bind(this)}><FormattedMessage {...messages.sign_in_caption2}/></a>
                                            </td>
                                    }
                                </tr>
                            ).valueSeq()}
                            </tbody>
                        </table>
                    </div>
                    <div className="linked_accounts__show-more row form-group">
                        <div className="col-md-8"/>
                        <div className="col-md-4">
                            {
                                this.props.nextLinkedVendorsExist == false ?
                                    false
                                    :
                                    <div className="linked_accounts__show-more-caption">
                                        <Submit caption={<FormattedMessage {...messages.show_more}/>}
                                                imagePath="/assets/VMS_33button_go_bblue_down.png"
                                                onClickHandler={this.props.fetchMoreLinkedVendors.bind(this)}/>
                                    </div>
                            }
                        </div>
                    </div>
                    {/*third case is for sub vendor and fourth case is for master vendor logged in as sub vendor*/}
                </div>
                : this.props.vendorAccountType == "Sub" && this.props.corporateVendorId != "" && this.props.currentVendorId != ""
                || this.props.vendorAccountType === "Master" && this.props.currentVendorId != "" && this.props.corporateVendorId != "" ?
                    <div>
                        <div className="linked-accounts__title2">
                            <FormattedMessage {...messages.corporate_account_link}/>
                        </div>
                        <div className="linked-accounts__table_pad2">
                            <table className="linked-accounts__table">
                                <thead>
                                <tr >
                                    <th className="linked-accounts__header linked-accounts__table_text col-md-2.5">
                                        <FormattedMessage {...messages.company_name_title}/>
                                    </th>
                                    <th className="linked-accounts__header linked-accounts__table_text">
                                        <FormattedMessage {...messages.location_title}/>
                                    </th>
                                    <th className="linked-accounts__header linked-accounts__table_text">
                                        <FormattedMessage {...messages.phone_title}/>
                                    </th>
                                    <th className="linked-accounts__header linked-accounts__table_text">
                                        <FormattedMessage {...messages.ari_id_title}/>
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr key={this.props.corporateVendorId}>
                                    <td className="linked-accounts__item">
                                        {this.getCompany(this.props.corporateVendorId, this.props.businessName)}
                                    </td>
                                    <td className="linked-accounts__item">
                                        {this.getLocation(this.props.corporateVendorId, this.props.businessCity, this.props.businessState, this.props.businessZip)}
                                    </td>
                                    <td className="linked-accounts__item">
                                        {this.props.businessPhone.substring(0, 3) + "-" + this.props.businessPhone.substring(3, 6)+ "-" + this.props.businessPhone.substring(6, 10)}
                                    </td>
                                    <td className="linked-accounts__item">
                                        {this.props.corporateVendorId}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        {/*fifth case is for independent vendor*/}
                    </div>
                    : undefined

            }
        </div>;
    }
}


export default injectIntl(LinkedVendorComp);

