import {createSelector} from 'reselect';
import {emptyMapIfUndefined,falseIfUndefined, emptyStringIfUndefined, validatePasswordPolicy } from 'utils/HelperFunctions';
import {Map, fromJS} from 'immutable';
import moment from 'moment';
import * as constants from 'constants/App';

function validateEmail(emailAddress) {
    emailAddress = emptyStringIfUndefined(emailAddress);
    var atpos = emailAddress.indexOf("@");
    var dotpos = emailAddress.lastIndexOf(".");
    return !(atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= emailAddress.length) ;
}


const isRequestFormValidSelector = (state) => {
    let enrollmentInfo = state.appState.getIn(['uiData','vendorLinkedRegister','linkedRequestInfo']);
    let fieldsToValidate = [
        'contactName',
        'emailAddress',
        'newPassword',
        'confirmPassword',
    ];

    //required fields should be populated
    let inValidFields = fieldsToValidate.reduce((p, c) => {
        if (emptyStringIfUndefined(enrollmentInfo.get(c)) == '')
            return p.concat(c);
        else
            return p;
    }, []);

    //validate email
    if(!validateEmail(enrollmentInfo.get('emailAddress')))
        inValidFields = inValidFields.concat('emailAddress');

    if(!validatePasswordPolicy(enrollmentInfo.get('newPassword')))
    {
        inValidFields = inValidFields.concat('newPassword');
    }

    //email and re-enter email should match
    if (enrollmentInfo.get('newPassword') != enrollmentInfo.get('confirmPassword')) {
        inValidFields = inValidFields.concat('confirmPassword');
    }

    return {
        isFormValid: inValidFields.length == 0,
        inValidFields: inValidFields
    }
}





const LinkedVendorRegister = createSelector(
    isRequestFormValidSelector,

    (isRequestFormValid) => {

        return {

            isRequestFormValid
        };
    }
);

export default LinkedVendorRegister;
