/**
 * Created by prboyapa on 2/26/2016.
 */
import React,{useRef} from 'react';
import {defineMessages, FormattedMessage, RawIntlProvider, IntlProvider, injectIntl} from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import ConfirmMessageBox from './ConfirmMessageBox';
import './RequestApproval.css';
import Spinner from 'components/Spinner';
import { PO_AUTHORIZATION_STATUS } from 'constants/App';
import Help from 'components/Help';
import classnames from 'classnames';
import { useAppState } from 'shared/AppState/app-state';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const RequestApproval = (props) => {
    const [ appState, dispatch ] = useAppState();

    const cancelPOShowConfirmWindow = useRef();  

   const getButtonCaption = () => {

        const messages = defineMessages({
            user_action_add_caption: {
                id: 'user_action_add_caption',
                description: 'Request Approval',
                defaultMessage: 'Request Approval'
            },
            user_action_edit_caption: {
                id: 'user_action_edit_caption',
                description: 'Close PO',
                defaultMessage: 'Close PO'
            }
        });

        switch (props.actionType.toUpperCase()) {
            case "ADD":
                return <FormattedMessage {...messages.user_action_add_caption}/>;
            case "EDIT":
                return props.isDirtyPO || props.isRequestingApproval || props.authorizedStatus==PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED
                    || props.authorizedStatus==PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT
                    ? <FormattedMessage {...messages.user_action_add_caption}/>
                    : <FormattedMessage {...messages.user_action_edit_caption}/>;
            case "VIEW":
                return props.allowedToClosePO ? 
                    <FormattedMessage {...messages.user_action_edit_caption}/>
                    :false;                    
        }
    }

    /*Update the purchase order status in
     specific purchase order and then redirect user to the dashboard */
     const  onSavePurchaseOrderDetailsHandler = (e) => {
        e.preventDefault();

        const messages = defineMessages({
            line_items_error_message: {
                id: 'line_items_error_message',
                description: 'Please add at least one line Item',
                defaultMessage: 'Please add at least one line Item'
            }
        });

        if (props.lineItemsCount == 0) {
            window.scrollTo(0, 0);
            //TODO - remove the hardcoded string and get a formatted string as per locale.
            props.displayErrorMessage(<FormattedMessage {...messages.line_items_error_message}/>);
            return false;
        }

        props.onSavePurchaseOrderDetails();

        return (props.estimatedCompletionDate!=undefined && moment(props.estimatedCompletionDate).isValid()); 
    }

    const  onUpdatePurchaseOrderDetailsHandler = (e) => {        
        e.preventDefault();

        const messages = defineMessages({
            line_items_error_message: {
                id: 'line_items_error_message',
                description: 'Please add at least one line Item',
                defaultMessage: 'Please add at least one line Item'
            }
        });

        if (props.lineItemsCount == 0) {
            window.scrollTo(0, 0);
            props.displayErrorMessage(<FormattedMessage {...messages.line_items_error_message}/>);
            return false;
        }

        props.onUpdatePurchaseOrderDetails();

        return (props.estimatedCompletionDate!=undefined && moment(props.estimatedCompletionDate).isValid()); 
    }

    /*Update the purchase order status in
     specific purchase order and then redirect user to the dashboard */
     const onCancelPOHandler = (e) => {
        e.preventDefault();

        cancelPOShowConfirmWindow.current.showModal();
    }

  
        const messages = defineMessages({
            tax_edit_text: {
                id: 'tax_edit_text',
                description: 'Tax can be added/edited while closing the PO',
                defaultMessage: 'Tax can be added/edited while closing the PO'
            },
            cancel_po_confirmation_no: {
                id: 'cancel_po_confirmation_no',
                description: 'No',
                defaultMessage: 'No'
            },
            cancel_po_confirmation_yes: {
                id: 'cancel_po_confirmation_yes',
                description: 'Yes',
                defaultMessage: 'Yes'
            },
            user_action_cancel_po: {
                id: 'user_action_cancel_po',
                description: 'Cancel PO',
                defaultMessage: 'Cancel PO'
            },
            cancel_po_confirmation_title: {
                id: 'cancel_po_confirmation_title',
                description: 'Cancel PO',
                defaultMessage: 'Cancel PO'
            },
            cancel_po_confirmation_message: {
                id: 'cancel_po_confirmation_message',
                description: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?',
                defaultMessage: 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?'
            }, 
            missing_product_code_disabled_reason: {
                id: 'missing_product_code_disabled_reason',
                description: 'There are lines missing product codes. Please add the correct product codes and pricing, if applicable.',
                defaultMessage: 'There are lines missing product codes. Please add the correct product codes and pricing, if applicable.'
            },          
        });
        //flag to disable request approval/close po button if line item is missing vendor product code
        const missingProductCodeDisable = appState.isMissingProductCodeWorkFlowEnabled && (props.lineItems.valueSeq().filter(li => li.get('isBeingDeleted') != false && (li.get('vendorProductCode') == null || li.get('vendorProductCode') == undefined)).count() > 0);

        return <div>
            <div className={classNames('container', 'request_approval__link_button',
                {'request_approval__save_add': props.actionType.toUpperCase() == 'ADD'},
            )}>                

                <div className="row">
                    
                        {
                            props.actionType.toUpperCase() != 'ADD' && props.canShow == true 
                             && props.allowedToCancelPO
                             ?
                                <div className="col-md-6">
                                    <ConfirmMessageBox ref={cancelPOShowConfirmWindow}
                                                       title={messages.cancel_po_confirmation_title}
                                                       message={messages.cancel_po_confirmation_message}
                                                       okButtonText={messages.cancel_po_confirmation_yes}
                                                       cancelButtonText={messages.cancel_po_confirmation_no}
                                                       onOKClickEvent={props.onCancelPOEvent.bind(this,props.actionType)}
                                                       onCancelClickEvent={null}/>

                                    <div className='request_approval__cancel_po'>
                                        <a onClick={onCancelPOHandler.bind(this)}>
                                            <img src="/assets/VMS_28button_delete_blue.png"/>
                                            {' '}{<FormattedMessage {...messages.user_action_cancel_po}/>}
                                        </a>
                                         <Help videoId="cancel_po" whiteSpaceAtBottomHeight="0.7em"/> 
                                    </div>
                                </div>
                                : 
                                <div className="col-md-6"> </div>
                        }                        
                     <OverlayTrigger placement='right'
                                     trigger={missingProductCodeDisable && !props.requestApprovalDisable ? ['hover','focus'] : null }
                                     overlay={<Tooltip><RawIntlProvider value={props.intl}><FormattedMessage {...messages.missing_product_code_disabled_reason} /></RawIntlProvider></Tooltip>}>
                                  
                    <div className="col-md-6">
                        {
                            props.actionType.toUpperCase() != 'VIEW'
                            ||  props.allowedToClosePO ?
                            
                                <div className={classnames(
                                    {'request_approval__save': !props.requestApprovalDisable},
                                    {'request_approval__save-disable_wrapper': props.requestApprovalDisable ||  missingProductCodeDisable })}>
                                        <div className={classnames({'request_approval__save-disable': props.requestApprovalDisable ||  missingProductCodeDisable })}>
                                        <a onClick={ props.actionType.toUpperCase() == "EDIT" 
                                        ? onUpdatePurchaseOrderDetailsHandler.bind(this)
                                        : props.allowedToClosePO
                                            ? onUpdatePurchaseOrderDetailsHandler.bind(this)
                                            : onSavePurchaseOrderDetailsHandler.bind(this)}
                                        >
                                        {getButtonCaption()}
                                        {' '}<img src="/assets/VMS_33button_go_bblue.png"/>                                        
                                    </a>
                                    <div className="request_approval__save inline-block">
                                    {props.isRequestingApproval ?
                                        <Spinner spinnerType="dark-blue"/>
                                    : undefined
                                    }
                                    </div>    
                                    </div>
                                </div>
                                : undefined
                        }
                    </div>
                   </OverlayTrigger>
                </div>   
            </div>


            {
                /*Show the tax line information message */
                <div className="request_approval__tax_info">
                    <FormattedMessage {...messages.tax_edit_text} />
                </div>
            }

        </div>
}


export default injectIntl(RequestApproval)
