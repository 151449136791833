import * as constants from 'constants/App';
import { Map, fromJS } from 'immutable';
import moment from 'moment';

import craneInspectionComponentData from '../data/CraneInspectionComponentData';
import * as craneInspectionEnums from '../data/CraneInspectionEnums';

const componentDetails = craneInspectionComponentData;

const initialUiData = Map();
const defaultUiData = Map({
	showWindow: false,
	formData: Map({
		hasInspectionPassed: null,
		inspectorAttested: false,
		inspectorSignature: '',
		inspectionDate: null,
		customer: '',
		address: '',
		unitNumber: '',
		reportNumber: '',
		craneManufacturer: '',
		craneCapacity: '',
		craneSerialNumber: '',
		craneModelNumber: '',
		interval: null,
		intervalOther: '',
		hoistSystemBrakesType: null,
		boomJibBrakesType: null,
		isAllOk: false
	}),
	formValidationMessages: Map(),
	componentDetails: Map(),
	shouldSkipFailOptions: false,
});

const initialServerData = Map();

export function uiDataCraneInspection(state = initialUiData, action) {
	switch (action.type) {
		case constants.CRANE_INSPECTION__DISPLAY:
			let stateTemp = defaultUiData.set('showWindow', action.show);
			return stateTemp;
		case constants.CRANE_INSPECTION__RESET:
			return defaultUiData;
		case constants.CRANE_INSPECTION__RESET_ERROR:
			return state.set('errorMessage', undefined);
		case constants.CRANE_INSPECTION__SET_DEFAULT_VALUES:
			return state
				.setIn(['formData', 'customer'], action.customer)
				.setIn(['formData', 'unitNumber'], action.unitNumber)
				.setIn(['formData', 'reportNumber'], action.reportNumber)
				.setIn(['formData', 'address'], action.address)
				.setIn(['componentDetails'], fromJS(componentDetails));

		case constants.CRANE_INSPECTION__SET_FORM_DATA: {
			let newState = state.setIn(['formData', action.field], action.value);

			if (action.field === 'isAllOk' && action.value === true) {
				newState = newState.set('componentDetails', state.get('componentDetails').map((component) => (
					component.set('componentResultCode', craneInspectionEnums.componentResultCode.PASS)
				)));
			}

			return newState;
		}
		case constants.CRANE_INSPECTION__SET_VALIDATION_MESSAGE:
			return state.setIn(['formValidationMessages', action.field], action.message);
		case constants.CRANE_INSPECTION__RESET_VALIDATION_MESSAGES:
			return state.set('formValidationMessages', Map());

		case constants.CRANE_INSPECTION__SET_COMPONENT_DETAILS: {
			let newState = state.setIn(['componentDetails', action.componentId, action.field], action.value);

			if (action.value !== craneInspectionEnums.componentResultCode.PASS) {
				newState = newState.setIn(['formData', 'isAllOk'], false);
			}

			return newState;
		}
		case constants.CRANE_INSPECTION__SET_ALL_COMPONENT_OK:
			return state.set('componentDetails', state.get('componentDetails').map((component) => (
				component.set('componentResultCode', craneInspectionEnums.componentResultCode.PASS)				
			)));

		case constants.CRANE_INSPECTION__SKIP_FAIL_OPTIONS:
			return state.set('shouldSkipFailOptions', true);

		case constants.CRANE_INSPECTION__SAVE_CONFIRMATION_REQUESTED:
			return state
				.set('errorMessage', undefined)
				.set('isSaving', true);
		case constants.CRANE_INSPECTION__SAVE_CONFIRMATION_REJECTED:
			return state
				.set('errorMessage', action.errorMessage)
				.set('isSaving', false);
		case constants.CRANE_INSPECTION__SAVE_CONFIRMATION_RESOLVED:
			return state.set('isSaving', false);

		case constants.CRANE_INSPECTION__SAVE_FORM_REQUESTED:
			return state
				.set('errorMessage', undefined)
				.set('isSaving', true);
		case constants.CRANE_INSPECTION__SAVE_FORM_REJECTED:
			return state
				.set('errorMessage', action.errorMessage)
				.set('isSaving', false);
		case constants.CRANE_INSPECTION__SAVE_FORM_RESOLVED:
			return state.set('isSaving', false);

		default:
			return state;
	}
}

export function serverDataCraneInspection(state = initialServerData, action) {
	switch (action.type) {
		default:
			return state;
	}
}