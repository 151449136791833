// #region Imports
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";

import * as Api from "utils/Api";
import Spinner from "components/Spinner";
import {
  ModalClose,  
  ModalMainHeader,  
  ModalBodyTable,  
  ButtonRowStyled,
  ButtonStyled
} from "DesignSpecs/components/FormComponents";
import Permissions, { defaultPermissions } from "../common/Permissions";
import SelectedUsers from "./SelectedUsers";
import FormWrapper from "components/FormWrapper";
import * as helperFunctions from "utils/HelperFunctions";
// #endregion

// #region Styled Components
const TitleStyled = styled.div`
	text-align: left;
  font-family: "Gotham SSm A", "Gotham SSm B";
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  padding-top: 2rem;
`;

const SpinnerContainer = styled.div`	
  display: inline-block;

  & .spinner {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: inherit;
    vertical-align: top;
    margin-left: 5px;
    border-width: 5px;
  }
`;
// #endregion

// #region Component
const PermissionsStep = ({    
  selectedUsers,
  token,
  personaUserId,
  routeParams,
  intl: { formatMessage },  
  onRemoveUser,
  onReturn 
}) => {
  const storeId = routeParams.match.params.storeId;

  // #region State  
  const [pageErrors, setPageErrors] = useState();
  const [saving, setSaving] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [limitToThesePermissions, setLimitToThesePermissions] = useState([]);
  // #endregion

  // #region Ref
  // #endregion  

  // #region Event handlers  
  const handlePermissionChange = (newSelectedPermissions) => {
    setPageErrors();
    setSelectedPermissions(newSelectedPermissions);
  }
  
  const handleSave = () => {
    
    const postData = async () => {						
      try {
        setSaving(true);
        

        for (var user of selectedUsers) {          
          let response = await Api.createPermissions(user.id, selectedPermissions, storeId, token);
        }

        setSaving(false);
        routeParams.history.goBack();    
      } 
      catch (error) {
        setPageErrors(helperFunctions.getErrorObject(error));
        setSaving(false);
      }
    }

    if (!saving && selectedPermissions.length > 0)
      postData();    
  }

  const handlePermissionsError = (error) => {        
    /*if (error === 'error_admin_cannot_change_permission') {            
      setPageErrors([{message: formatMessage(messages['permissions_step__error_admin_cannot_change_permission'])}]);
    }

    else {
      // These errors are not handled.
    } */   
  }
  // #endregion

  // #region Effects
  useEffect(
    () => {
      let didCancel = false;

      const fetchData = async () => {        
        if (!personaUserId)
          return;
        
        try {                                 
          const currentUsersPermissions = await Api.fetchPermissions(
            personaUserId,
            storeId,
            token
          );

          // Get permissions to select by default. (and make sure current user has access to those permissions)
          const newSelectedPermissions = defaultPermissions.filter(p => (currentUsersPermissions.includes(p)));

          if (!didCancel) {              
            setSelectedPermissions(newSelectedPermissions);
            setLimitToThesePermissions(currentUsersPermissions);            
          }
        } catch (e) {
          if (!didCancel) {            
            setPageErrors(helperFunctions.getCustomErrorObject(e));         
          }
        }
      };
  
      fetchData();
  
      return () => {
        didCancel = true;
      };           
    }, [personaUserId]
  );
  // #endregion

  // #region International messages
  const messages = defineMessages({
    close_dialog: {
      id: "close_dialog",
      description: "  Close",
      defaultMessage: "Close"
    },
    add_users_to_store_grant_permissions_title: {
      id: "add_users_to_store_grant_permissions_title",
      description: "Grant permissions title",
      defaultMessage: "Grant permissions"
    },
    permissions_step__permissions_caption: {
      id: "permissions_step__permissions_caption",
      description: "Grant permissions to",
      defaultMessage: "Grant permissions to"
    },      
    
    permissions_return: {
      id: "permissions_return",
      description: "Return",
      defaultMessage: "Return"
    },
    permissions_save_changes: {
      id: "permissions_save_changes",
      description: "Save changes",
      defaultMessage: "Save changes"
    }
  });
  // #endregion

  // #region Render helpers  
  const renderCloseButton = () => (
    <ModalClose role='button' onClick={() => {
      routeParams.history.goBack();
    }}>
      <img src="/assets/VMS_33button_kill_blue.png"/>
      {"  "} {<FormattedMessage {...messages.close_dialog} />}
    </ModalClose>
  );

  const renderReturnButton = () => (
    <ButtonStyled
      role="button"
      onClick={onReturn}
    >
      <img src="/assets/VMS_33button_back_blue2.png" />
      {" "}
      <FormattedMessage
        {...messages.permissions_return}
      />
    </ButtonStyled>
  );

  const renderSaveButton = () => (
    <ButtonStyled
      role="button"
      disable={saving || selectedPermissions.length === 0}
      onClick={handleSave}
    >
      <FormattedMessage {...messages.permissions_save_changes} />
      {" "}
      <img src="/assets/VMS_33button_go_bblue.png" />
    </ButtonStyled>
  );
  // #endregion  

  // #region Render
  return (
    <div>
      
      <FormWrapper
        key="pageErrors"
        id="pageErrors"          
        formErrors={pageErrors}
      />

      <ButtonRowStyled>        
        {renderCloseButton()}
        <div></div>
      </ButtonRowStyled>

      <ModalMainHeader>
        <FormattedMessage {...messages.add_users_to_store_grant_permissions_title} />             
      </ModalMainHeader>

      <ModalBodyTable>

        <TitleStyled>
          <FormattedMessage {...messages.permissions_step__permissions_caption} />
        </TitleStyled>

        <SelectedUsers
          selectedUsers={selectedUsers}
          allowRemovingUser={false}
          onRemoveUser={onRemoveUser}
        />        

        <hr />

        <Permissions
          selectedPermissions={selectedPermissions}
          limitToThesePermissions={limitToThesePermissions}
          onPermissionChange={handlePermissionChange}
          onError={handlePermissionsError}          
        />

        <br />

      </ModalBodyTable>
      
      <ButtonRowStyled>
        {renderReturnButton()}
        <div>
          {renderSaveButton()}
          {saving && 
            <SpinnerContainer><Spinner spinnerType="blue" manualPositioning /></SpinnerContainer> 
          }
        </div>
      </ButtonRowStyled>

    </div>
  );
  // #endregion
};
// #endregion

export default injectIntl(PermissionsStep);
