import React from 'react';
import ReactDoM from 'react-dom'
import { Map } from 'immutable';
import AtaSearchContainer from 'AtaSearch/AtaSearchContainer';
import './SearchATACodes.css';
import { Overlay, Popover, Tooltip } from 'react-bootstrap';
import classnames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';


class SearchATACodes extends React.Component {
    constructor(props) {
        super(props);
        this.displayName = 'SearchATACodes';
    }

    /*Update state on select of searched ata code*/
    onSelectATACodeHandler(ataCode) {
        this.setState({ ataCode });
        this.props.onSearchATACodeSelect(ataCode);
    }

    /*Store searched keyword in the state*/
    onUpdateAutoCompleteATACodeDescHandler(value, supressAtaSearch) {
        this.props.onUpdateAutoCompleteATACodeDesc(value, supressAtaSearch);
    }

    render() {
        const messages = defineMessages({
            ata_search_tool_tip: {
            id: 'ata_search_tool_tip',
            description: 'Want to search parts, try this box!',
            defaultMessage: 'Want to search parts, try this box!'
            },
        })
        return (
            <div className='ata-search'>
                <AtaSearchContainer
                    ref={ataSearchComponent => this.ataSearchComponent = ataSearchComponent}
                    ataCodes={this.props.ataCodesForSearch}
                    onUpdateAutoCompleteATACodeDesc={this.onUpdateAutoCompleteATACodeDescHandler.bind(this)}
                    isfetchingData={this.props.isfetchingData}
                    onHideContainer={this.props.onHideContainer}
                    showAtaSearchResultContainer={this.props.showAtaSearchResultContainer}
                    onSelectAtaCode={this.onSelectATACodeHandler.bind(this)}
                    searchedText={this.props.autoCompleteATACodeDesc}
                    resetAtaSearch = {this.props.resetAtaSearch}
                />
            </div>
        );
    }
};


export default SearchATACodes;
