// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './TopLayout.css';

const TopLayout = ({children}) => {    
    return <div className='driver_validation_complaints__top_layout'>
        {children}
    </div>
}

export default TopLayout;