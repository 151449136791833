import {call, put, select} from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';


export function* receiveClientParametersVehicle(){
    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE});
        yield put({type: constants.FETCH_DATA_FOR_CLIENT_PARAMETERS});

        // Read data from app state
        const state = yield select();

        const vehicleId = state.appState.getIn(['serverData','shared','vehicleDetails','id']);

        const currentParameters = yield call(Api.fetchClientParameters,vehicleId, helperFunctions.getToken(state));

        yield put({ type: constants.SAVE_CLIENT_PARAMETERS, currentParameters });
        yield put({type: constants.SEND_CLICK_CLIENT_PARAMETERS_RESOLVED});
    }
    catch (e) {
        yield put({type: constants.SEND_CLICK_CLIENT_PARAMETERS_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
        yield put({type: constants.SET_ERROR_MESSAGE,errorMessage: helperFunctions.getGenericErrorObject(e)});
    }





}


