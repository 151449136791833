import React from 'react';
import  ReactDoM from 'react-dom';
import {defineMessages, FormattedMessage} from 'react-intl';
import Spinner from 'components/Spinner';
import './VendorTermsAndConditions.css';

class VendorTermsAndConditionsDisplay extends React.Component {


    render() {
        const messages = defineMessages({
            read_and_agree: {
                id: 'read_and_agree',
                description: 'I have read and agree to the terms and conditions indicated above.',
                defaultMessage: 'I have read and agree to the terms and conditions indicated above.'
            },

        });
        if (this.props.isFetchingTermsAndConditions ) {

            return <Spinner spinnerType="dark-blue"/>;
        }

        return (
            <div className='terms_conditions__container'>
                <span dangerouslySetInnerHTML={{ __html: this.props.termsAndConditions }}></span>
                <div className='terms_conditions__accepted row'>
                    <div className='col-md-1 terms_conditions__radio'
                         onClick={this.props.onAcceptedTerms.bind(this)} >
                        <img ref="termsReadAndAgreed"
                             src={this.props.toggleAcceptedFlag ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png"}

                        />

                    </div>
                    <div className='col-md-11 terms_conditions__accepted-by'>
                        <div className='row'><FormattedMessage {...messages.read_and_agree } /></div>
                        </div>
                    </div>
            </div>
        );
    }
};


export default VendorTermsAndConditionsDisplay;

