// #region Imports
import React from "react";
import {  injectIntl } from "react-intl";
import AgedPOs from "./AgedPOs";
import {useLocation} from "react-router-dom";
import { connect } from "react-redux";
// #endregion


// #region Component
const AttentionNeeded = (props) => {
  
    const location = useLocation();

    // #region Render
    return (
      location.search.includes("agedPOs") ?
       <AgedPOs token={props.token}
       vendorId={props.vendorId}/>
       : undefined
      
    );
    // #endregion
};
// #endregion

const mapStateToProps = (state, props) => {
  return {
    token: state.appState.getIn(["serverData", "shared", "token"]),
    vendorId: state.appState.getIn(["serverData", "shared", "vendorId"]),
 
  };
};
export default connect(mapStateToProps)(injectIntl(AttentionNeeded));