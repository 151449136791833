import React from 'react';
import styled from 'styled-components';

/* 
This library is for styled-components used to compose a form.

TODO: move to shared folder instead of design and separate modal components and others that aren't part of a form.

*/

export const Label = styled.div`
	font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color: black;
    font-size: 14px;
    white-space: nowrap;
`;

export const LabelGrey = styled.div`
	font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color:grey;
    font-size: 14px;
    white-space: nowrap;
`;

export const Input = styled.input`				
	border-radius: 1px;
    height: 25px;
    padding-left: 12px;
	width: 18rem;
	border: 1px solid #ccc;
    &:-ms-input-placeholder{
        color: grey;
    }

	&:focus {	
		border-color: #66afe9;
		outline: 0;
		-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
		box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
	}

	${props => props.error && props.touched && `				
		border-color: red;		
	`};
`;

export const FormMainHeadingText=styled.div`
	font-size:42px;
	font-family: "Gotham Light", "Gotham SSm B";
	color: ${props=>props.theme.color.black};
`;

export const FormMainHeadingTextBlue=styled.div`
font-size:42px;
font-family: "Gotham SSm A", "Gotham SSm B";
font-style: normal;font-weight: 300;
color: ${props=>props.theme.color.primaryBlue};
`;

export const FormSubheadingText=styled.div`
font-size:36px;
font-family: "Gotham Light";
color: ${props=>props.theme.color.primaryBlue};
`;

export const FormBodyText=styled.div`
font-size:16px;
font-family: "Gotham Medium";
color: ${props=>props.theme.color.black};
`;

export const FormActionButtonLinkText=styled.div`
font-size:16px;
font-family: "Gotham Medium";
color: ${props=>props.theme.color.primaryBlue};
`;

export const FormTableHeaderText=styled.div`
font-size:14px;
font-family: "Gotham SSm A", "Gotham SSm B";
font-weight: 600;
color: ${props=>props.theme.color.black};
`;

export const FormTableText=styled.div`
font-size:16px;
font-family: "Gotham SSm A", "Gotham SSm B";
font-weight: 400;
color: ${props=>props.theme.color.black};
`;

export const ModalClose=styled.div`
    padding-top: 10px;
    font-family: "Gotham SSm A", "Gotham SSm B";font-style: normal;font-weight: 500;
    color: #0f4e95;
    cursor: pointer;
`;
    
export const ModalMainHeader=styled.div`
font-size: 42px;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;font-weight: 300;
    color: #5587B7;
    padding-top: 15px;
    padding-left: 0px;
    line-height: normal;
    margin-bottom: -10px;
`;

export const ModalBody=styled.div`  
  padding: 10px 30px 30px 30px;
  font-family: 'Gotham SSm A', 'Gotham SSm B';
`;

export const ModalContainer=styled.div`
  padding-left: 15px;
`;

export const ModalBodyTable=styled.div`
    border: solid rgb(140, 170, 202);
    border-top-width: 1px;
    border-right-width: 2px;    
    border-bottom-width: 1px;
    border-left-width: 2px;    
    margin-top: 3em;
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
`;

export const FormText=styled.div`
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
	font-family: "Gotham SSm A", "Gotham SSm B";
	color: ${props => props.theme.color.black};
    font-style: normal;
    font-weight: 300;
    padding-left: 0px;
`;

export const FormTextGray=styled.div`
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
	font-family: "Gotham SSm A", "Gotham SSm B";
	color: ${props => props.theme.color.ariGray};
    font-style: normal;
    font-weight: 300;
    padding-left: 0px;
`;

export const InputImage=styled.input`
cursor: pointer;
vertical-align: bottom;
outline: none;
`;

export const SubmitImage=styled.input`
cursor: pointer;
width: ${props => props.width};
height: ${props => props.height};
vertical-align: bottom;
outline: none;
`;

export const Image = styled.img`
cursor: pointer;
`;

export const IconImage = styled.img`
cursor: pointer;
width: ${props => props.width};
height: ${props => props.height};
`;

export const InputFeedback = styled.div`				
	color: red;		
	font-family: "Gotham SSm A", "Gotham SSm B";
	font-size: 11px;
`;

export const InputButton=styled.div`
cursor: pointer;
width: 25px;
height: 25px;
`;

export const Row = styled.div`
	display: flex;	
	justify-content: space-between;

	font-weight: normal;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color:black;
	font-size: 14px;
	
	padding-bottom: 1rem;
`;

export const Col = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    overflow-wrap: break-word;
    padding-left:${props => props.padLeft};
    padding-top:${props => props.padTop};
    padding-bottom:${props => props.padBottom};
`;

export const ButtonRowStyled = styled.div`	
  display: flex;
  justify-content: space-between;
`;

export const ButtonStyled = styled.div`
  cursor: ${({disable}) => disable ? `not-allowed` : `pointer`};
  padding-top: 10px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 700;
  color: #5587b7;
`

export const ImageWithText =styled.div`
cursor: pointer;
`;

