import React from 'react';
import ReactDoM from 'react-dom';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import { Map } from 'immutable';
import { defineMessages } from 'react-intl';
import { emptyMapIfUndefined, falseIfUndefined, emptyStringIfUndefined, validateEmail, renderError, validatePasswordPolicy } from 'utils/HelperFunctions';
import AccountInfo from '../components/AccountInfo';
import BankingInfo from '../components/BankingInfo';
import ServiceProfile from '../components/ServiceProfile';
import AuthEmailNotification from '../components/AuthEmailNotification';
import RegistrationViewSelector from '../selectors/RegistrationView';
import './RegistrationView.css';
import TermsAndConditions from '../components/VendorTermsAndConditionsRegister';
import * as sharedActions from 'shared/actions/App';
import * as CookieHelper from 'utils/CookieHelper';
import * as appActions from '../../../../actions/App';
class RegistrationView extends React.Component {
    constructor(props) {
        super(props);        
    }

    componentDidMount() {
        this.props.dispatch({ type: constants.RESET_REGISTRATION });
        this.props.dispatch({ type: constants.CLEAR_ERROR_MESSAGE });
    }

    isFieldEmpty(value) {
        return (emptyStringIfUndefined(value) == '' || value.length == undefined || value.length == 0);
    }

    validateFormForAccountInfo() {
        const errorMessages = defineMessages({
            ari_required: {
                id: 'ari_required',
                description: 'Please enter the correct ID supplied by Holman',
                defaultMessage: 'Please enter the correct ID supplied by Holman'
            },
            tax_id_required: {
                id: 'tax_id_required',
                description: 'Please enter either SSN or Employer Id',
                defaultMessage: 'Please enter either SSN or Employer Id'
            },
            password_required: {
                id: 'password_required',
                description: 'Please enter Password',
                defaultMessage: 'Please enter Password'
            },
            confirm_password_required: {
                id: 'confirm_password_required',
                description: 'Please confirm password',
                defaultMessage: 'Please confirm password'
            },
            password_should_match: {
                id: 'password_should_match',
                description: 'Password does not match',
                defaultMessage: 'Password does not match'
            },
            business_number_required:{
                id:'business_number_required',
                description:'Please enter Business Number',
                defaultMessage:'Please enter Business Number'
            },
            federal_tax_id_required:{
                id:'federal_tax_id_required',
                description:'Pleae enter Federal Tax Id',
                defaultMessage:'Pleae enter Federal Tax Id'
            },
            password_field_required: {
                id: 'password_field_required',
                description: 'Please enter valid password',
                defaultMessage: 'Password must meet complexity criteria below'
            }
        })

        let isFormValid = true;
        const country=this.props.formData.get(constants.FORM_DATA.COUNTRY);

        //check ari id field
        //  required 
        const ariId = this.props.formData.get(constants.FORM_DATA.ARI_ID);
        let validationErrs;
        if (this.isFieldEmpty(ariId)) {
            isFormValid = false;
            validationErrs = {
                name: constants.FORM_DATA.ARI_ID,
                value: errorMessages.ari_required
            }
            this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
        }
        //check tax id field
        if(country=='CAN'){            
            //business_number_required
            const businessNumber=this.props.formData.get(constants.FORM_DATA.BUSINESS_NUMBER);
            var bnTestExpression = /^\d{9}$/;
            if(this.isFieldEmpty(businessNumber)||!bnTestExpression.test(businessNumber)){
                isFormValid=false;
                validationErrs={
                    name:constants.FORM_DATA.BUSINESS_NUMBER,
                    value:errorMessages.business_number_required                }

                this.props.dispatch({type:constants.UPDATE_FORM_DATA_VALIDATION_ERRS,validationErrs});
            }
            
        }
        else if (country=='MEX'){
            //Federal Tax Id required
            const federalTaxId=this.props.formData.get(constants.FORM_DATA.FEDERAL_TAX_ID);
            var taxTestExpression = /^[a-zA-Z0-9_]*$/;
            if(this.isFieldEmpty(federalTaxId)||!taxTestExpression.test(federalTaxId)){
                isFormValid=false;
                validationErrs={
                    name:constants.FORM_DATA.FEDERAL_TAX_ID,
                    value:errorMessages.federal_tax_id_required                }

                this.props.dispatch({type:constants.UPDATE_FORM_DATA_VALIDATION_ERRS,validationErrs});
            }
        }
        else{
            //  only one required 
            //  both cannot be filled 
            let ssn = this.props.formData.get(constants.FORM_DATA.SSN);
            let employerId = this.props.formData.get(constants.FORM_DATA.EMPLOYER_ID);
            let fieldsEmpty = this.isFieldEmpty(ssn) && this.isFieldEmpty(employerId);
            if ((this.isFieldEmpty(ssn) && this.isFieldEmpty(employerId))
                || (!this.isFieldEmpty(ssn) && !this.isFieldEmpty(employerId))) {

                isFormValid = false;
                validationErrs = {
                    name: constants.FORM_DATA.TAX_ID,
                    value: errorMessages.tax_id_required
                }
                this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
            }
        }

        //password is required        
        const password = this.props.formData.get(constants.FORM_DATA.PASSWORD);

        if (!validatePasswordPolicy(password)) {
            isFormValid = false;
            validationErrs = {
                name: constants.FORM_DATA.PASSWORD,
                value: errorMessages.password_field_required
            }
            this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
        }
        //confirm password is required
        const confirmPassword = this.props.formData.get(constants.FORM_DATA.CONFIRM_PASSWORD);

        if (this.isFieldEmpty(confirmPassword)) {
            isFormValid = false;
            validationErrs = {
                name: constants.FORM_DATA.CONFIRM_PASSWORD,
                value: errorMessages.confirm_password_required
            }
            this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
        }
        //passwords should match 
        if (password != confirmPassword) {
            isFormValid = false;
            validationErrs = {
                name: constants.FORM_DATA.CONFIRM_PASSWORD,
                value: errorMessages.password_should_match
            }
            this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
        }
        return isFormValid;
    }


    validateFormForBankInfo() {
        const errorMessages = defineMessages({
            routing_number_required: {
                id: 'routing_number_required',
                description: 'Please enter Routing number',
                defaultMessage: 'Please enter Routing number'
            },
            institute_id_required:{
                id:'institute_id_required',
                description:'Please enter 3 digit Institue Id',
                defaultMessage:'Please enter 3 digit Institute Id'
            },
            transit_id_required:{
                id:'transit_id_required',
                description:'Please enter 5 digit Transit Id',
                defaultMessage:'Please enter 5 digit Transit Id'
            },
            check_number_required: {
                id: 'check_number_required',
                description: 'Please enter the Checking account number',
                defaultMessage: 'Please enter the Checking account number'
            },
            check_number_required_canada: {
                id: 'check_number_required_canada',
                description: 'Please enter Financial Institution account number',
                defaultMessage: 'Please enter Financial Institution account number'
            },
            re_enter_check_number_rquired: {
                id: 're_enter_check_number_rquired',
                description: 'Please re enter the Checking account number',
                defaultMessage: 'Please re enter the Checking account number'
            },
            re_enter_check_number_rquired_canada: {
                id: 're_enter_check_number_rquired_canada',
                description: 'Please re enter Financial Institution account number',
                defaultMessage: 'Please re enter Financial Institution account number'
            },
            check_numbers_should_match: {
                id: 'check_numbers_should_match',
                description: 'Both account numbers must be identical. Please re-enter checking account number',
                defaultMessage: 'Both account numbers must be identical. Please re-enter checking account number'
            },
            payment_email_required: {
                id: 'payment_email_required',
                description: 'Please enter the Payment email address',
                defaultMessage: 'Please enter the Payment email address'
            },
            invalid_email_format: {
                id: 'invalid_email_format',
                description: 'Payment email format is invalid',
                defaultMessage: 'Payment email format is invalid'
            },
            invalid_routing_number: {
                id: 'invalid_routing_number',
                description: 'The first digit should start with 0,1,2 or 3',
                defaultMessage: 'The first digit should start with 0,1,2 or 3'
            },
            routing_number_should_be_9_digits: {
                id: 'invalid_routing_number',
                description: 'Routing number has to be 9 digits',
                defaultMessage: 'Routing number has to be 9 digits'
            },
            
        })
        
        let isFormValid = true;
        let validationErrs;
        const country=this.props.formData.get(constants.FORM_DATA.COUNTRY);
        if(country=='CAN'){
            const instituteId=this.props.formData.get(constants.FORM_DATA.INSTITUTE_ID);
            if(this.isFieldEmpty(instituteId) || instituteId.length<3){
                isFormValid=false;
                validationErrs = {
                    name: constants.FORM_DATA.INSTITUTE_ID,
                    value: errorMessages.institute_id_required
                }
                this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
            }

            const transitId=this.props.formData.get(constants.FORM_DATA.TRANSIT_ID);
            if(this.isFieldEmpty(transitId) || transitId.length<5){
                isFormValid=false;
                validationErrs = {
                    name: constants.FORM_DATA.TRANSIT_ID,
                    value: errorMessages.transit_id_required
                }
                this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
        
            }
        }
        else if(country=='USA'){
            //required fields
            const routingNumber = this.props.formData.get(constants.FORM_DATA.ROUTING_NUMBER);
        
            if (this.isFieldEmpty(routingNumber)) {
                isFormValid = false;
                validationErrs = {
                    name: constants.FORM_DATA.ROUTING_NUMBER,
                    value: errorMessages.routing_number_required
                }
                this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
            }
            else {
                const firstChar = routingNumber.toString().substr(0, 1);
                const routingCharsAllowed = ['0', '1', '2', '3'];

                if (!routingCharsAllowed.includes(firstChar)) {
                    isFormValid = false;
                    validationErrs = {
                        name: constants.FORM_DATA.ROUTING_NUMBER,
                        value: errorMessages.invalid_routing_number
                    }
                    this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
                }

                else if (routingNumber.length != 9) {
                    isFormValid = false;
                    validationErrs = {
                        name: constants.FORM_DATA.ROUTING_NUMBER,
                        value: errorMessages.routing_number_should_be_9_digits
                    }
                    this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
                }
            }
        }
        if(country!='MEX'){
            const checkingNumber = this.props.formData.get(constants.FORM_DATA.CHECKING_ACC_NUMBER);
            if (this.isFieldEmpty(checkingNumber)) {
                isFormValid = false;
                validationErrs = {
                    name: constants.FORM_DATA.CHECKING_ACC_NUMBER,
                    value: country=='CAN'? errorMessages.check_number_required_canada:errorMessages.check_number_required
                }
                this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
            }

            const reEnterCheckingNumber = this.props.formData.get(constants.FORM_DATA.CONFIRM_CHECKING_ACC_NUMBER);
            if (this.isFieldEmpty(reEnterCheckingNumber)) {
                isFormValid = false;
                validationErrs = {
                    name: constants.FORM_DATA.CONFIRM_CHECKING_ACC_NUMBER,
                    value: country=='CAN'? errorMessages.re_enter_check_number_rquired_canada:errorMessages.re_enter_check_number_rquired
                }
                this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
            }

       

            //checking numbers should match 
            if (checkingNumber != reEnterCheckingNumber) {
                isFormValid = false;
                validationErrs = {
                    name: constants.FORM_DATA.CONFIRM_CHECKING_ACC_NUMBER,
                    value:errorMessages.check_numbers_should_match
                }
                this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
            }
        }

        const paymentEmail = this.props.formData.get(constants.FORM_DATA.PAYMENT_EMAIL);
        if (this.isFieldEmpty(paymentEmail)) {
            isFormValid = false;
            validationErrs = {
                name: constants.FORM_DATA.PAYMENT_EMAIL,
                value: errorMessages.payment_email_required
            }
            this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
        }

        //payment email address 
        if (!validateEmail(paymentEmail)) {
            isFormValid = false;
            validationErrs = {
                name: constants.FORM_DATA.PAYMENT_EMAIL,
                value: errorMessages.invalid_email_format
            }
            this.props.dispatch({ type: constants.UPDATE_FORM_DATA_VALIDATION_ERRS, validationErrs })
        }

        return isFormValid;
    }

    validateFormAuthEmailConfig() {
        const errorMessages = defineMessages({
            atleast_one_email_required: {
                id: 'atleast_one_email_required',
                description: 'Please enter atleast one email',
                defaultMessage: 'Please enter atleast one email'
            },
            name_email_required: {
                id: 'name_email_required',
                description: 'Both Name and Email are required',
                defaultMessage: 'Both Name and Email are required'
            },
            invalid_email_format: {
                id: 'invalid_email_format',
                description: 'Email format is invalid',
                defaultMessage: 'Email format is invalid'
            },
            invalid_is_registered: {
                id: 'invalid_is_registered',
                description: 'Either register for notification emails or opt out',
                defaultMessage: 'Either register for notification emails or opt out'
            },
        })

        let isFormValid = true;
        let validationErrs;
        let contacts = this.props.authorizationEmailConfig.get('contacts');

        if (this.props.authorizationEmailConfig.get('isRegistered') == undefined) {
            isFormValid = false;            
            validationErrs = {
                name: 'isRegistered',
                value: errorMessages.invalid_is_registered
            }
            this.props.dispatch({ type: constants.UPDATE_EMAIL_CONFIG_VALIDATION_ERRS, validationErrs })
        }

        if (contacts != undefined && contacts.length > 0) {

            for (var index = 0; index < contacts.length; index++) {

                //required 
                if ((this.isFieldEmpty(contacts[index].name) && !this.isFieldEmpty(contacts[index].email))
                    || (!this.isFieldEmpty(contacts[index].name) && this.isFieldEmpty(contacts[index].email))
                    || (this.isFieldEmpty(contacts[index].name) && this.isFieldEmpty(contacts[index].email))
                    ) {
                    isFormValid = false;
                    validationErrs = {
                        name: index,
                        value: errorMessages.name_email_required
                    }
                    this.props.dispatch({ type: constants.UPDATE_EMAIL_CONFIG_VALIDATION_ERRS, validationErrs })
                }

                //email format
                if (!this.isFieldEmpty(contacts[index].email) && !validateEmail(contacts[index].email)) {
                    isFormValid = false;
                    validationErrs = {
                        name: index,
                        value: errorMessages.invalid_email_format
                    }
                    this.props.dispatch({ type: constants.UPDATE_EMAIL_CONFIG_VALIDATION_ERRS, validationErrs })
                }
            }
        }

        return isFormValid;
    }

    validateTermsAndConditions() {

        const errorMessages = defineMessages({
            terms_and_conditions_required: {
                id: 'terms_and_conditions_required',
                description: 'terms_and_conditions_required',
                defaultMessage: 'Please accepted Terms And Conditions'
            },

        });

        let isFormValid = true;
        let validationErrs;


        if (!this.props.toggleAcceptedFlag) {
            isFormValid = false;
            validationErrs = {
                name: "TERMS_AND_CONDITIONS",
                value: errorMessages.terms_and_conditions_required
            }
            this.props.dispatch({ type: "TERMS_AND_CONDITIONS_VALIDATION_ERRS", validationErrs })
        }else{
            isFormValid = true;
            this.removeValidationErr("TERMS_AND_CONDITIONS")

        }



        return isFormValid;
    }

    onAcceptedTermsHandler(){

        this.props.dispatch({type:  constants.TOGGLE_TERMS_AND_CONDITIONS_ACCEPTED })
    }

    onSubmitHandler(step) {

        switch (step) {
            case 1:
                if (this.validateFormForAccountInfo()) {
                    this.props.dispatch({ type: constants.SUBMIT_ACCOUNT_INFO });
                }
                break;
            case 2:
                if ((this.props.tcInitialsAcceptedFlag != undefined && this.props.tcInitialsAcceptedFlag.length == 2) && this.props.toggleAcceptedFlag) {
                    this.props.dispatch({type: constants.SAVE_TERMS_AND_CONDITIONS_STATUS,termsAndConditionsFlag: true});
                    this.props.dispatch({type:"ACCEPTED_TERMS_AND_CONDITIONS_SUBMISSION_REGISTER"});
                }else{
                    this.props.dispatch(appActions.tcConditionTermsAndConditionsChecked(true))
                }
                break;
            case 3:
                if (this.validateFormForBankInfo()) {
                    this.props.dispatch({ type: constants.SUBMIT_BANK_INFO })
                }
                break;
            case 4:
                this.props.dispatch({ type: constants.SAVE_VENDOR_SERVICES_FOR_REGISTRATION });
                break;
            case 5:
                if (this.validateFormAuthEmailConfig()) {
                    this.props.dispatch({ type: constants.SAVE_EMAIL_AUTH_NOTIFICATION });
                }
                break;
            default:
                break;
        }
    }

    onChangeRegistrationFieldValueHandler(inputName, event) {
        let registrationField = {
            name: inputName,
            value: event.target.value
        };
        this.props.dispatch({ type: constants.UPDATE_REGISTRATION_FIELD, registrationField: registrationField });
    }

    onCountryChangeHandler(country,ariId){
        let registrationField = {
            name: constants.FORM_DATA.COUNTRY,
            value: country
        };
        this.props.dispatch({type:'REGISTRATION_SELECTED_COUNTRY_CHANGED'});
        this.props.dispatch({ type: constants.UPDATE_REGISTRATION_FIELD, registrationField: registrationField });

        let ariIdField={
            name:constants.FORM_DATA.ARI_ID,
            value:ariId
        }
        this.props.dispatch({ type: constants.UPDATE_REGISTRATION_FIELD, registrationField: ariIdField });
        
        if(country=="MEX"){            
            this.props.dispatch(sharedActions.selectLocale("es-MX"));
            CookieHelper.createCookie('vms_language_preference', JSON.stringify({
                languageLocaleCookie:"es-MX",
            }));
        }
        else
        {
            this.props.dispatch(sharedActions.selectLocale("en-US"));
            CookieHelper.createCookie('vms_language_preference', JSON.stringify({
                languageLocaleCookie:"en-US",
            }));
        }
        this.props.dispatch(sharedActions.setSelectedCountry(country));
    }

    nextStepHandler() {
        this.props.dispatch({ type: constants.REGISTRATION_NEXT_STEP });
    }

    removeValidationErr(fieldName) {
        this.props.dispatch({ type: constants.REMOVE_VALIDATION_ERROR, fieldName })
    }
    removeContactHandler(index, event) {
        let updatedContacts = this.props.authorizationEmailConfig.get('contacts');
        updatedContacts.splice(index, 1);
        this.props.dispatch({ type: constants.REMOVE_NOTIFICATION_CONTACT, updatedContacts })
    }
    addContactRowHandler() {
        let contacts = this.props.authorizationEmailConfig.get('contacts');
        let updatedContacts = contacts.concat({ seq: contacts.length + 1, name: '', email: '' });
        this.props.dispatch({ type: constants.UPDATE_NOTIFICATION_CONTACT, updatedContacts })
    }

    updateContactHandler(inputName, index, event) {
        if (event != undefined) {
            let updatedContacts = this.props.authorizationEmailConfig.get('contacts');
            updatedContacts[index][inputName] = event.target.value;
            this.props.dispatch({ type: constants.UPDATE_NOTIFICATION_CONTACT, updatedContacts })
        }
    }
    updateEmailConfigRegistrationHandler(isRegistered) {
        this.props.dispatch({ type: constants.UPDATE_EMAIL_CONFIG_REGISTRATION, isRegistered })
    }
    removeValidationErrForAuthEmailConfigHandler(fieldName) {
        this.props.dispatch({ type: constants.REMOVE_EMAIL_CONFIG_VALIDATION_ERRS, fieldName })
    }
    loadVendorServicesHandler() {
        this.props.dispatch({ type: constants.FETCH_VENDOR_SERVICES_FOR_REGISTRATION });
    }

    //Vendor service profile events
    ChangeVendorServicesClickHandler() {

    }
    vendorServicesSelectedHandler(selectedValue, serviceId) {
        // this.onBlurChange();
        if (selectedValue) {
            this.props.dispatch({ type: constants.VENDOR_SERVICE_SELECTED_FOR_REGISTRATION, servicesSelected: false, serviceKey: serviceId });
        } else {
            this.props.dispatch({ type: constants.VENDOR_SERVICE_SELECTED_FOR_REGISTRATION, servicesSelected: true, serviceKey: serviceId });
        }
    }
    ServicesCancelClickEvent() {

    }
    vendorMinoritySelectedHandler(serviceId) {
        this.props.vendorMinority.map(service => {
            this.props.dispatch({ type: constants.VENDOR_MINORITY_SELECTED_FOR_REGISTRATION, minoritySelected: false, minorityKey: service.get("id") });
        });
        this.props.dispatch({ type: constants.VENDOR_MINORITY_SELECTED_FOR_REGISTRATION, minoritySelected: true, minorityKey: serviceId });
    }
    onSaveVendorServicesClickHandler() {
        this.props.dispatch({ type: constants.SAVE_VENDOR_SERVICES_FOR_REGISTRATION });
    }
    onBlurChange() {
    }

    onToggleAcceptedTermsHandler (){
    
        this.props.dispatch(appActions.termsAndConditionsToggleAccepted());
    }

    onInitialsAcceptedTermsHandler (tcInitialsValue){
        this.props.dispatch(appActions.termsAndConditionsInitialsAccepted(tcInitialsValue));
 
    }

    render() {
        return (
            <div className='registration'>
                {
                    this.props.routeParams.match.params.step == 'accountInfo' ?
                        <AccountInfo onSubmitStep={this.onSubmitHandler.bind(this)}
                            onChangeRegistrationFieldValue={this.onChangeRegistrationFieldValueHandler.bind(this)}
                            formDataValidationMessages={this.props.formDataValidationMessages}
                            removeValidationErr={this.removeValidationErr.bind(this)}
                            isSubmittingAccountInfo={this.props.isSubmittingAccountInfo}
                            country={this.props.formData.get(constants.FORM_DATA.COUNTRY)}
                            error={renderError('accountInfoErrorMessage', this.props, this.props.accountInfoErrorMessage)}
                            onCountryChange={this.onCountryChangeHandler.bind(this)}
                            formData={this.props.formData}
                        />
                        : undefined
                }
                {
                    this.props.routeParams.match.params.step === 'vendorTermsAndConditions' ?
                           <div>
                                 <TermsAndConditions
                                       termsAndConditions={this.props.termsAndConditions}
                                       toggleAcceptedFlag={this.props.toggleAcceptedFlag}
                                       onAcceptedTerms={this.onAcceptedTermsHandler.bind(this)}
                                       isFetchingTermsAndConditions={this.props.isFetchingTermsAndConditions}
                                       onSubmitStep={this.onSubmitHandler.bind(this)}
                                       isFetchingTermsAndConditionsAccepted={this.props.isFetchingTermsAndConditionsAccepted}
                                       error={renderError('termsAndConditionsErrorMessage', this.props, this.props.termsAndConditionsErr)}
                                       formDataValidationMessages={this.props.formDataValidationMessages}
                                       termsAndConditionsErr={this.props.termsAndConditionsErr}
                                       tcContinueValdiationFlag = {this.props.tcContinueValdiationFlag}
                                       tcInitialsAcceptedFlag={this.props.tcInitialsAcceptedFlag}
                                       onToggleAcceptedTerms={this.onToggleAcceptedTermsHandler.bind(this)}
                                       onInitialsAcceptedTerms={this.onInitialsAcceptedTermsHandler.bind(this)}

                        />
                    </div>
                    : undefined
                }
                {
                    this.props.routeParams.match.params.step == 'bankingInfo' ?
                        <BankingInfo
                            formData={this.props.formData}
                            onSubmitStep={this.onSubmitHandler.bind(this)}
                            onChangeRegistrationFieldValue={this.onChangeRegistrationFieldValueHandler.bind(this)}
                            isSubmittingBankInfo={this.props.isSubmittingBankInfo}
                            formDataValidationMessages={this.props.formDataValidationMessages}
                            removeValidationErr={this.removeValidationErr.bind(this)}
                            country={this.props.formData.get(constants.FORM_DATA.COUNTRY)}
                            error={renderError('bankInfoErrorMessage', this.props, this.props.bankInfoErrorMessage)}
                        />
                        : undefined
                }
                {
                    this.props.routeParams.match.params.step == 'serviceProfile' ?
                        <ServiceProfile
                            onSubmitStep={this.onSubmitHandler.bind(this)}
                            vendorServices={this.props.vendorServices}
                            vendorMinority={this.props.vendorMinority}
                            minoritySelected={this.props.minoritySelected}
                            loadVendorServices={this.loadVendorServicesHandler.bind(this)}
                            vendorServicesSelected={this.vendorServicesSelectedHandler.bind(this)}
                            vendorMinoritySelected={this.vendorMinoritySelectedHandler.bind(this)}
                            onSaveVendorServices={this.onSaveVendorServicesClickHandler.bind(this)}
                            isFetchingServiceInfo={this.props.isFetchingServiceInfo}
                            isSubmittingServiceInfo={this.props.isSubmittingServiceInfo}
                            error={renderError('serviceInfoErrorMessage', this.props, this.props.serviceInfoErrorMessage)}
                            showVendorMinority={true}
                            country={this.props.formData.get(constants.FORM_DATA.COUNTRY)}
                        />
                        : undefined
                }
                {
                    this.props.routeParams.match.params.step == 'authEmailNotification' ?
                        <AuthEmailNotification
                            formMode={constants.ADD}
                            onSubmitStep={this.onSubmitHandler.bind(this)}
                            onChangeRegistrationFieldValue={this.onChangeRegistrationFieldValueHandler.bind(this)}
                            addContactRow={this.addContactRowHandler.bind(this)}
                            updateContact={this.updateContactHandler.bind(this)}
                            removeContact={this.removeContactHandler.bind(this)}
                            authorizationEmailConfig={this.props.authorizationEmailConfig.get('contacts')}
                            isRegistered={this.props.authorizationEmailConfig.get('isRegistered')}
                            updateEmailConfigRegistration={this.updateEmailConfigRegistrationHandler.bind(this)}
                            authorizationEmailConfigValidationErrs={this.props.authorizationEmailConfigValidationErrs}
                            removeValidationErrForAuthEmailConfig={this.removeValidationErrForAuthEmailConfigHandler.bind(this)}
                            isSubmittingAuthEmailConfigInfo={this.props.isSubmittingAuthEmailConfigInfo}
                            error={renderError('authorizationEmailConfigErrormessage', this.props, this.props.authorizationEmailConfigErrormessage)}
                            tabIndexStartFrom ={100}
                        />
                        : undefined
                        }
            </div>
        );
                        }
};

function mapPropsToState(state, props) {
    return {
        ...RegistrationViewSelector(state, props),
        currentStep: state.appState.getIn(['uiData', 'registrationView', 'currentStep']),
        formDataValidationMessages: state.appState.getIn(['uiData', 'registrationView', 'formDataValidationErrs']),
        authorizationEmailConfig: state.appState.getIn(['uiData', 'registrationView', 'authorizationEmailConfig']),
        authorizationEmailConfigValidationErrs: state.appState.getIn(['uiData', 'registrationView', 'authorizationEmailConfigValidationErrs', 'contacts']),
        isSubmittingAccountInfo: state.appState.getIn(['uiData', 'registrationView', 'isSubmittingAccountInfo']),
        isSubmittingBankInfo: state.appState.getIn(['uiData', 'registrationView', 'isSubmittingBankInfo']),
        isFetchingServiceInfo: state.appState.getIn(['uiData', 'registrationView', 'isFetchingServiceInfo']),
        isSubmittingServiceInfo: state.appState.getIn(['uiData', 'registrationView', 'isSubmittingServiceInfo']),
        isSubmittingAuthEmailConfigInfo: state.appState.getIn(['uiData', 'registrationView', 'isSubmittingAuthEmailConfigInfo']),
        termsAndConditions:state.appState.getIn(['serverData', 'shared', 'vendorTermsAndConditions']) || null,
        acceptedTermsAndConditions:state.appState.getIn(['serverData', 'shared', 'acceptedTermsAndConditions']),
        toggleAcceptedFlag:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','toggleAcceptedFlag']) || false,
        isFetchingTermsAndConditions:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','isFetchingTermsAndCondition']) || false,
        tcInitialsAcceptedFlag:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','tcInitialsAcceptedFlag']) || undefined,
        tcContinueValdiationFlag:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','tcContinueValdiationFlag']) || false,
        isFetchingTermsAndConditionsAccepted:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','isFetchingTermsAndConditionAccepted']) || false,
        termsAndConditionsErr:state.appState.getIn(['uiData', 'registrationView','formDataValidationErrs','TERMS_AND_CONDITIONS'])        
    }
}

export default connect(mapPropsToState)(RegistrationView);