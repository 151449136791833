// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './Title.css';

const Title = (props) => {
    const messages = defineMessages({
            documents_title: {
                id: 'documents_title',
                description: 'Title for documents',
                defaultMessage: 'Documents'
            },
        });

	return <div className='documents__title'>
		<FormattedMessage {...messages.documents_title}/>                
	</div>
}

export default Title;