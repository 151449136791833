import * as constants from 'constants/App';

export function showPopOverARIId(popOverDisplayShowARIId) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_ARI_ID, popOverDisplayShowARIId }
}

export function showPopOverVendorPersonal(popOverDisplayShowVendorPersonal) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_PERSONAL, popOverDisplayShowVendorPersonal }

}

export function saveRecoverPasswordARIId(recoverPasswordARIId) {
    return { type: constants.SAVE_RECOVER_PASSWORD_INFORMATION_ID, recoverPasswordARIId }

}

export function saveRecoverPasswordPersonal(recoverPasswordPersonal) {
    return { type: constants.SAVE_RECOVER_PASSWORD_INFORMATION_PERSONAL, recoverPasswordPersonal }
}

export function saveRecoverPasswordPersonalError(recoverPasswordPersonalError) {
    return { type: constants.ERROR_RECOVER_PASSWORD_PERSONAL, recoverPasswordPersonalError }
}

export function showPopOverTaxId(popOverDisplayShowTaxId) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_TAX_ID, popOverDisplayShowTaxId }
}

export function showPopOverBusinessNumber(popOverDisplayShowBusNum) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_BUSINESS_NUMBER, popOverDisplayShowBusNum }
}

export function showPopOverEmail(popOverDisplayShowEmail) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_EMAIL, popOverDisplayShowEmail }
}

export function showPopOverFederalTaxId(popOverDisplayShowFedTaxId) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_FEDERAL_TAX_ID, popOverDisplayShowFedTaxId }
}
