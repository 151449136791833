import {call, put, select} from "redux-saga/effects";
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';


export function* createPOSearch(context, action) {
    try {

      if(action.createFilters.createValue.serviceClass === "CARWASH") {
            yield* createPOSearchCarWash(context, action);
        }else{
          yield* createPOSearchRepair(context, action);
      }
    }
    catch (e) {
        let requestPOInfo;
        var customError=helperFunctions.getCustomErrorObject(e);
        yield put({ type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REJECTED, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SET_VEHICLE_SEARCH_ERROR_MESSAGE, errorMessage: customError!=undefined && customError.length>0?customError[0].message:undefined });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED, errorObject: e });
        if(customError !== undefined && customError.length > 0)
        yield put({type: "VALIDATION_SEARCH_PO_CREATION",requestPOInfo:  {
                value:customError[0].message}})
    }
}

export function* fetchClientNumbers(action) {

    try {
        const state = yield select();

        yield put({ type: 'FETCH_CLIENT_NUMBERS_REQUESTED' });

        const vendorClientNumber=  yield call(Api.fetchVendorClientNumber, state, helperFunctions.getToken(state),);


        yield put({ type: 'FETCH_CLIENT_NUMBERS_ACCEPTED',vendorClientNumber });

    }
    catch (e) {
        if (e.code == 'API-045') {
            yield put({
                type: 'FETCH_CLIENT_NUMBERS_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }else {
            yield put({
                type: constants.SET_ERROR_MESSAGE,
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
        }
    }
}

export function* createPOSearchRepair(context, action) {
    try {
        const state = yield select();

        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        // Inform application that data request has started
        yield put({ type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED });

        // Clear previous data in app state
        yield put({ type: constants.CLEAR_DIAGNOSIS_DETAILS });
        yield put({ type: constants.CLEAR_SELECTED_SERVICES });
        yield put({ type: constants.CLEAR_ODOMETER_DATA });
        yield put({ type: constants.RESET_COMPLAINTS });
        yield put({ type: constants.CLEAR_COMPLAINT_NOTES });
        yield put({ type: constants.DELETE_ALL_LINE_ITEMS });
        yield put({ type: constants.CLEAR_PURCHASE_ORDER_DETAILS });
        yield put({ type: constants.HIDE_VEHICLE_SEARCH_ERROR_MESSAGE });
        yield put({ type: constants.CLEAR_REPAIR_HISTORY });
        yield put({ type: constants.CLEAR_ADD_EDIT_VIEW_SERVICES });
        yield put({ type: constants.RESET_VENDOR_VEHICLE_PARAMETERS });
        yield put({type: constants.CLEAR_MULTI_VEH_DATA_FOR_PO_CREATION});

        yield put({ type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED });
        let createFilters = action.createFilters;
        let vehicleDetails = "";

        if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.VIN8) {
            vehicleDetails = yield call(Api.fetchVehicleByVin8, createFilters.createValue.vin8, helperFunctions.getToken(state));
        }

        if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.PLATE_STATE || createFilters.createFor === constants.SEARCH_FILTER_TYPE.TAG_PROV) {
            vehicleDetails = yield call(Api.fetchVehicleByPlateState, createFilters.createValue.licensePlate,createFilters.createValue.licensePlateState,createFilters.createValue.countryCode, helperFunctions.getToken(state));
        }

        if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE) {
                try {
                    vehicleDetails = yield call(Api.fetchVehClientUnitNo, state, helperFunctions.getToken(state), createFilters.createValue.custId, createFilters.createValue.vehicle);
                }
                catch (e) {

                }

                if (vehicleDetails === undefined || vehicleDetails === "") {
                    vehicleDetails = yield call(Api.fetchVehCustARIUnitNo, state, helperFunctions.getToken(state), createFilters.createValue.custId, createFilters.createValue.vehicle);

                }
        }

        if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.SERVICE_CARD) {
            vehicleDetails = yield call(Api.fetchVehicleByServiceNo, createFilters.createValue.vehicleServiceCardNo, helperFunctions.getToken(state));

        }

            yield put({type: constants.CLEAR_VEHICLE_SEARCH_VIN});
            if (action.myUnitsPage) {

                vehicleDetails[0].existingOrderWaitingOrApprovedAuthorization = 0;
            }

        yield put({type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_RESOLVED});


           //if multiple vehicles returned go to multi vehicle landing page if not then odometer page
           if(vehicleDetails.length > 1){
               yield put({type: constants.SAVE_MULTI_VEH_DATA_FOR_PO_CREATION_DATA, vehicleDetails});

               if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.VIN8) {
                   yield call(context.history.push,`/vehicles/vin8/${createFilters.createValue.vin8}/${"RepairCreatePO"}`);}

               if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.PLATE_STATE || createFilters.createFor === constants.SEARCH_FILTER_TYPE.TAG_PROV) {
                   yield call(context.history.push,`/vehicles/plate/${createFilters.createValue.licensePlate}/state/${createFilters.createValue.licensePlateState}/${"RepairCreatePO"}`);
               }

               if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE) {
                   yield call(context.history.push,`/vehicles/clientId/${createFilters.createValue.custId}/unitNo/${createFilters.createValue.vehicle}/${"RepairCreatePO"}`);
               }

               if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.SERVICE_CARD) {
                   yield call(context.history.push,`/vehicles/serviceNo/${createFilters.createValue.vehicleServiceCardNo}/${"RepairCreatePO"}`);
               }

           }else {

               yield put({type: constants.SAVE_VEH_DATA_FOR_PO_CREATION_DATA, vehicleDetails: vehicleDetails[0]});
               if ((vehicleDetails[0].existingOrderWaitingOrApprovedAuthorization == undefined || vehicleDetails[0].existingOrderWaitingOrApprovedAuthorization == 0) && 
                   vehicleDetails[0].existingOrderPreviouslyVoided == undefined || vehicleDetails[0].existingOrderPreviouslyVoided == 0) {

                   yield call(context.history.push,`/po/odometer/${vehicleDetails[0].id}`);
               }
           }

    }
    catch (e) {
        let requestPOInfo;
        var customError=helperFunctions.getCustomErrorObject(e);
        yield put({ type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REJECTED, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SET_VEHICLE_SEARCH_ERROR_MESSAGE, errorMessage: customError!=undefined && customError.length>0?customError[0].message:undefined });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED, errorObject: e });
        if(customError !== undefined && customError.length > 0)
            yield put({type: "VALIDATION_SEARCH_PO_CREATION",requestPOInfo:  {
                    value:customError[0].message}})
    }
}

export function* createPOSearchCarWash(context, action) {
    try {
        const state = yield select();

        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        // Inform application that data request has started
        yield put({ type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED });

        // Clear previous data in app state
        yield put({ type: constants.CLEAR_DIAGNOSIS_DETAILS });
        yield put({ type: constants.CLEAR_SELECTED_SERVICES });
        yield put({ type: constants.CLEAR_ODOMETER_DATA });
        yield put({ type: constants.RESET_COMPLAINTS });
        yield put({ type: constants.CLEAR_COMPLAINT_NOTES });
        yield put({ type: constants.DELETE_ALL_LINE_ITEMS });
        yield put({ type: constants.CLEAR_PURCHASE_ORDER_DETAILS });
        yield put({ type: constants.HIDE_VEHICLE_SEARCH_ERROR_MESSAGE });
        yield put({ type: constants.CLEAR_REPAIR_HISTORY });
        yield put({ type: constants.CLEAR_ADD_EDIT_VIEW_SERVICES });
        yield put({ type: constants.RESET_VENDOR_VEHICLE_PARAMETERS });

        yield put({ type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED });
        let createFilters = action.createFilters;
        let vehicleDetails = "";

        if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.VIN8) {
            vehicleDetails = yield call(Api.fetchVehicleByVin8, createFilters.createValue.vin8, helperFunctions.getToken(state));
        }

        if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.PLATE_STATE || createFilters.createFor === constants.SEARCH_FILTER_TYPE.TAG_PROV) {
            vehicleDetails = yield call(Api.fetchVehicleByPlateState, createFilters.createValue.licensePlate,createFilters.createValue.licensePlateState,createFilters.createValue.countryCode, helperFunctions.getToken(state));
        }

        if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.SERVICE_CARD) {
            vehicleDetails = yield call(Api.fetchVehicleByServiceNo, createFilters.createValue.vehicleServiceCardNo, helperFunctions.getToken(state));

        }

        yield put({type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_RESOLVED});

        //if multiple vehicles returned go to multi vehicle landing page if not then carwash page
        if(vehicleDetails.length > 1){
            yield put({type: constants.SAVE_MULTI_VEH_DATA_FOR_PO_CREATION_DATA, vehicleDetails});
            if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.VIN8) {
                yield call(context.history.push, `/vehicles/vin8/${createFilters.createValue.vin8}/${"CarWashCreatePO"}`);}

            if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.PLATE_STATE || createFilters.createFor === constants.SEARCH_FILTER_TYPE.TAG_PROV) {
                yield call(context.history.push,`/vehicles/plate/${createFilters.createValue.licensePlate}/state/${createFilters.createValue.licensePlateState}/${"CarWashCreatePO"}`);
            }
            if (createFilters.createFor === constants.SEARCH_FILTER_TYPE.SERVICE_CARD) {
                yield call(context.history.push,`/vehicles/serviceNo/${createFilters.createValue.vehicleServiceCardNo}/${"CarWashCreatePO"}`);
            }

        }else {

                yield put({
                    type: "FETCH_CAR_WASH_DETAILS",
                    clientNumber: createFilters.createFor === constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE ?
                        createFilters.createValue.custId
                        : vehicleDetails[0].clientId,
                    unitNumber: createFilters.createFor === constants.SEARCH_FILTER_TYPE.CLIENT_VEHICLE ?
                        createFilters.createValue.vehicle : vehicleDetails[0].ariUnitNo
                });

        }

    }
    catch (e) {
        let requestPOInfo;
        var customError=helperFunctions.getCustomErrorObject(e);
        yield put({ type: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REJECTED, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SET_VEHICLE_SEARCH_ERROR_MESSAGE, errorMessage: customError!=undefined && customError.length>0?customError[0].message:undefined });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_VEH_DATA_FOR_PO_CREATION_REQUESTED, errorObject: e });
        if(customError !== undefined && customError.length > 0)
            yield put({type: "VALIDATION_SEARCH_PO_CREATION",requestPOInfo:  {
                    value:customError[0].message}})
    }
}
