export function dealerDraftAddComplaintCode(complaintCode) {
    return {type: "ADD_COMPLAINT_CODE_DEALER_DRAFT", complaintCode}
}

/* Toggles the visibility of Add new line items modal*/
export function dealerDraftToggleShowWindow() {
    return {type: "TOGGLE_SHOW_MODAL_ADD_NEW_LINE_ITEMS_DEALER_DRAFT"}
}

/*Adds correction details - qty, cost, hours, rate*/
export function dealerDraftAddCorrectionDetails(correctionDetails) {
    return {type: "DEALER_DRAFT_ADD_CORRECTION_DETAILS", correctionDetails}
}

export function dealerDraftTogglePaymentAccepted() {
    return {type: "TOGGLE_REQUEST_PAYMENT"}
}

export function dealerDraftToggleInitialLicense(initialLicenseToggle) {
    return {type: "TOGGLE_INITIAL_LICENSE",initialLicenseToggle}
}

export function dealerDraftToggleTempTag(tempLicenseToggle) {
    return {type: "TOGGLE_TEMP_LICENSE",tempLicenseToggle}
}

export function dealerDraftToggleNoSelection(noSelectionToggle) {
    return {type: "TOGGLE_NO_SELECTION",noSelectionToggle}
}


export function dealerDraftPaymentAcceptedError(popOverDisplayShowPaymentError) {
    return {type: "POP_OVER_DISPLAY_PAYMENT_ACCEPTED_ERROR", popOverDisplayShowPaymentError}
}

export function dealerDraftRegistrationError(popOverDisplayShowRegistrationError) {
    return {type: "POP_OVER_DISPLAY_REGISTRATION_ERROR", popOverDisplayShowRegistrationError}
}

export function dealerDraftDeliveryDateError(popOverDisplayShowDeliveryDateError) {
    return {type: "POP_OVER_DISPLAY_DELIVERY_DATE_ERROR", popOverDisplayShowDeliveryDateError}
}