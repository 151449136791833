import React from 'react';
import {render} from 'react-dom';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import FAQQuestionComponent from '../components/FAQQuestionComponent';
import {Map} from 'immutable';
import {HELP_FAQ_TITLE_STATUS} from 'constants/App';
import './FAQList.css';
import OldBrowserWarning from 'components/OldBrowserWarning';
import Features from 'components/Features';

class FAQList extends React.Component {

    render() {


        var sorted = this.props.questions.sortBy(
            (a) => a.get('id')
        );


        let theQuestions = sorted.map((question, sectionKeyQuestion) => {

            return <FAQQuestionComponent
            sectionTitle={this.props.sectionTitle}
            sectionKeyQuestion={sectionKeyQuestion}
            question={question.get('question')}
            answer={question.get('answer')}
            isVisible={question.get('isVisible')}
            questionClickHandler={this.props.questionClickHandler}
            />;
        }).valueSeq();


        return (
            <Features render={restrictedFeatures => (
            <div>
                {(this.props.questions.isEmpty() === true) ? null :
                  this.props.isVideoSection === true && restrictedFeatures.showVideoLinks === false ? null :
                    <div className="faq-list__spacing">
                        <hr className="hr-color__color"/>
                        <h3 className="section-title-handling__align">
                            <FormattedMessage
        id={this.props.title.get('id')}
        description={this.props.title.get('description')}
        defaultMessage={this.props.title.get('defaultMessage')}/>
</h3>
<div role="button">
     <span onClick={this.props.titleClickHandler.bind(this, this.props.sectionTitle)}>
     {
         this.props.isVisible == true ?
             <div>
                 <img className="img-center-align__align"
                      src="/assets/VMS_button_hide_older_pos.png"/>

             </div>

                                     : <div>
                                         <img className="img-center-align__align"
        src="/assets/VMS_button_show_all_pos.png"/>

</div>
    }

                             </span>
                        </div>
                    </div>
                             }
                             {this.props.isVideoSection ? <div className="old-browser-warning">
                    <OldBrowserWarning />
                </div> : undefined}
                <div>
                             {!this.props.isVisible || theQuestions}
                </div>

            </div>)}/>)


    }

}



export default FAQList;