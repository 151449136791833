import {createSelector} from 'reselect';
import {Map} from 'immutable';
import React from 'react';
import {isAuthenticated} from 'utils/HelperFunctions';

const questionsSelector =
    state => state.appState.getIn(['serverData', 'helpFAQQuestions', 'questions']);


const isAuth = (state) => {
    return isAuthenticated(state);
};


const vendorType = (state) => {
    return state.appState.getIn(['serverData', 'shared', 'serviceClassification']);
};

const getQuestions = (sectionQuestions, auth,vendorType) => {
    let mapOfQuestions = {};
    let publicQuestions = {};

    if (auth === true) {

        return sectionQuestions.map((section, sectionKeyQuestion) => {

            publicQuestions = vendorType === "REPAIR" ? repairOnlyQuestions(section) : carWashOnlyQuestions(section);

            return mapOfQuestions[sectionKeyQuestion] = publicQuestions;
        });

    } else {

        return sectionQuestions.map((section, sectionKeyQuestion) => {

            publicQuestions = publicOnlyQuestions(section);

            return mapOfQuestions[sectionKeyQuestion] = publicQuestions;
        });
    }
}

const publicOnlyQuestions = (filteredQuestions) => {

    return  filteredQuestions.filter((queue) => {

        return queue.get('isPublicQuestion') === true;

    });

}

const carWashOnlyQuestions = (filteredQuestions) => {

    return  filteredQuestions.filter((queue) => {

        return queue.get('vendorType') === "CARWASH" || queue.get('vendorType') === "ANY";

    });

}

const repairOnlyQuestions = (filteredQuestions) => {

    return  filteredQuestions.filter((queue) => {

        return queue.get('vendorType') === "REPAIR" || queue.get('vendorType') === "ANY";

    });

}

export default  createSelector(questionsSelector, isAuth,vendorType, getQuestions);