import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import classNames from 'classnames';
import { PO_PAYMENT_STATUS } from 'constants/App';
import VehicleInformation from 'components/VehicleInformation';
import VehicleInformationV2 from '../../screens/PO/shared/components/VehicleInformation';
import './PurchaseOrderBasicDetails.css';

class PurchaseOrderBasicDetails extends React.Component {

    render() {
        return <div className={classNames({'purchase_order_vehicle_info': this.props.actionType == 'CLOSE'},
                                          {'purchase_order_vehicle_info__add_edit': this.props.actionType != 'CLOSE'})
                                        }>
            {
                this.props.paymentStatus == PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS
                    ? <VehicleInformationV2 vehicleDetails={this.props.vehicleDetails}
                                         vendorAccountType={this.props.vendorAccountType}
                                         poVendorId={this.props.poVendorId}
                                          businessAddress={this.props.businessAddress}
                                          loggedInVendorId={this.props.loggedInVendorId}
                                            />

                    : <VehicleInformation vehicleDetails={this.props.vehicleDetails}
                                          client={this.props.client}
                                          actionType={this.props.actionType}/>
            }
        </div>;
    }
}


export default PurchaseOrderBasicDetails;
