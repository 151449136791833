import * as constants from 'constants/App';
import { Map, fromJS } from 'immutable';
import moment from 'moment';
import dotInspectionComponentData from '../data/DotInspectionComponentData';
import * as dotInspectionEnums from '../data/DotInspectionEnums';

const componentDetails = dotInspectionComponentData;

const initialUiData = Map();
const defaultUiData = Map({
    showWindow: false,
    formData: Map({
        hasInspectionPassed: null,        
		inspectorAttested: false,
		inspectorSignature: '',                		
		reportNumber: 0,
		fleetUnitNumber: '',
		inspectionDate: null,
		motorCarrierOperator: '',
		motorCarrierAddressCityStateZip: 'Client address, Client city, Client state, Client zip',
		vehicleIdentification: 'VIN',
		vehicleType: 'TRUCK',
		inspectorName: '',
		inspectorQualified: true,
		repairingVendor: 'Vendor name',
		vendorAddressCityStateZip: 'Vendor address, city, state, zip',
		otherFailureReasons: '',
		isAllOk: false
    }),
	formValidationMessages: Map(),
	componentDetails: fromJS(componentDetails),
	shouldSkipFailOptions: false, 
});
const initialServerData = Map();

export function uiDataDotInspection(state = initialUiData, action) {    
    switch (action.type) {
		case constants.DOT_INSPECTION__DISPLAY:		
			let stateTemp = defaultUiData.set('showWindow', action.show);
            return stateTemp;		

        case constants.DOT_INSPECTION__RESET:
            return defaultUiData;
		case constants.DOT_INSPECTION__RESET_ERROR:
			return state.set('errorMessage', undefined);

        case constants.DOT_INSPECTION__SET_FORM_DATA: {					
			let newState = state.setIn(['formData', action.field], action.value);

			if (action.field === 'isAllOk' && action.value === true) {
				newState = newState.set('componentDetails', state.get('componentDetails').map((component) => (
					component.set('componentResultCode', dotInspectionEnums.componentResultCode.PASS)
				)));
			}
			
			return newState;
		}
        case constants.DOT_INSPECTION__SET_VALIDATION_MESSAGE:
            return state.setIn(['formValidationMessages', action.field], action.message);
        case constants.DOT_INSPECTION__RESET_VALIDATION_MESSAGES:
            return state.set('formValidationMessages', Map());

		case constants.DOT_INSPECTION__SET_COMPONENT_DETAILS: {
			let newState = state.setIn(['componentDetails', action.componentId, action.field], action.value);

			if (action.value === false) {
				newState = newState.setIn(['formData', 'isAllOk'], false);
			}

			return newState;
		}
		case constants.DOT_INSPECTION__SET_ALL_COMPONENT_OK:
			return state.set('componentDetails', state.get('componentDetails').map((component) => (
				component.set('componentResultCode', dotInspectionEnums.componentResultCode.PASS)
			)));

		case constants.DOT_INSPECTION__SKIP_FAIL_OPTIONS:
			return state.set('shouldSkipFailOptions', true);

		case constants.DOT_INSPECTION__SAVE_CONFIRMATION_REQUESTED:
			return state
				.set('errorMessage', undefined)
				.set('isSaving', true);
		case constants.DOT_INSPECTION__SAVE_CONFIRMATION_REJECTED:
			return state
				.set('errorMessage', action.errorMessage)
				.set('isSaving', false);
		case constants.DOT_INSPECTION__SAVE_CONFIRMATION_RESOLVED:
			return state.set('isSaving', false);

		case constants.DOT_INSPECTION__SAVE_FORM_REQUESTED:
			return state
				.set('errorMessage', undefined)
				.set('isSaving', true);
		case constants.DOT_INSPECTION__SAVE_FORM_REJECTED:
			return state
				.set('errorMessage', action.errorMessage)
				.set('isSaving', false);
		case constants.DOT_INSPECTION__SAVE_FORM_RESOLVED:
			return state.set('isSaving', false);

        default:
            return state;
    }
}

export function serverDataDotInspection(state = initialServerData, action) {
    switch (action.type) {
        //case constants.DOT_INSPECTION:
        //    return Map();                         
        default:
            return state;
    }
}