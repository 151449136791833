import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';


export function* fetchClientNumbers(action) {

    try {
        const state = yield select();

        yield put({ type: 'FETCH_CAR_WASH_CLIENT_NUMBERS_REQUESTED' });

        const vendorClientNumber=  yield call(Api.fetchVendorClientNumber, state, helperFunctions.getToken(state),);


        yield put({ type: 'FETCH_CAR_WASH_CLIENT_NUMBERS_ACCEPTED',vendorClientNumber });

    }
    catch (e) {
        if (e.code == 'API-045') {
            yield put({
                type: 'FETCH_CAR_WASH_CLIENT_NUMBERS_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }else {
            yield put({
                type: constants.SET_ERROR_MESSAGE,
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
        }
    }
}

export function* fetchCarWashDetails(context,action) {

    try {
        const state = yield select();

        yield put({ type: 'FETCH_CAR_WASH_DETAILS_REQUESTED' });
        yield put({ type: 'CLEAR_CREATE_PO_DETAILS' });
        var applicableTax;
        const vendorId=state.appState.getIn(['serverData', 'shared', 'vendorId']);
        const countrySelected = state.appState.getIn(["uiData", "shared", "selectedCountry"]);

        let vehicleCarWashDetails;

        try{
            vehicleCarWashDetails=  yield call(Api.fetchCarWashDetailsVehClientUnitNo,state, helperFunctions.getToken(state),action.clientNumber,action.unitNumber);    
        }
        catch(e){
            
        }
        if(vehicleCarWashDetails==undefined || vehicleCarWashDetails === "")

        vehicleCarWashDetails=  yield call(Api.fetchCarWashDetailsVehCustARIUnitNo,state, helperFunctions.getToken(state),action.clientNumber,action.unitNumber);


        if(vehicleCarWashDetails.length > 1){
            yield put({type: constants.SAVE_MULTI_VEH_DATA_FOR_PO_CREATION_DATA, vehicleDetails: vehicleCarWashDetails});

            yield call(context.history.push,`/vehicles/clientId/${action.clientNumber}/unitNo/${action.unitNumber}/${"CarWashCreatePO"}`);

        }else {
            applicableTax = yield  call(Api.fetchApplicableTax, vehicleCarWashDetails[0].id, vendorId, helperFunctions.getToken(state));


            yield put({type: "FETCH_TAX_DATA_RESOLVED", applicableTax});
            yield put({type: 'FETCH_CAR_WASH_DETAILS_ACCEPTED', vehicleCarWashDetails: vehicleCarWashDetails[0]});
            yield put({type: 'FETCH_CAR_WASH_PARAMETERS'});
        }

    }
    catch (e) {
        var customError=helperFunctions.getCustomErrorObject(e);
            yield put({
                type: 'FETCH_CAR_WASH_DETAILS_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
        if(customError !== undefined && customError.length > 0)
            yield put({type: "VALIDATION_SEARCH_PO_CREATION",requestPOInfo:  {
                    value:customError[0].message}})


    }
}

export function* fetchingOrderDetails() {

    try {
        const state = yield select();

        yield put({ type: 'FETCH_CAR_WASH_ORDER_REQUESTED' });

        const orderSearchDetails=  yield call(Api.fetchCarWashOrderDetails, state, helperFunctions.getToken(state),);

        yield put({ type: 'FETCH_CAR_WASH_ORDER_ACCEPTED',orderSearchDetails });

    }
    catch (e) {
        if (e.code == 'API-045') {
            yield put({
                type: 'FETCH_CAR_WASH_ORDER_REJECTED',
                errorCode: e.code ? e.code : '',
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });


        }else {
            yield put({
                type: constants.SET_ERROR_MESSAGE,
                errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
            });
        }
    }
}

export function* fetchCarWashParameterDetails(context,action) {

    try {
        const state = yield select();
        let vehicleId = state.appState.getIn(['uiData', 'carWashAccount', 'vehicleDetails', 'id']);
        yield put({ type: 'FETCH_CAR_WASH_PARAMETERS_REQUESTED' });

        const carWashParameterDetails=  yield call(Api.fetchCarWashParameterDetails,state, helperFunctions.getToken(state));

        if(carWashParameterDetails.detailWashMaxUnitPrice === null && carWashParameterDetails.regularWashMaxUnitPrice === null){
                yield put({type: "VALIDATION_SEARCH_PO_CREATION",requestPOInfo:  {
                        value:"CarWash Order can not be created for this vehicle. Please contact 1-800-CAR-CARE."}})
        }else{
            yield put({ type: 'FETCH_CAR_WASH_PARAMETERS_ACCEPTED',carWashParameterDetails });
            yield call(context.history.push,`/carWash/vehicle/${vehicleId}`);
        }

    }
    catch (e) {

        yield put({
            type: 'FETCH_CAR_WASH_PARAMETERS_REJECTED',
            errorCode: e.code ? e.code : '',
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });


    }
}

export function* createCarWashPOSubmit(context) {

    try {
        const state = yield select();

        yield put({ type: 'CREATE_CAR_WASH_PO_REQUESTED' });

         yield call(Api.createCarWashPOSubmit,state, helperFunctions.getToken(state));

        yield put({ type: 'CREATE_CAR_WASH_PO_ACCEPTED' });
        yield call(context.history.push,`/carWash`);
        yield put ({type: "FETCH_ORDER_DETAILS"});
    }
    catch (e) {
        yield put({ type:  'CREATE_CAR_WASH_PO_REJECTED', errorMessage: helperFunctions.getCustomErrorObject(e) });


    }
}
