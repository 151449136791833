const dotInspectionComponentData = {
    1: {
		id: 1,     
		componentResultCode: null,
		repairedDate: null
	},
	2: {
		id: 2,     
		componentResultCode: null,
		repairedDate: null
	},
	3: {
		id: 3,     
		componentResultCode: null,
		repairedDate: null
	},
	4: {
		id: 4,     
		componentResultCode: null,
		repairedDate: null
	},
	5: {
		id: 5,     
		componentResultCode: null,
		repairedDate: null
	},
	6: {
		id: 6,     
		componentResultCode: null,
		repairedDate: null
	},
	7: {
		id: 7,     
		componentResultCode: null,
		repairedDate: null
	},
	8: {
		id: 8,     
		componentResultCode: null,
		repairedDate: null
	},
	9: {
		id: 9,     
		componentResultCode: null,
		repairedDate: null
	},
	10: {
		id: 10,     
		componentResultCode: null,
		repairedDate: null
	},
	11: {
		id: 11,     
		componentResultCode: null,
		repairedDate: null
	},
	12: {
		id: 12,     
		componentResultCode: null,
		repairedDate: null
	},
	13: {
		id: 13,     
		componentResultCode: null,
		repairedDate: null
	},
	14: {
		id: 14,     
		componentResultCode: null,
		repairedDate: null
	},
	15: {
		id: 15,     
		componentResultCode: null,
		repairedDate: null
	},
	16: {
		id: 16,     
		componentResultCode: null,
		repairedDate: null
	},
	17: {
		id: 17,     
		componentResultCode: null,
		repairedDate: null
	},
	18: {
		id: 18,     
		componentResultCode: null,
		repairedDate: null
	},
	19: {
		id: 19,     
		componentResultCode: null,
		repairedDate: null
	},
	20: {
		id: 20,     
		componentResultCode: null,
		repairedDate: null
	},
	21: {
		id: 21,     
		componentResultCode: null,
		repairedDate: null
	},
	22: {
		id: 22,     
		componentResultCode: null,
		repairedDate: null
	},
	23: {
		id: 23,     
		componentResultCode: null,
		repairedDate: null
	},
	24: {
		id: 24,     
		componentResultCode: null,
		repairedDate: null
	},
	25: {
		id: 25,     
		componentResultCode: null,
		repairedDate: null
	},
	26: {
		id: 26,     
		componentResultCode: null,
		repairedDate: null
	},
	27: {
		id: 27,     
		componentResultCode: null,
		repairedDate: null
	},
	28: {
		id: 28,     
		componentResultCode: null,
		repairedDate: null
	},
	29: {
		id: 29,     
		componentResultCode: null,
		repairedDate: null
	},
	30: {
		id: 30,     
		componentResultCode: null,
		repairedDate: null
	},
	31: {
		id: 31,     
		componentResultCode: null,
		repairedDate: null
	},
	32: {
		id: 32,     
		componentResultCode: null,
		repairedDate: null
	},
	33: {
		id: 33,     
		componentResultCode: null,
		repairedDate: null
	},
	34: {
		id: 34,     
		componentResultCode: null,
		repairedDate: null
	},
	35: {
		id: 35,     
		componentResultCode: null,
		repairedDate: null
	},
	36: {
		id: 36,     
		componentResultCode: null,
		repairedDate: null
	},
	37: {
		id: 37,     
		componentResultCode: null,
		repairedDate: null
	},
	38: {
		id: 38,     
		componentResultCode: null,
		repairedDate: null
	},
	39: {
		id: 39,     
		componentResultCode: null,
		repairedDate: null
	},
	40: {
		id: 40,     
		componentResultCode: null,
		repairedDate: null
	},
	41: {
		id: 41,     
		componentResultCode: null,
		repairedDate: null
	},
	42: {
		id: 42,     
		componentResultCode: null,
		repairedDate: null
	},
	43: {
		id: 43,     
		componentResultCode: null,
		repairedDate: null
	},
	44: {
		id: 44,     
		componentResultCode: null,
		repairedDate: null
	},
	45: {
		id: 45,     
		componentResultCode: null,
		repairedDate: null
	},
	46: {
		id: 46,     
		componentResultCode: null,
		repairedDate: null
	},
	47: {
		id: 47,     
		componentResultCode: null,
		repairedDate: null
	},
	48: {
		id: 48,     
		componentResultCode: null,
		repairedDate: null
	},
	49: {
		id: 49,     
		componentResultCode: null,
		repairedDate: null
	},
	50: {
		id: 50,     
		componentResultCode: null,
		repairedDate: null
	},
						
};

export default dotInspectionComponentData;