import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import './CannotEditClaimBox.css';

export default class CannotEditClaimBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }


    showModal() {
        this.setState({ show: true });
    }

    hideModal() {
        this.setState({ show: false });
    }

    onOKClick(e) {
        e.preventDefault();
        this.hideModal();

      
    }


    render() {

        return (
            <Modal
                {...this.props}
                show={this.state.show}
                dialogClassName="custom-modal">
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <div className="claim_box__message">
                                    {

                                        <FormattedMessage
                                            id={this.props.message.id}
                                            description={this.props.message.description}
                                            defaultMessage={this.props.message.defaultMessage}
                                            values={{emailAddress:  this.props.selectedCountry == "CAN" ?  "CanadaClaims@holman.com" : "fleetclaims@holman.com", br: data => <br/> , a: data=><a href={`mailto:${data}`}>{data}</a>,b: data => <b>{data}</b>}}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col>
                                <div className="claim_box__links-yes">
                                    <a onClick={this.onOKClick.bind(this)}>
                                        {
                                            <FormattedMessage
                                                id={this.props.okButtonText.id}
                                                description={this.props.okButtonText.description}
                                                defaultMessage={this.props.okButtonText.defaultMessage}
                                            />
                                        }
                                        {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }
}
