import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, injectIntl, RawIntlProvider } from 'react-intl';
import './CorrectionDetails.css';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import { OverlayTrigger, ToggleButton, Tooltip } from 'react-bootstrap';
import CheckCircle from 'components/CheckCircle';
import styled from 'styled-components';

const CheckCircleStyled = styled(CheckCircle)`
    padding: 0.5rem;
    color: black;
    `;

const CorrectionDetailsPM = (props) => {

    const renderInputBoxWithNALabel = () => {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_displayed_message: {
                id: 'contract_pricing_cannot_be_edited_displayed_message',
                description: 'This product code has contract pricing. Price cannot be edited or displayed.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited or displayed.'
            },
        });

        return <OverlayTrigger placement='right'
            overlay={<Tooltip><RawIntlProvider value={props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_displayed_message} /></RawIntlProvider></Tooltip>}
        >
            <input
                disabled={true}
                className="correction-type__input-readonly"
                value="N/A"
            />
        </OverlayTrigger>
    }

    const renderpartsCostInputBoxWithConditionalOverlay = (partsCost, showOverlay) => {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_message: {
                id: 'contract_pricing_cannot_be_edited_message',
                description: 'This product code has contract pricing. Price cannot be edited.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited.'
            },
        });

        const partsCostInputBox = <DynamicNumber
            disabled={!props.partsCostEditable}
            className={props.partsCostEditable ? "correction-type__input-medium" : "correction-type__input-readonly"}
            onChange={props.handleChange.bind(this, "COST")}
            separator={'.'}
            integer={6}
            fraction={2}
            positive={true}
            negative={false}
            thousand={false}
            value={partsCost}
            ref={props.pmCheckRef}
        />;

        if (showOverlay)
            return <OverlayTrigger placement='right'
                overlay={<Tooltip><RawIntlProvider value={props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_message} /></RawIntlProvider></Tooltip>}
            >
                {partsCostInputBox}
            </OverlayTrigger>;
        else
            return partsCostInputBox
    }

    const PriceControlConfirmation = () => {
        const messages = defineMessages({
            price_exceeds_confirmation: {
                id: 'price_exceeds_confirmation',
                description: 'Confirm',
                defaultMessage: 'Confirm'
            },
        });
        
        return <div {...props}>
            <CheckCircleStyled message={<FormattedMessage {...messages.price_exceeds_confirmation} />} size={"sm"} checked={priceValidationConfirmed} onClick={() => {props.handlePriceValdationConfirmationButtonClick(0)}} />
        </div>;
    }

    let partsQty = props.currentPartsQty === 0 ? "" : props.currentPartsQty.toString().match(/^-?\d+(?:\.\d{0,2})?/);
    let partsCost = props.currentPartsCost === 0 ? "" : props.currentPartsCost.toString().match(/^-?\d+(?:\.\d{0,2})?/);
    let partsTotal = props.correctionDetailsTotal.get('partTotal').toFixed(2);
    
    //Flag for final price validation visiblity
    let priceValidationExceeds = props.priceValidations?.find(x => x != undefined).menupricingexceeds;
    //Flag for price exceed confirmation button
    let priceValidationConfirmed = props.pricesConfirmedList[0];

    const messages = defineMessages({
        pm_qty: {
            id: 'pm_qty',
            description: 'Qty',
            defaultMessage: 'Qty'
        },
        pm_cost: {
            id: 'pm_cost',
            description: 'Cost',
            defaultMessage: 'Cost'
        },
        pm_amount: {
            id: 'pm_amount',
            description: 'Amount',
            defaultMessage: 'Amount'
        },
    });
    return (
        <div className="correction-type__pm col-xl-13">
            <div className="row">
                <div className="form-group_small col-sm-3">
                    <FormattedMessage {...messages.pm_qty} />
                    <DynamicNumber
                        className="correction-type__input-small"
                        onChange={props.handleChange.bind(this, "QTY")}
                        separator={'.'}
                        integer={3}
                        fraction={props.QtyShouldBeWholeNumber ? 0 : 2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={partsQty}
                    />
                </div>
                <div className={priceValidationExceeds ? "form-group col-error col-sm-4" : "form-group col-sm-4"}>
                    <div className={priceValidationExceeds && !priceValidationConfirmed ? "form-error" : ""}>
                        <FormattedMessage {...messages.pm_cost} />
                        {props.partsCostHidden ?
                            renderInputBoxWithNALabel()
                            :
                            renderpartsCostInputBoxWithConditionalOverlay(partsCost, !props.partsCostEditable)
                        }
                    </div>
                    {priceValidationExceeds ? <PriceControlConfirmation/> : null}
                </div>
                <div className="form-group col-sm-4 ">
                    {props.intl.locale == 'es-MX' ? <div><br /></div> : ''}
                    <FormattedMessage {...messages.pm_amount} />
                    {props.partsCostHidden ?
                        renderInputBoxWithNALabel()
                        :
                        <DynamicNumber
                            disabled
                            className="correction-type__input-readonly"
                            separator={'.'}
                            integer={6}
                            fraction={2}
                            positive={true}
                            negative={false}
                            thousand={false}
                            value={partsTotal}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default injectIntl(CorrectionDetailsPM);
