import { createSelector } from 'reselect';
import { emptyMapIfUndefined, falseIfUndefined } from 'utils/HelperFunctions';
import { Map, fromJS } from 'immutable';
import * as constants from 'constants/App';

const dealerDraftRequests =
    state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'dealerDraftDealerRequests', 'dealerRequestDetails']));

const dealerDraftActiveRequestsSelector = createSelector(dealerDraftRequests, (orders) => {

    return emptyMapIfUndefined(orders).filter(e => e.get("status") === "ACTIVE" ||e.get("status") === "OPEN" );


});

const dealerDraftProcessedRequestsSelector = createSelector(dealerDraftRequests, (orders) => {

    return emptyMapIfUndefined(orders).filter(e => e.get("status") === "PROCESSED"
    || e.get("status") === "APPROVED" || e.get("status") === "PENDING" || e.get("status") === "POST"  || e.get("status") === "SUBMITTED" );


});


const DealerRequestsView = createSelector(
    dealerDraftActiveRequestsSelector,
    dealerDraftProcessedRequestsSelector,

    (dealerDraftActiveRequests,
     dealerDraftProcessedRequests) => {

        return {
            dealerDraftActiveRequests,
            dealerDraftProcessedRequests
        };
    }
);

export default DealerRequestsView;