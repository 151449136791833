import React from 'react';
import ReactDoM from 'react-dom';
import * as constants from 'constants/App';
import { defineMessages, FormattedMessage } from 'react-intl';
import Submit from 'components/Submit';
import VendorServices from '../../VendorAccountInformation/components/VendorServices'
import './ServiceProfile.css';
import Spinner from 'components/Spinner';

class ServiceProfile extends React.Component {
    componentDidMount() {
        this.props.loadVendorServices();
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.onSubmitStep(4);
    }
    //dummy functions to fulfill VendorService component
    dummyCancelHandler(){}
    //dummy functions to fulfill VendorService component
    dummyBlurChange(){}
    
    render() {
        const messages = defineMessages({
            services: {
                id: 'services',
                description: 'Step 4of 5: Services Profile',
                defaultMessage: 'Step 4 of 5: Services Profile'
            },
            go_to_next_step: {
                id: 'go_to_next_step',
                description: 'Go to next step',
                defaultMessage: 'Go to next step'
            },
        });
        return (
            <div className='step3'>
                <div className='step3__header'><FormattedMessage {...messages.services } /></div>
                <div className='step3__services'>
                    {this.props.error}
                    <div className='step3__services-input'>
                        <div>
                            {this.props.isFetchingServiceInfo ?
                                <div className="">
                                    <Spinner />
                                </div>
                                : <VendorServices
                                    vendorServices={this.props.vendorServices}
                                    vendorMinority={this.props.vendorMinority}
                                    vendorServicesClick={this.props.vendorServicesClick}
                                    isEditModeServices={true}
                                    minoritySelected={this.props.minoritySelected}
                                    vendorServicesSelected={this.props.vendorServicesSelected}
                                    servicesCancel={this.dummyCancelHandler.bind(this)}
                                    vendorMinoritySelected={this.props.vendorMinoritySelected}
                                    onSaveVendorServices={this.props.onSaveVendorServices}
                                    onBlurChange={this.dummyBlurChange.bind(this)}
                                    showFooterButtons={false}
                                    showVendorMinority={this.props.showVendorMinority}
                                    country={this.props.country}
                                />
                            }

                        </div>

                    </div>
                    <div className='step3__next-step'>
                        <Submit caption={<FormattedMessage {...messages.go_to_next_step }/>}
                            imagePath="/assets/VMS_33button_go_bblue.png"
                            onClickHandler={this.onSubmit.bind(this)}
                            showSpinner={this.props.isSubmittingServiceInfo} />
                    </div>
                </div>
            </div>
        );
    }
};
export default ServiceProfile;