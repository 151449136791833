import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';

function getValidTaxId(enrollmentRequestInfo) { 
    let ssn = enrollmentRequestInfo.get('ssnArea') + enrollmentRequestInfo.get('ssnGroup') + enrollmentRequestInfo.get('ssnSerial');
    let employerId = enrollmentRequestInfo.get('employerIdFirstPart') + enrollmentRequestInfo.get('employerIdSecondPart');
    let isSSNValid = ssn != undefined && ssn.length == 9;
    let isEmployerIdValid = employerId != undefined && employerId.length == 9;
    let taxId = isSSNValid ? ssn : isEmployerIdValid ? employerId : "";
    let taxIdType = isSSNValid ? 'SSN' : isEmployerIdValid ? 'EIN' : "";

    return {
        taxId: taxId,
        taxIdType: taxIdType
    }
}


function composeDataToBePostedForEnrollmentRequest(enrollmentRequestInfo) {
var faxTestExpression = /^\d{10}$/;
var faxNumber =
    helperFunctions.emptyStringIfUndefined(enrollmentRequestInfo.get("faxArea")) +
    helperFunctions.emptyStringIfUndefined(enrollmentRequestInfo.get("faxPrefix")) +
    helperFunctions.emptyStringIfUndefined(enrollmentRequestInfo.get("faxSuffix"));  
    return {
        referralCode: enrollmentRequestInfo.get('referralCode'),
        businessName: enrollmentRequestInfo.get('businessName'),
        location: {
            address1: enrollmentRequestInfo.get('address1'),
            address2: enrollmentRequestInfo.get('address2'),
            city: enrollmentRequestInfo.get('city'),
            state: enrollmentRequestInfo.get('state'),
            zipCode: enrollmentRequestInfo.get('zip'),
            country: enrollmentRequestInfo.get('country')
        },
        businessContact: {
            contactName: enrollmentRequestInfo.get('contactName'),
            phoneNumber: enrollmentRequestInfo.get('phoneArea') + enrollmentRequestInfo.get('phonePrefix') + enrollmentRequestInfo.get('phoneSuffix'),
            extension: enrollmentRequestInfo.get('extension'),
            faxNumber :faxTestExpression.test(faxNumber)? enrollmentRequestInfo.get('faxArea') + enrollmentRequestInfo.get('faxPrefix') + enrollmentRequestInfo.get('faxSuffix'):undefined,
            email: enrollmentRequestInfo.get('email')
        },
        reCaptchaVerificationToken: enrollmentRequestInfo.get('reCaptchaVerificationToken')
    };
}

export function* submitVendorEnrollmentRequest(context) {
    try {
        //clear out any error messages
        yield put({ type: constants.CLEAR_ERROR_MESSAGE });
        yield put({ type: constants.SUBMIT_VENDOR_ENROLLMENT_REQUEST_REQUESTED });
        // Read data from app state
        const state = yield select();
        const token = helperFunctions.getToken(state);

        const requestInfoPostData = composeDataToBePostedForEnrollmentRequest(state.appState.getIn(['uiData', 'vendorEnrollmentRequestView', 'enrollmentRequestInfo']));
        
        const response = yield call(Api.submitVendorEnrollmentRequest, requestInfoPostData, token);
        const isRequestInfoSubmittedSuccessfully = response.id != undefined && !isNaN(response.id) && response.id > 0;
        const isReferralCodeEmpty = requestInfoPostData.referralCode == undefined || requestInfoPostData.referralCode == "";
        
        const preLoadDetailsInfo = {
            businessName:requestInfoPostData.businessName,
            contactName: requestInfoPostData.businessContact.contactName,
            id: response.id
        }

        // navigate user to the appropriate page
        if (isRequestInfoSubmittedSuccessfully) {
            if (!isReferralCodeEmpty) {
                //on success update details field with business name and contact name
                yield put({ type: constants.UPDATE_PRE_LOAD_DETAIL_INFO, detailInfo: preLoadDetailsInfo });
                yield call(context.history.push,`/vendorEnrollment/request/detail/${response.id}`);    //step 2
            }
            else {
                yield put({ type: constants.RESET_ENROLLMENT_REQUEST_INFO });
            }
        }
        
        yield put({ type: constants.SUBMIT_VENDOR_ENROLLMENT_REQUEST_RESOLVED});
    }
    catch (e) {
        if(typeof window !== 'undefined'
            && typeof window.grecaptcha !== 'undefined'
            && typeof window.grecaptcha.render === 'function') {
            grecaptcha.reset();
        }
        yield put({ type: constants.SUBMIT_VENDOR_ENROLLMENT_REQUEST_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    }
}
