import * as constants from 'constants/App';

export function saveARIUserToken(token){
    return {type: constants.ARIUSER__SEARCH_NEW_VENDOR, token};
}

export function showPopOverVendorIdEmpty(popOverDisplayShowVendorIdEmpty) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_VENDOR_ID_EMPTY, popOverDisplayShowVendorIdEmpty }
}

export function showPopOverVendorPermission(popOverDisplayShowVendorPermission) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_VENDOR_PERMISSION, popOverDisplayShowVendorPermission }
}

export function showPopOverSuffixMissing(popOverDisplayShowSuffixMissing) {
    return { type: constants.POP_OVER_DISPLAY_SHOW_SUFFIX_MISSING, popOverDisplayShowSuffixMissing }
}

export function clearSearchVendor() {
    return { type: constants.CLEAR_ARIUER_SEARCH_VENDOR }
}

export function fetchVendor(token, vendorId) {
    return { type: constants.ARIUSER_SEARCH_VENDOR, token, vendorId }
}
