/**
 * Created by prboyapa on 1/11/2016.
 */
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import Title from 'components/Title';
import './ComplaintVehicleTitle.css';

class ComplaintVehicleTitle extends React.Component {

    render() {
        //render the view
        return <div className="container-fluid complaint_vehicle_title">
            <Title caption={this.props.caption}/>
        </div>;
    }
}


export default ComplaintVehicleTitle;