import { createSelector } from 'reselect';
import { emptyMapIfUndefined, falseIfUndefined } from 'utils/HelperFunctions';
import { Map, fromJS } from 'immutable';
import * as constants from 'constants/App';


const passwordSettingsSelector = (state) => {
    return emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'passwordSettings']));
}

const loggedinUserIdSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'vendorId']));

const servicesSelector =
    state => emptyMapIfUndefined(fromJS(state.appState.getIn(['uiData', 'vendorAccount', 'vendorProfile', 'services'])));

const getServicesSelector = createSelector(servicesSelector, (services) => {
    return services.sort((a, b) => a.get("description").localeCompare(b.get("description")))    
});

const vendorMinority =
    state => emptyMapIfUndefined(fromJS(state.appState.getIn(['uiData', 'vendorAccount', 'vendorProfile', 'minorityVendor'])));

const getMinoritySelector = createSelector(vendorMinority, (services) => {
    return services.sort((a, b) => a.get("description").localeCompare(b.get("description")))
})


const getMinoritySelected = createSelector(getMinoritySelector, (selected) => {

    return selected.filter((visible) => {

        return visible.get('isServiceOffered') === true;

    });
});

const emailPaymentSelector =
    state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'settingsView', 'contactSettings', 'contactsEmail']));

const getEmailPaymentSelector = createSelector(emailPaymentSelector, (email) => {

    return email.map((e) => {
        return e
    }).filter((a) => a.get("notifyPayments") === true);


});

const getContactNamePayment = createSelector(getEmailPaymentSelector, (name) => {

    return name.map(e => e.get("name")).first();


});


const getContactEmailPayment = createSelector(getEmailPaymentSelector, (email) => {

    return email.map(e => e.get("email")).first();


});

const vendorCheckingAccount =
    state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'vendorAccount', 'accountInformation', 'checkingInitial']));


const vendorCheckingAccountInitialFilter = createSelector(vendorCheckingAccount, (account) => {

    return account.map(e => e).filter((visible) => {

        return visible.get('type') === "MAINTENANCE";

    });


});


const vendorCheckingAccountInitial = createSelector(vendorCheckingAccountInitialFilter, (account) => {

    return account.map(e => e.get("accountNumber")).first();


});

const genericErrSelector = state => state.appState.getIn(['uiData', 'shared', 'errorMessage'])
const authEmailNotificationErrSelector = createSelector(
    genericErrSelector,
    state => state.appState.getIn(['uiData',  'vendorAccount', 'authorizationEmailConfig', 'authorizationEmailConfigErrormessage']),
    (genericErr, authEmailNotifyErr) => {
        return authEmailNotifyErr == undefined || authEmailNotifyErr.length == 0 ? genericErr : authEmailNotifyErr;
    }
)

const authorizationEmailConfigSelector = state => {
    if (state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'contacts']) != undefined){
        let contacts = state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'contacts']); 
        return contacts.toJSON()
                .filter(obj => obj.elementState != constants.ELEMENT_STATE.DELETE)
                .sort((a, b) => a.id - b.id)
    }
    else
        return [];
}
//.sort((a, b) => a.name != undefined ? a.name.localeCompare(b.name) : true)
const authorizationEmailConfigIsRegisteredSelector = state => {
    return state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'isRegistered'])
}

const maxContactIdSelector = createSelector(
    authorizationEmailConfigSelector, (authorizationEmailConfig) => {
        let maxId = 0;
        if (authorizationEmailConfig != undefined && authorizationEmailConfig.length > 0) {
            authorizationEmailConfig.map((item) => item.id > maxId ? maxId = item.id : maxId = maxId)
        }
        return maxId;
    }
)

const maxContactIdForAddSelector = createSelector(
    maxContactIdSelector, 
    state => state.appState.getIn(['uiData', 'vendorAccount', 'authorizationEmailConfig', 'firstContactIdForAdd']),
    (maxContactId, firstContactIdForAdd) => {
        if(firstContactIdForAdd!=undefined && maxContactId<firstContactIdForAdd)
            return firstContactIdForAdd
        else 
            return maxContactId + 1
    }
)
//const loggedinUserIdSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'vendorId']));

const showVendorMinority  =createSelector(
    state=>state.appState.getIn(['serverData', 'shared', 'vendorId']),
    (vendorId)=>{
        if(vendorId!=null && vendorId!=undefined){
            if(vendorId.endsWith('MM')) return false;
        }
        return true;
    })


const VendorAccountView = createSelector(
    passwordSettingsSelector,
    loggedinUserIdSelector,
    getServicesSelector,
    getMinoritySelected,
    getMinoritySelector,
    getContactNamePayment,
    getContactEmailPayment,
    vendorCheckingAccountInitial,
    authEmailNotificationErrSelector,
    authorizationEmailConfigSelector,
    authorizationEmailConfigIsRegisteredSelector,
    maxContactIdSelector,
    maxContactIdForAddSelector,
    showVendorMinority,
    (
        passwordSettings,
        loggedinUserId,
        vendorServices,
        minoritySelected,
        vendorMinority,
        contactNamePayment,
        contactEmailPayment,
        vendorCheckingAccount,
        authorizationEmailConfigErrormessage,
        authorizationEmailConfig,
        authorizationEmailConfigIsRegistered,
        maxContactId,
        maxContactIdForAdd,
        showVendorMinority,
    ) => {

        return {

            passwordSettings,
            loggedinUserId,
            vendorServices,
            minoritySelected,
            vendorMinority,
            contactNamePayment,
            contactEmailPayment,
            vendorCheckingAccount,
            authorizationEmailConfigErrormessage,
            authorizationEmailConfig,
            authorizationEmailConfigIsRegistered,
            maxContactId,
            maxContactIdForAdd,
            showVendorMinority
        };
    }
);

export default VendorAccountView;
