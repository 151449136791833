// #region Imports
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import Submit from '../../../../../../../App/shared/components/Submit';
import * as sharedActions from '../../../../../../shared/actions/App';
import CorrectionDetails from './CorrectionDetails';
import CorrectionTypes from './CorrectionTypes';
import SearchATACodes from './SearchATACodes';
import FrequentlyUsedATACodes from './FrequentlyUsedATACodes';
import './ATASearchCorrectionsAndCostStep.css';
import ClientParametersComponent from 'components/ClientParametersComponent';
import { connect } from "react-redux";
import AddNewLineItemsSelector from '../selectors/AddNewLineItemsView';
import { zeroIfUndefined, emptyMapIfUndefined } from 'utils/HelperFunctions';
import * as addNewLineItemsViewActions from '../actions/AddNewLineItemsView';
import * as configuration from 'configuration';
import * as constants from 'constants/App';
import { Map } from 'immutable';
import FormWrapper from 'components/FormWrapper';
import StepNumber from "StepNumber";
import { useAppState } from 'shared/AppState/app-state';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
// #endregion

// #region Styled Components
// #endregion

// #region Component
const ATASearchCorrectionsAndCostStep = (props) => {

    // #region State
    const [tiresStepNeeded, setTiresStepNeeded] = useState(false);
    const [brakesStepNeeded, setBrakesStepNeeded] = useState(false);
    const [laborRateUpdateStepNeeded, setLaborRateUpdateStepNeeded] = useState(false);
    const [ appState, dispatch ] = useAppState();
    const pmCheckRef = useRef();
    const partsCheckRef = useRef();
    // #endregion

    // #region Ref
    // #endregion  

    // #region Callback
    const debouncedFetchATACodesBySearchKey = useCallback(_.debounce((value) => {
        if (value != undefined && value.length >= 3) {
            const vehicleId = props.vehicleDetails.get('id');
            const searchData = {
                vehicleId: vehicleId,
                searchKey: value,
                correctionTypesAssociatedToVendorProductCode: props.vendorProductCodeInformation?.correctionAndRepairTypesRestricted
            }
            props.dispatch({ type: constants.SEARCHED_ATA_CODES_DATA_LOADED, searchData });

            props.dispatch(addNewLineItemsViewActions.hideContainer(false));
        }
    }, configuration.searchDelay), []);
    // #endregion  

    // #region Event handlers
    /*Handle input value change*/
    const onHandleCorrectionsDetailsChange = (inputType, e, modelValue, viewValue) => {

        let correctionDetails = Map({
            type: inputType,
            value: viewValue
        });

        props.dispatch(addNewLineItemsViewActions.addCorrectionDetails(correctionDetails));
    }

    /*Adds line item data to PO*/
    const onAddLineItemToPOHandler = () => {
        // Let parent know that the current step is done
        props.onContinue();
    }

    /* Continue link clicked */
    const onContinueClickHandler = () => {
        // Let parent know that the current step is done
        props.onContinue();
    }

    /* Handle click on window */
    const onWindowClickHandler = (e) => {
        if (props.showAtaSearchResultContainer && !(e.target.parentElement.id == 'ataSearchResult' ||
            e.target.parentElement.parentElement.id == 'ataSearchResult' ||
            e.target.parentElement.parentElement.parentElement.id == 'ataSearchResult')) {
            props.onUpdateAutoCompleteATACodeDesc('');
            props.onHideAtaSearchResultContainer(true);
        }
    }

    /*Updates state with ata code description*/
    const onUpdateAutoCompleteATACodeDescHandler = (value, supressAtaSearch) => {
        if (value == undefined) return;

        props.dispatch(addNewLineItemsViewActions.updateSearchedATADescription(value));

        if (supressAtaSearch) return;

        if (value.length >= 3) {
            debouncedFetchATACodesBySearchKey(value);
        }

        if (value.length == 0) {
            props.dispatch(addNewLineItemsViewActions.clearATACodesBySearchKey());
        }
    }

    //Hide ata search result container
    const onHideAtaSearchResultContainerHandler = () => {
        props.dispatch(addNewLineItemsViewActions.hideContainer(true));
    }

    const isRestricted = (correctionType, repairType) => {
        if ( correctionType == "REPAIR" || correctionType == "ADJUST" || correctionType == "PM" )
        {
            // Repair type does not matter for REPAIR, ADJUST or PM
            return props.selectedProductCodes[0].correctionAndRepairTypesRestricted.some(x => x.correctionType == correctionType);
        }
        else
        {
            return props.selectedProductCodes[0].correctionAndRepairTypesRestricted.some(x => x.correctionType == correctionType && ( x.repairType == "ALL" || x.repairType == repairType));
        }
    }

    /*Updates selected correction type*/
    const onSelectCorrectionTypeHandler = (correctionType) => {
 
        if (!appState.isFlatRateFeatureEnabled)
            props.dispatch(addNewLineItemsViewActions.updateCorrectionType(correctionType, props.hourlyLaborRate));
        else
        {
            // Set correction type
            props.dispatch(addNewLineItemsViewActions.updateCorrectionType(correctionType, undefined));
        }
        if (appState.isLockNationalAccountPricingFeatureEnabled) {
                // Set partUnitPrice 
           if (correctionType === "REPLACE" && !isRestricted("REPLACE","PARTS") && props.selectedProductCodes[0].partPricingSource === "national_account_pricing" && props.selectedProductCodes[0].partUnitPrice !== null) {
                    let partUnitPrice = Map({
                        type: constants.CORRECTION_DETAILS_TYPE.COST,
                        value: props.selectedProductCodes[0].partUnitPrice
             });
                    props.dispatch(addNewLineItemsViewActions.addCorrectionDetails(partUnitPrice));
            }

            if (correctionType == "PM" && !isRestricted(correctionType,"") && props.selectedProductCodes[0].laborPricingSource === "national_account_pricing" && ( props.selectedProductCodes[0].laborUnitPrice !== null || props.selectedProductCodes[0].partUnitPrice !== null)) {
                    let partUnitPrice = Map({
                        type: constants.CORRECTION_DETAILS_TYPE.COST,
                        value: props.selectedProductCodes[0].laborUnitPrice + props.selectedProductCodes[0].partUnitPrice
                    });
                    props.dispatch(addNewLineItemsViewActions.addCorrectionDetails(partUnitPrice));
            }


            // Set laborUnitPrice 
            if (correctionType !== "PM" && !isRestricted(correctionType,"LABOR") && props.selectedProductCodes[0].laborPricingSource === "national_account_pricing" && props.selectedProductCodes[0].laborUnitPrice !== null) {
                    let laborUnitPrice = Map({
                        type: constants.CORRECTION_DETAILS_TYPE.RATE,
                        value: props.selectedProductCodes[0].laborUnitPrice
                    });
                    props.dispatch(addNewLineItemsViewActions.addCorrectionDetails(laborUnitPrice));
                }
        }

        if (appState.isHideClientPricingFeatureEnabled) {
            // Set partUnitPrice 
            if (correctionType == "REPLACE" && !isRestricted("REPLACE","PARTS") && props.selectedProductCodes[0].partPricingSource === "client_contract_pricing") {
                    let partUnitPrice = Map({
                        type: constants.CORRECTION_DETAILS_TYPE.COST,
                        value: 0
                    });
                    props.dispatch(addNewLineItemsViewActions.addCorrectionDetails(partUnitPrice));
            }

            if (correctionType == "PM" && !isRestricted("PM","") && props.selectedProductCodes[0].partPricingSource === "client_contract_pricing") {
                    let partUnitPrice = Map({
                        type: constants.CORRECTION_DETAILS_TYPE.COST,
                        value: 0
                    });
                    props.dispatch(addNewLineItemsViewActions.addCorrectionDetails(partUnitPrice));
            }

            // Set labor price 
            if (correctionType !== "PM" && !isRestricted(correctionType,"LABOR") && props.selectedProductCodes[0].partPricingSource === "client_contract_pricing") {
                    let laborUnitPrice = Map({
                        type: constants.CORRECTION_DETAILS_TYPE.RATE,
                        value: 0
                    });
                    props.dispatch(addNewLineItemsViewActions.addCorrectionDetails(laborUnitPrice));
             }
        }
        
        props.priceValidationCleanUp();
    }

    /*Updates searched ata code*/
    const onSearchATACodeSelectHandler = (ataCode) => {
        let searchedATACode = Map({
            type: constants.UPDATE_ATA_CODE_TYPE.SEARCHED_ATA_CODE,
            ataCode: ataCode,
            vendorProductCode: props.vendorProductCodeInformation?.vendorProductCode
        });
        props.dispatch(addNewLineItemsViewActions.updateATACode(searchedATACode));

        props.dispatch({ type: constants.ATA_PARAMETERS_SCREEN_LOADED, productCode: ataCode });
    }

    /*Updates selected ata code*/
    const onSelectATACodeHandler = (productCode) => {
        let selectedATACode = Map({
            type: constants.UPDATE_ATA_CODE_TYPE.SELECTED_ATA_CODE,
            productCode: productCode,
            vendorProductCode: props.vendorProductCodeInformation?.vendorProductCode
        });
        props.dispatch(addNewLineItemsViewActions.updateATACode(selectedATACode));

        props.dispatch({ type: constants.ATA_PARAMETERS_SCREEN_LOADED, productCode: productCode });
        
        props.priceValidationCleanUp();
    }

    const resetAtaSearch = () => {
        props.dispatch({ type: constants.CLEAR_FIELDS_AFTER_SAVE_ADD_NEW_LINE_ITEM_DATA });
    }

    const onPriceValidationLinkClick = (ref) => {
        if(ref.current != null){
            ref.current.focus();
        }
    }

    // #endregion  

    // #region Effects
    // Initialize data
    useEffect(() => {
        const displayName = "ATASearchCorrectionsAndCostStep";

        if (props.vehicleDetails.get('id') != undefined && props.complaintCode.get('code') != undefined)
            props.dispatch(addNewLineItemsViewActions.LoadFrequentlyUsedATACode(displayName, props.vehicleDetails.get('id'), props.complaintCode.get('code')));

        //Gets the list of ATA codes along with restricted codes that are mutually exclusive to use with given repair type in the same order
        if(!appState.isAllowMutuallyExclusiveATAsFeatureEnabled)
            props.dispatch(sharedActions.fetchRestrictedATACodes(displayName));
    }, []);

    // Determine if 'Tires' Step is needed and inform parent Wizard
    useEffect(() => {
        setTiresStepNeeded(props.isUsingTireProductcode);

        // let parent know
        props.setTiresStepNeeded(props.isUsingTireProductcode);

    }, [props.isUsingTireProductcode]);

    // Determine if 'Brakes' Step is needed and inform parent Wizard
    useEffect(() => {
        setBrakesStepNeeded(props.brakesInfo.isUsingBrakesProductcode);

        // let parent know
        props.setBrakesStepNeeded(props.brakesInfo.isUsingBrakesProductcode);

    }, [props.brakesInfo.isUsingBrakesProductcode]);

    // Determine if 'LanorRateUpdate' Step is needed and inform parent Wizard
    useEffect(() => {
        // If Flat rate feature is enabled, LaborRateUpdateStep should not be displayed to the user
        if (!appState.isFlatRateFeatureEnabled) {
            const isLaborRateStepNeeded = props.laborRateExceedsLimit &&
                props.isStandardRateUpdateDue &&
                !props.laborRateWindowShownForThisSession;

            setLaborRateUpdateStepNeeded(isLaborRateStepNeeded);

            // let parent know
            props.setLaborRateUpdateStepNeeded(isLaborRateStepNeeded);
        }
    }, [appState.isFlatRateFeatureEnabled, props.laborRateExceedsLimit, props.isStandardRateUpdateDue, props.laborRateWindowShownForThisSession]);
    // #endregion

    // #region International messages  
    const messages = defineMessages({
        add_line_item_to_po: {
            id: 'add_line_item_to_po',
            description: 'Add line item to PO ',
            defaultMessage: 'Add line item to PO '
        },
        parameters_header: {
            id: 'parameters_header',
            description: 'Header for ata parameters ',
            defaultMessage: 'Parameters '
        },
        empty_parameters: {
            id: 'empty_parameters',
            description: 'Description when parameters are empty',
            defaultMessage: 'None at this time'
        },
        add_new_line_item_caption: {
            id: 'add_new_line_item_caption',
            description: 'Add new line item caption',
            defaultMessage: 'Add {complaint_description} items'
        },
        continue_link: {
            id: 'continue_link',
            description: 'Continue',
            defaultMessage: 'Continue'
        },
        return: {
            id: 'return',
            description: 'Return',
            defaultMessage: 'Return'
        },
        items_added: {
            id: 'items_added',
            description: 'items_added',
            defaultMessage: 'Items added to PO:'
        },
        find_part_for_unmapped_prod_code: {
            id: 'find_part_for_unmapped_prod_code',
            description: 'Message to display when Unmapped product code is selected',
            defaultMessage: 'This product code is either incorrect or needs more detail. If correct, please search using the Holman parts search above.'
        },
        price_exceeds_error_message: {
            id: 'price_exceeds_error_message',
            description: 'Message to display when price exceeds final price value',
            defaultMessage: "The highlighted field below has a cost that is too high. Please verify the selected part and correction type, edit the cost, then click confirm. If the cost is correct, please click confirm. The 'Add line item' button will be re-enabled once you confirm your cost."
        },
        price_exceeds_continue_error_message: {
            id: 'price_exceeds_continue_error_message',
            description: 'Message to display when price exceeds final price value',
            defaultMessage: "The highlighted field below has a cost that is too high. Please verify the selected part and correction type, edit the cost, then click confirm. If the cost is correct, please click confirm. The 'Continue' button will be re-enabled once you confirm your cost."
        },
        validation_link_message: {
            id: 'validation_link_message',
            description: 'Confirm {description} Cost',
            defaultMessage: 'Confirm {description} Cost'
        },
        save_disabled_exceeds_tooltip_message: {
            id: 'save_disabled_exceeds_tooltip_message',
            description: 'Tooltip to display when price exceeds and save button is disabled',
            defaultMessage: 'Line cannot be added until you confirm your prices on the highlighted lines.'
        },
        save_disabled_exceeds_continue_tooltip_message: {
            id: 'save_disabled_exceeds_continue_tooltip_message',
            description: 'Tooltip to display when price exceeds and continue button is disabled',
            defaultMessage: 'Continue button will be re-enabled once you confirm prices on highlighted lines.'
        }
    });
    // #endregion

    // #region variables
    const moreStepsAhead = tiresStepNeeded || brakesStepNeeded || laborRateUpdateStepNeeded;

    let productCodeParams = props.ataParametersSorted == undefined ? '' :
        props.ataParametersSorted.map((param) => {
            return <ClientParametersComponent key={param.get('id')}
                description={param.get('description')}
            />
        });

    const addLineItemToPoLink = <Submit
        caption={<FormattedMessage {...messages.add_line_item_to_po} />}
        imagePath="/assets/VMS_33button_add_blue.png"
        onClickHandler={onAddLineItemToPOHandler}
        showSpinner={props.isCreatingLineItems}
        spinnerType="blue" />

    const continueLink = <Submit
        caption={<FormattedMessage {...messages.continue_link} />}
        imagePath="/assets/VMS_33button_add_blue.png"
        onClickHandler={onContinueClickHandler}
        spinnerType="blue" />

    const returnLink = <div className=""
        role="button"
        onClick={() => props.onReturn()}>
        <img src="/assets/VMS_33button_back_blue.png" />
        {' '}
        <FormattedMessage {...messages.return} />
    </div>
    
    //Flag for final price validation
    let priceValidationExceeds = props.priceValidations != null 
        && props.priceValidations.some(x => x != undefined && (x.menupricingexceeds || x.partscostexceeds));
    //Flag for final price validation visibility
    let priceValidationShown = !props.pricesConfirmedList[0] && priceValidationExceeds;
    
    let tooltipMessage = moreStepsAhead ? messages.save_disabled_exceeds_continue_tooltip_message
    : messages.save_disabled_exceeds_tooltip_message;
    
    let validationMessage = moreStepsAhead ? messages.price_exceeds_continue_error_message
    : messages.price_exceeds_error_message;

    //Display Validation message and Parts/PM validation link if either exceeds
    const priceControlValidation = <Alert variant='danger' style={{ marginBottom: "0"}}>
            <FormattedMessage {...validationMessage} />
            <div>
                <ul style={{ textAlign: 'left', textDecoration: 'underline', listStyleType: 'none', paddingTop: "0.5rem" }}>
                    {props.priceValidations != null && !props.pricesConfirmedList[0] && props.priceValidations.find(x => x != undefined).partscostexceeds
                        ? <li><Alert.Link onClick={() => {onPriceValidationLinkClick(partsCheckRef)}}>
                            <FormattedMessage {...messages.validation_link_message} values={{ description: "Parts" }} />
                        </Alert.Link></li>
                        : null}
                    {props.priceValidations != null && !props.pricesConfirmedList[0] && props.priceValidations.find(x => x != undefined).menupricingexceeds
                        ? <li><Alert.Link onClick={() => {onPriceValidationLinkClick(pmCheckRef)}}>
                            <FormattedMessage {...messages.validation_link_message} values={{ description: "PM" }} />
                        </Alert.Link></li>
                        : null}
                </ul>
            </div>
        </Alert>

    const renderSaveDisabledTooltip = (props) =>(
        <Tooltip id='save_disabled_exceeds_tooltip' style={{maxWidth: '220px'}}>
            <FormattedMessage {...tooltipMessage}/>
        </Tooltip>
    )

    //#endregion    

    // #region Render
    return (
        <div onClick={onWindowClickHandler}>
            <div className="add-new-line-items">
                
                <div className='price-alert'>
                    {priceValidationExceeds ? priceControlValidation : null}
                </div>

                <div className='add-new-line-items_step-1-2-3-container'>
                    <div className="row add-new-line-items__caption">
                        <div>{props.isRepairTypeChangeNeeded ? 
                            <FormattedMessage
                                id="repair_type_change_step_header"
                                description="Header for repair type change step"
                                defaultMessage="Confirm job details for this line"
                            />
                            :
                            props.complaintCode.get('id') !== undefined ?
                            <FormattedMessage
                                values={{
                                    complaint_description:
                                        <FormattedMessage
                                            id={props.complaintCode.get('id')}
                                            description={props.complaintCode.get('description')}
                                            defaultMessage={props.complaintCode.get('description')} />
                                }}
                                {...messages.add_new_line_item_caption} />
                            : undefined}
                        </div>
                    </div>

                    {props.itemsAddedToPo != undefined && props.itemsAddedToPo.size > 0 && !props.isRepairTypeChangeNeeded ?
                        <div className="add-new-line-items__recenly-added-ata-codes">
                            <div className="add-new-line-items__recenly-added-ata-codes-header"><FormattedMessage {...messages.items_added} /></div>
                            {
                                props.itemsAddedToPo.map(item => {
                                    return <div>{item.get('productDescription')}</div>;
                                }).valueSeq()}
                        </div>
                        : undefined
                    }
                    <div className="row">
                        <table className="add-new-line-items__details table-responsive">
                            <tbody>
                                <tr >
                                    {(!appState.isProductCodeFeatureEnabled || (appState.isProductCodeFeatureEnabled && props.isUnmappedProductCodeSelected)) && !props.isRepairTypeChangeNeeded ?
                                        <td className="add-new-line-items__search-ata-codes_border">
                                            <StepNumber number={props.stepNumber} top="-35px" />

                                            <table className="add-new-line-items__ata-codes">
                                                <tbody>
                                                    <tr >
                                                        <td >
                                                            <div className="add-new-line-items__search-ata-codes">
                                                                <SearchATACodes
                                                                    ataCodesForSearch={props.ataCodesForSearch}
                                                                    searchedATACode={props.searchedATACode}
                                                                    selectedATACodeDescription={props.selectedATACodeDescription}
                                                                    onSearchATACodeSelect={onSearchATACodeSelectHandler}
                                                                    onUpdateAutoCompleteATACodeDesc={onUpdateAutoCompleteATACodeDescHandler}
                                                                    autoCompleteATACodeDesc={props.autoCompleteATACodeDesc}
                                                                    isfetchingData={props.isFetchiDataForSearchATA}
                                                                    onHideContainer={onHideAtaSearchResultContainerHandler}
                                                                    showAtaSearchResultContainer={!props.hideAtaSearchResultContainer}
                                                                    resetAtaSearch={resetAtaSearch}
                                                                />
                                                            </div>
                                                            <div className="add-new-line-items__frequently-used-ata-codes">
                                                                {
                                                                    !appState.isProductCodeFeatureEnabled && props.frequentlyUsedATACodes != undefined && props.frequentlyUsedATACodes.size > 0 ?
                                                                        <FrequentlyUsedATACodes
                                                                            frequentlyUsedATACodes={props.frequentlyUsedATACodes}
                                                                            onSelectATACode={onSelectATACodeHandler} />
                                                                        : undefined
                                                                }
                                                                {props.isUnmappedProductCodeSelected ? <FormattedMessage {...messages.find_part_for_unmapped_prod_code } />

                                                                    : undefined
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        : undefined}
                                    <td className={classnames('add-new-line-items__correction-types_border',
                                        props.correctionType == 'None' ? 'add-new-line-items__disabled' : undefined)}>
                                            <StepNumber number={( appState.isProductCodeFeatureEnabled && !props.isUnmappedProductCodeSelected ) ? props.stepNumber : props.stepNumber+1 } top="-35px"/>


                                        <CorrectionTypes
                                            correctionTypes={props.correctionTypes}
                                            onSelectCorrectionType={onSelectCorrectionTypeHandler}
                                        />
                                    </td>
                                    <td className={classnames('add-new-line-items__correction-details_border',
                                        props.correctionType == 'None' || props.correctionType == '' ? 'add-new-line-items__disabled' : undefined)}>
                                            <StepNumber number={( appState.isProductCodeFeatureEnabled && !props.isUnmappedProductCodeSelected ) ? props.stepNumber + 1 : props.stepNumber+2 } top="-35px"/>


                                        <CorrectionDetails
                                            correctionType={props.correctionType}
                                            restrictedRepairTypes={props.restrictedRepairTypes}
                                            handleChange={onHandleCorrectionsDetailsChange}
                                            correctionDetailsTotal={props.correctionDetailsTotal}
                                            hideLaborInputFields={props.hideLaborInputFields}
                                            currentLaborRate={zeroIfUndefined(props.newLineItems.get('laborRate'))}
                                            currentLaborHours={zeroIfUndefined(props.newLineItems.get('hours'))}
                                            currentPartsQty={zeroIfUndefined(props.newLineItems.get('qty'))}
                                            currentPartsCost={zeroIfUndefined(props.newLineItems.get('partCost'))}
                                            currentMonths={zeroIfUndefined(props.newLineItems.getIn(['partWarranty', 'months']))}
                                            currentMiles={zeroIfUndefined(props.newLineItems.getIn(['partWarranty', 'miles']))}
                                            currentHours={zeroIfUndefined(props.newLineItems.getIn(['partWarranty', 'hours']))}
                                            partsCostEditable={!(appState.isLockNationalAccountPricingFeatureEnabled && props.selectedProductCodes[0]?.partPricingSource === "national_account_pricing") && !( appState.isHideClientPricingFeatureEnabled && props.selectedProductCodes[0]?.partPricingSource === "client_contract_pricing")}
                                            laborCostEditable={!(appState.isLockNationalAccountPricingFeatureEnabled && props.selectedProductCodes[0]?.laborPricingSource === "national_account_pricing") && !( appState.isHideClientPricingFeatureEnabled && props.selectedProductCodes[0]?.laborPricingSource === "client_contract_pricing")}
                                            partsCostHidden={appState.isHideClientPricingFeatureEnabled && props.selectedProductCodes[0]?.partPricingSource === "client_contract_pricing"}
                                            laborCostHidden={appState.isHideClientPricingFeatureEnabled && props.selectedProductCodes[0]?.laborPricingSource === "client_contract_pricing"}
                                            QtyShouldBeWholeNumber={appState.isPreventFractionalQtyFeatureEnabled}
                                            isFlatRateFeatureEnabled={appState.isFlatRateFeatureEnabled}
                                            isQtySyncFeatureEnabled={appState.isQtySyncFeatureEnabled}
                                            priceValidations={props.priceValidations}
                                            pmCheckRef={pmCheckRef}
                                            partsCheckRef={partsCheckRef}
                                            handlePriceValdationConfirmationButtonClick={props.handlePriceValdationConfirmationButtonClick}
                                            pricesConfirmedList={props.pricesConfirmedList}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className={classnames('col-md-5 submit add-new-line-items__return')}>
                        {
                            props.showReturnLink ? returnLink : undefined
                        }
                    </div>

                    <div className={classnames('col-md-7 add-new-line-items__save',
                        !props.enableAddLineItemToPo || props.isCreatingLineItems ? 'add-new-line-items__save__disabled' : undefined,
                        priceValidationShown ? 'add-new-line-items__save__exceeds_disabled' : null,)}>
                        <OverlayTrigger
                        placement='right'
                        trigger={priceValidationShown ? ['hover', 'focus'] : null}
                        overlay={renderSaveDisabledTooltip()}>
                            <div>
                            {
                                moreStepsAhead ? continueLink : addLineItemToPoLink
                            }
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
                <div className="row add-new-line-parameters">
                    <div className="add-new-line-parameters__header">
                        <FormattedMessage {...messages.parameters_header} />
                    </div>
                    <div>
                        {productCodeParams.size === 0 ?
                            <p className="add-new-line-empty__description">
                                <FormattedMessage {...messages.empty_parameters} />
                            </p>
                            :
                            <div> {productCodeParams} </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
// #endregion

function mapToStateProps(state, props) {
    return {
        ...AddNewLineItemsSelector(state, props),
        addNewLineItemsErrorMessage: state.appState.getIn(['uiData', 'addNewLineItemsView', 'errorMessage']),
        /* flat rate feature related data */
        selectedProductCodes: emptyMapIfUndefined(state.appState.getIn(['serverData', 'addNewLineItemsView', 'searchedAtaCodes'])),

        
    }
}
export default connect(mapToStateProps)(injectIntl(ATASearchCorrectionsAndCostStep));