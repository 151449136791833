import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';
import {emptyMapIfUndefined, emptyArrayIfUndefined} from 'utils/HelperFunctions';

export function uiDataMyUnitsView(state = initialAppState.getIn(['uiData', 'vendorMyUnits']), action) {
    switch (action.type) {
       case constants.SHOW_DUE_SERVICES:
            return state.set('showWindow', !state.get('showWindow'));
        case constants.MY_UNITS_SCREEN_UNLOADED:
            return state.set('showWindow',false);
        case constants.CLEAR_MY_UNITS:
            return state.set('myUnits', []);
        case constants.CLEAR_MY_OTHER_UNITS:
            return state.set('myOtherUnits', []);
        case constants.CURRENT_VIN_ID:
            return state.set('currentVin8',action.vin8);
        case constants.CURRENT_VEHICLE_ID:
            return state.set('currentVehicleId',action.vehicleId);
        case constants.MY_UNITS_REQUESTED:
            return state.set('isFetchingDataUnits', true).set('isDirty', false);
        case constants.MY_UNITS_REJECTED:
            return state.set('isFetchingDataUnits', false);
        case constants.MY_UNITS_RESOLVED:
            let hasMoreUnits = action.vendorUnitInfo == undefined || action.vendorUnitInfo.length == 0 ? false : true;
            var unitsArray = emptyArrayIfUndefined(state.getIn(['myUnits']));
            var moreUnitsArray= unitsArray.concat(action.vendorUnitInfo);
            return state.set('isFetchingDataUnits', false)
                .set('myUnits',moreUnitsArray)
                .set('nextUnitsExist',hasMoreUnits).set('showWindow',false).set('currentVin8','').set('currentVehicleId','');
        case constants.MY_OTHER_UNITS_REQUESTED:
            return state.set('isFetchingDataOtherUnits', true).set('isDirty', false);
        case constants.MY_OTHER_UNITS_REJECTED:
            return state.set('isFetchingDataOtherUnits', false);
        case constants.MY_OTHER_UNITS_RESOLVED:
            let hasMoreOtherUnits = action.vendorUnitInfo == undefined || action.vendorUnitInfo.length == 0 ? false : true;
            var otherUnitsArray = emptyArrayIfUndefined(state.getIn(['myOtherUnits']));
            var moreOtherUnitsArray= otherUnitsArray.concat(action.vendorUnitInfo);
            return state.set('isFetchingDataOtherUnits', false)
                .set('myOtherUnits',moreOtherUnitsArray)
                .set('nextOtherUnitsExist',hasMoreOtherUnits).set('showWindow',false).set('currentVin8','').set('currentVehicleId','');
        case constants.MY_OTHER_UNITS_SCREEN_UNLOADED:
            return state.set('showWindow',false);
        case "FETCH_VEH_DATA_FOR_MY_UNITS_LOOKUP_RESOLVED":
            return state.set('vehicleDetails', fromJS(action.vehicleDetails));
        default:
            return state;
    }
}