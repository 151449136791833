import { createSelector } from 'reselect';
import { Map } from 'immutable';
import {emptyMapIfUndefined,falseIfUndefined, emptyStringIfUndefined} from 'utils/HelperFunctions';
import * as constants from 'constants/App';
import moment from 'moment';

//Returns list of ata codes already in the PO
const lineItemsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'lineItems']));

const isfetchingDataSelector = state => falseIfUndefined(state.appState.getIn(['uiData', 'complaintView', 'isFetchingData']));
const isGotoNextStepRequestedSelector = state => falseIfUndefined(state.appState.getIn(['uiData', 'complaintView', 'isGotoNextStepRequested']));

const isDiagnosisSelectedSelector = state=>state.appState.getIn(['uiData', 'complaintView', 'diagnosisSelected']);

export const diagnosisDetailsSelector = state=>  state.appState.getIn(['uiData', 'complaintView', 'diagnosisItem']);

const hourlyLaborRateSelector = state =>state.appState.getIn(['serverData', 'shared', 'vendorVehicleParameters', 'hourlyLaborRate']);


/*Vehicle details with updated odometer and updated engine hours */
export const vehicleDetailsSelector = createSelector(
    state => state.appState.getIn(['serverData', 'shared', 'vehicleDetails']),
    state => state.appState.getIn(['uiData', 'odometerView', 'newOdometer']),
    state => state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'Mileage']),
    state => state.appState.getIn(['uiData', 'odometerView', 'newEngineHours']),
    state => state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'EngineHours']),
    (vehicleDetails, newOdometer, recentOdometer, newEngineHours, recentEngineHours)=>
        vehicleDetails.setIn(['Mileage'], isNaN(newOdometer) ? isNaN(recentOdometer) ? '' : Number(recentOdometer) : Number(newOdometer))
            .setIn(['EngineHours'], isNaN(newEngineHours) ? isNaN(recentEngineHours) ? '' : Number(recentEngineHours) : Number(newEngineHours))
);

export const complaintsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints']));
const complaintTypesSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'complaintTypes']));

const canAddAnotherComplaintSelector = (state)=> {
    // If no open complaints and none were skipped, allow user to add another complaint.
    // Basically this will limit user to only adding 1 new complaint at a time.
    return emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints']))
                .count(complaint=>complaint.get('isComplaintOpen') || complaint.get('wasComplaintSkipped')) == 0
        && emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints'])).count() < 15;
};

const serviceSelectedSelector = state => state.appState.getIn(['uiData', 'complaintView', 'servicesSelected']);

// The 'isSelected' flag is computed for each service by inspecting 'selectedServices'.
// The 'selectedServices' are available in 'this', as they are passed in as 'context' by the selector
// See documentation of Immutable.js Map.reduce() function for more details on how this works.
function servicesDataTransformer(serviceList, service) {
    let isSelected = this.filter(s => s === service.get('serviceId')).count() == 1;
    return serviceList.setIn([service.get('serviceId')], service.setIn(['isSelected'], isSelected));
}

const showWindowSelector = state => state.appState.getIn(['uiData', 'addServiceLineItemsView', 'showWindow']);

const purchaseOrderExistsSelector=state=>state.appState.getIn(['uiData','addEdit','purchaseOrder'])==undefined || state.appState.getIn(['uiData','addEdit','purchaseOrder','id'])==undefined?false:true;

/*Get service details*/
function getServiceInfo(serviceRecord, vehicleInfo) {
    let overDueBy = '';
    let scheduled = '';
    let status = '';

    let dueBy = emptyMapIfUndefined(serviceRecord).get('dueBy') || '';

    switch (dueBy.toUpperCase()) {
    case 'METER':
        overDueBy = (serviceRecord.get('dueOdometer') - (vehicleInfo.get('estimatedOdometer') || 0)).toLocaleString() + ' miles';
        scheduled = serviceRecord.get('dueOdometer').toLocaleString();
        status = serviceRecord.get('dueStatus');
        break;
    case 'DAYS':
        let dueDate = Date.parse(serviceRecord.get('dueDate'));
        if (!isNaN(dueDate)) {
            overDueBy = (moment(serviceRecord.get('dueDate')).diff(new Date(), 'days')).toLocaleString() + ' days';
            scheduled = moment(serviceRecord.get('dueDate')).format('MM/DD/YYYY');
            status = serviceRecord.get('dueStatus');
        }
        break;
    case 'HOURS':
        overDueBy = (serviceRecord.get('dueHourMeter') - (vehicleInfo.get('estimatedHourMeter') || 0)).toLocaleString() + ' hours';
        scheduled = serviceRecord.get('dueHourMeter').toLocaleString();
        status = serviceRecord.get('dueStatus');
        break;
    default:
        break;
    }
    return {
        overDueBy: overDueBy,
        scheduled: scheduled,
        status: status
    };
}

/*Checks mandatory fields of the service obbject*/
function validateServiceObject(service) {
    if (service == undefined)
        return false;
    if (service.get('id') == undefined || service.get('id') == '' || service.get('id') == null)
        return false;
    if (service.get('serviceName') == undefined || service.get('serviceName') == '' || service.get('serviceName') == null)
        return false;
    if (service.get('dueBy') == undefined || service.get('dueBy') == '' || service.get('dueBy') == null)
        return false;
    return true;
}


/*Selector to transform the state data to the component data*/
const vehicleServicesSelector = createSelector(
    state => state.appState.getIn(['serverData', 'complaintView', 'maintenanceServices']),
    state => state.appState.getIn(['serverData', 'complaintView', 'regulatoryServices']),
    state => state.appState.getIn(['serverData', 'shared', 'vehicleDetails']),
    (maintenanceServices, regulatoryServices, vehicleDetails) => {
        let vehMaintenanceServices = Map();
        let vehRegulatoryServices = Map();
        let servicesFlatStructure = Map();
        let servicesFiltered = Map();

        if (maintenanceServices != undefined) {
            servicesFlatStructure = maintenanceServices.filter(x=>x.get('ataCodes').get(0).get('code')!=null)
            .reduce((p,c)=>{
                return p.set(c.get('id'), Map({
                    id:c.get('id'),
                    dueDate: c.get('dueDate'), 
                    productCode: c.get('ataCodes').get(0).get('code')
                   
                }));      
            }, Map());
        }

        if(servicesFlatStructure!=undefined ){
            servicesFiltered =  servicesFlatStructure.reduce((p, c) => {
                let dueATACodeWithEarlierDueDate = p.filter(x=>x.get('productCode')==c.get('productCode') && c.get('dueDate') <= x.get('dueDate'));
                
                if(dueATACodeWithEarlierDueDate!=undefined && dueATACodeWithEarlierDueDate.size>0){
                    return p.setIn([c.get('productCode')], c);
                }
                else{
                    return p.set(c.get('productCode'), c);    
                }
            }, Map());
        }

        //get PM services
        if (maintenanceServices != undefined)

            vehMaintenanceServices = maintenanceServices.reduce((services, current) => {

                if (!validateServiceObject(current))
                    return services;                

                let filteredItems = servicesFiltered.filter(x=>x.get('id')==current.get('id'));

                if(filteredItems==undefined || filteredItems.size == 0)
                    return services; 

                let serviceInfo = getServiceInfo(current, vehicleDetails);

                if ( serviceInfo.status == constants.SERVICE_STATUS.APPROACHING || serviceInfo.status==constants.SERVICE_STATUS.OVERDUE || serviceInfo.status == constants.SERVICE_STATUS.DUE )
                    return services.set(current.get('id'), Map({
                        serviceId: current.get('id'),
                        serviceName: current.get('serviceName'),
                        serviceSchedule: serviceInfo.scheduled,
                        serviceOverDueBy: serviceInfo.overDueBy,
                        serviceType: constants.SERVICE_TYPE.PM,
                        serviceStatus: serviceInfo.status,
                        ataCodes: current.get('ataCodes')
                    }));
                else
                    return services; 

            }, Map());

     

        if (regulatoryServices != undefined)
        //get regulatory services
            vehRegulatoryServices = regulatoryServices.reduce((services, current) => {
                if (!validateServiceObject(current))
                    return services;
                var serviceInfo = getServiceInfo(current, vehicleDetails);                
                if ( serviceInfo.status == constants.SERVICE_STATUS.APPROACHING || serviceInfo.status==constants.SERVICE_STATUS.OVERDUE || serviceInfo.status==constants.SERVICE_STATUS.DUE )
                    return services.set(current.get('id'), Map({
                        serviceId: current.get('id'),
                        serviceName: current.get('serviceName'),
                        serviceSchedule: serviceInfo.scheduled,
                        serviceOverDueBy: serviceInfo.overDueBy,
                        serviceType: constants.SERVICE_TYPE.INSPECTION,
                        serviceStatus: serviceInfo.status,
                        ataCodes: current.get('ataCodes')
                    }));
                else
                     return services; 

            }, Map());

        //don't push undefined obejcts to the app state
        if (vehMaintenanceServices == undefined) vehMaintenanceServices = Map();
        if (vehRegulatoryServices == undefined) vehRegulatoryServices = Map();



        return new Map([...vehMaintenanceServices, ...vehRegulatoryServices]);
    }
);

// Selector returns a list of services with a derived 'isSelected' flag
const servicesSelector = createSelector(
    vehicleServicesSelector,
    serviceSelectedSelector,
    (services, serviceSelected) =>
        services.reduce(servicesDataTransformer, Map(), serviceSelected)
);

const showErrorMessagesSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'complaintView', 'showErrorMessages']));

const skippingComplaintForComplaintKeySelector = state => emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'skippingComplaintForComplaintKey'])); 
const creatingOrEditingComplaintForComplaintKeySelector = state => emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'creatingOrEditingComplaintForComplaintKey']));

const ComplaintView = createSelector(
    isfetchingDataSelector,
    vehicleDetailsSelector,
    complaintsSelector,
    complaintTypesSelector,
    canAddAnotherComplaintSelector,
    servicesSelector,
    showWindowSelector,
    diagnosisDetailsSelector,
    lineItemsSelector,
    isDiagnosisSelectedSelector,
    hourlyLaborRateSelector,
    showErrorMessagesSelector,
    skippingComplaintForComplaintKeySelector,
    creatingOrEditingComplaintForComplaintKeySelector,
    isGotoNextStepRequestedSelector,
    purchaseOrderExistsSelector,
    (isfetchingData, vehicleDetails, complaints, complaintTypes, canAddAnotherComplaint, services, showAddServiceLineItemsWindow,
     diagnosisDetails, lineItems, isDiagnosisSelected, hourlyLaborRate, showErrorMessages, skippingComplaintForComplaintKey, creatingOrEditingComplaintForComplaintKey,isGotoNextStepRequested,
    purchaseOrderExists) => {
        return {
            isfetchingData,
            vehicleDetails,
            complaints,
            complaintTypes,
            canAddAnotherComplaint,
            services,
            showAddServiceLineItemsWindow,
            diagnosisDetails,
            lineItems,
            isDiagnosisSelected,
            hourlyLaborRate,
            showErrorMessages,
            skippingComplaintForComplaintKey, 
            creatingOrEditingComplaintForComplaintKey,
            isGotoNextStepRequested,
            purchaseOrderExists
        };
    }
);

export default ComplaintView;