import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';

export function uiDataVendorEnrollmentRequestView(state = initialAppState.getIn(['uiData', 'vendorEnrollmentRequestView']), action) {
    switch (action.type) {
        case constants.RESET_REQUEST_SUBMITTED_FLAG:
            return state.set('requestSubmitted', false);
        case constants.SUBMIT_VENDOR_ENROLLMENT_REQUEST_REQUESTED:
            return state.set('isSubmitting', true)
                .set('errorMessage', []);
        case constants.SUBMIT_VENDOR_ENROLLMENT_REQUEST_RESOLVED:
            return state.set('isSubmitting', false)
                .set('requestSubmitted', true);
        case constants.SUBMIT_VENDOR_ENROLLMENT_REQUEST_REJECTED:
            return state.set('isSubmitting', false)
                .set('errorMessage', action.errorMessage);
        case constants.RESET_ENROLLMENT_REQUEST_INFO:
            return state.set('serviceResponse', { id: undefined, referenceNumber: undefined })
                .set('enrollmentRequestInfo', Map({
                    country:'USA'
                }))
                .set('isDirty', false);    
        case constants.UPDATE_ENROLLMENT_REQUEST_INFO:
            let fieldName = action.requestInfo.name;
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.BUSINESS_NAME)
                return state.setIn(['enrollmentRequestInfo', 'businessName'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.ADDRESS_1)
                return state.setIn(['enrollmentRequestInfo', 'address1'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.ADDRESS_2)
                return state.setIn(['enrollmentRequestInfo', 'address2'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.CITY)
                return state.setIn(['enrollmentRequestInfo', 'city'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.STATE)
                return state.setIn(['enrollmentRequestInfo', 'state'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.ZIP)
                return state.setIn(['enrollmentRequestInfo', 'zip'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.COUNTRY)
                return state.setIn(['enrollmentRequestInfo', 'country'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.CONTACT_NAME)
                return state.setIn(['enrollmentRequestInfo', 'contactName'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.PHONE_AREA)
                return state.setIn(['enrollmentRequestInfo', 'phoneArea'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.PHONE_PREFIX)
                return state.setIn(['enrollmentRequestInfo', 'phonePrefix'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.PHONE_SUFFIX)
                return state.setIn(['enrollmentRequestInfo', 'phoneSuffix'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.EXTENSION)
                return state.setIn(['enrollmentRequestInfo', 'extension'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.EMAIL)
                return state.setIn(['enrollmentRequestInfo', 'email'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.RE_ENTER_EMAIL)
                return state.setIn(['enrollmentRequestInfo', 'reEnterEmail'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.FAX_AREA)
                return state.setIn(['enrollmentRequestInfo', 'faxArea'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.FAX_PREFIX)
                return state.setIn(['enrollmentRequestInfo', 'faxPrefix'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.FAX_SUFFIX)
                return state.setIn(['enrollmentRequestInfo', 'faxSuffix'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.REFERRAL_CODE)
                return state.setIn(['enrollmentRequestInfo', 'referralCode'], action.requestInfo.value.trim());
            if (fieldName == constants.VENDOR_ENROLLMENT_REQUEST_FIELD_NAME.RECAPTCHA_VERIFICATION_TOKEN)
                return state.setIn(['enrollmentRequestInfo', 'reCaptchaVerificationToken'], action.requestInfo.value);
            else
                return state;
        case constants.UPDATE_ENROLLMENT_STEP:
            return state.set('enrollmentStep', action.enrollmentStep);
        case constants.ENROLLMENT_REQUEST_FORM_IS_DIRTY:
            return state.set('isDirty', action.isDirty);    
        default:
            return state;
    }
}