export const interval = {
  annual: 'ANNUAL',
  quadrennial: 'QUADRENNIAL',
  other: 'OTHER'
};

export const hoistSystemBrakesType = {
  hydraulic: 'HYDRAULIC',
  air: 'AIR'
};

export const boomJibBrakesType = {
  hydraulic: 'HYDRAULIC',
  air: 'AIR'
};

export const componentResultCode = {
  PASS: 'PASS',
  FAIL: 'FAIL',
  NA: 'NA'
};