import React from 'react';
import { defineMessages } from 'react-intl';
import { Navbar } from 'react-bootstrap';
import './ARIUserNavigationBar.css';


const ARIUserNavigationBar = () => {     
  const messages = defineMessages({
    sign_out_link: {
      id: 'sign_out',
      description: 'Text for Sign Out Link in header',
      defaultMessage: 'Sign Out'
    }
  });

  return (
    <div className='navigation-bar'>
      <Navbar>
        <Navbar.Brand>
          <div 
            css={`
              padding-left: 2rem;
              padding-bottom: 5rem;
            `}
          >
            <img 
              className="navbar-brand navigation-bar__logo" 
              src="/assets/Logo-Primary-PMS.png"
              width={350}            
            />  
          </div>
          <Navbar.Toggle />
        </Navbar.Brand>
      </Navbar>
    </div>  
  )
}

export default ARIUserNavigationBar;