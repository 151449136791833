// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
// Our stuff
import './Footer.css';

const Footer = (props) => {
    const messages = defineMessages({
            goto_next_link_caption: {
                id: 'goto_next_link_caption',
                description: 'Go to next step',
                defaultMessage: 'Go to next step'
            }
        });

    return <div className='b__e--m'>
        
        <Row>
            <Col sm={{ span: 4, offset: 8}} className='driver_validation_complaints__next_step_wrapper'>
                <div className='driver_validation_complaints__next_step' 
                        role='button'
                        onClick={props.onGoToNextStep}>
                    <FormattedMessage {...messages.goto_next_link_caption}/>                    
                    {' '}<img src="/assets/VMS_33button_go_bblue.png"/>
                </div>
            </Col>
        </Row>

    </div>
}

export default Footer;