import Immutable from 'immutable';
import { hasTokenExpired } from './HelperFunctions';

function fromJSGreedy(js) {
    return typeof js !== 'object' || js === null ? js :
        Array.isArray(js) ?
            Immutable.Seq(js).map(fromJSGreedy).toList() :
            Immutable.Seq(js).map(fromJSGreedy).toMap();
}

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }

        var deserializedState = JSON.parse(serializedState);
        var state = {
            appState: fromJSGreedy(deserializedState.appState),
            routing: fromJSGreedy(deserializedState.routing)
        };
        var token = state.appState.getIn(['serverData', 'shared', 'token']);
        var personaToken=state.appState.getIn(['serverData', 'shared', 'personaToken']);
        //if token has not expired, return state saved in local storage
        if ((token!=undefined && hasTokenExpired(token)) || (personaToken!=undefined && hasTokenExpired(personaToken))) {
            return undefined;
        }
        else {
            return state;
        }
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify({
            appState: state.appState,
            routing: state.routing
        });
        localStorage.setItem('state', serializedState);
    } catch (err) {
        // Ignore write errors.
    }
}

export const clearState = () => {
    try {
        localStorage.removeItem('state');
    } catch (err) {
        // Ignore write errors.
    }
}