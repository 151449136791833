import React from 'react';
import { connect } from 'react-redux';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { renderError } from 'utils/HelperFunctions';
import { defineMessages, FormattedMessage } from 'react-intl';
import './EnrollmentConfirmation.css';
import { Link } from 'react-router-dom';

class EnrollmentConfirmation extends React.Component {

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    confirmationHeader() {
        const messages = defineMessages({
            thank_you: {
                id: 'thank_you',
                description: 'Thank you',
                defaultMessage: 'Thank you'
            },
            return: {
                id: 'return:',
                description: 'Return to main page',
                defaultMessage: 'Return to main page'
            },
        })

        return <FormattedMessage {...messages.thank_you } />
    }
    confirmationMessage() {
        const messages = defineMessages({
            confirmation_for_request_submission: {
                id: 'confirmation_for_request_submission',
                description: 'Confirmation For Request Submission',
                defaultMessage: 'Thank you for taking the time to contact Holman. We are not accepting new vendor applications at this time.' +
                ' Thank you for filling out your information. Your facility will be placed on our vendor waiting list if a need arises for' +
                ' a repair facility in your area.'
            },
        })

        return <FormattedMessage {...messages.confirmation_for_request_submission } />
    }

    render() {
        const messages = defineMessages({
            return: {
                id: 'return',
                description: 'Return',
                defaultMessage: 'Return'
            },
        })
        return (
            <div className="enrollment-confirmation">
                <div className='enrollment-confirmation__header'>{this.confirmationHeader()}</div>
                <div className='enrollment-confirmation__message'>{this.confirmationMessage()}</div>
                    <div className="enrollment-confirmation__return"
                         role="button"
                         onClick={this.onReturnClickHandler.bind(this)}>
                        <img src="/assets/VMS_33button_back_blue.png" />
                        {' '}
                        <FormattedMessage {...messages.return} />
                    </div>
            </div>
        );
    }
};
export default EnrollmentConfirmation;