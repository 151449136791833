import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import './EditVendorAccountInformation.css'
import {Popover, Overlay} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';

class EditVendorAccountInformation extends React.Component {

    onHide() {
        this.props.dispatch({type: constants.CLEAR_ACCOUNT_INFORMATION_ERROR});

    }

    onSaveVendorBankHandler(){

        this.props.onSaveBankingAccount(this.refs.txtVendorRouting.value,this.refs.txtVendorChecking.value,this.refs.txtVendorChecking2.value)

    }

    render() {
        const messages = defineMessages({
            edit_routing_caption: {
                id: 'edit_routing_caption',
                description: 'routing and transit number caption',
                defaultMessage: 'Enter 9-digit Routing and Transit Number:'
            },            
            edit_routing_caption_canada: {
                id: 'edit_routing_caption_canada',
                description: 'routing and transit number caption',
                defaultMessage: 'Enter Institute ID followed by Transit ID:'
            },

            edit_checking_caption: {
                id: 'edit_checking_caption',
                description: 'checking number caption',
                defaultMessage: 'Enter Checking Account Number: <br/> (Do not include check number)'
            },
            re_enter_caption: {
                id: 're_enter_caption',
                description: 're-enter checking number caption',
                defaultMessage: 'Re-Enter Checking Account Number:'
            },
            cancel__edit_caption: {
                id: 'cancel__edit_caption',
                description: 'cancel caption',
                defaultMessage: 'Cancel'
            },
            save_account_caption: {
                id: 'save_account_caption',
                description: 'save account caption',
                defaultMessage: 'Save changes to bank account information'
            }
        });
        const tabIndexGroup = this.props.tabIndexStartFrom;
        return <div>
            <div className="edit_account-component__view4 row">
                <div className="col-md-7 edit_account-component__title">
                   {
                       this.props.country=='CAN'?<FormattedMessage {...messages.edit_routing_caption_canada}/>
                       :
                       <FormattedMessage {...messages.edit_routing_caption}/>
    }
                </div>
                <div className="col-md-5 edit_account-component__text">
                    <Overlay
                        show={this.props.popOverDisplayShowRoutingNumber}
                        onHide={this.onHide.bind(this)}
                        placement="right"
                        target={props => ReactDOM.findDOMNode(this.refs.txtVendorRouting)}

                    >
                        <Popover id="RoutingNumberErrorMessage"
                                 className="edit_account-component__errorMessage">
                            {this.props.routingNumberError}
                        </Popover>

                    </Overlay>
                    <input ref="txtVendorRouting" className="edit_account-component__input" 
                    type="text" onKeyPress={helperFunctions.isNumberKey.bind(this)} tabIndex={tabIndexGroup+1} maxLength={this.props.country=='CAN'?"8":"9"}                    
                           onBlur={this.props.onBlurChange.bind(this)} />
                </div>
            </div>
            <div className="edit_account-component__view row">
                <div className="col-md-7 edit_account-component__title">
                    <FormattedMessage {...messages.edit_checking_caption}  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                </div>
                <div className="col-md-5 edit_account-component__text">
                    <Overlay
                        show={this.props.popOverDisplayShowChecking1}
                        onHide={this.onHide.bind(this)}
                        placement="right"
                        target={props => ReactDOM.findDOMNode(this.refs.txtVendorChecking)}

                    >
                        <Popover id="Checking1ErrorMessage"
                                 className="edit_account-component__errorMessage">
                            {this.props.checking1Error}
                        </Popover>

                    </Overlay>
                    <input ref="txtVendorChecking" className="edit_account-component__input" 
                        type="password" onKeyPress={helperFunctions.isNumberKey.bind(this)} tabIndex={tabIndexGroup+2} maxLength="17"
                           onBlur={this.props.onBlurChange.bind(this)} />
                </div>
            </div>
            <div className="edit_account-component__view3 row">
                <div className="col-md-7 edit_account-component__title">
                    <FormattedMessage {...messages.re_enter_caption}/>
                </div>
                <div className="col-md-5 edit_account-component__text">
                    <Overlay
                        show={this.props.popOverDisplayShowChecking2}
                        onHide={this.onHide.bind(this)}
                        placement="right"
                        target={props => ReactDOM.findDOMNode(this.refs.txtVendorChecking2)}

                    >
                        <Popover id="Checking2ErrorMessage"
                                 className="edit_account-component__errorMessage">
                            {this.props.checking2Error}
                        </Popover>

                    </Overlay>
                    <input ref="txtVendorChecking2" 
                    className="edit_account-component__input" type="password" tabIndex={tabIndexGroup+3} maxLength={'17'} 
                           onBlur={this.props.onBlurChange.bind(this)} onKeyPress={helperFunctions.isNumberKey.bind(this)} />
                </div>
            </div>
            <div className="edit_account-component__view2 row">
                <div className="col-md-5">

                    <a className="edit_account-component__caption"
                       onClick={this.props.onCancelAccountClick.bind(this)}
                       tabIndex={tabIndexGroup+5}
                       >
                        <FormattedMessage {...messages.cancel__edit_caption}/>{' '}
                        <img src="/assets/VMS_28button_delete_blue.png"/>
                    </a>


                </div>
                <div className="col-md-7 edit_account-component__save-padding">
                    <div className="edit_account-component__caption">
                        <a onClick={this.onSaveVendorBankHandler.bind(this)}
                            tabIndex={tabIndexGroup+4}>
                            <FormattedMessage {...messages.save_account_caption}/>{' '}
                            <img src="/assets/VMS_28button_edit_clear.png"/>
                        </a>

                    </div>
                </div>

            </div>
        </div>;
    }
}

export default (EditVendorAccountInformation);
