import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import './ContactCustomerDirectlyForApprovalBanner.css';


const ContactCustomerDirectlyForApprovalBanner = () => {
    const messages = defineMessages({
        contact_customer_directly: {
            id: 'contact_customer_directly',
            description: 'This PO is for a Holman Small Business Solutions client. For this transaction, you will be dealing directly with the client rather than Holman. If you have any questions about this PO, please call the client directly at the number provided once the PO is created.',
            defaultMessage: 'This PO is for a Holman Small Business Solutions client. For this transaction, you will be dealing directly with the client rather than Holman. If you have any questions about this PO, please call the client directly at the number provided once the PO is created.'
        }
    });

    return <div className='contact_customer_directly_for_approval_banner'>
            <div className='contact_customer_directly_for_approval_banner_text'>
                <FormattedMessage {...messages.contact_customer_directly} />
            </div>
        </div>
}



export default ContactCustomerDirectlyForApprovalBanner;