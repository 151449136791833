import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage,RawIntlProvider,injectIntl} from 'react-intl';
import { validatePasswordPolicy } from 'utils/HelperFunctions';
import {Map} from 'immutable';
import './Register.css';
import * as constants from 'constants/App';
import { Popover, Overlay } from 'react-bootstrap';
import Submit from 'components/Submit';
import ReactDOM from 'react-dom';
import PasswordPolicy from 'shared/components/PasswordPolicy';

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formValidated: false,   //whether form is validated or not
            currentFocusField: []   //array of fields that have recieved focus event
        }
    }

    onSubmitHandler(){
        this.setState({ formValidated: true, currentFocusField: [] });
        if (this.props.isRequestFormValid.isFormValid) {
            this.props.onSubmit();
        }
    }

    isFieldValid(fieldName) {
        return (!this.state.currentFocusField.includes(fieldName) && this.state.formValidated
        && this.props.isRequestFormValid.inValidFields.includes(fieldName));
    }

    onFieldFocus(fieldName) {
        let updatedFocusFields = this.state.currentFocusField.concat(fieldName);
        this.setState({ currentFocusField: updatedFocusFields });
    }

    renderValidationOverlay(fieldName, message) {
        return <Overlay
            show={this.isFieldValid(fieldName)}
            target={() => ReactDOM.findDOMNode(this.refs[fieldName])}
            placement="right">
            <Popover id={"popOver" + fieldName} className="linked-accounts__error_message">
            <RawIntlProvider value={this.props.intl}>
                <FormattedMessage {...message} />
                </RawIntlProvider>
            </Popover>
        </Overlay>
    }

    render() {
        const messages = defineMessages({
            register_header: {
                id: 'register_header',
                description: 'register linked vendor',
                defaultMessage: 'Registration'
            },
            contact_header: {
                id: 'contact_header',
                description: 'contact',
                defaultMessage: 'Contact Name'
            },
            email_header: {
                id: 'email_header',
                description: 'email',
                defaultMessage: 'Email Recipient'
            },
            new_password_header: {
                id: 'new_password_header',
                description: 'new password',
                defaultMessage: 'New Password'
            },
            confirm_password_header: {
                id: 'confirm_password_header',
                description: 'confirm password',
                defaultMessage: 'Confirm New Password'
            },
            submit: {
                id: 'submit',
                description: 'Submit',
                defaultMessage: 'Submit'
            },
        });
        const errorMessages = defineMessages({
            new_password_required: {
                id: 'new_password_required',
                description: 'Please enter Address 2',
                defaultMessage: 'Please enter new password'
            },
            contact_name_required: {
                id: 'contact_name_required',
                description: 'Please enter Contact name',
                defaultMessage: 'Please enter Contact name'
            },
            email_required: {
                id: 'email_required',
                description: 'Please enter an email address (example: email@domain.com)',
                defaultMessage: 'Please enter an email address (example: email@domain.com)'
            },
            re_enter_password_required: {
                id: 're_enter_password_required',
                description: 'Please re-enter correct password',
                defaultMessage: 'Please re-enter correct password'
            },
            password_field_required: {
                id: 'password_field_required',
                description: 'Please enter valid password',
                defaultMessage: 'Password must meet complexity criteria below'
            }
        });

        return <div>
            <div className='linked_register__header' >
                <FormattedMessage {...messages.register_header} />
            </div>
            <div className='linked_register__register'>
                <div className='linked_register__input-group'>
                    <label ><FormattedMessage {...messages.contact_header } /></label>
                    <input type="text"
                           ref='contactName'
                           className='linked_register__block linked_register__input-contact'
                           onChange={this.props.onRequestInfoChange.bind(this, "LINKED_VENDOR_ENROLLMENT_CONTACT_NAME")}
                           onFocus={this.onFieldFocus.bind(this, 'contactName')}
                    />
                    {this.renderValidationOverlay('contactName', errorMessages.contact_name_required)}
                </div>

                <div className='linked_register__input-group' >
                    <label  ><FormattedMessage {...messages.email_header } /></label>
                    <input type="text"
                           ref='emailAddress'
                           className='linked_register__block linked_register__input-email'
                           onChange={this.props.onRequestInfoChange.bind(this, "LINKED_VENDOR_ENROLLMENT_EMAIL_ADDRESS")}
                           onFocus={this.onFieldFocus.bind(this, 'emailAddress')}
                    />
                    {this.renderValidationOverlay('emailAddress', errorMessages.email_required)}

                </div>
                <div className='linked_register__input-group'>
                    <label ><FormattedMessage {...messages.new_password_header } /></label>
                    <input type="password" ref='newPassword' className='linked_register__block linked_register__input'
                           onChange={this.props.onRequestInfoChange.bind(this, "LINKED_VENDOR_ENROLLMENT_NEW_PASSWORD")}
                           onFocus={this.onFieldFocus.bind(this, 'newPassword')} />
                    {this.renderValidationOverlay('newPassword', errorMessages.password_field_required)}
                    
                </div>
                <div>
                    <div className='linked_register__input-group'>
                        <label ><FormattedMessage {...messages.confirm_password_header } /></label>
                        <input type="password" ref='confirmPassword' className='linked_register__block linked_register__input'
                               onChange={this.props.onRequestInfoChange.bind(this, "LINKED_VENDOR_ENROLLMENT_CONFIRM_PASSWORD")}
                               onFocus={this.onFieldFocus.bind(this, 'confirmPassword')} />
                        <Overlay
                            show={this.isFieldValid('confirmPassword')}
                            target={() => ReactDOM.findDOMNode(this.refs.confirmPassword)}
                            placement="right">
                            <Popover id={"popOverConfirmPassword"} className="linked-accounts__error_message">
                            <RawIntlProvider value={this.props.intl}>
                                <FormattedMessage {...errorMessages.re_enter_password_required} />
                                </RawIntlProvider>
                            </Popover>
                        </Overlay>

                    </div>
                </div>
                <div>
                    <PasswordPolicy />
                </div>
            </div>
            <div className='linked_register__footer'>
                <div className='row'>
                    <div className='col-md-8'/>
                    <div className='col-md-4 linked_register__submit'>
                        <Submit caption={<FormattedMessage {...messages.submit} />}
                                imagePath="/assets/VMS_33button_go_bblue.png"
                                onClickHandler={this.onSubmitHandler.bind(this)}/>
                    </div>
                </div>
            </div>
        </div>;
    }
}


export default injectIntl(Register);

