import React from 'react';
//import './Alert.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import { GENERIC_ERROR_MESSAGE } from 'constants/App';
import { Link } from 'react-router-dom';

function htmlLinkClickHandler(e){
  e.preventDefault();
  window.location.reload();
}

const Alert = (props) => {
  const messages = defineMessages({
    generic_error_message_1: {
      id: 'generic_error_message_1',
      description: 'An unexpected error has occurred. Please try again later or ',
      defaultMessage: 'An unexpected error has occurred. Please try again later or '
    },
    generic_error_message_2: {
      id: 'generic_error_message_2',
      description: 'FeedbackForm link description',
      defaultMessage: 'Click here'
    },
    generic_error_message_3: {
      id: 'generic_error_message_3',
      description: 'FeedbackForm link description',
      defaultMessage: ' to report an issue.'
    },
    generic_error_message: {
      id: 'generic_error_message',
      description: 'FeedbackForm link description',
      defaultMessage: "An unexpected error has occurred. Please try again later or Click <a href='/contact/FeedbackForm'>here</a> to report an issue."
    },
    generic_ui_error_message_1: {
      id:'generic_ui_error_message_1', 
      description: "An unexpected error has occurred. Please ", 
      defaultMessage: "An unexpected error has occurred. Please ", 
    },
    generic_ui_error_message_2: {
      id:'generic_ui_error_message_2', 
      description: "click here", 
      defaultMessage: "click here", 
    },
    generic_ui_error_message_3: {
      id:'generic_ui_error_message_3', 
      description: " to reload the app or contact the Holman call center at 1-866-274-1025.",
      defaultMessage: " to reload the app or contact the Holman call center at 1-866-274-1025.",
    }
  });
  return (
    <div className="">
      <div className="alert alert-danger" role="alert">
        {
          props.message == 'GENERIC_ERROR_MESSAGE' ?
          <div>
            <FormattedMessage  {...messages.generic_error_message_1} />
            <Link to='/contact/FeedbackForm'><FormattedMessage  {...messages.generic_error_message_2} /></Link>
            <FormattedMessage  {...messages.generic_error_message_3} />
          </div>
          : props.message == 'GENERIC_UI_ERROR_MESSAGE' ?
            <div>
              <FormattedMessage  {...messages.generic_ui_error_message_1} />              
              <a href='#' onClick={htmlLinkClickHandler}> <FormattedMessage  {...messages.generic_ui_error_message_2} />
              </a>
              <FormattedMessage  {...messages.generic_ui_error_message_3} />
            </div>
            : <div>
              {props.message}
              </div>
        }
      </div>

    </div>
  )
}

export default Alert;