const allReasons = [
    { id:"reason_T29", value: 'T29', name: 'WORN', isSelected: false ,description:"WORN", defaultMessage:"WORN"},
    { id:"reason_T30", value: 'T30', name: 'CUSTOMER REQUEST', isSelected: false,description:"CUSTOMER REQUEST", defaultMessage:"CUSTOMER REQUEST" },
    { id:"reason_T03", value: 'T03', name: 'BLOW OUT', isSelected: false,description:"BLOW OUT", defaultMessage:"BLOW OUT" },
    {  id:"reason_T04",value: 'T04', name: 'CUSTOMER COLLECTED/RETAINED', isSelected: false,description:"CUSTOMER COLLECTED/RETAINED", defaultMessage:"CUSTOMER COLLECTED/RETAINED" },
    { id:"reason_T31",value: 'T31', name: 'UNEVEN WEAR', isSelected: false,description:"UNEVEN WEAR", defaultMessage:"UNEVEN WEAR" },
    {  id:"reason_T13",value: 'T13', name: 'PUNCTURED', isSelected: false,description:"PUNCTURED", defaultMessage:"PUNCTURED" },
    { id:"reason_T15", value: 'T15', name: 'SIDEWALL DAMAGE / BULGE', isSelected: false,description:"SIDEWALL DAMAGE / BULGE", defaultMessage:"SIDEWALL DAMAGE / BULGE" },
    { id:"reason_T21", value: 'T21', name: 'TREAD MISMATCH', isSelected: false ,description:"TREAD MISMATCH", defaultMessage:"TREAD MISMATCH"},
]

export default allReasons;