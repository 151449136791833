// #endregion
import React from "react";
import styled from "styled-components";
// #endregion

// #region Styled Components
const StepNumberStyled = styled.div`	
text-align: center;
position: relative;
z-index: 1000;
height: 5px;
top: ${props => props.top ? props.top : `-15px`};
`;
// #endregion

// #region Component
/* This component assumes that an image exists in the assets folder with naming convention 'VMS_28button_{number}_clear.png' */
const StepNumber = ({ number, top }) => {
    return <StepNumberStyled top={top}>
        <img src={`/assets/VMS_28button_${number}_clear.png`} />
    </StepNumberStyled>
}

export default StepNumber;