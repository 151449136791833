// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './VehicleComponents.css';
import CheckCircle from 'components/CheckCircle';
import VehicleComponent from './VehicleComponent';
import BitResult from './BitResult';
import VehicleComponentHeader from './VehicleComponentHeader';

class VehicleComponents extends React.Component {	
	constructor(props) {
		super(props);
		this.handleChangeDebounced = _.debounce(this.handleChange, 1000);
	}

	handleChange = (id, value) => {        		
        this.props.setFormData(id, value);        
	}
	
	handleChangeDebouncedCaller = (event) => { 
		// Note: You must call the debounced function with id and value instead of passing the event object.
		//       Event may be null by the time the debounced function is invoked.
		this.handleChangeDebounced(event.target.id, event.target.value);
    }

	render() {		
		const messages = defineMessages({
			bit_inspection_form__all_ok: {
				id: 'bit_inspection_form__all_ok',
				description: 'BIT inspection form All Ok button.',
				defaultMessage: 'All OK'
			},							

			/* Truck */
			bit_inspection_form__component_51: {
				id: 'bit_inspection_form__component_51',
				description: 'BIT inspection form component 51.',
				defaultMessage: '1. Fire extinguisher and reflective warning devices'
			}, 
			bit_inspection_form__component_52: {
				id: 'bit_inspection_form__component_52',
				description: 'BIT inspection form component 52.',
				defaultMessage: '2. Horn, defroster, gauges, odometer, and speedometer'
			}, 
			bit_inspection_form__component_53: {
				id: 'bit_inspection_form__component_53',
				description: 'BIT inspection form component 53.',
				defaultMessage: '3. Mirrors and supports'
			}, 
			bit_inspection_form__component_54: {
				id: 'bit_inspection_form__component_54',
				description: 'BIT inspection form component 54.',
				defaultMessage: '4. Windshield wipers, window cracks'
			}, 
			bit_inspection_form__component_55: {
				id: 'bit_inspection_form__component_55',
				description: 'BIT inspection form component 55.',
				defaultMessage: '5. All lights, signals, reflectors, mudflaps'
			}, 
			bit_inspection_form__component_56: {
				id: 'bit_inspection_form__component_56',
				description: 'BIT inspection form component 56.',
				defaultMessage: '6. Electrical wiring-condition and protection'
			}, 
			bit_inspection_form__component_57: {
				id: 'bit_inspection_form__component_57',
				description: 'BIT inspection form component 57.',
				defaultMessage: '7. Batteries-water level, terminals, and cables'
			}, 
			bit_inspection_form__component_58: {
				id: 'bit_inspection_form__component_58',
				description: 'BIT inspection form component 58.',
				defaultMessage: '8. Warning devices-air, oil, temperature, anti skid, and/or vacuum'
			}, 
			bit_inspection_form__component_59: {
				id: 'bit_inspection_form__component_59',
				description: 'BIT inspection form component 59.',
				defaultMessage: '9. Radiator and water hoses- coolant level, condition, and/or leaks'
			}, 
			bit_inspection_form__component_60: {
				id: 'bit_inspection_form__component_60',
				description: 'BIT inspection form component 60.',
				defaultMessage: '10. Belts-compressor, fan, water pump, and/or alternator'
			}, 
			bit_inspection_form__component_61: {
				id: 'bit_inspection_form__component_61',
				description: 'BIT inspection form component 61.',
				defaultMessage: '11. Air hoses and tubing leaks, condition, and/or protection'
			}, 
			bit_inspection_form__component_62: {
				id: 'bit_inspection_form__component_62',
				description: 'BIT inspection form component 62.',
				defaultMessage: '12. Fuel system-tank, hoses, tubing, and/or pump; leaks'
			}, 
			bit_inspection_form__component_63: {
				id: 'bit_inspection_form__component_63',
				description: 'BIT inspection form component 63.',
				defaultMessage: '13. Exhaust system, manifolds, piping, muffler; leaks and/or condition'
			}, 
			bit_inspection_form__component_64: {
				id: 'bit_inspection_form__component_64',
				description: 'BIT inspection form component 64.',
				defaultMessage: '14. Engine-mounting, excessive grease and/or oil'
			}, 
			bit_inspection_form__component_65: {
				id: 'bit_inspection_form__component_65',
				description: 'BIT inspection form component 65.',
				defaultMessage: '15. Clutch adjustment-free play'
			}, 
			bit_inspection_form__component_66: {
				id: 'bit_inspection_form__component_66',
				description: 'BIT inspection form component 66.',
				defaultMessage: '16. Air filter, throttle linkage'
			}, 
			bit_inspection_form__component_67: {
				id: 'bit_inspection_form__component_67',
				description: 'BIT inspection form component 67.',
				defaultMessage: '17. Starting and charging system'
			}, 
			bit_inspection_form__component_68: {
				id: 'bit_inspection_form__component_68',
				description: 'BIT inspection form component 68.',
				defaultMessage: '18. Tractor-protection valve'
			}, 
			bit_inspection_form__component_69: {
				id: 'bit_inspection_form__component_69',
				description: 'BIT inspection form component 69.',
				defaultMessage: '19. Hydraulic brake system-adjustment, components, and/or condition'
			}, 
			bit_inspection_form__component_70: {
				id: 'bit_inspection_form__component_70',
				description: 'BIT inspection form component 70.',
				defaultMessage: '20. Hydraulic master cylinder level, leaks, and/or condition'
			}, 
			bit_inspection_form__component_71: {
				id: 'bit_inspection_form__component_71',
				description: 'BIT inspection form component 71.',
				defaultMessage: '21. Hoses and tubing-condition and protection'
			}, 
			bit_inspection_form__component_72: {
				id: 'bit_inspection_form__component_72',
				description: 'BIT inspection form component 72.',
				defaultMessage: '22. Air brake system-adjustment, components, and/or condition'
			}, 
			bit_inspection_form__component_73: {
				id: 'bit_inspection_form__component_73',
				description: 'BIT inspection form component 73.',
				defaultMessage: '23. 1 minute air or vacuum loss test'
			}, 
			bit_inspection_form__component_74: {
				id: 'bit_inspection_form__component_74',
				description: 'BIT inspection form component 74.',
				defaultMessage: '24. Air compressor governor cut-in and cut-out pressures (85-130)'
			}, 
			bit_inspection_form__component_75: {
				id: 'bit_inspection_form__component_75',
				description: 'BIT inspection form component 75.',
				defaultMessage: '25. Primary air tank-drain and test check valve'
			}, 
			bit_inspection_form__component_76: {
				id: 'bit_inspection_form__component_76',
				description: 'BIT inspection form component 76.',
				defaultMessage: '26. Other air tank-drain and check for contamination; securement'
			}, 
			bit_inspection_form__component_77: {
				id: 'bit_inspection_form__component_77',
				description: 'BIT inspection form component 77.',
				defaultMessage: '27. Tires-tread depth, inflation, and condition'
			}, 
			bit_inspection_form__component_78: {
				id: 'bit_inspection_form__component_78',
				description: 'BIT inspection form component 78.',
				defaultMessage: '28. Wheels, lug nuts, and studs-cracks, looseness, and/or condition'
			}, 
			bit_inspection_form__component_79: {
				id: 'bit_inspection_form__component_79',
				description: 'BIT inspection form component 79.',
				defaultMessage: '29. Parking brake-able to hold the vehicle'
			}, 
			bit_inspection_form__component_80: {
				id: 'bit_inspection_form__component_80',
				description: 'BIT inspection form component 80.',
				defaultMessage: '30. Emergency stopping system-labeled and operative'
			}, 
			bit_inspection_form__component_81: {
				id: 'bit_inspection_form__component_81',
				description: 'BIT inspection form component 81.',
				defaultMessage: '31. Brakes release after complete loss of service air'
			}, 
			bit_inspection_form__component_82: {
				id: 'bit_inspection_form__component_82',
				description: 'BIT inspection form component 82.',
				defaultMessage: '32. Steering system-mounting, free lash, and components'
			}, 
			bit_inspection_form__component_83: {
				id: 'bit_inspection_form__component_83',
				description: 'BIT inspection form component 83.',
				defaultMessage: '33. Steering arms, drag links, and/or tie rod ends'
			}, 
			bit_inspection_form__component_84: {
				id: 'bit_inspection_form__component_84',
				description: 'BIT inspection form component 84.',
				defaultMessage: '34. Connecting devices- fifth wheel, pintle hitch, and/or safety devices'
			}, 
			bit_inspection_form__component_85: {
				id: 'bit_inspection_form__component_85',
				description: 'BIT inspection form component 85.',
				defaultMessage: '35. Suspension system-springs, shackles, u-bolts, and/or torque rods'
			}, 
			bit_inspection_form__component_86: {
				id: 'bit_inspection_form__component_86',
				description: 'BIT inspection form component 86.',
				defaultMessage: '36. Frame and cross members-cracks and/or condition'
			}, 
			bit_inspection_form__component_87: {
				id: 'bit_inspection_form__component_87',
				description: 'BIT inspection form component 87.',
				defaultMessage: '37. Drive shaft, universal joints, and/or guards'
			}, 
			bit_inspection_form__component_88: {
				id: 'bit_inspection_form__component_88',
				description: 'BIT inspection form component 88.',
				defaultMessage: '38. Transmission and differential-mounting, leaks, and/or condition'
			}, 
			bit_inspection_form__component_89: {
				id: 'bit_inspection_form__component_89',
				description: 'BIT inspection form component 89.',
				defaultMessage: '39. Wheel seals-leaks and/or condition'
			}, 
			bit_inspection_form__component_90: {
				id: 'bit_inspection_form__component_90',
				description: 'BIT inspection form component 90.',
				defaultMessage: '40. Under carriage-clean and secure'
			},
			bit_inspection_form__component_91: {
				id: 'bit_inspection_form__component_91',
				description: 'BIT inspection form component 91.',
				defaultMessage: '41. Other'
			}, 
						 		
			/* Bus */
			bit_inspection_form__component_150: {
				id: 'bit_inspection_form__component_150',
				description: 'BIT inspection form component 150.',
				defaultMessage: '1. Fire extinguisher, first aid kit, and reflective warning devices'
			}, 
			bit_inspection_form__component_151: {
				id: 'bit_inspection_form__component_151',
				description: 'BIT inspection form component 151.',
				defaultMessage: '2. Horn, defroster, gauges, odometer, and speedometer'
			}, 
			bit_inspection_form__component_152: {
				id: 'bit_inspection_form__component_152',
				description: 'BIT inspection form component 152.',
				defaultMessage: '3. Driver seat, passenger seats, padding, interior, and floor condition'
			}, 
			bit_inspection_form__component_153: {
				id: 'bit_inspection_form__component_153',
				description: 'BIT inspection form component 153.',
				defaultMessage: '4. Windshield wipers, windows, mirrors, and supports'
			}, 
			bit_inspection_form__component_154: {
				id: 'bit_inspection_form__component_154',
				description: 'BIT inspection form component 154.',
				defaultMessage: '5. All interior and exterior lights, signals, reflectors'
			}, 
			bit_inspection_form__component_155: {
				id: 'bit_inspection_form__component_155',
				description: 'BIT inspection form component 155.',
				defaultMessage: '6. Electrical wiring-condition and protection'
			}, 
			bit_inspection_form__component_156: {
				id: 'bit_inspection_form__component_156',
				description: 'BIT inspection form component 156.',
				defaultMessage: '7. Batteries-water level, terminals, and cables'
			}, 
			bit_inspection_form__component_157: {
				id: 'bit_inspection_form__component_157',
				description: 'BIT inspection form component 157.',
				defaultMessage: '8. Warning devices-air, oil, temperature, exit, and/or vacuum'
			}, 
			bit_inspection_form__component_158: {
				id: 'bit_inspection_form__component_158',
				description: 'BIT inspection form component 158.',
				defaultMessage: '9. Heaters, defrosters, switches, and vents'
			}, 
			bit_inspection_form__component_159: {
				id: 'bit_inspection_form__component_159',
				description: 'BIT inspection form component 159.',
				defaultMessage: '10. Doors, exterior, paint, and marking'
			}, 
			bit_inspection_form__component_160: {
				id: 'bit_inspection_form__component_160',
				description: 'BIT inspection form component 160.',
				defaultMessage: '11. Radiator and water hoses-coolant level, condition, and/or leaks'
			}, 
			bit_inspection_form__component_161: {
				id: 'bit_inspection_form__component_161',
				description: 'BIT inspection form component 161.',
				defaultMessage: '12. Belts-compressor, fan, water, and/or alternator'
			}, 
			bit_inspection_form__component_162: {
				id: 'bit_inspection_form__component_162',
				description: 'BIT inspection form component 162.',
				defaultMessage: '13. Air hoses and tubing-leaks, condition, and/or protection'
			}, 
			bit_inspection_form__component_163: {
				id: 'bit_inspection_form__component_163',
				description: 'BIT inspection form component 163.',
				defaultMessage: '14. Fuel system-tank, hoses, tubing, and/or pump-leaks'
			}, 
			bit_inspection_form__component_164: {
				id: 'bit_inspection_form__component_164',
				description: 'BIT inspection form component 164.',
				defaultMessage: '15. Exhaust system, manifolds, piping, muffler-leaks and/or condition'
			}, 
			bit_inspection_form__component_165: {
				id: 'bit_inspection_form__component_165',
				description: 'BIT inspection form component 165.',
				defaultMessage: '16. Engine-mounting, excessive grease and/or oil'
			}, 
			bit_inspection_form__component_166: {
				id: 'bit_inspection_form__component_166',
				description: 'BIT inspection form component 166.',
				defaultMessage: '17. Clutch adjustment-free play'
			}, 
			bit_inspection_form__component_167: {
				id: 'bit_inspection_form__component_167',
				description: 'BIT inspection form component 167.',
				defaultMessage: '18. Air filter, throttle linkage'
			}, 
			bit_inspection_form__component_168: {
				id: 'bit_inspection_form__component_168',
				description: 'BIT inspection form component 168.',
				defaultMessage: '19. Starting and charging system'
			}, 
			bit_inspection_form__component_169: {
				id: 'bit_inspection_form__component_169',
				description: 'BIT inspection form component 169.',
				defaultMessage: '20. Hydraulic brake system-adjustment, components, and/or condition'
			}, 
			bit_inspection_form__component_170: {
				id: 'bit_inspection_form__component_170',
				description: 'BIT inspection form component 170.',
				defaultMessage: '21. Hydraulic master cylinder-level, leaks, and/or condition'
			}, 
			bit_inspection_form__component_171: {
				id: 'bit_inspection_form__component_171',
				description: 'BIT inspection form component 171.',
				defaultMessage: '22. Hoses and tubing-condition, protection'
			}, 
			bit_inspection_form__component_172: {
				id: 'bit_inspection_form__component_172',
				description: 'BIT inspection form component 172.',
				defaultMessage: '23. Air brake system-adjustment, compartments, and/or condition'
			}, 
			bit_inspection_form__component_173: {
				id: 'bit_inspection_form__component_173',
				description: 'BIT inspection form component 173.',
				defaultMessage: '24. 1 minute air or vacuum loss test'
			}, 
			bit_inspection_form__component_174: {
				id: 'bit_inspection_form__component_174',
				description: 'BIT inspection form component 174.',
				defaultMessage: '25. Air compressor governor-cut in and cut out pressure (85-130)'
			}, 
			bit_inspection_form__component_175: {
				id: 'bit_inspection_form__component_175',
				description: 'BIT inspection form component 175.',
				defaultMessage: '26. Primary air tank-drain and test function of check valve'
			}, 
			bit_inspection_form__component_176: {
				id: 'bit_inspection_form__component_176',
				description: 'BIT inspection form component 176.',
				defaultMessage: '27. Other air tanks-drain and check for contamination'
			}, 
			bit_inspection_form__component_177: {
				id: 'bit_inspection_form__component_177',
				description: 'BIT inspection form component 177.',
				defaultMessage: '28. Tires-tread depth, inflation, condition'
			}, 
			bit_inspection_form__component_178: {
				id: 'bit_inspection_form__component_178',
				description: 'BIT inspection form component 178.',
				defaultMessage: '29. Wheels, lug nuts, and studs-cracks, looseness, and/or condition'
			}, 
			bit_inspection_form__component_179: {
				id: 'bit_inspection_form__component_179',
				description: 'BIT inspection form component 179.',
				defaultMessage: '30. Parking brake-able to hold the vehicle'
			}, 
			bit_inspection_form__component_180: {
				id: 'bit_inspection_form__component_180',
				description: 'BIT inspection form component 180.',
				defaultMessage: '31. Emergency stopping system-labeled, operative'
			}, 
			bit_inspection_form__component_181: {
				id: 'bit_inspection_form__component_181',
				description: 'BIT inspection form component 181.',
				defaultMessage: '32. Brakes do not release after complete loss of service air'
			}, 
			bit_inspection_form__component_182: {
				id: 'bit_inspection_form__component_182',
				description: 'BIT inspection form component 182.',
				defaultMessage: '33. Steering system-mounting, free lash and components'
			}, 
			bit_inspection_form__component_183: {
				id: 'bit_inspection_form__component_183',
				description: 'BIT inspection form component 183.',
				defaultMessage: '34. Steering arms, drag links, and/or tie rod ends'
			}, 
			bit_inspection_form__component_184: {
				id: 'bit_inspection_form__component_184',
				description: 'BIT inspection form component 184.',
				defaultMessage: '35. Suspension system-springs, shackles, u-bolts, and/or torque rods'
			}, 
			bit_inspection_form__component_185: {
				id: 'bit_inspection_form__component_185',
				description: 'BIT inspection form component 185.',
				defaultMessage: '36. Frame and cross members-cracks and/or condition'
			}, 
			bit_inspection_form__component_186: {
				id: 'bit_inspection_form__component_186',
				description: 'BIT inspection form component 186.',
				defaultMessage: '37. Drive shaft, universal joints, and/or guards'
			}, 
			bit_inspection_form__component_187: {
				id: 'bit_inspection_form__component_187',
				description: 'BIT inspection form component 187.',
				defaultMessage: '38. Transmission and differential-mounting, leaks, and/or condition'
			}, 
			bit_inspection_form__component_188: {
				id: 'bit_inspection_form__component_188',
				description: 'BIT inspection form component 188.',
				defaultMessage: '39. Wheel seals-leaks and/or condition'
			}, 
			bit_inspection_form__component_189: {
				id: 'bit_inspection_form__component_189',
				description: 'BIT inspection form component 189.',
				defaultMessage: '40. Under carriage-clean and secure'
			}, 
			bit_inspection_form__component_190: {
				id: 'bit_inspection_form__component_190',
				description: 'BIT inspection form component 190.',
				defaultMessage: '41. Other'
			}, 
						
			/* Trailer */
			bit_inspection_form__component_100: {
				id: 'bit_inspection_form__component_100',
				description: 'BIT inspection form component 100.',
				defaultMessage: '1. All lights, signals, reflectors'
			}, 
			bit_inspection_form__component_101: {
				id: 'bit_inspection_form__component_101',
				description: 'BIT inspection form component 101.',
				defaultMessage: '2. Mudflaps'
			}, 
			bit_inspection_form__component_102: {
				id: 'bit_inspection_form__component_102',
				description: 'BIT inspection form component 102.',
				defaultMessage: '3. Air leaks-brake system'
			}, 
			bit_inspection_form__component_103: {
				id: 'bit_inspection_form__component_103',
				description: 'BIT inspection form component 103.',
				defaultMessage: '4. Air leaks-dump system'
			}, 
			bit_inspection_form__component_104: {
				id: 'bit_inspection_form__component_104',
				description: 'BIT inspection form component 104.',
				defaultMessage: '5. Frame, subframe, and body-cracks'
			}, 
			bit_inspection_form__component_105: {
				id: 'bit_inspection_form__component_105',
				description: 'BIT inspection form component 105.',
				defaultMessage: '6. Brake adjustment'
			}, 
			bit_inspection_form__component_106: {
				id: 'bit_inspection_form__component_106',
				description: 'BIT inspection form component 106.',
				defaultMessage: '7. Brake system, drums, and components-condition'
			}, 
			bit_inspection_form__component_107: {
				id: 'bit_inspection_form__component_107',
				description: 'BIT inspection form component 107.',
				defaultMessage: '8. Suspension system-springs, shackles, u-bolts, and/or torque rods'
			}, 
			bit_inspection_form__component_108: {
				id: 'bit_inspection_form__component_108',
				description: 'BIT inspection form component 108.',
				defaultMessage: '9. Connecting device-kingpin, drawbar, eye and/or safety devices'
			}, 
			bit_inspection_form__component_109: {
				id: 'bit_inspection_form__component_109',
				description: 'BIT inspection form component 109.',
				defaultMessage: '10. Fifth wheel on pull trailer'
			}, 
			bit_inspection_form__component_110: {
				id: 'bit_inspection_form__component_110',
				description: 'BIT inspection form component 110.',
				defaultMessage: '11. Air hoses and tubing-leaks, condition, and/or protection'
			}, 
			bit_inspection_form__component_111: {
				id: 'bit_inspection_form__component_111',
				description: 'BIT inspection form component 111.',
				defaultMessage: '12. Tires-tread depth, inflation, and condition'
			}, 
			bit_inspection_form__component_112: {
				id: 'bit_inspection_form__component_112',
				description: 'BIT inspection form component 112.',
				defaultMessage: '13. Wheels, lug nuts, and studs-cracks, looseness, and/or condition'
			}, 
			bit_inspection_form__component_113: {
				id: 'bit_inspection_form__component_113',
				description: 'BIT inspection form component 113.',
				defaultMessage: '14. Hoses and tubing-condition, protection'
			}, 
			bit_inspection_form__component_114: {
				id: 'bit_inspection_form__component_114',
				description: 'BIT inspection form component 114.',
				defaultMessage: '15. Hydraulic Master cylinder-level, leaks, and/or condition'
			}, 
			bit_inspection_form__component_115: {
				id: 'bit_inspection_form__component_115',
				description: 'BIT inspection form component 115.',
				defaultMessage: '16. Parking brake-able to hold the vehicle'
			}, 
			bit_inspection_form__component_116: {
				id: 'bit_inspection_form__component_116',
				description: 'BIT inspection form component 116.',
				defaultMessage: '17. Emergency breakaway brake system'
			}, 
			bit_inspection_form__component_117: {
				id: 'bit_inspection_form__component_117',
				description: 'BIT inspection form component 117.',
				defaultMessage: '18. Air relay valves and tank-mounting'
			}, 
			bit_inspection_form__component_118: {
				id: 'bit_inspection_form__component_118',
				description: 'BIT inspection form component 118.',
				defaultMessage: '19. Brakes release after complete loss of service air'
			}, 
			bit_inspection_form__component_119: {
				id: 'bit_inspection_form__component_119',
				description: 'BIT inspection form component 119.',
				defaultMessage: '20. Wheel seals-leaks and/or condition'
			}, 
			bit_inspection_form__component_120: {
				id: 'bit_inspection_form__component_120',
				description: 'BIT inspection form component 120.',
				defaultMessage: '21. Under carriage-clean and secure'
			}, 			
			bit_inspection_form__component_121: {
				id: 'bit_inspection_form__component_121',
				description: 'BIT inspection form component 121.',
				defaultMessage: '22. Other'
			},
																					
		});
		
        return (
			<div>  
				
				<div className='bit_inspection_vehicle_components__all_ok'>
				
					<CheckCircle 
						name='isAllOk'
						className='bit_inspection_vehicle_components__check_circle'					
						message={<FormattedMessage {...messages.bit_inspection_form__all_ok} />}
						checked={this.props.formData.get('isAllOk')}
						onClick={(name, checked) => this.props.setFormData(name, !checked)}
					/>
								
				</div>								
				
				<VehicleComponentHeader />

				{this.props.assetType === 'trailer' ?
					<React.Fragment>
						<VehicleComponent 
							componentId='100'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_100} />}
							componentDetails={this.props.componentDetails.get('100')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='101'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_101} />}
							componentDetails={this.props.componentDetails.get('101')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='102'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_102} />}
							componentDetails={this.props.componentDetails.get('102')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='103'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_103} />}
							componentDetails={this.props.componentDetails.get('103')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='104'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_104} />}
							componentDetails={this.props.componentDetails.get('104')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='105'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_105} />}
							componentDetails={this.props.componentDetails.get('105')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='106'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_106} />}
							componentDetails={this.props.componentDetails.get('106')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='107'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_107} />}
							componentDetails={this.props.componentDetails.get('107')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='108'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_108} />}
							componentDetails={this.props.componentDetails.get('108')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='109'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_109} />}
							componentDetails={this.props.componentDetails.get('109')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='110'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_110} />}
							componentDetails={this.props.componentDetails.get('110')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='111'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_111} />}
							componentDetails={this.props.componentDetails.get('111')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='112'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_112} />}
							componentDetails={this.props.componentDetails.get('112')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='113'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_113} />}
							componentDetails={this.props.componentDetails.get('113')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='114'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_114} />}
							componentDetails={this.props.componentDetails.get('114')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='115'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_115} />}
							componentDetails={this.props.componentDetails.get('115')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='116'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_116} />}
							componentDetails={this.props.componentDetails.get('116')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='117'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_117} />}
							componentDetails={this.props.componentDetails.get('117')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='118'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_118} />}
							componentDetails={this.props.componentDetails.get('118')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='119'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_119} />}
							componentDetails={this.props.componentDetails.get('119')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='120'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_120} />}
							componentDetails={this.props.componentDetails.get('120')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='121'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_121} />}
							componentDetails={this.props.componentDetails.get('121')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>						
					</React.Fragment>

				: this.props.assetType === 'bus' ?
					<React.Fragment>
						<VehicleComponent 
							componentId='150'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_150} />}
							componentDetails={this.props.componentDetails.get('150')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='151'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_151} />}
							componentDetails={this.props.componentDetails.get('151')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='152'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_152} />}
							componentDetails={this.props.componentDetails.get('152')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='153'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_153} />}
							componentDetails={this.props.componentDetails.get('153')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='154'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_154} />}
							componentDetails={this.props.componentDetails.get('154')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='155'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_155} />}
							componentDetails={this.props.componentDetails.get('155')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='156'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_156} />}
							componentDetails={this.props.componentDetails.get('156')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='157'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_157} />}
							componentDetails={this.props.componentDetails.get('157')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='158'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_158} />}
							componentDetails={this.props.componentDetails.get('158')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='159'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_159} />}
							componentDetails={this.props.componentDetails.get('159')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='160'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_160} />}
							componentDetails={this.props.componentDetails.get('160')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='161'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_161} />}
							componentDetails={this.props.componentDetails.get('161')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='162'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_162} />}
							componentDetails={this.props.componentDetails.get('162')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='163'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_163} />}
							componentDetails={this.props.componentDetails.get('163')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='164'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_164} />}
							componentDetails={this.props.componentDetails.get('164')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='165'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_165} />}
							componentDetails={this.props.componentDetails.get('165')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='166'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_166} />}
							componentDetails={this.props.componentDetails.get('166')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='167'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_167} />}
							componentDetails={this.props.componentDetails.get('167')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='168'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_168} />}
							componentDetails={this.props.componentDetails.get('168')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='169'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_169} />}
							componentDetails={this.props.componentDetails.get('169')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='170'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_170} />}
							componentDetails={this.props.componentDetails.get('170')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='171'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_171} />}
							componentDetails={this.props.componentDetails.get('171')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='172'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_172} />}
							componentDetails={this.props.componentDetails.get('172')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='173'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_173} />}
							componentDetails={this.props.componentDetails.get('173')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='174'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_174} />}
							componentDetails={this.props.componentDetails.get('174')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='175'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_175} />}
							componentDetails={this.props.componentDetails.get('175')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='176'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_176} />}
							componentDetails={this.props.componentDetails.get('176')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='177'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_177} />}
							componentDetails={this.props.componentDetails.get('177')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='178'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_178} />}
							componentDetails={this.props.componentDetails.get('178')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='179'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_179} />}
							componentDetails={this.props.componentDetails.get('179')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='180'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_180} />}
							componentDetails={this.props.componentDetails.get('180')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='181'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_181} />}
							componentDetails={this.props.componentDetails.get('181')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='182'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_182} />}
							componentDetails={this.props.componentDetails.get('182')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='183'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_183} />}
							componentDetails={this.props.componentDetails.get('183')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='184'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_184} />}
							componentDetails={this.props.componentDetails.get('184')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='185'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_185} />}
							componentDetails={this.props.componentDetails.get('185')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='186'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_186} />}
							componentDetails={this.props.componentDetails.get('186')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='187'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_187} />}
							componentDetails={this.props.componentDetails.get('187')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='188'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_188} />}
							componentDetails={this.props.componentDetails.get('188')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='189'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_189} />}
							componentDetails={this.props.componentDetails.get('189')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='190'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_190} />}
							componentDetails={this.props.componentDetails.get('190')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
					</React.Fragment>

				: /* TRUCK */
					<React.Fragment>
						<VehicleComponent 
							componentId='51'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_51} />}
							componentDetails={this.props.componentDetails.get('51')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='52'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_52} />}
							componentDetails={this.props.componentDetails.get('52')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='53'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_53} />}
							componentDetails={this.props.componentDetails.get('53')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='54'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_54} />}
							componentDetails={this.props.componentDetails.get('54')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='55'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_55} />}
							componentDetails={this.props.componentDetails.get('55')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='56'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_56} />}
							componentDetails={this.props.componentDetails.get('56')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='57'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_57} />}
							componentDetails={this.props.componentDetails.get('57')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='58'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_58} />}
							componentDetails={this.props.componentDetails.get('58')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='59'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_59} />}
							componentDetails={this.props.componentDetails.get('59')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='60'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_60} />}
							componentDetails={this.props.componentDetails.get('60')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='61'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_61} />}
							componentDetails={this.props.componentDetails.get('61')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='62'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_62} />}
							componentDetails={this.props.componentDetails.get('62')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='63'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_63} />}
							componentDetails={this.props.componentDetails.get('63')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='64'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_64} />}
							componentDetails={this.props.componentDetails.get('64')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='65'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_65} />}
							componentDetails={this.props.componentDetails.get('65')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='66'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_66} />}
							componentDetails={this.props.componentDetails.get('66')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='67'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_67} />}
							componentDetails={this.props.componentDetails.get('67')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='68'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_68} />}
							componentDetails={this.props.componentDetails.get('68')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='69'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_69} />}
							componentDetails={this.props.componentDetails.get('69')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='70'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_70} />}
							componentDetails={this.props.componentDetails.get('70')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='71'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_71} />}
							componentDetails={this.props.componentDetails.get('71')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='72'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_72} />}
							componentDetails={this.props.componentDetails.get('72')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='73'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_73} />}
							componentDetails={this.props.componentDetails.get('73')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='74'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_74} />}
							componentDetails={this.props.componentDetails.get('74')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='75'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_75} />}
							componentDetails={this.props.componentDetails.get('75')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='76'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_76} />}
							componentDetails={this.props.componentDetails.get('76')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='77'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_77} />}
							componentDetails={this.props.componentDetails.get('77')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='78'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_78} />}
							componentDetails={this.props.componentDetails.get('78')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='79'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_79} />}
							componentDetails={this.props.componentDetails.get('79')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='80'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_80} />}
							componentDetails={this.props.componentDetails.get('80')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='81'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_81} />}
							componentDetails={this.props.componentDetails.get('81')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='82'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_82} />}
							componentDetails={this.props.componentDetails.get('82')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='83'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_83} />}
							componentDetails={this.props.componentDetails.get('83')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='84'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_84} />}
							componentDetails={this.props.componentDetails.get('84')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='85'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_85} />}
							componentDetails={this.props.componentDetails.get('85')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='86'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_86} />}
							componentDetails={this.props.componentDetails.get('86')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='87'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_87} />}
							componentDetails={this.props.componentDetails.get('87')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='88'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_88} />}
							componentDetails={this.props.componentDetails.get('88')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='89'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_89} />}
							componentDetails={this.props.componentDetails.get('89')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='90'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_90} />}
							componentDetails={this.props.componentDetails.get('90')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
						<VehicleComponent 
							componentId='91'
							componentName={<FormattedMessage {...messages.bit_inspection_form__component_91} />}
							componentDetails={this.props.componentDetails.get('91')}
							onComponentDetailsChanged={this.props.onComponentDetailsChanged}
						/>
					</React.Fragment>
				}
				


				<BitResult 
					formData={this.props.formData}
					formValidationMessages={this.props.formValidationMessages}
					userLocale={this.props.userLocale}
					poCreationDate={this.props.poCreationDate}
					setFormData={this.props.setFormData}
					onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}					
				/>

			</div>
        );
    }
}

export default VehicleComponents;
