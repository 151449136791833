import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map, fromJS} from 'immutable';


export function serverDataClientParameters(state = initialAppState.getIn(['serverData', 'clientParameters']), action) {
    switch (action.type) {
        case constants.SAVE_CLIENT_PARAMETERS:
            return state.set('parameters',fromJS(action.currentParameters));
        case constants.FETCH_DATA_FOR_CLIENT_PARAMETERS:
            return state.set('isFetchingData', true)
                        .set('errorMessage', []);   
        case constants.SEND_CLICK_CLIENT_PARAMETERS_RESOLVED:
            return state.set('isFetchingData', false);
        case constants.SEND_CLICK_CLIENT_PARAMETERS_REJECTED:
            return state.set('isFetchingData', false)
                        .set('errorMessage', action.errorMessage); 
        default:
            return state;
    }
}