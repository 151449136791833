// This is a global theme that is based on Dan's design.  
// This file should not change often and requires a review meeting.
// If you want custom colors, make those changes in your component.

// As of 3/28/2022 Holman Branding has been added.

export const theme = {

  /* legacy ARI color palette */
	color: {
	
		// Uses: Holman logo, framing links and shop name
		ariLegacyBlue: '#0f4e95', 
		
		// Uses: Subheads, action button links, buttons, text field borders
		primaryBlue: '#5587b7', 
	
		//Uses: Background for main area
		blueBackground: '#dce2f3', 
	
		// Uses: Some text, some hairline rules
		ariGray: '#5b656e', 
	
		// Uses: Text field background when field is editable, background bar at top of screen
		grayBackground: '#dddfe1', 
	
		// Uses: Background for newly approved POs
		greenBackground: '#b4db82', 
	
		// Uses: Background for alerts
		redBackground: '#f8b6c7', 
	
		// Uses: Alert text, with and without red background
		alertText: '#ed1d30', 
	
		// Uses: Most text, most hairline rules
		black: '#000000', 
	
		// Uses: Screen backgrounds, indicating background for primary actions
		white: '#ffffff' 
	},

  /*  ***As of 3/28/2022 this is the new Holman Brand Palette***

  BRAND PALETTE
  Our new color palette is inspired
  by the original Holman blue
  complimented with secondary
  colors that are warm, friendly,
  energetic and clean.
  Holman colors should be used in
  all applications with Drive Blue
  dominating the overall brand palette
  and Fresh Blue and Field Green used
  as accent colors. Put It In Neutral is
  reserved as background color that
  floods large layout sections adding
  warmth and humanity to the
  overall aesthetic.
  */
  holmanColor: {

    // Primary
		driveBlue: '#003057', 
	
		// Primary Accent
		freshBlue: '#0069A7', 
	
		// Primary Accent
		fieldGreen: '#2C9942', 

    // Secondary / Background
    putItInNeutral: '#F3E8DA',

    //#region Grays
    
    // Black
    black: 'black',

    // 75%
    gray75: '#636466',

    // 50%
    gray50: '#939598',

    // 25%
    gray75: '#c7c8ca',

    // White
    white: 'white',

    //#endregion Grays

  },

  holmanFont: {

    // Primary
		primary: 'Poppins, arial, "sans serif"'
		
  }






};