import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Notification from 'Notification';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as constants from 'constants/App';
import { isAuthenticated } from 'utils/HelperFunctions';
import './TLSWarning.css';
const bowser = require('bowser');

class TLSWarning extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            isNotSupportTLS: false,
            didCheckTLSSupport: this.props.didCheckTLSSupport
        };
    }

    isNotSupportTLS() {
        const browser = bowser.getParser(window.navigator.userAgent);
    
        const notSupportTLS = browser.satisfies({
            desktop: {
              "internet explorer": "<11",
              "microsoft edge": "<12",
              firefox: "<27",
              chrome: "<30",
              safari: "<7",
              opera: "<17",
              maxthon: "<4.1"
            },        
            mobile: {
              safari: '<9.3',
              opera: '<12.1',
              'android browser': '<62',
              blackberry: "<10",
              chrome: "<62",
              firefox: "<57",
              "internet explorer": "<11",
              "uc browser": "<11.4",
              "samsung internet for android": "<4",  
              "microsoft edge": "<12",          
              bada: "<7.12",
              maxthon: "<9.0"
            },        
            tablet: {
                safari: '<9.3',
                opera: '<12.1',
                'android browser': '<62',
                blackberry: "<10",
                chrome: "<62",
                firefox: "<57",
                "internet explorer": "<11",
                "uc browser": "<11.4",
                "samsung internet for android": "<4",  
                "microsoft edge": "<12",          
                bada: "<7.12",
                maxthon: "<9.0"
              }
          });
            
        return notSupportTLS;
    }

    componentDidMount() {
        this.setState({ show: this.props.is_user_logged_in });

        if (this.props.is_user_logged_in && !this.state.didCheckTLSSupport) {
            this.setState({ didCheckTLSSupport: true });
            this.setState({ isNotSupportTLS: this.isNotSupportTLS() });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== undefined && prevProps.is_user_logged_in !== this.props.is_user_logged_in) {
            this.setState({ show: this.props.is_user_logged_in });
        }
    }

    componentWillUnmount() {
        this.setState({ show: false });
        this.setState({ isNotSupportTLS: false });
        this.setState({ didCheckTLSSupport: false });
    }

    onTLSLearnMoreHandler(e) {
        e.preventDefault();
        this.props.dispatch({ type: constants.NAVIGATE_TO, path: `/tlsWarning/learnMore` });
    }

    render() {
        const messages = defineMessages({
            tls_warning_title: {
                id: 'tls_warning_title',
                description: 'tls warning title',
                defaultMessage: 'Browser security warning'
            },
            tls_warning_message_line1: {
                id: 'tls_warning_message_line1',
                description: 'tls warning message line 1',
                defaultMessage: 'This website does not allow older browsers to be used for security purposes. To access this website, you will need to upgrade to the latest version of Microsoft Internet Explorer, Google Chrome, Apple Safari or Mozilla Firefox.'
            },
            tls_warning_message_line3: {
                id: 'tls_warning_message_line3',
                description: 'tls warning message line 3',
                defaultMessage: 'If you have any questions, please consult your IT department or internet security provider.'
            },
            tls_warning_message_line4: {
                id: 'tls_warning_message_line4',
                description: 'tls warning message line 4',
                defaultMessage: 'To reach Holman regarding this security update, please contact Holman PartnerConnect help desk at <a href="mailto:partnerconnect@holman.com">partnerconnect@holman.com</a>'
            },
            tls_warning_learn_more_button: {
                id: 'tls_warning_learn_more_button',
                description: 'tls warning learn more button',
                defaultMessage: 'Learn More'
            }
        });

        return <React.Fragment>
            {this.state.show && this.state.isNotSupportTLS && (
                <Notification type={constants.TYPE.WARNING} render={
                    <div className="tls_warning container-fluid">
                        <div className="tls_warning-header row">
                            <FormattedMessage {...messages.tls_warning_title} />
                        </div>
                        <div className="tls_warning-body row">
                            <div className="container-fluid">
                                <div className="tls_warning_message_line1 row">
                                    <FormattedMessage {...messages.tls_warning_message_line1} />
                                </div>
                                <div className="tls_warning_message_line3 row">
                                    <FormattedMessage {...messages.tls_warning_message_line3} />
                                </div>
                                <div className="tls_warning_message_line4 row">
                <FormattedMessage {...messages.tls_warning_message_line4} values={{ br: data => <br/>, a: data => <a href='mailto:partnerconnect@holman.com'>{data}</a>}}/>
                                </div>
                            </div>
                        </div>
                        <div className="tls_warning-footer row">
                            <div className="container-fluid">
                                <div
                                    className="pull-right tls_warning-learn_more_button"
                                    role="button"
                                    onClick={this.onTLSLearnMoreHandler.bind(this)}
                                >
                                    <FormattedMessage {...messages.tls_warning_learn_more_button} />
                                    {' '}
                                    <img src="/assets/VMS_33button_go_bblue.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                } />)
            }
        </React.Fragment>;
    }
}

function select(state) {
    return {
        is_user_logged_in: isAuthenticated(state),
        didCheckTLSSupport: false
    };
}

export default connect(select)(TLSWarning);
