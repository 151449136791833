import React, { useRef, useState } from 'react';
import {Map} from 'immutable';
import './ServiceLineItem.css';
import {LINE_ITEM_TYPE, SERVICE_LINE_ITEM_DETAILS_TYPE} from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import {ButtonGroup, Button, Tooltip, OverlayTrigger, Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import CheckCircle from 'components/CheckCircle';
import { defineMessages, FormattedMessage } from 'react-intl';

const CheckCircleStyled = styled(CheckCircle)`
    padding: 0.5rem;
    color: black;
    margin-left: -1.5rem;
    padding-bottom: 1rem;
    font-weight: 700;
`;

const ServiceLineItem = (props) => {

    const getTooltip = (toolTipText) => {
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }

    const getATACodes = (productCode, description) => {

        return <OverlayTrigger placement='right' keConfirmary={productCode} overlay={getTooltip(description)}>

            <div key={productCode} className='text-truncate'>
                {description}
            </div>

        </OverlayTrigger>

    }

    const PriceControlConfirmation = () => {
        const messages = defineMessages({
            price_exceeds_confirmation: {
                id: 'price_exceeds_confirmation',
                description: 'Confirm',
                defaultMessage: 'Confirm'
            },
        });
        
        return <div {...props}>
            <CheckCircleStyled message={<FormattedMessage {...messages.price_exceeds_confirmation} />} size={"sm"} checked={props.priceConfirmed()}  onClick={props.handlePriceConfirmedClicked}/>
        </div>;
    }

    let priceValidationExceeds = props.priceValidations != undefined && props.priceValidations.find(x => x.ataCode == props.ataCode.get('productCode'))?.menupricingexceeds;
    
    return (
            <Row className="service-line-item__row" key={props.ataCode.get('productCode')}>
                <Col sm md={6}>
                    <div className="service-line-item__ata-description">
                        {getATACodes(props.ataCode.get("productCode"), props.ataCode.get("description"))}
                    </div>
                </Col>
                <Col sm md={2}>
                    <DynamicNumber
                        className="service-line-item__cost-input form-control"
                        onChange={props.handleChange.bind(this, LINE_ITEM_TYPE.PART, props.ataCode.get('productCode'))}
                        separator={'.'}
                        integer={6}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        placeholder={"$"}
                        value={props.ataCode.get('partCost') !== undefined ?  props.ataCode.get('partCost').toString().match(/^-?\d+(?:\.\d{0,2})?/) : 0}
                    />
                </Col>
                <Col sm md={2}>
                    <DynamicNumber
                        className="service-line-item__cost-input form-control"
                        onChange={props.handleChange.bind(this, LINE_ITEM_TYPE.LABOR, props.ataCode.get('productCode'))}
                        separator={'.'}
                        integer={6}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        placeholder={"$"}
                        value={props.ataCode.get('laborRate') !== undefined ? props.ataCode.get('laborRate').toString().match(/^-?\d+(?:\.\d{0,2})?/) : 0}
                    />
                </Col>
                <Col sm md={2}>
                    <div className={priceValidationExceeds && !props.priceConfirmed() ? "class-error" : ""}>
                    <DynamicNumber
                        className="service-line-item__cost-input form-control"
                        separator={'.'}
                        integer={6}
                        fraction={2}
                        positive={true}
                        negative={false}
                        thousand={false}
                        value={props.ataCode.get('total') !== undefined ?  props.ataCode.get('total').toString().match(/^-?\d+(?:\.\d{0,2})?/) : 0}
                        onChange={props.handleChange.bind(this, SERVICE_LINE_ITEM_DETAILS_TYPE.TOTAL, props.ataCode.get('productCode'))}
                        placeholder={"$"}
                        ref={props.serviceItemRef}
                    />
                    </div>
                    {priceValidationExceeds ? <PriceControlConfirmation/> : null}
                </Col>
            </Row>
        );
    }
;



export default ServiceLineItem;
