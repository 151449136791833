import React from 'react';
import ReactDOM from 'react-dom';
import Submit from 'components/Submit';
import * as constants from 'constants/App';
import './AuthEmailNotification.css';
import { Table } from 'react-bootstrap';
import { Popover, Overlay } from 'react-bootstrap';
import * as helperFunctions from 'utils/HelperFunctions';
import classnames from 'classnames';
import AuthEmailNotificationView from './AuthEmailNotificationView';
import AuthEmailNotificationAddEdit from './AuthEmailNotificationAddEdit';

class AuthEmailNotification extends React.Component {
    constructor(props) {
        super(props);
    }
  
    render() {
        return this.props.formMode == constants.VIEW ?
            <AuthEmailNotificationView
                isRegistered={this.props.isRegistered}
                title={this.props.title}
                error={this.props.error}
                authorizationEmailConfig={this.props.authorizationEmailConfig}
                onEditConfiguration={this.props.onEditConfiguration}
            />
            : <AuthEmailNotificationAddEdit
                isRegistered = {this.props.isRegistered}
                formMode = {this.props.formMode}
                title = {this.props.title}
                authorizationEmailConfig = {this.props.authorizationEmailConfig}
                addContactRow = {this.props.addContactRow}
                onCancel = {this.props.onCancel}
                isSubmittingAuthEmailConfigInfo = {this.props.isSubmittingAuthEmailConfigInfo}
                updateEmailConfigRegistration = {this.props.updateEmailConfigRegistration}
                removeContact = {this.props.removeContact}
                removeValidationErrForAuthEmailConfig = {this.props.removeValidationErrForAuthEmailConfig}
                onSubmitStep = {this.props.onSubmitStep}
                updateContact = {this.props.updateContact}
                authorizationEmailConfigValidationErrs = {this.props.authorizationEmailConfigValidationErrs}
                error={this.props.error}
                tabIndexStartFrom={this.props.tabIndexStartFrom}
            />
    }
};
export default AuthEmailNotification;