// #region Imports
import React, { useState,useEffect } from "react";
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage} from 'react-intl';
import styled from "styled-components";
// #endregion

// #region Styled Components
const ModalStyled = styled(Modal).attrs({
    dialogClassName: "custom-modal"
  })`
    .custom-modal {
      max-width: 600px;
  }`;
// #endregion

// #region Component
const EmailConfirmation = ({
  show,
  onOKClickEvent,
  title,
  message,
  okButtonText,
  firstName,
  lastName
}) => {

// #region Event handlers
    function  handleOKClick() {
        if (onOKClickEvent != undefined && onOKClickEvent != null)
            onOKClickEvent();
    } 
// #endregion

// #region Render
        return (
            <ModalStyled
                show={show}>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <span className="confirm_message_box__header">
                                    {
                                        <FormattedMessage
                                            id={title.id}
                                            description={title.description}
                                            defaultMessage={title.defaultMessage}
                                        />
                                    }
                                </span>
                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col>
                                <div className="confirm_message_box__message">
                                    {
                                        <FormattedMessage
                                        values={{
                                            firstName: firstName != undefined ? firstName : "",
                                            lastName: lastName != undefined ? lastName : ""
                                        }}
                                            id={message.id}
                                            description={message.description}
                                            defaultMessage={message.defaultMessage}
                                        />
                                    }
                                </div>

                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col>
                                <div className="confirm_message_box__links-yes">
                                    <a onClick={() => {handleOKClick();}}>
                                        {
                                            <FormattedMessage
                                                id={okButtonText.id}
                                                description={okButtonText.description}
                                                defaultMessage={okButtonText.defaultMessage}
                                            />
                                        }
                                        {' '}<img src="/assets/VMS_33button_go_bblue.png" />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                    </Container>
                </Modal.Body>
            </ModalStyled>
        );
// #endregion
  };
// #endregion  
export default EmailConfirmation;