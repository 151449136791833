import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import './LoginFooter.css';

class LogInFooter extends React.Component {
    render() {
        return <div className="login-footer">
            <div>With a global workforce of more than 2,800 skilled professionals in offices throughout North America,
                the UK, and Europe, Holman is known and trusted across the globe. Suppliers can enroll in the Holman network
                online or by calling 888-683-8357.
            </div>
        </div>;
    }
}

export default LogInFooter;