var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//#region Imports
import React, { useState } from "react";
import { CloseButton, Col, Container, Modal, OverlayTrigger, ProgressBar, Row, Spinner, Tooltip } from "react-bootstrap";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import styled from 'styled-components';
import { ModalClose, ModalMainHeader, ModalBody, ButtonStyled } from "shared/DesignSpecs/components/FormComponents";
import { useDropzone } from "react-dropzone";
import { uploadRepairEstimateImage } from "shared/utils/Api";
import { useAppState } from "shared/AppState/app-state";
import { GetRepairEstimateImagesDocument, useDeleteRepairEstimateImageMutation, useGetRepairEstimateImagesQuery, useSubmitAdditionalImagesMutation } from "shared/types/__generated__/graphql-type";
import FormWrapper from "shared/components/FormWrapper";
import SpinnerComponent from "shared/components/Spinner";
import * as Bluebird from "bluebird";
import configuration from "shared/configuration";
//#endregion
// #region Styled Components
const ModalStyled = styled(Modal).attrs({
    dialogClassName: "childStyle"
}) `
.childStyle {
  width: 900px;
  max-width: 900px;
  min-height: 600px;
}`;
//#endregion
//#region Component
const AccidentAssignmentsImageSubmissionModal = ({ showModal, toggleShowModal, submissionCount, repairEstimateId, viewRefetch }) => {
    // #region State
    const [isLoading, setIsLoading] = useState(false);
    const [isCleaningUp, setIsCleaningUp] = useState(false);
    const [dropZoneErrors, setDropZoneErrors] = useState(null);
    const [dropZoneFiles, setDropZoneFiles] = useState([]);
    const [currentImages, setCurrentImages] = useState([]);
    const [queryErrors, setQueryErrors] = useState(null);
    const [uploadErrors, setUploadErrors] = useState([]);
    const [deleteImagesQueue, setDeleteImagesQueue] = useState([]);
    const [uploadImagesProgress, setUploadImagesProgress] = useState(0);
    // #endregion
    // #region Ref
    // #endregion
    // #region Hooks
    const intl = useIntl();
    const [appState, dispatch] = useAppState();
    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        accept: { "image/*": [".JPG", ".JPEG", ".JPE", ".BMP", ".GIF", ".PNG", ".JFIF"] },
        onDropAccepted: acceptedFiles => {
            onDropAccepted(acceptedFiles);
        },
        onDropRejected(fileRejections, event) {
            onDropRejected(fileRejections);
        },
        onDrop(acceptedFiles, fileRejections, event) {
            setDropZoneErrors(null);
            setUploadErrors([]);
        },
        maxFiles: 100,
    });
    const { data: imagesData, loading: imagesLoading, error: imagesError, refetch: imagesRefresh } = useGetRepairEstimateImagesQuery({
        variables: {
            repairEstimateId: repairEstimateId,
            submissionNumber: submissionCount
        },
    });
    const [deleteRepairEstimateImageMutation, { loading: deleteLoading, error: deleteError }] = useDeleteRepairEstimateImageMutation({
        refetchQueries: [
            GetRepairEstimateImagesDocument,
            'GetRepairEstimateImages'
        ]
    });
    const [submitAdditionalImagesMutation, { data: additionalImagesData, loading: additionalImagesLoading, error: additionalImagesError }] = useSubmitAdditionalImagesMutation({
        variables: {
            repairEstimateId: repairEstimateId,
            submissionNumber: submissionCount
        },
    });
    // #endregion
    // #region Effects
    // #endregion
    // #region Event handlers
    const onCloseModal = (cleanUp) => __awaiter(void 0, void 0, void 0, function* () {
        if (cleanUp && _images.length !== 0) {
            yield deleteCanceledUploadedImages();
        }
        clearErrors();
        setCurrentImages([]);
        viewRefetch().finally(() => {
            toggleShowModal();
        });
    });
    const onClickDeleteImage = (repairEstimateId, imageId, fileName, e) => {
        //Disable delete button
        e.currentTarget.classList.add("disabled");
        //Add image to deletion queue
        setDeleteImagesQueue(newDeletedImagesQueue => [...deleteImagesQueue, fileName]);
        deleteRepairEstimateImageMutation({
            variables: {
                repairEstimateId: repairEstimateId,
                imageId: imageId
            }
        }).finally(() => {
            let target = e.target;
            //renable delete button
            target === null || target === void 0 ? void 0 : target.classList.remove("disabled");
            //remove image from deletion queue
            setDeleteImagesQueue(resolvedImagesQueue => resolvedImagesQueue.filter(i => { return i !== fileName; }));
            //remove image from any remaining state
            setCurrentImages(currentImages.filter(image => { return image.fileName !== fileName; }));
            setDropZoneFiles(dropZoneFiles.filter(f => { return f.name !== fileName; }));
        });
    };
    //Delete all currently uploaded images
    const deleteCanceledUploadedImages = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsCleaningUp(true);
        yield Promise.all(_images.map(i => {
            return deleteRepairEstimateImageMutation({
                variables: {
                    repairEstimateId: repairEstimateId,
                    imageId: i.imageUrl
                }
            });
        })).finally(() => setIsCleaningUp(false));
    });
    const onDropAccepted = (acceptedFiles) => {
        const uploadImages = () => __awaiter(void 0, void 0, void 0, function* () {
            let uploadedFileCount = 0;
            Bluebird.map(acceptedFiles, (file) => {
                return Promise.resolve(uploadRepairEstimateImage(file.name, submissionCount, file, repairEstimateId, appState.token))
                    .then((id) => {
                    return id;
                }, (error) => {
                    setDropZoneFiles(dropZoneFiles => dropZoneFiles.filter(f => { return f.name !== file.name; }));
                    setUploadErrors(uploadErrors => [...uploadErrors, { message: error.Message }]);
                })
                    .finally(() => {
                    uploadedFileCount++;
                    let progress = Math.floor(uploadedFileCount / acceptedFiles.length * 100);
                    setUploadImagesProgress(progress);
                });
            }, { concurrency: configuration.accmanConcurentImageUpload }).finally(() => {
                setIsLoading(false);
                imagesRefresh().then((result) => {
                    setCurrentImages(currentImages.concat(result.data.repairEstimateImages.filter(image => {
                        return acceptedFiles.some(f => {
                            return f.name === image.fileName;
                        });
                    })));
                });
            });
        });
        setDropZoneFiles(acceptedFiles);
        setUploadImagesProgress(0);
        setIsLoading(true);
        uploadImages();
    };
    const onDropRejected = (fileRejections) => {
        if (fileRejections.some(r => r.errors.some(e => e.code == "too-many-files"))) {
            setDropZoneErrors([{ message: intl.formatMessage(messages.accident_assignments_submission_rejected_max_files, { fileCount: "100" }) }]);
        }
        else {
            let errorMessage = { message: intl.formatMessage(messages.accident_assignments_submission_rejected_image) };
            setDropZoneErrors([errorMessage]);
        }
    };
    const onContinueClicked = () => {
        const messages = defineMessages({
            accident_assignments_submission_continue_validate: {
                id: "accident_assignments_submission_continue_validate",
                description: "Please upload {fileType} before continuing",
                defaultMessage: "Please upload {fileType} before continuing"
            }
        });
        clearErrors();
        if (_images.length === 0) {
            setDropZoneErrors([{ message: intl.formatMessage(messages.accident_assignments_submission_continue_validate, { fileType: "images" }) }]);
        }
        else {
            setDropZoneFiles([]);
            setIsCleaningUp(true);
            submitAdditionalImagesMutation().then(() => {
                onCloseModal(false);
            }, (error) => {
                setUploadErrors(uploadErrors => [...uploadErrors, { message: error.message }]);
            }).finally(() => { setIsCleaningUp(false); });
        }
    };
    const clearErrors = () => {
        setDropZoneErrors(null);
        setQueryErrors(null);
        setUploadErrors([]);
        setDropZoneFiles([]);
        setDeleteImagesQueue([]);
    };
    // #endregion
    // #region International messages
    const messages = defineMessages({
        cancel: {
            id: "cancel",
            description: "Cancel",
            defaultMessage: "Cancel"
        },
        accident_assignments_submission_images_drag_drop: {
            id: "accident_assignments_submission_images_drag_drop",
            description: "Drag and drop your images here or click the icon to upload one or more",
            defaultMessage: "Drag and drop your images here or click the icon to upload one or more"
        },
        accident_assignments_submission_document_drag_drop: {
            id: "accident_assignments_submission_document_drag_drop",
            description: "Drag and drop your repair estimate here or click the icon to upload one",
            defaultMessage: "Drag and drop your repair estimate here or click the icon to upload one"
        },
        submit_additional_images: {
            id: 'submit_additional_images',
            description: 'Submit additional images',
            defaultMessage: 'Submit additional images'
        },
        browse_file: {
            id: 'browse_file',
            description: 'Browse file',
            defaultMessage: 'Browse file'
        },
        files_uploaded: {
            id: 'files_uploaded',
            description: '{fileCount} file(s) uploaded',
            defaultMessage: '{fileCount} file(s) uploaded'
        },
        accident_assignments_submission_rejected_image: {
            id: 'accident_assignments_submission_rejected_image',
            description: 'Some file(s) could not be uploaded because of incorrect file type. Acceptable image types include: JPG PNG JPEG JPE BMP GIF JFIF',
            defaultMessage: 'Some file(s) could not be uploaded because of incorrect file type. Acceptable image types include: JPG PNG JPEG JPE BMP GIF JFIF'
        },
        accident_assignments_submission_rejected_max_files: {
            id: 'accident_assignments_submission_rejected_max_files',
            description: 'Upload exceeds maximum file count: {fileCount} file(s)',
            defaultMessage: 'Upload exceeds maximum file count: {fileCount} file(s)'
        },
        accident_assignments_submission_additional_images_title: {
            id: "accident_assignments_submission_additional_images_title",
            description: "Upload additional images",
            defaultMessage: "Upload additional images"
        },
        accident_assignments_submission_additional_images: {
            id: "accident_assignments_submission_additional_images",
            description: "Click below to select additional images to add to the original estimate.",
            defaultMessage: "Click below to select additional images to add to the original estimate."
        },
    });
    // #endregion
    // #region Render helpers
    const renderCloseButton = () => (React.createElement(ModalClose, { role: 'button', id: "close_btn", onClick: () => onCloseModal(true), style: { width: "fit-content", marginBottom: "0.5rem", cursor: isCleaningUp ? "default" : '', opacity: isCleaningUp ? "50%" : '' }, disabled: isCleaningUp },
        React.createElement("img", { src: "/assets/VMS_33button_kill_blue.png" }),
        "  ",
        " ",
        React.createElement(FormattedMessage, Object.assign({}, messages.cancel))));
    const getFileName = (fileName) => {
        return (React.createElement(OverlayTrigger, { placement: "right", key: fileName, overlay: React.createElement(Tooltip, { id: "tooltop" }, fileName) },
            React.createElement("div", { key: fileName }, fileName.substring(0, 25) + "...")));
    };
    // #endregion  
    let _images = currentImages;
    // #region Render
    return (React.createElement(ModalStyled, { show: showModal, backdrop: "static", keyboard: false, style: { overflow: "auto" } },
        React.createElement(ModalBody, null,
            renderCloseButton(),
            dropZoneErrors ? React.createElement(FormWrapper, { id: "dropzoneErrorMessages", key: "dropzoneErrorMessages", formErrors: dropZoneErrors })
                : null,
            (imagesError === null || imagesError === void 0 ? void 0 : imagesError.message) ? React.createElement(FormWrapper, { id: "queryErrorMessages", key: "queryErrorMessages", formErrors: [{ message: imagesError === null || imagesError === void 0 ? void 0 : imagesError.message }] })
                : null,
            (deleteError === null || deleteError === void 0 ? void 0 : deleteError.message) ? React.createElement(FormWrapper, { id: "deleteErrorMessages", key: "deleteErrorMessages", formErrors: [{ message: deleteError === null || deleteError === void 0 ? void 0 : deleteError.message }] })
                : null,
            uploadErrors.length > 0 ? React.createElement(FormWrapper, { id: "uploadErrorMessages", key: "uploadErrorMessages", formErrors: uploadErrors })
                : null,
            React.createElement(Container, { className: "accident_assignments__body_container" }, isCleaningUp ? React.createElement(SpinnerComponent, { spinnerType: "blue" }) :
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 1, className: "accident_assignments__step_col" },
                        React.createElement(Row, { className: "accident_assignments__step_count" }, 1),
                        React.createElement(Row, { className: "accident_assignments__step_border" })),
                    React.createElement(Col, null,
                        React.createElement(ModalMainHeader, null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_additional_images_title))),
                        React.createElement(Row, { className: "accident_assignments__body_text", style: { padding: "2rem 0 3.5rem 0" } },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_additional_images))),
                        React.createElement(Container, { style: { paddingLeft: "0", paddingRight: "0", minHeight: "200px" } },
                            dropZoneFiles.length === 0 && (_images === undefined || (_images === null || _images === void 0 ? void 0 : _images.length) === 0) ?
                                React.createElement("div", Object.assign({}, getRootProps({ className: "accident_assignments__upload_container" })),
                                    React.createElement("input", Object.assign({}, getInputProps(), { type: "file" })),
                                    React.createElement(Row, { className: "justify-content-center accident_assignments__body_text" },
                                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_images_drag_drop))),
                                    React.createElement(Row, { className: "justify-content-center" },
                                        React.createElement("button", { type: "button", onClick: open, className: "accident_assignments__upload_file_icon", id: "upload_btn" },
                                            React.createElement("img", { src: "/assets/Imageupload.jpg", style: { width: "30px", height: "29px" } }))))
                                : isLoading || imagesLoading ? React.createElement(ProgressBar, { animated: true, now: uploadImagesProgress })
                                    : React.createElement(Container, { fluid: true, className: "accident_assignments__estimate_submission_subsection" },
                                        React.createElement(Row, { className: "accident_assignments_image_container" }, _images.map((image, count) => (React.createElement("div", { className: "accident_assignments_image_item", key: image.fileName },
                                            React.createElement(CloseButton, { className: "accident_assignments__image_close", id: `delete_image_${count + 1}_btn`, onClick: (e) => onClickDeleteImage(repairEstimateId, image.imageUrl, image.fileName, e) }),
                                            React.createElement("div", null,
                                                deleteImagesQueue.includes(image.fileName) ? React.createElement(Spinner, { className: "accident_assignments__submission_image_spinner" }) : null,
                                                React.createElement("img", { className: "accident_assignments_submission_image", src: image.thumbnailUrl })),
                                            React.createElement("div", { className: "accident_assignments__submission_image_text" },
                                                React.createElement("div", { style: { fontStyle: "italic" } }, image.fileName.length > 25 ? getFileName(image.fileName) : image.fileName),
                                                React.createElement("div", null, `${count + 1} of ${_images === null || _images === void 0 ? void 0 : _images.length}`))))))),
                            (!isLoading && !imagesLoading) && (dropZoneFiles.length !== 0 || (_images === null || _images === void 0 ? void 0 : _images.length) !== 0) ?
                                React.createElement(Row, null,
                                    React.createElement(Col, { className: "justify-content-start align-self-center" },
                                        React.createElement(FormattedMessage, Object.assign({}, messages.files_uploaded, { values: { fileCount: _images === null || _images === void 0 ? void 0 : _images.length } }))),
                                    React.createElement(Col, { className: "justify-content-end d-flex" },
                                        React.createElement(ButtonStyled, { role: "button", onClick: open, disable: false, style: { width: "fit-content", paddingRight: "0", paddingTop: "0" }, id: "additional_upload_btn" },
                                            React.createElement(FormattedMessage, Object.assign({}, messages.browse_file)),
                                            " ",
                                            React.createElement("img", { src: "/assets/VMS_28button_edit_clear.png" }))))
                                : null),
                        React.createElement(Row, null,
                            React.createElement(Col, { className: "justify-content-end d-flex" },
                                React.createElement(ButtonStyled, { role: "button", onClick: () => { onContinueClicked(); }, style: { width: "fit-content", paddingRight: "0", paddingTop: "3.5rem", opacity: isLoading || imagesLoading ? "50%" : "" }, disable: isLoading || imagesLoading, id: "continue_btn" },
                                    React.createElement(FormattedMessage, Object.assign({}, messages.submit_additional_images)),
                                    " ",
                                    React.createElement("img", { src: "/assets/VMS_33button_go_bblue.png" }))))))))));
    // #endregion
};
// #endregion
export default (AccidentAssignmentsImageSubmissionModal);
