import React from 'react';
import {Link} from 'react-router-dom';
import { defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { Map } from 'immutable';
import './PIPPOList.css';
import {OverlayTrigger,Tooltip} from 'react-bootstrap';
import Help from 'components/Help';
import { IntlProvider } from 'react-intl';
import Features from 'components/Features';

class PIPPOList extends React.Component {

    getTooltip(toolTipText){
        return <Tooltip id="tooltip"> {toolTipText} </Tooltip>;
    }

    getVehicle(vin8,vehicleYear,vehicleMake,vehicleModel) {

        return <OverlayTrigger
        placement='right'
        key={vin8}
        overlay={this.getTooltip((vehicleYear == null ? '' : vehicleYear)
            + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))}
    >
        <div key={vin8}>
            {(vehicleYear + ' ' + vehicleMake + ' ' + vehicleModel).length > 10
                ? ((vehicleYear == null ? '' : vehicleYear)
            + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel)).substr(0, 9) + '...'
                : ((vehicleYear == null ? '' : vehicleYear)
            + ' ' + (vehicleMake == null ? '' : vehicleMake) + ' ' + (vehicleModel == null ? '' : vehicleModel))
    }
            </div>
        </OverlayTrigger>

            }

    getReferenceNo(refNo) {

                return <OverlayTrigger
                placement='right'
                overlay={this.getTooltip(refNo == null ? "None" : refNo)}
            >
                <div>
                    {refNo == null ? "None" : refNo.length > 5
                        ? (refNo == null ? "None" : refNo).substr(0, 5) + '...'
                        : (refNo == null ? "None" : refNo)
                    }
                </div>
            </OverlayTrigger>

            }

    render() {
        const messages = defineMessages({
            payment_in_progress_caption: {
                id: 'payment_in_progress_caption',
                description: 'Caption for Payment in progress POs',
                defaultMessage: 'Payment in progress'
            },
            payment_in_progress_vehicle_column_header: {
                id: 'payment_in_progress_vehicle_column_header',
                description: 'Header for Vehicle Column',
                defaultMessage: 'Vehicle'
            },
            payment_in_progress_vin_column_header: {
                id: 'payment_in_progress_vin_column_header',
                description: 'Header for VIN Column',
                defaultMessage: 'VIN'
            },
            payment_in_progress_po_number_column_header: {
                id: 'payment_in_progress_po_number_column_header',
                description: 'Header for PO Number Column',
                defaultMessage: 'PO no.'
            },
            active_po_reference_number_column_header: {
                id: 'active_po_reference_number_column_header',
                description: 'Header for ref # Column',
                defaultMessage: 'Your Ref #'
            },
            payment_in_progress_po_total_amount_column_header: {
                id: 'payment_in_progress_po_total_amount_column_header',
                description: 'Header for PO Total Amount Column',
                defaultMessage: 'Amount'
            },
            payment_in_progress_po_view_link_caption: {
                id: 'payment_in_progress_po_view_link_caption',
                description: 'Text for PO View link',
                defaultMessage: 'VIEW'
            },
            payment_in_progress_no_pos_caption: {
                id: 'payment_in_progress_no_pos_caption',
                description: 'Caption to display if no POs are available for display on Shop View',
                defaultMessage: 'None at this Time'
            },
            payment_in_progress_today: {
                id: 'payment_in_progress_today',
                description: 'Text for po date column when po date is Today',
                defaultMessage: 'Today'
            },
            none:{
                id: 'none',
                description: 'Text for po number column when po is not approved',
                defaultMessage: 'none'
            }
        });

        const today = new Date();
        const activePOs = this.props.activePOs.sortBy(po=>new Date(po.get('createdDate'))).reverse();

        return <Features render={restrictedFeatures => (<div className="pip-po-list table-responsive">
            <div className="pip-po-list__caption">
                <FormattedMessage {...messages.payment_in_progress_caption}/>
        {restrictedFeatures.showVideoLinks?<Help videoId="payment_in_progress" whiteSpaceAtBottomHeight="1.25em"/>:false}

        </div>
        <table className="pip-po-list__table" width="100%">
            <thead>
            <tr>
                <th className="pip-po-list__header-cell">
                    <FormattedMessage {...messages.payment_in_progress_vehicle_column_header}/>
                </th>
                <th className="pip-po-list__header-cell">
                    <FormattedMessage {...messages.payment_in_progress_vin_column_header}/>
                </th>
                <th className="pip-po-list__header-cell">
                    <FormattedMessage {...messages.payment_in_progress_po_number_column_header}/>
                </th>
                <th className="pip-po-list__header-cell">
                    <FormattedMessage {...messages.active_po_reference_number_column_header}/>
                </th>
                <th className="pip-po-list__header-cell">
                    <FormattedMessage {...messages.payment_in_progress_po_total_amount_column_header}/>
                </th>
                <th className="pip-po-list__header-cell"/>
            </tr>
            </thead>
            <tbody>
            {
                (activePOs.count() == 0)
                    ?  <tr>
                            <td colSpan="6" className="pip-po-list__cell pip-po-list__cell_align-center">
                             <FormattedMessage {...messages.payment_in_progress_no_pos_caption}/>
                            </td>
                        </tr>

                    :

            activePOs.valueSeq().map(po =>
                <tr key={po.get('id')} className="pip-po-list__row">
                    <td className="pip-po-list__cell">
                {this.getVehicle(po.get('vehicleVIN8'),po.get('vehicleYear'),po.get('vehicleMake'),po.get('vehicleModel'))}
                                </td>
                                <td className="pip-po-list__cell">
                                {po.get('vehicleVIN8')}
                                </td>
                                    <td className="pip-po-list__cell">
                                        {po.get('poNumber')}
                                    </td>
                                    <td className="pip-po-list__cell">
                                        {po.get('vendorReferenceNo')==null?
                                        <FormattedMessage {...messages.none}/>:                                        
                                        this.getReferenceNo(po.get('vendorReferenceNo'))}
                                    </td>
                                    <td className="pip-po-list__cell">
                                     <IntlProvider locale="en">
                                         <FormattedNumber value={po.get('totalAmount')}
                                    style="currency"
                                    currency={this.props.selectedCurrency}
                                    minimumFractionDigits={2}/>
                </IntlProvider>
               </td>
               <td className="pip-po-list__cell
                                    pip-po-list__cell_edit-action">
                         <Link to={`/PO/view/${po.get('id')}`}>
                             <FormattedMessage {...messages.payment_in_progress_po_view_link_caption}/>
                         </Link>
                     </td>
                 </tr>
         )
                         }
     </tbody>
 </table>
 </div>)}/>;
                         }
                         }


export default PIPPOList;