import { useState } from "react";
import * as Api from "utils/Api";
import { getErrorObject } from "screens/App/shared/utils/HelperFunctions";

export const useLineItemValidator = (token, intialConfirmationSize) => {
    //Validation objects returned from api
    const [priceValidations, setPriceValidations] = useState();
    //Times validation has ran
    const [priceValidationShownCount, setPriceValidationShownCount] = useState(0);
    //Max number of times validation will run
    const priceValidationThreshold = 1;
    //Array of bools representing price confirmation checkboxes
    const [pricesConfirmedList, setPricesConfirmedList] = useState(new Array(intialConfirmationSize).fill(false));
    //Error Message
    const [priceValidationErrorMessage, setPriceValidationErrorMessage] = useState();

    async function fetchApiAsync(lineItem) {
        try {          
            const result = await Api.validateLineItemPrice(lineItem.poNumber, lineItem, token);
            return result;

        } catch (error) {
            throw error;
        }

    }

    const validateLineItemAsync = async (lineItems) => {
        try {
            //Clear errors
            setPriceValidationErrorMessage();

            //return true if shown count exceeds threshold
            if (priceValidationShownCount >= priceValidationThreshold) {
                return true;
            }

            if (lineItems.length > 0) {
                const results = await Promise.all(
                    lineItems.map(async (lineItem) => {
                        return await fetchApiAsync(lineItem);
                    })
                )

                let priceValidationResults = results.map((result) => {
                    return {
                        ataCode: result.AtaCode,
                        lineItemValidationReferenceId: result.LineItemValidationReferenceId,
                        partscostexceeds: result.PriceValidation.UnitPriceExceedsThreshold,
                        menupricingexceeds: result.PriceValidation.TotalAmountExceedsThreshold
                    }
                })
                setPriceValidations(priceValidationResults);
                setPriceValidationShownCount(priceValidationShownCount + 1);

                //return false if any element in priceValidationResults exceeds
                if (priceValidationResults.some((result) =>
                    result.menupricingexceeds || result.partscostexceeds
                )) {
                    return false;
                }

            }

            return true;
        } catch (error) {
            setPriceValidationErrorMessage(getErrorObject(error));
        }
    }
    
    const handlePriceValdationConfirmationButtonClick = (index) =>{
            const newConfirmedList = pricesConfirmedList.map((p,i) => {
                if(i === index){
                    return !p;
                }else{
                    return p;
                }
            });
            setPricesConfirmedList(newConfirmedList);
    }

    const priceValidationCleanUp = () =>{
        setPriceValidationShownCount(0);
        setPriceValidations(null);
        setPricesConfirmedList(new Array(intialConfirmationSize).fill(false));
    }

    return {
        validateLineItemAsync,
        priceValidations,
        pricesConfirmedList,
        handlePriceValdationConfirmationButtonClick,
        priceValidationCleanUp,
        priceValidationErrorMessage
    }
}