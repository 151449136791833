import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Overlay, Popover } from 'react-bootstrap';
import * as actions from '../actions/Login';
import * as VMSCookie from 'utils/CookieHelper';
import { connect } from 'react-redux';
import './LoginHeader.css';
import Spinner from 'components/Spinner';
import * as sharedActions from '../../../../shared/actions/App';
import LoginHeaderSelector from '../selectors/LoginHeader';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import FormWrapper from 'components/FormWrapper';
import Help from 'components/Help';
import TranslationIcon from 'components/SiteTranslations';
import * as CookieHelper from 'utils/CookieHelper';
import { withRouter } from 'react-router-dom'

const LogInHeader = (props) =>  {
    //State
    const [rememberMe, setRememberMe] = useState((VMSCookie.readCookie('vms_vendorId') == null || VMSCookie.readCookie('vms_vendorId') == '') ? false : true);
    const [vendorId, setVendorId] = useState((VMSCookie.readCookie('vms_vendorId') == null || VMSCookie.readCookie('vms_vendorId') == '') ? '' : JSON.parse(VMSCookie.readCookie('vms_vendorId')).vendorId);
    const [languageLocaleCookie, setLanguageLocaleCookie] = useState((VMSCookie.readCookie('vms_language_preference') == null || VMSCookie.readCookie('vms_language_preference') == '') ? "en-US" : JSON.parse(VMSCookie.readCookie('vms_language_preference')).languageLocaleCookie);

    //Refs
    const imgUS = useRef();
    const imgCanada = useRef();
    const imgMexico = useRef();
    const rememberMeRef = useRef();
    const txtUserId = useRef();
    const txtPassword = useRef();

    useEffect(() => {
        if (VMSCookie.readCookie('vms_vendorId') != null && VMSCookie.readCookie('vms_vendorId') != '') {
            props.dispatch(sharedActions.setSelectedCountry(JSON.parse(VMSCookie.readCookie('vms_vendorId')).selectedCountry));
        }
        if (VMSCookie.readCookie('vms_language_preference') !== null && VMSCookie.readCookie('vms_language_preference') !== '') {
            props.dispatch(sharedActions.selectLocale(languageLocaleCookie));
        }
    }, []);    

    const onLogInClick = (event) => {
        event.preventDefault();

        const userId = txtUserId.current.value;
        const password = txtPassword.current.value;

        if (userId != '' && password != '') {

            props.dispatch(actions.signInClicked(userId.trim(), password, rememberMeRef.current.checked));

        }

    }
    
    const onRememberMeChange = () => {
        setRememberMe(rememberMe.current.checked);
    }

    const onHide = () => {

        props.dispatch(actions.clearLoginErrorMessage());

    }

    const validatePassword = () => {

        return true;
    }

    const validateUserId = () => {

        return true;

    }

    const onFlagChange = (event) => {

        const country = event.currentTarget.attributes.value.value;
        props.dispatch(sharedActions.setSelectedCountry(country));
        if (country === 'MEX') {
            props.dispatch(sharedActions.selectLocale('es-MX'));
            CookieHelper.createCookie('vms_language_preference', JSON.stringify({
                languageLocaleCookie: 'es-MX',
            }));
        } else if (languageLocaleCookie == 'es-MX') {
            props.dispatch(sharedActions.selectLocale('en-US'));
            CookieHelper.createCookie('vms_language_preference', JSON.stringify({
                languageLocaleCookie: 'en-US',
            }));
        }
    }
        const { formatMessage } = props.intl;
        const messages = defineMessages({
            login_id: {
                id: 'login_id',
                description: 'ID',
                defaultMessage: 'ID'
            },
            password: {
                id: 'password',
                description: 'Password',
                defaultMessage: 'Password'
            },
            rememberMe: {
                id: 'rememberMe',
                description: 'Remember me',
                defaultMessage: 'Remember me'
            },
            selectCountry: {
                id: 'selectCountry',
                description: 'Select your country',
                defaultMessage: 'Select your country'
            },
            headerCaption: {
                id: 'headerCaption',
                description: 'Supplier sign in',
                defaultMessage: 'Supplier sign in'
            },
            recover_password_text: {
                id: 'recover_password_text',
                description: 'Forgot password link',
                defaultMessage: 'Forgot your password?'

            }
        });

        return <div className='login'>

            <div className="login-header">
                <form className="row">

                    <div className="login-header__logo-caption col-auto">

                        <img className="login-header__logo" src="/assets/Logo-Primary-PMS.png" />

                    </div>
                    <div className="col">
                        <div className='row gx-0 login-header__id-password-group'>

                            <div className="col-auto">

                                <label
                                    className="login-header__input_label_small form-label"><FormattedMessage {...messages.selectCountry} /></label>

                            </div>


                            <div className="col-auto">

                                <img ref={imgUS} src="/assets/VMS_US_Flag.jpg" className={classnames(
                                    { 'login-image-selected': props.selectedCountry == 'USA' },
                                    { 'none': props.selectedCountry != 'USA' }
                                )} title="United States" role="button" value="USA" onClick={onFlagChange.bind(this)} />
                                {" "}


                            </div>


                            <div className="col-auto">

                                <img ref={imgCanada} className={classnames(
                                    { 'login-image-selected': props.selectedCountry == 'CAN' },
                                    { 'none': props.selectedCountry != 'CAN' }
                                )}
                                    src="/assets/VMS_CANADA_Flag.jpg" role="button" value="CAN" title="Canada"
                                    onClick={onFlagChange.bind(this)} />
                            </div>
                            <div className="col-auto">

                                <img ref={imgMexico} className={classnames(
                                    { 'login-image-selected': props.selectedCountry == 'MEX' },
                                    { 'none': props.selectedCountry != 'CAN' }
                                )}
                                    src="/assets/VMS_MEXICO_Flag.jpg" role="button" value="MEX" title="Mexico"
                                    onClick={onFlagChange.bind(this)} />
                            </div>

                            <div className="col-auto">
                                <span className="login-header__nav-link d-none d-xl-block">|</span>
                            </div>
                            <div className="col">
                                <TranslationIcon backgroundColor='grey' />
                            </div>
                        </div>

                        <div className='container gx-0'>
                            <div className='row'>
                                <div>
                                    <div className='row gx-1'>
                                        <div className='col-auto'>
                                            {props.errorMessage == undefined ? false :
                                                <Overlay
                                                    show={props.errorMessage == undefined ? false : true}
                                                    onHide={onHide}
                                                    placement="bottom"
                                                    target={txtUserId.current}
                                                    rootClose
                                                >
                                                    <Popover id="popOver" className="ErrorMessage">
                                                        {props.errorMessage}
                                                    </Popover>
                                                </Overlay>
                                            }
                                            <input type="text"
                                                onBlur={validateUserId}
                                                className="login-header__input_box2 form-control"
                                                size="10"
                                                maxLength="50"
                                                ref={txtUserId}
                                                defaultValue={vendorId}
                                                placeholder={"ID"}
                                                autoFocus


                                            />
                                        </div>

                                        <div className="col-auto login-header__password-group">
                                            <div><input type="password"
                                                className="login-header__input_box form-control"
                                                size="12"
                                                ref={txtPassword}
                                                onBlur={validatePassword}
                                                maxLength="24"
                                                placeholder={formatMessage(messages.password)}

                                            />
                                            </div>
                                        </div>

                                        <div className=" login-header__submit">

                                            <div className="login-header__submit-btn">
                                                <div>
                                                    <input type="image" src="/assets/VMS_33button_go_bblue.png" role="button"
                                                        onClick={(e) => onLogInClick(e)} />
                                                </div>
                                                <div className="login-header__submit-btn_label"><span>&nbsp;</span></div>
                                            </div>
                                            {props.isfetchingData
                                                ? <div className="login-header__submit-spinner">
                                                    <div className="login-header__spinner-container">
                                                        <Spinner />
                                                    </div>
                                                    <div className="login-header__input_label"><span>&nbsp;</span></div>
                                                </div>

                                                : undefined
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="row gx-0 login-header__remember_me_checkbox" style={{ marginLeft: "0.5rem", marginBottom: "1rem" }}>

                                    <input type="checkbox"
                                        tabIndex="-1"
                                        ref={rememberMeRef}
                                        checked={rememberMe}
                                        onChange={onRememberMeChange}
                                        className="col-auto login-header__remember_me_checkbox" />
                                    <label className="col-auto login-header__remember_me"><FormattedMessage {...messages.rememberMe} /></label>
                                    <div className='col-auto'>
                                        {props.selectedCountry == "MEX" ? false : <Help videoId="username_and_password" whiteSpaceAtBottomHeight="0.2em" />}
                                    </div>
                                </div>

                                <div className="row gx-0 login-header__input_label_password" style={{ marginLeft: "0.5rem" }}>
                                    <Link className="col-auto login-header__input_label_password" to='/recoverPassword' >
                                        <FormattedMessage {...messages.recover_password_text} />
                                    </Link>
                                    <div className='col-auto'>
                                        {props.selectedCountry == "MEX" ? false : <Help videoId="forgot_password" whiteSpaceAtBottomHeight="0.2em" />}
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>
                </form>
            </div>

        </div>;
    }


export default connect(LoginHeaderSelector)(injectIntl(LogInHeader));