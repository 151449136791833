import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map, fromJS} from 'immutable';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';

export function uiDataAdjustment(state = initialAppState.getIn(['uiData', 'adjustment']), action) {
    switch (action.type) {                
        default:
            return state;
    }
}

export function serverDataAdjustment(state = initialAppState.getIn(['serverData', 'adjustment']), action) {
    switch (action.type) {
        case constants.FETCH_ADJUSTMENT_DATA_REQUESTED:             
            return state
                .set('isFetchingData', true)
                .delete('lineItemIdBeingEdited');
        case constants.FETCH_ADJUSTMENT_DATA_REJECTED:
            return state
                .set('isFetchingData', false)
                .set('errorMessage',action.errorMessage);
        case constants.FETCH_ADJUSTMENT_DATA_RESOLVED:            
            return state
                .set('purchaseOrder', fromJS(action.purchaseOrderData.purchaseOrder))                
                .set('vehicleDetails', fromJS(action.purchaseOrderData.vehicleDetails))                
                .set('vendor', fromJS(action.purchaseOrderData.vendor))
                .set('complaints', fromJS(action.purchaseOrderData.complaints))
                .set('notesHistory', fromJS(action.purchaseOrderData.notes))
                .set('lineItems', fromJS(action.purchaseOrderData.lineItems))
                .set('isDirty', false)
                .set('isFetchingData', false)
                .delete('errorMessage');
        case constants.ADJUSTMENT_EDIT_LINE_ITEM:
            return state
                .set('lineItemIdBeingEdited', action.lineItemKey)
                .setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], true);
        case constants.ADJUSTMENT_UPDATE_LINE_ITEM_REQUESTED:
            return state.delete('errorMessage');
        case constants.ADJUSTMENT_UPDATE_LINE_ITEM_RESOLVED:
            return state
                .mergeIn(['lineItems', action.lineItemKey], action.lineItem)                
                .setIn(['lineItems', action.lineItemKey, 'isAdjustedByPartVendor'], true)
                .delete('lineItemIdBeingEdited')
                .setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], false)
                .set('isDirty', false);                
        case constants.ADJUSTMENT_UPDATE_LINE_ITEM_REJECTED:                
            return state                
                .delete('lineItemIdBeingEdited')
                .setIn(['lineItems', action.lineItemKey, 'isAdjustedByPartVendor'], true)
                .setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], false)
                .setIn(['lineItems', action.lineItemKey, 'costError'], action.errorMessage[0].message)                
                .set('isDirty', false);
        case constants.ADJUSTMENT_CANCEL_UPDATE_LINE_ITEM:
            return state
                .delete('lineItemIdBeingEdited')
                .setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], false)
                .set('isDirty', false);
        case constants.ADJUSTMENT_DELETE_LINE_ITEM_RESOLVED:
            return state.deleteIn(['lineItems', action.lineItemKey]);
        case constants.ADJUSTMENT_DOCUMENT_CLICK:
            return state
                .set('lineItems', state.get('lineItems').map((item, key)=>item.delete('costError'))); // Clear all cost errors.
        default:
            return state;
    }
}