// libraries
import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import {Map} from 'immutable';
// our stuff
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
import * as helperFunctions from 'utils/HelperFunctions';

export function* fetchData(action) {
    try {
        const state = yield select();
        const token = helperFunctions.getToken(state);
        const vendorId = helperFunctions.getVendorId(state);        
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
        yield put({ type: constants.FETCH_VENDOR_DOCUMENTS_DATA_REQUESTED });        
        
		const files = yield call(Api.fetchVendorDocuments, vendorId, token,vendorLanguage);

		const data = { files };

		yield put({type: constants.FETCH_VENDOR_DOCUMENTS_DATA_RESOLVED, data});                                                 
    }
    catch (e) {
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_VENDOR_DOCUMENTS_DATA_REJECTED, errorObject: e });
        yield put({ type: constants.FETCH_VENDOR_DOCUMENTS_DATA_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });    
   
    }
}

function validateDocument(fileInfo) {    
    let validationErrors = {};

    if (!fileInfo || fileInfo.count() == 0) 
        validationErrors.general = 'Error';
    
    if (!fileInfo.get('name')) 
        validationErrors.name = 'documents_name_required';

    if (!fileInfo.get('documentType')) 
        validationErrors.documentType = 'documents_document_type_required';            
    
    return Map(validationErrors);
}

export function* uploadDocument(action) {
    try {        
        const state = yield select();
        const token = helperFunctions.getToken(state);        
        const vendorId = helperFunctions.getVendorId(state);
        const fileInfo = state.appState.getIn(['uiData', 'vendorDocuments', 'fileInfo']);
        const file = action.file;        
        const vendorLanguage = helperFunctions.getSelectedLanguageCode(state);
        // Validate file information
        let validationErrors = validateDocument(fileInfo);        
        if (validationErrors.count() > 0) {
            yield put({type: constants.VENDOR_DOCUMENTS_VALIDATION_FAILED, validationErrors});
        }
        else {
            // Inform application that PO data is being fetched
            yield put({type: constants.UPLOAD_VENDOR_DOCUMENT_REQUESTED});
                        
			let id = yield call(Api.uploadVendorDocument, token, vendorId, file, fileInfo,vendorLanguage);            
                            
            let uploadedFileInfo = Map();        
            uploadedFileInfo = uploadedFileInfo.set(id, 
                Map({
                    name: fileInfo.get('name'),
                    createdDate: fileInfo.get('createdDate'),
                    documentType: fileInfo.get('documentType'),
                    id
                })
            );

            yield put({type: constants.SET_VENDOR_DOCUMENTS_STEP, step: constants.VENDOR_DOCUMENT_STEPS.DOCUMENTS});
            yield put({type: constants.CLEAR_FILE_INFO_VENDOR_DOCUMENTS});

            yield put({type: constants.UPLOAD_VENDOR_DOCUMENT_RESOLVED, uploadedFileInfo});        
        }
    }
    catch (e) {        
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.UPLOAD_VENDOR_DOCUMENT_REJECTED, errorObject: e });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });       
        yield put({ type: constants.UPLOAD_VENDOR_DOCUMENT_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });    
    }
}