export function dealerDraftFetchDealerRequests(){
    return { type: "FETCH_DEALER_DRAFT_DEALER_REQUESTS"}
}

export function dealerDraftToggleShowAllActiveRequests() {
    return {type: "TOGGLE_SHOW_ALL_ACTIVE_REQUESTS"}
}

export function dealerDraftToggleShowAllProcessedRequests() {
    return {type: "TOGGLE_SHOW_ALL_PROCESSED_REQUESTS"}
}