import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Modal } from 'react-bootstrap';
import AdjustedLineItem from '../components/AdjustedLineItem';
import NewLineItem from '../components/NewLineItem';
import RejectedLineItem from '../components/RejectedLineItem';
import NeedMoreNotesLineItem from '../components/NeedMoreNotesLineItem';
import { LINE_ITEM_TYPE, LINE_ITEM_STATUS } from 'constants/App';
import { useAppState } from 'shared/AppState/app-state';

const ReviewActionRequiredItemView = (props) => {

    const [appState, dispatch] = useAppState();

    const closeModalHandler = (e) => {
        props.closeModalHandler();
    }
    const lineItem = props.lineItem;
    const productDescription = (appState.isProductCodeFeatureEnabled && lineItem.get('vendorProductCode') !== undefined && lineItem.get('vendorProductCode') !== null ? lineItem.get('vendorProductCode') + ' - ' : '') + lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase());
    const submittedQty = lineItem.get('previousQuantity') != null ? lineItem.get('previousQuantity') : lineItem.get('quantity'); //if previous value is null, that means Holman tech did not adjust this field
    const submittedCost = lineItem.get('previousUnitPrice') != null ? lineItem.get('previousUnitPrice') : lineItem.get('unitPrice');
    const submittedTotal = submittedQty * submittedCost;
    const ariQty = lineItem.get('quantity');
    const ariCost = lineItem.get('unitPrice');
    const ariTotal = lineItem.get('totalAmount');
    const hasAdjustment = lineItem.get('previousQuantity') != null || lineItem.get('previousUnitPrice') != null;
    const lineNotes = lineItem.get('authorizationStatusReason');
    const approvalStatus = lineItem.get('approvalStatus');
    const actionRequiredStatus = lineItem.get('ActionRequiredStatus');

    return <Modal show={props.showModalActionRequired} dialogClassName="action_required_dialog">
        <Modal.Body>
            {
                actionRequiredStatus == LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES ?
                    <NeedMoreNotesLineItem selectedCountry={props.selectedCountry} associatedPendingLines={props.associatedPendingLines} lineItem={props.lineItem} closeModalHandler={closeModalHandler} selectedCurrency={props.selectedCurrency} onSubmitVendorNotesClickHandler={props.onSubmitVendorNotesClickHandler} isHsbClientVehicle={props.isHsbClientVehicle}
                    entityForAssistance={props.entityForAssistance} fleetAdminContactInfo={props.fleetAdminContactInfo} fleetAdminContactInfoError={props.fleetAdminContactInfoError} /> :
                    actionRequiredStatus == 'CREATED_BY_ARI' ?
                        <NewLineItem selectedCountry={props.selectedCountry} lineItem={props.lineItem} selectedCurrency={props.selectedCurrency} closeModalHandler={closeModalHandler} onAcceptNewLineClickHandler={props.onAcceptNewLineClickHandler} isHsbClientVehicle={props.isHsbClientVehicle}
                        entityForAssistance={props.entityForAssistance} fleetAdminContactInfo={props.fleetAdminContactInfo} fleetAdminContactInfoError={props.fleetAdminContactInfoError} /> :
                        actionRequiredStatus == 'ADJUSTED' ?
                            <AdjustedLineItem isPOAdjustedBySystem={props.isPOAdjustedBySystem} selectedCountry={props.selectedCountry} associatedPendingLines={props.associatedPendingLines} pendingLineTypes={props.pendingLineTypes} lineItem={props.lineItem} closeModalHandler={closeModalHandler} selectedCurrency={props.selectedCurrency} onAcceptAdjustmentsClickHandler={props.onAcceptAdjustmentsClickHandler} isHsbClientVehicle={props.isHsbClientVehicle}
                            entityForAssistance={props.entityForAssistance} fleetAdminContactInfo={props.fleetAdminContactInfo} fleetAdminContactInfoError={props.fleetAdminContactInfoError} /> :
                            approvalStatus == LINE_ITEM_STATUS.REJECTED ?
                                <RejectedLineItem selectedCountry={props.selectedCountry} lineItem={props.lineItem} closeModalHandler={closeModalHandler} selectedCurrency={props.selectedCurrency} onAcceptRejectionClickHandler={props.onAcceptRejectionClickHandler} isHsbClientVehicle={props.isHsbClientVehicle}
                                entityForAssistance={props.entityForAssistance} fleetAdminContactInfo={props.fleetAdminContactInfo} fleetAdminContactInfoError={props.fleetAdminContactInfoError} />
                                : undefined
            }
        </Modal.Body>

    </Modal>
}

export default ReviewActionRequiredItemView;
