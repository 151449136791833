import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import LineItems from './LineItems';
import './Services.css';
import ComplaintNotesReadOnly from 'AddEditView/ComplaintNotesReadOnly.js';

class Services extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const messages = defineMessages({
            services_view_caption_2: {
                id: 'services_view_caption_2',
                description: 'Maintenance',
                defaultMessage: 'Maintenance'
            }
        });

        return <div className={classNames('adjust_po_services_view')}>
            <div className="row adjust_po_services_view__caption">
                <div className="col-md-12">
                    <FormattedMessage {...messages.services_view_caption_2} />
                </div>
            </div>

            <div className="adjust_po_services_view__notesText">
                {this.props.notesHistory != undefined ?
                    <ComplaintNotesReadOnly notes={this.props.notesHistory}/>
                : undefined}
            </div>

                    <LineItems lineItems={this.props.lineItems}
                       actionType={this.props.actionType}
                       complaintCode="MA99"
                       isServiceComplaint={this.props.hideAddLineItemsForServices}
                       hideCostInformation = {this.props.hideCostInformation}
                        onOpenTiresInfo = {this.props.onOpenTiresInfo}
                       selectedCurrency={this.props.selectedCurrency}
                       />
        </div>;
    }
}



export default Services;