import React from "react";
import { useAppState } from "../screens/App/shared/AppState/app-state";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import configuration from '../screens/App/shared/configuration';

const httpLink = new HttpLink({
  uri: configuration.AccmanApiUrl + "graphql/",
});

const ApolloProviderWrapper = (props) => {
  const [ appState, dispatch ] = useAppState();

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = appState.token;
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      authLink,
      httpLink
    ]),
  });

  return (
    <ApolloProvider client={client}>
      <div>
        {props.children}
      </div>
    </ApolloProvider>
  );
}

export default ApolloProviderWrapper;