export default {
  "skip_the_phone": "Skip the phone: Create a PO",
  "help_link": "Help",
  "contact_link": "Contact",
  "search_vehicle_by_vin8": "Search vehicle",
  "your_account": "Your Account",
  "sign_out": "Sign Out",
  "help_link_2": "Help",
  "contact_link_2": "Contact",
  "open_pos_link_2": "View all maintenance purchase orders",
  "open_pos_link_header": "View Orders",
  "close": "Close",
  "ok": "OK",
  "session_expiring_caption": "Session Expiring!",
  "session_expiring_note": "Your session will expire in 30 minutes; press OK to remain logged in.  If you log off, any changes on this page will not be saved.",
  "login_id": "ID",
  "password": "Password",
  "rememberMe": "Remember me",
  "selectCountry": "Select your country",
  "headerCaption": "Supplier sign in",
  "add_another_complaint": "Add another complaint",
  "add_line_items_for_complaint": "Add line items for {complaintType}",
  "add_line_items_for_services": "Add line items for Services due",
  "add_edit_view_complaint_view_complaint_type": "Complaint type",
  "add_edit_view_complaint_view_complaint_caption": "Complaint",
  "line_item_view_approved": "Approved line Items",
  "line_item_view_pending": "Pending line Items",
  "line_item_view_rejected_items": "Rejected line Items",
  "line_item_view_quantity": "Qty",
  "line_item_view_hour": "Hour",
  "line_item_view_cost": "Cost",
  "line_item_view_amount": "Amount",
  "po_totals_view_subtotals": "Subtotal",
  "po_totals_view_approved": "Approved",
  "po_totals_view_pending_approval": "Pending approval",
  "approved_pos_caption": "Approved for service",
  "waiting_for_ari_approval_caption": "Waiting for Holman approval",
  "waiting_for_ari_approval_caption_mex":"Waiting for ARIZA approval",
  "waiting_for_client_approval_caption": "Waiting for client approval",
  "waiting_for_driver_validation_caption": "Waiting for driver validation",
  "purchase_order_payment_in_progress": "Payment in progress",
  "your_action_required": "Your action required",
  "edit_purchase_order": "Purchase order no.",
  "edit_purchase_order_edit_mode": "Purchase order no. {poNumber}",
  "close_purchase_order": "Close purchase order no.",
  "purchase_order_no": "Purchase order no. {poNumber}",
  "approved_po_total": "Approved total:",
  "bank_account_no": "Last four digits of bank account: {bankAccountNo}",
  "close_dialog": "Close",
  "add_line_item_to_po": "Add line item to PO",
  "adjust_hours": "Hours",
  "adjust_rate": "Rate",
  "adjust_amount": "Amount",
  "pm_qty": "Qty",
  "pm_cost": "Cost",
  "pm_amount": "Amount",
  "repair_hours": "Hours",
  "repair_rate": "Rate",
  "repair_amount": "Amount",
  "replace_hours": "Hours",
  "replace_rate": "Rate",
  "replace_qty": "Qty",
  "replace_cost": "Part Cost",
  "replace_amount": "Amount",
  "freq_used_ata_code": "Or choose from frequently entered parts:",
  "save_and_add_caption": "Save and add additional line items",
  "close_without_save": "Close without saving",
  "save": "Save",
  "add_pm_caption": "Add Services Due",
  "service_header_caption": "Service",
  "total_part_header_caption": "Parts",
  "total_labor_header_caption": "Labor",
  "total_amount_header_caption": "Total",
  "parameter_caption": "Parameters",
  "problem_with_close_po": "Found a problem? Edit PO before closing",
  "total": "Total",
  "tax_required": "Check here to verify that no taxes are required for this PO",
  "invoice_number": "Invoice no.",
  "invoice_date": "Invoice date",
  "invoice_name": "Your Name",
  "subtotal": "Subtotal",
  "repair_completion_date": "Repair completion date",
  "tax_exempted": "Tax Exempted ?",
  "enter_tax": "Enter tax",
  "tax_exempted_yes": "Yes",
  "tax_exempted_no": "No",
  "tax_gst": "GST",
  "tax_pst": "PST",
  "tax_hst": "HST",
  "tax_qst": "QST",
  "payment_confirmation_text": "Confirm the payment will be credited to account ending in {bankAccountNo}",
  "work_details_text": "Confirm that work detailed in the purchase order is fully completed",
  "no_tax_reason": "No-Tax reason",
  "no_tax_reason_repair_type": "Repair type is tax exempt",
  "no_tax_reason_interstate": "Interstate commerce vehicle exempt",
  "close_po_caption": "Request payment of ${total} and close PO",
  "error_invoice_no": "Please enter an Invoice Number longer than 3 characters and remove any empty spaces",
  "error_invoice_date": "Please enter Invoice Date.",
  "error_invoice_name": "Please enter First and last name.",
  "error_invoice_completion_date": "Please enter Repair Completion Date.",
  "error_invoice_tax": "Please enter tax.",
  "error_exempted_reason": "Please provide no tax reason.",
  "error_payment_confirmation": "Please confirm the account number.",
  "error_work_details": "Please confirm the work is complete.",
  "page_title": "Close purchase order no.",
  "close_confirmation_title": "Adjust PO",
  "close_confirmation_message": "Are there any adjustments required in this PO?",
  "close_confirmation_yes": "Yes",
  "close_confirmation_no": "No",
  "contact_customer_directly": "This PO is for a Holman Small Business Solutions client. For this transaction, you will be dealing directly with the client rather than Holman. If you have any questions about this PO, please call the client directly at the number provided once the PO is created.",
"dot_inspection__title": 'DOT annual vehicle inspection',
"dot_inspection__edit_form": 'View/Edit DOT vehicle inspection form',
"dot_inspection__save_continue": 'Save and continue', 
"dot_inspection__pass": 'PASS',
"dot_inspection__fail": 'FAIL',
"dot_inspection__has_inspection_passed_required": 'Please select PASS or FAIL',
"dot_inspection__has_inspection_passed_components_invalid": 'All vehicle components need to be marked as OK or NA or have a repaired date to pass inspection',
"dot_inspection__has_inspection_passed_unsafe_conditions": 'The inspection cannot pass with unsafe conditions listed',
"dot_inspection__has_inspection_passed_no_failure_specified": 'Please specify failed components',
"dot_inspection__has_inspection_failed_all_components_required": 'All components must specify OK/NA/Needs repair.',
"dot_inspection__has_inspection_failed_repair_date_required": 'Repair date cannot be empty',
"dot_inspection__has_inspection_failed_otherFailureReasons_required": 'Other failure reasons cannot be empty',
"dot_inspection__inspector_attest": 'I attest that I am qualified to perform a DOT Inspection and that this vehicle has passed all the inspection items for the annual vehicle inspection in accordance with 49 CFR PART 396.',
"dot_inspection__attest_required": 'Please attest to this statement',
"dot_inspection__inspector_signature": "Inspector's signature",
"dot_inspection__inspector_signature_note": "By typing your name, you agree this will serve as the electronic respresentation of your signature.",
"dot_inspection__signature_required": 'Please enter your signature',
"dot_inspection_confirmation__fail_confirm_title": 'DOT annual vehicle inspection',
"dot_inspection_confirmation__fail_confirm_question": 'Are you going to repair the failed vehicle components on this repair invoice?',
"dot_inspection_confirmation__fail_confirm_yes": 'Yes and edit PO',
"dot_inspection_confirmation__fail_confirm_no": 'No and specify the failed components',
"dot_inspection_form__title": 'DOT annual vehicle inspection form',
"dot_inspection_form__errors": 'Please correct the issues below.',
"dot_inspection_form__vehicle_history": 'Vehicle history record',
"dot_inspection_form__report_number": 'Report number',
"dot_inspection_form__fleet_unit_number": 'Fleet unit number',
"dot_inspection_form__date": 'Date',
"dot_inspection_form__motor_carrier": 'Motor carrier operator',
"dot_inspection_form__inspector_name": "Inspector's name",
"dot_inspection__inspector_name_required": "Please enter inspector's name",
"dot_inspection_form__motor_carrier_address": 'Address, city, state, zip',
"dot_inspection_form__inspector_qualified_statement": 'This inspector meets the qualification requirements in section 396.19.',
"dot_inspection__inspector_qualified_required": 'Inspector must be qualified to mark inspection as pass',
"dot_inspection_form__inspector_qualified_yes": 'YES',
"dot_inspection_form__vehicle_identification": 'Vehicle identification',
"dot_inspection_form__repairing_vendor": 'Repairing vendor',
"dot_inspection_form__vehicle_type": 'Vehicle type',
"dot_inspection_form__tractor": 'Tractor',
"dot_inspection_form__trailer": 'Trailer',
"dot_inspection_form__truck": 'Truck',
"dot_inspection_form__other": 'Other',
"dot_inspection_form__vendor_address": 'Address, city, state, zip',
"dot_inspection_form__save": 'Save and continue',
'dot_inspection_form__all_ok': 'All OK',
'dot_inspection_form__vehicle_components_title': 'Vehicle components inspected',
'dot_inspection_form__vehicle_components_header_1': '1. Brake system',
'dot_inspection_form__vehicle_components_header_2': '2. Coupling devices',
'dot_inspection_form__vehicle_components_header_3': '3. Exhaust system',
'dot_inspection_form__vehicle_components_header_4': '4. Fuel system',
'dot_inspection_form__vehicle_components_header_5': '5. Lighting devices',
'dot_inspection_form__vehicle_components_header_6': '6. Safe loading',
'dot_inspection_form__vehicle_components_header_7': '7. Steering mechanism',
'dot_inspection_form__vehicle_components_header_8': '8. Suspension',
'dot_inspection_form__vehicle_components_header_9': '9. Frame',
'dot_inspection_form__vehicle_components_header_10': '10. Tires',
'dot_inspection_form__vehicle_components_header_11': '11. Wheels and rims',
'dot_inspection_form__vehicle_components_header_12': '12. Windshield glazing',
'dot_inspection_form__vehicle_components_header_13': '13. Windshield wipers',
'dot_inspection_form__vehicle_component_1': 'a. Service brakes',
'dot_inspection_form__vehicle_component_2': 'b. Parking Brake System',
'dot_inspection_form__vehicle_component_3': 'c. Brake Drums or Rotors',
'dot_inspection_form__vehicle_component_4': 'd. Brake Hose',
'dot_inspection_form__vehicle_component_5': 'e. Brake Tubing',
'dot_inspection_form__vehicle_component_6': 'f. Low Pressure Warning Device',
'dot_inspection_form__vehicle_component_7': 'g. Tractor Protection Valve',
'dot_inspection_form__vehicle_component_8': 'h. Air Compressor',
'dot_inspection_form__vehicle_component_9': 'i. Electric Brakes',
'dot_inspection_form__vehicle_component_10': 'j. Hydraulic Brakes',
'dot_inspection_form__vehicle_component_11': 'k. Vacuum Systems',
'dot_inspection_form__vehicle_component_12': 'a. Fifth Wheels',
'dot_inspection_form__vehicle_component_13': 'b. Pintle Hooks',
'dot_inspection_form__vehicle_component_14': 'c. Drawbar/Towbar Eye',
'dot_inspection_form__vehicle_component_15': 'd. Drawbar/Towbar Tongue',
'dot_inspection_form__vehicle_component_16': 'e. Safety Devices',
'dot_inspection_form__vehicle_component_17': 'f. Saddle-Mounts',
'dot_inspection_form__vehicle_component_18': 'a. Any exhaust system determined to be leaking at a point forward of or directly below the driver/sleeper compartment.',
'dot_inspection_form__vehicle_component_19': 'b. A bus exhaust system leaking or discharging to the atmosphere in violation of standards (1), (2) or (3).',
'dot_inspection_form__vehicle_component_20': 'c. No part of the exhaust system of any motor vehicle shall be so located as would be likely to result in burning, charring, or damaging the electrical wiring, the fuel supply, or any combustible part of the motor vehicle.',
'dot_inspection_form__vehicle_component_21': 'a. Visible leak',
'dot_inspection_form__vehicle_component_22': 'b. Fuel tank filler cap missing',
'dot_inspection_form__vehicle_component_23': 'c. Fuel tank securely  attached',
'dot_inspection_form__vehicle_component_24': 'All lighting devices and reflectors required by Section 393 shall be operable.',
'dot_inspection_form__vehicle_component_25': 'a. Part(s) of vehicle or condition of loading such that the spare tire or any part of the load or dunnage can fall onto the roadway.',
'dot_inspection_form__vehicle_component_26': 'b. Protection against shifting cargo',
'dot_inspection_form__vehicle_component_27': 'a. Steering Wheel Free Play',
'dot_inspection_form__vehicle_component_28': 'b. Steering Column',
'dot_inspection_form__vehicle_component_29': 'c. Front Axle Beam and All Steering Components Other Than Steering Column',
'dot_inspection_form__vehicle_component_30': 'd. Steering Gear Box',
'dot_inspection_form__vehicle_component_31': 'e. Pitman Arm',
'dot_inspection_form__vehicle_component_32': 'f. Power Steering',
'dot_inspection_form__vehicle_component_33': 'g. Ball and Socket Joints',
'dot_inspection_form__vehicle_component_34': 'h. Tie Rods and Drag Links',
'dot_inspection_form__vehicle_component_35': 'i. Nuts',
'dot_inspection_form__vehicle_component_36': 'j. Steering System',
'dot_inspection_form__vehicle_component_37': 'a. Any U-bolt(s), spring hanger(s), or other axle positioning part(s) cracked, broken, loose or missing resulting in shifting of an axle from its normal position.',
'dot_inspection_form__vehicle_component_38': 'b. Spring Assembly',
'dot_inspection_form__vehicle_component_39': 'c. Torque, Radius or Tracking Components.',
'dot_inspection_form__vehicle_component_40': 'a. Frame Members',
'dot_inspection_form__vehicle_component_41': 'b. Tire and Wheel Clearance',
'dot_inspection_form__vehicle_component_42': 'c. Adjustable Axle Assemblies (Sliding Subframes)',
'dot_inspection_form__vehicle_component_43': 'a. Tires on any steering axle of a power unit.',
'dot_inspection_form__vehicle_component_44': 'b. All other tires.',
'dot_inspection_form__vehicle_component_45': 'a. Lock or Side Ring',
'dot_inspection_form__vehicle_component_46': 'b. Wheels and Rims',
'dot_inspection_form__vehicle_component_47': 'c. Fasteners',
'dot_inspection_form__vehicle_component_48': 'd. Welds',
'dot_inspection_form__vehicle_component_49': 'Requirements and exceptions as stated pertaining to any crack, discoloration or vision reducing matter (reference 393.60 for exceptions)',
'dot_inspection_form__vehicle_component_50': 'Any power unit that has an inoperative wiper, or missing or damaged parts that render it ineffective.',
'dot_inspection_form__other_failure_reasons': 'List any other condition which may prevent safe operation of this vehicle.',
'dot_inspection_form__ok': 'OK',
  'dot_inspection_form__na': 'N/A',
  'dot_inspection_form__needs_repair': 'Needs<br></br>repair',
'dot_inspection_form__repaired_date': 'Repaired<br></br>date',
'dot_inspection_form__fail_confirm_title': 'DOT annual vehicle inspection',
'dot_inspection_form__fail_confirm_question': 'Are you going to repair the failed vehicle components on this repair invoice?',
'dot_inspection_form__fail_confirm_yes': 'Yes and edit PO',
'dot_inspection_form__fail_confirm_no': 'No and continue',

// BIT inspection
"bit_inspection__title": 'BIT inspection',
'bit_inspection__truck': 'truck',
'bit_inspection__bus': 'bus',
'bit_inspection__trailer': 'trailer',
"bit_inspection__edit_form": 'View/Edit BIT inspection form',
"bit_inspection__save_continue": 'Save and continue', 
"bit_inspection__pass": 'PASS',
"bit_inspection__fail": 'FAIL',
"bit_inspection__has_inspection_passed_required": 'Please select PASS or FAIL',
"bit_inspection__has_inspection_passed_components_invalid": 'All items need to be marked as OK or N/A to pass inspection',
"bit_inspection__has_inspection_passed_unsafe_conditions": 'The inspection cannot pass with unsafe conditions listed',
  "bit_inspection__has_inspection_passed_no_failure_specified": 'Please specify failed items',
  "bit_inspection__has_inspection_failed_all_components_required": 'All components must specify OK/NA/Def.',
"bit_inspection__has_inspection_other_option_comments_required": 'Comments are required for "Other" vehicle component.',
"bit_inspection__inspector_attest": 'I attest that I am qualified to perform the requested inspection and that this vehicle has passed all the inspection items in accordance with the BIT requirements.',
"bit_inspection__attest_required": 'Please attest to this statement',
"bit_inspection__inspector_signature": "Inspector's signature",
"bit_inspection__inspector_signature_note": "By typing your name, you agree this will serve as the electronic respresentation of your signature.",
"bit_inspection__signature_required": 'Please enter your signature',
"bit_inspection_confirmation__fail_confirm_title": 'BIT inspection',
"bit_inspection_confirmation__fail_confirm_question": 'Are you going to repair the failed items on this repair invoice?',
"bit_inspection_confirmation__fail_confirm_yes": 'Yes and edit PO',
"bit_inspection_confirmation__fail_confirm_no": 'No and specify the failed items',
"bit_inspection_form__title": 'BIT inspection form',
"bit_inspection_form__carrier_name": 'Carrier name',
"bit_inspection_form__unit_number": 'Unit number',
"bit_inspection_form__year": 'Year',
"bit_inspection_form__make": 'Make',
"bit_inspection_form__license_plate": 'License plate',	
"bit_inspection_form__date": 'Date',
"bit_inspection_form__save": 'Save and continue',	
'bit_inspection_form__all_ok': 'All OK',			
'bit_inspection_form__item': 'Item',
  'bit_inspection_form__ok': 'OK',
  'bit_inspection_form__na': 'N/A',
'bit_inspection_form__defective': 'Def',
'bit_inspection_form__comments': 'Comments',	

// TRUCK
'bit_inspection_form__component_51': '1. Fire extinguisher and reflective warning devices',
'bit_inspection_form__component_52': '2. Horn, defroster, gauges, odometer, and speedometer',
'bit_inspection_form__component_53': '3. Mirrors and supports',
'bit_inspection_form__component_54': '4. Windshield wipers, window cracks',
'bit_inspection_form__component_55': '5. All lights, signals, reflectors, mudflaps',
'bit_inspection_form__component_56': '6. Electrical wiring-condition and protection',
'bit_inspection_form__component_57': '7. Batteries-water level, terminals, and cables',
'bit_inspection_form__component_58': '8. Warning devices-air, oil, temperature, anti skid, and/or vacuum',
'bit_inspection_form__component_59': '9. Radiator and water hoses- coolant level, condition, and/or leaks',
'bit_inspection_form__component_60': '10. Belts-compressor, fan, water pump, and/or alternator',
'bit_inspection_form__component_61': '11. Air hoses and tubing leaks, condition, and/or protection',
'bit_inspection_form__component_62': '12. Fuel system-tank, hoses, tubing, and/or pump; leaks',
'bit_inspection_form__component_63': '13. Exhaust system, manifolds, piping, muffler; leaks and/or condition',
'bit_inspection_form__component_64': '14. Engine-mounting, excessive grease and/or oil',
'bit_inspection_form__component_65': '15. Clutch adjustment-free play',
'bit_inspection_form__component_66': '16. Air filter, throttle linkage',
'bit_inspection_form__component_67': '17. Starting and charging system',
'bit_inspection_form__component_68': '18. Tractor-protection valve',
'bit_inspection_form__component_69': '19. Hydraulic brake system-adjustment, components, and/or condition',
'bit_inspection_form__component_70': '20. Hydraulic master cylinder level, leaks, and/or condition',
'bit_inspection_form__component_71': '21. Hoses and tubing-condition and protection',
'bit_inspection_form__component_72': '22. Air brake system-adjustment, components, and/or condition',
'bit_inspection_form__component_73': '23. 1 minute air or vacuum loss test',
'bit_inspection_form__component_74': '24. Air compressor governor cut-in and cut-out pressures (85-130)',
'bit_inspection_form__component_75': '25. Primary air tank-drain and test check valve',
'bit_inspection_form__component_76': '26. Other air tank-drain and check for contamination; securement',
'bit_inspection_form__component_77': '27. Tires-tread depth, inflation, and condition',
'bit_inspection_form__component_78': '28. Wheels, lug nuts, and studs-cracks, looseness, and/or condition',
'bit_inspection_form__component_79': '29. Parking brake-able to hold the vehicle',
'bit_inspection_form__component_80': '30. Emergency stopping system-labeled and operative',
'bit_inspection_form__component_81': '31. Brakes release after complete loss of service air',
'bit_inspection_form__component_82': '32. Steering system-mounting, free lash, and components',
'bit_inspection_form__component_83': '33. Steering arms, drag links, and/or tie rod ends',
'bit_inspection_form__component_84': '34. Connecting devices- fifth wheel, pintle hitch, and/or safety devices',
'bit_inspection_form__component_85': '35. Suspension system-springs, shackles, u-bolts, and/or torque rods',
'bit_inspection_form__component_86': '36. Frame and cross members-cracks and/or condition',
'bit_inspection_form__component_87': '37. Drive shaft, universal joints, and/or guards',
'bit_inspection_form__component_88': '38. Transmission and differential-mounting, leaks, and/or condition',
'bit_inspection_form__component_89': '39. Wheel seals-leaks and/or condition',
'bit_inspection_form__component_90': '40. Under carriage-clean and secure',	
  'bit_inspection_form__component_91': '41. Other',
    
// TRAILER
'bit_inspection_form__component_100': '1. All lights, signals, reflectors',
'bit_inspection_form__component_101': '2. Mudflaps',
'bit_inspection_form__component_102': '3. Air leaks-brake system',
'bit_inspection_form__component_103': '4. Air leaks-dump system',
'bit_inspection_form__component_104': '5. Frame, subframe, and body-cracks',
'bit_inspection_form__component_105': '6. Brake adjustment',
'bit_inspection_form__component_106': '7. Brake system, drums, and components-condition',
'bit_inspection_form__component_107': '8. Suspension system-springs, shackles, u-bolts, and/or torque rods',
'bit_inspection_form__component_108': '9. Connecting device-kingpin, drawbar, eye and/or safety devices',
'bit_inspection_form__component_109': '10. Fifth wheel on pull trailer',
'bit_inspection_form__component_110': '11. Air hoses and tubing-leaks, condition, and/or protection',
'bit_inspection_form__component_111': '12. Tires-tread depth, inflation, and condition',
'bit_inspection_form__component_112': '13. Wheels, lug nuts, and studs-cracks, looseness, and/or condition',
'bit_inspection_form__component_113': '14. Hoses and tubing-condition, protection',
'bit_inspection_form__component_114': '15. Hydraulic Master cylinder-level, leaks, and/or condition',
'bit_inspection_form__component_115': '16. Parking brake-able to hold the vehicle',
'bit_inspection_form__component_116': '17. Emergency breakaway brake system',
'bit_inspection_form__component_117': '18. Air relay valves and tank-mounting',
'bit_inspection_form__component_118': '19. Brakes release after complete loss of service air',
'bit_inspection_form__component_119': '20. Wheel seals-leaks and/or condition',
'bit_inspection_form__component_120': '21. Under carriage-clean and secure',
  'bit_inspection_form__component_121': '22. Other',
    
// BUS
'bit_inspection_form__component_150': '1. Fire extinguisher, first aid kit, and reflective warning devices',
'bit_inspection_form__component_151': '2. Horn, defroster, gauges, odometer, and speedometer',
'bit_inspection_form__component_152': '3. Driver seat, passenger seats, padding, interior, and floor condition',
'bit_inspection_form__component_153': '4. Windshield wipers, windows, mirrors, and supports',
'bit_inspection_form__component_154': '5. All interior and exterior lights, signals, reflectors',
'bit_inspection_form__component_155': '6. Electrical wiring-condition and protection',
'bit_inspection_form__component_156': '7. Batteries-water level, terminals, and cables',
'bit_inspection_form__component_157': '8. Warning devices-air, oil, temperature, exit, and/or vacuum',
'bit_inspection_form__component_158': '9. Heaters, defrosters, switches, and vents',
'bit_inspection_form__component_159': '10. Doors, exterior, paint, and marking',
'bit_inspection_form__component_160': '11. Radiator and water hoses-coolant level, condition, and/or leaks',
'bit_inspection_form__component_161': '12. Belts-compressor, fan, water, and/or alternator',
'bit_inspection_form__component_162': '13. Air hoses and tubing-leaks, condition, and/or protection',
'bit_inspection_form__component_163': '14. Fuel system-tank, hoses, tubing, and/or pump-leaks',
'bit_inspection_form__component_164': '15. Exhaust system, manifolds, piping, muffler-leaks and/or condition',
'bit_inspection_form__component_165': '16. Engine-mounting, excessive grease and/or oil',
'bit_inspection_form__component_166': '17. Clutch adjustment-free play',
'bit_inspection_form__component_167': '18. Air filter, throttle linkage',
'bit_inspection_form__component_168': '19. Starting and charging system',
'bit_inspection_form__component_169': '20. Hydraulic brake system-adjustment, components, and/or condition',
'bit_inspection_form__component_170': '21. Hydraulic master cylinder-level, leaks, and/or condition',
'bit_inspection_form__component_171': '22. Hoses and tubing-condition, protection',
'bit_inspection_form__component_172': '23. Air brake system-adjustment, compartments, and/or condition',
'bit_inspection_form__component_173': '24. 1 minute air or vacuum loss test',
'bit_inspection_form__component_174': '25. Air compressor governor-cut in and cut out pressure (85-130)',
'bit_inspection_form__component_175': '26. Primary air tank-drain and test function of check valve',
'bit_inspection_form__component_176': '27. Other air tanks-drain and check for contamination',
'bit_inspection_form__component_177': '28. Tires-tread depth, inflation, condition',
'bit_inspection_form__component_178': '29. Wheels, lug nuts, and studs-cracks, looseness, and/or condition',
'bit_inspection_form__component_179': '30. Parking brake-able to hold the vehicle',
'bit_inspection_form__component_180': '31. Emergency stopping system-labeled, operative',
'bit_inspection_form__component_181': '32. Brakes do not release after complete loss of service air',
'bit_inspection_form__component_182': '33. Steering system-mounting, free lash and components',
'bit_inspection_form__component_183': '34. Steering arms, drag links, and/or tie rod ends',
'bit_inspection_form__component_184': '35. Suspension system-springs, shackles, u-bolts, and/or torque rods',
'bit_inspection_form__component_185': '36. Frame and cross members-cracks and/or condition',
'bit_inspection_form__component_186': '37. Drive shaft, universal joints, and/or guards',
'bit_inspection_form__component_187': '38. Transmission and differential-mounting, leaks, and/or condition',
'bit_inspection_form__component_188': '39. Wheel seals-leaks and/or condition',
'bit_inspection_form__component_189': '40. Under carriage-clean and secure',
  'bit_inspection_form__component_190': '41. Other',
    
'bit_inspection_form__fail_confirm_title': 'Repair PO?',
'bit_inspection_form__fail_confirm_question': 'Are you going to repair the failed items on this repair invoice?',
'bit_inspection_form__fail_confirm_yes': 'Yes and edit PO',
'bit_inspection_form__fail_confirm_no': 'No and continue',				

// FRA inspection
"fra_inspection__title": 'FRA inspection',	
"fra_inspection__edit_form": 'View/Edit FRA inspection form',
"fra_inspection__save_continue": 'Save and continue', 
"fra_inspection__pass": 'PASS',
"fra_inspection__fail": 'FAIL',
"fra_inspection__has_inspection_passed_required": 'Please select PASS or FAIL',
"fra_inspection__has_inspection_passed_components_invalid": 'All items need to be marked as OK to pass inspection',
"fra_inspection__has_inspection_passed_unsafe_conditions": 'The inspection cannot pass with unsafe conditions listed',
"fra_inspection__has_inspection_passed_no_failure_specified": 'Please specify failed items',
"fra_inspection__inspector_attest": 'I attest that I am qualified to perform the requested inspection and that this vehicle has passed all the inspection items in accordance with the FRA requirements.',
"fra_inspection__attest_required": 'Please attest to this statement',
"fra_inspection__inspector_signature": "Inspector's signature",
"fra_inspection__inspector_signature_note": "By typing your name, you agree this will serve as the electronic respresentation of your signature.",
"fra_inspection__signature_required": 'Please enter your signature',
"fra_inspection_confirmation__fail_confirm_title": 'FRA inspection',
"fra_inspection_confirmation__fail_confirm_question": 'Are you going to repair the failed items on this repair invoice?',
"fra_inspection_confirmation__fail_confirm_yes": 'Yes and edit PO',
"fra_inspection_confirmation__fail_confirm_no": 'No and specify the failed items',
"fra_inspection_form__title": 'FRA inspection form',
"fra_inspection_form__carrier_name": 'Carrier name',
"fra_inspection_form__unit_number": 'Unit number',
"fra_inspection_form__year": 'Year',
"fra_inspection_form__make": 'Make',
"fra_inspection_form__license_plate": 'License plate',	
"fra_inspection_form__date": 'Date',
"fra_inspection_form__save": 'Save and continue',	
'fra_inspection_form__all_ok': 'All OK',			
'fra_inspection_form__item': 'Action items',
'fra_inspection_form__pass': 'PASS',
'fra_inspection_form__fail': 'FAIL',
'fra_inspection_form__fail_confirm_title': 'Repair PO?',
'fra_inspection_form__fail_confirm_question': 'Are you going to repair the failed items on this repair invoice?',
'fra_inspection_form__fail_confirm_yes': 'Yes and edit PO',
'fra_inspection_form__fail_confirm_no': 'No and continue',	
'fra_inspection_form__rail_gear': "Rail gear",
'fra_inspection_form__rail_gear_model': "Rail gear model",
'fra_inspection_form__rail_gear_front_serial_number': "Front serial number",
'fra_inspection_form__rail_gear_rear_serial_number': "Rear serial number",
'fra_inspection_form__guide_wheel_load_lf': "L/F",
'fra_inspection_form__guide_wheel_load_rf': "R/F",
'fra_inspection_form__guide_wheel_load_lr': "L/R",
'fra_inspection_form__guide_wheel_load_rr': "R/R",
'fra_inspection_form__component_201': "Visually inspect rail gear for damage and missing components. Check all mounting hardware. Tighten any loose mounting hardware. Check pivot bearings for wear.",
'fra_inspection_form__component_202': "Inspect steering wheel lock mechanism for proper installation and function.",
'fra_inspection_form__component_203': "Inspect guide wheels. Insure flange wear is within tolerance. Check wheel bearings for play, wear and noise. Advise if guide wheel bearings need to be serviced.",
'fra_inspection_form__component_204': "Check guide wheel load per manuafacturer's recommendations. Advise if heights and weights require adjustments. Check if within OEM specification.",
'fra_inspection_form__component_205': "Inspect rail gear lock mechanisms and lock pins for proper operation.",
'fra_inspection_form__component_206': "Check truck tires for proper inflation. Replace any missing valve stem caps. Inflate tires to recommended pressure on door jamb decal.",
'fra_inspection_form__component_207': "Check steering wheel stops on vehicles where installed.  Inspect: front ABS sensor wiring, upper control arms, brake hoses and brake hose retaining clamps for damage. Report any deficiencies.",
'fra_inspection_form__component_208': "Visually inspect modified truck wheels, studs, lug nuts and tires for missing equipment, wear or damage. Visually inspect wheel spacers. Do not remove truck wheels. Tighten wheel nuts to correct torque.",
'fra_inspection_form__component_209': "Inspect rail gear installation for proper alignment. Paint guide wheels flanges and track test. Advise if vehicle requires rail gear alignment.",
'fra_inspection_form__component_210': "Inspect shunt system for damage and proper operation. Adjust shunt brushes as required.",
'fra_inspection_form__component_211': "Verify proper operation of strobe light. Required under 49CFR214.",
'fra_inspection_form__component_212': "Verify proper operation of back up alarm. Required under 49CFR214.",
'fra_inspection_form__component_213': "Lubricate all grease fittings on rail gear. Lubricate other moving parts with light oil.",
'fra_inspection_form__component_214': "Insure that rail gear is in compliance with all the manufacturer's service and safety bulletins.",
'fra_inspection_form__component_215': "Record any abnormal damage to rail gear system that would indicate derail or collision damage.",
'fra_inspection_form__component_216': "Check hydraulic valves, hoses and cylinders as equipped for damage, leaking and routing. Advise any deficiencies.",

  "add_complaint_caption": "Complaint 1",
  "goto_next_link_caption": "Go to next step",
  "add_services_caption": "This vehicle has services due",
  "add_services_caption_note": "SELECT TO ADD TO PURCHASE ORDER",
  "schedule_header": "Schedule",
  "due_header": "Due",
  "odometer_details_caption": "Required update",
  "odometer_details_odometer": "Odometer",
  "odometer_details_last_reading": "Last reading",
  "odometer_details_engine_hours": "Hour meter",
  "odometer_details_select_one": "Select One",
  "odometer_details_at_shop": "Vehicle is at the shop",
  "odometer_details_not_at_shop": "Vehicle is not at the shop",
  "odometer_details_save_update": "Confirm Vehicle and go to the next step",
  "odometer_details_unavailable": "Unavailable",
  "ododmeter_Validation_Message_1": "Warning: The meter entered {newOdometer} differs from the estimated meter by:{odometerDifference}.",
  "ododmeter_Validation_Message_2": "Warning: The meter entered exceeds the estimated meter by: {newOdometer}.",
  "ododmeter_Validation_Message_3": "Warning: The meter entered {newOdometer} differs from the expected meter {estimatedOdometer} for {currentDate} by:{estimatedOdometerDiff}.",
  "ododmeter_Validation_Message_4": "Warning: The meter entered {newOdometer} differs from the estimated meter by: {odometerDifference}.",
  "ododmeter_Validation_Message_5": "Warning: This Unit is identified as EQUIPMENT. Odometer reading will be copied to Hour Meter reading based on client needs.",
  "hourmeter_Validation_Message": "Warning: Hour meter cannot be less than last recorded value.",
  "action_required_issue_note": "If you have questions regarding these adjustments, please call \n1-866-274-1025 to discuss with a Holman technician",
  "action_required_issue_note_can":"If you have questions regarding these adjustments, please call \n1-800-363-7676 to discuss with a Holman technician",
  'action_required_submitted_description':'Submitted',
  'action_required_title': 'Your Action Required',
  "action_required_note_from_ari_description":"Note from Holman",
  "action_required_note_from_ari_description_client":"Note from client",
  "action_required_provide_note_description":"Please provide your response below",     
  "action_required_submit_note_all_rejected":"This PO will be cancelled once you review and accept the rejected lines, and click Submit",
  "action_required_submit_note_client_auth":"This PO will be sent for Client/Holman Authorization once you review and accept the changes, and click Submit",
  "action_required_submit_note_need_notes":"This PO will be sent to Holman for Approval once you review and accept the changes, and click Submit",
  "action_required_submit_note_po_approved": "You can continue with the repair once you review and accept the changes, and click submit",  
  "action_required_forward_for_further_review": "Forward for further review",
  "action_required_line_item_review":"Review ",
  "action_required_line_item_review_complete":"This line is reviewed",
  "action_required_line_item_review_pending":"This line is not reviewed",
  "action_required_line_status_need_notes":"Notes Required",
  "action_required_line_status_adjusted":"Adjusted",
  "action_required_line_status_rejected":"Rejected",
  "action_required_line_status_created":"Created by Holman", 
  "action_required_line_item_view_note":"Review the following line(s)",
  "action_required_po_edit": "Edit PO",
  "adjusted_line_window_title":"Please Review",
  "need_more_notes_window_title":"Please Review",
  "rejected_line_window_title":"Please Review",
  "new_line_window_title":"Please Review",
  "action_required_po_submit":"Submit",    
  "note_show_more_description":'show more',
  "note_show_less_description":'show less',
  "complaint_notes_readonly_show_less":"...show less",
  "complaint_notes_readonly_show_more":"...show more",
  "repair_notes_show_less":"...show less",
  "repair_notes_show_more":"...show more",
  "adjusted_line_standard_ari_note_cost":"We've made the adjustment noted above to this purchase order.",
  "adjusted_line_standard_ari_note_labor":"We've made the adjustment noted above to this purchase order.",
  "need_more_notes_vendor_note_error_message":"Please provide your response below; enter 4 or more characters",
  "approval_request_unsaved_changes_prompt":"This purchase order has unsaved changes. Please click CANCEL to stay on this screen and review the changes. Then click Submit at bottom of the screen to send the changes to Holman.",
  "need_more_notes_submit_description":" Submit ",
  "need_more_notes_line_item_related_line_description":"Associated {associatedLineType} line will not be approved until you provide more notes for this line.",
  "need_more_notes_line_item_status_description":"Holman needs more information about {productDescription}",
  "need_more_notes_line_item_reviewed_description":"You've reviewed this line",
  "new_line_item_created_by_ari":"Created By Holman",
  "new_line_item_reviewed_description":"You've reviewed this line",
  "new_line_item_accept_description":" Accept ",
  "new_line_item_status_description":"{productDescription} was created by Holman",
  "rejected_line_item_status_description":"{productDescription} was rejected by Holman",
  "rejected_line_item_status_description_client":"{productDescription} was rejected by client",
  "rejected_line_payment_not_authorized_description":"NO PAYMENT WILL BE AUTHORIZED FOR THIS LINE",
  "rejected_line_item_accept_description":" Accept ",    
  "rejected_line_item_reviewed_description":"You've reviewed this line",
  "adjusted_line_item_reviewed_description":"You've reviewed this line",
  "adjusted_line_item_related_line_description":"Associated {associatedLineType} line will not be approved until you accept this change.",
  "adjusted_line_item_status_description":"{productDescription} was adjusted by Holman",
  "adjusted_line_item_status_description_client":"{productDescription} was adjusted by client",
  "adjusted_line_item_accept_description":" Accept ",
  "adjusted_line_item_view_quantity": "Qty",
  "adjusted_line_item_view_hour": "Hour",
  "adjusted_line_item_view_cost": "Cost",
  "adjusted_line_item_view_amount": "Amount",
  "adjusted_line_item_view_submitted": "Submitted",
  "adjusted_line_item_view_ari_adjustment": "Holman Adjustment",
  "adjusted_line_item_view_ari_adjustment_client": "Client Adjustment",
  "adjusted_line_item_view_ari_created": "Created by Holman",
  "adjusted_line_item_view_reject_intial_adjustment_message":"Reject adjustment and forward for further review",
  "adjusted_line_item_view_accept_adjustments": "Accept adjustment and continue repair",
  "adjusted_line_item_view_accept_adjustments_client_auth": "Accept adjustment to start client authorization process",
  "dri_val_complaint_title": "Complaint",
  "dri_val_complaint_select": "Select complaint type(s) confirmed by driver.",
  "complaint_view_complaint_caption": "Complaint",
  "po_actions_diagnosis_view_caption": "Diagnosis",
  "po_actions_line_item_view_quantity": "Qty",
  "po_actions_line_item_view_hour": "Hour",
  "po_actions_line_item_view_cost": "Cost",
  "po_actions_line_item_view_amount": "Amount",
  "rejected_line_item_view_quantity": "Qty",
  "rejected_line_item_view_hour": "Hour",
  "rejected_line_item_view_cost": "Rate",
  "rejected_line_item_view_amount": "Amount",
  "rejected_line_item_view_submitted": "Submitted",
  "rejected_line_item_view_acknowledge_lines": "Acknowledge unauthorized line {lineTotal, plural, one {item} other {items}} and continue repair",
  "rejected_line_item_view_acknowledge_lines_client_auth": "Acknowledge unauthorized line {lineTotal, plural, one {item} other {items}} to start client authorization process",
  "rejected_line_item_view_acknowledge_lines_and_cancel": "Acknowledge unauthorized line {lineTotal, plural, one {item} other {items}} and cancel repair",
  "rejected_line_item_view_no_payment_authorized": "No payment will be authorized for {lineTotal, plural, one {this line item} other {these line items}.",
  "authorization_status_reason": "Note from Holman technician",
  "authorization_status_reason_client": "Note from client",
  "rejected_line_item_view_no_payment_authorized_lines": "",
  "services_view_caption_2": "Maintenance",
  "adjustment_required": "Adjustment required",
  "not_authorized": "Not authorized",
  "adjust_reject_purchase_order_number": "Purchase order no.",
  "shopview_approved_pos_caption": "Approved for service",
  "shopview_waiting_for_driver_validation_caption": "Waiting for driver validation",
  "shopview_waiting_for_ari_approval_caption": "Waiting for Holman approval",
  "shopview_waiting_for_ari_approval_caption_mex": "Waiting for ARIZA approval",
  "shopview_waiting_for_client_approval_caption": "Waiting for client approval",
  "shopview_your_action_required": "Your action required",
  "active_po_vehicle_column_header": "Vehicle",
  "active_po_vin_column_header": "VIN",
  "active_po_po_number_column_header": "PO no.",
  "active_po_po_total_amount_column_header": "Amount",
  "active_po_po_approved_amount_column_header": "Approved",
  "active_po_po_pending_amount_column_header": "Pending",
  "po_edit_link_caption": "EDIT",
  "po_view_link_caption": "VIEW",
  "po_close_link_caption": "CLOSE",
  "po_reply_link_caption": "Reply",
  "today": "Today",
  "none": "None",
  "no_pos_caption": "None at this Time",
  "show_all_waiting_pos": "Show all {poCount} purchase orders",
  "hide_older_pos": "Hide older purchase orders",
  "payment_in_progress_caption": "Payment in progress",
  "payment_in_progress_vehicle_column_header": "Vehicle",
  "payment_in_progress_vin_column_header": "VIN",
  "payment_in_progress_po_number_column_header": "PO no.",
  "payment_in_progress_po_date_column_header": "PO date",
  "payment_in_progress_po_total_amount_column_header": "Amount",
  "payment_in_progress_po_view_link_caption": "VIEW",
  "payment_in_progress_no_pos_caption": "None at this Time",
  "payment_in_progress_today": "Today",
  "shopview_no_pos_caption": "No Open Purchase Orders",
  "diagnosis_view_caption": "Diagnosis",
  "diagnosis_view_caption1": "Request approval for diagnostic charge:",
  "diagnosis_view_caption2": "hour(s) at",
  "estimated_completion_description": "Estimated completion for all repairs and services",
  "Estimated_Completion_Date_Morning": "Morning",
  "Estimated_Completion_Date_Afternoon": "Afternoon",
  "Estimated_Completion_Date_Evening": "Evening",
  "user_action_add_caption": "Request Approval",
  "user_action_view_caption": "Close PO",
  "tax_edit_text": "Tax can be added/edited while closing the PO",
  "preventive_maintenance_caption": "Maintenance",
  "vehicle_details_vin_no": "VIN",
  "vehicle_details_client": "Client",
  "vehicle_details_ari_unit_no": "Holman unit no.",
  "vehicle_details_engin_type": "Engine type",
  "vehicle_details_engin_hours": "Hour meter",
  "vehicle_details_vehicle": "Vehicle",
  "vehicle_details_plate": "Plate",
  "vehicle_details_color": "Color",
  "vehicle_details_mileage": "Mileage",
  "vehicle_details_other": "Other",
  "vehicle_details_documents": "Vehicle Documents",
  "vehicle_details_parameters": "Client Documents",
  "vehicle_details_po_number": "PO no.",
  "vehicle_details_unavailable": "Unavailable",
  "approved_items": "Approved line items",
  "line_items": "Line items",
  "po_actions_po_totals_view_subtotals": "Subtotal",
  "po_actions_po_totals_view_tax": "Tax",
  "po_actions_po_totals_view_total": "Total",
  "po_actions_vehicle_details_client": "Client",
  "po_actions_vehicle_details_ari_unit_no": "Holman unit no.",
  "po_actions_vehicle_details_vehicle": "Vehicle",
  "po_actions_vehicle_details_mileage": "Mileage",
  "estimated_completion_morning": 'Morning',
  "estimated_completion_afternoon": 'Afternoon',
  "estimated_completion_evening": 'Evening',
  "estimated_completion_unavailable": 'Unavailable',
  "purchase_order_title_unavailable": 'Unavailable',
  "po_actions_vehicle_details_vin_no": 'VIN',
  "close_po_submit_1": 'Request payment of ',
  "close_po_submit_2": ' and close PO',
  'user_action_cancel_po': 'Cancel PO',
  'cancel_po_confirmation_no': 'No',
  'cancel_po_confirmation_yes': 'Yes ',
  'cancel_po_confirmation_title': 'Cancel PO',
  'cancel_po_confirmation_message': 'You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?',
  'add_line_items_for_maintenance': 'Add line items for Maintenance',
  "signed_out": 'You have successfully signed out.',
  'user_action_edit_caption': 'Close PO',
  "search_po_caption": "Search by",
  "search_po_service_in_progress_caption": "Service in progress",
  "search_po_payment_in_progress_caption": "Payment in progress",
  "search_po_paid_caption": "Paid",
  "search_po_po_number_column_header": "PO no.",
  "search_po_po_date_column_header": "PO date",
  "search_po_po_status_column_header": "Status",
  "search_po_invoice_no_column_header": "Invoice no.",
  "search_po_po_payment_requested_column_header": "Payment requested",
  "search_po_po_payment_date_column_header": "Payment date",
  "search_po_po_total_amount_column_header": "Amount",
  "search_po_none": "None",
  "search_po_po_view_link_caption": "VIEW",
  "search_po_today": "Today",
  "search_po_vin8_no_pos_caption": "None at this time",
  "search_po_no_pos_caption": "None at this time",
  "search_po_waiting_for_ari_approval": "Waiting for Holman approval",
  "search_po_waiting_for_ari_approval_mex": "Waiting for ARIZA approval",
  "search_po_waiting_for_client_approval": "Waiting for client approval",
  "search_po_waiting_for_vendor_approval": "Waiting for vendor approval",
  "search_po_approved": "Approved",
  'close_po_confirmation_title': `Close PO`,
'close_po_has_diagnosis_lines_only_message': 'PO’s cannot be closed for diagnosis only. Please correct line item(s) to indicate the repairs completed and resubmit or contact 1-866-274-1025 for assistance.',
'close_po__non_intellipay_vendor': 'This PO cannot be processed online. Please submit this invoice through your corporate remit for payment.',
'close_po_confirmation_ok': 'OK',
"close_po__accident_claim": "For claim {claimId}",
'repair_hide_labor': 'Labor for {newProductDescription} should be included in {existingProductDescription}',
'adjust_hide_labor': 'Labor for {newProductDescription} should be included in {existingProductDescription}',
'replace_hide_labor': 'Labor for {newProductDescription} should be included in {existingProductDescription}',
'add_edit_view_confirmation_title': 'Edit PO',
'add_edit_view_confirmation_message': 'If you are attempting to edit item quantity or price, please delete both parts and labor and resubmit the new values. Are you sure you want to delete this item?',
'add_edit_view_po_confirmation_no': 'No',
'add_edit_view_confirmation_yes': 'Yes',
'complaint_code_MA99': 'Maintenance',
'complaint_code_RI99': "Safety / Gov't insp",
'complaint_code_TI99': 'Tires / Wheels',
  'complaint_code_BR99': 'Brake system',
  'complaint_code_LI99': 'Lighting / Wiring',
  'complaint_code_CH99': 'Charging / Starting',
  'complaint_code_EN99': 'Engine system',
  'complaint_code_DR99': 'Driveability / Sensors',
  'complaint_code_CO99': 'Cooling system',
  'complaint_code_FU99': 'Fuel system',
  'complaint_code_ST99': 'Steering system',
  'complaint_code_SU99': 'Suspension',
  'complaint_code_EX99': 'Exhaust system',
  'complaint_code_AC99': 'Heating / AC system',
  'complaint_code_UN99': 'Other system concern',
  "complaint_error": "Please add complaint type in order to continue.",
  "complaint_type_header": "SELECT A COMPLAINT TYPE",
  "complaint_type": "Complaint type:",
  "change_complaint_type": "Change complaint type",
  "add_complaint_type": "Add complaint type",
  "warranty": "Warranty:",
  "warranty_months": "Months",
  "warranty_miles": "Miles",
  "warranty_hours": "Hours",
  "contact_footer": "<a href='/contact/FeedbackForm'> Click here </a>send us an email with your question and we will be sure to get back to you soon",
  "return": "Return",
  "help_title": "Help",
  "documents_browse_button": "Browse file",
  "documents_cancel_button": "Cancel",
  "documents_hide": "Hide documents",
  "documents_return_button": "Return",
  "documents_save_button": "Save new document",
  "documents_upload": "Upload new document",
  "documents_header_name": "Name",
  "documents_header_upload_date": "Upload date",
  "documents_header_document_type": "Document type",
  "documents_show_documents": "Show documents",
  "documents_title": "Documents",
  "documents_title_for_vehicle": "Documents for VIN {vin8}",
  "documents_file_name": "Selected file name",
  "documents_name": "Name",
  "documents_document_type": "Document type",
  "documents_date_received": "Date received",
  "documents_name_required": "Please enter a name.",
  "documents_document_type_required": "Please select a document type.",
  "documents_drag_drop": "Drag and drop your file here or click to browse.",
  "documents_none": "None at this time",
  "help_caption": "Frequently asked questions",
  "faq_enrollment_title": "Enrollment/Registration",
  "faq_purchaseOrder_title": "Purchase Order/Payment/Invoicing",
  "faq_bank_title": "Bank Accounts",
  "faq_profile_title": "Profile Information",
  "faq_troubleshoot_title": "Troubleshooting",
  "faq_general_operations_title": "General System Operation",
  "faq_general_information_title": "General Information",
  "faq_enroll_question1": "How can I become a Holman Service Provider?",
  "faq_enroll_answer1": "Simply click the word Enroll on the home page. This is a 2 part process. You’ll be notified via e-mail when you’re accepted. You can then maintain your account online.",
  "faq_enroll_question54": "How can I become a Holman Service Provider?",
  "faq_enroll_answer54":"Please contact Holman at the toll free number shown",
  "faq_enroll_question2": "When I try to register it is asking for my Federal Tax ID#.",
  "faq_enroll_answer2": "Your Federal Tax ID# is ‘9’ digits; enter without the dash. <br></br> e.g.: Correct/ 123456789 Incorrect/ 12-3456789",
  "faq_purchase_question3": "What does approved PO's mean?",
  "faq_purchase_answer3": "Approved PO’s are your pending invoices that are still pending payment.",
  "faq_purchase_question4": "What does processed PO's mean?",
  "faq_purchase_answer4": "Processed PO’s are your PO’s that have been closed for payment.You can check status on these payments by clicking on the processed date for the selected invoice.",
  "faq_purchase_question5": "How do I find past activity on the Purchase order Pages?",
  "faq_purchase_answer5": "Purchase Order pages begin by displaying the most current activity for your account.Use the Prior Month arrow to view purchase orders from earlier dates. Use the Next Month arrow to move to a later date.You may also select a date directly from the dropdown list.Only dates containing account activity are available for display.",
  "faq_purchase_question6": "Is it possible to find an invoice without paging through months of activity?",
  "faq_purchase_answer6": "If you know the Invoice, Purchase Order or Payment Number, You can use the Invoice Search page and the optional Find by Number feature. Enter the Item number and press the appropriate Find button. You may also select items by scanning the Search by Grouping section of the page.",
  "faq_purchase_question7": "Can I arrange the items on the Purchase Order page?",
  "faq_purchase_answer7": "Yes, by selecting any heading from the purchase order table, the items will be sorted on that column. Selecting the same heading a second time will reverse the order of the items.",
  "faq_purchase_question8": "Can I see additional information for a purchase order?",
  "faq_purchase_answer8": "Yes, you can select the PO number from any purchase order. This will display the PO detail line items. On the Processed page you can also select the Processed date.This will display the payment detail for that item.",
  "faq_purchase_question9": "Why is my payment amount different from the amount that I submitted?",
  "faq_purchase_answer9": "There is a Holman Pay amount applied to each invoice total; regardless of what services are rendered. E.G.: LOF, inspections, services, tax etc.",
  "faq_purchase_question10": "How do I expedite my call into the Holman call center?",
  "faq_purchase_answer10": "1. Client ID & Vehicle Number or Last 8 Digits of the VIN. <br></br> 2. Current Mileage on the Vehicle. <br></br> 3. Able to explain the Repairs or Services needed/suggested. <br></br> 4. Pricing broken down on Parts/Labor and Sales Tax. <br></br>",
  "faq_bank_question11": "What if I do not receive my payment notification email?",
  "faq_bank_answer11": "Log on to the website and go to your processed PO’s, then locate the invoice you are questioning and click on the You will then be provided with the info on that payment.processed date. Please be advised that these notifications can not be resent.",
  "faq_enroll_question12": "How will I receive my payment notification?",
  "faq_enroll_answer12": "Your payment notification e-mail originates from Fleet-Alert@holman.com. This is not spam & cannot be recreated. Please add this to your address book since these alerts are sent in bulk and can easily be recognized as spam.",
  "faq_bank_question13": "Why don’t I always receive an email each time funds post to my checking account?",
  "faq_bank_answer13": "If you’re not allowing our address Fleet-Alert@holman.com to come to your e-mail it may be recognized as spam. You can always verify deposits for a particular vehicle PO using last 8 digits of the VIN in footer search section (“Search for my purchase orders by last 8 characters of VIN”).",
  "faq_bank_question14": "Why can't I deposit to a savings account?",
  "faq_bank_answer14": "At this time we are only able to accept a Checking Account for ACH transfers. Since the vast majority of companies use a Checking Account this was the best choice for us to offer our service providers.",
  "faq_bank_question15": "My checking account information has changed but I closed out PO's.",
  "faq_bank_answer15": "If invalid checking account information is on file, it will result in delayed payment by at least a week. Please go to Member Services and then to Payment account maintenance to update your checking account information, unless Holman has already contacted you about the error.",
  "faq_bank_question16": "Can I change my checking account information online?",
  "faq_bank_answer16": "Yes, Go to Member Services: Payment Account Maintenance: Register your new checking account to be used for future payment of services. Enter both your bank routing & account #.",
  "faq_bank_question17": "I already enrolled and registered but skipped the step to add my checking account information.",
  "faq_enroll_answer17": "Sign in to your account and go to Member Services; Payment Account Maintenance to add your checking account information, this completes the setup of your account.",
  "faq_profile_question18": "How do I update my profile information?",
  "faq_profile_answer18": "Under 'YOUR ACCOUNT', you can change your password, email address and checking account information, update contact information, addresses etc.",
  "faq_profile_question19": "What if I forget my password?",
  "faq_profile_answer19": "Go to sign in and click on Email Me. You will be prompted to enter in you Federal Tax ID # (EIN #) and Supplier Id #. Once all information is entered, click email me and your password will be emailed to the address we have on file.",
"faq_profile_question20": "How do I update my Client Authorization email address?",
"faq_profile_answer20": "Log into your account with your supplier ID and password, go to Member Services on the left hand side of the page and click on Client Authorization, you will need to enter your password again, then enter the updated info and hit submit.",
"faq_troubleshoot_question21": "I am using a newer browser but I cannot register.",
"faq_troubleshoot_answer21": "There are two possibilities. Check to see if your browser has Scripting enabled. This is a required browser option that allows your computer to process some Web page features. The end result is an enriched and speedier interaction with the Web. Also, verify that your browser is accepting browser cookies. This browser option is required to provide you with a personalized dialogue.",
"faq_troubleshoot_question22": "Are you a Corporate office with linked locations trying to sign into your account?",
"faq_troubleshoot_answer22": "We’ve made enhancements so your ‘linked’ locations can now maintain their own service profiles As a result we added a 2nd level of password protection Please call Supplier Relations #888-683-8327 #1 for assistance. We regret any inconvenience.",
"faq_troubleshoot_question23": "I need to access an existing account.",
"faq_troubleshoot_answer23": "For your security, please call Supplier Relations @ 1-866-274-1025 #1 to verify specific account information. A temporary password will be provided, if needed.",
  "faq_general_operations_question24": "Is there an additional charge or fee for using Holman PartnerConnect?",
  "faq_general_operations_answer24": "No, Holman PartnerConnect can help reduce the need for phone calls, increasing efficiency and flexibility for your shop. There are no additional fees for using this value added service.",
  "faq_general_operations_question25": "Does each user in my shop need to have an individual password?",
  "faq_general_operations_answer25": "No, Holman PartnerConnect uses a single sign-on concept, consisting of your current user ID and password.",
  "faq_general_operations_question26": "Can I start building a purchase order and submit at a later time?",
  "faq_general_operations_answer26": "Yes, PO's which have been started and not submitted for approval will be shown in the" + ' "Your Action is Required" ' + "section.",
  "faq_general_operations_question27": "Is it possible to make a change once a PO is submitted?",
"faq_general_operations_answer27": "Yes, users are allowed to make edits to approved or pending PO’s, and resubmit them for approval.",
  "faq_general_operations_question28": "Can I still process payment for open authorized purchase orders on the new" + '<divFAQ> <br></br> </divFAQ>' + "website?",
  "faq_general_operations_answer28": "Yes, Holman PartnerConnect allows you to process payments online, as well as view payment status.",
  "faq_general_operations_question29": "Will I be able to update my shop’s information (Banking, email, service profile) on" + '<divFAQ> <br></br> </divFAQ>' + " Holman PartnerConnect?",
  "faq_general_operations_answer29": "Yes, Holman PartnerConnect will allow users to update account critical information such as bank account, phone and e-mail contacts, service performed etc. This can be found by clicking on “YOUR ACCOUNT” at the top of the main landing page.",
  "faq_general_operations_question30": "How will Holman help me grow my business?",
  "faq_general_operations_answer30": "Our Holman PartnerConnect site will display vehicles which are in your servicing area, and categorize them into assets which need service now, vehicles you've previously worked on, and those that you haven't, as an example.",
  "faq_general_operations_question31": "general system operation question 31",
  "faq_general_operations_answer31": "general system operation answer 31",
  "faq_general_information_question31": "What is Holman PartnerConnect?",
  "faq_general_information_answer31": "Holman PartnerConnect is a completely new two-way business portal, designed to save you time and money, while providing you with an easy way to handle the bulk of your Holman business interactions. This new site will be replacing our current supplier webpage.",
  "faq_general_information_question32": "How will Holman PartnerConnect benefit me?",
  "faq_general_information_answer32": "Holman PartnerConnect will allow you to create purchase orders online, for most of the common, day to day operations that are typically performed in your shop. In most cases, you can simply skip the phone by creating a purchase order request over the internet.  In addition to creating PO's online, our new system will also provide you with purchase order status at a glance, while allowing for PO edits on the fly. Once work on the vehicle has been completed, you can quickly and easily review and close the purchase order. All of these features are designed to save you time and increase productivity, which translates to real, tangible savings as you go about the course of your busy day.",
  "feedback_form_header": "Feedback Form",
  "faq_footer": "You may find answers to your questions and concerns in our <a href='/contact/HelpFAQ'>Frequently Asked Questions.</a>",
  "thanks_title": "Thank you!",
  "thanks_sub": "Your message has been sent successfully.",
  "suggestion_dropdown_question": "What do you have to say?",
  "section_dropdown_question": "What section is your feedback for?",
  "comment_title": "Comment/Suggestion",
  "criticism_title": "Criticism",
  "praise_title": "Praise",
  "question_title": "Question",
  "problem_title": "Found a Problem",
  "section_question_title": "Web site general",
  "create_po_title": "Create PO",
  "close_po_title": "Close PO",
  "edit_po_title": "Edit PO",
  "search_po_title": "Search PO",
  "po_dashboard_title": "PO Dashboard",
  "other_title": "Other",
  "radio_title": "Prospective supplier",
  "name_header": "Name",
  "company_header": "Company (Optional)",
  "email_header": "Email",
  "phone_header": "Phone",
  "ext_header": "Ext. (Optional)",
  "send_message_header": "Send a message",
  "send": "Send",
  "active_address_header": "Holman Global Headquarters <br></br> 4001 Leadenhall Road <br></br> Mount Laurel, NJ 08054",
  "mexican_active_address_header":"Boulevard Manuel Avila Camacho #685 <br></br> Fraccionamiento Industrial Alce Blanco <br></br>    Naucalpan Mexico 53370 ",
  "car_care_description": "For problems viewing, <br/> Creating, Editing or <br/> Closing Purchase Orders",
  "car_care_contact_number": "1-866-274-1025",
  "website_assistance_header": "Website Assistance",
  "website_assistance_contact_number": "1-866-274-1025",
  "canadian_active_address_header": "Holman Financial Services Inc. <br></br> 1270 Central Parkway W. <br></br> Suite 600 <br></br> Mississauga, Ontario L5C 4P4 <br></br> 800-361-5882",
  "canadian_car_care_description": "For problems viewing, <br/> Creating, Editing or <br/> Closing Purchase Orders",
  "canadian_car_care_contact_number": "1-866-274-1025",
  "canadian_website_assistance_header": "Website Assistance",
  "canadian_website_assistance_contact_number": "1-866-416-6542",
  "shop_select": "Please Select One!",
  "parameters_header": "Parameters",
  "parameters_header_services": "Client Vehicle Service Rules ",
  "empty_parameters": "None at this time",
  "client_parameter_header": "Client Parameters",
  "general_parameter_header": "General parameters",
  "vin_parameter_header": "Parameters for VIN",
  "repair_history_of_vehicle": "Repair History",
  "client_parameters_of_vehicle": "Client Parameters",
  "tax": "Tax",
  "pm_sheets": "PM Information",
  "recent_service": "Recent Service",
  "line_item_view_date": "Date",
  "line_item_view_service": "Service",
  "line_item_view_odometer": "Odometer",
  "line_item_view_supplier": "Supplier",
  "line_view_link_caption": "VIEW",
  "add_no_services_caption": "This vehicle has no services due",
  "no_records_message": "No recent services for this vehicle",
  "show_more": "Show More",
  "learn_more_caption": " Learn more",
  "payment_status_bulletin_title": "Payment status info",
  "marketing_message": "Holman has revolutionized fleet management with technology that enables organizations around the world to realize new levels of efficiency and value by leveraging the power of data and analysis.",
  "driver_validation_text": "This purchase order requires driver validation. Advise driver to validate repairs with Holman prior to performing work.",
  "line_items_error_message": "Please add at least one line Item",
  "existing_po_link": "{po} already exists. Are you sure you want to continue with a new PO {yes}|{no}?",
  "vehicle_search_message": "{vehicleSearchMessage}",
  "recover_password_text": "Forgot your password?",
  "email_notification_title": "Request your password",
  "id_issued_ari_input": "Holman Issued ID (mandatory)",
  "email_me_button": "Email me",
  "thank_you_recover_password_title": "Reset your password",
  "thank_you_recover_password_subtext": "If the information you provided matches the account information we have on record, we will send an email within the next 10 minutes to the address on file with details for resetting your password.",
  "error_message_recover_id": "ID issued by Holman should not be left blank",
  "error_message_contact_name": "Please enter valid contact name.",
  "error_message_recover_identification": "Please enter tax information.",
  "error_message_incorrect_identification": "Invalid ID. Please enter in the following format:  (XXX-XX-XXXX) or (XX-XXXXXX)",
  "online_status__internet_down": "There seems to be a problem with your internet connection.",    
  "identification_ssn": "Last 4 digits of EIN/Taxpayer ID",
  "identification_email": "E-mail address",
  "return_login": "Return to main page",
  "driver_reference_number_title": "Your reference#",
  "driver_reference_number_subTitle": "Invoice#/RO#/WO#",
  "active_po_reference_number_column_header": "Your Ref #",
  "odometer_details_optional": " (if equipped)",
  "po_requester_title": "Your Name",
  "generic_error_message": "An unexpected error has occurred. Please try again later or Click <a href='/contact/FeedbackForm'>here</a> to report an issue.",
  "generic_error_message_1": "An unexpected error has occurred. Please try again later or ",
  "generic_error_message_2": "Click here",
  "generic_error_message_3": " to report an issue.",
  'error_tax_amount': 'PO exceeds the tax threshold, Please call the call center at 1-866-274-1025',
  'add_new_line_item_caption': 'Add {complaint_description} items',
  "coming_soon_title": "Coming Soon",
  "coming_soon__gamify_business_title": "Your Holman Business",
  "coming_soon_gamify_performance_title": "Your Holman Performance",
  "coming_soon_setting_title": "Settings",
  "coming_soon_business_growth_title": "Business Growth",
  "contact_information_title": "Contact Information",
  "vendor_business_phone_number": "Business",
  "vendor_extension_number": "Ext.",
  "vendor_extension_number_edit": "Ext. (Optional)",
  "vendor_fax_number": "Fax",
  "vendor_fax_number_edit": "Fax (Optional)",
  "settings_Cancel": "Cancel",
  "save_contact_information": "Save contact information settings",
  "name_information_title": "Business Name",
  "position_information_title": "Position",
  "settings_view_caption": "Settings",
  "vendor_street_address_title": "Street Address",
  "vendor_city_title": "City",
  "vendor_state_title": "State",
  "vendor_zip_title": "Zip Code",
  "changePassword_view_caption": "Password",
  "changePassword_change_password": "Change Password",
  "edit_contact_information": "Edit contact information settings",
  "vendor_name_error_message": "Please enter primary contact first and last name.",
  "vendor_street_address_error_message": "Please enter your street address.",
  "vendor_city_address_error_message": "Please enter your city address.",
  "vendor_zip_address_error_message": "Please Enter a valid ZipCode.",
  "vendor_business_phone_error_message": "Invalid Phone Number Format. Please enter in the following format: (XXX-XXX-XXXX)",
  "vendor_fax_phone_error_message": "Invalid Fax Number Format. Please enter in the following format: (XXX-XXX-XXXX)",
  "vendor_street_address2_title": "Street Address 2",
  "change_password_ari_id": "ID issued by Holman",
  "change_password_current": "Current",
  "change_password_new": "New",
  "change_password_confirm_new": "Confirm new",
  "changePassword_save_password": "Save Password",
  "change_password_ariId_error_mesg": "Please enter ID issued by Holman.",
  "change_password_new_pwd_error_mesg": "Please enter new password.",
  "change_password_ariId_error_mesg1": "ID issued by Holman is incorrect",
  "change_password_current_pwd_error_mesg": "Please enter current password.",
  "change_password_new_pwd_error_mesg1": "New password cannot be the same as current password.",
  "change_password_current_pwd_error_mesg1": "Current password is incorrect.",
  "change_password_confirm_pwd_error_mesg": "Please confirm new password.",
  "change_password_confirm_pwd_error_mesg1": "Password does not match.",
  "password_successfully_changed": "Your password has been successfully changed.",
  "location_title": "Location",
  "contact_title": "Contact Name",
  "email_recipient_title": "Payment Email Recipient",
  "account_view_caption": "Account information",
  "bank_account_caption": "Bank Account",
  "checking_account_caption": "Checking Account No.",
  "edit_account_information": "Edit bank account information",
  "account_submission_success": "Your banking update has been submitted for processing. Please allow one full business day for completion of banking change. Remember: To ensure payment of services, always verify your payment account when submitting online invoicing.",  
  'account_submission_success_new_text': "Recent changes to your bank account information have placed all payments on a temporary hold.  Please be prepared to receive a phone call from Holman to verify these changes to allow payments to resume.",
  "tax_information_caption": "Tax information",
  "federal_tax_caption": "Federal tax classification",
  "ssn_caption": "Social Security no./Employer ID",
  "checking_account_match_error_message": "Both account numbers must be identical. Please re-enter_checking account number.",
  "checking_account_empty_error_message": "Please enter checking account number.",
  "routing_empty_error_message": "Please Enter a valid routing and transit number.",
  "invalid_routing_error_message": "The first digit should start with 0,1,2 or 3. Invalid Routing number.",
  "vendor_email_recipient_error_message": "Invalid Email Address Format.",
  "edit_routing_caption": "Enter 9-digit Routing and Transit Number:",
  "edit_checking_caption": "Enter Checking Account Number: <br></br> (Do not include check number)",
  "re_enter_caption": "Re-Enter Checking Account Number:",
  "cancel__edit_caption": "Cancel",
  "save_account_caption": "Save changes to bank account information",
  "account_verify_caption": "Account Verification Required",
  "account_verify_sub_caption": "All changes to a bank account registration requires re-entry of your name and Tax Id. Please allow one full business day for completion of a banking change and always verify your account number prior to submitting an on-line invoice.",
  "contact_name_caption": "Contact name",
  "social_security_caption": "Social Security no.",
  "employer_id_no_caption": "Employer ID no.",
  "terms_caption": "Terms and Conditions",
  "terms_conditions_text": "1) Authorizes Holman to make payment directly to the bank account you will be providing <br/> 2) Consents to pay the bank handling fee as agreed with Holman during your enrollment process <br/> 3) Acknowledges that the origination of all transactions to the account must comply with U.S. law <br/> 4) **Will inform Holman of any changes, including account and business location at least 5 business days prior to the change**<br/> 5)  Acknowledges that Holman may reproduce an invoice evidencing transactions under this agreement",
  "radio_button_agree_caption": "I have read and agree to the terms and conditions indicated above.",
  "cancel_verify_caption": "Cancel",
  "continue_verify_caption": "Continue",
  "terms_and_condition_error_message": "Please agree to the terms and conditions in order to continue.",
  "services_view_caption": "Services",
  "minority_vendor_caption": "Minority Vendor",
  "save_services_caption": "Save changes to services",
  "services_Cancel": "Cancel",
  "edit_services_caption": "Edit services",
  "vendor_account_confirmation_no": "No",
  "vendor_account_confirmation_yes": "Yes",
  "vendor_account_confirmation_title": "Vendor Account",
  "vendor_account_confirmation_message": "This page has unsaved changes. Please click CANCEL to stay on this screen.",
"grow_business_caption": "Grow your business",
"my_units_caption": "Get more business today",
"total_vehicle_count_caption": "Total Holman vehicles assigned to you",
"services_due_now_caption": "Holman vehicles you have worked on in the past with services due now",
  "additional_services_due_count_caption": "Additional Holman vehicles in your area with services due now",
  "your_units_title": "Your Units",
  "units_due_caption": "Clients with Holman vehicles that you have serviced in the past",
  "units_due_sub_caption": "Holman High Performance Vendors have visibility to vehicles in their respective areas, and are provided with tools to help manage the preventative services on those assets. This page contains information such as vehicles which are coming due or are past due for preventive services or inspections. Holman is providing this information only to our High Performance Vendors.",
  "my_units_service_due": "Services due",
  "my_units_email_driver": "Email driver",
  "my_units_client_name": "Customer name",
  "my_units_vehicle": "Vehicle",
  "my_units_vin8": "VIN no.",
  "my_units_reject_unit": "Reject unit",
  "other_units_caption": "Other units",
  "confirm_reject_title": "Rejecting Unit?",
  "confirm_reject_message": "Are you sure, you want to reject the unit?",
  "confirm_reject_yes": "Yes",
  "confirm_reject_no": "No",
  "close_without_save_caption": "Close",
  "due_services_caption": "This vehicle has service due",
  "create_po_caption": "Create a PO",
  "service_caption": "Services",
  "schedule_caption": "Schedule",
  "due_caption": "Due",
  "company_name_title": "Company name",
  "phone_title": "Phone",
  "ari_id_title": "Holman Issued ID",
  "search_by_title": "Search by:",
  "sign_in_caption1": "Registered ",
  "sign_in_caption2": "Sign In",
  "register_caption": "Register ",
"linked_account_title": "To Access Your Linked Account",
  "select_caption": "Select Location",
  "corporate_account_link": "Corporate Account ",
  "or_caption": "Or",
  "other_units_due_sub_caption": "Additional business opportunities, these units have not been in your shop.",
  "add_services_sub_caption": "The fleet manager or driver of this vehicle has been notified of this pending service.",
  "error_message_incorrect_identification_canada": "Invalid ID. Please enter in the following format: (XX-XXXXXX)",
  "employer_id_no_caption_CAN": "Business Number",
  "edit_routing_caption_canada": "Enter Institute ID followed by Transit ID:",
  "gst_caption_canada": "Business Number",
  "units_due_sub_caption_non_hp": "Holman High Performance Vendors have visibility to vehicles in their respective areas, and are provided with tools to help manage the preventative services on those assets. This page contains information such as vehicles which are coming due or are past due for preventive services or inspections.",
  "faq_purchase_question100": "Why is my payment amount different from the amount that I submitted?",
  "faq_purchase_answer100": "There is a Holman Pay amount applied to each invoice total; regardless of what services are rendered. E.G.: LOF, inspections, services, tax etc.",
  "faq_general_operations_question101": "Will I be able to update my shop’s information (Banking, email) on" + '<divFAQ> <br></br> </divFAQ>' + " Holman PartnerConnect?",
  "faq_general_operations_answer101": "Yes, Holman PartnerConnect will allow users to update account critical information such as bank account, phone and e-mail contacts. This can be found by clicking on “YOUR ACCOUNT” at the top of the main landing page.",
  "faq_general_information_question102": "How will Holman PartnerConnect benefit me?",
  "faq_general_information_answer102": "Holman PartnerConnect will allow you to create purchase orders online, for washes and detailing,the operations that are typically performed in your shop. In most cases, you can simply skip the phone by creating a purchase order request over the internet.  In addition to creating PO's online, our new system will also provide you with purchase order status at a glance, while allowing for PO edits on the fly. Once work on the vehicle has been completed, you can quickly and easily review and close the purchase order. All of these features are designed to save you time and increase productivity, which translates to real, tangible savings as you go about the course of your busy day.",
  "vehicle_search_error": "{vehicleSearchError}",
  "po_creation_error": "{poCreationError}",
  'vin8_label': 'VIN8',
  'purchase_order_label': 'Purchase Order',
  'invoice_label': 'Invoice',
  'payment_label': 'Payment',
  'search_duration': 'Duration',
  'last_60_days': 'Last 60 days',
  'last_120_days': 'Last 120 days',
  "car_wash_title": "Select client and enter vehicle number to create a PO",
  "car_wash_plate": "Plate #",
  "car_wash_state": "State",
  "car_wash_or": "Or",
  "car_wash_client_no": "Client no.",
  "car_wash_ari_unit_no": "Unit no.",
  "client_number_error": "Please select a client number.",
  "ari_unit_no_error": "Please input your unit number.",
  "vendor_details_title_car_wash": "Car wash service",
  "payment_in_progress_total_amount_column_header": "Total",
  "payment_in_progress_plate_number_column_header": "Plate No.",
  "active_po_invoice_number_column_header": "Invoice No.",
  "car_wash_service_date_label": "Service date",
  "car_wash_invoice_no_label": "Invoice No.",
  "car_wash_repair_selection": "Car Wash",
  "detail_car_wash_repair_selection": "Detail Car Wash",
  "car_wash_create_po": "Request Payment of ",
  "total_info_label_car_wash": "Total",
  "tax_info_label_car_wash": "Tax",
  'search_po_payment_no_column_header': 'Payment no.',
  'search_po_vehicle_column_header': 'Vehicle',
  "vendor_details_title_request_no": "Courtesy Request No. {requestNo}",
  "dealer_draft_confirm_payment": "Confirm the payment will be credited to account ending in {checkingAccount}",
  "dealer_draft_add_edit_view_confirmation_title": "Delete Request",
  "request_payment":"Request Payment ",
  "restore_request": "Restore request",
  "vehicle_details_issue_date": "Issue Date",
  "vehicle_details_status": "Status",
  "optional_information_description": "Please provide the following information if available:",
  "optional_information_sub_description": "NOTE: Do not enter temporary plate or registration",
  "dealer_draft_delivery_date_description": "Delivery date",
  "dealer_draft_license_plate_description": "License Plate#",
  "dealer_draft_registration_date_description": "Registration Expiration",
  "dealer_draft_Registration_state_description": "Registration State",
  "dealer_draft_payment_accepted_error": "Please confirm payment before requesting approval.",
  "dealer_draft_delivery_date_invalid_error_label": "Please provide a past date",
  "dealer_draft_reg_date_invalid_error_label": "Please provide a future date",
  "add_new_service_item_caption": "Add Service items",
  "cost_details": "Cost",
  "request_no_subtotal_view": "Subtotal",
  "request_no_tax_rate_view": "Tax Rate",
  "request_location_tax_rate_view":"Location",
  "show_all_waiting_requests": "Show all {poCount} requests",
  "hide_older_requests": "Hide older requests",
  "dealer_draft_active_requests_title": "Active Requests",
  "dealer_draft_active_requests_client_no": "Client No.",
  "dealer_draft_active_requests_vehicle_no": "Holman Unit No.",
  "dealer_draft_active_requests_requests_no": "Request No.",
  "dealer_draft_active_requests_vin8": "Vin8",
  "dealer_draft_active_requests_no_requests_caption": "None at this Time",
  "quick_start": "Quick start",
  "username_and_password": "User name and Password",
  "forgot_password": "Forgot Password",
  "explanation_of_po_types": "Brief explanation of Purchase order types",
  "your_action_is_required": "Your action is required",
  "approved_for_service": "Approved for service",
  "waiting_for_ari_client_approval": "Waiting for Holman/Client approval",
  "payment_in_progress": "Payment in Progress",
  "search_for_my_pos": "Search my Purchase orders",
  "searching_for_vehicle": "Entering a vehicle serial number and what it returns",
  "close_po": "Close a Purchase order",
  "build_po": "Build a Purchase order",
  "edit_po": "Edit a Purchase order",
  "help": "Help",
  "contact": "Contact",
  "cancel_po": "Cancel a Purchase order",
  "search_tips": "Search Tips",
  "vehicle_information": "Vehicle information, history, parameters, PM schedules",
  "aged_pos": "Aged Purchase Orders",
  "old_browser_warning": "This feature may not work correctly as your browser seems a little outdated. We recommend upgrading to the latest version of Chrome, Firefox or Internet Explorer.",
  "help_videos": "Tutorial Videos",
  "service_classification": "Business Classification",
  "maintenance_general_classification": "General Repair & Maintenance",
  "other_classification": "Car Wash or Detail-Only",
  'corporate_vendor_details_title': 'Corporate Account',
  'corporate_vendor_details_sub_title': 'To Access Your Main Corporate Account',
  'corporate_vendor_login_text_1': `Sign in ? click `,
  'corporate_vendor_login_text_2': `here`,
  'corporate_vendor_forgot_pwd_text_1': `Forgot your password ? `,
  'corporate_vendor_forgot_pwd_text_2': 'send me an email',
  'help_faq_return': 'Return',
  'supplier_enrollment_subheader':'Already a Holman Supplier? Please return to the main page to sign in with your ID and Password',
  'not_enroll_text':'Not a Holman Partner? ',
  'register_here':'Register Here',
  'waiting_for_client_caption':'Waiting for client approval',
  'enroll_link':'Enroll',
  'recently_enrolled':'Recently enrolled?',
  'create_new_account':'Step 1 of 5: Create a New Account',
  'notification_email' : 'Step 5 of 5: Authorization Email Configuration',
  'ari_id':'ID Issued by Holman',
  'ssn':'Social Security no.',
  'employer_id':'Employer ID no.',
  'create_pwd':'Create Password',
  'confirm_pwd':'Confirm Password',
  'go_to_next_step':'Go to next step',
  'bank_info':'Step 3 of 5: Banking Information',
  'routing_number':'Enter 9 digit Routing and Transit number',
  'checking_acc_number':'Enter Checking Account Number (Do not include check number)',
  're_enter_checking_acc_number':'Re-Enter Checking Account Number',
  'payment_email':'Payment Email Address',
  'services':'Step 4 of 5: Services Profile',
  'note':'Checking Register indicates your acceptance of the authorization program.<br></br> Notification Emails will be delivered to the below address provided.<br></br> Additionally, you will also immediately be advised of the notification when logged into this website.<br></br>',
  'register':'Register',
  'onlineusage_caption':'Online usage',
  'onlineusage_description':'Purchase Orders created or closed online.',
  'repairvalue_caption':'Estimate accuracy',
  'repairvalue_description':'Repair costs are measured against available published pricing, as well ...',
  'pmcompletion_caption':'PM completion',
  'pmcompletion_description':'Holman vehicles assigned to you for which PM was completed on time.',
  'workretention_caption':'Work retention',
  'workretention_description':'Holman vehicles assigned to you for which work was retained.',
  'auth_notification_title':'Authorization Email Configuration',
  'currentRegistrationStatus':'Current Registration Status',
  'notRegistered':'Not Registered',
  'edit_configuration_caption':'Edit Authorization Email Configuration',
  'no_services_caption':'None',
  'faq_footer1':'You may find answers to your questions and concerns in our ',
  'faq_footer2':'Frequently Asked Questions.',
  'faq_title_help':'Help',
  'faq_caption_help':'Frequently asked questions',
  'contact_footer_1':'Click here',
  'contact_footer_2':' to send us an email with your question and we will be sure to get back to you soon',
  'contact_footer_3':'If you require alternate language assistance, please contact <br></br>one of our call centers and we will be happy to assist you. <br></br>In the US  - 800-227-2273 <br></br>In Canada - 800-363-7676 <br></br>In Mexico - 800-002-7492',
  'registered':'Registered',
  'cancel':'Cancel',
  'save_contacts':'Save changes to contact information settings',
  'header':'Supplier Enrollment Request',
  'physical_address':'Business Name & Address',
  'business_name':'Business Name',
  'address_1':'Address 1',
  'address_2':'Address 2',
  'city':'City',
  'state':'State',
  'zip':'Zip',
  'country':'Country',
  'contact_information':'Contact Information',
  'name':'Name',
  'phone':'Phone',
  'ext':'Ext.',
  'email':'Email',
  'reenter_email':'Re-enter email',
  'fax':'Fax (optional)',
  'referral_code':'Referral Code',
  'referral_code_note':'If you have a referral code enter it here',
  'submit':'Submit',
  'business_name_required':'Please enter Business name',
  'address1_required':'Please enter Address 1',
  'address2_required':'Please enter Address 2',
  'city_required':'Please enter City',
  'state_required':'Please select a State',
  'zip_required':'Please enter Zip code of 5 or 9 digits',
  'country_required':'Please select a Country',
  'contact_name_required':'Please enter Contact name',
  'phone_area_required':'Please enter Phone',
  'phone_prefix_required':'Please enter Phone',
  'phone_suffix_required':'Please enter Phone number',
  'email_required':'Please enter an email address (example: email@domain.com)',
  're_enter_email_required':'Please re-enter correct email address',
'supplier_enrollment':'Supplier Enrollment Request',
'terms_and_conditions':'Terms and conditions',
'all_officials_sub_heading':'All official Holman service facilities:',
  'read_and_agree':'I have read and agree to the terms and conditions indicated above.',
  'tc_initials' : 'Please enter first and last name initials',
  'sign_in' : 'Your initials',
  'accept_terms_and_condition': 'Please accept Terms and Conditions',
  'accepted_by':'Accepted by:',
  'tax_information':'Tax information',
  'legal_name':'Legal business name',
  'legal_name_note':'(as detailed in tax records)',
  'contact_name':'Contact name',
  'business_dba_required':'Business name (DBA)',
  'tax_classification':'Federal tax classification',
  'client_referral':'If you were referred to Holman by one of our clients, please provide their name',
  'liability_tax_classification':'Enter the tax classification (C=C corp, S=S corp, P=Partnership)',
  'term_must_be_selected':'Please read and agree to the Terms and Conditions',
  'terms_accepted_by_required':'Initials Required',
  'tax_classification_required':'Please choose a federal tax classification',
  'liability_tax_invalid_value':'Please enter a valid value for Liability Tax Classification',
  'ein_required':'Please either provide SSN or Employer Id',
  'legal_name_required':'Please provide a value for Legal Name',
  'contact_name_required_details':'Please provide a value for Contact Name',
  'approval_not_requested':'Approval not requested',
  'approval_request_not_submitted':'You have created/updated a Purchase Order but approval was never requested for it. Please verify if the repair is needed and request for approval. Otherwise Cancel the PO',
  'approval_request_or_cancel_po':'Request Approval or Cancel PO',
  "quick_start_guide":"Click Here for a Quick Video Training Guide",
  "thank_you":"Thank you",
  "confirmation_for_detail_submission":'Your enrollment request number is {requestId}.<br></br><br></br> Your enrollment request has been submitted for processing. You will receive an email with your enrollment status within three to five business days. <br></br><br></br> If you are approved, you will be instructed via email to complete the registration process ASAP by clicking on the hyperlink to return to the Suppliers website and provide your checking account information.<br></br><br></br> Upon approval, a permanent Holman Partner ID will be assigned to you. Use your temporaryenrollment request number for any correspondence with Holman. <br></br><br></br>  PLEASE DIRECT ANY ENROLLMENT INQUIRIES, OR REQUESTED DOCUMENTS TO <a>enrollments@holman.com</a>. <br></br><br></br> Thank you for submitting your enrollment request.',
  "terms_info":"Step 2 of 5: Holman Partner Connect Terms & Conditions",
  "select_one":"Select one:",
  "optOut":"Opt out",
  "name_auth_email":"Name",
  "email_auth":"Email Address",
  "add_new_contact":"Add new contact",
  "termsHeader":'General Terms and Conditions ("Holman Terms")',
  "search_for_po_orders_by_vin":"Search for Purchase order(s) by VIN8...",
  "search_for_po_orders_by_po":"Search for Purchase order(s) by PO #...",
  "search_for_po_orders_by_payment":"Search for Purchase order(s) by Payment #...",
  "search_for_po_orders_by_invoice":"Search for Purchase order(s) by Invoice #...",
  "search_for_po_orders":"Search for PO...",
  "or_by_caption":"Or by",
  "new_work_assignment":"New Work Assignment",
  "model":"Model",
  "search_zip":"Search Zip...",
  "reason_for_repair":"Please enter complaint, cause and correction here.",
  "engine_hours":"Hour meter <br></br> (if equipped)",
  "odometer_caption":"Odometer",
  "view_all_courtesy":"View all open Courtesy Requests",
  "register_header":"Registration",
  "contact_header":"Contact Name",
  "new_password_header":"New Password",
  "confirm_password_header":"Confirm New Password",
  "new_password_required":"Please enter new password",
  "re_enter_password_required":"Please re-enter correct password",
  "tax__classification_I":"Individual/Sole Proprietor",
  "tax__classification_C":"C Corporation",
  "tax__classification_S":"S Corporation",
  "tax__classification_P":"Partnership",
  "tax__classification_L":"Limited Liability Company",
  "complaint_code_DG99":"Diagnosis",
  "complaint_code_CL99":"Clutch concern",
  "complaint_code_FR99":"Frame concern",
  "complaint_code_GA99":"Instrumentation concern",
  "complaint_code_GL99":"Glass concern",
  "complaint_code_HE99":"Heater concern",
  "complaint_code_IN99":"Insurance concern",
  "complaint_code_RA99":"Radio concern",
  "complaint_code_TR99":"Transmission concern",
  "complaint_code_UP99":"Upfitted equip concern",
  "complaint_code_WI99":"Wiring concern",
  "complaint_code_XX99":"Other",
  "complaint_code_SA01":"Sales tax",
  "complaint_code_RE01":"Rental",
  "complaint_code_MA01":"MA01",
  "complaint_code_1224":"R/S due to driver error",
  "complaint_code_1024":"R/S for a mechnical break down",
  "complaint_code_1324":"R/S due to driver abuse",
  "complaint_code_3124":"Accident non reported",
  "complaint_code_3214":"Accident  reported",
  "complaint_code_3824":"Warranty repair",
  "complaint_code_4224":"R/S due to a warranty repair",
  "complaint_code_3811":"Fdx obsolete CD code",
  "complaint_code_3814":"Campaign directive",
  "complaint_code_4114":"Body maintenance",
  "complaint_code_RC01":"Roadside concern",
  "complaint_code_FL99":"Forklift",
  "complaint_code_HR99":"Hyrail",
  "complaint_code_AL99":"Aerial /  Lift",
  "complaint_code_RE99":"Refrigeration",
  "complaint_code_RC99":"Road call",
  "complaint_code_TL99":"Trailer",
  "complaint_code_DL99":"Driveline concern",
  "complaint_code_CA99":"Cab concern",
  "complaint_code_UN01":"Not supplied",
  "correctionTypes_REPLACE":"REPLACE",
  "correctionTypes_REPAIR":"REPAIR",
  "correctionTypes_ADJUST":"ADJUST",
  "correctionTypes_PM":"PM",
  "correction_parts_labor_subTitle":"(PARTS AND/OR LABOR)",
  "correction_labor_subTitle":"(LABOR ONLY)",
  "correction_pm_subTitle":"(MENU PRICED ITEMS)",
  "performance":"Performance",
  "chart_legend":"Chart legend",
  "best_in_class":"Best in class",
  "above_average":"Above Avg",
  "avg":"Avg",
  "needs_improvement":"Needs Improvement",
  "items_added":"Items added to PO:",
  "exceptions_Name":"Name",
  "exceptions_Base_Intellipay_Fee":"Base Holman Pay Fee",
  "exceptions_Escalator":"Escalator for non PartnerConnect use",
  "exceptions_Other_Notes":"Other Notes",
  "exceptions_Canada":"Canada",
"exceptions_Supplier":"Supplier:",
  "exceptions_Supplier_sub_header":"The following government entities have different terms than our standard Terms and Conditions (visit <a>https://partnerconnect.holman.com/terms</a> ). Please note that transactions for these specific entities will be processed according to the chart below. Transactions for all other Holman clients will follow the standard Terms and Conditions.",
  "exceptions_gov_canada":"Federal Government of Canada",
  "exceptions_intellipay_4":"4% Holman Pay Fee",
  "exceptions_intellipay_3":"3% Holman Pay Fee",
  "exceptions_no_escalator":"No escalator",
  "exceptions_other_notes1":"Allow existing payment flexibilities",
  "exceptions_other_notes2":"Can mandate APC and IP",
  "exceptions_prov_1":"Province of Northwest Territories",
  "exceptions_prov_2":"Province of British Columbia",
  "exceptions_prov_3":"Province of Nova Scotia",
  "exceptions_prov_4":"Province of Alberta",
  "exceptions_prov_5":"Province of Ontario",
  "exceptions_prov_6":"BC Hydro",
  "exceptions_prov_7":"Hydro One",
  "exceptions_rcmp":"RCMP",
  "exceptions_state_1":"State of Connecticut",
  "exceptions_state_2":"State of Georgia",
  "exceptions_state_3":"State of Minnesota",
  "exceptions_state_4":"State of New York",
  "exceptions_state_5":"State of North Carolina",
  "exceptions_state_6":"State of West Virginia",
  "exceptions_state_7":"State of Wisconsin",
  "exceptions_us":"United States",
  "contact_form_header":"To create a new supplier account, please use the link provided on the main webpage.",
  "terms_and_conditions_subheader_login":"We're updating the terms for your account.",
  "terms_and_conditions_header_login":"Terms & Conditions",
"cancel_po_footer_message":"If you have questions regarding these adjustments, please call <br/>1-866-274-1025 to discuss with a Holman technician",
"enrollement":"Enrollment",
"new_supplier_registration":"Registration",
"my_purchase_order":"My Purchase Orders",
"my_purchase_order_dynamic":"My {ordersTypeText} for last {days} days",
  "my_purchase_order_dynamic_search":"My {ordersTypeText} for {searchText}",
  "ododmeter_Validation_Message_6":"Please enter Odometer value",
  "continue_link":"Continue", 
  "tires_replaced":"Click on the Tires being replaced",
  "tires_tread":"Tires tread depth",
  "inches_caption":"Inches",
  "mm_caption":"MM",
  "unit_confirmation_box_title":"Confirm measurement unit change",
  "unit_confirmation_message_for_tires":"Measurement unit change will be applied to all the tires. Press Ok if you want to change it to ",
  "unit_confirmation_message_for_brakes":"Measurement unit change will be applied to all the brake components. Press Ok if you want to change it to ",
  "unit_confirmation_message_end_part":"?",
  "unit_confirmation_yes":"Yes, change the measurement unit.",
  "unit_confirmation_no":"No",
  "brake_rotor":"Brake Rotor measurements required",
  "brake_drum":"Brake Drum measurements required",
  "brake_lining":"Brake lining thickness required",
  "front_brakes":"Front",
  "left_brakes":"Left",
  "right_brakes":"Right",
  "milli_meter":"MM",
  "add_axle":"Add Axle",
  "remove_axle":"Remove Axle",
  "measurements_in":"Measurements in:",
  "spare_tire":"Spare",
  "add_wheels":"Add Wheels",
  "remove_wheels":"Remove Wheels",
  "province":"Province",
  "postalCode":"PostalCode",
  "province_required":"Please select a Province",
  "postalCode_required":"Please enter a valid Postal Code in the format: XXX XXX",
  "businessNumber":"Business Number",
  "institute_id":"Institute Id",
  "transit_id":"Transit Id",
  "checking_acc_no_canada":"Financial Institution Account Number",
  "reenter_checking_acc_no_canada":"Confirm Account Number",
  "business_number_required":"Invalid Business Number. Please enter in the following format: (XXXX)",
  "businessNumber_required":"Please provide a value for Business Number",
  "feedback_required":"Please fill out this field",
  "feedback_required_email":"Please include an '@' in the email address",
  'existing_po_error_selection_no': 'No',
  'existing_po_error_selection_yes': 'Yes',
  'existing_po_error_selection_po':"Purchase Order",
  "car_wash_error_1":"Odometer is not within our expected mileage range for this vehicle",
  "car_wash_error_2":"Please select a service date.",
  "car_wash_error_3":"Please enter an Invoice Number longer than 3 characters and remove any empty spaces",
  "car_wash_error_4":"Please enter Car Wash Cost",
  "car_wash_error_5":"Item exceeds allowable service costs please contact Holman at 800-227-2273",
  "car_wash_error_6":"Please enter Detail Car Wash Cost",
  "car_wash_error_7":"Detail Car Wash Cost exceeds Maximum Pricing",
  "car_wash_error_8":"Tax exceeds the tax threshold",
  "car_wash_error_9":"Odometer is not within our expected mileage range for this vehicle",
  "car_wash_error_10":"Tax exceeds the tax threshold",
  "unsaved_data":"You have unsaved changes that will be lost if you decide to continue. Click OK to continue or Cancel to stay on the same page.",
  "car_wash_select":"Select",
  "car_wash_confirmation_title":"Cancel Request",
  "car_wash_confirmation_message":"You are about to VOID this purchase order and remove it from Holman PartnerConnect. Are you sure you want to void the PO?",
  "car_wash_confirmation_no":"No",
  "car_wash_confirmation_yes":"Yes",
  "reason_T29":"WORN",
  "reason_T30":"CUSTOMER REQUEST",
  "reason_T03":"BLOW OUT",
  "reason_T04":"CUSTOMER COLLECTED/RETAINED",
  "reason_T31":"UNEVEN WEAR",
  "reason_T13":"PUNCTURED",
  "reason_T15":"SIDEWALL DAMAGE / BULGE",
  "reason_T21":"TREAD MISMATCH",
  "select_country":"Select Country",
  "select_country_usa":"USA",
  "select_country_canada":"Canada",
  "select_state":"Select State",
  "select_providence":"Select Province",
  "new_tires":"New Tire Details",
  "wheel_tread_depth_new_tooltip":"New Tread Depth",
  "wheel_tread_depth_old_tooltip":"Old Tread Depth",
  "tire_width_tooltip":"Tire Width",
  "tire_aspect_ratio_tooltip":"Aspect Ratio",
  "tire_rim_diameter_tooltip":"Rim Diameter",
  "tire_manufacturer_tooltip":"Tire Manufacturer",
  "tire_model_tooltip":"Tire Model",
  "tire_reason_for_replacement_tooltip":"Reason For Replacement",
  "confirmation_for_request_submission":"Thank you for taking the time to contact Holman. We are not accepting new vendor applications at this time. Thank you for filling out your information. Your facility will be placed on our vendor waiting list if a need arises for a repair facility in your area.",
  "purchase_order_paid":"Purchase order no. {poNumber}",
  "onlineUsage_month":"Online usage %, by month",
  "repairValue_month":"Repair value %, by month",
  "pmCompletion_month":"PM completion %, by month",
  "workRetention_month":"Work retention %, by month",
  "all_dropdown":"All",
  "invalid_email_format":"Email format is invalid",
  "terms_and_conditions_required":"Please accepted Terms And Conditions",
  "routing_number_required":"Please enter Routing number",
  "institute_id_required":"Please enter 3 digit Institute Id",
  "transit_id_required":"Please enter 5 digit Transit Id",
  "check_number_required":"Please enter the Checking account number",
  "check_number_required_canada":"Please enter Financial Institution account number",
  "re_enter_check_number_rquired":"Please re enter the Checking account number",
  "re_enter_check_number_rquired_canada":"Please re enter Financial Institution account number",
  "check_numbers_should_match":"Both account numbers must be identical. Please re-enter checking account number",
  "payment_email_required":"Please enter the Payment email address",
  "invalid_routing_number":"The first digit should start with 0,1,2 or 3",
  "routing_number_should_be_9_digits":"Routing number has to be 9 digits",
  "atleast_one_email_required":"Please enter atleast one email",
  "name_email_required":"Both Name and Email are required",
  "invalid_is_registered":"Either register for notification emails or opt out",
  "ari_required":"Please enter the correct ID supplied by Holman",
  "tax_id_required":"Please enter either SSN or Employer Id",
  "password_required":"Please enter Password",
  "confirm_password_required":"Please confirm password",
  "password_should_match":"Password does not match",
  "max_min_spec_not_met":"The entered value exceeds min/max specifications",
  "max_min_spec_required":"Min/Max specification is required",
  "front_axle_spec_required":"At least one front axle value must be filled",
  "rear_axle_spec_required":"At least one rear axle value must be filled",
  "front_axle":"Front Axle",
  "save_tire_changes":"Save",
  "continue_to_tire_spec":"Continue",
  "save_brake_changes":"Save",
  "brake_title":"Brakes {brake_component} required",
  "continue_to_brake_spec":"Continue",
  "rear_brakes":"Rear",
  "payments":"Payments",
  "invoices":"Invoices",
  "purchase_order":"Purchase Orders",
  "fee_message_national_account":"National account fee calculated at {feePercent}%.",
  "fee_message_insurance_po_intellipay":"Insurance purchase order, Holman Pay fee calculated at {feePercent}%.",
  "fee_message_po_accessed_online":"Purchase order initiated, edited, or closed online, Holman Pay fee calculated at {feePercent}%.",
  "fee_message_po_not_accessed_online":"Purchase order not initiated, edited, or closed online, Holman Pay fee calculated at {feePercent}%.",
  "dealer_submitted_request_error":"This is not a valid courtesy request",
  "dealerdraft_submit_label":"Courtesy Request",
  "search_for_dealerdraft_submit":"Search for Courtesy Request...",
  'redirect_sso_user': 'Redirecting to dashboard...',
  'signout_sso_user': 'You have been logged out of Holman PartnerConnect. It is now safe to close your browser.',
  "po_assistance_header_anony":"For assistance with an existing purchase order or to reach a Holman call center technician, please call one of the following: <Row><div>USA<b>  1-866-274-1025</b></div> <div>Canada<b>  1-800-363-7676</b></div> <div>Mexico<b>  1-800-002-7492</b></div></Row>",    
  "po_assistance_header_anony_MEX": "For assistance with an existing purchase order or to reach a Holman call center technician, please call : 1-800-002-7492",
  "plate_state_label":"Plate and State",
  "tag_prov_label":"Plate and Province",
  "service_card_label":"Service Card No.",
  "client_vehicle_label":"Client ID & Unit No.",
  "search_for_po_orders_by_vin8":"VIN8",
  "search_for_po_orders_by_plate":"Plate",
  "search_for_po_orders_by_state":"State",
  "search_for_po_orders_by_prov":"Province",
  "search_for_po_orders_by_client":"Client ID",
  "search_for_po_orders_by_vehicle":"Unit No.",
  "search_for_po_orders_by_service_no":"Service Card Number",
  "vin8_error":"Please input a vin8",
  "plate_error":"Please enter your license plate",
  "state_error":"Please select a State",
  "tag_error":"Please enter your plate",
  "providence_error":"Please select a Province",
  "client_error":"Please input your client number",
  "vehicle_error":"Please input your vehicle number",
  "service_error":"Please input your service card number",
  "reference_number_validation_message":"Please input your 6 digit reference number",
  "non_intellipay_account_message":"If you need to update your banking information, please contact the Partner Relations teams listed below: <br></br> In the United States - 1-888-683-8327,  <br></br> In Canada - 1-866-416-6542",
  "error_invoice_tax_canada":"Tax only applicable for {applicationTaxType} please correct.",
  "error_invoice_start_date":"Please enter Repair Start Date.",
  "view":"VIEW",
  "dealer_draft_processed_requests_title": "Processed Requests",
  "labor_rate":"Labor Rates",
  "optional_rates":"Optional rates",
  "body_shop_rate":"Body Shop Rate",
  "mobile_onsite_rate":"Mobile/Onsite Rate",
  "equipment_add_rate":"Equipment/Add-on Rate",
  "light_duty":"Light Duty",
  "medium_duty":"Medium Duty",
  "heavy_duty":"Heavy Duty",
  "trailer":"Trailer",
  "ari_standard_rate":"Holman Standard Rate (required)",
  "laborRate_required":"Please enter labor rate of $9.99 or more",
  "multi_client_name":"Client Name",
  "multi_full_vin":"Full Vin. No",
  "multi_year":"Year",
  "multi_make":"Make",
  "multi_model":"Model",
  "custom_error_message":"No purchase orders found, that match this criteria.",
  "multi_veh_title_repair_po":"Your search has returned multiple vehicles with the same information. Please select the vehicle you are working on from the following list in order to proceed to start your po creation.",
  "multi_veh_title_carwash_po":"Your search has returned multiple vehicles with the same information. Please select the vehicle you are working on from the following list in order to proceed to start your car wash po creation.",
  "multi_veh_title_search_vin":"Your search has returned multiple vehicles with the same information. Please select the vehicle you are working on from the following list in order to proceed.",
  
  "REFERENCE_NO_mandatory":"Please provide Reference number",
  "REFERENCE_NO_min_length":"Reference number should be at least {minLength} characters long",   
  "REFERENCE_NO_max_length":"Reference number should not be more than {maxLength} characters",
  "REFERENCE_NO_min_max_length":"Reference number should be between {minLength} and {maxLength} characters long",
  "REFERENCE_NO_exact_length":"Reference number should be exactly {exactLength} characters long",

  "YOUR_NAME_mandatory":"Please provide Your name",
  "YOUR_NAME_min_length":"Your name should be at least {minLength} characters long",   
  "YOUR_NAME_max_length":"Your name should not be more than {maxLength} characters",
  "YOUR_NAME_min_max_length":"Your name should be between {minLength} and {maxLength} characters long",
  "YOUR_NAME_exact_length":"Your name should be exactly {exactLength} characters long",

  "INVOICE_NO_mandatory":"Please provide invoice number",
  "INVOICE_NO_min_length":"Invoice number should be at least {minLength} characters long",   
  "INVOICE_NO_max_length":"Invoice number should not be more than {maxLength} characters",
  "INVOICE_NO_min_max_length":"Invoice number should be between {minLength} and {maxLength} characters long",
  "INVOICE_NO_exact_length":"Invoice number should be exactly {exactLength} characters long",

  "REPAIR_START_DATE_mandatory":"Please provide repair start date",
  "REPAIR_COMPLETION_DATE_mandatory":"Please provide repair completion date",
  "INVOICE_DATE_mandatory":"Please provide invoice date",
  "ODOMETER_mandatory":"Please provide odometer",
  "TAX_mandatory":"Please provide tax or tax exemption reason",
  "CONFIRM_WORK_IS_COMPLETE_mandatory":"Please confirm tha the work is complete",
  "CONFIRM_BANK_ACCOUNT_mandatory":"Please confirm the account number",

  "vendor_details_sub_title_processed":"Your Request has been accepted and submitted for payment. Please <a>Print</a> and retain this page for your records.",

  "businessNumber_last4digits":"Last 4 digits of Business Number",
  "recover_password_subtitle": "Plus one of the following:",
  "error_message_incorrect_identification_last_4_digits": "Invalid ID. Please enter in the following format: (XXXX)",
  "invalid_payment_email_format": "Payment email format is invalid",
  "login_button": "Login",
  "forgot_password_button": "Forgot Password",
  "reset_password_result_title": "Reset your password",
  "reset_password_result_subtext": "The reset password link you have selected was either used or has expired. For your security, please complete the reset password process again.",
  "reset_password_result_subtext_2": "If you have any questions, please contact the U.S Vendor Relations team at 1-888-683-8327 or the Canadian Vendor Relations team at 1-866-416-6542.",
  "reset_password_success_title": "Reset your password",
  "reset_password_success_subtext": "Your password has been successfully changed. You may login to Holman PartnerConnect now.",
  "confirm_password_field_required": "Confirm password cannot empty",
  "confirm_password_field_does_not_match": "Confirm password doesn\'t match",
  "reset_password_title": "Reset your password",
  "password_field_required": "Password must meet complexity criteria below",
  "new_password_field": "New Password: ",
  "confirm_password_field": "Confirm Password: ",
  "reset_password_button": "Reset Password",
  "reset_password_home_button": "Home",
  "federal_tax_id_last4digits": "Last 4 digits of Federal Tax Id",
  "federal_tax_id_required": "Invalid Federal Tax Id. Please enter in the following format: (XXXX)",
  "recover_too_attempts_title": "Reset your password",
  "recover_too_attempts_subtext": "You have exceeded the number of successful reset password attempts that can be completed in one hour and your account has been locked.",
  "recover_too_attempts_subtext_2": "If you have any questions, please contact the U.S. Vendor Relations team at 1-888-683-8327, the Canadian Vendor Relations team at 1-866-416-6542 or ARIZA customer service at 01-800-00-27492.",
  "return_button": "Return",
  "recover_password_or_seperator": "or",
  "mex_tax_id" : "Federal Tax Id",
  "recaptcha_title":"Please click checkbox below, this is a security measure to avoid spam.",
  "password_policy_title" : "New Password must meet the following criteria:",
  "password_policy_8_chars" : "Contain at least 8 characters",
  "password_policy_uppercase" : "Contain at least 1 uppercase letter",
  "password_policy_lowercase" : "Contain at least 1 lowercase letter",
  "password_policy_digit" : "Contain at least 1 number",
  "password_policy_special_char" : "Contain at least 1 symbol from this list !#$%&\'()*+,-./:;<=>?@[\]^_{|}~",
  "invalid_token_title": "Holman User",
  "invalid_token_subtext": "Holman User token is invalid.",
  "invalid_token_subtext_2": "Please contact APCITsupport@holman.com for assistance.",
  "vendor_search_label": "Enter a Vendor ID to access the account",
  "vendor_search_placeholder": "Enter Vendor ID",
  "vendor_search_invalid": "Invalid Vendor Search Container",
  "vendor_search_permission_error": "Holman User doesnot have permission to view this vendor",
  "vendor_search_empty": "Vendor Id cannot be empty",
  "vendor_search_error": "Vendor does not found",
  "vendor_search_suffix_missing": "Enter the country suffix at the end of each vendor ID (UA, MM, CC)",
  "change_password_title": "Change your password",
  "current_password_field": "Current Password",
  "already_used_in_last_5_passwords": "The password you have entered is not valid because it matches one of the last five you have used. Please create a new password.",
  "current_password_field_required": "Current password cannot empty",
  "change_password_sub_title": "Our records indicate that your current password doesn’t meet the complexity requirements. For your security, please set up a new password.",
  "qty_must_match_with_tire_selection_counter":"You must CLICK ON THE TIRE(s) in the diagram that corresponds to the position you are replacing. The tire will turn blue once you have clicked on it. Press RETURN to correct your entry.",
  "tls_warning_title": "Browser security warning",
  "tls_warning_message_line1": "This website does not allow older browsers to be used for security purposes. To access this website, you will need to upgrade to the latest version of Microsoft Internet Explorer, Google Chrome, Apple Safari or Mozilla Firefox.",
  "tls_warning_message_line3": "If you have any questions, please consult your IT department or internet security provider.",
  "tls_warning_message_line4": "To reach Holman regarding this security update, please contact Holman PartnerConnect help desk at <a>partnerconnect@holman.com</a>",
  "tls_warning_learn_more_button": "Learn More",
  "tls_learn_more_title": "Outdated or unsupported browser detected",
  "tls_learn_more_message": "<p><p>This website does not allow older browsers to be used for security purposes. To access this website, you will need to upgrade to the latest version of Internet Explorer (IE), Chrome, Safari or Firefox.</p><p>Information for your IT Help desk:TLS 1.0 and TLS 1.1. protocols are disabled on this website in order to meet best practice security standards for safeguarding data.</p>You will need to install a web browser that is compatible with the TLS 1.2 protocol. The following browser versions support TLS 1.2:</p><ul><li>Google Chrome version 30 or higher</li><li>Mozilla Firefox version 27 or higher</li><li>Microsoft Internet Explorer 11 or higher</li><li>Microsoft Edge</li><li>Apple Safari 7 or higher</li></ul><br></br>If you have any questions, please contact Holman at <a>partnerconnect@holman.com</a>.</p>",
  
  // Crane inspection
  "crane_inspection__title": 'Mobile crane safety inspection',
  "crane_inspection__edit_form": 'View/Edit Crane inspection form',
  "crane_inspection__save_continue": 'Save and continue',
  "crane_inspection__pass": 'PASS',
  "crane_inspection__fail": 'FAIL',
  "crane_inspection__manufacturer": "Manufacturer",
  "crane_inspection__manufacturer_required": "Provide manufacturer",
  "crane_inspection__capacity": "Capacity",
  "crane_inspection__capacity_required": "Provide capacity",
  "crane_inspection_form__serial_number": "Serial number",
  "crane_inspection_form__serial_number_required": "Provide serial number",
  'crane_inspection__serial_number': "Serial number",
  'crane_inspection__model_number': "Model number",
  'crane_inspection__hoistSystemBrakesType': "Hoist system brakes",
  'crane_inspection__hoistSystemBrakesType_Hydraulic': "Hydraulic",
  'crane_inspection__hoistSystemBrakesType_Air': "Air",
  'crane_inspection__boomJibBrakesType': "Boom/jib brakes",
  'crane_inspection__boomJibBrakesType_Hydraulic': "Hydraulic",
  'crane_inspection__boomJibBrakesType_Air': "Air",
  'crane_inspection__interval': "Type",
  'crane_inspection__interval_annual': "Annual",
  'crane_inspection__interval_quadrennial': "Quadrennial",
  'crane_inspection__interval_other': "Other",
  'crane_inspection_form__deficiencies': "Deficiencies",
  'crane_inspection__serial_number_required': "Provide serial number",
  'crane_inspection__model_number_required': "Provide model number",
  'crane_inspection__hoistSystemBrakesType_required': "Provide hoist system brakes",
  'crane_inspection__boomJibBrakesType_required': "Provide boom/jib brakes",
  'crane_inspection__interval_required': "Provide type",
  'crane_inspection__interval_other_required': "Provide other type",
  "crane_inspection__has_inspection_passed_required": 'Please select PASS or FAIL',
  "crane_inspection__has_inspection_passed_components_invalid": 'All vehicle components need to be marked as Sat. or N/A to pass inspection',
  "crane_inspection__has_inspection_passed_unsafe_conditions": 'The inspection cannot pass with unsafe conditions listed',
  "crane_inspection__has_inspection_passed_no_failure_specified": 'Please specify failed vehicle components and deficiencies',
  "crane_inspection__has_inspection_passed_no_failure_unsat_deficiencies": 'Vehicle components marked as Sat. or N/A cannot have deficiencies listed',
  "crane_inspection__has_inspection_fail_deficiencies_without_unsat": 'Please specify the deficiencies for Unsat. vehicle components',
  "crane_inspection__has_inspection_failed_all_components_required": 'All components must specify Sat./Unsat./NA.',
  "crane_inspection__inspector_attest": 'I attest that I am qualified to perform an ANSI and/or OSHA inspection and that this unit has passed all the ANSI and/or OSHA inspection requirements.',
  "crane_inspection__attest_required": 'Please attest to this statement',
  "crane_inspection__inspector_signature": "Inspector's signature",
  "crane_inspection__inspector_signature_note": "By typing your name, you agree this will serve as the electronic respresentation of your signature.",
  "crane_inspection__signature_required": 'Please enter your signature',
  "crane_inspection_confirmation__fail_confirm_title": 'ANSI crane mobile inspection',
  "crane_inspection_confirmation__fail_confirm_question": 'Are you going to repair the failed vehicle components on this repair invoice?',
  "crane_inspection_confirmation__fail_confirm_yes": 'Yes and edit PO',
  "crane_inspection_confirmation__fail_confirm_no": 'No and specify the failed components',
  "crane_inspection_form__title": 'Mobile crane safety inspection form',
  "crane_inspection_form__component_subtext_line1": '* Important to maintain current copy of inspection report in this vehicle.',
  "crane_inspection_form__component_subtext_line2": '* Local State laws may request inspections more frequently. Investigate all States in which you travel.',
  "crane_inspection_form__component_subtext_line3": 'All unsatisfactory wire rope shall be replaced as per original manufacturer\'s recommendations.',
  "crane_inspection_form__customer": 'Customer',
  "crane_inspection_form__address": 'Address',
  "crane_inspection_form__unit_number": 'Unit number',
  "crane_inspection_form__year": 'Year',
  "crane_inspection_form__make": 'Make',
  "crane_inspection_form__license_plate": 'License plate',
  "crane_inspection_form__date": 'Date',
  "crane_inspection_form__save": 'Save and continue',
  'crane_inspection_form__all_ok': 'All OK',
  'crane_inspection_form__item': 'Action items',
  'crane_inspection_form__pass': 'Sat.',
  'crane_inspection_form__fail': 'Unsat.',
  'crane_inspection_form__na': 'N/A',
  'crane_inspection_form__fail_confirm_title': 'Repair PO?',
  'crane_inspection_form__fail_confirm_question': 'Are you going to repair the failed vehicle components on this repair invoice?',
  'crane_inspection_form__fail_confirm_yes': 'Yes and edit PO',
  'crane_inspection_form__fail_confirm_no': 'No and continue',
  'crane_inspection_form__rail_gear': "Rail gear",
  'crane_inspection_form__rail_gear_model': "Rail gear model",
  'crane_inspection_form__rail_gear_front_serial_number': "Front serial number",
  'crane_inspection_form__rail_gear_rear_serial_number': "Rear serial number",
  'crane_inspection_form__component_300': "Current certification posting",
  'crane_inspection_form__component_301': "*Operator instructions",
  'crane_inspection_form__component_302': "*Preventative maintenance records",
  'crane_inspection_form__component_303': "Hand signal charts",
  'crane_inspection_form__component_304': "*Proper load charts",
  'crane_inspection_form__component_305': "Properly marked operator controls, levels, diagrams",
  'crane_inspection_form__component_306': "Required informational/warning decals",
  'crane_inspection_form__component_307': "Warning device(s)/Backup alarm",
  'crane_inspection_form__component_308': "Safety and machine guards",
  'crane_inspection_form__component_309': "Access provisions (railings, etc.)",
  'crane_inspection_form__component_310': "Fire extinguishers",
  'crane_inspection_form__component_311': "Anti-two block",
  'crane_inspection_form__component_312': "Load cells and indicators",
  'crane_inspection_form__component_313': "Electrical components/assemblies",
  'crane_inspection_form__component_314': "Emergency stop switch",
  'crane_inspection_form__component_315': "Overload switch",
  'crane_inspection_form__component_316': "Master switches or drum controller",
  'crane_inspection_form__component_317': "Switches, contactors, relays",
  'crane_inspection_form__component_318': "*Main control station",
  'crane_inspection_form__component_319': "Remote control station and buttons",
  'crane_inspection_form__component_320': "Bucket/ground controls",
  'crane_inspection_form__component_321': "Indicators: level, boom angle/length, drum rotation, load.",
  'crane_inspection_form__component_322': "Motors",
  'crane_inspection_form__component_323': "*Shafts, gearing, coupling, guards",
  'crane_inspection_form__component_324': "*Bearing assembly(s)",
  'crane_inspection_form__component_325': "Seals",
  'crane_inspection_form__component_326': "Sheave assemblies",
  'crane_inspection_form__component_327': "*Equalizer sheave and pin",
  'crane_inspection_form__component_328': "Brakes",
  'crane_inspection_form__component_329': "Limit switch(s)",
  'crane_inspection_form__component_330': "Locking devices",
  'crane_inspection_form__component_331': "*Drums and anchors",
  'crane_inspection_form__component_332': "*Load block assembly(s)",
  'crane_inspection_form__component_333': "Counterweight hooks and bolts",
  'crane_inspection_form__component_334': "*Hooks tram/throat",
  'crane_inspection_form__component_335': "Hook NDT",
  'crane_inspection_form__component_336': "*Bearing assembly(s)",
  'crane_inspection_form__component_337': "*Seals",
  'crane_inspection_form__component_338': "Brakes",
  'crane_inspection_form__component_339': "Drive line and battery(s)",
  'crane_inspection_form__component_340': "*Friction clutches, linkage, and pins",
  'crane_inspection_form__component_341': "*Gear box; all gears and pinions",
  'crane_inspection_form__component_342': "*Boom and attachments",
  'crane_inspection_form__component_343': "*Boom rotation assy., locks, and brakes",
  'crane_inspection_form__component_344': "*Boom hoist lock or pawl",
  'crane_inspection_form__component_345': "*Boom and jib back stops",
  'crane_inspection_form__component_346': "Swing barriers",
  'crane_inspection_form__component_347': "*Automatic boom kickout",
  'crane_inspection_form__component_348': "*Outrigger assembly(s)",
  'crane_inspection_form__component_349': "*Welds, chords, lacings",
  'crane_inspection_form__component_350': "*Wedge sockets",
  'crane_inspection_form__component_351': "Drums",
  'crane_inspection_form__component_352': "Automatic lubrication systems",
  'crane_inspection_form__component_353': "Manual lubrication/fluid levels",
  'crane_inspection_form__component_354': "Gauge (oil, etc.) and gauge ports",
  'crane_inspection_form__component_355': "Superstructure (main frame, glass)",
  'crane_inspection_form__component_356': "Bolts, nuts, rivets, mounts, brackets",
  'crane_inspection_form__component_357': "*Air/hydraulic system: cylinders, hoses and fittings",
  'crane_inspection_form__component_358': "Muffler/exhaust system",
  'crane_inspection_form__component_359': "Housekeeping",
  'crane_inspection_form__component_360': "Steering",
  'crane_inspection_form__component_361': "Weldments",
  'crane_inspection_form__component_362': "Bolts",
  'crane_inspection_form__component_363': "Tracks",
  'crane_inspection_form__component_364': "Track drive",
  'crane_inspection_form__component_365': "Main Hoist",
  'crane_inspection_form__component_366': "Aux. Hoist",
  'crane_inspection_form__component_367': "Aux. Hoist (2)",
  'crane_inspection_form__component_368': "Pendants",
  'crane_inspection_form__component_369': "Pendants (2)",
  'crane_inspection_form__component_370': "Jib Stays",
  'crane_inspection_form__component_371': "Boom Hoist",
  'crane_inspection_form__component_group_18': "Records",
  'crane_inspection_form__component_group_19': "Safety equipment",
  'crane_inspection_form__component_group_20': "Electrical systems & control systems",
  'crane_inspection_form__component_group_21': "Hoist systems",
  'crane_inspection_form__component_group_22': "Boom / jib",
  'crane_inspection_form__component_group_23': "Lubes systems gauges",
  'crane_inspection_form__component_group_24': "Miscellaneous",
  'crane_inspection_form__component_group_25': "Crawler car body",
  'crane_inspection_form__component_group_26': "Wire rope and hook condition report",
  'crane_inspection__vehicle_history_title': "Vehicle history record",
  'crane_inspection__vehicle_history_report_number': "Report number",
  'crane_inspection__vehicle_history_unit_number': "Fleet unit number",
  'crane_inspection__vehicle_history_inspection_date': "Date",
  "insurance_edit_check_message":"If you need to revise an estimate on claim-related events please email a supplement and supporting documentation to <a>{emailAddress}</a>",
  "insurance_edit_check_ok":"Ok",
  "work_completed_tax_deferred_header":"Tax amount to be added later.",
  "work_completed_tax_deferred_subtext":"If you believe you are seeing this message in error, please call 1-866-274-1025 to discuss with a Holman technician.",
  "work_completed_tax_deferred_subtext_can":"If you believe you are seeing this message in error, please call 1-800-363-7676 to discuss with a Holman technician.",

/*User Account Menu */
  'user_account_menu_your_account':	' Your account',
  'user_account_corporate_account_link':	' Corporate account',
  'user_account_menu_manage_stores':	' Manage stores',
  'user_account_menu_manage_users':	' Manage users',
  'user_account_menu_access_stores':	' Access stores',
  'user_account_menu_sign_out':	' Sign out',

 'all_stores__title':'Your stores',
 'all_stores_ari_vendor_id_store_id':'Holman ID/ Store no.',
 'all_stores_address':'Store address',
 'all_stores__manage_stores':'Access store',
 'all_stores__pagination_results_caption':'Showing results {first}-{last} of {storeCount}',
 'all_stores__search_text_place_holder':'Search for store',
 'all_stores__search_previous':'previous',
 'all_stores__search_next':'next',
 'all_stores__search_no_stores_text':'No results found for this search criteria.',

 'manage_stores__title':'Manage stores',
 'manage_stores_ari_vendor_id_store_id':'Holman ID/ Store no.',
 'manage_stores_address':'Store address',
 'manage_stores__manage_stores':'Manage store',
 'manage_stores__pagination_results_caption':'Showing results {first}-{last} of {storeCount}',
 'manage_stores__search_text_place_holder':'Search for store',
 'manage_stores__search_previous':'previous',
 'manage_stores__search_next':'next',
 'manage_stores__search_no_stores_text':'No results found for this search criteria.',

  'user_profile__title':'User profile',
  'user_profile_resend_invite_link':'Send invite',
  'add_stores__title':'Add store(s)',
  'user_profile__edit_permissions':'Edit permissions',
  'user_profile__remove_access':'Remove access',
  'user_profile__remove_access_all':'Remove access to all stores',
  'user_profile__back':'Back to all users',
  'user_profile_no_stores_text':'User does not have access to any stores.',

  'manage_users__title':'Manage users',
  'manage_users_name':'Name',
  'manage_users_email':'Email',
  'manage_users_resend_invite_btn':'Send invite',
  'manage_users_remove_all_user_access_btn':'Remove all access',
  'manage_users__no_search_results':'No users matching the search criteria found',
  'manage_users__pagination_results_caption':'Showing results {first}-{last} of {userCount}',
  'manage_users__pagination_results_search_caption':' for the search term',
  'manage_users__clear_search_results_caption':'Clear search results',
  'manage_users__search_text_place_holder':'Search for user',
  'manage_users__search_previous':'previous',
  'manage_users__search_next':'next',
  'add_user__title':'Set up new user',
  'manage_users__search_no_users_text':'No results found for this search criteria.',
  'manage_users__no_users_to_manage': 'You are not currently managing any users.',

  'remove_access__title':'Remove user access',
  'remove_access_message':"Are you sure you want to remove {firstName} {lastName}'S access for {vendorId} at {address}? Click yes to continue. Click no to cancel.",
  'remove_all_access_message':"Are you sure you want to remove {firstName} {lastName}'S access to all of their stores? Click yes to continue. Click no to cancel.",
  'remove_access_yes':'Yes',
  'remove_access_no':'No',
  'delete_user':'Delete user',

  'recover_password_sub_heading'   :  'Please click on the appropriate flag',
  'recover_password_email_address' : 'E-mail address ID ',  //identification_email
  'recover_password_vendorId':'Holman issued ID ',
  'recover_password_ARI_access_error_message':'Please select how do you access Holman Partner Connect',

  'reset_password_persona_user_registration_mesg':'Enter password to register your account with Holman Partner Connect',
  'reset_password_persona_user_registration_title':'Set password',

  'invalid_user_email_format':'Please enter a valid Email address',
  'persona_header_text':'Holman PartnerConnect',
  'store_profile__title':'Store profile',
  'email_confirmation_title':'Email confirmation',
  'email_confirmation_message':'An invitation link has been sent to {firstName} {lastName}.',
  'email_confirmation_ok':'Ok',
  
  'store_profile_name':'Name',
  'store_profile_email_address':'Email address',
  'store_profile__pagination_results_caption':'Showing results {first}-{last} of {userCount}',
  'store_profile__search_previous':'previous',
  'store_profile__search_next':'next',
  'store_profile__admin':'Administrator',
  'store_profile__remove_access':'Remove access',
  'store_profile__back':'Back to manage stores',
  'store_profile_no_users_text':'There are no users who have access to this store.',

  // Holman Redirect Screen
  'holman_redirect__holman_button1': 'Go to new site',
  'holman_redirect__ari_button1': 'Stay on old site',
  'holman_redirect__holman_redirect__holman_button2': 'Go to the new Holman PartnerConnect website',
  'holman_redirect__ari_button2': 'Stay on old ARI PartnerConnect website',
  'holman_redirect__holman_redirect__holman_button3': 'Go to the new Holman PartnerConnect website',
  'holman_redirect__instructions_new_site': `<p>
  Please click the button below to navigate to the new Holman PartnerConnect website.
  If you have any of the following issues after navigating, follow the instructions below:
</p>

<ul>
  <li>
    Corporate firewall blocking new website: partnerconnect.holman.com
    <ul>
      <li>Click the <b>back button</b> to return to previous screen and then click <b>Stay on old site.</b></li>
      <li>Work with your IT support team to unblock access to partnerconnect.holman.com</li>
    </ul>
  </li>
  <li>
    Not able to login because your username and password were stored on old site
    <ul>
      <li>Use the forgot password link on the new site to reset your password.</li>                  
    </ul>
  </li>
  <li>
    If the previous solutions do not resolve your issues...
    <ul>
      <li>Contact Holman via email: <Mailto>aripartnerconnect@arifleet.com</Mailto></li>
    </ul>
  </li>
</ul>`,
  'holman_redirect__instructions_old_site': `<p>
  You can continue using the old site until <Date>April 30th</Date>.  During that time please try to use the new
  web site and resolve any issues.  Below are common issues and their resolutions:
</p>

<ul>
  <li>
    Corporate firewall blocking new website: partnerconnect.holman.com
    <ul>
      <li>Click the <b>back button</b> to return to previous screen and then click <b>Stay on old site.</b></li>                    
      <li>Work with your IT support team to unblock access to partnerconnect.holman.com</li>
    </ul>
  </li>
  <li>
    Not able to login because your username and password were stored on old site
    <ul>
      <li>Use the forgot password link on the new site to reset your password.</li>                  
    </ul>
  </li>
  <li>
    If the previous solutions do not resolve your issues...
    <ul>
      <li>Contact Holman via email: <Mailto>aripartnerconnect@arifleet.com</Mailto></li>
    </ul>
  </li>
</ul>`,


  'add_users_to_store_search_caption':'Search for users',
  'search_users_caption':'Find and select users to grant access',
  'search_users_add_user_text1':'User not found? No problem.',
  'search_users_add_user_text2':'You can set up a new user here',
  'select_users_caption':'Selected users',
  'select_users_none':'No users selected',
  'add_users_to_store_continue':'Finish selecting users and continue',
  'add_users_to_store__no_user_found':'No user found',
  'add_users_to_store_grant_permissions_title':'Grant permissions',
  'permissions_step__permissions_caption':'Grant permissions to',
  'permissions_return':'Return',
  'permissions_save_changes':'Save changes',
  'permissions__grant_delegate_access_title':'Grant administrator access',
  'permissions__grant_delegate_access_instructions':'Admins may create new users, set permissions and edit permissions. Admins may only grant or edit permissions for which they already have access.',
  'permissions_yes':'Yes',
  'permissions_no':'No',
  'permissions_features':'Permissions features',
  'permissions_orders':'PURCHASE ORDERS',
  'permissions_delivery_requests':'COURTESY DELIVERY REQUESTS',
  'permissions_your_account_settings_contact_details':'SETTINGS & CONTACT DETAILS',
  'permissions_bank':'BANK DETAILS',
  'permissions_tax':'TAX DETAILS',
  'permissions_documents':'UPLOAD DOCUMENTS',
  'permissions_auth_email':'UPDATE AUTHORIZATION EMAIL CONFIGURATION',
  'permissions_services':'SERVICES',
  'permissions_view':'View',
  'permissions_search_view':'Search / View',
  'permissions_create':'Create',
  'permissions_edit':'Edit',
  'permissions_close_view':'Close / View',
  'permissions_void':'Cancel (void)',
  'permissions_upload':'Upload',
  'add_users_to_store__add_user_title':'Add user to this store',
  'message_title': 'Payment details update in progress',
  'permissions__car_wash_vendor_po_note': 'For car wash vendors, all purchase order permissions are required',
  'payment_status_bulletin_body' : 'Recent changes to your bank account information have placed all payments on a temporary hold.  Please be prepared to receive a phone call from Holman to verify these changes to allow payments to resume.',
  'account_verify_sub_caption_for_payment_status':'Any changes to your bank account information will place all payments on a temporary hold. <alert>For security reasons</alert>, please be prepared to <b>receive</b> a phone call from Holman to verify these changes.',
  'account_verify_sub_caption_for_payment_email':'All changes to the email recipient field requires re-entry of your name and tax ID.',
  'edit_payment_email_caption':'Payment email recipient',
  'edit_payment_email_validation_error_message':'Please enter a valid payment email',
  'edit_payment_email_cancel__edit_caption':'Cancel',
  'edit_payment_email_save__caption':'Save payment email recipient',

  'payment_email_caption':'Payment email recipient',
  'edit_payment_email_information':'Edit payment email recipient',

  
  'add_users_to_store_add_new_user_caption2': 'To set up a new user, complete the fields below and click create user.  Once the user is created, an email with instructions to access Holman PartnerConnect will be sent.',
  'manage_stores_admin':'Administrator',
  'manage_stores_store_information':'Store information',
  'store_profile__search_text_place_holder':'Search for user',
  'store_profile__pagination_results_search_caption':' for the search term',

  'add_users_to_store_add_new_users_caption':'Set up new user',
  'add_new_user_first_name_placeHolder':'Enter first name',
  'add_new_user_last_name_placeHolder':'Enter last name',
  'add_new_user_email_placeHolder':'Enter email',
  'add_new_user_create_user':'Create user',
  'add_new_user__email_invalid':'Invalid email address format',
  'add_new_user__email_invalid_required':'Email address is required',
  'add_users_to_store_return':'Return',
  'add_users_to_store_add_new_user__continue':'Continue',
  'add_users_to_store_add_new_user__dirty_warning':"This screen has unsaved changes. Any new users you created will be saved, but permissions will not be. Click 'Close' to discard changes.",
  'add_users__back':'Back to all users',

  'add_stores_to_store_search_caption':'Search for stores',
  'search_stores_caption':'Find and select stores',
  'select_stores_caption':'Selected stores',
  'add_stores_to_user__continue':'Finish selecting stores and continue',
  'add_stores_to_user__no_store_found':'No store found',
  'add_stores_to_user__select':'Select',
  'add_stores_to_user__select_all_stores':'Select all stores',
  'add_stores_to_user__ari_vendor_id_store_id':'Holman ID / Store no.',
  'add_stores_to_user__address':'Store address',
  'add_stores_to_user__manage_stores':'Manage store',
  'add_stores_to_user__pagination_results_caption':'Showing results {first}-{last} of {storeCount}',
  'add_stores_to_user__pagination_results_search_caption':' for the search term',
  'add_stores_to_user__clear_search_results_caption':'. Clear search results',
  'add_stores_to_user__search_text_place_holder':'Search for store',
  'add_stores_to_user__search_previous':'previous',
  'add_stores_to_user__search_next':'next',
  'add_stores_to_user__search_no_stores_text':'No results found for this search criteria.',
  'select_stores_none':'No stores selected',

  'modify_user_permissions__grant_permissions_title':'Modify permissions',
  'modify_user_permissions__return':'Return',
  'modify_user_permissions__save_changes':'Save changes',    
  'modify_user_permissions__store':'Store',

  'permissions__select_all':'Click here to select all permission features',
  'permissions__deselect_all':'Click here to deselect all permission features',
  'permissions__bank_details':'Bank details',
  'permissions__unable_to_edit_permission_level':'You are unable to edit this feature at your current permission level.',
  'permissions__unable_to_edit_minimum':'The PO Search / View permission cannot be deselected because it is needed to view the PartnerConnect shopview screen and search for purchase orders.',
  'permissions__delivery_requests_us_only':'Only available for U.S. users',
  'permissions_close_view_edit':'Close / View / Edit',
  'user_profile_search_text_place_holder':'Search for store',

  'user_profile__search_no_users_text':'No results found for this search criteria.',
  'manage_stores__no_store_results':'User does not have access to any stores.',
  'all_stores__access_no_stores_text':'You do not have access to any stores.',
  'store_profile_search_no_users_text':'No results found for this search criteria.',

  'user_profile':'User profile',
  'reset_password_result_subtext3':'This link has expired. Please contact the person who invited you and request that he or she send a new link.',
  'send_invite_result_title':'Invitation',
  'auto_negotiated_note_US':'If you do not accept these changes, you must call Holman at \n1-866-274-1025',
  'auto_negotiated_note_CAN':'If you do not accept these changes, you must call Holman at \n1-800-363-7676',
  'ari_issued_id':'Holman Issued ID',
  'vendor_location':'Location',
  'search_po_client_name_column_header':'Client Name',
  'search_po_full_vin_column_header':'Full Vin. No',
  'search_po_client_id_amount_column_header':'Client ID',
  'client_po_title':'Client-provided PO#',
  'client_po_label':'Client PO',
  'search_for_client_po':'Search for Purchase order(s) by Client PO#...',
  'client_po_number_caption':'Client-provided PO# <br></br> (if available)',
  'client_po_subtitle':'(if available)',
  "closed_po_driver_reference_number_title": "<br></br> Your reference#",
  "existing_po_voided_link":"User voided POs exist for this asset. As a result, this purchase order will be subjected to further review. {click}",
  "existing_po_voided_error_selection_continue":"Click here to continue",
  "existing_po_voided_error_selection_po":"PO",

  'recover_password_email_address_sub':'(E-mail as a user ID)',
  'recover_password_vendorId_sub':'(i.e. 123456UA or 987654CC)',
  'recover_password_choice_sub':'What method do you use for logging into Holman PartnerConnect?',
  'recover_password_footer_sub':'Please note: the email address you enter MUST match what is on file with Holman.',
  'coupon_header_services':'Coupon Schedule',
  'pm_sheet_header':'Schedule',
  'complaint_notes_readonly_your_note':'Your Note: ',
  'complaint_notes_readonly_ari_note':'Holman: ',
  'repair_notes_your_note':'Your Note: ',
  'repair_notes_client_note':'Client: ',
  "repair_start_date":"Repair start date",
  'action_required_line_items_title':'PO Request for VIN:',
  
  'find_part_for_unmapped_prod_code' : 'This product code is either incorrect or needs more detail. If correct, please search using the Holman parts search above.',
  'search_product_code_caption': "Find and select product code",
  'search_product_code_placeholder': "Search by product code or description",
  'product_code_search_continue': "Continue",
  'product_code_search_none_found': "No results found",
  
  'add_services_using_product_code_caption_note': "Please add the following line(s) to the purchase order using appropriate product codes.",    


'contract_pricing_cannot_be_edited_displayed_message': "This product code has contract pricing. Price cannot be edited or displayed.",
  'contract_pricing_cannot_be_edited_message' : "This product code has contract pricing. Price cannot be edited.",
  
  'repair_labor_qty': "Qty",
  'repair_labor_cost': "Lab Cost",

  'adjust_labor_cost': "Lab Cost",
  'adjust_labor_qty': "Qty",
  
  'replace_labor_cost': "Lab Cost",
  'replace_labor_qty': "Qty",
    
  'price_exceeds_error_message': "The highlighted field below has a cost that is too high. Please verify the selected part and correction type, edit the cost, then click confirm. If the cost is correct, please click confirm. The 'Add line item' button will be re-enabled once you confirm your cost.",
  'price_exceeds_continue_error_message':"The highlighted field below has a cost that is too high. Please verify the selected part and correction type, edit the cost, then click confirm. If the cost is correct, please click confirm. The 'Continue' button will be re-enabled once you confirm your cost.",
  'service_exceeds_error_message': 'The highlighted field(s) below have a cost that is too high. Please edit the cost, then click confirm. If the cost is correct, please click confirm. The save buttons will be re-enabled once you confirm your cost.',
  'services_save_disabled_exceeds_tooltip_message': 'Services due cannot be added until you confirm your prices on the highlighted lines.',
  'save_disabled_exceeds_tooltip_message': 'Line cannot be added until you confirm your prices on the highlighted lines.',
  'save_disabled_exceeds_continue_tooltip_message': "Continue button will be re-enabled once you confirm prices on highlighted lines.",
  'validation_link_message': "Confirm {description} Cost",
  'price_exceeds_confirmation': "Confirm",


  'add_product_code_instruction': "These lines have been added by a Holman technician, but do not have product codes. Please follow the steps to add product code.",
  'missing_product_code_icon_title': 'Click to add missing Product Code',

  'delete_associated_line_confirmation_message': "Any associated Parts or Labor line will also be deleted. Are you sure you want to delete this line?",
  'missing_product_code_step_continue': "Continue",
  'missing_product_code_step_return': "Delete line",
  'missing_product_code_information_1': "Note from Holman: The line item for <line_description></line_description> was added without a product code. Please add the correct product code and pricing, if applicable.",
  'missing_product_code_information_2': "Click Continue to provide missing product code information.",
  'missing_product_code_information_3': "Click Delete line to delete this line item from the Purchase Order.",
  'missing_product_code':' for <line_description></line_description>',
  'adjusted_line_window_tooltip':'This product code has contract pricing. Price cannot be edited or displayed.',
  'missing_product_code_disabled_reason':'There are lines missing product codes. Please add the correct product codes and pricing, if applicable.',
  'advanced_search_link':'SEARCH PURCHASE ORDERS',
  'advanced_search_link_2':'Search orders',
  'adv_search_ata': 'Search for Parts',

  'forward_for_further_review_caption': 'Forward for further review',
  'forward_for_further_review_text0': 'Please enter a note in the box below explaining why you would like the client to review this PO.',
  'forward_for_further_review_text1': 'Please provide your response below',
  'forward_for_further_review_submit_note': 'Submit note',

  'reject_system_adjustment': 'Initial negotiation rejected by supplier. Please review parts pricing and labor time(s) accordingly.',

  'repair_notes_caption': 'Repair notes',

  //Fleet Admin
  'fleet_admin_fallback_ari_contact_info': 'If you do not accept these changes, you may forward this PO for further review \nor call Holman technician.',
  'fleet_admin_fallback_contact_info': 'If you do not accept these changes, you may forward this PO for further review \nor call Holman technician.',
  'fleet_admin_contact_info': 'If you do not accept these changes, you may forward this PO for further review \nor call {fleetAdminName} at {fleetAdminPhone}.',
  'fleet_admin_contact_info_issue_note': 'If you any questions regarding this client/notes request, you may forward \nthis PO for further review or call {fleetAdminName} at {fleetAdminPhone}.',

  'dealer_draft_reg_no_selection_label':'None of the above',
  'dealer_draft_reg_label':'Registration information',
  'dealer_draft_temp_license_label':'Temp license plate #',
  'dealer_draft_temp_exp_label':'Temp registration expiration',
  'dealer_draft_temp_state_label':'Temp registration state',
  'dealer_draft_initial_license_label':'Initial licensing issued',
  'dealer_draft_temp_label':'Temp Tag issued',
  'dealer_draft_reg_details_label':'Registration details',
  'dealer_draft_reg_details_error_label':'Please select one of the fields below',
  'dealer_draft_reg_details_select_label':'Select one',
  'dealer_draft_no_selection_error_label':'Our records indicate that your dealership is to complete initial licensing. Before proceeding, please contact us at delivery@holman.com or 800-257-7781 for further assistance.',
  'dealer_draft_delivery_date_error_label':'Please select a date',
  
  //Aged POs
  'no_aged_pos_dialog':'None at this time',
  'aged_po_title':'Your attention needed',
  'aged_po_messaging':'Your shop has open purchase orders that are more than 30 days old. Please complete and close these purchase orders at your earliest convenience. Holman will not process or pay POs that are greater than 120 days old.',
  'shopview_link':'Go to Shop View',
  'created_date_column':'Created date',
  'vin_column':'VIN',
  'po_column':'PO no.',
  'ref_column':'Your Ref #',
  'amount_column':'Amount',
  'edit_link':'EDIT',
  'close_link':'CLOSE',
  'aged_po_date':'days ago',
  'aged_po_link':'View aged purchase orders',
  'aged_po_link2':'View aged orders',
  'holman_vendor':'HOLMAN VENDOR',

  'repair_type_change_step_continue':'Continue',
  'repair_type_change_step_delete_line':'Delete line',
  'repair_type_change_step_header':'Confirm job details for this line',
  'repair_type_change_step_info_1':'Note from Holman: The line item for <line_description></line_description> was added by a Holman technician but the estimate needs to be updated.',
  'repair_type_change_step_info_2':'Click Continue to update the estimate.',
  'repair_type_change_step_info_3':'Click Delete line to delete this item from the Purchase Order',

  'ie_warning_title':"Browser security warning",
  'ie_warning_message_line1':"Effective October 31, 2023, Holman PartnerConnect will no longer be compatible with Microsoft Internet Explorer (IE) because it is no longer being supported by Microsoft. This means IE no longer receives security updates, making it unsafe to use for Holman, our customers, or partners.",
  'ie_warning_message_line2':"If you want to learn more, please see {articleLink} from Microsoft: ",
  'ie_warning_message_line3':"Common questions:",
  'ie_warning_message_question1':"What should I do next?",
  'ie_warning_message_question2':"What if I don't know my username and password because it's saved in my browser?",
  'ie_warning_message_question3':"What if I can't download a new browser?",
  'ie_warning_message_answer1':"You should download a modern browser such as Microsoft Edge, Google Chrome, or Mozilla Firefox.",
  'ie_warning_message_answer2':"First download a new browser.",
  'ie_warning_message_answer3':"Then you can {resetpasswordLink}.",
  'ie_warning_message_answer4':"Contact your IT Team.",
  'ie_warning_message_answer5':"If you don't have an IT person in your company, you can reach out to Holman IT at {mailAddress}.",
  'ie_warning_article_text':"this article",
  'ie_warning_reset_password_text':"reset your password",
  'ie_warning_learn_more_button':"Learn More",
  'ie_warning_signout_button':"Sign out and use a different browser",

  //Labor rate update
  'labor_rate_update_title':"Labor Rate Update",
  'labor_rate_update_question_line1':"You have changed labor rate from ${currentRate} to ${newRate}.  Is this your new standard labor rate?",
  'labor_rate_update_question_line2':"Is this your new standard labor rate?",
  'labor_rate_update_yes':"Yes, request new rate",
  'labor_rate_update_no':"No",
  'labor_rate_update_error':"Please select One",

  'tire_width_required':"Please enter Tire width",
  'aspect_ratio_required':"Please enter Aspect ratio",
  'rim_diameter_required':"Please enter Rim diameter",
  'tire_manufacturer_required':"Please select Tire manufacturer",
  'tire_model_required':"Please enter Tire model",
  'tire_reason_required':"Please select a reason for replacement",
  'old_tire_tread_depth_required':"Please enter the old tire tread depth for the replaced tire.",
  'qty_must_match_with_tire_selection_counter_custom_message':"Number of tires selected for replacement does not match the quantity defined in the line item. Please select ",
  'qty_must_match_end_message':" tire(s).",

  //Accident Management
  'wheel_required': "Required",
  'images_and_estimates_requested': 'Images and estimates requested',
  'repair_approved_eta_pending': 'Repair approved, repair ETA needed',
  'repair_approved': 'Repair approved',
  'payment_submitted': 'Payment submitted',
  'pending_repair_approval': 'Pending approval for {submission}',
  'original_estimate': 'original estimate',
  'supplement': 'supplement {submissionNumber}',
  'show_all_waiting_claim': 'Show all {claimCount} claims',
  'hide_older_claim': 'Hide older claims',
  'accident_assignments_your_action_title': 'Your action required',
  'accident_assignments_approved_title': 'Approved',
  'accident_assignments_pending_approval_title': 'Pending approval',
  'accident_assignments_payment_in_progress_title': 'Payment in progress',
  'accident_assignments_vehicle_header': 'Vehicle',
  'accident_assignments_vin_header': 'VIN',
  'accident_assignments_claim_header': 'Claim #',
  'accident_assignments_status_header': 'Status',
  'accident_assignments_view_caption': 'VIEW',
  'accident_assignments_no_claim': 'None at this time',
  'repair_estimate_link': 'View all open Accident POs',
  "accident_assignments_eta_title": "Update repair ETA",
  "accident_assignments_body_1": "Enter the estimated completion date for all repairs",
  "update_eta_link": "Update repair ETA",
  "accident_assignments_eta_note_body": "Please enter a reason for the change in repair ETA",
  "accident_assignments_eta_date_error": "Please enter a valid estimated completion date",
  "accident_assignments_eta_note_error": "Please enter a reason for the change in repair ETA",
  "accident_assignments_submission_continue_validate": "Please upload {fileType} before continuing",
  "accident_assignments_submission_submit_validate": "Please enter pricing before submitting for approval",
  "accident_assignments_submission_images_drag_drop": "Drag and drop your images here or click the icon to upload one or more",
  "accident_assignments_submission_document_drag_drop": "Drag and drop your repair estimate here or click the icon to upload one",
  'submit_for_approval_link': 'Submit for approval',
  'submit_additional_images': 'Submit additional images',
  'return_link': 'Return',
  'browse_file': 'Browse file',
  'files_uploaded': '{fileCount} file(s) uploaded',
  'accident_assignments_submission_rejected_image': 'Some file(s) could not be uploaded because of incorrect file type. Acceptable image types include: JPG PNG JPEG JPE BMP GIF JFIF',
  'accident_assignments_submission_rejected_document': 'Some file(s) could not be uploaded because of incorrect file type. Acceptable document types include: PDF',
  'accident_assignments_submission_rejected_max_files': 'Upload exceeds maximum file count: {fileCount} file(s)',
  "accident_assignments_submission_images_title": "Upload images",
  "accident_assignments_submission_estimate_title": "Upload repair estimate copy",
  "accident_assignments_submission_pricing_title": "Repair pricing",
  "accident_assignments_submission_additional_images_title": "Upload additional images",
  "accident_assignments_submission_images_body": "Submit images of the four corners of the vehicle, odometer, plate number, VIN, and any areas of damage. These images will be used by the AccMan team to review the repair estimate, and will be accessible to the client and their insurance company.",
  "accident_assignments_submission_estimate_body": "To expedite the review of this accident assignment, please be sure to upload the images and repair estimate, input your pricing. All three items are needed to submit your repair estimate to Holman's Accident Management team. \n\n You can add additional images here throughout the repair process if needed.",
  "accident_assignments_submission_pricing_body": "Lastly, enter the cost of the labor and parts in the lines below.",
  "accident_assignments_submission_additional_images": "Click below to select additional images to add to the original estimate.",
  "accident_assignments_pricing_description": "Description",
  "accident_assignments_pricing_cost": "Cost",
  "accident_assignments_pricing_subtotal": "SUBTOTAL",
  "accident_assignments_view__claim_header": "Claim {claimId}",
  "accident_assignments_history_details": "Show history details",
  "accident_assignments_decline_assignment": "Decline accident assignment",
  "accident_assignments_initial_submission_header": "Submit initial images, estimate and pricing",
  "accident_assignments_initial_submission_subheader_1": "To expedite the review of this accident assignment, please be sure to upload images, copy of the repair estimate, and input your pricing. All three items are needed to submit your repair estimate to Holman's Accident Management team.",
  "accident_assignments_initial_submission_subheader_2": "After the initial repair estimate is approved, you can upload any additional photos here.",
  "accident_assignments_submit_initial_estimate": "Submit initial estimate",
  "accident_assignments_additional_images_header": "Submit additional images",
  "accident_assignments_submit_additional_images": "Submit images",
  "accident_assignments_additional_images_subheader": "Upload images here",
  "accident_assignments_history_header": "History",
  "accident_assignments_eta_header": "Update repair ETA",
  "accident_assignments_eta_subheader": "Please enter the estimated completion date for these repairs.",
  "accident_assignments_supplement_header": "Submit supplemental repair estimate",
  "accident_assignments_supplement_subheader": "Upload your supplemental repair estimate here",
  "accident_assignments_supplement_button": "Submit supplemental repair",
  "accident_assignments_history_subheader": "Estimates/Supplements",
  "accident_assignments_driver_contact_title": "Driver Contact",
  "accident_assignments_driver_contact_caption": "Contact",
  "accident_assignments_driver_phone_caption": "Phone",
  "accident_assignments_driver_email_caption": "Email",
  "accident_assignments_pending_estimate_title": "Pending images and estimate",
  "accident_assignments_view__pending_approval_title": "Pending Holman approval for {submissionNumber} estimate",
  "accident_assignments_pending_eta_title": "Repair approved - Please provide repair ETA",
  "accident_assignments_approved_title": "Repair approved",
  "accident_assignments_payment_in_progress_title": "Payment in progress",
  "accident_assignments_payment_submitted_title": "Payment submitted",
  "accident_assignments_work_completed_title": "Work completed",
  "accident_assignments_vehicle_vin_caption": "VIN",
  "accident_assignments_vehicle_client_caption": "Client",
  "accident_assignments_vehicle_unit_number_caption": "Holman unit no.",
  "accident_assignments_vehicle_engine_caption": "Engine type",
  "accident_assignments_vehicle_vehicle_caption": "Vehicle",
  "accident_assignments_vehicle_plate_caption": "Plate",
  "accident_assignments_vehicle_color_caption": "Color",
  "accident_assignments_vehicle_mileage_caption": "Mileage",
  "accident_assignments_vehicle_other_caption": "Other",
  'vehicle_documents': 'Vehicle Documents',
  'repair_history_of_vehicle': 'Repair History of Vehicle',
  "accident_assignments_unavailable_caption": "Unavailable",
  "accident_assignments_image_upload_caption": "Uploaded on {uploadDate}",
  "accident_assignments_image_title": "Images",
  "accident_assignments_images_submission": "Collected for {submissionNumber}",
  "accident_assignments_pricing_header": "Pricing",
  "accident_assignments_pricing_description": "Description",
  "accident_assignments_pricing_qty": "Qty",
  "accident_assignments_pricing_cost": "Cost",
  "accident_assignments_pricing_subtotal": "SUBTOTAL",
  "accident_assignments_pricing_damage_parts": "VEHICLE DAMAGE PARTS",
  "accident_assignments_pricing_damage_labor": "VEHICLE DAMAGE LABOR",
  "accident_assignments_pricing_paint_parts": "PAINT SUPPLIES PARTS",
  "accident_assignments_pricing_paint_labor": "PAINT LABOR",
  "accident_assignments_pricing_mechanical": "MECHANICAL LABOR",
  "accident_assignments_pricing_other": "OTHER",
  "accident_assignments_pricing_supplement_damage_parts": "SUPPLEMENT {submissionNumber} - VEHICLE DAMAGE PARTS",
  "accident_assignments_pricing_supplement_damage_labor": "SUPPLEMENT {submissionNumber} - VEHICLE DAMAGE LABOR",
  "accident_assignments_pricing_supplement_paint_parts": "SUPPLEMENT {submissionNumber} - PAINT SUPPLIES PARTS",
  "accident_assignments_pricing_supplement_paint_labor": "SUPPLEMENT {submissionNumber} - PAINT LABOR",
  "accident_assignments_pricing_supplement_mechanical": "SUPPLEMENT {submissionNumber} - MECHANICAL LABOR",
  "accident_assignments_pricing_supplement_other": "SUPPLEMENT {submissionNumber} - OTHER",
  "accident_assignments_close_header": "Close PO",
  "accident_assignments_close_subheader": "Once all repairs are complete, including supplemental repairs, you can close the repair",
  "accident_assignments_eta_note_length_error": "Please enter a reason between 3-900 characters",
  "claim_no_label": "Accident Claim",
  'search_for_claim_no': 'Search for Accident Estimate by Claim #...',
  'search_claim_error': 'Invalid Claim Number #{claimId}. Please reconfirm and try again.',
  "accident_assignments_submission_supplement_title": "Upload supplemental copy",
  "accident_assignments_submission_supplement_pricing_body": "Submit supplemental pricing in the line below",
  "accident_assignments_decline_claim_confirmation_title": "Decline Assignment",
  'accident_assignments_decline_claim_confirmation_message': "You are about to decline this accident repair assignment and remove it from Holman PartnerConnect. Are you sure you want to decline this assignment?",
  "accident_assignments_declined_title": "Assignment Declined",
  'accident_assignments_close_po_disabled_message': 'Please update repair ETA before closing PO',
  "accident_assignments_locked_po_message": "PO is currently being reviewed by a Holman Technician.",
  "accident_assignments_locked_po_2_message":"PO is currently being reviewed by another Holman Technician.",
  "accident_po_link": "View accident orders",
}
