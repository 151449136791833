import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import {Map, fromJS} from 'immutable';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';

export function uiDataAddEditView(state = initialAppState.getIn(['uiData', 'addEdit']), action) {
    switch (action.type) {
        case constants.REQUEST_APPROVAL_REQUESTED:
            return state.set('isRequestingApproval', true)
                        .set('RequestApprovalDisable',true)
                        .set('errorMessage',[])
                        .set('isDirty', false);
        case constants.REQUEST_APPROVAL_RESOLVED:
            return state.set('isRequestingApproval', false)
                        .set('RequestApprovalDisable',false)
                        .set('errorMessage',[]);        
        case constants.REQUEST_APPROVAL_REJECTED:
            return state.set('isRequestingApproval', false)
                .set('RequestApprovalDisable',false)
                        .set('errorMessage', action.errorMessage);
        case constants.CANCEL_PO_REJECTED:
            return state.set('isRequestingApproval', false)
                .set('RequestApprovalDisable',false)
                .set('errorMessage', action.errorMessage);
        case constants.CANCEL_PO_RESOLVED:
            return state.set('isRequestingApproval', false)
                .set('RequestApprovalDisable',false)
                .set('errorMessage',[]);
        case constants.UPDATE_LINE_ITEM_REQUESTED:
            return state.setIn(['lineItems', action.lineItemId, 'isBeingUpdated'], true); 
        case constants.UPDATE_LINE_ITEM_REJECTED:
        case constants.UPDATE_LINE_ITEM_RESOLVED:
            return state.setIn(['lineItems', action.lineItemId, 'isBeingUpdated'], false); 
        case constants.DELETE_LINE_ITEM_REQUESTED:
            return state.setIn(['lineItems', action.lineItemId, 'isBeingDeleted'], true); 
        case constants.DELETE_LINE_ITEM_REJECTED:
        case constants.DELETE_LINE_ITEM_RESOLVED:
            return state.setIn(['lineItems', action.lineItemId, 'isBeingDeleted'], false);     
        case constants.CREATE_PO_REQUESTED:
            return state.set('isCreatingPO', true)
            .set('errorMessage',[]);    
        case constants.CREATE_PO_RESOLVED:            
            return state.set('isCreatingPO', false).setIn(['purchaseOrder','id'],action.poNumber)
        case constants.CREATE_PO_REJECTED:
            return state.set('isCreatingPO', false); 
        case constants.FETCH_PO_DATA_REQUESTED:
             if (action.mode == 'add' || action.mode == 'edit')
                return state.set('isFetchingData', true);
        case constants.FETCH_PO_DATA_REJECTED:
                return state.set('isFetchingData', false);
        case constants.FETCH_PO_DATA_RESOLVED:
            if (action.mode == 'add' || action.mode == 'edit')
                return state.set('purchaseOrder', fromJS(action.purchaseOrderData.purchaseOrder))
                    .set('client', fromJS(action.purchaseOrderData.client))
                    .set('vendor', fromJS(action.purchaseOrderData.vendor))
                    .set('complaints', fromJS(action.purchaseOrderData.complaints))
                    .set('notesHistory', fromJS(action.purchaseOrderData.notes))
                    .set('lineItems', fromJS(action.purchaseOrderData.lineItems))
                    .set('isDirty',false)
                    .set('isFetchingData', false)
                    .set('errorMessage',[]);
            else if(action.mode=='view')
            return state.set('errorMessage',[]);
            else {
                return state;
            }
        case constants.SAVE_LINE_ITEM:
            //Hack for setIn function weird behavior where it swallows the exception if 
            //one of the nested keys doesnt exists
            if(state.get('lineItems') == undefined && state.getIn(['lineItems', action.lineItem.lineItemId]) == undefined ) 
            {
                const item = Map();
                return state.set('lineItems', item.set(action.lineItem.lineItemId, fromJS(action.lineItem))).set('isDirty', true);
            }
            else     
                return state.setIn(['lineItems', action.lineItem.lineItemId],fromJS(action.lineItem)).set('isDirty', true);
        
        case constants.DELETE_LINE_ITEM:
            return state.deleteIn(['lineItems', action.lineItemId]).set('isDirty', true);
        
        case constants.SAVE_SERVICE_DESCRIPTION:
            return state.setIn(['complaints', action.complaintCode, 'comment'], action.comments);

        case constants.SAVE_ESTIMATED_DATE:
            return state.setIn(['purchaseOrder', 'estimatedCompletionDate'], action.newEstimatedDate);

        case constants.EDIT_LINE_ITEM:
            return state.setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], true);

         case constants.UPDATE_LINE_ITEM:
            return state.setIn(['lineItems', action.lineItemId, 'quantity'], action.quantity)
                .setIn(['lineItems', action.lineItemId, 'unitPrice'], action.unitPrice)
                .setIn(['lineItems', action.lineItemId, 'totalAmount'], action.quantity * action.unitPrice)
                .setIn(['lineItems', action.lineItemId, 'isBeingEdited'], false)
                .set('isDirty', true);

        case constants.CANCEL_UPDATE_LINE_ITEM:
            return state.setIn(['lineItems', action.lineItemKey, 'isBeingEdited'], false);    

        case constants.DELETE_ALL_LINE_ITEMS:
            return state.deleteIn(['lineItems']);

        case constants.CLEAR_PURCHASE_ORDER_DETAILS:
            return state.deleteIn(['purchaseOrder']);

        /* complaints */
        case constants.ADD_EDIT_VIEW_ADD_COMPLAINT:
            let existingComplaints = emptyMapIfUndefined(state.get('complaints'));
            let newComplaints = existingComplaints.concat(action.newComplaint);
            return state.set('complaints', newComplaints).set('isDirty', true);
        case constants.ADD_EDIT_VIEW_DELETE_COMPLAINT:
            return state.deleteIn(['complaints', action.complaintKey]);
        case constants.ADD_EDIT_VIEW_DELETE_COMPLAINTS:
            return state.delete('complaints');
        case constants.RESET_COMPLAINTS:
                let newComplaintObj = {};
                let complaintKey = 'complaint1';
                newComplaintObj[complaintKey] = Map({
                    index: 1,
                    title: "Complaint 1",
                    code: undefined,
                    description: undefined,
                    isSelectMode: true,
                    wasComplaintSkipped: false,
                    isComplaintOpen: true});
                let newComplaint = new Map(newComplaintObj);
            return state.set('complaints', Map(newComplaint));
        case constants.UPDATE_COMPLAINT_CODE:
            return state.setIn(['complaints', action.complaintKey, 'code'], action.code)
                        .setIn(['complaints', action.complaintKey, 'description'], action.description);    
        case constants.CREATE_OR_EDIT_COMPLAINT_REQUESTED:
            return state.set('creatingOrEditingComplaintForComplaintKey', action.complaintKey);
        case constants.CREATE_OR_EDIT_COMPLAINT_REJECTED:    
            return state.set('creatingOrEditingComplaintForComplaintKey', undefined);
        case constants.CREATE_OR_EDIT_COMPLAINT_RESOLVED:
            return state.setIn(['complaints', action.complaintKey, 'code'], action.code)
                .setIn(['complaints', action.complaintKey, 'description'], action.description)
                .setIn(['complaints', action.complaintKey, 'wasComplaintSkipped'], false)
                .setIn(['complaints', action.complaintKey, 'isComplaintOpen'], false)
                .set('creatingOrEditingComplaintForComplaintKey', undefined);
        case constants.SKIP_COMPLAINT_REQUESTED:
            return state.set('skippingComplaintForComplaintKey', action.complaintKey);
        case constants.SKIP_COMPLAINT_REJECTED:
             return state.set('skippingComplaintForComplaintKey', undefined);
        case constants.SKIP_COMPLAINT_RESOLVED:
            return state.setIn(['complaints', action.complaintKey, 'wasComplaintSkipped'], true)
                .setIn(['complaints', action.complaintKey, 'isComplaintOpen'], false)
                .set('skippingComplaintForComplaintKey', undefined);
        case constants.TOGGLE_COMPLAINT_SELECT_MODE:
            return state.setIn(['complaints', action.complaintKey, 'isSelectMode'],
                  !state.getIn(['complaints', action.complaintKey, 'isSelectMode']));
        case constants.CLEAR_COMPLAINT_NOTES:
            return state.remove('notes');
        case constants.UPDATE_COMPLAINT_NOTES:
            let newState = state.setIn(['notes', action.complaintKey, 'noteText'], action.notes);            
            if (action.noteId != undefined)
                newState = newState.setIn(['notes', action.complaintKey, 'id'], action.noteId)
            return newState;
        case constants.SAVE_COMPLAINT_NOTES_REQUESTED:
            return state.setIn(['notes', action.complaintKey, 'savingNote'], true)
                        .set('errorMessage', []);
        case constants.SAVE_COMPLAINT_NOTES_RESOLVED:
            return state.setIn(['notes', action.complaintKey, 'savingNote'], false);
        case constants.SAVE_COMPLAINT_NOTES_REJECTED:
            return state.setIn(['notes', action.complaintNotesData.complaintKey, 'savingNote'], false)
                        .set('errorMessage', action.complaintNotesData.errorMessage); 
        //save estimated completion date errors to isRequestingApproval node because its a part of Request approval UI                           
        case constants.ESTIMATED_COMPLETION_DATE_CHANGE_REQUESTED:
            return state.set('errorMessage',[]);        
        case constants.ESTIMATED_COMPLETION_DATE_CHANGE_REJECTED:                        
            return state.set('errorMessage', action.errorMessage);;                     
        case constants.ESTIMATED_COMPLETION_DATE_CHANGE_RESOLVED:
            return state.set('errorMessage',[]);        
        case constants.UPDATE_IS_ESTIMATED_COMPLETION_DATE_VALID:
            return state.setIn(['purchaseOrder', 'isEstimateDateValid'], action.isEstimateDateValid)
        case constants.SAVE_LATEST_LINE_ITEM_ID:
            return state.setIn(['purchaseOrder', 'latestLineItemId'], action.lineItemId)
        case constants.CLEAR_LINE_ITEM_ID_FOR_TIRES:
            return state.setIn(['purchaseOrder', 'latestLineItemId'], null)   
                    .setIn(['purchaseOrder', 'lineItemForTires'], null)
                    .setIn(['purchaseOrder', 'lineItemQty'], null)
                    .setIn(['purchaseOrder', 'lineItemUnitPrice'], null)
                    .setIn(['purchaseOrder', 'lineItemCorrectionType'], null)                    
        case constants.SAVE_LINE_ITEM_FOR_TIRES:
            return state.setIn(['purchaseOrder', 'lineItemForTires'], action.lineItem.lineItemId)                                       
                        .setIn(['purchaseOrder', 'lineItemQty'], action.lineItem.lineItemQty)  
                        .setIn(['purchaseOrder', 'lineItemUnitPrice'], action.lineItem.unitPrice)  
                        .setIn(['purchaseOrder', 'lineItemCorrectionType'], action.lineItem.correctionType)  
                                                             
        default:
            return state;
    }
}

export function serverDataAddEditView(state = initialAppState.getIn(['serverData', 'view']), action) {
    switch (action.type) {
        case constants.FETCH_PO_DATA_REQUESTED:
             if (action.mode == 'view')
                return state.set('isFetchingData', true)
                            .set('errorMessage',[]);
             else 
                return state.set('errorMessage',[]);   
                                               
        case constants.FETCH_PO_DATA_REJECTED:
                return state.set('isFetchingData', false)
                            .set('errorMessage',action.errorMessage);
        case constants.FETCH_PO_DATA_RESOLVED:
            if(action.mode == 'view')
                return state.set('purchaseOrder', fromJS(action.purchaseOrderData.purchaseOrder))
                    .set('client', fromJS(action.purchaseOrderData.client))
                    .set('vendor', fromJS(action.purchaseOrderData.vendor))
                    .set('complaints', fromJS(action.purchaseOrderData.complaints))
                    .set('notesHistory', fromJS(action.purchaseOrderData.notes))
                    .set('lineItems', fromJS(action.purchaseOrderData.lineItems))
                    .set('isDirty', false)
                    .set('isFetchingData', false)
                    .set('errorMessage',[])
                    .set('paymentInfo', fromJS(action.purchaseOrderData.paymentInfo));
            else
                return state;
        case constants.FETCH_DATA_FOR_DUE_SERVICES_REQUESTED:
                return state.set('isFetchingData', true);
        case constants.FETCH_DATA_FOR_DUE_SERVICES_RESOLVED:
                return state.setIn(['maintenanceServices'], fromJS(action.maintenanceServices))
                .setIn(['regulatoryServices'], fromJS(action.regulatoryServices))
                .setIn(['ataCodes'], fromJS(action.ataCodes))
                .setIn('isFetchingData', false);
        case constants.FETCH_DATA_FOR_DUE_SERVICES_REJECTED:    
                return state.set('isFetchingData', false);
        case constants.CLEAR_ADD_EDIT_VIEW_SERVICES:
                return state.setIn(['maintenanceServices'], Map())
                            .setIn(['regulatoryServices'], Map());
        case constants.CLEAR_PURCHASE_ORDER:
                return state.set('purchaseOrder', Map())
                            .set('client', Map())
                            .set('vendor', Map())
                            .set('complaints', Map())
                            .set('notesHistory', Map())
                            .set('lineItems', Map())
                            .set('isDirty',false)
                            .set('isFetchingData', false)
        default:
            return state;
    }
}