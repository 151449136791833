import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import LineItems from './../../screens/PO/AddEditView/components/LineItems';
import Comments from './Comments';
import './Services.css';
import ComplaintNotes from './ComplaintNotes.js';
import ComplaintNotesReadOnly from './ComplaintNotesReadOnly.js';

class Services extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const messages = defineMessages({
            preventive_maintenance_caption: {
                id: 'preventive_maintenance_caption',
                description: 'Maintenance',
                defaultMessage: 'Maintenance'
            }
        });

        return <div>
            <div className={classNames('services_view',
                {'services_view_edit': this.props.actionType == 'ADD'})}>
                <div className="row services_view__caption">
                    <div className="col-md-12">
                        <FormattedMessage {...messages.preventive_maintenance_caption} />
                    </div>
                </div>

                {this.props.actionType == 'EDIT' ?
                    <div> <ComplaintNotesReadOnly notes={this.props.notesTextHistory}/>
                        <ComplaintNotes
                            complaintKey={this.props.complaintKey}
                            onComplaintNotesSave={this.props.onComplaintNotesSave}
                            onComplaintNotesSaveState={this.props.onComplaintNotesSaveState}
                            listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
                        />

                    </div>
                    :this.props.actionType == 'ADD'?
                        <ComplaintNotes notes={this.props.notesText}
                                        complaintKey={this.props.complaintKey}
                                        onComplaintNotesSave={this.props.onComplaintNotesSave}
                                        onComplaintNotesSaveState={this.props.onComplaintNotesSaveState}
                                        listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
                        />
                        :this.props.actionType == 'VIEW' ?
                            <ComplaintNotesReadOnly notes={this.props.notesTextHistory}/>
                            :
                            <ComplaintNotes
                                complaintKey={this.props.complaintKey}
                                onComplaintNotesSave={this.props.onComplaintNotesSave}
                                onComplaintNotesSaveState={this.props.onComplaintNotesSaveState}
                                listOfComplaintsForWhichNoteIsRequired={this.props.listOfComplaintsForWhichNoteIsRequired}
                            />
                }

                <LineItems lineItems={this.props.lineItems}
                           actionType={this.props.actionType}
                           complaintCode="MA99"
                           isServiceComplaint={true}
                           onAddLineItemsClick={this.props.onAddLineItemsClick}
                           onAddLineItemsMaintenanceClick={this.props.onAddLineItemsMaintenanceClick}
                           onMissingProductCodeIconClick={this.props.onMissingProductCodeIconClick}
                           onDeleteLineItemClick={this.props.onDeleteLineItemClick}
                           onEditLineItemClick={this.props.onEditLineItemClick}
                           onChangeRepairTypeClick={this.props.onChangeRepairTypeClick}
                           onUpdateLineItemClick={this.props.onUpdateLineItemClick}
                           onCancelUpdateLineItemClick={this.props.onCancelUpdateLineItemClick}
                           hideCostInformation={this.props.hideCostInformation}
                           hideAddLineItemsForServices={this.props.hideAddLineItemsForServices}
                           anyRemainingAtaCodesToBeAdded={this.props.anyRemainingAtaCodesToBeAdded}
                           lineItemIdBeingEdited={this.props.lineItemIdBeingEdited}
                           onOpenTiresInfo = {this.props.onOpenTiresInfo}
                           selectedCurrency={this.props.selectedCurrency}
                />
            </div>
            {/*<hr className="service_view__hr"/>*/}
        </div>;
    }
}



export default Services;