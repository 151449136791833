import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import './DotInspectionFormFailOptions.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';

class DotInspectionFormFailOptions extends React.Component {	
	handleYesClick = () => {
		this.props.onYesClick();
	}

	handleNoClick = () => {
		this.props.onNoClick();
	}


	render() {
		const messages = defineMessages({			
			dot_inspection_form__fail_confirm_title: {
                id: 'dot_inspection_form__fail_confirm_title',
                description: 'DOT Inspection - fail confirmation title',
                defaultMessage: 'DOT annual vehicle inspection'
            }, 
			dot_inspection_form__fail_confirm_question: {
                id: 'dot_inspection_form__fail_confirm_question',
                description: 'DOT Inspection - fail confirmation question',
                defaultMessage: 'Are you going to repair the failed vehicle components on this repair invoice?'
			},
			dot_inspection_form__fail_confirm_yes: {
                id: 'dot_inspection_form__fail_confirm_yes',
                description: 'DOT Inspection form - fail yes button text',
                defaultMessage: 'Yes and edit PO'
            },
            dot_inspection_form__fail_confirm_no: {
                id: 'dot_inspection_form__fail_confirm_no',
                description: 'DOT Inspection form - fail No button text',
                defaultMessage: 'No and continue'
			},						
		});

		return (
			<div className='dot_inspection_form_fail_options'>
				
				<div className='dot_inspection_form_fail_options__title'>
					<FormattedMessage {...messages.dot_inspection_form__fail_confirm_title} />
				</div>

				<div className='dot_inspection_form_fail_options__question'>
					<FormattedMessage {...messages.dot_inspection_form__fail_confirm_question} />
				</div>

				<div className='row'>
					<div 
						className='col-sm-6 dot_inspection_form_fail_options__answer' 
						onClick={this.handleYesClick}
					>
						<img src="/assets/VMS_28button_kill_clear.png" />{' '}
						<FormattedMessage {...messages.dot_inspection_form__fail_confirm_yes} />
					</div>
					<div 
						className='col-sm-6 dot_inspection_form_fail_options__answer text-right' 
						onClick={this.handleNoClick}
					>
						<FormattedMessage {...messages.dot_inspection_form__fail_confirm_no} />
						{' '}<img src="/assets/VMS_33button_go_bblue.png" />
						<div className='dot_inspection_form__spinner'>
							{this.props.isSaving &&
								<Spinner spinnerType='blue' />										
							}
						</div>
					</div>
				</div>

			</div>
		);
	}
}

export default DotInspectionFormFailOptions;