import React from 'react';
import './ComplaintNotes.css';
import Spinner from 'components/Spinner';
import { defineMessages, FormattedMessage,injectIntl } from 'react-intl';
import classNames from 'classnames';

class ComplaintNotes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isEditNotes: false,
            focusNotes: false
        }
    }

    componentDidMount(){

        this.onComplaintNotesSaveHandlerdebounce = _.debounce(this.onNotesSaveStateHandler,1000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isEditNotes && this.state.focusNotes) {
            this.refs.refComplaintNotes.focus();
            this.setState({
                focusNotes: false
            });
        }
    }

    onNotesSaveHandler = (event) => {
        event.preventDefault();

        /*Save the notes to the app state by complaint key*/

        this.props.onComplaintNotesSave(this.props.complaintKey, this.refs.refComplaintNotes.value);
    }

    onNotesSaveStateHandler = (event) => {
       //event.preventDefault();

        /*Save the notes to the app state by complaint key*/

        if(this.refs.refComplaintNotes != undefined)
        this.props.onComplaintNotesSaveState(this.props.complaintKey, this.refs.refComplaintNotes.value);
    }

    onNotesSaveStateHandler2 = (event) => {


        this.onComplaintNotesSaveHandlerdebounce(event);
    }

    render() {
        const {formatMessage} = this.props.intl;
        const messages = defineMessages({
            reason_for_repair: {
                id: 'reason_for_repair',
                description: 'reason_for_repair',
                defaultMessage: 'Please enter complaint, cause and correction here.'
            }
        });
        return <div className="complaint-notes-view">
                
            <div className="complaint-notes-view__note_container">
                <textarea id={this.props.complaintKey} className={classNames(
                              'complaint-notes-view__textarea',
                              {'complaint-notes-view__alert':   this.props.listOfComplaintsForWhichNoteIsRequired.includes(this.props.complaintKey)})}
                          ref="refComplaintNotes" key={this.props.complaintKey}
                          defaultValue={this.props.notes}
                          placeholder={formatMessage(messages.reason_for_repair)}
                          onBlur={this.onNotesSaveHandler.bind(this)}
                          onChange={this.onNotesSaveStateHandler2.bind(this)}
                          maxLength="1000" />

                { this.props.savingNote ?
                    <div className="complaint-notes-view__spinner-container">
                        <Spinner spinnerType='blue' />
                    </div>
                : undefined }

            </div>

        </div>
    }

}


export default injectIntl(ComplaintNotes);