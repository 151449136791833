export const partVendors = [ // These part vendors are hard-codes for now.            
            "596220UA",            
            "022701UA",
            "064001UA",
            "552501UA",            
            "593230UA",
            "565617UA",
            "038860UA",
            "067657UA",
            "595755UA",
            "022685UA",            
            "FC076675UA",
            "594926UA",
            "565617UA",
            "089818UA",
            "036660UA",                        
            "028332UA",
            "022683UA",
            "561336UA",
            "551023UA"
        ];