import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import { falseIfUndefined, emptyStringIfUndefined, getTokenExpirationTimeinMinutes } from 'utils/HelperFunctions';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import FormWrapper from 'components/FormWrapper';
import { Popover, Overlay } from 'react-bootstrap';
import './ARIUser.css';
import { jwtDecode } from 'jwt-decode';
import * as helperFunctions from 'utils/HelperFunctions';
import * as configuration from 'configuration';
import * as sharedActions from 'actions/App';
import ARIUserInvalidTokenView from '../components/ARIUserInvalidTokenView';
import SearchVendor from '../components/SearchVendor';
import * as actions from '../actions/ARIUser';
import queryString from 'query-string'

class ARIUser extends React.Component {
    constructor(props) {
        super(props);

        let params =  queryString.parse(this.props.routeParams.location.search);
        let token = params != null && params.token != '' ? params.token : '';
        
        this.state = {
            token: token,
            subject: undefined,
            showErrorMessage: false
        };

        if (this.state.token) {
            try {
                const jwtToken = jwtDecode(this.state.token);
                const subject = jwtToken.sub;
                this.state.subject = subject;
                this.props.dispatch(actions.saveARIUserToken(this.state.token));
            }
            catch (err) {
                this.state.token = '';
            }
        }
    }

    componentDidMount() {
        
    }

    onSearchVendorClickHandler(e) {
        e.preventDefault();
    }

    render() {

        if (!(this.state.subject && this.state.subject === 'ARIUSER') || (helperFunctions.getTokenExpirationTimeinMinutes(this.state.token) <= 0)) {
            return <ARIUserInvalidTokenView />;
        }

        return <div className="row ari-user container-fluid" id="ari-user">
            <div className="col-sm-12">
                <SearchVendor />
            </div>
        </div>;
    }
}

// Which props do we want to inject, given the global state?
function select(state) {
    return {
    };
}

export default connect(select)(injectIntl(ARIUser));