import {createSelector} from 'reselect';
import {Map} from 'immutable';

const emptyMap = Map();
const availableVehicleComplaintsSelector = state => state.appState.getIn(['serverData', 'driverValidationComplaints', 'availableVehicleComplaints']);

export const getAvailableComplaints = 
    createSelector(
        availableVehicleComplaintsSelector,
        (availableVehicleComplaints) => {
            if (!availableVehicleComplaints)
                return emptyMap;                        
            
            const sorted = availableVehicleComplaints.sortBy(a=>(
                a.get('complaintCode')
            ));

            return sorted;
        }
    );