import React, { useEffect, useState } from 'react';
import ReactDoM from 'react-dom'
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, injectIntl, RawIntlProvider } from 'react-intl';
import './CorrectionDetails.css';
import * as helperFunctions from 'utils/HelperFunctions';
import DynamicNumber from 'react-dynamic-number';
import {  OverlayTrigger, Tooltip } from 'react-bootstrap';
import CheckCircle from 'components/CheckCircle';
import styled from 'styled-components';

const CheckCircleStyled = styled(CheckCircle)`
    padding: 0.5rem;
    color: black;
    `;

const CorrectionDetailsReplace = (props) => {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    const renderInputBoxWithNALabel = () => {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_displayed_message: {
                id: 'contract_pricing_cannot_be_edited_displayed_message',
                description: 'This product code has contract pricing. Price cannot be edited or displayed.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited or displayed.'
            },
        });

        return <OverlayTrigger placement='right'
            overlay={<Tooltip><RawIntlProvider value={props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_displayed_message} /></RawIntlProvider></Tooltip>}
        >
            <input
                disabled={true}
                className="correction-type__input-readonly"
                value="N/A"
            />
        </OverlayTrigger>
    }

    const renderLaborRateInputBoxWithConditionalOverlay = (laborRate, showOverlay) => {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_message: {
                id: 'contract_pricing_cannot_be_edited_message',
                description: 'This product code has contract pricing. Price cannot be edited.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited.'
            },
        });

        const laborRateInputBox = <DynamicNumber
            disabled={!props.laborCostEditable}
            className={props.laborCostEditable ? "correction-type__input-medium" : "correction-type__input-readonly"}
            onChange={props.handleChange.bind(this, "RATE")}
            separator={'.'}
            integer={6}
            fraction={2}
            positive={true}
            negative={false}
            thousand={false}
            value={laborRate}
        />;

        if (showOverlay)
            return <OverlayTrigger placement='right'
                overlay={<Tooltip><RawIntlProvider value={props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_message} /></RawIntlProvider></Tooltip>}
            >
                {laborRateInputBox}
            </OverlayTrigger>;
        else
            return laborRateInputBox
    }

    const renderpartsCostInputBoxWithConditionalOverlay = (partsCost, showOverlay) => {
        const messages = defineMessages({
            contract_pricing_cannot_be_edited_message: {
                id: 'contract_pricing_cannot_be_edited_message',
                description: 'This product code has contract pricing. Price cannot be edited.',
                defaultMessage: 'This product code has contract pricing. Price cannot be edited.'
            },
        });

        const partsCostInputBox = <DynamicNumber
            disabled={!props.partsCostEditable}
            className={props.partsCostEditable ? "correction-type__input-medium" : "correction-type__input-readonly"}
            onChange={props.handleChange.bind(this, "COST")}
            separator={'.'}
            integer={6}
            fraction={2}
            positive={true}
            negative={false}
            thousand={false}
            value={partsCost}
            ref={props.partsCheckRef}
        />;

        if (showOverlay)
            return <OverlayTrigger placement='right'
                overlay={<Tooltip><RawIntlProvider value={props.intl}><FormattedMessage {...messages.contract_pricing_cannot_be_edited_message} /></RawIntlProvider></Tooltip>}
            >
                {partsCostInputBox}
            </OverlayTrigger>;
        else
            return partsCostInputBox
    }

    const qtySync = (evt, modelValue, viewValue) =>
    {
        props.handleChange("QTY",evt, modelValue, viewValue);
        props.handleChange("HOURS",evt, modelValue, viewValue);
    }

    let laborRate = props.currentLaborRate === 0 ? "" : props.currentLaborRate.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let laborHours = props.currentLaborHours === 0 ? "" : props.currentLaborHours.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let laborTotal = props.correctionDetailsTotal.get('laborTotal').toFixed(2);

        let partsQty = props.currentPartsQty === 0 ? "" : props.currentPartsQty.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let partsCost = props.currentPartsCost === 0 ? "" : props.currentPartsCost.toString().match(/^-?\d+(?:\.\d{0,2})?/);
        let partsTotal = props.correctionDetailsTotal.get('partTotal').toFixed(2);

        let months = props.currentMonths === 0 ? "" : props.currentMonths;
        let miles = props.currentMiles === 0 ? "" : props.currentMiles;
        let hours = props.currentHours === 0 ? "" : props.currentHours;
        
        //Flag for final price validation visiblity
        let priceValidationExceeds = props.priceValidations?.find(x => x != undefined).partscostexceeds;
        //Flag for price exceed confirmation button
        let priceValidationConfirmed = props.pricesConfirmedList[0];
        //Set validation highlighting class on input
        let costInputClass = priceValidationExceeds ? " col-error" : "";
    
        const PriceControlConfirmation = () => {
            const messages = defineMessages({
                price_exceeds_confirmation: {
                    id: 'price_exceeds_confirmation',
                    description: 'Confirm',
                    defaultMessage: 'Confirm'
                },
            });
    
            return <div {...props}>
                <CheckCircleStyled message={<FormattedMessage {...messages.price_exceeds_confirmation} />} size={"sm"} checked={priceValidationConfirmed} onClick={() => {props.handlePriceValdationConfirmationButtonClick(0)}} />
            </div>;
        }

        const messages = defineMessages({
            replace_hours: {
                id: 'replace_hours',
                description: 'Hours',
                defaultMessage: 'Hours'
            },
            replace_rate: {
                id: 'replace_rate',
                description: 'Rate',
                defaultMessage: 'Rate'
            },
            replace_qty: {
                id: 'replace_qty',
                description: 'Qty',
                defaultMessage: 'Qty'
            },
            replace_cost: {
                id: 'replace_cost',
                description: 'Cost',
                defaultMessage: 'Part Cost'
            },
            replace_amount: {
                id: 'replace_amount',
                description: 'Amount',
                defaultMessage: 'Amount'
            },
            replace_hide_labor: {
                id: 'replace_hide_labor',
                description: 'Labor for {newProductDescription} should be included in {existingProductDescription}',
                defaultMessage: 'Labor for {newProductDescription} should be included in {existingProductDescription}'
            },
            warranty: {
                id: 'warranty',
                description: 'Warranty',
                defaultMessage: 'Warranty:'
            },
            warranty_months: {
                id: 'warranty_months',
                description: 'Months',
                defaultMessage: 'Months'
            },
            warranty_miles: {
                id: 'warranty_miles',
                description: 'Miles',
                defaultMessage: 'Miles'
            },
            warranty_hours: {
                id: 'warranty_hours',
                description: 'Hours',
                defaultMessage: 'Hours'
            },
            replace_labor_cost: {
                id: 'replace_labor_cost',
                description: 'Lab Cost',
                defaultMessage: 'Lab Cost'
            },
            replace_labor_qty: {
                id: 'replace_labor_qty',
                description: 'Qty',
                defaultMessage: 'Qty'
            },
        });
    
    return (
            <div className="correction-type__replace">
                {props.restrictedRepairTypes == undefined || (!props.restrictedRepairTypes.includes("ALL") && !props.restrictedRepairTypes.includes("PARTS")) ?
                    <div className="correction-type__part-detail col-xl-14">
                        <div className="row">
                            <div className={isIE ? "form-group row" : "form-group_small col-sm-3 "}>
                                {props.intl.locale == 'es-MX' ? <div><br /></div> : ''}
                                <span className="form-group_text">
                                    <FormattedMessage {...messages.replace_qty} />
                                </span>
                                <DynamicNumber
                                    className="correction-type__input-small"
                                    onChange={props.isQtySyncFeatureEnabled ? qtySync.bind(this) : props.handleChange.bind(this, "QTY")}
                                    separator={'.'}
                                    integer={3}
                                    fraction={props.QtyShouldBeWholeNumber ? 0 : 2}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={partsQty}
                                />
                            </div>
                            <div className={isIE ? "form-group row" + costInputClass : "form-group col-sm-4" + costInputClass }>
                                <div className={priceValidationExceeds && !priceValidationConfirmed ? "form-error" : ""}>
                                    <span className="form-group_text">
                                        <FormattedMessage {...messages.replace_cost} />
                                    </span>
                                    {props.partsCostHidden ?
                                        renderInputBoxWithNALabel()
                                        :
                                        renderpartsCostInputBoxWithConditionalOverlay(partsCost,!props.partsCostEditable)
                                    }
                                </div>
                                {priceValidationExceeds ? <PriceControlConfirmation/> : null}
                            </div>
                            <div className={isIE ? "form-group row" : "form-group col-sm-4 "}>
                                {props.intl.locale == 'es-MX' ? <div><br /></div> : ''}
                                <span className="form-group_text">
                                    <FormattedMessage {...messages.replace_amount} />
                                </span>
                                {props.partsCostHidden ?
                                    renderInputBoxWithNALabel()
                                    :
                                    <DynamicNumber
                                        disabled
                                        className="correction-type__input-readonly"
                                        separator={'.'}
                                        integer={6}
                                        fraction={2}
                                        positive={true}
                                        negative={false}
                                        thousand={false}
                                        value={partsTotal}
                                    />
                                }
                            </div>
                        </div>
                    </div> : undefined}
                {!props.hideLaborInputFields.size > 0 && (props.restrictedRepairTypes == undefined || (!props.restrictedRepairTypes.includes("ALL") && !props.restrictedRepairTypes.includes("LABOR"))) ?
                    <div className="correction-type__labor-detail col-xl-14">
                        <div className="row">
                            <div className={isIE ? "form-group row" : "form-group_small col-sm-3"}>
                                <span className="form-group_text">
                                    {props.isFlatRateFeatureEnabled ? <FormattedMessage {...messages.replace_labor_qty} /> : <FormattedMessage {...messages.replace_hours} />}
                                </span>
                                <DynamicNumber
                                    className="correction-type__input-small"
                                    onChange={props.isQtySyncFeatureEnabled ? qtySync.bind(this) : props.handleChange.bind(this, "HOURS")}
                                    separator={'.'}
                                    integer={3}
                                    fraction={props.QtyShouldBeWholeNumber ? 0 : 2}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={laborHours}
                                />
                            </div>
                            <div className={isIE ? "form-group row" : "form-group col-sm-4"}>
                                <span className="form-group_text">
                                    {props.isFlatRateFeatureEnabled ? <FormattedMessage {...messages.replace_labor_cost} /> : <FormattedMessage {...messages.replace_rate} />}
                                </span>
                                {props.laborCostHidden ?
                                    renderInputBoxWithNALabel()
                                    :
                                    renderLaborRateInputBoxWithConditionalOverlay(laborRate, !props.laborCostEditable)
                                }
                            </div>
                            <div className={isIE ? "form-group row" : "form-group col-sm-4 "}>
                                <span className="form-group_text">
                                    <FormattedMessage {...messages.replace_amount} />
                                </span>
                                {props.laborCostHidden ?
                                    renderInputBoxWithNALabel()
                                    :
                                    <DynamicNumber
                                        disabled
                                        className="correction-type__input-readonly"
                                        separator={'.'}
                                        integer={6}
                                        fraction={2}
                                        positive={true}
                                        negative={false}
                                        thousand={false}
                                        value={laborTotal}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    :
                    props.hideLaborInputFields.size > 0 ? <div className="correction-type__labor-detail">
                        <span className="form-group_text"><FormattedMessage values={{
                            newProductDescription: props.hideLaborInputFields.get('newProductDescription'),
                            existingProductDescription: props.hideLaborInputFields.get('existingProductDescription')
                        }} {...messages.replace_hide_labor} /></span>
                    </div> : undefined
                }

                {props.restrictedRepairTypes == undefined || (!props.restrictedRepairTypes.includes("ALL") && !props.restrictedRepairTypes.includes("PARTS")) ?

                    <div className="correction-type__warranty-detail col-xl-14">
                        <div className="correction-type__warranty-title ">
                            <FormattedMessage {...messages.warranty} />
                        </div>
                        <div className="row">
                            <div className={isIE ? "form-group row" : "form-group_small col-sm-3 "}>
                                <span className="form-group_text">
                                    <FormattedMessage {...messages.warranty_months} />
                                </span>
                                <DynamicNumber
                                    className="correction-type__input-small"
                                    onChange={props.handleChange.bind(this, "WARRANTY_MONTHS")}
                                    separator={'.'}
                                    integer={3}
                                    fraction={0}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={months}
                                />
                            </div>
                            <div className={isIE ? "form-group row": "form-group col-sm-4 "}>
                                <span className="form-group_text">
                                    <FormattedMessage {...messages.warranty_miles} />
                                </span>
                                <DynamicNumber
                                    className="correction-type__input-medium"
                                    onChange={props.handleChange.bind(this, "WARRANTY_MILES")}
                                    separator={'.'}
                                    integer={6}
                                    fraction={0}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={miles}
                                />
                            </div>
                            <div className={isIE ? "form-group row" : "form-group col-sm-4 "}>
                                <span className="form-group_text">
                                    <FormattedMessage {...messages.warranty_hours} />
                                </span>
                                <DynamicNumber
                                    className="correction-type__input-large2"
                                    onChange={props.handleChange.bind(this, "WARRANTY_HOURS")}
                                    separator={'.'}
                                    integer={6}
                                    fraction={0}
                                    positive={true}
                                    negative={false}
                                    thousand={false}
                                    value={hours}
                                />
                            </div>
                        </div>
                    </div>
                    : undefined
                }

            </div>
        );
    }
;


export default injectIntl(CorrectionDetailsReplace);
