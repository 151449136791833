import React from 'react';
import Title from 'components/Title';
import './SearchPOTitle.css';

class SearchPOTitle extends React.Component {

    render() {
        //render the view
        return <div className="container-fluid search_po_title">
            <Title caption={this.props.caption}/>
        </div>;
    }
}


export default SearchPOTitle;