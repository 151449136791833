import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';

export function uiDataResetPassword(state = initialAppState.getIn(['uiData', 'resetPassword']), action) {
    switch (action.type) {
        case constants.SAVE_RESET_PASSWORD_INFORMATION_PERSONAL:
            return state.set('personal', action.resetPasswordPersonal);
        case constants.CLEAR_RESET_PASSWORD_ERROR:
            return state.setIn(['popOver', 'isPopOverDisplayedShowPassword'], false)
                .setIn(['popOver', 'isPopOverDisplayedShowConfirmPassword'], false)
                .setIn(['isResetPasswordSuccessed'], false)
                .setIn(['isRequestedRejected'], false)
                .setIn(['personal'], '')
                .setIn(['errorMessage'], [])
                .setIn(['confirmPasswordErrorMessage'], '')
                .setIn(['hasErrorMessage'], false);
        case constants.POP_OVER_DISPLAY_INVALID_PASSWORD:
            return state.setIn(['popOver', 'isPopOverDisplayedShowPassword'],
                action.popOverDisplayShowPassword, !state.getIn(['popOver', 'isPopOverDisplayedShowPassword'],
                    action.popOverDisplayShowPassword));
        case constants.POP_OVER_DISPLAY_CONFIRM_PASSWORD:
            return state.setIn(['popOver', 'isPopOverDisplayedShowConfirmPassword'],
                action.popOverDisplayShowConfirmPassword, !state.getIn(['popOver', 'isPopOverDisplayedShowConfirmPassword'],
                    action.popOverDisplayShowConfirmPassword));
        case constants.RESET_PASSWORD_REQUESTED:
            return state.set('isFetchingData', true)
            .setIn(['errorMessage'], [])
            .setIn(['hasErrorMessage'], false);
        case constants.RESET_PASSWORD_APPROVED:
            return state.set('isResetPasswordSuccessed', true);
        case constants.RESET_PASSWORD_RESOLVED:
            return state.set('isFetchingData', false)
            .set('isRequestedRejected', false)
            .setIn(['hasErrorMessage'], false);
        case constants.RESET_PASSWORD_MOUNT:
            return state.set('isResetPasswordSuccessed', false);
        case constants.SAVE_CONFIRM_PASSWORD_ERROR_MESSGAE:
            return state.set('confirmPasswordErrorMessage', action.confirmPasswordErrorMessage);
        case constants.RESET_PASSWORD_REJECTED:
            return state.set('isFetchingData', false)
            .set('errorMessage', [{ message: action.errorMessage }])
            .set('isResetPasswordSuccessed', false)
            .set('isRequestedRejected', true);
        case constants.SHOW_RESET_PASSWORD_ERROR_MESSAGE:
            return state.setIn(['hasErrorMessage'], true);
        default:
            return state;
    }
}