// libraries
import React from 'react';
import { defineMessages, FormattedMessage,injectIntl,RawIntlProvider } from 'react-intl';
import { Overlay, Popover } from 'react-bootstrap';
// our stuff
import './VehicleComponents.css';
import CheckCircle from 'components/CheckCircle';
import VehicleComponent from './VehicleComponent';
import FraResult from './FraResult';
import VehicleComponentHeader from './VehicleComponentHeader';
import VehicleComponentGuideWheelLoad from './VehicleComponentGuideWheelLoad';

class VehicleComponents extends React.Component {	
	constructor(props) {
		super(props);
		this.handleChangeDebounced = _.debounce(this.handleChange, 1000);
		this.rearSerialNumberRef = React.createRef();
		this.frontSerialNumberRef = React.createRef();
		this.modelRef = React.createRef();
	}

	handleChange = (id, value) => {        		
        this.props.setFormData(id, value);        
	}

	handleInputChange = (event) => {        
        this.props.setFormData(event.currentTarget.id, event.currentTarget.value);
	}
	
	handleChangeDebouncedCaller = (event) => { 
		// Note: You must call the debounced function with id and value instead of passing the event object.
		//       Event may be null by the time the debounced function is invoked.
		this.handleChangeDebounced(event.target.id, event.target.value);
    }

	render() {		
		
		const messages = defineMessages({
			fra_inspection_form__all_ok: {
				id: 'fra_inspection_form__all_ok',
				description: 'FRA inspection form All Ok button.',
				defaultMessage: 'All OK'
			},							

			fra_inspection_form__model: {
                id: 'fra_inspection__model',
                description: 'Model',
                defaultMessage: 'Rail gear model'
			},
			fra_inspection__rail_gear_model_required: {
				id: 'fra_inspection__rail_gear_model_required',
                description: 'Required',
                defaultMessage: 'Provide model'
			},
			fra_inspection_form__front_serial_number: {
                id: 'fra_inspection__front_serial_number',
                description: 'Front serial number',
                defaultMessage: 'Front serial number'
			},
			fra_inspection__front_serial_required: {
				id: 'fra_inspection__front_serial_required',
                description: 'Required',
                defaultMessage: 'Provide front serial number'
			},
			fra_inspection_form__rear_serial_number: {
                id: 'fra_inspection__rear_serial_number',
                description: 'Rear serial number',
                defaultMessage: 'Rear serial number'
			},
			fra_inspection__rear_serial_required: {
				id: 'fra_inspection__rear_serial_required',
                description: 'Required',
                defaultMessage: 'Provide rear serial number'
			},
						
			fra_inspection_form__component_201: {
				id: 'fra_inspection_form__component_201',
				description: 'FRA inspection form component 201.',
				defaultMessage: 'Visually inspect rail gear for damage and missing components. Check all mounting hardware. Tighten any loose mounting hardware. Check pivot bearings for wear.'
			}, 
			fra_inspection_form__component_202: {
				id: 'fra_inspection_form__component_202',
				description: 'FRA inspection form component 202.',
				defaultMessage: 'Inspect steering wheel lock mechanism for proper installation and function.'
			}, 
			fra_inspection_form__component_203: {
				id: 'fra_inspection_form__component_203',
				description: 'FRA inspection form component 203.',
				defaultMessage: 'Inspect guide wheels. Insure flange wear is within tolerance. Check wheel bearings for play, wear and noise. Advise if guide wheel bearings need to be serviced.'
			}, 
			fra_inspection_form__component_204: {
				id: 'fra_inspection_form__component_204',
				description: 'FRA inspection form component 204.',
				defaultMessage: "Check guide wheel load per manuafacturer's recommendations. Advise if heights and weights require adjustments. Check if within OEM specification."
			}, 
			fra_inspection_form__component_205: {
				id: 'fra_inspection_form__component_205',
				description: 'FRA inspection form component 205.',
				defaultMessage: 'Inspect rail gear lock mechanisms and lock pins for proper operation.'
			}, 
			fra_inspection_form__component_206: {
				id: 'fra_inspection_form__component_206',
				description: 'FRA inspection form component 206.',
				defaultMessage: 'Check truck tires for proper inflation. Replace any missing valve stem caps. Inflate tires to recommended pressure on door jamb decal.'
			}, 
			fra_inspection_form__component_207: {
				id: 'fra_inspection_form__component_207',
				description: 'FRA inspection form component 207.',
				defaultMessage: 'Check steering wheel stops on vehicles where installed.  Inspect: front ABS sensor wiring, upper control arms, brake hoses and brake hose retaining clamps for damage. Report any deficiencies.'
			}, 
			fra_inspection_form__component_208: {
				id: 'fra_inspection_form__component_208',
				description: 'FRA inspection form component 208.',
				defaultMessage: 'Visually inspect modified truck wheels, studs, lug nuts and tires for missing equipment, wear or damage. Visually inspect wheel spacers. Do not remove truck wheels. Tighten wheel nuts to correct torque.'
			}, 
			fra_inspection_form__component_209: {
				id: 'fra_inspection_form__component_209',
				description: 'FRA inspection form component 209.',
				defaultMessage: 'Inspect rail gear installation for proper alignment. Paint guide wheels flanges and track test. Advise if vehicle requires rail gear alignment.'
			}, 
			fra_inspection_form__component_210: {
				id: 'fra_inspection_form__component_210',
				description: 'FRA inspection form component 210.',
				defaultMessage: 'Inspect shunt system for damage and proper operation. Adjust shunt brushes as required.'
			}, 
			fra_inspection_form__component_211: {
				id: 'fra_inspection_form__component_211',
				description: 'FRA inspection form component 211.',
				defaultMessage: 'Verify proper operation of strobe light. Required under 49CFR214.'
			}, 
			fra_inspection_form__component_212: {
				id: 'fra_inspection_form__component_212',
				description: 'FRA inspection form component 212.',
				defaultMessage: 'Verify proper operation of back up alarm. Required under 49CFR214.'
			}, 
			fra_inspection_form__component_213: {
				id: 'fra_inspection_form__component_213',
				description: 'FRA inspection form component 213.',
				defaultMessage: 'Lubricate all grease fittings on rail gear. Lubricate other moving parts with light oil.'
			}, 
			fra_inspection_form__component_214: {
				id: 'fra_inspection_form__component_214',
				description: 'FRA inspection form component 214.',
				defaultMessage: "Insure that rail gear is in compliance with all the manufacturer's service and safety bulletins."
			}, 
			fra_inspection_form__component_215: {
				id: 'fra_inspection_form__component_215',
				description: 'FRA inspection form component 215.',
				defaultMessage: 'Record any abnormal damage to rail gear system that would indicate derail or collision damage.'
			}, 
			fra_inspection_form__component_216: {
				id: 'fra_inspection_form__component_216',
				description: 'FRA inspection form component 216.',
				defaultMessage: 'Check hydraulic valves, hoses and cylinders as equipped for damage, leaking and routing. Advise any deficiencies.'
			}, 							
																		
		});
		
        return (
			<div>  
				
				<div className='fra_inspection_vehicle_components__all_ok'>
				
					<CheckCircle 
						name='isAllOk'
						className='fra_inspection_vehicle_components__check_circle'					
						message={<FormattedMessage {...messages.fra_inspection_form__all_ok} />}
						checked={this.props.formData.get('isAllOk')}
						onClick={(name, checked) => this.props.setFormData(name, !checked)}
					/>
								
				</div>								
				
				<VehicleComponentHeader />

				<VehicleComponent 
					componentId='200'
					renderName={ 
						<div>
							<span className='fra_inspection_vehicle_components__input_group'>
								<FormattedMessage {...messages.fra_inspection_form__model} />
								{' '}
								<input 
									type='text' 								
									id='railGearModel' 							
									maxLength='200'
									size={12}
									value={this.props.formData.get('railGearModel')}
									onChange={this.handleInputChange}
									ref={this.modelRef}
								/>
								{ !!this.props.formValidationMessages.get('railGearModel') &&
									<Overlay
										target={this.modelRef.current}
										show="true"
										placement="top">
										<Popover
											id='railGearModelOverlay'
											className='fra_inspection_vehicle_components__error_message'
										>
											<RawIntlProvider value={this.props.intl}>
												<FormattedMessage {...(messages[this.props.formValidationMessages.get('railGearModel')])} />
											</RawIntlProvider>
										</Popover>
									</Overlay>
								} 
								{' '}
							</span>

							<span className='fra_inspection_vehicle_components__input_group'>
								<FormattedMessage {...messages.fra_inspection_form__front_serial_number} />
								{' '}
								<input 
									type='text' 							
									id='railGearFrontSerialNumber' 							
									maxLength='50'
									size={12}
									value={this.props.formData.get('railGearFrontSerialNumber')}
									onChange={this.handleInputChange}
									ref={this.frontSerialNumberRef}
								/>
								{ !!this.props.formValidationMessages.get('railGearFrontSerialNumber') &&
									<Overlay
										target={this.frontSerialNumberRef.current}
										placement="top"
										show="true">
										<Popover
											id='railGearFrontSerialNumberOverlay'
											className='fra_inspection_vehicle_components__error_message'
										>
											<RawIntlProvider value={this.props.intl}>
												<FormattedMessage {...(messages[this.props.formValidationMessages.get('railGearFrontSerialNumber')])} />
											</RawIntlProvider>
										</Popover>
									</Overlay>
								}
								{' '}
							</span>

							<span className='fra_inspection_vehicle_components__input_group'>
								<FormattedMessage {...messages.fra_inspection_form__rear_serial_number} />
								{' '}
								<input 
									type='text' 								
									id='railGearRearSerialNumber' 							
									maxLength='50'
									size={12}
									value={this.props.formData.get('railGearRearSerialNumber')}
									onChange={this.handleInputChange}
									ref={this.rearSerialNumberRef}
								/>
								{ !!this.props.formValidationMessages.get('railGearRearSerialNumber') &&
									<Overlay
										show="true"
										target={this.rearSerialNumberRef.current}
										placement="top">
										<Popover
											id='railGearRearSerialNumberOverlay'
											className='fra_inspection_vehicle_components__error_message'
										>
											<RawIntlProvider value={this.props.intl}>
												<FormattedMessage {...(messages[this.props.formValidationMessages.get('railGearRearSerialNumber')])} />
											</RawIntlProvider>
										</Popover>
									</Overlay>
								}
							</span>
						</div> }					
					showPass={false} showFail={false}
					
				/>
				<VehicleComponent 
					componentId='201'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_201} />}
					componentDetails={this.props.componentDetails.get('201')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='202'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_202} />}
					componentDetails={this.props.componentDetails.get('202')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='203'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_203} />}
					componentDetails={this.props.componentDetails.get('203')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='204'
					renderName={
						<div>
							<div><FormattedMessage {...messages.fra_inspection_form__component_204} /></div>
							<VehicleComponentGuideWheelLoad
								formData={this.props.formData}
								formValidationMessages={this.props.formValidationMessages}
								setFormData={this.props.setFormData}
							/>
						</div>
					}
					componentDetails={this.props.componentDetails.get('204')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='205'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_205} />}
					componentDetails={this.props.componentDetails.get('205')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='206'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_206} />}
					componentDetails={this.props.componentDetails.get('206')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='207'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_207} />}
					componentDetails={this.props.componentDetails.get('207')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='208'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_208} />}
					componentDetails={this.props.componentDetails.get('208')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='209'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_209} />}
					componentDetails={this.props.componentDetails.get('209')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='210'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_210} />}
					componentDetails={this.props.componentDetails.get('210')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='211'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_211} />}
					componentDetails={this.props.componentDetails.get('211')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='212'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_212} />}
					componentDetails={this.props.componentDetails.get('212')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='213'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_213} />}
					componentDetails={this.props.componentDetails.get('213')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='214'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_214} />}
					componentDetails={this.props.componentDetails.get('214')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='215'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_215} />}
					componentDetails={this.props.componentDetails.get('215')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>
				<VehicleComponent 
					componentId='216'
					renderName={<FormattedMessage {...messages.fra_inspection_form__component_216} />}
					componentDetails={this.props.componentDetails.get('216')}
					onComponentDetailsChanged={this.props.onComponentDetailsChanged}
				/>


				<FraResult 
					formData={this.props.formData}
					userLocale={this.props.userLocale}
					formValidationMessages={this.props.formValidationMessages}
					setFormData={this.props.setFormData}
					poCreationDate={this.props.poCreationDate}
					onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}					
				/>

			</div>
        );
    }
}

export default injectIntl(VehicleComponents);