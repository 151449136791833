// #region Imports
import React, { useEffect, useState, useCallback } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import Submit from '../../../../../../shared/components/Submit';
import './LaborRateUpdateStep.css';
import ClientParametersComponent from 'components/ClientParametersComponent';
import { connect } from "react-redux";
import AddNewLineItemsSelector from '../selectors/AddNewLineItemsView';
import LaborRateUpdateView from "../../../components/LaborRateUpdate";
// #endregion

// #region Styled Components
// #endregion

// #region Component
const LaborRateUpdateStep = (props) => {

    // #region State
    const [showLaborRateValidationError, setShowLaborRateValidationError] = useState(false);

    // Three possible values, null - no reponse, Y - update labor rate, N - do not update labor rate
    const [updateLaborRateFlag, setUpdateLaborRateFlag] = useState(null);
    // #endregion

    // #region Ref
    // #endregion  

    // #region Callback
    // #endregion  

    // #region Event handlers

    /* Continue link clicked */
    const onAddLineItemToPOHandler = () => {
        if (updateLaborRateFlag == null)
            setShowLaborRateValidationError(true);
        else {
            setShowLaborRateValidationError(false);

            let newLineItems = props.newLineItems;

            let lineItem = {
                productCode: newLineItems.get('ataCode'),
                description: newLineItems.get('lineDescription').toUpperCase(),
                partQty: newLineItems.get('qty'),
                partCost: newLineItems.get('partCost'),
                laborHours: newLineItems.get('hours'),
                laborRate: newLineItems.get('laborRate'),
                complaintCode: newLineItems.get('complaintCode'),
                correctionType: newLineItems.get('selectedCorrectionType'),
                partWarrantyMonths: newLineItems.getIn(['partWarranty', 'months']),
                partWarrantyMiles: newLineItems.getIn(['partWarranty', 'miles']),
                partWarrantyHours: newLineItems.getIn(['partWarranty', 'hours']),
                multipleLineItemsPermittedForATA: props.selectedATAAllowsMultipleLines
            }

            props.dispatch({
                type: "SEND_LABOR_RATE_CHANGE_REQUEST",
                laborRate: newLineItems.get("laborRate"),
                flag: updateLaborRateFlag
            });

            // Let parent know that the current step is done
            props.onContinue();   
        }
    }

    // #endregion  

    // #region Effects
    // #endregion

    // #region International messages
    const messages = defineMessages({
        add_line_item_to_po: {
            id: 'add_line_item_to_po',
            description: 'Add line item to PO ',
            defaultMessage: 'Add line item to PO '
        },
        parameters_header: {
            id: 'parameters_header',
            description: 'Header for ata parameters ',
            defaultMessage: 'Parameters '
        },
        empty_parameters: {
            id: 'empty_parameters',
            description: 'Description when parameters are empty',
            defaultMessage: 'None at this time'
        },
        add_new_line_item_caption: {
            id: 'add_new_line_item_caption',
            description: 'Add new line item caption',
            defaultMessage: 'Add {complaint_description} items'
        },
        continue_link: {
            id: 'continue_link',
            description: 'Continue',
            defaultMessage: 'Continue'
        },
        return: {
            id: 'return',
            description: 'Return',
            defaultMessage: 'Return'
        },
        items_added: {
            id: 'items_added',
            description: 'items_added',
            defaultMessage: 'Items added to PO:'
        }
    });  
    // #endregion

    // #region variables
    let productCodeParams = props.ataParametersSorted == undefined ? '' :
        props.ataParametersSorted.map((param) => {
            return <ClientParametersComponent key={param.get('id')}
                description={param.get('description')}
            />
        });

    const returnLink = <div className=""
        role="button"
        onClick={() => props.onReturn()}>
        <img src="/assets/VMS_33button_back_blue.png" />
        {' '}
        <FormattedMessage {...messages.return} />
    </div>

    const addLineItemToPoLink = <Submit
        caption={<FormattedMessage {...messages.add_line_item_to_po} />}
        imagePath="/assets/VMS_33button_add_blue.png"
        onClickHandler={onAddLineItemToPOHandler}
        showSpinner={props.isCreatingLineItems}
        spinnerType="blue" />

    //#endregion    

    // #region Render
    return (
        <div>
            <div className="add-new-line-items">
                <div className='add-new-line-items_step-1-2-3-container'>
                    <div className="row add-new-line-items__caption">
                        <div>{props.complaintCode.get('id') !== undefined ?
                            <FormattedMessage
                                values={{
                                    complaint_description:
                                        <FormattedMessage
                                            id={props.complaintCode.get('id')}
                                            description={props.complaintCode.get('description')}
                                            defaultMessage={props.complaintCode.get('description')} />
                                }}
                                {...messages.add_new_line_item_caption} />
                            : undefined}
                        </div>
                    </div>

                    {props.itemsAddedToPo != undefined && props.itemsAddedToPo.size > 0 ?
                        <div className="add-new-line-items__recenly-added-ata-codes">
                            <div className="add-new-line-items__recenly-added-ata-codes-header"><FormattedMessage {...messages.items_added} /></div>
                            {
                                props.itemsAddedToPo.map(item => {
                                    return <div>{item.get('productDescription')}</div>;
                                }).valueSeq()}
                        </div>
                        : undefined
                    }
                    <LaborRateUpdateView currentRate={props.standardLaborRate}
                        newRate={props.currentLineLaborRate}
                        saveLaborRateFlag={setUpdateLaborRateFlag}
                        showLaborRateValidationError={showLaborRateValidationError}
                        frameNumber={props.laborRateFrameNumber}
                    />
                </div>
                <div className="row">
                    <div className={classnames('col-md-5 submit add-new-line-items__return')}>
                        {
                            returnLink
                        }
                    </div>

                    <div className={classnames('col-md-7 add-new-line-items__save',
                        !props.enableAddLineItemToPo || props.isCreatingLineItems ? 'add-new-line-items__save__disabled' : undefined)}>
                        {
                            addLineItemToPoLink
                        }
                    </div>
                </div>
                <div className="row add-new-line-parameters">
                    <div className="add-new-line-parameters__header">
                        <FormattedMessage {...messages.parameters_header} />
                    </div>
                    <div>
                        {productCodeParams.size === 0 ?
                            <p className="add-new-line-empty__description">
                                <FormattedMessage {...messages.empty_parameters} />
                            </p>
                            :
                            <div> {productCodeParams} </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
// #endregion

function mapToStateProps(state, props) {
    return {
        ...AddNewLineItemsSelector(state, props)
    }
}
export default connect(mapToStateProps)(injectIntl(LaborRateUpdateStep));