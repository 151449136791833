import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';


export function* fetchTermsAndConditions(context,action) {

    try{
        yield put({ type:  constants.FETCH_TERMS_AND_CONDITIONS_REQUESTED });

        const vendorTermsAndConditions =  yield call(Api.getTermsAndConditionText);

        yield put({ type: constants.SAVE_VENDOR_TERMS_AND_CONDITIONS,vendorTermsAndConditions });

        yield put({ type:  constants.FETCH_TERMS_AND_CONDITIONS_RESOLVED });

    }catch(e){
        yield put({ type: constants.FETCH_TERMS_AND_CONDITIONS_REJECTED });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage:  helperFunctions.getGenericErrorObject(e) });

    }

}