/**
 * Created by prboyapa on 2/8/2016.
 */
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import './POTotals.css';
import { IntlProvider } from 'react-intl';

class POTotals extends React.Component {

    render() {
        const messages = defineMessages({
            po_actions_po_totals_view_subtotals: {
                id: 'po_actions_po_totals_view_subtotals',
                description: 'Subtotal',
                defaultMessage: 'Subtotal'
            },
            po_actions_po_totals_view_tax: {
                id: 'po_actions_po_totals_view_tax',
                description: 'Tax',
                defaultMessage: 'Tax'
            },
            po_actions_po_totals_view_total: {
                id: 'po_actions_po_totals_view_total',
                description: 'Total',
                defaultMessage: 'Total'
            }
        });

        return <div className='po_actions_totals_view'>
            <div className='table-responsive'>
                <table className="po_actions_totals_view__table">
                    <tbody>
                    <tr className="row">
                        <td className="col-md-8">
                        </td>
                        <td className="col-md-2 po_actions_totals_view__line_item">
                            <FormattedMessage {...messages.po_actions_po_totals_view_subtotals} />
                        </td>
                        <td className={classNames("col-md-2", "po_actions_totals_view__currency",
                                                {po_actions_totals_view_currency__bold: this.props.actionType == 'CLOSE'})}>
                            <IntlProvider locale="en">
                                <FormattedNumber value={this.props.approvedAmountTotal + this.props.pendingAmountTotal}
                                             style="currency"
                                             currency={this.props.selectedCurrency}/>
                            </IntlProvider>
                        </td>
                    </tr>
                    <tr className={classNames("row", {po_actions_totals__hide: this.props.actionType == "CLOSE"})}>
                        <td className="col-md-8">
                        </td>
                        <td className="col-md-2 po_actions_totals_view__line_item">
                            <FormattedMessage {...messages.po_actions_po_totals_view_tax} />
                        </td>
                        <td className="col-md-2  po_actions_totals_view__currency">
                            <IntlProvider locale="en">
                                <FormattedNumber value={this.props.taxAmount} style="currency" currency={this.props.selectedCurrency}/>
                            </IntlProvider>
                        </td>
                    </tr>
                    <tr className={classNames("row", {po_actions_totals__hide: this.props.actionType == "CLOSE"})}>
                        <td className="col-md-8">
                        </td>
                        <td className="col-md-2 po_actions_totals_view__line_item">
                            <FormattedMessage {...messages.po_actions_po_totals_view_total} />
                        </td>
                        <td className="col-md-2  po_actions_totals_view__currency">
                            <IntlProvider locale="en">
                                <FormattedNumber
                                value={this.props.approvedAmountTotal + this.props.pendingAmountTotal + this.props.taxAmount}
                                style="currency" currency={this.props.selectedCurrency}/>
                            </IntlProvider>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    }
}


export default POTotals;