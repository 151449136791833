import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import OdometerVehicleTitle from '../components/OdometerVehicleTitle';
import OdometerVehicleInformation from '../components/OdometerVehicleInformation';
import OdometerUpdate from '../components/OdometerUpdate';
import * as odometerViewActions from '../actions/OdometerView';
import Spinner from 'components/Spinner';
import OdometerViewSelector from '../selectors/OdometerView';
import RecentService from '../components/RecentService';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { emptyMapIfUndefined } from 'utils/HelperFunctions';
import { useAppState } from 'shared/AppState/app-state';
import ContactCustomerDirectlyForApprovalBanner from 'components/ContactCustomerDirectlyForApprovalBanner';

const OdometerView = (props) => {

    const [ appState, dispatch ] = useAppState();

    useEffect(() => {
        props.dispatch(odometerViewActions.odometerScreenLoaded(props.routeParams.match.params.vehicleId, !appState.isFlatRateFeatureEnabled)) ;
    }, []);

    useEffect(() => {
        props.dispatch(odometerViewActions.odometerScreenLoaded(props.routeParams.match.params.vehicleId, !appState.isFlatRateFeatureEnabled));
    }, [props.routeParams.match.params.vehicleId]);

    const onSaveOdometerDetailsHandler = (newOdometer, newEngineHours, vendorReferenceNo, vendorRequisitioner, clientReferenceNo) => {
        props.dispatch(odometerViewActions.saveOdometerDetails(newOdometer, newEngineHours, vendorReferenceNo, vendorRequisitioner, clientReferenceNo));
    }

    const onSaveUpdateClickHandler = () => {
        props.dispatch({ type: constants.CREATE_PO_CLICKED, vehicleId: props.routeParams.match.params.vehicleId });
    }

    const onVehicleAtShopChangeHandler = (isVehicleAtShop) => {
        props.dispatch(odometerViewActions.saveVehicleAtShopDetails(isVehicleAtShop));
    }

    if (props.isfetchingData) {
        return <Spinner />;
    }

    const vehicleDetails = props.vehicleDetails.toJSON();

    return <div>
        <FormWrapper key='odometerErrorMessage' id="odometerErrorMessage" {...props} formErrors={props.genericErrorMessage} />
        {props.vehicleDetails.get('isHsbClientVehicle') && <ContactCustomerDirectlyForApprovalBanner />}
        <OdometerVehicleTitle caption={<div>{vehicleDetails.year + ' ' +
            vehicleDetails.make + ' ' +
            vehicleDetails.model} </div>} />
        <OdometerVehicleInformation vehicleDetails={props.vehicleDetails} client={props.client} />
        <FormWrapper key='odometerUpdateErrorMessage' id="odometerUpdateErrorMessage" {...props} formErrors={props.odometerUpdateErrorMessage} />
        <OdometerUpdate vehicleDetails={props.vehicleDetails}
            odometerData={props.odometerData}
            onSaveVehicleDetails={onSaveOdometerDetailsHandler.bind(this)}
            onSaveUpdateClick={onSaveUpdateClickHandler.bind(this)}
            onVehicleAtShopSelectionChange={onVehicleAtShopChangeHandler.bind(this)}
            client={props.client}
            isCreatingPO={props.isCreatingPO}
            driverValidationFlag={props.driverValidationFlag}
            selectedLocale={props.selectedLocale}
            paymentType={props.paymentType}
            fieldValidations={props.fieldValidations}
        />

        <RecentService vehicleRecentService={props.vehicleRecentService}
            vin8={props.vin8}
            vehicleDetails={props.vehicleDetails} />
    </div>;
}

function mapStateToProps(state, props) {
    return {
        ...OdometerViewSelector(state, props),
        genericErrorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage']),
        odometerUpdateErrorMessage: state.appState.getIn(['uiData', 'odometerView', 'errorMessage']),
        selectedLocale: state.appState.getIn(['uiData', 'shared', 'selectedLocale']),
        paymentType: state.appState.getIn(['serverData', 'shared', 'paymentType']),
        fieldValidations: emptyMapIfUndefined(state.appState.getIn(['uiData', 'shared', 'fieldValidations']))
    };
}

export default connect(mapStateToProps)(OdometerView);