/**
 * Created by prboyapa on 2/26/2016.
 */
import React from 'react';
import {defineMessages, FormattedMessage, FormattedNumber} from 'react-intl';
import {Map} from 'immutable';
import classnames from 'classnames';
import {LABOR, LINE_ITEM_STATUS} from 'constants/App';
import './AddLineItemsLink.css';

class AddLineItemsLink extends React.Component {

    onAddLineItemsClickHandler(complaintCode, event) {
        this.props.onAddLineItemsClick(complaintCode);
    }

    onAddLineItemsMaintenanceClickHandler(complaintCode, event) {
        this.props.onAddLineItemsMaintenanceClick(complaintCode);
    }

    render() {
        const messages = defineMessages({
            add_line_items_for_complaint: {
                id: 'add_line_items_for_complaint',
                description: 'Add line items for {complaintType}',
                defaultMessage: 'Add line items for {complaintType}'
            },
            add_line_items_for_services: {
                id: 'add_line_items_for_services',
                description: 'Add line items for Services due',
                defaultMessage: 'Add line items for Services due'
            },
            add_line_items_for_maintenance: {
                id: 'add_line_items_for_maintenance',
                description: 'Add line items for Maintenance',
                defaultMessage: 'Add line items for Maintenance'
            },
        });
        return <div className='add_line_items_link'>
            <table className="container add_line_items_link__table">
                <tbody>
                {
                 this.props.hideAddLineItemsForServices? false:   
                 <tr className="flex-row">
                    <td className="col add_line_items_link__add_new">

                    </td>
                    <td colSpan="4" className={classnames('col add_line_items_link__add_new',
                    !this.props.anyRemainingAtaCodesToBeAdded? 'add_line_items_link__add_new__disabled':false
                    )}>

                        <a onClick={this.onAddLineItemsClickHandler.bind(this, this.props.complaintCode) }>
                            {
                                /*  Show Add line items link to below to the Pending line items*/
                                this.props.isServiceComplaint?
                                    <FormattedMessage {...messages.add_line_items_for_services}/>
                                    : <FormattedMessage values={{complaintType: <FormattedMessage
                                                            id={'complaint_code_' + this.props.complaintCode}
                                                            description={this.props.complaintType}
                                                            defaultMessage={this.props.complaintType}/>}}
                                                            {...messages.add_line_items_for_complaint}/>
                            }
                            {'  '} <img src="/assets/VMS_28button_add_blue.png"/>

                        </a>
                    </td>
                </tr>
                }

                {
                    /*  Show Add line items link to below to the Pending line items*/
                    this.props.isServiceComplaint ?

                        <tr className="row">
                            <td className="col add_line_items_link__add_new">
                            </td>
                            <td colSpan="4" className="col add_line_items_link__add_new">
                                <a onClick={this.onAddLineItemsMaintenanceClickHandler.bind(this, this.props.complaintCode) }>
                                    {
                                        <FormattedMessage {...messages.add_line_items_for_maintenance}/>
                                    }
                                    {'  '} <img src="/assets/VMS_28button_add_blue.png"/>

                                </a>
                            </td>
                        </tr>
                        : undefined
                }

                </tbody>
            </table>
        </div>
    }
}

export default AddLineItemsLink;