import { call, put, select } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';

export function* fetchingDealerDraftDealerRequests() {

    try {

        const state = yield select();

        yield put({ type: 'FETCH_DEALER_DEALER_REQUESTS_REQUESTED' });

        const dealerRequests = yield call(Api.fetchDealerDraftDealerRequests, state, helperFunctions.getToken(state));

        yield put({ type: 'FETCH_DEALER_DEALER_REQUESTS_ACCEPTED', dealerRequests });

    }
    catch (e) {

        yield put({
            type: 'FETCH_DEALER_DEALER_REQUESTS_REJECTED',
            errorCode: e.code ? e.code : '',
            errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE
        });

    }
}
