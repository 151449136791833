import React, { useEffect, useRef, useState } from 'react';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage,injectIntl,RawIntlProvider} from 'react-intl';
import classNames from 'classnames';
import {Overlay, Popover} from 'react-bootstrap';
import './TaxInformationClosedPO.css';
import {LINE_ITEM_TYPE, INVOICE_FIELD_TYPE} from 'constants/App';
import moment from 'moment';
import ReactDOM from 'react-dom';
import * as helperFunctions from 'utils/HelperFunctions';
import {NumericFormat} from 'react-number-format';

const TaxInformationClosedPO = (props) => {
    const taxExe = useRef();
    const txtTax = useRef();
    const txtHST = useRef();
    const txtGST = useRef();
    const txtQST = useRef();
    const txtPST = useRef();

    moment.locale(props.userLocale);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        var applicationTaxType = props.applicableTaxTypes !== undefined ? props.applicableTaxTypes.map((tax)=> {return tax.toArray().toString().substring(0,3)}).toArray().toString() : "";

        props.onInputChangeHandler(INVOICE_FIELD_TYPE.IS_TAX_EXEMPTED, applicationTaxType === "" ? true : false)
    
    }, []);

        const messages = defineMessages({
            tax_exempted: {
                id: 'tax_exempted',
                description: 'Tax Exempted ?',
                defaultMessage: 'Tax Exempted ?'
            },
            enter_tax: {
                id: 'enter_tax',
                description: 'Enter tax',
                defaultMessage: 'Enter tax'
            },
            tax_exempted_yes: {
                id: 'tax_exempted_yes',
                description: 'Yes',
                defaultMessage: 'Yes'
            },
            tax_exempted_no: {
                id: 'tax_exempted_no',
                description: 'No',
                defaultMessage: 'No'
            },
            tax_gst: {
                id: 'tax_gst',
                description: 'GST',
                defaultMessage: 'GST'
            },
            tax_pst: {
                id: 'tax_pst',
                description: 'PST',
                defaultMessage: 'PST'
            },
            tax_hst: {
                id: 'tax_hst',
                description: 'HST',
                defaultMessage: 'HST'
            },
            tax_qst: {
                id: 'tax_qst',
                description: 'QST',
                defaultMessage: 'QST'
            },
            no_tax_reason: {
                id: 'no_tax_reason',
                description: 'No-Tax reason',
                defaultMessage: 'No-Tax reason'
            },
            no_tax_reason_repair_type: {
                id: 'no_tax_reason_repair_type',
                description: 'Repair type is tax exempt',
                defaultMessage: 'Repair type is tax exempt'
            },
            no_tax_reason_interstate: {
                id: 'no_tax_reason_interstate',
                description: 'Interstate commerce vehicle exempt',
                defaultMessage: 'Interstate commerce vehicle exempt'
            },
            error_invoice_tax: {
                id: 'error_invoice_tax',
                description: 'Please enter tax.',
                defaultMessage: 'Please enter tax.'
            },
            error_tax_amount:{
                id:'error_tax_amount',
                description:'tax amount exceeds 15% of subtotal',
                defaultMessage:'PO exceeds the tax threshold, Please call the call center at 1-866-274-1025'

            },
            error_tax_exempt: {
                id: 'error_tax_exempt',
                description: 'client is tax exempt',
                defaultMessage: 'This client is tax exempt'

            },error_invoice_tax_canada: {
                id: 'error_invoice_tax_canada',
                description: 'error_invoice_tax_canada',
                defaultMessage: 'Tax only applicable for {applicationTaxType} please correct.'
            }
        });
      var applicationTaxType =props.applicableTaxTypes !== undefined ? props.applicableTaxTypes.map((tax)=> {return tax.toArray().toString().substring(0,3)}).toArray().toString() : "";

        return <div className="row">
            <div className="close-po__tax-group-control">
                <div className="close-po_tax_exempted__align">
                    <div className="close-po__tax-group">
                        <FormattedMessage {...messages.tax_exempted}/>
                    </div>
                    <Overlay
                        show={props.popOverDisplayShowTaxExe}
                        onHide={() => props.onInputBlurHandler("tax")}
                        placement="left"
                        target={taxExe.current}

                    >
                        <Popover  id="error_popover_invoice_tax"
                                  className="close-po__error_message">
                                        <RawIntlProvider value={props.intl}>
                            <FormattedMessage {...messages.error_tax_exempt} />
                            </RawIntlProvider>
                        </Popover>

                    </Overlay>
                    <div className="close-po__radio" tabIndex="7" ref={taxExe}
                         onClick={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.IS_TAX_EXEMPTED, false, e)}

                    >
                        <img src={props.invoiceDetails.get('isTaxExempted') == false
                            ? "/assets/VMS_checked.png"
                            : "/assets/VMS_unchecked.png"}
                        />
                        {' '} <FormattedMessage {...messages.tax_exempted_no}/>

                    </div>
                    <div className="close-po__radio" tabIndex="8"
                         onClick={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.IS_TAX_EXEMPTED, true, e)}>
                        <img src={props.invoiceDetails.get('isTaxExempted') == true || applicationTaxType === ""
                            ? "/assets/VMS_checked.png"
                            : "/assets/VMS_unchecked.png"}
                        />
                        {' '} <FormattedMessage {...messages.tax_exempted_yes}/>

                    </div>
                </div>
            </div>
            <div className="close-po_tax_enter__align">
                {
                    props.invoiceDetails.get('isTaxExempted') == false ?
                        props.invoiceDetails.get('canShowMultipleTaxTypes') == false ?

                                    <div
                                        className={classNames('close-po_tax_exempted__align', {'has-error': showError && !props.validationDetails.get('taxAmount')})}>
                                        <Overlay
                                            show={props.popOverDisplayShowTax}
                                            onHide={() => props.onInputBlurHandler("tax")}
                                            placement="bottom"
                                            target={txtTax.current}

                                        >
                                            <Popover  id="error_popover_invoice_tax"
                                                className="close-po__error_message">
                                                {props.taxErrorMessage}
                                            </Popover>

                                        </Overlay>
                                        <div className="close-po__no-tax-group__format">
                                            <FormattedMessage {...messages.enter_tax}/>
                                            </div>                                       

                                      
                            <NumericFormat 
                                className={classNames("close-po__input close-po__input-tax", 'tax-control')}
                                data-taxType={LINE_ITEM_TYPE.TAX}
                                getInputRef={txtTax}
                                tabIndex="9"
                                onChange={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.TAX_AMOUNT, null, e)}
                                onBlur={() => props.onInputBlurHandler("tax")}
                                decimalSeparator={'.'} 
                                inputMode="numeric"
                                decimalScale="2"
                                defaultValue={props.uiTaxes.get('tax') !== undefined ? props.uiTaxes.get('tax').toString() : 0}
                        disabled={applicationTaxType === 'Y'}
                           />
                            
                                    </div>

                            :
                                <div>
                                    <div className="close-po__no-tax-group">
                                        <FormattedMessage {...messages.enter_tax}/>
                                    </div>
                                    <div className="close-po__canadian-control">
                                            <div
                                            className={classNames({"has-error": showError && props.validationDetails.get('canadianTaxes') == false})}>
                                            <div className="close-po__tax_field-header">
                                                <FormattedMessage {...messages.tax_hst}/>
                                                {' '}
                                            </div>
                                            <Overlay
                                                show={props.popOverDisplayShowTaxCanada}
                                                onHide={() => props.onInputBlurHandler("taxCanada")}
                                                placement="right"
                                                target={txtPST.current}
                                            >
                                                <Popover  id="error_popover_invoice_tax2"
                                                    className="close-po__error_message">
                                                        <RawIntlProvider value={props.intl}>
                                                    <FormattedMessage {...messages.error_invoice_tax_canada} values={{applicationTaxType : applicationTaxType}} />
                                                    </RawIntlProvider>
                                                </Popover>

                                            </Overlay>



                                            
                                        <NumericFormat 
                                            className={classNames("close-po__input-tax", 'tax-control')}
                                            data-taxType="HST"
                                            getInputRef={txtHST}
                                            tabIndex="10"
                                            onChange={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.TAX_AMOUNT, null, e)}
                                            onBlur={() => props.onInputBlurHandler("taxCanada")}
                                            defaultValue={props.uiTaxes.get('HST') !== undefined   ? (props.uiTaxes.get('HST').toString()) : 0}
                                            disabled={!applicationTaxType.includes("HST") && (props.uiTaxes.get('HST') !== undefined ? Number(props.uiTaxes.get('HST').toString()) === 0 : true)}
                                            decimalSeparator={'.'} 
                                            inputMode="numeric"
                                            decimalScale="2"
                                        />

                                        </div>

                                        <div
                                            className={classNames({"has-error": showError && props.validationDetails.get('canadianTaxes') == false})}>
                                            <div className="close-po__tax_field-header">
                                                <FormattedMessage {...messages.tax_gst} />
                                                {' '}
                                            </div>

                                        <NumericFormat 
                                            className={classNames("close-po__input-tax", 'tax-control')}
                                            data-taxType="GST" getInputRef={txtGST}
                                            tabIndex="11"
                                            onChange={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.TAX_AMOUNT, null, e)}
                                            onBlur={() => props.onInputBlurHandler("taxCanada")}
                                            defaultValue={props.uiTaxes.get('GST') !== undefined   ? (props.uiTaxes.get('GST').toString().toString()) : 0}
                                            disabled={!applicationTaxType.includes("GST") && (props.uiTaxes.get('GST') !== undefined ? Number(props.uiTaxes.get('GST').toString()) === 0:true) }
                                            decimalSeparator={'.'} 
                                            inputMode="numeric"
                                            decimalScale="2"
                                        />
                                        </div>
                                    </div>

                                    <div className="close-po__canadian-control">
                                        <div
                                            className={classNames({"has-error": showError && props.validationDetails.get('canadianTaxes') == false})}>
                                            <div className="close-po__tax_field-header">
                                                <FormattedMessage {...messages.tax_pst}/>
                                                {' '}
                                            </div>

                                            <NumericFormat 
                                            className={classNames("close-po__input-tax", 'tax-control')}
                                            data-taxType="PST" getInputRef={txtPST}
                                            tabIndex="12"
                                            onChange={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.TAX_AMOUNT, null, e)}
                                            onBlur={() => props.onInputBlurHandler("taxCanada")}
                                            defaultValue={props.uiTaxes.get('PST') !== undefined  ? (props.uiTaxes.get('PST').toString().toString()) : 0}
                                            disabled={!applicationTaxType.includes("PST") && (props.uiTaxes.get('PST') !== undefined ? Number(props.uiTaxes.get('PST').toString()) === 0 : true)}
                                            decimalSeparator={'.'} 
                                            inputMode="numeric"
                                            decimalScale="2"
                                        />                                           
                                        </div>

                                        <div
                                            className={classNames({"has-error": showError && props.validationDetails.get('canadianTaxes') == false})}>
                                            <div className="close-po__tax_field-header">
                                                <FormattedMessage {...messages.tax_qst}/>
                                                {' '}
                                            </div>

                                            <NumericFormat 
                                            className={classNames("close-po__input-tax", 'tax-control')}
                                            data-taxType="QST" getInputRef={txtQST}
                                            tabIndex="13"
                                            onChange={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.TAX_AMOUNT, null, e)}
                                            onBlur={() => props.onInputBlurHandler("taxCanada")}
                                            defaultValue={props.uiTaxes.get('QST') !== undefined  ? (props.uiTaxes.get('QST').toString().toString()) : 0}
                                            disabled={!applicationTaxType.includes("QST") &&  (props.uiTaxes.get('QST') !== undefined ?Number(props.uiTaxes.get('QST').toString().toString()) === 0 : true)}
                                            decimalSeparator={'.'} 
                                            inputMode="numeric"
                                            decimalScale="2"
                                        />              
                                        </div>
                                    </div>
                                </div>

                        :
                        <div className="close-po__no-tax">

                            <div className="close-po__tax-group-no">
                                <FormattedMessage {...messages.no_tax_reason}/>
                            </div>

                            <div
                                className={classNames('close-po__radio', {'has-error': showError && !props.validationDetails.get('exemptedReason')})}
                                onClick={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.EXEMPTED_REASON, "TaxExempt", e)}>
                                <img
                                    src={props.invoiceDetails.get('exemptedReason') == "TaxExempt"
                                        ? "/assets/VMS_checked.png"
                                        : "/assets/VMS_unchecked.png"}
                                />
                                {' '}
                                <FormattedMessage {...messages.no_tax_reason_repair_type}/>
                            </div>
                            <div
                                className={classNames('close-po__radio', {'has-error': showError && !props.validationDetails.get('exemptedReason')})}
                                onClick={(e) => props.onInputChangeHandler(INVOICE_FIELD_TYPE.EXEMPTED_REASON, "VehicleExempt", e)}>
                                <img
                                    src={props.invoiceDetails.get('exemptedReason') == "VehicleExempt"
                                        ? "/assets/VMS_checked.png"
                                        : "/assets/VMS_unchecked.png"}
                                />
                                {' '}
                                <FormattedMessage {...messages.no_tax_reason_interstate}/>
                            </div>
                        </div>
                }
            </div>
        </div>;
}


export default injectIntl(TaxInformationClosedPO);
