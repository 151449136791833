import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import './Services.css';
import {SERVICE_STATUS} from 'constants/App';
import {Input,Image} from 'react-bootstrap'
import * as ComplaintViewActions from '../actions/ComplaintView';

class Services extends React.Component {

    toggleSelection(serviceId, isSelected, e) {
        e.target.src = isSelected ? "/assets/VMS_checked.png" : "/assets/VMS_unchecked.png";
        this.props.onSelectService(serviceId);
    }

    getEmptyCaption() {
        const messages = defineMessages({
                add_no_services_caption: {
                    id: 'add_no_services_caption',
                    description: 'This vehicle has no services due',
                    defaultMessage: 'This vehicle has no services due'
                },
            }
        )
        return <div >
            <div className="row services__caption">
                <div className="col-md-10">
                    <FormattedMessage {...messages.add_no_services_caption}/>
                </div>
            </div>
        </div>
    }

    getCaption() {
        const messages = defineMessages({
                add_services_caption: {
                    id: 'add_services_caption',
                    description: 'This vehicle has services due',
                    defaultMessage: 'This vehicle has services due'
                },
                add_services_caption_note: {
                    id: 'add_services_caption_note',
                    description: 'ADD TO PURCHASE ORDER',
                    defaultMessage: 'SELECT TO ADD TO PURCHASE ORDER'
                },
                add_services_using_product_code_caption_note: {
                    id: 'add_services_using_product_code_caption_note',
                    description: 'Please add the following line(s) to the purchase order using appropriate product code',
                    defaultMessage: 'Please add the following line(s) to the purchase order using appropriate product code'
                }
            }
        )
        return <div >
            <div className="row services__caption">
                <div className="col-md-11">
                    <FormattedMessage {...messages.add_services_caption}/>
                </div>
            </div>
            <div className="services__caption-note">
                {this.props.isReadOnly ? <FormattedMessage {...messages.add_services_using_product_code_caption_note} /> : <FormattedMessage {...messages.add_services_caption_note} />}
            </div>
        </div>
    }

    getHeaders() {
        const messages = defineMessages({
            schedule_header: {
                id: 'schedule_header',
                description: 'Schedule',
                defaultMessage: 'Schedule'
            },
            due_header: {
                id: 'due_header',
                description: 'Due',
                defaultMessage: 'Due'
            }
        });

        return <tr className="services__list-header">
            <td className="services__inline-block services__list-toggle"></td>
            <td >
                <table className="services__container">
                    <tbody>
                    <tr>
                        <td className="services__inline-block services__list-name"></td>
                        <td className="services__inline-block services__list-schedule">
                            <FormattedMessage {...messages.schedule_header} />
                        </td>
                        <td className="services__inline-block services__list-due">
                            <FormattedMessage {...messages.due_header} />
                        </td>
                    </tr>
                    </tbody>

                </table>
            </td>
        </tr>
    }

    getRows() {
        return this.props.services.valueSeq().map((k) =>
            <tr key={k.get('serviceId')} className="services__list">
               
                <td>
                {!this.props.isReadOnly ?
                    <table>
                        <tbody>
                        <tr>
                            <td>
                                <img className="services__list_toggle"
                                     src={k.get('isSelected')?"/assets/VMS_checked.png":"/assets/VMS_unchecked.png"}
                                     onClick={this.toggleSelection.bind(this,k.get('serviceId'),k.get('isSelected'))}/>
                            </td>
                        </tr>
                        </tbody>
                    </table> : undefined }
                </td>
                <td >
                    <div className={classnames('services__list-row-item',
                                               {'services__list_overdue':k.get('serviceStatus')==SERVICE_STATUS.OVERDUE || k.get('serviceStatus')==SERVICE_STATUS.DUE}
                                    )}>
                        <table className="services__container">
                            <tbody>
                            <tr>
                                <td className="services__inline-block services__list-name">
                                    {k.get('serviceName')}
                                </td>
                                <td className="services__inline-block services__list-schedule">
                                    {k.get('serviceSchedule')}
                                </td>
                                <td className="services__inline-block services__list-due">
                                    {k.get('serviceOverDueBy')}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </td>

            </tr>
        )
    }

    render() {

        if (this.props.services.size <= 0) {
            return <div className="container-fluid services">{this.getEmptyCaption()}</div>;
        }

        return (
            <div className="container-fluid services">
                {this.getCaption()}
                <table className="services__container">
                    <thead>
                    {this.getHeaders()}
                    </thead>
                    <tbody>
                    {this.getRows()}
                    </tbody>
                </table>
                <hr className="service__complaints-hr"/>
            </div>
        )
    }
}


export default Services;