/**
 * Created by prboyapa on 2/8/2016.
 */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { defineMessages, FormattedMessage, FormattedNumber, FormattedDate, FormattedTime } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import './EstimatedCompletionDate.css';
import InputCalendar from 'components/InputCalendar';

const EstimatedCompletionDate = ({ 
    estimatedCompletionDate,
    actionType,
    userLocale,
    onDateChangeEvent,
    purchaseOrder,
    isEstimateDateValid,
    onUpdateEstimatedCompletionDate
}) =>
{
        //const locale = window.navigator.userLanguage || window.navigator.language;
        moment.locale(userLocale);

        const inputFormat = "MM/DD/YYYY";
        const mode = "date";
        const [selectedTime, setSelectedTime] = useState(undefined);

    const validateTime = (estimatedDate, from, to) => {
        return (moment(estimatedDate).hours() >= from && moment(estimatedDate).hours() < to);
    }

    const onDateChangeHandler = (newDate) => {

        if (newDate !== undefined) {

            if (moment(newDate).isSameOrAfter(moment().local())) {
                onDateChangeEvent(newDate);
                onUpdateEstimatedCompletionDate(true);
                isEstimateDateValid = true 


            } else {
                onDateChangeEvent(new Date(moment(estimatedCompletionDate)));
                onUpdateEstimatedCompletionDate(true);
                isEstimateDateValid = true 
            }
        }

    }

    const onTimeChangeHandler = (newTime) => {
        setSelectedTime(newTime);
        updateEstimatedDateTime(newTime, estimatedCompletionDate);
    }

    const updateEstimatedDateTime = (hours, newDate) => {

        if (newDate != undefined && newDate.isValid()) {

            let estimatedDateTime;

            if (newDate == undefined || !newDate.isValid()) {
                estimatedDateTime = moment(0, "HH").add(hours == undefined ? 8 : hours, 'hours')
            }
            else {
                estimatedDateTime = moment(newDate).local().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).add(hours == undefined ? 8 : hours, 'hours')
            }

            estimatedDateTime = moment(estimatedDateTime).local();
            onDateChangeEvent(estimatedDateTime);
            onUpdateEstimatedCompletionDate(true);
            isEstimateDateValid = true;

        } else {
            onUpdateEstimatedCompletionDate(false);
            onDateChangeEvent(undefined);
            isEstimateDateValid = false;
        }

    }

    const messages = defineMessages({
            estimated_completion_description: {
                id: 'estimated_completion_description',
                description: 'Estimated completion for all repairs and services',
                defaultMessage: 'Estimated completion for all repairs and services'
            },
            estimated_completion_morning: {
                id: 'estimated_completion_morning',
                description: 'Morning',
                defaultMessage: 'Morning'
            },
            estimated_completion_afternoon: {
                id: 'estimated_completion_afternoon',
                description: 'Afternoon',
                defaultMessage: 'Afternoon'
            },
            estimated_completion_evening: {
                id: 'estimated_completion_evening',
                description: 'Evening',
                defaultMessage: 'Evening'
            },
            estimated_completion_unavailable: {
                id: 'estimated_completion_unavailable',
                description: 'Unavailable',
                defaultMessage: 'Unavailable'
            }
        });

        return (
        <div className={classNames('estimated_completion',
            { 'estimated_completion__edit': actionType == 'ADD' })}>
            <table className="container">
                <tbody>
                    {
                    /*Show or hide editable date control based on user action */                    <tr className="row">

                            <td className="col-md-1">
                            </td>
                            <td colSpan="3" className={classNames('col-md-10',
                                'estimated_completion__box',
                                { "estimated_completion_date_edit__alert": !isEstimateDateValid })}>
                                <div className='row' css={`min-width: 30em;`}>
                                    <div className="col-md-12 estimated_completion__description">
                                        <div className='row'>
                                            <div className="col-md-6 estimated_completion__label">
                                                <FormattedMessage {...messages.estimated_completion_description} />
                                            </div>

                                            <div className={classNames('col-md-6',
                                                { 'estimated_completion__date': actionType == 'VIEW' },
                                                { 'estimated_completion__date_edit': actionType != 'VIEW' })}>
                                                <div className='row'>
                                                    <div className="col-md-12">
                                                        {actionType == 'VIEW' ?


                                                            purchaseOrder.get('estimatedCompletionDate') != undefined &&
                                                                moment(purchaseOrder.get('estimatedCompletionDate')).isValid()
                                                                ? moment(purchaseOrder.get('estimatedCompletionDate')).format(inputFormat)
                                                                : <FormattedMessage {...messages.estimated_completion_unavailable} />
                                                            :
                                                            <div className='row'>
                                                                <InputCalendar
                                                                inputProps={{ className: 'estimated_completion_date__date_picker-input' }}
                                                                onDateChange={onDateChangeHandler}
                                                                modifiers={{disabled: {
                                                                    before: new Date(moment()),
                                                                }}}
                                                                locale={userLocale}
                                                                initialValue={estimatedCompletionDate !== undefined ? new Date(moment(estimatedCompletionDate)) : undefined}
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row estimated_completion_time'>
                                                    <div className={classNames({ 'col-md-3': userLocale === 'fr-CA' },
                                                        { 'col-md-4': userLocale === 'en-US' || userLocale === 'es-MX' })}>
                                                        <a className={classNames('btn btn-default',
                                                            { 'btn-default_selected': validateTime(purchaseOrder.get('estimatedCompletionDate'), 0, 12) == true },
                                                            { 'disabled': actionType == 'VIEW' })}
                                                            role="button"
                                                            onClick={onTimeChangeHandler.bind(this, 8)}>
                                                            <FormattedMessage {...messages.estimated_completion_morning} /></a>
                                                    </div>
                                                    <div className={classNames({ "col-md-4": userLocale === 'es-MX' },
                                                        { "col-md-4 estimated_completion_date__afternoon": userLocale !== 'es-MX' })}
                                                    >
                                                        <a className={classNames('btn btn-default',
                                                            { 'btn-default_selected': validateTime(purchaseOrder.get('estimatedCompletionDate'), 12, 18) == true },
                                                            { 'disabled': actionType == 'VIEW' }
                                                        )} role="button"
                                                            onClick={onTimeChangeHandler.bind(this, 12)}>
                                                            <FormattedMessage {...messages.estimated_completion_afternoon} /></a>
                                                    </div>
                                                    <div className={classNames({ "col-md-4": userLocale !== 'es-MX' },
                                                        { "col-md-4 estimated_completion_date__afternoon": userLocale === 'es-MX' })}>
                                                        <a className={classNames('btn btn-default',
                                                            { 'btn-default_selected': validateTime(purchaseOrder.get('estimatedCompletionDate'), 18, 24) == true },
                                                            { 'disabled': actionType == 'VIEW' })} role="button"
                                                            onClick={onTimeChangeHandler.bind(this, 18)}>
                                                            <FormattedMessage {...messages.estimated_completion_evening} /></a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="col-md-1">
                            </td>
                        </tr>
                    }

                </tbody>
            </table>
        </div>
    )
}



export default EstimatedCompletionDate;