import { format, isValid, parse } from "date-fns";
import FocusTrap from "focus-trap-react";
import moment from "moment";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { DayPicker, useInput } from "react-day-picker";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { formatDate } from "../utils/MomentLocaleUtils";
import { enUS } from "date-fns/locale";

const PopperDiv = styled.div`
background-color: white;
z-index: 999;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
`;

const InputCalendar = forwardRef((props, ref) => {
    //Initialize props for Calendar and associated input
    const { inputProps, dayPickerProps, setSelected } = useInput({
        format: 'P',
        defaultSelected: isValid(props.initialValue) ? new Date(moment(props.initialValue)) : undefined
    });
    //Date input display value
    const [inputValue, setInputValue] = useState(isValid(props.initialValue) ? format(props.initialValue, 'P', {locale: _locale}) : '');
    //Input ref for popper
    const popperRef = useRef(null);
    //Element for popper to display
    const [popperElement, setPopperElement] = useState(null);
    //State controlling popper visibility
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [_locale, setLocale] = useState(enUS);

    //Create popper
    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'top-start'
    });

    useEffect(() => {
        var locale = props.locale
        if(locale == 'es-MX'){
            locale = 'es';
        }

        const importLocale = async () => {
            const localeToSet = await import(
                'date-fns/locale/'+locale+'/index.js'
            );
            setLocale(localeToSet);
        }

        if(_locale.code !== locale){
            importLocale();
        }
    }, [props.locale]);

    const onFocus = () => {
        setIsPopperOpen(true);
    }

    const onBlur = () => {
        setIsPopperOpen(false);
    }

    //Handle Calendar combo box input change
    const handleInputChange = (e) => {
        let before = props.modifiers.disabled?.before;
        let after = props.modifiers.disabled?.after;

        setInputValue(e.currentTarget.value);
        const date = parse(e.currentTarget.value, 'P', new Date());
        if (isValid(date) && (before == undefined || date >= before) && (after == undefined || date <= after)) {
            setSelected(date);
            props.onDateChange(date);
        } else {
            setSelected(undefined);
            props.onDateChange(undefined);
        }
    }

    const handleDaySelect = (day) => {
        setSelected(day);
        setInputValue(format(day, 'P', {locale: _locale}));
        props.onDateChange(day);
        onBlur();
    }

    return (
        <div id={props.id}>
            <div ref={popperRef}>
                <input {...inputProps} {...props.inputProps}
                    onFocus={onFocus}
                    onChange={(e) => {
                        handleInputChange(e);
                    }}
                    value={inputValue}
                    placeholder="MM/DD/YYYY"
                    ref={ref}
                    id={props.id + "_input"}
                />
            </div>
            {isPopperOpen ?
                //Focus trap to handle focus
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: onBlur,
                        returnFocusOnDeactivate: false
                    }}>
                    <PopperDiv tabIndex={-1}
                        style={popper.styles.popper}
                        className={'input_popper_calendar'}
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <DayPicker {...dayPickerProps}
                            mode='single'
                            onSelect={(day) => {
                               handleDaySelect(day);
                            }}
                            fixedWeeks
                            modifiers={props.modifiers}
                            locale={_locale}
                        />
                    </PopperDiv>
                </FocusTrap>
                : null}

        </div>
    )
})

export default InputCalendar