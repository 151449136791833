import React from 'react';
import './ARIUserInvalidTokenView.css';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as constants from 'constants/App';
import { connect } from 'react-redux';
import TranslationIcon from 'components/SiteTranslations';

class ARIUserInvalidTokenView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const messages = defineMessages({
            invalid_token_title: {
                id: 'invalid_token_title',
                description: 'invalid token title',
                defaultMessage: 'Holman User'
            },
            invalid_token_subtext: {
                id: 'invalid_token_subtext',
                description: 'invalid token subtext',
                defaultMessage: 'Holman User token is invalid.'
            },
            invalid_token_subtext_2: {
                id: 'invalid_token_subtext_2',
                description: 'invalid token subtext 2',
                defaultMessage: 'Please contact HPCITsupport@holman.com for assistance.'
            }
        });

        return <div className="invalid-token-component">
            <div className="invalid_token_help_container row">
                <span>
                    <TranslationIcon backgroundColor='blue' />
                </span>
            </div>
            <div className="invalid-token__header">
                <span>
                    <FormattedMessage {...messages.invalid_token_title} />
                </span>
            </div>
            <div className="invalid-token__body row">
                <div className="invalid-token__message">
                <div className="row">
                        <span>
                            <FormattedMessage  {...messages.invalid_token_subtext} />
                        </span>
                    </div>
                    <div className="row">
                        <span>
                            <FormattedMessage  {...messages.invalid_token_subtext_2} />
                        </span>
                    </div>
                </div>
            </div>            
            <div className="invalid-token__footer">               
            </div>
        </div>;
    }
}

function select(state) {
    return {};
}

export default connect(select)(ARIUserInvalidTokenView);