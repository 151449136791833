import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import VehicleInformation from 'components/VehicleInformation';
import './OdometerVehicleInformation.css';

class OdometerVehicleInformation extends React.Component {

    render() {
        return <div className="container-fluid odometer_vehicle_info_box">
            <VehicleInformation vehicleDetails={this.props.vehicleDetails}
                                client={this.props.client}
                                />

        </div>;
    }
}

export default OdometerVehicleInformation;
