import React from 'react';
import { connect } from 'react-redux';
import * as VendorEnrollmentViewActions from '../actions/VendorEnrollmentView';
import * as constants from 'constants/App';
import FormWrapper from 'components/FormWrapper';
import { renderError } from 'utils/HelperFunctions';
import { defineMessages, FormattedMessage } from 'react-intl';
import './EnrollmentConfirmation.css';
import { Link } from 'react-router-dom';


class EnrollmentConfirmation extends React.Component {

    onReturnClickHandler(e) {
        e.preventDefault();
        window.history.back();
    }

    confirmationHeader() {
        const messages = defineMessages({
            thank_you: {
                id: 'thank_you',
                description: 'Thank you',
                defaultMessage: 'Thank you'
            },
            return: {
                id: 'return',
                description: 'Return to main page',
                defaultMessage: 'Return to main page'
            },
        })

        return <FormattedMessage {...messages.thank_you } />
    }
    confirmationMessage() {
        const messages = defineMessages({
            confirmation_for_detail_submission: {
                id: 'confirmation_for_detail_submission',
                description: 'Confirmation For Detail Submission',
                defaultMessage: 'Your enrollment request number is {requestId}.<br/><br/> Your enrollment request has been submitted for processing. You will receive an email with your enrollment status' +
                ' within three to five business days. <br/><br/> If you are approved, you will be instructed via email to complete the registration' +
                ' process ASAP by clicking on the hyperlink to return to the Suppliers website and provide your checking account information.' +
                '<br/><br/> Upon approval, a permanent Holman Partner ID will be assigned to you. Use your temporary' +
                ' enrollment request number for any correspondence with Holman. <br/><br/> PLEASE DIRECT ANY ENROLLMENT INQUIRIES, OR REQUESTED DOCUMENTS TO' + 
                '<a href="mailto:enrollments@holman.com">enrollments@holman.com</a> <br/><br/> Thank you for submitting your enrollment request.'
            },
        })

    return <FormattedMessage {...messages.confirmation_for_detail_submission } values={{requestId: this.props.requestId ,br: data => <br/> , a: data=> <a href="mailto:enrollments@holman.com">{data}</a>,b: data => <b>{data}</b>}} />
    }

    render() {
        const messages = defineMessages({
            return: {
                id: 'return:',
                description: 'Return',
                defaultMessage: 'Return'
            },
        })
        return (
            <div className="enrollment-confirmation">
                <div className='enrollment-confirmation__header'>{this.confirmationHeader()}</div>
                <div className='enrollment-confirmation__message'>{this.confirmationMessage()}</div>
                <div className='row'>
                    <div className="enrollment-confirmation__return"
                         role="button"
                         onClick={this.onReturnClickHandler.bind(this)}>
                        <img src="/assets/VMS_33button_back_blue.png" />
                        {' '}
                        <FormattedMessage {...messages.return} />
                    </div>
                </div>
            </div>
        );
    }
};
export default EnrollmentConfirmation;