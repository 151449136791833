import React from 'react';
import ReactDOM from 'react-dom';
import {Overlay, Popover} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {defineMessages, FormattedMessage,injectIntl,RawIntlProvider} from 'react-intl';
import './ChangePassword.css';
import classNames from 'classnames';
import PasswordPolicy from 'shared/components/PasswordPolicy';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
    }

    onTextChange(e){
        e.preventDefault();

        if(e.target.id=='txtAriID')
        this.props.onTextChangeHandler('ARIID',e.target.value,);
        else if(e.target.id=='txtCurrentPassword')
            this.props.onTextChangeHandler('CURRENT_PWD',e.target.value,);
        else if(e.target.id=='txtNewPassword')
            this.props.onTextChangeHandler('NEW_PWD',e.target.value,);
        else if(e.target.id=='txtConfirmNewPassword')
            this.props.onTextChangeHandler('CONFIRM_NEW_PWD',e.target.value,);
    }

    onSaveClick(e){
        e.preventDefault();
       this.props.onSavePasswordClickHandler(this.refs.txtAriID.value, this.refs.txtCurrentPassword.value,this.refs.txtNewPassword.value,this.refs.txtConfirmNewPassword.value);

    }



    render() {
        const messages = defineMessages({
            changePassword_view_caption: {
                id: 'changePassword_view_caption',
                description: 'Password',
                defaultMessage: 'Password'
            },
            changePassword_change_password: {
                id: 'changePassword_change_password',
                description: 'Change Password',
                defaultMessage: 'Change Password'
            },
            changePassword_save_password: {
                id: 'changePassword_save_password',
                description: 'Save Password',
                defaultMessage: 'Save Password'
            },
            change_password_ari_id:{
                id:'change_password_ari_id',
                description:'ID issued by Holman',
                defaultMessage:'ID issued by Holman'
            },
            change_password_ari_id_mex:{
                id:'change_password_ari_id_mex',
                description:'ID issued by ARIZA',
                defaultMessage:'ID issued by ARIZA'
            },
            change_password_current:{
                id:'change_password_current',
                description:'Current',
                defaultMessage:'Current'
            },
            change_password_new:{
                id:'change_password_new',
                description:'New',
                defaultMessage:'New'
            },
            change_password_confirm_new:{
                id:'change_password_confirm_new',
                description:'Confirm new',
                defaultMessage:'Confirm new'
            },
            change_password_ariId_error_mesg:{
                id:'change_password_ariId_error_mesg',
                description:'Please enter ID issued by Holman.',
                defaultMessage:'Please enter ID issued by Holman.'
            },
            change_password_ariId_error_mesg1:{
                id:'change_password_ariId_error_mesg1',
                description:'ID issued by Holman is incorrect.',
                defaultMessage:'ID issued by Holman is incorrect.'
            },
            change_password_new_pwd_error_mesg:{
                id:'change_password_new_pwd_error_mesg',
                description:'Please enter new password.',
                defaultMessage:'Please enter new password.'
            },
            change_password_current_pwd_error_mesg:{
                id:'change_password_current_pwd_error_mesg',
                description:'Please enter current password.',
                defaultMessage:'Please enter current password.'
            },
            change_password_new_pwd_error_mesg1:{
                id:'change_password_new_pwd_error_mesg1',
                description:'New password cannot be the same as current password.',
                defaultMessage:'New password cannot be the same as current password.'
            },
            already_used_in_last_5_passwords: {
                id: 'already_used_in_last_5_passwords',
                description: 'The password you have entered is not valid because it matches one of the last five you have used. Please create a new password.',
                defaultMessage: 'The password you have entered is not valid because it matches one of the last five you have used. Please create a new password.'
            },
            change_password_current_pwd_error_mesg1:{
                id:'change_password_current_pwd_error_mesg2',
                description:'Current password is incorrect.',
                defaultMessage:'Current password is incorrect.'
            },
            change_password_confirm_pwd_error_mesg:{
                id:'change_password_confirm_pwd_error_mesg',
                description:'Please confirm new password.',
                defaultMessage:'Please confirm new password.'
            },
            change_password_confirm_pwd_error_mesg1:{
                id:'change_password_confirm_pwd_error_mesg1',
                description:'Password does not match.',
                defaultMessage:'Password does not match.'
            },
            settings_Cancel:{
                id:'settings_Cancel',
                description:'Cancel',
                defaultMessage:'Cancel'
            },
            password_successfully_changed:{
                id:'password_successfully_changed',
                description:'Your password has been successfully changed.',
                defaultMessage:'Your password has been successfully changed.'
            },
            password_field_required: {
                id: 'password_field_required',
                description: 'Please enter valid password',
                defaultMessage: 'Password must meet complexity criteria below'
            },
            already_used_in_last_5_passwords:{
                id:'already_used_in_last_5_passwords',
                description:'The password you have entered is not valid because it matches one of the last five you have used. Please create a new password.',
                defaultMessage:'The password you have entered is not valid because it matches one of the last five you have used. Please create a new password.'
            }            
        });
        const tabIndexGroup = this.props.tabIndexStartFrom;
        return <div className={classNames({'change_password_update': this.props.passwordSettings.get('isEditMode')},
            {'change_password_view': !this.props.passwordSettings.get('isEditMode')})}>
                    {!this.props.passwordSettings.get('isEditMode') ?
                        <div>
                            <div className="container change_password__view_row">
                                <div className="col-sm-6 change_password__caption">
                                    <FormattedMessage {...messages.changePassword_view_caption}/>
                                </div>
                                <div className="flex-row change_password__caption1">
                                    <a onClick={this.props.onChangePasswordClickHandler.bind(this)}>
                                        <FormattedMessage {...messages.changePassword_change_password}/>{' '}
                                        <img src="/assets/VMS_28button_edit_blue.png"/>
                                    </a>

                                </div>
                            </div>
                            <div className="row">
                                { this.props.passwordSettings.get('wasPasswordChanged') &&
                                <div className="change_password__saved_success">
                                    <FormattedMessage {...messages.password_successfully_changed}/>
                                </div>
                                }
                            </div>
                        </div>
                        :
                        <div>
                        <div className="row change_password__header-row">
                            <div className="col-md-12">
                            <div className="change_password__caption"><FormattedMessage {...messages.changePassword_view_caption}/></div>
                                </div>
                            </div>
                        <div className="row change_password__header-row">

                    <div className="col-md-3">
                        <div className="change_password__caption2">                            
                            <FormattedMessage {...messages.change_password_ari_id}/>
                        </div>
                        <div >
                            <Overlay
                                show={this.props.passwordSettings.get('showAriIdError')}
                                placement="left"
                                target={props=>ReactDOM.findDOMNode(this.refs.txtAriID)}

                            >
                                <Popover  id="popOver_AriID"
                                          className="change_password__errorMessage">
                                               <RawIntlProvider value={this.props.intl}>
                                    {this.props.passwordSettings.get('ariIdErrorMessage') == 'Mesg2' ?
                                        <FormattedMessage {...messages.change_password_ariId_error_mesg1}/>
                                       :
                                    this.props.passwordSettings.get('ariIdErrorMessage') == 'Mesg1'?
                                        <FormattedMessage {...messages.change_password_ariId_error_mesg}/>
                                        :''
                                    }
                                    </RawIntlProvider>
                                </Popover>

                            </Overlay>
                            <form>
                            <input id="txtAriID"  type="text" className="change_password__input-box" tabIndex={tabIndexGroup + 1}
                                   ref="txtAriID" cols="6" maxLength="10" size="10" value={this.props.passwordSettings.get('ariId')}
                                   onChange={this.onTextChange.bind(this)}/>
                                </form>
                        </div>

                    </div>

                    <div className="col-md-3">
                        <div className="change_password__caption2">
                            {this.props.intl.locale=='es-MX'?<div><br /></div>:''}
                            <FormattedMessage {...messages.change_password_current}/>
                        </div>
                        <div >
                            <Overlay
                                show={this.props.passwordSettings.get('showCurrentPwdError')}
                                placement="top"
                                target={props=>ReactDOM.findDOMNode(this.refs.txtCurrentPassword)}

                            >


                                <Popover  id="popOver_current_pwd"
                                            className="change_password__errorMessage">
                                                 <RawIntlProvider value={this.props.intl}>
                                    {
                                        this.props.passwordSettings.get('ariIdErrorMessage')=='API-045' || this.props.passwordSettings.get('ariIdErrorMessage')=='API-030'
                                        ?
                                            <FormattedMessage {...messages.change_password_current_pwd_error_mesg1}/>
                                        : this.props.passwordSettings.get('ariIdErrorMessage')=='API-142' 
                                        ? <FormattedMessage {...messages.already_used_in_last_5_passwords}/>
                                        :
                                        this.props.passwordSettings.get('ariIdErrorMessage')=='empty'
                                        ?
                                            <FormattedMessage {...messages.change_password_current_pwd_error_mesg}/>
                                        :
                                            <FormattedMessage {...messages.change_password_current_pwd_error_mesg}/>
                                    }
                                    </RawIntlProvider>
                                </Popover>

                            </Overlay>
                            <input type="password" className="change_password__input-box" tabIndex={tabIndexGroup + 2}
                                    id ="txtCurrentPassword" ref="txtCurrentPassword" cols="10" maxLength="20" size="10"
                                    value={this.props.passwordSettings.get('currentPassword')}
                                    onChange={this.onTextChange.bind(this)}/>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="change_password__caption2">
                            {this.props.intl.locale=='es-MX'?<div><br /></div>:''}
                            <FormattedMessage {...messages.change_password_new}/></div>
                        <div>
                            <Overlay
                                show={this.props.passwordSettings.get('showNewPwdError')}
                                placement="bottom"
                                target={props=>ReactDOM.findDOMNode(this.refs.txtNewPassword)}

                            >
                                <Popover  id="popOver_newPwd"
                                        className="change_password__errorMessage">
                                             <RawIntlProvider value={this.props.intl}>
                                    {this.props.passwordSettings.get('newPwdErrorMessage') == 'Mesg3' ?
                                        <FormattedMessage {...messages.change_password_new_pwd_error_mesg}/>
                                        :
                                        this.props.passwordSettings.get('newPwdErrorMessage') == 'Mesg4' ?
                                        <FormattedMessage {...messages.change_password_new_pwd_error_mesg1}/>
                                        :
                                        this.props.passwordSettings.get('newPwdErrorMessage') == 'InvalidPassword' ?
                                        <FormattedMessage {...messages.password_field_required}/>
                                        :''
                                    }
                                    </RawIntlProvider>
                                </Popover>

                            </Overlay>
                            <input type="password" className="change_password__input-box" tabIndex={tabIndexGroup + 3}
                                id="txtNewPassword" ref="txtNewPassword" cols="10" maxLength="20" size="10"
                                value={this.props.passwordSettings.get('newPassword')}
                                onChange={this.onTextChange.bind(this)}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="change_password__caption2"><FormattedMessage {...messages.change_password_confirm_new}/></div>
                        <div >
                            <Overlay
                                show={this.props.passwordSettings.get('showConfirmNewPwdError')}
                                placement="top"
                                target={props=>ReactDOM.findDOMNode(this.refs.txtConfirmNewPassword)}
                            >
                                <Popover  id="popOver_ConfirmPwd"
                                        className="change_password__errorMessage">
                                             <RawIntlProvider value={this.props.intl}>
                                    {this.props.passwordSettings.get('confirmPwdErrorMessage') == 'Mesg2' ?
                                        <FormattedMessage {...messages.change_password_confirm_pwd_error_mesg1}/>
                                        :
                                        <FormattedMessage {...messages.change_password_confirm_pwd_error_mesg}/>
                                    }
                                    </RawIntlProvider>  
                                </Popover>

                            </Overlay>
                            <input type="password" className="change_password__input-box" tabIndex={tabIndexGroup + 4}
                                id="txtConfirmNewPassword" ref="txtConfirmNewPassword"
                                onChange={this.onTextChange.bind(this)} cols="10" maxLength="20" size="10" />
                        </div>
                        </div>
                    </div>
                    <div>
                        <PasswordPolicy />
                    </div>
                    <div className="row">

                        <div className="col-sm-3">
                            <div className="change_password__caption3"
                                tabIndex={tabIndexGroup + 6}>
                                <a className="" onClick={this.props.onCancelClickHandler.bind(this)}  >                                    
                                    <FormattedMessage {...messages.settings_Cancel}/>
                                    {' '}<img src="/assets/VMS_28button_delete_blue.png"/>
                                </a>
                            </div>
                        </div>

                        <div className={this.props.passwordSettings.get('isChangingPassword') ?  "col-sm-9 change_password__caption4 change-password-change_button-disabled" : "col-sm-9 change_password__caption4"}
                                tabIndex={tabIndexGroup + 5}>
                            <a className="" onClick={this.onSaveClick.bind(this)} ><FormattedMessage {...messages.changePassword_save_password}/>{' '}
                                <img src="/assets/VMS_28button_edit_clear.png"/>
                            </a>
                        </div>

                    </div>

                </div>

            }

        </div>;
    }
}



export default injectIntl(ChangePassword);
