// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
// our stuff
import CheckCircle from 'components/CheckCircle';
import * as craneInspectionEnums from '../data/CraneInspectionEnums';


class CraneInspectionHoistSystemBrakesType extends React.Component {

	handleHoistSystemBrakesCodeClick = (name, checked) => {
		if (!checked) {
			this.props.setFormData('hoistSystemBrakesType', name);
		}
	}

	render() {
		const messages = defineMessages({
			crane_inspection__hoistSystemBrakesType_Hydraulic: {
				id: 'crane_inspection__hoistSystemBrakesType_Hydraulic',
				description: 'Hydraulic',
				defaultMessage: 'Hydraulic'
			},
			crane_inspection__hoistSystemBrakesType_Air: {
				id: 'crane_inspection__hoistSystemBrakesType_Air',
				description: 'Air',
				defaultMessage: 'Air'
			}
		});

		if (!this.props.formData)
			return null;

		return (
			<div>
				<Row className="crane_inspection__hoistSystemBrakesType">
					<Col sm={6}>
						<CheckCircle
							name={craneInspectionEnums.hoistSystemBrakesType.hydraulic}
							checked={this.props.formData.get('hoistSystemBrakesType') === craneInspectionEnums.hoistSystemBrakesType.hydraulic}
							message={<FormattedMessage {...messages.crane_inspection__hoistSystemBrakesType_Hydraulic} />}
							onClick={this.handleHoistSystemBrakesCodeClick}
						/>
					</Col>

					<Col sm={6}>
						<CheckCircle
							name={craneInspectionEnums.hoistSystemBrakesType.air}
							checked={this.props.formData.get('hoistSystemBrakesType') === craneInspectionEnums.hoistSystemBrakesType.air}
							message={<FormattedMessage {...messages.crane_inspection__hoistSystemBrakesType_Air} />}
							onClick={this.handleHoistSystemBrakesCodeClick}
						/>
					</Col>
				</Row>
			</div>
		)
	}
}

export default CraneInspectionHoistSystemBrakesType;