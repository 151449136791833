/**
 * Summary: This file contains font styled components based on the design spec created by Dan Willis.
 *
 * Description: All of the components defined in this file should come straight from the design spec.
 *              These are font components that are defined on pages 5 and 8.
 *              If a variation is needed for a specific page that you are working on, please keep those
 *              changes in your file.  You can 'override' these settings.
 *              If there is a site wide change to the style, the change needs to be discussed by a review team.
 *
 * @link   https://pulse.holmanenterprises.com/docs/DOC-20407
 * @file   This file contains font styled components based on the design spec created by Dan Willis.
 * @author Rob Brodie (Design by Dan Willis).
 * @since  5/29/19
 */
import React from 'react';
import styled from 'styled-components';

export const PageHeader = styled.div`	
	font-family: "Gotham SSm A", "Gotham SSm B";
	font-style: normal;	
	color: ${props => props.theme.color.black};
	font-size: 42px;	
	padding-top: 20px;
	padding-bottom: 20px;
`;

export const Subhead = styled.div`	
	font-family: "Gotham SSm A", "Gotham SSm B";
	font-style: normal;	
	color: ${props => props.theme.color.primaryBlue};
	font-size: 36px;		
	padding-bottom: 16px;
	padding-top: 16px;
`;

export const ThirdLevelSubhead = styled.div`	
	font-family: "Gotham SSm A", "Gotham SSm B";
	font-style: normal;	
	color: ${props => props.theme.color.black};			
	font-size: 21px;		
	padding-bottom: 10px;
`;

export const BodyCopyStyled = styled.div`	
	font-weight: 500;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color: ${props => props.theme.color.black};
    font-size: 16px;
`;

export const ActionButtonLinkStyled = styled.div`	
	font-weight: 500;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color: ${props => props.theme.color.primaryBlue};
    font-size: 16px;
`;

export const TableHeaderStyled = styled.div`	
	font-weight: bold;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color: ${props => props.theme.color.black};
    font-size: 14px;
`;

export const TableTextStyled = styled.span`	
	font-weight: 300;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-style: normal;
    color: ${props => props.theme.color.black};
    font-size: 16px;
`;