import React,{Fragment} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import './VendorAccountInformation.css'
import VendorAccountVerification from './VendorAccountVerification';
import VendorTaxInformation from './VendorTaxInformation';
import { PAYMENT_TYPE } from 'constants/App';
import PaymentEmailInformation from './PaymentEmailInformation';
import { useAppState } from 'shared/AppState/app-state';
import * as helperFunctions from 'utils/HelperFunctions';

const VendorAccountInformation = (props) => {

        const [ appState, dispatch ] = useAppState();

        const messages = defineMessages({
            account_view_caption: {
                id: 'account_view_caption',
                description: 'account information',
                defaultMessage: 'Account information'
            },
            bank_account_caption: {
                id: 'bank_account_caption',
                description: 'bank account information title',
                defaultMessage: 'Bank Account'
            },
            checking_account_caption: {
                id: 'checking_account_caption',
                description: 'checking account information title',
                defaultMessage: 'Checking Account No.'
            },
            web_draft_caption: {
                id: 'web_draft_caption',
                description: 'web_draft_caption',
                defaultMessage: 'Web Draft'
            },
            account_no_caption: {
                id: 'account_no_caption',
                description: 'account_no_caption',
                defaultMessage: 'Account No.'
            },
            edit_account_information: {
                id: 'edit_account_information',
                description: 'Button for editing account information',
                defaultMessage: 'Edit bank account information'
            },
            account_submission_success:{
                id: 'account_submission_success',
                description: 'caption for when the account information change is successful',
                defaultMessage: 'Your banking update has been submitted for processing. Please allow one full business day for completion' +
                'of banking change. Remember: To ensure payment of services, always verify your payment account when submitting online invoicing.'
            },
            account_submission_success_new_text:{
                id: 'account_submission_success_new_text',
                description: 'caption for when the account information change is successful',
                defaultMessage: 'Recent changes to your bank account information have placed all payments on a temporary hold.  Please be prepared to receive a phone call from Holman to verify these changes to allow payments to resume.'

            },
            non_intellipay_account_message:{
                id:'non_intellipay_account_message',
                description:'If you need to update your banking information, please contact the Partner Relations teams listed below: <br/> In the United States - 1-888-683-8327, <br/> In Canada - 1-866-416-6542',
                defaultMessage:'If you need to update your banking information, please contact the Partner Relations teams listed below: <br/> In the United States - 1-888-683-8327, <br/> In Canada - 1-866-416-6542'
            }

        });        
 
        return <div>
            {["VW", "ED"].some(p => appState.bankAccountPermissions.includes(p)) &&
            <Fragment>
            <hr className="vendor-account_info__hr"/>
            <div className="vendor-account_info__caption">
                <FormattedMessage {...messages.account_view_caption}/>
            </div>
           
            {props.accountSubmissionSuccess ?
                <div className="vendor-account-info__view row">
                <div className="vendor-account-component__text2">
                {appState.isPaymentStatusCheckEnabled ?   <FormattedMessage {...messages.account_submission_success_new_text}/> : <FormattedMessage {...messages.account_submission_success}/>}
                </div>
                </div>
                : undefined
            }
            {
            
            !props.editModeValidation && !props.paymentEmailEditModeValidation?
            props.paymentType === PAYMENT_TYPE.EDI || props.isWorkCompletionFeatureEnabled ?
                <div>
                    <div className="vendor-account-info__view row">
                        <div className="col-md-10  vendor-account-info__title">
                            <FormattedMessage {...messages.bank_account_caption}/>
                        </div>
                    </div>
                    <div className="vendor-account-info__view row">
                        <div className="col-md-10 vendor-account-component__text">
                            <FormattedMessage {...messages.non_intellipay_account_message}  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                        </div>
                    </div>

                </div>:
                props.isDealerDraft ?                
                <div>
                    <div className="vendor-account-info__view row">
                        <div className="col-md-7  vendor-account-info__title">
                            <FormattedMessage {...messages.bank_account_caption}/>
                        </div>

                        <div className="col-md-3  vendor-account-info__title">
                            <FormattedMessage {...messages.web_draft_caption}/>
                        </div>
                    </div>
                    <div className="vendor-account-info__view row">
                        <div className="col-md-7  vendor-account-info__title">
                            <FormattedMessage {...messages.checking_account_caption}/>
                        </div>
                        <div className="col-md-3  vendor-account-info__title">
                            <FormattedMessage {...messages.account_no_caption}/>
                        </div>
                    </div>
                    <div className="vendor-account-info__view row">
                        <div className="col-md-7 vendor-account-component__text">
                            {props.vendorCheckingAccount == null ? false : '*****'+props.vendorCheckingAccount}
                        </div>
                        <div className="col-md-3 vendor-account-component__text">
                            {props.vendorCheckingAccount == null ? false : '*****'+props.vendorCheckingAccount}
                        </div>
                    </div>
                    {appState.bankAccountPermissions.includes("ED") &&
                    <Fragment>
                    {props.accountCountry === "USA" &&  props.paymentType !== PAYMENT_TYPE.EDI && !props.isWorkCompletionFeatureEnabled?
                        <div className="vendor-account-component__view2 container">
                            <a className="vendor-account-component__caption2"
                               onClick={props.onChangeAccountVerifyClick.bind(this)}>
                                <FormattedMessage {...messages.edit_account_information}/>{' '}
                                <img src="/assets/VMS_28button_edit_blue.png"/>
                            </a>
                        </div>
                            :                             
                        <div className="vendor-account-info__view row">
                        <div className="col-md-12 vendor-account-component__text">
                           <br></br>
                            <FormattedMessage {...messages.non_intellipay_account_message}  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                        </div>
                        </div>
                    }
                    </Fragment>
                    }
                    
                    
                </div>
                  :
                  <div>
                  <div className="vendor-account-info__view row">
                      <div className="col-md-7  vendor-account-info__title">
                          <FormattedMessage {...messages.bank_account_caption}/>
                      </div>
                  </div>
                  <div className="vendor-account-info__view row">
                      <div className="col-md-7  vendor-account-info__title">
                          <FormattedMessage {...messages.checking_account_caption}/>
                      </div>
                  </div>
                  <div className="vendor-account-info__view row">
                      <div className="col-md-7 vendor-account-component__text">
                          {props.vendorCheckingAccount == null ? false : '*****'+props.vendorCheckingAccount}
                      </div>
                  </div>
                  {appState.bankAccountPermissions.includes("ED") &&
                    <Fragment>
                      {props.accountCountry === "USA" && props.paymentType !== PAYMENT_TYPE.EDI  && !props.isWorkCompletionFeatureEnabled?
                          <div className="vendor-account-component__view2 container">
                              <a className="vendor-account-component__caption2"
                                 onClick={props.onChangeAccountVerifyClick.bind(this)}>
                                  <FormattedMessage {...messages.edit_account_information}/>{' '}
                                  <img src="/assets/VMS_28button_edit_blue.png"/>
                              </a>
                          </div>
                          : 
                        <div className="vendor-account-info__view row">
                        <div className="col-md-12 vendor-account-component__text">
                           <br></br>
                            <FormattedMessage {...messages.non_intellipay_account_message}  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}}/>
                        </div>
                        </div> 
                      }
                    </Fragment>
                  }                        
              </div>
                
                : <VendorAccountVerification
                    isPaymentStatusCheckEnabled = {appState.isPaymentStatusCheckEnabled}
                    isLinkedVendor={props.isLinkedVendor}
                    onCancelAccountClick={props.onCancelAccountClick.bind(this)}
                    onEditAccountClick={props.onEditAccountClick.bind(this)}
                    editModeAccount={props.editModeAccount}
                    editModePaymentEmail={props.editModePaymentEmail}
                    paymentEmail={props.paymentEmail}
                    isFetchingData={props.isFetchingData}
                    popOverDisplayShowARIId={props.popOverDisplayShowARIId}
                    popOverDisplayShowPersonal={props.popOverDisplayShowPersonal}
                    accountApproved={props.accountApproved}
                    accountIdError={props.accountIdError}
                    accountPersonalError={props.accountPersonalError}
                    accountErrorMessage={props.accountErrorMessage}
                    termsAndConditionAccept={props.termsAndConditionAccept}
                    agreedToTermsAndConditions={props.agreedToTermsAndConditions.bind(this)}
                    popOverDisplayShowChecking1={props.popOverDisplayShowChecking1}
                    popOverDisplayShowChecking2={props.popOverDisplayShowChecking2}
                    popOverDisplayShowRoutingNumber={props.popOverDisplayShowRoutingNumber}
                    checking1Error={props.checking1Error}
                    checking2Error={props.checking2Error}
                    routingNumberError={props.routingNumberError}
                    onSaveBankingAccount={props.onSaveBankingAccount}
                    onSavePaymentEmail={props.onSavePaymentEmail}
                    popOverDisplayShowTerms={props.popOverDisplayShowTerms}
                    termsError={props.termsError}
                    onBlurChange={props.onBlurChange}
                    tabIndexStartFrom={props.tabIndexStartFrom}
                    country={props.country}
                    termsAndConditions={props.termsAndConditions}
                    toggleAcceptedFlag={props.toggleAcceptedFlag}
                    isFetchingTermsAndConditions={props.isFetchingTermsAndConditions}
                    paymentType={props.paymentType}
                    selectedCountry={props.selectedCountry}
                    isPaymentEmailEditModeValidation={props.paymentEmailEditModeValidation}
                    personaUserName={appState.personaUser!=undefined && appState.personaUser!=null?
                        helperFunctions.getFormattedPersonaUserName(appState.personaUser.firstName,appState.personaUser.lastName):null}
                />

            }
            {!props.editModeValidation && !props.paymentEmailEditModeValidation &&
                !props.isLinkedVendor && !(props.selectedCountry=="MEX") && !props.isSSOUser &&
                <Fragment>
                    <hr className="vendor-contact-info__hr" />
                    <PaymentEmailInformation
                        paymentEmail={props.paymentEmail}                        
                        onChangePaymentEmailVerifyClick={props.onChangePaymentEmailVerifyClick}
                    />
                </Fragment>
            }
           </Fragment>
            }
      
            {appState.taxIdPermssions.includes("VW") &&
            <Fragment>
            <hr className="vendor-contact-info__hr"/>
            <VendorTaxInformation
                taxId={props.taxId}
                taxClassification={props.taxClassification}
                country={props.country}
            />
            </Fragment>
            }

            <hr className="vendor-account_info__hr"/>
            
        </div>
        ;
    
}

export default (VendorAccountInformation);
