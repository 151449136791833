import { call, put, select, take, cancel, fork,all } from 'redux-saga/effects'
import { Map, List, fromJS } from 'immutable';
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import { diagnosisDetailsSelector, complaintsSelector } from 'selectors/ComplaintView';
import * as actionRequiredSelector from 'selectors/ActionRequired';
import { emptyMapIfUndefined, emptyArrayIfUndefined, getWheelObject, isNewTire,getSelectedLanguageCode } from 'utils/HelperFunctions';
import { Schema, arrayOf, normalize } from 'normalizr';
const skippedComplaintCode = 'UN01';
const skippedComplaintDescription = 'NOT SUPPLIED';
const diagATACode = '53001010';
const diagATADescription = 'DIAGNOSIS';
const diagComplaintCode = 'DG99';
const maintenanceComplaintCode = 'MA99';
import moment from 'moment';
import lodash from 'lodash';
import * as helperFunctions from 'utils/HelperFunctions';

export function* fetchPOData(context, action) {
  try {
    const state = yield select();
    const token = helperFunctions.getToken(state);
     const vendorId = state.appState.getIn(['serverData', 'shared', 'vendorId']);

    if (action.mode == "edit") {
      yield put({ type: constants.CLEAR_COMPLAINT_NOTES });
    }
    //clear out any error messages
    yield put({ type: constants.CLEAR_ERROR_MESSAGE });
    yield put({ type: constants.CLEAR_PURCHASE_ORDER });
    // Inform application that PO data is being fetched    
    yield put({ type: constants.FETCH_PO_DATA_REQUESTED, mode: action.mode, poNumber: action.poNumber });
    yield put({ type: constants.HIDE_VEHICLE_SEARCH_ERROR_MESSAGE });
    yield put({ type: constants.RESET_VENDOR_VEHICLE_PARAMETERS })

    const [purchaseOrder, lineItems, vehicle, vendor, complaints, notes,paymentInfo] = yield all([
      call(Api.fetchPurchaseOrder, action.poNumber, token),
      call(Api.fetchLineItems, action.poNumber, token,getSelectedLanguageCode(state)),
      call(Api.fetchVehicle, action.poNumber, token),
      call(Api.fetchVendor, action.poNumber, token),
      call(Api.fetchComplaints, action.poNumber, token, state),
      call(Api.fetchComplaintNotes, action.poNumber, token,getSelectedLanguageCode(state)),
      call(Api.fetchVendorPaymentInfo,action.poNumber,token)
    ]);

       /*if(notes != undefined ) {
           for (var key in notes) {
               const createdBy = notes[key].createdBy;
               if (createdBy !== vendorId) {
                   notes[key].noteText = "Holman - " + notes[key].noteText.split("\n");
               }
           }
       }*/

       var productDescription='';

       if(notes != undefined ) {
           for (var key in notes) {                
               productDescription='';
              
                   for(var lineKey in lineItems){
                   
                       if(lineItems[lineKey].id==notes[key].lineItemId)                       

                       productDescription=lineItems[lineKey].vendorProductCode == null ? lineItems[lineKey].productDescription +', '+ (lineItems[lineKey].lineItemType == constants.LINE_ITEM_TYPE.PM ? constants.LINE_ITEM_TYPE.PM_TEXT : lineItems[lineKey].lineItemType.toUpperCase()) :  lineItems[lineKey].vendorProductCode + ' - ' + lineItems[lineKey].productDescription +', '+ (lineItems[lineKey].lineItemType == constants.LINE_ITEM_TYPE.PM ? constants.LINE_ITEM_TYPE.PM_TEXT : lineItems[lineKey].lineItemType.toUpperCase());   
                      }        
                   notes[key].vendorId=vendorId;
                   notes[key].productDescription=productDescription; 

           }
       }
    if (action.mode == 'edit' && action.shouldLoadLaborRate) {
      //Get vendor vehicle parameters
      const vendorVehicleParameters = yield call(Api.fetchVendorVehicleParameters, helperFunctions.getVendorId(state), vehicle.id, helperFunctions.getToken(state));
      yield put({ type: constants.SET_VENDOR_VEHICLE_PARAMETERS, vendorVehicleParameters });
    }


      //If logged in vendor is different from PO vendor; then do not query for the bank account information
    if (purchaseOrder.vendorId == helperFunctions.getVendorId(state)) {
        //Make a call to get bank account information only if the PO status is Paid or payment in progress      
        //this is to fix the issue of candian vendors getting error during view, add, edit pos if infinium is down
        vendor.country = vendor.businessAddress.country;     
        if(vendor.businessAddress.country=='CAN'){
            if(purchaseOrder.paymentStatus==constants.PO_PAYMENT_STATUS.PAYMENT_IN_PROGRESS || purchaseOrder.paymentStatus==constants.PO_PAYMENT_STATUS.PAID)
            {        
                try{
                    const vendorBankAccountInformation = yield call(Api.fetchVendorBankAccountInformation, purchaseOrder.vendorId, purchaseOrder.vendorId + 'MAINTENANCE', helperFunctions.getToken(state));
                    vendor.bankAccountNumber = vendorBankAccountInformation != null ? vendorBankAccountInformation.accountNumber : null;
                }
                catch(e){
                    vendor.bankAccountNumber=null;
                }        
            }
        }
        else{
            const vendorBankAccountInformation = yield call(Api.fetchVendorBankAccountInformation, purchaseOrder.vendorId, purchaseOrder.vendorId + 'MAINTENANCE', helperFunctions.getToken(state));
            vendor.bankAccountNumber = vendorBankAccountInformation != null ? vendorBankAccountInformation.accountNumber : null;
        }
        }


    // Prepare data
    var purchaseOrderData = {
      purchaseOrder: purchaseOrder,
      vehicleDetails: vehicle,
      vendor: vendor,
      complaints: complaints,
      notes: notes,
      lineItems: lineItems,
      paymentInfo:paymentInfo
    };

    const isHsbClientVehicle = vehicle.isHsbClientVehicle;
    const entityForAssistance = purchaseOrder.entityForAssistance;
    const actionRequiredLines = actionRequiredSelector.filterAllActionRequiredLines(fromJS(lineItems ? lineItems : {}));

    let rejectEditPORequest = (action.mode == 'edit' && purchaseOrder.authorizationStatus != constants.PO_AUTHORIZATION_STATUS.APPROVED &&
    purchaseOrder.authorizationStatus != constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED && 
    purchaseOrder.authorizationStatus != constants.PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT && 
    purchaseOrder.authorizationStatus != constants.PO_AUTHORIZATION_STATUS.WORK_COMPLETED) 
    || (action.mode == 'edit' && purchaseOrder.isInsurancePo);

    if(action.mode == 'edit' && isHsbClientVehicle && entityForAssistance == constants.ENTITY_FOR_ASSISTANCE.CUSTOMER && actionRequiredLines.count() == 0 
        && purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL)
    {
      rejectEditPORequest = false;
    }

    /*if ((purchaseOrder.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.CANCELLED) ||
      (action.mode == 'edit' && (purchaseOrder.authorizationStatus != constants.PO_AUTHORIZATION_STATUS.APPROVED ||
        purchaseOrder.paymentStatus != constants.PO_PAYMENT_STATUS.PAYMENT_NOT_REQUESTED) && purchaseOrder.isApprovalRequestedAfterLastChange!='N' )
    )*/
 /* If you are navigating to an insurance PO or a PO not matching the status' below remove purchaseorder details to not display PO and navigate back to the shopview page. else go to the cooresponding page */
    if (rejectEditPORequest) 
     {
      //throw true;
      purchaseOrderData = {
        purchaseOrder: undefined,
        vehicleDetails: undefined,
        vendor: undefined,
        complaints: undefined,
        notes: undefined,
        lineItems: undefined,
        client: undefined,
        paymentInfo:undefined
      }
      //TODO - purchase order data from state should be cleared if the fetch is not successful
      yield put({ type: constants.FETCH_PO_DATA_REJECTED });

        yield call(context.history.push,'/');
    }
    else
      yield put({ type: constants.FETCH_PO_DATA_RESOLVED, purchaseOrderData, mode: action.mode });


  }
  catch (e) {
    yield put({ type: constants.FETCH_PO_DATA_REJECTED, mode: action.mode, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_PO_DATA_REQUESTED, errorObject: e });
  }
}

export function* addNewLineItem(hours, rate, ataCode, ataDescription, complaintCode, correctionType, lineItemType, partWarrantyMonths, partWarrantyMiles, partWarrantyHours, poNumber, token, vendorProductCode,pricingSource,vendorReferenceNo, lineItemValidationReferenceId) {
    const state = yield select();
    let lineItem = {
      lineItemType: lineItemType,
      unitPrice: rate,
      quantity: hours,
      complaintCode: complaintCode,
      productCode: ataCode,
      approvalStatus: constants.LINE_ITEM_STATUS.PENDING,
      totalAmount: hours * rate,
      correctionType: correctionType,
      partWarranty: {
        months: partWarrantyMonths,
        meter: partWarrantyMiles,
        hours: partWarrantyHours
      },
      vendorProductCode: vendorProductCode,
      productDescription: ataDescription,
      vendorReferenceNo: vendorReferenceNo,
      lineItemValidationReferenceId: lineItemValidationReferenceId
    };

  // call api to create new line
  const lineItemId = yield call(Api.createLineItem, poNumber, lineItem, token,getSelectedLanguageCode(state));

  // Save lineItemid returned by the service
  lineItem['lineItemId'] = lineItemId;

    // Save productDescription for UI
    lineItem['productDescription'] = ataDescription;

    // save pricing source
    lineItem['pricingSource'] = pricingSource

    // save line in app state
    yield put({ type: constants.SAVE_LINE_ITEM, lineItem });

  //save the parts line item
  if((lineItemType == constants.LINE_ITEM_TYPE.PART && correctionType == constants.CORRECTION_TYPE.REPLACE))
    yield put({ type: constants.SAVE_LATEST_LINE_ITEM_ID, lineItemId })

  return lineItemId;
}

export function* createMaintenanceComplaint() {
  // Read data from app state
  const state = yield select();
  let nextComplaintId = emptyMapIfUndefined(state.appState.getIn(["uiData", "addEdit", "complaints"])).count() + 1;
  let newComplaintObj = {};
  let complaintKey = 'complaint' + nextComplaintId;
  newComplaintObj[complaintKey] = Map({
    index: nextComplaintId,
    title: "Complaint " + nextComplaintId,
    code: maintenanceComplaintCode,
    description: 'Maintenance',
    isSelectMode: false,
    wasComplaintSkipped: false,
    isComplaintOpen: false
  });
  let newComplaint = new Map(newComplaintObj);
  yield put({ type: constants.ADD_EDIT_VIEW_ADD_COMPLAINT, newComplaint });
}

export function* addServiceLineItem(action) {
  try {

    //clear out any error messages
    yield put({ type: constants.CLEAR_ERROR_MESSAGE });
    yield put({ type: constants.ADD_SERVICE_LINE_ITEM_REQUESTED });
    // Read data from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
    const dueServiceDetails = state.appState.getIn(['uiData', 'addServiceLineItemsView', 'serviceLineItems']);
    const availableAtaCodes = emptyMapIfUndefined(state.appState.getIn(['serverData', 'complaintView', 'ataCodes']))
      .merge(emptyMapIfUndefined(state.appState.getIn(['serverData', 'view', 'ataCodes'])));

    // Add maintenance complaint if it doesn't already exist
    if (emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints']))
      .count(c => c.get('code') == maintenanceComplaintCode) == 0) {
      yield* createMaintenanceComplaint();
    }

    // Loop through all the ATA codes and add lines to PO
    const arrayOfATACodes = dueServiceDetails.keySeq().toArray();
    for (var i = 0; i < arrayOfATACodes.length; i++) {

      let ataCode = arrayOfATACodes[i];
      let lineItemValidationReferenceId = action.priceValidations?.find(e => e.ataCode === ataCode).lineItemValidationReferenceId;
      let ataDescription = availableAtaCodes.getIn([ataCode, 'description']);
      let partCost = helperFunctions.zeroIfUndefined(dueServiceDetails.getIn([ataCode, 'partCost']));
      let laborRate = helperFunctions.zeroIfUndefined(dueServiceDetails.getIn([ataCode, 'laborRate']));
      let total = helperFunctions.zeroIfUndefined(dueServiceDetails.getIn([ataCode, 'total']));
      if (!isNaN(total) && Number(total) >= 0)
        yield* addNewLineItem(1, total, ataCode, ataDescription, maintenanceComplaintCode, constants.CORRECTION_TYPE.PM, constants.LINE_ITEM_TYPE.PM, undefined, undefined, undefined, poNumber, helperFunctions.getToken(state), lineItemValidationReferenceId);

    }

    // clear data in app state 
    yield put({ type: constants.REMOVE_SERVICE_ATA_CODE_DATA });
    //Clean up price validation state
    action.priceValidationCleanUp();

    // close the add service line item pop up
    yield put({ type: constants.TOGGLE_SHOW_MODAL_ADD_SERVICE_LINE_ITEMS });

    yield put({ type: constants.ADD_SERVICE_LINE_ITEM_RESOLVED });

  }
  catch (e) {
    // yield put({ type: constants.ADD_SERVICE_LINE_ITEM_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    var customError = helperFunctions.getCustomErrorObject(e);
    var genericError = helperFunctions.getGenericErrorObject(e);
    if (customError != undefined && customError.length > 0)
      yield put({ type: constants.ADD_SERVICE_LINE_ITEM_REJECTED, errorMessage: customError });
    else if (genericError != undefined && genericError.length > 0)
      yield put({ type: constants.ADD_SERVICE_LINE_ITEM_REJECTED, errorMessage: genericError });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.ADD_SERVICE_LINE_ITEM_REQUESTED, errorObject: e });
  }
}

export function* addNewLineItemHandler(action) {
  try {

    //clear out any error messages
    yield put({ type: constants.CLEAR_ERROR_MESSAGE });
    yield put({ type: constants.ADD_NEW_LINE_ITEM_REQUESTED });

    //Delete original line from RepairTypeChangeStep
    if(action.lineItem.repairTypeChangeStepNeeded && action.lineItem.lineItemIdRepairTypeChange != undefined){
      yield* deleteLineItem({ type: constants.DELETE_LINE_ITEM_CLICKED, lineItemKey: action.lineItem.lineItemIdRepairTypeChange });
    }

    // Read data from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
      const existingLineItems = emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'lineItems']));

    if(action.lineItem.multipleLineItemsPermittedForATA==false){

      
    // Check if part or labor line already exists on PO
    if (action.lineItem.correctionType == "PM") {
      if (action.lineItem.laborRate !== undefined && existingLineItems.filter(x => x.get('lineItemType') == constants.LINE_ITEM_TYPE.PM
        && x.get('productCode') === action.lineItem.productCode).size > 0) {
        yield put({
          type: constants.ADD_NEW_LINE_ITEM_REJECTED,
          errorMessage: [{ message: 'The item you have selected already exists on this PO. Please update the existing line item or contact Holman for assistance.' }]
        });
        window.scrollTo(0, 0);
        return;
      }

    } else if (action.lineItem.partCost !== undefined && existingLineItems.filter(x => x.get('lineItemType') == constants.LINE_ITEM_TYPE.PART
      && x.get('productCode') === action.lineItem.productCode).size > 0
      || action.lineItem.laborRate !== undefined && existingLineItems.filter(x => x.get('lineItemType') == constants.LINE_ITEM_TYPE.LABOR
        && x.get('productCode') === action.lineItem.productCode).size > 0
    ) {
      yield put({
        type: constants.ADD_NEW_LINE_ITEM_REJECTED,
        errorMessage: [{ message: 'The item you have selected already exists on this PO. Please update the existing line item or contact Holman for assistance.' }]
      });
      window.scrollTo(0, 0);
      return;
    }

    }

    // If part Cost is provided, add Part line to PO
    if (action.lineItem.partCost !== undefined && Number(action.lineItem.partCost) >= 0 && action.lineItem.correctionType != "PM")
    {
      yield* addNewLineItem(action.lineItem.partQty, action.lineItem.partCost, action.lineItem.productCode, action.lineItem.description, action.lineItem.complaintCode, action.lineItem.correctionType, constants.LINE_ITEM_TYPE.PART, action.lineItem.partWarrantyMonths, action.lineItem.partWarrantyMiles, action.lineItem.partWarrantyHours, poNumber, helperFunctions.getToken(state),action.lineItem.vendorProductCode,action.lineItem.partPricingSource,action.lineItem.vendorReferenceNo, action.lineItem.lineItemValidationReferenceId);
    
    }

    // If Labor Rate is provided, add Labor line to PO
    if (action.lineItem.correctionType != "PM" && Number(action.lineItem.laborRate) >= 0 && Number(action.lineItem.laborHours) > 0)
    {
      yield* addNewLineItem(action.lineItem.laborHours, action.lineItem.laborRate, action.lineItem.productCode, action.lineItem.description, action.lineItem.complaintCode, action.lineItem.correctionType, constants.LINE_ITEM_TYPE.LABOR, action.lineItem.partWarrantyMonths, action.lineItem.partWarrantyMiles, action.lineItem.partWarrantyHours, poNumber, helperFunctions.getToken(state),action.lineItem.vendorProductCode,action.lineItem.laborPricingSource,action.lineItem.vendorReferenceNo);
           
    }

    if (action.lineItem.correctionType == "PM")
      if (action.lineItem.laborHours == undefined ||Number(action.lineItem.laborHours == 0)) {
        yield* addNewLineItem(Number(action.lineItem.partQty), Number(action.lineItem.partCost), action.lineItem.productCode, action.lineItem.description, action.lineItem.complaintCode, action.lineItem.correctionType, constants.LINE_ITEM_TYPE.PM, action.lineItem.partWarrantyMonths, action.lineItem.partWarrantyMiles, action.lineItem.partWarrantyHours, poNumber, helperFunctions.getToken(state),action.lineItem.vendorProductCode,action.lineItem.laborPricingSource,action.lineItem.vendorReferenceNo, action.lineItem.lineItemValidationReferenceId);
      } else {

        yield* addNewLineItem(1, Number(action.lineItem.partCost) + Number(action.lineItem.laborRate), action.lineItem.productCode, action.lineItem.description, action.lineItem.complaintCode, action.lineItem.correctionType, constants.LINE_ITEM_TYPE.PM, action.lineItem.partWarrantyMonths, action.lineItem.partWarrantyMiles, action.lineItem.partWarrantyHours, poNumber, helperFunctions.getToken(state),action.lineItem.vendorProductCode,action.lineItem.laborPricingSource,action.lineItem.vendorReferenceNo, action.lineItem.lineItemValidationReferenceId);
      }
    // Reset data in app state
    yield put({ type: constants.CLEAR_FIELDS_AFTER_SAVE_ADD_NEW_LINE_ITEM_DATA });

    yield put({ type: constants.ADD_NEW_LINE_ITEM_RESOLVED });
    //Clean up price validation state
    action.priceValidationCleanUp();


    //Close modal and reset repairtype state
    if(action.lineItem.repairTypeChangeStepNeeded){
      yield put({ type: constants.CLEAR_LINE_ITEM_ID_REPAIR_TYPE_CHANGE });
      yield put({ type: constants.TOGGLE_SHOW_MODAL_ADD_NEW_LINE_ITEMS });
    }
    

    // Set line items details for viewing in the add new line item
    let lineItemForView = Map({
      productCode: action.lineItem.productCode,
      productDescription: action.lineItem.description,
      vendorProductCode: action.lineItem.vendorProductCode
    });
    yield put({ type: constants.SAVE_RECENT_ITEMS_ADDED_TO_PO, lineItemForView });

  }
  catch (e) {
    var customError = helperFunctions.getCustomErrorObject(e);
    var genericError = helperFunctions.getGenericErrorObject(e);
    if (customError != undefined && customError.length > 0)
      yield put({ type: constants.ADD_NEW_LINE_ITEM_REJECTED, errorMessage: customError });
    else if (genericError != undefined && genericError.length > 0)
      yield put({ type: constants.ADD_NEW_LINE_ITEM_REJECTED, errorMessage: genericError });

    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.ADD_NEW_LINE_ITEM_REQUESTED, errorObject: e });
  }
}

export function* deleteLineItem(action) {
  try {
    yield put({ type: constants.DELETE_LINE_ITEM_REQUESTED, lineItemId: action.lineItemKey });

    // Read data from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);

    // delete line item from PO
    yield call(Api.deleteLineItem, poNumber, action.lineItemKey, helperFunctions.getToken(state));

    // delete line item from app state
    yield put({ type: constants.DELETE_LINE_ITEM, lineItemId: action.lineItemKey });

    yield put({ type: constants.DELETE_LINE_ITEM_RESOLVED, lineItemId: action.lineItemKey });

  }
  catch (e) {
    yield put({ type: constants.DELETE_LINE_ITEM_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e), lineItemId: action.lineItemKey });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.DELETE_LINE_ITEM_REQUESTED, errorObject: e });
  }
}

export function* updateLineItemHandler(action) {
  try {
    yield put({ type: constants.UPDATE_LINE_ITEM_REQUESTED, lineItemId: action.lineItemId });

    // Read data from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
    let quantity = Number(action.quantity);
    let unitPrice = Number(action.unitPrice);

    // update line item on PO
    yield call(Api.editLineItem, poNumber, action.lineItemId, quantity, unitPrice, helperFunctions.getToken(state),getSelectedLanguageCode(state));

    // update line item in app state
    yield put({ type: constants.UPDATE_LINE_ITEM, lineItemId: action.lineItemId, quantity, unitPrice });

    yield put({ type: constants.UPDATE_LINE_ITEM_RESOLVED, lineItemId: action.lineItemId });
  }
  catch (e) {
    yield put({ type: constants.UPDATE_LINE_ITEM_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e), lineItemId: action.lineItemId });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.UPDATE_LINE_ITEM_REQUESTED, errorObject: e });
  }
}

export function* estimatedCompletionDateChanged(action) {
  try {
    yield put({ type: constants.ESTIMATED_COMPLETION_DATE_CHANGE_REQUESTED });
    yield put({ type: constants.CLEAR_ERROR_MESSAGE });


    // Read data from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);

    // update estimateCompletionDate in app state
    yield put({ type: constants.SAVE_ESTIMATED_DATE, newEstimatedDate: action.newEstimatedDate });

    // update estimateCompletionDate on PO
    yield call(Api.editEstimatedCompletionDate, poNumber, action.newEstimatedDate, helperFunctions.getToken(state));


    yield put({ type: constants.ESTIMATED_COMPLETION_DATE_CHANGE_RESOLVED });
  }
  catch (e) {
    yield put({ type: constants.ESTIMATED_COMPLETION_DATE_CHANGE_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.ESTIMATED_COMPLETION_DATE_CHANGE_REQUESTED, errorObject: e });
  }
}



export function* saveComplaintNotesHandler(action) {

  try {
    //clear out any error messages
    yield put({ type: constants.CLEAR_ERROR_MESSAGE });
    yield put({ type: constants.SAVE_COMPLAINT_NOTES_REQUESTED, complaintKey: action.complaintKey });

    // Read data from app state
    const state = yield select();
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
    let complaintCode = state.appState.getIn(['uiData', 'addEdit', 'complaints', action.complaintKey, 'code']);
    if (complaintCode == undefined) complaintCode = 'UN01';
    let noteId = state.appState.getIn(['uiData', 'addEdit', 'notes', action.complaintKey, 'id']);

      if (noteId != undefined) {

          if (action.notes != undefined && action.notes != '') {
              yield call(Api.editComplaintNotes, poNumber, complaintCode, undefined, action.notes, noteId, helperFunctions.getToken(state));

          } else {
              yield call(Api.deleteComplaintNotes, noteId,poNumber, helperFunctions.getToken(state));
          }

      }
      else {
          // add complaint note
          if (action.notes != undefined && action.notes != '') {
              const notesData = {
                  noteText: action.notes
              };
              noteId = yield call(Api.createComplaintNotes, poNumber, complaintCode, notesData, helperFunctions.getToken(state));
          }
      }

    // update line item in app state
    yield put({ type: constants.UPDATE_COMPLAINT_NOTES, complaintKey: action.complaintKey, notes: action.notes, noteId: noteId });

    yield put({ type: constants.SAVE_COMPLAINT_NOTES_RESOLVED, complaintKey: action.complaintKey });
  }
  catch (e) {
    yield put({
      type: constants.SAVE_COMPLAINT_NOTES_REJECTED,
      complaintNotesData: {
        complaintKey: action.complaintKey,
        errorMessage: helperFunctions.getCustomErrorObject(e)
      }
    });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
  }
}

export function* requestApprovalClicked(context, action) {
    try {

        yield put({ type: constants.CLEAR_ERROR_MESSAGE });

        // Read data from app state
        const state = yield select();
        /*Mandatory Complaint Note check */
        let listOfComplaintsForWhichNoteIsRequired = [];

        listOfComplaintsForWhichNoteIsRequired = helperFunctions.getListOfComplaintsForWhichNoteIsRequired(state);


        if (listOfComplaintsForWhichNoteIsRequired !== undefined && listOfComplaintsForWhichNoteIsRequired.length !== 0) {
            //throw error if complaint was just created and no notes exist for it
            let topComplaintMandatoryNotesNeeded = listOfComplaintsForWhichNoteIsRequired[0];
            document.getElementById(topComplaintMandatoryNotesNeeded).focus();
            throw Error("Please fill in your complaint notes");
        }

    //Estimated completion date must not be empty
    let estimateCompletionDate = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'estimatedCompletionDate']);
    if (estimateCompletionDate == undefined || !moment(estimateCompletionDate).isValid()) {
      yield put({ type: constants.UPDATE_IS_ESTIMATED_COMPLETION_DATE_VALID, isEstimateDateValid: false });
      return;
    }
    else {
      yield put({ type: constants.UPDATE_IS_ESTIMATED_COMPLETION_DATE_VALID, isEstimateDateValid: true });
    }

    //Start the approval process
    yield put({ type: constants.REQUEST_APPROVAL_REQUESTED });

    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);

    estimateCompletionDate = moment(estimateCompletionDate);

    // update estimateCompletionDate on PO
    if (action.actionType == 'add') {
      moment.locale(state.appState.getIn(['uiData', 'shared', 'selectedLocale']));
      yield call(Api.editEstimatedCompletionDate, poNumber, estimateCompletionDate, helperFunctions.getToken(state));
    }    

    // Request approval
    yield call(Api.requestApproval, poNumber, helperFunctions.getToken(state),helperFunctions.getSelectedLanguageCode(state));
    //window.location.replace('/');

    const serviceClassification = state.appState.getIn(['serverData', 'shared', 'serviceClassification']);

    if(serviceClassification == constants.CARWASH)
        yield call(context.history.push,'/carWash');
    else 
    // Navigate to shop view
        yield call(context.history.push,'/');
    
    yield put({ type: constants.REQUEST_APPROVAL_RESOLVED });

    yield put({ type: constants.CLEAR_DIAGNOSIS_DETAILS });
    yield put({ type: constants.CLEAR_SELECTED_SERVICES });
    yield put({ type: constants.CLEAR_ODOMETER_DATA });
    yield put({ type: constants.RESET_COMPLAINTS });
    yield put({ type: constants.CLEAR_COMPLAINT_NOTES });
    yield put({ type: constants.DELETE_ALL_LINE_ITEMS });
    yield put({ type: constants.CLEAR_PURCHASE_ORDER_DETAILS });
    yield put({ type: constants.HIDE_VEHICLE_SEARCH_ERROR_MESSAGE });
    yield put({ type: constants.CLEAR_REPAIR_HISTORY });
    yield put({ type: constants.CLEAR_ADD_EDIT_VIEW_SERVICES });

  }
  catch (e) {
    yield put({ type: constants.REQUEST_APPROVAL_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.ESTIMATED_COMPLETION_DATE_CHANGE_REQUESTED, errorObject: e });
  }
}

export function* fetchDataForDueServices(action) {
  try {
    const state = yield select();

    // Inform application that data request has started
    yield put({ type: constants.FETCH_DATA_FOR_DUE_SERVICES_REQUESTED });

    const [maintenanceServices, regulatoryServices] = yield all([
      call(Api.fetchMaintenanceServices, action.vehicleId, helperFunctions.getToken(state)),
      call(Api.fetchRegulatoryServices, action.vehicleId, helperFunctions.getToken(state))
    ]);

    const ataCodes = helperFunctions.getServiceAtaCodesToSave(maintenanceServices, regulatoryServices);

    yield put({ type: constants.FETCH_DATA_FOR_DUE_SERVICES_RESOLVED, maintenanceServices, regulatoryServices, ataCodes });

  }
  catch (e) {
    yield put({ type: constants.FETCH_DATA_FOR_DUE_SERVICES_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_DATA_FOR_DUE_SERVICES_REQUESTED, errorObject: e });
  }
}

export function* cancelPo(context, action) {
  try {

    // Read data from app state
    const state = yield select();
    let poNumber = action.actionType == "VIEW" ?
      state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'id'])
      : state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);



    yield call(Api.cancelPo, poNumber, helperFunctions.getToken(state));

    const isCarWashVendor = state.appState.getIn(['serverData', 'shared', 'serviceClassification']) == constants.CARWASH
    
    if(isCarWashVendor)
        yield call(context.history.push,'/carWash');
    else
        yield call(context.history.push,'/');
      
    yield put({ type: constants.CLEAR_DIAGNOSIS_DETAILS });
    yield put({ type: constants.CLEAR_SELECTED_SERVICES });
    yield put({ type: constants.CLEAR_ODOMETER_DATA });
    yield put({ type: constants.RESET_COMPLAINTS });
    yield put({ type: constants.CLEAR_COMPLAINT_NOTES });
    yield put({ type: constants.DELETE_ALL_LINE_ITEMS });
    yield put({ type: constants.CLEAR_PURCHASE_ORDER_DETAILS });
    yield put({ type: constants.HIDE_VEHICLE_SEARCH_ERROR_MESSAGE });
    yield put({ type: constants.CLEAR_REPAIR_HISTORY });
    yield put({ type: constants.CLEAR_ADD_EDIT_VIEW_SERVICES });
  }
  catch (e) {
    yield put({ type: constants.CANCEL_PO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CANCEL_PO_REJECTED, errorObject: e });
  }
}

export function* onRequestApprovalOrClosePOHandler(context, action) {
  try {
    // Read data from app state
    const state = yield select();

    //is carwash vendor 
    const isCarWashVendor = state.appState.getIn(['serverData', 'shared', 'serviceClassification']) == constants.CARWASH
    const isInsurancePo = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'isInsurancePo']) || state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'estimatedCompletionDate']);

    //Estimated completion date must not be empty
    let estimateCompletionDate = undefined; 
    
    if(action.actionType == 'view' && (isCarWashVendor || isInsurancePo))
        estimateCompletionDate=state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'estimatedCompletionDate']);
    else 
        estimateCompletionDate=state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'estimatedCompletionDate']);
    
    if (estimateCompletionDate == undefined || !moment(estimateCompletionDate).isValid()) {
      yield put({ type: constants.UPDATE_IS_ESTIMATED_COMPLETION_DATE_VALID, isEstimateDateValid: false });
      return;
    }
    else {
      yield put({ type: constants.UPDATE_IS_ESTIMATED_COMPLETION_DATE_VALID, isEstimateDateValid: true });
    }


    let isDirtyPO = action.isDirtyPO;
    let authorizedStatus = action.authorizedStatus;

    let poNumber = undefined; 
    if(action.actionType == 'view' && (isCarWashVendor || isInsurancePo))    
      poNumber = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'id']);
    else 
      poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
    

    if (isDirtyPO == true || (authorizedStatus == constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED || authorizedStatus == constants.PO_AUTHORIZATION_STATUS.NEW_WORK_ASSIGNMENT)) {
      yield put({ type: constants.REQUEST_APPROVAL_CLICKED, actionType: 'edit' });
    }
    else {
      //Redirect the user to Dashboard
      const closePOPath = isDirtyPO ? '/' : `/PO/close/${poNumber}`
        yield call(context.history.push,(null, null, closePOPath));
        yield call(context.history.push,closePOPath);
    }
  }
  catch (e) {
  }
}

function getWheelModel(tempId, id, vehicleId, lineItemId, axleNumber, position, eventType,
  unitOfMeasurement, treadDepth, replacedTireTreadDepth, width, diameter, manufacturer, model, wearOutReason, isEditable, isDirty) {
  return {
    tempId: tempId,
    id: id,
    vehicleId: vehicleId,
    lineItemId: lineItemId,
    axleNumber: axleNumber,
    position: position,
    eventType: eventType,
    unitOfMeasurement: unitOfMeasurement,
    treadDepth: treadDepth,
    replacedTireTreadDepth: replacedTireTreadDepth,
    width: width,
    diameter: diameter,
    manufacturer: manufacturer,
    model: model,
    wearOutReason: wearOutReason,
    isEditable: isEditable,
    isDirty: isDirty
  }
}

function* getLineItemIdBeingEdited() {
  const state = yield select();
  const lineItemIdForTires = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'lineItemForTires']);
  return lineItemIdForTires || null;

}

//gets if the measurement unit for tread depth has been changed 
function hasTireUnitChanged(defaultUnit, originalTireSpecs) {
  return originalTireSpecs.findIndex(x => x.get('unitOfMeasurement') != defaultUnit) > -1
}

//get the wheels/tires that have been edited
function getAddedTires(originalTireSpec, updatedTireSpec){
  let addedItems = updatedTireSpec.filter(x => originalTireSpec.findIndex(y => y.id == x.id) == -1);
  return addedItems;
}

//gets the wheels that have been edited
function getEditedTires(originalTireSpec, updatedTireSpec){
    //get the edited items
    let editedItems = updatedTireSpec.filter(x => {
      let item = originalTireSpec.find(y => y.id == x.id);
      if (!item)
        return false
      else
        return true
    })

    return editedItems ; 
}


//get the final data (modelled) to be posted to the endpoint
function getTireDataToPost(tireSpecs, lineItemIdBeingEdited,vehicleId){
  
      //all the wheels with eventType as 'existing' must not have lineItemId
      const dataToPost = tireSpecs.reduce((p, c) => {
        //debugger
        let lineItemId = c.lineItemId; 

        // if(c.eventType == constants.EVENT_TYPE.EXISTING)
        //   lineItemId = null; 

        if(c.eventType == constants.EVENT_TYPE.REPLACED && !c.lineItemId){
          lineItemId = lineItemIdBeingEdited;  
        }

        const obj = {
          id: c.id,
          vehicleId: vehicleId,
          lineItemId: lineItemId,
          axleNumber: c.axleNumber,
          position: c.position,
          eventType: c.eventType,
          unitOfMeasurement: c.unitOfMeasurement,
          treadDepth: c.treadDepth,
          replacedTireTreadDepth: c.replacedTireTreadDepth,
          width: c.width,
          diameter: c.diameter,
          manufacturer: c.manufacturer,
          model: c.model,
          wearOutReason: c.wearOutReason,
          aspectRatio: c.aspectRatio
        }

        if (c.eventType == constants.EVENT_TYPE.EXISTING && !c.treadDepth)
          return p
        else
          return p.concat(obj)

      }, [])

      return dataToPost; 
}
//gets if any wheel from axle has been deleted
function hasAnyWheelBeenDeleted(originalTireSpec, updateTireSpec){
  return originalTireSpec.filter( x=> updateTireSpec.findIndex( y => y.get('id') == x.get('id')) == -1).size > 0
}
export function* saveTireSpecifications(context, action) {
  try {

    const state = yield select();

    // Inform application that data request has started
    yield put({ type: constants.SAVE_TIRE_SPECIFICATIONS_REQUESTED });
    yield put({ type: constants.REMOVE_LINE_ITEM_QTY_MISMATCH_ERR });

    //current line item being edited
    const lineItems = state.appState.getIn(['uiData', 'addEdit', 'lineItems']);
    let lineItemIdBeingEdited = yield* getLineItemIdBeingEdited(lineItems);
    const vehicleId = state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);
    let updatedTireSpec = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'tireSpecification']) || List();
    let originalTireSpec = state.appState.getIn(['serverData', 'addNewLineItemsView', 'tires', 'copyTireSpecification']) || List();
    const defaultUnit = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'defaultTreadDepthUnit']);
    const shouldUpdateAllWithNewUnit = hasTireUnitChanged(defaultUnit, originalTireSpec)
    const isAnyWheelDeleted = hasAnyWheelBeenDeleted(originalTireSpec, updatedTireSpec)
    const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
         
      updatedTireSpec = updatedTireSpec.toJSON()
      originalTireSpec = originalTireSpec.toJSON()

    // //update replaced tires with manufaturing details     
    const tireDetails = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tireDetails']) || Map();
    for (var index = 0; index < updatedTireSpec.length; index++) {
      var element = updatedTireSpec[index];
      if (element.eventType == 'replaced' && element.lineItemId == lineItemIdBeingEdited) {
        element.manufacturer = tireDetails.get(constants.TIRE_DETAILS.MANUFACTURER);
        element.wearOutReason = tireDetails.get(constants.TIRE_DETAILS.REASON);
        element.model = tireDetails.get(constants.TIRE_DETAILS.MODEL);
        element.width = tireDetails.get(constants.TIRE_DETAILS.TIRE_WIDTH);
        element.diameter = tireDetails.get(constants.TIRE_DETAILS.RIM_DIAMETER);
        element.aspectRatio = tireDetails.get(constants.TIRE_DETAILS.ASPECT_RATIO);
      }
    }
    //debugger

    //get the edited items
    let editedItems = getEditedTires(originalTireSpec, updatedTireSpec)

    //get the added items
    let addedItems = getAddedTires(originalTireSpec, updatedTireSpec);

    let changedItems = []; 

    //post every wheel info if any wheel is deleted
    if(isAnyWheelDeleted)    
       changedItems = updatedTireSpec
    else 
       changedItems = [].concat(editedItems).concat(addedItems);

    //debugger
    if (lineItemIdBeingEdited == null) {
      lineItemIdBeingEdited = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'latestLineItemId']) || null;
    }
    
    const dataToPost = getTireDataToPost(updatedTireSpec, lineItemIdBeingEdited,vehicleId)

    const ids = yield call(Api.saveTireSpecifications, poNumber, dataToPost, helperFunctions.getToken(state));

    yield put({ type: constants.RESET_TIRE_SPECIFICATIONS });
    yield put({ type: constants.CLEAR_LINE_ITEM_ID_FOR_TIRES });
    yield put({ type: constants.SAVE_TIRE_SPECIFICATIONS_RESOLVED });
  }

  catch (e) {
    yield put({ type: constants.SAVE_TIRE_SPECIFICATIONS_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CANCEL_PO_REJECTED, errorObject: e });
  }
}

export function* saveNewLineItemWithTireSpecs(context, action) {

  yield call(() => addNewLineItemHandler(action));
  yield call(() => saveTireSpecifications(context, action));

}

export function* fetchTireSpecification(context, action) {
  try {
    const state = yield select();

    // Inform application that data request has started
    yield put({ type: constants.FETCH_TIRE_SPECIFICATIONS_REQUESTED });

    let poNumber = null; 
    if(action.mode == 'view')
     poNumber = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'id']) || null;    
    else 
     poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']) || null;
    //if po data is not present in uiData then get it from serverData
    if (!poNumber)
      poNumber = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'id'])


    const vehicleId = state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);

    //current line item being edited
    const lineItems = state.appState.getIn(['uiData', 'addEdit', 'lineItems']);
    let lineItemIdBeingEdited = yield* getLineItemIdBeingEdited(lineItems);

    let tireSpecs = yield call(Api.fetchTireSpecifications, poNumber, helperFunctions.getToken(state)) || [];
    tireSpecs = tireSpecs.reduce((p, c) => {
      c.tempId = helperFunctions.getTireTempId(lineItemIdBeingEdited, c.axleNumber, c.position);
      return p.concat(c);
    }, []);

    const updatedTireSpecs = tireSpecs;

    //add dummy axles
    const maxAxles = lodash.maxBy(tireSpecs, (tire) => {return tire.axleNumber})

    const maxAxleNumber = !maxAxles || !maxAxles.axleNumber || maxAxles.axleNumber < 2? 2 :maxAxles.axleNumber;   

    let axles = []; 
    for (var index = 2; index <= maxAxleNumber; index++) {
      const shouldAdd = tireSpecs.findIndex(x=> x.axleNumber == index) == -1;

      if(shouldAdd){
        axles = axles.concat(
          {axleNumber:index, position:'left_inner'},
          {axleNumber:index, position:'right_inner'},
        )
      }
    }
    yield put({ type: constants.ADD_DUMMY_AXLE, axles })
    
    

    //debugger
    //--------------------   
    const unit = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'defaultTreadDepthUnit']);

    //udpate the default unit
    let defaultTreadDepthUnit = unit;
    if (!!updatedTireSpecs && updatedTireSpecs.length > 0) {
      defaultTreadDepthUnit = updatedTireSpecs[0].unitOfMeasurement;
    }
    yield* updateTreadDepthMeasurementUnit(context,{defaultTreadDepthUnit: defaultTreadDepthUnit} )

      //--------------------
    //debugger


    yield put({ type: constants.LOAD_TIRE_SPECIFICATION, tireSpecs: updatedTireSpecs })
    yield put({ type: constants.LOAD_COPY_TIRE_SPECIFICATION, tireSpecs })

    //save tire details 
    if (!!action && !!action.lineItemId) {
      const replacedWheels = updatedTireSpecs.filter(x => x.lineItemId == action.lineItemId && x.eventType == constants.EVENT_TYPE.REPLACED);
      if (!!replacedWheels && replacedWheels.length > 0) {
        const tireDetailsForTheLine = replacedWheels[0];
        if (!!tireDetailsForTheLine) {
          const tireDetails = {
            TIRE_WIDTH: tireDetailsForTheLine.width,
            ASPECT_RATIO: tireDetailsForTheLine.aspectRatio,
            RIM_DIAMETER: tireDetailsForTheLine.diameter,
            MANUFACTURER: tireDetailsForTheLine.manufacturer,
            MODEL: tireDetailsForTheLine.model,
            REASON: tireDetailsForTheLine.wearOutReason
          }
          yield put({ type: constants.UPDATE_TIRE_DETAILS_ALL, tireDetails });
        }

      }
    }

    yield put({ type: constants.FETCH_TIRE_SPECIFICATIONS_RESOLVED });
  }
  catch (e) {
    yield put({ type: constants.FETCH_TIRE_SPECIFICATIONS_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CANCEL_PO_REJECTED, errorObject: e });
  }
}

export function* addNewWheel(context, action) {
  try {
    const state = yield select();
    let currentAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'tireSpecification']) || List();
    const vehicleId = state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);
//debugger

    let dummyAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'dummyAxlesToAdd']) || List();
//debugger

    dummyAxles = dummyAxles.concat([
      {
        axleNumber: action.dataForNewWheel.axlePosition,
        position: constants.TIRE_POSITIONS.LEFT_OUTER
      },
      {
        axleNumber: action.dataForNewWheel.axlePosition,
        position: constants.TIRE_POSITIONS.RIGHT_OUTER
      },
    ]);
//debugger

    yield put({ type: constants.ADD_DUMMY_AXLE, axles: dummyAxles })
  }
  catch (e) {

  }
}
export function* updateTreadDepth(context, action) {
    //debugger


  try {
    const state = yield select();
    let currentAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'tireSpecification']) || List();

    let currentAxlesArray = currentAxles.toJSON();
    let idx = currentAxlesArray.findIndex(x => x.tempId == action.treadDepthData.tireId)
    const defaultTreadDepthUnit = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'defaultTreadDepthUnit']);
    //debugger
    if (idx != -1) {

      if (action.treadDepthData.isTireNew){        
        currentAxlesArray[idx].replacedTireTreadDepth = action.treadDepthData.treadDepthValue;
      }
      else{
        currentAxlesArray[idx].treadDepth = action.treadDepthData.treadDepthValue;
      }
      //debugger
    }
    else {
      const dummyWheel = getDummyWheel(action.treadDepthData.tireId, defaultTreadDepthUnit, constants.EVENT_TYPE.EXISTING, action.treadDepthData.treadDepthValue);
      currentAxlesArray = currentAxlesArray.concat(dummyWheel);
      //debugger
    }

    yield put({ type: constants.UPDATE_AXLES, axles: currentAxlesArray })
  }
  catch (e) {

  }
}

function getDummyWheel(tireTempId, defaultTreadDepthUnit, eventType, treadDepth) {
  const wheelInfo = helperFunctions.decodedTireTempId(tireTempId);
  const isEventTypeReplace = eventType == constants.EVENT_TYPE.REPLACED;

  const dummyWheel = getWheelModel(
    tireTempId,
    null,
    null, 
    isEventTypeReplace?wheelInfo.lineItemId:null,
    wheelInfo.axleNumber,
    wheelInfo.tirePosition,
    eventType,
    defaultTreadDepthUnit,
    treadDepth, null, null, null, null, null, null, true, false)

  return dummyWheel;
}

export function* removeAxle(context, action) {
  try {
    const state = yield select();
    //remove axles
    let currentAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'tireSpecification']) || List();
    currentAxles = currentAxles.toJSON().filter(x => x.axleNumber != action.axleToRemove);
    yield put({ type: constants.UPDATE_AXLES, axles: currentAxles });

    //remove dummy axles
    let dummyAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'dummyAxlesToAdd']) || List();
    dummyAxles = dummyAxles.toJSON().filter(x => x.axleNumber != action.axleToRemove);
    yield put({ type: constants.ADD_DUMMY_AXLE, axles: dummyAxles })
  }
  catch (e) {

  }
}

export function* removeWheel(context, action) {
  try {
    const state = yield select();
    let currentAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'tireSpecification']) || List();
    currentAxles = currentAxles.toJSON().filter(x => !(x.axleNumber == action.axlePosition
      && (x.position == constants.TIRE_POSITIONS.LEFT_OUTER || x.position == constants.TIRE_POSITIONS.RIGHT_OUTER)));

    yield put({ type: constants.UPDATE_AXLES, axles: currentAxles })

    //remove dummy wheels
    let dummyAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'dummyAxlesToAdd']) || List();
    dummyAxles = dummyAxles.toJSON().filter(x => !(x.axleNumber == action.axlePosition
      && (x.position == constants.TIRE_POSITIONS.LEFT_OUTER || x.position == constants.TIRE_POSITIONS.RIGHT_OUTER)));
    yield put({ type: constants.ADD_DUMMY_AXLE, axles: dummyAxles })

  }
  catch (e) {

  }
}
export function* addNewAxle(context, action) {
  try {
    const state = yield select();

    let dummyAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'dummyAxlesToAdd']) || List();

    dummyAxles = dummyAxles.concat([
      {
        axleNumber: action.dataForNewWheel.axlePosition,
        position: constants.TIRE_POSITIONS.LEFT_INNER
      },
      {
        axleNumber: action.dataForNewWheel.axlePosition,
        position: constants.TIRE_POSITIONS.RIGHT_INNER
      },
    ]);
    yield put({ type: constants.ADD_DUMMY_AXLE, axles: dummyAxles })

  }
  catch (e) {

  }
}

export function* updateTreadDepthMeasurementUnit(context, action) {
  try {
    const state = yield select();
    let currentAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'tireSpecification']) || List();
    let currentAxlesArray = currentAxles.toJSON();

    for (var index = 0; index < currentAxlesArray.length; index++) {
      currentAxlesArray[index].unitOfMeasurement = action.defaultTreadDepthUnit;
    }

    yield put({ type: constants.SAVE_TREAD_DEPTH_MEASUREMENT_UNIT, defaultTreadDepthUnit: action.defaultTreadDepthUnit })
    yield put({ type: constants.UPDATE_AXLES, axles: currentAxlesArray })

  }
  catch (e) {

  }
}

export function* updateTireSelection(context, action) {
  try {
    const state = yield select();
    let currentAxles = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'tireSpecification']) || List();
    let currentAxlesArray = currentAxles.toJSON();
    const defaultTreadDepthUnit = state.appState.getIn(['uiData', 'addNewLineItemsView', 'tires', 'defaultTreadDepthUnit']);

    //current line item being edited
    const lineItems = state.appState.getIn(['uiData', 'addEdit', 'lineItems']);
    let lineItemIdBeingEdited = yield getLineItemIdBeingEdited(lineItems);
    const wheelInfo = helperFunctions.decodedTireTempId(action.tireIdToBeUpdated);

    let idx = currentAxlesArray.findIndex(x => x.tempId == action.tireIdToBeUpdated)

    if (idx != -1) {
      currentAxlesArray[idx].eventType = isNewTire(currentAxlesArray[idx].eventType) ? 'existing' : 'replaced';
      currentAxlesArray[idx].lineItemId = currentAxlesArray[idx].eventType == 'replaced'? lineItemIdBeingEdited: null;
      currentAxlesArray[idx].tempId = helperFunctions.getTireTempId(lineItemIdBeingEdited, wheelInfo.axleNumber, wheelInfo.tirePosition);
    }
    else {
      const dummyWheel = getDummyWheel(action.tireIdToBeUpdated, defaultTreadDepthUnit, constants.EVENT_TYPE.REPLACED, null);
      currentAxlesArray = currentAxlesArray.concat(dummyWheel);
    }
    yield put({ type: constants.UPDATE_AXLES, axles: currentAxlesArray })


  }
  catch (e) {

  }
}

function getBrakeModel(id, vehicleId, lineItemId, axleNumber, position, measurement, component, unitOfMeasurement, eventType,
  maxDrumDiameter, minRotorThickness) {
  return {
    id: id,
    vehicleId: vehicleId,
    lineItemId: lineItemId,
    axleNumber: axleNumber,
    position: position,
    measurement: measurement,
    component: component,
    unitOfMeasurement: unitOfMeasurement,
    eventType: eventType,
    createdBy: "string",
    createdOn: "2017-05-24T18:00:59.718Z",
    maxDrumDiameter: maxDrumDiameter,
    minRotorThickness: minRotorThickness
  }
}

export function* fetchBrakesSpec(context, action) {
  try {
    const state = yield select();

    // Inform application that data request has started
    yield put({ type: constants.FETCH_BRAKES_SPECIFICATIONS_REQUESTED });
    let poNumber = null; 
    if(action.mode == 'view')
     poNumber = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'id']) || null;    
    else 
     poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']) || null;

    //if po data is not present in uiData then get it from serverData
    if (!poNumber)
      poNumber = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'id'])


    const vehicleId = state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);

    //current line item being edited
    const lineItems = state.appState.getIn(['uiData', 'addEdit', 'lineItems']);
    let lineItemIdBeingEdited = yield* getLineItemIdBeingEdited(lineItems);

    let brakesSpecs = yield call(Api.fetchBrakesSpecifications, poNumber, helperFunctions.getToken(state)) || [];
    const unit = state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'defaultBrakeMeasurementUnit']);

    //udpate the default unit
    let defaultUnit = unit;
    if (!!brakesSpecs && brakesSpecs.length > 0) {
      defaultUnit = brakesSpecs[0].unitOfMeasurement;
    }
    yield put({ type: constants.SAVE_BRAKES_MEASUREMENT_UNIT, defaultUnit })


    //create dummy axles
    const componentTypes = ['shoe', 'drum', 'rotor', 'pad']
    for (var index = 0; index < componentTypes.length; index++) {
      var element = componentTypes[index];

      const brakesForComponent = brakesSpecs.filter(x => x.component == element);
      let maxAxleNumber = 0 ; 

      if(!brakesForComponent)
        maxAxleNumber = 2; 
      else {
        const maxAxles = lodash.maxBy(brakesForComponent, (brake) => {return brake.axleNumber})
        maxAxleNumber = !maxAxles || maxAxles.axleNumber < 3? 2 : maxAxles.axleNumber; 
      }
      //debugger
      for (var axleNumber = 1; axleNumber < maxAxleNumber + 1; axleNumber++) {
        
        const currentAxles = brakesSpecs.filter(x => x.axleNumber == axleNumber && x.component == element);
        //debugger
        if (currentAxles.length == 0) {
          const leftBrake = getBrakeModel(null, vehicleId, '', axleNumber, constants.BRAKE_POSITIONS.LEFT, null,
            element, defaultUnit, null, null);

          const rightBrake = getBrakeModel(null, vehicleId, '', axleNumber, constants.BRAKE_POSITIONS.RIGHT, null,
            element, defaultUnit, null, null);

          const dataForNewBrake = [leftBrake, rightBrake];
          brakesSpecs = brakesSpecs.concat(dataForNewBrake);
        }
        else{
          const leftBrakeExists = brakesSpecs.filter(x => x.axleNumber == axleNumber && x.component == element && x.position== constants.BRAKE_POSITIONS.LEFT).length > 0;
          const rightBrakeExists = brakesSpecs.filter(x => x.axleNumber == axleNumber && x.component == element && x.position== constants.BRAKE_POSITIONS.RIGHT).length > 0;

          const leftBrake = !leftBrakeExists ? getBrakeModel(null, vehicleId, '', axleNumber, constants.BRAKE_POSITIONS.LEFT, null,
            element, defaultUnit, null, null) 
            :undefined;

          const rightBrake =!rightBrakeExists ? getBrakeModel(null, vehicleId, '', axleNumber, constants.BRAKE_POSITIONS.RIGHT, null,
            element, defaultUnit, null, null)
            :undefined;
          
          if(!leftBrakeExists)
            brakesSpecs = brakesSpecs.concat(leftBrake);

          if(!rightBrakeExists)
            brakesSpecs = brakesSpecs.concat(rightBrake);
            
        } 
      }

      
    }




    //------------------------------------

    yield put({ type: constants.LOAD_BRAKES_SPECIFICATION, brakesSpecs })
    yield put({ type: constants.LOAD_COPY_BRAKES_SPECIFICATION, brakesSpecs })

    yield put({ type: constants.FETCH_BRAKES_SPECIFICATIONS_RESOLVED });
  }
  catch (e) {
    yield put({ type: constants.FETCH_BRAKES_SPECIFICATIONS_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CANCEL_PO_REJECTED, errorObject: e });
  }
}
//gets if any wheel from axle has been deleted
function hasAnyBrakesBeenDeleted(originalBrakesSpec, updatedBrakesSpec){
  return originalBrakesSpec.filter( x=> updatedBrakesSpec.findIndex( y => y.id == x.id) == -1).length > 0
}

export function* saveBrakesInfo(context, action) {

  try {

    const state = yield select();

    // Inform application that data request has started
    yield put({ type: constants.SAVE_BRAKE_SPECIFICATIONS_REQUESTED });

    //current line item being edited
    const lineItems = state.appState.getIn(['uiData', 'addEdit', 'lineItems']);
    let lineItemIdBeingEdited = yield* getLineItemIdBeingEdited(lineItems);
    const vehicleId = state.appState.getIn(['serverData', 'shared', 'vehicleDetails', 'id']);

    let updatedBrakesSpec = state.appState.getIn(['uiData', 'addNewLineItemsView', 'brakes', 'brakeSpec']) || List();
    updatedBrakesSpec = updatedBrakesSpec.toJSON() || [];

    let originalBrakesSpec = state.appState.getIn(['serverData', 'addNewLineItemsView', 'brakes', 'copyBrakeSpecification']) || List();
    originalBrakesSpec = originalBrakesSpec.toJSON() || [];

    const isAnyBrakesDeleted = hasAnyBrakesBeenDeleted(originalBrakesSpec, updatedBrakesSpec);
    //get the edited items
    let editedItems = updatedBrakesSpec.filter(x => {

      let item = originalBrakesSpec.find(y => y.axleNumber == x.axleNumber && y.position == x.position && y.component == x.component && y.id == x.id && !!x.id);
   
      if (!item)
        return false
      else
        if(!x.measurement)
          return false
        else
          return true
    })
//debugger

    //get the added items
    let addedItems = updatedBrakesSpec.filter(x =>
      !x.id && !!x.measurement
    );

    let changedItems = []; 
      changedItems = [].concat(editedItems).concat(addedItems);
//debugger

    if (lineItemIdBeingEdited == null) {
      lineItemIdBeingEdited = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'latestLineItemId']) || null;
    }


    //if (!!lineItemIdBeingEdited) {
      //all the wheels with eventType as 'existing' must not have lineItemId
      const dataToPost = changedItems.reduce((p, c) => {
        const obj = {
          id: c.id,
          vehicleId: vehicleId,
          lineItemId: c.eventType == constants.EVENT_TYPE.EXISTING ? null : lineItemIdBeingEdited,
          axleNumber: c.axleNumber,
          position: c.position,
          eventType: c.eventType,
          measurement: c.measurement,
          maxDrumDiameter: !c.maxDrumDiameter ? null : c.maxDrumDiameter,
          minRotorThickness: !c.minRotorThickness ? null : c.minRotorThickness,
          component: c.component,
          unitOfMeasurement: c.unitOfMeasurement,
        }
        return p.concat(obj)

      }, [])
//debugger
      const poNumber = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'id']);
      const ids = yield call(Api.saveBrakesInfo, poNumber, dataToPost, helperFunctions.getToken(state));
      yield put({ type: constants.RESET_BRAKE_SPECIFICATIONS });
      //yield put({ type: constants.CLEAR_LINE_ITEM_ID_FOR_TIRES });
    //}

    yield put({ type: constants.SAVE_BRAKE_SPECIFICATIONS_RESOLVED });
  }

  catch (e) {
    yield put({ type: constants.SAVE_BRAKE_SPECIFICATIONS_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.CANCEL_PO_REJECTED, errorObject: e });
  }


}
export function* saveNewLineItemWithBrakes(context, action) {
  yield call(() => addNewLineItemHandler(action));
  yield call(() => saveBrakesInfo(context, action));
}

export function* sendLaborRateChangeRequest(context,action){
  try{    
    
    const state = yield select();
    if(action.flag=='Y'){
      
    const vendorId=state.appState.getIn(['serverData', 'shared', 'vendorId']);
    yield call(Api.saveVendorLaborRateInformation, action.laborRate, vendorId,helperFunctions.getToken(state));    

    const laborRateDetails=yield call(Api.fetchVendorLaborRateInformation, vendorId, helperFunctions.getToken(state));
    
    yield put({
        type:  "SAVE_VENDOR_LABOR_RATE_DETAILS",
        laborRateDetails: laborRateDetails
    });
  }
  
 
    yield put({
      type:  "SAVE_LABOR_RATE_WINDOW_SHOWN_FOR_SESSION"      
  });    
  
  }
  catch(e){
    //yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
    //yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: 'LABOR_RATE_CHANGE_REJECTED', errorObject: e });
  }
}