import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';
import { zeroIfUndefined } from 'utils/HelperFunctions';

export function uiDataDealerDraftDealerRequests(state = initialAppState.getIn(['uiData', 'dealerDraftDealerRequests']), action) {
    switch (action.type) {
        case "FETCH_DEALER_DEALER_REQUESTS_REQUESTED":
            return state.set('isFetchingDealerRequests', true);
        case "FETCH_DEALER_DEALER_REQUESTS_ACCEPTED":
            return state.set('isFetchingDealerRequests', false).set('dealerRequestDetails',fromJS(action.dealerRequests));
        case "FETCH_DEALER_DEALER_REQUESTS_REJECTED":
            return state.set('isFetchingDealerRequests', false);
        case "TOGGLE_SHOW_ALL_ACTIVE_REQUESTS":
            return state.set('showAllActiveRequests', !state.get('showAllActiveRequests'));
        case "TOGGLE_SHOW_ALL_PROCESSED_REQUESTS":
            return state.set('showAllProcessedRequests', !state.get('showAllProcessedRequests'));
        default:
            return state;
    }
}