import { Map, fromJS } from 'immutable';
import * as constants from 'constants/App';
import * as AddNewLineItemsServices from '../services/AddNewLineItemsView';
import * as sharedActions from '../../../../../../shared/actions/App';

/*Updates selected or searched ata code*/
export function updateATACode(ataCode) {
    return {type: constants.UPDATE_ATA_CODE, ataCode}
}

/*Updates correction type*/
export function updateCorrectionType(correctionType, hourlyLaborRate) {
    return {type: constants.UPDATE_SELECTED_CORRECTION_TYPE, correctionType, hourlyLaborRate}
}

/*Adds correction details - qty, cost, hours, rate*/
export function addCorrectionDetails(correctionDetails) {
    return {type: constants.ADD_CORRECTION_DETAILS, correctionDetails}
}

/*Adds new line item data to PO */
export function addNewLineItemToPO(lineItems) {
    return {type: constants.ADD_NEW_LINE_ITEM_TO_PO_DATA, lineItems}
}

/*Adds new line item data to PO */
export function addDiagnosticLineItemToPO(lineItems) {
    return {type: "ADD_DIAGNOSIS_LINE_ITEM_TO_PO_DATA", lineItems}
}

/* Toggles the visibility of Add new line items modal*/
export function toggleShowWindow() {
    return {type: constants.TOGGLE_SHOW_MODAL_ADD_NEW_LINE_ITEMS}
}

export function canShowAtaSearchToolTip(){
    return {type: constants.CAN_SHOW_ATA_SEARCH_TOOL_TIP, canShow:true}
}
/*Adds complaint code*/
export function addComplaintCode(complaintCode) {
    return {type: constants.ADD_COMPLAINT_CODE, complaintCode}
}

/*Resets add new line item data*/
export function resetAddNewLineItemData() {
    return {type: constants.RESET_ADD_NEW_LINE_ITEM_DATA}
}

/*Updates searched ata code description*/
export function updateSearchedATADescription(value) {
    return {type: constants.AUTO_COMPLETE_ATA_CODE_DESCRIPTION, value}
}

//Hide shows ata code search result container
export function hideContainer(hide){
    return {type: constants.HIDE_ATA_SEARCH_RESULTS_CONTAINER, hide};
}

//Loads searched ata codes ata data into state
export function LoadATACodesData(ataCodes) {
    return { type: constants.LOAD_SEARCHED_ATA_CODES_DATA, ataCodes }
}

//Clears ata code search key
export function clearATACodesBySearchKey() {
    return { type: constants.CLEAR_ATA_CODES_BY_SEARCH };
}

//Resets add new line item data in server data
export function clearAddLineItemsServerData() {
    return { type: constants.CLEAR_ADD_LINE_ITEMS_SERVER_DATA };
}

//Loads frequently used ata codes
export function addFrequentlyUsedATACode(freqentlyUsedATACodes) {
    return { type: constants.LOAD_FREQUENTLY_USED_ATA_CODES, freqentlyUsedATACodes };
}


/*Load frequently used ata codes into the app state*/
export function LoadFrequentlyUsedATACode(componentName, vehicleId, complaintCode) {

    return function (dispatch, getState) {
        dispatch(sharedActions.fetchDataFromAPIStart(componentName));
        AddNewLineItemsServices.getFrequentlyUsedATACodes(
            componentName,
            vehicleId,
            complaintCode,
            getState(),
            function (err, serviceResult) {

                if (err) {
                    sharedActions.stopSpinnerAndDisplayError(componentName, err, dispatch);
                    return false;
                }                

                //prepare ATA codes data to be stored in the master list
                let finalSetOfATACodes = serviceResult.frequentlyUsedATACodes.reduce((p, c) => {
                    return p.set(c.code, Map({
                        productCode: c.code,
                        description: c.description,
                        complaintCode: c.complaintCode,
                        popularity:c.popularity,
                        correctionTypesRestricted: c.correctionTypesRestricted,
                        correctionAndRepairTypesRestricted:c.correctionAndRepairTypesRestricted,
                        multipleLineItemsPermitted:c.multipleLineItemsPermitted                        
                    }));
                }, Map());
                
                //save frequently used ata codes to the state
                dispatch(addFrequentlyUsedATACode(finalSetOfATACodes));

                dispatch(sharedActions.fetchDataFromAPIComplete(componentName));
            }
        );

    };
}