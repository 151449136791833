// Libraries
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl';
// Our stuff
import './ReturnButton.css';

const ReturnButton = ({onClick}) => {
    const messages = defineMessages({
            documents_return_button: {
                id: 'documents_return_button',
                description: 'Return button',
                defaultMessage: 'Return'
            }
        });

    return <div className='documents__return'>
        <span onClick={onClick} className='documents__return_button' role="button">
            <img src="/assets/VMS_33button_back_blue.png"/>{' '}            
            <FormattedMessage {...messages.documents_return_button}/>                
        </span>        
    </div>
}

export default ReturnButton;