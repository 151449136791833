// libraries
import { call, put, select, take, cancel, fork,all } from 'redux-saga/effects'
import {Map,fromJS} from 'immutable';
// our stuff
import * as Api from 'utils/Api';
import * as constants from 'constants/App';
import {emptyMapIfUndefined} from 'utils/HelperFunctions';
import * as helperFunctions from 'utils/HelperFunctions';

export function* fetchData(context,action) {
    try {
        const state = yield select();
        const token = helperFunctions.getToken(state);
        
        // Inform application that PO data is being fetched
        yield put({ type: constants.FETCH_ACTION_REQUIRED_DATA_REQUESTED });

        const vendorId=state.appState.getIn(['serverData', 'shared', 'vendorId']);
        const [purchaseOrder, lineItems, vehicle, vendor, complaints, notes,adjustmentStatus] = yield all([
            call(Api.fetchPurchaseOrder, action.poNumber, token),
            call(Api.fetchLineItems, action.poNumber, token,helperFunctions.getSelectedLanguageCode(state), true),
            call(Api.fetchVehicle, action.poNumber, token),
            call(Api.fetchVendor, action.poNumber, token),
            call(Api.fetchComplaints, action.poNumber, token, state),
            call(Api.fetchComplaintNotes, action.poNumber, token),
            call(Api.fetchPOAdjustmentStatus, action.poNumber, token)
        ]);
              
        var productDescription='';

        if(notes != undefined ) {
            for (var key in notes) {                
                productDescription='';
                    for(var lineKey in lineItems){
                        if(lineItems[lineKey].id==notes[key].lineItemId)                        
                        productDescription=lineItems[lineKey].vendorProductCode == null ? lineItems[lineKey].productDescription +', '+ (lineItems[lineKey].lineItemType == constants.LINE_ITEM_TYPE.PM ? constants.LINE_ITEM_TYPE.PM_TEXT : lineItems[lineKey].lineItemType.toUpperCase()) :  lineItems[lineKey].vendorProductCode + ' - ' + lineItems[lineKey].productDescription +', '+ (lineItems[lineKey].lineItemType == constants.LINE_ITEM_TYPE.PM ? constants.LINE_ITEM_TYPE.PM_TEXT : lineItems[lineKey].lineItemType.toUpperCase());   
                    }
                    notes[key].vendorId=vendorId;
                    notes[key].productDescription=productDescription;
                  
            }
        }
        
        
        if(purchaseOrder.authorizationStatus==constants.PO_AUTHORIZATION_STATUS.WAITING_FOR_VENDOR_APPROVAL ||
            purchaseOrder.authorizationStatus==constants.PO_AUTHORIZATION_STATUS.APPROVAL_NOT_REQUESTED ) {

            // Prepare data
            const purchaseOrderData = {
                purchaseOrder: purchaseOrder,
                vehicleDetails: vehicle,
                vendor: vendor,
                complaints: complaints,
                notes: notes,
                lineItems: lineItems,
                adjustmentStatus: adjustmentStatus
            };

            yield put({type: constants.FETCH_ACTION_REQUIRED_DATA_RESOLVED, purchaseOrderData});
        }
        else {


            yield put({type: constants.FETCH_ACTION_REQUIRED_DATA_REJECTED});
            yield call(context.history.push,'/');
        }

    }
    catch (e) {
        yield put({ type: constants.FETCH_ACTION_REQUIRED_DATA_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e) });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_ACTION_REQUIRED_DATA_REQUESTED, errorObject: e });
    }
}

export function* rejectPOAdjustments(context, action){
    try {

        yield put({ type: constants.REJECT_PO_ADJUSTMENTS_REQUESTED });

        // Read data from app state
        const state = yield select();

        const poNumber = state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'id']);

        if (!action.isHsbClientVehicle) {
            yield call(Api.rejectAdjustments, poNumber, helperFunctions.getToken(state));
        } else {
            yield call(Api.sendOrderToCustomer, poNumber, action.sendOrderToCustomerRequestBody, helperFunctions.getToken(state));
        }
        yield call(context.history.push, '/');


        yield put({ type: constants.ACTION_REQUIRED_CANCEL_PO_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.ACTION_REQUIRED_CANCEL_PO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.ACCEPT_ADJUSTMENT_REQUESTED, errorObject: e });
    }
}


export function* cancelPO(context) {
    try {
        
        yield put({ type: constants.ACTION_REQUIRED_CANCEL_PO_REQUESTED });

        // Read data from app state
        const state = yield select();
        const poNumber = state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'id']);
        
        yield call(Api.cancelPo, poNumber, helperFunctions.getToken(state));
        yield call(context.history.push,'/');
        

        yield put({ type: constants.ACTION_REQUIRED_CANCEL_PO_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.ACTION_REQUIRED_CANCEL_PO_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e)});
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.ACCEPT_ADJUSTMENT_REQUESTED, errorObject: e });
    }
}

export function* submitPOAcknowledgements(context, action) {
    try {
        yield put({ type: constants.ACKNOWLEDGE_LINE_ITEMS_REQUESTED });

        // Read data from app state
        const state = yield select();
        const poNumber = state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'id']);
        const isClientAuthorizationRequired = state.appState.getIn(['serverData', 'actionRequired', 'purchaseOrder', 'isClientAuthorizationRequired']) == 'Y';
        const poHasVendorNotes = action.lineItems.filter(li =>
            li.get('approvalStatus') == constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES).count() > 0;
        const poHasPendingLines = action.lineItems.filter(li =>
            li.get('approvalStatus') == constants.LINE_ITEM_STATUS.PENDING).count() > 0;
        //if vendor accepted adjustments or rejection, then call acknowledge item
        //else if vendor provided notes, then call update notes
        //Also acknowledge associated lines      


        var acknowledgeLinesAndNotes = action.lineItems
            .map(li => li.get('approvalStatus') == constants.LINE_ITEM_STATUS.WAITING_FOR_VENDOR_NOTES ?
                call(Api.SubmitVendorNotes, poNumber, li.get('id'), li.get('vendorNote'), helperFunctions.getToken(state)) :
                call(Api.acknowledgeLineItem, poNumber, li.get('id'), helperFunctions.getToken(state)))
            .toArray();

        yield all(acknowledgeLinesAndNotes);

        var associatedAcknowledgedLine = action.associatedPendingLines
            .map(li =>
                call(Api.acknowledgeLineItem, poNumber, li.get('id'), helperFunctions.getToken(state)))
            .toArray();

        yield all(associatedAcknowledgedLine);

        if (action.cancelPo) {
            yield call(Api.cancelPo, poNumber, helperFunctions.getToken(state));
            yield call(context.history.push, '/');
        }
        else {
            if (poHasVendorNotes)
                yield call(Api.requestApproval, poNumber, helperFunctions.getToken(state), helperFunctions.getSelectedLanguageCode(state));

            var orderInfo = yield call(Api.fetchPurchaseOrder, poNumber, helperFunctions.getToken(state));

            if (orderInfo.authorizationStatus == constants.PO_AUTHORIZATION_STATUS.APPROVED)
                yield call(context.history.push, `/PO/edit/${poNumber}`);
            else
                yield call(context.history.push, `/PO/view/${poNumber}`);

        }
        //yield call(context.history.push,'/');
        yield put({ type: constants.ACKNOWLEDGE_LINE_ITEMS_RESOLVED });
    }
    catch (e) {
        yield put({ type: constants.ACKNOWLEDGE_LINE_ITEMS_REJECTED, errorMessage: helperFunctions.getCustomErrorObject(e), adjustedLineItems: action.adjustedLineItems });
        yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: helperFunctions.getGenericErrorObject(e) });
        yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.ACCEPT_ADJUSTMENT_REQUESTED, errorObject: e });
    }
}