import React from 'react';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import Spinner from 'components/Spinner';
import {falseIfUndefined, emptyMapIfUndefined, emptyStringIfUndefined,zeroIfUndefined} from 'utils/HelperFunctions';
import VehicleSearch from '../components/VehicleSearchResult';
import {connect} from "react-redux";
import * as constants from 'constants/App';
import './VehicleSearchResultsView.css';
import {withRouter} from 'react-router-dom'

class VehiclesSearchResultsView extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    multiVehicleTitleHandler() {
        const {formatMessage} = this.props.intl;
        const messages = defineMessages({
            multi_veh_title_repair_po: {
                id: 'multi_veh_title_repair_po',
                description: 'multi_veh_title_repair_po',
                defaultMessage: 'Your search has returned multiple vehicles with the same information. Please select the vehicle you are working on from the following list in order to proceed to start your po creation.'
            }, multi_veh_title_carwash_po: {
                id: 'multi_veh_title_carwash_po',
                description: 'multi_veh_title_carwash_po',
                defaultMessage: 'Your search has returned multiple vehicles with the same information. Please select the vehicle you are working on from the following list in order to proceed to start your car wash po creation.'
            }, multi_veh_title_search_vin: {
                id: 'multi_veh_title_search_vin',
                description: 'multi_veh_title_search_vin',
                defaultMessage: 'Your search has returned multiple vehicles with the same information. Please select the vehicle you are working on from the following list in order to proceed.'
            }

        });
        if (window.location.href.includes("/CarWashCreatePO")) {
            return formatMessage(messages.multi_veh_title_carwash_po);
        }
        else if (window.location.href.includes("/SearchPO")) {
            return formatMessage(messages.multi_veh_title_search_vin);
        }
        else if (window.location.href.includes("/RepairCreatePO")) {
            return formatMessage(messages.multi_veh_title_repair_po);
        }

    }


    onNextStep(ariUnitNo,clientId,vehicleId){

        //save your selected vehicle if multiple are returned
        if(this.props.multiVehicleDetails !== undefined ) {
            const selectedVehicle = this.props.multiVehicleDetails.filter((vehicleSelected) => {

                return vehicleSelected.get("id") === Number(vehicleId);

            });
            if(window.location.href.includes("/SearchPO")){
                this.props.dispatch({
                    type: constants.SEARCH_FOR_POS_BY_RESOLVED,
                    vehicleDetails: selectedVehicle.first()
                });
            }else {
                this.props.dispatch({
                    type: constants.SAVE_VEH_DATA_FOR_PO_CREATION_DATA,
                    vehicleDetails: selectedVehicle.first()
                });
            }
        }

        if(window.location.href.includes("/CarWashCreatePO")){
            this.props.dispatch({ type: "FETCH_CAR_WASH_DETAILS", clientNumber:  clientId, unitNumber: ariUnitNo });

        }
        else if (window.location.href.includes("/SearchPO")){
            this.props.dispatch({ type: constants.SEARCH_FOR_PO_BY_VIN8_AND_VEHICLEID, vehicleId });
        }
        else if (window.location.href.includes("/RepairCreatePO")) {
            if (this.props.existingOrder === 0) {
                this.props.history.push(`/po/odometer/${vehicleId}`);
            }
        }

    }

    render() {

        return <div>
            <div className="vehicle_search_Result_view__title">
            {this.multiVehicleTitleHandler()}
            </div>
              <VehicleSearch
                  vehicleDetails={this.props.multiVehicleDetails}
                  serviceClassification={this.props.serviceClassification}
                  onNextStep={this.onNextStep.bind(this)}
                  existingOrder={this.props.existingOrder}
              />

        </div>

    }
}


function mapStateToProps(state, props) {
    return {
        serviceClassification: state.appState.getIn(['serverData', 'shared', 'serviceClassification']),
        multiVehicleDetails: emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'multiVehicleDetails'])),
        existingOrder:zeroIfUndefined(state.appState.getIn(['serverData', 'shared', 'vehicleDetails','existingOrderWaitingOrApprovedAuthorization']))
    };
}

export default connect(mapStateToProps) (injectIntl(withRouter(VehiclesSearchResultsView)));