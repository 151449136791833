import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { Map, fromJS } from 'immutable';

export function uiDataVendorEnrollmentView(state = initialAppState.getIn(['uiData', 'vendorEnrollmentView']), action) {
    switch (action.type) {
        case constants.SUBMIT_VENDOR_ENROLLMENT_DETAIL_REQUESTED:
            return state.set('isSubmitting', true)
                .set('errorMessage', []);
        case constants.SUBMIT_VENDOR_ENROLLMENT_DETAIL_RESOLVED:
            return state.set('isSubmitting', false)
                .set('referenceNumber', action.response.referenceNumber)
                .set('requestSubmitted', true);
        case constants.SUBMIT_VENDOR_ENROLLMENT_DETAIL_REJECTED:
            return state.set('isSubmitting', false)
                .set('errorMessage', action.errorMessage);
        case constants.RESET_ENROLLMENT_INFO:
            return state.set('referenceNumber', undefined )
                .set('requestSubmitted', false)
                .set('enrollmentDetailsInfo', Map());
        case constants.UPDATE_ENROLLMENT_DETAIL_INFO:        
            let fieldName = action.requestInfo.name;            
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BUSINESS_NAME)
                return state.setIn(['enrollmentDetailsInfo', 'businessName'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.CONTACT_NAME)               
                return state.setIn(['enrollmentDetailsInfo', 'contactName'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.TERMS_READ_AND_AGREED)
                return state.setIn(['enrollmentDetailsInfo', 'termsReadAndAgreed'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BUSINESS_NAME_DBA)
                return state.setIn(['enrollmentDetailsInfo', 'businessNameDba'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SELECTED_TAX_CLASSIFICATION)
                return state.setIn(['enrollmentDetailsInfo', 'selectedTaxClassification'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.LIABILITY_TAX_CLASSIFICATION)
                return state.setIn(['enrollmentDetailsInfo', 'liabilityTaxClassification'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SSN_AREA)
                return state.setIn(['enrollmentDetailsInfo', 'ssnArea'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SSN_GROUP)
                return state.setIn(['enrollmentDetailsInfo', 'ssnGroup'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SSN_SERIAL)
                return state.setIn(['enrollmentDetailsInfo', 'ssnSerial'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.EMPLOYER_ID_FIRST_PART)
                return state.setIn(['enrollmentDetailsInfo', 'employerIdFirstPart'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.EMPLOYER_ID_SECOND_PART)
                return state.setIn(['enrollmentDetailsInfo', 'employerIdSecondPart'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.CLIENT_REFERENCE_NAME)
                return state.setIn(['enrollmentDetailsInfo', 'clientReferenceName'], action.requestInfo.value!=undefined?action.requestInfo.value.toUpperCase():action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.ID)
                return state.setIn(['enrollmentDetailsInfo', 'id'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.SERVICE_CLASSIFICATION)
                return state.setIn(['enrollmentDetailsInfo', 'serviceClassification'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BUSINESS_NUMBER)
                return state.setIn(['enrollmentDetailsInfo', 'businessNumber'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.VENDOR_LABOR_RATE)
                return state.setIn(['enrollmentDetailsInfo', 'ariStandardRate'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.BODY_SHOP_RATE)
                return state.setIn(['enrollmentDetailsInfo', 'bodyShopRate'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.MOBILE_RATE)
                return state.setIn(['enrollmentDetailsInfo', 'mobileRate'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.EQUIPMENT_RATE)
                return state.setIn(['enrollmentDetailsInfo', 'equipmentRate'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.LIGHT_DUTY_RATE)
                return state.setIn(['enrollmentDetailsInfo', 'lightDutyRate'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.MEDIUM_DUTY_RATE)
                return state.setIn(['enrollmentDetailsInfo', 'mediumDutyRate'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.HEAVY_DUTY_RATE)
                return state.setIn(['enrollmentDetailsInfo', 'heavyDutyRate'], action.requestInfo.value);
            if (fieldName == constants.VENDOR_ENROLLMENT_DETAIL_FIELD_NAME.TRAILER_RATE)
                return state.setIn(['enrollmentDetailsInfo', 'trailerRate'], action.requestInfo.value);

            else
                return state;
        case constants.UPDATE_PRE_LOAD_DETAIL_INFO:
            return state.setIn(['enrollmentDetailsInfo', 'businessNameDba'], action.detailInfo.businessName)
                .setIn(['enrollmentDetailsInfo', 'contactName'], action.detailInfo.contactName)
                .setIn(['enrollmentDetailsInfo', 'serviceClassification'], "REPAIR")
                .setIn(['enrollmentDetailsInfo', 'id'], action.detailInfo.id);
        case constants.ENROLLMENT_FORM_IS_DIRTY:
            return state.set('isDirty', action.isDirty);
        case "SELECTED_SERVICE_CLASSIFICATION":
            return state.set('selectedServiceClassification', action.isSelectedBusiness, !state.get('selectedServiceClassification', action.isSelectedBusiness));
        default:
            return state;
    }
}