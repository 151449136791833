import {createSelector} from 'reselect';
import {Map, List} from 'immutable';
import {LINE_ITEM_STATUS, LINE_ITEM_TYPE} from 'constants/App';
import moment from 'moment';
import {emptyStringIfUndefined, emptyMapIfUndefined, falseIfUndefined} from 'utils/HelperFunctions';
import * as constants from 'constants/App';

const isfetchingDataSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view')
        return falseIfUndefined(state.appState.getIn(['serverData', 'view', 'isFetchingData']));
    else
        return falseIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'isFetchingData']));
};

const isRequestingApprovalSelector = state => state.appState.getIn(['uiData', 'addEdit', 'isRequestingApproval']);

const odometerDataSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'odometerView']));

const userLocaleSelector = state => state.appState.getIn(['uiData', 'shared', 'selectedLocale']);

const canShowSelector = (state, props)=> {
    let purchaseOrderVendorId;
    let currentVendorId;

    //get current purchase order vendor id
    if (props.routeParams.match.params.actionType == 'view')
        purchaseOrderVendorId = emptyStringIfUndefined(state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'vendorId']));
    else
        purchaseOrderVendorId = emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'vendorId']));

    //get current logged-in vendor id
    currentVendorId = emptyStringIfUndefined(state.appState.getIn(['serverData', 'shared', 'vendorId']));
    return purchaseOrderVendorId != '' ? currentVendorId == purchaseOrderVendorId : true;
}

const bankAccountSelector = (state, props) => {
    var bankAccountNo = null;
    if (props.routeParams.match.params.actionType == 'view')
        bankAccountNo = state.appState.getIn(['serverData', 'view', 'vendor', 'bankAccountNumber']);
    else
        bankAccountNo = state.appState.getIn(['uiData', 'addEdit', 'vendor', 'bankAccountNumber']);

    return bankAccountNo == null ? '' : bankAccountNo;
};

const estimatedCompletionDateSelector = (state, props) => {
    var estimatedDate = null;
    if (props.routeParams.match.params.actionType == 'view')
    {
        estimatedDate = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'estimatedCompletionDate']);            
    }
    else
        estimatedDate = state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'estimatedCompletionDate']);            

    estimatedDate = moment(estimatedDate).isValid() && estimatedDate!=undefined ? moment(estimatedDate) : undefined; 
    
    return estimatedDate;
};

const purchaseOrderSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view')
        return emptyMapIfUndefined(state.appState.getIn(['serverData', 'view', 'purchaseOrder']));
    else
        return emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder']));
};

const vehicleDetailsSelector = (state, props) => {

    if (props.routeParams.match.params.actionType == 'view') {
        return emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'vehicleDetails']));
    }
    else {
        let vehicleDetails = emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'vehicleDetails']));
        let newOdometer = state.appState.getIn(['uiData', 'odometerView', 'newOdometer']);
        let newHourmeter = state.appState.getIn(['uiData', 'odometerView', 'newEngineHours']);
        if (!isNaN(newOdometer))
            vehicleDetails = vehicleDetails.setIn(['mileage'], newOdometer);
        if (!isNaN(newHourmeter))
            vehicleDetails = vehicleDetails.setIn(['engineHours'], newHourmeter);
        return vehicleDetails;
    }
};

const clientDetailsSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view')
        return emptyMapIfUndefined(state.appState.getIn(['serverData', 'view', 'client']));
    else
        return emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'client']));
};

const complaintsSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view')
        return emptyMapIfUndefined(state.appState.getIn(['serverData', 'view', 'complaints']));
    else
        return emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints']));
}
const complaintTypesSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData', 'shared', 'complaintTypes']));
const canAddAnotherComplaintSelector = (state, props)=> {
    if (props.routeParams.match.params.actionType == 'view')
        return false;
    else
    // If no open complaints and none were skipped, allow user to add another complaint.
    // Basically this will limit user to only adding 1 new complaint at a time.
        return emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints']))
                .count(complaint=>complaint.get('isComplaintOpen') || complaint.get('wasComplaintSkipped')) == 0
            && emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'complaints'])).count() < 15;
};

const lineItemsSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view')
        return emptyMapIfUndefined(state.appState.getIn(['serverData', 'view', 'lineItems']));
    else
        return emptyMapIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'lineItems']));
};

const approvedAmountTotalSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view') {
        let approvedAmount = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'authorizedAmount']);
        return approvedAmount == undefined ? 0 : approvedAmount;
    }
    else
        return calculateApprovedTotal(state);
};

const pendingAmountTotalSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view') {
        let pendingAmount = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'pendingAmount']);
        return pendingAmount == undefined ? 0 : pendingAmount;
    }
    else
        return calculatePendingTotal(state);
};

const taxAmountSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view') {
        let taxAmount = state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'taxAmount']);
        return taxAmount == undefined ? 0 : taxAmount;
    }
    else
        return calculateTaxTotal(state);
};

const calculateApprovedTotal = (state) => {
    return state.appState.getIn(['uiData', 'addEdit', 'lineItems']) == undefined ? 0 :
        state.appState.getIn(['uiData', 'addEdit', 'lineItems'])
            .filter(li => li.get('approvalStatus') == LINE_ITEM_STATUS.APPROVED
            && !(li.get('lineItemType') == LINE_ITEM_TYPE.TAX
            || li.get('lineItemType') == LINE_ITEM_TYPE.CANADA_SALES_TAX))
            .reduce(sum, 0);
};

const calculatePendingTotal = (state) => {
    return state.appState.getIn(['uiData', 'addEdit', 'lineItems']) == undefined ? 0 :
        state.appState.getIn(['uiData', 'addEdit', 'lineItems'])
            .filter(li => li.get('approvalStatus') == LINE_ITEM_STATUS.PENDING
            && !(li.get('lineItemType') == LINE_ITEM_TYPE.TAX
            || li.get('lineItemType') == LINE_ITEM_TYPE.CANADA_SALES_TAX))
            .reduce(sum, 0);
};

const calculateTaxTotal = (state) => {
    return state.appState.getIn(['uiData', 'addEdit', 'lineItems']) == undefined ? 0 :
        state.appState.getIn(['uiData', 'addEdit', 'lineItems'])
            .filter(li => li.get('lineItemType') == LINE_ITEM_TYPE.TAX
            || li.get('lineItemType') == LINE_ITEM_TYPE.CANADA_SALES_TAX)
            .reduce(sum, 0);
};

const sum = (total, item) => {
    return total + item.get('totalAmount');
};

/* Check if customized description available otherwise
 read csv string from selected services*/
const selectedServicesDescriptionSelector = (state, props) => {
    /*DO NOT REMOVE THE BELOW COMMENTED CODE*/
    /*Services comments below will be shown once we have more clarity from the BUSINESS */
    /*    var comments = state.appState.getIn(['uiData', 'addEdit', 'complaints', 'MA99', 'Comment']);
     if (comments == undefined) {
     comments = state.appState.getIn(['serverData', 'complaintView', 'services'])
     .reduce(servicesDescriptionDataTransformer,
     '', state.appState.getIn(['uiData', 'complaintView', 'servicesSelected']));
     if (props.routeParams.params.actionType == 'view')
     comments = comments.length > 0 ? 'Holman states that following services are due: ' + comments : null;
     else
     comments = null;
     }
     return comments == undefined || comments == null ? '' : comments;*/

    return '';
};

// The 'selectedServices' are available in 'this', as they are passed in as 'context' by the selector
function servicesDescriptionDataTransformer(complaintDescription, service) {
    // check if service is selected or not - 'selectedServices'.
    if (this.filter(s => s === service.get('serviceId')).count() > 0)
        return complaintDescription.length > 0 ?
        complaintDescription + ', ' + service.get('serviceName')
            : service.get('serviceName');
    else return complaintDescription;
}

const authorizedStatusSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view')
        return emptyStringIfUndefined(state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'authorizationStatus']));
    else
        return emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'authorizationStatus']));
};

const entityForAssistanceSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view')
        return emptyStringIfUndefined(state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'entityForAssistance']));
    else
        return emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'entityForAssistance']));
};

const paymentStatusSelector = (state, props) => {
    if (props.routeParams.match.params.actionType == 'view')
        return emptyStringIfUndefined(state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'paymentStatus']));
    else
        return emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'paymentStatus']));
};

const isDirtyPOSelector = state => state.appState.getIn(['uiData', 'addEdit', 'isDirty']);

const skippingComplaintForComplaintKeySelector = state => emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'skippingComplaintForComplaintKey'])); 
const creatingOrEditingComplaintForComplaintKeySelector = state => emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'creatingOrEditingComplaintForComplaintKey']));

const isEstimateDateValidSelector = state => {
    if(state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder','isEstimateDateValid'])==undefined)
        return true; 
    else 
        return state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder','isEstimateDateValid']); 
}

const lineItemIdBeingEditedSelector = (state, props) => {

    var lineItemIdBeingEdited = undefined;

    if (props.routeParams.match.params.actionType != 'view') {
        const lineItems = state.appState.getIn(['uiData', 'addEdit', 'lineItems']);

        if (lineItems !== undefined) {
            // We can have only one line being edited at a time
            const lineBeingEdited = state.appState.getIn(['uiData', 'addEdit', 'lineItems'])
                .filter(li => li.get('isBeingEdited') == true).first();

            if (lineBeingEdited !== undefined)
                lineItemIdBeingEdited = lineBeingEdited.get('lineItemId');
        }
    }

    return lineItemIdBeingEdited;
}

const selectedTiresSelector = createSelector(
    state => state.appState.getIn(['serverData', 'addNewLineItemsView', 'tires', 'copyTireSpecification']) || List(),
    lineItemIdBeingEditedSelector,
    (wheelsForLineItem, lineItemIdBeingEdited) => {
        const selectedTires = wheelsForLineItem.filter(x => x.get('eventType') == constants.EVENT_TYPE.REPLACED && x.get('lineItemId') == lineItemIdBeingEdited);
        return selectedTires.size;
    }
)

const purchaseORderVendorIdSelector = (state, props)=> {
    let purchaseOrderVendorId;

    //get current purchase order vendor id
    if (props.routeParams.match.params.actionType == 'view')
        purchaseOrderVendorId = emptyStringIfUndefined(state.appState.getIn(['serverData', 'view', 'purchaseOrder', 'vendorId']));
    else
        purchaseOrderVendorId = emptyStringIfUndefined(state.appState.getIn(['uiData', 'addEdit', 'purchaseOrder', 'vendorId']));

    return purchaseOrderVendorId;
}

const PurchaseOrderView = createSelector(
    isfetchingDataSelector,
    estimatedCompletionDateSelector,
    vehicleDetailsSelector,
    complaintsSelector,
    complaintTypesSelector,
    canAddAnotherComplaintSelector,
    lineItemsSelector,
    approvedAmountTotalSelector,
    pendingAmountTotalSelector,
    userLocaleSelector,
    selectedServicesDescriptionSelector,
    authorizedStatusSelector,
    paymentStatusSelector,
    clientDetailsSelector,
    bankAccountSelector,
    taxAmountSelector,
    purchaseOrderSelector,
    odometerDataSelector,
    isDirtyPOSelector,
    canShowSelector,
    skippingComplaintForComplaintKeySelector,
     creatingOrEditingComplaintForComplaintKeySelector,
     isRequestingApprovalSelector,
     isEstimateDateValidSelector,
     lineItemIdBeingEditedSelector,
     selectedTiresSelector,
     purchaseORderVendorIdSelector,
     entityForAssistanceSelector,
    (isfetchingData,
     estimatedCompletionDate,
     vehicleDetails,
     complaints,
     complaintTypes,
     canAddAnotherComplaint,
     lineItems,
     approvedAmountTotal,
     pendingAmountTotal,
     userLocale,
     servicesDescription,
     authorizedStatus,
     paymentStatus,
     client,
     bankAccountNo,
     taxAmount,
     purchaseOrder,
     odometer,
     isDirtyPO,
     canShow,
     skippingComplaintForComplaintKey,
     creatingOrEditingComplaintForComplaintKey,
     isRequestingApproval,
     isEstimateDateValid,
     lineItemIdBeingEdited,
     selectedTires,
    purchaseORderVendorId,
    entityForAssistance) => {
        return {
            isfetchingData,
            estimatedCompletionDate,
            vehicleDetails,
            complaints,
            complaintTypes,
            canAddAnotherComplaint,
            lineItems,
            approvedAmountTotal,
            pendingAmountTotal,
            userLocale,
            servicesDescription,
            authorizedStatus,
            paymentStatus,
            client,
            bankAccountNo,
            taxAmount,
            purchaseOrder,
            odometer,
            isDirtyPO,
            canShow,
            skippingComplaintForComplaintKey,
            creatingOrEditingComplaintForComplaintKey,
            isRequestingApproval,
            isEstimateDateValid,
            lineItemIdBeingEdited,
            selectedTires,
            purchaseORderVendorId,
            entityForAssistance
        }
    }
);

export default PurchaseOrderView;