import { call, put, select, take, cancel, fork } from 'redux-saga/effects'
import * as Api from 'utils/Api';
import configuration from 'configuration';
import * as constants from 'constants/App';
import * as helperFunctions from 'utils/HelperFunctions';

function* offsetGeneratorForFetchRepairHistory() {
  let startValue = 0;
  while (true) {
    yield startValue++;
  }
}

function* fetchDataForRepairHistoryScreen(action, offsetGenerator) {
  try {
    const state = yield select();

    // Inform application that data request has started
    yield put({ type: constants.FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_REQUESTED });

    // Get recent repair history of vehicle
    const recentServicesData = yield call(Api.fetchRepairHistory, action.vehicleId, offsetGenerator.next().value, configuration.repairHistoryPageSize, helperFunctions.getToken(state));

    yield put({ type: constants.SAVE_RECENT_SERVICE_DATA, recentServicesData });
    yield put({ type: constants.FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_RESOLVED });

  }
  catch (e) {
    yield put({ type: constants.FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_REJECTED, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });
    yield put({ type: constants.SET_ERROR_MESSAGE, errorMessage: e.message ? e.message : constants.GENERIC_ERROR_MESSAGE });
    yield put({ type: constants.SAVE_ERROR_INFORMATION, actionName: constants.FETCH_DATA_FOR_REPAIR_HISTORY_SCREEN_REQUESTED, errorObject: e });
  }
}

export function* repairHistoryScreenFlow(action) {
  yield put({ type: constants.CLEAR_REPAIR_HISTORY });
  let offsetGenerator = offsetGeneratorForFetchRepairHistory();
  while (true) {
    let task = yield fork(fetchDataForRepairHistoryScreen, action, offsetGenerator);
    let nextAction = yield take([constants.REPAIR_HISTORY_SCREEN_FETCH_MORE_POS_CLICKED, constants.REPAIR_HISTORY_SCREEN_UNLOADED]);
    if (nextAction.type === constants.REPAIR_HISTORY_SCREEN_UNLOADED) {
      yield cancel(task);
      break;
    }
  }
}