import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Map} from 'immutable';
import './TermsView.css';
import {Link} from 'react-router-dom';
import Spinner from 'components/Spinner';

class TermsView extends React.Component {

    componentDidMount() {
        this.props.dispatch({ type: "TERMS_AND_CONDITIONS_STATIC" });
    }

    render() {
        const messages = defineMessages({
            termsHeader: {
                id: 'termsHeader',
                description: 'termsHeader',
                defaultMessage: 'General Terms and Conditions ("Holman Terms")'
            }

        });

        if (this.props.isFetchingTermsAndConditions ) {

            return <Spinner spinnerType="dark-blue"/>;
        }

        return <div>
            <div className='terms__header' >
                <FormattedMessage {...messages.termsHeader} />
            </div>
            <div className="terms__body">
                <span dangerouslySetInnerHTML={{ __html: this.props.termsAndConditions }}></span>
            </div>
        </div>;
    }
}

function mapStateToProps(state, props) {
    return {
        termsAndConditions:state.appState.getIn(['serverData', 'shared', 'vendorTermsAndConditions']) || null,
        isFetchingTermsAndConditions:state.appState.getIn(['uiData', 'shared', 'vendorTermsAndConditions','isFetchingTermsAndCondition']) || false,

    };
}


export default connect(mapStateToProps)(TermsView);