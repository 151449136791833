import React from 'react';
import {connect} from 'react-redux';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Map, fromJS} from 'immutable';
import './VehicleDetails.css';
import * as constants from 'constants/App';
import Spinner from 'components/Spinner';
import {
    emptyMapIfUndefined,
    emptyStringIfUndefined,
    falseIfUndefined,
    emptyStringIfNull,
    renderError,
    validateEmail,
    zeroIfUndefined
} from 'utils/HelperFunctions';
import moment from 'moment';

class VehicleDetails extends React.Component {


    render() {
        //Get data from app state (reducer store)
        var firstOfVin8 = '';
        var lastOfVin8 = '';
        /* this split is to highlight the last 8 chars of vin number as vin8 */
        var vehicleDetails = this.props.vehicleDetails.toJSON();
        var vinNumber = vehicleDetails.vin8;
        if (vinNumber != undefined) {
            lastOfVin8 = vinNumber.slice(9, vinNumber.length);
            firstOfVin8 = vinNumber.slice(0, 9);
        }

        var date = new moment(vehicleDetails.issueDate);

        const messages = defineMessages({
            vendor_details_title_request_no: {
                id: 'vendor_details_title_request_no',
                description: 'vendor_details_title_request_no',
                defaultMessage: 'Courtesy Request No. {requestNo}'
            },
            vendor_details_sub_title_processed: {
                id: 'vendor_details_sub_title_processed',
                description: 'vendor_details_sub_title_processed',
                defaultMessage: 'Your Request has been accepted and submitted for payment. Please <a onclick={window.print();}>Print</a> and retain this page for your records.'
            },
            vehicle_details_issue_date: {
                id: 'vehicle_details_issue_date',
                description: 'issue date',
                defaultMessage: 'Issue Date'
            },
            vehicle_details_vin_no: {
                id: 'vehicle_details_vin_no',
                description: 'VIN',
                defaultMessage: 'VIN'
            },
            vehicle_details_client: {
                id: 'vehicle_details_client',
                description: 'Client',
                defaultMessage: 'Client'
            },
            vehicle_details_ari_unit_no: {
                id: 'vehicle_details_ari_unit_no',
                description: 'Holman unit no.',
                defaultMessage: 'Holman unit no.'
            },
            vehicle_details_vehicle: {
                id: 'vehicle_details_vehicle',
                description: 'Vehicle',
                defaultMessage: 'Vehicle'
            },
            vehicle_details_status: {
                id: 'vehicle_details_status',
                description: 'status',
                defaultMessage: 'Status'
            },
            request_location_tax_rate_view: {
                id: 'request_location_tax_rate_view',
                description: 'request_location_tax_rate_view',
                defaultMessage: 'Location'
            },
        });

        return <div>
            <div className="dealer_draft_details__title">
                <FormattedMessage values={{requestNo: this.props.requestNo}} {...messages.vendor_details_title_request_no} />
            </div>
            {this.props.requestStatus === "VIEW" ?
                <div className="dealer_draft_details__sub-title">
                    <FormattedMessage  {...messages.vendor_details_sub_title_processed}  values={{ br: data => <br/>, a: data =><a  onClick={() => window.print()} style={{'padding-left':'5px','padding-right':'5px', 'display':'contents'}}>{data}</a>,b: data => <b>{data}</b>}}/>
                </div>
                : undefined
            }
            <div className="dealer_draft_details">
                <div className="container-fluid" css={`padding: 0;`}>
                    <div className="row dealer_draft_details__left_padding">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-5 dealer_draft_details__label">
                                    <FormattedMessage {...messages.vehicle_details_issue_date} />
                                </div>
                                <div className="col-md-7 dealer_draft_details__val">
                                    {date.format('MM/DD/YYYY')}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 dealer_draft_details__label">
                                    <FormattedMessage {...messages.vehicle_details_vin_no} />
                                </div>
                                <div className="col-md-7 dealer_draft_details__val">{firstOfVin8}
                                    <span className="car_wash_details__val_highlight">{lastOfVin8}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 dealer_draft_details__label">
                                    <FormattedMessage {...messages.vehicle_details_client} />
                                </div>
                                <div className="col-md-7 dealer_draft_details__val">
                                    {
                                        (vehicleDetails.clientId !== undefined && vehicleDetails.clientName !== undefined) ?
                                            <div> {vehicleDetails.clientName} {' ('}
                                                {vehicleDetails.clientId} {')'}
                                            </div>
                                            : undefined
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-5 dealer_draft_details__label">
                                    <FormattedMessage {...messages.vehicle_details_ari_unit_no} />
                                </div>
                                <div className="col-md-7 dealer_draft_details__val">
                                    {vehicleDetails.ariUnitNo}
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6 car_wash_details__spacing">
                            <div className="row">
                                <div className="col-md-3 dealer_draft_details__label">
                                    <FormattedMessage {...messages.vehicle_details_status} />
                                </div>
                                <div className="col-md-8 car_wash_details__val_highlight">
                                    {vehicleDetails.status}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 dealer_draft_details__label">
                                    <FormattedMessage {...messages.vehicle_details_vehicle} />
                                </div>
                                <div className="col-md-8 dealer_draft_details__val">
                                    {vehicleDetails.vehicleYear} {vehicleDetails.vehicleMake} {vehicleDetails.vehicleModel}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 dealer_draft_details__label-loc">
                                    <FormattedMessage {...messages.request_location_tax_rate_view} />
                                </div>
                                <div className="col-md-8 dealer_draft_details__val-loc">
                                    {vehicleDetails.vehicleCity !== null || vehicleDetails.vehicleState !== null ? vehicleDetails.vehicleCity + ', ' + vehicleDetails.vehicleState : undefined}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}




export default (VehicleDetails);

