import React from 'react';
import { jwtDecode } from 'jwt-decode';
import { connect } from 'react-redux';
import * as sharedActions from '../shared/actions/App';
import Submit from '../shared/components/Submit';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Button, Container,Row,Col } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import './SessionExpiration.css';
import * as configuration from '../shared/configuration';
import * as constants from 'constants/App';
import * as helperFunctions from '../shared/utils/HelperFunctions';
import styled from "styled-components";

// #region Styled Components
const ModalStyled = styled(Modal).attrs({
    dialogClassName: "custom-modal"
  })`
    .custom-modal {
      max-width: 600px;
  }`;
// #endregion

class SessionExpiration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show,
            cancelTimeoutValue:undefined
        }
    }

    isTokenExpiring() {
        //if modal is shown do nothing
        /*If the token expiring in "sessionExpirationTimeInMinutes", then show modal*/        
        if(this.state.show==false) {            
            const expirationTimeinMinutes = this.props.personaToken !== undefined
                ? helperFunctions.getTokenExpirationTimeinMinutes(this.props.personaToken)
                : helperFunctions.getTokenExpirationTimeinMinutes(this.props.token);            

            if(expirationTimeinMinutes<=configuration.sessionExpirationTimeInMinutes) {
                this.showModal(expirationTimeinMinutes*60);
            }
            else
            {
                setTimeout(this.isTokenExpiring.bind(this), configuration.sessionExpirationCheckFrequencyInMinutes * 60 * 1000);
            }

        }
    }

    componentDidMount() {
        this.isTokenExpiring();
    }


    showModal(SecondsToExpire) {
        this.setState({show: true});
        let timeoutValue=SecondsToExpire*1000;                
        setTimeout(this.SessionExpired.bind(this),timeoutValue);        
        this.setState({cancelTimeoutValue: timeoutValue});
    }

    SessionExpired(){
        this.setState({show: false});
        
        this.props.dispatch({type:constants.SIGN_OUT});

        //Check if token is meant for APC user or SSO user
        const jwtToken = jwtDecode(this.props.token);
        const audien = jwtToken.aud;        
        if(!!this.props.personaToken || audien == "VMS" || jwtToken.sub === 'ARIUSER')
            this.props.dispatch({type: constants.NAVIGATE_TO, path: '/login?signed_out=1'});
        else
            this.props.dispatch({type: constants.NAVIGATE_TO, path: '/sso?signed_out=1'});
    }

    onOKClick() {
        clearTimeout(this.state.cancelTimeoutValue);
        this.setState({cancelTimeoutValue:undefined});
        this.setState({show: false});
        this.props.dispatch(sharedActions.refreshToken());
        setTimeout(this.isTokenExpiring.bind(this), configuration.sessionExpirationCheckFrequencyInMinutes * 60 * 1000);
    }


    render() {

        const messages = defineMessages({
            close: {
                id: 'close',
                description: 'Close',
                defaultMessage: 'Close'
            },
            ok: {
                id: 'ok',
                description: 'OK ',
                defaultMessage: 'OK '
            },
            session_expiring_caption: {
                id: 'session_expiring_caption',
                description: 'Session Expiring!',
                defaultMessage: 'Session Expiring!'
            },
            session_expiring_note: {
                id: 'session_expiring_note',
                description: 'Your session will expire in 30 minutes; press OK to remain logged in.  If you log off, any changes on this page will not be saved.',
                defaultMessage: 'Your session will expire in 30 minutes; press OK to remain logged in.  If you log off, any changes on this page will not be saved.'
            }
        });
        return (

            <ModalStyled
                {...this.props}
                show={this.state.show}
            >

                <Modal.Body>
                    <Container style={{"padding-left":"20px"}}>

                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col lg={4} xl={4}>
                                &nbsp;
                                <span className="add-service-line-items__caption"><FormattedMessage {...messages.session_expiring_caption} /></span>
                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col lg={6} xl={6}>
                               <div className="SessionExpiration__note">

                                   <FormattedMessage {...messages.session_expiring_note} />

                                   </div>

                            </Col>
                        </Row>
                        <Row><Col>&nbsp;</Col></Row>
                        <Row>
                            <Col lg={{ span: 1, offset: 5}}>
                                <div className="add-service-line-items__save">
                                    <Submit
                                        caption={<FormattedMessage {...messages.ok}/>}
                                        imagePath="/assets/VMS_33button_go_bblue.png"
                                        onClickHandler={this.onOKClick.bind(this)}/>
                                </div>
                            </Col>

                        </Row>
                        </Container>
                </Modal.Body>

            </ModalStyled>

        );
    }
}

function select(state) {
    return {
        token: state.appState.getIn(['serverData', 'shared', 'token'])
    };
}
export default connect(select)(SessionExpiration);