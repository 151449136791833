import styled from 'styled-components'

export const PrimaryButtonStyled = styled.button`
  color: white;
  background: ${({theme})=>theme.holmanColor.driveBlue};              
  border: 1px solid ${({theme})=>theme.holmanColor.gray50};
  font-family: ${({theme})=>theme.holmanFont.primary};
  font-weight: 700;
  padding: 1rem;
  transition: 0.3s;                  

  ${props => (!props.variant || props.variant === 'default') && `					  
    color: white;
    background: ${props.theme.holmanColor.freshBlue};              
    border: 1px solid ${props.theme.holmanColor.freshBlue};
  &:hover {
      background: ${props.theme.holmanColor.fieldGreen};
      border: 1px solid ${props.theme.holmanColor.fieldGreen};
    }
    &:active {
      background: hsla(132, 55%, 75%, 1);
      border: 1px solid hsla(132, 55%, 75%, 1);
    }
	`}

  ${props => props.variant === 'contrast' && `					  
    color: ${props.theme.holmanColor.driveBlue};
    background: ${props.theme.holmanColor.white};
    border: 1px solid ${props.theme.holmanColor.gray50};
    &:hover {
      color: ${props.theme.holmanColor.white};
      background: ${props.theme.holmanColor.fieldGreen};
      border: 1px solid ${props.theme.holmanColor.fieldGreen};
  }
  &:active {
    background: hsla(132, 55%, 75%, 1);
      border: 1px solid hsla(132, 55%, 75%, 1);
  }
	`}
`

export const SecondaryButtonStyled = styled.button`
  color: ${({theme})=>theme.holmanColor.driveBlue};
  background: ${({theme})=>theme.holmanColor.white};
  border: 1px solid ${({theme})=>theme.holmanColor.gray50};
  font-family: ${({theme})=>theme.holmanFont.primary};
  font-weight: 700;
  padding: 1rem;              
  transition: 0.3s;

  ${props => (!props.variant || props.variant === 'default') && `					  
    color: ${props.theme.holmanColor.driveBlue};
    background: ${props.theme.holmanColor.white};
    border: 1px solid ${props.theme.holmanColor.gray50};
  &:hover {
      background: ${props.theme.holmanColor.putItInNeutral};
  }
  &:active {
      background: ${props.theme.holmanColor.putItInNeutral};
  }
	`}

  ${props => props.variant === 'contrast' && `					  
    color: white;
    background: ${props.theme.holmanColor.driveBlue};              
    border: 1px solid ${props.theme.holmanColor.driveBlue};
    &:hover {
      background: ${props.theme.holmanColor.fieldGreen};
      border: 1px solid ${props.theme.holmanColor.fieldGreen};
    }
    &:active {
      background: hsla(132, 55%, 75%, 1);
      border: hsla(132, 55%, 75%, 1);
    }
	`}
`