/**
 * Created by prboyapa on 1/11/2016.
 */
import React from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FormattedMessage } from 'react-intl';
import Help from 'components/Help';

class Title extends React.Component {

    render() {
        //render the view
        return <div>
            {this.props.caption}
            {this.props.videoId==null?false:<Help videoId={this.props.videoId} whiteSpaceAtBottomHeight="1.25em"/>}
        </div>;
    }
}


export default Title;