import {OrderedMap,Map} from 'immutable';

const documentTypes = {    
    vehicle: OrderedMap({
        8:Map({type:'VEH-State Inspection',displayName:'State Inspection'}),
        9:Map({type:'VEH-DOT Inspection',displayName:'DOT Inspection'}),
        10:Map({type:'VEH-ANSI Inspection',displayName:'ANSI Inspection'}),
        11:Map({type:'VEH-FRA Inspection',displayName:'FRA Inspection'}),
        12:Map({type:'VEH-BIT Inspection',displayName:'BIT Inspection'}),
        13:Map({type:'VEH-Emissions Inspection',displayName:'Emissions Inspection'}),
        14:Map({type:'VEH-LAW 430 INSPECTION - QUEBEC',displayName:'LAW 430 INSPECTION - QUEBEC'}),
        15:Map({type:'VEH-RCMP/GRC - 3 MONTH INSPECTION',displayName:'RCMP/GRC - 3 MONTH INSPECTION'}),
        16:Map({type:'VEH-RCMP/GRC - NEW VEHICLE INSPECTION',displayName:'RCMP/GRC - NEW VEHICLE INSPECTION'}),
        17:Map({type:'VEH-NDT INSPECTION',displayName:'NDT INSPECTION'}),
        18:Map({type:'VEH-BOOM INSPECTION (CANADA ONLY)',displayName:'BOOM INSPECTION (CANADA ONLY)'}),
        19:Map({type:'VEH-Quarterly Safety Inspection; (Non-Regulatory)',displayName:'Quarterly Safety Inspection; (Non-Regulatory)'}),
        20:Map({type:'VEH-Monthly Safety Inspection; (Non-Regulatory)',displayName:'Monthly Safety Inspection; (Non-Regulatory)'}),
        21:Map({type:'VEH-Annual Safety Inspection; (Non-Regulatory)',displayName:'Annual Safety Inspection; (Non-Regulatory)'}),
        22:Map({type:'VEH-Vehicle Weight Inspection (Scaling)',displayName:'Vehicle Weight Inspection (Scaling)'}),
        23:Map({type:'VEH-PSIP; (California Periodic Smoke Inspection)',displayName:'PSIP; (California Periodic Smoke Inspection)'}),
        24:Map({type:'VEH-Semi-Annual Safety Inspection (Non-Regulatory)',displayName:'Semi-Annual Safety Inspection (Non-Regulatory)'}),
        25:Map({type:'VEH-Aerial Inspection',displayName:'Aerial Inspection'}),
        26:Map({type:'VEH-CNG/LNG Fuel System Inspection',displayName:'CNG/LNG Fuel System Inspection'}),
        27:Map({type:'VEH-CNG 3 Year 36K Certified Tank Inspection',displayName:'CNG 3 Year 36K Certified Tank Inspection'}),
        28:Map({type:'VEH-Tailgate Inspection (Canada Only)',displayName:'Tailgate Inspection (Canada Only)'}),
        29:Map({type:'VEH-Completed PM Sheet',displayName:'Completed PM Sheet'}),
        30:Map({type:'VEH-Completed DVIR',displayName:'Completed DVIR'}),
        31:Map({type:'VEH-Repair Warranty Information',displayName:'Repair Warranty Information'}),
        32:Map({type:'VEH-Repair Photos   *(See Veh Image above)',displayName:'Repair Photos   *(See Veh Image above)'}),
        33:Map({type:'VEH-Accident Report',displayName:'Accident Report'}),
        34:Map({type:'VEH-Accident Estimate',displayName:'Accident Estimate'}),
        35:Map({type:'VEH-Accident Invoice',displayName:'Accident Invoice'}),
    })   
};

export default documentTypes;