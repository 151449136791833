import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Popover } from 'react-bootstrap';
import { SplitButton, MenuItem } from 'react-bootstrap';
import moment from 'moment';

import './CraneInspectionVehicleHistory.css';

class CraneInspectionVehicleHistory extends React.Component {

	render() {
		const messages = defineMessages({
			crane_inspection__vehicle_history_title: {
				id: 'crane_inspection__vehicle_history_title',
				description: 'Vehicle history record',
				defaultMessage: 'Vehicle history record'
			},
			crane_inspection__vehicle_history_report_number: {
				id: 'crane_inspection__vehicle_history_report_number',
				description: 'Report number',
				defaultMessage: 'Report number'
			},
			crane_inspection__vehicle_history_unit_number: {
				id: 'crane_inspection__vehicle_history_unit_number',
				description: 'Fleet unit number',
				defaultMessage: 'Fleet unit number'
			},
			crane_inspection__vehicle_history_inspection_date: {
				id: 'crane_inspection__vehicle_history_inspection_date',
				description: 'Date',
				defaultMessage: 'Date'
			}
		});

		return (
			
				<div className='row'>
					<div className='col-md-7'>
						&nbsp;
					</div>
					<div className='col-md-5 crane_inspection__vehicle_history'>
						<div className='row crane_inspection__vehicle_history_row crane_inspection__vehicle_history_title'>
							<div className='col-md-12 crane_inspection__vehicle_history_col'>
								<label><FormattedMessage {...messages.crane_inspection__vehicle_history_title} /></label>
							</div>
						</div>
						<div className='row crane_inspection__vehicle_history_row'>
							<div className='col-md-6 crane_inspection__vehicle_history_col'>
								<label><FormattedMessage {...messages.crane_inspection__vehicle_history_report_number} /></label>
							</div>

							<div className='col-md-6 crane_inspection__vehicle_history_col'>
								<label><FormattedMessage {...messages.crane_inspection__vehicle_history_unit_number} /></label>
							</div>

							<div className='container' css={`padding: 0;`}>
								<div className='row crane_inspection__vehicle_history_row'>
									<div className='col-md-6 crane_inspection__vehicle_history_col'>
										<span>{this.props.formData.get('reportNumber')}</span>
									</div>

									<div className='col-md-6 crane_inspection__vehicle_history_col'>
										<span>{this.props.formData.get('unitNumber')}</span>
									</div>

								</div>
							</div>
						</div>
						<div className='row crane_inspection__vehicle_history_row'>
							<div className='col-md-12 crane_inspection__vehicle_history_col'>
								<label><FormattedMessage {...messages.crane_inspection__vehicle_history_inspection_date} /></label>
								{' '}
								<span>{moment(this.props.formData.get('inspectionDate')).format("MM/DD/YYYY")}</span>
							</div>

						</div>
					</div>
				</div>
			
		)
	}
};

export default CraneInspectionVehicleHistory;