import React from 'react';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { reducer } from '../screens/App/shared/reducers/App';
import rootSaga from 'saga/index';
import DevTools from '../screens/App/containers/DevTools';
import thunkMiddleware from 'redux-thunk';
import { loadState, saveState } from '../screens/App/shared/utils/LocalStorage';
import * as config from '../screens/App/shared/configuration';
import { throttle } from 'lodash';
import * as sharedActions from 'actions/App';
import * as helperFunctions from 'utils/HelperFunctions';
import * as constants from 'constants/App';
import {withRouter} from 'react-router-dom';
import {callLogService} from 'utils/CallAPI';

/* This class has following responsibilities:
    1) Inject redux provider into component tree
    2) Create a redux store with saga, thunk and Devtools middlewares 
    3) Provide the router instance received to the saga middlewares using the approach described below:
    https://github.com/ReactTraining/react-router/issues/3972
*/

const configureStore = (initialState, context = {}, isDevelopment) => {

    const sagaMiddleware = createSagaMiddleware();

    var createStoreWithMiddleware;

    if (isDevelopment) {
        createStoreWithMiddleware = compose(
            applyMiddleware(thunkMiddleware, sagaMiddleware),
            DevTools.instrument()
        )(createStore);
    }
    else {
        createStoreWithMiddleware = compose(
            applyMiddleware(thunkMiddleware, sagaMiddleware)
        )(createStore);
    }

    const store = createStoreWithMiddleware(reducer, loadState());

    sagaMiddleware.run(rootSaga, context);

    const saveStateThrottled = throttle(function () { saveState(store.getState()) },
        config.throttledSaveStateInSeconds * 1000);

    store.subscribe(() => {
        saveStateThrottled();
    });
   
    return store;
};

class ProviderWithRouter extends React.Component {

    constructor(props) {
        super(props);

        this.store = configureStore(undefined, {
            history:  props.history,
        }, props.isDevelopment);
    }

    render() {
        return (
            <Provider store={this.store}>                
                <div>
                    {this.props.children}
                    {this.props.isDevelopment ? <DevTools store={this.store} /> : false}
                </div>                
            </Provider>
        );
    }
}

export default withRouter(ProviderWithRouter);