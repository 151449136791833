// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './VehicleComponentHeader.css';

class VehicleComponentHeader extends React.Component {	
	render() {		
		const messages = defineMessages({
			dot_inspection_form__ok: {
				id: 'dot_inspection_form__ok',
				description: 'DOT inspection form OK label.',
				defaultMessage: 'OK'
			},
			dot_inspection_form__na: {
				id: 'dot_inspection_form__na',
				description: 'DOT inspection form NA label.',
				defaultMessage: 'N/A'
			},
			dot_inspection_form__needs_repair: {
				id: 'dot_inspection_form__needs_repair',
				description: 'DOT inspection form need repair label.',
				defaultMessage: 'Needs<br/>repair'
			},
			dot_inspection_form__repaired_date: {
				id: 'dot_inspection_form__repaired_date',
				description: 'DOT inspection form repaired date label.',
				defaultMessage: 'Repaired<br/>date'
			},
		});
	
		return (
			<tr>
				<td className='dot_inspection_vehicle_component__ok_group_header'>
					<FormattedMessage {...messages.dot_inspection_form__ok} />
				</td>
				<td className='dot_inspection_vehicle_component__na_group_header'>
					<FormattedMessage {...messages.dot_inspection_form__na} />
				</td>
				<td className='dot_inspection_vehicle_component__group_header dot_inspection_vehicle_component__group_header--small' >					
					<FormattedMessage {...messages.dot_inspection_form__needs_repair}  values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}} />
				</td>
				<td className='dot_inspection_vehicle_component__group_header dot_inspection_vehicle_component__group_header--small' >					
					<FormattedMessage {...messages.dot_inspection_form__repaired_date} values={{ br: data => <br/> , b: data => <b>{data}</b>, a: data => <a>{data}</a>}} />
				</td>
				<td className='dot_inspection_vehicle_component__group_header'>					
					{this.props.groupName}					
				</td>
			</tr>
		)
    }
}

export default VehicleComponentHeader;