import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Api from "utils/Api";
import * as constants from "constants/App";
import styled from "styled-components";
import Spinner from "components/Spinner";
import ReactPaginate from "react-paginate";
import FormWrapper from "components/FormWrapper";
import * as helperFunctions from "utils/HelperFunctions";
import { Subhead } from "DesignSpecs/components/FontGuide";
import { PageLayout } from "DesignSpecs/components/Layouts";
import {
  Label,
  FormTextGray,
  Input,
  InputFeedback,
  Image,
  InputImage,
  Row,
  Col
} from "DesignSpecs/components/FormComponents";

import { defineMessages, FormattedMessage, injectIntl } from "react-intl";

const ClearImageStyled = styled.img`
  display: block;
  position: absolute;
  top: 2px;
  right: 3px; 
  cursor: pointer;                                
  width: 22px;
  height: 22px;
`;
const NoResultsText = styled.div`
    font-size: 16px;
	  font-family: "Gotham SSm A", "Gotham SSm B";
   	color: #000000;
    font-style: normal;
    font-weight: 300;
    padding-left: 11em;
`;
const StyledPagination = styled(ReactPaginate)`
      margin-top: 2rem;
    li > a{
      color: #337ab7;
    }
    .page-item.active .page-link{
      background-color: #337ab7;
    }
`;
const loadingStates = { loading: "loading", loaded: "loaded", error: "error" };

const AccessStores = ({
  token,
  personaUserId,
  personaAccessStoreErrorMessage,
  showPersonaAccessStoreErrorMessage,
  showPersonaAccessStoreSpinner,
  intl,
  dispatch
}) => {
  const { formatMessage } = intl;
  const [loadingState, setLoadingState] = useState(loadingStates.loading);
  const [searchResultLoadingState, setSearchResultLoadingState] = useState(
    loadingStates.loaded
  );
  const [accessStoreLoadingState, setAccesStoreLoadingState] = useState(
    loadingStates.loaded
  );
  const [error, setError] = useState();
  const [stores, setStores] = useState();
  const [pageCount, setPageCount] = useState();
  const [storeSearchKeyWord, setStoreSearchKeyword] = useState("");  
  const [forcePage, setForcePage] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [storeCount, setStoreCount] = useState();
  const pageSize = 20;
  const adminAccess = "N";
  //const [userList,setUserList]=useState();

  function handlePageClick(data) {       
    setCurrentPageNumber(data.selected);
  }

  function onSearchClick(value) {    
    setCurrentPageNumber(0);    
    setStoreSearchKeyword(value);
  }

  function accessStoreClicked(storeId) { 
    dispatch({
      type: "PERSONA_ACCESS_STORE_CLICKED",
      storeId: storeId
    });  
  }

  async function Fetch() {
    let didCancel = false;

    const fetchData = async () => {
      try {        
        setSearchResultLoadingState(loadingStates.loading);
        const [vendorResult] = await Promise.all([
          Api.fetchStores(
            personaUserId,
            currentPageNumber == undefined ? 0 : currentPageNumber,
            pageSize,
            storeSearchKeyWord.trim(),
            adminAccess,
            token
          )
        ]);

        if (!didCancel) {
          setStoreCount(vendorResult.vendorCount);          
          setStores(vendorResult.vendors);
          setPageCount(Math.ceil(vendorResult.vendorCount / pageSize));          
          setSearchResultLoadingState(loadingStates.loaded);
          dispatch({ type: "PERSONA_USER_STORE_ACCESS_RESOLVED" });

        }
      } catch (e) {
        setSearchResultLoadingState(loadingStates.error);
        if (!didCancel) {          
          dispatch({
            type: "PERSONA_USER_STORE_ACCESS_REJECTED",
            errorMessage: helperFunctions.getCustomErrorObject(e)
          });
          dispatch({
            type: constants.SET_ERROR_MESSAGE,
            errorMessage: helperFunctions.getGenericErrorObject(e)
          });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }

  useEffect(
    () => {
      
      Fetch();
    },
    [storeSearchKeyWord, currentPageNumber]
  );

  const handleSubmit = useCallback(data => { });

  const messages = defineMessages({
    all_stores__title: {
      id: "all_stores__title",
      description: "All stores",
      defaultMessage: "All stores"
    },

    all_stores_ari_vendor_id_store_id: {
      id: "all_stores_ari_vendor_id_store_id",
      description: "Holman ID/ Store no.",
      defaultMessage: "Holman ID/ Store no."
    },

    all_stores_address: {
      id: "all_stores_address",
      description: "Store address",
      defaultMessage: "Store addres"
    },

    all_stores__manage_stores: {
      id: "all_stores__manage_stores",
      description: "Access store",
      defaultMessage: "Access store"
    },

    all_stores__no_search_results: {
      id: "all_stores__no_search_results",
      description: "No stores matching the search criteria found",
      defaultMessage: "No stores matching the search criteria found"
    },
    all_stores__pagination_results_caption: {
      id: "all_stores__pagination_results_caption",
      description: "Showing results {first}-{last}",
        defaultMessage: "Showing results {first}-{last} of {storeCount}"
    },
    all_stores__pagination_results_search_caption: {
      id: "all_stores__pagination_results_search_caption",
      description: " for the search term",
      defaultMessage: " for the search term"
    },    
    all_stores__search_text_place_holder: {
      id: "all_stores__search_text_place_holder",
      description: "Search for store",
      defaultMessage: "Search for store"
    },
    all_stores__search_previous: {
      id: "all_stores__search_previous",
      description: "previous",
      defaultMessage: "previous"
    },
    all_stores__search_next: {
      id: "all_stores__search_next",
      description: "next",
      defaultMessage: "next"
    },
    all_stores__search_no_stores_text: {
      id: "all_stores__search_no_stores_text",
      description: "No results found for this search criteria.",
      defaultMessage: "No results found for this search criteria."
    },
    all_stores__access_no_stores_text: {
      id: "all_stores__access_no_stores_text",
      description: "You do not have access to any stores.",
      defaultMessage: "You do not have access to any stores."
    }
  });
  
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          StoreSearchKeyword: "",
          StoreList: []
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSearchClick(values.StoreSearchKeyword);
        }}
      >

        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue
          } = props;          
          return (
            <form onSubmit={handleSubmit}>                              
              <div>

                <PageLayout>
                  <FormWrapper
                    key="accessStoreErrorMessage"
                    id="accessStoreErrorMessage"
                    formErrors={personaAccessStoreErrorMessage}
                  />
                  <Subhead>
                    <FormattedMessage {...messages.all_stores__title} />

                  </Subhead>
                  
                  <div css={`
                    display: flex;
                    justify-content: flex-end;
                  `}>                        
                    <div css={`
                      position: relative;
                    `}>

                      <Input
                        id="txtStoreSearch"
                        type="text"
                        value={values.StoreSearchKeyword}
                        onChange={e => {
                          setFieldValue(
                            "StoreSearchKeyword",
                            e.target.value,
                            true
                          );                          
                        }}
                        placeholder={formatMessage(
                          messages.all_stores__search_text_place_holder
                        )}
                        onBlur={handleBlur}
                        error={errors.StoreSearchKeyword}
                        touched={touched.StoreSearchKeyword}
                      />
                      {!!values.StoreSearchKeyword &&
                        <ClearImageStyled 
                          src='/assets/VMS_33button_kill_clear_inline.png'                           
                          onClick={()=>{
                            setStoreSearchKeyword('');
                            setCurrentPageNumber(0); 
                            setFieldValue("StoreSearchKeyword", '', true);
                          }}
                        />
                      }
                    </div>

                    <div>                      
                      &nbsp;
                      <span style={{ display: "inline-block" }}>
                        {searchResultLoadingState ==
                          loadingStates.loading
                          ? <div
                            style={{
                              display: "inline-block",
                              width: "25px",
                              height: "25px",
                              position: "inherit",
                              verticalAlign: "top"
                            }}
                          >
                            <Spinner />
                          </div>
                          : <InputImage
                            type="image"
                            src="/assets/VMS_search_button_blue.png"
                            alt="Submit"
                            onclick={handleSubmit}
                          />}
                      </span>
                      <div>
                        {errors.StoreSearchKeyword &&
                          touched.StoreSearchKeyword &&
                          <InputFeedback>
                            {errors.StoreSearchKeyword}
                          </InputFeedback>}
                      </div>
                    </div>
                  </div>

                  { showPersonaAccessStoreSpinner
                    ? <Spinner />
                    :  <div> { stores == null || stores.length == 0
                      ? false

                      : <div style={{
                        "paddingTop": "15px"
                      }}>
                        <Row>
                          <FormTextGray>
                            <FormattedMessage
                              values={{
                                first: (currentPageNumber * pageSize) + 1,
                                last: (currentPageNumber * pageSize) + stores.length,
                                storeCount: storeCount
                              }}
                              {...messages.all_stores__pagination_results_caption}
                            />
                            {storeSearchKeyWord != undefined && storeSearchKeyWord != '' ?
                              <span>
                                <span>
                                  <FormattedMessage                                  
                                    {...messages.all_stores__pagination_results_search_caption}
                                  />                                
                                </span>
                                {' "'}
                                <span css={`
                                  color: ${({theme})=>theme.color.primaryBlue};
                                `}>
                                  {storeSearchKeyWord}
                                </span>
                                {'"'}
                              </span> : undefined
                            }      
                                                      
                          </FormTextGray>
                          
                        </Row>
                        </div>
                    }
                        <br />
                        <Row fontWeight="bold">
                          <Col width="20rem">
                            <Label>
                              <FormattedMessage
                                {...messages.all_stores_ari_vendor_id_store_id}
                              />

                            </Label>
                          </Col>
                          <Col width="36rem">
                            <Label>
                              <FormattedMessage
                                {...messages.all_stores_address}
                              />
                            </Label>
                          </Col>

                          <Col width="12rem" />
                        </Row>

                        <Row>
                          <Col width="100%">
                            <div
                              style={{
                                "borderTop": "2px solid black",
                                width: "100%"
                              }}
                            />

                          </Col>
                        </Row>
                       {(stores == null || stores.length == 0) && storeSearchKeyWord === '' ?
                        <NoResultsText>
                         <FormattedMessage                                      
                           {...messages.all_stores__access_no_stores_text}
                         />                                    
                       </NoResultsText>  
                       
                        
                         :stores == null || stores.length == 0
                      ?    <NoResultsText>
                                <FormattedMessage
                                  values={{
                                    searchTerm: storeSearchKeyWord
                                  }}
                                  {...messages.all_stores__search_no_stores_text}
                                />                                
                              </NoResultsText>
                 
                      : 
                        !!stores &&
                          stores.map(vendor => {
                            return (
                              <div
                                key={vendor.id}
                                style={{
                                  "borderBottom": "1px solid grey",
                                  width: "100%",
                                  "paddingTop": "5px"
                                }}
                              >
                                <Row>

                                  <Col width="20rem">
                                    {vendor.id}
                                    {" "}
                                    {vendor.storeNumber != "" ? 
                                   "/ " + vendor.storeNumber : undefined
                                   }
                                  </Col>

                                  <Col width="36rem">
                                    {vendor.businessName} <br />
                                    {vendor.businessAddress.address1}
                                    {" "}
                                    <br />
                                    {vendor.businessAddress.city}
                                    {", "}
                                    {vendor.businessAddress.state}
                                    {" "}
                                    {" "}
                                    {" "}
                                    {vendor.businessAddress.zipCode}
                                    <br />
                                    {vendor.businessPhone.substr(0, 3) +
                                      "-" +
                                      vendor.businessPhone.substr(
                                        3,
                                        3
                                      ) +
                                      "-" +
                                      vendor.businessPhone.substr(6, 4)}
                                  </Col>

                                  <Col width="12rem">
                                    <Row onClick={() => {
                                            accessStoreClicked(
                                              vendor.id
                                            );
                                          }}   style={{
                                            "cursor":"pointer",
                                            "color":'#337ab7'
                                          }}>
                                      <Col width="10rem">
                                        <FormattedMessage
                                          {...messages.all_stores__manage_stores}
                                        />
                                      </Col><Col width="2rem">
                                        {" "}<Image
                                          src="/assets/VMS_button_go_clear.png"
                                          
                                        />
                                      </Col>
                                    </Row>                                        
                                  </Col>
                                </Row>

                              </div>
                            );
                          })}

                        <Row>
                          <Col width="18rem" />
                          <Col width="38rem">
                          {storeCount <= 20 ? false :
                            <StyledPagination
                              previousLabel={formatMessage(
                                messages.all_stores__search_previous
                              )}
                              nextLabel={formatMessage(
                                messages.all_stores__search_next
                              )}
                              breakLabel={"..."}
                              pageCount={pageCount}
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={1}
                              onPageChange={handlePageClick}
                              className={"pagination pagination-lg"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"page-item active"}
                              pageLinkClassName={"page-link"}
                              previousLinkClassName={"page-link"}
                              nextLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              nextClassName={"page-item"}                                  
                              disableInitialCallback={true}
                              forcePage={currentPageNumber}
                            />
                              }
                          </Col>
                          <Col width="15rem" />
                        </Row>
                      </div>}
                </PageLayout>

              </div>                
            </form>
          );
        }}

      </Formik>

    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.appState.getIn(["serverData", "shared", "personaToken"]),
    personaUserId: state.appState.getIn([
      "serverData",
      "shared",
      "personaUserId"
    ]),
    personaAccessStoreErrorMessage: state.appState.getIn([
      "uiData",
      "shared",
      "personaAccessStoreErrorMessage"
    ]),
    showPersonaAccessStoreErrorMessage: state.appState.getIn([
      "uiData",
      "shared",
      "showPersonaAccessStoreErrorMessage"
    ]) == undefined
      ? false
      : state.appState.getIn([
        "uiData",
        "shared",
        "showPersonaAccessStoreErrorMessage"
      ]),
      showPersonaAccessStoreSpinner:state.appState.getIn([
        "uiData",
        "shared",
        "showPersonaAccessStoreSpinner"
      ])==undefined?false:
      state.appState.getIn([
        "uiData",
        "shared",
        "showPersonaAccessStoreSpinner"
      ])
  };
};

export default connect(mapStateToProps)(injectIntl(AccessStores));
