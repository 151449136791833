import React from 'react';
import { connect } from 'react-redux';
import { clearErrorMessage } from '../shared/actions/App';
import './Error.css';

class Error extends React.Component {

    constructor(props) {
        super(props);
        this.handleDismissClick = this.handleDismissClick.bind(this);
    }

    handleDismissClick(e) {
        this.props.dispatch(clearErrorMessage());
        e.preventDefault();
    }

    render() {
       
        const { errorMessage, dispatch } = this.props;
        if (errorMessage == undefined || errorMessage == "" || errorMessage == null || (errorMessage.length == 0)) {
            return null;
        }

        if(typeof errorMessage === 'object') {
            return null; 
        }

        if(typeof(errorMessage)!='string' && errorMessage !== undefined && errorMessage.length > 0){
            return null; 
        }

        return (
            <p className="error">
                <b>{errorMessage}</b>
        {' '}
        (<a href="#"
                    onClick={this.handleDismissClick}>
    Dismiss
</a>)
</p>
)
    }
}

// Which props do we want to inject, given the global state?
function select(state) {
    return {
        errorMessage: state.appState.getIn(['uiData', 'shared', 'errorMessage'])
    };
}

export default connect(select)(Error);