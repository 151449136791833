import { createSelector } from 'reselect';
import {emptyMapIfUndefined, falseIfUndefined} from 'utils/HelperFunctions';

const isfetchingDataSelector = state => falseIfUndefined(state.appState.getIn(['uiData', 'shopView','isFetchingData']));
const activePOsSelector = state => emptyMapIfUndefined(state.appState.getIn(['serverData','shopView','activePOs']));
const showAllApprovedPOsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData',  'shopView','showAllApprovedPOs']));
const showAllWaitingForARIApprovalPOsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData', 'shopView','showAllWaitingForARIApprovalPOs']));
const showAllWaitingForClientAuthorizationPOsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData','shopView','showAllWaitingForClientAuthorizationPOs']));
const showAllVendorActionNeededPOsSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData','shopView','showAllVendorActionNeededPOs']));
const showAllNewWorkAssignmentSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData','shopView','showAllNewWorkAssignmentPOs']));
const showAllWorkCompletedSelector = state => emptyMapIfUndefined(state.appState.getIn(['uiData','shopView','showAllWorkCompletedPOs']));
const entireBulletinMessage = state => emptyMapIfUndefined(state.appState.getIn(['serverData','shopView','bulletinMessage']));

const getBulletinSelector = createSelector(entireBulletinMessage,(bulletinList) => {

    return bulletinList.sortBy((param)=> -param.get("id"));

});


const getPrimaryBulletinSelector = createSelector(entireBulletinMessage,(bulletinList) => {

    return bulletinList.filter((params) => {
            return params.get('bulletinType') === 'primary';
        }

    );

});

const getSecondaryBulletinSelector = createSelector(entireBulletinMessage,(bulletinList) => {

    return bulletinList.filter((params) => {
            return params.get('bulletinType') === 'secondary';
        }

    ).sortBy((param)=> -param.get("id"));

});

const ShopView = createSelector(
    isfetchingDataSelector,
    activePOsSelector,
    showAllApprovedPOsSelector,
    showAllWaitingForARIApprovalPOsSelector,
    showAllWaitingForClientAuthorizationPOsSelector,
    showAllVendorActionNeededPOsSelector,
    showAllNewWorkAssignmentSelector,
    showAllWorkCompletedSelector,
    getBulletinSelector,
    getPrimaryBulletinSelector,
    getSecondaryBulletinSelector,
    (isfetchingData,
        activePOs,
        showAllApprovedPOs,
        showAllWaitingForARIApprovalPOs,
        showAllWaitingForClientAuthorizationPOs,
        showAllVendorActionNeededPOs,
        showAllNewWorkAssignment,
        showAllWorkCompleted,
        bulletinMessage,
        primaryBulletin,
        secondaryBulletin
        ) => {
        return {
            isfetchingData,
            activePOs,
            showAllApprovedPOs,
            showAllWaitingForARIApprovalPOs,
            showAllWaitingForClientAuthorizationPOs,
            showAllVendorActionNeededPOs,
            showAllNewWorkAssignment,
            showAllWorkCompleted,
            bulletinMessage,
            primaryBulletin,
            secondaryBulletin
        }
    }
);

export default ShopView;