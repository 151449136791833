import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';
import { fromJS } from 'immutable';

export function uiDataVehicleSearchCreatePO(state = initialAppState.getIn(['uiData', 'vehicleSearchCreatePO']), action) {
    switch (action.type) {
        case "FETCH_CLIENT_NUMBERS_REQUESTED":
            return state.set('isFetchingData', true);
        case "FETCH_CLIENT_NUMBERS_ACCEPTED":
            return state.set('vendorClientNumbers',fromJS(action.vendorClientNumber)).setIn(['vehicleSearchError', 'vehicleNumberError'],false)
                .setIn(['vehicleSearchError', 'clientNumberError'],false).set('isFetchingData', false).setIn(['vehicleSearchError', 'vehicleDetailsDispatchError'],"");
        case "FETCH_CLIENT_NUMBERS_REJECTED":
            return state.set('isFetchingData', false);
        case "CREATE_PO_CLICK_REQUESTED":
            return state.set('isFetchingData', true);
        case "VALIDATION_SEARCH_PO_CREATION":
                return state.set('poCreationError', action.requestPOInfo.value).set('poCreationErrorFlag',true);
        case "VALIDATION_SEARCH_PO_CREATION_RESOLVED":
            return state.set('poCreationError', null).set('poCreationErrorFlag',false);
        case "CLEAR_SEARCH_ERROR_MESSAGE":
            return state.set('poCreationError', null)
                .set('poCreationErrorFlag',false);
        default:
            return state;
    }
}

export function serverDataVehicleSearchCreatePO(state = initialAppState.getIn(['serverData', 'vehicleSearchCreatePO']), action) {
    switch (action.type) {

       default:
            return state;
    }
}