import * as constants from 'constants/App';


export function saveFeedbackForm(dataForm) {
    return {type: constants.SAVE_FEEDBACK_FORM,dataForm}
}

export function saveDataFeedbackFormSelect(dataDrop) {
    return {type: constants.SAVE_FEEDBACK_FORM_QUESTIONS_SELECTION,dataDrop}
}

export function mountDataFeedbackForm() {
    return {type: constants.SEND_FEEDBACK_FORM_MOUNT}
}
