// libraries
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
// our stuff
import './VehicleComponents.css';
import CheckCircle from 'components/CheckCircle';
import VehicleComponentHeader from './VehicleComponentHeader';
import VehicleComponent from './VehicleComponent';
import DotResult from './DotResult';

class VehicleComponents extends React.Component {	
	constructor(props) {
		super(props);
		this.handleChangeDebounced = _.debounce(this.handleChange, 1000);
	}

	handleChange = (id, value) => {        		
        this.props.setFormData(id, value);        
	}
	
	handleChangeDebouncedCaller = (event) => { 
		// Note: You must call the debounced function with id and value instead of passing the event object.
		//       Event may be null by the time the debounced function is invoked.
		this.handleChangeDebounced(event.target.id, event.target.value);
    }

	render() {		
		const messages = defineMessages({
			dot_inspection_form__all_ok: {
				id: 'dot_inspection_form__all_ok',
				description: 'DOT inspection form All Ok button.',
				defaultMessage: 'All OK'
			},
			dot_inspection_form__vehicle_components_title: {
				id: 'dot_inspection_form__vehicle_components_title',
				description: 'DOT inspection form vehicle components inspected title.',
				defaultMessage: 'Vehicle components inspected'
			},


			dot_inspection_form__vehicle_components_header_1: {
				id: 'dot_inspection_form__vehicle_components_header_1',
				description: 'DOT inspection form vehicle components header 1.',
				defaultMessage: '1. Brake system'
			},
			dot_inspection_form__vehicle_components_header_2: {
				id: 'dot_inspection_form__vehicle_components_header_2',
				description: 'DOT inspection form vehicle components header 2.',
				defaultMessage: '2. Coupling devices'
			},
			dot_inspection_form__vehicle_components_header_3: {
				id: 'dot_inspection_form__vehicle_components_header_3',
				description: 'DOT inspection form vehicle components header 3.',
				defaultMessage: '3. Exhaust system'
			},
			dot_inspection_form__vehicle_components_header_4: {
				id: 'dot_inspection_form__vehicle_components_header_4',
				description: 'DOT inspection form vehicle components header 4.',
				defaultMessage: '4. Fuel system'
			},
			dot_inspection_form__vehicle_components_header_5: {
				id: 'dot_inspection_form__vehicle_components_header_5',
				description: 'DOT inspection form vehicle components header 5.',
				defaultMessage: '5. Lighting devices'
			},
			dot_inspection_form__vehicle_components_header_6: {
				id: 'dot_inspection_form__vehicle_components_header_6',
				description: 'DOT inspection form vehicle components header 6.',
				defaultMessage: '6. Safe loading'
			},
			dot_inspection_form__vehicle_components_header_7: {
				id: 'dot_inspection_form__vehicle_components_header_7',
				description: 'DOT inspection form vehicle components header 7.',
				defaultMessage: '7. Steering mechanism'
			},
			dot_inspection_form__vehicle_components_header_8: {
				id: 'dot_inspection_form__vehicle_components_header_8',
				description: 'DOT inspection form vehicle components header 8.',
				defaultMessage: '8. Suspension'
			},
			dot_inspection_form__vehicle_components_header_9: {
				id: 'dot_inspection_form__vehicle_components_header_9',
				description: 'DOT inspection form vehicle components header 9.',
				defaultMessage: '9. Frame'
			},
			dot_inspection_form__vehicle_components_header_10: {
				id: 'dot_inspection_form__vehicle_components_header_10',
				description: 'DOT inspection form vehicle components header 10.',
				defaultMessage: '10. Tires'
			},
			dot_inspection_form__vehicle_components_header_11: {
				id: 'dot_inspection_form__vehicle_components_header_11',
				description: 'DOT inspection form vehicle components header 11.',
				defaultMessage: '11. Wheels and rims'
			},
			dot_inspection_form__vehicle_components_header_12: {
				id: 'dot_inspection_form__vehicle_components_header_12',
				description: 'DOT inspection form vehicle components header 12.',
				defaultMessage: '12. Windshield glazing'
			},
			dot_inspection_form__vehicle_components_header_13: {
				id: 'dot_inspection_form__vehicle_components_header_13',
				description: 'DOT inspection form vehicle components header 13.',
				defaultMessage: '13. Windshield wipers'
			},

			dot_inspection_form__vehicle_component_1: {
				id: 'dot_inspection_form__vehicle_component_1',
				description: 'DOT inspection form vehicle component 1.',
				defaultMessage: 'a. Service brakes'
			},
			dot_inspection_form__vehicle_component_2: {
				id: 'dot_inspection_form__vehicle_component_2',
				description: 'DOT inspection form vehicle component 2.',
				defaultMessage: 'b. Parking Brake System'
			},
			dot_inspection_form__vehicle_component_3: {
				id: 'dot_inspection_form__vehicle_component_3',
				description: 'DOT inspection form vehicle component 3.',
				defaultMessage: 'c. Brake Drums or Rotors'
			},
			dot_inspection_form__vehicle_component_4: {
				id: 'dot_inspection_form__vehicle_component_4',
				description: 'DOT inspection form vehicle component 4.',
				defaultMessage: 'd. Brake Hose'
			},
			dot_inspection_form__vehicle_component_5: {
				id: 'dot_inspection_form__vehicle_component_5',
				description: 'DOT inspection form vehicle component 5.',
				defaultMessage: 'e. Brake Tubing'
			},
			dot_inspection_form__vehicle_component_6: {
				id: 'dot_inspection_form__vehicle_component_6',
				description: 'DOT inspection form vehicle component 6.',
				defaultMessage: 'f. Low Pressure Warning Device'
			},
			dot_inspection_form__vehicle_component_7: {
				id: 'dot_inspection_form__vehicle_component_7',
				description: 'DOT inspection form vehicle component 7.',
				defaultMessage: 'g. Tractor Protection Valve'
			},
			dot_inspection_form__vehicle_component_8: {
				id: 'dot_inspection_form__vehicle_component_8',
				description: 'DOT inspection form vehicle component 8.',
				defaultMessage: 'h. Air Compressor'
			},
			dot_inspection_form__vehicle_component_9: {
				id: 'dot_inspection_form__vehicle_component_9',
				description: 'DOT inspection form vehicle component 9.',
				defaultMessage: 'i. Electric Brakes'
			},
			dot_inspection_form__vehicle_component_10: {
				id: 'dot_inspection_form__vehicle_component_10',
				description: 'DOT inspection form vehicle component 10.',
				defaultMessage: 'j. Hydraulic Brakes'
			},
			dot_inspection_form__vehicle_component_11: {
				id: 'dot_inspection_form__vehicle_component_11',
				description: 'DOT inspection form vehicle component 11.',
				defaultMessage: 'k. Vacuum Systems'
			},
			dot_inspection_form__vehicle_component_12: {
				id: 'dot_inspection_form__vehicle_component_12',
				description: 'DOT inspection form vehicle component 12.',
				defaultMessage: 'a. Fifth Wheels'
			},
			dot_inspection_form__vehicle_component_13: {
				id: 'dot_inspection_form__vehicle_component_13',
				description: 'DOT inspection form vehicle component 13.',
				defaultMessage: 'b. Pintle Hooks'
			},
			dot_inspection_form__vehicle_component_14: {
				id: 'dot_inspection_form__vehicle_component_14',
				description: 'DOT inspection form vehicle component 14.',
				defaultMessage: 'c. Drawbar/Towbar Eye'
			},
			dot_inspection_form__vehicle_component_15: {
				id: 'dot_inspection_form__vehicle_component_15',
				description: 'DOT inspection form vehicle component 15.',
				defaultMessage: 'd. Drawbar/Towbar Tongue'
			},
			dot_inspection_form__vehicle_component_16: {
				id: 'dot_inspection_form__vehicle_component_16',
				description: 'DOT inspection form vehicle component 16.',
				defaultMessage: 'e. Safety Devices'
			},
			dot_inspection_form__vehicle_component_17: {
				id: 'dot_inspection_form__vehicle_component_17',
				description: 'DOT inspection form vehicle component 17.',
				defaultMessage: 'f. Saddle-Mounts'
			},
			dot_inspection_form__vehicle_component_18: {
				id: 'dot_inspection_form__vehicle_component_18',
				description: 'DOT inspection form vehicle component 18.',
				defaultMessage: 'a. Any exhaust system determined to be leaking at a point forward of or directly below the driver/sleeper compartment.'
			},
			dot_inspection_form__vehicle_component_19: {
				id: 'dot_inspection_form__vehicle_component_19',
				description: 'DOT inspection form vehicle component 19.',
				defaultMessage: 'b. A bus exhaust system leaking or discharging to the atmosphere in violation of standards (1), (2) or (3).'
			},
			dot_inspection_form__vehicle_component_20: {
				id: 'dot_inspection_form__vehicle_component_20',
				description: 'DOT inspection form vehicle component 20.',
				defaultMessage: 'c. No part of the exhaust system of any motor vehicle shall be so located as would be likely to result in burning, charring, or damaging the electrical wiring, the fuel supply, or any combustible part of the motor vehicle.'
			},
			dot_inspection_form__vehicle_component_21: {
				id: 'dot_inspection_form__vehicle_component_21',
				description: 'DOT inspection form vehicle component 21.',
				defaultMessage: 'a. Visible leak'
			},
			dot_inspection_form__vehicle_component_22: {
				id: 'dot_inspection_form__vehicle_component_22',
				description: 'DOT inspection form vehicle component 22.',
				defaultMessage: 'b. Fuel tank filler cap missing'
			},
			dot_inspection_form__vehicle_component_23: {
				id: 'dot_inspection_form__vehicle_component_23',
				description: 'DOT inspection form vehicle component 23.',
				defaultMessage: 'c. Fuel tank securely  attached'
			},
			dot_inspection_form__vehicle_component_24: {
				id: 'dot_inspection_form__vehicle_component_24',
				description: 'DOT inspection form vehicle component 24.',
				defaultMessage: 'All lighting devices and reflectors required by Section 393 shall be operable.'
			},
			dot_inspection_form__vehicle_component_25: {
				id: 'dot_inspection_form__vehicle_component_25',
				description: 'DOT inspection form vehicle component 25.',
				defaultMessage: 'a. Part(s) of vehicle or condition of loading such that the spare tire or any part of the load or dunnage can fall onto the roadway.'
			},
			dot_inspection_form__vehicle_component_26: {
				id: 'dot_inspection_form__vehicle_component_26',
				description: 'DOT inspection form vehicle component 26.',
				defaultMessage: 'b. Protection against shifting cargo'
			},
			dot_inspection_form__vehicle_component_27: {
				id: 'dot_inspection_form__vehicle_component_27',
				description: 'DOT inspection form vehicle component 27.',
				defaultMessage: 'a. Steering Wheel Free Play'
			},
			dot_inspection_form__vehicle_component_28: {
				id: 'dot_inspection_form__vehicle_component_28',
				description: 'DOT inspection form vehicle component 28.',
				defaultMessage: 'b. Steering Column'
			},
			dot_inspection_form__vehicle_component_29: {
				id: 'dot_inspection_form__vehicle_component_29',
				description: 'DOT inspection form vehicle component 29.',
				defaultMessage: 'c. Front Axle Beam and All Steering Components Other Than Steering Column'
			},
			dot_inspection_form__vehicle_component_30: {
				id: 'dot_inspection_form__vehicle_component_30',
				description: 'DOT inspection form vehicle component 30.',
				defaultMessage: 'd. Steering Gear Box'
			},
			dot_inspection_form__vehicle_component_31: {
				id: 'dot_inspection_form__vehicle_component_31',
				description: 'DOT inspection form vehicle component 31.',
				defaultMessage: 'e. Pitman Arm'
			},
			dot_inspection_form__vehicle_component_32: {
				id: 'dot_inspection_form__vehicle_component_32',
				description: 'DOT inspection form vehicle component 32.',
				defaultMessage: 'f. Power Steering'
			},
			dot_inspection_form__vehicle_component_33: {
				id: 'dot_inspection_form__vehicle_component_33',
				description: 'DOT inspection form vehicle component 33.',
				defaultMessage: 'g. Ball and Socket Joints'
			},
			dot_inspection_form__vehicle_component_34: {
				id: 'dot_inspection_form__vehicle_component_34',
				description: 'DOT inspection form vehicle component 34.',
				defaultMessage: 'h. Tie Rods and Drag Links'
			},
			dot_inspection_form__vehicle_component_35: {
				id: 'dot_inspection_form__vehicle_component_35',
				description: 'DOT inspection form vehicle component 35.',
				defaultMessage: 'i. Nuts'
			},
			dot_inspection_form__vehicle_component_36: {
				id: 'dot_inspection_form__vehicle_component_36',
				description: 'DOT inspection form vehicle component 36.',
				defaultMessage: 'j. Steering System'
			},
			dot_inspection_form__vehicle_component_37: {
				id: 'dot_inspection_form__vehicle_component_37',
				description: 'DOT inspection form vehicle component 37.',
				defaultMessage: 'a. Any U-bolt(s), spring hanger(s), or other axle positioning part(s) cracked, broken, loose or missing resulting in shifting of an axle from its normal position.'
			},
			dot_inspection_form__vehicle_component_38: {
				id: 'dot_inspection_form__vehicle_component_38',
				description: 'DOT inspection form vehicle component 38.',
				defaultMessage: 'b. Spring Assembly'
			},
			dot_inspection_form__vehicle_component_39: {
				id: 'dot_inspection_form__vehicle_component_39',
				description: 'DOT inspection form vehicle component 39.',
				defaultMessage: 'c. Torque, Radius or Tracking Components.'
			},
			dot_inspection_form__vehicle_component_40: {
				id: 'dot_inspection_form__vehicle_component_40',
				description: 'DOT inspection form vehicle component 40.',
				defaultMessage: 'a. Frame Members'
			},
			dot_inspection_form__vehicle_component_41: {
				id: 'dot_inspection_form__vehicle_component_41',
				description: 'DOT inspection form vehicle component 41.',
				defaultMessage: 'b. Tire and Wheel Clearance'
			},
			dot_inspection_form__vehicle_component_42: {
				id: 'dot_inspection_form__vehicle_component_42',
				description: 'DOT inspection form vehicle component 42.',
				defaultMessage: 'c. Adjustable Axle Assemblies (Sliding Subframes)'
			},
			dot_inspection_form__vehicle_component_43: {
				id: 'dot_inspection_form__vehicle_component_43',
				description: 'DOT inspection form vehicle component 43.',
				defaultMessage: 'a. Tires on any steering axle of a power unit.'
			},
			dot_inspection_form__vehicle_component_44: {
				id: 'dot_inspection_form__vehicle_component_44',
				description: 'DOT inspection form vehicle component 44.',
				defaultMessage: 'b. All other tires.'
			},
			dot_inspection_form__vehicle_component_45: {
				id: 'dot_inspection_form__vehicle_component_45',
				description: 'DOT inspection form vehicle component 45.',
				defaultMessage: 'a. Lock or Side Ring'
			},
			dot_inspection_form__vehicle_component_46: {
				id: 'dot_inspection_form__vehicle_component_46',
				description: 'DOT inspection form vehicle component 46.',
				defaultMessage: 'b. Wheels and Rims'
			},
			dot_inspection_form__vehicle_component_47: {
				id: 'dot_inspection_form__vehicle_component_47',
				description: 'DOT inspection form vehicle component 47.',
				defaultMessage: 'c. Fasteners'
			},
			dot_inspection_form__vehicle_component_48: {
				id: 'dot_inspection_form__vehicle_component_48',
				description: 'DOT inspection form vehicle component 48.',
				defaultMessage: 'd. Welds'
			},
			dot_inspection_form__vehicle_component_49: {
				id: 'dot_inspection_form__vehicle_component_49',
				description: 'DOT inspection form vehicle component 49.',
				defaultMessage: 'Requirements and exceptions as stated pertaining to any crack, discoloration or vision reducing matter (reference 393.60 for exceptions)'
			},
			dot_inspection_form__vehicle_component_50: {
				id: 'dot_inspection_form__vehicle_component_50',
				description: 'DOT inspection form vehicle component 50.',
				defaultMessage: 'Any power unit that has an inoperative wiper, or missing or damaged parts that render it ineffective.'
			},
			
			dot_inspection_form__other_failure_reasons: {
				id: 'dot_inspection_form__other_failure_reasons',
				description: 'DOT inspection form other failure reasons.',
				defaultMessage: 'List any other condition which may prevent safe operation of this vehicle.'
			},
															
		});
		
        return (
			<div className='dot_inspection_vehicle_components'>  
				
				<div className='dot_inspection_vehicle_components__all_ok'>
				
					<CheckCircle 
						name='isAllOk'
						className='dot_inspection_vehicle_components__check_circle'					
						message={<FormattedMessage {...messages.dot_inspection_form__all_ok} />}
						checked={this.props.formData.get('isAllOk')}
						onClick={(name, checked) => this.props.setFormData(name, !checked)}
					/>
								
				</div>

				<div className='dot_inspection_vehicle_component__title'>
					<FormattedMessage {...messages.dot_inspection_form__vehicle_components_title} />
				</div>
				
				<div className='row dot_inspection_vehicle_components__column_container'>

					{/* Column 1 */}
					<div className='col-sm-4 dot_inspection_vehicle_components__column'>				
						<table className='dot_inspection_vehicle_components__column_table'>
							<tbody>

								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_1} />} />
								
								<VehicleComponent 
									componentId='1'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_1} />}
									componentDetails={this.props.componentDetails.get('1')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='2'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_2} />}
									componentDetails={this.props.componentDetails.get('2')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='3'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_3} />}
									componentDetails={this.props.componentDetails.get('3')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='4'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_4} />}
									componentDetails={this.props.componentDetails.get('4')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='5'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_5} />}
									componentDetails={this.props.componentDetails.get('5')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='6'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_6} />}
									componentDetails={this.props.componentDetails.get('6')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='7'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_7} />}
									componentDetails={this.props.componentDetails.get('7')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='8'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_8} />}
									componentDetails={this.props.componentDetails.get('8')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='9'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_9} />}
									componentDetails={this.props.componentDetails.get('9')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='10'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_10} />}
									componentDetails={this.props.componentDetails.get('10')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='11'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_11} />}
									componentDetails={this.props.componentDetails.get('11')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
							

								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_2} />} />

								<VehicleComponent 
									componentId='12'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_12} />}
									componentDetails={this.props.componentDetails.get('12')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='13'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_13} />}
									componentDetails={this.props.componentDetails.get('13')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='14'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_14} />}
									componentDetails={this.props.componentDetails.get('14')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='15'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_15} />}
									componentDetails={this.props.componentDetails.get('15')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='16'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_16} />}
									componentDetails={this.props.componentDetails.get('16')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='17'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_17} />}
									componentDetails={this.props.componentDetails.get('17')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>


								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_3} />} />

								<VehicleComponent 
									componentId='18'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_18} />}
									componentDetails={this.props.componentDetails.get('18')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='19'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_19} />}
									componentDetails={this.props.componentDetails.get('19')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='20'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_20} />}
									componentDetails={this.props.componentDetails.get('20')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>

							</tbody>
						</table>				
					</div>
						
					{/* Column 2 */}
					<div className='col-sm-4 dot_inspection_vehicle_components__column'>				
						<table className='dot_inspection_vehicle_components__column_table'>
							<tbody>

								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_4} />} />
								
								<VehicleComponent 
									componentId='21'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_21} />}
									componentDetails={this.props.componentDetails.get('21')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='22'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_22} />}
									componentDetails={this.props.componentDetails.get('22')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='23'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_23} />}
									componentDetails={this.props.componentDetails.get('23')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								
								
								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_5} />} />

								<VehicleComponent 
									componentId='24'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_24} />}
									componentDetails={this.props.componentDetails.get('24')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>


								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_6} />} />

								<VehicleComponent 
									componentId='25'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_25} />}
									componentDetails={this.props.componentDetails.get('25')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='26'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_26} />}
									componentDetails={this.props.componentDetails.get('26')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>


								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_7} />} />

								<VehicleComponent 
									componentId='27'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_27} />}
									componentDetails={this.props.componentDetails.get('27')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='28'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_28} />}
									componentDetails={this.props.componentDetails.get('28')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='29'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_29} />}
									componentDetails={this.props.componentDetails.get('29')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='30'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_30} />}
									componentDetails={this.props.componentDetails.get('30')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='31'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_31} />}
									componentDetails={this.props.componentDetails.get('31')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='32'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_32} />}
									componentDetails={this.props.componentDetails.get('32')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='33'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_33} />}
									componentDetails={this.props.componentDetails.get('33')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='34'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_34} />}
									componentDetails={this.props.componentDetails.get('34')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='35'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_35} />}
									componentDetails={this.props.componentDetails.get('35')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='36'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_36} />}
									componentDetails={this.props.componentDetails.get('36')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>


								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_8} />} />

								<VehicleComponent 
									componentId='37'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_37} />}
									componentDetails={this.props.componentDetails.get('37')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='38'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_38} />}
									componentDetails={this.props.componentDetails.get('38')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='39'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_39} />}
									componentDetails={this.props.componentDetails.get('39')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>

							</tbody>							
						</table>				
					</div>

					{/* Column 3 */}
					<div className='col-sm-4 dot_inspection_vehicle_components__column'>
						<table className='dot_inspection_vehicle_components__column_table'>
							<tbody>

								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_9} />} />
								
								<VehicleComponent 
									componentId='40'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_40} />}
									componentDetails={this.props.componentDetails.get('40')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='41'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_41} />}
									componentDetails={this.props.componentDetails.get('41')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='42'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_42} />}
									componentDetails={this.props.componentDetails.get('42')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>


								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_10} />} />

								<VehicleComponent 
									componentId='43'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_43} />}
									componentDetails={this.props.componentDetails.get('43')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='44'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_44} />}
									componentDetails={this.props.componentDetails.get('44')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>


								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_11} />} />

								<VehicleComponent 
									componentId='45'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_45} />}
									componentDetails={this.props.componentDetails.get('45')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='46'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_46} />}
									componentDetails={this.props.componentDetails.get('46')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='47'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_47} />}
									componentDetails={this.props.componentDetails.get('47')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>
								<VehicleComponent 
									componentId='48'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_48} />}
									componentDetails={this.props.componentDetails.get('48')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>


								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_12} />} />

								<VehicleComponent 
									componentId='49'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_49} />}
									componentDetails={this.props.componentDetails.get('49')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>								


								<VehicleComponentHeader groupName={<FormattedMessage {...messages.dot_inspection_form__vehicle_components_header_13} />} />

								<VehicleComponent 
									componentId='50'
									componentName={<FormattedMessage {...messages.dot_inspection_form__vehicle_component_50} />}
									componentDetails={this.props.componentDetails.get('50')}
									onComponentDetailsChanged={this.props.onComponentDetailsChanged}
								/>

								<tr>
									<td className='dot_inspection_vehicle_components__cell'>					
										
									</td>
									<td className='dot_inspection_vehicle_components__cell'>
										
									</td>
									<td className='dot_inspection_vehicle_components__cell'>
										
									</td>
									<td colSpan="2" className='dot_inspection_vehicle_components__cell'>
										<div>
											<FormattedMessage {...messages.dot_inspection_form__other_failure_reasons} />
										</div>
										<div>
											<textarea 
												className='dot_inspection_vehicle_components__other_failure_reasons'
												id='otherFailureReasons'
												maxLength='200'
												defaultValue={this.props.formData.get('otherFailureReasons')}
												onChange={this.handleChangeDebouncedCaller}
											></textarea>
										</div>
									</td>
								</tr>

							</tbody>					
						</table>																
					</div>						
					
				</div>      

				<DotResult 
					formData={this.props.formData}
					formValidationMessages={this.props.formValidationMessages}
					userLocale={this.props.userLocale}
					poCreationDate={this.props.poCreationDate}
					setFormData={this.props.setFormData}
					onInspectionDateChangeHandler={this.props.onInspectionDateChangeHandler}					
				/>

			</div>
        );
    }
}

export default VehicleComponents;