import * as constants from 'constants/App';
import initialAppState from 'reducers/InitialAppState';


export function uiDataLoginView(state = initialAppState.getIn(['uiData','loginView']), action) {

    switch (action.type) {
        case constants.SIGN_IN_DATA_REQUESTED:
            return state.set('isFetchingData', true).set('loginErrorMessage', undefined);
        case constants.FETCH_SIGN_IN_DATA_REJECTED:
            return state.set('loginErrorMessage', action.errorMessage).set('isFetchingData', false).set('genericLoginErrorMessage',undefined);
        case constants.FETCH_SIGN_IN_DATA_RESOLVED:
            return state.set('isFetchingData', false).set('genericLoginErrorMessage',undefined);
        case constants.CLEAR_LOGIN_ERROR_MESSAGE:
            return state.set('loginErrorMessage', undefined);
        case constants.SET_ERROR_MESSAGE:
            return state.set('genericLoginErrorMessage',action.errorMessage);
        default:
            return state;
    }
}